const navigation = async () => {
  const paneDetails = window.getActivePaneDetails('#pane-2');
  //We need to slow down the request because we end up in a mess if
  //healthcheck_assessment gets loaded before the loading page!!
  await new Promise(resolve => setTimeout(resolve, 500));

  //wait for the usernames to load before moving on as well...
  //const [user1Res, user2Res] = await Promise.all([window.z2hApp.pageData.healthcheck.username.username1.promise,
  //window.z2hApp.pageData.healthcheck.username.username2.promise])
  const user1Res = await window.z2hApp.pageData.healthcheck.username.username1.promise;

  const breaches1 = user1Res && user1Res.status === 200 ? await user1Res.data : [];

  //const breaches2 = user2Res && user2Res.status === 200 ? await user2Res.json() : [];
  window.z2hApp.pageData.healthcheck.username.username1.breaches = breaches1;
  //window.z2hApp.pageData.healthcheck.username.username2.breaches = breaches2;
  localStorage.setItem('healthcheck-complete', true);

  z2hApp.requestData('post', '', 'users/@me', JSON.stringify({ healthcheck: true }));
  z2hApp.requestData('post', '', 'users/@me/complete-task', JSON.stringify({ taskName: 'step_completed_setup' }));

  z2hApp.paneNavigation('healthcheck_assessment', paneDetails.pane, paneDetails.activePaneIndex + 1);
};

module.exports = ({ z2hApp }) => {
  const password = window.z2hApp.pageData.healthcheck.password || {};
  if (!password || !password.data || password.data === '') {
    navigation();
    return;
  }
  //We only need to send the first 5 characters of the sha1 password
  const fiveOfSha1 = window.z2hApp.pageData.healthcheck.password.data.substr(0, 5);
  const restOfSha1 = password.data.substr(5);
  //clear the password field now:
  $('#healthcheck_password_1').val('');

  async function getPwned() {
    const hibp =
      fiveOfSha1 &&
      //(await fetch(`https://cors-anywhere.herokuapp.com/https://api.pwnedpasswords.com/range/${fiveOfSha1}`, options));
      (await z2hApp.requestData('get', '', `pwned/${fiveOfSha1}/password`));

    const res = hibp.status === 200 && (await hibp.data);
    //we get back a text file consisting of a bunch of sha1's each followed by a colon and
    //the number of times its been compromised.
    const idx = res.search(restOfSha1.toUpperCase() + ':');
    //I'm sure theres a neater way to do this but my brain is not working right now...
    if (idx >= 0) {
      let compromisedTimes = res.substr(idx + restOfSha1.length + 1, 10);
      compromisedTimes = compromisedTimes.match(/.+/g)[0];
      window.z2hApp.pageData.healthcheck.password.compromised = compromisedTimes;
    } else {
      window.z2hApp.pageData.healthcheck.password.compromised = 0;
    }
    navigation();
  }
  getPwned();
};
