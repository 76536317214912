const config = require('../../../config');
const z2hApp = require('../../app/z2hApp');
const { includes } = require('../../getPageData_helpers/groupIconList');

const { infoTextRow, thirdheading, primaryText } = require('../../page_helpers/components');
const strings = config.strings;
const _ = require('lodash');

const { subheading } = require('../../page_helpers/components');

module.exports = ({ nav, data }) => {
  const z2hTemplates = require('../../app/templates');

  const presetsTable = () => {
    const table = z2hApp.constructBlock(
      z2hTemplates.compileBlockAttribute({
        template: 'block-suggested-presets',
        fields: {
          handleManual: 'handleManual',
          action: 'confirmSelectPresets',
          class: ['remove:loading-wheel'],
        },
      }),
    );

    const columnGroup = $(table).find('.column-group');

    // Clear out container
    columnGroup.empty();

    // Get action to perform whenever one of these services is clicked
    const action = 'selectPreset';

    // Render each service

    _.cloneDeep(window.state.topPresets).forEach((s) => {
      const pageItem = z2hApp.constructBlock(
        z2hTemplates.compileBlockAttribute({
          template: 'block-login-avatar',
          fields: { avatar: s.url, label: s.name, action, selected: false, data_id: s.id },
        }),
      );
      columnGroup.append(pageItem);
    });

    //Just chuck our table in a div so we can .html() it and send it to our insertable
    const wrapper = $('<div>');
    wrapper.append(table);
    return wrapper;
  };

  const reonboarding = window.state.userData.reonboarding;
 

  //Render the page
  return {
    template: 'block-section-onboarding',
    fields: {
      id: 'select-presets',
      class: ['no-title', 'has-inner-scrollable'],

    },
    rows: [
      reonboarding && {
        template: 'block-onboarding-close',
      },
      thirdheading({ text: strings.ADD_SERVICE_SEARCH_ONBOARD_NOW_ITS_YOUR_TURN() }),

      {
        template: 'block-section-row',
        fields: {
          class: 'no-border',
        },
        columns: [primaryText(strings.LETS_START_SELECT_COUNT()(15), false, false, true, true)],
      },

      {
        template: 'block-insertable',
        fields: {
          //class: ['column', 'no-border'],
          class: ['scrollable'],

          html: presetsTable().html(),
        },
      },
      {
        template: 'block-section-row',
        fields: {
          class: 'centered-text',
        },
        columns: [
          {
            template: 'block-text-button',
            fields: {
              class: 'cta-button',
              text: strings.CREATE_SERVICES(),
              action: 'createServicesBatch',
              navigation_template: 'createServicesBatch_loading',
              navigation_data: nav + 1,
              navigation_validation: 'validateCreateServicesBatch',
            },
          },
        ],
      },
    ],
  };
};
