module.exports = {
  createAccount_updateDisplayName: require('./createAccount_updateDisplayName'),
  hideQuickSelectAddLoginScreen: require('./hideQuickSelectAddLoginScreen'),
  createAccount_addNewService: require('./createAccount_addNewService'),
  confirmActivationCode: require('./confirmActivationCode'),
  phoneNumberSignIn: require('./phoneNumberSignIn'),
  hideLogin: require('./hideLogin'),
  selectLanguage: require('./selectLanguage'),

};
