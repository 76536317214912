const showToastMessage = require('../../action_helpers/showToastMessage');
const $ = require('jquery');
const strings = require('../../../config').strings;
const fb = require('../../app/firebaseUtils');

module.exports = function extensionLogin({ }) {
  const phoneNumber = $('#sign-in-number').intlTelInput('getNumber');
  const appVerifier = window.recaptchaVerifier;
  window.state = window.state || {};
  window.state.phoneNumber = phoneNumber;

  console.info('Authenticating with phone number...', phoneNumber);

  fb
    .auth()
    .signInWithPhoneNumber(phoneNumber, appVerifier)
    .then(confirmationResult => {
      console.info('signInWithPhoneNumber: success.');
      $('#block-activation-code-1').focus();

      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      window.confirmationResult = confirmationResult;

      if (window.parent && window.parent.postMessage) {
        // Send confirmationResult.verificationId to window.parent using
        const targetOrigin = '*';
        const message = confirmationResult.verificationId;
        window.parent.postMessage(message, targetOrigin);
      }
    })
    .catch(error => {
      console.error('signInWithPhoneNumber: error: ', error);
      const message = error.message ? error.message : strings.MSG_FALLBACK_ERROR_MESSAGE();

      showToastMessage(message);

      // Go back to previous pane
      $('#verify-device-2')
        .removeClass('active')
        .addClass('right');
      $('#verify-device-1')
        .removeClass('left')
        .addClass('active');
    });

  return Promise.resolve();
};
