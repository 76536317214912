module.exports = async ({}) => {
  //const { userData } = require('../../app/z2hApp');

  const z2hApp = window.z2hApp;

  // Highlight 'Profile' in the main nav
  //z2hApp.highlightNavElement(
  //  $('#main-nav')
  //    .find('[data-template=viewProfile]')
  //    .closest('li'),
  //);

  // Highlight 'Profile' in the main nav
  //z2hApp.highlightNavElement(
  //  $('[data-template=inviteAFriend]').find('div'),
  //  //.closest('div'),
  //);

   
  return new Promise(async (resolve, reject) => {
    const displayAutoFill = await checkIfWeShouldDisplayOptionToEnableAutofill();
    const user = await profileData();

    return resolve({ ...z2hApp.userData, ...user, ...{ displayEnableAutofill: displayAutoFill } });
  });

  // Check if user has autofill enabled on their device
  // return userData()
  //   .then(userObj => {
  //     return checkIfWeShouldDisplayOptionToEnableAutofill();
  //   })
  //   .then(shouldDisplay => {
  //     return { ...userData, ...score, ...subscription, displayEnableAutofill: shouldDisplay };
  //   });
};

// =================================================================================================
// Function to check if user has autofill enabled on their device and therefore whether we should
// show them an option to go and enable autofill.
// This function will return a promise which will resolve with Boolean(false) if the user
// has enabled forghetti in their Accessibility -> Installed Services settings.
async function checkIfWeShouldDisplayOptionToEnableAutofill() {
  const cordova = window.cordova;
  if (!cordova || !cordova.exec || cordova.platformId !== 'android') return Promise.resolve(false);

  return new Promise((resolve, reject) => {
    cordova.exec(
      (winParam) => resolve(winParam !== true),
      (error) => reject(error),
      'AndroidAutofillPlugin',
      'hasPermission',
    );
  }).catch((err) => {
    console.error(err);
    return false;
  });
}

function getScore() {
  let score = 0;
  //Create account
  score += 1;
  //signature doodle
  if ((window.state.currentServices && window.state.currentServices.length > 0) || window.state.groupsList.length > 0)
    score += 1;
  //first login
  if ((window.state.currentServices && window.state.currentServices.length > 0) || window.state.groupsList.length > 0)
    score += 1;
  //reset password
  //if (window.state.userData.resetPasswordSelected) score += 1;
  score += 1;
  //healthcheck
  if (localStorage.getItem('healthcheck-complete') || window.state.userData.healthcheck) score += 1;

  return { total: 5, score };
}

async function profileData() {
  let user; 
  if (window.state.userDataPromise) {
    user = await window.state.userDataPromise;
  } else {
    user = await z2hApp.fetchFullUserDetails();
  }

  const score = getScore();
  let premium = window.state && window.state.userData && window.state.userData.subscription;
  if (!premium && window.state.launchData.home.premium) {
    premium = true;
  }

  const subscription = {
    premium,
    subscriptionType: premium.subscription_type || 'premium',
  };
  return { ...score, ...subscription, ...user };
}
