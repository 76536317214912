module.exports = {
  copyPrimaryTextToClipboard: require('./copyPrimaryTextToClipboard'),
  purchaseSelectedProduct: require('./purchaseSelectedProduct'),
  displayContextualHelp: require('./displayContextualHelp'),
  openUpgradeTodayUrl: require('./openUpgradeTodayUrl'),
  uploadAndCropImage: require('./uploadAndCropImage'),
  hidePane3: require('./hidePane3'),
  openMenu: require('./openMenu'),
  logout: require('./logout'),
  justCropImage: require('./justCropImage'),
  openBannerUrl: require('./openBannerUrl'),
  badgeGotoPane2Template: require('./badgeGotoPane2Template'),
  badgeGotoAction: require('./badgeGotoAction'),
  openBannerUrlSocial: require('./openBannerUrlSocial'),
  openBannerUrlRate: require('./openBannerUrlRate'),
  purchaseLegal: require('./purchaseLegal'),
  reonboarding: require('./reonboarding'),
  gotoReonboarding: require('./gotoReonboarding'),


};
