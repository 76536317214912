const showToastMessage = require('../action_helpers/showToastMessage');
const strings = require('../../config').strings;

module.exports = (section) => {
  let error = false;

  const selected = $(section).find('[data-selected="true"]');

  // Check that code is not empty
  if (selected.length === 0) {
    showToastMessage(strings.MUST_SELECT_MORE_THAN_ONE_SERVICES(), { center: true });
    error = true;
  }

  if (selected.length === 15) {
    showToastMessage(strings.TOAST_TOO_MANY_SERVICES_SELECTED()(15), { center: true });
    error = true;
  }
  if (error) {
    throw new Error('Validation error');
  }
};
