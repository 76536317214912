const config = require('../../../config');

const { GRP_INVITE_EMAIL_SUBJECT, GRP_INVITE_EMAIL_BODY } = config.strings;
module.exports = ({ }) => {
  const inviteId = window.state.selectedInviteId;
  const inviteUrl = config.urls.GROUP_INVITE_URL(inviteId);

  // We also include the referral URL in the email
  const referralCode = window.state.launchData.home.referral_id;
  const profileName = window.state.userData.name;
  const referralUrl = config.urls.REFERRAL_LINK(referralCode, profileName || window.state.userData.id);

  const emailSubject = encodeURIComponent(GRP_INVITE_EMAIL_SUBJECT());
  const emailBody = encodeURIComponent(GRP_INVITE_EMAIL_BODY()(inviteUrl, referralUrl, referralCode) + '\n\n\n');

  window.location.href = `mailto:?subject=${emailSubject}&body=${emailBody}`;
};
