const showToastMessage = require('../../action_helpers/showToastMessage');
const strings = require('../../../config').strings;

module.exports = ({ z2hApp, batch }) => {
  const serviceId = window.state.selectedServiceId;
  const services = window.state.currentServices;
  const service = services.find((s) => s.id === serviceId);

  const conf = batch ? true : confirm(strings.MSG_DELETE_LOGIN_CONFIRM());
  if (conf) {
    window.state.currentServices = services.filter((s) => s.id !== serviceId);
    let group = window.state.groupsList.find((g) => g.id === window.state.selectedGroupId);
    group.services = group.services.filter((s) => s.id !== serviceId);

    localStorage.setItem('services-' + group.id, JSON.stringify(group.services));

    z2hApp.showServices(window.state.currentServices, null, true, false);
    z2hApp
      .requestData('delete', '', 'services/' + serviceId)

      .then((response) => {
        if (response.status <= 209) {
          !batch && showToastMessage(strings.MSG_LOGIN_DELETED()(service.name));
        } else {
          !batch && showToastMessage(response.user_message);
        }
      });

    !batch && z2hApp.paneNavigation('back', $('#pane-2'), 0);
  }
};
