const createAccountActions = require('./createAccount');
const miscellaneousActions = require('./miscellaneous');
const extensionActions = require('./extension');
const serviceActions = require('./services');
const profileActions = require('./profile');
const groupActions = require('./groups');
const healthcheckActions = require('./healthcheck');
const importServices = require('./importServices');


// Export an object which contains all action functions at the root level
const actions = {
  ...createAccountActions,
  ...miscellaneousActions,
  ...extensionActions,
  ...profileActions,
  ...serviceActions,
  ...groupActions,
  ...healthcheckActions,
  ...importServices,
};

module.exports = actions;
