const config = require('../../../config');
const { infoTextRow, thirdheading, primaryText } = require('../../page_helpers/components');

const strings = require('../../../config').strings;

module.exports = ({ nav }) => {
  const headerFields = () => {
    let fields = {
      id: 'doodle-pad-onboarding-confirm',
      class: 'no-title',
      navbar: false,
    };

    return fields;
  };

  const rows = [];

  //reonboarding && rows.push({ template: 'block-onboarding-close' })
  rows.push(thirdheading({ text: strings.KEYCHAIN_HEADING(), additionalClass: 'centered-text' }));

  rows.push({
    template: 'block-section-row',
    fields: { class: 'no-border', primary_heading: strings.KEYCHAIN_MESSAGE_2() },
  });

  rows.push({
    template: 'block-section-row',
    fields: { class: 'no-border' },
    columns: [{ template: 'block-info-text', fields: { text: strings.KEYCHAIN_MESSAGE(), class: 'centered-text' } }],
  });

  rows.push({
    template: 'block-section-row',
    fields: { class: 'no-border' },
    columns: [
      {
        template: 'block-onboarding-image',
        fields: { imagePath: 'img/onboarding/password350-once.gif', imageStyle: 'max-height: 200px' },
      },
    ],
  });

  rows.push({
    template: 'block-section-row',
    fields: { class: 'no-border' },
    columns: [{ template: 'block-info-text', fields: { text: strings.KEYCHAIN_MESSAGE_3(),  class: 'centered-text' } }],
  });


  rows.push({
    template: 'block-section-row',
    fields: { class: ['no-border', 'no-grow'] },
    columns: [
      {
        template: 'block-cta-button',
        fields: {
          text: strings.KEYCHAIN_YES(),
          navigation_action: 'keychain_yes',
        },
      },
    ],
  });

  rows.push({
    template: 'block-section-row',
    fields: { class: 'no-border' },
    columns: [
      {
        template: 'block-text-button',
        fields: {
          text: strings.KEYCHAIN_NO(),
          navigation_action: 'keychain_no',
        },
      },
    ],
  });

  return {
    template: 'block-section-onboarding',
    fields: headerFields(),
    rows: rows,
  };
};
