const { addToBatch, processBatch } = require('../../actions/services/addNewServicesBatch');
const _ = require('lodash');
const serviceTemplate = () => ({
  groupId: '',
  serviceData: {
    name: '',
    username: '',
    website: '',
    icon: '',
  },
  serviceUnsaved: false,
  fieldsArray: [],
  fieldsSaved: {},
  fieldsUnsaved: {},
});

module.exports = ({ section, z2hApp }) => {
  const { selectedGroupId } = window.state;
  const selectedGroup = state.groupsList.find((g) => g.id === selectedGroupId) || {};
  const services = window.state.selectedImportedServices;

  const batch = [];
  for (const s of services) {
    const service = serviceTemplate();
    const { serviceData, fieldsArray, fieldsSaved, fieldsUnsaved } = service;

    service.groupId = selectedGroupId;
    serviceData.name = s.name;
    serviceData.username = s.username;
    serviceData.website = s.address;
    serviceData.icon = s.url;
    z2hApp.pageData.service = _.cloneDeep(service);

    batch.push(service);
  }

  const onSuccess = () => z2hApp.paneNavigation('importMoveServicesWelcome', $('#pane-2'), 1);
  const onFailure = () => z2hApp.paneNavigation('back', $('#pane2'), 1);

  addToBatch(batch, true);
  processBatch({ z2hApp, onSuccess, onFailure, importing: true });
};
