const z2hApp = require("../../app/z2hApp");

module.exports = ({ currentTarget }) => {
  const selectedGroup = currentTarget && $(currentTarget).attr('data-id')
  const groupId = selectedGroup || z2hApp.currentGroup().id;

  if (groupId) {
    $(`#groups-nav li[data-id=${groupId}]`).click();
  }
};
