module.exports = async ({ z2hApp, section }) => {
 

  if (!window.state.userDataPromise) {
    window.state.userDataPromise = z2hApp.fetchFullUserDetails();
  }
  let template = 'viewYourSubscription';

  if (section.length) {
    const paneDetails = window.getActivePaneDetails('#pane-2');
    z2hApp.paneNavigation(template, $('#pane-2'), paneDetails.activePaneIndex + 1);
  } else {
    z2hApp.paneNavigation(template, $('#pane-2'), 1);
  }
};
