const strings = require('../../../config').strings;
const fb = require('../../app/firebaseUtils');

module.exports = ({ z2hApp }) => {
  const conf = confirm(strings.MSG_LOGOUT_CONFIRM());
  if (conf) {
    z2hApp.clearCachedData();
    fb.signOut();
    window.location.reload();
  }
};
