import fb from '../../app/firebaseUtils';
/**
 * Confirm migration code
 */
const showToastMessage = require('../../action_helpers/showToastMessage');

module.exports = ({ section, z2hApp }) => {
  // let verificationCode = '';
  // for (let i = 1; i <= 6; i++) {
  //   verificationCode += $(section)
  //     .find('#block-activation-code-' + i)
  //     .val();
  // }

  let verificationCode = $(section).find('[data_name="new-user-activation-code"] input').val();
  let credential = null;
  if (window.creds && !window.creds.instantVerification) {
    window.creds.code = verificationCode;
    credential = window.creds;

  } else if (window.creds && window.creds.instantVerification){
      credential = window.creds
  } else {
    credential = fb.PhoneAuthProvider(window.verificationId, verificationCode);
  }

  const pane = '#pane-2';

  const activePaneIndex = $(pane + '-inner')
    .children('.active')
    .index();
  $(pane).addClass('busy');

  fb.reauthenticateAndRetrieveDataWithCredential(credential)
    .then((userCredential) => {
      console.log('userCredential: ', userCredential);
      z2hApp.paneNavigation('migratePhoneNumber2A', $(pane), activePaneIndex + 1);
    })
    // .then(()=>{
    //   return z2hApp.requestData('post', '', 'users/@me/change-number', { new_number: window.state.newPhoneNumber })
    // }).then(()=>{
    //   fb.reloadUser()
    // })
    // .then((_) => {

    //   z2hApp.paneNavigation('migratePhoneNumber3', $(pane), activePaneIndex + 1);
    // })
    .catch((error) => {
      $(pane).removeClass('busy');
      showToastMessage(`Incorrect verification code. Please try again`);
      console.error('Update (migrate) phone number: error: ', error);

      // // Error; SMS not sent
      // if (error.code === 'auth/credential-already-in-use') {
      //   showToastMessage(`Error: This phone number already has a forghetti account`);
      //   // Go back
      //   z2hApp.paneNavigation('migratePhoneNumber1', $(pane), activePaneIndex - 1);
      // } else if (error.code === 'auth/code-expired') {
      //   showToastMessage(`Incorrect verification code. Please try again`);
      //   // Go back
      //   z2hApp.paneNavigation('migratePhoneNumber1', $(pane), activePaneIndex - 1);
      // } else {
      //   showToastMessage(error);
      // }
    });
};

/*
  firebase.auth().signInWithPhoneNumber('+447759027599', window.recaptchaVerifier).then(confirmationResult => {
  // const verificationCode = prompt()
  const credential = firebase.auth.PhoneAuthProvider.credential(confirmationResult.verificationId, '221116')
  firebase.auth().currentUser.updatePhoneNumber(credential)
  })
*/
