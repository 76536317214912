const { strings } = require('../../../config')
const lochyLoadingTemplate = require('./lochyLoadingTemplate');

module.exports = () => ({
  ...(lochyLoadingTemplate({
    id: 'healthcheck_detail_loading',
    heading: strings.HEALTHCHECK_LOADING_DETAIL(),
    lochy: 'mechanic',
  }
  ))
})