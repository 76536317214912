const config = require('../../../config');

const onboadingTemplate = require('./onboarding_template');

const { strings } = config;

const { START, ONBOARDING_RESETPASSWORD_HEADING, ONBOARDING_RESETPASSWORD_TEXT, ONBOARDING_HOW_IT_WORKS } = strings;

module.exports = ({ nav }) => {
  return onboadingTemplate({
    nav,
    imagePath: 'img/onboarding/onboarding4.png',
    secondary_heading: ONBOARDING_HOW_IT_WORKS(),
    primary_heading: ONBOARDING_RESETPASSWORD_HEADING(),
    text: ONBOARDING_RESETPASSWORD_TEXT(),
    nextPage: 'onboarding_doodletutorial1',
    nextLink: START(),
    showSkip: true,
  });
};
