module.exports = ({ currentTarget }) => {
  const DOMTarget = $(currentTarget);
  const allOrSimple = DOMTarget.attr('data-value');

  const fieldData = z2hApp.pageData.service.fieldsUnsaved[z2hApp.active.pageId];
  // eslint-disable-next-line camelcase
  const { generation_settings } = fieldData;

  //TODO Get this list from the server
  const simpleSymbols = [
    '-',
    '!',
    '$',
    '^',
    '*',
    '_',
    '+',
    '[',
    ']',
    '.'
  ];

  $('.special-character-cell').each((idx, elem) => {
    if (allOrSimple === 'ALL') {
      $(elem).attr('data-selected', true);
    } else {
      $(elem).attr('data-selected', simpleSymbols.includes($(elem).text()));
    }
  });

  const symbolsActive = $('.special-character-cell[data-selected="true"]').text();
  const allSymbols = $('.special-character-cell').text();
  const excludedSymbols = allSymbols
    .split('')
    .filter((f) => symbolsActive.indexOf(f) < 0)
    .join('');

  generation_settings.symbols = symbolsActive.length > 0;
  generation_settings.simple_symbols = false;
  generation_settings.exclude = symbolsActive.length > 0 ? excludedSymbols : '';
};
