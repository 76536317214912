module.exports = {
  goToServiceCodes_extension: require('./goToServiceCodes_extension'),
  usePasswordInCurrentTab: require('./usePasswordInCurrentTab'),
  useUsernameInCurrentTab: require('./useUsernameInCurrentTab'),
  extensionChooseGroup: require('./extensionChooseGroup'),
  extensionAddLogin: require('./extensionAddLogin'),
  extensionLogin: require('./extensionLogin'),
  usePasswordInVerify: require('./usePasswordInVerify'),
  useUsernameSecondaryInCurrentTab: require('./useUsernameSecondaryInCurrentTab'),
};
