module.exports = ({ currentTarget, z2hApp }) => {
  const value = $(currentTarget).val();
  if (!value) return;

  // Update "unsaved" data
  const fieldData = z2hApp.pageData.service.fieldsUnsaved[z2hApp.active.pageId];
  const generation_settings = fieldData.generation_settings;

  generation_settings.words = parseInt(value);
};
