const config = require('../../../config');
const onboardingPageTemplate = require('../createAccount/createAccount_onboarding_template');

module.exports = ({ data, nav }) => {
  return onboardingPageTemplate(
    1,
    config.strings.ONBOARDING_HEADER_1(),
    config.strings.ONBOARDING_MESSAGE_1(),
    'img/onboarding/desktop/1.jpg',
    'onboarding2',
    'back',
    parseInt(nav) + 1,
    parseInt(nav) - 1,
    true, // hideSkip
  );
};
