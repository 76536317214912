const fuzzBall = require('fuzzball');
const parseUrl = require('./parseUrl')

//Use fuzzball to try and match our urls.
module.exports = function fuzzyMatchUrl(url, urlsToSearch) {
  const parsedUrl = parseUrl(url);
  const urls = urlsToSearch.map(url => ({ hostname: parseUrl(url.address).hostname, id: url.id }));

  const options = {
    returnObjects: true,
    scorer: fuzzBall.token_set_ratio,
    limit: 5,
    full_process: false,
    processor: urls => urls.hostname
  }
  let hostname = parsedUrl.hostname;



  hostname = hostname.replace('www.', '')
  const hostArray = hostname.split('.');

  //subdomain + domain + two dot tld 
  if (hostArray.length >= 4) {
    hostname = hostArray[1];
  }
  //could be subdomain +domain +tld or
  //domain +two dot tld
  else if (hostArray.length == 3) {
    if (hostArray[1] == 'org' || hostArray[1] == 'co') {
      hostname = hostArray[0];
    }
    else {
      hostname = hostArray[1];
    }
  }
  //one dot tld
  else if (hostArray.length == 2) {
    hostname = hostArray[0];
  }
  //no tld.



  console.log(hostname)
  return fuzzBall.extract(hostname, urls, options);

};
