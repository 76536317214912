const LINK_EXPIRY_VALUES = {
  15: '15 minutos',
  30: '30 minutos',
  60: '1 hora',
  360: '6 horas',
  720: '12 horas',
  1440: '1 dia',
  '-1': 'Nunca',
};

const LINK_MAX_USE_VALUES = {
  1: 'Uma vez',
  2: 'Duas vezes',
  5: '5 vezes',
  10: '10 vezes',
  20: '20 vezes',
  50: '50 vezes',
  '-1': 'Sem limite',
};

module.exports = {
  YOU: 'Você',
  BACK: 'Retroceder',
  NEXT: 'Próximo',
  CONTINUE: 'Continuar',
  SAVE: 'Salvar',
  ADD: 'Adicionar',
  SKIP: 'Pular',
  CANCEL: 'Cancelar',
  PREVIOUS: 'Anterior',
  EDIT: 'Editar',
  NONE: 'Nenhum',
  START: 'Começar',
  SEARCH: 'Pesquisar',
  CLOSE: 'Fechar',
  SEE_DETAILS: 'Ver detalhes',
  UNAVAILABLE: 'Indisponível',
  DONE: 'Concluído',
  WATCH_AGAIN: 'Ver novamente',
  NEW: 'Novo',

  UNAVAILABLE_FORGHETTIBLES_MESSAGE:
    'Alguns dos seus "forghettibles" estão indisponíveis porque você excedeu o limite do seu plano gratuito. Cadastre-se para uma conta premium para obter forghettibles ilimitados!',

  FORGHETTI_TAGLINE: 'Esqueça suas senhas. Para sempre.',
  CREATE_ACCOUNT: 'Criar uma conta ',
  HAVE_ACCOUNT: 'eu já tenho uma conta',

  COMBOBULATING: '... combinando os seus forghettibles ...',

  CREATE_ACCOUNT_1_HEADER: 'Insira o seu número',
  CREATE_ACCOUNT_1_HEADING: 'Verifique o seu dispositivo',
  CREATE_ACCOUNT_1_MSG:
    'Por favor, digite o seu número de telemóvel para receber um <strong>código de verificação único</strong> por SMS.',
  CREATE_ACCOUNT_1_PLACEHOLDER: 'O teu número',
  CREATE_ACCOUNT_1_MSG_2:
    'Altere o código internacional se precisar e insira o número do seu telemóvel\npor exemplo, 938512345.',
  CREATE_ACCOUNT_1_MSG_3:
    'Mensagens de SMS serão apenas enviadas para verificar o seu dispositivo ou para atualizações essenciais da aplicação.',

  ERROR_TEL_NUM_EMPTY: 'Número do telemóvel deve ser fornecido',

  MAIN_NAV_GROUP: 'Grupos',
  MAIN_NAV_PROFILE: 'Perfil',
  MAIN_NAV_HEALTHCHECK: 'Saúde',
  MAIN_NAV_IMPORT: 'Serviços de importação',
  MAIN_NAV_LOGOUT: 'Sair',
  NAVBAR_JOIN_GROUP: 'Juntar-se ao grupo',
  NAVBAR_NEW_GROUP: 'Novo grupo',
  NAVBAR_NEW_PASSWORD: 'Nova senha',
  NAVBAR_NEW_PIN: 'Novo pin',
  NAVBAR_NEW_WORD: 'Nova palavra memorável',
  NAVBAR_HOME: 'Casa',
  NAVBAR_GROUPS: 'Grupos',
  NAVBAR_HEALTHCHECK: 'Saúde',
  NAVBAR_PROFILE: 'Perfil',

  /* Lochy loading - paneBusyText.js */
  LOCHY_LOADING_TEXT_DEFAULT: 'A carregar...',
  LOCHY_LOADING_TEXT_GROUP: (group) => `A carregar serviços para <em>${group}</em>`,
  LOCHY_LOADING_TEXT_GROUPSLIST: 'A carregar grupos',
  LOCHY_LOADING_TEXT_HEALTHCHECK: 'A carregar verificação de saúde',
  LOCHY_LOADING_TEXT_CHOOSE_SEARCH_ICON: 'A pesquisar na rede ícones de serviço',
  LOCHY_LOADING_TEXT_CHOOSE_CHOOSE_GROUP_ICON: 'A carregar ícone do grupo',
  LOCHY_LOADING_TEXT_CREATE_GROUP_INVITE: 'A criar convite de grupo',
  LOCHY_LOADING_TEXT_EDIT_SERVICE: 'Editar detalhes do serviço',
  LOCHY_LOADING_TEXT_UPDATE_SERVICE: 'A salvar detalhes do serviço',
  LOCHY_LOADING_TEXT_PROFILE: 'A carregar seu perfil',
  LOCHY_LOADING_TEXT_SUBSCRIPTION: 'A carregar detalhes da sua assinatura',

  /*                                 */

  CREATE_ACCOUNT_2_HEADER: 'Digitar o seu código',
  CREATE_ACCOUNT_2_HEADING: 'Digite o seu código de verificação',
  CREATE_ACCOUNT_2_MSG: (telNumber) => `Por favor digite o seu código de verificação enviado para ${telNumber}..`,
  CREATE_ACCOUNT_2_LINK_BACK: 'Eu não recebi um código',
  CREATE_ACCOUNT_2_MSG_2: (termsofserviceurl) =>
    `Ao inserir o código de verificação, você está a concordar com os nossos <a href="${termsofserviceurl}" target="_new">termos de serviço</a>.`,

  ERROR_TEL_NUM_INVALID: 'Por favor, coloque um número de telefone válido',
  ERROR_ACT_CODE_EMPTY: 'Por favor, insira o código de ativação que lhe foi enviado',
  ERROR_ACT_CODE_INVALID: 'Por favor, insira um código de ativação válido',
  ERROR_ACT_CODE_INVALID: 'O código de ativação está incorreto. Por favor, tente novamente',

  CREATE_ACCOUNT_VERIFYING: 'A verificar o código de verificação',
  CREATE_ACCOUNT_VERIFYING_PHONE: 'A verificar o seu número de telefone',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_1: 'Nenhuma senha é guardada',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_2: 'Só precisas de te lembrar do teu rabisco',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_3: 'Você pode usar forghetti em todos os dispositivos',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_4: 'Faça upgrade para poder partilhar senhas em segurança dentro dos grupos',

  CREATE_ACCOUNT_REFERRAL_HEADER: 'Código de referência',
  CREATE_ACCOUNT_REFERRAL_HEADING: 'Insira um código de referência',
  CREATE_ACCOUNT_REFERRAL_PLACEHOLDER: 'Código de referência',
  REFERRAL_ENTRY_MESSAGE_1: `Você recebeu um código de referência de um amigo? Em caso afirmativo, insira-o aqui. Caso contrário, você pode saltar esta etapa.`,

  CREATE_ACCOUNT_3_HEADER: 'Editar Perfil',
  CREATE_ACCOUNT_3_HEADING: 'Edite o seu perfil',
  CREATE_ACCOUNT_3_TEXT: `Você pode definir um nome e um ícone para o seu perfil aqui. Isso ajudará a identificá-lo se você decidir partilhar o acesso ou for convidado a juntar-se a outro grupo de utilizadores.`,
  CREATE_ACCOUNT_3_LABEL: 'Nome',
  CREATE_ACCOUNT_3_TEXT_2: `Se desejar, você pode saltar esta etapa e deixar a configuração do nome e imagem para mais tarde.`,

  CREATE_ACCOUNT_ADD_LOGIN_MSG: `Selecione um serviço para atualizar a partir destes aplicativos populares`,

  CREATE_ACCOUNT_FINAL_HEADER: 'Terminado!',
  CREATE_ACCOUNT_FINAL_MSG: `Você está pronto para forghetti!`,
  CREATE_ACCOUNT_FINAL_MSG_2: `Você está no caminho certo para proteger a sua conta e esquecer o stress das senhas para sempre!`,
  CREATE_ACCOUNT_FINAL_MSG_3: `Agora, nós vamos explicar-lhe as etapas necessárias para transformar uma senha existente numa senha forghetti.`,
  CREATE_ACCOUNT_FINAL_BUTTON: 'Mostre-me como!',
  CREATE_ACCOUNT_FINAL_LINK: `Eu já sei como lidar com forghetti`,

  CREATE_ACCOUNT_ADD_CUSTOM_LOGIN: `Ou outra coisa...`,
  CREATE_ACCOUNT_SERVICE_NAME_TOOLTIP: `Para adicionar um serviço diferente, você precisará de pelo menos fornecer o nome de um serviço. Os campos de \n\ne-mail / nome de utilizador e endereço de internet são opcionais, mas sugerimos que os adicione também!`,
  CREATE_ACCOUNT_CUSTOM_LOGIN_ADD_PASSWORD_TOOLTIP: `Você precisará de adicionar pelo menos um tipo de senha para ser gerada. \n\nExistem algumas diferentes opções para escolher, pois alguns serviços exigem requisitos de senha específicos.`,
  CREATE_ACCOUNT_CUSTOM_LOGIN_NEXTPAGE_TOOLTIP: `Quando estiver totalmente satisfeito, clique em ‘Avançar’ para continuar.`,
  CREATE_ACCOUNT_NEW_LOGIN_DOODLEPAD_MSG: `O seu rabisco será usado sempre que você quiser recuperar a sua senha no futuro, por isso faça valer a pena!`,
  CREATE_ACCOUNT_ADD_ANOTHER_LOGIN_TOOLTIP: `Para adicionar outro serviço à sua conta, clique aqui para abrir novamente a nova janela de login.`,

  ADD_SERVICE_SEARCH_HEADER: `Adicionar novo serviço`,
  ADD_SERVICE_SEARCH: `Selecione um serviço destes aplicativos populares ou comece a digitar o nome`,
  ADD_SERVICE_SUGGESTED: `Serviços sugeridos`,
  ADD_SERVICE_CREATE: (name) => `Criar "${name}" "${name}"`,
  ADD_SERVICE_SELECT_ICON_HEADER: `Escolha um ícone`,
  ADD_SERVICE_SELECT_ICON: (serviceName) => `Selecione um ícone para ${serviceName}`,
  ADD_SERVICE_SELECT_GROUP_HEADER: `Selecione o grupo`,

  ADD_SERVICE_YOUR_GROUP: (serviceName, groupName) =>
    `<em>${serviceName}</em> <em>${serviceName}</em> será adicionado a <em>${groupName}</em> <em>${groupName}</em>`,
  ADD_SERVICE_SELECT_GROUP: (serviceName) => `Selecione um grupo para adicionar <br /><em>${serviceName}</em>`,
  ADD_SERVICE_SELECT_GROUP_SELECT: (serviceName) =>
    `Ou selecione um grupo diferente para adicionar <br /><em>${serviceName}</em>`,
  MOVE_SERVICE_SELECT_GROUP: (serviceName) => `Selecione um grupo para mover <br /><em>${serviceName}</em>`,
  MOVE_SERVICE_LOADING: 'A mover o seu serviço',
  MOVE_SERVICE_CONFIRM_HEADER: 'A mover o seu serviço',
  MOVE_SERVICE_CONFIRM_NOTE: (
    service,
    fromGroup,
    toGroup,
    numFields,
  ) => `<em>${service}</em> será movido do seu <em>${fromGroup}</em> grupo para outro <em>${toGroup}</em> grupo.
Este serviço tem <em>${numFields}</em> forghettible${numFields > 1 ? 's' : ''}.`,
  MOVE_SERVICE_CONFIRM_NOTE_UPDATE: (fromGroup, toGroup, list, listLength) =>
    `Um novo <em>${list}</em> forghettible${
      listLength > 1 ? 's' : ''
    } será gerado a partir do seu rabisco <em>${toGroup}</em>. Se você desejar aceder ao seu antigo forghettible${
      listLength > 1 ? 's' : ''
    }, desenho simplesmente o seu rabisco <em>${fromGroup}</em>.`,
  MOVE_SERVICE_CONFIRM: 'Confirma mover',
  ADD_SERVICE_SELECT_GROUP_ONBOARD: (serviceName) => `Adicione o seu serviço <em>${serviceName}</em> a um grupo`,
  ADD_SERVICE_USERNAME: (serviceName) =>
    `Digite o nome de utilizador para <em>${serviceName}</em> ou selecione da lista abaixo.`,
  ADD_SERVICE_USERNAME_ONBOARD: (serviceName) => `Digite o seu <em>${serviceName}</em> e-mail ou nome de utilizador`,
  ADD_SERVICE_USERNAME_HEADER: `Selecione o nome de utilizador`,
  ADD_SERVICE_USERNAME_PLACEHOLDER: `Email ou nome de utilizador`,
  ADD_SERVICE_SEARCH_FOR_SERVICE: `Insira um serviço que você utiliza aqui`,
  ADD_SERVICE_SEARCH_FOR_ICONS: 'Pesquisar ícones',
  ADD_SERVICE_SUGGESTED_USERNAMES: `Nomes de utilizador sugeridos`,
  ADD_SERVICE_DRAW_DOODLE: (groupName) => `Desenhe um rabisco para o seu grupo <em>${groupName}</em>`,
  ADD_SERVICE_USERNAME_BLANK: `Você deve inserir um nome de utilizador ou endereço de e-mail`,
  ADD_SERVICE_USERNAME_TOO_LONG: 'Seu nome de utilizador deve ter menos de 64 caracteres',

  ADD_SERVICE_WEBSITE_HEADER: 'Entrar no site',
  ADD_SERVICE_WEBSITE: (serviceName) =>
    `Insira o URL do site para <em>${serviceName}</em>. Você pode saltar esta etapa e inserir o URL do site mais tarde.`,
  ADD_SERVICE_WEBSITE_PLACEHOLDER: `URL do site`,

  ADD_SERVICE_SEARCH_ONBOARD_LETS_START: `Vamos começar por selecionar um destes serviços populares ou ...`,
  ADD_SERVICE_SEARCH_ONBOARD_NOW_ITS_YOUR_TURN: `AGORA É SUA VEZ`,
  ADD_SERVICE_DOODLE_DO_IT_AGAIN: `VAMOS FAZER DE NOVO`,
  ADD_SERVICE_DOODLE_ONBOARD_NOW_ITS_YOUR_TURN: `AGORA É SUA VEZ`,
  ADD_SERVICE_DOODLE_CREATE_DOODLE: 'Crie o seu rabisco de assinatura',
  ADD_SERVICE_DOODLE_CONFIRM_DOODLE: `Vamos verificar se você consegue se memorizar... desenhe o seu rabisco <em>mais uma vez</em>`,
  ADD_SERVICE_GENERATING_FORGHETTI_ONBOARD: (serviceName) =>
    `Parabéns, forghetti está a gerar uma nova senha para <em>${serviceName}</em>`,
  VIEW_SERVICE_GENERATING_FORGHETTI: (serviceName) => `A gerar a sua senha forghetti para <em>${serviceName}</em>`,
  VIEW_SERVICE_RESET_PASSWORD: `Redefina a sua senha agora`,
  VIEW_SERVICE_PASSWORD_HELP: (serviceName) =>
    `Nós geramos uma senha super forte para <em>${serviceName}</em>. Agora é a altura de redefinir a sua senha antiga.`,
  VIEW_SERVICE_PASSWORD_HELP_MOVE: (serviceName, groupName) =>
    `Nós mudamos <em>${serviceName}</em> para <em>${groupName}</em>. Se o seu rabisco para este serviço mudou, você precisará de atualizar sua senha..`,
  VIEW_SERVICE_OLD_PREFIX: 'Antiga',
  VIEW_SERVICE_NEW_PREFIX: 'Nova',
  HIDE_QUICK_SELECT: `Não me mostre isto novamente`,
  HIDE_QUICK_SELECT_CONFIRM_TITLE: `Ocultar esta janela?`,
  HIDE_QUICK_SELECT_CONFIRM: `Isto removerá esta página de ser exibida no futuro, e adicionar um novo serviço o levará diretamente para a página “adicionar outro serviço”. \n\nIsso pode ser revertido nas configurações em "preferências do utilizador" a qualquer momento.`,
  ADD_SERVICE_DODODLES_DO_NOT_MATCH: `Os seus rabiscos não combinam. Tente novamente.`,
  THEME_COLOUR: 'Cor',
  THEME_COLOURS: ['Azul impecável', 'Verde fresco', 'Vermelho sem medo', 'Laranja alegre', 'Amarelo amigável'],
  THEME_MODES: ['Dia', 'Noite'],
  THEME_BLUE: 'Azul Impecável',

  DELETE_ACCOUNT_CONTINUE: 'Continuar',
  DELETE_ACCOUNT_HEADER: 'Apagar conta',
  DELETE_ACCOUNT_HEADING: 'Apagar a minha conta',
  DELETE_ACCOUNT_LINE_1: 'Apagar a sua conta irá:',
  DELETE_ACCOUNT_LINE_2: '• Apagar a sua conta de forghetti',
  DELETE_ACCOUNT_LINE_3: '• Apagar toda a informação que forghetti mantém no seu perfil',
  DELETE_ACCOUNT_LINE_4: '• Apagar você de todos os grupos forghetti',
  DELETE_ACCOUNT_LINE_5: '• Remover o acesso à sua conta a partir de todos os dispositivos ligados',
  DELETE_ACCOUNT_CHANGE_INSTEAD: 'Quer mudar de telefone em vez disso?',

  DELETE_ACCOUNT_CONFIRM_MESSAGE: (telNumber) =>
    'Enviamos um código de confirmação para o seu número de telefone (' +
    telNumber +
    '). ' +
    'Por favor, digite esse código para confirmar que deseja apagar a sua conta:',
  DELETE_ACCOUNT_CONFIRM_BUTTON: 'confirme',

  DEVICES_HEADER: 'Gestão de Dispositivos',
  DEVICES_LAST_ACTIVE: 'Última actividade: ',
  DEVICES_FORGHETTI_APP: ' - aplicação forghetti',
  DEVICES_LOG_OUT_ALL: 'Saia de todos os dispositivos',

  SERVICES_PERSONAL: 'Pessoal',
  SERVICES_ADD_LOGIN: 'Adicionar login',
  SERVICES_RECENT: 'Recente', // Not currently used

  SEARCH_INTERNET: 'Pesquise uma imagem na Internet',

  SERVICE_HEADER_NEW: 'Adicionar Login',
  SERVICE_NAME_PLACEHOLDER: 'Dê um nome ao seu login',

  SERVICE_DOODLEPAD_SUBHEADING: 'Rabisque no pad',
  DOODLEPAD_SECRET: 'ocultar',
  DOODLEPAD_SHOW: 'mostrar',
  DOODLEPAD_REDOODLE: 'apagar',
  ERROR_NO_DOODLE: 'Por favor, desenhe o seu rabisco',

  SERVICE_EDIT_FIELD_HEADER: 'Definições',

  FIELD_TYPES: {
    lns: 'AbC123_*.',
    wns: 'Palavras123_ *.',
    lnx: 'AbC123 ~?%',
    wnx: 'Palavras123 ~?%',
    ln: 'AbC123',
    wn: 'Palavras123',
    l: 'Abc',
    w: 'Palavras',
    n: '123',
  },

  FIELD_TEMPLATE_PIN: 'PIN',
  FIELD_TEMPLATE_COMPLEX_PASSWORD: 'Senha complexa',
  FIELD_TEMPLATE_PASSWORD: 'Senha',
  FIELD_TEMPLATE_MEMWORD: 'Palavra memorável',
  FIELD_TEMPLATE_FORGHETTIBLE: 'Apenas letras',

  SERVICE_USERNAME_LABEL: 'Email ou nome de utilizador',
  SERVICE_WEBSITE_LABEL: 'Endereço de internet',

  SERVICE_EDIT_HEADER: 'Detalhes',
  SERVICE_EDIT_USERNAME_PLACEHOLDER: 'Digite o método de login',
  SERVICE_EDIT_WEBSITE_PLACEHOLDER: 'www.webaddress.com',

  SERVICE_ADD_FORGHETTIBLE: 'Adicionar um forghettible',
  SERVICE_DFT_FORGHETTIBLE_NAME: 'Senha',

  SERVICE_NAME_LENGTH_INVALID: 'O nome do serviço deve ter entre 2 e 32 caracteres',
  SERVICE_USERNAME_LENGTH_INVALID: 'O e-mail / nome de utilizador de serviço deve ter menos de 64 caracteres',
  SERVICE_ADDRESS_LENGTH_INVALID: 'O endereço de internet do serviço deve ter menos de 128 caracteres',
  SERVICE_NO_FORGHETTIBLES: 'Você deve adicionar pelo menos um forghettible. Uma senha, por exemplo.',

  SERVICE_CHOOSE_ICON: 'Escolha o ícone',

  SERVICE_DOODLEPAD_HEADING: 'Rabisque no pad',
  SERVICE_DOODLEPAD_RIGHT_NAV: 'Detalhes',

  SERVICE_ADDING: 'A adicionar o serviço',

  SERVICE_CONNECTING: 'A ligar',

  FIELD_DETAILS_HEADER: 'Definições',
  FIELD_NAME: 'Nome',
  FIELD_NAME_PLACEHOLDER: 'Dê um nome ao seu forghettible',
  FIELD_TYPE: 'Tipo',
  FIELD_LENGTH: 'comprimento',
  FIELD_LENGTH_PICKER_CUSTOM: 'personalizadas',

  FIELD_RIGHT_NAV: 'Está bem',

  FIELD_OPTIONS_HEADING: 'Opções',

  ADVANCED_OPTIONS: 'Opções avançadas',
  RESET_FIELD: 'Redefinir forghettible',
  DELETE_FIELD: 'Apagar',
  RESTORE_FIELD: 'Restaurar forghettible',

  FIELD_ADVANCED_OPTIONS_HEADER: 'Opções avançadas',

  RESTORE_FIELD_PAGE_HEADER: 'Restaurar',
  RESTORE_FIELD_SUBHEADING: 'Precisa de uma senha antiga?',
  RESTORE_FIELD_DESCRIPTION:
    'Você pode voltar a uma versão anterior aqui. Basta selecionar a versão que deseja restaurar.',
  RESTORE_FIELD_VERSION_HISTORY: 'Histórico da versão',
  RESTORE_FIELD_CURRENT: 'Atual',

  SERVICE_VIEW_HEADER: 'Resumo',
  SERVICE_VIEW_RIGHT_NAV: 'Completo',

  SERVICE_SUMMARY_MESSAGE: `Os seus forghettibles foram gerados. Por favor, selecione abaixo para copiar, visualizar ou aceder a este login.`,
  SERVICE_SUMMARY_FIRST_LOGIN_MSG1: `Parabéns! Você acabou de gerar a sua primeira senha forghetti!`,
  SERVICE_SUMMARY_FIRST_LOGIN_MSG2: `Agora você precisará substituir a sua senha existente por esta nova para que ela tenha efeito.`,
  SERVICE_SUMMARY_FIRST_LOGIN_PASSWORD_TOOLTIP: `Quando você estiver pronto pode copiar a sua nova senha para a área de transferência clicando neste ícone.`,

  SERVICE_DETAILS_HEADER: 'Detalhes',
  SERVICE_DETAILS_RIGHT_NAV: 'Editar',
  SERVICE_DETAILS_DELETE: 'Apagar Login',

  PROFILE_HEADER: 'Perfil',
  PROFILE_WIZARD_COMPLETE: 'Conclua a sua configuração de forghetti.',
  PROFILE_WIZARD_SEE_DETAILS: 'Veja detalhes > >',
  PROFILE_PREMIUM_BANNER_HEADING: 'Experimente Premium',
  PROFILE_PREMIUM_BANNER_DETAIL: 'Desbloqueie recursos e seja mais seguro online.',
  PROFILE_THEME_MODE_LABEL: 'Modo',
  PROFILE_THEME_COLOUR_LABEL: 'Cor',
  PROFILE_BADGES: 'Seus emblemas',
  PROFILE_LINK_ACCOUNT: 'Sua conta',
  PROFILE_LINK_PREMIUM: 'Premium',
  PROFILE_ENABLE_AUTOFILL: 'Ativar preenchimento automático',
  PROFILE_LINK_USER_PREFERENCES: 'Preferências da aplicação',
  PROFILE_YOUR_SUBSCRIPTION: 'Sua assinatura',
  PROFILE_LINK_INVITE: 'Partilhe forghetti com um amigo',
  /*PROFILE_LINK_INVITE_SECONDARY: 'Partilhe com amigos e ganhe logins grátis',*/
  PROFILE_REFERRAL_CODE: 'Código de Referencia',
  PROFILE_REFERRAL_CODE_SECONDARY: '',
  PROFILE_LINK_DEVICES: 'Gestão de dispositivo',
  PROFILE_LINK_HELP: 'Obter ajuda',
  PROFILE_LINK_FEEDBACK: 'Envie-nos os seus comentários',
  PROFILE_LINK_ABOUT: 'Sobre',
  PROFILE_LINK_LOGOUT: 'Sair',

  PROFILE_ACCOUNT_HEADER: 'Sua conta',
  PROFILE_ACCOUNT_LINK_SECURITY: 'Segurança',
  PROFILE_ACCOUNT_LINK_MIGRATE: 'Alterar número',
  PROFILE_ACCOUNT_LINK_DELETE: 'Apagar a minha conta',
  PROFILE_ACCOUNT_LINK_SUBSCRIPTION: 'Sua assinatura',

  PROFILE_REONBOARDING: 'Aprenda como funciona o forghetti',
  PROFILE_GET_ONLINE_HELP: 'Obtenha ajuda online',
  PROFILE_GET_VIDEO_HELP: 'Obtenha ajuda online de vídeo',
  PROFILE_HELP_HEADER: 'Ajuda',

  PROFILE_EDIT_NAME_HEADER: 'Detalhes de perfil',
  PROFILE_EDIT_NAME_LABEL: 'Seu nome',
  PROFILE_EDIT_TEL_LABEL: 'Número de telefone',

  PROFILE_NAME_BLANK: 'O nome do perfil não pode ficar por preencher',
  PROFILE_NAME_TOO_LONG: 'O nome do perfil não pode ter mais de 32 caracteres',

  PROFILE_NAME_INVALID: 'O nome do perfil pode apenas conter letras, números e símbolos',

  PROFILE_MIGRATE_1_HEADER: 'Alterar número',
  PROFILE_MIGRATE_1_HEADING: 'Número de telefone atual',
  PROFILE_MIGRATE_1_INFO: 'Insira o seu novo código de país e número de telefone:',
  PROFILE_MIGRATE_1_PLACEHOLDER: 'Número de telemóvel',
  PROFILE_MIGRATE_1_BUTTON: 'Enviar código de verificação',

  PROFILE_MIGRATE_2_HEADER: 'Coloque o código',
  PROFILE_MIGRATE_2_HEADING: 'Número de telefone atual',
  PROFILE_MIGRATE_2_HEADING2: 'Novo Número de Telefone',
  PROFILE_MIGRATE_2_INFO: 'Agora digite o código de verificação que você recebeu no seu novo telefone:',
  PROFILE_MIGRATE_2_BUTTON: 'confirme',

  PROFILE_MIGRATE_3_HEADER: 'Tudo terminado',
  PROFILE_MIGRATE_3_MSG: 'Seu número de telefone foi alterado',
  PROFILE_MIGRATE_3_HEADING: 'Novo Número de Telefone',

  PROFILE_ABOUT_HEADER: 'Sobre',
  PROFILE_ABOUT_PRIVACY: 'Política de Privacidade',
  PROFILE_ABOUT_COOKIE: 'Política de Cookies',
  PROFILE_ABOUT_TERMS: 'Termos de serviço',
  PROFILE_ABOUT_ACCEPTABLE: 'Política de Utilização Aceitável',
  PROFILE_ABOUT_VERSION_HEADING: 'Versão',

  PROFILE_REFERRAL_CODE_HEADING: 'Código de Referencia',
  PROFILE_REFERRAL_CODE_LABEL: 'Código de Referencia',
  PROFILE_REFERRAL_CODE_PLACEHOLDER: 'Digite o seu código de referência',
  PROFILE_EDIT_REFERRAL_CODE_HEADING: 'Editar Código de Referência',
  PROFILE_REFERRAL_CODE_TEXT: 'Se você recebeu um código de referência, insira-o abaixo.',

  PROFILE_PREMIUM_TYPE: {
    premium_trial: 'Utilizador de teste premium',
    premium_life: 'Utilizador vitalício',
    free: 'Utilizador grátis',
    premium: 'Assinante premium',
    premium_paid: 'Assinante premium',
  },
  APP_PREFERENCES_HEADER: 'Preferências da aplicação',
  APP_PREFERENCES_THEME: 'Tema',
  APP_PREFERENCES_QUICK_SELECT: 'Mostrar dicas',
  APP_PREFERENCES_SHOW_QUICK_SELECT: 'Mostrar rápido janela de adição de login',
  APP_PREFERENCES_SECRET_DOODLE: 'Rabisco secreto por defeito',
  APP_PREFERENCES_SHOW_TOOLTIPS: 'Mostrar dicas de ferramentas',
  APP_PREFERENCES_REQUIRE_TOUCH_ID: 'Exigir biometria para abrir a aplicação',
  APP_PREFERENCES_NOTIFICATIONS: 'Notificações',
  APP_PREFERENCES_COUNTRY: 'Língua',
  APP_PREFERENCES_TOUCH_ID_TOAST: `Por favor, certifique-se de que o ID biométrico esteja registrado no seu dispositivo`,

  PREMIUM_HEADER: 'Forghetti Premium',

  YOUR_SUBSCRIPTION_HEADER: 'Sua assinatura',
  YOUR_SUBSCRIPTION_SUBHEADING: 'Teste Premium',

  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_TEXT_1: (date) =>
    `Você está atualmente a utilizar uma versão Premium gratuita. Esta versão expirará em ${date} Nessa altura, você pode continuar como utilizador grátis (mas perderá todos os seus benefícios premium) ou comprar uma assinatura premium no aplicativo.`,
  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_TEXT_2: (date) => `Esperamos que você esteja a gostar de usar forghetti.`,

  YOUR_SUBSCRIPTION_FREE_TEXT_1: (date) =>
    `Você é atualmente um utilizador gratuito. Inscreva-se hoje para beneficiar de logins ilimitados, da capacidade de criar grupos ilimitados que você pode partilhar entre amigos, familiares e colegas de trabalho e de obter relatórios detalhados de verificação de saúde.`,
  YOUR_SUBSCRIPTION_FREE_TEXT_2: (date) => `Esperamos que você esteja a gostar de utilizar forghetti.`,
  YOUR_SUBSCRIPTION_FREE_TEXT_3:
    'Se você já tiver uma assinatura da app store, clique em restaurar assinatura para reaplicar.',
  YOUR_SUBSCRIPTION_FREE_DOWNLOAD: `Não tem o aplicação? Descarrege agora a partir da sua app store`,

  YOUR_SUBSCRIPTION_PREMIUM_TEXT_1: (date) =>
    `Atualmente você é um utilizador Premium. Sua assinatura expira em ${date}. Quando você comprar uma assinatura, ela é configurada para ser renovada automaticamente. Se você desejar, pode desligar essa opção na sua conta da App Store.`,
  YOUR_SUBSCRIPTION_PREMIUM_TEXT_2: (date) => `Esperamos que você esteja gostando de usar forghetti.`,

  YOUR_SUBSCRIPTION_PREMIUM_NO_DATE_TEXT_1: `Atualmente você é um utilizador Premium. Quando você comprar uma assinatura, ela é configurada para ser renovada automaticamente. Se você desejar, pode desligar essa opção na sua conta da App Store.`,

  YOUR_SUBSCRIPTION_PREMIUM_LIFE_TEXT_1: (date) =>
    `A sua assinatura premium life nunca se expirará. Você pode continuar usando forghetti para sempre!`,
  YOUR_SUBSCRIPTION_PREMIUM_LIFE_TEXT_2: (date) => `Esperamos que você esteja a gostar de usar forghetti.`,
  YOUR_SUBSCRIPTION_FREE_TEXT_APP_3:
    'Se você já tiver uma assinatura da app store, clique em restaurar assinatura para reaplicar.',

  YOUR_SUBSCRIPTION_FREE_TEXT_APP_1: `Você é atualmente um utilizador gratuito. Inscreva-se hoje para beneficiar de logins ilimitados, da capacidade de criar grupos ilimitados que você pode partilhar entre amigos, familiares e colegas de trabalho e de obter relatórios detalhados de verificação de saúde.`,

  YOUR_SUBSCRIPTION_FREE_TEXT_APP_2: 'Digitalize o código QR com seu telefone para assinar o serviço Premium.',
  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_BUTTON_LABEL: 'Ver preços',
  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_CORDOVA_BUTTON_LABEL: 'Ver inscrições',

  YOUR_SUBSCRIPTION_FREE_BUTTON_LABEL: 'Atualizar',

  PREMIUM_NO_PRODUCT_SELCTED: 'Seleção inválida de produto ',
  PREMIUM_PURCHASE_BUTTON: 'Comprar',
  PREMIUM_PURCHASE_BUTTON_NA: 'Em breve',
  COMING_SOON: 'Em breve',
  PREMIUM_PRICE_NONE: 'N / A',
  PREMIUM_PURCHASE_BUTTON_OWNED: 'Subscrição atual',
  PREMIUM_PRUCHASE_REFRESH_PURCAHSE: 'Atualize a sua compra',
  PREMIUM_MONTHLY_HEADER: 'Premium todos os meses',
  PREMIUM_MONTHLY_LIST: [
    {
      description: 'Grupos ilimitados',
    },
    {
      description: 'Logins ilimitados',
    },
    {
      description: 'Suporte prioritário',
    },
  ],
  PREMIUM_ANNUAL_ALIAS: 'Plano anual',
  PREMIUM_MONTHLY_ALIAS: 'Plano mensal',
  PREMIUM_ONE_OFF_ALIAS: 'Compra única',
  PREMIUM_LOADING_OWNED: 'Parabéns! Você é agora um utilizador premium!',
  PREMIUM_LOADING_RELOAD: 'Em breve, o forghetti recarregará a sua nova assinatura.',
  PREMIUM_LOADING_APPROVED: 'Aguarde enquanto confirmamos a sua assinatura na app store!',
  PREMIUM_LOADING_REGISTERED: 'Aguarde enquanto carregamos as assinaturas disponíveis ...',
  PREMIUM_MANAGE_SUBSCRIPTIONS: 'Gestão da assinatura',
  PREMIUM_IMPOSSIBLE_TO_VERIFY: 'Não foi possível verificar a sua compra',
  PREMIUM_RESTORE_PURCHASE: 'Restaurar assinatura',
  PREMIUM_YEARLY_HEADER: 'Premium para todo o ano',
  PREMIUM_YEARLY_LIST: [
    {
      description: 'Grupos ilimitados',
    },
    {
      description: 'Logins ilimitados',
    },
    {
      description: 'Suporte prioritário',
    },
  ],
  PREMIUM_LIFETIME_HEADER: 'Premium para sempre',
  PREMIUM_LIFETIME_LIST: [
    {
      description: 'Grupos ilimitados',
    },
    {
      description: 'Logins ilimitados',
    },
    {
      description: 'Suporte prioritário',
    },
  ],
  ENABLE_ANDROID_AUTOFILL_HEADING: 'Permitir Preenchimento Automático',
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_1: `A Forghetti usa o serviço de acessibilidade do Android para a função de preenchimento automático, que ajuda a buscar senhas quando solicitado em qualquer aplicativo no seu dispositivo.`,
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_2:
    'Garantimos que a Forghetti não lê ou acessa nenhum outro conteúdo da tela. Identificamos apenas quando um campo de senha aparece na tela para preenchimento automático.',
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_3: `Para usar este serviço, é necessário o consentimento do usuário. Ao clicar no botão 'Concordo', você nos dá permissão para usar o Serviço de Acessibilidade para Ofill Auto -Bill. Se você optar por negar o consentimento, o recurso de preenchimento automático será desativado, mas você poderá continuar usando o Forghetti sem essa funcionalidade.`,
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_4: `Observe que navegar nessa tela não é considerado como consentimento e não usamos mensagens de divisão automática para obter consentimento.`,
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_5: `Para obter mais informações sobre como nosso uso da API de acessibilidade é implementado e protegido, bem como nosso manuseio de dados do usuário, revise nossa política de privacidade.`,

  ENABLE_ANDROID_AUTOFILL_BUTTON: 'Concordar',
  ENABLE_ANDROID_AUTOFILL_BUTTON_NO: 'Agora não',
  MAKE_ADMIN_MESSAGE: 'Gostaria de tornar este utilizador num administrador deste grupo?',
  MAKE_OWNER_MESSAGE: 'Gostaria de transferir administração deste grupo para este utilizador?',

  REMOVE_ADMIN_MESSAGE: 'Gostaria de remover o estatuto de administrador deste utilizador?',
  DELETE_GROUP_CONFIRM: 'Tem a certeza de que deseja apagar este grupo?',
  LEAVE_GROUP_CONFIRM: 'Gostaria de sair deste grupo?',
  GROUP_DELETED_MESSAGE: 'O grupo foi apagado.',
  REMOVE_MEMBER_MESSAGE: 'Gostaria de remover esta pessoa do grupo?',
  REMOVE_MEMBER_SUCCESS: 'Um membro do grupo foi removido',
  GROUP_SHARED_BY: 'Grupos partilhados por',
  ERROR_INVALID_NUM_WORDS: 'O número de palavras deve ser entre 1 e 10',
  ERROR_INVALID_NUM_LETTERS: 'O número de caracteres deve ser entre 1 e 64',

  UPGRADE_REQUIRED: 'Requer atualização',
  ADD_LOGIN_NOT_ALLOWED: `Como utilizador gratuito, você só tem permissão para adicionar logins no seu grupo pessoal. Atualize hoje para criar grupos e partilhar logins entre amigos, familiares e colegas de trabalho!`,
  UPGRADE_TODAY_MESSAGE: `Como utilizador gratuito, você pode juntar-se a um grupo.
Atualize hoje para criar grupos e partilhar logins entre amigos, familiares e colegas de trabalho!`,

  UPGRADE_TODAY_BUTTON: 'Atualize hoje',
  UPGRADE_TODAY_PAGE_HEADING: 'Atualize hoje',
  UPGRADE_TODAY_MSG_1: 'Torne-se um utilizador premium da forghetti',
  UPGRADE_TODAY_PRICE: '£ 24,99 por ano',
  UPGRADE_TODAY_MSG_2: 'Disponha de todas as funcionalidades que a Forghetti oferece',
  UPGRADE_TODAY_MSG_3:
    'A assinatura premium está disponível como uma compra na aplicação em qualquer uma de nossas aplicações móveis:',

  UPGRADE_TODAY_MSG_4:
    'Uma assinatura anual permite que você se torne um Utilizador Premium com a capacidade de criar logins ilimitados, senhas forghettible e grupos.',

  INVITE_FRIEND_HEADER: 'Obtenha serviços gratuitos',
  INVITE_FRIEND_REFERRAL_LABEL: 'Link de referência',
  INVITE_FRIEND_REFERRAL_COPY: 'Copiar link de referência para a área de transferência',
  INVITE_FRIEND_REFERRAL_EMAIL: 'email de Link de referência ',
  INVITE_FRIEND_PREAMBLE: 'Partilhe forghetti com um amigo',
  INVITE_FRIEND_TEXT: `Basta partilhar o seu link pessoal.`,
  INVITE_FRIEND_BENEFIT:
    'Referencie um amigo e obtenha mais 5 serviços adicionados ao seu grupo pessoal quando ele se inscrever. Faça com que 5 amigos se inscrevam e nós lhe daremos 15 serviços extras.',

  GROUPS_LIST_HEADER: 'Os seus grupos',
  GROUPS_LIST_INFO_MESSAGE: 'Gerir os seus grupos ou alternar entre grupos',
  GROUPS_LIST_PERSONAL_HEADING: 'Os seu Grupo Pessoal',
  GROUPS_LIST_OTHER_GROUPS_HEADING: 'Os seus Outros Grupos',
  GROUPS_LIST_ADD_NEW_GROUP: 'Adicionar um novo grupo',
  GROUPS_LIST_CURRENTLY_ACTIVE: 'ATUALMENTE ATIVO',
  GROUPS_NEW_HEADER: 'Novo grupo',
  GROUPS_NEW_RIGHT_NAV: 'Criar',
  GROUPS_NEW_NAME_PLACEHOLDER: 'Insira um título',

  GROUPS_ADD_GROUP_HEADER: 'Adicionar grupo',
  GROUPS_JOIN_GROUP: 'Juntar-se ao grupo',
  GROUPS_JOIN_GROUP_TEXT: 'Junte-se a um grupo usando um link de convite',
  GROUPS_CREATE_GROUP: 'Criar grupo',
  GROUPS_CREATE_GROUP_TEXT: 'Crie um novo grupo para partilhar com outras pessoas',
  GROUPS_FREE_JOIN_WARNING: 'Como utilizador gratuito, você pode juntar-se a um grupo.',
  GROUPS_ADD_UPGRADE:
    'Atualize hoje para criar grupos e partilhar logins entre amigos, familiares e colegas de trabalho.',
  GROUP_NEW_INVITE_GENERATING: 'A gerar o seu convite de grupo',
  LEAVE_GROUP_MESSAGE: 'Você foi removido do grupo.',
  GROUP_DETAIL_LOADING: `A recuperar detalhes do grupo`,
  GRP_SHARING_HEADING: 'A partilhar',
  GRP_MEMBERS_HEADING: 'Membros',
  GRP_SETTINGS_HEADING: 'Configurações do grupo',

  GROUP_VIEW_HEADER: 'Detalhes do Grupo',
  GROUP_VIEW_RIGHT_NAV: 'Editar',
  GROUP_VIEW_USER_ADMIN: 'Administrador ',
  GROUP_VIEW_USER_OWNER: 'Proprietário',
  GROUP_VIEW_USER_YOU: 'Você',
  GROUP_VIEW_USER_READ_ONLY: 'Só de leitura',
  GROUP_VIEW_SWITCH_TO: 'Mudar para este grupo',
  GROUP_VIEW_LEAVE: 'Sair deste grupo',
  GROUP_VIEW_DELETE: 'Apagar grupo',
  GROUP_VIEW_PREMIUM: 'Utilizador premium',
  GROUP_VIEW_FREE: 'Utilizador grátis',
  GROUP_DETAIL_SAVE_LOADING: `Atualize os detalhes do seu grupo`,
  GROUP_EDIT_HEADER: 'Detalhes do Grupo',
  GROUP_EDIT_MAX_MEMBERS: 'Número máximo de membros',
  GROUP_EDIT_MAX_MEMBERS_SECONDARY: 'Incluindo você',
  PERSONAL_GROUP_MESSAGE: `Este é o seu grupo pessoal. Este grupo nunca é partilhado com mais ninguém. Para alterar o ícone deste grupo, vá ao seu Perfil e altere a imagem do seu perfil.`,

  GROUP_CHOOSE_ICON: 'Escolha o ícone',

  GROUP_NAME_BLANK: 'O nome do grupo não pode ficar vazio',
  GROUP_NAME_TOO_LONG: 'O nome do grupo não pode ter mais de 32 caracteres',

  GRP_MAX_MEMBERS_ERROR: 'O número máximo de membros deve ser um número entre 1 e 50',

  JOIN_GROUP_PLACEHOLDER: 'Insira o link do seu convite',
  JOIN_GROUP_HEADING: 'Juntar-se ao grupo',
  GRP_INVITE_CODE_LABEL: 'Grupo do link do seu convite ',
  GRP_INVITE_CODE_NOTE: (group) => `Convide membros para <em>${group}</em> partilhando este link`,
  JOIN_GROUP_RIGHT_NAV: 'Junte-se',

  REFERRAL_COPY_MESSAGE: (url) =>
    `Convido-te a usar forghetti. Descarregue a aplicação e esqueça as senhas para sempre. Vá para ${url}`,

  CREATE_GROUP_INVITE_TEXT_1: `Envie este código para sua família, amigos ou membros da equipe para dar-lhes acesso a este grupo.

Para se juntar a um grupo, clique em 'Adicionar' E depois em 'Juntar ao Grupo' e cole o link de convite.`,

  INVITE_NEW_MEMBER: 'Convide um novo membro',
  GRP_INVITE_HELP_TEXT: `Todos os membros do grupo podem criar novos logins, mas apenas os administradores podem apaga-los. Os administradores também podem remover membros, no entanto, se você criar o grupo, nunca poderá ser removido por outro administrador.`,

  // Template for text that is copied to clipboard when you choose to copy a group invite code to clipboard
  GRP_INVITE_CLIPBOARD_MESSAGE: (code) => `${code}`,

  INVITE_LINK_HEADING: 'Convite',
  INVITE_SETTINGS_HEADING: 'Definições',
  LINK_EXPIRY_LABEL: 'O link expira após',
  MAX_USES_LABEL: 'Número máximo de utilizações',

  LINK_EXPIRY_VALUE: (mins) => LINK_EXPIRY_VALUES[String(mins)],
  LINK_MAX_USES_VALUE: (num) => LINK_MAX_USE_VALUES[String(num)],
  INVITE_NO_LIMIT: LINK_MAX_USE_VALUES['-1'],
  INVITE_EXPIRY_NEVER: LINK_EXPIRY_VALUES['-1'],

  DEACTIVATE_GRP_LINK_CONFIRM: 'Tem certeza de que deseja apagar este convite?',
  DEACTIVATE_GRP_LINK_SUCCESS: 'O convite do grupo foi apagado com sucesso.',

  GRP_INVITE_CREATE_NAV: 'Criar',

  // Share Group Invite page
  SHARE_INVITE_HEADING: 'Convidar membros',
  GRP_INVITE_CODE_LABEL: 'Link de convite',
  CPY_GRP_INVITE: 'Copiar convite para área de transferência',
  DEACTIVATE_LINK: 'Apagar o convite',
  SHARE_INVITE_VIA_EMAIL: 'Partilhar o convite por e-mail',
  COPY_INVITE_SUCCESS: `Convite de grupo copiado para a área de transferência.`,

  ONBOARDING_HEADER_1: 'Crie um login para um site favorito',
  ONBOARDING_HEADER_2: 'Desenhe seu o seu rabisco',
  ONBOARDING_HEADER_3: 'A sua senha foi gerada',
  ONBOARDING_HEADER_4: 'Copie e prossiga!',
  ONBOARDING_HEADER_5: 'Já está!',
  ONBOARDING_WELCOME_HEADING: `Olá, o meu nome é Locky <br> Bem-vindo a forghetti`,
  ONBOARDING_WELCOME_TEXT:
    'Ao utilizar o forghetti, você pode fazer login em todos os serviços que usa, com um rabisco seguro.',
  ONBOARDING_DOODLE_HEADING: 'Você vai precisar de um rabisco de assinatura',
  ONBOARDING_DOODLE_TEXT:
    'De linhas a pontos, rabiscos aleatórios ou sequências de números. Sua combinação única gera uma senha super forte.',
  ONBOARDING_HOW_IT_WORKS: 'Como funciona',
  ONBOARDING_ADDSERVICE_HEADING: 'Ótimo para qualquer serviço',
  ONBOARDING_ADDSERVICE_TEXT:
    'Sempre que você adiciona um serviço, gera uma senha única e ultra segura. Você pode até gerar números PIN e palavras memoráveis ​​também.',
  ONBOARDING_RESETPASSWORD_HEADING: 'Redefina e troque sua senha antiga',
  ONBOARDING_RESETPASSWORD_TEXT:
    'Abra o site e redefina a sua senha antiga, substituindo-a pela nova senha forghetti ultra segura.',
  ONBOARDING_SECURESHARING_HEADING: 'Partilha segura de senha',
  ONBOARDING_SECURESHARING_TEXT:
    'Use grupos para partilhar o acesso aos seus serviços favoritos de forma segura com amigos, familiares e colegas de trabalho.',
  ONBOARDING_UNLIKE_OTHERS_HEADING: 'Ao contrário de outros gestores de senha ...',
  ONBOARDING_UNLIKE_OTHERS_TEXT:
    'Não salvamos um banco de dados de senhas. Nós os geramos quando você precisa deles. Eles são calculados usando o seu rabisco como a chave. Super seguro e conhecido apenas por você.',
  ONBOARDING_DOODLETUTORIAL1_HEADING: 'A criar o seu rabisco de assinatura! Como fazer isso?',
  ONBOARDING_SHOWMEHOW: 'Deixa-me mostrar-te como se faz!',
  ONBOARDING_DOODLETUTORIAL2_HEADING: 'Em primeiro lugar, você pode desenhar um padrão ...',
  ONBOARDING_DOODLETUTORIAL: 'Tutorial de rabisco seguro',
  ONBOARDING_DOODLETUTORIAL3_HEADING: 'ou uma série de linhas',
  ONBOARDING_DOODLETUTORIAL4_HEADING: 'até mesmo pontos estão bem ...',
  ONBOARDING_DOODLETUTORIAL5_HEADING: 'Ou uma combinação deles todos juntos',
  ONBOARDING_DOODLETUTORIAL6_HEADING: 'Mas lembre-se de que a ordem é importante,',
  ONBOARDING_DOODLETUTORIAL7_HEADING: 'Desenhar o rabisco A',
  ONBOARDING_DOODLETUTORIAL8_HEADING: 'não é o mesmo que desenhar o rabisco B',
  ONBOARDING_YOURTURN: `Agora é sua vez`,

  ONBOARDING_COMPLETE_WELCOME: 'Bem vindo ao forghetti',
  ONBOARDING_COMPLETE_CONGRATULATIONS:
    'Parabéns! Você acabou de dar os primeiros passos para proteger a sua identidade online',
  ONBOARDING_COMPLETE_COMPLETE_SETUP: 'Conclua aa sua configuração de forghetti',
  ONBOARDING_COMPLETE_CREATE_FORGHETTI: 'Crie uma conta forghetti',
  ONBOARDING_COMPLETE_SIGNATURE_DOODLE: 'Crie o seu rabisco de assinatura',
  ONBOARDING_COMPLETE_FIRST_LOGIN: 'Adicione o seu primeiro serviço',
  ONBOARDING_COMPLETE_RESET_PASSWORD: 'Gere uma senha segura',
  ONBOARDING_COMPLETE_IMPORT: 'Serviços de importação',
  ONBOARDING_COMPLETE_HEALTHCHECK: 'Execute uma verificação de saúde nas suas contas online',
  ONBOARDING_COMPLETE_IMPORT_BUTTON: 'Serviços de importação',
  ONBOARDING_COMPLETE_REMIND_LATER: 'Lembre-me mais tarde',
  ONBOARDING_COMPLETE_CONTINUE: `Vamos`,
  ONBOARDING_COMPLETE_RESET_PASSWORD: 'Redefinir uma senha insegura',
  ONBOARDING_COMPLETE_HEALTHCHECK_BUTTON: 'Execute uma verificação de saúde',
  ONBOARDING_COMPLETE_NEXT_STEPS: `<h2>Agora, por que não:</h2><ul><li>Faça uma verificação de saúde nas suas contas online?</li><li>Ou atualize outro serviço no forghetti.</li><li>Ou dê uma olhadela ...</li></ul>`,

  ONBOARDING_EXISTING_TITLE: 'Novas ferramentas',
  ONBOARDING_EXISTING_WELCOME_HEADING: 'Muito obrigado',
  ONBOARDING_EXISTING_WELCOME_TEXT: `A equipe da forghetti tem estado muito ocupada. Fizemos muitas melhorias nos bastidores e antecipadamente. Dê uma olhadela em algumas das novas ferramentas.`,
  ONBOARDING_EXISTING_MOVE_SERVICES_HEADING: 'Serviços de mudanças',
  ONBOARDING_EXISTING_MOVE_SERVICES_TEXT:
    'Forghetti agora permite mover serviços de um grupo para outro. Basta clicar nos três pontinhos de cada serviço e selecionar mover.',
  ONBOARDING_EXISTING_HEALTHCHECK_HEADING: 'Verificação de saúde',
  ONBOARDING_EXISTING_HEALTHCHECK_TEXT:
    'O forghetti agora inclui a verificação e saúde, uma ferramenta que permite monitorizar ativamente os seus nomes de utilizador e senhas contra violações de dados conhecidas.',
  ONBOARIDNG_EXISTING_SHARING_HEADING: 'Partilha rápida de grupo',
  ONBOARDING_EXISTING_SHARING_TEXT:
    'Partilhe os seus grupos forghetti com seus amigos, familiares e colegas de trabalho com apenas um clique.',
  ONBOARDING_EXISTING_SWITCHING_HEADING: 'Troca rápida de grupo',
  ONBOARDING_EXISTING_SWTICHING_TEXT:
    'Trabalhar com os seus grupos ficou mais fácil do que nunca. Adicionar novos serviços a um grupo é mais rápido e fácil do que nunca.',
  ONBOARDING_EXISTING_PRICING_HEADING: 'Atualizações de preços',
  ONBOARDING_EXISTING_PRICING_TEXT:
    'Forghetti agora está a oferecer mais planos de assinatura para atender às suas necessidades.',

  ACCOUNT_SECURITY_HEADER: 'Segurança',
  ACCOUNT_SECURITY_HEADING: 'Nada é mais seguro',
  ACCOUNT_SECURITY_SECONDARY_HEADING:
    'Tratamos a segurança dos dados como uma prioridade. Forghetti permite gerar logins para vários serviços usando um único rabisco. A parte inteligente de tudo isso é que nos bancos de dados de Forghetti não há senhas armazenadas…. nenhuma. Ao contrário de todos os outros aplicativos de gestão de senhas, e existem muitos, não armazenamos as suas senhas.  ',
  ACCOUNT_SECURITY_LEARN_MORE: 'Saiba mais sobre a segurança forghetti',

  MSG_FALLBACK_ERROR_MESSAGE: `Ocorreu um erro! Desculpe.`,

  MSG_GROUP_ICON_UPDATED: 'Ícone atualizado',
  MSG_COPIED_FORGHETTIBLE: (name) => `${name} copiado para a área de transferência`,
  MSG_OPEN_FORGHETTIBLE: `Clique aqui para abrir`,
  MSG_DELETE_LOGIN_CONFIRM: 'Tem a certeza de que deseja apagar este login?',
  MSG_LOGIN_DELETED: (name) => `Login ${name} apagado`,
  MSG_SERVICE_ICON_DELETED: 'Ícone de serviço atualizado',
  MSG_LOGOUT_CONFIRM: 'Tem a certeza de que deseja sair?',
  MSG_LOGOUT_ALL_DEVICES_CONFIRM: 'Você tem certeza?',
  MSG_USER_PROMOTED_ADMIN: 'O utilizador foi promovido a administrador.',
  MSG_USER_PROMOTED_OWNER: 'Administração foi transferida',

  MSG_CONFIRM_RESET: 'Por favor, confirme que deseja redefinir esta senha',
  MSG_CONFIRM_RESTORE: 'Por favor, confirme que deseja restaurar esta versão anterior.',
  MSG_NEW_GROUP_CREATED: (name) => `Novo grupo foi criado: ${name}`,
  MSG_OPENING_WEBSITE: 'A abrir o site ...',
  MSG_REMOVED_ADMIN: 'O estatudo de administrador foi cancelado a este utilizador',
  MSG_UNSAVED_CONFIRM: 'Existem alterações não gravadas. Tem certeza de que deseja retroceder?',

  EXTENSION_NONE_FOUND: 'Não foram encontrados forghettibles para este site',
  EXTENSION_GO_TO_FORGHETTI: 'Vá para a aplicação Forghetti',
  EXTENSION_NOACCESS_HEADER: 'Atualize hoje',
  EXTENSION_UPGRADE: `Atualmente você é um utilizador gratuito. Atualize hoje para utilizar a extensão forghetti Chrome e beneficiar de logins ilimitados e da capacidade de criar grupos e partilhar logins entre amigos, familiares e colegas de trabalho. Se quiser subscrever a assinatura Premium, selecione o botão atualizar abaixo.`,
  EXTENSION_NOLOGINFOUND_HEADER: `Não encontrado em forghetti`,
  EXTENSION_NOLOGINFOUND_MSG:
    'Nenhum forghettible foi encontrado para este site. Desenhe um doodle abaixo para criar rapidamente um novo forghettible para este site',

  CONTEXT_EDIT_LOGIN_LIST_ITEM: 'Editar',
  CONTEXT_MOVE_LOGIN_LIST_ITEM: 'Mover',
  CONTEXT_ADD_LOGIN_LIST_ITEM: 'Adicionar forghettible',
  CONTEXT_RESET_LOGIN_LIST_ITEM: 'Redefinir forghettible',
  CONTEXT_DELETE_LOGIN_LIST_ITEM: 'Apagar',
  CONTEXT_UPLOAD_GROUP_PHOTO: 'Tirar fotos',
  CONTEXT_UPLOAD_GROUP_IMAGE: 'Carregar Imagem',
  CONTEXT_CHOOSE_GROUP_IMAGE: 'Escolher imagem',
  CONTEXT_TAKE_PROFILE_PIC: 'Tirar foto',
  CONTEXT_UPLOAD_PROFILE_PIC: 'Carregar foto',
  CONTEXT_UPLOAD_SERVICE_IMAGE: 'Carregar Imagem',
  CONTEXT_CHOOSE_SERVICE_IMAGE: 'Escolher imagem',
  CONTEXT_SERVICE_IMAGE_WEBSEARCH: 'Pesquisar na internet',
  CONTEXT_MAKE_ADMIN: 'Tornar Administrador',
  CONTEXT_MAKE_OWNER: 'Faça o proprietário',

  CONTEXT_REMOVE_FROM_GROUP: 'Remover do grupo',
  CONTEXT_REVOKE_ADMIN: 'Anular Administrador',
  CONTEXT_NEW_GROUP_MEMBERS: 'Novo grupo máximo de membros',
  CONTEXT_SELECT_FIELD_TYPE: 'Selecione o tipo',
  CONTEXT_FIELD_TYPE_LNS: 'Letras, números e símbolos',
  CONTEXT_FIELD_TYPE_WNS: 'Palavras, números e símbolos',
  CONTEXT_FIELD_TYPE_LNX: 'Letras, números e símbolos complexos',
  CONTEXT_FIELD_TYPE_WNX: 'Palavras, números e símbolos complexos',
  CONTEXT_FIELD_TYPE_LN: 'Letras e números',
  CONTEXT_FIELD_TYPE_WN: 'Palavras e números',
  CONTEXT_FIELD_TYPE_L: 'Apenas letras',
  CONTEXT_FIELD_TYPE_W: 'Apenas palavras',
  CONTEXT_FIELD_TYPE_N: 'Apenas números',
  CONTEXT_ADD_FIELD:
    'Selecione um campo predefinido abaixo. Se necessário, cocê pode personalizar as configurações de campo depois.',
  CONTEXT_ADD_FIELD_PASS: 'Senha (letras, números e símbolos)',
  CONTEXT_ADD_FIELD_COMPLEX_PASS: 'Senha avançada (letras, números e símbolos complexos)',

  CONTEXT_ADD_FIELD_PIN: 'Pin (apenas números)',
  CONTEXT_ADD_FIELD_MEMWRD: 'Palavra memorável (apenas palavras)',
  CONTEXT_ADD_FIELD_FORGHETTIBLE: 'Apenas letras',
  CONTEXT_LINK_EXPIRE_15MINS: '15 minutos',
  CONTEXT_LINK_EXPIRE_30MINS: '30 minutos',
  CONTEXT_LINK_EXPIRE_1HOUR: '1 hora',
  CONTEXT_LINK_EXPIRE_6HOURS: '6 horas',
  CONTEXT_LINK_EXPIRE_12HOURS: '12 horas',
  CONTEXT_LINK_EXPIRE_1DAY: '1 dia',
  CONTEXT_LINK_EXPIRE_NEVER: 'Nunca',
  CONTEXT_LINK_EXPIRE_CANCEL: 'Cancelar',
  CONTEXT_LINK_USES_1: 'Uma vez',
  CONTEXT_LINK_USES_2: 'Duas vezes',
  CONTEXT_LINK_USES_5: '5 vezes',
  CONTEXT_LINK_USES_10: '10 vezes',
  CONTEXT_LINK_USES_20: '20 vezes',
  CONTEXT_LINK_USES_50: '50 vezes',
  CONTEXT_LINK_USES_NO_LIMIT: 'Sem limite',
  CONTEXT_LINK_USES_CANCEL: 'Cancelar',

  FINGERPRINT_TITLE: 'Bem vindo ao forghetti',
  FINGERPRINT_DESCRIPTION: 'Digitalize a sua impressão digital para desbloquear forghetti',
  FINGERPRINT_DESCRIPTION_FACE: 'Use Face ID para desbloquear forghetti',

  GENERATING_GROUP: 'A gerar o seu grupo',
  GENERATING_PASSWORD: 'A gerar a sua senha',
  DELETING_GROUP: 'A apagar o grupo',
  LOADING_SERVICE: 'A carregar o seu serviço',
  UNABLE_TO_PURCHASE: 'Neste momento não é possível comprar assinatura premium. Por favor, tente novamente em breve.',

  HEALTHCHECK_WELCOME_HEADER: 'Verificação de saúde',
  HEALTHCHECK_WELCOME_NOTE:
    'Execute a verificação de saúde do Forghetti e descubra se seus dados online foram expostos a uma violação de dados.',
  HEALTHCHECK_WELCOME_BUTTON: 'Execute uma verificação de saúde',
  HEALTHCHECK_USERNAMES_HEADER: `Vamos começar com o seu e-mail`,
  HEALTHCHECK_USERNAMES_INFO_1: 'Verifique o seu endereço de e-mail',
  HEALTHCHECK_USERNAMES_INFO_2:
    'De-nos o seu endereço de e-mail e nós o verificaremos em relação a quaisquer novos ou antigos hacks online.',
  HEALTHCHECK_USERNAMES_PRIMARY_SUBHEAD: 'O seu endereço de e-mail principal',
  HEALTHCHECK_USERNAMES_SECONDARY_SUBHEAD: 'o seu endereço de e-mail secundário',
  HEALTHCHECK_USERNAMES_PLACEHOLDER: 'Endereço de e-mail',
  HEALTHCHECK_PASSWORD_HEADER: `Vamos verificar a sua senha`,
  HEALTHCHECK_PASSWORD_INFO_1: 'Usa a mesma senha com frequência?',
  HEALTHCHECK_PASSWORD_INFO_2: `Antes de usar forghetti, você usava a mesma senha com frequência?
Vamos ver se ele foi exposto a uma violação de dados.`,
  HEALTHCHECK_PASSWORD_INFO_3:
    'Esta verificação é opcional. Forghetti não armazena ou transmite a sua senha. Iremos gerar imediatamente uma codificação segura da sua senha. Apenas parte do codigo da sua senha é transmitida pelo aplicação, portanto, você pode ter certeza de que sua senha está segura connosco.',

  HEALTHCHECK_PASSWORD_SUBHEAD: 'A sua senha',
  HEALTHCHECK_PASSWORD_OPTIONAL: 'Isto é opcional.',
  HEALTHCHECK_PASSWORD_PLACEHOLDER: 'Senha',
  HEALTHCHECK_PASSWORD_VAL_TOO_LONG: 'Campo de senha muito longo',

  HEALTHCHECK_RUNNING: 'A executar a seu verificação de saúde',
  HEALTHCHECK_USERNAME_VAL_FIELD_BLANK: 'O endereço de email não pode ficar vazio',
  HEALTHCHECK_USERNAME_VAL_FIELD_DUPLICATE: 'O endereço de e-mail secundário não pode ser igual ao primeiro',
  HEALTHCHECK_USERNAME_VAL_FIELD_TOO_SHORT: 'Endereço de email muito curto',
  HEALTHCHECK_USERNAME_VAL_FIELD_TOO_LONG: 'Endereço de email muito longo',

  HEALTHCHECK_ASSESSMENT_HEADER: 'Verificação de saúde',
  HEALTHCHECK_ASSESSMENT_EMAIL: (email) => `<em>Violações para  ${email}</em>`,
  HEALTHCHECK_ASSESSMENT_INFO_1: 'Os resultados do seu verificação de saúde são exibidos abaixo.',
  HEALTHCHECK_ASSESSMENT_INFO_2: 'Os utilizadores premium podem clicar no erro para obter mais informações.',

  HEALTHCHECK_ASSESSMENT_PASSWORD: '<em><em>Resultados para a sua senha</em></em>',
  HEALTHCHECK_ASSESSMENT_COMPROMISED_HEAD: `Serviços inseguros`,
  HEALTHCHECK_ASSESSMENT_NO_BREACH: 'Nenhum serviço violado com esta conta',
  HEALTHCHECK_ASSESSMENT_PASSWORD_STRENGTH: 'Força',
  HEALTHCHECK_ASSESSMENT_PASSWORD_WEAK: 'Fraco',
  HEALTHCHECK_ASSESSMENT_PASSWORD_STRONG: 'Forte',
  HEALTHCHECK_ASSESSMENT_PASSWORD_COMP: 'Aparece em uma violação de dados',
  HEALTHCHECK_ASSESSMENT_HIBP_LINK: 'fonte: <a data-actionclick="openHIBP">haveIbeenpwned</a>',
  HEALTHCHECK_ASSESMENT_UPGRADE_MESSAGE:
    'Os detalhes da violação de dados estão disponíveis apenas para utilizadores premium',
  HEALTHCHECK_DETAILS_PASSWORD_HEADER: 'Resultados da senha',
  HEALTHCHECK_DETAILS_USER_HEADER: `Detalhes de violação`,
  HEALTHCHECK_DETAILS_USER_TITLE: 'Nome',
  HEALTHCHECK_DETAILS_USER_DOMAIN: 'Domínio',
  HEALTHCHECK_DETAILS_USER_DATE: 'Data de violação',
  HEALTHCHECK_DETAILS_USER_ACCOUNTS_PWNED: 'Contas violadas',
  HEALTHCHECK_DETAILS_PASSWORD_INFO_X_BREACH: (breach) =>
    `A sua senha foi listada em <em>${breach}</em> violações de dados conhecidas. Isso não significa necessariamente que as suas contas estão inseguras. Você deve considerar gerar uma senha super segura com <em>forghetti</em> o mais rápidamente possível.`,
  HEALTHCHECK_DETAILS_PASSWORD_INFO_NO_BREACH: `Boas notícias! A sua senha não está listada em nenhuma violação de dados conhecida. Isso não garante que suas contas não estejam inseguras. Considere gerar uma nova senha super segura com <em>forghetti</em>.`,
  HEALTHCHECK_DETAILS_PASSWORD_STRENGTH_INFO_STRONG: `A sua senha é <strong>segura</strong>. Isso significa que ele passou em todos os critérios definidos pelo teste de segurança da senha forghetti`,
  HEALTHCHECK_DETAILS_PASSWORD_STRENGTH_INFO_WEAK: `A sua senha é <strong>fraca</strong>. Isso significa que ele falha nos seguintes critérios definidos pelo teste de segurança da senha forghetti:`,
  HEALTHCHECK_DETAILS_RESET_NOTE: 'Gere agora uma senha super segura com forghetti ',
  HEALTHCHECK_DETAILS_RESET_BUTTON: 'Criar um novo serviço',
  HEALTHCHECK_DETAILS_HIBP_LINK: 'fonte: haveIbeenpwned',
  HEALTHCHECK_LOADING_DETAIL: 'A carregar detalhes de violação de dados',

  IMPORT_SERVICE_WELCOME_BUTTON: 'Começar a importar serviços',
  IMPORT_SERVICE_WELCOME_INFO: 'Importe os seus dados de login existentes para forghetti',
  IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_1:
    'Para importar serviços, por favor abra a aplicação forghetti na área de trabalho.',
  IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_2:
    'Se você não tiver a aplicação forghetti na área de trabalho, clique no link abaixo',
  IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_BUTTON: 'Descarregar forghetti',
  IMPORT_SERVICES_LIST_HEADER: 'Selecione os serviços para importar',
  IMPORT_SERVICES_LIST_ERROR_NONE_SELECTED: 'Nenhum serviço importado está selecionado',
  IMPORT_SERVICES_YOUR_IMPORTED_SERVICES: 'Os seus serviços importados',
  IMPORT_SERVICES_LIST_NOTE: `Escolha os serviços que deseja importar para a forghetti.`,
  IMPORT_SERVICES_GROUP_NOTE: 'Selecione um grupo para o qual deseja mover os seus serviços',
  IMPORT_SERVICES_GROUP_HEADER: 'Selecionar grupo',
  IMPORT_SERVICES_USERNAME_HEADER: 'Digite os nomes de utilizador',
  IMPORT_SERVICES_USERNAME_NOTE: 'Verifique os nomes de utilizador associados a cada conta',
  IMPORT_SERVICES_CREATE_HEADER: 'Criar serviços',
  IMPORT_SERVICES_CREATE_BUTTON: 'Comece a criar serviços',
  IMPORT_SERVICES_CREATE_HOW_MANY: (number) => `<em>${number}</em> serviços para criar`,
  IMPORT_SERVICES_CREATE_LOADING: (current, total) => `Importado <em>${current}</em> de <em> ${total}</em> serviços`,
  IMPORT_SERVICES_COMPLETE_HEADER: 'Importação completa',
  IMPORT_SERVICES_COMPLETE_NOTE: `Parabéns! Os seus serviços foram importados`,
  IMPORT_FOLDER_SELECT_HEADER: 'Importar do Chrome',
  IMPORT_FOLDER_SELECT_CHROME: 'Importar serviços do Chrome',
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC: `forghetti precisará aceder aos seus dados de login armazenados no seu computador.`,
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_2: `Quando estiver pronto, clique no botão 'Selecione a sua pasta do Chrome' abaixo. Uma caixa de diálogo aparecerá no local de seus dados de login do Chrome. Basta selecionar 'Abrir' para dar acesso a esta pasta ao forghetti.`,
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_3:
    'Se você deseja localizar esta pasta manualmente, vá para Biblioteca / Suporte de aplicação / Google / Chrome',
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_4:
    'Lembre-se de que as suas senhas existentes não são descriptografadas por forghetti. forghetti apenas recupera os seus dados de login.',
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_5:
    'Assim que os seus dados de login sejam lidos, você poderá selecionar quais serviços deseja importar para o forghetti.',

  IMPORT_FOLDER_SELECT_CHROME_BUTTON: 'Selecione a sua pasta do Chrome',

  IMPORT_GROUP_HEADER: 'O seu grupo de serviço importado',
  IMPORT_GROUP_NOTE: (importingServices) =>
    `Um grupo especial de <em>serviços importados</em> está a ser criado automaticamente para você. O grupo conterá os serviços que você <em>${importingServices}</em> selecionou para importar.`,
  IMPORT_GROUP_NOTE_2:
    'Os serviços neste grupo não estão disponiveis. Para disponibilizar os serviços, você precisará movê-los para outro grupo. Clique em continuar que o levará para o assistente de importação de serviços. O assistente de serviços de importação tornará o processo de movimentação dos seus serviços de importação rápido e fácil.',
  IMPORT_GROUP_NOTE_3:
    'Se você não tem tempo para mover todos os seus serviços importados agora, não se preocupe. Você pode mover os seus serviços importados por meio do assistente de importação de serviços a qualquer momento e em qualquer dispositivo.',
  IMPORT_GROUP_BUTTON: 'Continuar',

  IMPORT_SERVICE_IMPORT_NODATA_HEADER: 'Incapaz de encontrar dados de login',
  IMPORT_SERVICE_IMPORT_NODATA_NOTE:
    'forghetti não encontrou nenhum dado de login que pudesse ser usado para importação',

  IMPORT_MOVE_SERVICE_HEADER: 'Comece a mover os seus serviços',
  IMPORT_MOVE_SERVICE_NOTE_1: (services) =>
    `Você tem ${services} no seu grupo de serviços importados. Para começar a ativar os seus serviços importados, você precisa movê-los para outro grupo.`,
  IMPORT_MOVE_SERVICE_BUTTON: 'Continuar',
  IMPORT_SERVICE_LOADING: 'Importando os seus serviços',
  IMPORT_SERVICE_GROUP_NAME: 'Serviços de importação',
  NEW_GROUP_REQUIRES_PREMIUM: 'A criação de um novo grupo requer assinatura premium.',

  IMPORT_TYPE_HEADER: 'Selecione o tipo de importação',
  IMPORT_TYPE_NOTE_1: `Forghetti pode importar serviços automaticamente dos seus dados de login do Google Chrome. Se você usa o Google Chrome e deseja importar serviços usando este método, clique no botão 'Importar Google Chrome' abaixo.`,
  IMPORT_TYPE_BUTTON_1: `Importar Google Chrome`,
  IMPORT_TYPE_NOTE_2: `Como alternativa, você pode importar os seus logins fazendo upload de um documento CSV ou Excel (XLSX). Para começar a importar serviços usando este recurso, clique no botão 'Carregar documento' abaixo.`,
  IMPORT_TYPE_BUTTON_2: 'Carregar documento',
  IMPORT_TYPE_NOTE_3: `Se você usar o safari, talvez queira usar a funcionalidade de carregar documentos para importar os seus serviços. Para encontrar as suas senhas do safari keychain, abra 'Preferências' (command + ',') no navegador Safari e navegue até ao guia de 'Senhas',`,

  IMPORT_UPLOAD_HEADER: 'Carregar documento',
  IMPORT_UPLOAD_NOTE_1:
    'Carregue um CSV ou um excel (XLSX) para começar a importar serviços. No mínimo, tudo o que você precisa é o nome de um serviço.',
  IMPORT_UPLOAD_BUTTON: 'Carregue o seu documento',
  IMPORT_UPLOAD_NOTE_2: 'Descarregue o exemplo CSV ou um Excel para começar.',
  IMPORT_UPLOAD_SAMPLE_CSV: 'Descarregue o exemplo CSV',
  IMPORT_UPLOAD_SAMPLE_XLS: 'Descarregue o exemplo excel',

  // ===========================================================================
  // Texto de ajuda
  // ===========================================================================

  //NO_LOGINS_MESSAGE: `Para começar, toque em 'Adicionar login' no canto superior direito para adicionar um login de um serviço que você use (por exemplo, Amazon, Facebook, Barclays).

  NO_LOGINS_MESSAGE: `Adicione serviços a este grupo clicando no ícone + (mais)

Partilhe este grupo com amigos, colegas ou familiares clicando no ícone de partilha (canto superior direito)

Você pode alterar o nome deste grupo e gerir quem tem acesso nas configurações do grupo

`,

  LOGIN_RECENT: 'Recente',
  HELP_LOGIN_TITLE:
    'Insira um título para o seu login (por exemplo, Amazon). Adicione um logótipo tocando no ícone da câmara. Se você não selecionar um ícone, um padrão será criado para você.',
  HELP_LOGIN_USERNAME:
    'Digite o nome de utilizador que você usa para o seu login. Este pode ser o seu endereço de e-mail, por exemplo. Se o fizer, isso permitirá que você copie e cole fácilmente da janela de resumo de login.',
  HELP_LOGIN_WEBSITE:
    'Opcionalmente, insira o endereço do site do seu login. Se o fizer, isso permitirá que você abra o site diretamente da janela de resumo de login.',
  HELP_LOGIN_ADD_FORGHETTIBLE: `Adicione aqui uma senha padrão (a senha por defeito é complexa e longa, por isso você não precisa editá-la), palavra memorável ou PIN. Se você quiser mais controle, adicione um Forghettible personalizado.

Você deve adicionar pelo menos um forghettible para continuar. Em seguida, basta tocar em Avançar no canto superior direito da tela.`,

  HELP_GROUPS_LIST_HEADER: `Toque no sinal + para criar o juntar-se a um grupo. Os assinantes gratuitos só podem se juntar a um grupo de assinantes pagos.

Você pode usar grupos para gerenciar diferentes categorias de login ou para partilhar um grupo de logins.

Lembre-se de que você precisará partilhar o rabisco do grupo com todos os membros para que eles possam gerar as mesmas senhas que você. Portanto, considere usar uma palavra, frase ou código memorável que seja simples de lembrar.
`,

  HELP_GROUPS_LIST_PERSONAL: `Este é o seu único grupo pessoal. Você não pode apaga-lo ou partilhá-lo com ninguém.`,
  HELP_GROUPS_LIST_OTHER_GROUPS: `Estes são quaisquer outros grupos que você criou como assinante Premium ou se juntou ao ser convidado por outra pessoa`,

  HELP_DOODLEPAD: `Desenhe um rabisco de que você se lembrará. Linhas conectadas, pontos ou uma combinação de ambos. Quanto mais camadas, mais seguro. Você precisa de se lembrar disso, pois esta é a chave para ser capaz de gerar, agora e para sempre, as suas senhas.

Preocupado com olhos curiosos? Toque em 'rabisco secreto' e desenhe sem deixar vestígios. Cometa um erro e você pode começar de novo tocando em 'Re-Rabiscar'. Em seguida, pressione para gerar sua senha.`,

  HELP_SERVICE_SUMMARY: `Toque no olho para ver a senha. Toque na sua senha para copiá-la para a área de transferência. Aceda o site do serviço e substitua a sua senha atual pela nova.

Agora você pode-se esquecer. Da próxima vez que você precisar, apenas desenhe o rabisco e forghetti irá gerá-lo novamente. Não fica guardado em nenhum lugar, mas é gerado quando você precisa com o seu rabisco.
`,

  HELP_FIELD_SETTINGS_LENGTH: `Escolha o comprimento do forghettible como o número de caracteres - usado para uma senha ou PIN padrão - ou o número de palavras, se estiver usando palavras memoráveis.`,
  HELP_FIELD_SETTINGS_TYPE: `Escolha o tipo de forghettible como uma combinação de letras, símbolos, números e palavras.`,
  HELP_FIELD_SETTINGS_NAME: `Insira um nome descritivo para a sua senha forghettible. Observe que esta não é a senha em si, pois ela é gerada automaticamente por forghetti.`,
  HELP_FIELD_SETTINGS_RESET: `Se você precisar alterar uma senha gerada por forghetti a qualquer momento, basta clicar em redefinir e da próxima vez que desenhar o seu rabisco a senha gerada terá mudado.`,
  HELP_RESTORE_PAGE: `As configurações para versões antigas de senhas forghetti podem ser restauradas clicando na data.

Se você fizer isso, quando desenhar o seu rabisco, a sua senha antiga será gerada.

Você pode alternar como desejar!
`,
  HELP_LOGINS_LIST: `Clique num login para gerar a sua senha.

Use a ferramenta de pesquisa para encontrar o login de que você precisa.
`,
  HELP_GROUP_PAGE: ` Desça até à parte inferior para apagar o grupo, para alternar para ver os logins para este grupo ou para sair de um grupo do qual você não deseja mais ser membro.`,
  HELP_GROUP_SHARING: `Crie um convite para partilhar com um amigo ou colega.`,
  HELP_GROUP_MEMBERS: `Gestão das permissões dos utilizadores do grupo, torne-os utilizadores administradores ou remova-os completamente.`,
  HELP_GROUP_INVITE_MAX_USES: `Controle por quanto tempo e quantas vezes um convite pode ser usado antes de enviá-lo a amigos e colegas.`,

  // ===========================================================================
  // Emails
  // ===========================================================================

  REFERRAL_EMAIL_SUBJECT: 'Um convite para usar esta aplicação porque nada é mais seguro que usar forghetti',
  REFERRAL_EMAIL_BODY: (url) =>
    `Olá,

Estou a usar uma ótimo aplicação chamada forghetti. Ajudou-me a ser capaz de esquecer as minhas senhas para sempre. As suas senhas não são armazenadas em lado nenhum.

Você gera as suas senhas quando precisa, criando um rabisco. Desenhe o mesmo rabisco e você obterá sempre a mesma senha. Tão simples, e tão inteligente.

Use este link de referência para se inscrever e obter recursos ilimitados para você e para mim!
${url}

Diverta-se!`,

  // ===========================================================================

  GRP_INVITE_EMAIL_SUBJECT: 'convite de grupo forghetti',
  GRP_INVITE_EMAIL_BODY: (inviteUrl, referralUrl, referralCode) =>
    `Olá, gostaria de partilhar o meu grupo forghetti com você. Clique no link a seguir para abrir o Forghetti e entrar no meu grupo:
${inviteUrl}

Se você não tem forghetti, adicione este código de referência ${referralCode} no seu perfil do aplicativo e ganhe mais tempo premium para você e para mim! Esqueça as suas senhas para sempre!`,

  APPRATE_TITLE: 'Você se importaria de classificar a avaliação do forghetti',
  APPRATE_MESSAGE: `Não vai demorar mais de um minuto e ajuda a promover a nossa aplicação. Obrigado pelo seu apoio`,
  APPRATE_CANCEL: 'Não, obrigado',
  APPRATE_LATER: 'Lembre-me mais tarde',
  APPRATE_RATE: 'Avaliar forghetti',
  APPRATE_YES: 'sim',
  APPRATE_NO: 'Na verdade não',
  APPRATE_PROMPT_TITLE: 'Você gosta de usar forghetti',
  APPRATE_FEEDBACK: 'Importa-se de nos dar o seu comentário?',

  BADGE_HEALTHCHECK_HEADER: 'Execute uma verificação de saúde',
  BADGE_HEALTHCHECK_INFO: 'Ganhe este emblema preenchendo o nome de utilizador e senha da verificão de saúde forghetti',
  BADGE_HEALTHCHECK_COMPLETE_INFO: 'Parabéns! Você ganhou este emblema ao concluir a verificação de saúde forghetti',
  BADGE_HEALTHCHECK_REWARD: (premium) => (premium ? 'Obtenha 2 serviços extras para o seu grupo pessoal' : ''),
  BADGE_HEALTHCHECK_CTA: 'Execute uma verificação de saúde agora',

  BADGE_RATING_HEADER: 'Avaliar forghetti',
  BADGE_RATING_INFO: 'Ganhe este emblema ao classificar forghetti',
  BADGE_RATING_COMPLETE_INFO: 'Parabéns! Você ganhou este emblema ao classificar forghetti',
  BADGE_RATING_REWARD: (premium) => (premium ? 'Obtenha 2 serviços extras para o seu grupo pessoal' : ''),
  BADGE_RATING_CTA: 'Por favor, avalie forghetti agora',

  BADGE_PROFILECOMPLETE_HEADER: 'Complete o seu perfil',
  BADGE_PROFILECOMPLETE_INFO: 'Ganhe este emblema ao preencher o seu perfil forghetti.',
  BADGE_PROFILECOMPLETE__COMPLETE_INFO: 'Parabéns! Você ganhou este emblema ao completar o seu perfil forghetti.',
  BADGE_PROFILECOMPLETE_REWARD: (premium) => (premium ? 'Obtenha 2 serviços extras para o seu grupo pessoal' : ''),
  BADGE_PROFILECOMPLETE_CTA: 'Complete o seu perfil agora',

  BADGE_SHARING_HEADER: 'Partilhe forghetti no facebook',
  BADGE_SHARING_INFO: 'Ganhe este emblema gostando e partilhando forghetti na sua rede social',
  BADGE_SHARING_COMPLETE_INFO: 'Parabéns! Você ganhou este emblema por gostar e partilhar forghetti na sua rede social',
  BADGE_SHARING_REWARD: (premium) => (premium ? 'Obtenha 2 serviços extras para o seu grupo pessoal' : ''),
  BADGE_SHARING_CTA: 'Partilhe forghetti no Facebook',

  BADGE_IMPORT_HEADER: 'Serviços de importação',
  BADGE_IMPORT_INFO: 'Ganhe este emblema ao importar serviços do seu telefone ou do keychain do seu PC',
  BADGE_IMPORT_COMPLETE_INFO:
    'Parabéns! Você ganhou este emblema quando importou serviços do seu telefone ou do keychain do seu PC',
  BADGE_IMPORT_REWARD: (premium) => (premium ? 'Obtenha 2 serviços extras para o seu grupo pessoal' : ''),
  BADGE_IMPORT_CTA: 'Comece a importar serviços agora ',

  BADGE_SUPERUSER_HEADER: 'Torne-se um superutilizador',
  BADGE_SUPERUSER_INFO: `Ganhe este emblema quando tiver partilhado forghetti com 5 amigos, tiver mais de dois grupos, tiver uma conta premium e mais de 50 serviços na sua conta forghetti`,
  BADGE_SUPERUSER_COMPLETE_INFO: `Parabéns! Você ganhou este emblema quando partilhou forghetti com 5 amigos, adicionou mais de dois grupos, assinou uma conta premium e criou mais de 50 serviços`,
  BADGE_SUPERUSER_REWARD: (premium) => (premium ? 'Obtenha 2 serviços extras para o seu grupo pessoal' : ''),
  BADGE_SUPERUSER_CTA: 'Partilhe forghetti com um amigo',

  BADGE_SERVICES_50_HEADER: '50 serviços!',
  BADGE_SERVICES_50_INFO: 'Ganhe este emblema criando 50 serviços na sua conta forghetti',
  BADGE_SERVICES_50_COMPLETE_INFO:
    'Parabéns! Você ganhou este emblema ao criar mais de 50 serviços na sua conta forghetti',
  BADGE_SERVICES_50_REWARD: (premium) => (premium ? 'Obtenha 2 serviços extras para o seu grupo pessoal' : ''),
  BADGE_SERVICES_50_CTA: 'Crie um serviço agora',

  BADGE_SERVICES_150_HEADER: '150 serviços!',
  BADGE_SERVICES_150_INFO: 'Ganhe este emblema criando 150 serviços na sua conta forghetti',
  BADGE_SERVICES_150_COMPLETE_INFO:
    'Parabéns! Você ganhou este emblema ao criar mais de 150 serviços na sua conta forghetti',
  BADGE_SERVICES_150_REWARD: (premium) => (premium ? 'Obtenha 2 serviços extras para o seu grupo pessoal' : ''),
  BADGE_SERVICES_150_CTA: 'Crie um serviço agora',

  BADGE_SERVICES_300_HEADER: '300 serviços!',
  BADGE_SERVICES_300_INFO: 'Ganhe este emblema criando 300 serviços na sua conta forghetti',
  BADGE_SERVICES_300_COMPLETE_INFO:
    'Parabéns! Você ganhou este emblema ao criar mais de 300 serviços na sua conta forghetti',
  BADGE_SERVICES_300_REWARD: (premium) => (premium ? 'Obtenha 2 serviços extras para o seu grupo pessoal' : ''),
  BADGE_SERVICES_300_CTA: 'Crie um serviço agora',

  BADGE_SERVICES_500_HEADER: '500 serviços!',
  BADGE_SERVICES_500_INFO: 'Ganhe este emblema criando 500 serviços na sua conta forghetti',
  BADGE_SERVICES_500_COMPLETE_INFO:
    'Parabéns! Você ganhou este emblema ao criar mais de 500 serviços na sua conta forghetti',
  BADGE_SERVICES_500_REWARD: (premium) => (premium ? 'Obtenha 2 serviços extras para o seu grupo pessoal' : ''),
  BADGE_SERVICES_500_CTA: 'Crie um serviço agora',

  BADGE_SHARING_1_HEADER: 'Compartilhe forghetti com um amigo',
  BADGE_SHARING_1_INFO: 'Ganhe este emblema quando indicar um amigo para forghetti e ele criar uma conta!',
  BADGE_SHARING_1_COMPLETE_INFO:
    'Parabéns! Você ganhou este emblema quando indicou um amigo para forghetti e ele criou uma conta!',
  BADGE_SHARING_1_REWARD: (premium) => (premium ? 'Obtenha 2 serviços extras para o seu grupo pessoal' : ''),
  BADGE_SHARING_1_CTA: 'Partilhe forghetti com um amigo',

  BADGE_SHARING_5_HEADER: 'Partilhe forghetti com 5 amigos',
  BADGE_SHARING_5_INFO: 'Ganhe este emblema quando indicar 5 amigos para forghetti e eles criarem uma conta!',
  BADGE_SHARING_5_COMPLETE_INFO:
    'Parabéns! Você ganhou este emblema quando indicou 5 amigos para forghetti e eles criaram contas!',
  BADGE_SHARING_5_REWARD: (premium) => (premium ? 'Obtenha 2 serviços extras para o seu grupo pessoal' : ''),
  BADGE_SHARING_5_CTA: 'Partilhe forghetti com um amigo',

  BADGE_SHARING_10_HEADER: 'Partilhe forghetti com 10 amigos',
  BADGE_SHARING_10_INFO: 'Ganhe este emblema quando indicar 10 amigos para forghetti e eles criarem uma conta!',
  BADGE_SHARING_10_COMPLETE_INFO:
    'Parabéns! Você ganhou este emblema quando indicou 10 amigos para forghetti e eles criaram contas!',
  BADGE_SHARING_10_REWARD: (premium) => (premium ? 'Obtenha 2 serviços extras para o seu grupo pessoal' : ''),
  BADGE_SHARING_10_CTA: 'Partilhe forghetti com um amigo',

  BADGE_SHARING_25_HEADER: 'Partilhe forghetti com 25 amigos',
  BADGE_SHARING_25_INFO: 'Ganhe este emblema ao indicar 25 amigos para forghetti e eles criarem uma conta!',
  BADGE_SHARING_25_COMPLETE_INFO:
    'Parabéns! Você ganhou este emblema quando indicou 25 amigos para forghetti e eles criaram contas!',
  BADGE_SHARING_25_REWARD: (premium) => (premium ? 'Obtenha 2 serviços extras para o seu grupo pessoal' : ''),
  BADGE_SHARING_25_CTA: 'Partilhe forghetti com um amigo',

  BADGE_SHARING_50_HEADER: 'Partilhe forghetti com 50 amigos',
  BADGE_SHARING_50_INFO: 'Ganhe este emblema quando indicar 50 amigos para forghetti e eles criarem uma conta!',
  BADGE_SHARING_50_COMPLETE_INFO:
    'Parabéns! Você ganhou este emblema quando indicou 50 amigos para forghetti e eles criaram contas!',
  BADGE_SHARING_50_REWARD: (premium) => (premium ? 'Obtenha 2 serviços extras para O seu grupo pessoal' : ''),
  BADGE_SHARING_50_CTA: 'Partilhe forghetti com um amigo',

  BADGE_GROUP_1_HEADER: 'Crie um novo grupo em forghetti',
  BADGE_GROUP_1_INFO: 'Ganhe este emblema ao criar e partilhar um novo grupo dentro da forghetti',
  BADGE_GROUP_1_COMPLETE_INFO:
    'Parabéns! Você ganhou este emblema quando criou e partilhou um novo grupo dentro da forghetti',
  BADGE_GROUP_1_REWARD: (premium) => (premium ? 'Obtenha 2 serviços extras para O seu grupo pessoal' : ''),
  BADGE_GROUP_1_CTA: 'Crie um grupo agora',

  BADGE_GROUP_5_HEADER: 'Crie 5 grupos em forghetti',
  BADGE_GROUP_5_INFO: 'Ganhe este emblema ao criar e partilhar 5 grupos dentro da forghetti',
  BADGE_GROUP_5_COMPLETE_INFO:
    'Parabéns! Você ganhou este emblema quando criou e partilhou 5 grupos dentro da forghetti',
  BADGE_GROUP_5_REWARD: (premium) => (premium ? 'Obtenha 2 serviços extras para o seu grupo pessoal' : ''),
  BADGE_GROUP_5_CTA: 'Crie um grupo agora',

  BADGE_LEGACY_HEADING: `Você é um superstar!`,
  BADGE_LEGACY_INFO_COMPLETE: `Muito obrigado por ser um utilizador forghetti de longa data. Como agradecimento, você recebeu este emblema especial e 3 meses de nossa assinatura premium gratuitamente!`,

  JOIN_GORUP_ALERT_MESSAGE: `Estamos apenas adicioná-lo ao grupo. Avisaremos quando estiver pronto.`,
  GROUP_UNAVAILABLE: (groupName) =>
    `O número de forghettibles grátis em <em>${groupName}</em> foi excedido. Para adicionar serviços ao grupo, considere fazer hoje uma atualização.`,
  GROUP_PERSONAL_UNAVAILABLE: (groupName) =>
    `O número de forghettibles grátis no seu grupo pessoal foi excedido. Para adicionar serviços ao grupo, considere fazer hoje uma atualização.`,
  GROUP_NONE_AVAILABLE: 'Todos os seus grupos excederam o limite permitido',

  SHARE_INVITE_USERNAME_HEADING: 'Antes de começar a partilhar',
  SHARE_INVITE_USERNAME_INFO:
    'Para que os seus amigos identifiquem os grupos que você deseja partilhar, forneça-nos um nome de perfil para sua conta',
  SHARE_INVITE_USERNAME_PLACEHOLDER: 'O seu nome',

  LEGACY_USER_ONBOARDING_HEADING: 'Nós valorizamos a sua lealdade',
  LEGACY_USER_ONBOARDING_INFO:
    'Como recompensa, estamos oferecendo a você uma assinatura premium de 3 meses totalmente grátis! Esperamos que você goste da nova versão do forghetti ainda mais do que a anterior.',
  CLAIM_PREMIUM: 'Reclamar a sua recompensa',
  LEGACY_USER_RELOAD:
    'Para aplicar a assinatura premium, precisamos recarregar o forghetti. Isso acontecerá automaticamente em alguns segundos.',

  SERVICE_OPEN_ICON_LABEL: 'abrir',
  SERVICE_VIEW_ICON_LABEL: 'Ver',
  SERVICE_HIDE_ICON_LABEL: 'ocultar',
  SERVICE_WEBSITE_ICON_LABEL: 'abrir',
  SERVICE_COPY_ICON_LABEL: 'copiar',

  GROUPSLIST_SWITCH_LABEL: 'trocar',
  GROUPSLIST_SHARE_LABEL: 'convidar',
  GROUPSLIST_SETTINGS_LABEL: 'definições',

  FORGHETTI_GROUP_PERMISSIONS: `Clique neste <a data-actionclick="openPermissionsLink">link</a> para saber mais sobre as permissões do grupo.`,

  SUBSCRIPTION_TANDC: `Por favor, observe que a sua assinatura será renovada automaticamente, a menos que seja cancelada antes do final do atual período. Você pode gerir e cancelar a sua assinatura - a qual desativa a renovação automática - acedendo à sua conta da app store após a compra. Para obter mais informações, visite os nossos <a data-actionclick="openTermsOfServiceUrl">termos de serviço</a> e nossa <a data-actionclick="openPrivacyPolicyUrl">política de privacidade</a>`,
  SUBSCRIPTION_TANDC_LIFETIME: `Por favor, observe que forghetti premium liftime dá-lhe direito a ... Para obter mais informações, visite os nossos <a data-actionclick="openTermsOfServiceUrl">termos de serviço</a> e nossa <a data-actionclick="openPrivacyPolicyUrl">política de privacidade</a>`,
  SUBSCRIPTION_ERROR: 'Ocorreu um erro ao validar as suas assinaturas. Por favor, tente novamente mais tarde.',

  SUBSCRIPTION_TANDC_HEADER: 'A sua assinatura',
  SERVICE_INJECT_LABEL: 'Usar',
  PHONE_NUMBER_TOO_LONG: 'Número de telefone inválido',

  ADD_SERVICE_LOADING_PLEASE_WAIT: 'Por favor, aguarde enquanto geramos o seu serviço forghetti',
  ADD_SERVICE_LOADING_GENERATING_SERVICE: 'A gerar o seu novo serviço',
  ADD_SERVICE_LOADING_GENERATING_PASSWORD: 'A gerar uma senha super segura',
  ADD_SERVICE_LOADING_LOADING_SERVICE_DETAILS: 'A carregar o seu novo serviço forghetti',

  VIEW_SERVICE_LOADING_LOADING_SERVICE_DETAIL: 'Por favor, espere enquanto carregamos o seu serviço',
  VIEW_SERVICE_LOADING_LOADING_SERVICE_FORGHETTIBLE: 'A construir os seus dados forghetti',

  MIGRATION_ALERT_HEADING: 'Migrar a sua conta',
  MIGRATION_ALERT_MESSAGE: `Para alterar o seu número, aceda à nossa aplicação da web em <a href="https://app.forghetti.com" target="_new">https://app.forghetti.com</a>.`,
  DELETE_ALERT_HEADING: 'Apagar a sua conta',
  DELETE_ALERT_MESSAGE: `Para apagar a sua conta, aceda à nossa aplicação da web em <a href="https://app.forghetti.com" target="_new">https://app.forghetti.com</a>.`,
  REFERRAL_CODE_UPDATE_NOT_ALLOWED: `Um código de referência já foi aplicado à sua conta`,
  REFERRAL_CODE_APPLIED: (code) => `O código de referência <em>${code}</em>foi aplicado à sua conta.`,
  INVALID_PHONE_NUMBER: 'O número de telefone é inválido. Por favor, tente novamente.',
  INVALID_ACTIVATION_CODE: 'O código de ativação não é válido',
  INVALID_TOO_MANY_ATTEMPTS: `Já houve muitas tentativas fracassadas de fazer login com este número. Por favor, tente novamente mais tarde.`,

  REONBOARDING_POPUP_GO_AGAIN: `Melhoramos a forma como você usa forghetti. Gostaria de se familiarizar com o seu funcionamento e o que torna o forghetti melhor?`,
  REONBOARDING_POPUP_BUTTON_GO: `Mostre-me`,
  REONBOARDING_POPUP_BUTTON_CANCEL: 'Não, obrigado',
  REONBOARDING_POPUP_HEADING: `Todos os novos forghetti 2.0`,
  HELP_QUICKSTART: 'início rápido integração',
  YOUR_BADGES: 'Os seus emblemas',
  GROUP_NOT_OWNER: 'Você só pode mover serviços para grupos dos quais é proprietário.',
  GROUP_NOT_OWNER_TITLE: 'Serviços de mudanças',
  VIEWSERVICE_NOT_COPIED_TO_CLIPBOARD: `<span class="toast--title">Você vai precisar da sua nova senha.</span><span class="toast--text">Clique no botão copiar no campo de senha para adicionar a sua nova senha à área de transferência.</span>`,
  EXTENSION_ATTEMPT: (website) =>
    `<strong class="toast--title">A pesquisar logins para </strong><span class="toast--clipboard-item">${website}</span>`,
  EXTENSION_ATTEMPT_FAIL: (website) =>
    `<span class="toast--title"><span class="toast--title">Incapaz de encontrar quaisquer logins correspondentes</span></span>`,

  UNIVERSAL_LINK_NEW_LOGIN: (url) => `A tentar encontrar um serviço correspondente para ${url}`,
  UNIVERSAL_LINK_NEW_LOGIN_NO_URL: `A iniciar o novo assistente de serviço`,

  CONFIRM_COUNTRY_UPDATE_HEADER: 'Mude o seu idioma',
  CONFIRM_COUNTRY_UPDATE_NOTE:
    'Para atualizar a sua preferência de idioma, forghetti precisará recarregar. Por favor, confirme esta ação',
  CONFIRM_BUTTON: 'Recarregar forghetti',

  FORGHETTI_LOGIN: 'Forghetti',
  FORGHETTI_LOGIN_TAGLINE: 'Esqueça as suas senhas. Para sempre.',
  FORGHETTI_LOGIN_CREATE: 'Crie a sua conta aqui',
  FORGHETTI_LOGIN_ALREADY_HAVE: 'eu já tenho uma conta',

  MUST_BE_LOGGED_IN_ACTION: 'Esta ação só está disponível quando ligado',
  UPDATING_FORGHETTIBLE: 'A atualizar seu forghettible',
  FORGHETTI_ON_MAC: `A área de trabalho forghetti está disponível para Mac. <a data-actionclick="openForghettiCom">Obtenha aqui.</a>`,
  FORGHETTI_ON_WINDOWS: `A área de trabalho forghetti está disponível para Windows. <a data-actionclick="openForghettiCom">Obtenha aqui.</a>`,

  REALTIME_NEW_SERVICE_FOR_GROUP: (group) => `Um novo serviço foi adicionado ao grupo partilhado${group}`,
  REALTIME_REMOVE_SERVICE_FOR_GROUP: (group) => `Um serviço foi eliminado do grupo partilhado ${group}`,
  REALTIME_KICK_SERVICE_FOR_GROUP: (group) => `Você foi eliminado do grupo ${group}`,
  REALTIME_BAN_SERVICE_FOR_GROUP: (group) => `Você foi expulso do grupo ${group}`,
  REALTIME_JOIN_SERVICE_FOR_GROUP: (group) => `Um novo utilizador foi adicionado ${group}`,
  REALTIME_REMOVE_GROUP: (group) => `O grupo ${group} foi eliminado pelo proprietário`,

  PERSONAL_GROUP: 'Pessoal',
  SHAREABLE_GROUP: 'Grupo partilhado',
  IMPORT_MENU: 'Serviços de importação',
  IMPORT_GROUP: 'Serviços importados',

  MONTHS: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],

  AND: 'e',
  NEW_GROUP: 'Novo grupo',
  LETS_START_SELECT: 'Vamos começar selecionando um ou mais desses serviços populares ...',
  CREATE_SERVICES: 'Criar serviços',
  CREATING_SERVICES: 'Estamos agora a gerar o seu serviços',
  MUST_SELECT_MORE_THAN_ONE_SERVICES: 'Selecione um ou mais serviços',
  ADD_NOTE: 'Adicione nova nota',
  EDIT_NOTE: 'Editar nota',
  NOTE: 'Notas',
  NOTE_COPIED: 'Nota copiada para a área de transferência',
  DELETE_NOTE: 'Apagar nota',

  FORGHETTI_LOGIN_TAGLINE: 'Esqueceu-se das suas senhas.',
  FORGHETTI_LOGIN_TAGLINE_2: 'Para sempre.',
  CREATE_ACCOUNT_1_HEADER_2: 'Crie a sua conta Forghetti.',

  CREATE_ACCOUNT_2_HEADING: 'Digite o seu código de verificação.',
  CREATE_ACCOUNT_FINAL_BUTTON: 'Mostre-me como',

  ONBOARDING_DOODLETUTORIAL2_HEADING_2: 'Desenhe um padrão',
  ONBOARDING_DOODLETUTORIAL3_HEADING_2: 'Várias linhas',
  ONBOARDING_DOODLETUTORIAL4_HEADING_2: 'Pontos também ...',
  ONBOARDING_DOODLETUTORIAL5_HEADING_2: 'Ou todos juntos',
  ONBOARDING_DOODLETUTORIAL6_HEADING_2: 'Mas lembre-se, o pedido é importante!',
  ONBOARDING_DOODLETUTORIAL7_HEADING_2: 'Rabisco A,',
  ONBOARDING_DOODLETUTORIAL8_HEADING_2: 'Rabisco A, não é o mesmo que o Rabisco B',
  SECURE_DOODLE_TUTORIAL: 'Tutorial do rabisco',
  DOODLE_TRY_AGAIN: 'Tente novamente',
  WELCOME_TO_FORGHETTI: 'Bem-vindo a Forghetti',

  ONBOARDING_COMPLETE_CONGRATULATIONS: `Parabéns! Você acabou de dar os primeiros passos para garantir a sua identidade on-line.`,

  ONBOARDING_COMPLETE_CREATE_FORGHETTI: 'Crie uma conta Forghetti.',
  ONBOARDING_COMPLETE_SIGNATURE_DOODLE: 'Crie o seu rabisco de assinatura.',

  ONBOARDING_COMPLETE_RESET_PASSWORD: 'Redefina uma senha insegura.',

  ONBOARDING_COMPLETE_HEALTHCHECK: 'Execute uma verificação de saúde em suas contas on-line.',

  ONBOARDING_COMPLETE_IMPORT: 'Serviços de importação',
  ONBOARDING_COMPLETE_IMPORT_BUTTON: 'Serviços de importação',
  ONBOARDING_COMPLETE_REMIND_LATER: 'Lembre-me mais tarde',
  ONBOARDING_COMPLETE_HEALTHCHECK_BUTTON: 'Execute uma verificação de saúde',

  USERNAME_OR_EMAIL: 'Nome de usuário',
  USERNAME_OR_EMAIL_SECOND: 'E-mail',
  SERVICE_EDIT_USERNAME_PLACEHOLDER: 'Insira qualquer outro detalhe de login',

  SERVICE_USERNAME_LABEL: 'Nome de usuário',
  SERVICE_USERNAME_SECOND_LABEL: 'E-mail',

  ADD_SERVICE_USERNAME_PLACEHOLDER: `Nome de usuário`,
  SERVICE_USERNAME_PLACEHOLDER_SECOND: `E-mail`,
  FREE_USER_UPGRADE: 'Atualizar para o premium',
  LOGIN_ACCOUNT_1_HEADER_2: 'Faça login na sua conta Forghetti.',
  KEYCHAIN_HEADING: 'Guarde o seu rabisco',
  KEYCHAIN_MESSAGE:
    'Você pode guardar o seu rabisco em segurança neste dispositivo para gerar as suas senhas ainda mais facilmente',
  KEYCHAIN_MESSAGE_2: 'Você gostaria de guardar o seu rabisco neste dispositivo?',
  KEYCHAIN_MESSAGE_3: 'Você pode sempre alterar isso nas suas configurações.',
  KEYCHAIN_YES: 'Sim, guarde o meu rabisco',
  KEYCHAIN_NO: 'Não, vou desenhar o meu rabisco cada vez',

  LOADING_VAULT: 'Carregando o seu cofre',
  ONBOARDING_COMPLETE_FIRST_LOGIN: 'Adicione um serviço.',
  ONBOARDING_COMPLETE_CONTINUE: `Agora redefina a uma senha`,
  STILL_LOADING: 'Estamos a carregar a sua conta',
  STILL_LOADING_2: 'A sua conta foi verificada',

  CREATE_ACCOUNT_VERIFYING_PHONE: 'Estamos a verificar o seu número',
  NOTIFICATION_MESSAGE_BAR: `Clique em 'Usar' num serviço para enviar a sua senha para {{service}}`,
  NO_NOTIFICATIONS_HELLO_TEXT: (name) => `Olá ${name}! Você ainda não tem nenhuma notificação.`,
  NO_NOTIFICATIONS_CHECK: 'Volte regularmente para atualizações.',
  REPLAY_DOODLE_HEADING: 'Repetir',
  FORGHETTIBLE: 'Forghettible',
  SERVICE_NO_NOTE: 'Sem nota',
  SERVICE_NOTE: 'Nota',
  SERVICE_BAD_NOTE: 'Para desbloquear esta nota, redesenhe o rabisco',
  SERVICE_NAME: 'Nome do Serviço',
  REPLAY_DOODLE: 'Repetir o rabisco',
  ENABLE_BIOMETRICS: 'Ativar biometria',
  DISABLE_BIOMETRICS: 'Desativar biometria',
  WEB_DELAY: 'Estabelecendo uma ligação e carregando os seus dados. Se demorar muito, verifique a sua ligação.',
  WEB_WAITING: 'Ainda a processar, por favor, espere ...',
  PROFILE_MIGRATE_2_INFO_2: 'Agora insira o código de verificação que você recebe em seu telefone antigo:',
  MIGRATE: 'Migrar',
  MONTHLY: 'Mês',
  YEARLY: 'Ano',
  UNLOCK_BIOMETRICS: 'Desbloquear com biometria',
  UNLOCK_DESCRIPTION: 'Desbloqueie sua conta Forghetti com biometria',
  UNLOCK_SERVICE: (service) => `Desbloquear ${service}`,
  UNLOCK_SERVICE_DESCRIPTION: (service) => `Para desbloquear ${service} Use Biometrics ou redesenhe seu doodle`,
  UNLOCK_FALLBACK: 'Use Doodle',
  UNLOCK_CANCEL: 'Cancelar',

  RATE_US: 'Nos avalie',
  SETUP_NEW_SERVICE_MESSAGE: `Vamos configurar o seu novo serviço fornecendo alguns detalhes.`,
  LETS_START_SELECT_COUNT: (count) =>
    `Vamos começar por selecionar esses serviços populares ... você pode selecionar <em data-count>${count}</em> mais no nível gratuito`,
  TOAST_TOO_MANY_SERVICES_SELECTED: (count) =>
    `Você selecionou muitos serviços. Selecione o máximo de ${count} Services.`,
  LETS_START_ADDING_DETAILS: `Vamos adicionar mais alguns detalhes`,
  ONBOARDING_COMPLETE_NEXT_STEPS: `<h2>Agora, por que não: </h2><ul><li>execute uma verificação de saúde nas suas contas on-line? </li><li>Ou atualize outro serviço em Forghetti.</li><li> Ou apenas dê uma olhadela a sua volta ...</li></ul>`,
  ONBOARDING_COMPLETE_CONGRATULATIONS: `Parabéns! Você acabou de dar os primeiros passos para garantir a sua identidade on-line.`,
  CREATE_ACCOUNT_1_MSG_4: `<b>NOTA</b>: Só usaremos o seu número para criar/apoiar a sua conta Forghetti. Você receberá um unico código de verificação de dispositivo.`,

  MOVE_SERVICE_CONFIRM_NOTE_UPDATE: (fromGroup, toGroup, list, listLength) =>
    `${listLength > 1 ? 'NOVO' : 'un novo'} <em>${list}</em> forghettible${
      listLength > 1 ? 's' : ''
    }  será gerado a partir de ${toGroup} o seu rabisco. Se desejar aceder ao seu antigo forghettible${
      listLength > 1 ? 's' : ''
    }, simplesmente desenhe o seu ${fromGroup} rabisco.`,
  YOUR_SUBSCRIPTION_PARTNER_PREMIUM_TEXT_1: (partner, link) =>
    `Nossos parceiros em <em> ${partner} </em> estão fornecendo a você o Forghetti Premium. Você pode descobrir mais sobre a sua assinatura aqui: <a href="${link}" target="_new"> ${link} </a>`,
  YOUR_SUBSCRIPTION_PARTNER_PREMIUM_TEXT_2: `Esperamos que você esteja gostando de usar Forghetti.`,
  PARTNER_PREMIUM_HEADING: `Parabéns!`,
  PARTNER_PREMIUM_MESSAGE: (partner) =>
    `Como um parceiro de Forghetti, <em> ${partner} </em> Os membros recebem premium forghetti. Você não precisa fazer nada, a assinatura será aplicada automaticamente à sua conta. Para que as alterações entrem em vigor imediatamente, clique imediatamente em 'Reload Forghetti' agora.`,
  PARNTER_PREMIUM_BUTTON: `Recarregar Forghetti`,

  CSL_SUBSCRIPTION_HEADER: `Premium ativado`,
  CSL_SUBSCRIPTION_1: `Você ativou nosso serviço premium. Obrigado. Quando esta assinatura expira, você pode continuar como um usuário gratuito (e perder todos os seus benefícios premium) ou renovar sua assinatura.`,
  CSL_SUBSCRIPTION_2: `Esperamos que você esteja gostando de usar Forghetti.`,
  LICENCE_KEY_INVALID: (partner) => `Desculpe, seu pedido de inscrição é inválido.`,
  LICENCE_KEY_USED: (partner) => ` Desculpe, sua solicitação de inscrição é inválida. `,
  LICENCE_KEY_ALREADY_APPLIED: (partner) => `Você se inscreveu com sucesso. Obrigado por usar este serviço.`,

  SERVICE_EMAIL_LABEL: 'E-mail',
  SERVICE_EMAIL_PLACEHOLDER: 'user@email.com',
  SERVICE_EDIT_EMAIL_PLACEHOLDER: 'user@email.com',
  PROFILE_USE_BIOMETRICS: 'Armazene seu doodle com biometria',
  BIOMETRICS_GROUPS_HEADER: 'Ativar biometria',
  BIOMETRICS_SELECT_GROUP: 'Selecione um grupo para armazenar seu doodle com biometria',
  BIOMETRICS: 'Biometria',
  BIOMETRICS_ENABLE: 'Habilitar',
  BIOMETRICS_DISABLE: 'Desativar',
  BIOMETRICS_SECURE: 'Seguro com biometria',
  BIOMETRICS_SECURE_MESSAGE: (group) =>
    `Seu doodle para <em> ${grupo} </em> será armazenado com segurança com biometria`,
  BIOMETRICS_USE_DOODLE: 'Use Doodle',
  BIOMETRICS_TRY_AGAIN: 'Tente novamente',
  NOTES: 'Notas',
  SELECT_SPECIAL_CHARACTERS: 'Selecione quais símbolos incluir',
  WORDS: 'Palavras <span class="monoespace">exemplo</span>',
  LETTERS: 'Letras <span class="monoespace">abc</span>',
  NUMBERS: 'Números <span class="monoespace">123</span>',
  SYMBOLS: 'Símbolos <span class="monoespace">!$%</span>',
  SELECT_PASSWORD_TYPE: (forghettibleName) => `${forghettibleName} opções`,
  SELECT_PASSWORD_NUMBERS: 'Total de letras / números / símbolos',
  SELECT_PASSWORD_WORDS: 'Palavras totais',
  ALL: 'Todos',
  SIMPLE: 'Simples',
  EDIT_PASSWORD_HEADING: 'Configurações',
  SERVICE_FIELD_PASSWORD_NO_OPTIONS: 'Pelo menos uma opção de senha deve ser selecionada',
  SERVICE_FIELD_PASSWORD_NO_SYMBOLS_SELECTED: 'Pelo menos um símbolo deve ser selecionado',
  SUBSCRIPTION_ENDS: (days) => `Sua assinatura deve expirar em ${days} dias.`,
  SUBSCRIPTION_ENDS_TOMORROW: 'Sua assinatura deve expirar amanhã.',
  SUBSCRIPTION_ENDS_TODAY: 'Sua assinatura deve expirar hoje.',
  NOTIFICATIONS: 'Notificações',
  CONTEXT_CHANGE_TYPE: 'Alterar opções',
  CONTEXT_CHANGE_LENGTH: 'Alteração de comprimento',
  CONTEXT_RENAME: 'Renomeie a Forghettible',
  NEW_FORGHETTIBLE: 'Novo forgéteado',
  OLD_FORGHETTIBLE: 'Velho paragetível',
  DELETE_FIELD_PROMPT: 'Tem certeza de que gostaria de excluir este campo?',
  ONBOARDING2_WELCOME_HEADING: 'Bem -vindo a Forghetti',
  ONBOARDING2_WELCOME_TEXT: 'Faça login em todos os serviços que você utiliza com um doodle seguro.',
  ONBOARDING2_DOODLE_HEADING: 'Crie seu doodle de assinatura',
  ONBOARDING2_DOODLE_TEXT:
    'De linhas ou pontos, rabiscos aleatórios ou seqüências numéricas. Sua combinação única gera uma senha super forte. ',
  ONBOARDIN2_HOWITWORKS: 'Como funciona',
  ONBOARDING_CONFIRM_DOODLE: 'Confirme seu doodle',
  ONBOARDING_BIOMETRICS_CONFIRM: 'Você gostaria de salvar isso em seu chaveiro?',
  SAVING_NOTE: 'Criptografar sua nota',
 
};
