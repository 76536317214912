const updateServiceIcon = require('../../action_helpers/updateServiceIcon');

module.exports = ({ element, section, z2hApp }) => {
  const clickedAvatar = $(element).closest('.login-avatar-wrapper');
  const suggestedPresetsSection = $(element).closest('.suggested-presets');

  suggestedPresetsSection.hide();

  const serviceName = $(clickedAvatar)
    .find('.login-avatar-label')
    .text();

  const serviceIconList = window.state.serviceIconList;
  const serviceDetails = serviceIconList.find(s => s.name === serviceName);
  const webAddress = serviceDetails.address;

  section
    .find('[data-actionkeyup=newServiceName]')
    .val(serviceName)
    .trigger('keyup');

  $('.suggested-presets').hide();

  if (webAddress) {
    section
      .find('[data-actionkeyup=newServiceWebAddress]')
      .val(webAddress)
      .trigger('keyup');
  }

  updateServiceIcon({
    iconUrl: serviceDetails.url,
    z2hApp: window.z2hApp,
    callback: (service, z2hApp, pane) => {},
    currentTarget: element,
  });
};
