/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */

const showResponseErrorMessage = require('../../action_helpers/showResponseErrorMessage');
const getFullServiceDetails = require('../../getPageData_helpers/getFullServiceDetails');
const fetchBase64Image = require('../../action_helpers/fetchBase64Image');
const hidePane3 = require('../../actions/miscellaneous/hidePane3');
const hideLogin = require('../createAccount/hideLogin');
const config = require('../../../config');
const { analyticsLogEvent } = require('../../app/firebaseUtils');
const _ = require('lodash');
const { strings } = config;

const INITIAL_DELAY_MS = 200;
const RETRY_IN_MS = 300;

const showToastMessage = require('../../action_helpers/showToastMessage');
const { makePaneLookBusy, makePaneLookIdle } = require('../../app/overlays');

// References to callbacks which will be called on success/failure
let _onSuccess;
let _onFailure;

// =================================================================================================
// This action will get all of the data for the new service from z2hApp.pageData.service.
// It will set a global newServiceStatus variable to pending. It will then proceed to create the new
// service (new-service API) and then start an "asynchronous loop" (a sort of recursive asynchronous
// function) to add all of the fields (forghettibles) to the new service.
// The code will check (on an interval) for the newServiceStatus flag to be set to "success" or
// "failed". It will then call the appropriate callback.
// =================================================================================================

module.exports = async ({ onSuccess, onFailure, batch = false }) => {
  // Either use the callbacks we've been give, or some defaults
  _onSuccess = onSuccess || ((_) => z2hApp.paneNavigation('viewService', $('#pane-2'), 1, null));
  _onFailure = onFailure || ((_) => hidePane3());

  // Get data for new service
  const { service } = z2hApp.pageData;
  const { serviceData, fieldsArray, fieldsSaved, fieldsUnsaved } = service;
  const groupId = window.state.selectedGroupId;

  // Add new password field to this service if it doesn't yet have any forghettibles specified
  if (fieldsArray.length === 0) {
    const { z2hTools } = window;
    const newFieldId = z2hTools.getUid();
    fieldsArray.push(newFieldId);
    const defaultGenerationSettings = window.state.defaultGenerationSettings || {};
    fieldsUnsaved[newFieldId] = {
      name: strings.FIELD_TEMPLATE_PASSWORD(),
      generation_settings: {
        exclude: '',
        length: defaultGenerationSettings.length || 16,
        numbers: true,
        symbols: false,
        simple_symbols: true,
        //symbols: true,
        lowercase: true,
        uppercase: true,
        words: 0,
        version: 1,
      },
    };
    const { STOP_SIMPLE_SYMBOLS } = require('../../../config/app');
    if (STOP_SIMPLE_SYMBOLS) {
      delete fieldsUnsaved[newFieldId].generation_settings.simple_symbols;
      fieldsUnsaved[newFieldId].generation_settings.symbols = true;
    }
    // Copy from fieldsUnsaved into fieldsSaved
    fieldsSaved[newFieldId] = fieldsUnsaved[newFieldId];
  }

  // For whatever reason, fieldsArray contains an array of unique IDs for each field/forghettible,
  // and fieldsSaved is an object like { fieldId: fieldDetails, fieldId: fieldDetails }
  // and now we want to put all of the fieldsSaved into fieldsData where ID is in fieldsArray
  const fieldsData = [];
  fieldsArray.forEach((id) => {
    fieldsData.push(fieldsSaved[id]);
  });

  window.state.newServiceStatus = 'pending';

  analyticsLogEvent('new_service');

  // Call API to add new service
  const endpoint = `groups/${groupId}/new-service`;

  $('#addservicePasswordLoading_text')
    .find('.secondary-text-content')
    .text(strings.ADD_SERVICE_LOADING_GENERATING_SERVICE());
  const response = await z2hApp.requestData('post', '', endpoint, serviceData);
  if (Math.floor(response.status / 100) !== 2) throw response;
  const newServiceId = response.data.id;

  window.state.newServiceId = newServiceId;

  window.state.temporaryServiceIconPromise = new Promise((resolve, reject) => {
    if (!serviceData.icon) resolve(null);

    fetchBase64Image(serviceData.icon)
      .then((icon) => {
        window.state.temporaryServiceIcon = icon;
        window.state.temporaryServiceIconChosen = true;
      })
      .then(() => {
        return updateServiceIcon(newServiceId, window.state.temporaryServiceIcon);
      })
      .then(() => {
        resolve(window.state.temporaryServiceIcon);
      })
      .catch((e) => {
        console.error('Error fetching icon for new service: ', e);
      });
  });

  const finalLoopIteration = fieldsArray.length;
  const currentLoopIteration = 0;
  setTimeout((_) => checkServiceAdded(z2hApp, batch), INITIAL_DELAY_MS);
  return newServiceAsyncLoop(newServiceId, fieldsData, finalLoopIteration, z2hApp, currentLoopIteration).catch((e) => {
    showResponseErrorMessage(err);

    if (!batch) {
      if ($('#pane-3').hasClass('active')) {
        z2hApp.paneNavigation('back', $('#pane-3'));
      } else {
        z2hApp.paneNavigation('back', $('#pane-2'));
      }
    }
  });
};

// =================================================================================================

async function updateServiceIcon(serviceId, image) {
  const data = { image };

  //$('#addservicePasswordLoading_text').find('.secondary-text-content').text('Uploading service icon');
  const response = await z2hApp.requestData('post', '', `services/${serviceId}/update-image`, data);
  if (response.status !== 200) console.error('New service icon upload failed:', response);
}

// =================================================================================================

const checkServiceAdded = async (z2hApp, batch) => {
  const { newServiceStatus } = window.state;
  if (newServiceStatus === 'pending') {
    // Check again after short period of time
    setTimeout((_) => checkServiceAdded(z2hApp, batch), RETRY_IN_MS);
    return;
  }

  //makePaneLookIdle(2);
  //makePaneLookIdle(3);

  if (newServiceStatus !== 'success') {
    // Error occurred
    showToastMessage(strings.MSG_FALLBACK_ERROR_MESSAGE());
    // Probably want to go back to addService1 page at this point
    _onFailure();
    return;
  }

  // Otherwise successful...

  // Set parameter for viewService page
  if (!batch) {
    window.state.selectedServiceId = window.state.newServiceId;
    await window.state.temporaryServiceIconPromise;

    $('#addservicePasswordLoading_text')
      .find('.secondary-text-content')
      .text(strings.ADD_SERVICE_LOADING_LOADING_SERVICE_DETAILS());
    getFullServiceDetails()
      .then((response) => {
        // Navigate to viewService page
        // getFullServiceDetails().then(serviceDetails => {
       
        window.state.currentServices.push(response);
        window.state.currentService = response;
        window.state.newlyAddedServiceDetailsToDisplay = response;

        window.z2hApp.pageData = z2hApp.pageData || {};
        window.z2hApp.pageData.service = _.cloneDeep(response);
        
        window.z2hApp.pageData.service.fieldsSaved = {};
        window.z2hApp.pageData.service.fieldsUnsaved = {};
        window.z2hApp.pageData.service.fieldsToBeReset = {};
        window.z2hApp.pageData.service.fieldsToBeRestored = {};

        // Add service to group
        const groupId = window.state.selectedGroupId;
        const groupsList = window.state.groupsList || [];
        const group = groupsList.find((g) => g.id === groupId) || {};
        const groupServices = group.services || [];
        if (!groupServices.find((s) => s.id === response.id)) {
          groupServices.push(response);
        }
        localStorage.setItem('services-' + groupId, JSON.stringify(response));
        _onSuccess();
      })
      .catch((_) => ({}));
  } else {
    _onSuccess();
  }
};

// =================================================================================================

const newServiceAsyncLoop = (newServiceId, fieldsData, finalLoopIteration, z2hApp, currentLoopIteration) => {
  if (currentLoopIteration >= finalLoopIteration) {
    // Loop finished
    window.state.newServiceStatus = 'success';
    return;
  }
  // Skip fields with no generation settings
  if (!fieldsData[currentLoopIteration].generation_settings) {
    currentLoopIteration++;
    return newServiceAsyncLoop(newServiceId, fieldsData, finalLoopIteration, z2hApp, currentLoopIteration);
  }
  // Next loop
  const currentFieldData = fieldsData[currentLoopIteration];
  currentFieldData.generation_settings.version = z2hApp.apiConfig.version;

  const endpoint = `services/${newServiceId}/new-field`;

  delete currentFieldData.generation_settings.collapsed;
  delete currentFieldData.generation_settings.type;

  const data = JSON.stringify(currentFieldData);

  $('#addservicePasswordLoading_text')
    .find('.secondary-text-content')
    .text(strings.ADD_SERVICE_LOADING_GENERATING_PASSWORD());
  return z2hApp
    .requestData('post', '', endpoint, data)
    .then((response) => {
      if (response.status !== 200) {
        window.state.newServiceStatus = 'failed';
        return;
      }
      currentLoopIteration++;
      return newServiceAsyncLoop(newServiceId, fieldsData, finalLoopIteration, z2hApp, currentLoopIteration);
    })
    .catch((err) => {
      console.error(err);
      window.state.newServiceStatus = 'failed';
    });
};
