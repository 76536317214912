const config = require('../../../config');
const { infoTextRow, thirdheading } = require('../../page_helpers/components');

const strings = require('../../../config').strings;

module.exports = ({ nav }) => {
  const user = window.state.userData;
  const groupId = window.state.selectedGroupId;
  const group = window.state.groupsList.find((g) => g.id === groupId) || {};
  const service = window.z2hApp.pageData.service.serviceData;
  const onboarding = window.state.userData.activeOnboarding;
  const reonboarding = window.state.userData.reonboarding;

  const serviceName = service.name;
  const serviceIcon = service.icon || config.app.DEFAULT_SERVICE_ICON;

  const groupIcon = group.personal
    ? user.avatar || config.app.DEFAULT_USER_ICON
    : group.icon || config.app.DEFAULT_GROUP_ICON;

  const groupIconId = group.personal ? window.state.userData.id : group.id;
  const groupName = group.name || '';

  const headerFields = () => {
    let fields = {
      id: onboarding ? 'doodle-pad-onboarding' : 'doodle-pad',
      class: 'no-title',
      navbar: false,
    };
    if (!onboarding) {
      fields = {
        ...fields,
        header: serviceName,

        navigation_left: strings.BACK(),
        navigation_left_data: Math.max(nav - 1, 0),
        navigation_left_template: 'back',
      };
    }
    return fields;
  };

  const rows = [];

  //reonboarding && rows.push({ template: 'block-onboarding-close' })
  //onboarding && rows.push(thirdheading({ text: strings.ADD_SERVICE_DOODLE_DO_IT_AGAIN, additionalClass: 'centered-text' }));
  onboarding && rows.push(infoTextRow(strings.ADD_SERVICE_DOODLE_CONFIRM_DOODLE(), true, false, true));

  rows.push({
    template: 'block-avatar-group',
    fields: {
      avatar_src_1: serviceIcon,
      avatar_src_2: groupIcon,
      avatar_id_1: service.id,
      avatar_id_2: groupIconId,
      avatar_name_1: serviceName,
      avatar_name_2: groupName,
      class: 'with-arrow',
    },
  });

  !onboarding && rows.push(infoTextRow(strings.ADD_SERVICE_DRAW_DOODLE()(group.name || ''), true, false, true));

  rows.push(
    {
      template: 'block-section-row',
      fields: { class: 'no-border' },
      columns: [{ template: 'block-doodlepad' }],
    },
    {
      template: 'block-doodlepad-buttons',
      fields: {
        //confirm_validation: 'validateDoodlepad_confirm',
        confirm_action: 'confirmDoodlePad_2',
      },
    },
  );

  return {
    template: onboarding ? 'block-section-onboarding' : 'block-section-page',
    fields: headerFields(),
    rows: rows,
  };
};
