const showAlertMessage = require('../../action_helpers/showAlertMessage');
const { LEGACY_USER_RELOAD } = require('../../../config/strings');
module.exports = ({ z2hApp }) => {
  window.state.userData.activeOnboarding = false;

  localStorage.setItem('onboarding_complete', true);
  const endpoint = 'users/@me/complete-task';
  const data = JSON.stringify({ taskName: 'step_onboarding' });
  showAlertMessage(LEGACY_USER_RELOAD());
  z2hApp.requestData('post', '', endpoint, data).then(e => {
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  });
};
