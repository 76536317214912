const z2hApp = require('../../app/z2hApp');

module.exports = ({ currentTarget, init = null }) => {
  const simpleSymbols = z2hApp.simpleSymbols;
  if (init) {
    if (!init.simple_symbols && !init.symbols) {
      $('.special-character-cell').each((idx, elem) => {
        $(elem).attr('data-selected', false);
      });
      //$('.special-character-table').hide();
      //$('#allOrSimpleSymbolsSelector').hide();

      return;
    }

    $('.special-character-cell').each((idx, elem) => {
      let select = true;
      const elemValue = $(elem).text();
      if (init.simple_symbols && !init.symbols) {
        select = simpleSymbols.indexOf(elemValue) >= 0;
      } else if (init.symbols) {
        select = true;
      }

      if (init.exclude.indexOf(elemValue) >= 0) {
        select = false;
      }

      $(elem).attr('data-selected', select);
    });
    return;
  }

  const { generation_settings } = z2hApp.pageData.service.fieldsUnsaved[z2hApp.active.pageId];

  const DOMTarget = $(currentTarget);

  if (DOMTarget.attr('data-selected') === 'true') {
    DOMTarget.attr('data-selected', false);
  } else {
    DOMTarget.attr('data-selected', true);
  }
  const symbolsActive = $('.special-character-cell[data-selected="true"]').text();
  const allSymbols = $('.special-character-cell').text();
  const excludedSymbols = allSymbols
    .split('')
    .filter((f) => symbolsActive.indexOf(f) < 0)
    .join('');

  const isSimpleSymbols = symbolsActive === simpleSymbols.join('');
  generation_settings.symbols = symbolsActive.length > 0 && !isSimpleSymbols;
  generation_settings.simple_symbols = symbolsActive.length > 0 && isSimpleSymbols;
  generation_settings.exclude = symbolsActive.length > 0 && !isSimpleSymbols ? excludedSymbols : '';
};
