const LINK_EXPIRY_VALUES = {
  15: '15分钟',
  30: '30分钟',
  60: '1小时',
  360: '6小时',
  720: '12小时',
  1440: '1天',
  "-1'": '决不',
};

const LINK_MAX_USE_VALUES = {
  1: '一次',
  2: '两次',
  5: '5次',
  10: '10次',
  20: '20次',
  50: '50次',
  '-1': '没有限制',
};

module.exports = {
  YOU: '您',
  BACK: '后退',
  NEXT: '下一个',
  CONTINUE: '继续',
  SAVE: '保存',
  ADD: '添加',
  SKIP: '略过',
  CANCEL: '取消',
  PREVIOUS: '前一个',
  EDIT: '编辑',
  NONE: '没有',
  START: '开始',
  SEARCH: '搜索',
  CLOSE: '关闭',
  SEE_DETAILS: '详情',
  UNAVAILABLE: '不适用',
  DONE: '完成',
  WATCH_AGAIN: '再次观看',
  NEW: '新的',

  UNAVAILABLE_FORGHETTIBLES_MESSAGE:
    '您的部分forghettibles不适用，因为您的已经超过了免费额度。请注册成为高级会员，享受无限量的forghettibles！',

  FORGHETTI_TAGLINE: '永久性忘记您的密码',
  CREATE_ACCOUNT: '创建一个帐户',
  HAVE_ACCOUNT: '我已经有一个帐户',

  COMBOBULATING: '... 产生您的forghettibles 密码 …',

  CREATE_ACCOUNT_1_HEADER: '输入号码',
  CREATE_ACCOUNT_1_HEADING: '验证您的设备',
  CREATE_ACCOUNT_1_MSG: '请输入您的手机号码，通过短信接收<strong>一次性验证码</strong>。',
  CREATE_ACCOUNT_1_PLACEHOLDER: '您的号码',
  CREATE_ACCOUNT_1_MSG_2: '请输入您的国家代码(如適用)，并输入您的手机号码。\n 比如 07795123456。',
  CREATE_ACCOUNT_1_MSG_3: '验证短信只会在，验证您的设备和必要的应用程序更新时才会发送。',

  ERROR_TEL_NUM_EMPTY: '必须提供手机号码',

  MAIN_NAV_GROUP: '组',
  MAIN_NAV_PROFILE: '简介',
  MAIN_NAV_HEALTHCHECK: '数据安全健康检查',
  MAIN_NAV_IMPORT: '汇入服务',
  MAIN_NAV_LOGOUT: '登出',
  NAVBAR_JOIN_GROUP: '加入群组',
  NAVBAR_NEW_GROUP: '新建群组',
  NAVBAR_NEW_PASSWORD: '新的密码',
  NAVBAR_NEW_PIN: '新的PIN',
  NAVBAR_NEW_WORD: '新的提示词',
  NAVBAR_HOME: '家/主页',
  NAVBAR_GROUPS: '群组',
  NAVBAR_HEALTHCHECK: '数据安全健康检查',
  NAVBAR_PROFILE: '简介',

  /* Lochy loading - paneBusyText.js */
  LOCHY_LOADING_TEXT_DEFAULT: '载入中...',
  LOCHY_LOADING_TEXT_GROUP: (group) => `为<em> ${group}</em>加载服务`,
  LOCHY_LOADING_TEXT_GROUPSLIST: '加载群组',
  LOCHY_LOADING_TEXT_HEALTHCHECK: '加载数据安全健康检查',
  LOCHY_LOADING_TEXT_CHOOSE_SEARCH_ICON: '搜索网络的服务图标',
  LOCHY_LOADING_TEXT_CHOOSE_CHOOSE_GROUP_ICON: '上传组图标',
  LOCHY_LOADING_TEXT_CREATE_GROUP_INVITE: '创建群组邀请',
  LOCHY_LOADING_TEXT_EDIT_SERVICE: '编辑服务细节',
  LOCHY_LOADING_TEXT_UPDATE_SERVICE: '存储服务细节',
  LOCHY_LOADING_TEXT_PROFILE: '加载个人资料',
  LOCHY_LOADING_TEXT_SUBSCRIPTION: '加载您的订购详细信息',

  /*                                 */

  CREATE_ACCOUNT_2_HEADER: '输入代码',
  CREATE_ACCOUNT_2_HEADING: '输入您的验证码',
  CREATE_ACCOUNT_2_MSG: (telNumber) => `请输入发送至${telNumber}的验证码。`,
  CREATE_ACCOUNT_2_LINK_BACK: '我还没有收到代码',
  CREATE_ACCOUNT_2_MSG_2: (termsofserviceurl) =>
    `通过输入验证码，即表示您同意我们的<a href="${termsofserviceurl}" target="_new">服务条款</a>。`,

  ERROR_TEL_NUM_INVALID: '请输入有效的电话号码',
  ERROR_ACT_CODE_EMPTY: '请输入已发送给您的激活码',
  ERROR_ACT_CODE_INVALID: '请输入一个有效的激活码',
  ERROR_ACT_CODE_INVALID: '激活码不正确。请再试一次',

  CREATE_ACCOUNT_VERIFYING: '检查验证码',
  CREATE_ACCOUNT_VERIFYING_PHONE: '验证您的手机号码',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_1: '未曾保存的密码',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_2: '您只需要记住您的涂鸦',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_3: '您可以在所有的设备上使用forghetti',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_4: '升级后能够在群组内安全地共享密码',

  CREATE_ACCOUNT_REFERRAL_HEADER: '推荐代码',
  CREATE_ACCOUNT_REFERRAL_HEADING: '输入推荐代码',
  CREATE_ACCOUNT_REFERRAL_PLACEHOLDER: '推荐代码',
  REFERRAL_ENTRY_MESSAGE_1: `您是否有朋友的推荐代码？如果是这样，请在此输入。否则，您可以跳过这一步。`,

  CREATE_ACCOUNT_3_HEADER: '编辑个人资料',
  CREATE_ACCOUNT_3_HEADING: '编辑您的个人资料',
  CREATE_ACCOUNT_3_TEXT: `如果您决定要共享他人访问或被邀请参加另一个用户组登录，您可以在这里设置个人资料的显示名称和图标，这将有助于他人识别您的身份。`,
  CREATE_ACCOUNT_3_LABEL: '您的名字',
  CREATE_ACCOUNT_3_TEXT_2: `如果您希望稍后设定名字和照片，您可以跳过这一步。`,

  CREATE_ACCOUNT_ADD_LOGIN_MSG: `从这些热门的应用程序中选择一去更新`,

  CREATE_ACCOUNT_FINAL_HEADER: '完成！',
  CREATE_ACCOUNT_FINAL_MSG: `您的forghetti可以使用！`,
  CREATE_ACCOUNT_FINAL_MSG_2: `您正在通向保护您的帐户和免去担心忘记密码的大路上！`,
  CREATE_ACCOUNT_FINAL_MSG_3: `现在，我们将引导您通过必要的步骤去更新您现有的密码成为forghettible。`,
  CREATE_ACCOUNT_FINAL_BUTTON: '引导我怎么样做！',
  CREATE_ACCOUNT_FINAL_LINK: `我已经掌握forghetti的使用方法`,

  CREATE_ACCOUNT_ADD_CUSTOM_LOGIN: `或者是其他...`,
  CREATE_ACCOUNT_SERVICE_NAME_TOOLTIP: `要添加不同的服务，您需要提供的服务的名称。\n\nEmail/ 用户名和网址是可选的，但我们建议增加这些呢！`,
  CREATE_ACCOUNT_CUSTOM_LOGIN_ADD_PASSWORD_TOOLTIP: `您将需要添加至少一种类型的密码。\n\n 有几种不同的密码选择，因为一些服务需要特定类型的密码。`,
  CREATE_ACCOUNT_CUSTOM_LOGIN_NEXTPAGE_TOOLTIP: `满意请单击“下一步”继续。`,
  CREATE_ACCOUNT_NEW_LOGIN_DOODLEPAD_MSG: `您的涂鸦将被用于您须要找回您的密码时，所以尽情发挥吧！`,
  CREATE_ACCOUNT_ADD_ANOTHER_LOGIN_TOOLTIP: `要添加其他服务到您的帐户，请点击这里再次进入新的登录界面。`,

  ADD_SERVICE_SEARCH_HEADER: `添加新的服务`,
  ADD_SERVICE_SEARCH: `从这些热门的应用程序选择一个服务或开始输入其他名称`,
  ADD_SERVICE_SUGGESTED: `推荐的服务`,
  ADD_SERVICE_CREATE: (name) => `创建 “${name}”`,
  ADD_SERVICE_SELECT_ICON_HEADER: `选择一个图标`,
  ADD_SERVICE_SELECT_ICON: (serviceName) => `为${serviceName}选择一个图标`,
  ADD_SERVICE_SELECT_GROUP_HEADER: `选择群组`,

  ADD_SERVICE_YOUR_GROUP: (serviceName, groupName) => `<em> ${serviceName} </em>将被添加到 <em>${groupName} </em>`,
  ADD_SERVICE_SELECT_GROUP: (serviceName) => `选择一个群组来添加<br /> <em> ${serviceName} </em>到`,
  ADD_SERVICE_SELECT_GROUP_SELECT: (serviceName) => `或者选择一个不同的群组以添加<br /> <em> ${serviceName} </em>`,
  MOVE_SERVICE_SELECT_GROUP: (serviceName) => `选择一个群组来移动<br /> <em> ${serviceName} </em>`,
  MOVE_SERVICE_LOADING: '移动您的服务',
  MOVE_SERVICE_CONFIRM_HEADER: '移动您的服务',
  MOVE_SERVICE_CONFIRM_NOTE: (
    service,
    fromGroup,
    toGroup,
    numFields,
  ) => `这个服务<em> ${service}</em>将会从<em> ${fromGroup} </em>群组移到<em> ${toGroup} </em>群组。
<br/>此服务有<em>${numFields}</em> forghettible${numFields > 1 ? 's' : ''}。`,
  MOVE_SERVICE_CONFIRM_NOTE_UPDATE: (fromGroup, toGroup, list, listLength) => `一个新的 <em>${list}</em> forghettible${
    listLength > 1 ? 's' : ''
  } 将从您的 <em>${toGroup}</em>生成涂鸦. 如果您想访问旧的forghettible ${
    listLength > 1 ? 's' : ''
  }, 只需要从<em>${fromGroup}</em>画您的涂鸦。
`,
  MOVE_SERVICE_CONFIRM: '确认移动',
  ADD_SERVICE_SELECT_GROUP_ONBOARD: (serviceName) => `添加您的<em>${serviceName}</em>到一个群组`,
  ADD_SERVICE_USERNAME: (serviceName) => `为<em> ${serviceName}</ em>输入用户名，或从下面的列表中选择。`,
  ADD_SERVICE_USERNAME_ONBOARD: (serviceName) => `输入您的<em> ${serviceName}</em>的电子邮件或用户名`,
  ADD_SERVICE_USERNAME_HEADER: `选择用户名`,
  ADD_SERVICE_USERNAME_PLACEHOLDER: `电子邮件或用户名`,
  ADD_SERVICE_SEARCH_FOR_SERVICE: `输入您在这里使用的服务`,
  ADD_SERVICE_SEARCH_FOR_ICONS: '搜索图标',
  ADD_SERVICE_SUGGESTED_USERNAMES: `建议的用户名`,
  ADD_SERVICE_DRAW_DOODLE: (groupName) => `绘制涂鸦为您的<em> ${groupName}</em>群组`,
  ADD_SERVICE_USERNAME_BLANK: `您必须输入用户名或电子邮件地址`,
  ADD_SERVICE_USERNAME_TOO_LONG: '您的用户名必须少于64个字符',

  ADD_SERVICE_WEBSITE_HEADER: '进入网站',
  ADD_SERVICE_WEBSITE: (serviceName) =>
    `输入网站网址的<em> ${serviceName}</em>。你可以跳过这一步骤，稍后输入网站网址。`,
  ADD_SERVICE_WEBSITE_PLACEHOLDER: `网址`,

  ADD_SERVICE_SEARCH_ONBOARD_LETS_START: `让我们起航，选择其中一个热门的服务，或者...`,
  ADD_SERVICE_SEARCH_ONBOARD_NOW_ITS_YOUR_TURN: `现在轮到您了`,
  ADD_SERVICE_DOODLE_DO_IT_AGAIN: `让我们再来一次`,
  ADD_SERVICE_DOODLE_ONBOARD_NOW_ITS_YOUR_TURN: `现在轮到您了`,
  ADD_SERVICE_DOODLE_CREATE_DOODLE: '创建您的签名涂鸦',
  ADD_SERVICE_DOODLE_CONFIRM_DOODLE: `让我们来看看你能否记住它...请<em>再一次</em>画您的涂鸦`,
  ADD_SERVICE_GENERATING_FORGHETTI_ONBOARD: (serviceName) =>
    `恭喜，forghetti现在正为<em>${serviceName} </em>生成新的密码`,
  VIEW_SERVICE_GENERATING_FORGHETTI: (serviceName) => `正在为<em>${serviceName} </em>生成您的forghetti密码`,
  VIEW_SERVICE_RESET_PASSWORD: `现在重置您的密码`,
  VIEW_SERVICE_PASSWORD_HELP: (serviceName) =>
    `我们为<em>${serviceName}</em>已经生成了一个超强的密码。现在是时候重新设置您的旧密码。`,
  VIEW_SERVICE_PASSWORD_HELP_MOVE: (serviceName, groupName) =>
    `我们已转移 <em>${serviceName}</em> 到 <em>${groupName}</em>。如果您这个服务的涂鸦已改变，您将需要更新您的密码。`,
  VIEW_SERVICE_OLD_PREFIX: '旧的',
  VIEW_SERVICE_NEW_PREFIX: '新的',
  HIDE_QUICK_SELECT: `不需要再告诉我`,
  HIDE_QUICK_SELECT_CONFIRM_TITLE: `隐藏此屏幕？`,
  HIDE_QUICK_SELECT_CONFIRM: `此页面将被删除未来不会再显示，从此增加新的服务将直接带您到“添加其他服务”页面。\n\n 在您的“用户设置”，可以随时还原此设置。`,
  ADD_SERVICE_DODODLES_DO_NOT_MATCH: `您的涂鸦不匹配。再试一次。`,
  THEME_COLOUR: '颜色',
  THEME_COLOURS: ['Flawless Blue', 'Fresh Green', 'Fearless Red', 'Feel-good Orange', 'Friendly Yellow'],
  THEME_BLUE: '完美无瑕的蓝',

  DELETE_ACCOUNT_CONTINUE: '继续',
  DELETE_ACCOUNT_HEADER: '删除帐户',
  DELETE_ACCOUNT_HEADING: '删除我的账户',
  DELETE_ACCOUNT_LINE_1: '删除您的帐户将：',
  DELETE_ACCOUNT_LINE_2: '•删除您的forghetti帐户',
  DELETE_ACCOUNT_LINE_3: '•擦除forghetti根据您的个人资料保存所有信息',
  DELETE_ACCOUNT_LINE_4: '•从所有forghetti群组删除您',
  DELETE_ACCOUNT_LINE_5: '删除您的帐户从您所有的登录设备',
  DELETE_ACCOUNT_CHANGE_INSTEAD: '需要更换到另一部手机？',

  DELETE_ACCOUNT_CONFIRM_MESSAGE: (telNumber) =>
    '我们已经发出一个确认码到您的手机 (' + telNumber + '). ' + '请输入代码以确认您将要删除自己的帐户：',
  DELETE_ACCOUNT_CONFIRM_BUTTON: '确认',

  DEVICES_HEADER: '设备管理',
  DEVICES_LAST_ACTIVE: '上一次登录： ',
  DEVICES_FORGHETTI_APP: '  -  forghetti应用',
  DEVICES_LOG_OUT_ALL: '退出所有设备',

  SERVICES_PERSONAL: '个人的',
  SERVICES_ADD_LOGIN: '添加登录',
  SERVICES_RECENT: '最近的', // Not currently used

  SEARCH_INTERNET: '在互联网上搜索图像',

  SERVICE_HEADER_NEW: '添加登录',
  SERVICE_NAME_PLACEHOLDER: '命名您的登录',

  SERVICE_DOODLEPAD_SUBHEADING: '在屏幕按键上涂鸦',
  DOODLEPAD_SECRET: '隐藏',
  DOODLEPAD_SHOW: '显示',
  DOODLEPAD_REDOODLE: '清除',
  ERROR_NO_DOODLE: '请画您的涂鸦',

  SERVICE_EDIT_FIELD_HEADER: '设置',

  FIELD_TYPES: {
    lns: 'ABC123_*.',
    wns: 'Words123_ *。',
    lnx: 'ABC123〜？％',
    wnx: 'Words123〜？％',
    ln: 'ABC123',
    wn: 'Words123',
    l: 'ABC',
    w: '英文字母',
    n: '123',
  },

  FIELD_TEMPLATE_PIN: '数字密码',
  FIELD_TEMPLATE_COMPLEX_PASSWORD: '复杂的密码',
  FIELD_TEMPLATE_PASSWORD: '密码',
  FIELD_TEMPLATE_MEMWORD: '易记词提示',
  FIELD_TEMPLATE_FORGHETTIBLE: 'Forghettible字',

  SERVICE_USERNAME_LABEL: '电子邮件或用户名',
  SERVICE_WEBSITE_LABEL: '网址',

  SERVICE_EDIT_HEADER: '细节',
  SERVICE_EDIT_USERNAME_PLACEHOLDER: '输入登录方法',
  SERVICE_EDIT_WEBSITE_PLACEHOLDER: 'www.webaddress.com',

  SERVICE_ADD_FORGHETTIBLE: '添加一个forghettible',
  SERVICE_DFT_FORGHETTIBLE_NAME: '密码',

  SERVICE_NAME_LENGTH_INVALID: '服务名称应为2到32个字符长度',
  SERVICE_USERNAME_LENGTH_INVALID: '服务Email /用户名应少于64个字符长',
  SERVICE_ADDRESS_LENGTH_INVALID: '服务网址应不超过128个字符长',
  SERVICE_NO_FORGHETTIBLES: '您必须添加至少一个forghettible。例如，一个密码。',

  SERVICE_CHOOSE_ICON: '选择图标',

  SERVICE_DOODLEPAD_HEADING: '在屏幕按键上涂鸦',
  SERVICE_DOODLEPAD_RIGHT_NAV: '细节',

  SERVICE_ADDING: '正在添加服务',

  SERVICE_CONNECTING: '正在连接',

  FIELD_DETAILS_HEADER: '设置',
  FIELD_NAME: '名称',
  FIELD_NAME_PLACEHOLDER: '命名您的forghettible',
  FIELD_TYPE: '类型',
  FIELD_LENGTH: '长度',
  FIELD_LENGTH_PICKER_CUSTOM: '个性化',

  FIELD_RIGHT_NAV: '好的',

  FIELD_OPTIONS_HEADING: '选项',

  ADVANCED_OPTIONS: '高级选项',
  RESET_FIELD: '复位forghettible',
  DELETE_FIELD: '删除',
  RESTORE_FIELD: '恢复forghettible',

  FIELD_ADVANCED_OPTIONS_HEADER: '高级选项',

  RESTORE_FIELD_PAGE_HEADER: '恢复',
  RESTORE_FIELD_SUBHEADING: '需要使用旧密码？',
  RESTORE_FIELD_DESCRIPTION: '您可从这里返回到以前的版本。只需选择您想恢复的版本。',
  RESTORE_FIELD_VERSION_HISTORY: '版本历史记录',
  RESTORE_FIELD_CURRENT: '当前的',

  SERVICE_VIEW_HEADER: '概要',
  SERVICE_VIEW_RIGHT_NAV: '完成',

  SERVICE_SUMMARY_MESSAGE: `您的forghettibles已经产生。请在下面选择复制，查看和访问此登录。`,
  SERVICE_SUMMARY_FIRST_LOGIN_MSG2: `您现在需要用这个新的密码取代现有的密码让它生效。`,
  SERVICE_SUMMARY_FIRST_LOGIN_PASSWORD_TOOLTIP: `当您准备好了，您可以通过点击该图标复制您的新密码到剪贴板。`,

  SERVICE_DETAILS_HEADER: '细节',
  SERVICE_DETAILS_RIGHT_NAV: '编辑',
  SERVICE_DETAILS_DELETE: '删除登录',

  PROFILE_HEADER: '个人资料',
  PROFILE_WIZARD_COMPLETE: '完成您的forghetti设置。',
  PROFILE_WIZARD_SEE_DETAILS: '查看详情>',
  PROFILE_PREMIUM_BANNER_HEADING: '尝试高级会员版',
  PROFILE_PREMIUM_BANNER_DETAIL: '解锁功能使在线更加安全。',
  PROFILE_THEME_MODE_LABEL: '模式',
  PROFILE_THEME_COLOUR_LABEL: '颜色',
  PROFILE_BADGES: '您的徽章',
  PROFILE_LINK_ACCOUNT: '您的帐户',
  PROFILE_LINK_PREMIUM: '高级会员版',
  PROFILE_ENABLE_AUTOFILL: '启用自动填充',
  PROFILE_LINK_USER_PREFERENCES: '应用程序设置偏好',
  PROFILE_YOUR_SUBSCRIPTION: '您的订阅',
  PROFILE_LINK_INVITE: '与朋友们分享forghetti',
  /*PROFILE_LINK_INVITE_SECONDARY: '与朋友们分享来赚取免费登录，*/
  PROFILE_REFERRAL_CODE: '推荐代码',
  PROFILE_REFERRAL_CODE_SECONDARY: '',
  PROFILE_LINK_DEVICES: '设备管理',
  PROFILE_LINK_HELP: '帮助',
  PROFILE_LINK_FEEDBACK: '向我们反馈您的意见',
  PROFILE_LINK_ABOUT: '关于',
  PROFILE_LINK_LOGOUT: '退出',

  PROFILE_ACCOUNT_HEADER: '您的帐户',
  PROFILE_ACCOUNT_LINK_SECURITY: '安全',
  PROFILE_ACCOUNT_LINK_MIGRATE: '更改号码',
  PROFILE_ACCOUNT_LINK_DELETE: '删除我的账户',
  PROFILE_ACCOUNT_LINK_SUBSCRIPTION: '您的订阅',

  PROFILE_REONBOARDING: '了解forghetti是如何工作的',
  PROFILE_GET_ONLINE_HELP: '获得在线帮助',
  PROFILE_GET_VIDEO_HELP: '获取视频在线帮助',
  PROFILE_HELP_HEADER: '帮助',

  PROFILE_EDIT_NAME_HEADER: '详细资料',
  PROFILE_EDIT_NAME_LABEL: '您的名字',
  PROFILE_EDIT_TEL_LABEL: '手机号码',

  PROFILE_NAME_BLANK: '个人资料名称不能为空',
  PROFILE_NAME_TOO_LONG: '个人资料名称不能超过32个字符',

  PROFILE_NAME_INVALID: '个人资料名称只能包含字母，数字和符号',

  PROFILE_MIGRATE_1_HEADER: '更改号码',
  PROFILE_MIGRATE_1_HEADING: '当前手机号码',
  PROFILE_MIGRATE_1_INFO: '请输入您新的国家代码和手机号码：',
  PROFILE_MIGRATE_1_PLACEHOLDER: '手机号码',
  PROFILE_MIGRATE_1_BUTTON: '发送验证码',

  PROFILE_MIGRATE_2_HEADER: '输入验证码',
  PROFILE_MIGRATE_2_HEADING: '当前手机号码',
  PROFILE_MIGRATE_2_HEADING2: '新的手机号码',
  PROFILE_MIGRATE_2_INFO: '现在在您的新手机上输入您收到的验证码：',
  PROFILE_MIGRATE_2_BUTTON: '确认',

  PROFILE_MIGRATE_3_HEADER: '完成',
  PROFILE_MIGRATE_3_MSG: '您的手机号码已更新',
  PROFILE_MIGRATE_3_HEADING: '新的手机号码',

  PROFILE_ABOUT_HEADER: '关于',
  PROFILE_ABOUT_PRIVACY: '个人隐私政策',
  PROFILE_ABOUT_COOKIE: '网络Cookie政策',
  PROFILE_ABOUT_TERMS: '服务条款',
  PROFILE_ABOUT_ACCEPTABLE: '可接受的使用政策',
  PROFILE_ABOUT_VERSION_HEADING: '版本',

  PROFILE_REFERRAL_CODE_HEADING: '推荐代码',
  PROFILE_REFERRAL_CODE_LABEL: '推荐代码',
  PROFILE_REFERRAL_CODE_PLACEHOLDER: '输入您的推荐代码',
  PROFILE_EDIT_REFERRAL_CODE_HEADING: '编辑推荐代码',
  PROFILE_REFERRAL_CODE_TEXT: '如果您收到一个推荐代码请在下面输入。',

  PROFILE_PREMIUM_TYPE: {
    premium_trial: '高级会员试用用户',
    premium_life: '终身会员',
    free: '免费会员',
    premium: '高级会员订阅者',
    premium_paid: '高级会员订阅者',
  },
  APP_PREFERENCES_HEADER: '应用程序偏好设置',
  APP_PREFERENCES_THEME: '主题',
  APP_PREFERENCES_QUICK_SELECT: '显示工具提示',
  APP_PREFERENCES_SHOW_QUICK_SELECT: '显示快速添加登录界面',
  APP_PREFERENCES_SECRET_DOODLE: '默认的神秘涂鸦',
  APP_PREFERENCES_SHOW_TOOLTIPS: '显示工具提示',
  APP_PREFERENCES_REQUIRE_TOUCH_ID: '需要生物识别技术开启应用程序',
  APP_PREFERENCES_NOTIFICATIONS: '通知',
  APP_PREFERENCES_COUNTRY: '语言',
  APP_PREFERENCES_TOUCH_ID_TOAST: `请确保生物识别ID已被登记在设备上`,

  PREMIUM_HEADER: 'Forghetti高级会员',

  YOUR_SUBSCRIPTION_HEADER: '您的订阅',
  YOUR_SUBSCRIPTION_SUBHEADING: '高级会员试用',

  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_TEXT_1: (date) =>
    `您当前在免费高级会员试用期。您的试用期将于${date}到期。到时，您可以继续作为一名免费用户（当失去所有的高级会员超值优惠）或购买应用内付费订阅。`,
  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_TEXT_2: (date) => `我们衷心希望您享受使用forghetti。`,

  YOUR_SUBSCRIPTION_FREE_TEXT_1: (date) =>
    `您目前是免费会员。今天升级订阅开始享受无限登录，无限量与朋友、家人、同事创建小组，并获得详细的数据安全健康检查报告。`,
  YOUR_SUBSCRIPTION_FREE_TEXT_2: (date) => `我们衷心希望您享受使用forghetti。`,
  YOUR_SUBSCRIPTION_FREE_TEXT_3: '如果你已经从订阅，点击恢复订阅并重新申请。',
  YOUR_SUBSCRIPTION_FREE_DOWNLOAD: `尚未安装此应用？那请您从应用程序商店立即下载`,

  YOUR_SUBSCRIPTION_PREMIUM_TEXT_1: (date) =>
    `您目前是高级会员。您的订阅有效期截止到${date}。当您购买订阅时它被设置为自动再订阅。如果您需要，可以在App Store帐户关闭这个功能。`,
  YOUR_SUBSCRIPTION_PREMIUM_TEXT_2: (date) => `我们衷心希望您享受使用forghetti。`,

  YOUR_SUBSCRIPTION_PREMIUM_NO_DATE_TEXT_1: `您目前是高级用户。当您购买订阅它被设置为自动更新。如果你愿意，你可以在App Store帐户关闭这个功能。`,

  YOUR_SUBSCRIPTION_PREMIUM_LIFE_TEXT_1: (date) => `您的终身高级会员将永不过期。请您开心使用forghetti到永远！`,
  YOUR_SUBSCRIPTION_PREMIUM_LIFE_TEXT_2: (date) => `我们衷心希望您享受使用forghetti。`,
  YOUR_SUBSCRIPTION_FREE_TEXT_APP_3: '如果您已经从订阅，点击恢复订阅并重新申请。',

  YOUR_SUBSCRIPTION_FREE_TEXT_APP_1: `您目前是免费会员。今天订阅开始享受无限登录，无限量与朋友、家人、同事创建小组，并获得详细的数据安全健康检查报告。`,

  YOUR_SUBSCRIPTION_FREE_TEXT_APP_2: '请用手机扫描QR代码来订阅高级会员服务。',
  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_BUTTON_LABEL: '查看定价',
  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_CORDOVA_BUTTON_LABEL: '查看订阅',

  YOUR_SUBSCRIPTION_FREE_BUTTON_LABEL: '升级',

  PREMIUM_NO_PRODUCT_SELCTED: '无效的产品选择',
  PREMIUM_PURCHASE_BUTTON: '采购',
  PREMIUM_PURCHASE_BUTTON_NA: '敬請期待',
  COMING_SOON: '敬請期待',
  PREMIUM_PRICE_NONE: 'N/A',
  PREMIUM_PRUCHASE_REFRESH_PURCAHSE: '刷新您的购买',
  PREMIUM_MONTHLY_HEADER: '每月高级会员',
  PREMIUM_MONTHLY_LIST: [
    {
      description: '无限量小组',
    },
    {
      description: '无限登录',
    },
    {
      description: '优先支持',
    },
  ],
  PREMIUM_ANNUAL_ALIAS: '年度计划',
  PREMIUM_MONTHLY_ALIAS: '月费计划',
  PREMIUM_ONE_OFF_ALIAS: '一次性购买',
  PREMIUM_LOADING_OWNED: '恭喜！您现在已成为高级会员！',
  PREMIUM_LOADING_RELOAD: '稍后forghetti将重新加载并开启您新的订阅。',
  PREMIUM_LOADING_APPROVED: '请稍候，我们正在通过应用商店确认您的订阅！',
  PREMIUM_LOADING_REGISTERED: '请稍候，我们正在加载可选址的订阅...',
  PREMIUM_MANAGE_SUBSCRIPTIONS: '管理订阅',
  PREMIUM_IMPOSSIBLE_TO_VERIFY: '对不起，一直无法确认您的购买',
  PREMIUM_RESTORE_PURCHASE: '恢复订阅',
  PREMIUM_YEARLY_HEADER: '全年的高级会员',
  PREMIUM_YEARLY_LIST: [
    {
      description: '无限量小组',
    },
    {
      description: '无限登录',
    },
    {
      description: '优先支持',
    },
  ],
  PREMIUM_LIFETIME_HEADER: '终身高级会员',
  PREMIUM_LIFETIME_LIST: [
    {
      description: '无限量小组',
    },
    {
      description: '无限登录',
    },
    {
      description: '优先支持',
    },
  ],
  ENABLE_ANDROID_AUTOFILL_HEADING: '启用自动填充功能',
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_1: `forghetti使用Android的可访问性服务来实现自动填充功能，当您在设备上的任何应用中提示时，它有助于获取密码。`,
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_2:
    '我们向您保证，forghetti不会读取或访问任何其他屏幕内容。我们仅确定何时在屏幕上出现密码字段以进行自动填充。',
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_3: `要使用此服务，需要使用用户同意。通过单击“同意”按钮，您可以允许使用自动填充的可访问性服务。如果您选择拒绝同意，则将禁用Autofill功能，但您可以在没有此功能的情况下继续使用forghetti。`,
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_4: `请注意，从此屏幕上导航并不是作为给予同意，我们也不会使用自动消耗的消息来收集同意。`,
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_5: `有关如何实施和确保我们对可访问性API的使用以及我们处理用户数据的更多信息，请查看我们的隐私政策。`,

  ENABLE_ANDROID_AUTOFILL_BUTTON: '同意',
  ENABLE_ANDROID_AUTOFILL_BUTTON_NO: '现在不要',
  MAKE_ADMIN_MESSAGE: '您确定将该用户变成本群组的管理员吗？',
  MAKE_OWNER_MESSAGE: '您确定将这个群组的所有权转移给这个用户吗？',

  REMOVE_ADMIN_MESSAGE: '您确定删除此用户的管理状态吗？',
  DELETE_GROUP_CONFIRM: '您确定要删除该群组吗？',
  LEAVE_GROUP_CONFIRM: '你希望离开这个群组吗？',
  GROUP_DELETED_MESSAGE: '该群组目前已被删除。',
  REMOVE_MEMBER_MESSAGE: '您希望把这个人从此群组中删除吗？',
  REMOVE_MEMBER_SUCCESS: '此成员已从群组中删除',
  GROUP_SHARED_BY: '群组被共享',
  ERROR_INVALID_NUM_WORDS: '英文单词的数目必须在1和10之间',
  ERROR_INVALID_NUM_LETTERS: '英文字亩数目必须是1和64之间',

  UPGRADE_REQUIRED: '需要升级',
  ADD_LOGIN_NOT_ALLOWED: `免费用户只可以添加登录到您的个人小组。今天升级到高级会员版，可以创建群组与朋友、家人和同事和共享登录！`,
  UPGRADE_TODAY_MESSAGE: `免费用户可以加入一个小组。
今天升级到高级会员版，可以创建群组与朋友、家人和同事和共享登录！`,

  UPGRADE_TODAY_BUTTON: '今日更新',
  UPGRADE_TODAY_PAGE_HEADING: '今日更新',
  UPGRADE_TODAY_MSG_1: '成为forghetti的高级用户',
  UPGRADE_TODAY_PRICE: '每年£23.99',
  UPGRADE_TODAY_MSG_2: '开放forghetti所有的功能',
  UPGRADE_TODAY_MSG_3: '高级会员可以在应用程序内购买升级：',

  UPGRADE_TODAY_MSG_4: '年度订阅让您成为一个高级会员。从此享受创建无限登录，无限forghettible域和组。',

  INVITE_FRIEND_HEADER: '获取免费服务',
  INVITE_FRIEND_REFERRAL_LABEL: '推荐链接',
  INVITE_FRIEND_REFERRAL_COPY: '复制链接推荐到剪贴板',
  INVITE_FRIEND_REFERRAL_EMAIL: '发送推荐链接到电子邮件',
  INVITE_FRIEND_PREAMBLE: '与朋友分享forghetti',
  INVITE_FRIEND_TEXT: `只分享您的个人链接。`,
  INVITE_FRIEND_BENEFIT:
    '推荐给一位朋友，当好友注册成功，您可以得到再添加五个服务到您的个人小组，每五个好友注册成功时，您将获得15个额外的服务！',

  GROUPS_LIST_HEADER: '您的个人小组',
  GROUPS_LIST_INFO_MESSAGE: '管理您的群组或组间进行切换',
  GROUPS_LIST_PERSONAL_HEADING: '您的个人小组',
  GROUPS_LIST_OTHER_GROUPS_HEADING: '您的其他群组',
  GROUPS_LIST_ADD_NEW_GROUP: '添加新群组',
  GROUPS_LIST_CURRENTLY_ACTIVE: '当前使用中',
  GROUPS_NEW_HEADER: '新的群组',
  GROUPS_NEW_RIGHT_NAV: '创造',
  GROUPS_NEW_NAME_PLACEHOLDER: '输入标题',

  GROUPS_ADD_GROUP_HEADER: '添加群组',
  GROUPS_JOIN_GROUP: '加入群组',
  GROUPS_JOIN_GROUP_TEXT: '使用邀请链接加入群组',
  GROUPS_CREATE_GROUP: '创建群组',
  GROUPS_CREATE_GROUP_TEXT: '创建新群组，与他人分享',
  GROUPS_FREE_JOIN_WARNING: '免费用户可以加入一个小组。',
  GROUPS_ADD_UPGRADE: '今天升级到高级会员版，可以创建群组与朋友、家人和同事和共享登录！',
  GROUP_NEW_INVITE_GENERATING: '正在生成您的群组邀请',
  LEAVE_GROUP_MESSAGE: '您现在已经从该群组删除。',
  GROUP_DETAIL_LOADING: `重新获取组详细信息`,
  GRP_SHARING_HEADING: '分享',
  GRP_MEMBERS_HEADING: '会员',
  GRP_SETTINGS_HEADING: '群组设置',

  GROUP_VIEW_HEADER: '组详细信息',
  GROUP_VIEW_RIGHT_NAV: '编辑',
  GROUP_VIEW_USER_ADMIN: '管理员',
  GROUP_VIEW_USER_OWNER: '所有者',
  GROUP_VIEW_USER_YOU: '您',
  GROUP_VIEW_USER_READ_ONLY: '唯读',
  GROUP_VIEW_SWITCH_TO: '切换到该群组',
  GROUP_VIEW_LEAVE: '离开此群组',
  GROUP_VIEW_DELETE: '删除群组',
  GROUP_VIEW_PREMIUM: '高级会员',
  GROUP_VIEW_FREE: '免费会员',
  GROUP_DETAIL_SAVE_LOADING: `更新您的群组详细信息`,
  GROUP_EDIT_HEADER: '组详细信息',
  GROUP_EDIT_MAX_MEMBERS: '最大成员数量',
  GROUP_EDIT_MAX_MEMBERS_SECONDARY: '包括您',
  PERSONAL_GROUP_MESSAGE: `这是您的个人小组。这小组不会与任何人分享。要更改这个小组的图标，前去您的个人资料设置，更改个人资料照片。`,

  GROUP_CHOOSE_ICON: '选择图标',

  GROUP_NAME_BLANK: '群组名称不能为空',
  GROUP_NAME_TOO_LONG: '群组名称不能超过32个字符',

  GRP_MAX_MEMBERS_ERROR: '最大成员数必须是1和50之间的整数',

  JOIN_GROUP_PLACEHOLDER: '输入您的邀请链接',
  JOIN_GROUP_HEADING: '加入群组',
  GRP_INVITE_CODE_LABEL: '群组邀请链接',
  GRP_INVITE_CODE_NOTE: (group) => `通过共享该链接，邀请成员到<em> ${group} </em>`,
  JOIN_GROUP_RIGHT_NAV: '加入',

  REFERRAL_COPY_MESSAGE: (url) => `我邀请您和我一同使用forghetti。下载应用程序，您将从此不会忘记所有的密码。点击${url}`,

  CREATE_GROUP_INVITE_TEXT_1: `请将此代码发送给您的家人、朋友或团队成员，方便他们访问该组`,

  INVITE_NEW_MEMBER: '邀请新成员',
  GRP_INVITE_HELP_TEXT: `所有群组成员均可以创建新的登录，但只有群组管理员有权删除它们。管理员还可以删除成员，但是如果是您创建的群组，您永远不能被另一个管理员删除。`,

  // Template for text that is copied to clipboard when you choose to copy a group invite code to clipboard
  GRP_INVITE_CLIPBOARD_MESSAGE: (code) => `${code}`,

  INVITE_LINK_HEADING: '邀请',
  INVITE_SETTINGS_HEADING: '设置',
  LINK_EXPIRY_LABEL: '链接到期日',
  MAX_USES_LABEL: '最大使用数',

  LINK_EXPIRY_VALUE: (mins) => LINK_EXPIRY_VALUES[String(mins)],
  LINK_MAX_USES_VALUE: (num) => LINK_MAX_USE_VALUES[String(num)],
  INVITE_NO_LIMIT: LINK_MAX_USE_VALUES['-1'],
  INVITE_EXPIRY_NEVER: LINK_EXPIRY_VALUES['-1'],

  DEACTIVATE_GRP_LINK_CONFIRM: '您确定要删除此邀请吗？',
  DEACTIVATE_GRP_LINK_SUCCESS: '邀请入群组的要求已被成功删除。',

  GRP_INVITE_CREATE_NAV: '创造',

  // Share Group Invite page
  SHARE_INVITE_HEADING: '邀请成员',
  GRP_INVITE_CODE_LABEL: '邀请链接',
  CPY_GRP_INVITE: '复制邀请到剪贴板',
  DEACTIVATE_LINK: '删除邀请',
  SHARE_INVITE_VIA_EMAIL: '通过电子邮件分享邀请',
  COPY_INVITE_SUCCESS: `复制组邀请到剪贴板。`,

  ONBOARDING_HEADER_1: '创建一个登录到您喜爱的网站',
  ONBOARDING_HEADER_2: '绘制您的涂鸦',
  ONBOARDING_HEADER_3: '您的密码已生成',
  ONBOARDING_HEADER_4: '复制并前往！',
  ONBOARDING_HEADER_5: '就是它啦！',
  ONBOARDING_WELCOME_HEADING: `嗨，我的名字是Locky<br>欢迎使用forghetti`,
  ONBOARDING_WELCOME_TEXT: '使用forghetti，您可以一个安全的涂鸦登录到您使用的所有服务。',
  ONBOARDING_DOODLE_HEADING: '您将需要一个签名涂鸦',
  ONBOARDING_DOODLE_TEXT: '从线的点，随机的连线或数字序列。您独特的组合可以产生超强的密码。',
  ONBOARDING_HOW_IT_WORKS: '这个怎么运作',
  ONBOARDING_ADDSERVICE_HEADING: '任何服务都棒',
  ONBOARDING_ADDSERVICE_TEXT:
    '您在任何时候添加一个服务，我们都产生一个独特的和超强的密码。您甚至可以生成数字代码和提示词。',
  ONBOARDING_RESETPASSWORD_HEADING: '重置和交换旧密码',
  ONBOARDING_RESETPASSWORD_TEXT: '打开网站重置您的旧密码。用新的和超安全的forghetti密码替换它。',
  ONBOARDING_SECURESHARING_HEADING: '安全的密码共享',
  ONBOARDING_SECURESHARING_TEXT: '利用群组与朋友、家人和同事安全地共享访问自己喜爱的服务。',
  ONBOARDING_UNLIKE_OTHERS_HEADING: '不像其他的密码管理器 ...',
  ONBOARDING_UNLIKE_OTHERS_TEXT:
    '我们不在数据库保存任何密码。它们随您的使用即时生成。他们使用您的涂鸦作为密钥计算。超级安全，只有您知道。',
  ONBOARDING_DOODLETUTORIAL1_HEADING: '现在，创造您的签名涂鸦！需要怎么做呢？',
  ONBOARDING_SHOWMEHOW: '让我引导您如何开始！',
  ONBOARDING_DOODLETUTORIAL2_HEADING: '首先，您可以画一个图案...',
  ONBOARDING_DOODLETUTORIAL: '生成安全涂鸦教程',
  ONBOARDING_DOODLETUTORIAL3_HEADING: '或一系列的线',
  ONBOARDING_DOODLETUTORIAL4_HEADING: '点也是好主意...',
  ONBOARDING_DOODLETUTORIAL5_HEADING: '或者将它们全部组合在一起',
  ONBOARDING_DOODLETUTORIAL6_HEADING: '但要记住它们的次序哦，',
  ONBOARDING_DOODLETUTORIAL7_HEADING: '画形状字母A',
  ONBOARDING_DOODLETUTORIAL8_HEADING: '和涂鸦字母B形状就不一样',
  ONBOARDING_YOURTURN: `现在轮到您了`,

  ONBOARDING_COMPLETE_WELCOME: '欢迎使用forghetti',
  ONBOARDING_COMPLETE_CONGRATULATIONS: '恭喜！您迈出了第一步，确保您的在线身份安全',
  ONBOARDING_COMPLETE_COMPLETE_SETUP: '完成您的forghetti设置',
  ONBOARDING_COMPLETE_CREATE_FORGHETTI: '创建forghetti帐户',
  ONBOARDING_COMPLETE_SIGNATURE_DOODLE: '创建您的签名涂鸦',
  ONBOARDING_COMPLETE_FIRST_LOGIN: '添加您的第一个服务软件',
  ONBOARDING_COMPLETE_RESET_PASSWORD: '生成一个安全的密码',
  ONBOARDING_COMPLETE_IMPORT: '汇入服务',
  ONBOARDING_COMPLETE_HEALTHCHECK: '开始您网上帐户的密码安全检测',
  ONBOARDING_COMPLETE_IMPORT_BUTTON: '汇入服务',
  ONBOARDING_COMPLETE_REMIND_LATER: '稍后提醒我',
  ONBOARDING_COMPLETE_CONTINUE: `我们开始吧`,
  ONBOARDING_COMPLETE_RESET_PASSWORD: '重置不安全的密码',
  ONBOARDING_COMPLETE_HEALTHCHECK_BUTTON: '运行密码安全健康检查',
  ONBOARDING_COMPLETE_NEXT_STEPS: `<h2>为什么现在不能：</h2> <ul> <li>为您的在线帐户运行安全健康检查？ </li> <li>或者更新forghetti的其他服务。</ li> <li>或者只是闲着... </ li> </ ul>`,

  ONBOARDING_EXISTING_TITLE: '新的功能',
  ONBOARDING_EXISTING_WELCOME_HEADING: '非常感谢你',
  ONBOARDING_EXISTING_WELCOME_TEXT: `我们Forghetti团队一直忙优化服务，立即体验我们的新功能吧。`,
  ONBOARDING_EXISTING_MOVE_SERVICES_HEADING: '转移服务',
  ONBOARDING_EXISTING_MOVE_SERVICES_TEXT:
    'forghetti现在允许您把一个服务从一个组移动到另一个。只需点击各服务上的三个点并选择移动。',
  ONBOARDING_EXISTING_HEALTHCHECK_HEADING: '数据安全健康检查',
  ONBOARDING_EXISTING_HEALTHCHECK_TEXT:
    'forghetti现在包括安全健康检查，该功能允许主动监视您的用户名和密码对照常见的数据泄露。',
  ONBOARIDNG_EXISTING_SHARING_HEADING: '快速群组共享',
  ONBOARDING_EXISTING_SHARING_TEXT: '一键点击分享您的forghetti群组与朋友、家人和同事。',
  ONBOARDING_EXISTING_SWITCHING_HEADING: '快速切换群组',
  ONBOARDING_EXISTING_SWTICHING_TEXT: '与小组合作变得比以往任何时候都更容易。添加新的服务比以往更快，更容易。',
  ONBOARDING_EXISTING_PRICING_HEADING: '定价更新',
  ONBOARDING_EXISTING_PRICING_TEXT: 'forghetti现在提供更多的订阅计划选择，以满足您的需求。',

  ACCOUNT_SECURITY_HEADER: '安全',
  ACCOUNT_SECURITY_HEADING: '绝对的安全',
  ACCOUNT_SECURITY_SECONDARY_HEADING:
    '数据安全是我们的宗旨。 forghetti使您能够生成使用单个涂鸦并登录多个服务。更厉害的是，在forghetti的数据库中没有存储任何密码...，没有一个哦。不同于所有其他众多的密码管理应用程序，我们不会保存您的密码。  ',
  ACCOUNT_SECURITY_LEARN_MORE: '了解更多关于forghetti安全',

  MSG_FALLBACK_ERROR_MESSAGE: `发生错误！很抱歉。`,

  MSG_GROUP_ICON_UPDATED: '更新图标',
  MSG_COPIED_FORGHETTIBLE: (name) => `${name}复制到剪贴板`,
  MSG_OPEN_FORGHETTIBLE: `点击这里打开`,
  MSG_DELETE_LOGIN_CONFIRM: '你确定要删除此登录？',
  MSG_LOGIN_DELETED: (name) => `登录'${name}'已删除`,
  MSG_SERVICE_ICON_DELETED: '服务图标更新',
  MSG_LOGOUT_CONFIRM: '您确定要登出吗？',
  MSG_LOGOUT_ALL_DEVICES_CONFIRM: '您确定吗？',
  MSG_USER_PROMOTED_ADMIN: '用户已晋升为管理员。',
  MSG_USER_PROMOTED_OWNER: '所有权已转移',

  MSG_CONFIRM_RESET: '请确认您想重置该密码',
  MSG_CONFIRM_RESTORE: '请确认您想恢复这个以前的版本？',
  MSG_NEW_GROUP_CREATED: (name) => `新群组已创建：${name}`,
  MSG_OPENING_WEBSITE: '打开网站...',
  MSG_REMOVED_ADMIN: '此用户管理员状态被取消',
  MSG_UNSAVED_CONFIRM: '有未保存的更改。您确定想返回吗？',

  EXTENSION_NONE_FOUND: '没有找此网址的forghettibles',
  EXTENSION_GO_TO_FORGHETTI: '转到forghetti',
  EXTENSION_NOACCESS_HEADER: '今日更新',
  EXTENSION_UPGRADE: `您目前是免费会员。立即升级到高级会员，您可以使用无限登录的forghetti Chrome扩展程序。并享有创造朋友，家人和同事之间的群体共享登录的能力。如果你想升级到高级会员，请选择下方的升级按钮。`,
  EXTENSION_NOLOGINFOUND_HEADER: `未找到forghetti`,
  EXTENSION_NOLOGINFOUND_MSG: '没有找到这个网站的forghettible。为此网址，在下面创建新的forghettible涂鸦',

  CONTEXT_EDIT_LOGIN_LIST_ITEM: '编辑',
  CONTEXT_MOVE_LOGIN_LIST_ITEM: '移动',
  CONTEXT_ADD_LOGIN_LIST_ITEM: '添加forghettible',
  CONTEXT_RESET_LOGIN_LIST_ITEM: '复位forghettible',
  CONTEXT_DELETE_LOGIN_LIST_ITEM: '删除',
  CONTEXT_UPLOAD_GROUP_PHOTO: '拍照',
  CONTEXT_UPLOAD_GROUP_IMAGE: '上传图片',
  CONTEXT_CHOOSE_GROUP_IMAGE: '选择图片',
  CONTEXT_TAKE_PROFILE_PIC: '拍照',
  CONTEXT_UPLOAD_PROFILE_PIC: '上传图片',
  CONTEXT_UPLOAD_SERVICE_IMAGE: '上传图片',
  CONTEXT_CHOOSE_SERVICE_IMAGE: '选择图片',
  CONTEXT_SERVICE_IMAGE_WEBSEARCH: '网络搜索',
  CONTEXT_MAKE_ADMIN: '设为管理员',
  CONTEXT_MAKE_OWNER: '设为拥有者',

  CONTEXT_REMOVE_FROM_GROUP: '从组中删除',
  CONTEXT_REVOKE_ADMIN: '撤销管理员',
  CONTEXT_NEW_GROUP_MEMBERS: '新群组最大成员',
  CONTEXT_SELECT_FIELD_TYPE: '选择类型',
  CONTEXT_FIELD_TYPE_LNS: '英文字母，数字和符号',
  CONTEXT_FIELD_TYPE_WNS: '英文单词 ，数字和符号',
  CONTEXT_FIELD_TYPE_LNX: '英文字母，数字和复杂的符号',
  CONTEXT_FIELD_TYPE_WNX: '英文单词，数字和复杂的符号',
  CONTEXT_FIELD_TYPE_LN: '字母和数字',
  CONTEXT_FIELD_TYPE_WN: '英文单词和数字',
  CONTEXT_FIELD_TYPE_L: '只可以英文字母',
  CONTEXT_FIELD_TYPE_W: '只可以英文单词',
  CONTEXT_FIELD_TYPE_N: '只可以数字',
  CONTEXT_ADD_FIELD: '在下面选择一个预先定义的字段。当完成设置后您可以稍后自定义。',
  CONTEXT_ADD_FIELD_PASS: '密码（字母，数字和符号）',
  CONTEXT_ADD_FIELD_COMPLEX_PASS: '高级密码（字母，数字和复杂的符号）',

  CONTEXT_ADD_FIELD_PIN: '数字密码（仅限数字）',
  CONTEXT_ADD_FIELD_MEMWRD: '提示词（只可以使用英文单词）',
  CONTEXT_ADD_FIELD_FORGHETTIBLE: 'Forghettible提示字（只可以使用英文字母）',
  CONTEXT_LINK_EXPIRE_15MINS: '15分钟',
  CONTEXT_LINK_EXPIRE_30MINS: '30分钟',
  CONTEXT_LINK_EXPIRE_1HOUR: '1小时',
  CONTEXT_LINK_EXPIRE_6HOURS: '6小时',
  CONTEXT_LINK_EXPIRE_12HOURS: '12小时',
  CONTEXT_LINK_EXPIRE_1DAY: '1天',
  CONTEXT_LINK_EXPIRE_NEVER: '永不',
  CONTEXT_LINK_EXPIRE_CANCEL: '取消',
  CONTEXT_LINK_USES_1: '1次',
  CONTEXT_LINK_USES_2: '2次',
  CONTEXT_LINK_USES_5: '5次',
  CONTEXT_LINK_USES_10: '10次',
  CONTEXT_LINK_USES_20: '20次',
  CONTEXT_LINK_USES_50: '50次',
  CONTEXT_LINK_USES_NO_LIMIT: '没有限制',
  CONTEXT_LINK_USES_CANCEL: '取消',

  FINGERPRINT_TITLE: '欢迎使用forghetti',
  FINGERPRINT_DESCRIPTION: '扫描您的指纹解锁forghetti',
  FINGERPRINT_DESCRIPTION_FACE: '使用面部识别解锁forghetti',

  GENERATING_GROUP: '正在生成您的群组',
  GENERATING_PASSWORD: '正在生成您的密码',
  DELETING_GROUP: '正在删除群组',
  LOADING_SERVICE: '正在载入您的服务',
  UNABLE_TO_PURCHASE: '当前无法购买高级会员。请稍后再试。',

  HEALTHCHECK_WELCOME_HEADER: '数据安全健康检查',
  HEALTHCHECK_WELCOME_NOTE: '运行forghetti数据安全健康检查评估，并发现，是否您在网上的数据信息有被泄露和不安全。',
  HEALTHCHECK_WELCOME_BUTTON: '运行数据安全健康检查',
  HEALTHCHECK_USERNAMES_HEADER: `让我们先从您的电子邮件开始`,
  HEALTHCHECK_USERNAMES_INFO_1: '检查您的电子邮件地址',
  HEALTHCHECK_USERNAMES_INFO_2: '让我们知道您的电子邮件地址，我们会检查它针对任何新的或以前的网上黑客。',
  HEALTHCHECK_USERNAMES_PRIMARY_SUBHEAD: '您的常用电子邮件地址',
  HEALTHCHECK_USERNAMES_SECONDARY_SUBHEAD: '您的次要电子邮件地址',
  HEALTHCHECK_USERNAMES_PLACEHOLDER: '电子邮件地址',
  HEALTHCHECK_PASSWORD_HEADER: `让我们来检测您的密码`,
  HEALTHCHECK_PASSWORD_INFO_1: '经常使用相同的密码吗？',
  HEALTHCHECK_PASSWORD_INFO_2: `使用forghetti之前，你有没有经常使用相同的密码？
让我们来检测是否已有数据泄漏。`,
  HEALTHCHECK_PASSWORD_INFO_3:
    '这个检测是可选项。Forgetti 不会存储或转移您的密码。我们将同时生成安全的随机代码。 只有部分随机代码会被此程序移动，所以可以相信您的密码在我们这里很安全。',

  HEALTHCHECK_PASSWORD_SUBHEAD: '您的密码',
  HEALTHCHECK_PASSWORD_OPTIONAL: '这是可选的。',
  HEALTHCHECK_PASSWORD_PLACEHOLDER: '密码',
  HEALTHCHECK_PASSWORD_VAL_TOO_LONG: '密码太长',

  HEALTHCHECK_RUNNING: '运行您的数据安全健康检查',
  HEALTHCHECK_USERNAME_VAL_FIELD_BLANK: '电子邮件地址不能为空',
  HEALTHCHECK_USERNAME_VAL_FIELD_DUPLICATE: '次要电子邮件地址不能和第一相同',
  HEALTHCHECK_USERNAME_VAL_FIELD_TOO_SHORT: '电子邮件地址太短',
  HEALTHCHECK_USERNAME_VAL_FIELD_TOO_LONG: '电子邮件地址太长',

  HEALTHCHECK_ASSESSMENT_HEADER: '数据安全健康检查',
  HEALTHCHECK_ASSESSMENT_EMAIL: (email) => `<em>被泄漏的${email} </em>`,
  HEALTHCHECK_ASSESSMENT_INFO_1: '您的安全健康检查的结果显示如下。',
  HEALTHCHECK_ASSESSMENT_INFO_2: '高级用户可以点击安全泄漏，以了解更多信息。',

  HEALTHCHECK_ASSESSMENT_PASSWORD: '<em>您密码的结果</em>',
  HEALTHCHECK_ASSESSMENT_COMPROMISED_HEAD: `受损的服务`,
  HEALTHCHECK_ASSESSMENT_NO_BREACH: '此帐号没有出现安全泄漏的服务',
  HEALTHCHECK_ASSESSMENT_PASSWORD_STRENGTH: '强度',
  HEALTHCHECK_ASSESSMENT_PASSWORD_WEAK: '弱',
  HEALTHCHECK_ASSESSMENT_PASSWORD_STRONG: '强',
  HEALTHCHECK_ASSESSMENT_PASSWORD_COMP: '出现数据泄露',
  HEALTHCHECK_ASSESSMENT_HIBP_LINK: '源：<a data-actionclick="openHIBP"> haveIbeenpwned </a>',
  HEALTHCHECK_ASSESMENT_UPGRADE_MESSAGE: '泄漏细节只提供给高级会员',
  HEALTHCHECK_DETAILS_PASSWORD_HEADER: '密码结果',
  HEALTHCHECK_DETAILS_USER_HEADER: `密码泄漏的细节`,
  HEALTHCHECK_DETAILS_USER_TITLE: '名称',
  HEALTHCHECK_DETAILS_USER_DOMAIN: '域',
  HEALTHCHECK_DETAILS_USER_DATE: '泄漏日期',
  HEALTHCHECK_DETAILS_USER_ACCOUNTS_PWNED: '账户被泄漏',
  HEALTHCHECK_DETAILS_PASSWORD_INFO_X_BREACH: (breach) =>
    `您的密码已被列入的<em> ${breach}</ em>数据可能已被泄露名單。但这并不一定意味着您的账号已被泄露。您应该尽快考虑使用<em> forghetti </ em>生成超级安全的密码。`,
  HEALTHCHECK_DETAILS_PASSWORD_INFO_NO_BREACH: `好消息！您的密码没有任何数据泄露。这并不能保证您的帐户还没有被攻破。考虑与<em> forghetti </em>生成一个新的超级安全的密码。`,
  HEALTHCHECK_DETAILS_PASSWORD_STRENGTH_INFO_STRONG: `您的密码是的<em>很安全的</ em>。这意味着它已通过由forghetti密码强度测试的所有标准`,
  HEALTHCHECK_DETAILS_PASSWORD_STRENGTH_INFO_WEAK: `您的密码安全是<em>弱</ em>的。这意味着它没有通过如下forghetti密码强度测试标准：`,
  HEALTHCHECK_DETAILS_RESET_NOTE: '现在由forghetti生成超强的密码',
  HEALTHCHECK_DETAILS_RESET_BUTTON: '创建一个新的服务',
  HEALTHCHECK_DETAILS_HIBP_LINK: '来源：haveIbeenpwned',
  HEALTHCHECK_LOADING_DETAIL: '加载泄漏细节',

  IMPORT_SERVICE_WELCOME_BUTTON: '开始汇入服务',
  IMPORT_SERVICE_WELCOME_INFO: '汇入现有的登陆信息到forghetti',
  IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_1: '请打开forghetti桌面应用程序汇入服务。',
  IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_2: '如果您没有forghetti桌面应用程序，请单击下面的链接',
  IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_BUTTON: '下载forghetti',
  IMPORT_SERVICES_LIST_HEADER: '选择服务汇入',
  IMPORT_SERVICES_LIST_ERROR_NONE_SELECTED: '没有选择汇入服务',
  IMPORT_SERVICES_YOUR_IMPORTED_SERVICES: '汇入的服务',
  IMPORT_SERVICES_LIST_NOTE: `选择要汇入的服务到forghetti。`,
  IMPORT_SERVICES_GROUP_NOTE: '选择一个群组为您的服务',
  IMPORT_SERVICES_GROUP_HEADER: '选择组',
  IMPORT_SERVICES_USERNAME_HEADER: '输入用户名',
  IMPORT_SERVICES_USERNAME_NOTE: '检查每个帐户相关联的用户名',
  IMPORT_SERVICES_CREATE_HEADER: '创建服务',
  IMPORT_SERVICES_CREATE_BUTTON: '开始创建服务',
  IMPORT_SERVICES_CREATE_HOW_MANY: (number) => `<em> ${number}</em>创造服务`,
  IMPORT_SERVICES_CREATE_LOADING: (current, total) => `导入 <em>${current}</em> of <em> ${total}</em> 服务`,
  IMPORT_SERVICES_COMPLETE_HEADER: '汇入完成',
  IMPORT_SERVICES_COMPLETE_NOTE: `恭喜！您的服务已汇入`,
  IMPORT_FOLDER_SELECT_HEADER: '从Chrome中汇入',
  IMPORT_FOLDER_SELECT_CHROME: '从Chrome中汇入服务',
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC: `forghetti将需要访问存储在您裝置上的登录数据。`,
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_2: `当你准备好请点击下面的“选择您的chrome文件夹”按钮。一个对话框会弹出在您的Chrome登录数据的位置。只需选择“打开”来允许forghetti访问这个文件夹。`,
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_3: '如果您想手动定位该文件夹，只要到图书管 /应用程序支持/Google/Chrome',
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_4: '记住，您的现有密码不会被forghetti解密。 forghetti只找回您的登录数据。',
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_5: '一旦您的登录数据可读您可以选择想汇入的服务到forghetti。',

  IMPORT_FOLDER_SELECT_CHROME_BUTTON: '选择您的Chrome文件夹',

  IMPORT_GROUP_HEADER: '您已汇入的服务组',
  IMPORT_GROUP_NOTE: (importingServices) =>
    `一个特别的群组<em>汇入服务</ em>已自动为您创建。该群组将包含<em> ${importingServices}</em>你选择的导汇入服务。`,
  IMPORT_GROUP_NOTE_2:
    '该群组中的服务没有开启。要启用服务，您需要将服务移动到其他群组。点击继续将带您到服务向导。服务向导将快速和方便您的移动汇入服务。 ',
  IMPORT_GROUP_NOTE_3:
    '如果您现在没有时间将所有的服务汇入，不用担心。您可以随时通过進入服务向导，移动您的服务到任何设备上。',
  IMPORT_GROUP_BUTTON: '继续',

  IMPORT_SERVICE_IMPORT_NODATA_HEADER: '无法找到登录数据',
  IMPORT_SERVICE_IMPORT_NODATA_NOTE: 'forghetti没有发现任何可以汇入登录数据',

  IMPORT_MOVE_SERVICE_HEADER: '开始移动您的服务',
  IMPORT_MOVE_SERVICE_NOTE_1: (services) =>
    `您有${services}在您的群组。要开始激活这些服务，您只需要将它们移动到其他群组。`,
  IMPORT_MOVE_SERVICE_BUTTON: '继续',
  IMPORT_SERVICE_LOADING: '正在汇入服务',
  IMPORT_SERVICE_GROUP_NAME: '汇入服务',
  NEW_GROUP_REQUIRES_PREMIUM: '创建新群组需要成为高级会员。',

  IMPORT_TYPE_HEADER: '选择汇入类型',
  IMPORT_TYPE_NOTE_1: `Forghetti可以自动从您的谷歌浏览器的登录数据汇入服务，如果你希望汇入服务请单击下面的“汇入谷歌浏览器”按钮。`,
  IMPORT_TYPE_BUTTON_1: `汇入谷歌浏览器`,
  IMPORT_TYPE_NOTE_2: `另外，您也可以通过上传CSV或Excel（XLSX）文件汇入您的登录信息。要开始汇入使用此功能请单击下面的“上传文件”按钮服务。`,
  IMPORT_TYPE_BUTTON_2: '上传文件',
  IMPORT_TYPE_NOTE_3: `如果您主要使用Safari浏览器，您可能需要使用上传文件功能汇入您的服务。要找到您的Safari 密码钥匙串，要在Safari浏览器的“设置 Preferences” (command + ',')，然后到‘密码’标签中找到自己的Safari密码钥匙串。`,

  IMPORT_UPLOAD_HEADER: '上传文件',
  IMPORT_UPLOAD_NOTE_1: '上传CSV或电子表格（XLSX）并开始汇入服务。最起码您需要的是一个服务的名称。',
  IMPORT_UPLOAD_BUTTON: '上传您的文件',
  IMPORT_UPLOAD_NOTE_2: '下载CSV或Excel示例以便开始。',
  IMPORT_UPLOAD_SAMPLE_CSV: '下载CSV示例',
  IMPORT_UPLOAD_SAMPLE_XLS: '下载Excel示例',

  // ================================================ ===========================
  //帮助文本
  // ================================================ ===========================

  //NO_LOGINS_MESSAGE: `要开始，点击“添加登录”在右上角添加您使用的服务登录（例如亚马逊，或中国银行）。

  NO_LOGINS_MESSAGE: `添加服务到该组，请单击 +（加号）图标

与朋友、同事或家人分享此群组，请通过点击共享图标（右上）

在群组的设置里，您可以更改该群组的名称和访问权限

`,

  LOGIN_RECENT: '最近的',
  HELP_LOGIN_TITLE:
    '为您的登录输入一个名称（例如亚马逊）。通过点击相机图标添加图标。如果您不选择，系統会为您创建一个。',
  HELP_LOGIN_USERNAME:
    '输入您用于登录的用户名，这可能是您的电子邮件地址，这将允许您复制并粘贴相关信息从您的登录摘要页。',
  HELP_LOGIN_WEBSITE: '也可以输入您登录的网站地址，这将允许您直接从登录摘要页打开网站。',
  HELP_LOGIN_ADD_FORGHETTIBLE: `在这里添加一个标准的密码（默认设置为复杂和长的，您可以不需要编辑这个密码），提示词或数字密码（PIN）。如果您想要更多的设置，添加自定义forghettible。

您必须添加至少一个forghettible以便继续。然后，只需点击下一步在屏幕的右上角。`,

  HELP_GROUPS_LIST_HEADER: `点击 + 号创建或加入一个群组。免费用户只能加入付费会员的一个群组。

您可以使用群组来管理不同类别登录或共享一个群组中所有登录。

记住，您将需要与所有成员共享群群组涂鸦，这样他们生成和您相同的密码，因此，可以考虑使用一个容易记起的单词，短语或代码。
`,

  HELP_GROUPS_LIST_PERSONAL: `这是您独一无二的个人小组。您无法将其删除或与其他人分享。`,
  HELP_GROUPS_LIST_OTHER_GROUPS: `这些群组是您已经作为一个高级用户所创建，或经他人被邀请加入`,

  HELP_DOODLEPAD: `画一个您可以记住的涂鸦。连接线，点或两者的组合。层数越多，就越安全。您需要记住它，因为这是能够现在和将来产生您的密码关键。

担心被窥探？轻按“神秘涂鸦”不留痕迹画涂鸦。画错，您可以通过点击“Redoodle”重新开始。然后按对勾生成密码。`,

  HELP_SERVICE_SUMMARY: `点击按眼睛图案查看密码。点击您的密码将其复制到剪贴板。转至所使用服务的网站，重置您的当前密码。

然后您就不需要记住它啦。下一次您需要它是，只需画涂鸦，forghetti将再次生成这个密码。此密码不保存任何地方，每次画涂鸦时临时生成。
`,

  HELP_FIELD_SETTINGS_LENGTH: `选择forghettible的长度， 比如 - 用于标准的密码或PIN的长度  - 或字的数目（如果使用提示词）。`,
  HELP_FIELD_SETTINGS_TYPE: `选择如字母，符号，数字和英文单词的组合作为forghettible类型。`,
  HELP_FIELD_SETTINGS_NAME: `为您forghettible密码输入一个描述性的名称。请注意，这不是密码本身，因为这是forghetti自动生成的。`,
  HELP_FIELD_SETTINGS_RESET: `如果您需要更改forghetti生成的密码，只需点击重置，下一次您画的涂鸦将生成的新的密码。`,
  HELP_RESTORE_PAGE: `对于旧版本forghetti密码的设置，可以通过点击日期来恢复。

如果你这样做，那么当您画卞出涂鸦后，旧密码将会产生。

您可以根据需要来回切换！
`,
  HELP_LOGINS_LIST: `点击登录即可生成密码。

使用搜索工具找到您需要的登录。
`,
  HELP_GROUP_PAGE: `滚动至底部去删除群组，切换到查看该群组的登录，或离开您不再想成为成员的群组。`,
  HELP_GROUP_SHARING: `邀请一个朋友或同事共享。`,
  HELP_GROUP_MEMBERS: `管理用户组的权限，使他们成为管理用户，或者完全删除他们。`,
  HELP_GROUP_INVITE_MAX_USES: `先控制多长时间和多少次他人可以使用您的邀请，再发送出去给朋友和同事共享。`,

  // ================================================ ===========================
  //电子邮件
  // ================================================ ===========================

  REFERRAL_EMAIL_SUBJECT: '邀请使用forghetti，因为没有什么比使用它更安全的',
  REFERRAL_EMAIL_BODY: (url) =>
    `嗨，

我使用了一个名为forghetti的伟大应用程序。它帮助我记住所有的密码，一辈子哦！而且您的密码不会被存储在任何地方。

通过画涂鸦来生成您的密码。画同样的涂鸦，每次您会得到相同的密码。如此简单，又如此聪明。

使用此推介链接注册你我同时获得更多的功能！
${url}

玩得开心！`,

  // ================================================ ===========================

  GRP_INVITE_EMAIL_SUBJECT: 'forghetti的群组邀请',
  GRP_INVITE_EMAIL_BODY: (inviteUrl, referralUrl, referralCode) =>
    `您好，我想与大家分享我的forghett群i组。请点击以下链接，打开Forghetti并加入我的群組：
${inviteUrl}

如果您没有forghetti，请添加此推推广代码${referralCode} 到您的应用程序中的个人资料，并获得更多使用时间！永远忘记您的密码！`,

  APPRATE_TITLE: '给forghetti点个赞及个回馈',
  APPRATE_MESSAGE: `这不会超过一分钟，有助于改进我们的应用程序。谢谢您的支持`,
  APPRATE_CANCEL: '不用了，谢谢',
  APPRATE_LATER: '稍后提醒我',
  APPRATE_RATE: '给forghetti打分儿',
  APPRATE_YES: '是',
  APPRATE_NO: '并不是的',
  APPRATE_PROMPT_TITLE: '您喜欢使用forghetti吗',
  APPRATE_FEEDBACK: '介意给我们一些建议吗？',

  BADGE_HEALTHCHECK_HEADER: '运行数据安全健康检查',
  BADGE_HEALTHCHECK_INFO: '通过完成forghetti用户名和密码，赢取数据安全健康检查徽章',
  BADGE_HEALTHCHECK_COMPLETE_INFO: '恭喜！您已经通过完成forghetti数据安全健康检查获得了这个徽章',
  BADGE_HEALTHCHECK_REWARD: (premium) => (premium ? '为您的个人小组获得2个额外的服务' : ''),
  BADGE_HEALTHCHECK_CTA: '现在运行数据安全健康检查',

  BADGE_RATING_HEADER: '给forghetti打分',
  BADGE_RATING_INFO: '为forghetti打分来赢取徽章',
  BADGE_RATING_COMPLETE_INFO: '恭喜！您赢得了这个徽章因为您为forghetti打了分',
  BADGE_RATING_REWARD: (premium) => (premium ? '为您的个人组获得2个额外的服务' : ''),
  BADGE_RATING_CTA: '现在给forghetti打分',

  BADGE_PROFILECOMPLETE_HEADER: '填写您的个',
  BADGE_PROFILECOMPLETE_INFO: '填写您的forghetti个',
  BADGE_PROFILECOMPLETE__COMPLETE_INFO: '恭喜！通过填写您的forghetti个',
  BADGE_PROFILECOMPLETE_REWARD: (premium) => (premium ? '为您的个人小组获得2个额外的服务' : ''),
  BADGE_PROFILECOMPLETE_CTA: '现在填写您的个',

  BADGE_SHARING_HEADER: '在Facebook上共享forghetti',
  BADGE_SHARING_INFO: '给forghetti点赞并分享在您的社交网络可以赢取徽章',
  BADGE_SHARING_COMPLETE_INFO: '恭喜！您赢得这个徽章， 因为给forghetti点赞并分享在您的社交网络',
  BADGE_SHARING_REWARD: (premium) => (premium ? '为您的个人组获得2个额外的服务' : ''),
  BADGE_SHARING_CTA: '在Facebook上分享forghetti',

  BADGE_IMPORT_HEADER: '汇入服务',
  BADGE_IMPORT_INFO: '当您从您的手机或电脑秘密钥匙keychain 汇入服务时可以赢得这个徽章',
  BADGE_IMPORT_COMPLETE_INFO: '恭喜！您赢得了这个徽章，因为您从您的手机或电脑秘密钥匙keychain  汇入了服务',
  BADGE_IMPORT_REWARD: (premium) => (premium ? '为您的个人组获得2个额外的服务' : ''),
  BADGE_IMPORT_CTA: '现在开始汇入服务 ',

  BADGE_SUPERUSER_HEADER: '成为超级用户',
  BADGE_SUPERUSER_INFO: `当您成功与5个朋友共享forghetti，有两个以上的群组，有一个高级会员，并在您的forghetti帐户拥有超过50个服务，便可以获得这个徽章。`,
  BADGE_SUPERUSER_COMPLETE_INFO: `恭喜！您赢得了这个徽章：成功与5个朋友共享forghetti，有两个以上的群组，有一个高级会员，并在您的forghetti帐户拥有超过50个服务。`,
  BADGE_SUPERUSER_REWARD: (premium) => (premium ? '为您的个人小组获得2个额外的服务' : ''),
  BADGE_SUPERUSER_CTA: '与朋友分享forghetti',

  BADGE_SERVICES_50_HEADER: '50个服务！',
  BADGE_SERVICES_50_INFO: '赢得这个徽章，在您的forghetti帐户创建50个服务',
  BADGE_SERVICES_50_COMPLETE_INFO: '恭喜！您已经在您的forghetti帐户创造了超过50个服务赢得这个徽章',
  BADGE_SERVICES_50_REWARD: (premium) => (premium ? '为您的个人组获得2个额外的服务' : ''),
  BADGE_SERVICES_50_CTA: '现在创建服务',

  BADGE_SERVICES_150_HEADER: '150个服务！',
  BADGE_SERVICES_150_INFO: '赢得这个徽章，在您的forghetti帐户创建150个服务',
  BADGE_SERVICES_150_COMPLETE_INFO: '恭喜！您已经在您的forghetti帐户创造了超过150个服务赢得这个徽章',
  BADGE_SERVICES_150_REWARD: (premium) => (premium ? '为您的个人组获得2个额外的服务' : ''),
  BADGE_SERVICES_150_CTA: '现在创建服务',

  BADGE_SERVICES_300_HEADER: '300个服务！',
  BADGE_SERVICES_300_INFO: '赢得这个徽章，在您的forghetti帐户创建300个服务',
  BADGE_SERVICES_300_COMPLETE_INFO: '恭喜！您已经在您的forghetti帐户创造了超过300个服务赢得这个徽章',
  BADGE_SERVICES_300_REWARD: (premium) => (premium ? '为您的个人组获得2个额外的服务' : ''),
  BADGE_SERVICES_300_CTA: '现在创建服务',

  BADGE_SERVICES_500_HEADER: '500个服务！',
  BADGE_SERVICES_500_INFO: '赢得这个徽章，在您的forghetti帐户创建500个服务',
  BADGE_SERVICES_500_COMPLETE_INFO: '恭喜！您已经在您的forghetti帐户创造了超过500个服务赢得这个徽章',
  BADGE_SERVICES_500_REWARD: (premium) => (premium ? '为您的个人组获得2个额外的服务' : ''),
  BADGE_SERVICES_500_CTA: '现在创建服务',

  BADGE_SHARING_1_HEADER: '与朋友分享forghetti',
  BADGE_SHARING_1_INFO: '当您推荐给朋友forghetti后他们成功创建一个帐户，便會获得这个徽章！',
  BADGE_SHARING_1_COMPLETE_INFO: '恭喜！你赢得了这个徽章，您推荐给朋友forghetti后他们成功地创建了一个帐户！',
  BADGE_SHARING_1_REWARD: (premium) => (premium ? '为您的个人组获得2个额外的服务' : ''),
  BADGE_SHARING_1_CTA: '与朋友分享forghetti',

  BADGE_SHARING_5_HEADER: '与5个朋友分享forghetti',
  BADGE_SHARING_5_INFO: '当您推荐给5个朋友forghetti后他们成功创建一个帐户，便會获得这个徽章！',
  BADGE_SHARING_5_COMPLETE_INFO: '恭喜！你赢得了这个徽章，您推荐给的5个朋友forghetti后他们成功地创建了一个帐户！',
  BADGE_SHARING_5_REWARD: (premium) => (premium ? '为您的个人组获得2个额外的服务' : ''),
  BADGE_SHARING_5_CTA: '与朋友分享forghetti',

  BADGE_SHARING_10_HEADER: '与10个朋友分享forghetti',
  BADGE_SHARING_10_INFO: '当您推荐给10个朋友forghetti后他们成功创建一个帐户，便會获得这个徽章！',
  BADGE_SHARING_10_COMPLETE_INFO: '恭喜！你赢得了这个徽章，您推荐给的10个朋友forghetti后他们成功地创建了一个帐户！',
  BADGE_SHARING_10_REWARD: (premium) => (premium ? '为您的个人组获得2个额外的服务' : ''),
  BADGE_SHARING_10_CTA: '与朋友分享forghetti',

  BADGE_SHARING_25_HEADER: '与25个朋友分享forghetti',
  BADGE_SHARING_25_INFO: '当您推荐给25个朋友forghetti后他们成功创建一个帐户，便會获得这个徽章！',
  BADGE_SHARING_25_COMPLETE_INFO: '恭喜！你赢得了这个徽章，您推荐的25个朋友forghetti后他们成功地创建了一个帐户！',
  BADGE_SHARING_25_REWARD: (premium) => (premium ? '为您的个人组获得2个额外的服务' : ''),
  BADGE_SHARING_25_CTA: '与朋友分享forghetti',

  BADGE_SHARING_50_HEADER: '与50个朋友分享forghetti',
  BADGE_SHARING_50_INFO: '当您推荐给50个朋友forghetti后他们成功创建一个帐户，便會获得这个徽章，！',
  BADGE_SHARING_50_COMPLETE_INFO: '恭喜！你赢得了这个徽章，您推荐的50个朋友forghetti后他们成功地创建了一个帐户！',
  BADGE_SHARING_50_REWARD: (premium) => (premium ? '为您的个人组获得2个额外的服务' : ''),
  BADGE_SHARING_50_CTA: '与朋友分享forghetti',

  BADGE_GROUP_1_HEADER: '创建一个forghetti新群组',
  BADGE_GROUP_1_INFO: '当您使用forghetti创建和共享一个新的群组，便會获得这个徽章。',
  BADGE_GROUP_1_COMPLETE_INFO: '恭喜！你赢得了这个徽章，因为您使用forghetti创建和共享一个新的群组',
  BADGE_GROUP_1_REWARD: (premium) => (premium ? '为您的个人组获得2个额外的服务' : ''),
  BADGE_GROUP_1_CTA: '现在创建一个群组',

  BADGE_GROUP_5_HEADER: '创建5个forghetti新群组',
  BADGE_GROUP_5_INFO: '获得这个徽章，当您使用forghetti创建和共享5个新的群组',
  BADGE_GROUP_5_COMPLETE_INFO: '恭喜！你赢得了这个徽章，因为您使用forghetti创建和共享5个新的群组',
  BADGE_GROUP_5_REWARD: (premium) => (premium ? '为您的个人组获得2个额外的服务' : ''),
  BADGE_GROUP_5_CTA: '现在创建一个群组',

  BADGE_LEGACY_HEADING: `您是一个超级巨星！`,
  BADGE_LEGACY_INFO_COMPLETE: `非常感谢您，作为一个长期forghetti用户。作为感谢的小礼物，您已经获得这个特殊的徽章和享受3个月的免费的高级会员版！`,

  JOIN_GORUP_ALERT_MESSAGE: `我们将您加入这个小组。当它完成时我们将通知您。`,
  GROUP_UNAVAILABLE: (groupName) =>
    `已经超过免费forghettibles的数量<em> ${groupName}</ em>。为了添加更多的服务今天升级吧。`,
  GROUP_PERSONAL_UNAVAILABLE: (groupName) => `已经超过您的免费forghettibles个人组数量。为了添加更多的服务今天升级吧。`,
  GROUP_NONE_AVAILABLE: '您所有的群组都已经超过了允许的数量',

  SHARE_INVITE_USERNAME_HEADING: '在您开始共享前',
  SHARE_INVITE_USERNAME_INFO: '为了让您的朋友可以识别您要共享群组，请向我们提供一个您的个人帐户名称',
  SHARE_INVITE_USERNAME_PLACEHOLDER: '您的名字',

  LEGACY_USER_ONBOARDING_HEADING: '我们重视您的忠诚度',
  LEGACY_USER_ONBOARDING_INFO:
    '作为回报我们送给您3个月高级会员(完全免费)！我们希望比起前一个版本，您更享受forghetti的新版本。',
  CLAIM_PREMIUM: '领取您的奖励',
  LEGACY_USER_RELOAD: '高级会员生效，需要重新加载forghetti。这将在几秒钟内自动发生。',

  SERVICE_OPEN_ICON_LABEL: '打开',
  SERVICE_VIEW_ICON_LABEL: '看',
  SERVICE_WEBSITE_ICON_LABEL: '打开',
  SERVICE_COPY_ICON_LABEL: '复制',

  GROUPSLIST_SWITCH_LABEL: '切换',
  GROUPSLIST_SHARE_LABEL: '邀请',
  GROUPSLIST_SETTINGS_LABEL: '设置',

  FORGHETTI_GROUP_PERMISSIONS: `点击此<a data-actionclick="openPermissionsLink">链接</a>更了解群组的权限。`,

  SUBSCRIPTION_TANDC: `请注意，您的订阅将自动更新，除非在当前期结束日期前取消。在App Store帐户的已购买设置中 - 您可以管理和取消订阅 - 这将关闭自动更新。欲了解更多信息，请访问服务<a data-actionclick="openTermsOfServiceUrl">服务条款</a> 和我们的 <a data-actionclick="openPrivacyPolicyUrl">隐私政策</a>  `,
  SUBSCRIPTION_TANDC_LIFETIME: `请注意，forghetti高级会员终身赋予您...欲了解更多信息，请访问我们的<a data-actionclick="openTermsOfServiceUrl">服务条款</a> 和我们的<a data-actionclick="openPrivacyPolicyUrl">隐私政策</a>`,
  SUBSCRIPTION_ERROR: '验证您的订阅时出错。请稍后再试。',

  SUBSCRIPTION_TANDC_HEADER: '您的订阅',
  SERVICE_INJECT_LABEL: '采用',
  PHONE_NUMBER_TOO_LONG: '无效的电话号码',

  ADD_SERVICE_LOADING_PLEASE_WAIT: '请等待，我们正在生成您的forghetti服务',
  ADD_SERVICE_LOADING_GENERATING_SERVICE: '正在生成您的新服务',
  ADD_SERVICE_LOADING_GENERATING_PASSWORD: '正在生成一个超级安全的密码',
  ADD_SERVICE_LOADING_LOADING_SERVICE_DETAILS: '正在载入您的新forghetti服务',

  VIEW_SERVICE_LOADING_LOADING_SERVICE_DETAIL: '请等待，我们正在加载您的服务',
  VIEW_SERVICE_LOADING_LOADING_SERVICE_FORGHETTIBLE: '建立您的forghettible数据',

  MIGRATION_ALERT_HEADING: '迁移您的帐户',
  MIGRATION_ALERT_MESSAGE: `改变您的手机号码，请前往我们的Web应用程序<a href="https://app.forghetti.com" target="_new">https://app.forghetti.com</a>。`,
  DELETE_ALERT_HEADING: '删除您的帐户',
  DELETE_ALERT_MESSAGE: `删除您的帐户，请前往我们的Web应用程序<a href="https://app.forghetti.com" target="_new"> https://app.forghetti.com </a>。`,
  REFERRAL_CODE_UPDATE_NOT_ALLOWED: `推荐代码已经应於您的帐户生效`,
  REFERRAL_CODE_APPLIED: (code) => `这个推荐代码<em> ${code}</em>已经於您的帐户生效。`,
  INVALID_PHONE_NUMBER: '手机号码无效。请再试一次。',
  INVALID_ACTIVATION_CODE: '激活码无效',
  INVALID_TOO_MANY_ATTEMPTS: `登录失败，这个号码已经使用太多次。请稍后再试。`,

  REONBOARDING_POPUP_GO_AGAIN: `我们改进了forghetti。您想熟悉一下forghetti强大的功能吗？`,
  REONBOARDING_POPUP_BUTTON_GO: `演示给我看看`,
  REONBOARDING_POPUP_BUTTON_CANCEL: '不用了，谢谢',
  REONBOARDING_POPUP_HEADING: `新的forghetti 2.0`,
  HELP_QUICKSTART: '快速入门',
  YOUR_BADGES: '您的徽章',
  GROUP_NOT_OWNER: '您只能在您所拥有的群组（您是群组的拥有者）间移动服务。',
  GROUP_NOT_OWNER_TITLE: '移动服务',
  VIEWSERVICE_NOT_COPIED_TO_CLIPBOARD: `<span class="toast--title">您将需要您的新密码。</span><span class="toast--text">单击密码字段中复制按钮，将您的新密码添加到剪贴板。</span>`,
  EXTENSION_ATTEMPT: (website) =>
    `<strong class="toast--title">搜索登录信息 </strong><span class="toast--clipboard-item">${website}</span>`,
  EXTENSION_ATTEMPT_FAIL: (website) => `<span class="toast--title">未找到任何匹配的登录</span>`,

  UNIVERSAL_LINK_NEW_LOGIN: (url) => `尝试找寻匹配的服务${url}`,
  UNIVERSAL_LINK_NEW_LOGIN_NO_URL: `推出新的服务向导`,

  CONFIRM_COUNTRY_UPDATE_HEADER: '更改您的语言',
  CONFIRM_BUTTON: '重新加载forghetti',

  FORGHETTI_LOGIN: 'forghetti',
  FORGHETTI_LOGIN_TAGLINE: '永远忘记您的密码。',
  FORGHETTI_LOGIN_CREATE: '创建一个帐户',
  FORGHETTI_LOGIN_ALREADY_HAVE: '我已经有一个帐户',

  MUST_BE_LOGGED_IN_ACTION: '只有登录时可用',
  UPDATING_FORGHETTIBLE: '更新您的forghettible',
  FORGHETTI_ON_MAC: `forghetti桌面程序适用于Mac。 <a data-actionclick="openForghettiCom">点击这里。</a>`,
  FORGHETTI_ON_WINDOWS: `forghetti桌面程序适用于Windows。 <a data-actionclick="openForghettiCom">点击这里。</a>`,
  REALTIME_NEW_SERVICE_FOR_GROUP: (group) => `一个新的服务被添加到这个共享组${group}`,
  REALTIME_REMOVE_SERVICE_FOR_GROUP: (group) => `一个服务从这个共享组${group}删除`,
  REALTIME_KICK_SERVICE_FOR_GROUP: (group) => `您从这个组${group}被踢出`,
  REALTIME_BAN_SERVICE_FOR_GROUP: (group) => `您从这个组${group}被禁止`,
  REALTIME_JOIN_SERVICE_FOR_GROUP: (group) => `一个新的用户加入了${group}`,
  REALTIME_REMOVE_GROUP: (group) => `该组${group}已被所有者删除`,
  PERSONAL_GROUP: '个人组',
  SHAREABLE_GROUP: '可以共享的组',
  IMPORT_GROUP: '汇入服务',
  IMPORT_MENU: '汇入服务',

  THEME_MODES: ['白天', '晚上'],

  MONTHS: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
  CSL_SUBSCRIPTION_HEADER: `高级会员服務已被激活`,
  CSL_SUBSCRIPTION_1: `感谢您激活forghetti高級会员服務。當本订阅到期时，您仍可以免费会员身份继续使用（沒有高级会员服務功能），或可以续订此高级会员服務。`,
  CSL_SUBSCRIPTION_2: `我们衷心希望您享受使用forghetti。`,
  LICENCE_KEY_INVALID: (partner) => `抱歉，你的申请未被接纳`,
  LICENCE_KEY_USED: (partner) => `抱歉，你的申请未被接纳。`,
  LICENCE_KEY_ALREADY_APPLIED: (partner) => `您已成功申请服务。感谢您使用此服务。`,

  AND: '和',
  NEW_GROUP: '新小组',
  LETS_START_SELECT: '让我们从选择其中一种或多种流行服务开始...',
  CREATE_SERVICES: '创建服务',
  CREATING_SERVICES: '我们现在正在生成您的服务s',
  MUST_SELECT_MORE_THAN_ONE_SERVICES: '请选择一项或多项服务',
  ADD_NOTE: '添加新注释',
  EDIT_NOTE: '编辑注释',
  NOTE: '笔记',
  NOTE_COPIED: '注意复制到剪贴板',
  DELETE_NOTE: '删除注释',

  FORGHETTI_LOGIN_TAGLINE: '忘记密码。',
  FORGHETTI_LOGIN_TAGLINE_2: '永远。',
  CREATE_ACCOUNT_1_HEADER_2: '创建您的Boodhetti帐户。',

  CREATE_ACCOUNT_2_HEADING: '输入您的验证代码。',
  CREATE_ACCOUNT_FINAL_BUTTON: '告诉我如何',

  ONBOARDING_DOODLETUTORIAL2_HEADING_2: '画一个图案',
  ONBOARDING_DOODLETUTORIAL3_HEADING_2: '多行',
  ONBOARDING_DOODLETUTORIAL4_HEADING_2: '点也...',
  ONBOARDING_DOODLETUTORIAL5_HEADING_2: '或一起',
  ONBOARDING_DOODLETUTORIAL6_HEADING_2: '但是请记住，订单很重要！',
  ONBOARDING_DOODLETUTORIAL7_HEADING_2: '涂鸦A，',
  ONBOARDING_DOODLETUTORIAL8_HEADING_2: 'Doodle A，与Doodle B不同',
  SECURE_DOODLE_TUTORIAL: '安全涂鸦教程',
  DOODLE_TRY_AGAIN: '再试一次',
  WELCOME_TO_FORGHETTI: '欢迎来到宽恕',

  ONBOARDING_COMPLETE_CONGRATULATIONS: `恭喜！您只是采取了第一步来确保您的在线身份。`,

  ONBOARDING_COMPLETE_CREATE_FORGHETTI: '创建一个宽恕帐户。',
  ONBOARDING_COMPLETE_SIGNATURE_DOODLE: '创建您的签名涂鸦。',

  ONBOARDING_COMPLETE_RESET_PASSWORD: '重置一个不安全的密码。',

  ONBOARDING_COMPLETE_HEALTHCHECK: '在您的在线帐户上运行健康检查。',

  ONBOARDING_COMPLETE_IMPORT: '导入服务',
  ONBOARDING_COMPLETE_IMPORT_BUTTON: '导入服务',
  ONBOARDING_COMPLETE_REMIND_LATER: '稍后提醒我',
  ONBOARDING_COMPLETE_HEALTHCHECK_BUTTON: '运行健康检查',

  USERNAME_OR_EMAIL: '用户名',
  USERNAME_OR_EMAIL_SECOND: '电子邮件',
  SERVICE_EDIT_USERNAME_PLACEHOLDER: '输入任何其他登录详细信息',

  SERVICE_USERNAME_LABEL: '用户名',
  SERVICE_USERNAME_SECOND_LABEL: '电子邮件',

  ADD_SERVICE_USERNAME_PLACEHOLDER: `用户名`,
  SERVICE_USERNAME_PLACEHOLDER_SECOND: `电子邮件`,
  FREE_USER_UPGRADE: '升级到高级',
  LOGIN_ACCOUNT_1_HEADER_2: '登录到您的bonghetti帐户.',
  KEYCHAIN_HEADING: '保存你的涂鸦',
  KEYCHAIN_MESSAGE: '您可以将涂鸦牢固地保存到此设备中，以使生成密码更加容易。',
  KEYCHAIN_MESSAGE_2: '您想把涂鸦保存到此设备上吗？',
  KEYCHAIN_MESSAGE_3: '您可以随时在设置中进行更改。',
  KEYCHAIN_YES: '是的，保存我的涂鸦',
  KEYCHAIN_NO: '不，我每次都会画涂鸦',

  LOADING_VAULT: '加载保管库',
  ONBOARDING_COMPLETE_FIRST_LOGIN: '添加服务。',
  ONBOARDING_COMPLETE_CONTINUE: `现在重置密码`,
  STILL_LOADING: '我们正在加载您的帐户',
  STILL_LOADING_2: '您的帐户已验证',

  CREATE_ACCOUNT_VERIFYING_PHONE: '我们正在验证您的电话号码',
  NOTIFICATION_MESSAGE_BAR: `单击服务上的“使用”，将密码发送到{{service}}`,
  NO_NOTIFICATIONS_HELLO_TEXT: (name) => `你好 ${name}！您还没有任何通知。`,
  NO_NOTIFICATIONS_CHECK: '请查看Reguarly以获取更新。',
  REPLAY_DOODLE_HEADING: '重播',
  FORGHETTIBLE: '宽恕',
  SERVICE_NO_NOTE: '没有笔记',
  SERVICE_NOTE: '笔记',
  SERVICE_BAD_NOTE: '要解锁此注释，请重新涂鸦',
  SERVICE_NAME: '服务名称',
  REPLAY_DOODLE: '重播涂鸦',
  ENABLE_BIOMETRICS: '启用生物识别技术',
  DISABLE_BIOMETRICS: '禁用生物识别技术',
  WEB_DELAY: '建立连接并加载您的详细信息。如果这需要太长，请检查您的连接。',
  WEB_WAITING: '仍在工作，请等待...',
  PROFILE_MIGRATE_2_INFO_2: '现在输入您在旧手机上收到的验证代码:',
  MIGRATE: '迁移',
  MONTHLY: '月',
  YEARLY: '年',
  UNLOCK_BIOMETRICS: '用生物识别技术解锁',
  UNLOCK_DESCRIPTION: '用生物识别学解锁您的bonghetti帐户',
  UNLOCK_SERVICE: (service) => `解锁 ${service}`,
  UNLOCK_SERVICE_DESCRIPTION: (service) => `解锁 ${service} 使用生物识别技术或重新涂鸦`,
  UNLOCK_FALLBACK: '使用涂鸦',
  UNLOCK_CANCEL: '取消',
  RATE_US: '评价我们',
  SETUP_NEW_SERVICE_MESSAGE: `让我们通过提供一些细节来设置您的新服务。`,
  LOGIN_ACCOUNT_1_MSG: `请输入您的手机号码以通过SMS接收一次性验证代码。`,
  LETS_START_SELECT_COUNT: (count) =>
    `让我们从选择这些流行服务中的这些开始...您可以在免费层上选择<em data-count>${count}</em>`,
  TOAST_TOO_MANY_SERVICES_SELECTED: (count) => `您选择了太多服务。请选择最多的 ${count} 服务。`,
  LETS_START_ADDING_DETAILS: `让我们添加更多细节`,
  ONBOARDING_COMPLETE_NEXT_STEPS: `<h2>现在为什么不</h2><ul><li>对您的在线帐户进行健康检查？ </li><li>或更新formhetti上的另一个服务。</li><li>或者只是环顾四周...</li></ul>`,
  ONBOARDING_COMPLETE_CONGRATULATIONS: `恭喜！您只是采取了第一步来确保您的在线身份。`,
  CREATE_ACCOUNT_1_MSG_4: `<b>注意：</bold>我们只会使用您的电话号码来创建/支持您的formhetti帐户。您将收到一次性设备验证代码。`,
  MOVE_SERVICE_CONFIRM_NOTE_UPDATE: (fromGroup, toGroup, list, listLength) =>
    `新 <em>${list}</em> 新的宽容  将从 ${toGroup} 生成您的涂鸦。如果您想访问旧的宽容，只需绘制您的 ${fromGroup} doodle即可。`,
  YOUR_SUBSCRIPTION_PARTNER_PREMIUM_TEXT_1: (partner, link) =>
    `我们的合作伙伴在<em> ${partner} </em>为您提供宽恕。您可以在此处找到有关您的订阅的更多信息：<a href="${link}“target="_new"> ${link} </a>`,
  YOUR_SUBSCRIPTION_PARTNER_PREMIUM_TEXT_2: `我们希望您喜欢使用nomhetti.`,
  PARTNER_PREMIUM_HEADING: `恭喜!`,
  PARTNER_PREMIUM_MESSAGE: (partner) =>
    `作为Boomhetti合作伙伴，<em> ${partner} </em>成员获得了Premium nomhetti。您无需做任何事情，订阅将自动应用于您的帐户。为了更改，立即单击“重新加载formhetti”。`,
  PARNTER_PREMIUM_BUTTON: `重新加载宽恕`,

  CSL_SUBSCRIPTION_HEADER: `高级激活`,
  CSL_SUBSCRIPTION_1: `您已经激活了我们的高级服务。谢谢。当此订阅过期时，您可以作为免费用户继续执行（并失去所有优质利益）或续订订阅.`,
  CSL_SUBSCRIPTION_2: `我们希望您喜欢使用Formhetti。`,
  LICENCE_KEY_INVALID: (partner) => `抱歉，您的申请请求无效.`,
  LICENCE_KEY_USED: (partner) => ` 抱歉，您的申请请求无效。 `,
  LICENCE_KEY_ALREADY_APPLIED: (partner) => `您已成功订阅。感谢您使用此服务。`,

  SERVICE_EMAIL_LABEL: '电子邮件',
  SERVICE_EMAIL_PLACEHOLDER: 'user@email.com',
  SERVICE_EDIT_EMAIL_PLACEHOLDER: 'user@email.com',
  PROFILE_USE_BIOMETRICS: '用生物识别技术存储涂鸦',
  BIOMETRICS_GROUPS_HEADER: '启用生物识别技术',
  BIOMETRICS_SELECT_GROUP: '选择一个用生物识别技术存储涂鸦的组',
  BIOMETRICS: '生物识别技术',
  BIOMETRICS_ENABLE: '使能够',
  BIOMETRICS_DISABLE: '禁用',
  BIOMETRICS_SECURE: '使用生物识别技术固定',
  BIOMETRICS_SECURE_MESSAGE: (group) => `<em> ${group} </em>的涂鸦将使用生物识别牢固存储`,
  BIOMETRICS_USE_DOODLE: '使用涂鸦',
  BIOMETRICS_TRY_AGAIN: '再试一次',
  NOTES: '笔记',
  SELECT_SPECIAL_CHARACTERS: '选择包含哪些符号',
  WORDS: '单词<span class=“单拼”>示例</span>',
  LETTERS: '字母<span class=“ Monospace”>ABC</span>',
  NUMBERS: '数字<span class=“ Monospace”>123</span>',
  SYMBOLS: '符号<span class=“单拼”>！$％</span>',
  SELECT_PASSWORD_TYPE: (forghettibleName) => `${forghettibleName}选项`,
  SELECT_PASSWORD_NUMBERS: '总字母 /数字 /符号',
  SELECT_PASSWORD_WORDS: '总词',
  ALL: '全部',
  SIMPLE: '简单的',
  EDIT_PASSWORD_HEADING: '设置',
  SERVICE_FIELD_PASSWORD_NO_OPTIONS: '至少必须选择一个密码选项',
  SERVICE_FIELD_PASSWORD_NO_SYMBOLS_SELECTED: '至少必须选择一个符号',
  SUBSCRIPTION_ENDS: (days) => `您的订阅将在 ${days}天到期.`,
  SUBSCRIPTION_ENDS_TOMORROW: '您的订阅将于明天到期。',
  SUBSCRIPTION_ENDS_TODAY: '您的订阅将于今天到期。',
  NOTIFICATIONS: '通知',
  CONTEXT_CHANGE_TYPE: '更改选项',
  CONTEXT_CHANGE_LENGTH: '更改长度',
  CONTEXT_RENAME: '重命名宽恕',
  NEW_FORGHETTIBLE: '新的宽恕',
  OLD_FORGHETTIBLE: '旧的',
  DELETE_FIELD_PROMPT: '您确定要删除此字段吗？',
  ONBOARDING2_WELCOME_HEADING: '欢迎来到宽恕',
  ONBOARDING2_WELCOME_TEXT: '使用一个安全的涂鸦登录您用户用户使用的所有服务。',
  ONBOARDING2_DOODLE_HEADING: '创建您的签名涂鸦',
  ONBOARDING2_DOODLE_TEXT: '从线或点到随机弯曲或数字序列。您独特的组合会产生一个超强密码。',
  ONBOARDIN2_HOWITWORKS: '怎么运行的',
  ONBOARDING_CONFIRM_DOODLE: '确认你的涂鸦',
  ONBOARDING_BIOMETRICS_CONFIRM: '您想将其保存到钥匙扣吗？',
  SAVING_NOTE: '加密笔记',
  };
