const config = require('../../../config');
const strings = config.strings;

function listRow(text) {
  return {
    template: 'block-info-list-row',
    fields: { text },
  };
}

module.exports = ({ data, nav }) => ({
  template: 'block-section-page',

  fields: {
    id: 'delete-account',
    header: strings.DELETE_ACCOUNT_HEADER(),
    navbar: false,
    navigation_left: config.strings.CANCEL(),
    navigation_left_data: 0,
    navigation_left_template: 'back',
  },

  rows: [

    listRow(strings.DELETE_ACCOUNT_LINE_1()),
    listRow(strings.DELETE_ACCOUNT_LINE_2()),
    listRow(strings.DELETE_ACCOUNT_LINE_3()),
    listRow(strings.DELETE_ACCOUNT_LINE_4()),
    listRow(strings.DELETE_ACCOUNT_LINE_5()),
    // Gap
    {
      template: 'block-section-row',
      fields: {},
    },

    //
    {
      template: 'block-section-row',
      fields: {
        navigation_data: nav + 1,
        navigation_template: window.cordova ? null : 'migratePhoneNumber1',
        action : window.cordova ? 'migrateAccountToast' : null,
      },
      columns: [
        {
          template: 'block-text-button-left',
          fields: {
            text: strings.DELETE_ACCOUNT_CHANGE_INSTEAD(),
          },
        },
      ],
    },

    {
      template: 'block-section-row',
      fields: {
        class: 'centered-text',
      },
      columns: [
        {
          template: 'block-text-button',
          fields: {
            class: 'cta-button',
            class2: 'red',
            text: config.strings.DELETE_ACCOUNT_CONTINUE(),
            action: 'sendDeleteAccountConfirmationCode',
          },
        },
      ],
    },
  ],
});
