const showUpgradeTodayPopup = require('../../action_helpers/showUpgradeTodayPopup');
const showAlertMessage = require('../../action_helpers/showAlertMessage');

const config = require('../../../config');
const strings = config.strings;

module.exports = ({ currentTarget }) => {
  const groupId = $(currentTarget).attr('data-id') || window.state.selectedGroupId;
  const movingService = z2hApp.pageData.service.movingService;

  const group = window.state.groupsList.find(g => g.id === groupId);

  const message = group.personal ? strings.GROUP_PERSONAL_UNAVAILABLE()() : strings.GROUP_UNAVAILABLE()(group.name);

  if (movingService) {
    showAlertMessage(strings.GROUP_NOT_OWNER(), strings.GROUP_NOT_OWNER_TITLE());
  } else {
    showUpgradeTodayPopup(message);
  }

};
