const config = require('../../../config');
const onboardingPageTemplate = require('./createAccount_onboarding_template');

module.exports = ({ data, nav }) => {
  return onboardingPageTemplate(
    3,
    config.strings.ONBOARDING_HEADER_3(),
    config.strings.ONBOARDING_MESSAGE_3(),
    'img/onboarding/desktop/3.jpg',
    'createAccount_onboarding4',
    'createAccount_onboarding2',
    parseInt(nav) + 1,
    parseInt(nav) - 1,
  );
};
