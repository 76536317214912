const strings = require('../../../config').strings;
const { infoTextRow, ctaButton } = require('../../page_helpers/components');

module.exports = () => ({
  template: 'block-section-page',
  fields: {
    id: 'no-access-for-free-users',
    active: false,
    header: strings.EXTENSION_NOACCESS_HEADER(),

    navigation_left: strings.PROFILE_LINK_LOGOUT(),
    navigation_left_action: 'logout',
  },
  rows: [
    infoTextRow(strings.EXTENSION_UPGRADE(), true, false),
    ctaButton(strings.YOUR_SUBSCRIPTION_FREE_BUTTON_LABEL(), 'openUpgradeTodayUrl'),
  ],
});
