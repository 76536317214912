const _ = require('lodash');
module.exports = ({ z2hApp, menuTarget }) => {
  // Get ID of selected service
  let serviceId = $(menuTarget).closest('[data-id]').attr('data-id');

  let fromView = false;
  if (!serviceId) {
    serviceId = window.state.currentService.id;
    fromView = true;
  }
  window.state.selectedServiceId = serviceId;

  //TODO this bit is basically them same as editServiceFieldDetails and should be seperated out.
  window.state.currentService = window.state.currentServices.find((s) => s.id === serviceId);

  z2hApp.pageData = {};
  z2hApp.pageData.service = {};
  z2hApp.pageData.service = _.cloneDeep(window.state.currentService);

  //A little flag to say we are working with reset forghettible

  z2hApp.pageData.service.resetFromContext = true;
  z2hApp.pageData.service.fieldsSaved = [];
  z2hApp.pageData.service.fieldsUnsaved = [];
  z2hApp.pageData.service.fieldsToBeReset = [];
  z2hApp.pageData.service.fieldsToBeRestored = [];

  if (!window.state.currentService) {
    // Check if service exists in another group
    const groupsList = window.state.groupsList;
    for (let i = 0; i < groupsList.length; i++) {
      const groupId = groupsList[i].id;
      const services = groupsList[i].services || [];
      if (services.find && services.find((s) => s.id === serviceId)) {
        window.state.currentService = services.find((s) => s.id === serviceId);
        window.state.selectedGroupId = groupId;
        window.state.currentServices = services;

        if (window.z2hApp.highlightNavElement)
          window.z2hApp.highlightNavElement($('#main-nav').find('[data-id=' + groupId + ']'));

        break;
      }
    }
  }

  // Check for a background request which had been made to get the fields for this service
  window.serviceFieldPromises = window.serviceFieldPromises || {};
  window.serviceFieldPromises[serviceId] =
    window.serviceFieldPromises[serviceId] || z2hApp.requestData(`get`, '', `services/${serviceId}/fields`);

  window.serviceFieldPromises[serviceId].then((response) => {
    const fields = [];
    for (let fieldId in response.data) {
      fields.push({ ...response.data[fieldId], id: fieldId });
    }

    console.log('fields', fromView);
    if (fromView) {
      return z2hApp.paneNavigation('viewService', $('#pane-2'), 2);
    }
    if (fields.length === 1) {
      z2hApp.pageData.service.fields = fields[0];
      window.state.selectedFieldId = fields[0].id;
      z2hApp.pageData.service.fieldsArray = [fields[0].id];
      z2hApp.pageData.service.origFieldsArray = [fields[0].id];

      const field = { ...fields[0] };
      if (field.generation_settings) {
        for (const s in field.generation_settings) {
          delete field[s];
        }
      }
      z2hApp.pageData.service.fieldsSaved[window.state.selectedFieldId] = field;

      z2hApp.paneNavigation('editServiceFieldDetails', $('#pane-2'), 1);
    } else {
      z2hApp.paneNavigation('editServiceDetails', $('#pane-2'), 1);
    }
  });
};
