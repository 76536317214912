const z2hApp = require('../../app/z2hApp');

module.exports = ({ currentTarget, init = null }) => {
  const DOMTarget = $(currentTarget);

  if (DOMTarget.attr('data-selected') === 'true') {
    DOMTarget.attr('data-selected', false);
  } else {
    DOMTarget.attr('data-selected', true);
  }

  const count = [];

  const allPresets = $('[data-actionclick="selectPreset"]');

  allPresets.addClass('exists');

  const selected = $('[data-action="confirmSelectPresets"]')
    .find('[data-selected]')
    .each((i, elem) => {
      if ($(elem).attr('data-selected') === 'true') {
        $(elem).removeClass('exists');
        count.push(elem);
      }
    });

  if (15 - count.length >= 0) {
    allPresets.removeClass('exists');
  }
  $('em[data-count]').text(15 - count.length);

  if (count.length > 15) {
    $('[data-actionclick="createServicesBatch"]').fadeOut();
  } else {
    if ($('[data-actionclick="createServicesBatch"]').is(':hidden')) {
      $('[data-actionclick="createServicesBatch"]').fadeIn();
    }
  }

  console.log('count', count.length);
};
