const showResponseErrorMessage = require('../../action_helpers/showResponseErrorMessage');
const overlays = require('../../app/overlays');

let lastResultsQuery = null;
let currentQuery = null;

// =================================================================================================
// This action will grab the search query from the nearest #service-icon-search field. It will then
// append the search results to the page/section

// The action to perform when one of these icons is clicked comes from window.state.webIconSearchAction

function webSearchForServiceIcons({ currentTarget }) {
  const section = $(currentTarget).closest('.section-pane');
  let query = $('#service-icon-search').val();
  if (!query) return;
  query += ' favicon';
  return imageSearch(query, section);
}

// =================================================================================================

function lookBusy(section) {
  const lochyLoadingText = require('../../page_helpers/paneBusyText');
  overlays.makePaneLookBusy(2, { text: lochyLoadingText['searchForServiceIcon']() });

  section.find('.loading-icon-row').removeClass('hidden');
}

// =================================================================================================

function lookIdle(section) {
  overlays.makePaneLookIdle(2);
  section.find('.loading-icon-row').addClass('hidden');
}

// =================================================================================================

function imageSearch(query, section, overlay = true) {
  if (overlay) {
    lookBusy(section);
    section.find('.search-result-row').remove();
  }

  currentQuery = query;

  const numImages = 16;
  const numImagesPerRow = 4;
  const queryString = `?query=${encodeURIComponent(query)}&limit=${numImages}`;

  z2hApp
    .requestData('get', '', 'search-image' + queryString)
    .then(response => {
      // Leave now if the user has started a new search since we started this one
      if (currentQuery !== query) return;

      lookIdle(section);

      // Leave now if we've already displayed results from the same query
      if (lastResultsQuery === query && section.find('.search-result-row').length > 0) {
        return;
      }

      const data = response.data || [];
      const thumbnailUrls = data.map(result => (result.thumbnail && result.thumbnail.url) || null);

      // Delete rows from before
      section.find('.search-result-row').remove();

      // Split into batches of 'numImagesPerRow'
      for (let i = 0; i < Math.ceil(thumbnailUrls.length / numImagesPerRow); i++) {
        const iconsForRow = thumbnailUrls.slice(i * numImagesPerRow, (i + 1) * numImagesPerRow);
        const row = generateRow(iconsForRow);
        row.addClass('search-result-row');
        section.find('.table-view').append(row);
      }
      lastResultsQuery = query;
    })
    .catch(err => {
      lookIdle(section);
      showResponseErrorMessage(err);
    });
}

// =================================================================================================

const serviceRow = iconUrlsForRow => {
  // Depending on whether we are displaying icons to be used for a SERVICE or icons to be used
  // for a GROUP, we assign a different action to occur when an icon is clicked
  const action = window.state.webIconSearchAction || 'chooseServiceIcon';

  return {
    template: 'block-section-row',
    fields: { class: 'centered-text', class2: 'no-border' },
    columns: iconUrlsForRow.map(url => ({
      template: 'block-login-avatar',
      fields: {
        avatar: url,
        data_id: '',
        label: '',
        action,
      },
    })),
  };
};

function generateRow(urls) {
  const rowFromTemplate = z2hApp.constructRow(serviceRow(urls));
  return rowFromTemplate;
}

module.exports = webSearchForServiceIcons;
