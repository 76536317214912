import { active } from 'domain';

const showToastMessage = require('../../action_helpers/showToastMessage');
const strings = require('../../../config').strings;
const { DEFAULT_SERVICE_ICON } = require('../../../config').app;

module.exports = async ({ currentTarget, z2hApp }) => {
  // Get doodle that user drew
  //const doodle = (window.currentDoodlepad.getShape() || {}).shape;

  let doodle;
  let doodleProm;

  if (!window.state.biometrics) {
    doodleProm = new Promise((resolve, reject) => {
      const shape = window.currentDoodlepad.getShape().shape;
      return resolve(shape);
    });
  } else {
    doodleProm = new Promise((resolve, reject) => {
      if (process.env.CORDOVA) {
        if (window.ss) {
          window.ss.get(
            (value) => {
              console.log('Stored doodle');
              return resolve(value);
            },
            () => {
              console.log('Failed to store doodle');
              return resolve(null);
            },
            window.state.selectedGroupId + '_doodle',
          );
        }
      } else {
        return resolve(null);
      }
    });
  }
  doodle = await doodleProm;
  let error = false;

  // Check that code is not empty
  if (!doodle) {
    showToastMessage(strings.ERROR_NO_DOODLE());
    error = true;
  }
  //window.state.movingService.from.service.doodle = doodle;

  z2hApp.pageData.service.groupId = window.state.selectedGroupId;
  const serviceId = window.state.movingService.from.service.id;

  const paneDetails = window.getActivePaneDetails('#pane-2');
  if (error) {
    const paneDetails = window.getActivePaneDetails(selector);

    const { biometricsOrDoodle } = require('../../actions/services');
    biometricsOrDoodle({
      currentTarget: null,
      z2hApp,
      pane: paneDetails.pane,
      newPaneIndex: paneDetails.activePaneIndex - 1,
      template: { biometrics: 'addService_biometrics', doodlepad: 'addService_doodle' },
    });
  } else {
    window.serviceFieldPromises[serviceId].then((response) => {
      z2hApp.paneNavigation('moveService_confirm', paneDetails.pane, paneDetails.activePaneIndex + 1);
    });
  }
};
