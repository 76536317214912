const config = require('../../../config');
const onboardingPageTemplate = require('./createAccount_onboarding_template');

module.exports = ({ data, nav }) => {
  return onboardingPageTemplate(
    2,
    config.strings.ONBOARDING_HEADER_2(),
    config.strings.ONBOARDING_MESSAGE_2(),
    'img/onboarding/desktop/2.jpg',
    'createAccount_onboarding3',
    'createAccount_onboarding1',
    parseInt(nav) + 1,
    parseInt(nav) - 1,
  );
};
