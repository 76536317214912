const { strings } = require('../../../config')
const lochyLoadingTemplate = require('./lochyLoadingTemplate');

module.exports = () => ({
  ...(lochyLoadingTemplate({
    id: 'moveService_loading',
    heading: strings.MOVE_SERVICE_LOADING(),
    lochy: 'mechanic',
  }
  ))
})