module.exports = ({ currentTarget }) => {
  const service = z2hApp.pageData.service || {};

  const strings = require('../../../config').strings;

  const row = $(currentTarget).closest('.row');

  let badNote=false;
  let note = service.note;
  if (!note) {
    note = strings.SERVICE_BAD_NOTE();
    badNote=true;
  }

  const content = row.find('[name=primary-text-content]');

  if (badNote) {
    content.addClass('secondary-text-content')
  }
  const button = row.find('[data-actionclick=viewNote]');

  const showIconHtml = button.hasClass('has-label')
    ? '<i class="f-icn f-icn-show"></i><div class="label">' + strings.SERVICE_VIEW_ICON_LABEL() + '</div>'
    : '<i class="f-icn f-icn-show"></i>';

  const hidePassword = (_) => {
    content.fadeOut(1000).html(
      // Replace each character with a dot
      '<span class="numbered-character-list">' + '</span>',
    );
    button.html(showIconHtml);
    row.removeClass('password-visible');
  };

  if (row.hasClass('password-visible')) {
    hidePassword();
    return;
  }

  content.hide().html(note).addClass('pre').fadeIn(1000);

  row.addClass('password-visible');

  const hideIconHtml = button.hasClass('has-label')
    ? '<i class="f-icn f-icn-hide"></i><div class="label">' + strings.SERVICE_HIDE_ICON_LABEL() + '</div>'
    : '<i class="f-icn f-icn-hide"></i>';
  button.html(hideIconHtml);
};
