const DialogMgr = require('dialog-manager');
const strings = require('../../../config/strings');

module.exports = ({ currentTarget }) => {
  const info = (info) => ({
    template: 'block-text-group',
    fields: {
      primary_html: info,
    },
  });

  const row = () => ({
    template: 'block-section-row',
    fields: {
      class: 'centered-text',
    },
    rows: [],
  });

  const ctaButton = (action, id, button) => ({
    template: 'block-text-button',
    fields: {
      action: action,
      data_id: id,
      text: button,
      class: 'cta-button',
    },
  });

  const image = (src) => ({
    template: 'block-lochy',
    selector: '',
    attributes: [
      { selector: 'img', type: 'src', content: src },
      { selector: '', type: 'class', content: 'no-border' },
      { selector: 'img', type: 'class', content: 'small' },
    ],
  });

  const buildButton = (action, id, button) => {
    const $row = z2hApp.constructBlock(window.z2hTemplates.compileBlockAttribute(row()));
    $row
      .find('.column-group')
      .append(z2hApp.constructBlock(window.z2hTemplates.compileBlockAttribute(ctaButton(action, id, button))));
    return $row;
  };

  const message = JSON.parse($(currentTarget).attr('data-actiondetail'));

  let bodyLocale = '';

  if (message.body['any']) {
    bodyLocale = message.body['any'];
  }
  if (message.body[localStorage.getItem('language')]) {
    bodyLocale = message.body[localStorage.getItem('language')];
  }
  let titleLocale = '';

  if (message.title['any']) {
    titleLocale = message.title['any'];
  }
  if (message.title[localStorage.getItem('language')]) {
    titleLocale = message.title[localStorage.getItem('language')];
  }

  let buttonLocale = '';

  if (message.button['any']) {
    buttonLocale = message.button['any'];
  }
  if (message.button[localStorage.getItem('language')]) {
    buttonLocale = message.button[localStorage.getItem('language')];
  }

  const node = $('<div>');

  message.image_src ? node.append(z2hApp.constructBlock(image(message.image_src))) : null;

  node.append(z2hApp.constructBlock(window.z2hTemplates.compileBlockAttribute(info(bodyLocale))));

  message.button && node.append(buildButton(message.action, message.data_id, buttonLocale));

  DialogMgr.showMsg({ closeEnabled: true, msgNode: node[0], titleText: titleLocale });
};
