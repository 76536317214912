const LINK_EXPIRY_VALUES = {
  15: '15 minutes',
  30: '30 minutes',
  60: '1 heure',
  360: '6 heures',
  720: '12 heures',
  1440: '1 jour',
  '-1': 'Jamais',
};

const LINK_MAX_USE_VALUES = {
  1: 'Une fois',
  2: 'Deux fois',
  5: '5 fois',
  10: '10 fois',
  20: '20 fois',
  50: '50 fois',
  '-1': 'Sans limite',
};

module.exports = {
  YOU: 'Vous',
  BACK: 'Retour',
  NEXT: 'Avance',
  CONTINUE: 'Continue',
  SAVE: 'Sauver',
  ADD: 'Ajouter',
  SKIP: 'Omettre',
  CANCEL: 'Annuler',
  PREVIOUS: 'Précédent',
  EDIT: 'Editer',
  NONE: 'Aucun',
  START: 'Commencer',
  SEARCH: 'Chercher',
  CLOSE: 'Fermer',
  SEE_DETAILS: 'Voir détails',
  UNAVAILABLE: 'Indisponible',
  DONE: 'Fini',
  WATCH_AGAIN: 'Regarder à nouveau',
  NEW: 'Nouveau',

  UNAVAILABLE_FORGHETTIBLES_MESSAGE: `Certains de vos 'forghettibles' ne sont pas disponibles car vous avez dépassé la limite de votre forfait gratuit. Inscrivez-vous pour un compte premium pour des 'forghettibles' illimités!'`,

  FORGHETTI_TAGLINE: 'Oubliez vos mots de passe. Définitivement',
  CREATE_ACCOUNT: 'Créer un compte',
  HAVE_ACCOUNT: `J'ai déjà un compte`,

  COMBOBULATING: `...combiner vos 'forghettibles'...`,

  CREATE_ACCOUNT_1_HEADER: 'Entrez votre numéro',
  CREATE_ACCOUNT_1_HEADING: 'Vérifiez votre appareil',
  CREATE_ACCOUNT_1_MSG:
    'Veuillez saisir votre numéro de téléphone portable pour recevoir <strong>un code de vérification unique</strong> par SMS.',
  CREATE_ACCOUNT_1_PLACEHOLDER: 'Votre numéro',
  CREATE_ACCOUNT_1_MSG_2:
    'Modifiez le code international si vous en avez besoin et entrez votre numéro de téléphone portable\npar ex. 07795123456.',
  CREATE_ACCOUNT_1_MSG_3: `Les messages SMS ne seront envoyés que lors de la vérification de vos appareils et pour les mises à jour essentielles de l'application.`,

  ERROR_TEL_NUM_EMPTY: 'Le numéro de mobile doit être fourni',

  MAIN_NAV_GROUP: 'Groupes',
  MAIN_NAV_PROFILE: 'Profil',
  MAIN_NAV_HEALTHCHECK: 'Bilan de santé',
  MAIN_NAV_IMPORT: 'Importer des services',
  MAIN_NAV_LOGOUT: 'Se déconnecter',
  NAVBAR_JOIN_GROUP: 'Rejoindre le groupe',
  NAVBAR_NEW_GROUP: 'Nouveau groupe',
  NAVBAR_NEW_PASSWORD: 'Nouveau mot de passe',
  NAVBAR_NEW_PIN: 'Nouvelle épingle',
  NAVBAR_NEW_WORD: 'Nouveau mot mémorable',
  NAVBAR_HOME: 'Accueil',
  NAVBAR_GROUPS: 'Groupes',
  NAVBAR_HEALTHCHECK: 'Bilan de santé',
  NAVBAR_PROFILE: 'Profil',

  /* Lochy loading - paneBusyText.js */
  LOCHY_LOADING_TEXT_DEFAULT: 'Chargement...',
  LOCHY_LOADING_TEXT_GROUP: (group) => `Chargement des services du <em>${group}</em>`,
  LOCHY_LOADING_TEXT_GROUPSLIST: 'Chargement des groupes',
  LOCHY_LOADING_TEXT_HEALTHCHECK: 'Chargement du bilan de santé',
  LOCHY_LOADING_TEXT_CHOOSE_SEARCH_ICON: `Recherche d'icônes de service sur le web`,
  LOCHY_LOADING_TEXT_CHOOSE_CHOOSE_GROUP_ICON: `Téléchargement de l'icône groupe`,
  LOCHY_LOADING_TEXT_CREATE_GROUP_INVITE: 'Créer une invitation à un groupe',
  LOCHY_LOADING_TEXT_EDIT_SERVICE: 'Modifier les détails du service',
  LOCHY_LOADING_TEXT_UPDATE_SERVICE: 'Enregistrement des détails du service',
  LOCHY_LOADING_TEXT_PROFILE: 'Chargement de votre profil',
  LOCHY_LOADING_TEXT_SUBSCRIPTION: 'Chargement des détails de votre abonnement',

  /*                                 */

  CREATE_ACCOUNT_2_HEADER: 'Entrez le code',
  CREATE_ACCOUNT_2_HEADING: 'Entrez votre code de vérification',
  CREATE_ACCOUNT_2_MSG: (telNumber) => `Veuillez saisir le code de vérification envoyé au ${telNumber}.`,
  CREATE_ACCOUNT_2_LINK_BACK: `Je n'ai pas reçu de code`,
  CREATE_ACCOUNT_2_MSG_2: (termsofserviceurl) =>
    `En entrant le code de vérification, vous acceptez nos <a href="${termsofserviceurl}" target="_new">conditions d'utilisation</a>.`,

  ERROR_TEL_NUM_INVALID: 'Veuillez saisir un numéro de téléphone valide',
  ERROR_ACT_CODE_EMPTY: `Veuillez saisir le code d'activation qui vous a été envoyé`,
  ERROR_ACT_CODE_INVALID: `Veuillez saisir un code d'activation valide`,
  ERROR_ACT_CODE_INVALID: `Le code d'activation est incorrect. Veuillez réessayer`,

  CREATE_ACCOUNT_VERIFYING: 'Vérification du code de vérification',
  CREATE_ACCOUNT_VERIFYING_PHONE: 'Vérification de votre numéro de téléphone',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_1: `Aucun mot de passe n'est jamais enregistré`,
  CREATE_ACCOUNT_ONBOARDING_5_LINE_2: 'Il vous suffit de vous souvenir de votre doodle',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_3: 'Vous pouvez utiliser forghetti sur tous les appareils',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_4:
    'Mettez à niveau pour pouvoir partager des mots de passe en toute sécurité au sein de groupes',

  CREATE_ACCOUNT_REFERRAL_HEADER: 'Code de Parrainage',
  CREATE_ACCOUNT_REFERRAL_HEADING: 'Entrez un code de parrainage',
  CREATE_ACCOUNT_REFERRAL_PLACEHOLDER: 'Code de Parrainage',
  REFERRAL_ENTRY_MESSAGE_1: `Avez-vous reçu un code de parrainage d'un ami? Si tel est le cas, veuillez le saisir ici. Sinon, vous pouvez ignorer cette étape.`,

  CREATE_ACCOUNT_3_HEADER: 'Editer le profil',
  CREATE_ACCOUNT_3_HEADING: 'Modifier votre profil',
  CREATE_ACCOUNT_3_TEXT: `Vous pouvez définir un nom et une icône pour votre profil ici. Cela vous aidera à vous identifier si vous décidez de partager l'accès ou êtes invité à rejoindre le groupe de connexions d'un autre utilisateur.`,
  CREATE_ACCOUNT_3_LABEL: 'Votre nom',
  CREATE_ACCOUNT_3_TEXT_2: `Vous pouvez ignorer cette étape si vous le souhaitez et laisser la définition d'un nom et d'une image jusqu'à plus tard.`,

  CREATE_ACCOUNT_ADD_LOGIN_MSG: `Sélectionnez un service à mettre à jour à partir de ces applications populaires`,

  CREATE_ACCOUNT_FINAL_HEADER: 'Fini!',
  CREATE_ACCOUNT_FINAL_MSG: `Vous êtes prêt à forghetti!`,
  CREATE_ACCOUNT_FINAL_MSG_2: `Vous êtes sur la bonne voie pour sécuriser votre compte et oublier définitivement le stress des mots de passe!`,
  CREATE_ACCOUNT_FINAL_MSG_3: `Nous allons maintenant vous guider à travers les étapes nécessaires pour mettre à jour un mot de passe existant en un mot de passe obligatoire.`,
  CREATE_ACCOUNT_FINAL_BUTTON: 'Me montrer comment!',
  CREATE_ACCOUNT_FINAL_LINK: `Je connais déjà mon chemin autour de forghetti`,

  CREATE_ACCOUNT_ADD_CUSTOM_LOGIN: `Ou autre chose...`,
  CREATE_ACCOUNT_SERVICE_NAME_TOOLTIP: `Pour ajouter un service différent, vous devrez fournir le nom du service au minimum.\n\nEmail / Nom d'utilisateur et Adresse Web sont facultatifs, mais nous vous suggérons de les ajouter également!`,
  CREATE_ACCOUNT_CUSTOM_LOGIN_ADD_PASSWORD_TOOLTIP: `Vous devrez ajouter au moins un type de mot de passe à générer.\n\nVous avez le choix entre plusieurs options, car certains services nécessitent des exigences de mot de passe spécifiques.`,
  CREATE_ACCOUNT_CUSTOM_LOGIN_NEXTPAGE_TOOLTIP: `Une fois que vous êtes totalement satisfait, cliquez sur "Suivant" pour continuer.`,
  CREATE_ACCOUNT_NEW_LOGIN_DOODLEPAD_MSG: `Votre doodle sera utilisé chaque fois que vous voudrez récupérer votre mot de passe à l'avenir, alors faites-le compter!`,
  CREATE_ACCOUNT_ADD_ANOTHER_LOGIN_TOOLTIP: `Pour ajouter un autre service à votre compte, cliquez ici pour afficher à nouveau le nouvel écran de connexion.`,

  ADD_SERVICE_SEARCH_HEADER: `Ajouter un nouveau service`,
  ADD_SERVICE_SEARCH: `Sélectionnez un service parmi ces applications populaires ou commencez à taper le nom`,
  ADD_SERVICE_SUGGESTED: `Services suggérés`,
  ADD_SERVICE_CREATE: (name) => `Créer "${name}" "${name}"`,
  ADD_SERVICE_SELECT_ICON_HEADER: `Choisissez un icône`,
  ADD_SERVICE_SELECT_ICON: (serviceName) => `Sélectionnez une icône pour ${serviceName}`,
  ADD_SERVICE_SELECT_GROUP_HEADER: `Sélectionnez un groupe`,

  ADD_SERVICE_YOUR_GROUP: (serviceName, groupName) =>
    `<em>${serviceName}</em> <em>${serviceName}</em> sera ajouté au <em>${groupName}</em> <em>${groupName}</em>`,
  ADD_SERVICE_SELECT_GROUP: (serviceName) => `Sélectionnez un groupe auquel ajouter <br /><em>${serviceName}</em>`,
  ADD_SERVICE_SELECT_GROUP_SELECT: (serviceName) =>
    `Ou sélectionnez un autre groupe auquel ajouter <br /><em>${serviceName}</em>`,
  MOVE_SERVICE_SELECT_GROUP: (serviceName) =>
    `Sélectionnez un groupe vers lequel déplacer <br /><em>${serviceName}</em>`,
  MOVE_SERVICE_LOADING: 'Déplacer votre service',
  MOVE_SERVICE_CONFIRM_HEADER: 'Déplacer votre service',
  MOVE_SERVICE_CONFIRM_NOTE: (
    service,
    fromGroup,
    toGroup,
    numFields,
  ) => `<em>${service}</em> sera déplacé de votre groupe <em>${fromGroup}</em> vers <em>${toGroup}</em>.
Ce service a <em>${numFields}</em> 'forghettible${numFields > 1 ? 's' : ''}'`,
  MOVE_SERVICE_CONFIRM_NOTE_UPDATE: (fromGroup, toGroup, list, listLength) =>
    `Un nouveau <em>${list}</em> 'forghettible${
      listLength > 1 ? 's' : ''
    }' sera généré à partir de votre doodle <em>${toGroup}</em>. Si vous souhaitez accéder à votre ancien 'forghettible${
      listLength > 1 ? 's' : ''
    }', dessinez simplement votre doodle <em>${fromGroup}</em> à la place.`,
  MOVE_SERVICE_CONFIRM: 'Confirmer le déplacement',
  ADD_SERVICE_SELECT_GROUP_ONBOARD: (serviceName) => `Ajouter votre service <em>${serviceName}</em> à un groupe`,
  ADD_SERVICE_USERNAME: (serviceName) =>
    `Saisissez le nom d'utilisateur pour <em>${serviceName}</em> ou sélectionnez-le dans la liste ci-dessous.`,
  ADD_SERVICE_USERNAME_ONBOARD: (serviceName) =>
    `Saisissez votre adresse e-mail ou votre nom d'utilisateur <em>${serviceName}</em>`,
  ADD_SERVICE_USERNAME_HEADER: `Sélectionnez le nom d'utilisateur`,
  ADD_SERVICE_USERNAME_PLACEHOLDER: `E-mail ou nom d'utilisateur`,
  ADD_SERVICE_SEARCH_FOR_SERVICE: `Entrez un service que vous utilisez ici`,
  ADD_SERVICE_SEARCH_FOR_ICONS: 'Rechercher des icônes',
  ADD_SERVICE_SUGGESTED_USERNAMES: `Noms d'utilisateur suggérés`,
  ADD_SERVICE_DRAW_DOODLE: (groupName) => `Dessinez le doodle de votre groupe <em>${groupName}</em>`,
  ADD_SERVICE_USERNAME_BLANK: `Vous devez saisir un nom d'utilisateur ou une adresse e-mail`,
  ADD_SERVICE_USERNAME_TOO_LONG: `Votre nom d'utilisateur doit comporter moins de 64 caractères`,

  ADD_SERVICE_WEBSITE_HEADER: `Saisissez l'URL du site Web`,
  ADD_SERVICE_WEBSITE: (serviceName) =>
    `Saisissez l'URL du site Web pour <em>${serviceName}</em>. Vous pouvez ignorer cette étape et saisir une URL de site Web ultérieurement.`,
  ADD_SERVICE_WEBSITE_PLACEHOLDER: `URL de site web`,

  ADD_SERVICE_SEARCH_ONBOARD_LETS_START: `Commençons par sélectionner l'un de ces services populaires ou ...`,
  ADD_SERVICE_SEARCH_ONBOARD_NOW_ITS_YOUR_TURN: `MAINTENANT C'EST VOTRE TOUR`,
  ADD_SERVICE_DOODLE_DO_IT_AGAIN: `RECOMMENÇONS ENCORE`,
  ADD_SERVICE_DOODLE_ONBOARD_NOW_ITS_YOUR_TURN: `MAINTENANT C'EST VOTRE TOUR`,
  ADD_SERVICE_DOODLE_CREATE_DOODLE: 'Créez votre doodle de signature',
  ADD_SERVICE_DOODLE_CONFIRM_DOODLE: `Vérifions que vous pouvez vous en souvenir ... dessinez votre doodle <em>une fois de plus</em>`,
  ADD_SERVICE_GENERATING_FORGHETTI_ONBOARD: (serviceName) =>
    `Félicitations, forghetti génère maintenant un nouveau mot de passe pour <em>${serviceName}</em>`,
  VIEW_SERVICE_GENERATING_FORGHETTI: (serviceName) =>
    `Génération de votre mot de passe forghetti pour <em>${serviceName}</em>`,
  VIEW_SERVICE_RESET_PASSWORD: `Réinitialisez votre mot de passe maintenant`,
  VIEW_SERVICE_PASSWORD_HELP: (serviceName) =>
    `Nous avons généré un mot de passe super fort pour <em>${serviceName}</em>. Il est maintenant temps de réinitialiser votre ancien mot de passe.`,
  VIEW_SERVICE_PASSWORD_HELP_MOVE: (serviceName, groupName) =>
    `Nous avons déplacé <em>${serviceName}</em> vers <em>${groupName}</em>. Si votre doodle pour ce service a changé, vous devrez mettre à jour votre mot de passe..`,
  VIEW_SERVICE_OLD_PREFIX: 'Vieux',
  VIEW_SERVICE_NEW_PREFIX: 'Nouveau',
  HIDE_QUICK_SELECT: `Ne plus me montrer ça`,
  HIDE_QUICK_SELECT_CONFIRM_TITLE: `Masquer cet écran?`,
  HIDE_QUICK_SELECT_CONFIRM: `Cela supprimera cette page de l'affichage à l'avenir, et l'ajout d'un nouveau service vous mènera directement à la page «Ajouter un autre service» à la place.\n\nCela peut être annulé à tout moment dans les paramètres sous "Préférences utilisateur".`,
  ADD_SERVICE_DODODLES_DO_NOT_MATCH: `Vos doodles ne correspondent pas. Réessayer.`,
  THEME_COLOUR: 'Couleur',
  THEME_COLOURS: ['Bleu impeccable', 'Vert frais', 'Rouge intrépide', 'Orange de bien-être', 'Jaune amical '],
  THEME_MODES: ['Jour', 'Nuit'],
  THEME_BLUE: 'Bleu impeccable',

  DELETE_ACCOUNT_CONTINUE: 'Continuer',
  DELETE_ACCOUNT_HEADER: 'Supprimer le compte',
  DELETE_ACCOUNT_HEADING: 'Supprimer mon compte',
  DELETE_ACCOUNT_LINE_1: 'La suppression de votre compte:',
  DELETE_ACCOUNT_LINE_2: '• Supprimer votre compte de forghetti',
  DELETE_ACCOUNT_LINE_3: '• Effacez toutes les informations que forghetti détient sous votre profil',
  DELETE_ACCOUNT_LINE_4: '• Vous supprimer de tous les groupes forghetti',
  DELETE_ACCOUNT_LINE_5: `• Supprimer l'accès à votre compte de tous les appareils connectés`,
  DELETE_ACCOUNT_CHANGE_INSTEAD: 'Vous souhaitez changer de téléphone à la place?',

  DELETE_ACCOUNT_CONFIRM_MESSAGE: (telNumber) =>
    `Nous avons envoyé un code de confirmation à votre numéro de téléphone (${telNumber}). Veuillez saisir ce code pour confirmer que vous souhaitez supprimer votre compte:`,
  DELETE_ACCOUNT_CONFIRM_BUTTON: 'Confirmer',

  DEVICES_HEADER: 'Gestion des appareils',
  DEVICES_LAST_ACTIVE: 'Le dernier actif: ',
  DEVICES_FORGHETTI_APP: ' - application forghetti',
  DEVICES_LOG_OUT_ALL: 'Déconnectez-vous de tous les appareils',

  SERVICES_PERSONAL: 'Personnel',
  SERVICES_ADD_LOGIN: 'Ajouter une connexion',
  SERVICES_RECENT: 'Récent', // Not currently used

  SEARCH_INTERNET: 'Rechercher une image sur Internet',

  SERVICE_HEADER_NEW: 'Ajouter une connexion',
  SERVICE_NAME_PLACEHOLDER: 'Nommez votre identifiant',

  SERVICE_DOODLEPAD_SUBHEADING: 'Doodle sur le doodlepad',
  DOODLEPAD_SECRET: 'Cacher',
  DOODLEPAD_SHOW: 'Montrer',
  DOODLEPAD_REDOODLE: 'Clair',
  ERROR_NO_DOODLE: 'Veuillez dessiner votre doodle',

  SERVICE_EDIT_FIELD_HEADER: 'Réglages',

  FIELD_TYPES: {
    lns: 'AbC123_*.',
    wns: 'Mots123_ *.',
    lnx: 'AbC123 ~?%',
    wnx: 'Mots123 ~?%',
    ln: 'AbC123',
    wn: 'Mots123',
    l: 'Abc',
    w: 'Mots',
    n: '123',
  },

  FIELD_TEMPLATE_PIN: 'PIN',
  FIELD_TEMPLATE_COMPLEX_PASSWORD: 'Mot de passe complexe',
  FIELD_TEMPLATE_PASSWORD: 'Mot de passe',
  FIELD_TEMPLATE_MEMWORD: 'Mot mémorable',
  FIELD_TEMPLATE_FORGHETTIBLE: 'Lettres uniquement',

  SERVICE_USERNAME_LABEL: `E-mail ou nom d'utilisateur`,
  SERVICE_WEBSITE_LABEL: 'Adresse web',

  SERVICE_EDIT_HEADER: 'Détails',
  SERVICE_EDIT_USERNAME_PLACEHOLDER: 'Entrez la méthode de connexion',
  SERVICE_EDIT_WEBSITE_PLACEHOLDER: 'www.adresseweb.com',

  SERVICE_ADD_FORGHETTIBLE: `Ajouter un 'forghettible'`,
  SERVICE_DFT_FORGHETTIBLE_NAME: 'Mot de passe',

  SERVICE_NAME_LENGTH_INVALID: 'Le nom du service doit comporter entre 2 et 32 ​​caractères',
  SERVICE_USERNAME_LENGTH_INVALID: `L'e-mail / nom d'utilisateur du service doit comporter moins de 64 caractères`,
  SERVICE_ADDRESS_LENGTH_INVALID: `L'adresse Web du service doit comporter moins de 128 caractères`,
  SERVICE_NO_FORGHETTIBLES: 'Vous devez en ajouter au moins un oubliable. Un mot de passe, par exemple.',

  SERVICE_CHOOSE_ICON: `Choisissez l'icône`,

  SERVICE_DOODLEPAD_HEADING: 'Doodle sur le pavé',
  SERVICE_DOODLEPAD_RIGHT_NAV: 'Détails',

  SERVICE_ADDING: `Ajout d'un service`,

  SERVICE_CONNECTING: 'De liaison',

  FIELD_DETAILS_HEADER: 'Réglages',
  FIELD_NAME: 'Nom',
  FIELD_NAME_PLACEHOLDER: `Nommez votre 'forghettible'`,
  FIELD_TYPE: 'Type',
  FIELD_LENGTH: 'Longueur',
  FIELD_LENGTH_PICKER_CUSTOM: 'Personnalisé',

  FIELD_RIGHT_NAV: `D'accord`,

  FIELD_OPTIONS_HEADING: 'Options',

  ADVANCED_OPTIONS: 'Options avancées',
  RESET_FIELD: 'Réinitialiser',
  DELETE_FIELD: 'Supprimer',
  RESTORE_FIELD: 'Restaurer définitivement',

  FIELD_ADVANCED_OPTIONS_HEADER: 'Options avancées',

  RESTORE_FIELD_PAGE_HEADER: 'Restaurer',
  RESTORE_FIELD_SUBHEADING: `Besoin d'un ancien mot de passe?`,
  RESTORE_FIELD_DESCRIPTION:
    'Vous pouvez revenir à une version précédente ici. Sélectionnez simplement la version que vous souhaitez restaurer.',
  RESTORE_FIELD_VERSION_HISTORY: 'Historique des versions',
  RESTORE_FIELD_CURRENT: 'Actuel',

  SERVICE_VIEW_HEADER: 'Sommaire',
  SERVICE_VIEW_RIGHT_NAV: 'Fini',

  SERVICE_SUMMARY_MESSAGE: `Vos 'forghettibles' ont été générés. Veuillez sélectionner ci-dessous pour copier, afficher et accéder à cette connexion.`,
  SERVICE_SUMMARY_FIRST_LOGIN_MSG1: `Toutes nos félicitations! Vous venez de générer votre premier mot de passe forghetti!`,
  SERVICE_SUMMARY_FIRST_LOGIN_MSG2: `Vous devrez maintenant remplacer votre mot de passe existant par ce nouveau pour qu'il prenne effet.`,
  SERVICE_SUMMARY_FIRST_LOGIN_PASSWORD_TOOLTIP: `Lorsque vous êtes prêt, vous pouvez copier votre nouveau mot de passe dans le presse-papiers en cliquant sur cette icône.`,

  SERVICE_DETAILS_HEADER: 'Détails',
  SERVICE_DETAILS_RIGHT_NAV: 'Éditer',
  SERVICE_DETAILS_DELETE: 'Supprimer la connexion',

  PROFILE_HEADER: 'Profil',
  PROFILE_WIZARD_COMPLETE: 'Terminez votre configuration forghetti.',
  PROFILE_WIZARD_SEE_DETAILS: 'Voir les détails > >',
  PROFILE_PREMIUM_BANNER_HEADING: 'Essayez Premium',
  PROFILE_PREMIUM_BANNER_DETAIL: 'Débloquez des fonctionnalités pour sécuriser votre ligne.',
  PROFILE_THEME_MODE_LABEL: 'Mode',
  PROFILE_THEME_COLOUR_LABEL: 'Couleur',
  PROFILE_BADGES: 'Vos badges',
  PROFILE_LINK_ACCOUNT: 'Votre compte',
  PROFILE_LINK_PREMIUM: 'Prime',
  PROFILE_ENABLE_AUTOFILL: 'Activer la saisie automatique',
  PROFILE_LINK_USER_PREFERENCES: `Préférences de l'application`,
  PROFILE_YOUR_SUBSCRIPTION: 'Votre abonnement',
  PROFILE_LINK_INVITE: 'Partagez forghetti avec un ami',
  /*PROFILE_LINK_INVITE_SECONDARY: 'Partagez avec des amis et gagnez des `'forghettibles'` gratuites',*/
  PROFILE_REFERRAL_CODE: 'Code de parrainage',
  PROFILE_REFERRAL_CODE_SECONDARY: '',
  PROFILE_LINK_DEVICES: `Gestion d'appareils`,
  PROFILE_LINK_HELP: `Obtenir de l'aide`,
  PROFILE_LINK_FEEDBACK: 'Envoyez-nous vos commentaires',
  PROFILE_LINK_ABOUT: 'À propos',
  PROFILE_LINK_LOGOUT: 'Se déconnecter',

  PROFILE_ACCOUNT_HEADER: 'Votre compte',
  PROFILE_ACCOUNT_LINK_SECURITY: 'Sécurité',
  PROFILE_ACCOUNT_LINK_MIGRATE: 'Changer de numéro',
  PROFILE_ACCOUNT_LINK_DELETE: 'Supprimer mon compte',
  PROFILE_ACCOUNT_LINK_SUBSCRIPTION: 'Votre abonnement',

  PROFILE_REONBOARDING: 'Apprenez comment fonctionne forghetti',
  PROFILE_GET_ONLINE_HELP: `Obtenez de l'aide en ligne`,
  PROFILE_GET_VIDEO_HELP: 'Obtenir une aide en ligne vidéo',
  PROFILE_HELP_HEADER: 'Aidez-moi',

  PROFILE_EDIT_NAME_HEADER: 'Détails du profil',
  PROFILE_EDIT_NAME_LABEL: 'Votre nom',
  PROFILE_EDIT_TEL_LABEL: 'Numéro de téléphone',

  PROFILE_NAME_BLANK: 'Le nom du profil ne peut pas être vide',
  PROFILE_NAME_TOO_LONG: 'Le nom du profil ne peut pas comporter plus de 32 caractères',

  PROFILE_NAME_INVALID: 'Le nom du profil ne peut contenir que des lettres, des chiffres et des symboles',

  PROFILE_MIGRATE_1_HEADER: 'Changer le numéro',
  PROFILE_MIGRATE_1_HEADING: 'Numéro de téléphone actuel',
  PROFILE_MIGRATE_1_INFO: 'Veuillez saisir votre nouveau code pays et numéro de téléphone:',
  PROFILE_MIGRATE_1_PLACEHOLDER: 'Numéro de portable',
  PROFILE_MIGRATE_1_BUTTON: 'Envoyer le code de vérification',

  PROFILE_MIGRATE_2_HEADER: 'Entrez le code',
  PROFILE_MIGRATE_2_HEADING: 'Numéro de téléphone actuel',
  PROFILE_MIGRATE_2_HEADING2: 'Nouveau numéro de téléphone',
  PROFILE_MIGRATE_2_INFO: 'Saisissez maintenant le code de vérification que vous recevez sur votre nouveau téléphone:',
  PROFILE_MIGRATE_2_BUTTON: 'Confirmer',

  PROFILE_MIGRATE_3_HEADER: 'Terminé',
  PROFILE_MIGRATE_3_MSG: 'Votre numéro de téléphone a été modifié',
  PROFILE_MIGRATE_3_HEADING: 'Nouveau numéro de téléphone',

  PROFILE_ABOUT_HEADER: 'À propos',
  PROFILE_ABOUT_PRIVACY: 'Politique de confidentialité',
  PROFILE_ABOUT_COOKIE: 'Gestion des cookies',
  PROFILE_ABOUT_TERMS: `Conditions d'utilisation`,
  PROFILE_ABOUT_ACCEPTABLE: `Politique d'utilisation acceptable`,
  PROFILE_ABOUT_VERSION_HEADING: 'Version',

  PROFILE_REFERRAL_CODE_HEADING: 'Code de Parrainage',
  PROFILE_REFERRAL_CODE_LABEL: 'Code de Parrainage',
  PROFILE_REFERRAL_CODE_PLACEHOLDER: 'Entrez votre code de parrainage',
  PROFILE_EDIT_REFERRAL_CODE_HEADING: 'Modifier le code de parrainage',
  PROFILE_REFERRAL_CODE_TEXT: 'Si vous avez reçu un code de parrainage, veuillez le saisir ci-dessous.',

  PROFILE_PREMIUM_TYPE: {
    premium_trial: `Utilisateur d'essai Premium`,
    premium_life: 'Utilisateur à vie',
    free: 'Utilisateur libre',
    premium: 'Abonné Premium',
    premium_paid: 'Abonné Premium',
  },
  APP_PREFERENCES_HEADER: `Préférences de l'application`,
  APP_PREFERENCES_THEME: 'Thème',
  APP_PREFERENCES_QUICK_SELECT: 'Afficher les info-bulles',
  APP_PREFERENCES_SHOW_QUICK_SELECT: `Afficher l'écran de connexion d'ajout rapide`,
  APP_PREFERENCES_SECRET_DOODLE: 'Doodle secret par défaut',
  APP_PREFERENCES_SHOW_TOOLTIPS: 'Afficher les infobulles',
  APP_PREFERENCES_REQUIRE_TOUCH_ID: `Exiger la biométrie pour ouvrir l'application`,
  APP_PREFERENCES_NOTIFICATIONS: 'Notifications',
  APP_PREFERENCES_COUNTRY: 'Langue',
  APP_PREFERENCES_TOUCH_ID_TOAST: `Veuillez vous assurer que l'identifiant biométrique est inscrit sur votre appareil`,

  PREMIUM_HEADER: 'forghetti Premium',

  YOUR_SUBSCRIPTION_HEADER: 'Votre abonnement',
  YOUR_SUBSCRIPTION_SUBHEADING: 'Essai premium',

  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_TEXT_1: (date) =>
    `Vous bénéficiez actuellement d'un essai Premium gratuit. Votre essai expirera le ${date}. À ce stade, vous pouvez soit continuer en tant qu'utilisateur gratuit (et perdre tous vos avantages premium), soit acheter un abonnement Premium intégré à l'application.`,
  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_TEXT_2: (date) => `Nous espérons que vous appréciez l'utilisation de forghetti.`,

  YOUR_SUBSCRIPTION_FREE_TEXT_1: (date) =>
    `Vous êtes actuellement un utilisateur gratuit. Abonnez-vous dès aujourd'hui pour bénéficier de connexions illimitées, de la possibilité de créer des groupes illimités que vous pouvez partager entre amis, famille et collègues et pour obtenir des rapports détaillés de bilan de santé.`,
  YOUR_SUBSCRIPTION_FREE_TEXT_2: (date) => `Nous espérons que vous appréciez l'utilisation de forghetti.`,
  YOUR_SUBSCRIPTION_FREE_TEXT_3: `Si vous avez déjà un abonnement de l'App Store, cliquez sur restaurer l'abonnement pour réappliquer.`,
  YOUR_SUBSCRIPTION_FREE_DOWNLOAD: `Vous n'avez pas l'application? Téléchargez dès maintenant depuis votre boutique d'applications`,

  YOUR_SUBSCRIPTION_PREMIUM_TEXT_1: (date) =>
    `Vous êtes actuellement un utilisateur Premium. Votre abonnement expire le ${date}. Lorsque vous achetez un abonnement, il est configuré pour se renouveler automatiquement. Si vous le souhaitez, vous pouvez désactiver cette option dans votre compte App Store.`,
  YOUR_SUBSCRIPTION_PREMIUM_TEXT_2: (date) => `Nous espérons que vous appréciez l'utilisation de forghetti.`,

  YOUR_SUBSCRIPTION_PREMIUM_NO_DATE_TEXT_1: `Vous êtes actuellement un utilisateur Premium. Lorsque vous achetez un abonnement, il est configuré pour se renouveler automatiquement. Si vous le souhaitez, vous pouvez désactiver cette option dans votre compte App Store.`,

  YOUR_SUBSCRIPTION_PREMIUM_LIFE_TEXT_1: (date) =>
    `Votre abonnement à vie premium n'expirera jamais. Vous pouvez continuer à utiliser forghetti définitivement!`,
  YOUR_SUBSCRIPTION_PREMIUM_LIFE_TEXT_2: (date) => `Nous espérons que vous appréciez l'utilisation de forghetti.`,
  YOUR_SUBSCRIPTION_FREE_TEXT_APP_3: `Si vous avez déjà un abonnement de l'App Store, cliquez sur restaurer l'abonnement pour réappliquer.`,

  YOUR_SUBSCRIPTION_FREE_TEXT_APP_1: `Vous êtes actuellement un utilisateur gratuit. Abonnez-vous dès aujourd'hui pour bénéficier de connexions illimitées, de la possibilité de créer des groupes illimités que vous pouvez partager entre amis, famille et collègues et pour obtenir des rapports détaillés de bilan de santé.`,

  YOUR_SUBSCRIPTION_FREE_TEXT_APP_2: 'Scannez le code QR avec votre téléphone pour vous abonner au service Premium.',
  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_BUTTON_LABEL: 'Voir les prix',
  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_CORDOVA_BUTTON_LABEL: 'Afficher les abonnements',

  YOUR_SUBSCRIPTION_FREE_BUTTON_LABEL: 'Améliorer',

  PREMIUM_NO_PRODUCT_SELCTED: 'Sélection de produit non valide',
  PREMIUM_PURCHASE_BUTTON: 'Achat',
  PREMIUM_PURCHASE_BUTTON_NA: 'Bientôt disponible',
  COMING_SOON: 'Bientôt disponible',
  PREMIUM_PRICE_NONE: 'N / A',
  PREMIUM_PURCHASE_BUTTON_OWNED: 'Possédé',
  PREMIUM_PRUCHASE_REFRESH_PURCAHSE: 'Actualisez votre achat',
  PREMIUM_MONTHLY_HEADER: 'Premium chaque mois',
  PREMIUM_MONTHLY_LIST: [
    {
      description: 'Groupes illimités',
    },
    {
      description: 'Connexions illimitées',
    },
    {
      description: 'Assistance prioritaire',
    },
  ],
  PREMIUM_ANNUAL_ALIAS: 'Plan annuel',
  PREMIUM_MONTHLY_ALIAS: 'Plan mensuel',
  PREMIUM_ONE_OFF_ALIAS: 'Achat unique',
  PREMIUM_LOADING_OWNED: 'Félicitations! Vous êtes désormais un utilisateur premium!',
  PREMIUM_LOADING_RELOAD: 'Bientôt, forghetti se rechargera avec votre nouvel abonnement.',
  PREMIUM_LOADING_APPROVED: `Veuillez patienter pendant que nous confirmons votre abonnement avec l'App Store!`,
  PREMIUM_LOADING_REGISTERED: 'Veuillez patienter pendant le chargement des abonnements disponibles ...',
  PREMIUM_MANAGE_SUBSCRIPTIONS: `Gérer l'abonnement`,
  PREMIUM_IMPOSSIBLE_TO_VERIFY: `Il n'a pas été possible de vérifier votre achat`,
  PREMIUM_RESTORE_PURCHASE: `Restaurer l'abonnement`,
  PREMIUM_YEARLY_HEADER: `Premium pour toute l'année`,
  PREMIUM_YEARLY_LIST: [
    {
      description: 'Groupes illimités',
    },
    {
      description: 'Connexions illimitées',
    },
    {
      description: 'Assistance prioritaire',
    },
  ],
  PREMIUM_LIFETIME_HEADER: 'Premium définitivement',
  PREMIUM_LIFETIME_LIST: [
    {
      description: 'Groupes illimités',
    },
    {
      description: 'Connexions illimitées',
    },
    {
      description: 'Assistance prioritaire',
    },
  ],
  ENABLE_ANDROID_AUTOFILL_HEADING: 'Activer la saisie automatique',
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_1: `Forghetti utilise le service d'accessibilité d'Android pour la fonction Autofill, ce qui aide à récupérer les mots de passe lorsqu'il est invité dans n'importe quelle application de votre appareil.`,
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_2:
    `Nous vous assurons, Forghetti ne lit ni n'accède à aucun autre contenu d'écran. Nous identifions uniquement que lorsqu'un champ de mot de passe apparaît à l'écran pour Autofill.`,
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_3: `Pour utiliser ce service, le consentement des utilisateurs est requis. En cliquant sur le bouton «d'accord», vous nous donnez la permission d'utiliser le service d'accessibilité pour Autofill. Si vous choisissez de nier le consentement, la fonctionnalité de mise à niveau automatique sera désactivée mais vous pouvez continuer à utiliser ForGhetti sans cette fonctionnalité.`,
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_4: `Veuillez noter que la navigation loin de cet écran n'est pas considérée comme donnant son consentement et que nous n'utilisons pas de messages automatique pour recueillir le consentement.`,
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_5: `Pour plus d'informations sur la façon dont notre utilisation de l'API d'accessibilité est mise en œuvre et sécurisée, ainsi que notre traitement des données utilisateur, veuillez consulter notre politique de confidentialité.`,

  ENABLE_ANDROID_AUTOFILL_BUTTON: 'Accepter',
  ENABLE_ANDROID_AUTOFILL_BUTTON_NO: 'Pas maintenant',

  MAKE_ADMIN_MESSAGE: 'Souhaitez-vous faire de cet utilisateur un administrateur de ce groupe?',
  MAKE_OWNER_MESSAGE: `Souhaitez-vous transférer la propriété de ce groupe à l'utilisateur?`,

  REMOVE_ADMIN_MESSAGE: `Souhaitez-vous supprimer le statut d'administrateur de cet utilisateur?`,
  DELETE_GROUP_CONFIRM: 'Voulez-vous vraiment supprimer ce groupe?',
  LEAVE_GROUP_CONFIRM: 'Souhaitez-vous quitter ce groupe?',
  GROUP_DELETED_MESSAGE: 'Le groupe a maintenant été supprimé.',
  REMOVE_MEMBER_MESSAGE: 'Souhaitez-vous supprimer cette personne du groupe?',
  REMOVE_MEMBER_SUCCESS: 'Le membre du groupe a été supprimé',
  GROUP_SHARED_BY: 'Groupes partagés par',
  ERROR_INVALID_NUM_WORDS: 'Le nombre de mots doit être compris entre 1 et 10',
  ERROR_INVALID_NUM_LETTERS: 'Le nombre de caractères doit être compris entre 1 et 64',

  UPGRADE_REQUIRED: 'Mise à jour requise',
  ADD_LOGIN_NOT_ALLOWED: `En tant qu'utilisateur gratuit, vous êtes uniquement autorisé à ajouter des identifiants à votre groupe personnel. Mettez à niveau aujourd'hui pour créer des groupes et partager des identifiants entre amis, famille et collègues!`,
  UPGRADE_TODAY_MESSAGE: `En tant qu'utilisateur gratuit, vous pouvez rejoindre un groupe.
Mettez à niveau aujourd'hui pour créer des groupes et partager des identifiants entre amis, famille et collègues!`,

  UPGRADE_TODAY_BUTTON: `Mettre à jour aujourd'hui`,
  UPGRADE_TODAY_PAGE_HEADING: `Mettre à jour aujourd'hui`,
  UPGRADE_TODAY_MSG_1: 'Devenez un utilisateur Premium forghetti',
  UPGRADE_TODAY_PRICE: '£ 24.99 par an',
  UPGRADE_TODAY_MSG_2: 'Libérez toutes les fonctionnalités offertes par forghetti',
  UPGRADE_TODAY_MSG_3: `L'abonnement Premium est disponible en achat intégré dans l'une de nos applications mobiles:`,

  UPGRADE_TODAY_MSG_4: `Un abonnement annuel vous permet de devenir un utilisateur Premium avec la possibilité de créer des connexions illimitées, des champs et des groupes oubliables.`,

  INVITE_FRIEND_HEADER: 'Obtenez des services gratuits',
  INVITE_FRIEND_REFERRAL_LABEL: 'Lien de référence',
  INVITE_FRIEND_REFERRAL_COPY: 'Copier le lien de parrainage dans le presse-papiers',
  INVITE_FRIEND_REFERRAL_EMAIL: 'Lien de référence par e-mail',
  INVITE_FRIEND_PREAMBLE: 'Partagez forghetti avec un ami',
  INVITE_FRIEND_TEXT: `Partagez simplement votre lien personnel.`,
  INVITE_FRIEND_BENEFIT: `Parrainez un ami et obtenez 5 services supplémentaires ajoutés à votre groupe personnel lorsqu'ils s'inscrivent. Demandez à 5 amis de s'inscrire et nous vous offrirons 15 services supplémentaires.`,

  GROUPS_LIST_HEADER: 'Vos groupes',
  GROUPS_LIST_INFO_MESSAGE: 'Gérez vos groupes ou basculez entre les groupes',
  GROUPS_LIST_PERSONAL_HEADING: 'Votre groupe personnel',
  GROUPS_LIST_OTHER_GROUPS_HEADING: 'Vos autres groupes',
  GROUPS_LIST_ADD_NEW_GROUP: 'Ajouter un nouveau groupe',
  GROUPS_LIST_CURRENTLY_ACTIVE: 'ACTUELLEMENT ACTIF',
  GROUPS_NEW_HEADER: 'Nouveau groupe',
  GROUPS_NEW_RIGHT_NAV: 'Créer',
  GROUPS_NEW_NAME_PLACEHOLDER: 'Entrez un titre',

  GROUPS_ADD_GROUP_HEADER: 'Ajouter un groupe',
  GROUPS_JOIN_GROUP: 'Rejoindre le groupe',
  GROUPS_JOIN_GROUP_TEXT: `Rejoignez un groupe en utilisant un lien d'invitation`,
  GROUPS_CREATE_GROUP: 'Créer un groupe',
  GROUPS_CREATE_GROUP_TEXT: `Créer un nouveau groupe à partager avec d'autres`,
  GROUPS_FREE_JOIN_WARNING: `En tant qu'utilisateur gratuit, vous pouvez rejoindre un groupe.`,
  GROUPS_ADD_UPGRADE: `Mettez à niveau dès aujourd'hui pour créer des groupes et partager des connexions entre amis, famille et collègues.`,
  GROUP_NEW_INVITE_GENERATING: 'Générer votre invitation de groupe',
  LEAVE_GROUP_MESSAGE: 'Vous avez maintenant été retiré du groupe.',
  GROUP_DETAIL_LOADING: `Récupération des détails du groupe`,
  GRP_SHARING_HEADING: 'Partage',
  GRP_MEMBERS_HEADING: 'Membres',
  GRP_SETTINGS_HEADING: 'Paramètres de groupe',

  GROUP_VIEW_HEADER: 'Détails du groupe',
  GROUP_VIEW_RIGHT_NAV: 'Éditer',
  GROUP_VIEW_USER_ADMIN: 'Administrateur',
  GROUP_VIEW_USER_OWNER: 'Propriétaire',
  GROUP_VIEW_USER_YOU: 'Tu',
  GROUP_VIEW_USER_READ_ONLY: 'Lecture seulement',
  GROUP_VIEW_SWITCH_TO: 'Passer à ce groupe',
  GROUP_VIEW_LEAVE: 'Quitter ce groupe',
  GROUP_VIEW_DELETE: 'Supprimer le groupe',
  GROUP_VIEW_PREMIUM: 'Utilisateur Premium',
  GROUP_VIEW_FREE: 'Utilisateur libre',
  GROUP_DETAIL_SAVE_LOADING: `Mettez à jour les détails de votre groupe`,
  GROUP_EDIT_HEADER: 'Détails du groupe',
  GROUP_EDIT_MAX_MEMBERS: 'Nombre maximum de membres',
  GROUP_EDIT_MAX_MEMBERS_SECONDARY: `En t'incluant`,
  PERSONAL_GROUP_MESSAGE: `Ceci est votre groupe personnel. Ce groupe ne doit jamais être partagé. Pour modifier l'icône de ce groupe, accédez à votre profil et modifiez votre photo de profil.`,

  GROUP_CHOOSE_ICON: `Choisissez l'icône`,

  GROUP_NAME_BLANK: 'Le nom du groupe ne peut pas être vide',
  GROUP_NAME_TOO_LONG: 'Le nom du groupe ne peut pas contenir plus de 32 caractères',

  GRP_MAX_MEMBERS_ERROR: 'Le nombre maximum de membres doit être un nombre compris entre 1 et 50',

  JOIN_GROUP_PLACEHOLDER: `Entrez votre lien d'invitation`,
  JOIN_GROUP_HEADING: 'Rejoindre le groupe',
  GRP_INVITE_CODE_LABEL: `Lien d'invitation au groupe`,
  GRP_INVITE_CODE_NOTE: (group) => `Invitez des membres à rejoindre <em>${group}</em> en partageant ce lien`,
  JOIN_GROUP_RIGHT_NAV: 'Joindre',

  REFERRAL_COPY_MESSAGE: (url) =>
    `Je vous invite à utiliser forghetti. Téléchargez l'application et oubliez les mots de passe pour toujours. Aller à ${url}`,

  CREATE_GROUP_INVITE_TEXT_1: `Envoyez ce code à votre famille, à vos amis ou aux membres de votre équipe pour leur permettre d'accéder à ce groupe.

Pour rejoindre un groupe, cliquez sur "Ajouter", puis sur "Rejoindre le groupe" et collez votre lien d'invitation.`,

  INVITE_NEW_MEMBER: 'Inviter un nouveau membre',
  GRP_INVITE_HELP_TEXT: `Tous les membres du groupe ont la possibilité de créer de nouvelles connexions, mais seuls les administrateurs ont la possibilité de les supprimer. Les administrateurs peuvent également supprimer des membres, mais si vous créez le groupe, vous ne pourrez jamais en être supprimé par un autre administrateur.`,

  // Template for text that is copied to clipboard when you choose to copy a group invite code to clipboard
  GRP_INVITE_CLIPBOARD_MESSAGE: (code) => `${code}`,

  INVITE_LINK_HEADING: 'Invitation',
  INVITE_SETTINGS_HEADING: 'Réglages',
  LINK_EXPIRY_LABEL: 'Le lien expire après',
  MAX_USES_LABEL: `Nombre maximum d'utilisations`,

  LINK_EXPIRY_VALUE: (mins) => LINK_EXPIRY_VALUES[String(mins)],
  LINK_MAX_USES_VALUE: (num) => LINK_MAX_USE_VALUES[String(num)],
  INVITE_NO_LIMIT: LINK_MAX_USE_VALUES['-1'],
  INVITE_EXPIRY_NEVER: LINK_EXPIRY_VALUES['-1'],

  DEACTIVATE_GRP_LINK_CONFIRM: 'Êtes-vous sûr de vouloir supprimer cette invitation?',
  DEACTIVATE_GRP_LINK_SUCCESS: `L'invitation au groupe a été supprimée avec succès.`,

  GRP_INVITE_CREATE_NAV: 'Créer',

  // Share Group Invite page
  SHARE_INVITE_HEADING: 'Inviter des membres',
  GRP_INVITE_CODE_LABEL: `Lien d'invitation`,
  CPY_GRP_INVITE: `Copier l'invitation dans le presse-papiers`,
  DEACTIVATE_LINK: `Supprimer l'invitation`,
  SHARE_INVITE_VIA_EMAIL: `Partager l'invitation par e-mail`,
  COPY_INVITE_SUCCESS: `Invitation de groupe copiée dans le presse-papiers.`,

  ONBOARDING_HEADER_1: 'Créez une connexion à un site web favori',
  ONBOARDING_HEADER_2: 'Dessinez votre doodle',
  ONBOARDING_HEADER_3: 'Votre mot de passe est généré',
  ONBOARDING_HEADER_4: 'Copiez et partez!',
  ONBOARDING_HEADER_5: "C'est ça!",
  ONBOARDING_WELCOME_HEADING: `Bonjour, je m'appelle Locky <br> Bienvenue à forghetti`,
  ONBOARDING_WELCOME_TEXT:
    'En utilisant forghetti, vous pouvez vous connecter à tous les services que vous utilisez, avec un doodle sécurisé.',
  ONBOARDING_DOODLE_HEADING: `Vous allez avoir besoin d'un doodle de signature`,
  ONBOARDING_DOODLE_TEXT:
    'Des lignes aux points, aux gribouillis aléatoires ou aux séquences de nombres. Votre combinaison unique génère un mot de passe super fort.',
  ONBOARDING_HOW_IT_WORKS: 'Comment ça fonctionne',
  ONBOARDING_ADDSERVICE_HEADING: 'Idéal pour tout service',
  ONBOARDING_ADDSERVICE_TEXT:
    'Chaque fois que vous ajoutez un service, nous générons un mot de passe unique et ultra fort. Vous pouvez même générer des numéros de broches et des mots mémorables.',
  ONBOARDING_RESETPASSWORD_HEADING: 'Réinitialisez et échangez votre ancien mot de passe',
  ONBOARDING_RESETPASSWORD_TEXT:
    'Ouvrez le site Web et réinitialisez votre ancien mot de passe, en le remplaçant par votre nouveau mot de passe forghetti ultra sécurisé.',
  ONBOARDING_SECURESHARING_HEADING: 'Partage de mot de passe sécurisé',
  ONBOARDING_SECURESHARING_TEXT: `Utilisez des groupes pour partager l'accès à vos services préférés en toute sécurité avec vos amis, votre famille et vos collègues.`,
  ONBOARDING_UNLIKE_OTHERS_HEADING: `Contrairement à d'autres gestionnaires de mots de passe ...`,
  ONBOARDING_UNLIKE_OTHERS_TEXT:
    'Nous ne sauvegardons pas une base de données de mots de passe. Nous les générons lorsque vous en avez besoin. Ils sont calculés en utilisant votre doodle comme clé. Super sûr et uniquement connu de vous.',
  ONBOARDING_DOODLETUTORIAL1_HEADING: 'Maintenant, créez votre griffonnage de signature! Comment faites-vous?',
  ONBOARDING_SHOWMEHOW: 'Laisse moi te montrer comment!',
  ONBOARDING_DOODLETUTORIAL2_HEADING: `Tout d'abord, vous pouvez dessiner un motif ...`,
  ONBOARDING_DOODLETUTORIAL: 'Tutoriel de griffonnage sécurisé',
  ONBOARDING_DOODLETUTORIAL3_HEADING: 'ou une série de lignes',
  ONBOARDING_DOODLETUTORIAL4_HEADING: 'même les points sont ok ...',
  ONBOARDING_DOODLETUTORIAL5_HEADING: 'Ou une combinaison de tous ensemble',
  ONBOARDING_DOODLETUTORIAL6_HEADING: `Mais rappelez-vous que l'ordre compte,`,
  ONBOARDING_DOODLETUTORIAL7_HEADING: 'Dessin doodle A',
  ONBOARDING_DOODLETUTORIAL8_HEADING: `n'est pas la même chose que dessiner le doodle B`,
  ONBOARDING_YOURTURN: `Maintenant c'est ton tour`,

  ONBOARDING_COMPLETE_WELCOME: 'Bienvenue à forghetti',
  ONBOARDING_COMPLETE_CONGRATULATIONS:
    'Toutes nos félicitations! Vous venez de faire les premiers pas pour sécuriser votre identité en ligne',
  ONBOARDING_COMPLETE_COMPLETE_SETUP: 'Terminez votre configuration forghetti',
  ONBOARDING_COMPLETE_CREATE_FORGHETTI: 'Créer un compte forghetti',
  ONBOARDING_COMPLETE_SIGNATURE_DOODLE: 'Créez votre griffonnage de signature',
  ONBOARDING_COMPLETE_FIRST_LOGIN: 'Ajoutez votre premier service',
  ONBOARDING_COMPLETE_RESET_PASSWORD: 'Généré un mot de passe sécurisé',
  ONBOARDING_COMPLETE_IMPORT: 'Importer des services',
  ONBOARDING_COMPLETE_HEALTHCHECK: 'Exécutez un bilan de santé sur vos comptes en ligne',
  ONBOARDING_COMPLETE_IMPORT_BUTTON: 'Importer des services',
  ONBOARDING_COMPLETE_REMIND_LATER: 'Rappelle-moi plus tard',
  ONBOARDING_COMPLETE_CONTINUE: `Allons-y`,
  ONBOARDING_COMPLETE_RESET_PASSWORD: 'Réinitialiser un mot de passe non sécurisé',
  ONBOARDING_COMPLETE_HEALTHCHECK_BUTTON: 'Exécuter un bilan de santé',
  ONBOARDING_COMPLETE_NEXT_STEPS: `<h2>Maintenant, pourquoi pas:</h2><ul><li>effectuez un Bilan de santé sur vos comptes en ligne?</li><li>Ou mettez à jour un autre service sur forghetti,</li><li>Ou jetez un œil aux alentours ...</li></ul>`,

  ONBOARDING_EXISTING_TITLE: 'Nouvelles fonctionnalités',
  ONBOARDING_EXISTING_WELCOME_HEADING: 'Un grand merci',
  ONBOARDING_EXISTING_WELCOME_TEXT: `L'équipe de forghetti a été très occupée. Nous avons apporté de nombreuses améliorations dans les coulisses et au début. Jetez un œil à certaines des nouvelles fonctionnalités.`,
  ONBOARDING_EXISTING_MOVE_SERVICES_HEADING: 'Services de déménagement',
  ONBOARDING_EXISTING_MOVE_SERVICES_TEXT: `forghetti vous permet désormais de déplacer des services d'un groupe à un autre. Cliquez simplement sur les trois points en regard de chaque service et sélectionnez déplacer.`,
  ONBOARDING_EXISTING_HEALTHCHECK_HEADING: 'Bilan de santé',
  ONBOARDING_EXISTING_HEALTHCHECK_TEXT: `forghetti inclut désormais un Bilan de santé, une fonctionnalité qui vous permet de surveiller activement vos noms d'utilisateur et mots de passe contre les violations de données bien connues.`,
  ONBOARIDNG_EXISTING_SHARING_HEADING: 'Partage de groupe rapide',
  ONBOARDING_EXISTING_SHARING_TEXT:
    'Partagez vos groupes forghetti avec vos amis, votre famille et vos collègues en un clic.',
  ONBOARDING_EXISTING_SWITCHING_HEADING: 'Changement de groupe rapide',
  ONBOARDING_EXISTING_SWTICHING_TEXT:
    'Travailler avec vos groupes est devenu plus facile que jamais. Ajouter de nouveaux services à un groupe est plus rapide et plus simple que jamais.',
  ONBOARDING_EXISTING_PRICING_HEADING: 'Mises à jour des prix',
  ONBOARDING_EXISTING_PRICING_TEXT: `forghetti propose désormais plus d'offres d'abonnement pour répondre à vos besoins.`,

  ACCOUNT_SECURITY_HEADER: 'Sécurité',
  ACCOUNT_SECURITY_HEADING: `Rien n'est plus sûr`,
  ACCOUNT_SECURITY_SECONDARY_HEADING: `Nous traitons la sécurité des données comme une priorité. forghetti vous permet de générer des connexions pour plusieurs services à l'aide d'un seul doodle. La partie la plus intelligente de tout cela est que dans les bases de données de forghetti, aucun mot de passe n’est stocké…. pas une. Contrairement à toutes les autres applications de gestion de mots de passe, et il y en a beaucoup, nous ne stockons pas vos mots de passe.  `,
  ACCOUNT_SECURITY_LEARN_MORE: 'En savoir plus sur la sécurité forghetti',

  MSG_FALLBACK_ERROR_MESSAGE: `Une erreur s'est produite! Désolé pour ça.`,

  MSG_GROUP_ICON_UPDATED: 'Icône mise à jour',
  MSG_COPIED_FORGHETTIBLE: (name) => `${name} copié dans le presse-papiers`,
  MSG_OPEN_FORGHETTIBLE: `Cliquez ici pour ouvrir`,
  MSG_DELETE_LOGIN_CONFIRM: 'Êtes-vous sûr de vouloir supprimer cette connexion?',
  MSG_LOGIN_DELETED: (name) => `Connexion ${name} supprimée`,
  MSG_SERVICE_ICON_DELETED: 'Icône de service mise à jour',
  MSG_LOGOUT_CONFIRM: 'Êtes-vous sûr de vouloir vous déconnecter?',
  MSG_LOGOUT_ALL_DEVICES_CONFIRM: 'Êtes-vous sûr?',
  MSG_USER_PROMOTED_ADMIN: `L'utilisateur a été promu administrateur.`,
  MSG_USER_PROMOTED_OWNER: 'La propriété a été transférée',

  MSG_CONFIRM_RESET: 'Veuillez confirmer que vous souhaitez réinitialiser ce mot de passe',
  MSG_CONFIRM_RESTORE: 'Veuillez confirmer que vous souhaitez restaurer cette version précédente?',
  MSG_NEW_GROUP_CREATED: (name) => `Un nouveau groupe a été créé: ${name}`,
  MSG_OPENING_WEBSITE: 'Ouverture du site Web ...',
  MSG_REMOVED_ADMIN: `Le statut d'administrateur a été révoqué pour cet utilisateur`,
  MSG_UNSAVED_CONFIRM: 'Il y a des modifications non enregistrées. Etes-vous sûr de vouloir y retourner?',

  EXTENSION_NONE_FOUND: 'Aucun objet forghettible trouvé pour ce site',
  EXTENSION_GO_TO_FORGHETTI: 'Aller à forghetti',
  EXTENSION_NOACCESS_HEADER: `Mettre à jour aujourd'hui`,
  EXTENSION_UPGRADE: `Vous êtes actuellement un utilisateur gratuit. Mettez à niveau dès aujourd'hui pour utiliser l'extension forghetti Chrome et bénéficier de connexions illimitées et de la possibilité de créer des groupes et de partager des connexions entre amis, famille et collègues. Si vous souhaitez passer à un abonnement Premium, veuillez sélectionner le bouton Mettre à niveau ci-dessous.`,
  EXTENSION_NOLOGINFOUND_HEADER: `Non trouvé à forghetti`,
  EXTENSION_NOLOGINFOUND_MSG: `Aucune 'forghettible' n'a été trouvée pour ce site. Dessinez un doodle ci-dessous pour créer rapidement un nouvel espace pour ce site web`,

  CONTEXT_EDIT_LOGIN_LIST_ITEM: 'Éditer',
  CONTEXT_MOVE_LOGIN_LIST_ITEM: 'Bouge toi',
  CONTEXT_ADD_LOGIN_LIST_ITEM: `Ajouter 'forghettible'`,
  CONTEXT_RESET_LOGIN_LIST_ITEM: `Réinitialiser 'forghettible'`,
  CONTEXT_DELETE_LOGIN_LIST_ITEM: 'Supprimer',
  CONTEXT_UPLOAD_GROUP_PHOTO: 'Prendre une photo',
  CONTEXT_UPLOAD_GROUP_IMAGE: `Télécharger l'image`,
  CONTEXT_CHOOSE_GROUP_IMAGE: 'Choisissez une image',
  CONTEXT_TAKE_PROFILE_PIC: 'Prendre une photo',
  CONTEXT_UPLOAD_PROFILE_PIC: 'Envoyer la photo',
  CONTEXT_UPLOAD_SERVICE_IMAGE: `Télécharger l'image`,
  CONTEXT_CHOOSE_SERVICE_IMAGE: 'Choisissez une image',
  CONTEXT_SERVICE_IMAGE_WEBSEARCH: 'Recherche Internet',
  CONTEXT_MAKE_ADMIN: 'Rendre administrateur',
  CONTEXT_MAKE_OWNER: 'Faire propriétaire',

  CONTEXT_REMOVE_FROM_GROUP: 'Supprimer du groupe',
  CONTEXT_REVOKE_ADMIN: `Révoquer l'administrateur`,
  CONTEXT_NEW_GROUP_MEMBERS: 'Nouveaux membres du groupe Max',
  CONTEXT_SELECT_FIELD_TYPE: 'Sélectionner le genre',
  CONTEXT_FIELD_TYPE_LNS: 'Lettres, chiffres et symboles',
  CONTEXT_FIELD_TYPE_WNS: 'Mots, chiffres et symboles',
  CONTEXT_FIELD_TYPE_LNX: 'Lettres, chiffres et symboles complexes',
  CONTEXT_FIELD_TYPE_WNX: 'Mots, nombres et symboles complexes',
  CONTEXT_FIELD_TYPE_LN: 'Lettres et chiffres',
  CONTEXT_FIELD_TYPE_WN: 'Mots et chiffres',
  CONTEXT_FIELD_TYPE_L: 'Lettres seulement',
  CONTEXT_FIELD_TYPE_W: 'Mots seulement',
  CONTEXT_FIELD_TYPE_N: 'Chiffres uniquement',
  CONTEXT_ADD_FIELD:
    'Sélectionnez un champ prédéfini ci-dessous. Vous pouvez personnaliser les paramètres de champ après si nécessaire.',
  CONTEXT_ADD_FIELD_PASS: 'Mot de passe (lettres, chiffres et symboles)',
  CONTEXT_ADD_FIELD_COMPLEX_PASS: 'Mot de passe avancé (lettres, chiffres et symboles complexes)',

  CONTEXT_ADD_FIELD_PIN: 'Pin (chiffres uniquement)',
  CONTEXT_ADD_FIELD_MEMWRD: 'Mot mémorable (mots uniquement)',
  CONTEXT_ADD_FIELD_FORGHETTIBLE: 'Lettres uniquement',
  CONTEXT_LINK_EXPIRE_15MINS: '15 minutes',
  CONTEXT_LINK_EXPIRE_30MINS: '30 minutes',
  CONTEXT_LINK_EXPIRE_1HOUR: '1 heure',
  CONTEXT_LINK_EXPIRE_6HOURS: '6 heures',
  CONTEXT_LINK_EXPIRE_12HOURS: '12 heures',
  CONTEXT_LINK_EXPIRE_1DAY: 'Un jour',
  CONTEXT_LINK_EXPIRE_NEVER: 'Jamais',
  CONTEXT_LINK_EXPIRE_CANCEL: 'Annuler',
  CONTEXT_LINK_USES_1: 'Une fois que',
  CONTEXT_LINK_USES_2: 'Deux fois',
  CONTEXT_LINK_USES_5: '5 fois',
  CONTEXT_LINK_USES_10: '10 fois',
  CONTEXT_LINK_USES_20: '20 fois',
  CONTEXT_LINK_USES_50: '50 fois',
  CONTEXT_LINK_USES_NO_LIMIT: 'Sans limites',
  CONTEXT_LINK_USES_CANCEL: 'Annuler',

  FINGERPRINT_TITLE: 'Bienvenue à forghetti',
  FINGERPRINT_DESCRIPTION: 'Scannez votre empreinte digitale pour déverrouiller forghetti',
  FINGERPRINT_DESCRIPTION_FACE: 'Utilisez Face ID pour déverrouiller forghetti',

  GENERATING_GROUP: 'Générer votre groupe',
  GENERATING_PASSWORD: 'Générer votre mot de passe',
  DELETING_GROUP: 'Supprimer un groupe',
  LOADING_SERVICE: 'Chargement de votre service',
  UNABLE_TO_PURCHASE: `Impossible d'acheter un abonnement premium pour le moment. Veuillez réessayer bientôt.`,

  HEALTHCHECK_WELCOME_HEADER: 'Bilan de santé',
  HEALTHCHECK_WELCOME_NOTE: `Exécutez l'évaluation forghetti Bilan de santé et découvrez si vos informations en ligne ont été compromises lors d'une violation de données.`,
  HEALTHCHECK_WELCOME_BUTTON: 'Exécuter un Bilan de santé',
  HEALTHCHECK_USERNAMES_HEADER: `Commençons par votre email`,
  HEALTHCHECK_USERNAMES_INFO_1: 'Vérifiez votre adresse e-mail',
  HEALTHCHECK_USERNAMES_INFO_2:
    'Faites-nous savoir votre adresse e-mail et nous la vérifierons contre tout piratage en ligne nouveau ou précédent.',
  HEALTHCHECK_USERNAMES_PRIMARY_SUBHEAD: 'Votre adresse e-mail principale',
  HEALTHCHECK_USERNAMES_SECONDARY_SUBHEAD: 'Votre adresse e-mail secondaire',
  HEALTHCHECK_USERNAMES_PLACEHOLDER: 'Adresse e-mail',
  HEALTHCHECK_PASSWORD_HEADER: `Vérifions votre mot de passe`,
  HEALTHCHECK_PASSWORD_INFO_1: 'Utilisez souvent le même mot de passe?',
  HEALTHCHECK_PASSWORD_INFO_2: `Avant d'utiliser forghetti, utilisiez-vous souvent le même mot de passe?
Voyons s'il a été exposé lors d'une violation de données.`,
  HEALTHCHECK_PASSWORD_INFO_3: `Cette vérification est facultative. forghetti ne stocke ni ne transmet votre mot de passe. Nous générerons immédiatement un hachage sécurisé de votre mot de passe. Seule une partie du hachage de votre mot de passe est transmise par l'application afin que vous puissiez être sûr que votre mot de passe est en sécurité avec nous.`,

  HEALTHCHECK_PASSWORD_SUBHEAD: 'Votre mot de passe',
  HEALTHCHECK_PASSWORD_OPTIONAL: 'Ceci est facultatif.',
  HEALTHCHECK_PASSWORD_PLACEHOLDER: 'Mot de passe',
  HEALTHCHECK_PASSWORD_VAL_TOO_LONG: 'Champ de mot de passe trop long',

  HEALTHCHECK_RUNNING: 'Exécution de votre bilan de santé',
  HEALTHCHECK_USERNAME_VAL_FIELD_BLANK: `'L'adresse e-mail ne peut pas être vide`,
  HEALTHCHECK_USERNAME_VAL_FIELD_DUPLICATE: `L'adresse e-mail secondaire ne peut pas être la même que la première`,
  HEALTHCHECK_USERNAME_VAL_FIELD_TOO_SHORT: 'Adresse e-mail trop courte',
  HEALTHCHECK_USERNAME_VAL_FIELD_TOO_LONG: 'Adresse e-mail trop longue',

  HEALTHCHECK_ASSESSMENT_HEADER: 'Bilan de santé',
  HEALTHCHECK_ASSESSMENT_EMAIL: (email) => `<em>Infractions pour ${email}</em>`,
  HEALTHCHECK_ASSESSMENT_INFO_1: 'Les résultats de votre Bilan de santé sont affichés ci-dessous.',
  HEALTHCHECK_ASSESSMENT_INFO_2: `Les utilisateurs Premium peuvent cliquer sur "effraction" pour obtenir plus d'informations.`,

  HEALTHCHECK_ASSESSMENT_PASSWORD: `<em><em>Résultats pour votre mot de passe</em></em>`,
  HEALTHCHECK_ASSESSMENT_COMPROMISED_HEAD: `Services compromis`,
  HEALTHCHECK_ASSESSMENT_NO_BREACH: 'Aucun service piraté avec ce compte',
  HEALTHCHECK_ASSESSMENT_PASSWORD_STRENGTH: 'Force',
  HEALTHCHECK_ASSESSMENT_PASSWORD_WEAK: 'Faible',
  HEALTHCHECK_ASSESSMENT_PASSWORD_STRONG: 'Fort',
  HEALTHCHECK_ASSESSMENT_PASSWORD_COMP: 'Apparaît dans une violation de données',
  HEALTHCHECK_ASSESSMENT_HIBP_LINK: 'source: <a data-actionclick="openHIBP">haveIbeenpwned</a>',
  HEALTHCHECK_ASSESMENT_UPGRADE_MESSAGE:
    'Les détails de la violation ne sont disponibles que pour les utilisateurs premium',
  HEALTHCHECK_DETAILS_PASSWORD_HEADER: 'Résultats du mot de passe',
  HEALTHCHECK_DETAILS_USER_HEADER: `Détails de la violation`,
  HEALTHCHECK_DETAILS_USER_TITLE: 'Nom',
  HEALTHCHECK_DETAILS_USER_DOMAIN: 'Domaine',
  HEALTHCHECK_DETAILS_USER_DATE: 'Date de violation',
  HEALTHCHECK_DETAILS_USER_ACCOUNTS_PWNED: 'Comptes violés',
  HEALTHCHECK_DETAILS_PASSWORD_INFO_X_BREACH: (breach) =>
    `Votre mot de passe a été répertorié dans <em>${breach}</em> violations de données bien connues. Cela ne signifie pas nécessairement que vos comptes ont été compromis. Vous devriez envisager de générer un mot de passe super fort avec <em>forghetti</em> dès que possible.`,
  HEALTHCHECK_DETAILS_PASSWORD_INFO_NO_BREACH: `Bonne nouvelle! Votre mot de passe n'est répertorié sur aucune violation de données connue. Cela ne garantit pas que vos comptes n'ont pas été compromis. Pensez à générer un nouveau mot de passe super fort avec <em>forghetti</em>.`,
  HEALTHCHECK_DETAILS_PASSWORD_STRENGTH_INFO_STRONG: `Votre mot de passe est <em>fort</em>. Cela signifie qu'il a passé tous les critères définis par le test de résistance du mot de passe forghetti`,
  HEALTHCHECK_DETAILS_PASSWORD_STRENGTH_INFO_WEAK: `Votre mot de passe est <em>faible</em>. Cela signifie qu'il échoue aux critères suivants définis par le test de résistance du mot de passe forghetti:`,
  HEALTHCHECK_DETAILS_RESET_NOTE: 'Générez un mot de passe sécurisé avec forghetti maintenant',
  HEALTHCHECK_DETAILS_RESET_BUTTON: 'Créer un nouveau service',
  HEALTHCHECK_DETAILS_HIBP_LINK: 'source: haveIbeenpwned',
  HEALTHCHECK_LOADING_DETAIL: 'Chargement des détails de la violation',

  IMPORT_SERVICE_WELCOME_BUTTON: 'Commencer à importer des services',
  IMPORT_SERVICE_WELCOME_INFO: 'Importez vos informations de connexion existantes dans forghetti',
  IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_1: `Pour importer des services, veuillez ouvrir l'application de bureau forghetti.`,
  IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_2: `Si vous n'avez pas l'application de bureau forghetti, cliquez sur le lien ci-dessous`,
  IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_BUTTON: 'Télécharger forghetti',
  IMPORT_SERVICES_LIST_HEADER: 'Sélectionnez les services à importer',
  IMPORT_SERVICES_LIST_ERROR_NONE_SELECTED: 'Aucun service importé sélectionné',
  IMPORT_SERVICES_YOUR_IMPORTED_SERVICES: 'Vos services importés',
  IMPORT_SERVICES_LIST_NOTE: `Choisissez les services que vous souhaitez importer dans forghetti.`,
  IMPORT_SERVICES_GROUP_NOTE: 'Sélectionnez un groupe vers lequel vous souhaitez déplacer vos services',
  IMPORT_SERVICES_GROUP_HEADER: 'Sélectionnez un groupe',
  IMPORT_SERVICES_USERNAME_HEADER: `Entrez les noms d'utilisateur`,
  IMPORT_SERVICES_USERNAME_NOTE: `Vérifiez les noms d'utilisateur associés à chaque compte`,
  IMPORT_SERVICES_CREATE_HEADER: 'Créer des services',
  IMPORT_SERVICES_CREATE_BUTTON: 'Commencer à créer des services',
  IMPORT_SERVICES_CREATE_HOW_MANY: (number) => `<em>${number}</em> services à créer`,
  IMPORT_SERVICES_CREATE_LOADING: (current, total) => `<em>${current}</em> services sur <em> ${total}</em> importés`,
  IMPORT_SERVICES_COMPLETE_HEADER: 'Importation terminée',
  IMPORT_SERVICES_COMPLETE_NOTE: `Toutes nos félicitations! Vos services ont été importés`,
  IMPORT_FOLDER_SELECT_HEADER: 'Importer depuis Chrome',
  IMPORT_FOLDER_SELECT_CHROME: 'Importer des services depuis Chrome',
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC: `forghetti devra accéder à vos données de connexion stockées sur votre ordinateur.`,
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_2: `Lorsque vous êtes prêt, veuillez cliquer sur le bouton "Sélectionnez votre dossier Chrome" ci-dessous. Une boîte de dialogue apparaîtra à l'emplacement de vos données de connexion Chrome. Sélectionnez simplement «Ouvrir» pour permettre à forghetti d'accéder à ce dossier.`,
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_3:
    'Si vous souhaitez localiser ce dossier manuellement, accédez simplement à Libary / Application Support / Google / Chrome',
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_4: `N'oubliez pas que vos mots de passe existants ne sont pas déchiffrés par forghetti. forghetti ne récupère que vos données de connexion.`,
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_5:
    'Une fois vos données de connexion lues, vous pourrez sélectionner les services que vous souhaitez importer dans forghetti.',

  IMPORT_FOLDER_SELECT_CHROME_BUTTON: 'Sélectionnez votre dossier Chrome',

  IMPORT_GROUP_HEADER: 'Votre groupe de services importé',
  IMPORT_GROUP_NOTE: (importingServices) =>
    `Un groupe spécial <em>Services importés</em> est automatiquement créé pour vous. Le groupe contiendra les <em>${importingServices}</em> services que vous avez sélectionnés pour l'importation.`,
  IMPORT_GROUP_NOTE_2: `Les services de ce groupe ne sont pas activés. Pour activer les services, vous devrez déplacer les services vers un autre groupe. Cliquez sur Continuer pour accéder à l'assistant d'importation des services. L'assistant de services d'importation rendra le processus de déplacement de vos services d'importation rapide et facile.`,
  IMPORT_GROUP_NOTE_3: `Si vous n'avez pas le temps de déplacer tous vos services importés pour le moment, ne vous inquiétez pas. Vous pouvez déplacer vos services importés via l'assistant d'importation de services à tout moment et sur n'importe quel appareil.`,
  IMPORT_GROUP_BUTTON: 'Continuer',

  IMPORT_SERVICE_IMPORT_NODATA_HEADER: 'Impossible de trouver les données de connexion',
  IMPORT_SERVICE_IMPORT_NODATA_NOTE: `forghetti n'a trouvé aucune donnée de connexion pouvant être utilisée pour l'importation`,

  IMPORT_MOVE_SERVICE_HEADER: 'Commencez à déplacer vos services',
  IMPORT_MOVE_SERVICE_NOTE_1: (services) =>
    `Vous avez ${services} dans votre groupe de services importé. Pour commencer à activer vos services importés, vous devez les déplacer vers un autre groupe.`,
  IMPORT_MOVE_SERVICE_BUTTON: 'Continuer',
  IMPORT_SERVICE_LOADING: 'Importer vos services',
  IMPORT_SERVICE_GROUP_NAME: 'Importer des services',
  NEW_GROUP_REQUIRES_PREMIUM: `La création d'un nouveau groupe nécessite un abonnement premium.`,

  IMPORT_TYPE_HEADER: `Sélectionnez le type d'importation`,
  IMPORT_TYPE_NOTE_1: `forghetti peut importer automatiquement des services à partir de vos données de connexion Google Chrome. Si vous utilisez Google Chrome et souhaitez importer des services à l'aide de cette méthode, cliquez sur le bouton "Importer Google Chrome" ci-dessous.`,
  IMPORT_TYPE_BUTTON_1: `Importer Google Chrome`,
  IMPORT_TYPE_NOTE_2: `Vous pouvez également importer vos identifiants en téléchargeant un document CSV ou Excel (XLSX). Pour commencer à importer des services à l'aide de cette fonctionnalité, cliquez sur le bouton «Télécharger le document» ci-dessous.`,
  IMPORT_TYPE_BUTTON_2: 'Télécharger le document',
  IMPORT_TYPE_NOTE_3: `Si vous utilisez principalement safari, vous souhaiterez peut-être utiliser la fonctionnalité de téléchargement de document pour importer vos services. Pour trouver les mots de passe de votre trousseau safari, ouvrez «Préférences» (command + ',') dans le navigateur Safari et accédez à l'onglet «Mots de passe»,`,

  IMPORT_UPLOAD_HEADER: 'Télécharger le document',
  IMPORT_UPLOAD_NOTE_1: `Téléchargez un fichier CSV ou une feuille de calcul (XLSX) pour commencer à importer des services. Au minimum, tout ce dont vous avez besoin est le nom d'un service.`,
  IMPORT_UPLOAD_BUTTON: 'Téléchargez votre document',
  IMPORT_UPLOAD_NOTE_2: `Téléchargez l'exemple CSV ou Excel pour commencer.`,
  IMPORT_UPLOAD_SAMPLE_CSV: 'Télécharger un exemple de CSV',
  IMPORT_UPLOAD_SAMPLE_XLS: 'Télécharger un exemple Excel',

  // ===========================================================================
  // Texte d'aide
  // ===========================================================================

  //NO_LOGINS_MESSAGE: `Pour commencer, appuyez sur `` Ajouter une connexion '' dans le coin supérieur droit pour ajouter une connexion à un service que vous utilisez (par exemple, Amazon, Facebook, Barclays).

  NO_LOGINS_MESSAGE: `Ajoutez des services à ce groupe en cliquant sur l'icône + (plus)

Partagez ce groupe avec vos amis, collègues ou famille en cliquant sur l'icône de partage (en haut à droite)

Vous pouvez modifier le nom de ce groupe et gérer qui a accès dans les paramètres du groupe

`,

  LOGIN_RECENT: 'Récent',
  HELP_LOGIN_TITLE: `Saisissez un titre pour votre connexion (par exemple, Amazon). Ajoutez un logo en appuyant sur l'icône de l'appareil photo. Si vous ne sélectionnez pas une icône, une icône par défaut sera créée pour vous.`,
  HELP_LOGIN_USERNAME: `Entrez le nom d'utilisateur que vous utilisez pour votre connexion. Cela peut être votre adresse e-mail par exemple. Si vous le faites, cela vous permettra de le copier et de le coller facilement à partir de l'écran de résumé de connexion.`,
  HELP_LOGIN_WEBSITE: `Facultativement, entrez l'adresse du site Web de votre connexion. Si vous le faites, cela vous permettra d'ouvrir le site Web directement à partir de l'écran de résumé de connexion.`,
  HELP_LOGIN_ADD_FORGHETTIBLE: `Ajoutez soit un mot de passe standard (la valeur par défaut est complexe et longue, vous n'avez donc pas besoin de le modifier du tout), un mot mémorable ou un code PIN ici. Si vous voulez plus de contrôle, ajoutez un forghettible personnalisé.

Vous devez en ajouter au moins un pour continuer. Ensuite, appuyez simplement sur Suivant dans le coin supérieur droit de l'écran.`,

  HELP_GROUPS_LIST_HEADER: `Appuyez sur le signe + pour créer ou rejoindre un groupe. Les abonnés gratuits ne peuvent rejoindre qu'un groupe d'abonnés payants.

Vous pouvez utiliser des groupes pour gérer différentes catégories de connexion ou pour partager un groupe de connexions.

N'oubliez pas que vous devrez partager le groupe Doodle avec tous les membres afin qu'ils puissent générer les mêmes mots de passe que vous. Pensez donc à utiliser un mot, une phrase ou un code mémorable qui est simple à rappeler.
`,

  HELP_GROUPS_LIST_PERSONAL: `Ceci est votre groupe personnel unique. Vous ne pouvez pas le supprimer ni le partager avec quelqu'un d'autre.`,
  HELP_GROUPS_LIST_OTHER_GROUPS: `Il s'agit de tous les autres groupes que vous avez créés en tant qu'abonné Premium ou que vous avez rejoint après avoir été invité par quelqu'un d'autre`,

  HELP_DOODLEPAD: `Dessinez un doodle dont vous vous souviendrez. Lignes connectées, points ou combinaison des deux. Plus il y a de couches, plus il est sécurisé. Vous devez cependant vous en souvenir car c'est la clé pour pouvoir générer vos mots de passe maintenant et pour toujours.

Inquiet des regards indiscrets? Appuyez sur "Doodle secret" et dessinez sans laisser de trace. Faites une erreur et vous pouvez recommencer en appuyant sur «Redoodle». Appuyez ensuite sur la coche pour générer votre mot de passe.`,

  HELP_SERVICE_SUMMARY: `Appuyez sur l'œil pour afficher le mot de passe. Appuyez sur votre mot de passe pour le copier dans le presse-papiers. Accédez au site Web du service et réinitialisez votre mot de passe actuel avec ce nouveau.

Alors tu peux l'oublier. La prochaine fois que vous en aurez besoin, dessinez simplement le doodle et forghetti le générera à nouveau. Il n'est enregistré nulle part, mais généré lorsque vous en avez besoin avec votre doodle.
`,

  HELP_FIELD_SETTINGS_LENGTH: `Choisissez la longueur du mot de passe comme le nombre de caractères - utilisé pour un mot de passe ou un code PIN standard - ou le nombre de mots, si vous utilisez des mots mémorables.`,
  HELP_FIELD_SETTINGS_TYPE: `Choisissez le type de forghettible comme une combinaison de lettres, symboles, chiffres et mots.`,
  HELP_FIELD_SETTINGS_NAME: `Entrez un nom descriptif pour votre mot de passe obligatoire. Notez que ce n'est pas le mot de passe lui-même, car il est généré automatiquement par forghetti.`,
  HELP_FIELD_SETTINGS_RESET: `Si vous avez besoin de changer un mot de passe généré par forghetti à tout moment, cliquez simplement sur réinitialiser et la prochaine fois que vous dessinez votre Doodle, le mot de passe généré aura changé.`,
  HELP_RESTORE_PAGE: `Les paramètres des anciennes versions des mots de passe forghetti peuvent être restaurés en cliquant sur la date.

Si vous faites cela, lorsque vous dessinez votre Doodle, votre ancien mot de passe sera généré.

Vous pouvez basculer comme vous le souhaitez!
`,
  HELP_LOGINS_LIST: `Cliquez sur un identifiant pour générer votre mot de passe.

Utilisez l'outil de recherche pour trouver la connexion dont vous avez besoin.
`,
  HELP_GROUP_PAGE: `Faites défiler vers le bas pour supprimer le groupe, pour basculer pour voir les connexions pour ce groupe ou pour quitter un groupe dont vous ne souhaitez plus être membre.`,
  HELP_GROUP_SHARING: `Créez une invitation à partager avec un ami ou un collègue.`,
  HELP_GROUP_MEMBERS: `Gérez les autorisations des utilisateurs du groupe, faites-en des utilisateurs administrateurs ou supprimez-les complètement.`,
  HELP_GROUP_INVITE_MAX_USES: `Contrôlez la durée et le nombre de fois qu'une invitation peut être utilisée avant de l'envoyer à vos amis et collègues.`,

  // ===========================================================================
  // Emails
  // ===========================================================================

  REFERRAL_EMAIL_SUBJECT: `Une invitation à utiliser forghetti, car rien n'est plus sûr`,
  REFERRAL_EMAIL_BODY: (url) =>
    `Salut,

J'utilise une excellente application appelée forghetti. Cela m'a aidé à ne plus jamais oublier mes mots de passe. Vos mots de passe ne sont stockés nulle part.

Vous générez vos mots de passe lorsque vous en avez besoin en créant un doodle. Dessinez le même doodle et vous obtenez le même mot de passe à chaque fois. Si simple, mais si intelligent.

Utilisez ce lien de parrainage pour vous inscrire et bénéficier de fonctionnalités étendues pour vous et moi!
${url}

Amusez-vous`,

  // ===========================================================================

  GRP_INVITE_EMAIL_SUBJECT: 'invitation de groupe forghetti',
  GRP_INVITE_EMAIL_BODY: (inviteUrl, referralUrl, referralCode) =>
    `Salut, je voudrais partager mon groupe forghetti avec vous. Cliquez sur le lien suivant pour ouvrir forghetti et rejoindre mon groupe:
${inviteUrl}

Si vous n'avez pas forghetti, ajoutez ce code de référence ${referralCode} dans votre profil sur l'application et obtenez plus de temps premium pour vous et moi! Oubliez vos mots de passe pour toujours!`,

  APPRATE_TITLE: 'Pourriez-vous évaluer forghetti',
  APPRATE_MESSAGE: `Cela ne prendra pas plus d'une minute et aide à promouvoir notre application. Merci pour votre aide`,
  APPRATE_CANCEL: 'Non merci',
  APPRATE_LATER: 'Rappelle-moi plus tard',
  APPRATE_RATE: 'Évaluer forghetti',
  APPRATE_YES: 'Oui',
  APPRATE_NO: 'Pas vraiment',
  APPRATE_PROMPT_TITLE: 'Aimez-vous utiliser forghetti',
  APPRATE_FEEDBACK: 'Voulez-vous nous faire part de vos commentaires?',

  BADGE_HEALTHCHECK_HEADER: 'Exécuter un bilan de santé',
  BADGE_HEALTHCHECK_INFO: `Obtenez ce badge en remplissant le nom d'utilisateur et le mot de passe forghetti Bilan de santé`,
  BADGE_HEALTHCHECK_COMPLETE_INFO:
    'Toutes nos félicitations! Vous avez obtenu ce badge en effectuant le bilan de santé forghetti',
  BADGE_HEALTHCHECK_REWARD: (premium) =>
    premium ? 'Obtenez 2 services supplémentaires pour votre groupe personnel' : '',
  BADGE_HEALTHCHECK_CTA: 'Exécutez un bilan de santé maintenant',

  BADGE_RATING_HEADER: 'Évaluer forghetti',
  BADGE_RATING_INFO: 'Gagnez cette vignette en notant forghetti',
  BADGE_RATING_COMPLETE_INFO: 'Toutes nos félicitations! Vous avez gagné cette vignette en notant forghetti',
  BADGE_RATING_REWARD: (premium) => (premium ? 'Obtenez 2 services supplémentaires pour votre groupe personnel' : ''),
  BADGE_RATING_CTA: 'Évaluez forghetti maintenant',

  BADGE_PROFILECOMPLETE_HEADER: 'Complète ton profil',
  BADGE_PROFILECOMPLETE_INFO: 'Gagnez cette vignette en complétant votre profil forghetti.',
  BADGE_PROFILECOMPLETE__COMPLETE_INFO:
    'Toutes nos félicitations! Vous avez gagné cette vignette en complétant votre profil forghetti.',
  BADGE_PROFILECOMPLETE_REWARD: (premium) =>
    premium ? 'Obtenez 2 services supplémentaires pour votre groupe personnel' : '',
  BADGE_PROFILECOMPLETE_CTA: 'Complétez votre profil maintenant',

  BADGE_SHARING_HEADER: 'Partagez forghetti sur Facebook',
  BADGE_SHARING_INFO: 'Gagnez cette vignette en aimant et en partageant forghetti sur vos réseaux sociaux',
  BADGE_SHARING_COMPLETE_INFO:
    'Toutes nos félicitations! Vous avez gagné cette vignette en aimant et en partageant forghetti sur vos réseaux sociaux',
  BADGE_SHARING_REWARD: (premium) => (premium ? 'Obtenez 2 services supplémentaires pour votre groupe personnel' : ''),
  BADGE_SHARING_CTA: 'Partagez forghetti sur Facebook',

  BADGE_IMPORT_HEADER: 'Importer des services',
  BADGE_IMPORT_INFO:
    'Gagnez cette vignette lorsque vous importez des services à partir de votre trousseau de téléphone ou de PC',
  BADGE_IMPORT_COMPLETE_INFO:
    'Toutes nos félicitations! Vous avez obtenu ce badge lorsque vous avez importé des services depuis votre trousseau de téléphone ou de PC',
  BADGE_IMPORT_REWARD: (premium) => (premium ? 'Obtenez 2 services supplémentaires pour votre groupe personnel' : ''),
  BADGE_IMPORT_CTA: 'Commencez à importer des services maintenant ',

  BADGE_SUPERUSER_HEADER: 'Devenez un super utilisateur',
  BADGE_SUPERUSER_INFO: `Gagnez cette vignette lorsque vous avez partagé avec succès forghetti avec 5 amis, vous avez plus de deux groupes, vous avez un compte premium et vous avez plus de 50 services sur votre compte forghetti`,
  BADGE_SUPERUSER_COMPLETE_INFO: `Toutes nos félicitations! Vous avez gagné cette vignette en partageant forghetti avec 5 amis, en ajoutant plus de deux groupes, en vous abonnant à un compte premium et en créant plus de 50 services`,
  BADGE_SUPERUSER_REWARD: (premium) =>
    premium ? 'Obtenez 2 services supplémentaires pour votre groupe personnel' : '',
  BADGE_SUPERUSER_CTA: 'Partagez forghetti avec un ami',

  BADGE_SERVICES_50_HEADER: '50 services!',
  BADGE_SERVICES_50_INFO: 'Gagnez cette vignette en créant 50 services dans votre compte forghetti',
  BADGE_SERVICES_50_COMPLETE_INFO:
    'Toutes nos félicitations! Vous avez gagné cette vignette en créant plus de 50 services dans votre compte forghetti',
  BADGE_SERVICES_50_REWARD: (premium) =>
    premium ? 'Obtenez 2 services supplémentaires pour votre groupe personnel' : '',
  BADGE_SERVICES_50_CTA: 'Créez un service maintenant',

  BADGE_SERVICES_150_HEADER: '150 services!',
  BADGE_SERVICES_150_INFO: 'Gagnez cette vignette en créant 150 services dans votre compte forghetti',
  BADGE_SERVICES_150_COMPLETE_INFO:
    'Toutes nos félicitations! Vous avez gagné cette vignette en créant plus de 150 services dans votre compte forghetti',
  BADGE_SERVICES_150_REWARD: (premium) =>
    premium ? 'Obtenez 2 services supplémentaires pour votre groupe personnel' : '',
  BADGE_SERVICES_150_CTA: 'Créez un service maintenant',

  BADGE_SERVICES_300_HEADER: '300 services!',
  BADGE_SERVICES_300_INFO: 'Gagnez cette vignette en créant 300 services dans votre compte forghetti',
  BADGE_SERVICES_300_COMPLETE_INFO:
    'Toutes nos félicitations! Vous avez gagné cette vignette en créant plus de 300 services dans votre compte forghetti',
  BADGE_SERVICES_300_REWARD: (premium) =>
    premium ? 'Obtenez 2 services supplémentaires pour votre groupe personnel' : '',
  BADGE_SERVICES_300_CTA: 'Créez un service maintenant',

  BADGE_SERVICES_500_HEADER: '500 services!',
  BADGE_SERVICES_500_INFO: 'Gagnez cette vignette en créant 500 services dans votre compte forghetti',
  BADGE_SERVICES_500_COMPLETE_INFO:
    'Toutes nos félicitations! Vous avez gagné cette vignette en créant plus de 500 services dans votre compte forghetti',
  BADGE_SERVICES_500_REWARD: (premium) =>
    premium ? 'Obtenez 2 services supplémentaires pour votre groupe personnel' : '',
  BADGE_SERVICES_500_CTA: 'Créez un service maintenant',

  BADGE_SHARING_1_HEADER: 'Partagez forghetti avec un ami',
  BADGE_SHARING_1_INFO: `Gagnez cette vignette lorsque vous parrainez un ami à forghetti et qu'il crée un compte!`,
  BADGE_SHARING_1_COMPLETE_INFO: `Toutes nos félicitations! Vous avez gagné cette vignette lorsque vous avez parrainé un ami chez forghetti et qu'il a créé un compte!`,
  BADGE_SHARING_1_REWARD: (premium) =>
    premium ? 'Obtenez 2 services supplémentaires pour votre groupe personnel' : '',
  BADGE_SHARING_1_CTA: 'Partagez forghetti avec un ami',

  BADGE_SHARING_5_HEADER: 'Partager forghetti avec 5 amis',
  BADGE_SHARING_5_INFO: `Gagnez cette vignette lorsque vous parrainez 5 amis à forghetti et qu'ils créent un compte!`,
  BADGE_SHARING_5_COMPLETE_INFO: `Toutes nos félicitations! Vous avez gagné cette vignette lorsque vous avez parrainé 5 amis chez forghetti et qu'ils ont créé des comptes!`,
  BADGE_SHARING_5_REWARD: (premium) =>
    premium ? `Obtenez 2 services supplémentaires pour votre groupe personnel` : ``,
  BADGE_SHARING_5_CTA: 'Partagez forghetti avec un ami',

  BADGE_SHARING_10_HEADER: 'Partager forghetti avec 10 amis',
  BADGE_SHARING_10_INFO: `Gagnez cette vignette lorsque vous parrainez 10 amis à forghetti et qu'ils créent un compte!`,
  BADGE_SHARING_10_COMPLETE_INFO: `Toutes nos félicitations! Vous avez gagné cette vignette lorsque vous avez parrainé 10 amis chez forghetti et qu'ils ont créé des comptes!`,
  BADGE_SHARING_10_REWARD: (premium) =>
    premium ? 'Obtenez 2 services supplémentaires pour votre groupe personnel' : '',
  BADGE_SHARING_10_CTA: 'Partagez forghetti avec un ami',

  BADGE_SHARING_25_HEADER: 'Partager forghetti avec 25 amis',
  BADGE_SHARING_25_INFO: `Gagnez cette vignette lorsque vous parrainez 25 amis à forghetti et qu'ils créent un compte!`,
  BADGE_SHARING_25_COMPLETE_INFO: `Toutes nos félicitations! Vous avez gagné cette vignette lorsque vous avez parrainé 25 amis chez forghetti et qu'ils ont créé des comptes!`,
  BADGE_SHARING_25_REWARD: (premium) =>
    premium ? 'Obtenez 2 services supplémentaires pour votre groupe personnel' : '',
  BADGE_SHARING_25_CTA: 'Partagez forghetti avec un ami',

  BADGE_SHARING_50_HEADER: 'Partagez forghetti avec 50 amis',
  BADGE_SHARING_50_INFO: `Gagnez cette vignette lorsque vous parrainez 50 amis à forghetti et qu'ils créent un compte!`,
  BADGE_SHARING_50_COMPLETE_INFO: `Toutes nos félicitations! Vous avez gagné cette vignette lorsque vous avez parrainé 50 amis chez forghetti et qu'ils ont créé des comptes!`,
  BADGE_SHARING_50_REWARD: (premium) =>
    premium ? 'Obtenez 2 services supplémentaires pour votre groupe personnel' : '',
  BADGE_SHARING_50_CTA: 'Partagez forghetti avec un ami',

  BADGE_GROUP_1_HEADER: 'Créer un nouveau groupe dans forghetti',
  BADGE_GROUP_1_INFO: 'Gagnez cette vignette lorsque vous créez et partagez un nouveau groupe au sein de forghetti',
  BADGE_GROUP_1_COMPLETE_INFO:
    'Toutes nos félicitations! Vous avez gagné cette vignette lorsque vous avez créé et partagé un nouveau groupe au sein de forghetti',
  BADGE_GROUP_1_REWARD: (premium) => (premium ? 'Obtenez 2 services supplémentaires pour votre groupe personnel' : ''),
  BADGE_GROUP_1_CTA: 'Créez un groupe maintenant',

  BADGE_GROUP_5_HEADER: 'Créer 5 groupes dans forghetti',
  BADGE_GROUP_5_INFO: 'Gagnez cette vignette lorsque vous créez et partagez 5 groupes dans forghetti',
  BADGE_GROUP_5_COMPLETE_INFO:
    'Toutes nos félicitations! Vous avez gagné cette vignette en créant et partageant 5 groupes au sein de forghetti',
  BADGE_GROUP_5_REWARD: (premium) => (premium ? 'Obtenez 2 services supplémentaires pour votre groupe personnel' : ''),
  BADGE_GROUP_5_CTA: 'Créez un groupe maintenant',

  BADGE_LEGACY_HEADING: `Vous êtes une superstar!`,
  BADGE_LEGACY_INFO_COMPLETE: `Merci beaucoup d'être un utilisateur de forghetti à long terme. En guise de remerciement, vous avez reçu ce badge spécial et 3 mois d'abonnement premium gratuits!`,

  JOIN_GORUP_ALERT_MESSAGE: `Nous vous ajoutons simplement au groupe. Nous vous tiendrons au courant quand ce sera fait.`,
  GROUP_UNAVAILABLE: (groupName) =>
    `Le nombre de billets gratuits sur <em>${groupName}</em> a été dépassé. Pour ajouter des services au groupe, pensez à effectuer une mise à niveau dès aujourd'hui.`,
  GROUP_PERSONAL_UNAVAILABLE: (groupName) =>
    `Le nombre de forghettibles gratuits sur votre groupe personnel a été dépassé. Pour ajouter des services au groupe, pensez à effectuer une mise à niveau dès aujourd'hui.`,
  GROUP_NONE_AVAILABLE: 'Tous vos groupes ont dépassé la limite autorisée',

  SHARE_INVITE_USERNAME_HEADING: 'Avant de commencer à partager',
  SHARE_INVITE_USERNAME_INFO:
    'Afin que vos amis puissent identifier les groupes que vous souhaitez partager, veuillez nous fournir un nom de profil pour votre compte',
  SHARE_INVITE_USERNAME_PLACEHOLDER: 'Votre nom',

  LEGACY_USER_ONBOARDING_HEADING: 'Nous apprécions votre fidélité',
  LEGACY_USER_ONBOARDING_INFO:
    'En récompense, nous vous offrons un abonnement premium de 3 mois absolument gratuit! Nous espérons que vous apprécierez encore plus la nouvelle version de forghetti que la précédente.',
  CLAIM_PREMIUM: 'Réclamer votre récompense',
  LEGACY_USER_RELOAD: `Afin d'appliquer l'abonnement premium, nous devons recharger forghetti. Cela se produira automatiquement dans quelques secondes.`,

  SERVICE_OPEN_ICON_LABEL: 'ouvert',
  SERVICE_VIEW_ICON_LABEL: 'vue',
  SERVICE_HIDE_ICON_LABEL: 'cacher',
  SERVICE_WEBSITE_ICON_LABEL: 'ouvert',
  SERVICE_COPY_ICON_LABEL: 'copie',

  GROUPSLIST_SWITCH_LABEL: 'commutateur',
  GROUPSLIST_SHARE_LABEL: 'inviter',
  GROUPSLIST_SETTINGS_LABEL: 'réglages',

  FORGHETTI_GROUP_PERMISSIONS: `Cliquez sur <a data-actionclick="openPermissionsLink">ce lien</a> pour en savoir plus sur les autorisations de groupe.`,

  SUBSCRIPTION_TANDC: `Veuillez noter que votre abonnement sera automatiquement renouvelé à moins qu'il ne soit annulé avant la fin de la période en cours. Vous pouvez gérer et annuler votre abonnement - qui désactive le renouvellement automatique - en accédant à votre compte App Store après l'achat. Pour plus d'informations, veuillez consulter nos <a data-actionclick="openTermsOfServiceUrl">conditions d'utilisation</a> et notre <a data-actionclick="openPrivacyPolicyUrl">politique de confidentialité</a>`,
  SUBSCRIPTION_TANDC_LIFETIME: `Veuillez noter que forghetti premium lifetime vous donne droit à ... Pour plus d'informations, veuillez consulter nos <a data-actionclick="openTermsOfServiceUrl">conditions d'utilisation</a> et notre <a data-actionclick="openPrivacyPolicyUrl">politique de confidentialité</a>`,
  SUBSCRIPTION_ERROR: `Une erreur s'est produite lors de la validation de vos abonnements. Veuillez réessayer plus tard.`,

  SUBSCRIPTION_TANDC_HEADER: 'Votre abonnement',
  SERVICE_INJECT_LABEL: 'Utilisation',
  PHONE_NUMBER_TOO_LONG: 'Numéro de téléphone invalide',

  ADD_SERVICE_LOADING_PLEASE_WAIT: 'Veuillez patienter pendant que nous générons votre service forghetti',
  ADD_SERVICE_LOADING_GENERATING_SERVICE: 'Générer votre nouveau service',
  ADD_SERVICE_LOADING_GENERATING_PASSWORD: 'Générer un mot de passe super sécurisé',
  ADD_SERVICE_LOADING_LOADING_SERVICE_DETAILS: 'Chargement de votre nouveau service forghetti',

  VIEW_SERVICE_LOADING_LOADING_SERVICE_DETAIL: 'Veuillez patienter pendant que nous chargeons votre service',
  VIEW_SERVICE_LOADING_LOADING_SERVICE_FORGHETTIBLE: 'Construire vos données prévisibles',

  MIGRATION_ALERT_HEADING: 'Migrez votre compte',
  MIGRATION_ALERT_MESSAGE: `Pour modifier votre numéro, veuillez vous rendre sur notre application Web à l'adresse <a href="https://app.forghetti.com" target="_new">https://app.forghetti.com</a>.`,
  DELETE_ALERT_HEADING: 'Supprimer votre compte',
  DELETE_ALERT_MESSAGE: `Pour supprimer votre compte, veuillez vous rendre sur notre application Web à l'adresse <a href="https://app.forghetti.com" target="_new">https://app.forghetti.com</a>.`,
  REFERRAL_CODE_UPDATE_NOT_ALLOWED: `Un code de parrainage a déjà été appliqué à votre compte`,
  REFERRAL_CODE_APPLIED: (code) => `Le code de parrainage <em>${code}</em> a été appliqué à votre compte.`,
  INVALID_PHONE_NUMBER: 'Le numéro de téléphone est invalide. Veuillez réessayer.',
  INVALID_ACTIVATION_CODE: `Le code d'activation n'est pas valide`,
  INVALID_TOO_MANY_ATTEMPTS: `Il y a eu trop de tentatives infructueuses de connexion avec ce numéro. Veuillez réessayer plus tard.`,

  REONBOARDING_POPUP_GO_AGAIN: `Nous avons amélioré la façon dont vous utilisez forghetti. Souhaitez-vous vous familiariser avec son fonctionnement et ce qui rend forghetti si génial?`,
  REONBOARDING_POPUP_BUTTON_GO: `Montre moi`,
  REONBOARDING_POPUP_BUTTON_CANCEL: 'Non merci',
  REONBOARDING_POPUP_HEADING: `Tout nouveau forghetti 2.0`,
  HELP_QUICKSTART: 'Intégration Quickstart',
  YOUR_BADGES: 'Vos badges',
  GROUP_NOT_OWNER: 'Vous ne pouvez déplacer des services que vers des groupes dont vous êtes le propriétaire.',
  GROUP_NOT_OWNER_TITLE: 'Services de déménagement',
  VIEWSERVICE_NOT_COPIED_TO_CLIPBOARD: `<span class="toast--title">Vous allez avoir besoin de votre nouveau mot de passe. </span><span class="toast--text">Cliquez sur le bouton Copier dans le champ du mot de passe pour ajouter votre nouveau mot de passe dans le presse-papiers.</span>`,
  EXTENSION_ATTEMPT: (website) =>
    `<strong class="toast--title">Recherche de connexions pour </strong><span class="toast--clipboard-item">${website}</span>`,
  EXTENSION_ATTEMPT_FAIL: (website) =>
    `<span class="toast--title"><span class="toast--title">Impossible de trouver les connexions correspondantes</span></span>`,

  UNIVERSAL_LINK_NEW_LOGIN: (url) => `Tentative de recherche d'un service correspondant pour ${url}`,
  UNIVERSAL_LINK_NEW_LOGIN_NO_URL: `Lancement du nouvel assistant de service`,

  CONFIRM_COUNTRY_UPDATE_HEADER: 'Changez votre langue',
  CONFIRM_COUNTRY_UPDATE_NOTE:
    'Afin de changer votre préférence de langue, veuillez cliquer sur le bouton ci-dessous pour enregistrer et recharger forghetti.',
  CONFIRM_BUTTON: 'Recharger forghetti',

  FORGHETTI_LOGIN: 'forghetti',
  FORGHETTI_LOGIN_TAGLINE: 'Oubliez vos mots de passe. Pour toujours.',
  FORGHETTI_LOGIN_CREATE: 'Créer un compte',
  FORGHETTI_LOGIN_ALREADY_HAVE: 'Je ai déjà un compte',

  MUST_BE_LOGGED_IN_ACTION: `Cette action n'est disponible que lorsque vous êtes connecté`,
  UPDATING_FORGHETTIBLE: `Mettre à jour votre 'forghettible'`,
  FORGHETTI_ON_MAC: `L'application forghetti est disponible pour Mac. <a data-actionclick="openForghettiCom">Obtenez-le ici.</a>`,
  FORGHETTI_ON_WINDOWS: `L'application forghetti est disponible pour Windows. <a data-actionclick="openForghettiCom">Obtenez-le ici.</a>`,

  REALTIME_NEW_SERVICE_FOR_GROUP: (group) => `Un nouveau service a été ajouté au groupe partagé ${group}`,
  REALTIME_REMOVE_SERVICE_FOR_GROUP: (group) => `Un service a été retiré du groupe partagé ${group}`,
  REALTIME_KICK_SERVICE_FOR_GROUP: (group) => `Vous avez été retiré du groupe ${group}`,
  REALTIME_BAN_SERVICE_FOR_GROUP: (group) => `Vous avez été banni du groupe ${group}`,
  REALTIME_JOIN_SERVICE_FOR_GROUP: (group) => `Un nouvel utilisateur a rejoint ${group}`,
  REALTIME_REMOVE_GROUP: (group) => `Le groupe ${group} a été supprimé par le propriétaire`,

  PERSONAL_GROUP: 'Personnel',
  SHAREABLE_GROUP: 'Groupe partageable',
  IMPORT_MENU: `Services d'importation`,
  IMPORT_GROUP: `Services importés`,

  MONTHS: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ],

  AND: 'et',

  NEW_GROUP: 'Nouveau groupe',
  LETS_START_SELECT: 'Commençons par sélectionner un ou plusieurs de ces services populaires ...',
  CREATE_SERVICES: 'Créer des services',
  CREATING_SERVICES: 'Nous générons maintenant votre services',
  MUST_SELECT_MORE_THAN_ONE_SERVICES: 'Veuillez sélectionner un ou plusieurs services',
  ADD_NOTE: 'Ajouter une nouvelle note',
  EDIT_NOTE: 'Note éditée',
  NOTE: 'Remarques',
  NOTE_COPIED: 'Remarque copiée dans le presse-papiers',
  DELETE_NOTE: 'Supprimer la note',

  FORGHETTI_LOGIN_TAGLINE: 'Oubliez vos mots de passe.',
  FORGHETTI_LOGIN_TAGLINE_2: 'Pour toujours.',
  CREATE_ACCOUNT_1_MSG_4:
    '<b>Note</b>: We will only ever use your number to create/support your forghetti account. You will receive a one-time device verification code.',
  CREATE_ACCOUNT_1_HEADER_2: 'Créez votre compte Forghetti.',

  CREATE_ACCOUNT_2_HEADING: 'Entrez votre code de vérification.',
  CREATE_ACCOUNT_FINAL_BUTTON: 'Me montrer comment',

  ONBOARDING_DOODLETUTORIAL2_HEADING_2: 'Dessiner un motif',
  ONBOARDING_DOODLETUTORIAL3_HEADING_2: 'Multiples lignes',
  ONBOARDING_DOODLETUTORIAL4_HEADING_2: 'Points aussi ...',
  ONBOARDING_DOODLETUTORIAL5_HEADING_2: 'Ou tous ensemble',
  ONBOARDING_DOODLETUTORIAL6_HEADING_2: `Mais rappelez-vous, l'ordre compte!`,
  ONBOARDING_DOODLETUTORIAL7_HEADING_2: 'Doodle a,',
  ONBOARDING_DOODLETUTORIAL8_HEADING_2: `Doodle a, n'est pas la même que doodle b`,
  SECURE_DOODLE_TUTORIAL: 'Tutoriel sécurisé Doodle',
  DOODLE_TRY_AGAIN: 'Essayer à nouveau',
  WELCOME_TO_FORGHETTI: 'Bienvenue à Forghetti',

  ONBOARDING_COMPLETE_CONGRATULATIONS:
    'Toutes nos félicitations! Vous venez de faire les premiers pas pour sécuriser votre identité en ligne.',

  ONBOARDING_COMPLETE_CREATE_FORGHETTI: 'Créez un compte ForGhetti.',
  ONBOARDING_COMPLETE_SIGNATURE_DOODLE: 'Créez votre doodle de signature.',

  ONBOARDING_COMPLETE_RESET_PASSWORD: 'Réinitialisez un mot de passe insénu.',

  ONBOARDING_COMPLETE_HEALTHCHECK: 'Exécutez une vérification de santé sur vos comptes en ligne.',

  ONBOARDING_COMPLETE_IMPORT: `Services d'importation`,
  ONBOARDING_COMPLETE_IMPORT_BUTTON: `Services d'importation`,
  ONBOARDING_COMPLETE_REMIND_LATER: `Rappelle-moi plus tard`,
  ONBOARDING_COMPLETE_HEALTHCHECK_BUTTON: 'Faire un bilan de santé',

  USERNAME_OR_EMAIL: `Nom d'utilisateur`,
  USERNAME_OR_EMAIL_SECOND: 'E-mail',
  SERVICE_EDIT_USERNAME_PLACEHOLDER: 'Entrez tout autre détail de connexion',

  SERVICE_USERNAME_LABEL: `Nom d'utilisateur`,
  SERVICE_USERNAME_SECOND_LABEL: 'E-mail',

  ADD_SERVICE_USERNAME_PLACEHOLDER: `Nom d'utilisateur`,
  SERVICE_USERNAME_PLACEHOLDER_SECOND: `E-mail`,
  FREE_USER_UPGRADE: 'Passer à la version premium',
  LOGIN_ACCOUNT_1_HEADER_2: 'Connectez-vous à votre compte forghetti.',

  KEYCHAIN_HEADING: 'Enregistrez votre doodle',
  KEYCHAIN_MESSAGE:
    'Vous pouvez enregistrer votre doodle en toute sécurité sur cet appareil pour faciliter la génération de vos mots de passe.',
  KEYCHAIN_MESSAGE_2: 'Souhaitez-vous enregistrer votre doodle sur cet appareil?',
  KEYCHAIN_MESSAGE_3: 'Vous pouvez toujours modifier cela dans vos paramètres.',
  KEYCHAIN_YES: 'Oui, sauf mon doodle',
  KEYCHAIN_NO: 'Non, je vais dessiner mon doodle à chaque fois',

  LOADING_VAULT: 'Chargement de votre coffre',
  ONBOARDING_COMPLETE_FIRST_LOGIN: 'Ajouter un service.',
  ONBOARDING_COMPLETE_CONTINUE: `Réinitialise maintenant un mot de passe`,
  STILL_LOADING: 'Nous chargeons votre compte',
  STILL_LOADING_2: 'Votre compte a été vérifié',

  CREATE_ACCOUNT_VERIFYING_PHONE: 'Nous vérifions votre numéro',
  NOTIFICATION_MESSAGE_BAR: `Cliquez sur «Utiliser» sur un service pour envoyer votre mot de passe à {{service}}`,
  NO_NOTIFICATIONS_HELLO_TEXT: (name) => `Bonjour ${name}! Vous n'avez pas encore de notifications.`,
  NO_NOTIFICATIONS_CHECK: 'Revenez régulièrement pour les mises à jour.',
  REPLAY_DOODLE_HEADING: 'Rejouer',
  FORGHETTIBLE: 'Forghetible',
  SERVICE_NO_NOTE: 'Pas de note',
  SERVICE_NOTE: 'Note',
  SERVICE_BAD_NOTE: 'Pour déverrouiller cette note, redessine le doodle',
  SERVICE_NAME: 'Nom du service',
  REPLAY_DOODLE: 'Rejouer doodle',
  ENABLE_BIOMETRICS: 'Activer la biométrie',
  DISABLE_BIOMETRICS: 'Désactiver la biométrie',
  WEB_DELAY:
    'Établir une connexion et charger vos coordonnées. Si cela prend trop de temps, veuillez vérifier votre connexion.',
  WEB_WAITING: 'Fonctionne toujours, veuillez attendre ...',
  PROFILE_MIGRATE_2_INFO_2: 'Entrez maintenant le code de vérification que vous recevez sur votre ancien téléphone:',
  MIGRATE: 'Émigrer',
  MONTHLY: 'Mois',
  YEARLY: 'Année',
  UNLOCK_BIOMETRICS: 'Débloquer avec la biométrie',
  UNLOCK_DESCRIPTION: 'Déverrouillez votre compte Forghetti avec biométrie',
  UNLOCK_SERVICE: (service) => `Déverrouiller ${service}`,
  UNLOCK_SERVICE_DESCRIPTION: (service) =>
    `Pour déverrouiller ${service} utilisez la biométrie ou redessinez votre doodle`,
  UNLOCK_FALLBACK: 'Utilisez Doodle',
  UNLOCK_CANCEL: 'Annuler',

  RATE_US: 'Évaluez-nous',
  SETUP_NEW_SERVICE_MESSAGE: `Installons votre nouveau service en fournissant quelques détails.`,

  LETS_START_SELECT_COUNT: (count) =>
    `Commençons par sélectionner ces services populaires ... vous pouvez sélectionner <em data-count>${count}</em> plus sur le niveau gratuit`,
  TOAST_TOO_MANY_SERVICES_SELECTED: (count) =>
    `Vous avez sélectionné trop de services. Veuillez sélectionner un maximum de services ${count}`,
  LETS_START_ADDING_DETAILS: `Ajoutons plus de détails`,

  ONBOARDING_COMPLETE_NEXT_STEPS: `<h2>Maintenant, pourquoi pas</h2><ul><li>ffectuez une vérification de santé sur vos comptes en ligne?</li><li>Ou mettez à jour un autre service sur Forghetti.</li><li> Ou jetez un œil autour de vous ...</li></ul>`,
  ONBOARDING_COMPLETE_CONGRATULATIONS:
    'Toutes nos félicitations! Vous venez de faire les premiers pas pour sécuriser votre identité en ligne.',
  CREATE_ACCOUNT_1_MSG_4: `<b>Remarque</b>: nous n'utiliserons jamais votre numéro pour créer / prendre en charge votre compte ForGhetti. Vous recevrez un code de vérification de périphérique unique.`,
  MOVE_SERVICE_CONFIRM_NOTE_UPDATE: (fromGroup, toGroup, list, listLength) =>
    `${listLength > 1 ? 'Nouveau' : 'un nouveau'} <em>${list}</em> Forghetible${
      listLength > 1 ? 's' : ''
    } sera généré à partir de <em>${toGroup}</em> Votre doodle. Si vous souhaitez accéder à votre ancienne pourhetble${
      listLength > 1 ? 's' : ''
    }, dessinez simplement votre ${fromGroup} doodle à la place.`,

  YOUR_SUBSCRIPTION_PARTNER_PREMIUM_TEXT_1: (partner, link) =>
    `Nos partenaires de <em> ${partner} </em> vous fournissent pour Forghetti Premium. Vous pouvez en savoir plus sur votre abonnement ici: <a href="${link}" target="_new"> ${link} </a>`,
  YOUR_SUBSCRIPTION_PARTNER_PREMIUM_TEXT_2: `Nous espérons que vous aimez utiliser Forghetti.`,
  PARTNER_PREMIUM_HEADING: `Toutes nos félicitations!`,
  PARTNER_PREMIUM_MESSAGE: (partner) =>
    `En tant que partenaire Forghetti, <em> ${partner} </em> Les membres obtiennent une prime pour les pourghetti. Vous n'avez rien à faire, l'abonnement sera automatiquement appliqué à votre compte. Pour que les modifications prennent effet, cliquez immédiatement sur «Recharger ForGhetti» maintenant.`,
  PARNTER_PREMIUM_BUTTON: `Recharger Forghetti`,

  CSL_SUBSCRIPTION_HEADER: `Premium activé`,
  CSL_SUBSCRIPTION_1: `Vous avez activé notre service premium. Merci. Lorsque cet abonnement expire, vous pouvez soit continuer en tant qu'utilisateur gratuit (et perdre tous vos avantages premium) ou renouveler votre abonnement.`,
  CSL_SUBSCRIPTION_2: `Nous espérons que vous appréciez d'utiliser Forghetti.`,
  LICENCE_KEY_INVALID: (partner) => `Désolé, votre demande de demande n'est pas valide.`,
  LICENCE_KEY_USED: (partner) => ` Désolé, votre demande de demande n'est pas valide. `,
  LICENCE_KEY_ALREADY_APPLIED: (partner) => `Vous vous êtes enregistré avec succès. Merci d'utiliser ce service.`,

  SERVICE_EMAIL_LABEL: 'E-mail',
  SERVICE_EMAIL_PLACEHOLDER: 'user@email.com',
  SERVICE_EDIT_EMAIL_PLACEHOLDER: 'user@email.com',
  PROFILE_USE_BIOMETRICS: 'Stockez votre doodle de biométrie',
  BIOMETRICS_GROUPS_HEADER: 'Activer la biométrie',
  BIOMETRICS_SELECT_GROUP: 'Sélectionnez un groupe pour stocker votre doodle avec la biométrie',
  BIOMETRICS: 'Biométrie',
  BIOMETRICS_ENABLE: 'Activer',
  BIOMETRICS_DISABLE: 'Désactiver',
  BIOMETRICS_SECURE: 'Sécurisé avec la biométrie',
  BIOMETRICS_SECURE_MESSAGE: (group) =>
    `Votre doodle pour <em> ${group} </em> sera stocké en toute sécurité avec la biométrie`,
  BIOMETRICS_USE_DOODLE: 'Utilisez Doodle',
  BIOMETRICS_TRY_AGAIN: 'Essayer à nouveau',
  NOTES: 'Remarques',
  SELECT_SPECIAL_CHARACTERS: 'Sélectionnez les symboles à inclure',
  WORDS: 'Mots <span class="monospace">exemple</span>',
  LETTERS: 'Letters <span class="monospace">ABC</span>',
  NUMBERS: 'Nombres <span class="monospace">123</span>',
  SYMBOLS: 'Symboles <span class="monospace">!$%</span>',
  SELECT_PASSWORD_TYPE: (forghettibleName) => `${forghettibleName} Options`,
  SELECT_PASSWORD_NUMBERS: 'Lettres totales / nombres / symboles',
  SELECT_PASSWORD_WORDS: 'Total des mots',
  ALL: 'Tous',
  SIMPLE: 'Simple',
  EDIT_PASSWORD_HEADING: 'Paramètres',
  SERVICE_FIELD_PASSWORD_NO_OPTIONS: 'Au moins une option de mot de passe doit être sélectionnée',
  SERVICE_FIELD_PASSWORD_NO_SYMBOLS_SELECTED: 'Au moins un symbole doit être sélectionné',
  SUBSCRIPTION_ENDS: (days) => `Votre abonnement doit expirer dans ${days} jours.`,
  SUBSCRIPTION_ENDS_TOMORROW: 'Votre abonnement doit expirer demain.',
  SUBSCRIPTION_ENDS_TODAY: `Votre abonnement doit expirer aujourd'hui.`,
  NOTIFICATIONS: 'Notifications',
  CONTEXT_CHANGE_TYPE: 'Modifier les options',
  CONTEXT_CHANGE_LENGTH: 'Longueur de changement',
  CONTEXT_RENAME: 'Renommer pour',
  NEW_FORGHETTIBLE: 'Nouveau ForgheTible',
  OLD_FORGHETTIBLE: 'Vieux Forghetible',
  DELETE_FIELD_PROMPT: 'Êtes-vous sûr que vous souhaitez supprimer ce champ?',
  ONBOARDING2_WELCOME_HEADING: 'Bienvenue à Forghetti',
  ONBOARDING2_WELCOME_TEXT: 'Connectez-vous à tous les services que vous utilisez avec un doodle sécurisé.',
  ONBOARDING2_DOODLE_HEADING: 'Créez votre doodle de signature',
  ONBOARDING2_DOODLE_TEXT: `Des lignes ou des points, aux séquences aléatoires ou séquences de nombres. Votre combinaison unique génère un mot de passe super fort. `,
  ONBOARDIN2_HOWITWORKS: 'Comment ça fonctionne',
  ONBOARDING_CONFIRM_DOODLE: 'Confirmez votre doodle',
  ONBOARDING_BIOMETRICS_CONFIRM: 'Souhaitez-vous enregistrer ceci sur votre trousseau?',
  SAVING_NOTE: 'Encrypter votre note',

};
