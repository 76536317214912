const config = require('../../../config');
const { infoTextRow } = require('../../page_helpers/components');

const { strings } = config;
const { ADD_SERVICE_SELECT_ICON, BACK, SKIP, ADD_SERVICE_SELECT_ICON_HEADER } = strings;

module.exports = ({ nav }) => {
  setTimeout(_ => $('#webSearchForServiceIcon .search-input').focus(), 1000);
  const onboarding = window.state.userData.activeOnboarding;

  const reonboarding = window.state.userData.reonboarding;

  const serviceName = window.z2hApp.pageData.service.serviceData.name;

  // Trigger immediate web search
  setTimeout(_ => $('[data-actionclick=webSearchForServiceIcons]').click(), 200);

  // Action to trigger if any of the icons are clicked on
  window.state.webIconSearchAction = 'addService_selectIcon';

  return {
    template: onboarding ? 'block-section-onboarding' : 'block-section-page',
    fields: {
      id: 'webSearchForServiceIcon',
      active: false,
      class: 'no-border',
      navbar: false,

      ...(!onboarding && {
        header: ADD_SERVICE_SELECT_ICON_HEADER(),
        navigation_left: BACK(),
        navigation_left_template: 'back',
        navigation_left_data: Math.max(nav - 1, 0),
      }),
      navigation_right: SKIP(),
      navigation_right_template: 'addService_website',
      navigation_right_data: Math.max(nav + 1, 0),

    },

    rows: [
      reonboarding && {
        template: 'block-onboarding-close'
      },
      infoTextRow(ADD_SERVICE_SELECT_ICON()(serviceName), true, false),

      {
        template: 'block-section-row',
        fields: {},
        columns: [
          {
            template: 'block-input-box',
            fields: {
              placeholder: strings.ADD_SERVICE_SEARCH_FOR_ICONS(),
              id: 'service-icon-search',
              value: serviceName || '',
            },
          },
          {
            template: 'block-icn-button',
            fields: {
              //class: 'hidden',
              icon: 'right-solid',
              id: 'service-icon-new-search',
              action: 'webSearchForServiceIcons',
            },
          },
        ],
      },
      onboarding && {
        template: 'block-text-button',
        fields: {
          //class: 'hidden',

          text: SKIP(),
          navigation_template: 'addService_website',
          navigation_data: Math.max(nav + 1, 0),

        },
      },
      // Loading icon
      {
        template: 'block-loading-icon-row',
        fields: {
          class: 'hidden',
        },
      },
    ],
  };
};
