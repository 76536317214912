const config = require('../../../config');

const onboadingTemplate = require('./onboarding_template');

const { strings } = config;

const { ONBOARDING_DOODLETUTORIAL, ONBOARDING_DOODLETUTORIAL3_HEADING } = strings;

module.exports = ({ nav }) => {
  return onboadingTemplate({
    nav,
    id: 'onboarding-doodletutorial3',
    imagePath: 'img/animations/01_multi_lines.json',
    secondary_heading: ONBOARDING_DOODLETUTORIAL(),
    primary_heading: ONBOARDING_DOODLETUTORIAL3_HEADING(),
    nextPage: 'onboarding_doodletutorial4',
  });
};
