const config = require('../../../config');
const z2hApp = require('../../app/z2hApp');
const { includes } = require('../../getPageData_helpers/groupIconList');
const strings = config.strings;

const { subheading } = require('../../page_helpers/components');

module.exports = ({ nav, data }) => {
  const z2hTemplates = require('../../app/templates');

  //Gen settings tells us what tabs should be selected (in server format)
  let genSettings = z2hApp.pageData.service.fieldsSaved[data.fieldId].generation_settings || {};

  let letterNumberSymbolCustomLength = genSettings.length ? genSettings.length : 0;
  //Show the letters/numbers/symbols counter by default
  let letterNumberSymbolCustomLengthShown = true;
  let wordCustomLength = genSettings.words ? genSettings.words : 0;
  //Hide the word counter by default
  let wordCustomLengthShown = false;

  //Render the page
  return {
    template: 'block-section-page',
    fields: {
      id: 'special-characters',
      active: false,
      header: strings.EDIT_PASSWORD_HEADING(),
      navigation_left: config.strings.BACK(),
      navigation_left_icon: '',
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'back',
      navigation_right: strings.FIELD_RIGHT_NAV(),
      navigation_right_action: 'savePasswordDetails',
    },
    rows: [
      subheading({
        text: strings.FIELD_OPTIONS_HEADING(),
        additionalClass: 'space-above',
      }),
      {
        template: 'block-section-row',
        fields: {
          id: 'wordSelector',
        },
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_html: strings['WORDS']() },
          },
          {
            template: 'block-toggle-switch',
            fields: {
              value: 'WORDS',
              data_id: 'WORDS',
              checked: true,
              action: 'selectLetterNumberSymbolWord',
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          id: 'wordsCustomLengthWrapper',
          class: 'transition-collapsible-row',
          class2: wordCustomLengthShown ? '' : 'collapsed',
        },
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_text: config.strings.SELECT_PASSWORD_WORDS() },
          },
          {
            template: 'block-editable-primary-text',
            fields: {
              class: 'align-right',
              class2: 'small-input',
              primary_text: wordCustomLength,
              action: 'selectWordCustomLength',
              name: 'wordsCustomLength',
              for: 'wordsCustomLength',
              id: 'wordsCustomLength',
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          id: 'letterSelector',
        },
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_html: strings['LETTERS']() },
          },
          {
            template: 'block-toggle-switch',
            fields: {
              value: 'LETTERS',
              data_id: 'LETTERS',
              checked: true,
              action: 'selectLetterNumberSymbolWord',
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          id: 'numberSelector',
        },
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_html: strings['NUMBERS']() },
          },
          {
            template: 'block-toggle-switch',
            fields: {
              value: 'NUMBERS',
              data_id: 'NUMBERS',
              checked: true,
              action: 'selectLetterNumberSymbolWord',
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          id: 'symbolSelector',
          class: 'no-border',
        },
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_html: strings['SYMBOLS']() },
          },
          {
            template: 'block-icn-button',
            fields: {
              icon: 'chevron-down',
              action: 'showHideContent',
            },
          },
          {
            template: 'block-toggle-switch',
            fields: {
              value: 'SYMBOLS',
              data_id: 'SYMBOLS',
              checked: true,
              action: 'selectLetterNumberSymbolWord',
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          id: 'symbolsWrapper',
          class: 'transition-collapsible-row',
          class2: 'collapsed',
          secondary_heading: config.strings.SELECT_SPECIAL_CHARACTERS(),
        },
        columns: [
          {
            template: 'block-insertable',
            fields: {
              class: 'column',
              html: specialCharacterTable().html(),
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          id: 'letterNumberSymbolCustomLengthWrapper',
          class: 'transition-collapsible-row',
          class2: letterNumberSymbolCustomLengthShown ? '' : 'collapsed',
        },
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_text: config.strings.SELECT_PASSWORD_NUMBERS() },
          },
          {
            template: 'block-editable-primary-text',
            fields: {
              class: 'align-right',
              class2: 'small-input',
              primary_text: letterNumberSymbolCustomLength,
              action: 'selectLetterNumberSymbolCustomLength',
              name: 'letterNumberSymbolCustomLength',
              for: 'letterNumberSymbolCustomLength',
              id: 'letterNumberSymbolCustomLength',
            },
          },
        ],
      },
    ],
  };
};
