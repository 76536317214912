const getDefaultGenerationSettings = require('../../getPageData_helpers/getDefaultGenerationSettings');
const getGenerationTypeFromData = require('../../getPageData_helpers/getGenerationSettingsType');
const config = require('../../../config');
const _ = require('lodash');
module.exports = ({ clickedElem }) => {
  const z2hApp = window.z2hApp;

  // Check if the clicked element has an id (loads data and gives it a unique id)
  let promForGenerationSettings;

  var fieldId = $(clickedElem).attr('id') || window.state.selectedFieldId;

  if (!fieldId) {
    // No ID for this field.. set one
    fieldId = z2hTools.getUid();
    $(clickedElem).attr('id', fieldId);
    z2hApp.active.pageId = fieldId;

    // Initialise the pageData
    z2hApp.pageData = z2hApp.pageData || {};
    z2hApp.pageData.service = z2hApp.pageData.service || {};
    z2hApp.pageData.service.fieldsArray = z2hApp.pageData.service.fieldsArray || [];
    z2hApp.pageData.service.fieldsSaved = z2hApp.pageData.service.fieldsSaved || {};
    z2hApp.pageData.service.fieldsUnsaved = z2hApp.pageData.service.fieldsUnsaved || {};

    // Add this new field to the page data
    z2hApp.pageData.service.fieldsArray.push(fieldId);
    z2hApp.pageData.service.fieldsSaved[fieldId] = { name: config.strings.SERVICE_DFT_FORGHETTIBLE_NAME() };
    z2hApp.pageData.service.fieldsUnsaved[fieldId] = { name: config.strings.SERVICE_DFT_FORGHETTIBLE_NAME() };

    // ===================
    promForGenerationSettings = getDefaultGenerationSettings().then((settings) => {
      z2hApp.userData = z2hApp.userData || {};
      z2hApp.userData.defaultGeneration = settings;
      z2hApp.pageData.service.fieldsSaved[fieldId].generation_settings = settings;
      z2hApp.pageData.service.fieldsUnsaved[fieldId].generation_settings = settings;
      return settings;
    });
  } else {
    // Return generation settings from field data that is already in fieldsSaved array
    z2hApp.active.pageId = fieldId;
    const generation_settings = z2hApp.pageData.service.fieldsSaved[fieldId].generation_settings;
    promForGenerationSettings = Promise.resolve(generation_settings);
  }

  window.state.selectedFieldId = fieldId;
  const selectedService = z2hApp.pageData.service;
  window.state.selectedServiceId = selectedService.id;
  const recovery_timestamps = (selectedService.fields[fieldId] || {}).recovery_timestamps;

  return promForGenerationSettings.then((generation_settings) => {
    var data = {
      fieldId,
      name: z2hApp.pageData.service.fieldsSaved[fieldId].name,
      length: generation_settings.length,
      type: getGenerationTypeFromData(generation_settings),
      collapsed: generation_settings.length <= 12 && generation_settings.length % 4 === 0,
      currentService: selectedService,
      recovery_timestamps: recovery_timestamps,
    };
    // Ensure that we copy the saved data to the unsaved data to be compared later
    z2hApp.pageData.service.fieldsUnsaved[data.fieldId] = _.cloneDeep(
      z2hApp.pageData.service.fieldsSaved[data.fieldId],
    );
    z2hApp.pageData.service.fieldsUnsaved[data.fieldId].generation_settings = { ...generation_settings };
    return data;
  });
};
