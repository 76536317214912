module.exports = {
  importWelcome: require('./importWelcome'),
  importList: require('./importList'),
  importGroup: require('./importGroup'),
  importUsernames: require('./importUsernames'),
  importCreatingServices: require('./importCreatingServices'),
  importComplete: require('./importComplete'),
  importSelectFolder: require('./importSelectFolder'),
  importServicesGroup: require('./importServicesGroup'),
  importMoveServicesWelcome: require('./importMoveServicesWelcome'),
  importMoveServicesWizard: require('./importMoveServicesWizard'),
  importImportType: require('./importImportType'),
  importUploadDocument: require('./importUploadDocument'),

}