const errorManager = require('../utils/errorManager');
const strings = require('../../config').strings;

/// TODO: A lot of code duplicated between this and 'validatePhoneNumberSignIn' which could be DRY'd up

module.exports = section => {
  errorManager.clearErrors(section);
  let error = false;

  const telNumField = $('#migration-number');

  // Get number entered into telephone number field
  // Remove any characters which are not 0-9 or #
  const value = telNumField.val().replace(/([^0-9#])/g, '');

  if (!value) {
    errorManager.reportError(strings.ERROR_TEL_NUM_EMPTY(), telNumField);
    error = true;
  }

  //const fullPhoneNumber = telNumField.intlTelInput("getNumber");
  const fullPhoneNumber = window.iti.getNumber();

  if (fullPhoneNumber.substr(0, 3) === '+44') {
    // Check that phone number:
    //    optionally starts with 0,
    //    contains 10 other digits,
    //    optionally followed by hash-sign and a 3-4 digit extension number.
    const regex = /^(0)?([0-9]){10}(\s?\#(\d{4}|\d{3}))?$/;
    if (!regex.test(value)) {
      errorManager.reportError(strings.ERROR_TEL_NUM_INVALID(), telNumField);
      error = true;
    }
  }

  if (error) {
    throw new Error('Validation error');
  }
};
