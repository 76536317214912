const strings = require('../../../config').strings;
const { infoTextRow, ctaButton } = require('../../page_helpers/components');
const actions = require('../../actions');

const { SEARCH_INTERNET, SEARCH, CANCEL, SERVICE_CHOOSE_ICON } = strings;

module.exports = ({ nav, data }) => {
  setTimeout(_ => $('#webSearchForServiceIcon .search-input').focus(), 1000);

  if (data.initialSearchQuery) {
    setTimeout(_ => $('[data-actionclick=webSearchForServiceIcons]').click(), 200);
  }

  return {
    template: 'block-section-page',
    fields: {
      id: 'webSearchForServiceIcon',
      active: false,
      header: SERVICE_CHOOSE_ICON(),
      navigation_left: CANCEL(),
      navigation_left_template: 'back',
      navigation_left_data: Math.max(nav - 1, 0),
      navbar: false,
    },
    rows: [
      infoTextRow(SEARCH_INTERNET(), true, false),
      {
        template: 'block-search-box',
        fields: {
          primary_text: SEARCH(),
          id: 'service-icon-search',
          value: data.initialSearchQuery || '',
        },
      },
      ctaButton(SEARCH(), 'webSearchForServiceIcons'),
      {
        template: 'block-loading-icon-row',
        fields: {
          class: 'hidden',
        },
      },
    ],
  };
};

$('body').on('keypress', '#webSearchForServiceIcon .search-input', e => {
  if (e.key === 'Enter') {
    actions.webSearchForServiceIcons({ immediately: true, currentTarget: e.currentTarget });
  }
});
