const config = require('../../../config');

const onboadingTemplate = require('./onboarding_template');

const { strings } = config;

const { ONBOARDING_DOODLETUTORIAL, ONBOARDING_DOODLETUTORIAL4_HEADING } = strings;

module.exports = ({ nav }) => {
  return onboadingTemplate({
    nav,
    id: 'onboarding-doodletutorial4',
    imagePath: 'img/animations/02_dots_too.json',
    secondary_heading: ONBOARDING_DOODLETUTORIAL(),
    primary_heading: ONBOARDING_DOODLETUTORIAL4_HEADING(),
    nextPage: 'onboarding_doodletutorial5',
  });
};
