module.exports = ({ element, section, z2hApp }) => {
  // element = <section class="row suggested-add-button"...

  //We could have been called from selecting a username or
  //by entering a username an email from the textbox.
  let username1 = '';
  if ($(element).prop('tagName') === 'BUTTON') {
    username1 = $(section).find('#healthcheck_primary_username_1').val();
  } else {
    username1 = $(element).find('.primary-text-content').text();
  }
  // const username2 = $(section)
  //   .find('#healthcheck_primary_username_2')
  //   .val();
  //console.log(username1);
  // Store service name in state
  window.z2hApp.pageData.healthcheck = {};
  window.z2hApp.pageData.healthcheck.username = {};
  window.z2hApp.pageData.healthcheck.username.username1 = {};
  // window.z2hApp.pageData.healthcheck.username.username2 = {};
  window.z2hApp.pageData.healthcheck.username.username1.data = username1;
  // window.z2hApp.pageData.healthcheck.username.username2.data = username2;
  window.z2hApp.pageData.healthcheck.username.requestState = 'init';

  //make a request to have I been pawned for the usernames
  function getPwned() {
    const fetchUser = (username) => {
      //TODO move this to the backend...
      if (!username) return Promise.resolve({});
      return z2hApp.requestData('get', '', `pwned/${username}/email`);

      //return fetch(`https://cors-anywhere.herokuapp.com/https://haveibeenpwned.com/api/v3/breachedaccount/${username}`, options);
    };
    //fire off a request to get a user. Store the promise but we're not going
    //to wait for the result here, we will do that on the loading screen.
    const user1Res = fetchUser(username1);
    window.z2hApp.pageData.healthcheck.username.username1.promise = user1Res;
    // const user2Res = fetchUser(username2);
    // window.z2hApp.pageData.healthcheck.username.username2.promise = user2Res;
  }
  getPwned();
};
