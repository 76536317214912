module.exports = ({ nav, data }) => {
  const landingPage = window.state.banner && window.state.banner.landingPage;

  if (landingPage) {
    return {
      template: 'block-section-page',
      fields: {
        id: 'landing-page',
        active: false,
      },
      rows: JSON.parse(window.state.banner.landingPage),
    };
  } else {
    return {
      template: 'block-section-page',
      fields: {
        id: 'landing-page',
        active: false,
        class: 'loading-landing-page',
      },
      rows: [
        {
          template: 'block-banner',
          fields: {
            context: 'landingPage',
          },
        },
        {
          template: 'block-banner',
          fields: {
            context: 'landingPage',
            class: 'invisible',
          },
        },
      ],
    };
  }
};
