const { thirdheading } = require('../../page_helpers/components');
const strings = require('../../../config').strings;

const template = ({ imagePath, primary_heading, secondary_heading, text, html, nextLink, nextPage, nav }) => ({
  rows: [
    imagePath && {
      template: 'block-onboarding-image',
      fields: { imagePath },
    },
    thirdheading(strings.ONBOARDING_EXISTING_TITLE()),
    {
      template: 'block-section-row',
      fields: {
        class: 'no-border',
        class2: 'centered-text',
        class3: 'onboarding-text',
        primary_heading_html: primary_heading,
      },
      columns: [
        text && {
          template: 'block-info-text',
          fields: { text },
        },
        html && {
          template: 'block-info-text',
          fields: { html },
        },
      ],
    },
    {
      template: 'block-cta-button',
      fields: {
        text: strings.CONTINUE(),
      },
    },
  ],
});

module.exports = ({ nav }) => {
  const reonboarding = window.state.userData.reonboarding;

  const templates = [];
  let t = {
    imagePath: 'img/lochy/11-Lochy-Confetti.gif',
    primary_heading: strings.ONBOARDING_EXISTING_WELCOME_HEADING(),
    html: strings.ONBOARDING_EXISTING_WELCOME_TEXT(),
  };
  templates.push(t);
  t = {
    imagePath: 'img/onboarding/onboarding-move-services.jpg',
    primary_heading: strings.ONBOARDING_EXISTING_MOVE_SERVICES_HEADING(),
    text: strings.ONBOARDING_EXISTING_MOVE_SERVICES_TEXT(),
  };
  templates.push(t);
  t = {
    imagePath: 'img/onboarding/onboarding-health-check.jpg',
    primary_heading: strings.ONBOARDING_EXISTING_HEALTHCHECK_HEADING(),
    text: strings.ONBOARDING_EXISTING_HEALTHCHECK_TEXT(),
  };
  templates.push(t);
  t = {
    imagePath: 'img/onboarding/onboarding-group-sharing.jpg',
    primary_heading: strings.ONBOARIDNG_EXISTING_SHARING_HEADING(),
    text: strings.ONBOARDING_EXISTING_SHARING_TEXT(),
  };
  templates.push(t);
  /*t = {
    imagePath: '',
    primary_heading: strings.ONBOARDING_EXISTING_SWITCHING_HEADING,
    text: strings.ONBOARDING_EXISTING_SWTICHING_TEXT,
  };
  templates.push(t);*/
  t = {
    imagePath: 'img/onboarding/onboarding-pricing.jpg',
    primary_heading: strings.ONBOARDING_EXISTING_PRICING_HEADING(),
    text: strings.ONBOARDING_EXISTING_PRICING_TEXT(),
  };
  templates.push(t);

  //Generate templates for each page and push them to
  //window.state.carousel for later generation
  const pages = [];
  templates.forEach(t => {
    pages.push(template(t));
  });

  const carousel = window.state.carousel || {};
  const id = { template: 'onboarding_existingUser' };

  id.carousel = `${id.template}_carousel`;
  id.arrows = `${id.template}_arrows`;
  id.navbar = `${id.template}_navbar`;

  $('body').on('beforeChange', `[data-id="${id.carousel}"]`, function (event, slick, currentSlide, nextSlide) {
    const toLastSlide = nextSlide === slick.slideCount - 1;
    console.log("beforeChange" + slick.slideCount);
    if (toLastSlide) {
      console.log("beforeChange2");
      const $nextPageButton = $(`[data-id="${id.carousel}"] .slick-slide`)
        .eq(nextSlide)
        .find('.cta-button:not([data-nav])');
      $nextPageButton
        .removeAttr('data-nav', 1)
        .attr('data-actionclick', 'legacyUserBonus')
        .attr('tabindex', '')
        .html(strings.START());
    }
  });

  //We build our page with a carousel (components/carousel.js)
  //and after it's loaded the content will be insterted
  //by 'compiling the blocks as above'.
  carousel[id.carousel] = [...pages];
  window.state.carousel = carousel;
  return {
    template: 'block-section-onboarding',
    fields: {
      id: id.template,
      class: 'carousel',
      navbar: false,

      navigation_right: reonboarding && strings.CLOSE(),
      navigation_right_action: reonboarding && 'hideLogin',
    },
    rows: [
      {
        template: 'block-insertable',
        fields: {
          id: id.arrows,
          class: 'slick-arrows',
        },
      },
      {
        template: 'block-carousel',
        fields: {
          id: id.carousel,
          navbar: false,
          //the data-slick attribute allows us to pass any options the slick carousel can define
          slick: JSON.stringify({
            dots: true,
            infinite: false,
            mobileFirst: false,
            dotsClass: 'slick-custom__dots',
            easing: 'ease-in',
            arrows: true,
            touchThreshold: 15, //To advance slides, the user must swipe a length of (1/touchThreshold) * the width of the slider
            waitForAnimate: true,
            prevArrow: `<button class="slick-arrow slick-arrow-prev"><i class="f-icn f-icn-chevron-left"></i></button>`,
            nextArrow: `<button class="slick-arrow slick-arrow-next"><i class="f-icn f-icn-chevron-right"></i></button>`,
            appendArrows: `#${id.arrows}`,
            appendDots: `#${id.navbar}`,
          }),
        },
      },
      {
        template: 'block-insertable',
        fields: {
          id: id.navbar,
          class: 'slick-nav-bar',
        },
      },
    ],
  };
};
$('body').on('click', `#onboarding_existingUser .cta-button`, function () {
  $('#onboarding_existingUser [data-slick]').slick('slickNext');
});
