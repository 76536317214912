const config = require('../../../config');
const strings = config.strings;

const { ctaButton } = require('../../page_helpers/components');

module.exports = ({ nav, data }) => {
  const userData = (window.state && window.state.userData) || {};
  const currentReferralCode = userData.referral_used || '';

  setTimeout(_ => {
    $('#referral-code-edit-field').focus();
  }, 500);

  return {
    template: 'block-section-page',
    fields: {
      id: 'edit-referral-code',
      active: false,
      header: strings.PROFILE_EDIT_REFERRAL_CODE_HEADING(),

      navigation_left: config.strings.BACK(),
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'referralCode',
    },
    rows: [
      {
        template: 'block-section-row',
        fields: {
          secondary_heading: strings.PROFILE_REFERRAL_CODE_LABEL(),
        },
        columns: [
          {
            template: 'block-editable-primary-text',
            fields: {
              id: 'referral-code-edit-field',
              for: 'referral-code-edit-field',
              name: 'referral-code-edit-field',
              value: currentReferralCode,
              primary_text: currentReferralCode,
              placeholder: strings.PROFILE_REFERRAL_CODE_PLACEHOLDER(),
            },
          },
        ],
      },
      ctaButton(config.strings.SAVE(), 'updateReferralCode'),
    ],
  };
};
