module.exports = {
  en: require('./strings.en'),
  de: require('./strings.de'),
  es: require('./strings.es'),
  fr: require('./strings.fr'),
  pt: require('./strings.pt'),
  ro: require('./strings.ro'),
  it: require('./strings.it'),
  zh: require('./strings.zh'),
  zh_hant: require('./strings.zh_hant'),
};
