const { strings } = require('../../../config');
const lochyLoadingTemplate = require('./lochyLoadingTemplate');

module.exports = () => ({
  ...lochyLoadingTemplate({
    id: 'vault_loading',
    heading: strings.STILL_LOADING_2(),
    lochy: 'dancing',
    bearWithUs: {
      interval: 4000,
      lochys: [
        {
          updateText: strings.STILL_LOADING(),
          updateLochy: 'unlocking',
        },
        {
          updateText: strings.STILL_LOADING(),
          updateLochy: 'mechanic',
        },
        {
          updateText: strings.STILL_LOADING(),
          updateLochy: 'shield',
        },
        {
          updateText: strings.STILL_LOADING(),
          updateLochy: 'safe',
        },
      ],
    },
  }),
});
