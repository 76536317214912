const config = require('../../../config');

const onboadingTemplate = require('./onboarding_template');

const strings = require('../../../config').strings;

module.exports = ({ nav }) => {
  return onboadingTemplate({
    nav,
    id: 'onboarding-doodletutorial1',
    lochyImagePath: 'img/lochy/08-Lochy-Cool.gif',
    primary_heading: strings.ONBOARDING_DOODLETUTORIAL1_HEADING(),
    nextLink: strings.ONBOARDING_SHOWMEHOW(),
    nextPage: 'onboarding_stage2',
    navbar: false,
    close: true,
  });
};
