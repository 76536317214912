module.exports = ({ z2hApp }) => {
  window.state.userData.activeOnboarding = false;
  window.state.userData.reonboarding = false;
  z2hApp.setSectionPaneActive(1);

  $('.overlay').fadeOut();

  $('body').trigger('z2hApp.statusBarChange');
  const errorManager = require('../../utils/errorManager');
  errorManager.clearAllErrors();
  localStorage.setItem('onboarding_complete', true);

  const endpoint = 'users/@me/complete-task';
  const data = JSON.stringify({ taskName: 'step_onboarding' });

  z2hApp.requestData('post', '', endpoint, data);
  setTimeout(() => {
    $('body').trigger('z2hApp.pagedisplayed', { newPane: $('#pane-1-inner').find('.active') });
  }, 1300);
};
