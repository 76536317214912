const strings = require('../../../config').strings;
const fb = require('../../app/firebaseUtils');

module.exports = ({ z2hApp }) => {
  // eslint-disable-next-line no-restricted-globals
  const conf = confirm(strings.MSG_LOGOUT_ALL_DEVICES_CONFIRM());
  if (conf) {
    $('#pane-2').addClass('busy');
    z2hApp.requestData('post', '', 'users/@me/logout-all').then((r) => {
      z2hApp.clearCachedData();
      fb.signOut();
      window.location.reload();
    });
  }
};
