module.exports = ({ currentTarget, z2hApp }) => {
  // Store in state

  let doodle = (window.currentDoodlepad.getShape() || {}).shape;

  window.state.doodlepadToConfirm = doodle;

  // Go to next page
  //if (window.state.userData.activeOnboarding) {
  const activePaneIndex = $('.overlay .active').index();

  const paneDetails = window.getActivePaneDetails();
  z2hApp.paneNavigation('onboarding_doodle_confirm', paneDetails.pane, paneDetails.activePaneIndex + 1);
  return;
  //}
};
