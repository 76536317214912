const config = require('../../../config');
const actions = require('../../actions');

const validations = require('../../validations');
const z2hApp = require('../../app/z2hApp');
const strings = config.strings;
const { helpTextRow } = require('../../page_helpers/components');

module.exports = ({ data, nav }) => {
  console.log('nav: ', nav);
 

  function submit(e) {
    const elem = $(e.currentTarget);
    const sectionPane = elem.closest('.section-pane');

    z2hApp.paneNavigation('verifyDevice_loading', $('.overlay'), nav + 1);

    actions['confirmActivationCode']({ z2hApp, sectionPane });
  }
  $('body').on('keyup', '[data_name="new-user-activation-code"] input', (e) => {
 
    const input = $(e.target);
    let fieldIndex = parseInt(input.attr('data-index'));
    if (e.key === 'Backspace' && fieldIndex > 0) {
      e.target.previousElementSibling.focus();
      return;
    }
  });
  // $('body').on('paste', '[data_name="new-user-activation-code"] input', (e) => {
  //   const data = e.originalEvent.clipboardData.getData('text') || window.clipboardData.getData('text');
  //   const value = data.split('');
  //   if (value.length === 6) {
  //     const inputs = $('.otp-field input');
  //     inputs.each((index, input) => (input.value = value[index]));

  //     $(this).blur();
  //     submit(e);
  //   }
  // });

  $('body').on('input', '[data_name="new-user-activation-code"] input', (e) => {
    //const data = e.target.value
    const inputs = $('.otp-field input');
    const input = $(e.target);
    const data = e.originalEvent.data || e.target.value;
    const value = data.split('');

  
    let isValidInput = false;
    if (value.length === 1) {
       isValidInput = data.match(/[0-9]/gi);
      input.val(isValidInput ? value[0] : '');
    }
    let fieldIndex = parseInt(input.attr('data-index'));


    //check all values are numbers
    let allValuesAreNumbers = true;
    value.forEach((val) => {
      if (isNaN(val)) {
        allValuesAreNumbers = false;
      }
    });
    if (!allValuesAreNumbers) {
      e.target.value = '';
      return;
    }
    if (value.length === 6) {
      const inputs = $('.otp-field input');
      inputs.each((index, input) => (input.value = value[index]));

      //blur the input
      $(e.target).blur();
      
      submit(e);
      return;
    }
    if (fieldIndex < 5 && isValidInput) {
      e.target.nextElementSibling.focus();
    }
    // if (e.originalEvent.inputType === 'deleteContentBackward' && fieldIndex > 0) {
    //   e.target.previousElementSibling.focus();
    //   return;
    // }
    if (fieldIndex == inputs.length - 1 && isValidInput) {
      //check if each field has a value
      let allFieldsHaveValue = true;
      inputs.each((index, input) => {
        if (!input.value || input.value === '') {
          allFieldsHaveValue = false;
        }
      });
      if (allFieldsHaveValue) {
        $(e.target).blur();
        submit(e);
      }
    }
  });

  return {
    template: 'block-section-page',
    fields: {
      id: 'verify-device-2',
      active: false,

      // focus_on: "block-activation-code-1",

      /// TODO

      // I've disabled this for now as on iOS it will set focus on the field,
      // the keyboard pops up, but then the signInWithPhoneNumber stuff will steal
      // focus (annoyingly) and the keyboard immediately disappears. In iOS we
      // cannot programatically set focus back on the input field.

      //header: strings.CREATE_ACCOUNT_2_HEADER(),

      class: ['no-title', 'onboarding'],
      // navigation_left: strings.BACK(),
      // navigation_left_icon: '',
      // navigation_left_data: '1',
      // navigation_left_template: 'back',

      // navigation_right: strings.NEXT(),
      // navigation_right_icon: '',
      // navigation_right_data: nav + 1,
      // navigation_right_template: 'verifyDevice_loading',
      // navigation_right_validation: 'validateActivationCode',
      // navigation_right_action: 'confirmActivationCode',
    },
    rows: [
      {
        template: 'block-close',
        fields: {
          navigation_icon: '',
          navigation_data: '0',
          navigation_template: 'back',
          action: null,
          animation: 'false',
        },
      },
      {
        template: 'block-section-row',
        fields: {
          primary_heading: strings.CREATE_ACCOUNT_2_HEADING(),
          class: ['align-left', 'no-border'],
          column_class: ['justify-content-start'],
        },
        columns: [
          {
            template: 'block-info-text',
            fields: {
              text: strings.CREATE_ACCOUNT_2_MSG()(data.phoneNumber),
            },
            columns: [],
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          primary_text: '',
          class: 'no-border',
        },
        columns: [
          {
            template: 'block-otp',
            fields: { data_name: 'new-user-activation-code' },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          primary_text: '',
          class: 'no-border',
        },
        columns: [
          {
            template: 'block-icn-button',
            fields: {
              icon: 'check-solid',
              class: 'icn-button-lrg',
              navigation_data: 4,
              navigation_template: 'verifyDevice_loading',
              navigation_action: 'confirmActivationCode',
              navigation_validation: 'validateActivationCode',
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          primary_text: '',
          class: ['no-border', 'grow'],
          class2: 'centered-text',
        },
        columns: [
          {
            template: 'block-text-button',
            fields: {
              navigation_data: 1,
              navigation_template: 'back',
              text: strings.CREATE_ACCOUNT_2_LINK_BACK(),
            },
          },
        ],
      },
      helpTextRow(strings.CREATE_ACCOUNT_2_MSG_2()(config.urls.TERMS_OF_SERVICE), false, false, true, true),
    ],
  };
};

$('body').on('keyup', '[data_name="new-user-activation-code"] input', (e) => {
  if (e.key !== 'Enter') return;

  $('.icn-button[data-actionclick=confirmActivationCode]').click();
});
