const DEFAULT_LINK_EXPIRY = 60 * 6; // minutes
const DEFAULT_LINK_USES = 1;

const pane = '#pane-2';

function createGroupInvite({ element }) {
  //$(pane).addClass('busy');
  const overlays = require('../../app/overlays');
  const lochyLoadingText = require('../../page_helpers/paneBusyText');
  overlays.makePaneLookBusy(2, { text: lochyLoadingText['createGroupInvite']() });

  // Check which user preference the toggle switch is for
  const dataId = $(element).attr('data-id');
  const groupId = dataId || z2hApp.currentGroup().id;
  let inviteParms = z2hApp.groupInviteParameters()
  const data = { expiry_time: inviteParms.expiry * 60 * 1000, maximum_uses: inviteParms.maxUses };

  let newInviteCode = '';
  // Generate new invite code and refresh group's invites list
  return z2hApp.requestData('post', '', `groups/${groupId}/invite`, data).then(response => {
    newInviteCode = response.data && response.data.url;
    const newInvite = {
      id: newInviteCode,
      link_creation_date: new Date().getTime(),
      expiry_date: new Date().getTime() + data.expiry_time,
      maximum_uses: data.maximum_uses,
    };
    z2hApp.addInviteToCurrentGroup( newInvite, groupId );
    z2hApp.setCurrentInviteId(newInviteCode);

    const activePaneIndex = $(pane + '-inner')
      .children('.active')
      .index();

    if (!window.state.userData.name || window.state.userData.name === '') {
      z2hApp.paneNavigation('shareInviteUsername', $(pane), activePaneIndex + 1);
    } else {
      z2hApp.paneNavigation('shareInvite', $(pane), activePaneIndex + 1);
    }
  });
}
module.exports = createGroupInvite;
