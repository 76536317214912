module.exports = ({ data }) => {
  let groupId = window.state.selectedGroupId;

  if (groupId === 'new') {
    //wev've ourselves in a dodgy state hre, let's just go back
    //to the personal group
    window.state.selectedGroupId = window.state.userData.personal_group_id;
    groupId = window.state.selectedGroupId;
  }

  let group = window.state.groupsList.find(g => g.id === groupId);

  let userIsAdminOrOwner = false;

  if (!group) {
    group = window.state.groupsList.find(g => g.personal);
  }

  if (!group.personal) {
    //There's an issue where we might not have the groups user details yet...
    //I don't know why!
    //TODO

    const personalTypeInGroup = ((group.users && group.users[window.state.userData.id]) || 0).member_type;

    const userIsAdmin = personalTypeInGroup === 1;
    const userIsOwner = personalTypeInGroup === 2;
    userIsAdminOrOwner = userIsAdmin || userIsOwner;
  }

  group.showInviteIcon = !group.personal && userIsAdminOrOwner;

  return Promise.resolve(group);
};
