module.exports = (_) => {
  console.log('addService_onboarding_complete.js');
  //check the onboarding tasks are completed...
  let data = {};

  //the first status is to check if they created an account...
  //always true
  data.createAccount = true;
  //the second is to check if they've actuall created the service
  data.signatureDoodle = window.state.currentServices.length > 0 || window.state.groupsList.length > 0;
  //same as above
  data.firstLogin = window.state.currentServices.length > 0 || window.state.groupsList.length > 0;

  //reset password? did they click the reset password button?
  //we might assume they've reset a password if they are not actually from the onboarding phase
  data.resetPassword = localStorage.getItem('generated-at-least-once');

  if (!data.resetPassword) {
    for (const group of window.state.groupsList) {
      if (group.services && group.services.some((g) => g.last_used > 0)) {
        data.resetPassword = true;
        localStorage.setItem('generated-at-least-once', true);
      }
    }
  }
 //data.resetPassword = false;
  //run import ... TODO
  data.importservices = false;

  //healthcheck ...
  data.healthCheck = localStorage.getItem('healthcheck-complete') || window.state.userData.healthcheck;

  data.overlay = $('.overlay').is(':visible');

  return Promise.resolve(data);
};
