const strings = require('../../../config/strings');

module.exports = async ({ element, section, z2hApp }) => {

  const importServices = window.state.groupsList.find(g => g.name === strings.IMPORT_SERVICE_GROUP_NAME());
  let groupId = importServices ? importServices.id : null;


  if (!importServices) {
    //If we don't have an import services group then we need to create it.

    z2hApp.requestData('post', '', 'groups/new', { name: strings.IMPORT_SERVICE_GROUP_NAME(), maximum_members: 1, mfa_level: 0 })
      .then(response => {
        groupId = response.data.id;

        // Update group name in state
        const group = window.state.groupsList.find(g => g.id === window.state.selectedGroupId) || {};
        group.name = strings.IMPORT_SERVICE_GROUP_NAME();
        group.id = groupId;
        window.state.selectedGroupId = groupId;

      }).then(_ => {
        return z2hApp.reloadFullGroupsList();
      }).then(_ => {
        window.state.selectedGroupId = groupId;
        //z2hApp.reloadUpdateSections();
        //z2hApp.refreshGroupsInNav();
        const paneDetails = window.getActivePaneDetails('#pane-2');
        z2hApp.paneNavigation('importMoveServices', paneDetails.pane, paneDetails.activePaneIndex + 1, null);

      })
  } else {
    window.state.selectedGroupId = groupId;
    const paneDetails = window.getActivePaneDetails('#pane-2');
    z2hApp.paneNavigation('importMoveServices', paneDetails.pane, paneDetails.activePaneIndex + 1, null);

  }

}