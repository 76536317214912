module.exports = {
  devices: require('./devices'),
  viewProfile: require('./viewProfile'),
  inviteAFriend: require('./inviteAFriend'),
  viewProfileAccount: require('./viewProfileAccount'),
  viewAppPreferences: require('./viewAppPreferences'),
  premium: require('./premium'),
  viewYourSubscription: require('./viewYourSubscription'),
  enableBiometricsGroups: require('./enableBiometricsGroups'),
  enableBiometricsDoodle: require('./enableBiometricsDoodle'),
  notifications: require('./notifications'),
  replayDoodle: require('./replayDoodle'),
  notificationsFromBell: require('./notificationsFromBell'),
};
