const showToastMessage = require('../action_helpers/showToastMessage');
const strings = require('../../config').strings;

module.exports = (section) => {
  // Get doodle that user drew
  let doodle = (window.currentDoodlepad.getShape() || {}).shape;

  console.log('doodle', doodle);
  let error = false;

  // Check that code is not empty
  if (!doodle) {
    showToastMessage(strings.ERROR_NO_DOODLE());
    error = true;
  }

  if (error) {
    throw new Error('Validation error');
  }
};
