
const { strings, urls } = require('../../../config');
const { infoTextRow } = require('../../page_helpers/components');

module.exports = ({ nav }) => {
  return {
    template: 'block-section-page',
    fields: {
      id: 'healthcheck',

      header: 'Healthcheck',
    },
    rows: [infoTextRow('Coming Soon', true, false)],
  };
};
