const getAccessKeys = require('../../getPageData_helpers/getAccessKeys');
const showToastMessage = require('../../action_helpers/showToastMessage');
const strings = require('../../../config').strings;
const _ = require('lodash');
const {
  infoTextRow,
  serviceField,
  unavailableServiceField,
  upgradeTodayButton,
  loadingServiceField,
} = require('../../page_helpers/components');
module.exports = ({ z2hApp, menuTarget }) => {
  // Get ID of selected service
  const row = $(menuTarget).closest('.row');
  const fieldId = row.attr('id');

  const copyIconLabel = strings.SERVICE_COPY_ICON_LABEL();
  const viewIconLabel = strings.SERVICE_VIEW_ICON_LABEL();
  const injectIconLabel = strings.SERVICE_INJECT_LABEL();

  const serviceData = window.z2hApp.pageData.service;
  const field = window.z2hApp.pageData.service.fields[fieldId];

  window.state.selectedFieldId = fieldId;
  z2hApp.active.pageId = fieldId;
  let serviceId = window.z2hApp.pageData.service.id;

  if (window.z2hApp.pageData.service.id !== window.state.currentService.id) {
    const services = window.state.currentServices;
    const service = services.find((s) => s.id === window.state.currentService.id);

    serviceId = service?.id;
    window.z2hApp.pageData = z2hApp.pageData || {};
    window.z2hApp.pageData.service = _.cloneDeep(service);

    window.z2hApp.pageData.service.fieldsSaved = {};
    window.z2hApp.pageData.service.fieldsUnsaved = {};
    window.z2hApp.pageData.service.fieldsToBeReset = {};
    window.z2hApp.pageData.service.fieldsToBeRestored = {};

    delete window.z2hApp.pageData.service.resetFromContext;
  }
  window.serviceFieldPromises = window.serviceFieldPromises || {};
  
  window.serviceFieldPromises[serviceId] =
    window.serviceFieldPromises[serviceId] || z2hApp.requestData(`get`, '', `services/${serviceId}/fields`);

  return window.serviceFieldPromises[serviceId].then((response) => {
    window.z2hApp.pageData.service.fields[fieldId] = { ...field, ...response.data[fieldId] };
    window.z2hApp.pageData.service.fieldsSaved[fieldId] = { ...field, ...response.data[fieldId] };

    // Array of field IDs
    window.z2hApp.pageData.service.fieldsArray = Object.keys(response.data);
    // Array of fields before any are deleted/added
    window.z2hApp.pageData.service.origFieldsArray = Object.keys(response.data);

    const { pane, activePaneIndex } = getActivePaneDetails('#pane-2');

    z2hApp.paneNavigation('editServiceFieldDetails', pane, activePaneIndex + 1);
    return z2hApp.pageData.service;
  });
};
