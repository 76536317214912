const $ = require('jquery');

module.exports = function usePasswordInCurrentTab({ currentTarget }) {
  const service = window.state.currentService || {};
  const fields = service.fields || {};
  const fieldId = $(currentTarget).closest('.row').attr('id');
  if (!fieldId) return;

  const fieldData = fields[fieldId] || {};
  const password = fieldData.access_key;
  if (!password || !window.parent) return;

  window.parent.postMessage({ eventName: 'usePasswordInCurrentTab', data: password }, '*');
};
