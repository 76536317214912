const $ = require('jquery');

const fb = require('./firebaseUtils');

const config = require('../../config');
const { app } = config;

const FADE_OUT_TRANSITION = 'transition-opacity-0';
const FADE_OUT_DURATION = 300;

// =================================================================================================

function transitionOutOfLoadingScreen() {
  console.info('Hiding loading screen');

  const loginScreen = $('.overlay');
  const splashScreen = $('#splash-screen');

  loginScreen.removeClass(FADE_OUT_TRANSITION);

  if (!fb.getCurrentUser()) {
    loginScreen.show();
  }

  splashScreen.addClass(FADE_OUT_TRANSITION);
  setTimeout((_) => splashScreen.hide(), FADE_OUT_DURATION);
}

// =================================================================================================
function menuIsOpen() {
  return $('body').hasClass('menu-open');
}

function openMenu() {
  $('body').addClass('menu-open opening');
  setTimeout(function () {
    $('body').removeClass('opening');
  }, 200);
}

function closeMenu() {
  if (menuIsOpen()) {
    $('body').removeClass('opening').addClass('closing');
    setTimeout(function () {
      $('body').removeClass('closing menu-open');
    }, 200);
  }
}

function toggleMenu() {
  if (menuIsOpen()) {
    closeMenu();
  } else {
    openMenu();
  }
}

function compactNav() {
  if ($('body').outerWidth() > app.MOBILE_VIEW_BREAKPOINT && $('body').outerWidth() <= app.COMPACT_VIEW_BREAKPOINT) {
    $('body').addClass('compact-nav');
  } else {
    $('body').removeClass('compact-nav');
  }
}

//Throttled window re-size monitoring
(function () {
  compactNav();
  var doThisAfterResizing;
  $(window).on('resize orientationchange', function () {
    clearTimeout(doThisAfterResizing);
    doThisAfterResizing = setTimeout(function () {
      compactNav();
    }, 200);
  });
})();

// Open/close menu based on swipe gestures
function onSwipeGesture(event, direction) {
  // Ignore gestures within doodlepad, also carousel
  // if ($(event.target).closest('.doodlepad').length) return;
  // if ($(event.target).closest('[data-slick]').length) return;

  if (!$(event.target).closest('#pane-1').length) return;

  if (direction === 'right' && !menuIsOpen()) {
    openMenu();
  } else if (direction === 'left' && menuIsOpen()) {
    closeMenu();
  }
}

// =================================================================================================
/*
// Show menu by default in browsers > 768px wide
if ($('html').width() > app.MOBILE_VIEW_BREAKPOINT) {
  openMenu();
}
*/

// =================================================================================================
// Event handlers

// Click icon to toggle menu
$('body').on('click', '.login-list-page__group-icon-wrapper', (_) => setTimeout(toggleMenu, 20));
$('body').on('click', '#menu-icon', (_) => setTimeout(toggleMenu, 20));

$(window).resize((_) => closeMenu());

// Close menu if clicking anywhere
// We want to capture a click within the document during the 'capture' phase
// (i.e. prior to any other click handlers). jQuery doesn't let us do that so
// we need to use document.addEventListener(... {capture:true}).
const onDocumentClick = (e) => {
  // If body has menu open, then close it
  if (!$('body').hasClass('menu-open') || $('html').width() > app.MOBILE_VIEW_BREAKPOINT) return;

  closeMenu();

  // If the click happened outside of the menu, prevent it triggering anything else
  if (!$(e.target).closest('#main-nav').length) {
    e.stopPropagation();
  }
};
document.addEventListener('click', onDocumentClick, { capture: true });

// =================================================================================================
// Touch/gesture event handling

// Open/close menu based on swipe gestures
function getTouchEventPosition(event) {
  const touch = event.originalEvent.touches[0] || event.originalEvent.changedTouches[0];
  return { x: touch.pageX, y: touch.pageY };
}

function addHorizontalSwipeListener(elem, callback) {
  let touchStartPos = null;
  // Capture pointer position on touch start
  elem.on('touchstart', (e) => {
    touchStartPos = getTouchEventPosition(e);
  });
  // On touch end, detect if it appears that the user swiped in a particular horizontal direction
  elem.on('touchend', (e) => {
    const touchEndPos = getTouchEventPosition(e);
    const swipeThreshold = 60; // pixels
    if (!touchStartPos) return;
    const yDelta = touchEndPos.y - touchStartPos.y;
    const xDelta = touchEndPos.x - touchStartPos.x;
    // Check if the distance moved is over our threshold to look like a swipe
    if (Math.abs(xDelta) < swipeThreshold) return;
    // Check if it was a horizontal swipe (i.e. pointer moved more horizontally than it did vertically)
    if (Math.abs(xDelta) > Math.abs(yDelta)) {
      // Check direction
      if (xDelta > 0) callback(e, 'right');
      else callback(e, 'left');
    }
  });
}

addHorizontalSwipeListener($('.main'), onSwipeGesture);

// =================================================================================================

module.exports = {
  transitionOutOfLoadingScreen,
  toggleMenu,
  closeMenu,
  openMenu,
  compactNav,
};
