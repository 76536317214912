const getDefaultGenerationSettings = require('../../getPageData_helpers/getDefaultGenerationSettings');

module.exports = (_) => {
  // Initialise page data in z2hApp
  z2hApp.pageData = z2hApp.pageData || {};
  z2hApp.pageData.service = z2hApp.pageData.service || {};
  z2hApp.pageData.service.fieldsArray = z2hApp.pageData.service.fieldsArray || [];
  z2hApp.pageData.service.fieldsSaved = z2hApp.pageData.service.fieldsSaved || {};

  window.state.temporaryServiceIcon = null;
  window.state.temporaryServiceIconChosen = false;
  window.state.selectedServiceId = 'new';

  return getDefaultGenerationSettings().then((settings) => ({ defaultGenerationSettings: settings }));
};
