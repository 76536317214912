const { strings } = require('../../../config')
const lochyLoadingTemplate = require('./lochyLoadingTemplate');

module.exports = () => ({
  ...(lochyLoadingTemplate({
    id: 'saveGroupDetails_loading',
    heading: strings.GROUP_DETAIL_SAVE_LOADING(),
    lochy: 'cool',
  }
  ))
})