module.exports = ({ currentTarget, init = null }) => {
  //Initialisation
  if (init) {
    if (!init.lowercase && !init.uppercase && !init.numbers && !init.simple_symbols && !init.symbols) {
      //No letters/numbers/symbols used in this password
      //Hide the letters/numbers/symbols counter
      $('#letterNumberSymbolCustomLengthWrapper').hide();
      $('#letterNumberSymbolCustomLength').val(init.length);
      return;
    }

    //There is at least one letter/number/symbol used in this password
    $('#letterNumberSymbolCustomLength').val(init.length > 0 ? init.length : init.words ? 5 : 16);
    $('#letterNumberSymbolCustomLengthWrapper').show();
    
    const toggleSwitches = $('[data-actionclick="selectLetterNumberSymbolWord"]:not([value="WORDS"])');

    // let selected = false;
    // toggleSwitches.each((idx, elem) => {
    //   const elemVal = $(elem).val();
    //   if (elemVal === 'CUSTOM') {
    //     if (!selected) {
    //       $(elem).addClass('active');
    //       $(elem).attr('data-selected', true);

    //       $('#letterNumberSymbolCustomLengthWrapper').show();
    //       $('#letterNumberSymbolCustomLength').val(init.length > 0 ? init.length : 16);
    //     }
    //     return;
    //   }
    //   const select = init.length === parseInt(elemVal);

    //   if (select) {
    //     $(elem).addClass('active');
    //     $(elem).attr('data-selected', true);
    //     $('#letterNumberSymbolCustomLength').val(init.length);
    //     selected = true;
    //   } else {
    //     $(elem).removeClass('active');
    //     $(elem).attr('data-selected', false);
    //   }
    // });


    //Loop over each switch and work out if it should be on.
    toggleSwitches
      .each((idx, elem) => {
        const type = $(elem).val();
        let select;
        if (type === 'LETTERS') {
          select = init.lowercase || init.uppercase;
        }
        if (type === 'NUMBERS') {
          select = init.numbers;
        }
        if (type === 'SYMBOLS') {
          select = init.simple_symbols || init.symbols;
        }
        if (type === 'WORDS') {
          select = init.words;
        }
        if (select) {
          $(elem).attr('data-selected', true);
          $(elem).addClass('active');
        } else {
          $(elem).attr('data-selected', false);
          $(elem).removeClass('active');
        }
      });


    return;
  }

  //Selected counter
  const DOMTarget = $(currentTarget);

  const theLetterNumberSymbolSwitches = $('[data-actionclick="selectLetterNumberSymbolWord"]:not([value="WORDS"])');
  //Check to see if the letters/numbers/symbols switches are all off
  let theLetterNumberSymbolSwitchesAreAllOff = true;
  theLetterNumberSymbolSwitches.each((idx, elem) => {
    if ($(elem).attr('data-selected') === 'true') {
      //One of the letters/numbers/symbols switches is now on
      theLetterNumberSymbolSwitchesAreAllOff = false;
      // Show password length field if it is hidden
      $('#letterNumberSymbolCustomLengthWrapper').show();
      return false;
    }
  });
  if (theLetterNumberSymbolSwitchesAreAllOff === true) {

    $('#letterNumberSymbolCustomLengthWrapper').hide();

    $('#letterNumberSymbolCustomLength').val(Number(DOMTarget.attr('data-value')));

    const fieldData = z2hApp.pageData.service.fieldsUnsaved[z2hApp.active.pageId];

    // eslint-disable-next-line camelcase
    const { generation_settings } = fieldData;
    // eslint-disable-next-line camelcase
    generation_settings.length = Number(DOMTarget.attr('data-value'));

  }

  
  
  
  
  // const tabs = $('#letterNumberSymbolCountSelector').children();

  // tabs.each((idx, elem) => {
  //   $(elem).removeClass('active');
  //   $(elem).attr('data-selected', false);
  // });

  // DOMTarget.attr('data-selected', true);
  // DOMTarget.addClass('active');

  // if (DOMTarget.attr('data-value') === 'CUSTOM') {
  //   $('#letterNumberSymbolCustomLengthWrapper').show();
  // } else {
  //   $('#letterNumberSymbolCustomLengthWrapper').hide();

  //   $('#letterNumberSymbolCustomLength').val(Number(DOMTarget.attr('data-value')));

  //   const fieldData = z2hApp.pageData.service.fieldsUnsaved[z2hApp.active.pageId];

  //   // eslint-disable-next-line camelcase
  //   const { generation_settings } = fieldData;
  //   // eslint-disable-next-line camelcase
  //   generation_settings.length = Number(DOMTarget.attr('data-value'));
  // }
};
