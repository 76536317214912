
const { strings, urls } = require('../../../config');
const { infoTextRow } = require('../../page_helpers/components');


//If this is not the electron app then we need to tell them to go and 
//and download the app on their PC/MAC
module.exports = ({ nav }) => {
  return {
    template: 'block-section-page',
    fields: {
      id: 'importServices',
      active: false,
      header: strings.IMPORT_TYPE_HEADER(),
      navbar: false,
      navigation_left: strings.BACK(),
      navigation_left_icon: '',
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'back',
      navigation_right: strings.CANCEL(),
      navigation_right_icon: '',
      navigation_right_data: 0,
      navigation_right_template: 'back',
    },
    rows: [


      infoTextRow(strings.IMPORT_TYPE_NOTE_1(), false, false, true),
      {
        template: 'block-section-row',
        fields: {
          class: 'centered-text',
          class2: 'no-border',
        },
        columns: [
          {
            template: 'block-text-button',
            fields: {
              text: strings.IMPORT_TYPE_BUTTON_1(),
              action: 'importCreatingServices2',
              //navigation_data: nav + 1,
              class: 'cta-button',
            }
          }
        ]
      },

      infoTextRow(strings.IMPORT_TYPE_NOTE_2(), false, false, true),



      {
        template: 'block-section-row',
        fields: {
          class: 'centered-text',
          class2: 'no-border',
        },
        columns: [
          {
            template: 'block-text-button',
            fields: {
              text: strings.IMPORT_TYPE_BUTTON_2(),
              action: 'importCreatingServices2',
              //navigation_data: nav + 1,
              class: 'cta-button',
            }
          }
        ]
      },
      infoTextRow(strings.IMPORT_TYPE_NOTE_3(), false, false, true),
    ],
  };
};
