const DialogMgr = require('dialog-manager');

// Ensure that dialogs can be closed by clicking on them
$(document).on('click', '.dialogMgr_content', _ => {
  $('.dialogMgr_root').click();
});

/**
 * Displays a message to the user for a short duration.
 * @param {string} msg Message to be displayed to user.
 */

module.exports = msg => {
  DialogMgr.showMsg({ msgHtml: msg, duration: 10000 });
};
