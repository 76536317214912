const showAlertMessage = require('../../action_helpers/showAlertMessage');
const strings = require('../../../config/strings');

module.exports = ({ element }) => {
  const countryData = $(element).countrySelect('getSelectedCountryData');
  if (countryData && countryData.iso2) {
    const countryCode = countryData.iso2 === 'gb' ? 'uk' : countryData.iso2;
    if (countryCode === localStorage.getItem('language')) return;
    //TODO
    const message =
      strings.CONFIRM_COUNTRY_UPDATE_NOTE() +
      `
      <section class="row centered-text"> 
        <div class="column">
          <div class="column-group">
            <button class="column text-button cta-button" data-id="${countryCode}" data-actionclick="updateUserCountry">${strings.CONFIRM_BUTTON()}</button> 
          </div>
        </div>      
      </section>`;

    showAlertMessage(message, true, strings.CONFIRM_COUNTRY_UPDATE_HEADER());
    //The disalog box
    $('body').on('z2hApp.pagedisplayed', () => {
      $('.dialogMgr_root').click();
    });
  }
};
