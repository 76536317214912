const z2hApp = require('../../app/z2hApp');
const config = require('../../../config');
const strings = config.strings;

module.exports = (params) => {
  const groupId = params.dataId || window.state.selectedGroupId;

  z2hApp.setCurrentGroup(groupId);
  // check if we have biometrics for this
  return new Promise((resolve, reject) => {
    if (window.Fingerprint) {
      window.Fingerprint.isAvailable(
        (type) => {
          const prom = new Promise((resolve, reject) => {
            let biometrics;

            window.Fingerprint.show(
              { disableBackup: false ,
                title: strings.UNLOCK_BIOMETRICS(),
                description: strings.UNLOCK_DESCRIPTION()
              },
              () => {
                if (window.ss) {
                  window.ss.get(
                    (value) => {
                      console.log('Get doodle');
                      return resolve(value);
                    },
                    () => {
                      console.log('Failed to get doodle');
                      return resolve(null);
                    },
                    groupId + '_doodle',
                  );
                } else {
                  return resolve(null);
                }
              },
              () => {
                return resolve(null);
              },
            );
          });
          prom.then((biometrics) => {
            if (biometrics) {
              //z2hApp.paneNavigation(page, pane ? pane : 2, newPaneIndex ? newPaneIndex : 1, null, serviceId);
              return resolve({ group: params, biometrics });
            } else {
              return z2hApp.paneNavigation('back', $('#pane-2'), 0);
            }
          });
        },
        (error) => {
          reject();
        },
      );
    } else {
      resolve(null);
    }
  });
};
