const { strings, urls } = require('../../../config');
const { infoTextRow } = require('../../page_helpers/components');
const config = require('../../../config');
const importMoveService = require('../../actions/importServices/importMoveService');
const importDiscardService = require('../../actions/importServices/importDiscardService');

let moveServiceIndex = 0;
let currentServices = [];
let removeLastSlide = false;

const addAnotherServiceToCarousel = () => {
  if (moveServiceIndex < currentServices.length - 1) {
    moveServiceIndex += 1;

    const template = currentService(currentServices[moveServiceIndex]);
    let page = $('<div></div>');
    for (const tr of template.rows) {
      page.append(z2hApp.constructRow(tr));
    }

    $('[data-id="importMoveServices_carousel"]').slick('slickAdd', page);
  }
};

const removeCurrentServiceFromCarousel = () => {
  //remove the from the last slide and the services
  currentServices.splice(
    currentServices.findIndex((s) => s.id === window.state.selectedServiceId),
    1,
  );
  moveServiceIndex -= 1;
  removeLastSlide = true;
};

const noMoreSlides = () => {};
$('body').on('click', '[data-id="importNext"]', (e) => {
  e.preventDefault();
  addAnotherServiceToCarousel();

  $('[data-id="importMoveServices_carousel"]').slick('slickNext');
});

$('body').on('click', '[data-id="importBack"]', (e) => {
  e.preventDefault();

  $('[data-id="importMoveServices_carousel"]').slick('slickPrev');
});

$('body').on('click', '[data-id="importCreate"]', (e) => {
  e.preventDefault();
  addAnotherServiceToCarousel();
  importMoveService({});
  removeCurrentServiceFromCarousel();
  $('[data-id="importMoveServices_carousel"]').slick('slickNext');
});

$('body').on('click', '[data-id="importDiscard"]', (e) => {
  e.preventDefault();
  addAnotherServiceToCarousel();
  importDiscardService({});
  removeCurrentServiceFromCarousel();
  $('[data-id="importMoveServices_carousel"]').slick('slickNext');
});

$('body').on(
  'beforeChange',
  `[data-id="importMoveServices_carousel"]`,
  function (event, slick, currentSlide, nextSlide) {
    //make sure the group icon updated.
    //window.state.selectedImportMoveGroup;

    if (currentSlide - 1 === 0) {
      addAnotherServiceToCarousel();
    }
  },
);

$('body').on('afterChange', `[data-id="importMoveServices_carousel"]`, function (event, slick, currentSlide) {
  //make sure the group icon updated.
  //window.state.selectedImportMoveGroup;

  //We need to do some setup for each service which we may have lost at some point.
  if (currentSlide < 0) {
    currentSlide = currentServices.length - 1;
  }
  window.state.selectedServiceId = currentServices[currentSlide].id;
  const importGroup = window.state.groupsList.find((g) => g.name === 'Import services');
  window.state.currentServices = importGroup.services;
  window.state.selectedGroupId = importGroup.id;
  removeLastSlide && $('[data-id="importMoveServices_carousel"]').slick('slickRemove', currentSlide - 1);
  removeLastSlide = false;
});

const currentService = (currentService) => {
  const currentMoveGroup = window.state.selectedImportMoveGroupId;
  const importGroup = window.state.ImportGroupId;

  const group = window.state.groupsList.find((g) => g.id === window.state.selectedImportMoveGroupId);

  return {
    template: 'block-section-page',
    fields: {
      id: `importMove_${currentService.id}`,
      active: false,
      header: `${currentService.name}`,
    },
    rows: [
      {
        template: 'block-avatar-group',
        fields: {
          avatar_src_1: currentService.icon || config.app.DEFAULT_USER_ICON,
          avatar_src_2: group.personal ? window.state.userData.avatar : group.icon || config.app.DEFAULT_GROUP_ICON,
          avatar_name_1: currentService.name,
          avatar_name_2: group.name,
          class: 'with-arrow',
          avatar_context_1: window.cordova ? 'addServiceImageCordova' : 'addServiceImage',
          avatar_data_id_1: currentService.id,
        },
      },

      {
        template: 'block-section-row',
        fields: {
          secondary_heading: 'Name',
        },
        columns: [
          {
            template: 'block-editable-primary-text',
            fields: {
              id: `importName_${currentService.id}`,
              for: `importName_${currentService.id}`,
              value: currentService.name,
              primary_text: currentService.name,
              placeholder: 'Name',
              maxlength: '32',
              action: 'importNameUpdate',
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          secondary_heading: 'Username',
        },
        columns: [
          {
            template: 'block-editable-primary-text',
            fields: {
              id: `importUsername_${currentService.id}`,
              for: `importUsername_${currentService.id}`,
              label: 'username',
              value: currentService.username,
              primary_text: currentService.username,
              maxLength: 32,
              placeholder: 'Username',
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          secondary_heading: 'Website',
        },
        columns: [
          {
            template: 'block-editable-primary-text',
            fields: {
              id: `importWebsite_${currentService.id}`,
              for: `importWebsite_${currentService.id}`,
              label: 'website',
              value: currentService.website,
              primary_text: currentService.website,
              maxLength: 32,
              placeholder: 'Website',
            },
          },
        ],
      },
    ],
  };
};
//If this is not the electron app then we need to tell them to go and
//and download the app on their PC/MAC
module.exports = ({ nav }) => {
  let group = window.state.groupsList.find((g) => g.id === window.state.selectedGroupId);
  window.state.selectedImportMoveGroupId = group.id;
  if (group.name === 'Import services') {
    group = window.state.groupsList.find((g) => g.personal);
    window.state.selectedImportMoveGroupId = group.id;
  }
  const importGroup = window.state.groupsList.find((g) => g.name === 'Import services');
  window.state.currentServices = importGroup.services;
  window.state.selectedGroupId = importGroup.id;

  const pages = [];

  //create the first two slides, we will add in the others on demand.
  currentServices = window.state.selectedImportedServices;

  window.state.selectedServiceId = currentServices[0].id;

  moveServiceIndex = 0;
  if (currentServices.length >= 2) {
    pages.push(currentService(currentServices[moveServiceIndex]));
    moveServiceIndex += 1;
    pages.push(currentService(currentServices[moveServiceIndex]));
  }
  //we only have one! don't want a slider.
  else {
  }
  const id = { template: 'importMoveServices' };
  id.carousel = `${id.template}_carousel`;

  //Yeah, we're creating a carousel of animations.
  //To do so we need to add to the carousel array held in window.state
  const carousel = window.state.carousel || {};
  carousel[id.carousel] = [...pages];
  window.state.carousel = carousel;

  return {
    template: 'block-section-page',
    fields: {
      id: id.template,
      class: 'carousel',
      header: 'Move imported services',
      navigation_right_template: 'back',
      navigation_right_data: 0,
      navigation_right: 'Done',
    },
    rows: [
      infoTextRow(`Moving your imported service to <em>${group.name}</em>. `, true, false, true),

      {
        template: 'block-carousel',
        fields: {
          id: id.carousel,
          navbar: false,
          //the data-slick attribute allows us to pass any options the slick carousel can define
          slick: JSON.stringify({
            infinite: true,
            fade: true,
            mobileFirst: false,
            easing: 'ease-in',
            arrows: false,
            touchThreshold: 15, //To advance slides, the user must swipe a length of (1/touchThreshold) * the width of the slider
            waitForAnimate: true,
            speed: 600,
          }),
        },
      },
      {
        template: 'block-section-row',
        fields: {
          class: 'centered-text',
        },
        columns: [
          {
            template: 'block-icn-button',
            fields: {
              data_id: 'importBack',
              label: 'Back',
              icon: 'chevron-left',
            },
          },

          {
            template: 'block-icn-button',
            fields: {
              data_id: 'importChangeGroup',
              label: 'change group',
              icon: 'sharing',
              navigation_template: 'importGroup',
              navigation_data: nav + 1,
            },
          },
          {
            template: 'block-icn-button',
            fields: {
              data_id: 'importCreate',
              label: 'Move service',
              icon: 'switch',
            },
          },
          {
            template: 'block-icn-button',
            fields: {
              data_id: 'importDiscard',
              label: 'Discard',
              icon: 'delete',
            },
          },
          {
            template: 'block-icn-button',
            fields: {
              data_id: 'importNext',
              label: 'Skip',
              icon: 'chevron-right',
            },
          },
        ],
      },
    ],
  };
};
