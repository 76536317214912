const { isWindow } = require('jquery');

module.exports = ({ element, currentTarget, z2hApp }) => {
  // Store in state
  const group = $(element).attr('data-id');
  const doodle = (window.currentDoodlepad.getShape() || {}).shape;

  localStorage.setItem(group + '_doodle', true);
  localStorage.setItem('bio', true);

  if (window.ss) {
    window.ss.set(
      () => {
        console.log('Stored doodle');
      },
      () => {
        console.log('Failed to store doodle');
      },
      group + '_doodle',
      doodle,
    );
  }
  const paneDetails = window.getActivePaneDetails('#pane-2');

  if (window.state.addBiometricsFromSettings) {
    z2hApp.paneNavigation('enableBiometricsGroups', paneDetails.pane, 1);
  } else {
    z2hApp.paneNavigation('viewGroupDetails', paneDetails.pane, 1);
  }
  return;
};
