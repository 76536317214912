module.exports = data => {
  if (
    data.uppercase == true &&
    data.lowercase == true &&
    data.numbers == true &&
    data.symbols == false &&
    data.simple_symbols == true &&
    data.words == 0
  ) {
    return 'lns';
  }
  if (
    data.uppercase == false &&
    data.lowercase == false &&
    data.numbers == true &&
    data.symbols == false &&
    data.simple_symbols == true &&
    data.words >= 1
  ) {
    return 'wns';
  }
  if (
    data.uppercase == true &&
    data.lowercase == true &&
    data.numbers == true &&
    data.symbols == true &&
    data.simple_symbols == false &&
    data.words == 0
  ) {
    return 'lnx';
  }
  if (
    data.uppercase == false &&
    data.lowercase == false &&
    data.numbers == true &&
    data.symbols == true &&
    data.simple_symbols == false &&
    data.words >= 1
  ) {
    return 'wnx';
  }

  if (
    data.uppercase == true &&
    data.lowercase == true &&
    data.numbers == true &&
    data.symbols == false &&
    data.simple_symbols == false &&
    data.words == 0
  ) {
    return 'ln';
  }
  if (
    data.uppercase == false &&
    data.lowercase == false &&
    data.numbers == true &&
    data.symbols == false &&
    data.simple_symbols == false &&
    data.words >= 1
  ) {
    return 'wn';
  }
  if (
    data.uppercase == true &&
    data.lowercase == true &&
    data.numbers == false &&
    data.symbols == false &&
    data.simple_symbols == false &&
    data.words == 0
  ) {
    return 'l';
  }
  if (
    data.uppercase == false &&
    data.lowercase == false &&
    data.numbers == false &&
    data.symbols == false &&
    data.simple_symbols == false &&
    data.words >= 1
  ) {
    return 'w';
  }
  if (
    data.uppercase == false &&
    data.lowercase == false &&
    data.numbers == true &&
    data.symbols == false &&
    data.simple_symbols == false &&
    data.words == 0
  ) {
    return 'n';
  }
  //cases which should no longer be true since v2
  if (
    data.uppercase == true &&
    data.lowercase == true &&
    data.numbers == true &&
    data.symbols == true &&
    data.words == 0
  ) {
    return 'lns';
  }

  if (
    data.uppercase == false &&
    data.lowercase == false &&
    data.numbers == true &&
    data.symbols == true &&
    data.words >= 1
  ) {
    return 'wns';
  }

  return '';
};
