const z2hApp = require('./z2hApp');

//CONTEXT MENU (modified)
(function () {
  'use strict';

  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  //
  // H E L P E R    F U N C T I O N S
  //
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  function clickInsideElementContext(e, contextClass) {
    var el = e.srcElement || e.target;

    if (el.classList.contains(contextClass)) {
      return el;
    }

    return false;
  }

  /**
   * Get's exact position of event.
   *
   * @param {Object} e The event passed in
   * @return {Object} Returns the x and y position
   */
  function getPosition(e) {
    var posx = 0;
    var posy = 0;

    if (!e) var e = window.event;

    if (e.pageX || e.pageY) {
      posx = e.pageX;
      posy = e.pageY;
    } else if (e.clientX || e.clientY) {
      posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
      posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
    }

    return {
      x: posx,
      y: posy,
    };
  }

  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  //
  // C O R E    F U N C T I O N S
  //
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  const strings = require('../../config').strings;

  /**
   * Variables.
   */
  var contextMenuLinkClassName = 'context-menu__link';
  var contextMenuActive = 'context-menu--active';

  var menuTarget;

  var clickCoords;
  var clickCoordsX;
  var clickCoordsY;

  var menu;
  var menuWidth;
  var menuHeight;

  var windowWidth;
  var windowHeight;

  let hideMenuOnTouchAnywhere = false;

  // =================================================================================================
  // Add event handlers
  $('body').on('click', '[data-context]', handleDocumentClick);
  document.addEventListener('click', handleDocumentClick, { capture: true });
  $('body').on('keyup', handleDocumentKeyup);
  $('body').on('keyup', handleDocumentKeyup);
  window.addEventListener('resize', () => {
    if (hideMenuOnTouchAnywhere) {
      toggleMenuOff();
    }
  });

  function handleDocumentKeyup(e) {
    // On Escape, close menu
    if (e.keyCode === 27) {
      toggleMenuOff();
    }
  }

  /**
   * Initialise our application's code.
   */
  function init() {
    //contextListener();
    // $(document).on('click', handleDocumentClick);

    // keyupListener();
    // resizeListener();

    // Add a global handler for clicking on items within context menus. This is added directly
    // to the context menu items in the HTML (context-menus.html) due to an issue with iOS Safari
    // not allowing you to open up the user's photo selection dialog unless the click handler is
    // directly coded in the HTML.
    window.contextMenuHandler = function (e) {
      e.stopPropagation();
      const clickedElIsLink = clickInsideElementContext(e, contextMenuLinkClassName);

      // Default click handling
      if (clickedElIsLink) {
        e.preventDefault();
        menuItemListener(clickedElIsLink);
      }
    };
  }

  function handleDocumentClick(e) {
    hideMenuOnTouchAnywhere = false;
    // If a context menu is displayed, hide it
    if ($('.context-menu--active').length > 0) {
      toggleMenuOff();
      return;
    }

    // Check if a parent element has a data-context attribute (i.e. specifying a context menu to be displayed)
    let target = $(e.target).attr('data-context') ? $(e.target) : $(e.target).closest('[data-context]');
    if (!target.length) return;

    e.stopPropagation();

    // Remove any error tooltip if there is one on (or within) the clicked element
    // prettier-ignore
    try { target.parent().find('.tooltipstered').tooltipster('close'); }
    catch (e) { }

    // Get the name of the context menu to display
    const contextMenuName = target.attr('data-context');
    if (!contextMenuName) return;

    // Find the correct menu to display
    menu = document.querySelector('[data-menu=' + contextMenuName + ']');

    // Take this opportunity to go through the context menu HTML and replace any strings
    // like {{CONTEXT_UPLOAD_GROUP_IMAGE}} with strings.CONTEXT_UPLOAD_GROUP_IMAGE
    replaceStringsInContextMenuHtml(menu);

    e.preventDefault();
    menuTarget = e.target;

    // Display the menu
    toggleMenuOn(menu);
    if (target.attr('data-position') !== 'center') {
      positionMenu(e);
    }
  }

  /* Replaces any strings like {{CONTEXT_UPLOAD_GROUP_IMAGE}} with strings.CONTEXT_UPLOAD_GROUP_IMAGE */
  function replaceStringsInContextMenuHtml(menu) {
    if (!$(menu).attr('gotStrings')) {
      let html = $(menu).html();
      let strTemplate = html.match(/{{[A-Z0-9\_]+}}/);
      while (strTemplate) {
        const strName = strTemplate[0].replace('{{', '').replace('}}', '');
        html = html.replace(strTemplate[0], strings[strName]() || '');
        strTemplate = html.match(/{{[A-Z0-9\_]+}}/);
      }
      $(menu).html(html);
      // Set an attribute so that we don't waste time doing this again
      $(menu).attr('gotStrings', true);
    }
  }

  // Set up an event handler for iOS, to hide the menu if there is a touch anywhere
  $('body').on('touchend', toggleMenuOffDelayed);
  function toggleMenuOffDelayed() {
    setTimeout((_) => {
      if (hideMenuOnTouchAnywhere) toggleMenuOff();
    }, 100);
  }

  /**
   * Turns the custom context menu on.
   */
  function toggleMenuOn(menu) {
    $('.context-menu-overlay').addClass('active');

    $(menu).addClass(contextMenuActive).focus();
    // We delay setting on this flag, so that the menu doesn't get hidden immediately after it
    // is displayed as a result of the click/touch event that was made to display the menu
    setTimeout((_) => (hideMenuOnTouchAnywhere = true), 300);
  }

  /**
   * Turns the custom context menu off.
   */
  function toggleMenuOff() {
    $('.context-menu-overlay').removeClass('active');
    $(menu).removeClass(contextMenuActive);
    hideMenuOnTouchAnywhere = false;
  }

  /**
   * Positions the menu properly.
   *
   * @param {Object} e The event
   */
  function positionMenu(e) {
    clickCoords = getPosition(e);
    clickCoordsX = clickCoords.x;
    clickCoordsY = clickCoords.y;

    menuWidth = menu.offsetWidth + 4;
    menuHeight = menu.offsetHeight + 4;

    windowWidth = window.innerWidth;
    windowHeight = window.innerHeight;

    if (windowWidth - clickCoordsX < menuWidth) {
      menu.style.left = windowWidth - menuWidth + 'px';
    } else {
      menu.style.left = clickCoordsX + 'px';
    }

    if (windowHeight - clickCoordsY < menuHeight) {
      menu.style.top = windowHeight - menuHeight + 'px';
    } else {
      menu.style.top = clickCoordsY + 'px';
    }
  }

  /**
   * Dummy action function that logs an action when a menu item link is clicked
   *
   * @param {HTMLElement} link The link that was clicked
   */
  function menuItemListener(link) {
    var task = link.getAttribute('data-task');
    var details = link.getAttribute('data-taskDetails');
    contextMenuTasks(task, details, link, menuTarget);
    toggleMenuOff();
  }

  /**
   * Run the app.
   */
  init();
})();

function contextMenuTasks(task, details, link, menuTarget) {
  const { STOP_SIMPLE_SYMBOLS } = require('../../config/app') || false;

  const z2hTemplates = require('./templates');
  const config = require('../../config');
  const actions = require('../actions');
  const strings = config.strings;
  const detailsCopy = details;

  var $currentPane = $(menuTarget)
    .closest('.section-pane-inner')
    .find('[data-context=newServiceAddNewField]')
    .closest('.section-pane');

  if (task) {
    if (actions[task]) {
      actions[task]({ link, details, task, menuTarget, z2hApp });
    }

    // -------------------------------------------------------------------------

    if (task == 'newGroupMaxMembers') {
      if (details == 'custom') {
        var promptValue = prompt('Enter a custom amount', z2hApp.temporary.newGroupMaxMembers.current_value);
        if (promptValue == null) {
          details = z2hApp.temporary.newGroupMaxMembers.current_value;
        } else {
          details = promptValue;
        }
      }
      z2hApp.temporary.newGroupMaxMembers.current_value = details;
      $('[data-actionclick=newGroupMaxMembers]')[0].innerHTML = details;
      if (z2hApp.temporary.newGroupMaxMembers.initial_value == details) {
        z2hApp.temporary.newGroupMaxMembers.unsaved = false;
      } else {
        z2hApp.temporary.newGroupMaxMembers.unsaved = true;
      }
    }

    // -------------------------------------------------------------------------

    if (task == 'newServiceType') {
      var section = $('[data-context="newServiceType"]')[0];
      var sectionTextEl = $('[data-context="newServiceType"]').children().children().children()[1];

      // Set temporary initial detail if it doesn't exist yet
      if (!z2hApp.temporary.newServiceFieldType) {
        z2hApp.temporary.newServiceFieldType = {
          initial_value: section.getAttribute('value'),
        };
      }

      // Reconfigure the tab picker based on the field type that was selected
      const defaultGenerationSettings =
        window.state.defaultGenerationSettings || window.state.userData.default_generation || {};

      // [l]etters [w]ords [n]umbers [s]ymbols
      // also set "unsaved" data
      const unsavedSettings = z2hApp.pageData.service.fieldsUnsaved[z2hApp.active.pageId].generation_settings;
      const DFT_NUM_WORDS = 1;

      // "details" contains the field type that was selected, e.g. "lns" to
      // represent 'letters, numbers and symbols'
      unsavedSettings.uppercase = details.includes('l');
      unsavedSettings.lowercase = details.includes('l');
      unsavedSettings.numbers = details.includes('n');
      unsavedSettings.symbols = details.includes('s');
      unsavedSettings.simple_symbols = details.includes('x');

      unsavedSettings.words = details.includes('w') ? DFT_NUM_WORDS : 0;

      // Words + Numbers/Letters - default to 4 numbers/letters and 1 word
      if (details === 'wn' || details === 'wns' || details === 'wnx') {
        unsavedSettings.length = 5;
      } else if (details === 'w') {
        unsavedSettings.length = 0;
      } else {
        unsavedSettings.length = defaultGenerationSettings.length || 16;
      }

      unsavedSettings.specialCharacters = details.includes('*');

      section.setAttribute('data-value', details);

      sectionTextEl.innerText = config.strings.FIELD_TYPES()[details] || '';

      if (details.includes('*')) {
        // const paneWrapper = $('.section-pane-wrapper').parent();

        const activePaneIndex = $('#pane-2' + '-inner')
          .children('.active')
          .index();

        z2hApp.paneNavigation('specialCharacters', $('#pane-2'), activePaneIndex + 1);
      } else if (details.includes('w')) {
        // Words
        z2hApp.reconfigureTabbedPicker([1, 2, 3, 4], DFT_NUM_WORDS);
      } else {
        // Letters/Numbers
        z2hApp.reconfigureTabbedPicker([4, 6, 8, 12], unsavedSettings.length);
      }

      // Set temporary data
      z2hApp.temporary.newServiceFieldType.current_value = details;
      if (z2hApp.temporary.newServiceFieldType.current_value == z2hApp.temporary.newServiceFieldType.initial_value) {
        z2hApp.temporary.newServiceFieldType.unsaved = false;
      } else {
        z2hApp.temporary.newServiceFieldType.unsaved = true;
      }
    }

    // -------------------------------------------------------------------------

    if (task == 'newServiceAddNewField') {
      let available;
      let personal = z2hApp.currentGroup()?.personal;
      if (window.state.updateSections && window.state.updateSections.your_journey_data.is_premium) {
        available = true;
      } else if (
        window.state.updateSections &&
        z2hApp.currentGroup()?.personal &&
        window.state.updateSections.your_journey_data &&
        window.state.updateSections.your_journey_data.free_fields_used <
          window.state.updateSections.your_journey_data.free_fields_maximum
      ) {
        available = true;
      } else if (
        window.state.updateSections &&
        !z2hApp.currentGroup()?.personal &&
        window.state.updateSections.your_journey_data &&
        window.state.updateSections.your_journey_data.free_fields_used_in_other_owned_groups <
          window.state.updateSections.your_journey_data.free_fields_maximum_in_other_owned_groups
      ) {
        available = true;
      }

      if (!available) {
        const showUpgradeTodayMessage = require('../action_helpers/showUpgradeTodayPopup');
        showUpgradeTodayMessage(
          personal ? strings.GROUP_PERSONAL_UNAVAILABLE()() : strings.GROUP_UNAVAILABLE()(z2hApp.currentGroup()?.name),
        );
        return;
      }
      var templateName;
      var templateId = z2hTools.getUid();

      if (!z2hApp.pageData.service.fieldsArray) z2hApp.pageData.service.fieldsArray = [];

      if (!z2hApp.pageData.service.fieldsSaved) z2hApp.pageData.service.fieldsSaved = {};
      if (!z2hApp.pageData.service.fieldsUnsaved) z2hApp.pageData.service.fieldsUnsaved = {};

      z2hApp.pageData.service.fieldsArray.push(templateId);

      const defaultGenerationSettings = window.state.defaultGenerationSettings || {};
      /*if (details == "default") {
        templateName = "User default";
      };*/
      // if (detailsCopy == 'complexPassword') {
      //   templateName = strings.FIELD_TEMPLATE_COMPLEX_PASSWORD();
      //   z2hApp.pageData.service.fieldsSaved[templateId] = z2hApp.pageData.service.fieldsUnsaved[templateId] = {
      //     name: templateName,
      //     generation_settings: {
      //       exclude: '',
      //       length: defaultGenerationSettings.length || 16,
      //       lowercase: true,
      //       numbers: true,
      //       symbols: true,
      //       simple_symbols: false,
      //       uppercase: true,
      //       words: 0,
      //       version: 1,
      //     },
      //   };

      //   z2hApp.pageData.service.fieldsUnsaved[z2hApp.active.pageId];
      //   z2hApp.temporary[templateId] = { unsaved: true };
      // }
      const isUnique = (name) => {
        for (const obj in z2hApp.pageData.service.fieldsSaved) {
          if (z2hApp.pageData.service.fieldsSaved[obj].name === name) {
            return false;
          }
        }
        for (const obj in z2hApp.pageData.service.fieldsUnsaved) {
          if (z2hApp.pageData.service.fieldsUnsaved[obj].name === name) {
            return false;
          }
        }

        for (const obj in z2hApp.pageData.service.fields) {
          if (z2hApp.pageData.service.fields[obj].name === name) {
            return false;
          }
        }

        return true;
      };

      if (detailsCopy == 'password') {
        templateName = strings.FIELD_TEMPLATE_PASSWORD();
        for (let i = 1; !isUnique(templateName); i++) {
          templateName = strings.FIELD_TEMPLATE_PASSWORD() + ' (' + i + ')';
        }

        z2hApp.pageData.service.fieldsSaved[templateId] = z2hApp.pageData.service.fieldsUnsaved[templateId] = {
          name: templateName,
          generation_settings: {
            exclude: '',
            length: defaultGenerationSettings.length || 16,
            lowercase: true,
            numbers: true,
            symbols: false,
            simple_symbols: true,
            uppercase: true,
            words: 0,
            version: 1,
          },
        };

        z2hApp.pageData.service.fieldsUnsaved[z2hApp.active.pageId];
        z2hApp.temporary[templateId] = { unsaved: true };
      }
      if (detailsCopy == 'pin') {
        templateName = strings.FIELD_TEMPLATE_PIN();
        for (let i = 1; !isUnique(templateName); i++) {
          templateName = strings.FIELD_TEMPLATE_PIN() + ' (' + i + ')';
        }
        z2hApp.pageData.service.fieldsSaved[templateId] = z2hApp.pageData.service.fieldsUnsaved[templateId] = {
          name: templateName,
          generation_settings: {
            exclude: '',
            length: 4,
            lowercase: false,
            numbers: true,
            symbols: false,
            simple_symbols: false,
            uppercase: false,
            words: 0,
            version: 1,
          },
        };

        z2hApp.pageData.service.fieldsUnsaved[z2hApp.active.pageId];
        z2hApp.temporary[templateId] = { unsaved: true };
      }
      if (detailsCopy == 'memorableWord') {
        templateName = strings.FIELD_TEMPLATE_MEMWORD();
        for (let i = 1; !isUnique(templateName); i++) {
          templateName = strings.FIELD_TEMPLATE_MEMWORD() + ' (' + i + ')';
        }
        z2hApp.pageData.service.fieldsSaved[templateId] = z2hApp.pageData.service.fieldsUnsaved[templateId] = {
          name: templateName,
          generation_settings: {
            exclude: '',
            length: 0, // No extra letters/numbers with your 'Memorable word'
            lowercase: false,
            numbers: false,
            symbols: false,
            simple_symbols: false,
            uppercase: false,
            words: defaultGenerationSettings.words || 1,
            version: 1,
          },
        };

        z2hApp.pageData.service.fieldsUnsaved[z2hApp.active.pageId];
        z2hApp.temporary[templateId] = { unsaved: true };
      }
      if (detailsCopy == 'forghettibleWord') {
        templateName = strings.FIELD_TEMPLATE_FORGHETTIBLE();
        for (let i = 1; !isUnique(templateName); i++) {
          templateName = strings.FIELD_TEMPLATE_FORGHETTIBLE() + ' (' + i + ')';
        }
        z2hApp.pageData.service.fieldsSaved[templateId] = z2hApp.pageData.service.fieldsUnsaved[templateId] = {
          name: templateName,
          // This will essentially be a'Password' but with no numbers or special characters
          generation_settings: {
            exclude: '',
            length: defaultGenerationSettings.length || 16,
            lowercase: true,
            numbers: false,
            symbols: false,
            simple_symbols: false,
            uppercase: true,
            words: 0,
            version: 1,
          },
        };

        z2hApp.pageData.service.fieldsUnsaved[z2hApp.active.pageId];
        z2hApp.temporary[templateId] = { unsaved: true };
      }

      //Special case for notes
      if (detailsCopy == 'notes') {
        templateName = 'Notes';
        z2hApp.pageData.service.fieldsSaved[templateId] = z2hApp.pageData.service.fieldsUnsaved[templateId] = {
          name: 'Note',
          // This will essentially be a'Password' but with no numbers or special characters
          note: '',
        };

        z2hApp.pageData.service.fieldsUnsaved[z2hApp.active.pageId];
        z2hApp.temporary[templateId] = { unsaved: true };
        z2hApp.paneNavigation('newNote', $currentPane, String(nav + 1));
        return;
      }

      // Add the new field to the 'Edit service details' page

      var nav = $currentPane.index();
      var template = {
        template: 'block-section-row',
        fields: {
          navigation_data: String(nav + 1),
          navigation_template: 'editServiceFieldDetails',
          id: templateId,
          class: 'transition-collapsible-row',
          class2: 'collapsed',
        },
        columns: [
          {
            template: 'block-text-group',
            fields: {
              primary_text: templateName,
            },
          },
          {
            template: 'block-icn-button',
            fields: {
              icon: 'settings',
            },
          },
        ],
      };

      // Create rows and append to page
      var rowItem = z2hApp.constructBlock(z2hTemplates.compileBlockAttribute(template));

      // Create columns and append to row
      for (var j = 0; j < template.columns.length; j++) {
        var pageBlock = template.columns[j];
        var blockItem = z2hApp.constructBlock(z2hTemplates.compileBlockAttribute(pageBlock));
        // Append to new element
        rowItem.children('.column').children('.column-group').append(blockItem);
      }

      rowItem.insertBefore($currentPane.find('[data-context=newServiceAddNewField]'));

      // Uncollapse the new section for animation
      const toggleRowCollapse = require('../page_helpers/toggleRowCollapse');
      toggleRowCollapse(templateId);

      // If we are on the page to create a new login during the Create Account process then display
      // a tooltip to direct them to the next page.
      if ($currentPane.attr('id') === 'create-account-add-new-custom-service') {
        $('.section-pane.active .nav-link.nav-right span')
          .tooltipster({
            content: config.strings.CREATE_ACCOUNT_CUSTOM_LOGIN_NEXTPAGE_TOOLTIP(),
            theme: ['tooltipster-borderless', 'tooltipster-borderless-info'],
            trigger: 'custom',
            triggerClose: {
              click: true,
              scroll: true,
            },
            side: ['bottom', 'top'],
          })
          .tooltipster('open');
      }
      //Post processing for the new field
      if ($currentPane.attr('id') === 'view-service') {
        actions['addForghettibleFromService']({ link, details, task, menuTarget, z2hApp, templateId });
      }
    }
  } else {
    console.log('This list action has no task set');
  }
}
