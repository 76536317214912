const strings = require('../../../config').strings;

const { helpTextRow, primaryText } = require('../../page_helpers/components');

module.exports = ({ backLink = true }) => {
  // Include a 'back' link, unless we have been asked not to
  let navLeft = {};
  // if (backLink) {
  //   navLeft = {
  //     navigation_left: strings.CANCEL(),
  //     navigation_left_icon: '',
  //     navigation_left_data: '0',
  //     navigation_left_template: 'back',
  //   };
  // }

  return {
    template: 'block-section-page',
    fields: {
      id: 'verify-device-1',
      active: false,
      //header: strings.CREATE_ACCOUNT_1_HEADER(),

      class: ['no-title','onboarding'],
      focus_on: 'sign-in-number',

      ...navLeft,

      // navigation_right: strings.NEXT(),
      // navigation_right_icon: '',
      // navigation_right_data: '2',
      // navigation_right_template: 'verifyPhoneNumber_loading',
      // navigation_right_action: 'phoneNumberSignIn',
      // navigation_right_id: 'verify_device_sign_in_button',
      // navigation_right_validation: 'validatePhoneNumberSignIn',
    },
    rows: [
      {
        template: 'block-close',
        fields: {
          navigation_icon: '',
          navigation_data: '0',
          navigation_template: 'back',
          action: null,
          animation: 'false',
        },
      },

      {
        template: 'block-section-row',
        fields: {
          primary_heading: strings.CREATE_ACCOUNT_1_HEADER_2(),
          class: ['align-left'],
          column_class: ['justify-content-start'],
        },
      },
      {
        template: 'block-section-row',
        columns: [primaryText(strings.CREATE_ACCOUNT_1_MSG(), false, false, true, true)],
      },
      {
        template: 'block-section-row',
        columns: [
          {
            template: 'block-telephone',
            fields: {
              id: 'sign-in-number',
              placeholder: strings.CREATE_ACCOUNT_1_PLACEHOLDER(),
              action: '',
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          class: ['grow'],
        },
        columns: [
          {
            template: 'block-icn-button',
            fields: {
              icon: 'check-solid',
              class: ['icn-button-lrg'],
              navigation_data: '2',
              navigation_template: 'verifyPhoneNumber_loading',
              navigation_action: 'phoneNumberSignIn',
              navigation_validation: 'validatePhoneNumberSignIn',
            },
          },
        ],
      },

      helpTextRow(strings.CREATE_ACCOUNT_1_MSG_4(), false, false, true, true),
    ],
  };
};

// =================================================================================================
// Event handlers

function onKeypressInSignInTelephoneField(e) {
  if (e.key !== 'Enter') return;
  $('[data-actionclick=phoneNumberSignIn]').click();
}

$('body').on('keypress', '#verify-device-1 #sign-in-number', onKeypressInSignInTelephoneField);
// setTimeout(_ => {
//   if ($('#ign-in-number').is(':visible')) {
//     $('#sign-in-number').focus();
//   }
// }, 1000);
