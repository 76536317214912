const showToastMessage = require('../../action_helpers/showToastMessage');
const createLogin = require('../../action_helpers/createLogin');
const parseUrl = require('../../action_helpers/parseUrl');
const strings = require('../../../config').strings;

function getSubdomain(hostname) {
  var regexParse = new RegExp('[a-z-0-9]{2,63}.[a-z.]{2,5}$');
  var urlParts = regexParse.exec(hostname);
  return hostname.replace(urlParts[0], '').slice(0, -1);
}

//==================================================================================================

module.exports = function extensionAddLogin({ z2hApp }) {
  // Get service details
  //  - name: comes from current tab URL
  //  - website: comes from current tab URL
  //  - username: default username from user preferences, if exists
  //  - fields: just one, using default password generation settings
  // On success, go to extension 'new service created' page
  // In unlikely event of a failure go to a 'Sorry!' page

  const currentTabUrl = window.state.currentTabUrl;
  const parsedUrl = parseUrl(currentTabUrl);
  const groupId = window.state.selectedGroupId;
  const website = parsedUrl.hostname;
  const username = localStorage.getItem('default-username') || '';
  const subdomain = getSubdomain(parsedUrl.hostname);

  // If website hostname = www.britishairways.com, we want to use
  // britishairways.com as the service name, so remove the subdomain.
  const name = subdomain.length > 0 ? parsedUrl.hostname.substr(subdomain.length + 1) : parsedUrl.hostname;

  const service = { name, website, username };

  const defaultGenerationSettings =
    window.state.defaultGenerationSettings || window.state.userData.default_generation || {};

  const fields = () => [
    {
      name: strings.FIELD_TEMPLATE_PASSWORD(),
      generation_settings: defaultGenerationSettings,
    },
  ];

  return createLogin(
    service,
    fields(),
    groupId,
    window.state.temporaryServiceIcon,
    // Success callback:
    serviceDetails => {
      window.state.currentService = serviceDetails;
      window.state.newlyAddedServiceDetailsToDisplay = serviceDetails;
      z2hApp.paneNavigation('viewService_extension', $('.overlay'));
    },
    // Failure callback:
    err => {
      showToastMessage(strings.MSG_FALLBACK_ERROR_MESSAGE());
      z2hApp.paneNavigation('back', $('.overlay'));
    },
  );
};
