module.exports = ({ z2hApp }) => {
  window.state.userData.activeOnboarding = true;
  window.state.userData.reonboarding = true;
  $('.overlay').fadeIn();
  setTimeout(() => {

    window.z2hApp.paneNavigation('onboarding_stage1', $('.overlay'), 0);

  }, 10);
};
