const config = require('../../../config');
const strings = config.strings;

/// TODO: This is a copy-and-tweak version of addNewService2_doodlepad so shares a lot of the same
/// code which can be DRYed up.

module.exports = ({ nav }) => {
  const user = window.state.userData;
  const title = z2hApp.pageData.service.serviceData.name;

  const groupId = window.state.selectedGroupId;
  const group = window.state.groupsList.find(g => g.id === groupId);
  const avatar = group.personal
    ? user.avatar || config.app.DEFAULT_USER_ICON
    : group.icon || config.app.DEFAULT_GROUP_ICON;

  return {
    template: 'block-section-page',
    fields: {
      id: 'create-account-first-login-doodle-pad',
      active: false,
      header: title,
      navbar: false,

      help_id: 'HELP_DOODLEPAD',

      navigation_left: strings.BACK(),
      navigation_left_icon: '',
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'back',
    },
    rows: [
      {
        template: 'block-section-row',
        fields: {
          primary_heading: config.strings.SERVICE_DOODLEPAD_SUBHEADING(),
          class: 'no-border',
          class2: 'centered-text',
        },
        columns: [
          {
            template: 'block-info-text',
            fields: { text: strings.CREATE_ACCOUNT_NEW_LOGIN_DOODLEPAD_MSG() },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          class: 'no-border',
        },
        columns: [{ template: 'block-doodlepad' }],
      },
      {
        template: 'block-doodlepad-buttons',
        fields: {
          confirm_data: String(nav + 1),
          confirm_template: 'addNewService3_connecting',
          confirm_validation: 'validateDoodlepad',
          confirm_action: 'createAccount_addNewService',
        },
      },
    ],
  };
};
