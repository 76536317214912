const openUrl = require('../../action_helpers/openUrl');
module.exports = ({ currentTarget, z2hApp }) => {
  $('.dialogMgr_root').click();

  const url = $(currentTarget).attr('data-actiondetail');
  if (url) {
    z2hApp
      .requestData('post', '', 'users/@me', {
        step_shared_on_social_media: true,
      })
      .then(_ => {
        this.initUpdatesPage();
      });
  }
  openUrl(url);
};
