module.exports = ({ z2hApp }) => {
  window.z2hApp.pageData = {};
  window.z2hApp.pageData.service = {};

  window.z2hApp.pageData.postUpdateAction = null;
  window.z2hApp.pageData.service.serviceData = {};
  window.z2hApp.pageData.service.fieldsArray = [];
  window.z2hApp.pageData.service.fieldsSaved = {};
  window.z2hApp.pageData.service.fieldsUnsaved = {};

  window.z2hApp.pageData.service = window.state.currentService;

  // if (window.state.currentService.last_used === 0) {
  //   return z2hApp.paneNavigation('addUsernameToExistingService', $('#pane-2'), 3);
  // }

  z2hApp.paneNavigation('viewService', $('#pane-2'), 3);
};
