const $ = require('jquery');

const INITIAL_DELAY_MS = 1000;

module.exports = function goToServiceCodes_extension({}) {
  // Check after one second to allow page to appear before possibly navigating
  // immediately to the service details.
  const goToViewService = _ => {
    const paneWrapper = $('.section-pane-wrapper').parent();

    // Loading z2hApp as a dependency at the top meant it was undefined at this point, because it
    // did not yet exist as window.z2hApp
    const z2hApp = window.z2hApp;

    z2hApp.paneNavigation('viewService_extension', paneWrapper, paneWrapper.find('.section-pane.active').index() + 1);
  };
  setTimeout(goToViewService, INITIAL_DELAY_MS);
};
