const lochys = {
  waving: 'img/lochy/01-Lochy-Waving',
  crying: 'img/lochy/02-Lochy-Crying',
  shield: 'img/lochy/03-Lochy-Shield',
  unlocking: 'img/lochy/04-Lochy-Unlocking',
  doctor: 'img/lochy/05-Lochy-Doctor',
  mechanic: 'img/lochy/06-Lochy-Mechanic',
  excited: 'img/lochy/07-Lochy-Excited',
  cool: 'img/lochy/08-Lochy-Cool',
  weights: 'img/lochy/09-Lochy-Weights',
  trophy: 'img/lochy/10-Lochy-Trophy',
  confetti: 'img/lochy/11-Lochy-Confetti',
  dancing: 'img/lochy/12-Lochy-Dancing',
  alert: 'img/lochy/13-Lochy-Alert',
  safe: 'img/badges/active/11',
};



module.exports = ({ id, heading, lochy, bearWithUs }, additionalRow) => {
  window.loading = null;
  if (bearWithUs) {
    let current = 0;
    window.loading = setInterval(() => {

      $('#vault_loading h1').text(bearWithUs.lochys[current].updateText);
      $('#vault_loading  img').fadeOut()
      setTimeout(() => {

        $('#vault_loading img').attr('src', `${lochys[bearWithUs.lochys[current].updateLochy]}.gif`).fadeIn()
      }, 1000)
      current = current + 1;
      if (current === bearWithUs.lochys.length) {
        current = 0;
      }
    }, bearWithUs.interval);
  }
  return {
    template:
      window.state && window.state.userData && window.state.userData.activeOnboarding
        ? 'block-section-onboarding'
        : 'block-section-page',
    fields: {
      id: id,
      active: false,
      class: 'no-title',
      //navbar: false,
      navbar: $('#pane-1').hasClass('active') ? { desktop: false, mobile: true } : false,
    },
    rows: [
      {
        template: 'block-section-row',
        fields: {
          primary_heading_html: heading,
          class: 'no-border',
        },
      },
      {
        template:
          window.state && window.state.userData && window.state.userData.activeOnboarding
            ? 'block-onboarding-image'
            : 'block-lochy',
        fields: {
          imagePath: `${lochys[lochy]}.gif`,
          class: 'no-border',
          size: 'small',
        },
        columns: [],
      },
      additionalRow && additionalRow,
    ],
  };
};
