// =================================================================================================

function get(name) {
  const instance = _getInstance();
  if (!instance) return Promise.resolve(); // Share Preferences not available. Ignore
  return new Promise((resolve, reject) => {
    instance.get(name, resolve, reject);
  });
}

// =================================================================================================

function set(name, value) {
  const instance = _getInstance();
  if (!instance) return Promise.resolve(); // Share Preferences not available. Ignore
  return new Promise((resolve, reject) => {
    instance.put(name, value, resolve, reject);
  });
}

// =================================================================================================
// Internal helpers

function _getInstance() {
  if (!process.env.CORDOVA) return null;
  try {
    return window.plugins.SharedPreferences.getInstance();
  } catch (err) {
    return null;
  }
}

module.exports = {
  get,
  set,
};
