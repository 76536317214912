import { active } from 'domain';

const showToastMessage = require('../../action_helpers/showToastMessage');
const strings = require('../../../config').strings;

module.exports = ({ currentTarget, z2hApp }) => {
  // Get doodle that user drew
  let doodle = (window.currentDoodlepad.getShape() || {}).shape;

  let error = false;

  // Check that code is not empty
  if (!doodle) {
    showToastMessage(strings.ERROR_NO_DOODLE());
    error = true;
  }

  if (window.state.doodlepadToConfirm !== doodle) {
    showToastMessage(strings.ADD_SERVICE_DODODLES_DO_NOT_MATCH(), { html: true, timeout: 3000, center: true });
    error = true;
  }


  const paneDetails = window.getActivePaneDetails('.overlay');
  if (error) {
    setTimeout(() => {
      return z2hApp.paneNavigation('back', paneDetails.pane, paneDetails.activePaneIndex - 1);
    }, 1000);
  } else {
    if (!window.ss) {
      z2hApp.paneNavigation('addNewService_selectPresets', paneDetails.pane, paneDetails.activePaneIndex + 1);
    } else {
      z2hApp.paneNavigation('onboarding_keychain', paneDetails.pane, paneDetails.activePaneIndex + 1);
    }
  }
};
