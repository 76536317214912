const errorManager = require('../utils/errorManager');
const showToastMessage = require('../action_helpers/showToastMessage');
const { USERNAME_NAME_MAXLENGTH } = require('../../config/app')
const z2hApp = window.z2hApp;

const config = require('../../config');
const strings = config.strings;

module.exports = (section) => {
  errorManager.clearErrors(section)
  let error = false
  const usernameField = $('#add-service-username-input');

  //const username = section.find('[name=new-group-name-field]')
  const username = usernameField.val()
  // if (username.length === 0) {
  //   error = true
  //   //showToastMessage('Username or email cannot be blank');
  //   errorManager.reportError(strings.ADD_SERVICE_USERNAME_BLANK, usernameField)
  // }
  if (username.length > USERNAME_NAME_MAXLENGTH) {
    error = true;
    errorManager.reportError(strings.ADD_SERVICE_USERNAME_TOO_LONG(), usernameField)
  }
  if (error) {
    throw new Error("Validation error")
  }

}