/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */

const showResponseErrorMessage = require('../../action_helpers/showResponseErrorMessage');
const getFullServiceDetails = require('../../getPageData_helpers/getFullServiceDetails');
const fetchBase64Image = require('../../action_helpers/fetchBase64Image');
const hidePane3 = require('../../actions/miscellaneous/hidePane3');
const hideLogin = require('../createAccount/hideLogin');
const config = require('../../../config');

const { strings } = config;

const INITIAL_DELAY_MS = 200;
const RETRY_IN_MS = 300;

const showToastMessage = require('../../action_helpers/showToastMessage');
const { makePaneLookBusy, makePaneLookIdle } = require('../../app/overlays');

// References to callbacks which will be called on success/failure
let _onSuccess;
let _onFailure;
let groupId;

// =================================================================================================
// This action will get all of the data for the new service from z2hApp.pageData.service.
// It will set a global newServiceStatus variable to pending. It will then proceed to create the new
// service (new-service API) and then start an "asynchronous loop" (a sort of recursive asynchronous
// function) to add all of the fields (forghettibles) to the new service.
// The code will check (on an interval) for the newServiceStatus flag to be set to "success" or
// "failed". It will then call the appropriate callback.
// =================================================================================================

let serviceBatch = [];
const addToBatch = (service, clear = false) => {
  if (clear) serviceBatch.length = 0;

  //serviceBatch.push(service);
  serviceBatch = service;
}

const processBatch = async ({ z2hApp, onSuccess, onFailure, importing = true }) => {

  const prom = Promise.resolve();

  if (importing) {
    const importServices = window.state.groupsList.find(g => g.name === "Import services");
    groupId = importServices ? importServices.id : null;
    if (!importServices) {
      //If we don't have an import services group then we need to create it.
      prom.then(_ => {
        z2hApp.requestData('post', '', 'groups/new', { name: 'Import services', maximum_members: 1, mfa_level: 0 })
      }).then(response => {
        groupId = response.data.id;
      })
    }
  }


  prom.then(_ => {


    //Add this stuff later!!
    // const { z2hTools } = window;
    // const fieldsArray = [];

    // //Generate a password field.
    // const newFieldId = z2hTools.getUid();
    // fieldsArray.push(newFieldId);
    // const defaultGenerationSettings = window.state.defaultGenerationSettings || {};
    // fieldsArray[newFieldId] = {
    //   name: strings.FIELD_TEMPLATE_PASSWORD,
    //   generation_settings: {
    //     exclude: '',
    //     length: defaultGenerationSettings.length || 16,
    //     numbers: true,
    //     symbols: false,
    //     simple_symbols: true,
    //     //symbols: true,
    //     lowercase: true,
    //     uppercase: true,
    //     words: 0,
    //     version: 1,
    //   },
    // };

    // Call API to add new service
    const endpoint = `groups/${groupId}/new-service-batch`;
    const batchArray = serviceBatch.map((s) => ({
      description: s.serviceData.name,
      group_id: groupId,
      icon: s.serviceData.icon,
      name: s.serviceData.name,
      username: s.serviceData.username,
      username_secondary: s.serviceData.username_secondary,
      website: s.serviceData.website,
      fields: [],
    }));

    const data = JSON.stringify({ services: batchArray });

    $('#addservicePasswordLoading_text').find('.secondary-text-content').text(strings.ADD_SERVICE_LOADING_GENERATING_SERVICE());


    return z2hApp.requestData('post', '', endpoint, data);
  }).then(response => {
    if (Math.floor(response.status / 100) !== 2) throw response;

    showToastMessage('Services are imported');

  })
};

module.exports = {
  addToBatch,
  processBatch
}
