const config = require('../../../config');
const strings = config.strings;
const { infoTextRow } = require('../../page_helpers/components');

module.exports = ({ data, nav }) => {
  const groupsList = window.state.groupsList || [];

  const personalGroup = groupsList.find(g => g.personal);

  // Create an array of group rows, which will exclude the personal group
  let otherGroups = groupsList.filter(g => !g.personal);

  /***
  Currently, the extension is only available to premium users, so
  we can skip the following section of code which is dedicated to
  filtering out groups that the user is not allowed to use.
  ***/

  const premium = true;

  /***
  const userId = window.state.userData.id;
  const premium = 
    window.state.launchData && 
    window.state.launchData.home && 
    window.state.launchData.home.premium;

  if (!p	remium) {
    // Filter out groups that user is an owner of
    otherGroups = otherGroups.filter(g => {
      return (g.users[userId] || {}).member_type !== 2;
    });
    // And you can only have one group (that you have joined)
    otherGroups = otherGroups.slice(0, 1);
  }
  ***/

  // Sort these groups by name (asending) and create "block-section-row"s for each of them
  otherGroups.sort((a, b) => (a.name > b.name ? 1 : -1));
  const groupRows = otherGroups.map(g => groupRow(g, nav));
  const groupCount = groupRows.length;

  const page = {
    template: 'block-section-page',
    fields: {
      id: 'groups-list',
      active: false,
      header: strings.GROUPS_LIST_HEADER(),
      help_id: 'HELP_GROUPS_LIST_HEADER',
      navigation_left: strings.BACK(),
      navigation_left_data: nav - 1,
      navigation_left_template: 'back',
    },
    rows: [infoTextRow(strings.GROUPS_LIST_INFO_MESSAGE()), groupRow(personalGroup, nav), ...groupRows],
  };

  if (groupCount === 0 && !premium) {
    page.rows.push(infoTextRow(strings.UPGRADE_TODAY_MESSAGE(), true));
    page.rows.push({
      template: 'block-section-row',
      columns: [
        {
          template: 'block-text-button',
          fields: {
            class: 'cta-button',
            text: strings.UPGRADE_TODAY_BUTTON(),
            navigation_template: 'openUpgradeTodayUrl',
          },
        },
      ],
    });
  }

  return page;
};

// Helpers =========================================================================================

// For each group, we want to display a row like this...
function groupRow(group, nav) {
  const icon = group.personal
    ? window.state.userData.avatar || config.app.DEFAULT_USER_ICON
    : group.icon || config.app.DEFAULT_GROUP_ICON;

  // Get a comma-separated list of users in this group, starting with yourself.
  let userList = config.strings.YOU();
  for (let u in group.users) {
    if (u !== window.state.userData.id) {
      // If not yourself...
      userList += ', ' + group.users[u].name;
    }
  }

  return {
    template: 'block-section-row',
    fields: {
      navigation_data: nav - 1,
      navigation_template: 'back',
      navigation_data_id: group.id,
      action: 'extensionChooseGroup',
    },
    columns: [
      {
        template: 'block-login-avatar',
        fields: { avatar: icon, data_id: group.id },
      },
      {
        template: 'block-text-group',
        fields: { primary_text: group.name, secondary_text: userList },
      },
      {
        template: 'block-icn-button',
        fields: { icon: 'chevron-right' },
      },
    ],
  };
}
