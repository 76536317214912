/* eslint-disable camelcase */
const config = require('../../../config');

const { thirdheading } = require('../../page_helpers/components');

const strings = require('../../../config').strings;

module.exports = ({
  id,
  nav,
  showSkip,
  imagePath,
  lochyImagePath,
  secondary_heading,
  primary_heading,
  text,
  nextPage,
  nextAction,
  nextLink = strings.NEXT(),
  callToAction = false,
  close = false,
}) => {

  const animations = window.state.animations || {};
  const settings = {
    path: imagePath,
  }
  animations[id] = { ...settings };

  window.state.animations = animations;
  const reonboarding = window.state.userData.reonboarding;


  return {
    template: 'block-section-page',

    fields: {
      id: id || 'onboarding-welcome',
      class: 'onboarding',
      navbar: false,

      navigation_right: reonboarding && strings.CLOSE(),
      navigation_right_action: reonboarding && 'hideLogin',
    },

    rows: [
      close && {
        template: 'block-onboarding-close'
      },
      imagePath && {
        template: 'block-animation',
        fields: { id: id },
      },
      thirdheading({ text: secondary_heading || '' }),
      {
        template: 'block-section-row',
        fields: {
          class: 'no-border',
          primary_heading,
        },
        columns: [
          {
            template: 'block-info-text',
            fields: { text },
          },
        ],
      },
      lochyImagePath && {
        template: 'block-onboarding-image',
        fields: { imagePath: lochyImagePath },
      },
      nextLink && {
        template: 'block-section-row',
        columns: [
          {
            template: 'block-text-button',
            fields: {
              class: callToAction ? 'cta-button' : '',
              text: nextLink,
              ...(nextPage && { navigation_data: nav + 1, navigation_template: nextPage }),
              ...(nextAction && { navigation_action: nextAction }),
            },
          },
        ],
      },
    ],
  }
}
