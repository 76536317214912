const errorManager = require('../utils/errorManager');
const removeQueryStringParameter = require('../utils/removeQueryStringParameter');
const z2hApp = window.z2hApp;

module.exports = section => {
  errorManager.clearErrors(section);

  let error = false;
  const field = $(section).find('[name=referral-code]');
  const value = field.val().trim();

  if (value) {
    return z2hApp.requestData('post', '', 'users/@me/apply-referral', { referral_id: value }).then(response => {
      if (Math.floor(response.status / 100) !== 2) {
        const errMsg =
          response.user_message ||
          response.developer_message ||
          response.message ||
          `An error occurred! Sorry about that.`;
        errorManager.reportError(errMsg, field);
        throw new Error(errMsg);
      }
      removeQueryStringParameter('referral');
    });
  }

  if (error) {
    throw new Error('Validation error');
  }
};
