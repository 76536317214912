//prettier-ignore
module.exports = [
  {
    category: 'Family',
    filename: 'img/forghetti-ui_group-icon-family-1_normal_60.svg',
  },
  {
    category: 'Family',
    filename: 'img/forghetti-ui_group-icon-family-2_normal_60.svg',
  },
  {
    category: 'Finance',
    filename: 'img/forghetti-ui_group-icon-finance-1_normal_60.svg',
  },
  {
    category: 'Finance',
    filename: 'img/forghetti-ui_group-icon-finance-2_normal_60.svg',
  },
  {
    category: 'Home',
    filename: 'img/forghetti-ui_group-icon-home-1_normal_60.svg',
  },
  {
    category: 'Legal',
    filename: 'img/forghetti-ui_group-icon-legal-1_normal_60.svg',
  },
  {
    category: 'Priority',
    filename: 'img/forghetti-ui_group-icon-priority-1_normal_60.svg',
  },
  {
    category: 'Priority',
    filename: 'img/forghetti-ui_group-icon-priority-2_normal_60.svg',
  },
  {
    category: 'Priority',
    filename: 'img/forghetti-ui_group-icon-priority-3_normal_60.svg',
  },
  {
    category: 'Shopping',
    filename: 'img/forghetti-ui_group-icon-shopping-1_normal_60.svg',
  },
  {
    category: 'Social',
    filename: 'img/forghetti-ui_group-icon-social-1_normal_60.svg',
  },
  {
    category: 'Sport',
    filename: 'img/forghetti-ui_group-icon-sport-1_normal_60.svg',
  },
  {
    category: 'Sport',
    filename: 'img/forghetti-ui_group-icon-sport-2_normal_60.svg',
  },
  {
    category: 'Technology',
    filename: 'img/forghetti-ui_group-icon-technology-1_normal_60.svg',
  },
  {
    category: 'Technology',
    filename: 'img/forghetti-ui_group-icon-technology-2_normal_60.svg',
  },
  {
    category: 'Travel',
    filename: 'img/forghetti-ui_group-icon-travel-1_normal_60.svg',
  },
  {
    category: 'Travel',
    filename: 'img/forghetti-ui_group-icon-travel-2_normal_60.svg',
  },
  {
    category: 'Travel',
    filename: 'img/forghetti-ui_group-icon-travel-3_normal_60.svg',
  },
  {
    category: 'Work',
    filename: 'img/forghetti-ui_group-icon-work-1_normal_60.svg',
  },
  {
    category: 'Work',
    filename: 'img/forghetti-ui_group-icon-work-2_normal_60.svg',
  },
  {
    category: 'Work',
    filename: 'img/forghetti-ui_group-icon-work-3_normal_60.svg',
  },
  {
    category: 'Work',
    filename: 'img/forghetti-ui_group-icon-work-finance-1_normal_60.svg',
  },
  {
    category: 'Work',
    filename: 'img/forghetti-ui_group-icon-work-industry-1_normal_60.svg',
  },
];
