const _ = require('lodash');
module.exports = ({ z2hApp, menuTarget }) => {
  z2hApp.pageData = {};
  z2hApp.pageData.service = {};
  z2hApp.pageData.postUpdateAction = null;
  z2hApp.pageData.service.serviceData = {};
  z2hApp.pageData.service.fieldsArray = [];
  z2hApp.pageData.service.fieldsSaved = {};
  z2hApp.pageData.service.fieldsUnsaved = {};
  // Get ID of selected service
  const serviceId = $(menuTarget).closest('[data-id]').attr('data-id');
  window.state.selectedServiceId = serviceId;
  window.state.currentService = window.state.currentServices.find((s) => s.id === serviceId);
  if (!window.state.currentService) {
    // Check if service exists in another group
    const groupsList = window.state.groupsList;
    for (let i = 0; i < groupsList.length; i++) {
      const groupId = groupsList[i].id;
      const services = groupsList[i].services || [];
      if (services.find && services.find((s) => s.id === serviceId)) {
        window.state.currentService = services.find((s) => s.id === serviceId);
        window.state.selectedGroupId = groupId;
        window.state.currentServices = services;

        if (window.z2hApp.highlightNavElement)
          window.z2hApp.highlightNavElement($('#main-nav').find('[data-id=' + groupId + ']'));

        break;
      }
    }
  }

  window.state.movingService = {};
  window.state.movingService.from = {};

  const services = window.state.currentServices;
  const service = _.cloneDeep(services.find((s) => s.id === serviceId));
  window.state.movingService.from.service = _.cloneDeep(service);

  const groups = window.state.groupsList;
  for (const g of groups) {
    const groupForService = g.services.find((group) => group.id === serviceId);
    if (groupForService) {
      window.state.selectedGroupId = g.id;
      break;
    }
  }

  window.state.movingService.from.group = window.state.selectedGroupId;

  //We need to get rid of the newly added service stuff in case we've left it
  //hanging around.
  if (window.state.newlyAddedServiceDetailsToDisplay) delete window.state.newlyAddedServiceDetailsToDisplay;

  z2hApp.pageData = z2hApp.pageData || {};
  z2hApp.pageData.service = {
    groupId: '',
    serviceData: {
      name: service.name,
      username: service.username,
      website: service.website,
      icon: service.icon,
    },
    serviceUnsaved: false,
    fieldsArray: [],
    fieldsSaved: {},
    fieldsUnsaved: {},
    movingService: true,
    currentServiceId: serviceId,
    currentGroupId: window.state.selectedGroupId,
  };

  z2hApp.paneNavigation('addService_group', $('#pane-2'), 1);
};
