const showResponseErrorMessage = require('../../action_helpers/showResponseErrorMessage');
const getAccessKeys = require('../../getPageData_helpers/getAccessKeys');
const overlays = require('../../app/overlays');
const strings = require('../../../config/strings');
const z2hApp = require('../../app/z2hApp');
const {
  infoTextRow,
  serviceField,
  unavailableServiceField,
  upgradeTodayButton,
  loadingServiceField,
} = require('../../page_helpers/components');
const _ = require('lodash');
const getNotes = require('../../getPageData_helpers/getNotes');

module.exports = async () => {

  // We don't want pane-2 looking 'busy' (i.e. having an overlay) when loading
  // the service details as we'll be displaying a "Connecting" screen instead.
  overlays.makePaneLookIdle(2);

  // Get basic service details from our 'currentServices' list
  const serviceId = window.state.selectedServiceId;
  //const services = window.state.currentServices;
  let service = window.state.currentService;

  //Take this opportunity to clear the copied to clipboard service id
  if (window.state.copiedToClipboard) delete window.state.copiedToClipboard;

  // Check for a background request which had been made to get the fields for this service
  // (When we go to the doodlepad screen, we fire off a premature fetch to get the list of fields
  // for a service to save time)
  window.serviceFieldPromises = window.serviceFieldPromises || {};
  window.serviceFieldPromises[serviceId] =
    window.serviceFieldPromises[serviceId] || z2hApp.requestData(`get`, ``, `services/${serviceId}/fields`);
  $('#viewService_loading_text')
    .find('.secondary-text-content')
    .text(strings.VIEW_SERVICE_LOADING_LOADING_SERVICE_FORGHETTIBLE());

  // Get doodle that user drew
  let doodle;
  let doodleProm;
  if (!window.state.biometrics) {
    doodleProm = new Promise((resolve, reject) => {
      const shape = window.currentDoodlepad.getShape().shape;
      return resolve(shape);
    });
  } else {
    doodleProm = new Promise((resolve, reject) => {
      if (process.env.CORDOVA) {
        if (window.ss) {
          window.ss.get(
            (value) => {
              console.log('Stored doodle', value);
              return resolve(value);
            },
            () => {
              console.log('Failed to store doodle');
              return resolve(null);
            },
            window.state.selectedGroupId + '_doodle',
          );
        }
      } else {
        return resolve(null);
      }
    });

    //doodle = localStorage.getItem(`${window.state.selectedGroupId}_doodle`);
  }
  doodle = await doodleProm;

  //clear the search parameters
  $('#login-list [data-actionclick="showServices"]').click()

  window.serviceHasNotePromise = window.serviceHasNotePromise || {};
  window.serviceHasNotePromise[serviceId] =
    window.serviceHasNotePromise[serviceId] || z2hApp.requestData(`get`, ``, `services/${serviceId}/has-note`);

  return window.serviceFieldPromises[serviceId]
    .then((response) => {
      service = _.cloneDeep(window.state.currentService);
      const fields = [];
      for (let fieldId in response.data) {
        fields.push({ ...response.data[fieldId], id: fieldId });
      }
      
      service.fields = response.data;
      service.fieldsArr = fields;
      service.fieldsSaved = response.data || {};

      service.fieldsArray = fields.map((f) => f.id);
      service.origFieldsArray = fields.map((f) => f.id);
      service.fieldsUnsaved = {};
      service.doodle = doodle;


      service.hasNote = window.serviceHasNotePromise[serviceId];

      service.id = serviceId;
      service.newlyAddedService = window.state.newlyAddedServiceDetailsToDisplay ? true : false;

      window.state.newlyAddedServiceDetailsToDisplay = null;
      window.state.currentService = service;
      if (window.state.currentService.last_used === 0) {
        window.state.currentService.last_used = Date.now();
      } 
      return service;
    })
    .catch((err) => {
      showResponseErrorMessage(err);
      z2hApp.paneNavigation('back', $('#pane-2'));
    });
};
