const openUrl = require('../../action_helpers/openUrl')
const showToastMessage = require('../../action_helpers/showToastMessage')
const { VIEWSERVICE_NOT_COPIED_TO_CLIPBOARD } = require('../../../config').strings;
module.exports = ({ currentTarget }) => {
  const serviceId = $(currentTarget).attr('data-id');
  if (!window.state.copiedToClipboard || window.state.copiedToClipboard !== serviceId) {
    showToastMessage(VIEWSERVICE_NOT_COPIED_TO_CLIPBOARD(), { timeout: 7500, html: true, center: true });
    window.state.copiedToClipboard = serviceId;

  } else {
    window.state.userData.resetPasswordSelected = true;
    openUrl(window.state.currentWebsite);
  }

}