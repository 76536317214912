const { strings, urls } = require('../../../config');
const { infoTextRow } = require('../../page_helpers/components');

module.exports = ({ nav }) => {
  window.z2hApp.pageData.healthcheck.password = {};
  setTimeout(_ => $('#healthcheck_password_1').focus(), 1000);

  return {
    template: 'block-section-page',
    fields: {
      id: 'healthcheck_password',
      active: false,
      navbar: false,
      navigation_left: strings.BACK(),
      navigation_left_data: nav - 1,
      navigation_left_template: 'back',
      header: strings.HEALTHCHECK_PASSWORD_HEADER(),
    },
    rows: [
      {
        template: 'block-section-row',
        fields: {
          secondary_heading_html: strings.HEALTHCHECK_PASSWORD_INFO_1(),
          class: 'third-heading',
        },
      },
      infoTextRow(strings.HEALTHCHECK_PASSWORD_INFO_2(), false, false),
      infoTextRow(strings.HEALTHCHECK_PASSWORD_INFO_3(), false, false),

      {
        template: 'block-section-row',
        fields: {},
        columns: [
          {
            template: 'block-input-box',
            fields: {
              label: strings.HEALTHCHECK_PASSWORD_SUBHEAD(),
              id: 'healthcheck_password_1',
              placeholder: strings.HEALTHCHECK_PASSWORD_PLACEHOLDER(),
              action: 'healthcheck_password_onchange',
              type: 'password',
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        columns: [
          {
            template: 'block-icn-button',
            fields: {
              icon: 'right-solid',
              class: 'icn-button-lrg',
              action: 'healthcheck_password',
              navigation_template: 'healthcheck_loading',
              navigation_data: nav + 1,
            },
          },
        ],
      },
    ],
  };
};

$('body').on('keyup', '#healthcheck_password_1', e => {
  if (e.key === 'Enter') {
    $('[data-actionclick="healthcheck_password"]').click();
    return;
  }
});
