const copyToClipboard = require('copy-html-to-clipboard');

const showToastMessage = require('../../action_helpers/showToastMessage');
const config = require('../../../config');

module.exports = ({ currentTarget }) => {
  const referralCode = window.state.launchData.home.referral_id;
  const profileName = window.state.userData.name;

  if (referralCode) {
    const referralUrl = config.urls.REFERRAL_LINK(referralCode, profileName || window.state.userData.id);
    const message = config.strings.REFERRAL_COPY_MESSAGE()(referralUrl);

    if (window.cordova && window.plugins.socialsharing) {
      window.plugins.socialsharing.shareWithOptions({ message, url: referralUrl});
      return;
    }
    else if (navigator.share) {
      navigator.share({ text: message });
    } else {
      copyToClipboard(referralUrl);
      showToastMessage(`Copied referral link to clipboard.`);
    }
  }
};
