const { strings } = require('../../../config');
const lochyLoadingTemplate = require('./lochyLoadingTemplate');

module.exports = () => ({
  ...lochyLoadingTemplate({
    id: 'viewService_loading',
    heading: strings.LOADING_SERVICE(),
    lochy: 'safe',
  },
    {
      template: 'block-section-row',
      fields: {
        id: 'viewService_loading_text',
        class: 'centered-text',

      },
      columns: [{
        template: 'block-text-group',
        fields: {
          secondary_text: strings.VIEW_SERVICE_LOADING_LOADING_SERVICE_DETAIL(),
        }
      }

      ]
    }
  ),
});
