const { primaryText } = require('../../page_helpers/components');
const strings = require('../../../config/strings');
module.exports = ({ nav }) => {
  return {
    template: 'block-section-onboarding',
    fields: {
      id: 'legacy-bonus',
      navbar: false,
    },
    rows: [
      {
        template: 'block-onboarding-image',
        fields: {
          imagePath: `img/badges/active/badge19.gif`,
          class: 'no-boader',
          size: 'small',
        },
      },
      {
        template: 'block-section-row',
        fields: {
          class: 'no-border',
          class2: 'centered-text',
          class3: 'onboarding-text',
          primary_heading_html: strings.LEGACY_USER_ONBOARDING_HEADING(),
        },
        columns: [
          {
            template: 'block-info-text',

            fields: { text: strings.LEGACY_USER_ONBOARDING_INFO() },
          },
        ],
      },
      {
        template: 'block-cta-button',
        fields: {
          text: strings.CLAIM_PREMIUM(),
          action: 'reloadWithReward',
        },
      },
    ],
  };
};
