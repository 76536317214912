
module.exports = ({ element, section, z2hApp }) => {
  const usernames = section.find('.text-input');
  const services = window.state.selectedImportedServices;

  usernames.each(function (i) {
    const id = $(this).attr('id');
    const val = $(this).val();
    const index = services.findIndex(elem => elem.id === id);
    window.state.selectedImportedServices[index].username = val;
  })
}