/**
 * Updates the source of any image currently on the screen which is associated to a particular
 * service ID. This will either be:
 *  * An image that has an ID that matches the service ID
 *  * An image with a data-id attribute that matches the service ID
 *  * An image within a container/div with a data-id attribute that matches the service ID
 */
const $ = require('jquery');

function updateOnScreenServiceImageSource(serviceId, imageSrc) {
  $(`img[data-id=${serviceId}], [data-id=${serviceId}] img, img[id=${serviceId}]`).attr('src', imageSrc);
}

module.exports = updateOnScreenServiceImageSource;
