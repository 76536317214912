const config = require('../../../config');
const fb = require('../../app/firebaseUtils');

const strings = config.strings;

module.exports = ({ nav, data }) => {
  // Set focus on the phone number entry
  setTimeout((_) => $('#migration-number').focus(), 1000);

  return {
    template: 'block-section-page',
    fields: {
      id: 'migrate-phone-number-1',
      header: strings.PROFILE_MIGRATE_1_HEADER(),

      navigation_left: config.strings.BACK(),
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'back',
      navbar: false,
    },
    rows: [
      {
        template: 'block-section-row',
        fields: { secondary_heading: strings.PROFILE_MIGRATE_1_HEADING() },
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_text: fb.getCurrentUser().phoneNumber },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: { class: 'no-border' },
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_text: strings.PROFILE_MIGRATE_1_INFO() },
          },
        ],
      },
      {
        template: 'block-section-row',
        columns: [
          {
            template: 'block-telephone',
            fields: {
              id: 'migration-number',
              placeholder: strings.PROFILE_MIGRATE_1_PLACEHOLDER(),
            },
          },
        ],
      },
      {
        template: 'block-cta-button',
        fields: {
          class: 'no-grow',
          navigation_data: nav + 1,
          text: strings.MIGRATE(),
          action: 'enterNewNumber',
          navigation_validation: 'migratePhoneNumber1',
        },
      },
    ],
  };
};
