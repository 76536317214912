const semver = require('semver');

const { infoTextRow } = require('~/js/page_helpers/components');
const config = require('~/config');

module.exports = ({ nav, data, doneButton }) => {
  const fields = data.fields || [];
  const fieldsToDisplay = fields.map(f => fieldRow(f.name, f.access_key, f.id));

  return {
    template: 'block-section-page',
    fields: {
      id: 'view-service',
      active: false,
      header: config.strings.SERVICE_VIEW_HEADER(),
      help_id: 'HELP_SERVICE_SUMMARY',
      navigation_left: config.strings.PROFILE_LINK_LOGOUT(),
      navigation_left_action: 'logout',
    },
    rows: [
      infoTextRow(config.strings.SERVICE_SUMMARY_MESSAGE(), true),
      data.username && usernameRow(data.username),
      data.website && websiteRow(data.website),
      ...fieldsToDisplay,
    ],
  };
};

//==================================================================================================

function usernameRow(username) {
  const row = {
    template: 'block-section-row',
    fields: {
      secondary_heading: config.strings.SERVICE_USERNAME_LABEL(),
      action: 'useUsernameInCurrentTab',
    },
    columns: [
      {
        template: 'block-text-group',
        fields: { primary_text: username },
      },
      {
        template: 'block-icn-button',
        fields: { icon: 'copy', action: 'copyPrimaryTextToClipboard' },
      },
    ],
  };

  const extensionVersion =
    (window.location.search
      .split('&')
      .map(q => q.replace('?', '').split('='))
      .find(q => q[0] === 'v') || [])[1] || '0.0.0';

  if (semver.gte(extensionVersion, '1.0.1')) {
    row.columns.push({
      template: 'block-icn-button',
      fields: { icon: 'right', action: 'useUsernameInCurrentTab' },
    });
  }

  return row;
}

//==================================================================================================

function websiteRow(website) {
  return {
    template: 'block-section-row',
    fields: {
      secondary_heading: config.strings.SERVICE_WEBSITE_LABEL(),
      action: 'openWebsite',
    },
    columns: [
      {
        template: 'block-text-group',
        fields: { primary_text: website },
      },
      {
        template: 'block-icn-button',
        fields: { icon: 'up', action: 'openWebsite' },
      },
    ],
  };
}

//==================================================================================================

function fieldRow(name, key = '', id) {
  return {
    template: 'block-section-row',
    fields: {
      secondary_heading: name,
      id: id,
      action: 'usePasswordInCurrentTab',
    },
    columns: [
      {
        template: 'block-text-group',
        fields: {
          primary_text: key
            .split('')
            .map(c => '●')
            .join(''),
        },
      },
      {
        template: 'block-icn-button',
        fields: { icon: 'copy', action: 'copyPasswordToClipboard' },
      },
      {
        template: 'block-icn-button',
        fields: { icon: 'show', action: 'startViewPasswordTimer' },
      },
      {
        template: 'block-icn-button',
        fields: { icon: 'right', action: 'usePasswordInCurrentTab' },
      },
    ],
  };
}
