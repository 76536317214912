const LINK_EXPIRY_VALUES = {
  15: '15 Minuten',
  30: '30 Minuten',
  60: '1 Stunde',
  360: '6 Stunden',
  720: '12 Stunden',
  1440: '1 Tag',
  '-1': 'Niemals',
};

const LINK_MAX_USE_VALUES = {
  1: 'Einmal',
  2: 'Zweimal',
  5: 'Fünfmal',
  10: 'Zehnmal',
  20: 'Zwanzigmal',
  50: 'Fünfzigmal',
  '-1': 'Kein Limit',
};

module.exports = {
  YOU: 'Du',
  BACK: 'Zurück',
  NEXT: 'Weiter',
  CONTINUE: 'Fortfahren',
  SAVE: 'Speichern',
  ADD: 'Hinzufügen',
  SKIP: 'Überspringen',
  CANCEL: 'Abbrechen',
  PREVIOUS: 'Vorherige',
  EDIT: 'Bearbeiten',
  NONE: 'Kein',
  START: 'Start',
  SEARCH: 'Suchen',
  CLOSE: 'Schließen',
  SEE_DETAILS: 'Details einblenden',
  UNAVAILABLE: 'Nicht vorhanden',
  DONE: 'Fertig',
  WATCH_AGAIN: 'Nochmal anschauen',
  NEW: 'Neu',

  UNAVAILABLE_FORGHETTIBLES_MESSAGE:
    'Einige deiner forghettibles sind nicht verfügbar, da du das Limit der Gratisversion erreicht hast. Abonniere die Premiumversion für unbegrenzte forghettibles!',

  FORGHETTI_TAGLINE: 'Vergiss deine Passwörter. Für immer.',
  CREATE_ACCOUNT: 'Ein Benutzerkonto erstellen',
  HAVE_ACCOUNT: 'Ich habe bereits ein Benutzerkonto',

  COMBOBULATING: '... deine Forghettibles werden zubereitet...',

  CREATE_ACCOUNT_1_HEADER: 'Nummer eingeben',
  CREATE_ACCOUNT_1_HEADING: 'Gerät verifizieren',
  CREATE_ACCOUNT_1_MSG:
    'Bitte gib Deine Mobiltelefonnummer ein <strong>um einen einmaligen Verfizierungscode</strong> per SMS zu erhalten.',
  CREATE_ACCOUNT_1_PLACEHOLDER: 'Deine Rufnummer',
  CREATE_ACCOUNT_1_MSG_2:
    'Ändere die internationale Vorwahl falls notwendig und gib Deine Mobiltelefonnummer\nein. z.B. 07795123456.',
  CREATE_ACCOUNT_1_MSG_3: 'SMS werden nur bei Geräteverifizierung und notwendigen App Updates versendet.',

  ERROR_TEL_NUM_EMPTY: 'Mobiltelefonnummer muss angegeben werden',

  MAIN_NAV_GROUP: 'Gruppen',
  MAIN_NAV_PROFILE: 'Profil',
  MAIN_NAV_HEALTHCHECK: 'Gesundheitscheck',
  MAIN_NAV_IMPORT: 'Leistungen importieren',
  MAIN_NAV_LOGOUT: 'Ausloggen',
  NAVBAR_JOIN_GROUP: 'Einer Gruppe beitreten',
  NAVBAR_NEW_GROUP: 'Neue Gruppe',
  NAVBAR_NEW_PASSWORD: 'Neues Passwort',
  NAVBAR_NEW_PIN: 'Neue PIN',
  NAVBAR_NEW_WORD: 'Neues merkbares Wort',
  NAVBAR_HOME: 'Home',
  NAVBAR_GROUPS: 'Gruppen',
  NAVBAR_HEALTHCHECK: 'Gesundheitscheck',
  NAVBAR_PROFILE: 'Profil',

  /* Lochy loading - paneBusyText.js */
  LOCHY_LOADING_TEXT_DEFAULT: 'wird geladen...',
  LOCHY_LOADING_TEXT_GROUP: (group) => `Dienste laden für <em>${group}</em>`,
  LOCHY_LOADING_TEXT_GROUPSLIST: 'Gruppen werden geladen',
  LOCHY_LOADING_TEXT_HEALTHCHECK: 'Gesundheitscheck wird geladen',
  LOCHY_LOADING_TEXT_CHOOSE_SEARCH_ICON: 'Das Netz wird nach Dienst Icons dursucht',
  LOCHY_LOADING_TEXT_CHOOSE_CHOOSE_GROUP_ICON: 'Gruppenicon wird hochgeladen',
  LOCHY_LOADING_TEXT_CREATE_GROUP_INVITE: 'Gruppeneinladung wird erstellt',
  LOCHY_LOADING_TEXT_EDIT_SERVICE: 'Dienstdetails bearbeiten',
  LOCHY_LOADING_TEXT_UPDATE_SERVICE: 'Dienstdetails werden gespeichert',
  LOCHY_LOADING_TEXT_PROFILE: 'Profil wird geladen',
  LOCHY_LOADING_TEXT_SUBSCRIPTION: 'Abodetails werden geladen',

  /*                                 */

  CREATE_ACCOUNT_2_HEADER: 'Code eingeben',
  CREATE_ACCOUNT_2_HEADING: 'Bitte deinen Verifizierungscode eingeben',
  CREATE_ACCOUNT_2_MSG: (telNumber) => `Bitte den Verifizierungscode eingeben der an ${telNumber} gesendet wurde.`,
  CREATE_ACCOUNT_2_LINK_BACK: 'Ich habe keinen Code erhalten',
  CREATE_ACCOUNT_2_MSG_2: (termsofserviceurl) =>
    `Mit der Eingabe des Verifizierungscodes stimmst du unseren <a href="${termsofserviceurl}" target="_new">Nutzungsbedingungen</a> zu.`,

  ERROR_TEL_NUM_INVALID: 'Bitte eine gültige Mobiltelefonnummer eingeben',
  ERROR_ACT_CODE_EMPTY: 'Bitte den Aktivierungscode eingeben, welcher dir zugeschickt wurde',
  ERROR_ACT_CODE_INVALID: 'Bitte einen gültigen Aktivierungscode eingeben',
  ERROR_ACT_CODE_INVALID: 'Dieser Aktivierungscode ist ungültig. Bitte versuche es erneut',

  CREATE_ACCOUNT_VERIFYING: 'Verifizierungscode wird überprüft',
  CREATE_ACCOUNT_VERIFYING_PHONE: 'Deine Mobiltelefonnummer wird überprüft',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_1: 'Passwörter werden niemals gespeichert',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_2: 'Du musst dich nur an dein Doodle erinnern',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_3: 'Du kannst forghetti auf allen Geräten verwenden',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_4:
    'Upgrade dein Benutzerkonto um Passwörter sicher innnerhalb einer Gruppe zu teilen',

  CREATE_ACCOUNT_REFERRAL_HEADER: 'Referenzcode',
  CREATE_ACCOUNT_REFERRAL_HEADING: 'Bitte Referenzcode eingeben',
  CREATE_ACCOUNT_REFERRAL_PLACEHOLDER: 'Referenzcode',
  REFERRAL_ENTRY_MESSAGE_1: `Hast du einen Referenzcode von einem Freund erhalten? Wenn nicht, kannst du diesen Schritt überspringen.`,

  CREATE_ACCOUNT_3_HEADER: 'Profil bearbeiten',
  CREATE_ACCOUNT_3_HEADING: 'Dein Profil bearbeiten',
  CREATE_ACCOUNT_3_TEXT: `Hier kannst du einen Namen und ein Icon für dein Profil einrichten. Dies hilft anderen dich zu erkennen falls du dich dazu entscheidest den Zugang zu einer Gruppe zu teilen oder eingeladen wirst der Login Gruppe eines anderen Benutzers beizutreten.`,
  CREATE_ACCOUNT_3_LABEL: 'Dein Name',
  CREATE_ACCOUNT_3_TEXT_2: `Du kannst diesen Schritt überspringen, falls du den Namen und das Icon für dein Profil später einrichten möchtest.`,

  CREATE_ACCOUNT_ADD_LOGIN_MSG: `Wähle von diesen beliebten Apps einen Dienst aus, welchen du aktualisieren möchtest`,

  CREATE_ACCOUNT_FINAL_HEADER: 'Fertig!',
  CREATE_ACCOUNT_FINAL_MSG: `Du bist jetzt bereit für forghetti!`,
  CREATE_ACCOUNT_FINAL_MSG_2: `Du bist auf gutem Weg dein Konto abzusichern und den Stress deiner Passwörter für immer zu vergessen! `,
  CREATE_ACCOUNT_FINAL_MSG_3: `Wir gehen jetzt zusammen die Schritte durch um ein vorhandenes Passwort in ein forghettible Passwort zu verwandeln.`,
  CREATE_ACCOUNT_FINAL_BUTTON: 'Zeig mir wie!',
  CREATE_ACCOUNT_FINAL_LINK: `Ich kenne mich schon mit forghetti aus!`,

  CREATE_ACCOUNT_ADD_CUSTOM_LOGIN: `Oder etwas anderes...`,
  CREATE_ACCOUNT_SERVICE_NAME_TOOLTIP: `Um einen anderen Dienst hinzuzufügen, musst du mindestens den Namen angeben. Die Felder \n\nEmail/Benutzername und Webadresse sind optional werden jedoch empfohlen!`,
  CREATE_ACCOUNT_CUSTOM_LOGIN_ADD_PASSWORD_TOOLTIP: `#REF!`,
  CREATE_ACCOUNT_CUSTOM_LOGIN_NEXTPAGE_TOOLTIP: `Es muss mindestens ein zu generierender Passworttyp hinzugefügt werden. \n\nEs stehen verschiedene Optionen zur Auswahl, da für einige Dienste bestimmte Passwortanforderungen erforderlich sind.`,
  CREATE_ACCOUNT_NEW_LOGIN_DOODLEPAD_MSG: `Dein Doodle wird nun immer verwendet wenn du in Zukunft ein Passwort abrufen möchtest, also lass es zählen!`,
  CREATE_ACCOUNT_ADD_ANOTHER_LOGIN_TOOLTIP: `Klick hier um erneut zum Login Screen zu kommen, wo du deinem Benutzerkonto einen weiteren Service hinzufügen kannst.`,

  ADD_SERVICE_SEARCH_HEADER: `Neuen Dienst hinzufügen`,
  ADD_SERVICE_SEARCH: `Wähle von diesen beliebten Apps einen Dienst aus oder beginne die Suche mit einem Namen`,
  ADD_SERVICE_SUGGESTED: `Vorgeschlagene Dienste`,
  ADD_SERVICE_CREATE: (name) => `Erstellen "${name}"`,
  ADD_SERVICE_SELECT_ICON_HEADER: `Ein Icon auswählen`,
  ADD_SERVICE_SELECT_ICON: (serviceName) => `Für [SERVICE] ein Icon auswählen`,
  ADD_SERVICE_SELECT_GROUP_HEADER: `Gruppe auswählen`,

  ADD_SERVICE_YOUR_GROUP: (serviceName, groupName) =>
    `<em>${serviceName}</em> <em>${serviceName}</em> wird hinzugefügt <em>${groupName}</em> <em>${groupName}</em>`,
  ADD_SERVICE_SELECT_GROUP: (serviceName) =>
    `Wähle eine Gruppe der du <br /><em>${serviceName}</em> hinzufügen möchtest`,
  ADD_SERVICE_SELECT_GROUP_SELECT: (serviceName) =>
    `Oder wähle eine andere Gruppe, der du <br /><em>${serviceName}</em> hinzufügen möchtest`,
  MOVE_SERVICE_SELECT_GROUP: (serviceName) =>
    `Wähle eine Gruppe in die du <br /><em>${serviceName}</em> bewegen möchtest`,
  MOVE_SERVICE_LOADING: 'Dein Dienst wird bewegt',
  MOVE_SERVICE_CONFIRM_HEADER: 'Dein Dienst wird bewegt',
  MOVE_SERVICE_CONFIRM_NOTE: (
    service,
    fromGroup,
    toGroup,
    numFields,
  ) => `<em>${service}</em> wird von <em>${fromGroup}</em> nach <em>${toGroup}</em> bewegt
Dieser Dienst hat <em>${numFields}</em> forghettible${numFields > 1 ? 's' : ''}`,
  MOVE_SERVICE_CONFIRM_NOTE_UPDATE: (fromGroup, toGroup, list, listLength) =>
    `Ein neuer <em>${list}</em> forghettible${
      listLength > 1 ? 's' : ''
    }  wird von dem <em>${toGroup}</em> Doodle generiert. Wenn du auf dein vorheriges forghettible${
      listLength > 1 ? 's' : ''
    } zugreifen möchtest, zeichne stattdessen einfach das <em>${fromGroup}</em> Doodle.`,
  MOVE_SERVICE_CONFIRM: 'Bewegung bestätigen',
  ADD_SERVICE_SELECT_GROUP_ONBOARD: (serviceName) => `Deinen <em>${serviceName}</em> Dienst einer Gruppe hinzufügen`,
  ADD_SERVICE_USERNAME: (serviceName) =>
    `Den Benutzernamen für <em>${serviceName}</em> eingeben oder aus der Liste wählen.`,
  ADD_SERVICE_USERNAME_ONBOARD: (serviceName) =>
    `Gib deine <em>${serviceName}</em> E-Mail-Adresse oder deinen Benutzernamen ein.`,
  ADD_SERVICE_USERNAME_HEADER: `Benutzernamen auswählen`,
  ADD_SERVICE_USERNAME_PLACEHOLDER: `E-Mail oder Benutzername`,
  ADD_SERVICE_SEARCH_FOR_SERVICE: `Gib hier einen Dienst ein, den du benutzt`,
  ADD_SERVICE_SEARCH_FOR_ICONS: 'Nach Icons durchsuchen',
  ADD_SERVICE_SUGGESTED_USERNAMES: `Vorgeschlagene Benutzernamen`,
  ADD_SERVICE_DRAW_DOODLE: (groupName) => `Zeichne das Doodle für deine <em>${groupName}</em> Gruppe`,
  ADD_SERVICE_USERNAME_BLANK: `Du musst einen Benutzernamen oder eine E-Mail-Adresse angeben`,
  ADD_SERVICE_USERNAME_TOO_LONG: 'Dein Benutzername muss kürzer als 64 Zeichen sein',

  ADD_SERVICE_WEBSITE_HEADER: 'Webseite eingeben',
  ADD_SERVICE_WEBSITE: (serviceName) =>
    `Webadresse/URL für <em>${serviceName}</em> eingeben. Du kannst diesen Schritt überspringen und später eine Webadresse/URL eingeben.`,
  ADD_SERVICE_WEBSITE_PLACEHOLDER: `Webadresse/URL`,

  ADD_SERVICE_SEARCH_ONBOARD_LETS_START: `Lass uns anfangen indem du einen dieser beliebten Dienste auswählst oder ...`,
  ADD_SERVICE_SEARCH_ONBOARD_NOW_ITS_YOUR_TURN: `JETZT BIST DU DRAN`,
  ADD_SERVICE_DOODLE_DO_IT_AGAIN: `UND NOCHMAL`,
  ADD_SERVICE_DOODLE_ONBOARD_NOW_ITS_YOUR_TURN: `JETZT BIST DU DRAN`,
  ADD_SERVICE_DOODLE_CREATE_DOODLE: 'Erstelle dein persönliches Doodle',
  ADD_SERVICE_DOODLE_CONFIRM_DOODLE: `Lass uns sicher gehen dass du's dir gemerkt hast... Zeichne dein Doodle <em>noch einmal</em>`,
  ADD_SERVICE_GENERATING_FORGHETTI_ONBOARD: (serviceName) =>
    `Glückwunsch, forghetti erstellt nun ein neues Passwort für <em>${serviceName}</em>`,
  VIEW_SERVICE_GENERATING_FORGHETTI: (serviceName) => `Dein forghetti Passwort für <em>${serviceName}</em> generieren`,
  VIEW_SERVICE_RESET_PASSWORD: `Passwort jetzt zurücksetzen`,
  VIEW_SERVICE_PASSWORD_HELP: (serviceName) =>
    `Wir haben ein super sicheres Passwort für <em>${serviceName}</em> erstellt. Nun musst du nur noch dein altes Passwort zurücksetzen.`,
  VIEW_SERVICE_PASSWORD_HELP_MOVE: (serviceName, groupName) =>
    `Wir haben <em>${serviceName}</em> nach <em>${groupName}</em> bewegt. Falls sich dein Doodle für diesen Dienst verändert hat, musst du dein Passwort aktualisieren..`,
  VIEW_SERVICE_OLD_PREFIX: 'Alt',
  VIEW_SERVICE_NEW_PREFIX: 'Neu',
  HIDE_QUICK_SELECT: `In Zukunft nicht mehr anzeigen`,
  HIDE_QUICK_SELECT_CONFIRM_TITLE: `Ausblenden?`,
  HIDE_QUICK_SELECT_CONFIRM: `Diese Seite wird dann in Zukunft nicht mehr angezeigt. Wenn Du einen neuen Dienst hinzufügst, gelangst Du stattdessen direkt zur Seite "Anderen Dienst hinzufügen".\n\nDies kann jederzeit in den Einstellungen unter "Benutzereinstellungen" rückgängig gemacht werden.`,
  ADD_SERVICE_DODODLES_DO_NOT_MATCH: `Deine Doodles stimmen nicht überein. Probier es noch einmal.`,
  THEME_COLOUR: 'Farbe',
  THEME_COLOURS: ['Makelloses Blau', 'Frisches Grün', 'Furchtloses Rot', 'Feel-good Orange', 'Freundliches Gelb'],
  THEME_MODES: ['Tag', 'Nacht'],
  THEME_BLUE: 'Makelloses Blau',

  DELETE_ACCOUNT_CONTINUE: 'Fortfahren',
  DELETE_ACCOUNT_HEADER: 'Benutzerkonto löschen',
  DELETE_ACCOUNT_HEADING: 'Mein Benutzerkonto löschen',
  DELETE_ACCOUNT_LINE_1: 'Benutzerkonto löschen bedeutet:',
  DELETE_ACCOUNT_LINE_2: '• Dein forghetti Benutzerkonto löschen',
  DELETE_ACCOUNT_LINE_3: '• Alle Informationen löschen die forghetti unter deinem Benutzerkonto hält',
  DELETE_ACCOUNT_LINE_4: '• Dich aus all deinen forghetti Gruppen löschen',
  DELETE_ACCOUNT_LINE_5: '• Den Zugang zu forghetti von all deinen angemeldeten Geräten entfernen',
  DELETE_ACCOUNT_CHANGE_INSTEAD: 'Möchtest du stattdessen das Mobiltelefon wechseln?',

  DELETE_ACCOUNT_CONFIRM_MESSAGE: (telNumber) =>
    `Wir haben dir einen Bestätigungscode an deine Mobiltelefonnummer geschickt. (${telNumber}). Bitte gib diesen Code ein um zu bestätigen, dass du dein Benutzerkonto löschen möchtest:`,
  DELETE_ACCOUNT_CONFIRM_BUTTON: 'Bestätigen',

  DEVICES_HEADER: 'Geräteverwaltung',
  DEVICES_LAST_ACTIVE: 'Zuletzt aktiv: ',
  DEVICES_FORGHETTI_APP: ' - forghetti App',
  DEVICES_LOG_OUT_ALL: 'Von allen Geräten abmelden',

  SERVICES_PERSONAL: 'Persönlich',
  SERVICES_ADD_LOGIN: 'Login hinzufügen',
  SERVICES_RECENT: 'Zuletzt verwendet', // Not currently used

  SEARCH_INTERNET: 'Das Internet nach einem Bild durchsuchen',

  SERVICE_HEADER_NEW: 'Login hinzufügen',
  SERVICE_NAME_PLACEHOLDER: 'Dein Login benennen',

  SERVICE_DOODLEPAD_SUBHEADING: 'Doodle auf dem Doodlepad',
  DOODLEPAD_SECRET: 'Ausblenden',
  DOODLEPAD_SHOW: 'Anzeigen',
  DOODLEPAD_REDOODLE: 'Löschen',
  ERROR_NO_DOODLE: 'Bitte zeichne dein Doodle',

  SERVICE_EDIT_FIELD_HEADER: 'Einstellungen',

  FIELD_TYPES: {
    lns: 'AbC123_*.',
    wns: 'Wörter123_*.',
    lnx: 'AbC123~?%',
    wnx: 'Words123~?%',
    ln: 'AbC123',
    wn: 'Wörter123',
    l: 'AbC',
    w: 'Wörter',
    n: '123',
  },

  FIELD_TEMPLATE_PIN: 'PIN',
  FIELD_TEMPLATE_COMPLEX_PASSWORD: 'Komplexes Passwort',
  FIELD_TEMPLATE_PASSWORD: 'Passwort',
  FIELD_TEMPLATE_MEMWORD: 'Merkbares Wort',
  FIELD_TEMPLATE_FORGHETTIBLE: 'Nur Buchstaben',

  SERVICE_USERNAME_LABEL: 'E-Mail oder Benutzername',
  SERVICE_WEBSITE_LABEL: 'Webadresse',

  SERVICE_EDIT_HEADER: 'Details',
  SERVICE_EDIT_USERNAME_PLACEHOLDER: 'Die Login-Methode eingeben',
  SERVICE_EDIT_WEBSITE_PLACEHOLDER: 'www.webadresse.com',

  SERVICE_ADD_FORGHETTIBLE: 'Ein forghettible hinzufügen',
  SERVICE_DFT_FORGHETTIBLE_NAME: 'Passwort',

  SERVICE_NAME_LENGTH_INVALID: 'Der Name für diesen Dienst sollte zwischen 2 und 32 Zeichen lang sein',
  SERVICE_USERNAME_LENGTH_INVALID: 'E-Mail/Benutzername für diesen Dienst sollte kürzer als 64 Zeichen sein',
  SERVICE_ADDRESS_LENGTH_INVALID: 'Die Webadresse für diesen Dienst sollte weniger als 128 Zeichen haben',
  SERVICE_NO_FORGHETTIBLES: 'Du musst mindestens ein forghettible einrichten. Ein Passwort zum Beispiel.',

  SERVICE_CHOOSE_ICON: 'Icon auswählen',

  SERVICE_DOODLEPAD_HEADING: 'Doodle auf dem Doodlepad',
  SERVICE_DOODLEPAD_RIGHT_NAV: 'Details',

  SERVICE_ADDING: 'Dienst wird hinzugefügt',

  SERVICE_CONNECTING: 'Verbindung wird hergestellt',

  FIELD_DETAILS_HEADER: 'Einstellungen',
  FIELD_NAME: 'Name',
  FIELD_NAME_PLACEHOLDER: 'Gib deinem forghettible einen Namen',
  FIELD_TYPE: 'Typ',
  FIELD_LENGTH: 'Länge',
  FIELD_LENGTH_PICKER_CUSTOM: 'Benutzerdefiniert',

  FIELD_RIGHT_NAV: 'OK',

  FIELD_OPTIONS_HEADING: 'Optionen',

  ADVANCED_OPTIONS: 'erweiterte Optionen',
  RESET_FIELD: 'Forghettible zurücksetzen',
  DELETE_FIELD: 'Löschen',
  RESTORE_FIELD: 'Forghettible wiederherstellen',

  FIELD_ADVANCED_OPTIONS_HEADER: 'Erweiterte Optionen',

  RESTORE_FIELD_PAGE_HEADER: 'Wiederherstellen',
  RESTORE_FIELD_SUBHEADING: 'Altes Passwort benötigt?',
  RESTORE_FIELD_DESCRIPTION:
    'Hier kannst du zu einer vorherigen Version zurückkehren. Wähl einfach aus welche Version du wiederherstellen möchtest.',
  RESTORE_FIELD_VERSION_HISTORY: 'Versionsverlauf',
  RESTORE_FIELD_CURRENT: 'Aktuell',

  SERVICE_VIEW_HEADER: 'Zusammenfassung',
  SERVICE_VIEW_RIGHT_NAV: 'Fertig',

  SERVICE_SUMMARY_MESSAGE: `Deine forghettibles wurden generiert. Bitte unten auswählen um das Login zu kopieren, anzuzeigen und darauf zuzugreifen.`,
  SERVICE_SUMMARY_FIRST_LOGIN_MSG1: `Glückwunsch! Du hast gerade dein erstes forghettible Passwort erstellt!`,
  SERVICE_SUMMARY_FIRST_LOGIN_MSG2: `Du musst jetzt dein bestehendes Passwort mit diesem neuen ersetzen damit es in Kraft tritt.`,
  SERVICE_SUMMARY_FIRST_LOGIN_PASSWORD_TOOLTIP: `Wenn du bereit bist, kannst du das neue Passwort in die Zwischenablage kopieren indem du auf dieses Icon klickst.`,

  SERVICE_DETAILS_HEADER: 'Details',
  SERVICE_DETAILS_RIGHT_NAV: 'Bearbeiten',
  SERVICE_DETAILS_DELETE: 'Login löschen',

  PROFILE_HEADER: 'Profil',
  PROFILE_WIZARD_COMPLETE: 'Das forghetti Setup abschließen.',
  PROFILE_WIZARD_SEE_DETAILS: 'Details einblenden > >',
  PROFILE_PREMIUM_BANNER_HEADING: 'Premium ausprobieren',
  PROFILE_PREMIUM_BANNER_DETAIL: 'Schalte weitere Funktionen frei und agiere sicherer im Netz.',
  PROFILE_THEME_MODE_LABEL: 'Modus',
  PROFILE_THEME_COLOUR_LABEL: 'Farbe',
  PROFILE_BADGES: 'Deine Badges',
  PROFILE_LINK_ACCOUNT: 'Dein Benutzerkonto',
  PROFILE_LINK_PREMIUM: 'Premium',
  PROFILE_ENABLE_AUTOFILL: 'Autofill aktivieren',
  PROFILE_LINK_USER_PREFERENCES: 'App Einstellungen',
  PROFILE_YOUR_SUBSCRIPTION: 'Dein Abo',
  PROFILE_LINK_INVITE: 'forghetti mit einer Freundin teilen',
  /*PROFILE_LINK_INVITE_SECONDARY: 'Mit Freunden teilen und gratis Logins erhalten',*/
  PROFILE_REFERRAL_CODE: 'Empfehlungscode',
  PROFILE_REFERRAL_CODE_SECONDARY: '',
  PROFILE_LINK_DEVICES: 'Geräteverwaltung',
  PROFILE_LINK_HELP: 'Hilfe bekommen',
  PROFILE_LINK_FEEDBACK: 'Schick uns dein Feedback',
  PROFILE_LINK_ABOUT: 'Über uns',
  PROFILE_LINK_LOGOUT: 'Abmelden',

  PROFILE_ACCOUNT_HEADER: 'Dein Benutzerkonto',
  PROFILE_ACCOUNT_LINK_SECURITY: 'Sicherheit',
  PROFILE_ACCOUNT_LINK_MIGRATE: 'Mobiltelefonnummer ändern',
  PROFILE_ACCOUNT_LINK_DELETE: 'Mein Benutzerkonto löschen',
  PROFILE_ACCOUNT_LINK_SUBSCRIPTION: 'Dein Abo',

  PROFILE_REONBOARDING: 'Lerne wie forghetti funktioniert',
  PROFILE_GET_ONLINE_HELP: 'Online Hilfe holen',
  PROFILE_GET_VIDEO_HELP: 'Online Video-Hilfe holen',
  PROFILE_HELP_HEADER: 'Hilfe',

  PROFILE_EDIT_NAME_HEADER: 'Profildetails',
  PROFILE_EDIT_NAME_LABEL: 'Dein Name',
  PROFILE_EDIT_TEL_LABEL: 'Mobiltelefonnummer',

  PROFILE_NAME_BLANK: 'Profilname darf nicht leer sein',
  PROFILE_NAME_TOO_LONG: 'Profilname darf nicht mehr als 32 Zeichen haben',

  PROFILE_NAME_INVALID: 'Profilname darf nur Buchstaben, Nummern und Symbole enthalten',

  PROFILE_MIGRATE_1_HEADER: 'Mobiltelefonnummer ändern',
  PROFILE_MIGRATE_1_HEADING: 'Aktuelle Mobiltelefonnummer',
  PROFILE_MIGRATE_1_INFO: 'Bitte neue Ländervorwahl und Mobiltelefonnummer eingeben',
  PROFILE_MIGRATE_1_PLACEHOLDER: 'Mobiltelefonnummer',
  PROFILE_MIGRATE_1_BUTTON: 'Verifizierungscode senden',

  PROFILE_MIGRATE_2_HEADER: 'Code eingeben',
  PROFILE_MIGRATE_2_HEADING: 'Aktuelle Mobiltelefonnummer',
  PROFILE_MIGRATE_2_HEADING2: 'Neue Mobiltelefonnummer',
  PROFILE_MIGRATE_2_INFO: 'Jetzt bitte den Verifizierungscode eingeben den du auf deinem neuen Telefon erhalten hast:',
  PROFILE_MIGRATE_2_BUTTON: 'Bestätigen',

  PROFILE_MIGRATE_3_HEADER: 'Fertig',
  PROFILE_MIGRATE_3_MSG: 'Deine Mobiltelefonnummer wurde geändert',
  PROFILE_MIGRATE_3_HEADING: 'Neue Mobiltelefonnummer',

  PROFILE_ABOUT_HEADER: 'Über uns',
  PROFILE_ABOUT_PRIVACY: 'Datenschutz-Bestimmungen',
  PROFILE_ABOUT_COOKIE: 'Cookie-Richtlinien',
  PROFILE_ABOUT_TERMS: 'Nutzungsbedingungen',
  PROFILE_ABOUT_ACCEPTABLE: '"Acceptable use policy" Richtlinie',
  PROFILE_ABOUT_VERSION_HEADING: 'Version',

  PROFILE_REFERRAL_CODE_HEADING: 'Empfehlungscode',
  PROFILE_REFERRAL_CODE_LABEL: 'Empfehlungscode',
  PROFILE_REFERRAL_CODE_PLACEHOLDER: 'Gib deinen Empfehlungscode ein',
  PROFILE_EDIT_REFERRAL_CODE_HEADING: 'Empfehlungscode bearbeiten',
  PROFILE_REFERRAL_CODE_TEXT: 'Falls du einen Empfehlungscode erhalten hast, gib diesen bitte unten ein:',

  PROFILE_PREMIUM_TYPE: {
    premium_trial: 'Premium Probeabo Benutzer',
    premium_life: 'Lebenslanger Benutzer',
    free: 'Gratis Benutzer',
    premium: 'Premium Abonnent',
    premium_paid: 'Premium Abonnent',
  },
  APP_PREFERENCES_HEADER: 'App Einstellungen',
  APP_PREFERENCES_THEME: 'Design',
  APP_PREFERENCES_QUICK_SELECT: 'Tooltipps anzeigen',
  APP_PREFERENCES_SHOW_QUICK_SELECT: 'Schnelles Hinzufügen eines Login anzeigen',
  APP_PREFERENCES_SECRET_DOODLE: 'Geheimes Doodle standardmäßig',
  APP_PREFERENCES_SHOW_TOOLTIPS: 'Tooltipps anzeigen',
  APP_PREFERENCES_REQUIRE_TOUCH_ID: 'Zum Öffnen der App biometrische Daten anfordern',
  APP_PREFERENCES_NOTIFICATIONS: 'Benachrichtigungen',
  APP_PREFERENCES_COUNTRY: 'Sprache',
  APP_PREFERENCES_TOUCH_ID_TOAST: `Bitte sicherstellen, dass biometrische Identifikation auf Ihrem Gerät aktiviert/möglich ist`,

  PREMIUM_HEADER: 'Forghetti Premium',

  YOUR_SUBSCRIPTION_HEADER: 'Dein Abo',
  YOUR_SUBSCRIPTION_SUBHEADING: 'Premium Probeabo',

  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_TEXT_1: (date) =>
    `Du befindest dich gerade in einem Premium Probeabo. Dein Probeabo läuft am ${date} ab. Danach kannst du forghetti entweder weiterhin als Gratis Benutzer nutzen (und alle deine Premium Vorteile verlieren) oder ein in-App-Premiumabo kaufen.`,
  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_TEXT_2: (date) => `Wir hoffen, dass dir forghetti bis jetzt gefällt.`,

  YOUR_SUBSCRIPTION_FREE_TEXT_1: (date) =>
    `Du bist derzeit ein gratis Benutzer. Meld dich noch heute an, um von unbegrenzten Logins zu profitieren, unbegrenzte Gruppen zu erstellen, die du mit Freunden, Familie und Mitarbeitern teilen kannst, und detaillierte Gesundheitscheck-Berichte zu erhalten.`,
  YOUR_SUBSCRIPTION_FREE_TEXT_2: (date) => `Wir hoffen, dass dir forghetti bis jetzt gefällt.`,
  YOUR_SUBSCRIPTION_FREE_TEXT_3:
    'Wenn du bereits ein Abo aus dem Appstore hast, klick Abo wiederherstellen um das Abo wieder anzuwenden.',
  YOUR_SUBSCRIPTION_FREE_DOWNLOAD: `Du hast die App noch nicht? Lade sie jetzt in deinem Appstore herunter`,

  YOUR_SUBSCRIPTION_PREMIUM_TEXT_1: (date) =>
    `Du bist momentan Premiumbenutzer. Dein Abo läuft am ${date} ab. Bei Kauf eines Premiumabos ist eine automatische Verlängerung des Abos voreingestellt. Du kannst dies in deinen Appstore Einstellungen deaktivieren.`,
  YOUR_SUBSCRIPTION_PREMIUM_TEXT_2: (date) => `Wir hoffen, dass dir forghetti bis jetzt gefällt.`,

  YOUR_SUBSCRIPTION_PREMIUM_NO_DATE_TEXT_1: `Du bist momentan Premiumbenutzer.`,

  YOUR_SUBSCRIPTION_PREMIUM_LIFE_TEXT_1: (date) =>
    `Dein lebenslanges Premiumabo läuft nie ab. Du kannst forghetti für immer benutzen!`,
  YOUR_SUBSCRIPTION_PREMIUM_LIFE_TEXT_2: (date) => `Wir hoffen, dass dir forghetti bis jetzt gefällt.`,
  YOUR_SUBSCRIPTION_FREE_TEXT_APP_3:
    'Wenn du bereits ein Abo aus dem Appstore hast, klick Abo wiederherstellen um das Abo wieder anzuwenden.',

  YOUR_SUBSCRIPTION_FREE_TEXT_APP_1: `Du bist derzeit ein gratis Benutzer. Meld dich noch heute an, um von unbegrenzten Logins zu profitieren, unbegrenzte Gruppen zu erstellen, die du mit Freunden, Familie und Mitarbeitern teilen kannst, und detaillierte Gesundheitscheck-Berichte zu erhalten.`,

  YOUR_SUBSCRIPTION_FREE_TEXT_APP_2: 'Scanne den QR-Code mit deinem Handy um de Premium-Service zu abonnieren.',
  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_BUTTON_LABEL: 'Abopreise anzeigen',
  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_CORDOVA_BUTTON_LABEL: 'Abo Optionen anzeigen',

  YOUR_SUBSCRIPTION_FREE_BUTTON_LABEL: 'Upgraden',

  PREMIUM_NO_PRODUCT_SELCTED: 'Ungültige Produktauswahl',
  PREMIUM_PURCHASE_BUTTON: 'Kaufen',
  PREMIUM_PURCHASE_BUTTON_NA: 'in Kürze',
  COMING_SOON: 'in Kürze',
  PREMIUM_PRICE_NONE: 'n. a.',
  PREMIUM_PURCHASE_BUTTON_OWNED: 'Derzeitiges Abonnement',
  PREMIUM_PRUCHASE_REFRESH_PURCAHSE: 'Deinen Kauf erneuern',
  PREMIUM_MONTHLY_HEADER: 'Premium jeden Monat',
  PREMIUM_MONTHLY_LIST: [
    {
      description: 'Unbegrenzte Gruppen',
    },
    {
      description: 'Unbegrenzte Logins',
    },
    {
      description: 'vorrangiger Support',
    },
  ],
  PREMIUM_ANNUAL_ALIAS: 'Jahresabrechnung',
  PREMIUM_MONTHLY_ALIAS: 'monatliche Abrechnung',
  PREMIUM_ONE_OFF_ALIAS: 'einmaliger Kauf',
  PREMIUM_LOADING_OWNED: 'Glückwunsch! Du bist jetzt Premiumbenutzer!',
  PREMIUM_LOADING_RELOAD: 'In Kürze wird forghetti sich mit deinem neuen Abo neu laden.',
  PREMIUM_LOADING_APPROVED: 'Bitte warte einen Moment während wir dein Premiumabo im Appstore überprüfen!',
  PREMIUM_LOADING_REGISTERED: 'Bitte warte einen Moment während wir die Abo Optionen laden...',
  PREMIUM_MANAGE_SUBSCRIPTIONS: 'Abo verwalten',
  PREMIUM_IMPOSSIBLE_TO_VERIFY: 'Es war nicht möglich deinen Abokauf zu bestätigen ',
  PREMIUM_RESTORE_PURCHASE: 'Abo wiederherstellen',
  PREMIUM_YEARLY_HEADER: 'Premium für das ganze Jahr',
  PREMIUM_YEARLY_LIST: [
    {
      description: 'Unbegrenzte Gruppen',
    },
    {
      description: 'Unbegrenzte Logins',
    },
    {
      description: 'vorrangiger Support',
    },
  ],
  PREMIUM_LIFETIME_HEADER: 'Premium für immer',
  PREMIUM_LIFETIME_LIST: [
    {
      description: 'Unbegrenzte Gruppen',
    },
    {
      description: 'Unbegrenzte Logins',
    },
    {
      description: 'vorrangiger Support',
    },
  ],
  ENABLE_ANDROID_AUTOFILL_HEADING: 'Autofill aktivieren',
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_1: `Forghetti verwendet Androids Barrierefreiheitsdienst für die Autofill -Funktion, mit der Kennwörter abgerufen werden können, wenn sie in einer App auf Ihrem Gerät aufgefordert werden.`,
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_2:
  'Wir versichern Ihnen, Forghetti liest oder greift keinen anderen Bildschirminhalt aus oder greift auf. Wir identifizieren nur, wann ein Kennwortfeld auf dem Bildschirm zum AutoFill angezeigt wird.',
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_3: `Um diesen Dienst zu nutzen, ist die Einwilligung der Benutzer erforderlich. Wenn Sie auf die Schaltfläche "Vereinbarung" klicken, geben Sie uns die Erlaubnis, den Barrierefreiheit Service für das Autofill zu verwenden. Wenn Sie die Einwilligung ablehnen, ist die Autofill -Funktion deaktiviert, aber Sie können weiterhin Annhetti ohne diese Funktionalität verwenden.`,
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_4: `Bitte beachten Sie, dass das Navigieren von diesem Bildschirm nicht als Einwilligung eingeleitet wird und wir keine automatischen Nachrichtensuche verwenden, um die Zustimmung zu sammeln.`,
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_5: `Weitere Informationen darüber, wie unsere Nutzung der API der Barrierefreiheit implementiert und gesichert ist, sowie über unsere Behandlung von Benutzerdaten finden Sie unsere Datenschutzrichtlinien.`,

  ENABLE_ANDROID_AUTOFILL_BUTTON: 'Zustimmen',
  ENABLE_ANDROID_AUTOFILL_BUTTON_NO: 'Nicht jetzt',

  MAKE_ADMIN_MESSAGE: 'Möchtest du diesen Benutzer als Administrator für diese Gruppe definieren?',
  MAKE_OWNER_MESSAGE: 'Möchtest du die Verwaltung dieser Gruppe an den Benutzer übergeben?',

  REMOVE_ADMIN_MESSAGE: 'Möchtest du diesem Benutzer den Administratorstatus entziehen?',
  DELETE_GROUP_CONFIRM: 'Bist du sicher, dass du diese Gruppe löschen möchtest?',
  LEAVE_GROUP_CONFIRM: 'Möchtest du diese Gruppe verlassen?',
  GROUP_DELETED_MESSAGE: 'Die Gruppe wurde gelöscht.',
  REMOVE_MEMBER_MESSAGE: 'Möchtest du diesen Benutzer aus der Gruppe entfernen?',
  REMOVE_MEMBER_SUCCESS: 'Gruppenmitglied wurde entfernt.',
  GROUP_SHARED_BY: 'Gruppen geteilt durch',
  ERROR_INVALID_NUM_WORDS: 'Wortanzahl muss zwischen 1 und 10 liegen',
  ERROR_INVALID_NUM_LETTERS: 'Zeichenanzahl muss zwischen 1 und 64 liegen',

  UPGRADE_REQUIRED: 'Upgrade benötigt',
  ADD_LOGIN_NOT_ALLOWED: `Als Gratisbenutzer darfst du nur deiner persönlichen Gruppe Logins hinzufügen. Du kannst heute upgraden um Gruppen zu erstellen und sie mit Freunden, Familie und Arbeitskollegen zu teilen!`,
  UPGRADE_TODAY_MESSAGE: `Als Gratisbenutzer kannst du einer Gruppe beitreten.
Du kannst heute upgraden um Gruppen zu erstellen und sie mit Freunden, Familie und Arbeitskollegen zu teilen!`,

  UPGRADE_TODAY_BUTTON: 'Upgrade heute',
  UPGRADE_TODAY_PAGE_HEADING: 'Upgrade heute',
  UPGRADE_TODAY_MSG_1: 'forghetti Premiumbenutzer werden',
  UPGRADE_TODAY_PRICE: '£24.99 pro Jahr',
  UPGRADE_TODAY_MSG_2: 'schalte alle Leistungen frei die forghetti anbietet',
  UPGRADE_TODAY_MSG_3: 'Die Premiummitgliedschaft ist in beiden unseren Apps als in-App Kauf erhältlich:',

  UPGRADE_TODAY_MSG_4:
    'Ein Jahresabo ermöglicht es dir als Premiumbenutzer unbegrenzte Logins, forghettible Felder und Gruppen zu erstellen.',

  INVITE_FRIEND_HEADER: 'Gratis Services erhalten',
  INVITE_FRIEND_REFERRAL_LABEL: 'Empfehlungs-Link',
  INVITE_FRIEND_REFERRAL_COPY: 'Empfehlungs-Link in die Zwischenablage kopieren',
  INVITE_FRIEND_REFERRAL_EMAIL: 'Empfehlungs-Link als E-Mail versenden',
  INVITE_FRIEND_PREAMBLE: 'forghetti mit einer/einem Freundin teilen',
  INVITE_FRIEND_TEXT: `Einfach deinen persönlichen Link teilen`,
  INVITE_FRIEND_BENEFIT:
    'Empfehle forghetti an einen Freundin weiter und erhalte 5 weitere Dienste für deine persönliche Gruppe wenn sie sich anmelden. Empfehle forghetti an 5 Freunde weiter und erhalte 15 zusätzliche Dienste.',

  GROUPS_LIST_HEADER: 'Deine Gruppen',
  GROUPS_LIST_INFO_MESSAGE: 'Gruppen verwalten oder zwischen Gruppen wechseln',
  GROUPS_LIST_PERSONAL_HEADING: 'Deine persönliche Gruppe',
  GROUPS_LIST_OTHER_GROUPS_HEADING: 'Deine anderen Gruppen',
  GROUPS_LIST_ADD_NEW_GROUP: 'Eine neue Gruppe hinzufügen',
  GROUPS_LIST_CURRENTLY_ACTIVE: 'DERZEIT AKTIV',
  GROUPS_NEW_HEADER: 'Neue Gruppe',
  GROUPS_NEW_RIGHT_NAV: 'Erstellen',
  GROUPS_NEW_NAME_PLACEHOLDER: 'Eine Bezeichnung eingeben',

  GROUPS_ADD_GROUP_HEADER: 'Gruppe hinzufügen',
  GROUPS_JOIN_GROUP: 'Gruppe beitreten',
  GROUPS_JOIN_GROUP_TEXT: 'Einer Gruppe über eine Einladung beitreten',
  GROUPS_CREATE_GROUP: 'Gruppe erstellen',
  GROUPS_CREATE_GROUP_TEXT: 'Eine neue Gruppe erstellen um sie mit anderen zu teilen',
  GROUPS_FREE_JOIN_WARNING: 'Als Gratisbenutzer kannst du einer Gruppe beitreten.',
  GROUPS_ADD_UPGRADE:
    'Du kannst heute upgraden um Gruppen zu erstellen und sie mit Freunden, Familie und Arbeitskollegen zu teilen!',
  GROUP_NEW_INVITE_GENERATING: 'Deine Gruppeneinladung wird generiert',
  LEAVE_GROUP_MESSAGE: 'Du wurdest nun aus der Gruppe entfernt',
  GROUP_DETAIL_LOADING: `Gruppendetails werden abgerufen`,
  GRP_SHARING_HEADING: 'Wird geteilt',
  GRP_MEMBERS_HEADING: 'Mitglieder',
  GRP_SETTINGS_HEADING: 'Gruppeneinstellungen',

  GROUP_VIEW_HEADER: 'Gruppendetails',
  GROUP_VIEW_RIGHT_NAV: 'Bearbeiten',
  GROUP_VIEW_USER_ADMIN: 'Administrator',
  GROUP_VIEW_USER_OWNER: 'Besitzer',
  GROUP_VIEW_USER_YOU: 'Du',
  GROUP_VIEW_USER_READ_ONLY: 'schreibgeschützt',
  GROUP_VIEW_SWITCH_TO: 'Zu dieser Gruppe wechseln',
  GROUP_VIEW_LEAVE: 'Diese Gruppe verlassen',
  GROUP_VIEW_DELETE: 'Gruppe löschen',
  GROUP_VIEW_PREMIUM: 'Premiumbenutzer',
  GROUP_VIEW_FREE: 'Gratisbenutzer',
  GROUP_DETAIL_SAVE_LOADING: `Deine Gruppendetails aktualisieren`,
  GROUP_EDIT_HEADER: 'Gruppendetails',
  GROUP_EDIT_MAX_MEMBERS: 'Max Anzahl an Mitgliedern',
  GROUP_EDIT_MAX_MEMBERS_SECONDARY: 'Inklusive dir',
  PERSONAL_GROUP_MESSAGE: `Dies ist deine persönliche Gruppe. Diese Gruppe wird nie mit anderen Benutzern geteilt. Um das Icon für diese Gruppe zu ändern, verwalte dein Profilbild in den Profileinstellungen.`,

  GROUP_CHOOSE_ICON: 'Icon auswählen',

  GROUP_NAME_BLANK: 'Gruppenname kann nicht leer sein',
  GROUP_NAME_TOO_LONG: 'Der Gruppenname kann nicht länger als 32 Zeichen sein',

  GRP_MAX_MEMBERS_ERROR: 'Max Anzahl Mitglieder muss eine Zahl zwischen 1 und 50 sein',

  JOIN_GROUP_PLACEHOLDER: 'Gib deinen Einladungs-Link ein',
  JOIN_GROUP_HEADING: 'Gruppe beitreten',
  GRP_INVITE_CODE_LABEL: 'Gruppen Einladungs-Link',
  GRP_INVITE_CODE_NOTE: (group) => `Lade andere Benutzer zu <em>${group}</em> ein in dem du diesen Link teilst.`,
  JOIN_GROUP_RIGHT_NAV: 'Beitreten',

  REFERRAL_COPY_MESSAGE: (url) =>
    `Ich lade dich ein forghetti zu nutzen. Lad dir die App herunter und vergiss deine Passwörter für immer. Gehe zu ${url}`,

  CREATE_GROUP_INVITE_TEXT_1: `Sende diesen Code an Freunde, Familie und Teammitglieder um ihnen Zugang zu dieser Gruppe zu gewähren.

Um einer Gruppe beizutreten klick 'Hinzufügen' und dann 'Gruppe beitreten'`,

  INVITE_NEW_MEMBER: 'Ein neues Mitglied einladen',
  GRP_INVITE_HELP_TEXT: `Alle Gruppenmitglieder können neue Logins erstellen, aber nur Administratoren können diese löschen. Administratoren können auch Mitglieder entfernen. Wenn Du jedoch die Gruppe erstellst, kannst du niemals von einem anderen Administrator entfernt werden.`,

  // Template for text that is copied to clipboard when you choose to copy a group invite code to clipboard
  GRP_INVITE_CLIPBOARD_MESSAGE: (code) => `${code}`,

  INVITE_LINK_HEADING: 'Einladung',
  INVITE_SETTINGS_HEADING: 'Einstellungen',
  LINK_EXPIRY_LABEL: 'Link läuft nach ... ab',
  MAX_USES_LABEL: 'Max Anzahl an Nutzungen',

  LINK_EXPIRY_VALUE: (mins) => LINK_EXPIRY_VALUES[String(mins)],
  LINK_MAX_USES_VALUE: (num) => LINK_MAX_USE_VALUES[String(num)],
  INVITE_NO_LIMIT: LINK_MAX_USE_VALUES['-1'],
  INVITE_EXPIRY_NEVER: LINK_EXPIRY_VALUES['-1'],

  DEACTIVATE_GRP_LINK_CONFIRM: 'Bist du dir sicher, dass du diese Einladung löschen möchtest?',
  DEACTIVATE_GRP_LINK_SUCCESS: 'Gruppeneinladung wurde erfolgreich gelöscht.',

  GRP_INVITE_CREATE_NAV: 'Erstellen',

  // Share Group Invite page
  SHARE_INVITE_HEADING: 'Mitglieder einladen',
  GRP_INVITE_CODE_LABEL: 'Einladungslink',
  CPY_GRP_INVITE: 'Die Einladung in die Zwischenablage kopieren',
  DEACTIVATE_LINK: 'Einladung löschen',
  SHARE_INVITE_VIA_EMAIL: 'Einladung via E-Mail teilen',
  COPY_INVITE_SUCCESS: `Gruppeneinladung wurde in die Zwischenablage kopiert.`,

  ONBOARDING_HEADER_1: 'Ein Login für eine Lieblingswebseite erstellen',
  ONBOARDING_HEADER_2: 'Zeichne dein Doodle',
  ONBOARDING_HEADER_3: 'Dein Passwort wurde generiert',
  ONBOARDING_HEADER_4: 'Kopieren und LOS!',
  ONBOARDING_HEADER_5: "Das war's!",
  ONBOARDING_WELCOME_HEADING: `Hi, mein Name ist Locky <br> Wilkommen bei forghetti`,
  ONBOARDING_WELCOME_TEXT:
    'Wenn du forghetti benutzt kannst du dich bei allen Diensten anmelden die du benutzt, mit einem sicheren Doodle.',
  ONBOARDING_DOODLE_HEADING: 'Du wirst ein persönliches Doodle brauchen',
  ONBOARDING_DOODLE_TEXT:
    'Von Linien und Punkten über zufällige Striche und Zahlenkombinationen. Deine einzigartige Kombination generiert ein super sicheres Passwort.',
  ONBOARDING_HOW_IT_WORKS: 'Wie es funktioniert',
  ONBOARDING_ADDSERVICE_HEADING: 'Toll für jeden Dienst',
  ONBOARDING_ADDSERVICE_TEXT:
    'Jedes Mal wenn du einen Dienst hinzufügst, generieren wir ein einzigartiges und ultra sicheres Passwort. Du kannst sogar PINs und merkbare Wörter erstellen.',
  ONBOARDING_RESETPASSWORD_HEADING: 'Zurücksetzen und dein altes Passwort austauschen',
  ONBOARDING_RESETPASSWORD_TEXT:
    'Öffne die Webseite, setze dein altes Passwort zurück, und tausche es gegen dein neues, ultra sicheres forghetti Passwort aus.',
  ONBOARDING_SECURESHARING_HEADING: 'Sicheres Passwörter-Teilen',
  ONBOARDING_SECURESHARING_TEXT:
    'Nutze Gruppen um den Zugang zu deinen Lieblingsdiensten sicher mit Freunden, Familie und Arbeitkollegen zu teilen.',
  ONBOARDING_UNLIKE_OTHERS_HEADING: 'Anders als andere Passwort-Manager...',
  ONBOARDING_UNLIKE_OTHERS_TEXT:
    'Speichern wir keine Passwort-Datenbank. Wir generieren sie wenn du sie brauchst. Sie werden mit deinem Doodle als Schlüssel kalkuliert. Super sicher und nur dir bekannt.',
  ONBOARDING_DOODLETUTORIAL1_HEADING: 'Nun, dein sicheres Doodle erstellen! Wie macht man das?',
  ONBOARDING_SHOWMEHOW: 'Ich zeig dir wie!',
  ONBOARDING_DOODLETUTORIAL2_HEADING: 'Erstens kannst du ein Muster zeichnen...',
  ONBOARDING_DOODLETUTORIAL: 'Sicheres Doodle Tutorial',
  ONBOARDING_DOODLETUTORIAL3_HEADING: 'oder eine Reihe Linien',
  ONBOARDING_DOODLETUTORIAL4_HEADING: 'selbst Punkte sind in Ordnung...',
  ONBOARDING_DOODLETUTORIAL5_HEADING: 'Oder eine Kombination aus Allem',
  ONBOARDING_DOODLETUTORIAL6_HEADING: 'Aber pass auf, die Reihenfolge ist wichtig',
  ONBOARDING_DOODLETUTORIAL7_HEADING: 'Doodle A zeichnen',
  ONBOARDING_DOODLETUTORIAL8_HEADING: 'ist nicht das gleiche wie Doodle B zeichnen',
  ONBOARDING_YOURTURN: `Jetzt bist du an der Reihe`,

  ONBOARDING_COMPLETE_WELCOME: 'Wilkommen bei forghetti',
  ONBOARDING_COMPLETE_CONGRATULATIONS:
    'Glückwunsch! Du hast gerade die ersten Schritte Richtung Absicherung deiner Online Identität gemacht',
  ONBOARDING_COMPLETE_COMPLETE_SETUP: 'Dein forghetti Setup vervollständigen',
  ONBOARDING_COMPLETE_CREATE_FORGHETTI: 'Ein forghetti Benutzerkonto erstellen',
  ONBOARDING_COMPLETE_SIGNATURE_DOODLE: 'Dein persönliches Doodle erstellen',
  ONBOARDING_COMPLETE_FIRST_LOGIN: 'Füge deinen ersten Dienst hinzu',
  ONBOARDING_COMPLETE_RESET_PASSWORD: 'Ein sicheres Passwort wurde generiert',
  ONBOARDING_COMPLETE_IMPORT: 'Dienste importieren',
  ONBOARDING_COMPLETE_HEALTHCHECK: 'Einen Gesundheitscheck an deinen Online Benutzerkonten durchführen',
  ONBOARDING_COMPLETE_IMPORT_BUTTON: 'Dienste importieren',
  ONBOARDING_COMPLETE_REMIND_LATER: 'Später erinnern',
  ONBOARDING_COMPLETE_CONTINUE: `Los geht's`,
  ONBOARDING_COMPLETE_RESET_PASSWORD: 'Ein unsicheres Passwort zurücksetzen',
  ONBOARDING_COMPLETE_HEALTHCHECK_BUTTON: 'Einen Gesundheitscheck durchführen',
  ONBOARDING_COMPLETE_NEXT_STEPS: `<h2>Und nun warum nicht:</h2><ul><li>Einen Gesundheitscheck an deinen Online Benutzerkonten durchführen?</li><li>Oder einen weiteren Dienst bei forghetti aktualisieren.</li><li>Oder einfach nur umschauen...</li></ul>`,

  ONBOARDING_EXISTING_TITLE: 'Neue Features',
  ONBOARDING_EXISTING_WELCOME_HEADING: 'Ein großes Dankeschön',
  ONBOARDING_EXISTING_WELCOME_TEXT: `Das forghetti Team war fleißig. Es gibt einige Verbesserungen hinter den Kulissen und in der App. Schau dir ein paar der neuen Features an.`,
  ONBOARDING_EXISTING_MOVE_SERVICES_HEADING: 'Dienste bewegen',
  ONBOARDING_EXISTING_MOVE_SERVICES_TEXT:
    'forghetti lässt dich jetzt Dienste von einer Gruppe in eine andere bewegen. Klick einfach auf die drei Punkte bei einem Dienst und wähl bewegen aus.',
  ONBOARDING_EXISTING_HEALTHCHECK_HEADING: 'Gesundheitscheck',
  ONBOARDING_EXISTING_HEALTHCHECK_TEXT:
    'forghetti beinhaltet nun auch einen Gesundheitscheck, ein Feature der es dir ermöglicht aktiv deine Benutzernamen und Passwörter gegenüber bekannten Datenlecks zu überprüfen.',
  ONBOARIDNG_EXISTING_SHARING_HEADING: 'Schnelles Gruppen-Teilen',
  ONBOARDING_EXISTING_SHARING_TEXT:
    'Teile deine forghetti Gruppen mit Freunden, Familie und Arbeitskollegen durch einen einzigen Klick.',
  ONBOARDING_EXISTING_SWITCHING_HEADING: 'Schnelles Gruppe-Wechseln',
  ONBOARDING_EXISTING_SWTICHING_TEXT:
    'Mit deinen Gruppen arbeiten ist einfacher denn je. Neue Dienste einer Gruppe hinzufügen ist nun schneller und einfacher als zuvor.',
  ONBOARDING_EXISTING_PRICING_HEADING: 'Aktualisierte Preise',
  ONBOARDING_EXISTING_PRICING_TEXT: 'forghetti bietet dir nun mehr Abo-Optionen um deinen Bedarf abzudecken.',

  ACCOUNT_SECURITY_HEADER: 'Sicherheit',
  ACCOUNT_SECURITY_HEADING: 'Nichts ist sicherer',
  ACCOUNT_SECURITY_SECONDARY_HEADING:
    'Für uns ist Datensicherheit eine oberste Priorität. forghetti lässt dich Logins für mehrere Dienste mit einem einzigen Doodle generieren. Das Clevere an der Sache ist, dass in der forghetti Datenbank keine Passwörter gespeichert werden... kein einziges. Anders als alle anderen Passwort-Manager Apps, und es gibt einige, speichern wir nie deine Passwörter.  ',
  ACCOUNT_SECURITY_LEARN_MORE: 'Erfahre mehr über forghettis Sicherheitsmaßnahmen',

  MSG_FALLBACK_ERROR_MESSAGE: `Es ist ein Fehler aufgetreten! Das tut uns Leid.`,

  MSG_GROUP_ICON_UPDATED: 'Icon aktualisiert',
  MSG_COPIED_FORGHETTIBLE: (name) => `${name} wurde in die Zwischenablage kopiert`,
  MSG_OPEN_FORGHETTIBLE: `Klick hier, um zu öffnen`,
  MSG_DELETE_LOGIN_CONFIRM: 'Bist du dir sicher, dass du dieses Login löschen möchtest?',
  MSG_LOGIN_DELETED: (name) => `Login ${name} gelöscht`,
  MSG_SERVICE_ICON_DELETED: 'Dienst-Icon aktualisiert',
  MSG_LOGOUT_CONFIRM: 'Sicher, dass du dich abmelden möchtest?',
  MSG_LOGOUT_ALL_DEVICES_CONFIRM: 'Bist du dir sicher?',
  MSG_USER_PROMOTED_ADMIN: 'Benutzer wurde zum Administrator befördert.',
  MSG_USER_PROMOTED_OWNER: 'Besitz wurde übertragen.',

  MSG_CONFIRM_RESET: 'Bitte bestätige, dass du dieses Passwort zurücksetzen möchtest.',
  MSG_CONFIRM_RESTORE: 'Bitte bestätige, dass du diese Version wiederherstellen möchtest? ',
  MSG_NEW_GROUP_CREATED: (name) => `Neue Gruppe wurde erstellt: ${name}`,
  MSG_OPENING_WEBSITE: 'Webseite wird geöffnet...',
  MSG_REMOVED_ADMIN: 'Administrator-Status wurde diesem Benutzer entzogen',
  MSG_UNSAVED_CONFIRM: 'Es gibt noch ungespeicherte Änderungen. Bist du dir sicher, dass zu zurück möchtest?',

  EXTENSION_NONE_FOUND: 'Keine forghettibles für diese Webseite gefunden',
  EXTENSION_GO_TO_FORGHETTI: 'Zu forghetti',
  EXTENSION_NOACCESS_HEADER: 'Heute upgraden',
  EXTENSION_UPGRADE: `Du bist momentan Gratisbenutzer. Upgrade dein forghetti heute um die Chrome-Erweiterung zu nutzen und profitiere von unbegrenzten Logins und der Möglichkeit Gruppen zu erstellen und Logins mit Freunden, Familie und Arbeitskollegen zu teilen. Wenn du zu einem Premiumabo upgraden möchtest klick bitte den Upgrade Button.`,
  EXTENSION_NOLOGINFOUND_HEADER: `Nicht in forghetti gefunden`,
  EXTENSION_NOLOGINFOUND_MSG:
    'Kein forghettible für diese Website gefunden. Male unten ein Doodle um schnell ein neues forghettible für diese Webseite zu erstellen.',

  CONTEXT_EDIT_LOGIN_LIST_ITEM: 'Bearbeiten',
  CONTEXT_MOVE_LOGIN_LIST_ITEM: 'Bewegen',
  CONTEXT_ADD_LOGIN_LIST_ITEM: 'Forghettible hinzufügen',
  CONTEXT_RESET_LOGIN_LIST_ITEM: 'Forghettible zurücksetzen',
  CONTEXT_DELETE_LOGIN_LIST_ITEM: 'Löschen',
  CONTEXT_UPLOAD_GROUP_PHOTO: 'Foto aufnehmen',
  CONTEXT_UPLOAD_GROUP_IMAGE: 'Bild hochladen',
  CONTEXT_CHOOSE_GROUP_IMAGE: 'Bild auswählen',
  CONTEXT_TAKE_PROFILE_PIC: 'Foto aufnehmen',
  CONTEXT_UPLOAD_PROFILE_PIC: 'Foto hochladen',
  CONTEXT_UPLOAD_SERVICE_IMAGE: 'Bild hochladen',
  CONTEXT_CHOOSE_SERVICE_IMAGE: 'Bild auswählen',
  CONTEXT_SERVICE_IMAGE_WEBSEARCH: 'Das Netz durchsuchen',
  CONTEXT_MAKE_ADMIN: 'Zu Administrator machen',
  CONTEXT_MAKE_OWNER: 'Zu Besitzer machen',

  CONTEXT_REMOVE_FROM_GROUP: 'Aus Gruppe entfernen',
  CONTEXT_REVOKE_ADMIN: 'Administrator-Status entziehen',
  CONTEXT_NEW_GROUP_MEMBERS: 'Neue Gruppe Max Mitgliederanzahl',
  CONTEXT_SELECT_FIELD_TYPE: 'Typ auswählen',
  CONTEXT_FIELD_TYPE_LNS: 'Buchstaben, Nummern und Symbole',
  CONTEXT_FIELD_TYPE_WNS: 'Wörter, Nummern und Symbole',
  CONTEXT_FIELD_TYPE_LNX: 'Buchstaben, Nummern und komplexe Symbole',
  CONTEXT_FIELD_TYPE_WNX: 'Wörter, Nummern und komplexe Symbole',
  CONTEXT_FIELD_TYPE_LN: 'Buchstaben und Nummern',
  CONTEXT_FIELD_TYPE_WN: 'Wörter und Nummern',
  CONTEXT_FIELD_TYPE_L: 'Nur Buchstaben',
  CONTEXT_FIELD_TYPE_W: 'Nur Wörter',
  CONTEXT_FIELD_TYPE_N: 'Nur Nummern',
  CONTEXT_ADD_FIELD:
    'Wähle ein vordefiniertes Feld unten. Du kannst die Einstellungen für dieses Feld später anpassen.',
  CONTEXT_ADD_FIELD_PASS: 'Passwort (Buchstaben, Nummern, Symbole)',
  CONTEXT_ADD_FIELD_COMPLEX_PASS: 'fortgeschrittenes Passwort (Buchstaben, Nummern, komplexe Symbole)',

  CONTEXT_ADD_FIELD_PIN: 'PIN (Nur Nummern)',
  CONTEXT_ADD_FIELD_MEMWRD: 'merkbares Wort (nur Wörter)',
  CONTEXT_ADD_FIELD_FORGHETTIBLE: 'Nur Buchstaben',
  CONTEXT_LINK_EXPIRE_15MINS: '15 Minuten',
  CONTEXT_LINK_EXPIRE_30MINS: '30 Minuten',
  CONTEXT_LINK_EXPIRE_1HOUR: '1 Stunde',
  CONTEXT_LINK_EXPIRE_6HOURS: '6 Stunden',
  CONTEXT_LINK_EXPIRE_12HOURS: '12 Stunden',
  CONTEXT_LINK_EXPIRE_1DAY: '1 Tag',
  CONTEXT_LINK_EXPIRE_NEVER: 'Niemals',
  CONTEXT_LINK_EXPIRE_CANCEL: 'Abbrechen',
  CONTEXT_LINK_USES_1: 'Einmal',
  CONTEXT_LINK_USES_2: 'Zweimal',
  CONTEXT_LINK_USES_5: 'Fünfmal',
  CONTEXT_LINK_USES_10: 'Zehnmal',
  CONTEXT_LINK_USES_20: 'Zwanzigmal',
  CONTEXT_LINK_USES_50: 'Fünfzigmal',
  CONTEXT_LINK_USES_NO_LIMIT: 'Kein Limit',
  CONTEXT_LINK_USES_CANCEL: 'Abbrechen',

  FINGERPRINT_TITLE: 'Wilkommen bei forghetti',
  FINGERPRINT_DESCRIPTION: 'Scanne deinen Fingerabdruck um forghetti zu entsperren',
  FINGERPRINT_DESCRIPTION_FACE: 'Benutze Face-ID um forghetti zu entsperren',

  GENERATING_GROUP: 'Deine Gruppe generieren',
  GENERATING_PASSWORD: 'Dein Passwort generieren',
  DELETING_GROUP: 'Gruppe wird gelöscht',
  LOADING_SERVICE: 'Dein Dienst wird geladen',
  UNABLE_TO_PURCHASE: 'Premiumabo kann zur Zeit nicht gekauft werden. Bitte probier es später noch einmal.',

  HEALTHCHECK_WELCOME_HEADER: 'Gesundheitscheck',
  HEALTHCHECK_WELCOME_NOTE:
    'Forghetti Gesundheitscheck ausführen und erfahren ob deine Online-Daten in einem Datenleck kompromittiert wurden.',
  HEALTHCHECK_WELCOME_BUTTON: 'Gesundheitscheck ausführen',
  HEALTHCHECK_USERNAMES_HEADER: `Fangen wir mit deiner E-Mail-Adresse an`,
  HEALTHCHECK_USERNAMES_INFO_1: 'Deine E-Mail-Adresse überprüfen',
  HEALTHCHECK_USERNAMES_INFO_2:
    'Lass uns deine E-Mail-Adresse erfahren und wir überprüfen sie auf neue und vorherigen online Hacks',
  HEALTHCHECK_USERNAMES_PRIMARY_SUBHEAD: 'Primäre E-Mail-Adresse',
  HEALTHCHECK_USERNAMES_SECONDARY_SUBHEAD: 'Sekundäre E-Mail-Adresse',
  HEALTHCHECK_USERNAMES_PLACEHOLDER: 'E-Mail-Adresse',
  HEALTHCHECK_PASSWORD_HEADER: `Lass uns dein Passwort überprüfen`,
  HEALTHCHECK_PASSWORD_INFO_1: 'Benutzt du oft das gleiche Passwort?',
  HEALTHCHECK_PASSWORD_INFO_2: `Bevor du forghetti benutzt hast, hast du oft das gleiche Passwort verwendet?
Lass uns schauen ob es in einem Datenlack aufgedeckt wurde`,
  HEALTHCHECK_PASSWORD_INFO_3:
    'Forghetti speichert oder überträgt dein Passwort nicht. Wir werden umgehend einen sicheren Hash deines Passworts generieren. Nur ein Teil dieses Hashs wird von der App übertragen, sodass du sicher sein kannst, dass dein Passwort bei uns sicher aufgehoben ist.',

  HEALTHCHECK_PASSWORD_SUBHEAD: 'Dein Passwort',
  HEALTHCHECK_PASSWORD_OPTIONAL: 'Dies ist optional',
  HEALTHCHECK_PASSWORD_PLACEHOLDER: 'Passwort',
  HEALTHCHECK_PASSWORD_VAL_TOO_LONG: 'Passwort-Feld zu lang',

  HEALTHCHECK_RUNNING: 'Gesundheitscheck wird durchgeführt',
  HEALTHCHECK_USERNAME_VAL_FIELD_BLANK: 'E-Mail-Adresse kann nicht leer sein',
  HEALTHCHECK_USERNAME_VAL_FIELD_DUPLICATE: 'Sekundäre E-Mail-Adresse darf nicht identisch zur primären sein',
  HEALTHCHECK_USERNAME_VAL_FIELD_TOO_SHORT: 'E-Mail-Adresse zu kurz',
  HEALTHCHECK_USERNAME_VAL_FIELD_TOO_LONG: 'E-Mail-Adresse zu lang',

  HEALTHCHECK_ASSESSMENT_HEADER: 'Gesundheitscheck',
  HEALTHCHECK_ASSESSMENT_EMAIL: (email) => `<em>Sicherheitslücken für ${email}</em>`,
  HEALTHCHECK_ASSESSMENT_INFO_1: 'Die Ergebnisse des Gesundheitschecks siehst du unten.',
  HEALTHCHECK_ASSESSMENT_INFO_2:
    'Premiumbenutzer können die Sicherheitslücken klicken um mehr Informationen zu erhalten.',

  HEALTHCHECK_ASSESSMENT_PASSWORD: '<em><em>Ergebnisse für dein Passwort</em></em>',
  HEALTHCHECK_ASSESSMENT_COMPROMISED_HEAD: `Kompromittierte Dienste`,
  HEALTHCHECK_ASSESSMENT_NO_BREACH: 'Keine Sicherheitslücken auf diesem Benutzerkonto',
  HEALTHCHECK_ASSESSMENT_PASSWORD_STRENGTH: 'Sicherheit',
  HEALTHCHECK_ASSESSMENT_PASSWORD_WEAK: 'Schwach',
  HEALTHCHECK_ASSESSMENT_PASSWORD_STRONG: 'Sicher',
  HEALTHCHECK_ASSESSMENT_PASSWORD_COMP: 'taucht in einem Datenleck auf',
  HEALTHCHECK_ASSESSMENT_HIBP_LINK: 'Quelle: <a data-actionclick="openHIBP">haveIbeenpwned</a>',
  HEALTHCHECK_ASSESMENT_UPGRADE_MESSAGE: 'Details zu Sicherheitslücken sind nur für Premiumbenutzer einsehbar',
  HEALTHCHECK_DETAILS_PASSWORD_HEADER: 'Passwort-Ergebnisse',
  HEALTHCHECK_DETAILS_USER_HEADER: `Details zur Sicherheitslücke`,
  HEALTHCHECK_DETAILS_USER_TITLE: 'Name',
  HEALTHCHECK_DETAILS_USER_DOMAIN: 'Domain',
  HEALTHCHECK_DETAILS_USER_DATE: 'Datum der Sicherheitslücke',
  HEALTHCHECK_DETAILS_USER_ACCOUNTS_PWNED: 'Konten mit Sicherheitslücke',
  HEALTHCHECK_DETAILS_PASSWORD_INFO_X_BREACH: (breach) =>
    `Dein Passwort ist in <em>${breach}</em> bekannte Datenlecks gelistet. Dies bedeutet nicht unbedingt, dass deine Konten kompromittiert wurden. Du solltest dennoch in Betracht ziehen schnellstmöglich ein super sicheres Passwort mit <em>forghetti</em> zu generieren.`,
  HEALTHCHECK_DETAILS_PASSWORD_INFO_NO_BREACH: `Gute Neuigkeiten! Dein Passwort ist in keinen bekannten Datenlecks gelistet. Dies garantiert jedoch nicht, dass deine Konten nicht trotzdem kompromittiert sein können. Erwäge ein neues super sicheres Passwort mit <em>forghetti</em> zu generieren.`,
  HEALTHCHECK_DETAILS_PASSWORD_STRENGTH_INFO_STRONG: `Dein Passwort ist <em>sicher</em>. Dies bedeutet, dass es alle Kriterien im forghetti Passwort-Sicherheitstest erfüllt.`,
  HEALTHCHECK_DETAILS_PASSWORD_STRENGTH_INFO_WEAK: `Dein Passwort ist <em>schwach</em>. Dies bedeutet, dass ees folgende Kriterien im forghetti Passwort-Sicherheitstest nicht erfüllt:`,
  HEALTHCHECK_DETAILS_RESET_NOTE: 'Generiere jetzt ein super sicheres Passwort mit forghetti',
  HEALTHCHECK_DETAILS_RESET_BUTTON: 'Einen neuen Dienst erstellen',
  HEALTHCHECK_DETAILS_HIBP_LINK: 'Quelle: havelbeenpwned',
  HEALTHCHECK_LOADING_DETAIL: 'Details zu Sicherheitslücken werden geladen',

  IMPORT_SERVICE_WELCOME_BUTTON: 'Anfangen Dienste zu importieren',
  IMPORT_SERVICE_WELCOME_INFO: 'Deine vorhandenen Login-Details in forghetti importieren',
  IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_1: 'Um Dienste zu importieren, öffne bitte die forghetti Desktop App.',
  IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_2: 'Wenn du die forghetti Desktop App noch nicht hast, klick den Link unten',
  IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_BUTTON: 'Forghetti herunterladen',
  IMPORT_SERVICES_LIST_HEADER: 'Dienste zum Importieren auswählen',
  IMPORT_SERVICES_LIST_ERROR_NONE_SELECTED: 'Keine importierten Dienste ausgewählt',
  IMPORT_SERVICES_YOUR_IMPORTED_SERVICES: 'Deine importierten Dienste',
  IMPORT_SERVICES_LIST_NOTE: `Wähle die Dienste aus die du in forghetti importieren möchtest`,
  IMPORT_SERVICES_GROUP_NOTE: 'Wähle eine Gruppe aus in die du deine Dienste bewegen möchtest',
  IMPORT_SERVICES_GROUP_HEADER: 'Gruppe auswählen',
  IMPORT_SERVICES_USERNAME_HEADER: 'Benutzernamen eingeben',
  IMPORT_SERVICES_USERNAME_NOTE: 'Die Benutzernamen die mit jedem Benutzerkonto assoziiert sind überprüfen',
  IMPORT_SERVICES_CREATE_HEADER: 'Dienste erstellen',
  IMPORT_SERVICES_CREATE_BUTTON: 'Anfangen Dienste zu erstellen',
  IMPORT_SERVICES_CREATE_HOW_MANY: (number) => `<em>${number}</em> Dienste zu erstellen`,
  IMPORT_SERVICES_CREATE_LOADING: (current, total) => `<em>${current}</em> von <em> ${total}</em> Diensten importiert`,
  IMPORT_SERVICES_COMPLETE_HEADER: 'Import vollständig',
  IMPORT_SERVICES_COMPLETE_NOTE: `Glückwunsch! Deine Dienste wurden importiert`,
  IMPORT_FOLDER_SELECT_HEADER: 'Aus Chrome importieren',
  IMPORT_FOLDER_SELECT_CHROME: 'Dienste aus Chrome importiert',
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC: `forghetti wird auf die auf deinem Computer gespeicherten Login Daten zugreifen müssen`,
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_2: `Wenn du bereit bist, klick auf den 'deinen Chrome Ordner auswählen' Button unten. Ein Dialogfeld wird am Speicherort deiner Chrome Login-Daten angezeigt. Wähle einfach "Öffnen", um forghetti Zugriff auf diesen Ordner zu gewähren.`,
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_3:
    'Wenn du diesen Ordner manuell lokalisieren möchtest, gehe zu Libary/Application Support/Google/Chrome',
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_4:
    'Denk daran, deine vorhandenen Kennwörter werden nicht durch forghetti entschlüsselt. Forghetti ruft nur deine Login-Daten ab.',
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_5:
    'Wenn deine Login-Daten abgerufen sind, kannst du auswählen welche Dienste du in forghetti importieren möchtest.',

  IMPORT_FOLDER_SELECT_CHROME_BUTTON: 'Wähle deinen Chrome Ordner aus',

  IMPORT_GROUP_HEADER: 'Deine importiere Dienste-Gruppe',
  IMPORT_GROUP_NOTE: (importingServices) =>
    `Eine gesonderte Gruppe <em>importierte Dienste</em> wird für dich erstellt. Die Gruppe wird von dir importierte <em>${importingServices}</em> Dienste beinhalten.`,
  IMPORT_GROUP_NOTE_2:
    'Die Dienste in dieser Gruppe sind nicht aktiviert. Um die Dienste zu aktivieren, musst du die Dienste in eine andere Gruppe verschieben. Wenn du auf Weiter klickst, gelangst du zum Dienste-importieren Assistenten. Mit dem Assistenten für Dienste-importieren kannst du deine importierten Dienste schnell und einfach verschieben.',
  IMPORT_GROUP_NOTE_3:
    'Wenn du jetzt nicht die Zeit hast alle deine importierten Dienste zu bewegen, mach dir keine Sorgen. Du kannst deine importierten Dienste jederzeit und auf jedem Gerät über den Assistenten verwalten.',
  IMPORT_GROUP_BUTTON: 'Fortfahren',

  IMPORT_SERVICE_IMPORT_NODATA_HEADER: 'Login-Daten konnten nicht gefunden werden',
  IMPORT_SERVICE_IMPORT_NODATA_NOTE: 'forghetti hat keine Login-Daten zum Importieren gefunden',

  IMPORT_MOVE_SERVICE_HEADER: 'Beginne jetzt deine Dienste zu bewegen',
  IMPORT_MOVE_SERVICE_NOTE_1: (services) =>
    `Du hast ${services} in deiner importierte Dienste Gruppe. Um anzufangen deine importierten Dienste zu aktivieren musst du sie in einer andere Gruppe bewegen.`,
  IMPORT_MOVE_SERVICE_BUTTON: 'Fortfahren',
  IMPORT_SERVICE_LOADING: 'Deine Dienste werden importiert',
  IMPORT_SERVICE_GROUP_NAME: 'Dienste importieren',
  NEW_GROUP_REQUIRES_PREMIUM: 'Um eine neue Gruppe zu erstellen brauchst du eine Premiumabo ',

  IMPORT_TYPE_HEADER: 'Import-Typ auswählen',
  IMPORT_TYPE_NOTE_1: `Forghetti kann automatisch Dienste von deinen Google Chrome Login-Daten importieren.Wenn du Google Chrome nutzt und Dienste mit dieser Methode importieren möchtest, klick den Butoon 'Google Chrome importieren'.`,
  IMPORT_TYPE_BUTTON_1: `Google Chrome importieren`,
  IMPORT_TYPE_NOTE_2: `Alternativ kannst du Dienste importieren indem du ein CSV oder Excel (XLSX) Dokument hochlädst. Um mit diesem Feature Dienste zu importieren, wähle 'Dokument hochladen'.`,
  IMPORT_TYPE_BUTTON_2: 'Dokument hochladen',
  IMPORT_TYPE_NOTE_3: `Wenn du vorwiegend Safari verwendest, solltest du eventuell den 'Dokument hochladen' Feature verwenden um Dienste zu importieren. Um zu deinen Safari keychain Passwörtern zu gelangen, öffne zuerst 'Einstellungen' (command + ',') im Safari-Browser und dann den Reiter 'Passwörter/Kennwörter'.`,

  IMPORT_UPLOAD_HEADER: 'Dokument hochladen',
  IMPORT_UPLOAD_NOTE_1:
    'Ein CSV Dokument oder eine Arbeitsmappe (XLSX) hochladen um Dienste zu importieren. Als Minimum brauchst du den Namen eines Dienstes.',
  IMPORT_UPLOAD_BUTTON: 'Dein Dokument hochladen',
  IMPORT_UPLOAD_NOTE_2: 'Das Beispiel-CSV oder Excel herunterladen um zu beginnen.',
  IMPORT_UPLOAD_SAMPLE_CSV: 'Beispiel-CSV herunterladen',
  IMPORT_UPLOAD_SAMPLE_XLS: 'Beispiel-Excel herunterladen',

  // ===========================================================================
  // Hilfe Text
  // ===========================================================================

  //NO_LOGINS_MESSAGE: `Um zu starten, tippe 'Login hinzufügen' in der oberen rechten Ecke um ein Login, das du nutzt hinzuzufügen (z.B. Amazon, Facebook, deine Bank).

  NO_LOGINS_MESSAGE: `Dienste zu dieser Gruppe hinzufügen mit dem +(Plus) Icon.

Diese Gruppe mit Freunden, Kollegen oder Familie teilen mit dem Teilen Icon (oben rechts)

Du kannst den Namen dieser Gruppe ändern und verwalten wer Zugang zu der Gruppe hat.

`,

  LOGIN_RECENT: 'Zuletzt verwendet',
  HELP_LOGIN_TITLE:
    'Gib deinem Login einen Titel (z.B. Amazon). Füge ein Icon hinzu indem du auf die Kamera klickst. Wenn du kein Icon auswählst, wird ein Standard-Icon für dich erstellt.',
  HELP_LOGIN_USERNAME: `Gib den Benutzernamen ein, den du für das Login verwendest. Dies könnte zum Beispiel deine E-Mail-Adresse sein. Wenn du dies tust, kannst du's ganz einfach vom Login-Zussamenfassung Bildschirm kopieren und einfügen.`,
  HELP_LOGIN_WEBSITE:
    'Gib optional die Webadresse für dein Login ein. So können wir direkt vom Login-Zusammenfassung Bildschirm die Webseite öffnen.',
  HELP_LOGIN_ADD_FORGHETTIBLE: `Gib entweder ein Standardpasswort (der Default ist lang und komplex also brauchst du dies nicht anpassen), ein merkbares Wort oder eine PIN hier ein. Wenn du mehr Kontrolle möchtest, füge ein benutzerdefiniertes Forghettible hinzu.

Du musst mindestens ein Forghettible hinzufügen um fortzufahren. Dann tippe auf Weiter in der oberen rechten Ecke des Bildschirms.`,

  HELP_GROUPS_LIST_HEADER: `Klick auf das + Symbol um einer Gruppe beizutreten oder eine zu erstellen. Gratisbenutzer können nur Gruppen von zahlenden Abonnenten beitreten.

Du kannst Gruppe dazu nutzen verschiedene Kategorien von Logins zu verwalten oder eine Gruppe von Logins zu teilen.

Vergiss nicht das Gruppen Doodle zu teilen, damit alle Benutzer der Gruppe das gleiche Passwort generieren können. Erwäge also ein merkbares Wort, einen Satz oder einen Code zu verwenden, den man sich einfach merken kann.
`,

  HELP_GROUPS_LIST_PERSONAL: `Dies ist deine einzigartige persönliche Gruppe. Du kannst sie weder löschen noch mit anderen teilen.`,
  HELP_GROUPS_LIST_OTHER_GROUPS: `Dies sind alle anderen Gruppen, die du entweder als Preimumbenutzer selbst erstellt hast oder denen du auf Einladung eines anderen Benutzers beigetreten bist.`,

  HELP_DOODLEPAD: `Zeichne ein Doodle das du dir merken kannst. Verbundene Linien, Punkte oder eine Kombination aus beiden. Je mehr Stufen, desto sicherer. Du musst es dir jedoch merken, da es der Schlüssel ist mit dem du deine Passwörter ab jetzt und für immer generieren wirst.

Besorgt über neugierige Augen? Tippe auf 'Geheimes Doodle' und zeichne spurlos. Wenn du einen Fehler machst, kannst du auf 'Redoodle' von Neuem anfangen. Drücke dann auf das Häkchen, um dein Passwort zu generieren.`,

  HELP_SERVICE_SUMMARY: `Tippe das Auge um dein Passwort zu sehen. Tippe auf das Passwort um es in die Zwischenablage zu kopieren. Geh dann zur Webseite von diesem Dienst und ersetze dein altes Passwort mit diesem neuen.

Dann kannst du es vergessen. Das nächste Mal wenn du es brauchst, musst du nur dein Doodle zeichnen und forghetti generiert es erneut für dich. Es wird nirgends gespeichert, sondern mit deinem Doodle generiert wenn du es brauchst.
`,

  HELP_FIELD_SETTINGS_LENGTH: `Wähle die Länge des Forghettibles als die Anzahl Zeichen - für ein Standard-Passwort oder eine PIN - oder die Anzahl Wörter -für ein merkbares Wort.`,
  HELP_FIELD_SETTINGS_TYPE: `Wähle den Typ Forghettible als eine Kombination aus Buchstaben, Symbolen, Zahlen und Wörtern.`,
  HELP_FIELD_SETTINGS_NAME: `Gib einen deskriptiven Namen für dein forghetti Passwort an. Denk dran, es ist nicht das Passwort selbst, da dieses von forghetti automatisch generiert wird.`,
  HELP_FIELD_SETTINGS_RESET: `Falls du zu irgendeinem Zeitpunkt ein von forghetti generiertes Passwort ändern möchtest, klick einfach Zürucksetzen und das Passwort was von deinem Doodle generiert wird ändert sich beim nächsten Mal Zeichnen.`,
  HELP_RESTORE_PAGE: `Die Einstellungen für ältere Versionen eines forghetti Passworts können durch das Klicken auf das entsprechende Datum wiederhergestellt werden.

Wen du dies beim Zeichnen deines Doodles machst, wird dein altes Passwort generiert.

Du kannst, wie du magst, hin- und herwechseln!
`,
  HELP_LOGINS_LIST: `Klick auf ein Login um dein Passwort zu generieren.

Nutze den Suchen-Tool um ein Login zu finden.
`,
  HELP_GROUP_PAGE: `Scrolle zum Ende um die Gruppe zu löschen, um die Logins für die Gruppe einzublenden, oder um eine Gruppe zu verlassen in der du nicht mehr Mitglied sein möchtest.`,
  HELP_GROUP_SHARING: `Erstelle eine Einladung um mit Freunden oder Kollegen zu teilen.`,
  HELP_GROUP_MEMBERS: `Gruppenmitglieder-Berechtigungen, Administratorenrechte und Mitgliedschaften verwalten.`,
  HELP_GROUP_INVITE_MAX_USES: `Kontrolliere für wie lange und wie oft eine Einladung benutzt werden kann, bevor du sie an Freunde und Kollegen verschickst.`,

  // ===========================================================================
  // E-Mails
  // ===========================================================================

  REFERRAL_EMAIL_SUBJECT: 'Eine Einladung für forghetti, weil nichts sicherer ist',
  REFERRAL_EMAIL_BODY: (url) =>
    `Hallo,

Ich benutze eine tolle App namens forghetti. Sie hat mir geholfen meine Passwörter für immer zu vergessen. Deine Passwörter werden nirgends gespeichert.

Du kannst Passwörter generieren, wenn du sie brauchst, indem du ein Doodle zeichnest. Zeichne das gleiche Doodle und du kriegst jedes Mal das gleiche Passwort. So einfach, und trotzdem so clever.

Nutze diesen Empfehlungs-Link um dich anzumelden, und schalte erweiterte Funktionen für dich und für mich frei!
${url}

Viel Spaß!`,

  // ===========================================================================

  GRP_INVITE_EMAIL_SUBJECT: 'forghetti Gruppen-Einladung',
  GRP_INVITE_EMAIL_BODY: (inviteUrl, referralUrl, referralCode) =>
    `Hallo, ich möchte meine forghetti Gruppe mit dir teilen. Klick auf folgenden Link um forghetti zu öffnen und der Gruppe beizutreten:
${inviteUrl}

Falls du forghetti noch nicht hast, füge diesen Empfehlunscode ${referralCode} in dein Profil in der App ein und hole dir zusätzliche Premium Zeit für dich und für mich! Vergiss deine Passwörter, für immer!`,

  APPRATE_TITLE: 'Würdest du forghetti bitte bewerten',
  APPRATE_MESSAGE: `Es braucht nicht länger als eine Minute und hilft uns unsere App zu vermarkten. Vielen Dank für deine Unterstützung`,
  APPRATE_CANCEL: 'Nein, danke',
  APPRATE_LATER: 'Später erinnern',
  APPRATE_RATE: 'forghetti bewerten',
  APPRATE_YES: 'Ja',
  APPRATE_NO: 'Nicht wirklich',
  APPRATE_PROMPT_TITLE: 'Nutzt du forghetti gerne',
  APPRATE_FEEDBACK: 'Würdest du uns ein wenig Feedback geben?',

  BADGE_HEALTHCHECK_HEADER: 'Einen Gesundheitscheck durchführen',
  BADGE_HEALTHCHECK_INFO:
    'Hol dir diesen Badge indem du den forghetti Gesundheitscheck für Benutzernamen und Passwörter durchführst.',
  BADGE_HEALTHCHECK_COMPLETE_INFO:
    'Glückwunsch! Du hast dir diesen Badge verdient, indem du den Gesundheitscheck durchgeführt hast',
  BADGE_HEALTHCHECK_REWARD: (premium) => (premium ? 'Hol dir 2 extra Dienste für deine persönliche Gruppe' : ''),
  BADGE_HEALTHCHECK_CTA: 'Jetzt einen Gesundheitscheck durchführen',

  BADGE_RATING_HEADER: 'Forghetti bewerten',
  BADGE_RATING_INFO: 'Hol dir diesen Badge indem du forghetti bewertest',
  BADGE_RATING_COMPLETE_INFO: 'Glückwunsch! Du hast dir diesen Badge verdient, indem du forghetti bewertet hast!',
  BADGE_RATING_REWARD: (premium) => (premium ? 'Hol dir 2 extra Dienste für deine persönliche Gruppe' : ''),
  BADGE_RATING_CTA: 'Forghetti jetzt bewerten',

  BADGE_PROFILECOMPLETE_HEADER: 'Vervollständige dein Profil',
  BADGE_PROFILECOMPLETE_INFO: 'Hol dir diesen Badge, indem du dein Profil vervollständigst',
  BADGE_PROFILECOMPLETE__COMPLETE_INFO:
    'Glückwunsch! Du hast dir diesen Badge verdient, indem du dein Profil vervollständigt hast',
  BADGE_PROFILECOMPLETE_REWARD: (premium) => (premium ? 'Hol dir 2 extra Dienste für dein persönliche Gruppe' : ''),
  BADGE_PROFILECOMPLETE_CTA: 'Jetzt dein Profil vervollständigen',

  BADGE_SHARING_HEADER: 'Forghetti auf Facebook teilen',
  BADGE_SHARING_INFO: 'Hol dir diesen Badge, indem du forghetti auf Facebook teilst',
  BADGE_SHARING_COMPLETE_INFO:
    'Glückwunsch! Du hast dir diesen Badge verdient, indem du forghetti auf Facebook geteilt hast',
  BADGE_SHARING_REWARD: (premium) => (premium ? 'Hol dir 2 extra Dienste für dein persönliche Gruppe' : ''),
  BADGE_SHARING_CTA: 'Forghetti auf Facebook teilen',

  BADGE_IMPORT_HEADER: 'Dienste importieren',
  BADGE_IMPORT_INFO: 'Hol dir diesen Badge, indem du Dienste von deinem Handy oder PC keychain importierst',
  BADGE_IMPORT_COMPLETE_INFO:
    'Glückwunsch! Du hast dir diesen Badge verdient, indem du Dienste von deinem Handy oder PC keychain importiert hast',
  BADGE_IMPORT_REWARD: (premium) => (premium ? 'Hol dir 2 extra Dienste für dein persönliche Gruppe' : ''),
  BADGE_IMPORT_CTA: 'Jetzt Dienste importieren ',

  BADGE_SUPERUSER_HEADER: 'Ein Super-Benutzer werden',
  BADGE_SUPERUSER_INFO: `Hol dir diesen Badge, indem du forghetti erflogreich mit 5 Freunden teilst, du mehr als 2 Gruppen besitzt, Premiumbenutzer bist und mehr als 50 Dienste in der App hast.`,
  BADGE_SUPERUSER_COMPLETE_INFO: `Glückwunsch! Du hast dir diesen Badge verdient, indem du forghetti erfolgreich mit 5 Freunden geteilt hast, mehr als 2 Gruppen besitzt und als Premiumbenutzer mehr als 50 Dienste hinzugefügt hast.`,
  BADGE_SUPERUSER_REWARD: (premium) => (premium ? 'Hol dir 2 extra Dienste für dein persönliche Gruppe' : ''),
  BADGE_SUPERUSER_CTA: 'Forghetti mit einem Freund teilen.',

  BADGE_SERVICES_50_HEADER: '50 Dienste!',
  BADGE_SERVICES_50_INFO: 'Hol dir diesen Badge, indem du 50 Dienste in deinem forghetti Benutzerkonto hinzufügst.',
  BADGE_SERVICES_50_COMPLETE_INFO:
    'Glückwunsch! Du hast dir diesen Badge verdient, indem du 50 Dienste in deinem forghetti Benutzerkonto hinzugefügt hast.',
  BADGE_SERVICES_50_REWARD: (premium) => (premium ? 'Hol dir 2 extra Dienste für dein persönliche Gruppe' : ''),
  BADGE_SERVICES_50_CTA: 'Jetzt einen Dienst hinzufügen',

  BADGE_SERVICES_150_HEADER: '150 Dienste!',
  BADGE_SERVICES_150_INFO: 'Hol dir diesen Badge, indem du 150 Dienste in deinem forghetti Benutzerkonto hinzufügst.',
  BADGE_SERVICES_150_COMPLETE_INFO:
    'Glückwunsch! Du hast dir diesen Badge verdient, indem du 150 Dienste in deinem forghetti Benutzerkonto hinzugefügt hast.',
  BADGE_SERVICES_150_REWARD: (premium) => (premium ? 'Hol dir 2 extra Dienste für dein persönliche Gruppe' : ''),
  BADGE_SERVICES_150_CTA: 'Jetzt einen Dienst hinzufügen',

  BADGE_SERVICES_300_HEADER: '300 Dienste!',
  BADGE_SERVICES_300_INFO: 'Hol dir diesen Badge, indem du 300 Dienste in deinem forghetti Benutzerkonto hinzufügst.',
  BADGE_SERVICES_300_COMPLETE_INFO:
    'Glückwunsch! Du hast dir diesen Badge verdient, indem du 300 Dienste in deinem forghetti Benutzerkonto hinzugefügt hast.',
  BADGE_SERVICES_300_REWARD: (premium) => (premium ? 'Hol dir 2 extra Dienste für dein persönliche Gruppe' : ''),
  BADGE_SERVICES_300_CTA: 'Jetzt einen Dienst hinzufügen',

  BADGE_SERVICES_500_HEADER: '500 Dienste!',
  BADGE_SERVICES_500_INFO: 'Hol dir diesen Badge, indem du 500 Dienste in deinem forghetti Benutzerkonto hinzufügst.',
  BADGE_SERVICES_500_COMPLETE_INFO:
    'Glückwunsch! Du hast dir diesen Badge verdient, indem du 500 Dienste in deinem forghetti Benutzerkonto hinzugefügt hast.',
  BADGE_SERVICES_500_REWARD: (premium) => (premium ? 'Hol dir 2 extra Dienste für dein persönliche Gruppe' : ''),
  BADGE_SERVICES_500_CTA: 'Jetzt einen Dienst hinzufügen',

  BADGE_SHARING_1_HEADER: 'Forghetti mit einem Freund teilen',
  BADGE_SHARING_1_INFO:
    'Hol dir diesen Badge, indem du forghetti mit einem Freund teilst und sie sich ein Benutzerkonto einrichten!',
  BADGE_SHARING_1_COMPLETE_INFO:
    'Glückwunsch! Du hast dir diesen Badge verdient, wenn du forghetti mit einem Freund geteilt hast, und sie sich ein Benutzerkonto eingerichtet haben!',
  BADGE_SHARING_1_REWARD: (premium) => (premium ? 'Hol dir 2 extra Dienste für dein persönliche Gruppe' : ''),
  BADGE_SHARING_1_CTA: 'Forghetti mit einem Freund teilen',

  BADGE_SHARING_5_HEADER: 'Forghetti mit 5 Freunden teilen',
  BADGE_SHARING_5_INFO:
    'Hol dir diesen Badge, indem du forghetti mit 5 Freunden teilst und sie sich ein Benutzerkonto einrichten!',
  BADGE_SHARING_5_COMPLETE_INFO:
    'Glückwunsch! Du hast dir diesen Badge verdient, wenn du forghetti mit 5 Freunden geteilt hast und sie sich ein Benutzerkonto eingerichtet haben.',
  BADGE_SHARING_5_REWARD: (premium) => (premium ? 'Hol dir 2 extra Dienste für dein persönliche Gruppe' : ''),
  BADGE_SHARING_5_CTA: 'Forghetti mit einem Freund teilen',

  BADGE_SHARING_10_HEADER: 'Forghetti mit 10 Freunden teilen',
  BADGE_SHARING_10_INFO:
    'Hol dir diesen Badge, indem du forghetti mit 10 Freunden teilst und sie sich ein Benutzerkonto einrichten!',
  BADGE_SHARING_10_COMPLETE_INFO:
    'Glückwunsch! Du hast dir diesen Badge verdient, wenn du forghetti mit 10 Freunden geteilt hast und sie sich ein Benutzerkonto eingerichtet haben.',
  BADGE_SHARING_10_REWARD: (premium) => (premium ? 'Hol dir 2 extra Dienste für dein persönliche Gruppe' : ''),
  BADGE_SHARING_10_CTA: 'Forghetti mit einem Freund teilen',

  BADGE_SHARING_25_HEADER: 'Forghetti mit 25 Freunden teilen',
  BADGE_SHARING_25_INFO:
    'Hol dir diesen Badge, indem du forghetti mit 25 Freunden teilst und sie sich ein Benutzerkonto einrichten!',
  BADGE_SHARING_25_COMPLETE_INFO:
    'Glückwunsch! Du hast dir diesen Badge verdient, wenn du forghetti mit 25 Freunden geteilt hast und sie sich ein Benutzerkonto eingerichtet haben.',
  BADGE_SHARING_25_REWARD: (premium) => (premium ? 'Hol dir 2 extra Dienste für dein persönliche Gruppe' : ''),
  BADGE_SHARING_25_CTA: 'Forghetti mit einem Freund teilen',

  BADGE_SHARING_50_HEADER: 'Forghetti mit 50 Freunden teilen',
  BADGE_SHARING_50_INFO:
    'Hol dir diesen Badge, indem du forghetti mit 50 Freunden teilst und sie sich ein Benutzerkonto einrichten!',
  BADGE_SHARING_50_COMPLETE_INFO:
    'Glückwunsch! Du hast dir diesen Badge verdient, wenn du forghetti mit 50 Freunden geteilt hast und sie sich ein Benutzerkonto eingerichtet haben.',
  BADGE_SHARING_50_REWARD: (premium) => (premium ? 'Hol dir 2 extra Dienste für dein persönliche Gruppe' : ''),
  BADGE_SHARING_50_CTA: 'Forghetti mit einem Freund teilen',

  BADGE_GROUP_1_HEADER: 'Eine neue Gruppe in forghetti erstellen',
  BADGE_GROUP_1_INFO: 'Hol dir diesen Badge, indem du in forghetti eine neue Gruppe erstellst und teilst',
  BADGE_GROUP_1_COMPLETE_INFO:
    'Glückwunsch! Du hast dir diesen Badge verdient, wenn du in forghetti eine neue Gruppe erstellt und geteilt hast.',
  BADGE_GROUP_1_REWARD: (premium) => (premium ? 'Hol dir 2 extra Dienste für dein persönliche Gruppe' : ''),
  BADGE_GROUP_1_CTA: 'Jetzt eine Gruppe erstellen',

  BADGE_GROUP_5_HEADER: '5 neue Gruppe in forghetti erstellen',
  BADGE_GROUP_5_INFO: 'Hol dir diesen Badge, indem du in forghetti 5 neue Gruppe erstellst und teilst',
  BADGE_GROUP_5_COMPLETE_INFO:
    'Glückwunsch! Du hast dir diesen Badge verdient, wenn du in forghetti 5 neue Gruppe erstellt und geteilt hast.',
  BADGE_GROUP_5_REWARD: (premium) => (premium ? 'Hol dir 2 extra Dienste für dein persönliche Gruppe' : ''),
  BADGE_GROUP_5_CTA: 'Jetzt eine Gruppe erstellen',

  BADGE_LEGACY_HEADING: `Du bist ein Superstar!`,
  BADGE_LEGACY_INFO_COMPLETE: `Vielen Dank, dass du schon so lange forghetti nutzt! Als kleines Dankeschön erhältst du diesen besonderen Badge und bekommst 3 Monate lang unser Premiumabo geschenkt!`,

  JOIN_GORUP_ALERT_MESSAGE: `Wir fügen dich gerade dieser Gruppe hinzu. Wir sagen dir Bescheid, wenn wir fertig sind.`,
  GROUP_UNAVAILABLE: (groupName) =>
    `Die Anzahl gratis Forghettibles für <em>${groupName}</em> wurde überschritten. Um weitere Dienste hinzuzufügen, erwäge noch heute zu upgraden.`,
  GROUP_PERSONAL_UNAVAILABLE: (groupName) =>
    `Die Anzahl gratis Forghettibles für deine persönliche Gruppe wurde überschritten. Um weitere Dienste hinzuzufügen, erwäge noch heute zu upgraden.`,
  GROUP_NONE_AVAILABLE: 'All deine Gruppen haben das zulässige Limit überschritten',

  SHARE_INVITE_USERNAME_HEADING: 'Bevor du anfängst zu teilen',
  SHARE_INVITE_USERNAME_INFO:
    'Damit deine Freunde die Gruppen identifizieren können, die tu teilen möchtest, gib bitte einen Profilnamen für dein Benutzerkonto an.',
  SHARE_INVITE_USERNAME_PLACEHOLDER: 'Dein Name',

  LEGACY_USER_ONBOARDING_HEADING: 'Wir schätzen deine Treue',
  LEGACY_USER_ONBOARDING_INFO:
    'Als Belohnung schenken wir dir 3 Monate Premiumabo komplett kostenlos! Wir hoffen dir gefällt die neue forghetti Version noch besser als die vorherige.',
  CLAIM_PREMIUM: 'Deine Belohnung abholen',
  LEGACY_USER_RELOAD:
    'Um das Premiumabo anzuwenden, müssen wir forghetti neu laden. Dies passiert in ein paar Sekunden automatisch.',

  SERVICE_OPEN_ICON_LABEL: 'öffnen',
  SERVICE_VIEW_ICON_LABEL: 'anzeigen',
  SERVICE_HIDE_ICON_LABEL: 'ausblenden',
  SERVICE_WEBSITE_ICON_LABEL: 'öffnen',
  SERVICE_COPY_ICON_LABEL: 'kopieren',

  GROUPSLIST_SWITCH_LABEL: 'wechseln',
  GROUPSLIST_SHARE_LABEL: 'einladen',
  GROUPSLIST_SETTINGS_LABEL: 'Einstellungen',

  FORGHETTI_GROUP_PERMISSIONS: `Klick diesen <a data-actionclick="openPermissionsLink">Link</a> um mehr über Gruppen-Berechtigungen rauszufinden.`,

  SUBSCRIPTION_TANDC: `Bitte beachten, dass dein Abonnement automatisch verlängert wird, sofern es nicht vor Ablauf des aktuellen Zeitraums gekündigt wird. Du kannst dein Abonnement jederzeit verwalten und kündigen - wodurch die automatische Verlängerung deaktiviert wird -, indem du nach dem Kauf auf dein App Store-Konto zugreifst. Weitere Informationen findest du in unseren <a data-actionclick="openTermsOfServiceUrl">Nutzungsbedingungen</a> und in unseren <a data-actionclick="openPrivacyPolicyUrl">Datenschutzbestimmungen</a>`,
  SUBSCRIPTION_TANDC_LIFETIME: `Bitte beachte, dass ein lebenslanges forghetti Premiumabo dir folgendes bietet: ... Weitere Informationen findest du in unseren  <a data-actionclick="openTermsOfServiceUrl">Nutzungsbedingungen</a> und in unseren <a data-actionclick="openPrivacyPolicyUrl">Datenschutzbestimmungen</a>`,
  SUBSCRIPTION_ERROR: 'Beim validieren deiner Abos ist ein Fehler aufgetreten. Bitte versuch es später erneut.',

  SUBSCRIPTION_TANDC_HEADER: 'Dein Abo',
  SERVICE_INJECT_LABEL: 'Benutzen',
  PHONE_NUMBER_TOO_LONG: 'Ungültige Mobiltelefonnummer',

  ADD_SERVICE_LOADING_PLEASE_WAIT: 'Bitte warte, während wir deinen forghetti Dienst generieren',
  ADD_SERVICE_LOADING_GENERATING_SERVICE: 'Dein neuer Dienst wird generiert',
  ADD_SERVICE_LOADING_GENERATING_PASSWORD: 'Ein super sicheres Passwort wird generiert',
  ADD_SERVICE_LOADING_LOADING_SERVICE_DETAILS: 'Dein neuer forghetti Dienst wird geladen',

  VIEW_SERVICE_LOADING_LOADING_SERVICE_DETAIL: 'Bitte warte, während wir deinen Dienst laden',
  VIEW_SERVICE_LOADING_LOADING_SERVICE_FORGHETTIBLE: 'Deine Forghettible-Daten werden aufgebaut',

  MIGRATION_ALERT_HEADING: 'Dein Benutzerkonto übertragen',
  MIGRATION_ALERT_MESSAGE: `Um deine Mobiltelefonnummer zu wechseln, nutze bitte unsere Web-App unter <a href="https://app.forghetti.com" target="_new">https://app.forghetti.com</a>.`,
  DELETE_ALERT_HEADING: 'Dein Benutzerkonto löschen',
  DELETE_ALERT_MESSAGE: `Um dein Benutzerkonto zu löschen, nutze bitte unsere Web-App unter <a href="https://app.forghetti.com" target="_new">https://app.forghetti.com</a>.`,
  REFERRAL_CODE_UPDATE_NOT_ALLOWED: `Es wurde bereits ein Empfehlungscode für dein Benutzerkonto verwendet`,
  REFERRAL_CODE_APPLIED: (code) => `Der Empfehlunscode <em>${code}</em> wurde für dein Benutzerkonto verwendet.`,
  INVALID_PHONE_NUMBER: 'Die Mobiltelefonnummer ist ungültig. Bitte versuche es erneut.',
  INVALID_ACTIVATION_CODE: 'Der Aktivierungscode ist ungültig.',
  INVALID_TOO_MANY_ATTEMPTS: `Es wurde zu oft versucht sich mit dieser Mobiltelefonnummer anzumelden. Bitte versuche es später erneut.`,

  REONBOARDING_POPUP_GO_AGAIN: `Wir haben die Art wie du forghetti benutzt verbessert. Möchtest du die Funktionsweise näher kennenlernen und rausfinden was forghetti so toll macht?`,
  REONBOARDING_POPUP_BUTTON_GO: `Zeig es mir`,
  REONBOARDING_POPUP_BUTTON_CANCEL: 'Nein danke',
  REONBOARDING_POPUP_HEADING: `Das neue forghetti 2.0`,
  HELP_QUICKSTART: 'Schnellstart onboarding',
  YOUR_BADGES: 'Deine Badges',
  GROUP_NOT_OWNER: 'Du kannst Dienste nur in eine Gruppe bewegen, die du selbst besitzt.',
  GROUP_NOT_OWNER_TITLE: 'Dienste werden bewegt',
  VIEWSERVICE_NOT_COPIED_TO_CLIPBOARD: `<span class="toast--title">Du wirst dein neues Passwort brauchen. </span><span class="toast--text">Klick den Kopieren Button im Passwort-Feld um dein neues Passwort in die Zwischenablage zu legen.</span>`,
  EXTENSION_ATTEMPT: (website) =>
    `<strong class="toast--title">Durchsuche Logins nach </strong><span class="toast--clipboard-item">${website}</span>`,
  EXTENSION_ATTEMPT_FAIL: (website) =>
    `<span class="toast--title"><span class="toast--title">Kein passenden Logins gefunden</span></span>`,

  UNIVERSAL_LINK_NEW_LOGIN: (url) => `Versuch einen passenden Dienst für ${url} zu finden`,
  UNIVERSAL_LINK_NEW_LOGIN_NO_URL: `Der Assistent für neue Dienste wird gestartet`,

  CONFIRM_COUNTRY_UPDATE_HEADER: 'Deine Sprache wechseln',
  CONFIRM_COUNTRY_UPDATE_NOTE:
    'Um deine Spracheinstellung anzupassen, drück den Button unten um zu speichern und forghetti neu zu laden.',
  CONFIRM_BUTTON: 'Forghetti neu laden',

  FORGHETTI_LOGIN: 'forghetti',
  FORGHETTI_LOGIN_TAGLINE: 'Vergiss deine Passwörter. Für immer.',
  FORGHETTI_LOGIN_CREATE: 'Ein Benutzerkonto erstellen',
  FORGHETTI_LOGIN_ALREADY_HAVE: 'Ich habe bereits ein Benutzerkonto',

  MUST_BE_LOGGED_IN_ACTION: 'Diese Aktion ist nur verfügbar, wenn Du angemeldet bist',
  UPDATING_FORGHETTIBLE: 'Dein Forghettible wird aktualisiert',
  FORGHETTI_ON_MAC: `Forghetti Desktop ist für Mac verfügbar. <a data-actionclick="openForghettiCom">Hol es dir hier.</a>`,
  FORGHETTI_ON_WINDOWS: `Forghetti Desktop ist für Windows verfügbar. <a data-actionclick="openForghettiCom">Hol es dir hier.</a>`,

  REALTIME_NEW_SERVICE_FOR_GROUP: (group) => `Ein neuer Dienst wurde der geteilten Gruppe ${group} hinzugefügt`,
  REALTIME_REMOVE_SERVICE_FOR_GROUP: (group) => `Ein Dienst wurde aus der geteilten Gruppe ${group} entfernt`,
  REALTIME_KICK_SERVICE_FOR_GROUP: (group) => `Du wurdest aus der Gruppe ${group} entfernt`,
  REALTIME_BAN_SERVICE_FOR_GROUP: (group) => `Du wurdest aus der Gruppe ${group} verbannt`,
  REALTIME_JOIN_SERVICE_FOR_GROUP: (group) => `Ein neuer Benutzer ist ${group} beigetreten`,
  REALTIME_REMOVE_GROUP: (group) => `Die Gruppe ${group} wurde vom Eigentümer entfernt`,

  PERSONAL_GROUP: 'Persönliche',
  SHAREABLE_GROUP: 'Teilbare Gruppe',
  IMPORT_MENU: 'Dienste importieren',
  IMPORT_GROUP: 'importierte Dienste',

  MONTHS: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],

  AND: 'Und',
  NEW_GROUP: 'Neue Gruppe',
  LETS_START_SELECT: 'Beginnen wir zunächst einen oder mehrere dieser beliebten Dienste ...',
  CREATE_SERVICES: 'Dienste erstellen',
  CREATING_SERVICES: 'Wir generieren jetzt Ihren Services',
  MUST_SELECT_MORE_THAN_ONE_SERVICES: 'Bitte wählen Sie einen oder mehrere Dienste aus',
  ADD_NOTE: 'Neue Notiz hinzufügen',
  EDIT_NOTE: 'Notiz bearbeiten',
  NOTE: 'Anmerkungen',
  NOTE_COPIED: 'Hinweis in die Zwischenablage kopiert',
  DELETE_NOTE: 'Notiz löschen',

  FORGHETTI_LOGIN_TAGLINE: 'Vergessen Sie Ihre Passwörter.',
  FORGHETTI_LOGIN_TAGLINE_2: 'Für immer.',
  CREATE_ACCOUNT_1_HEADER_2: 'Erstellen Sie Ihren Forghetti -Account.',

  CREATE_ACCOUNT_2_HEADING: 'Geben Sie Ihren Bestätigungs-Code ein.',
  CREATE_ACCOUNT_FINAL_BUTTON: 'Zeig mir wie',

  ONBOARDING_DOODLETUTORIAL2_HEADING_2: 'Zeichnen ein Muster',
  ONBOARDING_DOODLETUTORIAL3_HEADING_2: 'Mehrere Zeilen',
  ONBOARDING_DOODLETUTORIAL4_HEADING_2: 'Punkte auch ...',
  ONBOARDING_DOODLETUTORIAL5_HEADING_2: 'Oder alle zusammen',
  ONBOARDING_DOODLETUTORIAL6_HEADING_2: 'Aber denken Sie daran, die Bestellung ist wichtig!',
  ONBOARDING_DOODLETUTORIAL7_HEADING_2: 'Doodle A,',
  ONBOARDING_DOODLETUTORIAL8_HEADING_2: 'Doodle A ist nicht dasselbe wie Doodle B',
  SECURE_DOODLE_TUTORIAL: 'Sichern Sie das Doodle -Tutorial',
  DOODLE_TRY_AGAIN: 'Versuchen Sie es erneut',
  WELCOME_TO_FORGHETTI: 'Willkommen bei Forghetti',

  ONBOARDING_COMPLETE_CONGRATULATIONS: `Glückwunsch! Sie haben gerade die ersten Schritte unternommen, um Ihre Online -Identität zu sichern.`,

  ONBOARDING_COMPLETE_CREATE_FORGHETTI: 'Erstellen Sie einen Forghetti -Account.',
  ONBOARDING_COMPLETE_SIGNATURE_DOODLE: 'Erstellen Sie Ihr Signature -Doodle.',

  ONBOARDING_COMPLETE_RESET_PASSWORD: 'Setzen Sie ein unsicheres Passwort zurück.',

  ONBOARDING_COMPLETE_HEALTHCHECK: 'Führen Sie eine Gesundheitsprüfung auf Ihren Online -Konten durch.',

  ONBOARDING_COMPLETE_IMPORT: 'Importdienste',
  ONBOARDING_COMPLETE_IMPORT_BUTTON: 'Importdienste',
  ONBOARDING_COMPLETE_REMIND_LATER: 'Erinnere mich später',
  ONBOARDING_COMPLETE_HEALTHCHECK_BUTTON: 'Führen Sie einen Gesundheitscheck durch',

  USERNAME_OR_EMAIL: 'Nutzername',
  USERNAME_OR_EMAIL_SECOND: 'Email',
  SERVICE_EDIT_USERNAME_PLACEHOLDER: 'Geben Sie ein anderes Anmeldedetail ein',

  SERVICE_USERNAME_LABEL: 'Nutzername',
  SERVICE_USERNAME_SECOND_LABEL: 'Email',

  ADD_SERVICE_USERNAME_PLACEHOLDER: `Nutzername`,
  SERVICE_USERNAME_PLACEHOLDER_SECOND: `Email`,
  FREE_USER_UPGRADE: 'Auf Premium upgraden',
  LOGIN_ACCOUNT_1_HEADER_2: 'Melden Sie sich bei Ihrem Forghetti -Konto an.',
  KEYCHAIN_HEADING: 'Speichern Sie Ihr Kritzelei',
  KEYCHAIN_MESSAGE:
    'Sie können Ihr Doodle sicher auf diesem Gerät speichern, um die Generierung Ihrer Passwörter noch einfacher zu machen.',
  KEYCHAIN_MESSAGE_2: 'Möchten Sie Ihr Doodle auf diesem Gerät speichern?',
  KEYCHAIN_MESSAGE_3: 'Sie können dies jederzeit in Ihren Einstellungen ändern.',
  KEYCHAIN_YES: 'Ja, rette mein Kritzelei',
  KEYCHAIN_NO: 'Nein, ich werde jedes Mal mein Doodle zeichnen',

  LOADING_VAULT: 'Laden Sie Ihr Gewölbe',
  ONBOARDING_COMPLETE_FIRST_LOGIN: 'Fügen Sie einen Service hinzu.',
  ONBOARDING_COMPLETE_CONTINUE: `Setzen Sie nun ein Passwort zurück`,
  STILL_LOADING: 'Wir laden Ihr Konto',
  STILL_LOADING_2: 'Dein Account wurde verifiziert',

  CREATE_ACCOUNT_VERIFYING_PHONE: 'Wir überprüfen Ihre Nummer',
  NOTIFICATION_MESSAGE_BAR: `Klicken Sie in einem Dienst auf "Verwenden", um Ihr Passwort an {{service}} zu senden`,
  NO_NOTIFICATIONS_HELLO_TEXT: (name) => `Hallo ${name}! Sie haben noch keine Benachrichtigungen.`,
  NO_NOTIFICATIONS_CHECK: 'Schauen Sie sich die Aktualisierung wieder an.',
  REPLAY_DOODLE_HEADING: 'Wiederholung',
  FORGHETTIBLE: 'Forghettible',
  SERVICE_NO_NOTE: 'Keine Anmerkung',
  SERVICE_NOTE: 'Notiz',
  SERVICE_BAD_NOTE: 'Um diese Notiz zu entsperren, zeichnen Sie das Kritzelei neu',
  SERVICE_NAME: 'Dienstname',
  REPLAY_DOODLE: 'Replay Doodle',
  ENABLE_BIOMETRICS: 'Biometrie aktivieren',
  DISABLE_BIOMETRICS: 'Biometrie deaktivieren',
  WEB_DELAY:
    'Erstellen einer Verbindung und Laden Sie Ihre Details. Wenn dies zu lange dauert, überprüfen Sie bitte Ihre Verbindung.',
  WEB_WAITING: 'Arbeitet immer noch, bitte warte ...',
  PROFILE_MIGRATE_2_INFO_2: 'Geben Sie nun den Bestätigungscode ein, den Sie auf Ihrem alten Telefon erhalten:',
  MIGRATE: 'Wandern',
  MONTHLY: 'Monat',
  YEARLY: 'Jahr',
  UNLOCK_BIOMETRICS: 'Mit Biometrie freischalten',
  UNLOCK_DESCRIPTION: 'Schalten Sie Ihren Forghetti -Account mit Biometrie frei',
  UNLOCK_SERVICE: (service) => `Schalte ${service} frei`,
  UNLOCK_SERVICE_DESCRIPTION: (service) =>
    `Um ${service} zu entsperren, verwenden Sie Biometrie oder zeichnen Sie Ihr Kritzeland neu neu`,
  UNLOCK_FALLBACK: 'Verwenden Sie Doodle',
  UNLOCK_CANCEL: 'Stornieren',
  RATE_US: 'Bewerten Sie uns',
  SETUP_NEW_SERVICE_MESSAGE: `Lassen Sie uns Ihren neuen Service einrichten, indem Sie einige Details angeben.`,
  LOGIN_ACCOUNT_1_MSG: `Bitte geben Sie Ihre Handynummer ein, um einen einmaligen Überprüfungscode per SMS zu erhalten.`,
  LETS_START_SELECT_COUNT: (count) =>
    `Beginnen wir mit der Auswahl dieser beliebten Dienste ... Sie können <em data-count>${count}</em> mehr auf der kostenlosen Stufe auswählen`,
  TOAST_TOO_MANY_SERVICES_SELECTED: (count) =>
    `Sie haben zu viele Dienste ausgewählt. Bitte wählen Sie ein Maximum von ${count}-Diensten.`,
  LETS_START_ADDING_DETAILS: `Fügen wir weitere Details hinzu`,
  ONBOARDING_COMPLETE_NEXT_STEPS: `<h2>Warum nun nicht::</h2><ul><li>Führen Sie eine Gesundheitsprüfung auf Ihren Online -Konten durch?</li><li>Oder aktualisieren Sie einen weiteren Dienst auf Forghetti.</li><li> Oder schauen Sie sich einfach um ...</li></ul>`,
  ONBOARDING_COMPLETE_CONGRATULATIONS: `Glückwunsch! Sie haben gerade die ersten Schritte unternommen, um Ihre Online -Identität zu sichern.`,
  CREATE_ACCOUNT_1_MSG_4: `Hinweis: Wir werden Ihre Nummer immer nur verwenden, um Ihr Forghetti -Konto zu erstellen/zu unterstützen. Sie erhalten einen einmaligen Geräteverifizierungscode.`,
  MOVE_SERVICE_CONFIRM_NOTE_UPDATE: (fromGroup, toGroup, list, listLength) =>
    `${listLength > 1 ? 'Neu' : 'ein neuer'} <em>${list}</em> forghettible${
      listLength > 1 ? 's' : ''
    }  wird von ${toGroup} Ihr Doodle generiert. Wenn Sie auf Ihr altes Forghettible zugreifen möchten${
      listLength > 1 ? 's' : ''
    }, Zeichnen Sie stattdessen einfach Ihre ${toGroup} Doodle.`,
  YOUR_SUBSCRIPTION_PARTNER_PREMIUM_TEXT_1: (partner, link) =>
    `Unsere Partner bei <em> ${partner} </em> bieten Ihnen Forghetti Premium. Weitere Informationen zu Ihrem Abonnement finden Sie hier: <a href="${link}" target="_new"> ${link} </a>`,
  YOUR_SUBSCRIPTION_PARTNER_PREMIUM_TEXT_2: `Wir hoffen, dass Sie es genießen, Forghetti zu benutzen.`,
  PARTNER_PREMIUM_HEADING: `Glückwunsch!`,
  PARTNER_PREMIUM_MESSAGE: (partner) =>
    `Als Forghetti -Partner erhalten <em> ${partner} </em> Mitglieder Premium Forghetti. Sie müssen nichts tun, das Abonnement wird automatisch auf Ihr Konto angewendet. Damit Änderungen wirksam werden können, klicken Sie jetzt sofort auf "Reload forlohetti".`,
  PARNTER_PREMIUM_BUTTON: `Reload Forghetti`,

  CSL_SUBSCRIPTION_HEADER: `Premium aktiviert`,
  CSL_SUBSCRIPTION_1: `Sie haben unseren Premium -Service aktiviert. Danke schön. Wenn dieses Abonnement abläuft.`,
  CSL_SUBSCRIPTION_2: `Wir hoffen, Sie genießen es, Forghetti zu benutzen.`,
  LICENCE_KEY_INVALID: (partner) => `Entschuldigung, Ihre Bewerbungsfrage ist ungültig.`,
  LICENCE_KEY_USED: (partner) => ` Entschuldigung, Ihre Bewerbungsanforderung ist ungültig. `,
  LICENCE_KEY_ALREADY_APPLIED: (partner) =>
    `Sie haben erfolgreich abonniert. Vielen Dank für die Nutzung dieses Dienstes.`,

  SERVICE_EMAIL_LABEL: 'Email',
  SERVICE_EMAIL_PLACEHOLDER: 'user@email.com',
  SERVICE_EDIT_EMAIL_PLACEHOLDER: 'user@email.com',
  PROFILE_USE_BIOMETRICS: 'Bewahren Sie Ihr Kritzelei mit Biometrie auf',
  BIOMETRICS_GROUPS_HEADER: 'Biometrie aktivieren',
  BIOMETRICS_SELECT_GROUP: 'Wählen Sie eine Gruppe aus, um Ihr Doodle mit Biometrie zu speichern',
  BIOMETRICS: 'Biometrie',
  BIOMETRICS_ENABLE: 'Ermöglichen',
  BIOMETRICS_DISABLE: 'Deaktivieren',
  BIOMETRICS_SECURE: 'Mit Biometrie sicher',
  BIOMETRICS_SECURE_MESSAGE: (group) => `Ihr Doodle für <em> ${group} </em> wird sicher mit Biometrie gespeichert`,
  BIOMETRICS_USE_DOODLE: 'Verwenden Sie Doodle',
  BIOMETRICS_TRY_AGAIN: 'Versuchen Sie es erneut',
  NOTES: 'Anmerkungen',
  SELECT_SPECIAL_CHARACTERS: 'Wählen Sie aus, welche Symbole einbezogen werden sollen',
  WORDS: 'Wörter <span class="monospace">Beispiel</span>',
  LETTERS: 'Buchstaben <span class="monospace">abc</span>',
  NUMBERS: 'Zahlen <span class="monospace">123</span>',
  SYMBOLS: 'Symbole <span class="monospace">!$%</Span>',
  SELECT_PASSWORD_TYPE: (forghettibleName) => `${forghettibleName} Optionen`,
  SELECT_PASSWORD_NUMBERS: 'Gesamtbuchstaben / Zahlen / Symbole',
  SELECT_PASSWORD_WORDS: 'Gesamtwörter',
  ALL: 'Alle',
  SIMPLE: 'Einfach',
  EDIT_PASSWORD_HEADING: 'Einstellungen',
  SERVICE_FIELD_PASSWORD_NO_OPTIONS: 'Mindestens eine Passwortoption muss ausgewählt werden',
  SERVICE_FIELD_PASSWORD_NO_SYMBOLS_SELECTED: 'Mindestens ein Symbol muss ausgewählt werden',
  SUBSCRIPTION_ENDS: (days) => `Ihr Abonnement ist darauf zurückzuführen, dass ${days} Tage abgelaufen ist.`,
  SUBSCRIPTION_ENDS_TOMORROW: 'Ihr Abonnement soll morgen ablaufen.',
  SUBSCRIPTION_ENDS_TODAY: 'Ihr Abonnement wird heute ablaufen.',
  NOTIFICATIONS: 'Benachrichtigungen',
  CONTEXT_CHANGE_TYPE: 'Optionen ändern',
  CONTEXT_CHANGE_LENGTH: 'Länge ändern',
  CONTEXT_RENAME: 'Umbenennen ibhettible',
  NEW_FORGHETTIBLE: 'Neues Forghettible',
  OLD_FORGHETTIBLE: 'Altes Vergeben',
  DELETE_FIELD_PROMPT: 'Sind Sie sicher, dass Sie dieses Feld löschen möchten?',
  ONBOARDING2_WELCOME_HEADING: 'Willkommen bei Forghetti',
  ONBOARDING2_WELCOME_TEXT: 'Melden Sie sich bei allen Diensten an, die Sie mit einem sicheren Doodle benutzen.',
  ONBOARDING2_DOODLE_HEADING: 'Erstellen Sie Ihr Signaturekritzel',
  ONBOARDING2_DOODLE_TEXT:
    'Von Zeilen oder Punkten bis hin zu zufälligen Krengern oder Zahlensequenzen. Ihre eindeutige Kombination generiert ein super starkes Passwort. ',
  ONBOARDIN2_HOWITWORKS: 'Wie es funktioniert',
  ONBOARDING_CONFIRM_DOODLE: 'Bestätigen Sie Ihr Kritzelei',
  ONBOARDING_BIOMETRICS_CONFIRM: 'Möchten Sie dies in Ihrem Schlüsselbund speichern?',
  SAVING_NOTE: 'Verschlüsseln Sie Ihre Notiz',
 };
