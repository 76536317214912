function getResponseErrorMessage(response) {
  // prettier-ignore
  return (
    response.user_message || 
    response.developer_message || 
    response.message || 
    `An error occurred! Sorry about that.`
  );
}

module.exports = getResponseErrorMessage;
