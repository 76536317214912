const _ = require('lodash');
const { strings } = require('../../../config');
module.exports = ({ currentTarget, z2hApp, pane, newPaneIndex, template, dataId, groupId }) => {
  // Get source of selected img/icon

  //const groupId = group || window.state.selectedGroupId;

  const serviceId = dataId || $(currentTarget).attr('data-id') || window.state.selectedServiceId;

  window.state.selectedServiceId = serviceId;

  if (serviceId !== 'new') {
    window.state.currentService = window.state?.currentServices?.find((s) => s.id === serviceId);
  } else {
    window.state.currentService = null;
  }

  if (window.state.currentService && !window.z2hApp.pageData.service.movingService) {
    z2hApp.pageData.service = _.cloneDeep(window.state.currentService);
  }
  if (!groupId && serviceId !== 'new') {
    const group = window.state.groupsList.find((g) => {
      if (g.services) {
        return g?.services?.find((s) => s.id === window.state.currentService.id);
      }
      return false;
    });
    window.state.selectedGroupId = groupId = group.id;
  } else if (serviceId == 'new') {
    groupId = window.state.selectedGroupId;
  } else {
    window.state.selectedGroupId = groupId;
  }

  if (!window.state.currentService) {
    // Check if service exists in another group
    const groupsList = window.state.groupsList;
    for (let i = 0; i < groupsList.length; i++) {
      const groupId = groupsList[i].id;

      const services = groupsList[i].services || [];
      if (services.find && services.find((s) => s.id === serviceId)) {
        window.state.currentService = services.find((s) => s.id === serviceId);
        window.state.selectedGroupId = groupId;
        window.state.currentServices = services;

        if (window.z2hApp.highlightNavElement)
          window.z2hApp.highlightNavElement($('#main-nav').find('[data-id=' + groupId + ']'));

        break;
      }
    }
  }

  if (!template) {
    template = { biometrics: 'viewService1_biometrics', doodlepad: 'viewService1_doodlepad' };
  }

  // check if we have biometrics for this
  return new Promise((resolve, reject) => {
    if (window.Fingerprint) {
      window.Fingerprint.isAvailable(
        (type) => {
          const prom = new Promise((resolve, reject) => {
            if (process.env.CORDOVA) {
              return resolve(type && localStorage.getItem(groupId + '_doodle'));
            }
            return resolve(null);
          });
          prom.then((biometrics) => {
            const page = biometrics ? template['biometrics'] : template['doodlepad']; //'viewService1_biometrics' : 'viewService1_doodlepad';

            z2hApp.paneNavigation(page, pane ? pane : 2, newPaneIndex ? newPaneIndex : 1, null, serviceId);
            return resolve();
          });
        },
        (error) => {
          const page = template['doodlepad'];

          z2hApp.paneNavigation(page, pane ? pane : 2, newPaneIndex ? newPaneIndex : 1, null, serviceId);

          reject();
        },
      );
    } else {
      const page = template['doodlepad'];
      z2hApp.paneNavigation(page, pane ? pane : 2, newPaneIndex ? newPaneIndex : 1, null, serviceId);
    }
  });
};
