const config = require('../../../config');
const { infoTextRow, ctaButton } = require('../../page_helpers/components');
const strings = config.strings;

module.exports = ({ nav }) => {
  return {
    template: 'block-section-page',
    fields: {
      id: 'enable-android-autofill',
      header: strings.ENABLE_ANDROID_AUTOFILL_HEADING(),

      navigation_left: strings.BACK(),
      navigation_left_icon: '',
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'back',
    },
    rows: [
      infoTextRow(strings.ENABLE_ANDROID_AUTOFILL_INSTRUCTION_1(), true, false),
      infoTextRow(strings.ENABLE_ANDROID_AUTOFILL_INSTRUCTION_2(), true, false),

      infoTextRow(strings.ENABLE_ANDROID_AUTOFILL_INSTRUCTION_3(), true, false),

      infoTextRow(strings.ENABLE_ANDROID_AUTOFILL_INSTRUCTION_4(), true, false),
      ctaButton(strings.ENABLE_ANDROID_AUTOFILL_BUTTON(), 'openAndroidAutofillSettingsPage'),
      {
        template: 'block-section-row',
        fields: {
          class: 'no-border',
          class2: 'centered-text',
          //class3: additionalClass,
        },
        columns: [
          {
            template: 'block-text-button',
            fields: {
              class: '',
              navigation_template: 'back',
              navigation_data: Math.max(nav - 1, 0),
              navigation_pane: '2',
              text: strings.ENABLE_ANDROID_AUTOFILL_BUTTON_NO(),
            },
          },
        ],
      },

      infoTextRow(strings.ENABLE_ANDROID_AUTOFILL_INSTRUCTION_5(), true, false),
    ],
  };
};
