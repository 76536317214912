const errorManager = require('../utils/errorManager');
const toast = require('../action_helpers/showToastMessage');
const strings = require('../../config').strings;

module.exports = section => {
  errorManager.clearErrors(section);
  let error = false;

  const selected = section.find('li.import-item.selected')
  if (!selected.length) {
    error = true;
    //errorManager.reportError(strings.IMPORT_SERVICES_LIST_ERROR_NONE_SELECTED, );
    toast(strings.IMPORT_SERVICES_LIST_ERROR_NONE_SELECTED(), { center: true })
  }


  if (error) {
    throw new Error('Validation error');
  }

}