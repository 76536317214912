require('tooltipster')
require('../../node_modules/tooltipster/dist/css/tooltipster.bundle.min.css')
require('../../node_modules/tooltipster/dist/css/plugins/tooltipster/sideTip/themes/tooltipster-sideTip-borderless.min.css')

    // <link rel="stylesheet" type="text/css" href="tooltipster/dist/css/tooltipster.bundle.min.css" />

class ErrorManager {
	clearAllErrors() {
		$('body')
			.find('.field-in-error')
			.removeClass('field-in-error')
			.tooltipster('destroy')
	}

	clearErrors(section) {
		section
			.find('.field-in-error')
			.removeClass('field-in-error')
			.tooltipster('destroy')
	}
	
	reportError(message, field) {
		const elem = $(field)


		setTimeout(_ => {
			elem.tooltipster({
				content: message, 
	
				// Use .tooltipster-borderless-error class to style tooltip
				theme: ['tooltipster-borderless', 'tooltipster-borderless-error'],
				// Don't allow tooltip to be closed
				trigger: 'custom',
				triggerClose: {
					mouseleave: false,
					originClick: false,
					touchleave: false,
				},
				functionPosition: function(instance, helper, position) {
					var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
					var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
					// If browser is Safari on iOS, manually position the error tooltip 
					// to workaround positioning issue on iOS (tooltip displayed too high
					// up page by about 50px)
					if(isSafari && iOS) {
						if(position.side === 'top') {
							position.coord.top =  elem.offset().top - (position.distance + position.size.height)
						}
					}
					return position;
				}
			});

			elem.tooltipster('open')
		}, 200)

		elem.focus().select().addClass('field-in-error')
	}
}

// Export as singleton
module.exports = new ErrorManager()