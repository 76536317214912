module.exports = ({ z2hApp }) => {
  const paneSelector = '#pane-2';
  z2hApp.paneNavigation(
    'chooseGroupIcon',
    $(paneSelector),
    $(paneSelector + '-inner')
      .children('.active')
      .index() + 1,
  );
};
