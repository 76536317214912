const config = require('../../../config');
const showToastMessage = require('../../action_helpers/showToastMessage');

module.exports = ({ menuTarget, z2hApp }) => {
  const groupId = window.state.selectedGroupId;
  const userId = $(menuTarget)
    .closest('[data-id]')
    .attr('data-id');
  if (!userId) return;

  const conf = confirm(config.strings.REMOVE_ADMIN_MESSAGE());
  if (!conf) return;

  z2hApp
    .requestData('post', '', `groups/${groupId}/revoke-admin`, { user_id: userId })
    .then(response => {
      if (Math.floor(response.status / 100) !== 2) {
        throw new Error(
          response.user_message ||
          response.developer_message ||
          response.message ||
          config.strings.MSG_FALLBACK_ERROR_MESSAGE(),
        );
      } else {
        showToastMessage(config.strings.MSG_REMOVED_ADMIN());
      }

      // Update group in state - if we can find them
      window.state = window.state || {};
      const group = window.state.groupsList.find(g => g.id === groupId) || {};
      const users = group.users || {};
      const user = users[userId] || {};
      user.member_type = 0;

      // Refresh group details
      const pane = '#pane-2';
      const activePaneIndex = $(pane + '-inner')
        .children('.active')
        .index();
      z2hApp.paneNavigation('viewGroupDetails', $(pane), activePaneIndex);
    })
    .catch(err => {
      showToastMessage(err);
    });
};
