const showToastMessage = require('../../action_helpers/showToastMessage');
const { makePaneLookIdle } = require('../../app/overlays');

// =============================================================================
// Helper functions
// =============================================================================
let z2hApp = null;

function request(method, endpoint, data) {
  return z2hApp.requestData(method, '', endpoint, data).then((response) => {
    // If response has an error, throw it, to be caught by a future .catch block
    if (Math.floor(response.status / 100) !== 2) {
      throw new Error(
        response.user_message ||
          response.developer_message ||
          response.message ||
          `An error occurred! Sorry about that.`,
      );
    }
    return response;
  });
}

// =============================================================================

function updatePrimaryServiceDetails(serviceId, serviceData) {
  return request('post', 'services/' + serviceId, serviceData);
}

// =============================================================================

function deleteField(fieldId) {
  return request('delete', 'fields/' + fieldId);
}

// =============================================================================

function addField(fieldData, serviceId) {
  return request('post', 'services/' + serviceId + '/new-field', fieldData);
}

// =============================================================================

function resetField(fieldId) {
  return request('post', 'fields/' + fieldId + '/reset-timestamp');
}

// =============================================================================

function restoreField(fieldId, timestamp) {
  return request('post', 'fields/' + fieldId + '/revert-timestamp', { timestamp: parseInt(timestamp) });
}

// =============================================================================

const updateField = (fieldId, fieldData) => {
  let formattedFieldData = { ...fieldData };

  // Make sure we don't send any field access_key or recovery_timestamps object
  // to the server or this API request will fail (invalid request)
  delete formattedFieldData.access_key;
  delete formattedFieldData.recovery_timestamps;

  

  return request('post', 'fields/' + fieldId, formattedFieldData);
};

// =============================================================================
// Returns a single promise which is fulfilled once all fields to be removed
// have been sent through to the API and we have received the response.
async function deleteRemovedFields() {
  const origFields = z2hApp.pageData.service.origFieldsArray;
  const updFields = z2hApp.pageData.service.fieldsArray;
  const removed = origFields.filter((f) => !updFields.includes(f));

  // Call the API for each field in turn
  for (let f in removed) {
    const fieldId = removed[f];
    await deleteField(fieldId);

    // Remove field from local state
    if (window.state.currentService && window.state.currentService.fields) {
      delete window.state.currentService.fields[fieldId];
    }
  }
}

// =============================================================================

async function addNewFields() {
  const serviceId = window.state.selectedServiceId;
  const origFields = z2hApp.pageData.service.origFieldsArray;
  const updFields = z2hApp.pageData.service.fieldsArray;
  const added = updFields.filter((f) => !origFields.includes(f));

  // Call the API for each field in turn
  for (let f in added) {
    const fieldId = added[f];
    const fieldData = z2hApp.pageData.service.fieldsSaved[fieldId];

    fieldData.generation_settings.version = z2hApp.apiConfig.version;

    if (fieldData) {
      const response = await addField(fieldData, serviceId);

      // Update field in local state
      if (response && response.data && response.data.id) {
        window.state.currentService.fields[response.data.id] = {
          id: response.data.id,
          ...fieldData,
        };
      }
    }
  }
}

// =============================================================================

async function updateExistingFields() {
  const origFields = z2hApp.pageData.service.origFieldsArray;
  const updFields = z2hApp.pageData.service.fieldsArray;
  const updated = updFields.filter((f) => origFields.includes(f));

  // Call the API for each field in turn
  for (let f in updated) {
    const fieldId = updated[f];
    const fieldData = z2hApp.pageData.service.fieldsSaved[fieldId];
    if (fieldData) {
      await updateField(fieldId, fieldData);

      // Update field in local state
      const field = window.state.currentService.fields[fieldId];
      for (let i in fieldData) {
        field[i] = fieldData[i];
      }
    }
  }
}

// =============================================================================

async function resetFields() {
  const fieldsToReset = z2hApp.pageData.service.fieldsToBeReset;
  for (let i in fieldsToReset) {
    const fieldId = fieldsToReset[i];
    if (fieldId) {
      await resetField(fieldId);
    }
  }
}

// =============================================================================
/**
 * Returns the field timestamp as it was before the user started to edit it.
 * @param {string} fieldId
 */
function fieldTimestamp(fieldId) {
  const fields = z2hApp.pageData.service.fieldsSaved || [];
  const field = fields[fieldId] || {};
  return field.timestamp;
}

// =============================================================================

async function restoreFields() {
  const fieldsToRestore = z2hApp.pageData.service.fieldsToBeRestored;
  for (let i in fieldsToRestore) {
    const field = fieldsToRestore[i] || {};
    const timestamp = parseInt(field.timestamp);
    // If field has not been selected to be deleted and
    // does not already have the chosen timestamp...
    if (z2hApp.pageData.service.fieldsArray.includes(field.id) && fieldTimestamp(field.id) !== timestamp) {
      // ...restore the field to the selected version/timestamp
      await restoreField(field.id, field.timestamp);
    }
  }
}

// =============================================================================

const updateServiceInLoginsList = (serviceId, serviceData) => {
  const services = window.state.currentServices;
  const service = services.find((s) => s.id === serviceId);
  for (let i in serviceData) {
    service[i] = serviceData[i];
  }
  z2hApp.showServicesForGroup(window.state.selectedGroupId, false);
};

// =============================================================================

module.exports = (params) => {
  const section = params.section;
  console.log('parapms', params);
  z2hApp = params.z2hApp;

  const serviceId = window.state.selectedServiceId;

  const serviceName = section.find('[name=edit-service-name]').val();
  const username = section.find('[name=edit-service-username]').val();

  const usernameSecondary = section.find('[name=edit-service-username-secondary]').val();
  const website = section.find('[name=edit-service-website]').val();

  const serviceData = {
    name: serviceName,
    username: username,
    username_secondary: usernameSecondary,
    website: website,
  };

  const lochyLoadingText = require('../../page_helpers/paneBusyText');
  const overlays = require('../../app/overlays');
  overlays.makePaneLookBusy(2, { text: lochyLoadingText['updateServiceDetails']() });

  return (
    updatePrimaryServiceDetails(serviceId, serviceData)
      .then((_) => deleteRemovedFields())
      .then((_) => addNewFields())
      .then((_) => updateExistingFields())

      // NOTE: The next to are now superfluous as we will reset/restore a field
      // immediately, rather than requiring the user to go back to the service
      // details page and click 'Save', but the code is kept here in case we go back
      // to the previous approach, i.e. user can 'mark' fields they want to be
      // reset/restored and when they eventually 'save' all the updated service
      // details then we send off the requests to reset/restore those fields (if
      // they haven't additionally been deleted).
      .then((_) => resetFields())
      .then((_) => restoreFields())

      .then((_) => updateServiceInLoginsList(serviceId, serviceData))
      .then((_) => showToastMessage('Service details updated'))
      .catch((err) => {
        overlays.makePaneLookIdle(2);
        console.error(err);
        showToastMessage(err.message);
      })
      .then((_) => {
        // Update the "serviceFieldPromises" for this service. This is a promise used by several screens
        // to get the list of fields for a particular service. We can overwrite this promise with a
        // new, immediately-fulfilled promise to provide the updated list of fields.
        const fields = {};
        //window.state.currentService.fields.forEach((f) => (fields[f.id] = f));
        window.serviceFieldPromises[serviceId] = Promise.resolve({ data: window.state.currentService.fields });

        overlays.makePaneLookIdle(2);

        // Go back to the doodlepad (same as if you have reset/recovered a password)
        const paneDetails = window.getActivePaneDetails('#pane-2');

        //z2hApp.paneNavigation('viewService1_doodlepad', paneDetails.pane, paneDetails.activePaneIndex + 1, null);

      const { biometricsOrDoodle } = require('../../actions/services');
        biometricsOrDoodle({
          currentTarget: null,
          z2hApp,
          pane: paneDetails.pane,
          newPaneIndex: paneDetails.activePaneIndex + 1,

          template: { biometrics: 'viewService1_biometrics', doodlepad: 'viewService1_doodlepad' },
        });
      })
  );
};
