const { strings } = require('../../../config')
const lochyLoadingTemplate = require('./lochyLoadingTemplate');

module.exports = () => ({
  ...(lochyLoadingTemplate({
    id: 'verifyPhoneNumber_loading',
    heading: strings.CREATE_ACCOUNT_VERIFYING_PHONE(),
    lochy: 'shield',
  }
  ))
})