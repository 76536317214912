const errorManager = require('../utils/errorManager');
const strings = require('../../config').strings;

module.exports = section => {
  errorManager.clearErrors(section);

  // let code = ''
  // for(let i=1; i<=6; i++) {
  // 	code += $('#block-activation-code-'+i).val()
  // }

  let code = $('.activation-input').val();

  let error = false;

  // const firstField = $('#block-activation-code-1');
  // // Check that code is not empty
  // if (!code) {
  //   errorManager.reportError(strings.ERROR_ACT_CODE_EMPTY, firstField);
  //   error = true;
  // }
  // // Check that code contains 6 numeric digits
  // else if (!/^([0-9]){6}$/.test(code)) {
  //   errorManager.reportError(strings.ERROR_ACT_CODE_INVALID, firstField);
  //   error = true;
  // }

  if (error) {
    throw new Error('Validation error');
  }
};
