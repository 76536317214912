const config = require('~/config');
const strings = config.strings;
const { PROFILE_NAME_MAXLENGTH } = require('../../../config/app');

module.exports = ({ data, nav }) => {
  setTimeout(_ => {
    $('#profile-name').focus();
  }, 2000);

  return {
    template: 'block-section-page',

    fields: {
      id: 'create-account-display-name',
      header: strings.CREATE_ACCOUNT_3_HEADER(),

      navigation_right: strings.NEXT(),
      navigation_right_data: parseInt(nav) + 1,
      navigation_right_action: 'updateProfileName',
      navigation_right_template: 'createAccount_theme',
    },

    rows: [
      {
        template: 'block-section-row',
        fields: { primary_heading: strings.CREATE_ACCOUNT_3_HEADING() },
        columns: [
          {
            template: 'block-info-text',
            fields: { text: strings.CREATE_ACCOUNT_3_TEXT() },
          },
        ],
      },
      {
        template: 'block-section-row',
        columns: [
          {
            template: 'block-login-avatar',
            fields: {
              avatar: data.avatar || config.app.DEFAULT_USER_ICON,
              context: 'addProfileImage',
              data_id: data.id,
            },
          },
          {
            template: 'block-editable-primary-text',
            fields: {
              for: 'profile-name',
              id: 'profile-name',
              name: 'profile-name',
              placeholder: strings.CREATE_ACCOUNT_3_LABEL(),
              value: data.name,
              primary_text: data.name,
              maxlength: PROFILE_NAME_MAXLENGTH,
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          primary_text: '',
        },
        columns: [
          {
            template: 'block-icn-button',
            fields: {
              icon: 'check-solid',
              class: 'icn-button-lrg',
              navigation_data: parseInt(nav) + 1,
              navigation_template: 'createAccount_theme',
              navigation_action: 'updateProfileName',
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: { primary_text: '' },
        columns: [
          {
            template: 'block-text-group',
            fields: {
              primary_text: '',
              secondary_html: strings.CREATE_ACCOUNT_3_TEXT_2(),
            },
          },
        ],
      },
    ],
  };
};
