// Required to extend jQuery, but return value not used
require('croppie');

const getImageOrientation = require('../../action_helpers/getImageOrientation');
const getImageDimensions = require('../../action_helpers/getImageDimensions');
const { isIOS } = require('../../app/tools');

// Required so that CSS is included in page
require('../../../node_modules/croppie/croppie.css');

const DEFAULT_IMAGE = 'img/blank.png';

const croppieEditorProps = {
  viewport: { width: 200, height: 200, type: 'circle' },
  boundary: { width: 300, height: 300 },
  enableOrientation: true,
  enableExif: false,
};

const croppieResultProps = {
  size: { width: 250, height: 250 },
  format: 'jpeg',
  type: 'base64',
  circle: false,
};

let resolver = null;

// =============================================================================
/** onFileSelected
 * Open the cropper tool with the selected file displayed in it.
 */
function onFileSelected() {
  const files = this.files;
  const uploader = $('.image-uploader');
  const main = $('.image-uploader__main');
  const uploadedImageUrl = window.URL.createObjectURL(files[0]);
  const container = uploader.find('.image-uploader__container');

  // =============================================================================
  const hideUploader = _ => {
    uploader.hide();
    // Load a default image for when the image cropper is next displayed
    container.croppie('bind', { url: DEFAULT_IMAGE });
  };

  // Initalise croppie component if necessary
  if (!container.attr('data-initialised')) {
    container.croppie(croppieEditorProps);
    container.attr('data-initialised', true);
    main.on('click', 'button.close', hideUploader);
  }

  let orientation = null;
  // Ensure the image is displayed with the correct orientation
  getImageOrientation(files[0])
    .then(o => {
      orientation = o;
      return getImageDimensions(uploadedImageUrl);
    })
    .then(dims => {
      console.info('Image uploaded - orientation: ' + orientation + ', dimensions:', JSON.stringify(dims));
      container.croppie('bind', { url: uploadedImageUrl, orientation });
    });

  const rotateRightButton = uploader.find('button.rotateRight');
  rotateRightButton.unbind('click');
  rotateRightButton.click(e => {
    container.croppie('rotate', 90);
  })
  const rotateLeftButton = uploader.find('button.rotateLeft');
  rotateLeftButton.unbind('click');
  rotateLeftButton.click(e => {
    container.croppie('rotate', -90);
  })

  const submitButton = uploader.find('button.tick');
  submitButton.unbind('click');
  submitButton.click(e => {
    main.addClass('busy');

    container.croppie('result', croppieResultProps).then(base64Image => {
      // We now have an image, so we can resolve the promise with this.
      // However, we also want to pass a callback out with it so that when
      // our caller is done with the image we can close the crop tool.
      resolver({ base64Image, doneCallback: hideUploader });
    });
  });

  // Display the tool

  main.removeClass('busy');
  uploader.show();
}

// =============================================================================

module.exports = () => {
  return new Promise((resolve, reject) => {
    resolver = resolve;

    const fileInput = $('#hidden-file-input');
    if (!fileInput[0]) {
      console.error('File upload hidden input missing');
      return;
    }
    if (window.hiddenFileInputHandler) {
      fileInput[0].removeEventListener('change', window.hiddenFileInputHandler);
    }
    fileInput[0].value = null;
    window.hiddenFileInputHandler = onFileSelected;
    fileInput[0].addEventListener('change', onFileSelected);

    document.getElementById('hidden-file-input').click();
  });
};
