module.exports = ({ currentTarget }) => {
  const service = z2hApp.pageData.service || {};
  const fields = service.fields || {};

  const strings = require('../../../config').strings;

  const row = $(currentTarget).closest('.row');
  const fieldId = row.attr('id');

  const fieldData = fields[fieldId] || {};
  const password = fieldData.access_key;
  const oldPassword = fieldData.old_access_key;
  if (!password) {
    return;
  }

  const content = row.find('[name=primary-text-content]');
  const contextSeondary = row.find('[name=secondary-text-content]');
  const button = row.find('[data-actionclick=startViewPasswordTimer]');

  const showIconHtml = button.hasClass('has-label')
    ? '<i class="f-icn f-icn-show"></i><div class="label">' + strings.SERVICE_VIEW_ICON_LABEL() + '</div>'
    : '<i class="f-icn f-icn-show"></i>';

  const hidePassword = (_) => {
    content.html(
      // Replace each character with a dot
      '<span class="numbered-character-list">' +
        password
          .split('')
          .map((c) => '<span>●</span>')
          .join('') +
        '</span>',
    );
    if (oldPassword) {
      contextSeondary.html(
        // Replace each character with a dot
        '<span class="numbered-character-list">' +
          oldPassword
            .split('')
            .map((c) => '<span>●</span>')
            .join('') +
          '</span>',
      );
    }

    button.html(showIconHtml);
    row.removeClass('password-visible');
  };

  if (row.hasClass('password-visible')) {
    hidePassword();
    return;
  }

  content.html(
    // Wrap each character to make a list
    '<ol class="numbered-character-list">' +
      password
        .split('')
        .map((c) => '<li><span>' + c + '</span></li>')
        .join('') +
      '</ol>',
  );
  if (oldPassword) {
    contextSeondary.html(
      // Wrap each character to make a list
      '<ol class="numbered-character-list">' +
        oldPassword
          .split('')
          .map((c) => '<li><span>' + c + '</span></li>')
          .join('') +
        '</ol>',
    );
  }
  row.addClass('password-visible');

  const hideIconHtml = button.hasClass('has-label')
    ? '<i class="f-icn f-icn-hide"></i><div class="label">' + strings.SERVICE_HIDE_ICON_LABEL() + '</div>'
    : '<i class="f-icn f-icn-hide"></i>';
  button.html(hideIconHtml);


  if (window.cordova) {
    setTimeout(hidePassword, 15000);
  } else {
    setTimeout(hidePassword, 15000);
  }
  /*
  let timeout;

  let count = 60;
  const onTick = _ => {
    if (count === 1 || !row.hasClass('password-visible')) {
      hidePassword();
      clearInterval(timeout);
      return;
    }
    count -= 1;
    button.html('<span>' + count + 's</span>'); // Display number of seconds within the button
  };
  timeout = setInterval(onTick, 1000);
  onTick(); // Fire immediately
  */
};
