const config = require('../../../config');
const strings = config.strings;

function fieldRow(field) {
  return {
    template: 'block-section-row',
    fields: { class: 'greyed-out' },
    columns: [
      { template: 'block-text-group', fields: { primary_text: field.name } },
      { template: 'block-icn', fields: { icon: 'settings' } },
    ],
  };
}

module.exports = ({ nav, data }) => {
  const service = data;
  const fieldRows = (service.fields || []).map(f => fieldRow(f));
  const onboarding = window.state.userData.activeOnboarding;
  // If service is editable, the right-nav will take the user to the editServiceDetails page
  const navRight = service.editable
    ? {
      navigation_right: strings.SERVICE_DETAILS_RIGHT_NAV(),
      navigation_right_icon: '',
      navigation_right_data: nav + 1,
      navigation_right_template: 'editServiceDetails',
    }
    : {};

  const deleteButton = {
    template: 'block-section-row',
    fields: { action: 'deleteLogin', class: 'cta', class2: 'red' },
    columns: [
      {
        template: 'block-icn-button',
        fields: {
          icon: 'delete',
        },
      },
      {
        template: 'block-text-button',
        fields: {
          text: strings.SERVICE_DETAILS_DELETE(),
        },
      },
    ],
  };

  return {
    template: 'block-section-page',
    fields: {
      id: 'view-service-details',
      header: strings.SERVICE_DETAILS_HEADER(),

      navigation_left: strings.BACK(),
      navigation_left_icon: '',
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'back',
      navbar: false,
      ...navRight,
    },
    rows: [
      {
        template: 'block-section-row',
        columns: [
          {
            template: 'block-login-avatar',
            fields: {
              avatar: data.icon,
              //context: service.editable ? 'addServiceImage' : null,
              data_id: data.id,
            },
          },
          {
            template: 'block-text-group',
            fields: { primary_text: data.name },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          secondary_heading: strings.SERVICE_USERNAME_LABEL(),
          action: 'copyServiceUsernameToClipboard',
        },
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_text: data.username },
          },
          {
            template: 'block-icn-button',
            fields: { icon: 'copy', action: 'copyServiceUsernameToClipboard' },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          secondary_heading: strings.SERVICE_WEBSITE_LABEL(),
          action: 'openWebsite',
        },
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_text: data.website },
          },
          {
            template: 'block-icn-button',
            fields: { icon: 'up', action: 'openWebsite' },
          },
        ],
      },
      ...fieldRows,
      ...(service.editable ? [deleteButton] : []),
      // ...({
      //   template: 'block-section-row',
      //   fields: {},
      //   columns: [{
      //     template: 'block-text-button',
      //     fields: {
      //       text: 'Reset password',
      //       action: 'openUrl',
      //     }
      //   }],
      // })
    ],
  };
};
