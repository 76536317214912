const config = require('../../../config');
const { infoTextRow, ctaButton } = require('../../page_helpers/components');

const { strings, urls } = config;

const months = () => [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

// We will display different content depending on what subscription you have. The subscription comes
// from the 'launch' data API. Current subscription types are:
//  - free:
//  - premium:
//  - premium_trial:
//  - premium_monthly:
//  - premium_life:
//  - premium_paid
const content = {
  partner: (date, appStoreAvailable, partner) => {
    const rows = [];
    for (const p of partner.body) {
      if (p.search('Lochy') >= 0) {
        rows.push({
          template: 'block-lochy',
          fields: {
            imagePath: `img/lochy/${p}`,
            class: 'no-border',
            size: 'small',
          },
        });
      } else {
        rows.push(infoTextRow(p, true, false, true));
      }
    }
    return rows;
  },
  premium_trial: (date, appStoreAvailable) => [
    infoTextRow(strings.YOUR_SUBSCRIPTION_PREMIUM_TRIAL_TEXT_1()(date), true, false),
    ctaButton(
      window.cordova
        ? strings.YOUR_SUBSCRIPTION_PREMIUM_TRIAL_CORDOVA_BUTTON_LABEL()
        : strings.YOUR_SUBSCRIPTION_PREMIUM_TRIAL_BUTTON_LABEL(),
      window.cordova ? 'gotoPremium2' : 'openSubscriptionPricingUrl',
    ),
    infoTextRow(strings.YOUR_SUBSCRIPTION_PREMIUM_TRIAL_TEXT_2()(date), true, false),
  ],
  CSL: (date, appStoreAvailable) => [
    infoTextRow(strings.CSL_SUBSCRIPTION_1(), true, false),
    // ctaButton(
    //   window.cordova
    //     ? strings.YOUR_SUBSCRIPTION_PREMIUM_TRIAL_CORDOVA_BUTTON_LABEL()
    //     : strings.YOUR_SUBSCRIPTION_PREMIUM_TRIAL_BUTTON_LABEL(),
    //   window.cordova ? 'gotoPremium2' : 'openSubscriptionPricingUrl',
    // ),
    {
      template: 'block-lochy',
      fields: {
        imagePath: 'img/lochy/12-Lochy-Dancing.gif',
        class: 'no-border',
        size: 'small',
      },
    },
    infoTextRow(strings.CSL_SUBSCRIPTION_2(), true, false),
  ],

  premium_life: (date, appStoreAvailable) => [
    infoTextRow(strings.YOUR_SUBSCRIPTION_PREMIUM_LIFE_TEXT_1()(date), true, false),
    {
      template: 'block-lochy',
      fields: {
        imagePath: 'img/lochy/12-Lochy-Dancing.gif',
        class: 'no-border',
        size: 'small',
      },
    },
    infoTextRow(strings.YOUR_SUBSCRIPTION_PREMIUM_LIFE_TEXT_2()(date), true, false),
  ],
  free: (date, appStoreAvailable) => [
    appStoreAvailable && infoTextRow(strings.YOUR_SUBSCRIPTION_FREE_TEXT_1()(date), true, false),
    appStoreAvailable && infoTextRow(strings.YOUR_SUBSCRIPTION_FREE_TEXT_2()(date), true, false),

    appStoreAvailable && ctaButton(strings.YOUR_SUBSCRIPTION_FREE_BUTTON_LABEL(), 'gotoPremium2'),
    appStoreAvailable && infoTextRow(strings.YOUR_SUBSCRIPTION_FREE_TEXT_3(), true, false),

    appStoreAvailable && {
      template: 'block-section-row',
      fields: {
        class: 'no-border',
        class2: 'centered-text',
      },
      columns: [
        {
          template: 'block-text-button',
          fields: {
            class: '',
            action: 'gotoPremium2',
            text: strings.PREMIUM_RESTORE_PURCHASE(),
          },
        },
      ],
    },

    !appStoreAvailable && infoTextRow(strings.YOUR_SUBSCRIPTION_FREE_TEXT_APP_1(), true, false),
    !appStoreAvailable && infoTextRow(strings.YOUR_SUBSCRIPTION_FREE_TEXT_APP_2(), true, false),
    !appStoreAvailable && {
      template: 'block-lochy',
      fields: {
        imagePath: 'img/premiumQRcode.png',
        class: 'no-border',
      },
    },
    !appStoreAvailable && infoTextRow(strings.YOUR_SUBSCRIPTION_FREE_DOWNLOAD(), true, false),
    !appStoreAvailable && {
      template: 'block-section-row',
      fields: { class: 'no-border' },
      columns: [
        !window.state.electron ||
          (window.state.electron !== 'mac' && { template: 'block-playstore-link', fields: { url: urls.PLAYSTORE } }),
        { template: 'block-appstore-link', fields: { url: urls.APPSTORE } },
      ],
    },
  ],

  premium: (date, appStoreAvailable) => [
    date
      ? infoTextRow(strings.YOUR_SUBSCRIPTION_PREMIUM_TEXT_1()(date), true, false)
      : infoTextRow(strings.YOUR_SUBSCRIPTION_PREMIUM_NO_DATE_TEXT_1(), true, false),

    infoTextRow(strings.YOUR_SUBSCRIPTION_PREMIUM_TEXT_2()(date), true, false),
    appStoreAvailable && {
      template: 'block-section-row',
      fields: {
        class: 'centered-text',
      },
      columns: [
        {
          template: 'block-text-button',
          fields: {
            text: strings.PREMIUM_MANAGE_SUBSCRIPTIONS(),
            action: 'manageSubscriptions',
            class: 'cta-button',
          },
        },
      ],
    },
  ],
  premium_paid: (date, appStoreAvailable) => [
    infoTextRow(strings.YOUR_SUBSCRIPTION_PREMIUM_TEXT_1()(date), true, false),
    infoTextRow(strings.YOUR_SUBSCRIPTION_PREMIUM_TEXT_2()(date), true, false),
    appStoreAvailable && {
      template: 'block-section-row',
      fields: {
        class: 'centered-text',
      },
      columns: [
        {
          template: 'block-text-button',
          fields: {
            text: strings.PREMIUM_MANAGE_SUBSCRIPTIONS(),
            action: 'manageSubscriptions',
            class: 'cta-button',
          },
        },
      ],
    },
  ],
};

function formatDate(dateStr) {
  //coercion implied.
  if (!dateStr || isNaN(dateStr) || dateStr == '') return;

  const date = new Date(dateStr);
  const month = months()[date.getMonth()];
  const day = String(date.getDate()); // + (ending[String(date.getDate())] || 'th');
  const year = date.getFullYear();
  const formattedDate = month + ' ' + day + ', ' + year;
  return formattedDate;
}

module.exports = ({ nav, data }) => {
  // We need to determine what content to show, based on the user's subscription type.
  // We also need to format the expiry date of their subscription correctly to be
  // displayed in the content.
  let subscriptionType = '';
  let rows = [];

  const appStore = window.cordova && CdvPurchase;
  try {
    const subscription = (window.state && window.state.userData && window.state.userData.subscription) || {};
    subscriptionType = subscription.subscription_type;

    if (!subscriptionType && window.state.launchData.home.premium) {
      subscriptionType = 'premium';
    }

    if (
      (subscriptionType === 'free' || subscriptionType === 'premium_trial') &&
      data &&
      data.type === 'partner_upgrade'
    ) {
      subscriptionType = 'partner';
    }
    if (subscriptionType === 'premium_trial' && data && data.type === 'CSL') {
      subscriptionType = 'CSL';
    }

    const formattedDate = formatDate(subscription.subscription_end_date);
    const contentForUser = content[subscriptionType] || content['free'];
    rows = contentForUser(formattedDate, appStore, subscriptionType === 'partner' ? data.text : null);
  } catch (e) {
    console.error('Error getting subscription type: ', e);
  }

  let header = strings.YOUR_SUBSCRIPTION_HEADER();
  console.log('DATA', data);
  if (data && data.text?.header) {
    header = data.text?.header;
  }
  return {
    template: 'block-section-page',
    fields: {
      id: 'view-your-subscription',
      header,
      navbar: false,
      navigation_left: strings.BACK(),
      navigation_left_icon: '',
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'back',
    },
    rows,
  };
};
