const updateOnScreenServiceImageSource = require('../../action_helpers/updateOnScreenServiceImageSource');
const showResponseErrorMessage = require('../../action_helpers/showResponseErrorMessage');
const showToastMessage = require('../../action_helpers/showToastMessage');
const uploadAndCropImage = require('../miscellaneous/uploadAndCropImage');

module.exports = ({ z2hApp }) => {
  const serviceId = window.state.selectedServiceId;
  const newService = serviceId === 'new';

  // Firstly get the user to upload an image, and crop it if they like
  uploadAndCropImage().then(({ base64Image, doneCallback }) => {
    // If this is a new service we don't upload the new icon to the server yet
    // as the service itself doesn't exist on the server yet.
    if (newService) {
      // Store this icon to be uploaded to the service later if/when it gets
      // saved to the server.
      window.state.temporaryServiceIcon = base64Image;
      // This next flag indicates that we don't need to auto-generate an icon
      // for this service.
      window.state.temporaryServiceIconChosen = true;
      const serviceId = 'temporaryServiceIcon';
      // Update on screen
      updateOnScreenServiceImageSource(serviceId, base64Image);
      if (doneCallback) doneCallback();
      return;
    }

    // Send image to the server
    const data = { image: base64Image };
    z2hApp.requestData('post', '', `services/${serviceId}/update-image`, data).then((response) => {
      if (Math.floor(response.status / 100) !== 2) {
        return showResponseErrorMessage(response);
      }

      const newUrl = (response.data || {}).url || '';

      const services = !newService && window.state.currentServices;
      const service = !newService && services.find((s) => s.id === serviceId);
      service.icon = newUrl;

      // Update any images on screen which have a data-id or id which matches
      // the service ID
      updateOnScreenServiceImageSource(serviceId, base64Image);

      showToastMessage('Service icon updated');

      // Call the callback we were given (to close the upload tool)
      if (doneCallback) doneCallback();
    });
  });
};
