/* eslint-disable no-restricted-syntax */
const config = require('../../../config');
const z2hApp = require('../../app/z2hApp');
const actions = require('../../actions');
const { GROUP_NAME_MAXLENGTH } = require('../../../config/app');
const { strings } = config;

$('body').on('keyup', '#view-group-details input', (e) => {
  const { currentTarget } = e;
  if (e.key !== 'Enter') return;
  $(currentTarget).blur();

  // Trigger action
  actions.editCurrentGroup({
    section: $('#view-group-details'),
    element: currentTarget,
    currentTarget,
    z2hApp,
  });
});

const biometrics = (group, nav) => {
  delete window.state.addBiometricsFromSettings;
  let enabled = localStorage.getItem(`${group.id}_doodle`);
  let fields = null;
  if (enabled) {
    return {
      template: 'block-icn-button',
      fields: {
        icon: 'fingerprint-colour',

        data_id: group.id,
        action: 'toggleBiometricsState',
        label: strings.DISABLE_BIOMETRICS(),
      },
    };
  } else {
    return {
      template: 'block-icn-button',
      fields: {
        icon: 'fingerprint',
        //navigation_data: '1',
        //navigation_data: group.id,
        data_id: group.id,
        navigation_data: nav + 1,
        navigation_pane: '2',
        navigation_template: 'enableBiometricsDoodle',
        label: strings.ENABLE_BIOMETRICS(),
      },
    };
  }

  return { template: 'block-icn-button', fields };
};

const replayBiometrics = (group, nav) => {
  delete window.state.addBiometricsFromSettings;
  let enabled = localStorage.getItem(`${group.id}_doodle`);
  let fields = null;
  if (enabled) {
    return {
      template: 'block-icn-button',
      fields: {
        icon: 'doodlepad-drawn',
        data_id: group.id,
        navigation_data: nav + 1,
        navigation_pane: '2',
        navigation_template: 'replayDoodle',
        label: strings.REPLAY_DOODLE(),
      },
    };
  }

  return null;
};

module.exports = ({ data, nav }) => {
  // Set current selected group ID
  //window.state.selectedGroupId = data.id;
  const group = data.group;

  const biometricFields = window.cordova ? biometrics(group, nav) : null;
  const replayDoodle = window.cordova ? replayBiometrics(group, nav) : null;

  // ===============================================================================================
  // Work out if the user is the owner, admin or just a member of this group
  const personalTypeInGroup = data.personalTypeInGroup;
  const userIsAdmin = data.userIsAdmin;
  const userIsOwner = data.userIsOwner;
  const userIsAdminOrOwner = data.userIsAdminOrOwner;
  const userIsPremium = data.userIsPremium;
  const icon = data.icon;
  const canEditGroup = data.canEditGroup;

  // Create the empty page
  const page = {
    template: 'block-section-page',
    fields: {
      id: 'view-group-details',
      active: false,
      header: strings.GROUP_VIEW_HEADER(),

      help_id: 'HELP_GROUP_PAGE',

      navigation_left: config.strings.BACK(),
      navigation_left_icon: '',
      navigation_left_data: 0,
      navigation_left_template: 'groupsList',
      navigation_left_pane: 1,
      ...(canEditGroup && {
        navigation_right: strings.SAVE(),
        navigation_right_icon: '',
        navigation_right_action: 'editCurrentGroup',
        navigation_right_data: nav + 1,
        navigation_right_template: 'saveGroupDetails_loading',
        //navigation_right_validation: 'editGroupDetails',
      }),
      navbar: false,
    },
    rows: [
      // Display group name and avatar
      {
        template: 'block-section-row',
        columns: [
          {
            template: 'block-login-avatar',
            fields: {
              context: canEditGroup && window.cordova ? 'addImageCordova' : canEditGroup ? 'addImageDesktop' : '',
              data_id: group.id,
              avatar: icon,
            },
          },
          !canEditGroup && {
            template: 'block-text-group',
            fields: { primary_text: group.name },
          },
          canEditGroup && {
            template: 'block-editable-primary-text',
            fields: {
              primary_text: group.name,
              value: group.name,
              name: 'group-name',
              for: 'group-name',
              id: 'group-name',
              maxlength: GROUP_NAME_MAXLENGTH,
            },
          },
          canEditGroup && {
            template: 'block-icn-button',
            fields: {
              icon: 'edit',
              label: strings.EDIT(),
              action: 'focusOnGroupName',
            },
          },
          window.cordova && biometricFields,
          window.cordova && replayDoodle,
        ],
      },
      canEditGroup && createInviteRow(nav),
    ],
  };

  // Display list of group members
  if (!group.personal) {
    // Create a row for each member of the group
    const memberRows = [];
    const users = (group && group.users) || [];
    Object.keys(users).forEach((id) => {
      memberRows.push(memberRow(id, users[id], personalTypeInGroup));
    });
    page.rows.push(groupMembersHeadingRow, ...memberRows);
  }

  // If user is not owner and it's not your personal group, show option to leave group
  if (!group.personal && !userIsOwner) {
    page.rows.push(leaveGroupRow);
  }

  // If user is owner, and not your personal group, show option to delete group
  if (!group.personal && userIsOwner) {
    page.rows.push(deleteGroupRow(nav));
  }

  // Display some help text if this is the personal group
  if (group.personal) {
    page.rows.push(personalGroupMessageRow());
  }

  return page;
};

// =================================================================================================
// =================================================================================================

// -----------------------------------------------------------------------------------------------

const groupMembersHeadingRow = {
  template: 'block-section-row',
  fields: {
    secondary_heading: config.strings.GRP_MEMBERS_HEADING(),
    help_id: 'HELP_GROUP_MEMBERS',
    class: 'no-border',
  },
};

// -----------------------------------------------------------------------------------------------

// -----------------------------------------------------------------------------------------------
const createInviteRow = (_) => ({
  template: 'block-section-row',
  fields: {
    action: 'createGroupInvite',
  },
  columns: [
    {
      template: 'block-icn-button',
      fields: { icon: 'add' },
    },
    {
      template: 'block-text-button',
      fields: { text: config.strings.INVITE_NEW_MEMBER() },
    },
  ],
});

// -----------------------------------------------------------------------------------------------

const leaveGroupRow = {
  template: 'block-section-row',
  fields: {
    class: 'cta',
    class2: 'red',
    action: 'leaveGroup',
  },
  columns: [
    {
      template: 'block-icn-button',
      fields: { icon: 'exit' },
    },
    {
      template: 'block-text-button',
      fields: { text: strings.GROUP_VIEW_LEAVE() },
    },
  ],
};

// -----------------------------------------------------------------------------------------------

const deleteGroupRow = (nav) => ({
  template: 'block-section-row',
  fields: {
    class: 'cta',
    class2: 'red',
    action: 'deleteGroup',
    //navigation_data: nav + 1,
    //navigation_template: 'deletingGroup_loading',
  },
  columns: [
    {
      template: 'block-icn-button',
      fields: { icon: 'delete' },
    },
    {
      template: 'block-text-button',
      fields: { text: strings.GROUP_VIEW_DELETE() },
    },
  ],
});

// -----------------------------------------------------------------------------------------------

const personalGroupMessageRow = () => {
  return {
    template: 'block-section-row',
    columns: [
      {
        template: 'block-help-text',
        fields: {
          class: 'centered-text',
          text: strings.PERSONAL_GROUP_MESSAGE(),
        },
      },
    ],
  };
};

// -----------------------------------------------------------------------------------------------

function memberRow(id, user, personalTypeInGroup) {
  // If this group member is you, display your avatar
  const isYou = z2hApp.userId() === id;

  // Avatar will either be your own user avatar, or the user's avatar, or
  // a suitable replacement/default
  const avatar = isYou
    ? z2hApp.userAvatar() || config.app.DEFAULT_USER_ICON
    : user.avatar || config.app.DEFAULT_USER_ICON;

  // Member type: 1 = Admin, 2 = Owner
  const memberTypes = ['', strings.GROUP_VIEW_USER_ADMIN(), strings.GROUP_VIEW_USER_OWNER()];
  const memberType = memberTypes[user.member_type] || '';
  const premiumClass = user.premium ? 'avatar-badge-premium' : '';

  // The member name is either going to be your name, or the user's name, or
  // their nickname, or just leave it as blank
  const name = (isYou && strings.GROUP_VIEW_USER_YOU()) || user.name || user.nick || '';

  let menu = '';

  // If user is owner of group...
  if (personalTypeInGroup === 2) {
    if (user.member_type === 0) {
      menu = 'groupMemberMenu1'; // Make admin, remove
    }
    if (user.member_type === 1) {
      menu = 'groupMemberMenu2'; // Revoke admin, remove
    }
  }
  if (personalTypeInGroup === 1) {
    if (user.member_type === 0) {
      menu = 'groupMemberMenu3'; // Remove
    }
  }

  return {
    template: 'block-section-row',
    fields: {
      navigation_data_id: id,
    },
    columns: [
      {
        template: 'block-login-avatar',
        fields: { avatar, id: 'avatar_' + id, class: premiumClass },
      },
      {
        template: 'block-text-group',
        fields: { primary_text: name || `Anonymous`, secondary_text: memberType },
      },
      // Context-menu - three-dots icon
      menu && {
        template: 'block-icn-button',
        fields: { icon: 'dots', context: menu },
      },
    ],
  };
}

