module.exports = {
  healthcheck_welcome: require('./healthcheck_welcome'),
  healthcheck_usernames: require('./healthcheck_usernames'),
  healthcheck_password: require('./healthcheck_password'),
  healthcheck_assessment: require('./healthcheck_assessment'),
  healthcheck_assessment2: require('./healthcheck_assessment2'),
  healthcheck_resetPasswords: require('./healthcheck_resetPasswords'),
  healthcheck_results: require('./healthcheck_results'),
  healthcheck_details: require('./healthcheck_details'),
  healthcheck_details_user: require('./healthcheck_details_user'),
  healthcheck_details_password: require('./healthcheck_details_password'),


}