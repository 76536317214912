module.exports = {
  lochyLoadingTemplate: require('./lochyLoadingTemplate'),
  verifyDevice_loading: require('./verifyDevice_loading'),
  verifyPhoneNumber_loading: require('./verifyPhoneNumber_loading'),
  deletingGroup_loading: require('./deletingGroup_loading'),
  creatingGroup_loading: require('./creatingGroup_loading'),
  newInvite_loading: require('./newInvite_loading'),
  saveGroupDetails_loading: require('./saveGroupDetails_loading'),
  viewGroupDetails_loading: require('./viewGroupDetails_loading'),
  addServicePassword_loading: require('./addServicePassword_loading'),
  viewService_loading: require('./viewService_loading'),
  healthcheck_loading: require('./healthcheck_loading'),
  healthcheck_details_loading: require('./healthcheck_details_loading'),
  moveServicePassword_loading: require('./moveServicePassword_loading'),
  importingService_loading: require('./importingService_loading'),
  updatingForghettible_loading: require('./updatingForghettible_loading'),
  viewGroups_loading: require('./viewGroups_loading'),
  viewProfile_loading: require('./viewProfile_loading'),
  createServicesBatch_loading: require('./createServicesBatch_loading'),
  general_loading: require('./general_loading'),
  vault_loading: require('./vault_loading'),
  
};
