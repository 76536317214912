const config = require('~/config');

module.exports = ({ nav }) => {
  const serviceId = window.state.selectedServiceId;
  const services = window.state.currentServices;
  const service = services.find(s => s.id === serviceId);
  const user = window.state.userData;

  const groupId = window.state.selectedGroupId;
  const group = window.state.groupsList.find(g => g.id === groupId);

  const avatar = group.personal
    ? user.avatar || config.app.DEFAULT_USER_ICON
    : group.icon || config.app.DEFAULT_GROUP_ICON;

  const avatar_id = group.personal ? user.id : group.id;

  return {
    template: 'block-section-page',
    fields: {
      id: 'doodle-pad',
      header: service.name,
      help_id: 'HELP_DOODLEPAD',
      navbar: false,
      navigation_left: config.strings.PROFILE_LINK_LOGOUT(),
      navigation_left_action: 'logout',
    },
    rows: [
      {
        template: 'block-avatar-group',
        fields: {
          avatar_src_1: service.icon || config.app.DEFAULT_SERVICE_ICON,
          avatar_src_2: avatar,
          avatar_id_1: service.id,
          avatar_id_2: avatar_id,
          class: 'no-border',
        },
      },
      {
        template: 'block-section-row',
        fields: { class: 'no-border' },
        columns: [{ template: 'block-doodlepad' }],
      },
      {
        template: 'block-doodlepad-buttons',
        fields: {
          confirm_data: nav + 1,
          confirm_template: 'viewService_loading',
          confirm_validation: 'validateDoodlepad',
          confirm_action: 'goToServiceCodes_extension',
        },
      },
    ],
  };
};
