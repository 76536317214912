module.exports = ({ element, section, z2hApp }) => {
  // Get username from row that was clicked on

  if (!window.state.usernameFocused) {
    window.state.usernameFocused = 'add-service-username-input';
  }

  const injectField = $(`#${window.state.usernameFocused}`);

  const username = $(element).find('.primary-text-content').text();

  injectField.val(username);

  if (window.state.usernameFocused === 'add-service-username-input') {
    window.state.usernameFocused = 'add-service-username-input-secondary';
  } else {
    window.state.usernameFocused = 'add-service-username-input';
  }
  $(`#${window.state.usernameFocused}`).focus().blur();
};
