const config = require('../../../config');
const { YOUR_SUBSCRIPTION_PREMIUM_TEXT_1 } = require('../../../config/strings');
const { infoTextRow, progressBarWithMarkers } = require('../../page_helpers/components');
const strings = config.strings;

function navRow({ icon, label, destination, action, secondary_text = '' }) {
  let fields = {};
  if (action) {
    fields = { action };
  }
  if (destination) {
    fields = {
      navigation_pane: '2',
      navigation_data: '1',
      navigation_template: destination,
    };
  }
  return {
    template: 'block-section-row',
    fields,
    columns: [
      icon && { template: 'block-icn-button', fields: { icon } },
      { template: 'block-text-group', fields: { primary_text: label, secondary_text } },
      { template: 'block-icn-button', fields: { icon: 'chevron-right' } },
    ],
  };
}

const premiumText = ({ subscriptionType }) => {
  return strings.PROFILE_PREMIUM_TYPE()[subscriptionType];
};
const premiumLochy = ({ subscriptionType }) => {
  const lochys = {
    free: 'img/lochy/Lochy-free.gif',
    premium_paid: 'img/lochy/Lochy-annual.gif',
    premium_lifetime: 'img/lochy/Lochy-lifetime.gif',
    premium_trial: 'img/lochy/Lochy-monthly.gif',
    partner: 'img/lochy/Lochy-monthly.gif',
  };

  return lochys[subscriptionType];
};

module.exports = ({ data }) => {
  console.log('VIEW PROFILE DATA: ', data);

  const { displayEnableAutofill } = data;
  const {
    PROFILE_LINK_ACCOUNT,
    PROFILE_WIZARD_COMPLETE,
    PROFILE_WIZARD_SEE_DETAILS,
    PROFILE_LINK_PREMIUM,
    PROFILE_LINK_USER_PREFERENCES,
    PROFILE_YOUR_SUBSCRIPTION,
    PROFILE_LINK_INVITE,
    /*PROFILE_LINK_INVITE_SECONDARY,*/
    PROFILE_REFERRAL_CODE,
    PROFILE_REFERRAL_CODE_SECONDARY,
    PROFILE_LINK_DEVICES,
    PROFILE_LINK_HELP,
    PROFILE_LINK_FEEDBACK,
    PROFILE_LINK_ABOUT,
    PROFILE_LINK_LOGOUT,
    PROFILE_ENABLE_AUTOFILL,
    PROFILE_USE_BIOMETRICS,
    PROFILE_BADGES,
  } = strings;

  return {
    template: 'block-section-page',
    fields: {
      id: 'profile-landing-page',
      header: strings.PROFILE_HEADER(),
      navbar: { mobile: true, desktop: false },
    },

    rows: [
      {
        template: 'block-section-row',
        fields: {
          class: 'profile-name',
          //class2: 'mergeWithNextRow',
          /* This used to link to editProfileName, I should remove it
             but first I need to check if it's the right thing to do */
          navigation_pane: '2',
          navigation_data: '1',
          navigation_template: 'viewProfileAccount',
        },
        columns: [
          {
            template: 'block-login-avatar',
            fields: {
              avatar: data.avatar || config.app.DEFAULT_USER_ICON,
              data_id: data.id,
            },
          },
          {
            template: 'block-text-group',
            fields: { primary_text: data.name || '', secondary_text: premiumText(data) },
          },
          {
            template: 'block-badge',
            fields: {
              image: premiumLochy(data),
              action: 'gotoPremium',
            },
          },
        ],
      },

      data.score < data.total
        ? progressBarWithMarkers([1, 2, 3, 4, 5], data.score, {
            secondary_heading: PROFILE_WIZARD_COMPLETE(),
            //navigation: PROFILE_WIZARD_SEE_DETAILS,
            navigation_data: '1',
            navigation_pane: '2',
            navigation_template: 'addService_onboarding_complete',
            class: 'no-border',
          })
        : {},
      //   template: 'block-section-row',
      //   fields: {
      //     class: 'profile-name',
      //   },
      //   columns: [
      //     {
      //       template: 'block-text-group',
      //       fields: { primary_text: premiumText(data) },
      //     },
      //     {
      //       template: 'block-login-avatar',
      //       fields: {
      //         avatar: 'img/lochy/03-Lochy-Shield.gif',
      //       },
      //     },
      //   ],
      // },

      //We've got this funny way of caching these banners...
      //The template is stored in th state. If we don't have the state
      //we use go and get the data
      window.state.banner && window.state.banner.profile
        ? {
            template: 'block-section-row',
            
            columns: [JSON.parse(window.state.banner.profile)],
          }
        : {
            template: 'block-section-row',
            columns: [
              {
                template: 'block-banner',
                fields: {
                  context: 'profile',
                  class: 'loading-safe',
                },
              },
            ],
          },


      window.cordova && navRow({ icon: 'password', label: PROFILE_USE_BIOMETRICS(), destination: 'enableBiometricsGroups' }),
      // Invite a friend =======================================================
      navRow({
        icon: 'heart',
        label: PROFILE_LINK_INVITE(),
        //secondary_text: PROFILE_LINK_INVITE_SECONDARY,
        destination: 'inviteAFriend',
      }),
      // Profile ===============================================================
      navRow({ icon: 'shield', label: PROFILE_BADGES(), destination: 'badges' }),
      // Profile ===============================================================
      navRow({ icon: 'profile', label: PROFILE_LINK_ACCOUNT(), destination: 'viewProfileAccount' }),

      // Devices ===============================================================
      navRow({ icon: 'mobile', label: PROFILE_LINK_DEVICES(), destination: 'devices' }),
      // Account ===============================================================
      navRow({ icon: 'settings', label: PROFILE_LINK_USER_PREFERENCES(), destination: 'viewAppPreferences' }),

      //TODO
      // Enable autofill (if applicable) =======================================
      displayEnableAutofill &&
        navRow({ icon: 'password', label: PROFILE_ENABLE_AUTOFILL(), destination: 'enableAndroidAutofill' }),
      // Help ==================================================================
      navRow({ icon: '', label: PROFILE_LINK_HELP(), destination: 'viewHelpList' }),

      // Feedback ==============================================================
      navRow({
        icon: '',
        label: PROFILE_LINK_FEEDBACK(),
        //action:  'openFeedbackUrl',
        action: window.cordova ? 'appRating' : 'openFeedbackUrl',
      }),

      // About =================================================================
      navRow({ icon: '', label: PROFILE_LINK_ABOUT(), destination: 'viewAboutList' }),

      // Logout ================================================================
      navRow({ icon: '', label: PROFILE_LINK_LOGOUT(), action: 'logout' }),
      {
        template: 'block-section-row',
        fields: {
          class: 'no-border',
        },
        columns: [
          {
            template: 'block-section-row',
          },
        ],
      },
    ],
  };
};
