const getAccessKeys = require('../../getPageData_helpers/getAccessKeys');
const strings = require('../../../config').strings;
const {
  infoTextRow,
  serviceField,
  unavailableServiceField,
  upgradeTodayButton,
  loadingServiceField,
} = require('../../page_helpers/components');

module.exports = async ({ link, details, task, menuTarget, z2hApp, templateId }) => {
  const copyIconLabel = strings.SERVICE_COPY_ICON_LABEL();
  const viewIconLabel = strings.SERVICE_VIEW_ICON_LABEL();
  const injectIconLabel = strings.SERVICE_INJECT_LABEL();

  const serviceId = z2hApp.pageData.service.id;
  const fieldData = z2hApp.pageData.service.fieldsSaved[templateId];
  delete z2hApp.pageData.service.fieldsUnsaved[templateId] 
  delete z2hApp.temporary[templateId]

  $(`#${templateId}`).replaceWith(z2hApp.constructRow(loadingServiceField(fieldData.name, null, templateId)));

  return z2hApp.requestData('post', '', 'services/' + serviceId + '/new-field', fieldData).then((response) => {
    // If response has an error, throw it, to be caught by a future .catch block
    if (Math.floor(response.status / 100) !== 2) {
      throw new Error(
        response.user_message ||
          response.developer_message ||
          response.message ||
          `An error occurred! Sorry about that.`,
      );
    }

    getAccessKeys(serviceId, z2hApp.pageData.service.doodle).then((data) => {
      if (fieldData) {
        fieldData.id = response.data.id;
        fieldData.old_access_key = fieldData.access_key;
        fieldData.access_key = data[response.data.id].access_key;

        window.state.currentService.fields[response.data.id] = {
          id: response.data.id,
          ...fieldData,
        };
      }
      z2hApp.pageData.service.fields[response.data.id] = z2hApp.pageData.service.fieldsSaved[response.data.id] =
        fieldData;

      $(`#${templateId}`).attr('id', response.data.id);

      $(`#${response.data.id}`).fadeOut('slow', () => {
        $(`#${response.data.id}`).replaceWith(
          z2hApp.constructRow(
            serviceField(
              fieldData.name,
              fieldData.access_key,
              fieldData.id,
              { copyIconLabel, viewIconLabel, injectIconLabel },
              {
                moving: false,
                prefix: strings.VIEW_SERVICE_NEW_PREFIX(),
              },
            ),
          ),
        );
        $(`#${response.data.id}`).hide().fadeIn(1000);
      });
    });

    return response;
  });
};
