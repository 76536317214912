//TODO checking if we already have an imported services group and not adding matches in this case

const parseURL = require('../../action_helpers/parseUrl')
const fuzzyMatchUrl = require('../../action_helpers/fuzzyMatchUrl');

const showAlertMessage = require('../../action_helpers/showAlertMessage');
const strings = require('../../../config/strings');

let processing = false;

//There are some imports that are bunk, Android local storage that kind of thing. Ignore them
const urlPassedCheck = (url) => {
  let valid = false;

  if (url.protocol === 'http:' || url.protocol === 'https:') {
    valid = true;
  }
  return valid;
}


module.exports = async ({ element, section, z2hApp }) => {

  //Get a list of services that we know about...
  let services = window.state.serviceIconList;
  if (services.length === 0) {
    await z2hApp.fetchServicePresetsList()
    services = window.state.serviceIconList;
  }


  window.state.importedServices = [];
  processing = false;

  //This fires off a message to main process (this is the renderer process) to get the import done.
  //For MAC we are running in sandbox so it will open a dialog box that will allow the user to select the person.
  window.postMessage({
    action: 'import-services',
  });

  //After we've run the import we will get a message back to say that it is done, or failed.
  window.addEventListener('message', event => {

    const message = event.data;

    //TODO better error messages
    if (message.action === 'import-services-error') {
      showAlertMessage(strings.IMPORT_SERVICE_IMPORT_NODATA_NOTE(), true, strings.IMPORT_SERVICE_IMPORT_NODATA_HEADER());
    }

    //Import completed...
    if (message.action === 'import-services-complete') {

      if (window.state.importedServices.length || processing) return;

      if (!message.data.length) {
        showAlertMessage(strings.IMPORT_SERVICE_IMPORT_NODATA_NOTE(), true, strings.IMPORT_SERVICE_IMPORT_NODATA_HEADER());
        return;
      }
      processing = true;
      const logins = [];
      for (const login of message.data) {
        //Ignore items that don't actually have a URL, I'm pretty sure this doesn't happen but JIC
        if (login.origin_url === '') {
          continue;
        }

        const url = parseURL(login.origin_url);
        if (urlPassedCheck(url)) {
          //We're going to try and match the URLS to services we know about
          //We'll use some fuxxy matching algorithms, they're not perfect but it's the best we've got.
          const hostname = url.hostname.toLowerCase();
          const hosturl = login.origin_url;

          //the old code for matchin, keeping it here in case fuzzyMatch takes too long.
          //const match = services.find((service) => {
          //  return ((service.address.length >= 6 && hosturl.includes(service.address)) ||
          //    service.id === hostname);
          //})

          const match = fuzzyMatchUrl(login.origin_url, services);

          //A fuzzy match score of over 60 is good enough in our book, though we might want to change this.
          if (match[0].score >= 60) {

            match.username = login.username_value;
            match.lastUsed = login.date_last_used;
            match.timesUsed = login.times_used;

            const serviceMatch = window.state.serviceIconList.find(e => e.id === match[0].choice.id)
            match.url = serviceMatch.url;
            match.name = url.hostname;
            match.id = serviceMatch.id;
            //Don't add services we already have.
            if (!logins.find(login => login.id === match.id)) logins.push(match);

          } else {
            //If we couldn't find a good scoring match we'll add in this default. We will allow the user 
            //to update the data so don't worry too much.
            const template = {
              username: login.username_value,
              last_used: 0,
              timesUsed: 0,
              address: login.origin_url,
              //Make up an id
              id: window.z2hTools.getUid(),
              name: url.hostname,
              url: '',
              match,

            }
            if (!logins.find(login => login.name === template.name)) logins.push(template);
          }



        }
      }
      //Sort by name.
      window.state.importedServices = logins.sort((a, b) => a.url < b.url || a.url !== '' ? 1 : -1);
      // Go back to the doodlepad (same as if you have reset/recovered a password)
      const paneDetails = window.getActivePaneDetails('#pane-2');

      if (paneDetails.activePaneIndex <= 1) {
        paneDetails.activePaneIndex = 1;
      }
      //Display the list to users.
      z2hApp.paneNavigation('importList', $('#pane-2'), paneDetails.activePaneIndex + 1, null);
      processing = false;
    }
  });


}