const showToastMessage = require('../../action_helpers/showToastMessage');
const config = require('../../../config');

module.exports = ({ z2hApp }) => {
  const inviteId = window.state.selectedInviteId;
  //const groupId = window.state.selectedGroupId;
  const groupId = z2hApp.getGroupFromTemplate('shareInvite');

  const pane = '#pane-2';
  $(pane).addClass('busy');

  if (confirm(config.strings.DEACTIVATE_GRP_LINK_CONFIRM())) {
    z2hApp
      .requestData('post', '', `groups/${groupId}/expire-invite`, { invite_id: inviteId })
      .then(response => {
        if (Math.floor(response.status / 100) !== 2) {
          throw new Error(
            response.user_message ||
            response.developer_message ||
            response.message ||
            `An error occurred! Sorry about that.`,
          );
        }
        showToastMessage(config.strings.DEACTIVATE_GRP_LINK_SUCCESS());
      })
      .catch(err => showToastMessage(err.message))
      .then(_ => {
        // Remove invite from list
        const group = window.state.groupsList.find(g => g.id === groupId);
        if (group.invites) delete group.invites[inviteId];

        // Refresh group details
        const activePaneIndex = $(pane + '-inner')
          .children('.active')
          .index();
        z2hApp.paneNavigation('viewGroupDetails', $(pane), Math.max(activePaneIndex - 1, 0));
      });
  }
};
