const config = require('../../../config');
const { infoTextRow } = require('../../page_helpers/components');
const { SERVIVCE_NAME_MAXLENGTH } = require('../../../config/app');


const { strings } = config;
const { BACK, NEXT, IMPORT_SERVICES_USERNAME_NOTE, ADD_SERVICE_USERNAME_PLACEHOLDER, ADD_SERVICE_USERNAME, SEARCH, CANCEL, IMPORT_SERVICES_USERNAME_HEADER } = strings;

const usernameList = (service) => {
  return (
    {
      template: 'block-section-row',
      fields: {
        secondary_heading: service.name,
        class: 'third-heading',
      },
      columns: [
        {
          template: 'block-search-box',
          fields: {
            placeholder: ADD_SERVICE_USERNAME_PLACEHOLDER(),
            id: service.id,
            value: service.username,
            maxlength: SERVIVCE_NAME_MAXLENGTH,
          },
        },
      ]
    }
  )
}

module.exports = ({ nav, data }) => {


  //const serviceName = window.z2hApp.pageData.service.serviceData.name;
  const rows = [];
  const services = window.state.selectedImportedServices;
  rows.push(infoTextRow(IMPORT_SERVICES_USERNAME_NOTE(), true, false, true))
  for (const service of services) {
    //rows.push(infoTextRow(service.name, false, false, false));
    rows.push(usernameList(service));

  }

  return {
    template: 'block-section-page',
    fields: {
      id: 'addService_username',

      header: IMPORT_SERVICES_USERNAME_HEADER(),
      navigation_left: BACK(),
      //navigation_left_action: 'hidePane3',
      navigation_left_template: 'back',
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_right: NEXT(),
      navigation_right_data: nav + 1,
      navigation_right_template: 'importCreatingServices',
      navigation_right_action: 'importUsernames',
      //navigation_right_validation: 'importList',
      navbar: false,
    },
    rows: rows,
  };
};
