const config = require('../../../config');

const onboadingTemplate = require('./onboarding_template');

const strings = require('../../../config').strings;

module.exports = ({ nav }) => {
  return onboadingTemplate({
    nav,
    showSkip: true,
    imagePath: 'img/lochy/01-Lochy-Waving.gif',
    primary_heading: strings.ONBOARDING_WELCOME_HEADING(),
    text: strings.ONBOARDING_WELCOME_TEXT(),
    nextPage: 'onboarding_createdoodle',
  });
};
