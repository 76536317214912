const { strings } = require('../../../config');
const { primaryText } = require('../../page_helpers/components');

module.exports = ({ nav }) => {
  return {
    template: 'block-section-page',
    fields: {
      id: 'healthcheck_welcome',
      header: strings.HEALTHCHECK_WELCOME_HEADER(),
      navbar: { desktop: false, mobile: true },
    },
    rows: [
      primaryText(strings.HEALTHCHECK_WELCOME_NOTE(), true, false),
      {
        template: 'block-lochy',
        fields: {
          imagePath: 'img/lochy/05a-Lochy-Doctor-v2.gif',
          class: 'no-border',
          size: 'small',
        },
        columns: [],
      },
      {
        template: 'block-section-row',
        fields: {
          class: 'centered-text',
        },
        columns: [
          {
            template: 'block-text-button',
            fields: {
              text: strings.HEALTHCHECK_WELCOME_BUTTON(),
              navigation_template: 'healthcheck_usernames',
              navigation_data: '1',
              navigation_pane: '2',
              class: 'cta-button',
            },
          },
        ],
      },
    ],
  };
};
