
const errorManager = require('../utils/errorManager')
const strings = require('../../config').strings
const { GROUP_NAME_MAXLENGTH } = require('../../config/app')

module.exports = (section, pageData) => {
  errorManager.clearErrors(section)
  let error = false
  const groupNameField = section.find('[name=new-group-name-field]')
  const groupName = groupNameField.val().trim()
  if (groupName.length === 0) {
    error = true
    errorManager.reportError(strings.GROUP_NAME_BLANK(), groupNameField)
  }
  if (groupName.length > GROUP_NAME_MAXLENGTH) {
    error = true
    errorManager.reportError(strings.GROUP_NAME_TOO_LONG(), groupNameField)
  }
  if (error) {
    throw new Error("Validation error")
  }
}