const updateOnScreenServiceImageSource = require('../action_helpers/updateOnScreenServiceImageSource');
const showResponseErrorMessage = require('../action_helpers/showResponseErrorMessage');
const fetchBase64Image = require('../action_helpers/fetchBase64Image');

let workingOnIt = false;
function updateServiceIcon({ iconUrl, z2hApp, callback, currentTarget }) {
  if (workingOnIt) return;

  workingOnIt = true;
  const serviceId = window.state.selectedServiceId;
  const newService = window.state.selectedServiceId === 'new';

  const sectionPaneWrapper = $(currentTarget).closest('.section-pane-wrapper');
  const pane = sectionPaneWrapper.parent();

  // Unless this is a new service, we can optimistically update the icon on
  // screen and let the API update happen in the background.

  if (newService) {
    pane.addClass('busy');
    return fetchBase64Image(iconUrl)
      .then(base64Image => {
        window.state.temporaryServiceIcon = base64Image;
      window.state.temporaryServiceIconChosen = true;
        updateOnScreenServiceImageSource('temporaryServiceIcon', iconUrl);
      })
      .catch(err => {
        console.error('Error fetching service icon:', err);
      })
      .then(_ => {
        workingOnIt = false;
        pane.removeClass('busy');
        callback(null, z2hApp, pane);
      });
  }

  // If not a new service...
  updateOnScreenServiceImageSource(serviceId, iconUrl);

  const services = window.state.currentServices;
  const service = services.find(s => s.id === serviceId);
  service.icon = iconUrl;

  // Navigate to previous page
  callback(service, z2hApp, pane);

  // In the background, tell the API to update the stored image for this service.
  return fetchBase64Image(iconUrl)
    .then(base64Image => z2hApp.requestData('post', '', `services/${serviceId}/update-image`, { image: base64Image }))
    .then(response => {
      if (Math.floor(response.status / 100) !== 2) {
        showResponseErrorMessage(response);
        return;
      }
      // Now we can update the service properly
      const newUrl = (response.data || {}).url || '';
      service.icon = newUrl;
      updateOnScreenServiceImageSource(serviceId, newUrl);
    })
    .catch(err => {
      console.error('Error fetching service icon:', err);
    })
    .then(_ => {
      workingOnIt = false;
    });
}

module.exports = updateServiceIcon;
