const DialogMgr = require('dialog-manager');

const { getBadgeJSON, isBadgeActive, findBadgeById } = require('../../components/badges');

const heading = badge => ({
  template: 'block-primary-heading',
  fields: {
    heading: badge.heading(),
  },
});

const badgeTemplate = img => ({
  template: 'block-lochy',
  selector: '',
  attributes: [
    { selector: 'img', type: 'src', content: img },
    { selector: '', type: 'class', content: 'no-border' },
    { selector: 'img', type: 'class', content: 'small' },
  ],
});

const info = info => ({
  template: 'block-primary-text',
  fields: {
    class: 'wrap-text',
    text: info(),
  },
});
const row = () => ({
  template: 'block-section-row',
  fields: {
    class: 'centered-text',
  },
  rows: [],
});
const ctaButton = badge => ({
  template: 'block-cta-button',
  fields: {
    action: badge.cta,
    data_id: badge.cta_data,
    text: badge.cta_text(),
  },
});

const buildButton = badge => {
  const $row = z2hApp.constructBlock(z2hTemplates.compileBlockAttribute(ctaButton(badge)));
  return $row;
};

module.exports = ({ currentTarget, section, z2hApp }) => {
  const badgeId = $(currentTarget).attr('data-id');

  const badge = findBadgeById(badgeId);
  const badgeActive = isBadgeActive(badgeId).then(active => {
    const z2hTemplates = require('../../app/templates');
    const node = $('<div>');

    node.append(z2hApp.constructBlock(z2hTemplates.compileBlockAttribute(heading(badge))));
    node.append(
      z2hApp.constructBlock(badgeTemplate(`img/badges/${active ? badge.badge_active : badge.badge_disabled}`)),
    );
    node.append(
      z2hApp.constructBlock(z2hTemplates.compileBlockAttribute(info(active ? badge.info_complete : badge.info))),
    );
    !active && node.append(buildButton(badge));

    z2hApp.paneNavigation('badges', $('#pane-2'), 1);
    //const badgeTemplate = buildBageDetailsTemplate(badge);
    DialogMgr.showMsg({ closeEnabled: true, msgNode: node[0] });
    $('.cta-button').on('click', () => {
      $('.dialogMgr_root').click();
    });
  });
};
