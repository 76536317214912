const { strings, urls } = require('../../../config');
const { infoTextRow } = require('../../page_helpers/components');

module.exports = ({ nav }) => {
  return {
    template: 'block-section-page',
    fields: {
      id: 'upgrade-today',
      active: false,
      header: strings.UPGRADE_TODAY_PAGE_HEADING(),

      navigation_left: strings.BACK(),
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'back',
    },
    rows: [
      {
        template: 'block-section-row',
        fields: {
          class: 'centered-text',
          primary_heading: strings.UPGRADE_TODAY_MSG_1(),
          secondary_heading: strings.UPGRADE_TODAY_MSG_2(),
        },
      },
      infoTextRow(strings.UPGRADE_TODAY_MSG_3(), true, false),
      {
        template: 'block-section-row',
        fields: { class: 'no-border' },
        columns: [
          { template: 'block-playstore-link', fields: { url: urls.PLAYSTORE } },
          { template: 'block-appstore-link', fields: { url: urls.APPSTORE } },
        ],
      },
      infoTextRow(strings.UPGRADE_TODAY_MSG_4(), true, false),
    ],
  };
};
