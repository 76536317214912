const config = require('../../../config');
const onboardingPageTemplate = require('./createAccount_onboarding_template');

module.exports = ({ data, nav }) => {
  return onboardingPageTemplate(
    1,
    config.strings.ONBOARDING_HEADER_1(),
    config.strings.ONBOARDING_MESSAGE_1(),
    'img/onboarding/desktop/1.jpg',
    'createAccount_onboarding2',
    null,
    parseInt(nav) + 1,
    null,
  );
};
