const config = require('../../../config');
const { infoTextRow } = require('../../page_helpers/components');

const { strings } = config;
const {
  MOVE_SERVICE_SELECT_GROUP,
  ADD_SERVICE_SELECT_GROUP_ONBOARD,
  ADD_SERVICE_SELECT_GROUP,
  ADD_SERVICE_SELECT_GROUP_SELECT,
  ADD_SERVICE_YOUR_GROUP,
  BACK,
  ADD_SERVICE_SELECT_GROUP_HEADER,
  GROUP_NONE_AVAILABLE,
  NEXT,
} = strings;

module.exports = ({ nav }) => {
  const service = window.z2hApp.pageData.service.serviceData;
  const serviceName = service.name;
  const serviceIcon = service.icon || config.app.DEFAULT_SERVICE_ICON;

  const movingService = window.z2hApp.pageData.service.movingService;
  const currentGroupID = window.z2hApp.pageData.service.currentGroupId;
  const { groupsList } = window.state;
  const onboarding = window.state.userData.activeOnboarding;
  const reonboarding = window.state.userData.reonboarding;
  const action = 'addService_selectGroup';

  let selectedGroup = window.state.groupsList.find((g) => g.id === window.state.selectedGroupId);
  if (!selectedGroup) {
    selectedGroup = window.state.groupsList.find((g) => g.id === window.state.selectedGroupId);
  }
  let selectedGroupNotAvailable = false;
  let showNext = false;
  const icon = selectedGroup.personal
    ? window.state.userData.avatar || config.app.DEFAULT_USER_ICON
    : selectedGroup.icon || config.app.DEFAULT_GROUP_ICON;

  //NOTE: now you can add to all groups except:
  //if you are a free user and they are a free user and you've run of services on that group!
  const youArePremium = window.state.launchData.home.premium;
  let availableGroupsNumber = 0;
  let availableGroupsList = groupsList.map((group) => {
    let available = youArePremium;
    if (!available) {
      if (group.owner_id && group.users) {
        available = (group.users[group.owner_id] || {}).premium;
      } else {
        available = false;
      }
    }

    //If the owner isn't premium and you are not premium then we need
    //to check the number of services available to the group
    if (!available) {
      //Special case if this is a personal group.
      if (group.personal) {

        let freeFields = 15;
        let freeFieldsUsed = 0;

        if (
          window.state.updateSections &&
          window.state.updateSections.your_journey_data &&
          window.state.updateSections.your_journey_data.free_fields_maximum
        ) {
          freeFields = window.state.updateSections.your_journey_data.free_fields_maximum;
        } else if (localStorage.getItem('updateSections')) {
          freeFields = localStorage.getItem('updateSections').your_journey_data.free_fields_maximum;
        }

        if (
          window.state.updateSections &&
          window.state.updateSections.your_journey_data &&
          window.state.updateSections.your_journey_data.free_fields_used
        ) {
          freeFieldsUsed = window.state.updateSections.your_journey_data.free_fields_used
        } else if (localStorage.getItem('updateSections')) {
          freeFieldsUsed = localStorage.getItem('updateSections').your_journey_data.free_fields_used;
        }


        available = freeFieldsUsed < freeFields;
      } else {

        let freeFields = 10;
        let freeFieldsUsed = 0;
        if (
          window.state.updateSections &&
          window.state.updateSections.your_journey_data &&
          window.state.updateSections.your_journey_data.free_fields_maximum_in_other_owned_groups
        ) {
          freeFields = window.state.updateSections.your_journey_data.free_fields_maximum_in_other_owned_groups;
        } else if (localStorage.getItem('updateSections')) {
          freeFields = localStorage.getItem('updateSections').your_journey_data.free_fields_maximum_in_other_owned_groups;
        }
        if (
          window.state.updateSections &&
          window.state.updateSections.your_journey_data &&
          window.state.updateSections.your_journey_data.free_fields_used
        ) {
          freeFieldsUsed = window.state.updateSections.your_journey_data.free_fields_used_in_other_owned_groups
        } else if (localStorage.getItem('updateSections')) {
          freeFieldsUsed = localStorage.getItem('updateSections').your_journey_data.free_fields_used_in_other_owned_groups;
        }
        
        available = freeFieldsUsed < freeFields;
      }
    }

    // //If we are moving service then we can only do that if we are the owner of the group and we are moving to another
    //group we own
    if (available && movingService) {
      available = group.personal || (group.users && (group.users[window.state.userData.id] || {}).member_type == 2);
    }

    if (available) availableGroupsNumber += 1;

    return { ...group, available };
  });

  //if we're moving a service don't include the service that we already set
  if (movingService) {
    availableGroupsList = availableGroupsList.filter((group) => {
      return group.id !== currentGroupID;
    });
  } else if (!onboarding) {
    //remove the selectedGroupId from the list.
    //Also check if the selected is not available.

    availableGroupsList = availableGroupsList.filter((g) => {
      if (g.id === selectedGroup.id) {
        selectedGroupNotAvailable = !g.available;
      }
      return g.id !== selectedGroup.id;
    });
  }
  //Scenarios:

  //1. Onboarding
  //2. Moving service
  //3. Add service
  //    - No groups available
  //    - No selected group
  //    - Selected group not available
  //    - All of the above.

  const rows = [];
  reonboarding && rows.push({ template: 'block-onboarding-close' });
  onboarding &&
    rows.push({
      template: 'block-section-row',
      fields: {
        primary_heading_html: ADD_SERVICE_SELECT_GROUP_ONBOARD()(serviceName),
      },
    });
  movingService && rows.push(infoTextRow(MOVE_SERVICE_SELECT_GROUP()(serviceName), true, false, true));

  if ((!movingService && availableGroupsNumber === 0) || selectedGroupNotAvailable || onboarding) {
    rows.push(infoTextRow(ADD_SERVICE_SELECT_GROUP()(serviceName), true, false, true));
  } else if (!movingService) {
    rows.push(infoTextRow(ADD_SERVICE_YOUR_GROUP()(serviceName, selectedGroup.name), true, false, true));
    rows.push({
      template: 'block-avatar-group',
      fields: {
        avatar_src_1: serviceIcon,
        avatar_src_2: icon,
        //avatar_id_1: service.id,
        //avatar_id_2: groupIconId,
        avatar_name_1: serviceName,
        avatar_name_2: selectedGroup.name,
        class: 'with-arrow',
        action: 'addService_selectGroup',
      },
    });
    rows.push(infoTextRow(ADD_SERVICE_SELECT_GROUP_SELECT()(serviceName), true, false, true));
  }

  const page = {
    template: onboarding ? 'block-section-onboarding' : 'block-section-page',
    fields: {
      id: onboarding ? 'add-service-group-onboarding' : 'add-service-group',

      class: onboarding && 'no-title',
      ...(!onboarding && {
        header: ADD_SERVICE_SELECT_GROUP_HEADER(),

        navigation_left: BACK(),
        navigation_left_template: 'back',
        navigation_left_data: Math.max(nav - 1, 0),
        ...(!movingService &&
          !selectedGroupNotAvailable && {
            navigation_right: NEXT(),
            navigation_right_template: 'addService_username',
            navigation_right_data: nav + 1,
          }),
      }),
      navbar: false,
    },
    rows: rows,
  };

  //Put the groups you can have first...
  //availableGroupsList = availableGroupsList.sort((a, b) => {
  //  return a.available === b.available ? 0 : a.available ? -1 : 1;
  //});

  if (availableGroupsNumber === 0) {
    const showUpgradeTOdayMessage = require('../../action_helpers/showUpgradeTodayPopup');
    showUpgradeTOdayMessage(GROUP_NONE_AVAILABLE());
  }
  const numRows = availableGroupsList.length;
  for (let r = 0; r < numRows; r++) {
    const group = availableGroupsList[r];
    const icon = group.personal
      ? window.state.userData.avatar || config.app.DEFAULT_USER_ICON
      : group.icon || config.app.DEFAULT_GROUP_ICON;
    const row = {
      template: 'block-section-row',
      fields: {
        action: !group.available ? 'groupNotAvailable' : action,
        //data_id: group.id,
        navigation_data_id: group.id,
      },
      columns: [
        {
          template: 'block-login-avatar',
          fields: {
            avatar: icon,
            data_id: group.id,
            class: !group.available ? 'disabled' : '',
          },
        },
        {
          template: 'block-text-group',
          fields: {
            primary_text: group.name,
          },
        },
      ],
    };
    page.rows.push(row);
  }

  //TODO
  const addNewGroup = () => ({
    template: 'block-section-row',
    fields: {
      class: 'cta',
      action: 'addService_newGroup',
    },
    columns: [
      {
        template: 'block-icn-button',
        fields: {
          icon: 'add',
        },
      },
      {
        template: 'block-primary-text',
        fields: {
          text: strings.GROUPS_LIST_ADD_NEW_GROUP(),
        },
      },
    ],
  });

  page.rows.push(!onboarding && window.state.launchData.home.premium && addNewGroup());

  return page;
};
