const config = require('../../../config');
const { infoTextRow, thirdheading, primaryText } = require('../../page_helpers/components');

const strings = require('../../../config').strings;

module.exports = ({ nav }) => {
  const user = window.state.userData;
  const movingService = window.z2hApp.pageData.service.movingService;
  const movingFromTo = window.state.movingService && window.state.movingService.from.service.doodle ? 'to' : 'from';

  window.state.biometrics = false;
  const groupId =
    movingService && movingFromTo === 'from'
      ? window.z2hApp.pageData.service.currentGroupId
      : window.state.selectedGroupId;
  const group = window.state.groupsList.find((g) => g.id === groupId) || {};
  const service = window.z2hApp.pageData.service.serviceData;
  const onboarding = window.state.userData.activeOnboarding;

  const serviceName = service.name;
  const serviceIcon = service.icon || config.app.DEFAULT_SERVICE_ICON;

  const groupIcon = group.personal
    ? user.avatar || config.app.DEFAULT_USER_ICON
    : group.icon || config.app.DEFAULT_GROUP_ICON;

  const groupIconId = group.personal ? window.state.userData.id : group.id;
  const groupName = group.name || '';

  const headerFields = () => {
    let fields = {
      id: onboarding ? 'doodle-pad-onboarding' : movingService ? `moveService_${movingFromTo}Doodle` : 'doodle-pad',
      class: 'no-title',
      navbar: false,
    };
    if (!onboarding) {
      fields = {
        ...fields,
        header: serviceName,

        navigation_left: strings.BACK(),
        navigation_left_data: Math.max(nav - 1, 0),
        navigation_left_template: 'back',
      };
    }
    return fields;
  };

  const rows = [];

  //onboarding && rows.push(thirdheading({ text: strings.ADD_SERVICE_DOODLE_ONBOARD_NOW_ITS_YOUR_TURN, additionalClass: 'centered-text' }));
  onboarding && rows.push(infoTextRow(strings.ADD_SERVICE_DOODLE_CREATE_DOODLE(), true, false));

  !onboarding &&
    rows.push({
      template: 'block-avatar-group',
      fields: {
        avatar_src_1: serviceIcon,
        avatar_src_2: groupIcon,
        avatar_id_1: service.id,
        avatar_id_2: groupIconId,
        avatar_name_1: serviceName,
        avatar_name_2: groupName,
        class: 'with-arrow',
      },
    });

  !onboarding && rows.push(primaryText(strings.ADD_SERVICE_DRAW_DOODLE()(groupName || ''), true, false, true));

  rows.push(
    {
      template: 'block-section-row',
      fields: { class: 'no-border' },
      columns: [{ template: 'block-doodlepad' }],
    },
    {
      template: 'block-doodlepad-buttons',
      fields: {
        confirm_validation: 'validateDoodlepad',
        confirm_action: onboarding
          ? 'confirmDoodlePad_1'
          : movingService
          ? `moveService_${movingFromTo}DoodleSubmit`
          : 'doodlepad_submit',
      },
    },
  );

  return {
    template: onboarding ? 'block-section-onboarding' : 'block-section-page',
    fields: headerFields(),
    rows: rows,
  };
};
