const showToastMessage = require('../../action_helpers/showHelpMessage');
const config = require('../../../config');
const strings = config.strings;

module.exports = ({ currentTarget }) => {
  const elem = $(currentTarget);
  // let map = {
  // 	'newServiceEmailOrUsername': config.strings.HELP_LOGIN_USERNAME,
  // 	'newServiceWebAddress': config.strings.HELP_LOGIN_WEBSITE,
  // 	'newServiceName': config.strings.HELP_LOGIN_TITLE,
  // 	'newServiceAddNewField': config.strings.HELP_LOGIN_ADD_FORGHETTIBLE,
  // 	'groups-list': config.strings.HELP_GROUPS_LIST_HEADER,
  // 	'groupsListOtherGroups': config.strings.HELP_GROUPS_LIST_OTHER_GROUPS,
  // 	'groupsListPersonalHeading': config.strings.HELP_GROUPS_LIST_PERSONAL,
  // }
  const helpId = elem.attr('data-id');
  const msg = strings[helpId]();
  if (msg) showToastMessage(msg.replace(/\n/g, '<br/>'));
};
