
module.exports = url => {
  let _url = url;
  if (url.substr(0, 2) === '//') {
    _url = 'http://' + url.substr(2);
  }
  if (_url.substr(0, 4) !== 'http') {
    _url = 'http://' + _url;
  }

  console.log('Opening URL ' + _url);

  const cordova = window.cordova;


  if (cordova && cordova.InAppBrowser) {
    // The cordova-plugin-inappbrowser had to be installed in order to open links in iOS
    // We had to install the version from github (master branch) as the app did not compile
    // with version 3.2.0 (no known instance method for selector 'shouldAutorotateToInterfaceOrientation:')
    cordova.InAppBrowser.open(_url, '_system', 'location=yes');
  } 
  
  else {
    const newWindow = window.open(_url, '_blank', '', true);
    if (newWindow && !newWindow.closed) {
      newWindow.focus();
    }
  }
};
