// Removes a query string parameter from the current URL
// e.g. if we are at http://localhost:8080/?referral=value&group=x
// and this gets called like removeQueryStringParameter('referral')
// then the URL will be changed to http://localhost:8080/?group=x
// The page will not be reloaded.
function removeQueryStringParameter(name) {
  const pathname = window.location.pathname;
  const initialQueryString = window.location.search;
  const newQueryString = removeURLParameter(initialQueryString, name);
  window.history.replaceState({}, null, pathname + newQueryString);
}

function removeURLParameter(url, parameter) {
  //prefer to use l.search if you have a location/link object
  var urlparts = url.split('?');
  if (urlparts.length >= 2) {
    var prefix = encodeURIComponent(parameter) + '=';
    var pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0; ) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  }
  return url;
}

module.exports = removeQueryStringParameter;
