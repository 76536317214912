const config = require('../../../config');
const strings = config.strings;
const { infoTextRow, subheading } = require('../../page_helpers/components');

module.exports = ({ nav, data }) => {
  const group = data;

  const user = window.state.userData;
  const avatar = group.personal
    ? user.avatar || config.app.DEFAULT_USER_ICON
    : group.icon || config.app.DEFAULT_GROUP_ICON;

  const avatar_id = group.personal ? user.id : group.id;

  return {
    template: 'block-section-page',
    fields: {
      id: 'no-login-found',
      header: strings.EXTENSION_NOLOGINFOUND_HEADER(),
      navigation_left: strings.PROFILE_LINK_LOGOUT(),
      navigation_left_action: 'logout',
      navbar: false,

    },
    rows: [
      infoTextRow(strings.EXTENSION_NOLOGINFOUND_MSG(), true),
      {
        template: 'block-section-row',
        fields: {
          navigation_data: nav + 1,
          navigation_template: 'extension_chooseGroup',
        },
        columns: [
          {
            template: 'block-login-avatar',
            fields: { avatar: avatar, data_id: avatar_id },
          },
          {
            template: 'block-text-group',
            fields: { primary_text: group.name },
          },
          {
            template: 'block-icn-button',
            fields: { icon: 'chevron-right' },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: { class: 'no-border' },
        columns: [{ template: 'block-doodlepad' }],
      },
      {
        template: 'block-doodlepad-buttons',
        fields: {
          confirm_data: nav + 1,
          confirm_template: 'extension_addLogin_connecting',
          confirm_validation: 'validateDoodlepad',
          confirm_action: 'extensionAddLogin',
        },
      },
    ],
  };
};
