const packageJson = require('../../../package.json');
const config = require('../../../config');
const strings = config.strings;

function linkRow(title, action) {
  return {
    template: 'block-section-row',
    fields: { action },
    columns: [
      {
        template: 'block-text-group',
        fields: { primary_text: title },
      },
    ],
  };
}

const version = () => {
  let version;
  if (window.cordova) {
    version = `mobile.${packageJson.mobile_version}.${packageJson.version.split('.').slice(-1)}`;
  } else if (window.state.electron) {
    version = `desktop.${packageJson.electron_version}.${packageJson.version.split('.').slice(-1)}`;
  } else if (window.state.extension) {
    version = `extension.${packageJson.extension_version}.${packageJson.version.split('.').slice(-1)}`;
  } else {
    version = packageJson.version;
  }
  return version;
};
module.exports = ({ nav }) => ({
  template: 'block-section-page',
  fields: {
    id: 'view-about-list',
    header: strings.PROFILE_ABOUT_HEADER(),
    navigation_left: strings.BACK(),
    navigation_left_icon: '',
    navigation_left_data: Math.max(nav - 1, 0),
    navigation_left_template: 'back',
  },
  rows: [
    // =======================================================================
    linkRow(strings.PROFILE_ABOUT_TERMS(), 'openTermsOfServiceUrl'),
    linkRow(strings.PROFILE_ABOUT_PRIVACY(), 'openPrivacyPolicyUrl'),
    linkRow(strings.PROFILE_ABOUT_ACCEPTABLE(), 'openAcceptableUseUrl'),
    linkRow(strings.PROFILE_ABOUT_COOKIE(), 'openCookiePolicyUrl'),
    // =======================================================================
    {
      template: 'block-section-row',
      fields: { secondary_heading: strings.PROFILE_ABOUT_VERSION_HEADING() },
      columns: [
        {
          template: 'block-text-group',
          fields: { primary_text: version() },
        },
      ],
    },
  ],
});
