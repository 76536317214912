const showAlertMessage = require('../../action_helpers/showAlertMessage');
const getResponseErrorMessage = require('../../action_helpers/getResponseErrorMessage');
const { JOIN_GORUP_ALERT_MESSAGE } = require('../../../config/strings');
module.exports = ({ z2hApp, code }) => {
  let _code = code || $('#join-group-invite-code').val();

  if (!_code) return;

  // If it looks like the invite code is actually an invite URL, extract just the code
  if (_code.includes('invite=')) {
    _code = _code.split('invite=')[1];
    _code = _code.split('&')[0]; // Check that the URL didn't include anything else, like "...&blah=123"
  }

  let groupId;

  showAlertMessage(JOIN_GORUP_ALERT_MESSAGE());

  return z2hApp
    .requestData('post', '', 'groups/join', { invite_id: _code })
    .then(response => {
      /************
      Example response from groups/join:
      {
        status: 200,
        data: {
          current_uses: 1, expiry_date: 1541515827138, group_id: "-LHchgizO_LdwlQRrKI0", id: "5E_CyUprW",…}
          current_uses: 1
          expiry_date: 1541515827138
          group_id: "-LHchgizO_LdwlQRrKI0"
          id: "5E_CyUprW"
          link_creation_date: 1541429427138
          link_creator_id: "gxjOWLvAkBO8QSDhMBLdk5eAVYD2"
          maximum_uses: 1
          temporary_redirect: "https://test-z2h-invite.firebaseapp.com/5E_CyUprW---this-link-was-actually-successful-but-this-is-just-a-placeholder-path"
        }
      }
      *************/

      if (Math.floor(response.status / 100) !== 2) {
        throw new Error(getResponseErrorMessage(response));
      }
      groupId = response.data.group_id;
      // Fetch the group details so we can display the name of the group
      // in the success info box and add them to the list of displayed groups
      //return z2hApp.requestData('get', '', `groups/${groupId}`);
      //return Promise.resolve();
      return Promise.resolve(response);
    })
    .then(response => {
      if (Math.floor(response.status / 100) !== 2) {
        throw new Error(getResponseErrorMessage(response));
      }

      const group = response.data;
      group.id = groupId;

      //I don't know why, but preflight checks are failing so let's have a bodge
      setTimeout(() => {
        //z2hApp.reloadFullGroupsList().then(_ => {
        window.state.fullUserGroupsPromise.then(_=> {


          window.state.launchData.groups_list = window.state.groupsList;
          localStorage.setItem('launch', JSON.stringify(window.state.launchData));

          // Refresh displayed groups list
          //z2hApp.refreshGroupsInNav();
          z2hApp.paneNavigation('groupsList', $('#pane-1'), 0, null);
          //z2hApp.reloadUpdateSections();
          //window.state.groupsList.push(group);
          const addedGroup = window.state.groupsList.find(g => g.id === groupId);
          if (addedGroup.name) showAlertMessage(`You have joined the "${addedGroup.name}" group`, false, 'Group Joined');
          else showAlertMessage(`You have joined a new group`, false, 'Group Joined');
        });
      })

    }, 100)

    .catch(err => {
      showAlertMessage(`Your group invite link cannot be used:\n ${err.message}`, false, 'An error occurred');
    });
};
