const config = require('../../../config');
const { infoTextRow, thirdheading, primaryText } = require('../../page_helpers/components');
const actions = require('../../actions');

const { strings } = config;
const {
  BACK,
  ADD_SERVICE_USERNAME_PLACEHOLDER,
  ADD_SERVICE_USERNAME,
  SEARCH,
  CANCEL,
  SKIP,
  ADD_SERVICE_USERNAME_HEADER,
  ADD_SERVICE_SUGGESTED_USERNAMES,
  LETS_START_ADDING_DETAILS,
  SERVICE_EMAIL_LABEL,
  USERNAME_OR_EMAIL_SECOND,
  USERNAME_OR_EMAIL,
} = strings;
const { USERNAME_NAME_MAXLENGTH } = require('../../../config/app');

module.exports = ({ nav, data }) => {
  // Focus on input field after 1 second
  setTimeout((_) => $('#addService_username .service-username').focus(), 1000);

  const serviceName = window.z2hApp.pageData.service.name;
  window.z2hApp.pageData.service.username = '';

  return {
    template: 'block-section-page',
    fields: {
      id: 'addUsernameToExistingService',
      //header: ADD_SERVICE_USERNAME_HEADER(),
      navigation_left: BACK(),
      navigation_left_template: 'back',
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_right: SKIP(),
      navigation_right_action: 'saveUsername',
      navigation_right_data: nav + 1,
      navigation_right_template: 'general_loading',
      navbar: false,
      class: ['no-title', 'no-border'],
    },
    rows: [
      {
        template: 'block-section-row',
        fields: {
          primary_heading: LETS_START_ADDING_DETAILS(),
          class: ['no-border', 'align-left'],
          primary_heading_class: 'remove:primary-heading',
          column_class: 'align-left',
        },
      },

      {
        template: 'block-section-row',
        fields: {
          class: 'no-border',
        },
        columns: [
          {
            template: 'block-info-text',
            fields: {
              text: strings.SETUP_NEW_SERVICE_MESSAGE(),
            },
          },
        ],
      },

      {
        template: 'block-section-row',
        fields: {
          secondary_heading: USERNAME_OR_EMAIL(),
          class: ['no-border'],
        },
        columns: [
          {
            template: 'block-input-box',
            fields: {
              class: 'remove:input',
              id: 'add-service-username-input',
              value: '',
              maxlength: USERNAME_NAME_MAXLENGTH,
            },
          },
        ],
      },

      {
        template: 'block-section-row',
        fields: {
          secondary_heading: USERNAME_OR_EMAIL_SECOND(),
          class: ['no-border'],
        },
        columns: [
          {
            template: 'block-input-box',
            fields: {
              class: 'remove:input',
              id: 'add-service-username-secondary-input',
              value: '',
              maxlength: USERNAME_NAME_MAXLENGTH,
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          primary_heading_html: '&nbsp;',
          class: ['no-border', 'align-left'],
          column_class: 'align-left',
        },
      },
      {
        template: 'block-section-row',
        fields: {
          class: ['grow', 'no-border'],
        },
        columns: [
          {
            template: 'block-icn-button',
            fields: {
              icon: 'check-solid',
              action: 'saveUsername',
              class: 'icn-button-lrg',
              navigation_template: 'general_loading',
              navigation_data: nav + 1,
            },
          },
        ],
      },
    ],
  };
};

$('body').on('keyup', '#addService_username #add-service-username-input', (e) => {
  // if (e.key === 'Enter') {
  //   $('#addService_username [data-actionclick=addService_submitUsername]').click();
  //   return;
  // }

  // const tickIcon = document.querySelector('[data-actionclick="addService_submitUsername"');
  // e.target.value === '' ? tickIcon.classList.remove('active') : tickIcon.classList.add('active');

  $('[data-id="suggestedusernames"]').trigger('keyup', e.target.value);
});
