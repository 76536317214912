module.exports = [
  {
    name: "3",
    url: "img/logos/3_180px.png"
  },
  {
    name: "24 Hour Fitness",
    url: "img/logos/24-hour-fitness_180px.png"
  },
  {
    name: "Absolute Radio",
    url: "img/logos/absolute-radio_180px.png"
  },
  {
    name: "Adidas",
    url: "img/logos/addidas_180px.png"
  },
  {
    name: "Adobe Creative Cloud",
    url: "img/logos/adobe-creative-cloud_180px.png"
  },
  {
    name: "Ahold",
    url: "img/logos/ahold_180px.png"
  },
  {
    name: "AirBnB",
    url: "img/logos/airbnb_180px.png"
  },
  {
    name: "Alcampo",
    url: "img/logos/alcampo_180px.png"
  },
  {
    name: "Aldi",
    url: "img/logos/aldi_180px.png"
  },
  {
    name: "Ali",
    url: "img/logos/ali_180px.png"
  },
  {
    name: "Amazon Music",
    url: "img/logos/amazon-music_180px.png"
  },
  {
    name: "Amazon",
    url: "img/logos/amazon_180px.png",
    address: "amazon.com"
  },
  {
    name: "American Airlines",
    url: "img/logos/american-airlines_180px.png"
  },
  {
    name: "American Express",
    url: "img/logos/american-express_180px.png"
  },
  {
    name: "Anytime Fitness",
    url: "img/logos/anytime-fitness_180px.png"
  },
  {
    name: "Apple Music",
    url: "img/logos/apple-music_180px.png"
  },
  {
    name: "Apple",
    url: "img/logos/apple_180px.png",
    address: "apple.com"
  },
  {
    name: "ASDA",
    url: "img/logos/asda_180px.png"
  },
  {
    name: "ASOS",
    url: "img/logos/asos_180px.png"
  },
  {
    name: "Associated Press",
    url: "img/logos/associated-press_180px.png"
  },
  {
    name: "Auchan",
    url: "img/logos/auchan_180px.png"
  },
  {
    name: "Audi",
    url: "img/logos/audi_180px.png"
  },
  {
    name: "Audible",
    url: "img/logos/audible_180px.png"
  },
  {
    name: "Aviva",
    url: "img/logos/aviva_180px.png"
  },
  {
    name: "AWS",
    url: "img/logos/aws_180px.png",
    address: "aws.amazon.com"
  },
  {
    name: "AXA",
    url: "img/logos/axa_180px.png"
  },
  {
    name: "BA",
    url: "img/logos/ba_180px.png"
  },
  {
    name: "Bank of Ireland",
    url: "img/logos/bank-of-ireland_180px.png"
  },
  {
    name: "Bank of Scotland",
    url: "img/logos/bank-of-scotland_180px.png"
  },
  {
    name: "Bannatyne",
    url: "img/logos/bannatyne_180px.png"
  },
  {
    name: "Barclays",
    url: "img/logos/barclays_180px.png",
    address: "barclays.com"
  },
  {
    name: "BBC iPlayer",
    url: "img/logos/bbc-iplayer_180px.png"
  },
  {
    name: "BBC News",
    url: "img/logos/bbc-news_180px.png"
  },
  {
    name: "BBC Sport",
    url: "img/logos/bbc-sport_180px.png"
  },
  {
    name: "Bennet",
    url: "img/logos/bennet_180px.png"
  },
  {
    name: "Bentley",
    url: "img/logos/bentley_180px.png"
  },
  {
    name: "Best Western",
    url: "img/logos/best-western_180px.png"
  },
  {
    name: "Vlackrock",
    url: "img/logos/blackrock_180px.png"
  },
  {
    name: "BMW",
    url: "img/logos/bmw_180px.png"
  },
  {
    name: "Boden",
    url: "img/logos/boden_180px.png"
  },
  {
    name: "Boots",
    url: "img/logos/boots_180px.png"
  },
  {
    name: "BP",
    url: "img/logos/bp_180px.png"
  },
  {
    name: "British Journal of Anaesthesia",
    url: "img/logos/british-journal-of-anaesthesia_180px.png"
  },
  {
    name: "British Vogue",
    url: "img/logos/british-vogue_180px.png"
  },
  {
    name: "Brittany Ferries",
    url: "img/logos/brittany-ferries_180px.png"
  },
  {
    name: "BT Sport",
    url: "img/logos/bt-sport_180px.png"
  },
  {
    name: "BT",
    url: "img/logos/bt_180px.png"
  },
  {
    name: "Bupa",
    url: "img/logos/bupa_180px.png"
  },
  {
    name: "Burberry",
    url: "img/logos/burberry_180px.png"
  },
  {
    name: "Calvin Klein",
    url: "img/logos/calvin-klein_180px.png"
  },
  {
    name: "Carrefour",
    url: "img/logos/carrefour_180px.png"
  },
  {
    name: "Castorama",
    url: "img/logos/castorama_180px.png"
  },
  {
    name: "Chevrolet",
    url: "img/logos/chevrolet_180px.png"
  },
  {
    name: "Citibank",
    url: "img/logos/citibank_180px.png"
  },
  {
    name: "Classic FM",
    url: "img/logos/classic-fm_180px.png"
  },
  {
    name: "CNN",
    url: "img/logos/cnn_180px.png"
  },
  {
    name: "Co-Operativebank",
    url: "img/logos/co-operativebank_180px.png"
  },
  {
    name: "Conad",
    url: "img/logos/conad_180px.png"
  },
  {
    name: "Coop Italia",
    url: "img/logos/coop-italia_180px.png"
  },
  {
    name: "Cosmo UK",
    url: "img/logos/cosmo-uk_180px.png"
  },
  {
    name: "Costa",
    url: "img/logos/costa_180px.png"
  },
  {
    name: "Coutts",
    url: "img/logos/coutts_180px.png"
  },
  {
    name: "Coventry Building Society",
    url: "img/logos/coventry-building-society_180px.png"
  },
  {
    name: "Crai",
    url: "img/logos/crai_180px.png"
  },
  {
    name: "David Lloyd",
    url: "img/logos/david-lloyd_180px.png"
  },
  {
    name: "Debenhams",
    url: "img/logos/debenhams_180px.png"
  },
  {
    name: "Decathlon",
    url: "img/logos/decathlon_180px.png"
  },
  {
    name: "Deco",
    url: "img/logos/deco_180px.png"
  },
  {
    name: "Delta",
    url: "img/logos/delta_180px.png"
  },
  {
    name: "Dia Espania",
    url: "img/logos/dia-espania_180px.png"
  },
  {
    name: "Dia",
    url: "img/logos/dia_180px.png"
  },
  {
    name: "Dior",
    url: "img/logos/dior_180px.png"
  },
  {
    name: "Directline",
    url: "img/logos/directline_180px.png"
  },
  {
    name: "DM Drogeriemarkt",
    url: "img/logos/dm-drogeriemarkt_180px.png"
  },
  {
    name: "Dodge",
    url: "img/logos/dodge_180px.png"
  },
  {
    name: "Dolce & Gabbana",
    url: "img/logos/dolce-&-gabbana_180px.png"
  },
  {
    name: "Dropbox",
    url: "img/logos/dropbox_180px.png"
  },
  {
    name: "E Leclerc",
    url: "img/logos/e-leclerc_180px.png"
  },
  {
    name: "Easyjet",
    url: "img/logos/easyjet_180px.png"
  },
  {
    name: "Ebay",
    url: "img/logos/ebay_180px.png",
    address: "ebay.com"
  },
  {
    name: "EE",
    url: "img/logos/ee_180px.png"
  },
  {
    name: "Elcorte Ingles",
    url: "img/logos/elcorte-ingles_180px.png"
  },
  {
    name: "Elle UK",
    url: "img/logos/elle-uk_180px.png"
  },
  {
    name: "Eroski",
    url: "img/logos/eroski_180px.png"
  },
  {
    name: "Esprit",
    url: "img/logos/esprit_180px.png"
  },
  {
    name: "Euro-spin",
    url: "img/logos/euro-spin_180px.png"
  },
  {
    name: "Euronics",
    url: "img/logos/euronics_180px.png"
  },
  {
    name: "Expedia",
    url: "img/logos/expedia_180px.png"
  },
  {
    name: "Facebook",
    url: "img/logos/facebook_180px.png",
    address: "facebook.com"
  },
  {
    name: "Familos",
    url: "img/logos/familos_180px.png"
  },
  {
    name: "Fatface",
    url: "img/logos/fatface_180px.png"
  },
  {
    name: "First Direct",
    url: "img/logos/first-direct_180px.png"
  },
  {
    name: "Fitbit",
    url: "img/logos/fitbit_180px.png"
  },
  {
    name: "Flybe",
    url: "img/logos/flybe_180px.png"
  },
  {
    name: "Ford",
    url: "img/logos/ford_180px.png",
    address: "ford.com"
  },
  {
    name: "Fortnite",
    url: "img/logos/fortnite_180px.png"
  },
  {
    name: "Freecycle",
    url: "img/logos/freecycle_180px.png"
  },
  {
    name: "Garmin",
    url: "img/logos/garmin_180px.png"
  },
  {
    name: "General Motors",
    url: "img/logos/general-motors_180px.png"
  },
  {
    name: "Gillette",
    url: "img/logos/gillette_180px.png"
  },
  {
    name: "Github",
    url: "img/logos/github_180px.png",
    address: "github.com"
  },
  {
    name: "Gmail",
    url: "img/logos/gmail_180px.png",
    address: "gmail.com"
  },
  {
    name: "GMC",
    url: "img/logos/gmc_180px.png"
  },
  {
    name: "Goldman Sachs",
    url: "img/logos/goldman-sachs_180px.png"
  },
  {
    name: "Goldmoney",
    url: "img/logos/goldmoney_180px.png"
  },
  {
    name: "Golds Gym",
    url: "img/logos/golds-gym_180px.png"
  },
  {
    name: "Google Chrome",
    url: "img/logos/google-chrome_180px.png"
  },
  {
    name: "Google",
    url: "img/logos/google_180px.png",
    address: "google.com"
  },
  {
    name: "GoPro",
    url: "img/logos/gopro_180px.png"
  },
  {
    name: "Gucci",
    url: "img/logos/gucci_180px.png"
  },
  {
    name: "Gumtree",
    url: "img/logos/gumtree_180px.png",
    address: "gumtree.com"
  },
  {
    name: "H&M",
    url: "img/logos/h-&-m_180px.png"
  },
  {
    name: "Halifax",
    url: "img/logos/halifax_180px.png"
  },
  {
    name: "Harpers Bazaar",
    url: "img/logos/harpers-bazaar_180px.png"
  },
  {
    name: "Hilton",
    url: "img/logos/hilton_180px.png"
  },
  {
    name: "Hipercor",
    url: "img/logos/hipercor_180px.png"
  },
  {
    name: "HMRC",
    url: "img/logos/hmrc_180px.png"
  },
  {
    name: "Holiday-inn",
    url: "img/logos/holiday-inn_180px.png"
  },
  {
    name: "Home",
    url: "img/logos/home_180px.png"
  },
  {
    name: "Honda",
    url: "img/logos/honda_180px.png"
  },
  {
    name: "Hootsuite",
    url: "img/logos/hootsuite_180px.png"
  },
  {
    name: "House of Fraser",
    url: "img/logos/house-of-fraser_180px.png"
  },
  {
    name: "HSBC",
    url: "img/logos/hsbc_180px.png"
  },
  {
    name: "Hubspot",
    url: "img/logos/hubspot_180px.png"
  },
  {
    name: "Hyundai",
    url: "img/logos/hyundai_180px.png"
  },
  {
    name: "iCloud",
    url: "img/logos/icloud_180px.png",
    address: "icloud.com"
  },
  {
    name: "Ikea",
    url: "img/logos/ikea_180px.png",
    address: "ikea.com"
  },
  {
    name: "Instagram",
    url: "img/logos/instagram_180px.png",
    address: "instagram.com"
  },
  {
    name: "iTunes",
    url: "img/logos/itunes_180px.png",
    address: "itunes.com"
  },
  {
    name: "itv hub",
    url: "img/logos/itv-hub_180px.png"
  },
  {
    name: "J P Morgan",
    url: "img/logos/j-p-morgan_180px.png"
  },
  {
    name: "Jaguar",
    url: "img/logos/jaguar_180px.png"
  },
  {
    name: "JoMalone",
    url: "img/logos/jo-malone_180px.png"
  },
  {
    name: "John Lewis",
    url: "img/logos/john-lewis_180px.png"
  },
  {
    name: "Jurysinn",
    url: "img/logos/jurysinn_180px.png"
  },
  {
    name: "Kindle",
    url: "img/logos/kindle_180px.png"
  },
  {
    name: "KLM",
    url: "img/logos/klm_180px.png"
  },
  {
    name: "Konsumgoettin",
    url: "img/logos/konsumgoettin_180px.png"
  },
  {
    name: "Kroger",
    url: "img/logos/kroger_180px.png"
  },
  {
    name: "LA Fitness",
    url: "img/logos/la-fitness_180px.png"
  },
  {
    name: "La1 Tve",
    url: "img/logos/la1-tve_180px.png"
  },
  {
    name: "La2 Tve",
    url: "img/logos/la2-tve_180px.png"
  },
  {
    name: "Lamborghini",
    url: "img/logos/lamborghini_180px.png"
  },
  {
    name: "Lastminute.com",
    url: "img/logos/lastminute.com_180px.png"
  },
  {
    name: "Laterooms.com",
    url: "img/logos/laterooms.com_180px.png"
  },
  {
    name: "Leeds Building Society",
    url: "img/logos/leeds-building-society_180px.png"
  },
  {
    name: "Legoland Windsor",
    url: "img/logos/legoland-windsor_180px.png"
  },
  {
    name: "Leroy Merlin",
    url: "img/logos/leroy-merlin_180px.png"
  },
  {
    name: "Lidl Italia",
    url: "img/logos/lidl-italia_180px.png"
  },
  {
    name: "Lidl",
    url: "img/logos/lidl_180px.png"
  },
  {
    name: "Linkedin",
    url: "img/logos/linked-in_180px.png"
  },
  {
    name: "Lloyds Bank",
    url: "img/logos/lloyds-bank_180px.png"
  },
  {
    name: "Lloyds Pharmacy",
    url: "img/logos/lloyds-pharmacy_180px.png"
  },
  {
    name: "Loreal",
    url: "img/logos/loreal_180px.png"
  },
  {
    name: "Louis Vuitton",
    url: "img/logos/louis-vuitton_180px.png"
  },
  {
    name: "Lush",
    url: "img/logos/lush_180px.png"
  },
  {
    name: "LV",
    url: "img/logos/lv_180px.png"
  },
  {
    name: "M&S",
    url: "img/logos/m&s_180px.png"
  },
  {
    name: "Mailchimp",
    url: "img/logos/mailchimp_180px.png"
  },
  {
    name: "Map My Run",
    url: "img/logos/map-my-run_180px.png"
  },
  {
    name: "Marie Claire UK",
    url: "img/logos/marie-claire-uk_180px.png"
  },
  {
    name: "Marriott",
    url: "img/logos/marriott_180px.png"
  },
  {
    name: "Maserati",
    url: "img/logos/maserati_180px.png"
  },
  {
    name: "Mastercard",
    url: "img/logos/mastercard_180px.png"
  },
  {
    name: "Media Markt",
    url: "img/logos/media-markt_180px.png"
  },
  {
    name: "Mediamarktsaturn",
    url: "img/logos/mediamarktsaturn_180px.png"
  },
  {
    name: "Meet Edgar",
    url: "img/logos/meet-edgar_180px.png"
  },
  {
    name: "Mercadona",
    url: "img/logos/mercadona_180px.png"
  },
  {
    name: "Mercedes Benz",
    url: "img/logos/mercedes-benz_180px.png"
  },
  {
    name: "Microsoft",
    url: "img/logos/microsoft_180px.png"
  },
  {
    name: "Monster Energy",
    url: "img/logos/monster-energy_180px.png"
  },
  {
    name: "Morgan Stanley",
    url: "img/logos/morgan-stanley_180px.png"
  },
  {
    name: "Morrisons",
    url: "img/logos/morrisons_180px.png"
  },
  {
    name: "Nationwide",
    url: "img/logos/nationwide_180px.png"
  },
  {
    name: "Natwest",
    url: "img/logos/natwest_180px.png"
  },
  {
    name: "Nespresso",
    url: "img/logos/nespresso_180px.png"
  },
  {
    name: "Nest",
    url: "img/logos/nest_180px.png"
  },
  {
    name: "Nestle",
    url: "img/logos/nestle_180px.png"
  },
  {
    name: "Netflix",
    url: "img/logos/netflix_180px.png"
  },
  {
    name: "Next",
    url: "img/logos/next_180px.png"
  },
  {
    name: "Nike",
    url: "img/logos/nike_180px.png"
  },
  {
    name: "Nintendo",
    url: "img/logos/nintendo_180px.png"
  },
  {
    name: "Nuffield Health",
    url: "img/logos/nuffield-health_180px.png"
  },
  {
    name: "o2",
    url: "img/logos/o2_180px.png"
  },
  {
    name: "Ocado",
    url: "img/logos/ocado_180px.png"
  },
  {
    name: "One Plus",
    url: "img/logos/one-plus_180px.png"
  },
  {
    name: "Otto",
    url: "img/logos/otto_180px.png"
  },
  {
    name: "Pando",
    url: "img/logos/pando_180px.png"
  },
  {
    name: "Parkrun",
    url: "img/logos/parkrun_180px.png"
  },
  {
    name: "Partnership Card",
    url: "img/logos/partnership-card_180px.png"
  },
  {
    name: "Paultons Park",
    url: "img/logos/paultons-park_180px.png"
  },
  {
    name: "Paypal",
    url: "img/logos/paypal_180px.png"
  },
  {
    name: "Piccolo",
    url: "img/logos/piccolo_180px.png"
  },
  {
    name: "Places for People",
    url: "img/logos/places-for-people_180px.png"
  },
  {
    name: "Play Station",
    url: "img/logos/play-station_180px.png"
  },
  {
    name: "Playz",
    url: "img/logos/playz_180px.png"
  },
  {
    name: "Porsche",
    url: "img/logos/porsche_180px.png"
  },
  {
    name: "Poste Italiane",
    url: "img/logos/poste-italiane_180px.png"
  },
  {
    name: "Premier Inn",
    url: "img/logos/premier-inn_180px.png"
  },
  {
    name: "Prime Video",
    url: "img/logos/prime-video_180px.png"
  },
  {
    name: "Primelocation",
    url: "img/logos/primelocation_180px.png"
  },
  {
    name: "Procter & Gamble",
    url: "img/logos/procter-&-gamble_180px.png"
  },
  {
    name: "Pure Gym",
    url: "img/logos/pure-gym_180px.png"
  },
  {
    name: "Ralph Lauren",
    url: "img/logos/ralph-lauren_180px.png"
  },
  {
    name: "RBS",
    url: "img/logos/rbs_180px.png"
  },
  {
    name: "Redbull",
    url: "img/logos/redbull_180px.png"
  },
  {
    name: "Reuters",
    url: "img/logos/reuters_180px.png"
  },
  {
    name: "Revolut",
    url: "img/logos/revolut-1_180px.png"
  },
  {
    name: "Revolut (white)",
    url: "img/logos/revolut-white_180px.png"
  },
  {
    name: "Revolut",
    url: "img/logos/revolut_180px.png"
  },
  {
    name: "Rewe",
    url: "img/logos/rewe_180px.png"
  },
  {
    name: "Rightmove",
    url: "img/logos/rightmove_180px.png"
  },
  {
    name: "Ringo",
    url: "img/logos/ringo_180px.png"
  },
  {
    name: "Royal College of Anaesthetists",
    url: "img/logos/royal-college-of-anaesthetists_180px.png"
  },
  {
    name: "Royal College of Physicians",
    url: "img/logos/royal-college-of-physicians_180px.png"
  },
  {
    name: "Ryanair",
    url: "img/logos/ryanair_180px.png"
  },
  {
    name: "Sainsburies",
    url: "img/logos/sainsburies_180px.png"
  },
  {
    name: "Sainsburys Bank",
    url: "img/logos/sainsburys-bank_180px.png"
  },
  {
    name: "Sainsburys",
    url: "img/logos/sainsburys_180px.png"
  },
  {
    name: "Santander Bank",
    url: "img/logos/santander-bank_180px.png"
  },
  {
    name: "Scottish Widows",
    url: "img/logos/scottish-widows_180px.png"
  },
  {
    name: "Selfridges",
    url: "img/logos/selfridges_180px.png"
  },
  {
    name: "Shell",
    url: "img/logos/shell_180px.png",
    address: "shell.com"
  },
  {
    name: "Skipton Building Society",
    url: "img/logos/skipton-building-society_180px.png"
  },
  {
    name: "Sky",
    url: "img/logos/sky_180px.png",
    address: "sky.com"
  },
  {
    name: "Skype",
    url: "img/logos/skype_180px.png",
    address: "skype.com"
  },
  {
    name: "Sligro",
    url: "img/logos/sligro_180px.png"
  },
  {
    name: "Sports Direct",
    url: "img/logos/sports-direct_180px.png"
  },
  {
    name: "Spotify",
    url: "img/logos/spotify_180px.png",
    address: "spotify.com"
  },
  {
    name: "Squarespace",
    url: "img/logos/squarespace_180px.png",
    address: "squarespace.com"
  },
  {
    name: "Starbucks",
    url: "img/logos/starbucks_180px.png",
    address: "starbucks.com"
  },
  {
    name: "Stena Line",
    url: "img/logos/stena-line_180px.png"
  },
  {
    name: "Strava",
    url: "img/logos/strava_180px.png"
  },
  {
    name: "Stripe",
    url: "img/logos/stripe_180px.png"
  },
  {
    name: "Superdrug",
    url: "img/logos/superdrug_180px.png"
  },
  {
    name: "Supermercato24",
    url: "img/logos/supermercato24_180px.png"
  },
  {
    name: "Talk Sport",
    url: "img/logos/talk-sport_180px.png"
  },
  {
    name: "Telegraph",
    url: "img/logos/telegraph_180px.png"
  },
  {
    name: "Tesco Bank",
    url: "img/logos/tesco-bank_180px.png"
  },
  {
    name: "Tesco",
    url: "img/logos/tesco_180px.png",
    address: "tesco.com"
  },
  {
    name: "The Body Shop",
    url: "img/logos/the-body-shop_180px.png"
  },
  {
    name: "The Economist",
    url: "img/logos/the-economist_180px.png"
  },
  {
    name: "The Gym Group",
    url: "img/logos/the-gym-group_180px.png"
  },
  {
    name: "The Suffer Fest",
    url: "img/logos/the-suffer-fest_180px.png"
  },
  {
    name: "Toggl",
    url: "img/logos/toggl_180px.png"
  },
  {
    name: "Tommy Hilfiger",
    url: "img/logos/tommy-hilfiger_180px.png"
  },
  {
    name: "Total Fitness",
    url: "img/logos/total-fitness_180px.png"
  },
  {
    name: "Toyota",
    url: "img/logos/toyota_180px.png"
  },
  {
    name: "Trainer Road",
    url: "img/logos/trainer-road_180px.png"
  },
  {
    name: "Transferwise",
    url: "img/logos/transferwise_180px.png"
  },
  {
    name: "Travelodge",
    url: "img/logos/travelodge_180px.png"
  },
  {
    name: "Trello",
    url: "img/logos/trello-com_180px.png",
    address: "trello.com"
  },
  {
    name: "TSB",
    url: "img/logos/tsb_180px.png"
  },
  {
    name: "Tunein Radio",
    url: "img/logos/tunein-radio_180px.png"
  },
  {
    name: "Twitter",
    url: "img/logos/twitter_180px.png",
    address: "twitter.com"
  },
  {
    name: "Ubisoft",
    url: "img/logos/ubisoft_180px.png"
  },
  {
    name: "Under Armour",
    url: "img/logos/under-armour_180px.png"
  },
  {
    name: "Unilever",
    url: "img/logos/unilever_180px.png"
  },
  {
    name: "United Airlines",
    url: "img/logos/united-airlines_180px.png"
  },
  {
    name: "Virgin Active",
    url: "img/logos/virgin-active_180px.png"
  },
  {
    name: "Virgin Atlantic",
    url: "img/logos/virgin-atlantic_180px.png"
  },
  {
    name: "Virgin Media",
    url: "img/logos/virgin-media_180px.png"
  },
  {
    name: "Virgin Money",
    url: "img/logos/virgin-money_180px.png"
  },
  {
    name: "Virgin Radio",
    url: "img/logos/virgin-radio_180px.png"
  },
  {
    name: "Virgin",
    url: "img/logos/virgin_180px.png"
  },
  {
    name: "Visa",
    url: "img/logos/visa_180px.png"
  },
  {
    name: "Vitality",
    url: "img/logos/vitality_180px.png"
  },
  {
    name: "Vodafone",
    url: "img/logos/vodafone_180px.png"
  },
  {
    name: "Volkswagen",
    url: "img/logos/volkswagen_180px.png"
  },
  {
    name: "Waitrose",
    url: "img/logos/waitrose_180px.png",
    address: "waitrose.com"
  },
  {
    name: "Wall Street Journal",
    url: "img/logos/wall-street-journal_180px.png"
  },
  {
    name: "Washington Post",
    url: "img/logos/washington-post_180px.png"
  },
  {
    name: "Weleda",
    url: "img/logos/weleda_180px.png"
  },
  {
    name: "White Stuff",
    url: "img/logos/white-stuff_180px.png"
  },
  {
    name: "Wightlink",
    url: "img/logos/wightlink_180px.png"
  },
  {
    name: "Winchester Rackets & Fitness",
    url: "img/logos/winchester-rackets-fitness_180px.png"
  },
  {
    name: "Windows",
    url: "img/logos/windows_180px.png"
  },
  {
    name: "Xbox",
    url: "img/logos/xbox_180px.png",
    address: "xbox.com"
  },
  {
    name: "Xero",
    url: "img/logos/xero_180px.png"
  },
  {
    name: "Yorkshire Building Society",
    url: "img/logos/yorkshire-building-society_180px.png"
  },
  {
    name: "Youtube",
    url: "img/logos/youtube_180px.png",
    address: "youtube.com"
  },
  {
    name: "Zalando",
    url: "img/logos/zalando_180px.png"
  },
  {
    name: "Zara",
    url: "img/logos/zara_180px.png"
  },
  {
    name: "Zoopla",
    url: "img/logos/zoopla_180px.png"
  },
  {
    name: "Zuba Ski",
    url: "img/logos/zubaski_180px.png"
  },
  {
    name: "Zwift",
    url: "img/logos/zwift_180px.png"
  }
];
