const errorManager = require('../utils/errorManager');
const strings = require('../../config').strings;
const { HIBP_USERNAME_MAXLENGTH } = require('../../config/app');
// This validator is probably not necessary as profile name can be blank?
module.exports = section => {
  errorManager.clearErrors(section);

  const username1Field = $(section)
    .find('#healthcheck_primary_username_1')
  const value = username1Field.val();
  // const username2Field = $(section)
  //   .find('#healthcheck_primary_username_2')
  // const value2 = username2Field.val();

  let error = false;

  // Check for empty profile name
  if (value.trim().length === 0) {
    error = true;
    errorManager.reportError(strings.HEALTHCHECK_USERNAME_VAL_FIELD_BLANK(), username1Field);
  }

  // if (!error && value === value2) {
  //   error = true;
  //   errorManager.reportError(strings.HEALTHCHECK_USERNAME_VAL_FIELD_DUPLICATE, username2Field);
  // }

  if (!error) {
    if (value.trim().length > 0 && value.trim().length <= 3) {
      error = true;
      errorManager.reportError(strings.HEALTHCHECK_USERNAME_VAL_FIELD_TOO_SHORT(), username1Field);
    }

    // if (value2.trim().length > 0 && value2.trim().length <= 3) {
    //   error = true;
    //   errorManager.reportError(strings.HEALTHCHECK_USERNAME_VAL_FIELD_TOO_SHORT, username2Field);
    // }

    if (value.trim().length > HIBP_USERNAME_MAXLENGTH) {
      error = true;
      errorManager.reportError(strings.HEALTHCHECK_USERNAME_VAL_FIELD_TOO_LONG(), username1Field);
    }

    // if (value2.trim().length > 64) {
    //   error = true;
    //   errorManager.reportError(strings.HEALTHCHECK_USERNAME_VAL_FIELD_TOO_LONG, username2Field);
    // }
  }

  if (error) {
    throw new Error('Validation error');
  }
};
