const $ = require('jquery');
const md5 = require('md5');
// const z2hTemplates = require('./templates');

let z2hApp;
let z2hTemplates;

// =================================================================================================

function initialise(containerElem, serviceNameInput, limit = 3, hideOnInit = false) {
  // containerElem = <section class="row suggested-presets"...

  z2hApp = window.z2hApp;
  z2hTemplates = window.z2hTemplates;

  // Hide the whole "Suggested presets" section initially
  // $(containerElem).hide();

  // Initial refresh

  !hideOnInit && refresh(containerElem, serviceNameInput, limit);

  // Refresh on keyup
  $(serviceNameInput).keyup((_) => refresh(containerElem, serviceNameInput, limit));
}

// =================================================================================================

async function refresh(containerElem, serviceNameInput, limit) {
  // Get list of all suggested services from state

  await window.state.servicePresetPromise;
  await window.state.fullUserGroupsPromise;

  $(containerElem).removeClass('loading-wheel');

  const existingServices = [];
  for (const g of window.state.groupsList) {
    if (g.services && g.services.length > 0) {
      for (const s of g?.services) {
        if (s.website !== '' && !existingServices.includes(s.website)) {
          existingServices.push(s.website);
        }
      }
    }
  }
  const serviceIconList = window.state.serviceIconList || [];

  const columnGroup = $(containerElem).find('.column-group');

  // Get search input value
  const searchVal = $(serviceNameInput).val().toLowerCase();

  let services = [];
  if (searchVal) {
    // Firstly get services which have a name that STARTS WITH the search term
    services = serviceIconList.filter((s) => s.name.toLowerCase().indexOf(searchVal) === 0);

    // Secondly, if necessary, get services with names that CONTAIN the search term and aren't
    // already in the list of suggested services
    if (services.length < limit) {
      services = services.concat(
        serviceIconList.filter(
          (s) => s.name.toLowerCase().includes(searchVal) && !services.find((service) => service.name === s.name),
        ),
      );
    }
  } else {
    //Exclude from the list services that we've already got
    services = window.state.topPresets.filter((p) => !existingServices.some((x) => x === p.address));
  }

  // Limit the list length
  if (services.length > limit) {
    services = services.slice(0, limit);
  }

  // Check if we need to re-render the services displayed
  const hash = md5(JSON.stringify(services));
  const current = columnGroup.data('hash');
  if (hash === current) {
    return;
  }

  // Clear out container
  columnGroup.empty();

  // Hide the whole "Suggested presets" section if nothing to suggest
  if (services.length === 0) {
    $(containerElem).hide();
  } else {
    $(containerElem).show();
  }

  // Get action to perform whenever one of these services is clicked
  const action = $(containerElem).attr('data-action') || 'chooseSuggestedPreset';

  // Render each service
  services.forEach((s) => {
    const existsingService = existingServices.some((x) => x === s.address);

    const pageItem = z2hApp.constructBlock(
      z2hTemplates.compileBlockAttribute({
        template: 'block-login-avatar',
        fields: { avatar: s.url, label: s.name, action, class: existsingService && 'exists' },
      }),
    );
    columnGroup.append(pageItem);
  });
  // Store hash of results so we can avoid unnecessary re-renders
  columnGroup.data('hash', hash);
}

// =================================================================================================

module.exports = { init: initialise };
