const groupIconList = require('../../getPageData_helpers/groupIconList');
const { openMenu } = require('../../app/navigation');

const config = require('../../../config');

module.exports = ({ data }) => {
  const { DEFAULT_USER_ICON, DEFAULT_GROUP_ICON } = config.app;
  const group = data;
  let icon = group.icon || DEFAULT_GROUP_ICON;
  if (group.personal) {
    icon = window.state.userData.avatar || DEFAULT_USER_ICON;
  }
  const { showInviteIcon } = group;
  return {
    template: 'block-login-list-page',
    fields: {
      heading: group.name,
      group_icon: icon,
      ...(showInviteIcon && {
        action: 'createGroupInvite',
        share_icon: 'group-share',
        navigation_data: '1',
        navigation_pane: '2',
        navigation_template: 'newInvite_loading',
      }),
      navbar: true,
    },
    rows: [],
  };
};
