const showToastMessage = require('./showToastMessage');
const strings = require('../../config').strings;

module.exports = base64Image => {
  const groupId = window.state.selectedGroupId;

  // Send image to the server
  const data = { image: base64Image };
  const z2hApp = window.z2hApp;
  return z2hApp.requestData('post', '', 'groups/' + groupId + '/update-image', data).then(response => {
    if (Math.floor(response.status / 100) === 2) {
      const newUrl = (response.data || {}).url || '';
      const group = window.state.groupsList.find(g => g.id === groupId);
      if (group) {
        group.icon = newUrl;
        // Update any images on screen which have a data-id which matches the group ID
        $(`img[data-id=${groupId}]`).attr('src', newUrl);
        $(`div[data-id=${groupId}] img`).attr('src', newUrl);
      }
      showToastMessage(strings.MSG_GROUP_ICON_UPDATED());
    } else {
      showToastMessage(
        response.user_message || response.developer_message || response.message || strings.MSG_FALLBACK_ERROR_MESSAGE(),
      );
    }
  });
};
