const LINK_EXPIRY_VALUES = {
  15: '15 minuti',
  30: '30 minuti',
  60: '1 ora',
  360: '6 ore',
  720: '12 ore',
  1440: '1 giorno',
  '-1': 'Mai',
};

const LINK_MAX_USE_VALUES = {
  1: 'Una volta',
  2: 'Due volte',
  5: '5 volte',
  10: '10 volte',
  20: '20 volte',
  50: '50 volte',
  '-1': 'Senza limiti',
};

module.exports = {
  YOU: 'Tu',
  BACK: 'Indietro',
  NEXT: 'Il prossimo',
  CONTINUE: 'Continua',
  SAVE: 'Salva',
  ADD: 'Inserisci',
  SKIP: 'Salta',
  CANCEL: 'Annulla',
  PREVIOUS: 'Precedente',
  EDIT: 'modificare',
  NONE: 'Nessuna',
  START: 'Inizio',
  SEARCH: 'Ricerca',
  CLOSE: 'chiudere',
  SEE_DETAILS: 'Vedi i dettagli',
  UNAVAILABLE: 'Non disponibile',
  DONE: 'Fatto',
  WATCH_AGAIN: 'Guarda di nuovo',
  NEW: 'nuovo',

  UNAVAILABLE_FORGHETTIBLES_MESSAGE: `Alcuni dei tuoi 'forgettibles' non sono disponibili poiché hai superato il limite del tuo piano gratuito. Crea un account premium per un numero illimitato di 'forgettibles'`,

  FORGHETTI_TAGLINE: 'Dimentica le tue password. Per sempre.',
  CREATE_ACCOUNT: 'Crea un account',
  HAVE_ACCOUNT: 'Ho già un account',

  COMBOBULATING: `... combinando i tuoi 'forgettibles' ...`,

  CREATE_ACCOUNT_1_HEADER: 'Inserisci numero',
  CREATE_ACCOUNT_1_HEADING: 'Verifica il tuo dispositivo',
  CREATE_ACCOUNT_1_MSG:
    'Inserisci il tuo numero di cellulare per ricevere un <strong>codice di verifica una tantum</strong> tramite SMS.',
  CREATE_ACCOUNT_1_PLACEHOLDER: 'Il tuo numero',
  CREATE_ACCOUNT_1_MSG_2: `Cambia il prefisso internazionale se necessario e inserisci il tuo numero di cellulare,\nad es. 07795123456.`,
  CREATE_ACCOUNT_1_MSG_3: `I messaggi SMS verranno inviati solo durante la verifica dei dispositivi e per gli aggiornamenti essenziali dell'applicazione.`,

  ERROR_TEL_NUM_EMPTY: 'È necessario fornire il numero di cellulare',

  MAIN_NAV_GROUP: 'Gruppi',
  MAIN_NAV_PROFILE: 'Profilo',
  MAIN_NAV_HEALTHCHECK: 'Salute',
  MAIN_NAV_IMPORT: 'Servizi di importazione',
  MAIN_NAV_LOGOUT: 'Disconnettersi',
  NAVBAR_JOIN_GROUP: 'Unirsi al gruppo',
  NAVBAR_NEW_GROUP: 'Nuovo gruppo',
  NAVBAR_NEW_PASSWORD: 'Nuova password',
  NAVBAR_NEW_PIN: 'Nuovo PIN',
  NAVBAR_NEW_WORD: 'Nuova parola memorizzabile',
  NAVBAR_HOME: 'Casa',
  NAVBAR_GROUPS: 'Gruppi',
  NAVBAR_HEALTHCHECK: 'Salute',
  NAVBAR_PROFILE: 'Profilo',

  /* Lochy loading - paneBusyText.js */
  LOCHY_LOADING_TEXT_DEFAULT: 'Caricamento in corso...',
  LOCHY_LOADING_TEXT_GROUP: (group) => `Caricamento di servizio del <em>${group}</em>`,
  LOCHY_LOADING_TEXT_GROUPSLIST: 'Caricamento di gruppi',
  LOCHY_LOADING_TEXT_HEALTHCHECK: 'Caricamento del controllo dello stato',
  LOCHY_LOADING_TEXT_CHOOSE_SEARCH_ICON: 'controllo delle icone di servizio sul web',
  LOCHY_LOADING_TEXT_CHOOSE_CHOOSE_GROUP_ICON: `Caricamento dell'icona del gruppo`,
  LOCHY_LOADING_TEXT_CREATE_GROUP_INVITE: 'Creazione di un invito di gruppo',
  LOCHY_LOADING_TEXT_EDIT_SERVICE: 'Modifica i dettagli del servizio',
  LOCHY_LOADING_TEXT_UPDATE_SERVICE: 'Salvataggio dei dettagli del servizio',
  LOCHY_LOADING_TEXT_PROFILE: 'Caricamento del tuo profilo',
  LOCHY_LOADING_TEXT_SUBSCRIPTION: `Caricamento dei dettagli dell'abbonamento`,

  /*                                 */

  CREATE_ACCOUNT_2_HEADER: 'Inserisci il codice',
  CREATE_ACCOUNT_2_HEADING: 'Inserisci il tuo codice di verifica',
  CREATE_ACCOUNT_2_MSG: (telNumber) => `Inserisci il codice di verifica inviato al ${telNumber}..`,
  CREATE_ACCOUNT_2_LINK_BACK: 'Non ho ricevuto un codice',
  CREATE_ACCOUNT_2_MSG_2: (termsofserviceurl) =>
    `Inserendo il codice di verifica, accetti i nostri <a href="${termsofserviceurl}" target="_new">termini di servizio</a>.`,

  ERROR_TEL_NUM_INVALID: 'per favore inserisci UN numero di telefono valido',
  ERROR_ACT_CODE_EMPTY: 'Inserisci il codice di attivazione che ti è stato inviato',
  ERROR_ACT_CODE_INVALID: 'Inserisci un codice di attivazione valido',
  ERROR_ACT_CODE_INVALID: 'Il codice di attivazione non è corretto. Per favore riprova',

  CREATE_ACCOUNT_VERIFYING: 'Controllo del codice di verifica',
  CREATE_ACCOUNT_VERIFYING_PHONE: 'stiamo verificando il tuo numero di telefono',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_1: 'Nessuna password viene mai salvata',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_2: 'Hai solo bisogno di ricordare il tuo doodle',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_3: 'Puoi usare forghetti su tutti i dispositivi',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_4: `Esegui l'upgrade per poter condividere le password in modo sicuro all'interno dei gruppi`,

  CREATE_ACCOUNT_REFERRAL_HEADER: 'Codice di riferimento',
  CREATE_ACCOUNT_REFERRAL_HEADING: 'Inserisci un codice di riferimento',
  CREATE_ACCOUNT_REFERRAL_PLACEHOLDER: 'Codice di riferimento',
  REFERRAL_ENTRY_MESSAGE_1: `Hai ricevuto un codice di riferimento da un amico? In tal caso, inseriscilo qui. Altrimenti, puoi saltare questo passaggio.`,

  CREATE_ACCOUNT_3_HEADER: 'Modifica Profilo',
  CREATE_ACCOUNT_3_HEADING: 'modifica il tuo profilo',
  CREATE_ACCOUNT_3_TEXT: `Puoi impostare un nome e un'icona per il tuo profilo qui. Ciò aiuterà a identificarti se decidi di condividere l'accesso o se sei invitato a unirti al gruppo di accessi di un altro utente.`,
  CREATE_ACCOUNT_3_LABEL: 'Il tuo nome',
  CREATE_ACCOUNT_3_TEXT_2: `Puoi saltare questo passaggio se lo desideri e lasciare l'impostazione di un nome e un'immagine fino a più tardi.`,

  CREATE_ACCOUNT_ADD_LOGIN_MSG: `Seleziona un servizio da aggiornare da queste app popolari`,

  CREATE_ACCOUNT_FINAL_HEADER: 'Finito!',
  CREATE_ACCOUNT_FINAL_MSG: `Sei pronto per forghetti!`,
  CREATE_ACCOUNT_FINAL_MSG_2: `Sei sulla buona strada per proteggere il tuo account e dimenticare per sempre lo stress delle password!`,
  CREATE_ACCOUNT_FINAL_MSG_3: `Ora ti guideremo attraverso i passaggi necessari per aggiornare una password esistente in una password (FORGHETTI) modificabile.`,
  CREATE_ACCOUNT_FINAL_BUTTON: 'Mostrami come!',
  CREATE_ACCOUNT_FINAL_LINK: `Conosco già forghetti`,

  CREATE_ACCOUNT_ADD_CUSTOM_LOGIN: `O qualcos'altro...`,
  CREATE_ACCOUNT_SERVICE_NAME_TOOLTIP: `Per aggiungere un servizio diverso, dovrai fornire almeno il nome del servizio. I campi \n\nEmail / Nome utente e Indirizzo web sono opzionali, ma ti consigliamo di aggiungere anche quelli!`,
  CREATE_ACCOUNT_CUSTOM_LOGIN_ADD_PASSWORD_TOOLTIP: `Dovrai aggiungere almeno un tipo di password da generare.\n\nCi sono alcune diverse opzioni tra cui scegliere poiché alcuni servizi richiedono requisiti di password specifici.`,
  CREATE_ACCOUNT_CUSTOM_LOGIN_NEXTPAGE_TOOLTIP: `Quando sei soddisfatto di tutto, fai clic su "Avanti" per continuare.`,
  CREATE_ACCOUNT_NEW_LOGIN_DOODLEPAD_MSG: `Il tuo segno verrà utilizzato ogni volta che vorrai recuperare la tua password in futuro, quindi fallo contare!`,
  CREATE_ACCOUNT_ADD_ANOTHER_LOGIN_TOOLTIP: `Per aggiungere un altro servizio al tuo account, fai clic qui per visualizzare nuovamente la nuova schermata di accesso.`,

  ADD_SERVICE_SEARCH_HEADER: `Aggiungi nuovo servizio`,
  ADD_SERVICE_SEARCH: `Seleziona un servizio da queste app popolari o inizia a digitare il nome`,
  ADD_SERVICE_SUGGESTED: `Servizi suggeriti`,
  ADD_SERVICE_CREATE: (name) => `Creare "${name}" "${name}"`,
  ADD_SERVICE_SELECT_ICON_HEADER: `Scegli un'icona`,
  ADD_SERVICE_SELECT_ICON: (serviceName) => `Seleziona un'icona per ${serviceName}`,
  ADD_SERVICE_SELECT_GROUP_HEADER: `Seleziona gruppo`,

  ADD_SERVICE_YOUR_GROUP: (serviceName, groupName) =>
    `<em>${serviceName}</em> <em>${serviceName}</em> sarà aggiunto a <em>${groupName}</em> <em>${groupName}</em>`,
  ADD_SERVICE_SELECT_GROUP: (serviceName) => `Selezionare un gruppo a cui aggiungere <br /><em>${serviceName}</em>`,
  ADD_SERVICE_SELECT_GROUP_SELECT: (serviceName) =>
    `Oppure seleziona un gruppo diverso a cui aggiungere <br /><em>${serviceName}</em>`,
  MOVE_SERVICE_SELECT_GROUP: (serviceName) => `Selezionare un gruppo in cui spostare <br /><em>${serviceName}</em>`,
  MOVE_SERVICE_LOADING: 'Spostare il tuo servizio',
  MOVE_SERVICE_CONFIRM_HEADER: 'Spostare il tuo servizio',
  MOVE_SERVICE_CONFIRM_NOTE: (
    service,
    fromGroup,
    toGroup,
    numFields,
  ) => `<em>${service}</em> verrà spostato dal tuo gruppo <em>${fromGroup}</em> a <em>${toGroup}</em>.
Questo servizio ha <em>${numFields}</em> 'forghettible${numFields > 1 ? 's' : ''}'`,
  MOVE_SERVICE_CONFIRM_NOTE_UPDATE: (fromGroup, toGroup, list, listLength) =>
    `Verrà generato un nuovo <em>${list}</em> 'forghettible${
      listLength > 1 ? 's' : ''
    }' dal tuo segno <em>${toGroup}</em>. Se desideri accedere al tuo vecchio forghettible${
      listLength > 1 ? 's' : ''
    }, disegna semplicemente il tuo segno <em>${fromGroup}</em>.`,
  MOVE_SERVICE_CONFIRM: 'Conferma spostamento',
  ADD_SERVICE_SELECT_GROUP_ONBOARD: (serviceName) => `Aggiungi il tuo servizio <em>${serviceName}</em> a un gruppo`,
  ADD_SERVICE_USERNAME: (serviceName) =>
    `Immettere il nome utente per <em>${serviceName}</em> o selezionarlo dall'elenco seguente.`,
  ADD_SERVICE_USERNAME_ONBOARD: (serviceName) => `Inserisci la tua email o il tuo nome utente <em>${serviceName}</em>`,
  ADD_SERVICE_USERNAME_HEADER: `Seleziona nome utente`,
  ADD_SERVICE_USERNAME_PLACEHOLDER: `Email o nome utente`,
  ADD_SERVICE_SEARCH_FOR_SERVICE: `Inserisci qui un servizio che utilizzi`,
  ADD_SERVICE_SEARCH_FOR_ICONS: 'Cerca icone',
  ADD_SERVICE_SUGGESTED_USERNAMES: `Nomi utente suggeriti`,
  ADD_SERVICE_DRAW_DOODLE: (groupName) => `Disegna il segno per il tuo gruppo <em>${groupName}</em>`,
  ADD_SERVICE_USERNAME_BLANK: `Devi inserire un nome utente o un indirizzo e-mail`,
  ADD_SERVICE_USERNAME_TOO_LONG: 'Il tuo nome utente deve contenere meno di 64 caratteri',

  ADD_SERVICE_WEBSITE_HEADER: 'Accedi al sito web',
  ADD_SERVICE_WEBSITE: (serviceName) =>
    `Inserisci l'URL del sito web per <em>${serviceName}</em>. Puoi saltare questo passaggio e inserire l'URL di un sito web in un secondo momento.`,
  ADD_SERVICE_WEBSITE_PLACEHOLDER: `URL del sito`,

  ADD_SERVICE_SEARCH_ONBOARD_LETS_START: `Cominciamo selezionando uno di questi servizi popolari o ...`,
  ADD_SERVICE_SEARCH_ONBOARD_NOW_ITS_YOUR_TURN: `ORA È IL TUO TURNO`,
  ADD_SERVICE_DOODLE_DO_IT_AGAIN: `FACCIAMOLO ANCORA`,
  ADD_SERVICE_DOODLE_ONBOARD_NOW_ITS_YOUR_TURN: `ORA È IL TUO TURNO`,
  ADD_SERVICE_DOODLE_CREATE_DOODLE: 'Crea il tuo segno personale',
  ADD_SERVICE_DOODLE_CONFIRM_DOODLE: `Controlliamo che tu riesca a ricordarlo ... disegna il tuo segno <em>ancora una volta</em>`,
  ADD_SERVICE_GENERATING_FORGHETTI_ONBOARD: (serviceName) =>
    `Congratulazioni, forghetti sta ora generando una nuova password per <em>${serviceName}</em>`,
  VIEW_SERVICE_GENERATING_FORGHETTI: (serviceName) =>
    `Generazione della password forghetti per <em>${serviceName}</em>`,
  VIEW_SERVICE_RESET_PASSWORD: `Reimposta la tua password adesso`,
  VIEW_SERVICE_PASSWORD_HELP: (serviceName) =>
    `Abbiamo generato una password super complessa per <em>${serviceName}</em>. Ora è il momento di reimpostare la vecchia password.`,
  VIEW_SERVICE_PASSWORD_HELP_MOVE: (serviceName, groupName) =>
    `Abbiamo spostato <em>${serviceName}</em> in <em>${groupName}</em>. Se il tuo doodle per questo servizio è cambiato, dovrai aggiornare la tua password..`,
  VIEW_SERVICE_OLD_PREFIX: 'Vecchio',
  VIEW_SERVICE_NEW_PREFIX: 'Nuovo',
  HIDE_QUICK_SELECT: `Non mostrarmelo di nuovo`,
  HIDE_QUICK_SELECT_CONFIRM_TITLE: `Nascondere questa schermata?`,
  HIDE_QUICK_SELECT_CONFIRM: `Ciò rimuoverà questa pagina da visualizzazioni future e l'aggiunta di un nuovo servizio ti porterà direttamente alla pagina "aggiungi altro servizio". \n\nQuesto può essere ripristinato nelle impostazioni in "preferenze utente" in qualsiasi momento.`,
  ADD_SERVICE_DODODLES_DO_NOT_MATCH: `I tuoi segni non corrispondono. Riprova.`,
  THEME_COLOUR: 'Colore',
  THEME_COLOURS: ['Flawless Blue', 'Fresh Green', 'Fearless Red', 'Feel-good Orange', 'Friendly Yellow'],
  THEME_MODES: ['Giorno', 'Notte'],
  THEME_BLUE: 'Blu impeccabile',

  DELETE_ACCOUNT_CONTINUE: 'Continua',
  DELETE_ACCOUNT_HEADER: `Eliminare l'account`,
  DELETE_ACCOUNT_HEADING: 'Cancella il mio account',
  DELETE_ACCOUNT_LINE_1: `L'eliminazione del tuo account:`,
  DELETE_ACCOUNT_LINE_2: '• Eliminerà il tuo account da forghetti',
  DELETE_ACCOUNT_LINE_3: '• Cancellerà tutte le informazioni che forghetti detiene sotto il tuo profilo',
  DELETE_ACCOUNT_LINE_4: '• Ti eliminarà da tutti i gruppi Forghetti',
  DELETE_ACCOUNT_LINE_5: `• Rimuovi l'accesso al tuo account da tutti i dispositivi registrati`,
  DELETE_ACCOUNT_CHANGE_INSTEAD: 'Vuoi invece cambiare telefono?',

  DELETE_ACCOUNT_CONFIRM_MESSAGE: (telNumber) =>
    `Abbiamo inviato un codice di conferma al tuo numero di telefono (${telNumber}). Inserisci il codice per confermare che desideri eliminare il tuo account:`,
  DELETE_ACCOUNT_CONFIRM_BUTTON: 'Confermare',

  DEVICES_HEADER: 'Gestione dei dispositivi',
  DEVICES_LAST_ACTIVE: 'Ultimo attivo: ',
  DEVICES_FORGHETTI_APP: ' - app forghetti',
  DEVICES_LOG_OUT_ALL: 'Esci da tutti i dispositivi',

  SERVICES_PERSONAL: 'Personale',
  SERVICES_ADD_LOGIN: 'Aggiungi login',
  SERVICES_RECENT: 'Recente', // Not currently used

  SEARCH_INTERNET: `Cerca un'immagine in Internet`,

  SERVICE_HEADER_NEW: 'Aggiungi login',
  SERVICE_NAME_PLACEHOLDER: 'Assegna un nome al tuo login',

  SERVICE_DOODLEPAD_SUBHEADING: 'disegna il tuo segno sullo schermo',
  DOODLEPAD_SECRET: 'Nascondere',
  DOODLEPAD_SHOW: 'Mostrare',
  DOODLEPAD_REDOODLE: 'pulisci',
  ERROR_NO_DOODLE: 'Per favore disegna il tuo segno',

  SERVICE_EDIT_FIELD_HEADER: 'impostazioni',

  FIELD_TYPES: {
    lns: 'AbC123_*.',
    wns: 'parole 123_ *.',
    lnx: 'AbC123 ~?%',
    wnx: 'Parole123 ~?%',
    ln: 'AbC123',
    wn: 'Parole123',
    l: 'AbC',
    w: 'Parole',
    n: '123',
  },

  FIELD_TEMPLATE_PIN: 'PIN',
  FIELD_TEMPLATE_COMPLEX_PASSWORD: 'Password complessa',
  FIELD_TEMPLATE_PASSWORD: 'Password',
  FIELD_TEMPLATE_MEMWORD: 'Parola memorizzabile',
  FIELD_TEMPLATE_FORGHETTIBLE: `Solo lettere`,

  SERVICE_USERNAME_LABEL: 'Email o nome utente',
  SERVICE_WEBSITE_LABEL: 'Indirizzo Web',

  SERVICE_EDIT_HEADER: 'Dettagli',
  SERVICE_EDIT_USERNAME_PLACEHOLDER: 'Immettere il metodo di accesso',
  SERVICE_EDIT_WEBSITE_PLACEHOLDER: 'www.webaddress.com',

  SERVICE_ADD_FORGHETTIBLE: 'Aggiungi un forghettible',
  SERVICE_DFT_FORGHETTIBLE_NAME: 'password',

  SERVICE_NAME_LENGTH_INVALID: 'Il nome del servizio deve contenere da 2 a 32 caratteri',
  SERVICE_USERNAME_LENGTH_INVALID: `L'e-mail /nome utente del servizio devono contenere meno di 64 caratteri`,
  SERVICE_ADDRESS_LENGTH_INVALID: `L'indirizzo web del servizio deve contenere meno di 128 caratteri`,
  SERVICE_NO_FORGHETTIBLES: `Devi aggiungere almeno un 'forghettible'. Per esempio una password.`,

  SERVICE_CHOOSE_ICON: `Scegli l'icona`,

  SERVICE_DOODLEPAD_HEADING: 'disegna il tuo segno sullo schermo',
  SERVICE_DOODLEPAD_RIGHT_NAV: 'Dettagli',

  SERVICE_ADDING: 'Aggiunta di servizio',

  SERVICE_CONNECTING: 'Collegamento',

  FIELD_DETAILS_HEADER: 'impostazioni',
  FIELD_NAME: 'Nome',
  FIELD_NAME_PLACEHOLDER: `Dai un nome al tuo 'forghettible'`,
  FIELD_TYPE: 'Digita',
  FIELD_LENGTH: 'Lunghezza',
  FIELD_LENGTH_PICKER_CUSTOM: 'Personalizzato',

  FIELD_RIGHT_NAV: 'ok',

  FIELD_OPTIONS_HEADING: 'Opzioni',

  ADVANCED_OPTIONS: 'Opzioni avanzate',
  RESET_FIELD: `Resettare 'forghettible'`,
  DELETE_FIELD: 'Elimina',
  RESTORE_FIELD: `Ripristinare 'forghettible'`,

  FIELD_ADVANCED_OPTIONS_HEADER: 'Opzioni avanzate',

  RESTORE_FIELD_PAGE_HEADER: 'Ripristinare',
  RESTORE_FIELD_SUBHEADING: 'Hai bisogno di una vecchia password?',
  RESTORE_FIELD_DESCRIPTION:
    'Puoi tornare a una versione precedente qui. Seleziona semplicemente quale versione desideri ripristinare.',
  RESTORE_FIELD_VERSION_HISTORY: 'Cronologia delle versioni',
  RESTORE_FIELD_CURRENT: 'attuale',

  SERVICE_VIEW_HEADER: 'Sommario',
  SERVICE_VIEW_RIGHT_NAV: 'Fatto',

  SERVICE_SUMMARY_MESSAGE: `I tuoi 'forghettibles' sono stati generati. Seleziona di seguito per copiare, visualizzare e accedere a questo login.`,
  SERVICE_SUMMARY_FIRST_LOGIN_MSG1: `Congratulazioni! Hai appena generato la tua prima password 'forghettible'!`,
  SERVICE_SUMMARY_FIRST_LOGIN_MSG2: `Ora dovrai sostituire la tua password esistente con questa nuova affinché abbia effetto.`,
  SERVICE_SUMMARY_FIRST_LOGIN_PASSWORD_TOOLTIP: `Quando sei pronto, puoi copiare la tua nuova password negli appunti facendo clic su questa icona.`,

  SERVICE_DETAILS_HEADER: 'Dettagli',
  SERVICE_DETAILS_RIGHT_NAV: 'modificare',
  SERVICE_DETAILS_DELETE: 'Elimina login',

  PROFILE_HEADER: 'Profilo',
  PROFILE_WIZARD_COMPLETE: 'Completa la configurazione di forghetti.',
  PROFILE_WIZARD_SEE_DETAILS: 'Vedi i dettagli > >',
  PROFILE_PREMIUM_BANNER_HEADING: 'Prova Premium',
  PROFILE_PREMIUM_BANNER_DETAIL: 'Sblocca le funzionalità e sii più sicuro online.',
  PROFILE_THEME_MODE_LABEL: 'Modalità',
  PROFILE_THEME_COLOUR_LABEL: 'Colore',
  PROFILE_BADGES: 'I tuoi distintivi',
  PROFILE_LINK_ACCOUNT: 'Il tuo account',
  PROFILE_LINK_PREMIUM: 'Premium',
  PROFILE_ENABLE_AUTOFILL: 'Abilita la compilazione automatica',
  PROFILE_LINK_USER_PREFERENCES: `Preferenze dell'app`,
  PROFILE_YOUR_SUBSCRIPTION: 'Il tuo abbonamento',
  PROFILE_LINK_INVITE: 'Condividi forghetti con un amico',
  /*PROFILE_LINK_INVITE_SECONDARY: 'Condividi con gli amici e guadagna accessi gratuiti',*/
  PROFILE_REFERRAL_CODE: 'Codice di riferimento',
  PROFILE_REFERRAL_CODE_SECONDARY: '',
  PROFILE_LINK_DEVICES: 'Gestione dei dispositivi',
  PROFILE_LINK_HELP: 'Ottenere aiuto',
  PROFILE_LINK_FEEDBACK: 'Inviaci il tuo feedback',
  PROFILE_LINK_ABOUT: 'a proposito di',
  PROFILE_LINK_LOGOUT: 'Disconnettersi',

  PROFILE_ACCOUNT_HEADER: 'Il tuo account',
  PROFILE_ACCOUNT_LINK_SECURITY: 'Sicurezza',
  PROFILE_ACCOUNT_LINK_MIGRATE: 'Cambia numero',
  PROFILE_ACCOUNT_LINK_DELETE: 'Cancella il mio account',
  PROFILE_ACCOUNT_LINK_SUBSCRIPTION: 'Il tuo abbonamento',

  PROFILE_REONBOARDING: 'Scopri come funziona forghetti',
  PROFILE_GET_ONLINE_HELP: 'Ottieni aiuto in linea',
  PROFILE_GET_VIDEO_HELP: 'Ottieni video guida in linea',
  PROFILE_HELP_HEADER: 'Aiuto',

  PROFILE_EDIT_NAME_HEADER: 'Dettagli del profilo',
  PROFILE_EDIT_NAME_LABEL: 'Il tuo nome',
  PROFILE_EDIT_TEL_LABEL: 'Numero di telefono',

  PROFILE_NAME_BLANK: 'Il nome del profilo non può essere vuoto',
  PROFILE_NAME_TOO_LONG: 'Il nome del profilo non può contenere più di 32 caratteri',

  PROFILE_NAME_INVALID: 'Il nome del profilo può contenere solo lettere, numeri e simboli',

  PROFILE_MIGRATE_1_HEADER: 'Cambia numero',
  PROFILE_MIGRATE_1_HEADING: 'Numero di telefono corrente',
  PROFILE_MIGRATE_1_INFO: 'Inserisci il nuovo prefisso internazionale e il numero di telefono:',
  PROFILE_MIGRATE_1_PLACEHOLDER: 'Numero di cellulare',
  PROFILE_MIGRATE_1_BUTTON: 'Invia codice di verifica',

  PROFILE_MIGRATE_2_HEADER: 'Inserisci il codice',
  PROFILE_MIGRATE_2_HEADING: 'Numero di telefono attuale',
  PROFILE_MIGRATE_2_HEADING2: 'Nuovo numero di telefono',
  PROFILE_MIGRATE_2_INFO: 'Ora inserisci il codice di verifica che ricevi sul tuo nuovo telefono:',
  PROFILE_MIGRATE_2_BUTTON: 'Confermare',

  PROFILE_MIGRATE_3_HEADER: 'completato',
  PROFILE_MIGRATE_3_MSG: 'Il tuo numero di telefono è stato cambiato',
  PROFILE_MIGRATE_3_HEADING: 'Nuovo numero di telefono',

  PROFILE_ABOUT_HEADER: 'a proposito di',
  PROFILE_ABOUT_PRIVACY: 'informativa sulla privacy',
  PROFILE_ABOUT_COOKIE: 'Gestione dei Cookie',
  PROFILE_ABOUT_TERMS: 'Termini di servizio',
  PROFILE_ABOUT_ACCEPTABLE: 'Criteri di utilizzo',
  PROFILE_ABOUT_VERSION_HEADING: 'Versione',

  PROFILE_REFERRAL_CODE_HEADING: 'Codice di riferimento',
  PROFILE_REFERRAL_CODE_LABEL: 'Codice di riferimento',
  PROFILE_REFERRAL_CODE_PLACEHOLDER: 'Inserisci il tuo codice di riferimento',
  PROFILE_EDIT_REFERRAL_CODE_HEADING: 'Modifica codice di riferimento',
  PROFILE_REFERRAL_CODE_TEXT: 'Se hai ricevuto un codice di riferimento, inseriscilo di seguito.',

  PROFILE_PREMIUM_TYPE: {
    premium_trial: 'Utente di prova Premium',
    premium_life: 'Utente a vita',
    free: 'Utente libero',
    premium: 'Abbonato Premium',
    premium_paid: 'Abbonato Premium',
  },
  APP_PREFERENCES_HEADER: `Preferenze dell'app`,
  APP_PREFERENCES_THEME: 'Tema',
  APP_PREFERENCES_QUICK_SELECT: 'Mostra suggerimenti',
  APP_PREFERENCES_SHOW_QUICK_SELECT: `Mostra la schermata di accesso per l'aggiunta rapida`,
  APP_PREFERENCES_SECRET_DOODLE: 'Segno segreto per impostazione predefinita',
  APP_PREFERENCES_SHOW_TOOLTIPS: 'Mostra suggerimenti',
  APP_PREFERENCES_REQUIRE_TOUCH_ID: `Richiedi dati biometrici per aprire l'app`,
  APP_PREFERENCES_NOTIFICATIONS: 'Notifiche',
  APP_PREFERENCES_COUNTRY: 'linguaggio',
  APP_PREFERENCES_TOUCH_ID_TOAST: `Assicurati che l'ID biometrico sia registrato sul tuo dispositivo`,

  PREMIUM_HEADER: 'Forghetti Premium',

  YOUR_SUBSCRIPTION_HEADER: 'Il tuo abbonamento',
  YOUR_SUBSCRIPTION_SUBHEADING: 'Prova Premium',

  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_TEXT_1: (date) =>
    `Attualmente sei in una prova Premium gratuita. La tua prova scadrà il ${date}. A quel punto potrai continuare come utente gratuito (e perdere tutti i vantaggi premium) o acquistare un abbonamento Premium in-app.`,
  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_TEXT_2: (date) => `Ci auguriamo che tu sia soddisfatto di forghetti.`,

  YOUR_SUBSCRIPTION_FREE_TEXT_1: (date) =>
    `Sei attualmente un utente gratuito. Iscriviti oggi per beneficiare di accessi illimitati, la possibilità di creare gruppi illimitati che potrai condividere tra amici, familiari e colleghi e per ottenere rapporti dettagliati sul controllo dello stato di salute.`,
  YOUR_SUBSCRIPTION_FREE_TEXT_2: (date) => `Ci auguriamo che tu sia soddisfatto di forghetti.`,
  YOUR_SUBSCRIPTION_FREE_TEXT_3: `Se hai già un abbonamento dall'app store, fai clic su ripristina abbonamento per riapplicare.`,
  YOUR_SUBSCRIPTION_FREE_DOWNLOAD: `Non hai l'app? Scarica ora dal tuo app store`,

  YOUR_SUBSCRIPTION_PREMIUM_TEXT_1: (date) =>
    `Sei attualmente un utente Premium. Il tuo abbonamento scadrà il ${date}. Quando acquisti un abbonamento, questo si rinnova automaticamente. Se lo desideri, puoi disattivarlo nel tuo account App Store.`,
  YOUR_SUBSCRIPTION_PREMIUM_TEXT_2: (date) => `Ci auguriamo che tu sia soddisfatto di forghetti.`,

  YOUR_SUBSCRIPTION_PREMIUM_NO_DATE_TEXT_1: `Sei attualmente un utente Premium. Quando acquisti un abbonamento, questo si rinnova automaticamente. Se lo desideri, puoi disattivarlo nel tuo account App Store.`,

  YOUR_SUBSCRIPTION_PREMIUM_LIFE_TEXT_1: (date) =>
    `Il tuo abbonamento a vita premium non scadrà mai. Puoi continuare a usare forghetti per sempre!`,
  YOUR_SUBSCRIPTION_PREMIUM_LIFE_TEXT_2: (date) => `Ci auguriamo che tu sia soddisfatto di forghetti.`,
  YOUR_SUBSCRIPTION_FREE_TEXT_APP_3: `Se hai già un abbonamento dall'app store, fai clic su ripristina abbonamento per riapplicare.`,

  YOUR_SUBSCRIPTION_FREE_TEXT_APP_1: `Sei attualmente un utente gratuito. Iscriviti oggi per beneficiare di accessi illimitati, la possibilità di creare gruppi illimitati che puoi condividere tra amici, familiari e colleghi e per ottenere rapporti dettagliati sul controllo di stato.`,

  YOUR_SUBSCRIPTION_FREE_TEXT_APP_2: 'Scansiona il codice QR con il tuo telefono per iscriverti al servizio Premium.',
  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_BUTTON_LABEL: 'Visualizza i prezzi',
  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_CORDOVA_BUTTON_LABEL: 'Visualizza gli abbonamenti',

  YOUR_SUBSCRIPTION_FREE_BUTTON_LABEL: 'Aggiornamento',

  PREMIUM_NO_PRODUCT_SELCTED: 'Selezione del prodotto non valida',
  PREMIUM_PURCHASE_BUTTON: 'Acquista',
  PREMIUM_PURCHASE_BUTTON_NA: 'Prossimamente',
  COMING_SOON: 'Prossimamente',
  PREMIUM_PRICE_NONE: 'N / A',
  PREMIUM_PURCHASE_BUTTON_OWNED: 'Di proprietà',
  PREMIUM_PRUCHASE_REFRESH_PURCAHSE: 'Aggiorna il tuo acquisto',
  PREMIUM_MONTHLY_HEADER: 'Premium ogni mese',
  PREMIUM_MONTHLY_LIST: [
    {
      description: 'Gruppi illimitati',
    },
    {
      description: 'Accessi illimitati',
    },
    {
      description: 'Supporto prioritario',
    },
  ],
  PREMIUM_ANNUAL_ALIAS: 'Piano annuale',
  PREMIUM_MONTHLY_ALIAS: 'Piano mensile',
  PREMIUM_ONE_OFF_ALIAS: 'Acquisto una tantum',
  PREMIUM_LOADING_OWNED: 'Congatulazioni! Ora sei un utente premium!',
  PREMIUM_LOADING_RELOAD: 'A breve forghetti si ricaricherà con il tuo nuovo abbonamento.',
  PREMIUM_LOADING_APPROVED: `Attendi mentre confermiamo la tua iscrizione con l'app store!`,
  PREMIUM_LOADING_REGISTERED: 'Attendi mentre carichiamo gli abbonamenti disponibili ...',
  PREMIUM_MANAGE_SUBSCRIPTIONS: 'Gestisci abbonamento',
  PREMIUM_IMPOSSIBLE_TO_VERIFY: 'Non è stato possibile verificare il tuo acquisto',
  PREMIUM_RESTORE_PURCHASE: `Ripristina l'abbonamento`,
  PREMIUM_YEARLY_HEADER: `Premium per tutto l'anno`,
  PREMIUM_YEARLY_LIST: [
    {
      description: 'Gruppi illimitati',
    },
    {
      description: 'Accessi illimitati',
    },
    {
      description: 'Supporto prioritario',
    },
  ],
  PREMIUM_LIFETIME_HEADER: 'Premium per sempre',
  PREMIUM_LIFETIME_LIST: [
    {
      description: 'Gruppi illimitati',
    },
    {
      description: 'Accessi illimitati',
    },
    {
      description: 'Supporto prioritario',
    },
  ],
  ENABLE_ANDROID_AUTOFILL_HEADING: 'Abilita la compilazione automatica',
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_1: `Forghetti utilizza il servizio di accessibilità di Android per la funzione AutoFill, che aiuta a recuperare le password quando richiesto in qualsiasi app sul dispositivo.`,
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_2:
    'Ti assicuriamo che Forghetti non legga o accedesse nessun altro contenuto dello schermo. Identifichiamo solo quando viene visualizzato un campo password sullo schermo per AutoFill.',
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_3: `Per utilizzare questo servizio, è richiesto il consenso dell'utente. Facendo clic sul pulsante "Accetto", ci si dà l'autorizzazione per utilizzare il servizio di accessibilità per AutoFill. Se si sceglie di negare il consenso, la funzione AutoFill sarà disabilitata ma puoi continuare a utilizzare Forghetti senza questa funzionalità..`,
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_4: `Si prega di notare che la navigazione lontano da questa schermata non è presa come consenso e non utilizziamo messaggi auto-dismistosi per raccogliere il consenso..`,
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_5: `Per ulteriori informazioni su come il nostro utilizzo dell'API di accessibilità è implementato e protetto, nonché la nostra gestione dei dati degli utenti, si prega di rivedere la nostra politica sulla privacy.`,

  ENABLE_ANDROID_AUTOFILL_BUTTON: `Essere d'accordo`,
  ENABLE_ANDROID_AUTOFILL_BUTTON_NO: 'Non adesso',
  MAKE_ADMIN_MESSAGE: 'Desideri rendere questo utente un amministratore di questo gruppo?',
  MAKE_OWNER_MESSAGE: `Vuoi trasferire la proprietà di questo gruppo all'utente?`,

  REMOVE_ADMIN_MESSAGE: 'Vuoi rimuovere lo stato di amministratore per questo utente?',
  DELETE_GROUP_CONFIRM: 'Sei sicuro di voler eliminare questo gruppo?',
  LEAVE_GROUP_CONFIRM: 'Vuoi abbandonare questo gruppo?',
  GROUP_DELETED_MESSAGE: 'Il gruppo ora è stato eliminato.',
  REMOVE_MEMBER_MESSAGE: 'Vuoi rimuovere questa persona dal gruppo?',
  REMOVE_MEMBER_SUCCESS: 'Il membro del gruppo è stato rimosso',
  GROUP_SHARED_BY: 'Gruppi condivisi da',
  ERROR_INVALID_NUM_WORDS: 'Il numero di parole deve essere compreso tra 1 e 10',
  ERROR_INVALID_NUM_LETTERS: 'Il numero di caratteri deve essere compreso tra 1 e 64',

  UPGRADE_REQUIRED: 'Aggiornamento richiesto',
  ADD_LOGIN_NOT_ALLOWED: `Come utente gratuito, puoi solo aggiungere accessi al tuo gruppo personale. Esegui l'upgrade oggi per creare gruppi e condividere gli accessi tra amici, familiari e colleghi!`,
  UPGRADE_TODAY_MESSAGE: `Come utente gratuito, puoi unirti a un gruppo.
Esegui l'upgrade oggi per creare gruppi e condividere gli accessi tra amici, familiari e colleghi!`,

  UPGRADE_TODAY_BUTTON: 'Aggiorna oggi',
  UPGRADE_TODAY_PAGE_HEADING: 'Aggiorna oggi',
  UPGRADE_TODAY_MSG_1: 'Diventa un utente Premium forghetti',
  UPGRADE_TODAY_PRICE: `£ 24,99 all'anno`,
  UPGRADE_TODAY_MSG_2: 'Rilascia tutte le funzionalità offerte da forghetti',
  UPGRADE_TODAY_MSG_3: `L'abbonamento Premium è disponibile come acquisto in-app in ognuna delle nostre app mobili:`,

  UPGRADE_TODAY_MSG_4:
    'Un abbonamento annuale ti consente di diventare un Utente Premium con la possibilità di creare accessi illimitati, campi personalizzabili e gruppi.',

  INVITE_FRIEND_HEADER: 'Ottieni servizi gratuiti',
  INVITE_FRIEND_REFERRAL_LABEL: 'Link di riferimento',
  INVITE_FRIEND_REFERRAL_COPY: 'Copia il link di riferimento negli appunti',
  INVITE_FRIEND_REFERRAL_EMAIL: 'Link di riferimento via e-mail',
  INVITE_FRIEND_PREAMBLE: 'Condividi forghetti con un amico',
  INVITE_FRIEND_TEXT: `Condividi il tuo collegamento personale.`,
  INVITE_FRIEND_BENEFIT:
    'Presenta un amico e otterrai altri 5 servizi aggiunti al tuo gruppo personale quando si iscriverà. Convinci 5 amici a registrarsi e ti daremo 15 servizi extra.',

  GROUPS_LIST_HEADER: 'I tuoi gruppi',
  GROUPS_LIST_INFO_MESSAGE: `Gestisci i tuoi gruppi o passa da un gruppo all'altro`,
  GROUPS_LIST_PERSONAL_HEADING: 'Il tuo gruppo personale',
  GROUPS_LIST_OTHER_GROUPS_HEADING: 'I tuoi altri gruppi',
  GROUPS_LIST_ADD_NEW_GROUP: 'Aggiungi un nuovo gruppo',
  GROUPS_LIST_CURRENTLY_ACTIVE: 'ATTUALMENTE ATTIVO',
  GROUPS_NEW_HEADER: 'Nuovo gruppo',
  GROUPS_NEW_RIGHT_NAV: 'Creare',
  GROUPS_NEW_NAME_PLACEHOLDER: 'Immettere un titolo',

  GROUPS_ADD_GROUP_HEADER: 'Aggiungere gruppo',
  GROUPS_JOIN_GROUP: 'Unirsi al gruppo',
  GROUPS_JOIN_GROUP_TEXT: 'Unisciti a un gruppo utilizzando un link di invito',
  GROUPS_CREATE_GROUP: 'Creare un gruppo',
  GROUPS_CREATE_GROUP_TEXT: 'Crea un nuovo gruppo da condividere con altri',
  GROUPS_FREE_JOIN_WARNING: 'Come utente gratuito, puoi unirti a un gruppo.',
  GROUPS_ADD_UPGRADE: `Esegui l'upgrade oggi per creare gruppi e condividere gli accessi tra amici, familiari e colleghi.`,
  GROUP_NEW_INVITE_GENERATING: 'Generazione del tuo invito di gruppo',
  LEAVE_GROUP_MESSAGE: 'Ora sei stato rimosso dal gruppo.',
  GROUP_DETAIL_LOADING: `Recupero dei dettagli del gruppo`,
  GRP_SHARING_HEADING: 'Condivisione',
  GRP_MEMBERS_HEADING: 'Membri',
  GRP_SETTINGS_HEADING: 'Impostazioni di gruppo',

  GROUP_VIEW_HEADER: 'Dettagli del gruppo',
  GROUP_VIEW_RIGHT_NAV: 'modificare',
  GROUP_VIEW_USER_ADMIN: 'Amministratore',
  GROUP_VIEW_USER_OWNER: 'Proprietario',
  GROUP_VIEW_USER_YOU: 'tu',
  GROUP_VIEW_USER_READ_ONLY: 'Sola lettura',
  GROUP_VIEW_SWITCH_TO: 'Passa a questo gruppo',
  GROUP_VIEW_LEAVE: 'Lascia questo gruppo',
  GROUP_VIEW_DELETE: 'Elimina gruppo',
  GROUP_VIEW_PREMIUM: 'Utente Premium',
  GROUP_VIEW_FREE: 'Utente libero',
  GROUP_DETAIL_SAVE_LOADING: `Aggiorna i dettagli del tuo gruppo`,
  GROUP_EDIT_HEADER: 'Dettagli del gruppo',
  GROUP_EDIT_MAX_MEMBERS: 'Numero massimo di membri',
  GROUP_EDIT_MAX_MEMBERS_SECONDARY: 'Compreso te',
  PERSONAL_GROUP_MESSAGE: `Questo è il tuo gruppo personale. Questo gruppo non è mai condiviso con nessun altro. Per cambiare l'icona di questo gruppo, vai al tuo profilo e cambia la tua immagine del profilo.`,

  GROUP_CHOOSE_ICON: `Scegli l'icona`,

  GROUP_NAME_BLANK: 'Il nome del gruppo non può essere lasciato vuoto',
  GROUP_NAME_TOO_LONG: 'Il nome del gruppo non può contenere più di 32 caratteri',

  GRP_MAX_MEMBERS_ERROR: 'Il numero massimo di membri deve essere un numero compreso tra 1 e 50',

  JOIN_GROUP_PLACEHOLDER: 'Inserisci il tuo link di invito',
  JOIN_GROUP_HEADING: 'Unirsi al gruppo',
  GRP_INVITE_CODE_LABEL: 'Link di invito di gruppo',
  GRP_INVITE_CODE_NOTE: (group) => `Invita membri a <em>${group}</em> condividendo questo link`,
  JOIN_GROUP_RIGHT_NAV: 'Aderire',

  REFERRAL_COPY_MESSAGE: (url) =>
    `Ti invito a usare i forghetti. Scarica l'app e poi dimentica le password per sempre. Vai a ${url}`,

  CREATE_GROUP_INVITE_TEXT_1: `Invia questo codice a familiari, amici o membri del team per consentire loro di accedere a questo gruppo.

Per unirti a un gruppo, fai clic su "Aggiungi, quindi su" Unisciti al gruppo "e incolla il link di invito.`,

  INVITE_NEW_MEMBER: 'Invita un nuovo membro',
  GRP_INVITE_HELP_TEXT: `Tutti i membri del gruppo hanno la possibilità di creare nuovi accessi, ma solo gli amministratori hanno la possibilità di eliminarli. Gli amministratori possono anche rimuovere i membri, tuttavia se tu crei il gruppo, non potrai mai essere rimosso da esso da un altro amministratore.`,

  // Template for text that is copied to clipboard when you choose to copy a group invite code to clipboard
  GRP_INVITE_CLIPBOARD_MESSAGE: (code) => `${code}`,

  INVITE_LINK_HEADING: 'Invito',
  INVITE_SETTINGS_HEADING: 'impostazioni',
  LINK_EXPIRY_LABEL: 'Il collegamento scade dopo',
  MAX_USES_LABEL: 'Numero massimo di utilizzi',

  LINK_EXPIRY_VALUE: (mins) => LINK_EXPIRY_VALUES[String(mins)],
  LINK_MAX_USES_VALUE: (num) => LINK_MAX_USE_VALUES[String(num)],
  INVITE_NO_LIMIT: LINK_MAX_USE_VALUES['-1'],
  INVITE_EXPIRY_NEVER: LINK_EXPIRY_VALUES['-1'],

  DEACTIVATE_GRP_LINK_CONFIRM: 'Sei sicuro di voler eliminare questo invito?',
  DEACTIVATE_GRP_LINK_SUCCESS: `L'invito di gruppo è stato eliminato con successo.`,

  GRP_INVITE_CREATE_NAV: 'Creare',

  // Share Group Invite page
  SHARE_INVITE_HEADING: 'Invita membri',
  GRP_INVITE_CODE_LABEL: 'Link di invito',
  CPY_GRP_INVITE: `Copia l'invito negli appunti`,
  DEACTIVATE_LINK: 'Elimina invito',
  SHARE_INVITE_VIA_EMAIL: `Condividi l'invito tramite e-mail`,
  COPY_INVITE_SUCCESS: `Invito di gruppo copiato negli appunti.`,

  ONBOARDING_HEADER_1: 'Crea un accesso a un sito web preferito',
  ONBOARDING_HEADER_2: 'disegna il tuo segno sullo schermo',
  ONBOARDING_HEADER_3: 'La tua password è stato generata',
  ONBOARDING_HEADER_4: 'Copia e vai!',
  ONBOARDING_HEADER_5: 'Questo è tutto!',
  ONBOARDING_WELCOME_HEADING: `Ciao, mi chiamo Locky <br> Benvenuto in forghetti`,
  ONBOARDING_WELCOME_TEXT: 'Con forghetti potrai accedere a tutti i servizi che utilizzi, con un unico segno sicuro.',
  ONBOARDING_DOODLE_HEADING: 'Avrai bisogno di una firma con segno',
  ONBOARDING_DOODLE_TEXT:
    'Dalle linee ai punti, agli scarabocchi casuali o alle sequenze di numeri. La tua combinazione unica genera una password super sicura.',
  ONBOARDING_HOW_IT_WORKS: 'Come funziona',
  ONBOARDING_ADDSERVICE_HEADING: 'Ottimo per qualsiasi servizio',
  ONBOARDING_ADDSERVICE_TEXT:
    'Ogni volta che aggiungi un servizio, generiamo una password unica e ultra sicura. Puoi anche generare codici pin e parole memorizzabili.',
  ONBOARDING_RESETPASSWORD_HEADING: 'Reimposta e cambia la tua vecchia password',
  ONBOARDING_RESETPASSWORD_TEXT:
    'Apri il sito e reimposta la tua vecchia password, sostituendola con la tua nuova password forghetti ultra sicura.',
  ONBOARDING_SECURESHARING_HEADING: 'Condivisione sicura della password',
  ONBOARDING_SECURESHARING_TEXT: `Usa i gruppi per condividere l'accesso ai tuoi servizi preferiti in modo sicuro con amici, familiari e colleghi.`,
  ONBOARDING_UNLIKE_OTHERS_HEADING: 'A differenza di altri gestori di password ...',
  ONBOARDING_UNLIKE_OTHERS_TEXT:
    'Non salviamo un database di password. Li generiamo quando ne hai bisogno. Vengono calcolati utilizzando il tuo segno come chiave. Super sicuro e conosciuto solo da te.',
  ONBOARDING_DOODLETUTORIAL1_HEADING: 'Ora, creando il tuo segno firma! Come si fa?',
  ONBOARDING_SHOWMEHOW: 'Lascia che ti mostri come!',
  ONBOARDING_DOODLETUTORIAL2_HEADING: 'In primo luogo, puoi disegnare un motivo ...',
  ONBOARDING_DOODLETUTORIAL: 'Tutorial segno sicuro',
  ONBOARDING_DOODLETUTORIAL3_HEADING: 'o una serie di righe',
  ONBOARDING_DOODLETUTORIAL4_HEADING: 'anche i punti vanno bene ...',
  ONBOARDING_DOODLETUTORIAL5_HEADING: 'O una combinazione di tutti insieme',
  ONBOARDING_DOODLETUTORIAL6_HEADING: `Ma ricorda che l'ordine è importante,`,
  ONBOARDING_DOODLETUTORIAL7_HEADING: 'Un segno A',
  ONBOARDING_DOODLETUTORIAL8_HEADING: 'non è la stessa cosa che un segno B',
  ONBOARDING_YOURTURN: `Ora è il tuo turno`,

  ONBOARDING_COMPLETE_WELCOME: 'Benvenuti in forghetti',
  ONBOARDING_COMPLETE_CONGRATULATIONS:
    'Congratulazioni! Hai appena fatto i primi passi per proteggere la tua identità online',
  ONBOARDING_COMPLETE_COMPLETE_SETUP: 'Completa la configurazione di forghetti',
  ONBOARDING_COMPLETE_CREATE_FORGHETTI: 'Crea un account forghetti',
  ONBOARDING_COMPLETE_SIGNATURE_DOODLE: 'Crea il tuo segno personale',
  ONBOARDING_COMPLETE_FIRST_LOGIN: 'Aggiungi il tuo primo servizio',
  ONBOARDING_COMPLETE_RESET_PASSWORD: 'Hai generato una password sicura',
  ONBOARDING_COMPLETE_IMPORT: 'Servizi di importazione',
  ONBOARDING_COMPLETE_HEALTHCHECK: 'Esegui un controllo dello stato sui tuoi account online',
  ONBOARDING_COMPLETE_IMPORT_BUTTON: 'Servizi di importazione',
  ONBOARDING_COMPLETE_REMIND_LATER: 'Ricordamelo più tardi',
  ONBOARDING_COMPLETE_CONTINUE: `Andiamo`,
  ONBOARDING_COMPLETE_RESET_PASSWORD: 'Reimposta una password non sicura',
  ONBOARDING_COMPLETE_HEALTHCHECK_BUTTON: 'Esegui un controllo dello stato',
  ONBOARDING_COMPLETE_NEXT_STEPS: `<h2>Ora perché no:</h2><ul><li>eseguire un controllo dello stato di salute sui propri account online?</li><li>Oppure aggiorna un altro servizio su forghetti,</li><li>O dai un'occhiata in giro ...</li></ul>`,

  ONBOARDING_EXISTING_TITLE: 'Nuove caratteristiche',
  ONBOARDING_EXISTING_WELCOME_HEADING: 'Un grande grazie',
  ONBOARDING_EXISTING_WELCOME_TEXT: `La squadra di forghetti è stata impegnata. Abbiamo apportato molti miglioramenti dietro e davanti le quinte. Dai un'occhiata ad alcune delle nuove funzionalità.`,
  ONBOARDING_EXISTING_MOVE_SERVICES_HEADING: 'trasferimenti di servizi',
  ONBOARDING_EXISTING_MOVE_SERVICES_TEXT:
    'forghetti ora ti consente di spostare i servizi da un gruppo a un altro. Fai semplicemente clic sui tre punti accanto a ciascun servizio e seleziona Sposta.',
  ONBOARDING_EXISTING_HEALTHCHECK_HEADING: 'Controllo di stato',
  ONBOARDING_EXISTING_HEALTHCHECK_TEXT:
    'forghetti ora include Controllo di Stato, una funzione che ti consente di monitorare attivamente i tuoi nomi utente e password contro violazioni di dati ben note.',
  ONBOARIDNG_EXISTING_SHARING_HEADING: 'Rapida condivisione di gruppo',
  ONBOARDING_EXISTING_SHARING_TEXT:
    'Condividi i tuoi gruppi forghetti con i tuoi amici, familiari e colleghi di lavoro con un clic.',
  ONBOARDING_EXISTING_SWITCHING_HEADING: 'Cambio rapido di gruppo',
  ONBOARDING_EXISTING_SWTICHING_TEXT:
    'Lavorare con i tuoi gruppi è diventato più facile che mai. Aggiungere nuovi servizi a un gruppo è più facile e veloce che mai.',
  ONBOARDING_EXISTING_PRICING_HEADING: 'Aggiornamenti sui prezzi',
  ONBOARDING_EXISTING_PRICING_TEXT: 'forghetti offre ora più piani di abbonamento per soddisfare le tue esigenze.',

  ACCOUNT_SECURITY_HEADER: 'Sicurezza',
  ACCOUNT_SECURITY_HEADING: 'Niente è più sicuro',
  ACCOUNT_SECURITY_SECONDARY_HEADING:
    'Consideriamo la sicurezza dei dati una priorità. forghetti ti consente di generare login per più servizi utilizzando un unico segno. La parte intelligente di tutto questo è che nei database di forghetti non ci sono password memorizzate…. non uno. A differenza di ogni altra applicazione di gestione delle password, e ce ne sono molte, non memorizziamo le tue password.  ',
  ACCOUNT_SECURITY_LEARN_MORE: 'Ulteriori informazioni sulla sicurezza di forghetti',

  MSG_FALLBACK_ERROR_MESSAGE: `Si è verificato un errore! Ci dispiace`,

  MSG_GROUP_ICON_UPDATED: 'Icona aggiornata',
  MSG_COPIED_FORGHETTIBLE: (name) => `${name} copiato negli appunti`,
  MSG_OPEN_FORGHETTIBLE: `Clicca qui per aprire`,
  MSG_DELETE_LOGIN_CONFIRM: 'Sei sicuro di voler cancellare questo login?',
  MSG_LOGIN_DELETED: (name) => `Accesso ${name} eliminato`,
  MSG_SERVICE_ICON_DELETED: 'Icona del servizio aggiornata',
  MSG_LOGOUT_CONFIRM: 'Sei sicuro di voler effettuare il logout?',
  MSG_LOGOUT_ALL_DEVICES_CONFIRM: 'Sei sicuro?',
  MSG_USER_PROMOTED_ADMIN: `L'utente è stato promosso ad amministratore.`,
  MSG_USER_PROMOTED_OWNER: 'La proprietà è stata trasferita',

  MSG_CONFIRM_RESET: 'Conferma di voler reimpostare questa password',
  MSG_CONFIRM_RESTORE: 'Conferma che desideri ripristinare questa versione precedente?',
  MSG_NEW_GROUP_CREATED: (name) => `È stato creato un nuovo gruppo: ${name}`,
  MSG_OPENING_WEBSITE: 'Apertura del sito Web ...',
  MSG_REMOVED_ADMIN: 'Lo stato di amministratore è stato revocato per questo utente',
  MSG_UNSAVED_CONFIRM: 'Sono presenti modifiche non salvate. Sei sicuro di voler tornare indietro?',

  EXTENSION_NONE_FOUND: `Nessun 'forghettibles' trovato per questo sito`,
  EXTENSION_GO_TO_FORGHETTI: 'Vai a forghetti',
  EXTENSION_NOACCESS_HEADER: 'Aggiorna oggi',
  EXTENSION_UPGRADE: `Attualmente sei un utente gratuito. Esegui l'upgrade oggi per utilizzare l'estensione forghetti Chrome e beneficiare di accessi illimitati e della possibilità di creare gruppi e condividere accessi tra amici, familiari e colleghi. Se desideri passare a un abbonamento Premium, seleziona il pulsante Aggiorna in basso.`,
  EXTENSION_NOLOGINFOUND_HEADER: `Non trovato in forghetti`,
  EXTENSION_NOLOGINFOUND_MSG: `Nessun 'forghettible' trovato per questo sito web. Disegna uno segno di seguito per creare rapidamente un nuovo 'forghettible' per questo sito web`,

  CONTEXT_EDIT_LOGIN_LIST_ITEM: 'modificare',
  CONTEXT_MOVE_LOGIN_LIST_ITEM: 'muovere',
  CONTEXT_ADD_LOGIN_LIST_ITEM: `Aggiungi 'forghettible'`,
  CONTEXT_RESET_LOGIN_LIST_ITEM: `Ripristina 'forghettible'`,
  CONTEXT_DELETE_LOGIN_LIST_ITEM: 'Elimina',
  CONTEXT_UPLOAD_GROUP_PHOTO: 'Fare foto',
  CONTEXT_UPLOAD_GROUP_IMAGE: 'Carica immagine',
  CONTEXT_CHOOSE_GROUP_IMAGE: 'Scegli immagine',
  CONTEXT_TAKE_PROFILE_PIC: 'Fare foto',
  CONTEXT_UPLOAD_PROFILE_PIC: 'Carica una foto',
  CONTEXT_UPLOAD_SERVICE_IMAGE: 'Carica immagine',
  CONTEXT_CHOOSE_SERVICE_IMAGE: 'Scegli immagine',
  CONTEXT_SERVICE_IMAGE_WEBSEARCH: 'Ricerca sul web',
  CONTEXT_MAKE_ADMIN: 'Rendere amministratore',
  CONTEXT_MAKE_OWNER: 'Rendi proprietario',

  CONTEXT_REMOVE_FROM_GROUP: 'Rimuovi dal gruppo',
  CONTEXT_REVOKE_ADMIN: 'Revoca amministratore',
  CONTEXT_NEW_GROUP_MEMBERS: 'Nuovi numero max di membri del gruppo',
  CONTEXT_SELECT_FIELD_TYPE: 'Seleziona Tipo',
  CONTEXT_FIELD_TYPE_LNS: 'Lettere, numeri e simboli',
  CONTEXT_FIELD_TYPE_WNS: 'Parole, numeri e simboli',
  CONTEXT_FIELD_TYPE_LNX: 'Lettere, numeri e simboli complessi',
  CONTEXT_FIELD_TYPE_WNX: 'Parole, numeri e simboli complessi',
  CONTEXT_FIELD_TYPE_LN: 'Lettere e numeri',
  CONTEXT_FIELD_TYPE_WN: 'Parole e numeri',
  CONTEXT_FIELD_TYPE_L: 'Solo lettere',
  CONTEXT_FIELD_TYPE_W: 'Solo parole',
  CONTEXT_FIELD_TYPE_N: 'Solo numeri',
  CONTEXT_ADD_FIELD:
    'Seleziona un campo predefinito di seguito. È possibile personalizzare le impostazioni del campo dopo, se necessario.',
  CONTEXT_ADD_FIELD_PASS: 'Password (lettere, numeri e simboli)',
  CONTEXT_ADD_FIELD_COMPLEX_PASS: 'Password avanzata (lettere, numeri e simboli complessi)',

  CONTEXT_ADD_FIELD_PIN: 'Pin (solo numeri)',
  CONTEXT_ADD_FIELD_MEMWRD: 'Parola memorizzabile (solo parole)',
  CONTEXT_ADD_FIELD_FORGHETTIBLE: `Solo lettere`,
  CONTEXT_LINK_EXPIRE_15MINS: '15 minuti',
  CONTEXT_LINK_EXPIRE_30MINS: '30 minuti',
  CONTEXT_LINK_EXPIRE_1HOUR: '1 ora',
  CONTEXT_LINK_EXPIRE_6HOURS: '6 ore',
  CONTEXT_LINK_EXPIRE_12HOURS: '12 ore',
  CONTEXT_LINK_EXPIRE_1DAY: '1 giorno',
  CONTEXT_LINK_EXPIRE_NEVER: 'Mai',
  CONTEXT_LINK_EXPIRE_CANCEL: 'Annulla',
  CONTEXT_LINK_USES_1: 'Una volta',
  CONTEXT_LINK_USES_2: 'Due volte',
  CONTEXT_LINK_USES_5: '5 volte',
  CONTEXT_LINK_USES_10: '10 volte',
  CONTEXT_LINK_USES_20: '20 volte',
  CONTEXT_LINK_USES_50: '50 volte',
  CONTEXT_LINK_USES_NO_LIMIT: 'Senza limiti',
  CONTEXT_LINK_USES_CANCEL: 'Annulla',

  FINGERPRINT_TITLE: 'Benvenuti in forghetti',
  FINGERPRINT_DESCRIPTION: 'Scansiona la tua impronta digitale per sbloccare forghetti',
  FINGERPRINT_DESCRIPTION_FACE: 'Usa Face ID per sbloccare forghetti',

  GENERATING_GROUP: 'Generazione del tuo gruppo',
  GENERATING_PASSWORD: 'Generazione della tua password',
  DELETING_GROUP: 'Eliminazione del gruppo',
  LOADING_SERVICE: 'Caricamento del tuo servizio',
  UNABLE_TO_PURCHASE: `Impossibile acquistare l'abbonamento premium al momento. Riprova presto.`,

  HEALTHCHECK_WELCOME_HEADER: 'Controllo di stato',
  HEALTHCHECK_WELCOME_NOTE:
    'Esegui il controllo di stato di forghetti e scopri se i tuoi dati online sono stati compromessi a causa di una violazione dei dati.',
  HEALTHCHECK_WELCOME_BUTTON: 'Esegui un controllo di stato',
  HEALTHCHECK_USERNAMES_HEADER: `Cominciamo con la tua email`,
  HEALTHCHECK_USERNAMES_INFO_1: 'Controlla il tuo indirizzo email',
  HEALTHCHECK_USERNAMES_INFO_2:
    'Fateci sapere il vostro indirizzo e-mail e lo controlleremo contro eventuali violazioni online nuovi o precedenti.',
  HEALTHCHECK_USERNAMES_PRIMARY_SUBHEAD: 'Il tuo indirizzo email principale',
  HEALTHCHECK_USERNAMES_SECONDARY_SUBHEAD: 'Il tuo indirizzo email secondario',
  HEALTHCHECK_USERNAMES_PLACEHOLDER: 'Indirizzo email',
  HEALTHCHECK_PASSWORD_HEADER: `Controlliamo la tua password`,
  HEALTHCHECK_PASSWORD_INFO_1: 'Usi spesso la stessa password?',
  HEALTHCHECK_PASSWORD_INFO_2: `Prima di utilizzare forghetti usavi spesso la stessa password?
Vediamo se è stato esposto a una violazione dei dati.`,
  HEALTHCHECK_PASSWORD_INFO_3: `Questo controllo è facoltativo. Forghetti non memorizza né trasmette la tua password. Creeremo immediatamente un segmento sicuro della tua password. Solo un segmento della tua password viene trasmessa dall'applicazione, così puoi essere certo che la tua password sia al sicuro con noi.`,

  HEALTHCHECK_PASSWORD_SUBHEAD: 'La tua password',
  HEALTHCHECK_PASSWORD_OPTIONAL: 'Questo è opzionale.',
  HEALTHCHECK_PASSWORD_PLACEHOLDER: 'Password',
  HEALTHCHECK_PASSWORD_VAL_TOO_LONG: 'Campo della password troppo lungo',

  HEALTHCHECK_RUNNING: 'Esecuzione del controllo di stato',
  HEALTHCHECK_USERNAME_VAL_FIELD_BLANK: `L'indirizzo e-mail non può essere lasciato vuoto`,
  HEALTHCHECK_USERNAME_VAL_FIELD_DUPLICATE: `L'indirizzo e-mail secondario non può essere uguale al primo`,
  HEALTHCHECK_USERNAME_VAL_FIELD_TOO_SHORT: 'Indirizzo e-mail troppo corto',
  HEALTHCHECK_USERNAME_VAL_FIELD_TOO_LONG: 'Indirizzo email troppo lungo',

  HEALTHCHECK_ASSESSMENT_HEADER: 'Controllo di stato',
  HEALTHCHECK_ASSESSMENT_EMAIL: (email) => `<em>Violazioni per ${email}</em>`,
  HEALTHCHECK_ASSESSMENT_INFO_1: 'I risultati del controllo di stato di salute sono visualizzati di seguito.',
  HEALTHCHECK_ASSESSMENT_INFO_2:
    'Gli utenti Premium possono fare clic sulla violazione per ottenere ulteriori informazioni.',

  HEALTHCHECK_ASSESSMENT_PASSWORD: '<em><em>Risultati per la tua password</em></em>',
  HEALTHCHECK_ASSESSMENT_COMPROMISED_HEAD: `Servizi compromessi`,
  HEALTHCHECK_ASSESSMENT_NO_BREACH: 'Nessun servizio violato con questo account',
  HEALTHCHECK_ASSESSMENT_PASSWORD_STRENGTH: 'Forza',
  HEALTHCHECK_ASSESSMENT_PASSWORD_WEAK: 'Debole',
  HEALTHCHECK_ASSESSMENT_PASSWORD_STRONG: 'Forte',
  HEALTHCHECK_ASSESSMENT_PASSWORD_COMP: 'Appare in una violazione dei dati',
  HEALTHCHECK_ASSESSMENT_HIBP_LINK: 'fonte: <a data-actionclick="openHIBP">haveIbeenpwned</a>',
  HEALTHCHECK_ASSESMENT_UPGRADE_MESSAGE: 'I dettagli sulla violazione sono disponibili solo per gli utenti premium',
  HEALTHCHECK_DETAILS_PASSWORD_HEADER: 'Risultati della password',
  HEALTHCHECK_DETAILS_USER_HEADER: `Dettagli sulla violazione`,
  HEALTHCHECK_DETAILS_USER_TITLE: 'Nome',
  HEALTHCHECK_DETAILS_USER_DOMAIN: 'Dominio',
  HEALTHCHECK_DETAILS_USER_DATE: 'Data di violazione',
  HEALTHCHECK_DETAILS_USER_ACCOUNTS_PWNED: 'Account violati',
  HEALTHCHECK_DETAILS_PASSWORD_INFO_X_BREACH: (breach) =>
    `La tua password è stata inserita in <em>${breach}</em> noto violatore di dati. Ciò non significa necessariamente che i tuoi account siano stati compromessi. Dovresti considerare di generare una password super sicura con <em>forghetti</em> il ​​prima possibile.`,
  HEALTHCHECK_DETAILS_PASSWORD_INFO_NO_BREACH: `Grandi notizie! La tua password non è elencata in nessun noto violatore di dati. Ciò non garantisce che i tuoi account non siano stati compromessi. Valuta la possibilità di generare una nuova password super sicura con <em>forghetti</em>.`,
  HEALTHCHECK_DETAILS_PASSWORD_STRENGTH_INFO_STRONG: `La tua password è <em>sicura</em>. Ciò significa che ha superato tutti i criteri previsti dal test di sicurezza della password di forghetti`,
  HEALTHCHECK_DETAILS_PASSWORD_STRENGTH_INFO_WEAK: `La tua password è <em>debole</em>. Ciò significa che non soddisfa i seguenti criteri stabiliti dal test di sicurezza della password di forghetti:`,
  HEALTHCHECK_DETAILS_RESET_NOTE: 'Genera subito una password super sicura con forghetti',
  HEALTHCHECK_DETAILS_RESET_BUTTON: 'Crea un nuovo servizio',
  HEALTHCHECK_DETAILS_HIBP_LINK: 'fonte: haveIbeenpwned',
  HEALTHCHECK_LOADING_DETAIL: 'Caricamento dei dettagli della violazione',

  IMPORT_SERVICE_WELCOME_BUTTON: 'Inizia a importare i servizi',
  IMPORT_SERVICE_WELCOME_INFO: 'Importa i tuoi dati di accesso esistenti a forghetti',
  IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_1: `Per importare i servizi aprire l'app desktop forghetti.`,
  IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_2: `Se non si dispone dell'app desktop forghetti, fare clic sul collegamento sottostante`,
  IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_BUTTON: 'Scarica forghetti',
  IMPORT_SERVICES_LIST_HEADER: 'Seleziona i servizi da importare',
  IMPORT_SERVICES_LIST_ERROR_NONE_SELECTED: 'Nessun servizio importato selezionato',
  IMPORT_SERVICES_YOUR_IMPORTED_SERVICES: 'I tuoi servizi importati',
  IMPORT_SERVICES_LIST_NOTE: `Scegli i servizi che desideri importare in forghetti.`,
  IMPORT_SERVICES_GROUP_NOTE: 'Seleziona un gruppo in cui desideri spostare i tuoi servizi',
  IMPORT_SERVICES_GROUP_HEADER: 'Seleziona gruppo',
  IMPORT_SERVICES_USERNAME_HEADER: 'Immettere i nomi utente',
  IMPORT_SERVICES_USERNAME_NOTE: 'Controlla i nomi utente associati a ciascun account',
  IMPORT_SERVICES_CREATE_HEADER: 'Crea servizi',
  IMPORT_SERVICES_CREATE_BUTTON: 'Inizia a creare servizi',
  IMPORT_SERVICES_CREATE_HOW_MANY: (number) => `<em>${number}</em> servizi da creare`,
  IMPORT_SERVICES_CREATE_LOADING: (current, total) => `Importato <em>${current}</em> di <em> ${total}</em> servizi`,
  IMPORT_SERVICES_COMPLETE_HEADER: 'Importazione completata',
  IMPORT_SERVICES_COMPLETE_NOTE: `Congratulazioni! I tuoi servizi sono stati importati`,
  IMPORT_FOLDER_SELECT_HEADER: 'Importa da Chrome',
  IMPORT_FOLDER_SELECT_CHROME: 'Importa servizi da Chrome',
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC: `forghetti dovrà accedere ai tuoi dati di login memorizzati sul tuo computer.`,
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_2: `Quando sei pronto, fai clic sul pulsante "Seleziona la cartella Chrome" di seguito. Verrà visualizzata una finestra di dialogo nella posizione dei dati di accesso a Chrome. Seleziona semplicemente "Apri" per consentire a forghetti di accedere a questa cartella.`,
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_3:
    'Se desideri individuare manualmente questa cartella, vai su Libary / Application Support / Google / Chrome',
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_4:
    'Ricorda, le tue password esistenti non vengono decifrate da forghetti. forghetti recupera solo i tuoi dati di accesso.',
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_5:
    'Una volta letti i tuoi dati di accesso potrai selezionare i servizi che desideri importare in forghetti.',

  IMPORT_FOLDER_SELECT_CHROME_BUTTON: 'Seleziona la tua cartella Chrome',

  IMPORT_GROUP_HEADER: 'Il tuo gruppo di servizi importato',
  IMPORT_GROUP_NOTE: (importingServices) =>
    `Un gruppo speciale <em>Servizi importati</em> viene creato automaticamente per te. Il gruppo conterrà i <em>${importingServices}</em> servizi selezionati per l'importazione.`,
  IMPORT_GROUP_NOTE_2:
    'I servizi in questo gruppo non sono abilitati. Per abilitare i servizi dovrai spostare i servizi in un altro gruppo. Facendo clic su Continua si accederà alla procedura guidata dei servizi di importazione. La procedura guidata dei servizi di importazione renderà il processo di spostamento dei servizi di importazione semplice e veloce.',
  IMPORT_GROUP_NOTE_3:
    'Se non hai tempo per spostare tutti i servizi importati in questo momento, non preoccuparti. È possibile spostare i servizi importati tramite la procedura guidata dei servizi di importazione in qualsiasi momento e su qualsiasi dispositivo.',
  IMPORT_GROUP_BUTTON: 'Continua',

  IMPORT_SERVICE_IMPORT_NODATA_HEADER: 'Impossibile trovare i dati di accesso',
  IMPORT_SERVICE_IMPORT_NODATA_NOTE: `forghetti non ha trovato alcun dato di login utilizzabile per l'importazione`,

  IMPORT_MOVE_SERVICE_HEADER: 'Inizia a spostare i tuoi servizi',
  IMPORT_MOVE_SERVICE_NOTE_1: (services) =>
    `Hai ${services} nel tuo gruppo di servizi importati. Per iniziare ad attivare i servizi importati, è necessario spostarli in un altro gruppo.`,
  IMPORT_MOVE_SERVICE_BUTTON: 'Continua',
  IMPORT_SERVICE_LOADING: 'Stiamo importando i tuoi servizi',
  IMPORT_SERVICE_GROUP_NAME: 'Servizi di importazione',
  NEW_GROUP_REQUIRES_PREMIUM: 'La creazione di un nuovo gruppo richiede un abbonamento premium.',

  IMPORT_TYPE_HEADER: 'Seleziona il tipo di importazione',
  IMPORT_TYPE_NOTE_1: `Forghetti può importare automaticamente i servizi dai tuoi dati di accesso a Google Chrome. Se utilizzi Google Chrome e desideri importare servizi utilizzando questo metodo, fai clic sul pulsante "Importa Google Chrome" di seguito.`,
  IMPORT_TYPE_BUTTON_1: `Importa Google Chrome`,
  IMPORT_TYPE_NOTE_2: `In alternativa, puoi importare i tuoi dati di accesso caricando un documento CSV o Excel (XLSX). Per iniziare a importare servizi utilizzando questa funzione, fai clic sul pulsante "Carica documento" di seguito.`,
  IMPORT_TYPE_BUTTON_2: 'Carica il documento',
  IMPORT_TYPE_NOTE_3: `Se utilizzi principalmente Safari, potresti voler utilizzare la funzionalità di caricamento dei documenti per importare i tuoi servizi. Per trovare le password del tuo portachiavi Safari, apri "Preferenze" (command + ',') nel browser Safari e vai alla scheda "Password",`,

  IMPORT_UPLOAD_HEADER: 'Carica il documento',
  IMPORT_UPLOAD_NOTE_1:
    'Carica un CSV o un foglio di calcolo (XLSX) per iniziare a importare i servizi. Come minimo, tutto ciò di cui hai bisogno è il nome di un servizio.',
  IMPORT_UPLOAD_BUTTON: 'Carica il tuo documento',
  IMPORT_UPLOAD_NOTE_2: 'Scarica il CSV o Excel di esempio per iniziare.',
  IMPORT_UPLOAD_SAMPLE_CSV: 'Scarica CSV di esempio',
  IMPORT_UPLOAD_SAMPLE_XLS: 'Scarica excel di esempio',

  // ===========================================================================
  // Testo guida
  // ===========================================================================

  //NO_LOGINS_MESSAGE: `Per iniziare, tocca "Aggiungi accesso" nell'angolo in alto a destra per aggiungere un accesso a un servizio che utilizzi (ad esempio Amazon, Facebook, Barclays).

  NO_LOGINS_MESSAGE: `Aggiungi servizi a questo gruppo facendo clic sull'icona + (più)

Condividi questo gruppo con amici, colleghi o familiari cliccando sull'icona di condivisione (in alto a destra)

Puoi modificare il nome di questo gruppo e gestire chi ha accesso nelle impostazioni del gruppo

`,

  LOGIN_RECENT: 'Recente',
  HELP_LOGIN_TITLE: `Inserisci un titolo per il tuo login (ad esempio Amazon). Aggiungi un logo toccando l'icona della fotocamera. Se non selezioni un'icona, ne verrà creata una predefinita.`,
  HELP_LOGIN_USERNAME: `Inserisci il nome utente che utilizzi per il tuo login. Questo potrebbe essere il tuo indirizzo email, ad esempio. Se lo fai, questo ti permetterà di copiarlo e incollarlo facilmente dalla schermata di riepilogo dell'accesso.`,
  HELP_LOGIN_WEBSITE: `Facoltativamente, inserisci l'indirizzo del sito web del tuo login. Se lo fai, questo ti permetterà di aprire il sito web direttamente dalla schermata di riepilogo dell'accesso.`,
  HELP_LOGIN_ADD_FORGHETTIBLE: `Aggiungi una password standard (l'impostazione predefinita è complessa e lunga, quindi non è necessario modificarla affatto), una parola memorizzabile o un PIN qui. Se vuoi più controllo aggiungi un 'forghettible' personalizzabile.

Devi aggiungere almeno un 'forghettible' per continuare. Quindi tocca Avanti nell'angolo in alto a destra dello schermo.`,

  HELP_GROUPS_LIST_HEADER: `Tocca il segno + per creare o unirti a un gruppo. Gli abbonati gratuiti possono unirsi solo a un gruppo di un abbonato a pagamento.

È possibile utilizzare i gruppi per gestire diverse categorie di accesso o per condividere un gruppo di accessi.

Ricorda che dovrai condividere il gruppo Segno con tutti i membri in modo che possano generare le tue stesse password. Quindi considera l'utilizzo di una parola memorizzabile, una frase o un codice facile da ricordare.
`,

  HELP_GROUPS_LIST_PERSONAL: `Questo è il tuo unico gruppo personale. Non puoi eliminarlo o condividerlo con altri.`,
  HELP_GROUPS_LIST_OTHER_GROUPS: `Questi sono tutti gli altri gruppi che hai creato come abbonato Premium o a cui ti sei unito su invito di qualcun altro`,

  HELP_DOODLEPAD: `Disegna uno segno che ricorderai. Linee collegate, punti o una combinazione di entrambi. Più strati, più sicuro. Tuttavia, è necessario ricordarlo poiché questa è la chiave per poter generare le tue password ora e per sempre.

Preoccupato per occhi indiscreti? Tocca "Segno segreto" e disegna senza lasciare traccia. Fai un errore e puoi ricominciare da capo toccando "Ridisegna". Quindi premere il segno di spunta per generare la password.`,

  HELP_SERVICE_SUMMARY: `Tocca l'occhio per visualizzare la password. Tocca la tua password per copiarla negli appunti. Vai al sito web del servizio e reimposta la tua password attuale con questa nuova.

Allora puoi dimenticarlo. La prossima volta che ne avrai bisogno, disegna il segno e forghetti lo genererà di nuovo. Non viene salvato da nessuna parte, ma generato quando ne hai bisogno con il tuo segno.
`,

  HELP_FIELD_SETTINGS_LENGTH: `Scegli la lunghezza del 'forghettible' come il numero di caratteri - utilizzato per una password o PIN standard - o il numero di parole, se si utilizzano parole memorizzabili.`,
  HELP_FIELD_SETTINGS_TYPE: `Scegli il tipo di 'forghettible' come combinazione di lettere, simboli, numeri e parole.`,
  HELP_FIELD_SETTINGS_NAME: `Immettere un nome descrittivo per la password forghettible. Nota che questa non è la password stessa, poiché è generata automaticamente da forghetti.`,
  HELP_FIELD_SETTINGS_RESET: `Se devi modificare una password generata da forghetti in qualsiasi momento, clicca semplicemente su Ripristina e la prossima volta che disegnerai il tuo Segno la password generata sarà cambiata.`,
  HELP_RESTORE_PAGE: `Le impostazioni per le vecchie versioni delle password forghetti possono essere ripristinate cliccando sulla data.

Se lo fai, quando disegni il tuo Segno, verrà generata la tua vecchia password.

Puoi passare avanti e indietro come desideri!
`,
  HELP_LOGINS_LIST: `Fare clic su un accesso per generare la password.

Usa lo strumento di ricerca per trovare il login di cui hai bisogno.
`,
  HELP_GROUP_PAGE: `Scorri fino in fondo per eliminare il gruppo, per passare alla visualizzazione degli accessi per questo gruppo o per lasciare un gruppo di cui non desideri più essere membro.`,
  HELP_GROUP_SHARING: `Crea un invito da condividere con un amico o un collega.`,
  HELP_GROUP_MEMBERS: `Gestisci le autorizzazioni degli utenti del gruppo, rendili utenti amministratori o rimuovili del tutto.`,
  HELP_GROUP_INVITE_MAX_USES: `Controlla per quanto tempo e quante volte un invito può essere utilizzato prima di inviarlo ad amici e colleghi.`,

  // ===========================================================================
  // Emails
  // ===========================================================================

  REFERRAL_EMAIL_SUBJECT: 'Un invito a usare forghetti, perché niente è più sicuro',
  REFERRAL_EMAIL_BODY: (url) =>
    `Ciao,

Sto usando una fantastica app chiamata forghetti. Mi ha aiutato a dimenticare le mie password, per sempre. Le tue password non vengono memorizzate da nessuna parte.

Genera le tue password quando ne hai bisogno creando un Segno. Disegna lo stesso doodle e ottieni ogni volta la stessa password. Così semplice, eppure così intelligente.

Usa questo link di riferimento per registrarti e ottenere funzionalità estese sia per te che per me!
${url}

Divertiti!`,

  // ===========================================================================

  GRP_INVITE_EMAIL_SUBJECT: 'invito gruppo forghetti',
  GRP_INVITE_EMAIL_BODY: (inviteUrl, referralUrl, referralCode) =>
    `Salve, vorrei condividere con voi il mio gruppo forghetti. Clicca sul seguente link per aprire Forghetti e unirti al mio gruppo:
${inviteUrl}

Se non hai forghetti, aggiungi questo codice di riferimento ${referralCode} nel tuo profilo sull'app e ottieni più tempo premium sia per te che per me! Dimentica le tue password, per sempre!`,

  APPRATE_TITLE: 'Ti dispiacerebbe dare un voto a forghetti',
  APPRATE_MESSAGE: `Non ci vorrà più di un minuto e aiuta a promuove la nostra app. Grazie per il vostro sostegno`,
  APPRATE_CANCEL: 'No grazie',
  APPRATE_LATER: 'Ricordamelo più tardi',
  APPRATE_RATE: 'Vota forghetti',
  APPRATE_YES: 'sì',
  APPRATE_NO: 'Non proprio',
  APPRATE_PROMPT_TITLE: 'Ti piace usare i forghetti',
  APPRATE_FEEDBACK: 'Ti dispiace darci qualche feedback?',

  BADGE_HEALTHCHECK_HEADER: 'Esegui un controllo dello stato',
  BADGE_HEALTHCHECK_INFO: 'Guadagna questo badge completando il controllo di stato di nome utente e password forghetti',
  BADGE_HEALTHCHECK_COMPLETE_INFO:
    'Congratulazioni! Hai ottenuto questo badge completando il controllo di stato forghetti',
  BADGE_HEALTHCHECK_REWARD: (premium) => (premium ? 'Ottieni 2 servizi extra per il tuo gruppo personale' : ''),
  BADGE_HEALTHCHECK_CTA: 'Esegui subito un controllo di stato',

  BADGE_RATING_HEADER: 'Vota forghetti',
  BADGE_RATING_INFO: 'Guadagna questo badge valutando forghetti',
  BADGE_RATING_COMPLETE_INFO: 'Congratulazioni! Hai guadagnato questo badge valutando forghetti',
  BADGE_RATING_REWARD: (premium) => (premium ? 'Ottieni 2 servizi extra per il tuo gruppo personale' : ''),
  BADGE_RATING_CTA: 'Vota subito forghetti',

  BADGE_PROFILECOMPLETE_HEADER: 'Completa il tuo profilo',
  BADGE_PROFILECOMPLETE_INFO: 'Guadagna questo badge completando il tuo profilo forghetti.',
  BADGE_PROFILECOMPLETE__COMPLETE_INFO:
    'Congratulazioni! Hai guadagnato questo badge completando il tuo profilo forghetti.',
  BADGE_PROFILECOMPLETE_REWARD: (premium) => (premium ? 'Ottieni 2 servizi extra per il tuo gruppo personale' : ''),
  BADGE_PROFILECOMPLETE_CTA: 'Completa ora il tuo profilo',

  BADGE_SHARING_HEADER: 'Condividi forghetti su facebook',
  BADGE_SHARING_INFO: 'Guadagna questo badge mettendo mi piace e condividendo forghetti sui tuoi canali social',
  BADGE_SHARING_COMPLETE_INFO:
    'Congratulazioni! Ti sei guadagnato questo badge mettendo mi piace e condividendo forghetti sui tuoi canali social',
  BADGE_SHARING_REWARD: (premium) => (premium ? 'Ottieni 2 servizi extra per il tuo gruppo personale' : ''),
  BADGE_SHARING_CTA: 'Condividi forghetti su Facebook',

  BADGE_IMPORT_HEADER: 'Servizi di importazione',
  BADGE_IMPORT_INFO: 'Ottieni questo badge quando importi servizi dal tuo portachiavi del telefono o del PC',
  BADGE_IMPORT_COMPLETE_INFO:
    'Congratulazioni! Hai guadagnato questo badge quando hai importato servizi dal tuo portachiavi del telefono o del PC',
  BADGE_IMPORT_REWARD: (premium) => (premium ? 'Ottieni 2 servizi extra per il tuo gruppo personale' : ''),
  BADGE_IMPORT_CTA: 'Inizia subito a importare i servizi ',

  BADGE_SUPERUSER_HEADER: 'Diventa un super utente',
  BADGE_SUPERUSER_INFO: `Guadagna questo badge quando hai condiviso con successo forghetti con 5 amici, hai più di due gruppi, hai un account premium e hai oltre 50 servizi nel tuo account forghetti`,
  BADGE_SUPERUSER_COMPLETE_INFO: `Congratulazioni! Hai guadagnato questo badge condividendo forghetti con 5 amici, aggiunto più di due gruppi, sottoscritto un account premium e creato oltre 50 servizi`,
  BADGE_SUPERUSER_REWARD: (premium) => (premium ? 'Ottieni 2 servizi extra per il tuo gruppo personale' : ''),
  BADGE_SUPERUSER_CTA: 'Condividi forghetti con un amico',

  BADGE_SERVICES_50_HEADER: '50 servizi!',
  BADGE_SERVICES_50_INFO: 'Guadagna questo badge creando 50 servizi nel tuo account forghetti',
  BADGE_SERVICES_50_COMPLETE_INFO:
    'Congratulazioni! Hai guadagnato questo badge creando più di 50 servizi nel tuo account forghetti',
  BADGE_SERVICES_50_REWARD: (premium) => (premium ? 'Ottieni 2 servizi extra per il tuo gruppo personale' : ''),
  BADGE_SERVICES_50_CTA: 'Crea subito un servizio',

  BADGE_SERVICES_150_HEADER: '150 servizi!',
  BADGE_SERVICES_150_INFO: 'Guadagna questo badge creando 150 servizi nel tuo account forghetti',
  BADGE_SERVICES_150_COMPLETE_INFO:
    'Congratulazioni! Hai guadagnato questo badge creando più di 150 servizi nel tuo account forghetti',
  BADGE_SERVICES_150_REWARD: (premium) => (premium ? 'Ottieni 2 servizi extra per il tuo gruppo personale' : ''),
  BADGE_SERVICES_150_CTA: 'Crea subito un servizio',

  BADGE_SERVICES_300_HEADER: '300 servizi!',
  BADGE_SERVICES_300_INFO: 'Guadagna questo badge creando 300 servizi nel tuo account forghetti',
  BADGE_SERVICES_300_COMPLETE_INFO:
    'Congratulazioni! Hai guadagnato questo badge creando più di 300 servizi nel tuo account forghetti',
  BADGE_SERVICES_300_REWARD: (premium) => (premium ? 'Ottieni 2 servizi extra per il tuo gruppo personale' : ''),
  BADGE_SERVICES_300_CTA: 'Crea subito un servizio',

  BADGE_SERVICES_500_HEADER: '500 servizi!',
  BADGE_SERVICES_500_INFO: 'Guadagna questo badge creando 500 servizi nel tuo account forghetti',
  BADGE_SERVICES_500_COMPLETE_INFO:
    'Congratulazioni! Hai guadagnato questo badge creando più di 500 servizi nel tuo account forghetti',
  BADGE_SERVICES_500_REWARD: (premium) => (premium ? 'Ottieni 2 servizi extra per il tuo gruppo personale' : ''),
  BADGE_SERVICES_500_CTA: 'Crea subito un servizio',

  BADGE_SHARING_1_HEADER: 'Condividi forghetti con un amico',
  BADGE_SHARING_1_INFO: 'Guadagna questo badge quando presenti un amico a forghetti e loro creano un account!',
  BADGE_SHARING_1_COMPLETE_INFO:
    'Congratulazioni! Ti sei guadagnato questo badge quando hai presentato un amico a forghetti e loro hanno creato un account!',
  BADGE_SHARING_1_REWARD: (premium) => (premium ? 'Ottieni 2 servizi extra per il tuo gruppo personale' : ''),
  BADGE_SHARING_1_CTA: 'Condividi forghetti con un amico',

  BADGE_SHARING_5_HEADER: 'Condividi forghetti con 5 amici',
  BADGE_SHARING_5_INFO: 'Guadagna questo badge quando presenti 5 amici a forghetti e loro creano un account!',
  BADGE_SHARING_5_COMPLETE_INFO:
    'Congratulazioni! Ti sei guadagnato questo badge presentando 5 amici a forghetti e loro hanno creato degli account!',
  BADGE_SHARING_5_REWARD: (premium) => (premium ? 'Ottieni 2 servizi extra per il tuo gruppo personale' : ''),
  BADGE_SHARING_5_CTA: 'Condividi forghetti con un amico',

  BADGE_SHARING_10_HEADER: 'Condividi forghetti con 10 amici',
  BADGE_SHARING_10_INFO: 'Guadagna questo badge quando presenti 10 amici a forghetti e loro avranno creato un account!',
  BADGE_SHARING_10_COMPLETE_INFO:
    'Congratulazioni! Ti sei guadagnato questo badge quando hai presentato 10 amici a forghetti e loro hanno creato degli account!',
  BADGE_SHARING_10_REWARD: (premium) => (premium ? 'Ottieni 2 servizi extra per il tuo gruppo personale' : ''),
  BADGE_SHARING_10_CTA: 'Condividi forghetti con un amico',

  BADGE_SHARING_25_HEADER: 'Condividi forghetti con 25 amici',
  BADGE_SHARING_25_INFO: 'Guadagna questo badge quando inviti 25 amici a forghetti e loro avranno creato un account!',
  BADGE_SHARING_25_COMPLETE_INFO:
    'Congratulazioni! Ti sei guadagnato questo badge presentando 25 amici a forghetti e loro hanno creato degli account!',
  BADGE_SHARING_25_REWARD: (premium) => (premium ? 'Ottieni 2 servizi extra per il tuo gruppo personale' : ''),
  BADGE_SHARING_25_CTA: 'Condividi forghetti con un amico',

  BADGE_SHARING_50_HEADER: 'Condividi forghetti con 50 amici',
  BADGE_SHARING_50_INFO: 'Guadagna questo badge invitando 50 amici a forghetti e loro creano un account!',
  BADGE_SHARING_50_COMPLETE_INFO:
    'Congratulazioni! Ti sei guadagnato questo badge invitando 50 amici a forghetti e loro hanno creato degli account!',
  BADGE_SHARING_50_REWARD: (premium) => (premium ? 'Ottieni 2 servizi extra per il tuo gruppo personale' : ''),
  BADGE_SHARING_50_CTA: 'Condividi forghetti con un amico',

  BADGE_GROUP_1_HEADER: 'Crea un nuovo gruppo in forghetti',
  BADGE_GROUP_1_INFO: `Guadagna questo badge quando crei e condividi un nuovo gruppo all'interno di forghetti`,
  BADGE_GROUP_1_COMPLETE_INFO: `Congratulazioni! Hai guadagnato questo badge quando hai creato e condiviso un nuovo gruppo all'interno di forghetti`,
  BADGE_GROUP_1_REWARD: (premium) => (premium ? 'Ottieni 2 servizi extra per il tuo gruppo personale' : ''),
  BADGE_GROUP_1_CTA: 'Crea subito un gruppo',

  BADGE_GROUP_5_HEADER: 'Crea 5 gruppi in forghetti',
  BADGE_GROUP_5_INFO: `Ottieni questo badge quando crei e condividi 5 gruppi all'interno di forghetti`,
  BADGE_GROUP_5_COMPLETE_INFO: `Congratulazioni! Hai guadagnato questo badge quando hai creato e condiviso 5 gruppi all'interno di forghetti`,
  BADGE_GROUP_5_REWARD: (premium) => (premium ? 'Ottieni 2 servizi extra per il tuo gruppo personale' : ''),
  BADGE_GROUP_5_CTA: 'Crea subito un gruppo',

  BADGE_LEGACY_HEADING: `Sei una superstar!`,
  BADGE_LEGACY_INFO_COMPLETE: `Grazie mille per essere un utente forghetti di lungo termine. Come piccolo segno del nostro apprezzamento, ti è stato assegnato questo badge speciale e 3 mesi del nostro abbonamento premium gratuitamente!`,

  JOIN_GORUP_ALERT_MESSAGE: `Ti stiamo solo aggiungendo al gruppo. Ti faremo sapere quando sarà finito.`,
  GROUP_UNAVAILABLE: (groupName) =>
    `È stato superato il numero di 'forghettibles' gratuiti su <em>${groupName}</em>. Per aggiungere servizi al gruppo, considera l'adeguamento del tuo abbonamento oggi.`,
  GROUP_PERSONAL_UNAVAILABLE: (groupName) =>
    `È stato superato il numero di 'forghettibles' gratuiti nel tuo gruppo personale. Per aggiungere servizi al gruppo, considera l'adeguamento del tuo abbonamento oggi.`,
  GROUP_NONE_AVAILABLE: 'Tutti i tuoi gruppi hanno superato il limite consentito',

  SHARE_INVITE_USERNAME_HEADING: 'Prima di iniziare a condividere',
  SHARE_INVITE_USERNAME_INFO:
    'Affinché i tuoi amici possano identificare i gruppi che desideri condividere, forniscici un nome profilo per il tuo account',
  SHARE_INVITE_USERNAME_PLACEHOLDER: 'Il tuo nome',

  LEGACY_USER_ONBOARDING_HEADING: 'Apprezziamo la tua lealtà',
  LEGACY_USER_ONBOARDING_INFO:
    'Come ricompensa ti offriamo 3 mesi di abbonamento premium assolutamente gratuiti! Ci auguriamo che la nuova versione di forghetti vi piaccia ancora di più della precedente.',
  CLAIM_PREMIUM: 'Richiedi la tua ricompensa',
  LEGACY_USER_RELOAD: `Per poter applicare l'abbonamento premium è necessario ricaricare forghetti. Ciò avverrà automaticamente in pochi secondi.`,

  SERVICE_OPEN_ICON_LABEL: 'Aperto',
  SERVICE_VIEW_ICON_LABEL: 'Visualizza',
  SERVICE_HIDE_ICON_LABEL: 'Nascondere',
  SERVICE_WEBSITE_ICON_LABEL: 'Aperto',
  SERVICE_COPY_ICON_LABEL: 'copia',

  GROUPSLIST_SWITCH_LABEL: 'passare',
  GROUPSLIST_SHARE_LABEL: 'invitare',
  GROUPSLIST_SETTINGS_LABEL: 'impostazioni',

  FORGHETTI_GROUP_PERMISSIONS: `Fare clic su questo <a data-actionclick="openPermissionsLink">collegamento</a> per saperne di più sulle autorizzazioni di gruppo.`,

  SUBSCRIPTION_TANDC: `Tieni presente che l'abbonamento si rinnoverà automaticamente a meno che non venga annullato prima della fine del periodo corrente. Puoi gestire e annullare l'abbonamento, che disattiva il rinnovo automatico, accedendo al tuo account dell'app store dopo l'acquisto. Per ulteriori informazioni, visita i nostri <a data-actionclick="openTermsOfServiceUrl">termini di servizio</a> e la nostra <a data-actionclick="openPrivacyPolicyUrl">politica sulla privacy</a>`,
  SUBSCRIPTION_TANDC_LIFETIME: `Si prega di notare che forghetti premium vita ti dà diritto a ... Per ulteriori informazioni, visita i nostri <a data-actionclick="openTermsOfServiceUrl">termini di servizio</a> e la nostra <a data-actionclick="openPrivacyPolicyUrl">politica sulla privacy</a>`,
  SUBSCRIPTION_ERROR:
    'Si è verificato un errore durante la convalida dei tuoi abbonamenti. Per favore riprova più tardi.',

  SUBSCRIPTION_TANDC_HEADER: 'Il tuo abbonamento',
  SERVICE_INJECT_LABEL: 'Uso',
  PHONE_NUMBER_TOO_LONG: 'Numero di telefono non valido',

  ADD_SERVICE_LOADING_PLEASE_WAIT: 'Attendi mentre generiamo il tuo servizio forghetti',
  ADD_SERVICE_LOADING_GENERATING_SERVICE: 'Stiamo generando il tuo nuovo servizio',
  ADD_SERVICE_LOADING_GENERATING_PASSWORD: 'Stiamo generando una password super sicura',
  ADD_SERVICE_LOADING_LOADING_SERVICE_DETAILS: 'Caricamento del tuo nuovo servizio forghetti',

  VIEW_SERVICE_LOADING_LOADING_SERVICE_DETAIL: 'Attendi mentre carichiamo il tuo servizio',
  VIEW_SERVICE_LOADING_LOADING_SERVICE_FORGHETTIBLE: 'Costruire i tuoi dati forghettible',

  MIGRATION_ALERT_HEADING: 'trasferisci il tuo account',
  MIGRATION_ALERT_MESSAGE: `Per modificare il numero, vai alla nostra applicazione web su <a href="https://app.forghetti.com" target="_new">https://app.forghetti.com</a>.`,
  DELETE_ALERT_HEADING: 'cancella il tuo account',
  DELETE_ALERT_MESSAGE: `Per eliminare il tuo account, vai alla nostra applicazione web su <a href="https://app.forghetti.com" target="_new">https://app.forghetti.com</a>.`,
  REFERRAL_CODE_UPDATE_NOT_ALLOWED: `Un codice di riferimento è già stato applicato al tuo account`,
  REFERRAL_CODE_APPLIED: (code) => `Il codice di riferimento <em>${code}</em> è stato applicato al tuo account.`,
  INVALID_PHONE_NUMBER: 'Il numero di telefono non è valido. Per favore riprova.',
  INVALID_ACTIVATION_CODE: 'Il codice di attivazione non è valido',
  INVALID_TOO_MANY_ATTEMPTS: `Ci sono stati troppi tentativi falliti di accedere con questo numero. Per favore riprova più tardi.`,

  REONBOARDING_POPUP_GO_AGAIN: `Abbiamo migliorato il modo in cui usi i forghetti. Ti piacerebbe familiarizzare su come funziona e cosa rende forghetti così eccezionale?`,
  REONBOARDING_POPUP_BUTTON_GO: `Fammi vedere`,
  REONBOARDING_POPUP_BUTTON_CANCEL: 'No grazie',
  REONBOARDING_POPUP_HEADING: `Tutto nuovo forghetti 2.0`,
  HELP_QUICKSTART: 'Introduzione rapida',
  YOUR_BADGES: 'I tuoi badges',
  GROUP_NOT_OWNER: 'Puoi spostare i servizi solo nei gruppi di cui sei il proprietario.',
  GROUP_NOT_OWNER_TITLE: 'trasferimento di servizi',
  VIEWSERVICE_NOT_COPIED_TO_CLIPBOARD: `<span class="toast--title">Avrai bisogno della tua nuova password. </span><span class="toast--text">Clicca sul pulsante di copia nel campo della password per aggiungere la nuova password negli appunti.</span>`,
  EXTENSION_ATTEMPT: (website) =>
    `<strong class="toast--title">Ricerca degli accessi per </strong><span class="toast--clipboard-item">${website}</span>`,
  EXTENSION_ATTEMPT_FAIL: (website) =>
    `<span class="toast--title"><span class="toast--title">Impossibile trovare accessi corrispondenti</span></span>`,

  UNIVERSAL_LINK_NEW_LOGIN: (url) => `Tentativo di trovare un servizio corrispondente per ${url}`,
  UNIVERSAL_LINK_NEW_LOGIN_NO_URL: `Avvio della nuova procedura guidata di servizio`,

  CONFIRM_COUNTRY_UPDATE_HEADER: 'Cambia la tua lingua',
  CONFIRM_COUNTRY_UPDATE_NOTE:
    'Per aggiornare la tua lingua preferita, forghetti si dovrà ricaricare. Conferma questa azione',
  CONFIRM_BUTTON: 'Ricarica forghetti',
  FORGHETTI_LOGIN: 'forghetti',
  FORGHETTI_LOGIN_TAGLINE: 'Dimentica le tue password. Per sempre.',
  FORGHETTI_LOGIN_CREATE: 'Crea un account',
  FORGHETTI_LOGIN_ALREADY_HAVE: 'Ho già un account',

  MUST_BE_LOGGED_IN_ACTION: `Questa azione è disponibile solo dopo aver effettuato l'accesso`,
  UPDATING_FORGHETTIBLE: `Aggiornando il tuo 'forghettible'`,
  FORGHETTI_ON_MAC: `forghetti desktop è disponibile per Mac. <a data-actionclick="openForghettiCom">Prendilo qui.</a>`,
  FORGHETTI_ON_WINDOWS: `forghetti desktop è disponibile per Windows. <a data-actionclick="openForghettiCom">Prendilo qui.</a>`,

  REALTIME_NEW_SERVICE_FOR_GROUP: (group) => `Un nuovo servizio è stato aggiunto al gruppo condiviso ${group}`,
  REALTIME_REMOVE_SERVICE_FOR_GROUP: (group) => `Un servizio è stato rimosso dal gruppo condiviso ${group}`,
  REALTIME_KICK_SERVICE_FOR_GROUP: (group) => `Sei stato rimosso dal gruppo ${group}`,
  REALTIME_BAN_SERVICE_FOR_GROUP: (group) => `Sei stato bannato dal gruppo ${group}`,
  REALTIME_JOIN_SERVICE_FOR_GROUP: (group) => `Un nuovo utente si è aggiunto ${group}`,
  REALTIME_REMOVE_GROUP: (group) => `Il gruppo ${group} è stato rimosso dal proprietario`,

  PERSONAL_GROUP: 'Personale',
  SHAREABLE_GROUP: 'Gruppo condivisibile',
  IMPORT_MENU: `Servizi di importazione`,
  IMPORT_GROUP: `Servizi importati`,

  MONTHS: [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
  ],

  AND: 'E',

  NEW_GROUP: 'Nuovo gruppo',
  LETS_START_SELECT: 'Cominciamo selezionando uno o più di questi servizi popolari ...',
  CREATE_SERVICES: 'Crea servizi',
  CREATING_SERVICES: 'Ora stiamo generando il tuo servizios',
  MUST_SELECT_MORE_THAN_ONE_SERVICES: 'Seleziona uno o più servizi',
  ADD_NOTE: 'Aggiungi una nuova nota',
  EDIT_NOTE: 'Modifica Nota',
  NOTE: 'Appunti',
  NOTE_COPIED: 'Nota copiata sugli appunti',
  DELETE_NOTE: 'Elimina nota',

  FORGHETTI_LOGIN_TAGLINE: 'Dimentica le tue password.',
  FORGHETTI_LOGIN_TAGLINE_2: 'Per sempre.',

  CREATE_ACCOUNT_1_HEADER_2: 'Crea il tuo account Forghetti.',

  CREATE_ACCOUNT_2_HEADING: 'Inserisci il tuo codice di verifica.',
  CREATE_ACCOUNT_FINAL_BUTTON: 'Mostrami come',

  ONBOARDING_DOODLETUTORIAL2_HEADING_2: 'Disegna uno schema',
  ONBOARDING_DOODLETUTORIAL3_HEADING_2: 'Più righe',
  ONBOARDING_DOODLETUTORIAL4_HEADING_2: 'Dots anche ...',
  ONBOARDING_DOODLETUTORIAL5_HEADING_2: 'O tutti insieme',
  ONBOARDING_DOODLETUTORIAL6_HEADING_2: `Ma ricorda, l'ordine conta!`,
  ONBOARDING_DOODLETUTORIAL7_HEADING_2: 'Doodle a,',
  ONBOARDING_DOODLETUTORIAL8_HEADING_2: 'Doodle A, non è lo stesso di Doodle B',
  SECURE_DOODLE_TUTORIAL: 'Tutorial Doodle sicuro',
  DOODLE_TRY_AGAIN: 'Riprova',
  WELCOME_TO_FORGHETTI: 'Benvenuti a Forghetti',

  ONBOARDING_COMPLETE_CONGRATULATIONS: `Congratulazioni! Hai appena fatto i primi passi per proteggere la tua identità online.`,

  ONBOARDING_COMPLETE_CREATE_FORGHETTI: 'Crea un account Forghetti.',
  ONBOARDING_COMPLETE_SIGNATURE_DOODLE: 'Crea il tuo doodle firma.',

  ONBOARDING_COMPLETE_RESET_PASSWORD: 'Ripristina una password non sicura.',

  ONBOARDING_COMPLETE_HEALTHCHECK: 'Esegui un controllo di stato sui tuoi account online.',

  ONBOARDING_COMPLETE_IMPORT: 'Servizi di importazione',
  ONBOARDING_COMPLETE_IMPORT_BUTTON: 'Servizi di importazione',
  ONBOARDING_COMPLETE_REMIND_LATER: 'Ricordamelo più tardi',
  ONBOARDING_COMPLETE_HEALTHCHECK_BUTTON: 'Eseguire un controllo di stato',

  USERNAME_OR_EMAIL: 'Nome utente',
  USERNAME_OR_EMAIL_SECOND: 'E-mail',
  SERVICE_EDIT_USERNAME_PLACEHOLDER: 'Immettere qualsiasi altro dettaglio di accesso',

  SERVICE_USERNAME_LABEL: 'Nome utente',
  SERVICE_USERNAME_SECOND_LABEL: 'E-mail',

  ADD_SERVICE_USERNAME_PLACEHOLDER: `Nome utente`,
  SERVICE_USERNAME_PLACEHOLDER_SECOND: `E-mail`,
  FREE_USER_UPGRADE: 'Aggiornamento a Premium',
  LOGIN_ACCOUNT_1_HEADER_2: 'Accedi al tuo account Forghetti.',

  KEYCHAIN_HEADING: 'Salva il tuo doodle',
  KEYCHAIN_MESSAGE:
    'Puoi salvare saldamente il tuo doodle su questo dispositivo per rendere ancora più semplice la generazione delle password.',
  KEYCHAIN_MESSAGE_2: 'Vorresti salvare il tuo doodle su questo dispositivo?',
  KEYCHAIN_MESSAGE_3: 'Puoi sempre cambiarlo nelle tue impostazioni.',
  KEYCHAIN_YES: 'Sì, salva il mio doodle',
  KEYCHAIN_NO: 'No, disegnerò il mio doodle ogni volta',

  LOADING_VAULT: 'Caricamento del Caveau',
  ONBOARDING_COMPLETE_FIRST_LOGIN: 'Aggiungi un servizio.',
  ONBOARDING_COMPLETE_CONTINUE: `Ora reimpostare una password`,
  STILL_LOADING: 'Stiamo caricando il tuo account',
  STILL_LOADING_2: 'il tuo account è stato verificato',

  CREATE_ACCOUNT_VERIFYING_PHONE: 'Stiamo verificando il tuo numero',
  NOTIFICATION_MESSAGE_BAR: `Fai clic su 'Usa' su un servizio per inviare la password a {{service}}`,
  NO_NOTIFICATIONS_HELLO_TEXT: (name) => `Ciao ${name}! Non hai ancora notifiche.`,
  NO_NOTIFICATIONS_CHECK: 'Controlla regolarmente per gli aggiornamenti.',
  REPLAY_DOODLE_HEADING: 'Rigiocare',
  FORGHETTIBLE: 'Forghettible',
  SERVICE_NO_NOTE: 'Nessuna nota',
  SERVICE_NOTE: 'Nota',
  SERVICE_BAD_NOTE: 'Per sbloccare questa nota, ridisegnare il doodle',
  SERVICE_NAME: 'Nome di Servizio',
  REPLAY_DOODLE: 'Replay Doodle',
  ENABLE_BIOMETRICS: 'Abilita biometria',
  DISABLE_BIOMETRICS: 'Disabilita biometria',
  WEB_DELAY:
    'Stabilire una connessione e caricare i tuoi dettagli. Se questo richiede troppo tempo, controlla la connessione.',
  WEB_WAITING: 'Sto ancora lavorando, per favore aspetta ...',
  PROFILE_MIGRATE_2_INFO_2: 'Ora inserisci il codice di verifica che ricevi sul tuo vecchio telefono:',
  MIGRATE: 'Migrare',
  MONTHLY: 'Mese',
  YEARLY: 'Anno',
  UNLOCK_BIOMETRICS: 'Sblocca con biometria',
  UNLOCK_DESCRIPTION: 'Sblocca il tuo account Forghetti con biometria',
  UNLOCK_SERVICE: (service) => `Sblocca ${service}`,
  UNLOCK_SERVICE_DESCRIPTION: (service) => `Per sbloccare ${service} usa la biometria o ridisegnando il doodle`,
  UNLOCK_FALLBACK: 'Usa Doodle',
  UNLOCK_CANCEL: 'Annulla',
  RATE_US: 'Valutaci',
  SETUP_NEW_SERVICE_MESSAGE: `Impostiamo il tuo nuovo servizio fornendo alcuni dettagli.`,
  LOGIN_ACCOUNT_1_MSG: `Inserisci il numero di cellulare per ricevere un SMS con il codice di verifica una tantum.`,
  LETS_START_SELECT_COUNT: (count) =>
    `Cominciamo selezionando questi servizi popolari ... puoi selezionare <em data-count>${count}</em> di più sul livello gratuito`,
  TOAST_TOO_MANY_SERVICES_SELECTED: (count) =>
    `Hai selezionato troppi servizi. Seleziona un massimo di ${count} servizi.`,
  LETS_START_ADDING_DETAILS: `Aggiungiamo qualche dettaglio in più`,
  ONBOARDING_COMPLETE_CONGRATULATIONS: `Congratulazioni! Hai appena fatto i primi passi per proteggere la tua identità online.`,
  CREATE_ACCOUNT_1_MSG_4: `<b>Nota</b>: useremo il tuo numero esclusivamente per creare/supportare il tuo account Forghetti. Riceverai un codice di verifica del dispositivo una tantum.`,
  MOVE_SERVICE_CONFIRM_NOTE_UPDATE: (fromGroup, toGroup, list, listLength) =>
    `${listLength > 1 ? 'Nuovo' : 'un nuovo'} <em>${list}</em> forghettible${
      listLength > 1 ? 's' : ''
    }  Verrà generato da ${toGroup} il tuo doodle. Se desideri accedere al tuo vecchio forghettible${
      listLength > 1 ? 's' : ''
    }, basta, invece, disegnare il tuo doodle ${fromGroup}.`,
  YOUR_SUBSCRIPTION_PARTNER_PREMIUM_TEXT_1: (partner, link) =>
    `I nostri partner di <em> ${partner} </em> ti stanno fornendo Forghetti Premium. Puoi saperne di più sulla tua abbonamento qui: <a href="${link}>" target="_new"> ${link} </a>`,
  YOUR_SUBSCRIPTION_PARTNER_PREMIUM_TEXT_2: `Speriamo che ti piaccia usare forghetti.`,
  PARTNER_PREMIUM_HEADING: `Congratulazioni!`,
  PARTNER_PREMIUM_MESSAGE: (partner) =>
    `Come partner Forghetti, i membri <em> ${partner} </em> ottengono forghetti premium. Non è necessario fare nulla, l'abbonamento verrà automaticamente applicato sul tuo account. Affinché le modifiche abbiano effetto, fai clic immediatamente a "ricarica Forghetti".`,
  PARNTER_PREMIUM_BUTTON: `Ricarica forghetti`,

  CSL_SUBSCRIPTION_HEADER: `Premium attivato`,
  CSL_SUBSCRIPTION_1: `Hai attivato il nostro servizio premium. Grazie. Quando questo abbonamento scade, puoi andare avanti come utente gratuito (e perdere tutti i tuoi benefici premium) o rinnovare l'abbonamento.`,
  CSL_SUBSCRIPTION_2: `Speriamo che ti stia divertendo a usare Forghetti.`,
  LICENCE_KEY_INVALID: (partner) => `Scusa, la tua richiesta di domanda non è valida.`,
  LICENCE_KEY_USED: (partner) => ` Scusa, la tua richiesta di domanda non è valida. `,
  LICENCE_KEY_ALREADY_APPLIED: (partner) => `Ti sei iscritto con successo. Grazie per aver usato questo servizio.`,

  SERVICE_EMAIL_LABEL: 'E-mail',
  SERVICE_EMAIL_PLACEHOLDER: 'user@email.com',
  SERVICE_EDIT_EMAIL_PLACEHOLDER: 'user@email.com',
  PROFILE_USE_BIOMETRICS: 'Conserva il tuo doodle con biometria',
  BIOMETRICS_GROUPS_HEADER: 'Abilita biometria',
  BIOMETRICS_SELECT_GROUP: 'Seleziona un gruppo per archiviare il tuo doodle con biometria',
  BIOMETRICS: 'Biometrica',
  BIOMETRICS_ENABLE: 'Abilitare',
  BIOMETRICS_DISABLE: 'disattivare',
  BIOMETRICS_SECURE: 'Sicuro con biometria',
  BIOMETRICS_SECURE_MESSAGE: (group) =>
    `Il tuo doodle per <em> ${group} </em> verrà archiviato in modo sicuro con la biometria`,
  BIOMETRICS_USE_DOODLE: 'Usa Doodle',
  BIOMETRICS_TRY_AGAIN: 'Riprova',
  NOTES: 'Appunti',
  SELECT_SPECIAL_CHARACTERS: 'Seleziona quali simboli includere',
  WORDS: 'Parole <span class = "monospace">Esempio</span>',
  LETTERS: 'Lettere <span class = "Monospace">ABC</span>',
  NUMBERS: 'Numeri <Span class = "Monospace">123</span>',
  SYMBOLS: 'Simboli <Span class = "Monospace">!$%</span>',
  SELECT_PASSWORD_TYPE: (forghettibleName) => `${forghettibleName} opzioni`,
  SELECT_PASSWORD_NUMBERS: 'Lettere / numeri / simboli totali',
  SELECT_PASSWORD_WORDS: 'Parole totali',
  ALL: 'Tutto',
  SIMPLE: 'Semplice',
  EDIT_PASSWORD_HEADING: 'Impostazioni',
  SERVICE_FIELD_PASSWORD_NO_OPTIONS: `È necessario selezionare almeno un'opzione password`,
  SERVICE_FIELD_PASSWORD_NO_SYMBOLS_SELECTED: 'È necessario selezionare almeno un simbolo',
  SUBSCRIPTION_ENDS: (days) => `Il tuo abbonamento dovrebbe scadere in ${days}.`,
  SUBSCRIPTION_ENDS_TOMORROW: 'Il tuo abbonamento dovrebbe scadere domani.',
  SUBSCRIPTION_ENDS_TODAY: 'Il tuo abbonamento dovrebbe scadere oggi.',
  NOTIFICATIONS: 'Notifiche',
  CONTEXT_CHANGE_TYPE: 'Opzioni di modifica',
  CONTEXT_CHANGE_LENGTH: 'Cambiare lunghezza',
  CONTEXT_RENAME: 'Rinomina Forghettibile',
  NEW_FORGHETTIBLE: 'Nuovo Forghettibile',
  OLD_FORGHETTIBLE: 'Old Forghettible',
  DELETE_FIELD_PROMPT: 'Sei sicuro che vorresti eliminare questo campo?',
  ONBOARDING2_WELCOME_HEADING: 'Benvenuti a Forghetti',
  ONBOARDING2_WELCOME_TEXT: 'Accedi a tutti i servizi che utente con uno scarabocchio sicuro.',
  ONBOARDING2_DOODLE_HEADING: 'Crea la tua doodle firmata',
  ONBOARDING2_DOODLE_TEXT:
    'Da linee o punti, a squiggle casuali o sequenze di numeri. La tua combinazione unica genera una password super forte. ',
  ONBOARDIN2_HOWITWORKS: 'Come funziona',
  ONBOARDING_CONFIRM_DOODLE: 'Conferma il tuo doodle',
  ONBOARDING_BIOMETRICS_CONFIRM: 'Vorresti salvare questo sul tuo portachiavi?',
  SAVING_NOTE: 'Crittografia della tua nota',
};
