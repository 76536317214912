const viewService1_doodlepad_extension = require('./extension/viewService1_doodlepad_extension');
const viewService_extension = require('./extension/viewService_extension');
const noLoginFound = require('./extension/noLoginFound');

module.exports = {
  viewService1_doodlepad_extension,
  viewService_extension,
  noLoginFound,
  ...require('./groups'),
  ...require('./profile'),
  ...require('./services'),
  ...require('./createAccount'),
  ...require('./healthcheck'),
  ...require('./importServices'),

};
