const showToastMessage = require('../../action_helpers/showToastMessage');
const config = require('../../../config');

module.exports = ({ z2hApp }) => {
  const groupId = z2hApp.currentGroup().id;
  const conf = confirm(config.strings.LEAVE_GROUP_CONFIRM());
  if (conf) {
    // Remove group from state
    z2hApp.removeGroup(groupId);
    return z2hApp
      .requestData('delete', '', `groups/${groupId}`)
      .then((response) => {
        if (response.status <= 209) {
          showToastMessage(config.strings.GROUP_DELETED_MESSAGE());
        } else {
          showToastMessage(response.user_message);
        }
      })
      .then(() => {
        z2hApp.paneNavigation('groupsList', $('#pane-1'));
      });
  }
};
