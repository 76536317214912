module.exports = ({element}) => {

	const input = $(element)

	// Get the search input as a lowercase string without whitespace
	const simplify = str => str.split(' ').join('').toLowerCase()
	const searchValue = simplify(input.val())

	// Get all the rows, and the title of each one
	const rows = input.closest('.table-view').find('.row')

	// Show rows where the title contains search string
	rows.each((i, row) => {
		const title = simplify($(row).find('.primary-text-content').text())
		if(title.indexOf(searchValue) >= 0) {
			$(row).show()
		}
		else {
			$(row).hide()
		}
 	})
}