module.exports = ({ element }) => {
  // Check which user preference the toggle switch is for
  const dataId = $(element).closest('.toggle-switch').attr('data-id');

  if (!dataId) return;

  // Get current value
  const enabled = localStorage.getItem('userprefs-' + dataId) === 'true' ? true : false;
  const newValue = !enabled;
  // Store the opposite
  localStorage.setItem('userprefs-' + dataId, newValue);

  $('body').toggleClass('userprefs-' + dataId, newValue);
};
