const config = require('../../../config');
const z2hApp = require('../../app/z2hApp');
const strings = config.strings;
const { primaryText, infoTextRow } = require('../../page_helpers/components');

module.exports = ({ data, nav }) => {

  

  z2hApp.groupsListUpdates(data.groupsList).then((data) => {
    if (data.deletedGroups.length > 0 || data.newGroups.length > 0 ||  data.updatedGroups.length > 0 && z2hApp.isPageActive('groups-list')) {
    z2hApp.paneNavigation('groupsList', $('#pane-1'));
    }
  });
  const groupsList = data.groupsList || [];
  const personalGroup = data.personalGroup || {};
  let myGroups = data.myGroups || [];
  let otherGroups = data.otherGroups || [];
  // Create an array of groups, which will exclude the personal group
  const groupCount =data.nonPersonalGroupsLength || 0;

  const page = {
    template: 'block-section-page',
    fields: {
      id: 'groups-list',
      active: false,
      header: strings.GROUPS_LIST_HEADER(),
      class: 'mergeWithNextRow',
      navbar: { mobile: true, desktop: true },
    },
    rows: [
      {
        template: 'block-section-row',
        fields: {
          class: 'no-border',
          class2: 'groups-list-intro',
        },
        columns: [
          primaryText(strings.GROUPS_LIST_INFO_MESSAGE(), true, false, false, true),
        ],
      },
      groupRow(personalGroup),
    ],
  };
    // Add a row for each
  myGroups.forEach(group => page.rows.push(groupRow(group)));

  let lastOwnerId = '';
  otherGroups.forEach(group => {
    const groupOwnerTemplate = groupName => ({
      template: 'block-section-row',
      fields: {
        class: 'centered-text',
        class2: 'no-border',
        class3: 'groups-shared-by-heading',
      },
      columns: [
        {
          template: 'block-text-group',
          fields: {
            primary_text: strings.GROUP_SHARED_BY(),
            secondary_text: groupName,
          },
        },
      ],
    });
    if (lastOwnerId !== group.owner_id) {
      page.rows.push(groupOwnerTemplate(group.owner_name));
    }
    lastOwnerId = group.owner_id || '';

    page.rows.push(groupRow(group));
  });

  page.rows.push(infoTextRow(strings.FORGHETTI_GROUP_PERMISSIONS(), true, false, true));

  return page;
};

// Helpers =========================================================================================
// For each group, we want to display a row like this...
function groupRow(group) {
  // Determine what icon to display
  const icon = group.personal
    ? window.state.userData.avatar || config.app.DEFAULT_USER_ICON
    : group.icon || config.app.DEFAULT_GROUP_ICON;

  // Get a comma-separated list of users in this group, starting with yourself
  let userList = config.strings.YOU();
  for (let u in group.users) {
    if (u !== window.state.userData.id) {
      // If not yourself...
      userList += ', ' + group.users[u].name;
    }
  }
  const selectedGroup = z2hApp.currentGroup()?.id === group.id;

  return {
    template: 'block-section-row',
    fields: {
      action: 'switchToGroup',
      navigation_data_id: group.id,
    },
    columns: [
      {
        template: 'block-login-avatar',
        fields: { avatar: icon, data_id: group.id },
      },
      {
        template: 'block-text-group',
        fields: { primary_text: group.name, secondary_text: selectedGroup && strings.GROUPS_LIST_CURRENTLY_ACTIVE() },
      },
      {
        template: 'block-icn-button',
        fields: {
          icon: selectedGroup ? 'switch' : 'switch-colour',
          class: selectedGroup ? 'highlight__disabled' : 'highlight',
          label: strings.GROUPSLIST_SWITCH_LABEL(),
        },
      },
      group.showInviteIcon && {
        template: 'block-icn-button',
        fields: {
          icon: 'group-share',
          action: 'createGroupInvite',
          data_id: group.id,
          navigation_data: '1',
          navigation_pane: '2',
          navigation_template: 'newInvite_loading',
          label: strings.GROUPSLIST_SHARE_LABEL(),
        },
      },

      {
        template: 'block-icn-button',
        fields: {
          icon: 'settings',
          data_id: group.id,
          action: 'viewGroupDetails',
          label: strings.GROUPSLIST_SETTINGS_LABEL(),
        },
      },
    ],
  };
}
