module.exports = ({ currentTarget, z2hApp, menuTarget }) => {
  const z2hTemplates = require('../../app/templates');

  let row;
  let heading;

  if (menuTarget) {
    row = $(menuTarget).closest('.row ');

    heading = $(menuTarget).closest('.row ').find('.service-heading');
  } else {
    row = $(currentTarget).closest('.row ');

    heading = $(currentTarget).closest('.row ').find('.service-heading');
  }

  const template = z2hApp.constructBlock(
    z2hTemplates.compileBlockAttribute({
      template: 'block-editable-primary-text',
      fields: {
        primary_text: heading.text().trim(),
        name: 'renameForghettible',
        for: `renameForghettible_${row.attr('id')}`,
        id: `renameForghettible_${row.attr('id')}`,
        class: ['no-blur'],
        icon: 'check-solid',
        icon_action: 'saveRenameForghettible',
      },
    }),
  );

  heading.replaceWith(template);

  setTimeout(() => {
    $(`#renameForghettible_${row.attr('id')}`).trigger('focus');
  }, 500);

  z2hApp.pageData.saveRenameForghettible = (fieldId) => {
    const row = $(`#${fieldId}`);
    const label = $(`#${fieldId}`).find('label');

    label.fadeOut('slow', () => {
      const template = z2hApp.constructBlock(
        z2hTemplates.compileBlockAttribute({
          template: 'block-section-service-heading',
          fields: {
            secondary_heading: row.find('[name=renameForghettible]').val(),

            secondary_heading_action: 'renameForghettibleMakeInput',
          },
        }),
      );

      label.replaceWith(template);
      label.fadeIn('slow');
    });
  };
};
