const moveService = require('../services/moveService');
const showToastMessage = require('../../action_helpers/showToastMessage');
const strings = require('../../../config/strings');

module.exports = ({ }) => {
  const groupId = window.state.selectedImportMoveGroupId;
  window.state.movingService = {};
  window.state.movingService.from = {};


  const services = window.state.currentServices;
  const service = services.find(s => s.id === window.state.selectedServiceId);


  //this is a bit messy and needs to be sorted...


  window.state.movingService.from.service = { ...service };
  window.state.movingService.from.group = window.state.selectedGroupId;

  z2hApp.pageData = z2hApp.pageData || {};
  z2hApp.pageData.service = {
    groupId: '',
    serviceData: {
      name: service.name,
      username: service.username,
      website: service.website,
      icon: service.icon,
    },
    serviceUnsaved: false,
    fieldsArray: [],
    fieldsSaved: {},
    fieldsUnsaved: {},
    movingService: true,
    currentServiceId: '',
    currentGroupId: window.state.selectedGroupId,

  }

  z2hApp.pageData.service.groupId = groupId;
  z2hApp.pageData.service.currentServiceId = window.state.selectedServiceId;




  const onSuccess = () => {
    showToastMessage('service moved')
  };
  const onFailure = () => {

  };

  const defaultGenerationSettings = window.state.defaultGenerationSettings || {};
  const fieldData = {
    generation_settings: {
      exclude: '',
      length: defaultGenerationSettings.length || 16,
      numbers: true,
      symbols: false,
      simple_symbols: true,
      //symbols: true,
      lowercase: true,
      uppercase: true,
      words: 0,
      version: 1,
    },
    name: strings.FIELD_TEMPLATE_PASSWORD(),
  }
  // Next loop
  const endpoint = `services/${window.state.selectedServiceId}/new-field`;
  const data = JSON.stringify(fieldData);
  z2hApp
    .requestData('post', '', endpoint, data)
    .then(response => {

      moveService({ z2hApp, onSuccess, onFailure, batch: true, groupId })
    })


};
