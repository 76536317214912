const config = require('../../../config');
const strings = config.strings;

const { infoTextRow, primaryText } = require('../../page_helpers/components');

module.exports = ({ nav, data, dataId, noHeading, confirmAction }) => {
  // This eventually needs to be done by the getPageData for this page,
  // and probably get the data from the server, as we will also need the image
  // for this service and perhaps other info, which means we'll also need an initial
  // "connecting" page before this one.

  // =================================================================================================
  // let loginItem = $('.login-item[data-id=' + dataId + ']');
  // let serviceName = '';
  // if (loginItem[0]) {
  //   serviceName = loginItem.find('.login-name').text();
  // }
  // const service = data;
  // const user = window.state.userData;
  // if (!serviceName) serviceName = service.name;
  // const groupId = window.state.selectedGroupId;
  // const group = window.state.groupsList.find(g => g.id === groupId);
  // const avatar = group.personal
  //   ? user.avatar || config.app.DEFAULT_USER_ICON
  //   : group.icon || config.app.DEFAULT_GROUP_ICON;
  // const avatar_id = group.personal ? window.state.userData.id : group.id;

  // =================================================================================================

  const serviceId = dataId || window.state.selectedServiceId;
  const user = window.state.userData;
  const groupId = window.state.selectedGroupId;

  window.state.biometrics = false;
  
  // let group = window.state.groupsList.find(g => g.id === groupId) || {};
  // if (!group.services) {
  //   console.log('No group');
  //   const findGroup = () => {
  //     return window.state.groupsList.find(g => g.id === groupId) || {};
  //   };

  //   function timeout(ms) {
  //     return new Promise(resolve => setTimeout(resolve, ms));
  //   }
  //   async function sleep(fn) {
  //     await timeout(500);
  //     group = fn();
  //   }
  //   sleep(findGroup);

  //   //We've lost the services of the group. Try again
  // }
  // const service =
  //   (serviceId && group.services.find(s => s.id === serviceId)) || window.z2hApp.pageData.service.serviceData || {};

  let group = window.state.groupsList.find((g) => g.id === data.group);
  let service = data.service;
  const serviceName = service.name;
  const serviceIcon = service.icon || config.app.DEFAULT_SERVICE_ICON;

  const groupIcon = group.personal
    ? user.avatar || config.app.DEFAULT_USER_ICON
    : group.icon || config.app.DEFAULT_GROUP_ICON;

  const groupName = group.name || '';

  const memberType = group.personal ? 2 : (group.users[window.state.userData.id] || {}).member_type;

  // =================================================================================================

  return {
    template: 'block-section-page',
    fields: {
      id: 'doodle-pad',
      active: false,
      header: serviceName,

      help_id: 'HELP_DOODLEPAD',

      navigation_left: config.strings.BACK(),
      navigation_left_icon: '',
      navigation_left_data: 0, //Math.max(nav - 1, 0),
      navigation_left_template: 'back',
      //navigation_left_pane: 1,

      //if we can edit the service lets do that.

      navigation_right: memberType > 0 ? strings.SERVICE_DETAILS_RIGHT_NAV() : strings.SERVICE_DOODLEPAD_RIGHT_NAV(),
      navigation_right_data: String(nav + 1),
      navigation_right_template: memberType > 0 ? 'editServiceDetails' : 'viewServiceDetails',

      navbar: false,
    },
    rows: [
      {
        template: 'block-avatar-group',
        fields: {
          avatar_src_1: serviceIcon,
          avatar_src_2: groupIcon,
          avatar_name_1: serviceName,
          avatar_name_2: groupName,
          class: 'no-border',
        },
      },
      primaryText(strings.ADD_SERVICE_DRAW_DOODLE()(group.name || ''), true, false, true),
      {
        template: 'block-section-row',
        fields: { class: 'no-border' },
        columns: [{ template: 'block-doodlepad' }],
      },
      {
        template: 'block-doodlepad-buttons',
        fields: {
          confirm_data: nav + 1,
          confirm_template: 'viewService_loading',
          confirm_validation: 'validateDoodlepad',
          confirm_action: confirmAction || 'goToServiceCodesAfterDelay',
        },
      },
    ],
  };
};
