const config = require('../../../config');
const { infoTextRow, thirdheading, primaryText } = require('../../page_helpers/components');

const strings = require('../../../config').strings;

module.exports = ({ nav }) => {
  const headerFields = () => {
    let fields = {
      id: 'doodle-pad-onboarding-confirm',
      class: 'no-title',
      navbar: false,
    };

    return fields;
  };

  const rows = [];

  const reonboarding = window.state.userData.reonboarding;
  reonboarding && rows.push({
    template: 'block-onboarding-close',
  }),
  //reonboarding && rows.push({ template: 'block-onboarding-close' })
  rows.push(thirdheading({ text: strings.ADD_SERVICE_DOODLE_DO_IT_AGAIN(), additionalClass: 'centered-text' }));
  rows.push({
    template: 'block-section-row',
    fields: { class: 'no-border', primary_heading: strings.ONBOARDING_CONFIRM_DOODLE() },
  });
  rows.push(
    {
      template: 'block-section-row',
      fields: { class: 'no-border' },
      columns: [{ template: 'block-doodlepad' }],
    },
    {
      template: 'block-section-row',
      fields: { class: 'no-border' },
      columns: [
        {
          template: 'block-text-button',
          fields: {
            text: strings.DOODLE_TRY_AGAIN(),
            class: 'doodlepad-button-clear',
          },
        },
      ],
    },

    {
      template: 'block-doodlepad-confirm-only',
      fields: {
        confirm_validation: 'validateDoodlepad_confirm',
        confirm_action: 'confirmDoodlepad_onboarding',
      },
    },
  );

  return {
    template: 'block-section-onboarding',
    fields: headerFields(),
    rows: rows,
  };
};
