module.exports = {
  deleteAccountConfirmationCode: require('./deleteAccountConfirmationCode'),
  enableAndroidAutofill: require('./enableAndroidAutofill'),
  profileNotifications: require('./profileNotifications'),
  viewYourSubscription: require('./viewYourSubscription'),
  migratePhoneNumber1: require('./migratePhoneNumber1'),
  migratePhoneNumber2: require('./migratePhoneNumber2'),
  migratePhoneNumber3: require('./migratePhoneNumber3'),
  viewAppPreferences: require('./viewAppPreferences'),
  viewProfileAccount: require('./viewProfileAccount'),
  editReferralCode: require('./editReferralCode'),
  accountSecurity: require('./accountSecurity'),
  editProfileName: require('./editProfileName'),
  deleteAccount: require('./deleteAccount'),
  inviteAFriend: require('./inviteAFriend'),
  viewAboutList: require('./viewAboutList'),
  referralCode: require('./referralCode'),
  viewHelpList: require('./viewHelpList'),
  onboarding1: require('./onboarding1'),
  onboarding2: require('./onboarding2'),
  onboarding3: require('./onboarding3'),
  onboarding4: require('./onboarding4'),
  onboarding5: require('./onboarding5'),
  viewProfile: require('./viewProfile'),
  account2fa: require('./account2fa'),
  devices: require('./devices'),
  premium: require('./premium'),
  badges: require('./badges'),
  enableBiometricsGroups: require('./enableBiometricsGroups'),
  enableBiometricsDoodle: require('./enableBiometricsDoodle'),
  enableBiometricsConfirm: require('./enableBiometricsConfirm'),
  notifications: require('./notifications'),
  replayDoodle: require('./replayDoodle'),
  notificationsFromBell: require('./notificationsFromBell'),
  migratePhoneNumber2A : require('./migratePhoneNumber2A')
};
