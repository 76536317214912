const showToastMessage = require('../../action_helpers/showToastMessage');
const errorManager = require('../../utils/errorManager');
const { REFERRAL_CODE_UPDATE_NOT_ALLOWED } = require('../../../config/strings')

module.exports = ({ }) => {
  const fieldSelector = 'input#referral-code-edit-field';
  let referral_id = $(fieldSelector).val();
  if (!referral_id) return;

  // If it looks like the referral  code is actually an invite URL, extract just the code
  if (referral_id.includes('refer=')) {
    referral_id = referral_id.split('refer=')[1];
    referral_id = referral_id.split('&')[0]; // Check that the URL didn't include anything else, like "...&blah=123"
  }

  $('#pane-2').addClass('busy');

  return z2hApp
    .requestData('post', '', 'users/@me/apply-referral', { referral_id })
    .then(response => {
      const success = Math.floor(response.status / 100) === 2;
      if (success) {
        // Success
        showToastMessage('Referral code updated');
        try {
          window.state.userData.referral_used = referral_id;
        } catch (err) {
          //ignore
        }
        z2hApp.paneNavigation('referralCode', $('#pane-2'), 1);
      } else if (response.error_code === '1105') {
        const errMsg = REFERRAL_CODE_UPDATE_NOT_ALLOWED();

        showToastMessage(errMsg);

        //errorManager.reportError(errMsg, fieldSelector);
      } else {
        // Failure
        const errMsg =
          response.user_message ||
          response.developer_message ||
          response.message ||
          `An error occurred! Sorry about that.`;

        errorManager.reportError(errMsg, fieldSelector);
      }
      $('#pane-2').removeClass('busy');
    })
    .catch(err => {
      showToastMessage('An error occurred. Please try again');
      $('#pane-2').removeClass('busy');
    });
};
