const sha1 = require('sha1');
const owasp = require('owasp-password-strength-test');
owasp.config({
  allowPassphrases: false,
  maxLength: 128,
  minLength: 10,
  minOptionalTestsToPass: 4,
});
module.exports = ({ element, z2hApp }) => {
  const password = $(element).val();
  // Store service name in state
  window.z2hApp.pageData.healthcheck.password = {}
  if (!password || password === "") return;
  //check the strength of the password against OWASP before we sha1 it.
  const strength = owasp.test(password);
  window.z2hApp.pageData.healthcheck.password.owasp = strength;
  window.z2hApp.pageData.healthcheck.password.data = sha1(password);
  window.z2hApp.pageData.healthcheck.password.length = password.length;
};
