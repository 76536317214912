module.exports = async ({ z2hApp, currentTarget }) => {
  const z2hTemplates = require('../../app/templates');

  const row = $(currentTarget).closest('.row ');
  const newName = row.find('[name=renameUsername]').val();

  const service = z2hApp.pageData.service;
  const serviceId = service.id;

  service.username = newName;

  const serviceData = {
    username: service.username,
  };

  setTimeout(()=>{

    $(currentTarget).closest('label').removeClass('focussed current-focus').blur()
  },200)

  return z2hApp.requestData('post', '', 'services/' + serviceId, serviceData).then((response) => {
    row.find('input').blur();

    //Should get this from realtime.
    //z2hApp.showServicesForGroup(window.state.selectedGroupId, false);

    return response;
  });
};
