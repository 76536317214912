
module.exports = ({ element }) => {
  const $elem = $(element);
  const id = $elem.data('id');
  const checkIcon = 'f-icn-check-solid';
  const uncheckIcon = 'f-icn-uncheck-solid';
  const selected = $elem.hasClass('selected');

  const addIcon = selected ? uncheckIcon : checkIcon;
  const removeIcon = selected ? checkIcon : uncheckIcon;

  selected ? $elem.removeClass('selected') : $elem.addClass('selected');
  $elem.find('.f-icn').removeClass(removeIcon).addClass(addIcon);

  const $allItems = $('.import-item[data-actionclick="importServicesSelect"]')
  if (id === 'import-select-all') {
    selected ? $allItems.removeClass('selected') : $allItems.addClass('selected')
    $allItems.find('.f-icn').removeClass(removeIcon).addClass(addIcon);
  } else {
    //check if all the items are selected.
    const allSelected = $allItems.length === $('.import-item[data-actionclick="importServicesSelect"].selected').length;
    allSelected ? $('[data-id="import-select-all"]').addClass('selected') :
      $('[data-id="import-select-all"]').removeClass('selected');
    allSelected ? $('[data-id="import-select-all"]').find('.f-icn').removeClass(uncheckIcon).addClass(checkIcon) :
      $('[data-id="import-select-all"]').find('.f-icn').removeClass(checkIcon).addClass(uncheckIcon)

  }



}