const strings = require('../../../config').strings;
const config = require('../../../config');
const { updateProfileName } = require('../../actions');
const fb = require('../../app/firebaseUtils');
const errorManager = require('../../utils/errorManager');
const { PROFILE_NAME_MAXLENGTH } = require('../../../config/app');

function row(label, template, nav) {
  return {
    template: 'block-section-row',
    fields: {
      navigation_data: nav + 1,
      navigation_template: template,
    },
    columns: [
      {
        template: 'block-text-group',
        fields: { primary_text: label },
      },
      {
        template: 'block-icn-button',
        fields: { icon: 'chevron-right' },
      },
    ],
  };
}

module.exports = ({ nav, data }) => {
  const nameId = z2hTools.getUid();
  //console.log(data);

  return {
    template: 'block-section-page',
    fields: {
      id: 'view-profile-account',
      header: strings.PROFILE_ACCOUNT_HEADER(),

      navbar: false,
      navigation_left: strings.BACK(),
      navigation_left_data: '0',
      navigation_left_template: 'back',
      navigation_right: strings.SAVE(),
      navigation_right_icon: '',
      navigation_right_data: '0',
      navigation_right_template: 'back',
      navigation_right_action: 'updateProfileName',
      navigation_right_validation: 'editProfileName',
      navigation_right_class: 'hidden',
    },
    rows: [
      {
        template: 'block-section-row',
        fields: {},
        columns: [
          {
            template: 'block-login-avatar',
            fields: {
              avatar: data.avatar || config.app.DEFAULT_USER_ICON,
              context: window.cordova && window.navigator.camera ? 'addProfileImageCordova' : 'addProfileImageDesktop',
              data_id: data.id,
            },
          },
          {
            template: 'block-editable-primary-text',
            fields: {
              id: nameId,
              for: nameId,
              value: data.name,
              name: 'profile-name',
              primary_text: data.name,
              placeholder: strings.PROFILE_EDIT_NAME_LABEL(),
              maxlength: PROFILE_NAME_MAXLENGTH,
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          secondary_heading: strings.PROFILE_EDIT_TEL_LABEL(),
        },
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_text: data.phoneNumber },
          },
        ],
      },
      row(strings.PROFILE_ACCOUNT_LINK_SUBSCRIPTION(), 'viewYourSubscription', nav),
      row(strings.PROFILE_REFERRAL_CODE(), 'referralCode', nav),
      row(strings.PROFILE_ACCOUNT_LINK_SECURITY(), 'accountSecurity', nav),

      window.cordova
        ? {
            template: 'block-section-row',
            fields: {
              //navigation_data: nav + 1,
              //navigation_template: template,
              action: 'migrateAccountToast',
            },
            columns: [
              {
                template: 'block-text-group',
                fields: { primary_text: strings.PROFILE_ACCOUNT_LINK_MIGRATE() },
              },
              {
                template: 'block-icn-button',
                fields: { icon: 'chevron-right' },
              },
            ],
          }
        : 
      row(strings.PROFILE_ACCOUNT_LINK_MIGRATE(), 'migratePhoneNumber1', nav),

      // window.cordova
      //   ? {
      //       template: 'block-section-row',
      //       fields: {
      //         action: 'deleteAccountToast',
      //       },
      //       columns: [
      //         {
      //           template: 'block-text-group',
      //           fields: { primary_text: strings.PROFILE_ACCOUNT_LINK_DELETE() },
      //         },
      //         {
      //           template: 'block-icn-button',
      //           fields: { icon: 'chevron-right' },
      //         },
      //       ],
      //     }
      //   : 
        row(strings.PROFILE_ACCOUNT_LINK_DELETE(), 'deleteAccount', nav),
      //row(strings.PROFILE_ACCOUNT_LINK_MIGRATE, 'migratePhoneNumber1', nav),
      //row(strings.PROFILE_ACCOUNT_LINK_DELETE, 'deleteAccount', nav),
    ],
  };
};

function debounce(callback, wait) {
  let timeout;
  return (...args) => {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => callback.apply(context, args), wait);
  };
}

function checkElement(e) {
  //console.log(e.target);
  if (e.target.name !== 'profile-name') return;
  document.querySelector('.nav-link.nav-right.hidden[data-actionclick="updateProfileName"]').classList.remove('hidden');

  debounce(() => {
    errorManager.clearAllErrors();
    if (e.target.value === '') {
      const profileName = e.target.value;
      errorManager.reportError(strings.PROFILE_NAME_BLANK(), $('[name=profile-name]'));
    } else {
      const updateProfileName = require('../../actions/profile/updateProfileName');
      updateProfileName({ section: $('#pane-2-inner'), z2hApp: window.z2hApp });
    }
  }, 1000)();
}

document.querySelector('body').addEventListener('keyup', e => checkElement(e));
