
module.exports = ({ section, z2hApp }) => {
  window.state.selectedImportedServices = [];

  const selected = section.find('li.import-item.selected');
  const importedServices = window.state.importedServices;
  selected.each(function (index) {
    const id = $(this).data('id');
    const service = importedServices.find(elem => elem.id === id);
    window.state.selectedImportedServices.push(service);

  })
}
