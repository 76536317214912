const { strings } = require('../../../config');

//each item is an import item row.
const importListItem = (listItem) => {
  return {
    template: 'block-import-item',
    fields: {
      id: listItem.id,
      name: listItem.name,
      username: listItem.username,
      avatar: listItem.url,
      navigation_action: 'importServicesSelect',
      icon: 'uncheck-solid',
    }
  }
}

module.exports = ({ nav }) => {



  const list = window.state.importedServices;
  const rows = [];
  rows.push({
    template: 'block-section-row',
    columns: [{
      template: 'block-info-text',
      fields: {
        text: strings.IMPORT_SERVICES_LIST_NOTE(),
      }
    }, {
      template: 'block-icn-button',
      fields: {
        icon: 'uncheck-solid',
        label: 'all',
        action: 'importServicesSelect',
        data_id: 'import-select-all',
      }
    }]
  })
  setTimeout(() => {
    const z2hTemplates = require('../../app/templates');
    const $wrapper = $('#importList').find('.login-list')
    for (const service of list) {
      //rows.push(importListItem(service));
      const item = z2hApp.constructBlock(z2hTemplates.compileBlockAttribute(importListItem(service)));
      $wrapper.append(item);

    }


  }, 500);

  return {
    template: 'block-import',
    fields: {
      id: 'import_list',
      header: strings.IMPORT_SERVICES_LIST_HEADER(),
      navigation_left: strings.BACK(),
      navigation_left_data: nav - 1,
      navigation_left_template: 'back',
      navigation_right: strings.NEXT(),
      navigation_right_data: nav + 1,
      navigation_right_template: 'importServicesGroup',
      navigation_right_action: 'importList',
      navigation_right_validation: 'importList',
      navbar: false,
    },
    rows: rows,
  }
}
