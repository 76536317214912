// start z2hTools
// ======================================
  function z2hTools() {

    // P R O J E C T
    // e n v i r o n m e n t
    document.addEventListener('DOMContentLoaded', function() {
      // Variables
      this.uid = null

    }.bind(this));
  };

  // H E L P E R   F U N C T I O N S
  // e s c a p e   s t r i n g s
  z2hTools.prototype.esc = function(string = "") {
    return String(string);
	};
	
  z2hTools.prototype.isIOS = function() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
	};
	
  z2hTools.prototype.isSafari = function() {    
    return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
	};
	

  // c r e a t e   u i d
  // A (random 100000 to 599999) integer is created on first use, which is then incremented by (random 1 to 100000)
  // on each consecutive use. "z2h" is prepended to the result, as well as 4 random characters
  // e.g. "z2h5902420d8u" -> "z2h5888547emf" -> "z2h610259p0lm" -> "z2h6643600c40"
  z2hTools.prototype.getUid = function() {
    if (!this.uid) {
      this.uid = Math.floor(Math.random() * 500000) + 100000;
    };
    return 'z2h' + (this.uid + Math.floor(Math.random() * 100000) + 1) + Math.random().toString(35).substr(2, 4);
  };

  // I N I T
  window.z2hTools = new z2hTools();

	module.exports = window.z2hTools
// ======================================
// end z2hTools
