const config = require('../../../config');
const { infoTextRow } = require('../../page_helpers/components');

const { strings } = config;


module.exports = ({ nav }) => {
  const services = window.state.selectedImportedServices;

  return {
    template: 'block-section-page',
    fields: {
      id: 'importCreatingServices',

      header: strings.IMPORT_SERVICES_CREATE_HEADER(),
      navbar: false,
      navigation_left: strings.BACK(),
      navigation_left_template: 'back',
      navigation_left_data: Math.max(nav - 1, 0),
    },
    rows: [
      {
        template: 'block-lochy',
        fields: {
          imagePath: 'img/lochy/09-Lochy-Weights.gif',
          class: 'no-border',
        }
      },
      infoTextRow(strings.IMPORT_SERVICES_CREATE_HOW_MANY()(services.length), true, false, true),
      {
        template: 'block-section-row',
        columns: [
          {
            template: 'block-text-button',
            fields: {
              text: strings.IMPORT_SERVICES_CREATE_BUTTON(),
              action: 'importCreatingServices2',
              class: 'cta-button',
            }
          }
        ]
      },
    ],
  }
}

