const strings = require('~/config').strings;

module.exports = ({ nav }) => {
  const userAvatar = window.state.userData.avatar;

  const centeredMsg = text => ({
    template: 'block-section-row',
    fields: {
      class: 'no-border',
      class2: 'centered-text',
    },
    columns: [
      {
        template: 'block-info-text',
        fields: { text },
      },
    ],
  });

  // Icon to display will either be the user's avatar (if they've uploaded one) or a star
  const iconToDisplay = userAvatar
    ? {
      template: 'block-login-avatar',
      fields: { class: 'big-avatar', avatar: userAvatar },
    }
    : {
      template: 'block-icn',
      fields: { icon: 'star' },
    };

  return {
    template: 'block-section-page',

    fields: {
      id: 'create-account-theme',
      header: strings.CREATE_ACCOUNT_FINAL_HEADER(),
    },

    rows: [
      {
        template: 'block-section-row',
        fields: {
          class: 'no-border',
          class2: 'centered-text',
        },
        columns: [iconToDisplay],
      },
      centeredMsg(strings.CREATE_ACCOUNT_FINAL_MSG_2()),
      centeredMsg(strings.CREATE_ACCOUNT_FINAL_MSG_3()),
      // =========================================================================
      // "Show me how!" button
      {
        template: 'block-section-row',
        fields: { class: 'has-cta-button' },
        columns: [
          {
            template: 'block-text-button',
            fields: {
              class: 'cta-button',
              text: strings.CREATE_ACCOUNT_FINAL_BUTTON(),
              navigation_data: nav + 1,
              navigation_template: 'createAccount_onboarding1',
              // nav to createAccount_onboarding1
            },
          },
        ],
      },

      {
        template: 'block-section-row',
        columns: [
          {
            template: 'block-text-button',
            fields: {
              text: strings.CREATE_ACCOUNT_FINAL_LINK(),
              action: 'hideLogin',
            },
          },
        ],
      },
    ],
  };
};
