module.exports = {
  editServiceFieldDetails: require('./editServiceFieldDetails'),
  viewService1_doodlepad: require('./viewService1_doodlepad'),
  addNewServiceRedirect: require('./addNewServiceRedirect'),
  searchForServiceIcon: require('./searchForServiceIcon'),
  quickSelectAddLogin: require('./quickSelectAddLogin'),
  editServiceDetails: require('./editServiceDetails'),
  viewServiceDetails: require('./viewServiceDetails'),
  chooseServiceIcon: require('./chooseServiceIcon'),
  restorePassword: require('./restorePassword'),
  addNewService: require('./addNewService'),
  viewService: require('./viewService'),
  loginList: require('./loginList'),
  addService_onboarding_complete: require('./addService_onboarding_complete'),
  viewService1_biometrics: require('./viewService1_biometrics'),
  specialCharacters: require('./specialCharacters'),
};
