// Dev deployment app config
module.exports = {
  DEFAULT_SERVICE_ICON: 'img/login@3x.png',
  DEFAULT_GROUP_ICON: 'img/peaceful.png',
  DEFAULT_USER_ICON: 'img/peaceful.png',
  DEFAULT_GRP_LINK_EXPIRY_MINS: 15,
  DEFAULT_GRP_LINK_MAX_USES: 1,

  MOBILE_VIEW_BREAKPOINT: 768,
  COMPACT_VIEW_BREAKPOINT: 1193,
  THEME_DARK_RGB: '#202030',
  THEME_LIGHT_RGB: '#F8FAFA',
  DOODLEPAD_IDLE_TIMEOUT_SECONDS: 30,
  SERVICE_DETAILS_IDLE_TIMEOUT_SECONDS: 300,

  MAX_PASSWORD_LENGTH: 64,
  MAX_FIELD_WORDS_COUNT: 10,
  BRAND_COLOURS: ['#5a96ff', '#5af0be', '#ff556e', '#ff7d5a', '#ffeb46'],

  SENTRY_ID: process.env.SENTRY_ID,
  SENTRY_ENV: process.env.SENTRY_ENV,
  LOGROCKET_ID: process.env.LOGROCKET_ID,
  FORGHETTI_ROOT_URL: process.env.FORGHETTI_ROOT_URL,
  FORGHETTI_API_URL: process.env.FORGHETTI_API_URL,

  STOP_SIMPLE_SYMBOLS: process.env.STOP_SIMPLE_SYMBOLS,

  LEGACY_USER_TIMESTAMP: 1603814022968,

  FORGHETTI_ROOT_URL: process.env.FORGHETTI_ROOT_URL,
  FORGHETTI_API_URL: process.env.FORGHETTI_API_URL,

  FIREBASE: {
    apiKey: process.env.FIREBASE_APIKEY,
    authDomain: process.env.FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.FIREBASE_DATABASEURL,
    projectId: process.env.FIREBASE_PROJECTID,
    storageBucket: process.env.FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGINGSENDERID,
    appId: process.env.FIREBASE_APPID,
    measurementId: process.env.FIREBASE_MEASUREMENTID,
  },

  FIREBASE_MESSAGING_VAPIDKEY: process.env.FIREBASE_MESSAGING_VAPIDKEY,

  SERVIVCE_NAME_MAXLENGTH: 128,
  GROUP_NAME_MAXLENGTH: 128,
  WEBSITE_URL_MAXLENGTH: 512,
  PROFILE_NAME_MAXLENGTH: 128,
  USERNAME_NAME_MAXLENGTH: 128,
  HIBP_USERNAME_MAXLENGTH: 128,

  SUPPORTED_LANGUAGES: ['de', 'gb', 'es', 'fr', 'it', 'pt', 'ro', 'ch', 'zh', 'zh_hant'],
  LANGUAGES: [
    {
      iso2: 'gb',
      name: 'English',
    },
    { iso2: 'de', name: 'Deutsch' },
    { iso2: 'es', name: 'Español' },
    { iso2: 'fr', name: 'Français' },
    { iso2: 'it', name: 'Italiano' },
    { iso2: 'pt', name: 'Português' },
    { iso2: 'ro', name: 'Limba Română' },
    { iso2: 'zh', name: '简体中文' },
    { iso2: 'zh_hant', name: '繁体中文' },
  ],
  //SUPPORTED_LANGUAGES: ['gb'],
  //LANGUAGES: [{ iso2: 'gb', name: 'English (UK)' }],

  PERSONAL_GROUP_BASE: 'personal',
  SHAREABLE_GROUP_BASE: 'shareable group',
  IMPORT_GROUP_BASE: 'import group',
};
