const biometricsOrDoodle = require('./biometricsOrDoodle');

module.exports = async ({ z2hApp, currentTarget }) => {
  const z2hTemplates = require('../../app/templates');

  const first = $('#add-service-username-input').val();
  const second = $('#add-service-username-secondary-input').val();

  console.log('first: ', first);
  console.log('second: ', second);
  if (first === '' && second === '') {
    setTimeout(() => {
      let pane = '#pane-2';

      const paneDetails = window.getActivePaneDetails('#pane-2');

      biometricsOrDoodle({
        currentTarget: null,
        z2hApp,
        pane: $(pane),
        newPaneIndex: paneDetails.activePaneIndex + 1,
        template: { biometrics: 'viewService1_biometrics', doodlepad: 'viewService1_doodlepad' },
      });
    }, 1000);
    return;
  }

  const service = z2hApp.pageData.service;
  const serviceId = service.id;

  service.username = first;
  service.username_secondary = second;

  const serviceData = {
    username: first,
    username_secondary: second,
  };

  return z2hApp.requestData('post', '', 'services/' + serviceId, serviceData).then((response) => {
    // row.find('input').blur();
    // $(currentTarget).closest('label').removeClass('focussed current-focus');

    //Should get this from realtime.
    //z2hApp.showServicesForGroup(window.state.selectedGroupId, false);

    const group = window.state.groupsList.find((group) => group.id === window.state.selectedGroupId);
    const service = group.services.find((service) => service.id === serviceId);

    service.username = first;
    service.username_secondary = second;

    const curService = window.state.currentServices.find((s) => s.id === serviceId);
    curService.username = first;
    curService.username_secondary = second;

    window.state.currentService = service;

    let pane = '#pane-2';

    const paneDetails = window.getActivePaneDetails('#pane-2');

    biometricsOrDoodle({
      currentTarget: null,
      z2hApp,
      pane: $(pane),
      newPaneIndex: paneDetails.activePaneIndex + 1,
      template: { biometrics: 'viewService1_biometrics', doodlepad: 'viewService1_doodlepad' },
    });
    return response;
  });
};
