/********************************************************************************
 ********************************************************************************
 

   This page is not currently used, but we've kept it in case we need it later.

 
 ********************************************************************************
 ********************************************************************************/

module.exports = _ => ({
  template: 'block-section-page',
  fields: {
    id: 'account-2fa',
    active: false,
    header: 'Authentication',
    navigation_left: 'Account',
    navigation_left_icon: '',
    navigation_left_data: String(nav - 1) == '-1' ? 'null' : String(nav - 1),
    navigation_left_template: 'back',
  },
  rows: [
    {
      template: 'block-section-row',
      fields: {},
      columns: [
        {
          template: 'block-primary-heading',
          fields: {
            heading: 'Supercharge your security',
          },
        },
      ],
    },
    {
      template: 'block-section-row',
      fields: {},
      columns: [
        {
          template: 'block-text-group',
          fields: {
            secondary_text:
              'For even greater security, use a Two-Factor Verification method such as Google Authenticator which will provide you with a unique code that we will verify each time you connect a new device.',
          },
        },
      ],
    },
    {
      template: 'block-section-row',
      fields: {},
      columns: [
        {
          template: 'block-text-button',
          fields: {
            text: 'Learn more about Two-Factor Authentication',
          },
        },
      ],
    },
    {
      template: 'block-section-row',
      fields: {},
      columns: [
        {
          template: 'block-text-group',
          fields: {
            primary_text: 'Enable Two-Factor Authentication',
            secondary_text: 'You will need to download an authentication app in addition to activating this switch',
          },
        },
        {
          template: 'block-toggle-switch',
          fields: {
            active: false,
          },
        },
      ],
    },
  ],
});
