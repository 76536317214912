const hideLogin = require('../createAccount/hideLogin');
module.exports = ({ z2hApp, section }) => {
  const premium = window.state.launchData.home.premium;
  const { LEGACY_USER_TIMESTAMP } = require('../../../config/app');

  const waitForUser = async () => {
    await window.state.userDataPromise;
    return true;
  };

  waitForUser().then(_ => {
    const isLegacy = LEGACY_USER_TIMESTAMP >= window.state.userData.joined_at;

    if (isLegacy) {
      localStorage.setItem('onboarding_complete', true);

      const endpoint = 'users/@me/complete-task';
      const data = JSON.stringify({ taskName: 'step_onboarding' });

      z2hApp.requestData('post', '', endpoint, data);
      const paneDetails = window.getActivePaneDetails('.overlay');
      z2hApp.paneNavigation('legacyUserBonus', $('.overlay'), paneDetails.activePaneIndex + 1);

    } else {
      hideLogin({ z2hApp: window.z2hApp });
    }
  });
};
