const config = require('../../../config');
const { infoTextRow, thirdheading } = require('../../page_helpers/components');
const actions = require('../../actions');
const { PROFILE_NAME_MAXLENGTH } = require('../../../config/app');

const { strings } = config;
const { BACK, SHARE_INVITE_USERNAME_HEADING, SHARE_INVITE_USERNAME_INFO, SHARE_INVITE_USERNAME_PLACEHOLDER } = strings;

module.exports = ({ nav, data }) => {
  // Focus on input field after 1 second
  setTimeout(_ => $('#shareInvite_username .share-username').focus(), 1000);

  return {
    template: 'block-section-page',
    fields: {
      id: 'shareInvite_username',
      header: SHARE_INVITE_USERNAME_HEADING(),
      navigation_left: BACK(),
      //navigation_left_action: 'hidePane3',
      navigation_left_template: 'back',
      navigation_left_data: 0,

      navbar: false,
    },
    rows: [
      infoTextRow(SHARE_INVITE_USERNAME_INFO(), true, false, true),
      {
        template: 'block-section-row',
        columns: [
          {
            template: 'block-search-box',
            fields: {
              placeholder: SHARE_INVITE_USERNAME_PLACEHOLDER(),
              id: 'share-username',
              value: '',
              maxlength: PROFILE_NAME_MAXLENGTH,
            },
          },
          // Tick/submit button
          {
            template: 'block-icn-button',
            fields: {
              //class: 'hidden',
              icon: 'right-solid',
              id: 'username__tick',
              action: 'shareInvite_submit',
              class: 'no-border',
              navigation_validation: 'shareInvite_username',
            },
          },
        ],
      },
    ],
  };
};

$('body').on('keyup', '#shareInvite_username #share-username', e => {
  if (e.key === 'Enter') {
    $('#shareInvite_usernamee [data-actionclick=ashareUsername_submit]').click();
    return;
  }

  const tickIcon = document.querySelector('[data-actionclick="shareUsername_submit"');
  e.target.value === '' ? tickIcon.classList.remove('active') : tickIcon.classList.add('active');
});
