const config = require('../../../config');
const strings = config.strings;
const { primaryText, infoTextRow } = require('../../page_helpers/components');

module.exports = ({ data, nav }) => {
  const userId = window.state.userData.id;
  const premium = window.state.launchData.home.premium;
  const groupsList = data.groupsList || [];
  const personalGroup = groupsList.find((g) => g.personal);
  let myGroups = groupsList.filter((g) => window.state.userData.id === g.owner_id && !g.personal);
  let otherGroups = groupsList.filter((g) => window.state.userData.id !== g.owner_id);

  // Create an array of groups, which will exclude the personal group
  const groupCount = otherGroups.length + myGroups.length;

  const page = {
    template: 'block-section-page',
    fields: {
      id: 'biometrics-list',
      header: strings.BIOMETRICS_GROUPS_HEADER(),
      class: 'mergeWithNextRow',
      navbar: false,
      navigation_left: config.strings.BACK(),
      navigation_left_icon: '',
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'back',
    },
    rows: [
      {
        template: 'block-section-row',
        fields: {
          class: 'no-border',
          class2: 'groups-list-intro',
        },
        columns: [primaryText(strings.BIOMETRICS_SELECT_GROUP(), true, false, false, true)],
      },
      groupRow(personalGroup, nav),
    ],
  };
  // Sort by name
  myGroups.sort((a, b) => (a.name > b.name ? 1 : -1));
  myGroups.forEach((group) => page.rows.push(groupRow(group, nav)));

  // Sort the groups by name (asending)
  otherGroups.sort((a, b) => (a.name > b.name ? 1 : -1));
  // Add a row for each

  //page.rows.push(addNewGroup());
  otherGroups = otherGroups.map((group) => {
    let owner;
    if (group.users) {
      const usersArray = Object.entries(group.users);
      owner = usersArray.find((u) => u[1].member_type === 2);
    }
    return owner ? { owner_name: owner[1].name, ...group } : { owner_name: '', ...group };
  });
  otherGroups.sort((a, b) => (a.owner_id > b.owner_id ? 1 : -1));
  let lastOwnerId = '';
  otherGroups.forEach((group) => {
    const groupOwnerTemplate = (groupName) => ({
      template: 'block-section-row',
      fields: {
        class: 'centered-text',
        class2: 'no-border',
        class3: 'groups-shared-by-heading',
      },
      columns: [
        {
          template: 'block-text-group',
          fields: {
            primary_text: strings.GROUP_SHARED_BY(),
            secondary_text: groupName,
          },
        },
      ],
    });
    if (lastOwnerId !== group.owner_id) {
      page.rows.push(groupOwnerTemplate(group.owner_name));
    }
    lastOwnerId = group.owner_id || '';

    page.rows.push(groupRow(group, nav));
  });

  return page;
};

// Helpers =========================================================================================

// function addNewGroup() {
//   return {

//     template: 'block-section-row',
//     fields: {
//       secondary_heading: strings.GROUPS_LIST_ADD_NEW_GROUP,
//       navigation_data: '1',
//       navigation_pane: '2',
//       navigation_template: 'newGroup',
//       //class: 'centered-text'
//     },
//   }
// }
// For each group, we want to display a row like this...
function groupRow(group, nav) {
  let enabled = localStorage.getItem(`${group.id}_doodle`);
  console.log('enabled: ', enabled);
  // Determine what icon to display
  const icon = group.personal
    ? window.state.userData.avatar || config.app.DEFAULT_USER_ICON
    : group.icon || config.app.DEFAULT_GROUP_ICON;

  // Get a comma-separated list of users in this group, starting with yourself
  let userList = config.strings.YOU();
  for (let u in group.users) {
    if (u !== window.state.userData.id) {
      // If not yourself...
      userList += ', ' + group.users[u].name;
    }
  }

  window.state.addBiometricsFromSettings = true;

  console.log('group.id: ', group.id);
  const selectedGroup = window.state.selectedGroupId === group.id;

  let fields = {};

  if (enabled) {
    fields = {
      icon: 'password-colour',

      data_id: group.id,
      action: 'toggleBiometricsState',
      label: strings.BIOMETRICS_DISABLE(),
    };
  } else {
    fields = {
      ...fields,
      icon: 'password',
      //navigation_data: '1',
      //navigation_data: group.id,
      data_id: group.id,
      navigation_data: nav + 1,
      navigation_pane: '2',
      navigation_template: 'enableBiometricsDoodle',
      label: strings.BIOMETRICS_ENABLE(),
    };

  }

  const replay = enabled ? { template: 'block-icn-button',
    fields: {
 
      icon: 'doodlepad-drawn',
      data_id: group.id,
      navigation_data: nav + 1,
      navigation_pane: '2',
      navigation_template: 'replayDoodle',
      label: strings.REPLAY_DOODLE(),
    } } : null;

  return {
    template: 'block-section-row',

    columns: [
      {
        template: 'block-login-avatar',
        fields: { avatar: icon, data_id: group.id },
      },
      {
        template: 'block-text-group',
        fields: { primary_text: group.name },
      },

      {
        template: 'block-icn-button',
        fields,
      },
      replay
    ],
  };
}
