const config = require('../../../config');
const { helpButton } = require('../../page_helpers/components');

module.exports = ({ nav }) => {
  const fieldId = window.state.selectedFieldId;

  return {
    template: 'block-section-page',
    fields: {
      id: 'edit-service-field-advanced-options',
      active: false,
      header: config.strings.FIELD_ADVANCED_OPTIONS_HEADER(),

      navigation_left: config.strings.BACK(),
      navigation_left_template: 'back',
      navigation_left_data: Math.max(nav - 1, 0),
    },
    rows: [
      {
        template: 'block-section-row',
        fields: {
          class: 'cta',
          action: 'markFieldToBeReset',
          navigation_data_id: fieldId,
        },
        columns: [
          { template: 'block-icn-button', fields: { icon: 'reset' } },
          { template: 'block-text-group', fields: { primary_text: config.strings.RESET_FIELD() } },
          helpButton('HELP_FIELD_SETTINGS_RESET'),
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          class: 'cta',
          navigation_data: nav + 1,
          navigation_data_id: fieldId,
          navigation_template: 'restorePassword',
        },
        columns: [
          { template: 'block-icn-button', fields: { icon: 'restore' } },
          { template: 'block-text-group', fields: { primary_text: config.strings.RESTORE_FIELD() } },
          helpButton('HELP_RESTORE_PAGE'),
        ],
      },
    ],
  };
};
