import {auth} from "../../app/firebaseUtils";
import fb from '../../app/firebaseUtils';
const strings = require('../../../config').strings;


module.exports = ({ data }) => 
  
  {

setTimeout((_) => window.location.reload(), 1500);
  return {
  template: 'block-section-page',

  fields: {
    id: 'migrate-phone-number-3',
    header: strings.PROFILE_MIGRATE_3_HEADER(),
    navbar: false,
  },

  rows: [
    {
      template: 'block-section-row',
      fields: { primary_heading: strings.PROFILE_MIGRATE_3_MSG() },
    },
  ],
}
};
