const config = require('../../../config');

const { strings } = config;

module.exports = ({ data, nav }) => {
  const inviteCode = window.state.selectedInviteId;
  const groupId = window.state.selectedGroupId;

  const group = window.state.groupsList.find(g => g.id === groupId);
  const inviteUrl = config.app.FORGHETTI_ROOT_URL + '?invite=' + inviteCode;
  return {
    template: 'block-section-page',
    fields: {
      id: 'share-invite',
      active: false,
      header: strings.SHARE_INVITE_HEADING(),
      navigation_left: strings.BACK(),
      //navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_data: 0,
      navigation_left_template: 'back',

      navbar: false,
    },
    rows: [
      {
        template: 'block-section-row',
        fields: { class: 'centered-text', class2: 'no-border' },
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_html: strings.GRP_INVITE_CODE_NOTE()(group.name), secondary_class: 'nocolour' },
          },
        ],
      },

      {
        template: 'block-section-row',
        fields: {
          //secondary_heading: strings.GRP_INVITE_CODE_LABEL,
          action: 'copyGroupInviteToClipboard',
          class: 'centered-text',
        },
        columns: [
          {
            template: 'block-text-group',
            fields: {
              action: 'copyGroupInviteToClipboard',
              class: 'no-border',
              class2: 'centered-text',
              primary_html: `<em>${inviteUrl}</em>`,
            },
          },
        ],
      },
      !window.cordova && {
        template: 'block-section-row',
        fields: { action: 'emailGroupInviteLink' },
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_text: strings.SHARE_INVITE_VIA_EMAIL() },
          },
          {
            template: 'block-icn-button',
            fields: { icon: 'email' },
          },
        ],
      },
      !window.cordova && {
        template: 'block-section-row',
        fields: { action: 'copyGroupInviteToClipboard' },
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_text: strings.CPY_GRP_INVITE() },
          },
          {
            template: 'block-icn-button',
            fields: { icon: 'copy' },
          },
        ],
      },
      !window.cordova && {
        template: 'block-section-row',
        fields: { action: 'deactivateLink' },
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_text: strings.DEACTIVATE_LINK() },
          },
          {
            template: 'block-icn-button',
            fields: { icon: 'delete' },
          },
        ],
      },
    ],
  };
};
