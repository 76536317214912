const { strings } = require('../../../config');
const z2hApp = require('../../app/z2hApp');
const { primaryText } = require('../../page_helpers/components');

module.exports = ({ nav }) => {
  return {
    template: 'block-section-page',
    fields: {
      id: 'editNote',
      header: strings.NOTE(),
      navbar: { desktop: false, mobile: false },

      navigation_left: strings.BACK(),
      navigation_left_data: nav - 1,
      navigation_left_template: 'back',
      //navigation_right_validation: 'addNewService',

      navigation_right: strings.SAVE(),
      //navigation_right_data: nav,
      //navigation_right_validation: 'addNewService',
      navigation_right_action: 'saveNote',
      //navigation_right_template: 'createAccount_addCustomLogin_chooseGroup',
    },
    rows: [
      {
        template: 'block-section-row',
        fields: {
          class: 'centered-text',
        },
        columns: [
          {
            template: 'block-textarea',
            fields: {
              id: 'noteTextArea',
              value: z2hApp.pageData.service.note || '',
            },
          },
        ],
      },
    ],
  };
};
