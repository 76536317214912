
const config = require('../../../config');
module.exports = ({ currentTarget, section, z2hApp }) => {
  window.state.selectedImportedServices;
  const groupId = $(currentTarget).attr('data-id');

  window.state.selectedImportGroupId = groupId;

  //we need to update the group on all the other services.

  const avatarWrapper = $('#importMoveServices').find('[data-avatar="avatar-2"]');

  //get the carousel cards and update the selected group everywhere.
  const group = window.state.groupsList.find(g => g.id === groupId);
  avatarWrapper.find('.login-avatar-label').text(group.name);
  avatarWrapper.find('.login-avatar').attr('src', group.personal ? window.state.userData.avatar : group.icon || config.app.DEFAULT_GROUP_ICON);

  const paneDetails = window.getActivePaneDetails('#pane-2');

  z2hApp.paneNavigation('back', paneDetails.pane, paneDetails.activePaneIndex - 1);
}
