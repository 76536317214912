const openUrl = require('../../action_helpers/openUrl');
module.exports = ({ currentTarget, z2hApp }) => {
  let url = $(currentTarget).attr('data-actiondetail');
  if (!url) {
    url = $(currentTarget).attr('data-id');
  }
  if (url) {
    z2hApp
      .requestData('post', '', 'users/@me/complete-task', {
        taskName: 'step_shared_on_social_media',
      })
      .then(_ => {
        //this.initUpdatesPage();
      });
  }
  localStorage.setItem('social-sharing', true);
  openUrl(url);
};
