//The animations are stored as JSON in the js/animations folder
const { multiLines, dotsToo, allTogether, doodleA, doodleB } = require('../../animations');
const onboadingTemplate = require('./onboarding_template');
const { thirdheading } = require('../../page_helpers/components');

const strings = require('../../../config').strings;

let inited = false;
const template = ({
  animationId,
  animationPath,
  imagePath,
  primary_heading,
  secondary_heading,
  text,
  nextLink,
  nextAction,
  nextPage,
  nextTemplate,
  nav,
  watchAgain,
}) => ({
  rows: [
    {
      template: 'block-onboarding-nav-right',
      fields: {
        primary_text: strings.SKIP().toUpperCase(),
      },
    },
    animationId
      ? {
          template: 'block-animation',
          fields: { id: animationId },
        }
      : {},
    imagePath
      ? {
          template: 'block-onboarding-image',
          fields: { imagePath },
        }
      : {},
    thirdheading({ text: secondary_heading || '', additionalClass: 'centered-text' }),

    primary_heading
      ? {
          template: 'block-section-row',
          fields: {
            class: 'no-border',
            class2: 'centered-text',
            class3: 'onboarding-text',
            primary_heading: primary_heading,
          },
        }
      : {},
    nextLink
      ? {
          template: 'block-cta-button',
          fields: {
            text: nextLink,
            navigation_action: nextAction,
          },
        }
      : {},
    nextPage
      ? {
          template: 'block-cta-button',
          fields: {
            ...nextPage,
          },
        }
      : {},

    watchAgain
      ? {
          template: 'block-text-button',
          fields: {
            text: strings.WATCH_AGAIN(),
            class: 'watch-again',
          },
        }
      : {},
  ],
});

module.exports = ({ nav }) => {
  const templates = [];

  let t = {
    slide: 0,
    animationId: 'onboarding_multi_lines',
    animationPath: doodleA,
    primary_heading: strings.ONBOARDING_DOODLETUTORIAL2_HEADING_2(),
    secondary_heading: strings.SECURE_DOODLE_TUTORIAL(),
  };
  templates.push(t);
  t = {
    slide: 1,
    animationId: 'onboarding_multi_lines_two',
    animationPath: multiLines,
    primary_heading: strings.ONBOARDING_DOODLETUTORIAL3_HEADING_2(),
    secondary_heading: strings.SECURE_DOODLE_TUTORIAL(),
  };
  templates.push(t);
  t = {
    slide: 2,
    animationId: 'onboarding_dots_too',
    animationPath: dotsToo,
    primary_heading: strings.ONBOARDING_DOODLETUTORIAL4_HEADING_2(),
    secondary_heading: strings.SECURE_DOODLE_TUTORIAL(),
  };
  templates.push(t);
  t = {
    slide: 3,
    animationId: 'onboarding_or_all_together',
    animationPath: allTogether,
    primary_heading: strings.ONBOARDING_DOODLETUTORIAL5_HEADING_2(),
    secondary_heading: strings.SECURE_DOODLE_TUTORIAL(),
  };
  templates.push(t);
  t = {
    slide: 4,
    animationId: 'onboarding_dots_too_three',
    animationPath: multiLines,
    primary_heading: strings.ONBOARDING_DOODLETUTORIAL6_HEADING_2(),
    secondary_heading: strings.SECURE_DOODLE_TUTORIAL(),
  };
  templates.push(t);
  t = {
    slide: 5,
    animationId: 'onboarding_doodle_A',
    animationPath: doodleA,
    primary_heading: strings.ONBOARDING_DOODLETUTORIAL7_HEADING_2(),
    secondary_heading: strings.SECURE_DOODLE_TUTORIAL(),
  };
  templates.push(t);
  t = {
    slide: 6,
    animationId: 'onboarding_doodle_B',
    animationPath: doodleB,
    primary_heading: strings.ONBOARDING_DOODLETUTORIAL8_HEADING_2(),
    secondary_heading: strings.SECURE_DOODLE_TUTORIAL(),
    nextPage: {
      text: strings.ONBOARDING_YOURTURN(),
      navigation_template: 'onboarding_doodle',
      navigation_data: nav + 1,
    },
    callToAction: true,
  };
  templates.push(t);
  // t = {
  //   slide: 7,
  //   imagePath: 'img/lochy/04-Lochy-Unlocking.gif',
  //   primary_heading: '',

  //   watchAgain: true,
  // };
  //templates.push(t);
  //Create a template for slides in the carousel.
  const pages = [];
  templates.forEach((t) => {
    pages.push(template(t));
  });

  const id = { template: 'onboarding_stage2' };
  id.carousel = `${id.template}_carousel`;
  id.navbar = `${id.template}_navbar`;

  //We're going to move onto the next slide in the carousel when the navigation  completes.
  const animationComplete = () => {
    //Trigger a call to the next slide if the animation is complete...
    //TODO a proper method, NOT HIDDEN!
      const $elem = $(`[data-id="${id.carousel}"]`);
    $elem.slick('slickCurrentSlide') < templates.length - 1 ? $elem.slick('next') : '';
  };
  //Yeah, we're creating a carousel of animations.
  //To do so we need to add to the carousel array held in window.state
  const carousel = window.state.carousel || {};
  carousel[id.carousel] = [...pages];
  window.state.carousel = carousel;

  //each of our animations has a bunch of settings we can use - have a look at components/carousel.js for reference
  const animations = window.state.animations || {};
  templates.forEach((t) => {
    if (t.animationId) {
      const settings = {
        id: t.animationId,
        path: t.animationPath,
        template: id.template,
        loop: false,
        onComplete: animationComplete,
        slide: t.slide,
        //autoplay: t.slide === 0 ? true : false,
        autoplay: false,
        speed: 1.25,
      };
      animations[t.animationId] = { ...settings };
    }
  });
  //store the animations globally.
  window.state.animations = animations;

  // //Delay the first animation slightly so that it does not autoplay as the carousel comes into view
  $('body').on('init', `[data-id="${id.carousel}"]`, function (event, slick) {
    //Manually start the first animation after a delay
    const animeArray = Object.values(window.state.animations);
    setTimeout(function () {
      animeArray[0].instance.play();
    }, 1000);
    inited = true;
  });

  $('body').on('click', `#onboarding_stage2 .onboarding-close`, () => {
    $(`[data-id="${id.carousel}"]`).slick('slickGoTo', templates.length - 1);
  });

  //Event for carousel changes
  //we might get triggered from our animations completing,
  //or by the user clicking backward or forward
  $('body').on('beforeChange', `[data-id="${id.carousel}"]`, function (event, slick, currentSlide, nextSlide) {
    const animeArray = Object.values(window.state.animations);
    const animation = animeArray.find((a) => a.slide === currentSlide);
    animation && animation.instance.stop();
    //Hide the slick dots for cleanliness
    const toLastSlide = nextSlide === slick.slideCount - 1;
    if (toLastSlide === 'xxxx') {
      $('.slick-custom__dots').addClass('no-click').fadeTo(350, 0);
    } else {
      $('.slick-custom__dots').removeClass('no-click').fadeTo(350, 1);
    }
    $('#onboarding_stage2 .onboarding-close').hide();
  });
  $('body').on('afterChange', `[data-id="${id.carousel}"]`, function (event, slick, currentSlide) {
    //we've got a new slide play the animation
    const animeArray = Object.values(window.state.animations);
    const animation = animeArray.find((a) => a.slide === currentSlide);
    console.log('currentSlide', currentSlide);
    //Navigated back to first slide. Add a short delay to give the user a moment to settle their eyes
    if (inited && currentSlide === 0) {
      setTimeout(function () {
        animation && animation.instance.play();
      }, 500);
    }
    //Autoplay as normal
    else {
      setTimeout(function () {
        animation && animation.instance.play();
      }, 500);
    }
    if (currentSlide !== slick.slideCount - 1) {
      $('#onboarding_stage2 .onboarding-close').fadeIn();
    }
  });
  //Watch again, go back to the first slide
  $('body').on('click', `#onboarding_stage2 .watch-again`, function () {
    $('#onboarding_stage2 [data-slick]').slick('slickGoTo', 0);
  });

  const reonboarding = window.state.userData.reonboarding;

  return {
    template: 'block-section-onboarding',
    fields: {
      id: id.template,
      class: 'carousel',
    },
    rows: [
      // reonboarding && {
      //   template: 'block-onboarding-close',
      // },
      {
        template: 'block-carousel',
        fields: {
          id: id.carousel,
          navbar: false,
          //the data-slick attribute allows us to pass any options the slick carousel can define
          slick: JSON.stringify({
            dots: true,
            infinite: false,
            fade: true,
            mobileFirst: false,
            dotsClass: 'slick-custom__dots',
            easing: 'ease-in',
            arrows: false,
            touchThreshold: 15, //To advance slides, the user must swipe a length of (1/touchThreshold) * the width of the slider
            waitForAnimate: true,
            speed: 600,
            appendDots: `#${id.navbar}`,
          }),
        },
      },
      {
        template: 'block-insertable',
        fields: {
          id: id.navbar,
          class: 'slick-nav-bar',
        },
      },
    ],
  };
};
