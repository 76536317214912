/**
 * Contains functions for performing page validation.
 */
const createAccount_processReferralCode = require('./createAccount_processReferralCode');
const createAccount_updateDisplayName = require('./createAccount_updateDisplayName');
const validatePhoneNumberSignIn = require('./validatePhoneNumberSignIn');
const validateActivationCode = require('./validateActivationCode');
const migratePhoneNumber1 = require('./migratePhoneNumber1');
const createGroupInvite = require('./createGroupInvite');
const validateDoodlepad = require('./validateDoodlepad');
const editGroupDetails = require('./editGroupDetails');
const editServiceField = require('./editServiceField');
const editProfileName = require('./editProfileName');
const addNewService = require('./addNewService');
const newGroup = require('./newGroup');
const addService_username_onboard = require('./addService_username_onboard');
const healthcheck_username = require('./healthcheck_username');
const healthcheck_password = require('./healthcheck_password');
const importList = require('./importList');
const shareInvite_username = require('./shareInvite_username');
const validateBiometric = require('./validateBiometric');
const validateCreateServicesBatch = require('./validateCreateServicesBatch');

module.exports = {
  createAccount_processReferralCode,
  createAccount_updateDisplayName,
  validatePhoneNumberSignIn,
  validateActivationCode,
  migratePhoneNumber1,
  createGroupInvite,
  validateDoodlepad,
  editGroupDetails,
  editServiceField,
  editProfileName,
  addNewService,
  newGroup,
  addService_username_onboard,
  healthcheck_username,
  healthcheck_password,
  importList,
  shareInvite_username,
  validateBiometric,
  validateCreateServicesBatch,
};
