const errorManager = require('../utils/errorManager');
const z2hApp = window.z2hApp;

// This validator is probably not necessary as profile name can be blank?
module.exports = (section) => {
  errorManager.clearErrors(section);

  $('#pane-2').addClass('busy');

  const groupId = z2hApp.currentGroup()?.id;
  

  let linkExpiry = window.state.groupInviteLinkExpiry || 0;
  let maxUses = window.state.groupInviteMaxUses || 0;

  if (linkExpiry === -1) linkExpiry = 0;
  if (maxUses === -1) maxUses = 0;

  const data = { expiry_time: linkExpiry * 60 * 1000, maximum_uses: maxUses };

  // Generate new invite code and refresh group's invites list
  return z2hApp
    .requestData('post', '', `groups/${groupId}/invite`, data)
    .then((response) => z2hApp.requestData('get', '', `groups/${groupId}/invites`))
    .then((response) => {
      const group = window.state.groupsList.find((g) => g.id === groupId);
      if (Math.floor(response.status / 100) === 2) {
        // For some reason the API often returns an incomplete list of invites
        // at this point; It includes the invite we just added, but is usually
        // missing one of the older ones. A groups-full request shows it still
        // exists. So instead of wiping out our list, just added invites which
        // are missing from our list (i.e. the new one).
        for (let id in response.data) {
          group.invites[id] = response.data[id];
        }
      }

      // Refresh group details
      const pane = '#pane-2';
      const activePaneIndex = $(pane + '-inner')
        .children('.active')
        .index();
      z2hApp.paneNavigation('viewGroupDetails', $(pane), activePaneIndex - 1);
    });
};
