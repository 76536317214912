const pages = require('..');
const config = require('../../../config');
const { strings } = config;

const {
  infoTextRow,
  serviceField,
  unavailableServiceField,
  upgradeTodayButton,
  loadingServiceField,
  noteField,
  noteLoading,
  newNoteField,
} = require('../../page_helpers/components');

const allLoaded=false;
const getAccessKeys = require('../../getPageData_helpers/getAccessKeys');
const z2hApp = require('../../app/z2hApp');
const getNotes = require('../../getPageData_helpers/getNotes');
const { SERVICE_DETAILS_IDLE_TIMEOUT_SECONDS } = require('../../../config/app');

// =================================================================================================
const focusRemoveEdit = (field) => {
  $('body').on('focus', `#${field}`, () => {
    const row = $(`#${field}`).closest('.row');
    $(row).find('[data-actionclick="focusInputRow"]').hide();
  });
};

module.exports = ({ data, doneButton = false }) => {


  let serviceData = data || {};
  window.z2hApp.pageData.service = serviceData;

  const movingService = window.z2hApp.pageData.service && window.z2hApp.pageData.service.movingService;

  window.z2hApp.pageData.reloadAccesskeys = (serviceId, doodle) => {
    getAccessKeys(serviceId, doodle).then((data) => {
      serviceData = window.z2hApp.pageData.service;
      for (let fieldId in data) {
        //If we're moving a service we need to show the old passwords...

        const field = serviceData.fields[fieldId];
        const movingServiceFields = movingService ? window.state.movingService.from.service.serviceFields : null;
        if (movingServiceFields) {
          const movingField = movingServiceFields.find((f) => f.id === fieldId);
          field.old_access_key = movingField.access_key;
        }

        if (field) {
          field.access_key = data[fieldId].access_key;
        }

        $(`#view-service #${fieldId}`).fadeOut('slow', () => {
          $(`#view-service #${fieldId}`).replaceWith(
            z2hApp.constructRow(
              serviceField(
                field.name,
                field.access_key,
                field.id,
                { copyIconLabel, viewIconLabel, injectIconLabel },
                {
                  moving: movingService,
                  prefix: field.moving ? strings.VIEW_SERVICE_OLD_PREFIX() : strings.VIEW_SERVICE_NEW_PREFIX(),
                },
                field.access_key !== field.old_access_key ? field.old_access_key : null,
              ),
            ),
          );

          //if (window.state.movingService) delete window.state.movingService;
          //if (window.z2hApp.pageData.service.movingService) delete window.z2hApp.pageData.service.movingService;
          $(`#${fieldId}`).hide().fadeIn(1000);
        });
      }
      window.z2hApp.pageData.service = serviceData;
      z2hApp.fetchAndShowServices(window.state.selectedGroupId, false, true);
    });
  };

  window.z2hApp.pageData.reloadNotes = (serviceId, doodle) => {
    setTimeout(() => {
      let notesUpdate =  Promise.resolve(false);
      if (window.z2hApp.pageData.notesUpdate) {
        notesUpdate = window.z2hApp.pageData.notesUpdate;
      }
      notesUpdate
        .then((notesUpdate) => {})
        .then(() => {
          z2hApp.requestData(`get`, ``, `services/${serviceId}/has-note`).then((hasNote) => {
            serviceData =  window.z2hApp.pageData.service;
            if (hasNote.data) {
              getNotes(serviceId, doodle)
                .then((data) => {
                  serviceData.note = data;
                  const note = $('#note').replaceWith(z2hApp.constructRow(noteField('note')));
                })
                .catch((e) => {
                  console.log('No note found');
                  serviceData.note = null;
                  const note = $('#note').replaceWith(z2hApp.constructRow(noteField('note')));
                });
                
            } else {
              serviceData.note = null;
              const note = $('#note').replaceWith(z2hApp.constructRow(newNoteField('note')));
            }

            window.z2hApp.pageData.service = serviceData;
          });
        }, 1);
    });
  };

  window.z2hApp.pageData.reloadServiceInfo = (serviceId) => {
    $('.section-pane-nav__title').find('h1').text(serviceData.name);
    $('#view-service [data-avatar="avatar-2"] .login-avatar-label').text(serviceData.name);
  };

  window.z2hApp.pageData.reloadAccesskeys(serviceData.id, serviceData.doodle);
  window.z2hApp.pageData.reloadNotes(serviceData.id, serviceData.doodle);

  z2hApp.pageData.postUpdateAction = (fieldId, empty, action) => {
    const serviceData = window.z2hApp.pageData.service;
    const field = window.z2hApp.pageData.service.fields[fieldId];

    const paneDetails = window.getActivePaneDetails('#pane-2');
    if (paneDetails.activePaneIndex !== paneDetails.pane.find('#view-service').index()) {
      z2hApp.paneNavigation('back', paneDetails.pane, paneDetails.pane.find('#view-service').index(), null);
    }
    if (action === 'delete') return;

    $(`#${fieldId}`).replaceWith(z2hApp.constructRow(loadingServiceField(field.name, null, field.id)));

    getAccessKeys(serviceData.id, serviceData.doodle).then((data) => {
      const field = window.z2hApp.pageData.service.fields[fieldId];
      if (field) {
        field.old_access_key = field.access_key;
        field.access_key = data[fieldId].access_key;
      }

      $(`#${fieldId}`).fadeOut('slow', () => {
        $(`#${fieldId}`).replaceWith(
          z2hApp.constructRow(
            serviceField(
              field.name,
              field.access_key,
              field.id,
              { copyIconLabel, viewIconLabel, injectIconLabel },
              {
                moving: false,
                prefix: field.moving ? strings.VIEW_SERVICE_OLD_PREFIX() : strings.VIEW_SERVICE_NEW_PREFIX(),
              },
              field.access_key !== field.old_access_key ? field.old_access_key : null,
            ),
          ),
        );
        $(`#${fieldId}`).hide().fadeIn(1000);
      });
    });
  };

  z2hApp.pageData.postNoteUpdateAction = (note, action) => {
    $(`#note`).replaceWith(z2hApp.constructRow(noteLoading(strings.NOTE(), null, 'note')));
    window.z2hApp.pageData.service.note = note;
    const paneDetails = window.getActivePaneDetails('#pane-2');
    if (paneDetails.activePaneIndex !== paneDetails.pane.find('#view-service').index()) {
      z2hApp.paneNavigation('back', paneDetails.pane, paneDetails.pane.find('#view-service').index(), null);
    }

    window.z2hApp.pageData.reloadNotes(window.z2hApp.pageData.service.id, window.z2hApp.pageData.service.doodle);
  };

  const user = window.state.userData;
  const groupId = window.state.selectedGroupId;
  const group = window.state.groupsList.find((g) => g.id === groupId);
  const groupIcon = group.personal
    ? user.avatar || config.app.DEFAULT_USER_ICON
    : group.icon || config.app.DEFAULT_GROUP_ICON;
  const groupName = group.name;
  const serviceIcon = serviceData.icon || config.app.DEFAULT_SERVICE_ICON;
  const onboarding = window.state.userData.activeOnboarding;
  const newlyAdded = serviceData.newlyAddedService;
  window.state.currentWebsite = serviceData.website;
  //Just update the review state
  window.state.review = parseInt(localStorage.getItem('reviewCount'));
  if (!window.state.review) {
    window.state.review = 0;
  }
  window.state.review += 1;
  localStorage.setItem('reviewCount', window.state.review);

  //We allow the extension to reopen within a certain timeframe.
  if (window.state.extension) {
    const cacheObj = { ...data, exp: Date.now() + 10000, website: window.state.currentTabUrl };
    localStorage.setItem('viewServiceExtensionCache', JSON.stringify(cacheObj));
  }

  // Start building up the page...
  const page = {
    template: 'block-section-page',
    fields: {
      id: onboarding ? 'view-service-onboarding' : 'view-service',
      class: ['display', onboarding ? 'onboarding' : 'notonboarding'],
      active: false,
      header:  strings.SERVICE_VIEW_HEADER(),
      help_id: 'HELP_SERVICE_SUMMARY',
      navbar: false,
    },
    rows: [
      {
        template: 'block-avatar-group',
        fields: {
          avatar_src_1: groupIcon,
          avatar_src_2: serviceIcon,
          avatar_name_1: groupName,
          avatar_name_2: serviceData.name,
        },
      },
    ],
  };

  // Either display a 'Back' button on the left, or a 'Done' button
  // on the right.
  if (true) {
    page.fields = {
      ...page.fields,
    

      navigation_left: strings.EDIT(),
      navigation_left_action: 'changeViewServiceMode',
      navigation_right: strings.DONE(),
      navigation_right_data: 0,
      navigation_right_template: 'back',
      navigation_right_pane: 1,

    };
  } 

  const avatar = {
    template: 'block-section-row',
    fields: {
      class: ['collapsed', 'avatar-row'],
    },
    columns: [
      {
        template: 'block-login-avatar',
        fields: {
          avatar: serviceData.icon || config.app.DEFAULT_SERVICE_ICON,
          context: window.cordova ? 'addServiceImageCordova' : 'addServiceImage',
          data_id: serviceData.id,
        },
      },
      {
        template: 'block-icn-button',
      },
    ],
  };

  page.rows.push(avatar);
  //if (serviceData.username) {
  const serviceNameRow = {
    template: 'block-section-row',
    fields: {
      secondary_heading: strings.SERVICE_NAME(),
      class: ['collapsed', 'service-name-row'],
    },
    columns: [
      {
        template: 'block-primary-text',
        fields: {
          text: serviceData.name || '',

          name: 'renameService',
          for: 'renameService',
          id: 'renameService',
          class: ['toggleEditablePrimary'],
          //icon: 'check-solid',
          //icon_action: 'saveRenameFromViewService',
        },
      },
      // {
      //   template: 'block-icn-button',
      //   fields: { icon: 'edit', action: 'focusInputRow', label: strings.EDIT() },
      // },
    ],
  };

  page.rows.push(serviceNameRow);
  const usernameRow = {
    template: 'block-section-row',
    fields: {
      secondary_heading: strings.USERNAME_OR_EMAIL(),
      class: serviceData.username !== '' ? '' : 'collapsed',
    },
    columns: [
      {
        template: 'block-primary-text',
        fields: {
          text: serviceData.username || '',

          name: 'renameUsername',
          for: 'renameUsername',
          id: 'renameUsername',
          class: ['toggleEditablePrimary'],
          //icon: 'check-solid',
          //icon_action: 'saveRenameFromViewService',
        },
      },
      // {
      //   template: 'block-icn-button',
      //   fields: { icon: 'edit', action: 'focusInputRow', label: strings.EDIT() },
      // },

      {
        template: 'block-icn-button',
        fields: { icon: 'copy', action: 'copyServiceUsernameToClipboard', label: strings.SERVICE_COPY_ICON_LABEL() },
      },
    ],
  };

  // If being viewed in google chrome extension, add option to inject username into page
  if (window.state.extension) {
    usernameRow.columns.push({
      template: 'block-icn-button',
      fields: { icon: 'right', label: strings.SERVICE_INJECT_LABEL(), action: 'useUsernameInCurrentTab' },
    });
  }

  focusRemoveEdit('renameUsername');

  page.rows.push(usernameRow);

  //}

  //if (serviceData.username_secondary) {
  const usernameSecondaryRow = {
    template: 'block-section-row',
    fields: {
      secondary_heading: strings.USERNAME_OR_EMAIL_SECOND(),
      class: serviceData.username_secondary !== '' ? '' : 'collapsed',
    },
    columns: [
      {
        template: 'block-primary-text',
        fields: {
          text: serviceData.username_secondary || '',

          name: 'renameUsernameSecondary',
          for: 'renameUsernameSecondary',
          id: 'renameUsernameSecondary',
          class: ['toggleEditablePrimary'],

          //icon: 'check-solid',
          //icon_action: 'saveRenameFromViewService',
        },
      },
      // {
      //   template: 'block-icn-button',
      //   fields: { icon: 'edit', action: 'focusInputRow', label: strings.EDIT() },
      // },
      {
        template: 'block-icn-button',
        fields: {
          icon: 'copy',
          action: 'copyServiceUsernameSecondaryToClipboard',
          label: strings.SERVICE_COPY_ICON_LABEL(),
        },
      },
    ],
  };

  focusRemoveEdit('renameUsernameSecondary');

  // If being viewed in google chrome extension, add option to inject username into page
  if (window.state.extension) {
    usernameSecondaryRow.columns.push({
      template: 'block-icn-button',
      fields: { icon: 'right', label: strings.SERVICE_INJECT_LABEL(), action: 'useUsernameSecondaryInCurrentTab' },
    });
  }

  page.rows.push(usernameSecondaryRow);

  //}

  page.rows.push({
    template: 'block-section-row',
    fields: {
      secondary_heading: strings.SERVICE_WEBSITE_LABEL(),
      class: serviceData.website !== '' ? '' : 'collapsed',
    },
    columns: [
      {
        template: 'block-primary-text',
        fields: {
          text: serviceData.website || '',

          name: 'renameWebsite',
          for: 'renameWebsite',
          id: 'renameWebsite',
          class: ['toggleEditablePrimaryWebsite'],
          //icon: 'check-solid',
          //icon_action: 'saveRenameFromViewService',
        },
      },
      // {
      //   template: 'block-icn-button',
      //   fields: { icon: 'edit', action: 'focusInputRow', label: strings.EDIT() },
      // },
      {
        template: 'block-icn-button',
        fields: { icon: 'up', action: 'openWebsite', label: strings.SERVICE_OPEN_ICON_LABEL() },
      },
    ],
  });

  focusRemoveEdit('renameWebsite');

  //clear the moving
  //TODO put this somewher proper!!

  // Display fields of the service (e.g. password, PIN etc.)
  const serviceFields = serviceData.fields || {};
  let unavailableFields = 0;
  const copyIconLabel = strings.SERVICE_COPY_ICON_LABEL();
  const viewIconLabel = strings.SERVICE_VIEW_ICON_LABEL();
  const injectIconLabel = strings.SERVICE_INJECT_LABEL();

  page.rows.push(noteLoading(strings.NOTE(), '', 'note'));

  serviceData.fieldsArr
    .sort((a, b) => {
      return a === b ? 0 : a ? -1 : 1;
    })
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .forEach((f) => {
      page.rows.push(loadingServiceField(f.name, f.access_key, f.id));
    });

  page.rows.push({
    template: 'block-section-row',
    fields: {
      class: 'cta',
      class2: 'blue',
      context: 'newServiceAddNewField',
    },
    columns: [
      {
        template: 'block-icn-button',
        fields: { icon: 'add' },
      },
      {
        template: 'block-text-group',
        fields: { primary_text: config.strings.SERVICE_ADD_FORGHETTIBLE() },
      },
    ],
  });

  const resetPasswordTextTemplate = infoTextRow(
    movingService
      ? strings.VIEW_SERVICE_PASSWORD_HELP_MOVE()(serviceData.name, groupName)
      : strings.VIEW_SERVICE_PASSWORD_HELP()(serviceData.name),
    true,
    false,
    true,
    ['reset-password-row']
  );

  const resetPasswordTemplate = {
    template: 'block-section-row',
    fields: {
      class: ['centered-text', 'reset-password-row'],
    },
    columns: [
      {
        template: 'block-text-button',
        fields: {
          class: 'cta-button',
          text: strings.VIEW_SERVICE_RESET_PASSWORD(),
          action: 'registerAndOpenServiceUrl',
          data_id: serviceData.id,
        },
      },
    ],
  };
  const showReset = serviceData.newlyAddedService || movingService;
  showReset && serviceData.website && page.rows.push(resetPasswordTextTemplate, resetPasswordTemplate);

  // Check if we need to display a message to explain that some fields are unavailable
  const premium = window.state.launchData && window.state.launchData.home && window.state.launchData.home.premium;
  const showUnavailableMessage = unavailableFields > 0 && !premium;
  const unavailableMessage = showUnavailableMessage && infoTextRow(strings.UNAVAILABLE_FORGHETTIBLES_MESSAGE(), true);
  const upgradeToday = showUnavailableMessage && upgradeTodayButton();

  page.rows.push(unavailableMessage, upgradeToday);

  return page;
};
