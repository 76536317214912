const showToastMessage = require('../../action_helpers/showToastMessage');

const fb= require('../../app/firebaseUtils');
module.exports = ({ section }) => {
  const pane = '#pane-2';
  $(pane).addClass('busy');

  // Wrap everything in a promise so that errors go to catch block
  return Promise.resolve()
    .then(() => {
      //const phoneNumber = $('#migration-number').intlTelInput('getNumber');
      const phoneNumber = window.iti.getNumber();

      console.info('Authenticating with phone number...', phoneNumber);
      window.state.newPhoneNumber = phoneNumber;
      const appVerifier = window.recaptchaVerifier;
      return fb.signInWithPhoneNumber(fb.getCurrentUser()?.phoneNumber, appVerifier);
    })
    .then(confirmationResult => {
      $(pane).removeClass('busy');
      const activePaneIndex = $(pane + '-inner')
        .children('.active')
        .index();
      z2hApp.paneNavigation('migratePhoneNumber2', $(pane), activePaneIndex + 1);
      console.info('signInWithPhoneNumber: success.');

      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      window.confirmationResult = confirmationResult;
      return confirmationResult;
    })
    .catch(error => {
      $(pane).removeClass('busy');
      console.error('phoneNumberSignIn: error: ', error);
      showToastMessage(error);
    });
};
