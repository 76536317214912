const copyToClipboard = require('copy-html-to-clipboard');

const showToastMessage = require('../../action_helpers/showToastMessage');

module.exports = ({ currentTarget }) => {
  const fieldData = $(currentTarget)
    .closest('.row')
    .find('.primary-text-content')
    .text();

  if (fieldData) {
    copyToClipboard(fieldData);
    const trimmedFieldData = fieldData.length > 30 ? fieldData.substr(0, 27) + '...' : fieldData;
    showToastMessage(`Copied "${trimmedFieldData}" to clipboard`);
  }
};
