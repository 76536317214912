const strings = require('../../config/strings');

const copyIconLabel = strings.SERVICE_COPY_ICON_LABEL();
const viewIconLabel = strings.SERVICE_VIEW_ICON_LABEL();
const injectIconLabel = strings.SERVICE_INJECT_LABEL();

module.exports = {
  infoTextRow: (text, centered = false, border = true, html = false, additionalClasses) => {

    let classes =[];
    if (centered) {
      classes.push('centered-text')
    }
    if (border) {
      classes.push('no-border')
    }

    if (additionalClasses) {
      classes.push(...additionalClasses)
    }


    return (
    {
    template: 'block-section-row',
    fields: {
      class: classes.length > 0 ? classes : null,
      class2: border ? '' : 'no-border',
    },
    columns: [
      {
        template: 'block-info-text',
        fields: {
          text: html ? null : text,
          html: html ? text : null,
        },
      },
    ],
  })},

  helpButton: (helpId) => ({
    template: 'block-icn-button',
    fields: {
      icon: 'help-solid',
      data_id: helpId,
      action: 'displayContextualHelp',
    },
  }),

  subheading: ({ text, helpId, additionalClass }) => ({
    template: 'block-section-row',
    fields: {
      secondary_heading: text,
      //help_id: helpId,
      class: additionalClass || '',
    },
  }),

  thirdheading: ({ text, helpId, additionalClass }) => ({
    template: 'block-section-row',
    fields: {
      class: 'third-heading',
      secondary_heading: text,
      class2: additionalClass || '',

      //help_id: helpId,
    },
  }),

  ctaButton: (text, action, additionalClass) => ({
    template: 'block-section-row',
    fields: {
      class: 'no-border',
      class2: 'centered-text',
      class3: additionalClass,
    },
    columns: [
      {
        template: 'block-text-button',
        fields: {
          class: 'cta-button',
          action,
          text,
        },
      },
    ],
  }),

  centeredMessage: (text) => ({
    template: 'block-section-row',
    fields: {
      class: 'no-border',
      class2: 'centered-text',
    },
    columns: [
      {
        template: 'block-info-text',
        fields: { text },
      },
    ],
  }),

  serviceField: (
    name,
    key = '',
    id,
    { copyIconLabel, openIconLabel, injectIconLabel, viewIconLabel },
    moving = { moving: false, prefix: '' },

    oldKey = null,
  ) => {
    const headingText = (moving, name) => {
    
      if (!moving || !moving.moving || !oldKey) {
        return name;
      } else if (moving.prefix !== '') {
        return moving.prefix + ' ' + name;
      } else {
        return name;
      }
    };
    const component = {
      template: 'block-section-service',
      fields: {
        id: id,
        secondary_heading: headingText(moving, name),
        class: 'service-row'
        //secondary_heading_action: 'renameForghettibleMakeInput',
      },
      columns: [
        {
          template: 'block-text-group',
          columnGroup: 2,
          fields: {
            class: ['monospace'],
            primary_class: 'magnify',
            primary_text_label: oldKey && strings.NEW_FORGHETTIBLE(),
            primary_html:
              '<span class="numbered-character-list">' +
              key
                .split('')
                .map((c) => '<span>●</span>')
                .join('') +
              '</span>',

            primary_action: 'copyPasswordAndGiveLink',

            secondary_text_label: oldKey && strings.OLD_FORGHETTIBLE(),
            secondary_class: oldKey && ['nocolour', 'remove:secondary-text-content', 'primary-text-content'],
            secondary_parent_class: oldKey && [('remove:secondary-text', 'primary-text')],
            secondary_html:
              oldKey &&
              '<span class="numbered-character-list">' +
                oldKey
                  .split('')
                  .map((c) => '<span>●</span>')
                  .join('') +
                '</span>',

            secondary_action: 'copyPasswordAndGiveLink',
          },
        },
        {
          template: 'block-icn-button',
          columnGroup: 1,
          fields: { icon: 'copy', action: 'copyPasswordAndGiveLink', label: copyIconLabel },
        },
        {
          template: 'block-icn-button',
          columnGroup: 1,
          fields: { icon: 'show', action: 'startViewPasswordTimer', label: viewIconLabel },
        },
        {
          template: 'block-icn-button',
          columnGroup: 1,
          fields: { icon: 'settings', label: 'settings', context: 'editServiceFieldFromView' },
        },
      ],
    };

    // navigation_template: 'editServiceFieldDetails',
    // navigation_data: nav + 1,
    // id: field.id,
    // If viewing this service in google chrome extension, add link to inject
    // password into current tab window
    if (window.state.extension) {
      component.columns.push({
        template: 'block-icn-button',
        fields: { icon: 'right', action: 'usePasswordInCurrentTab', label: injectIconLabel },
      });
    }
    if (localStorage.getItem('verifyRequest')) {
      component.columns.push({
        template: 'block-icn-button',
        fields: { icon: 'right', action: 'usePasswordInVerify', label: injectIconLabel },
      });
    }

    return component;
  },

  unavailableServiceField: (name, key = '', id) => ({
    template: 'block-section-row',
    fields: {
      id: id,
      secondary_heading: name,
    },
    columns: [
      {
        template: 'block-text-group',
        fields: {
          class: 'monospace',
          primary_text: strings.UNAVAILABLE(),
        },
      },
    ],
  }),
  loadingServiceField: (name, key = '', id) => ({
    template: 'block-section-service',
    fields: {
      id: id,
      secondary_heading: name,
      class: ['loading-password', 'service-row'],
    },
    columns: [
      {
        template: 'block-icn-button',
        columnGroup: 1,
        fields: { icon: 'loading' },
      },
    ],
  }),
  noteField: (id) => ({
    template: 'block-section-service',
    fields: {
      id: id,
      secondary_heading: strings.NOTE(),
      action: 'copyNoteToClipboard',
      class: ['note-row'],
    },
    columns: [
      {
        template: 'block-text-group',
        columnGroup: 2,
        fields: {
          primary_html: '<span class="numbered-character-list">' + '</span>',
          primary_action: 'copyNoteToClipboard',
      
        },
      },

      {
        template: 'block-icn-button',
        columnGroup: 1,
        fields: { icon: 'copy', action: 'copyNoteToClipboard', label: copyIconLabel },
      },
      {
        template: 'block-icn-button',
        columnGroup: 1,
        fields: { icon: 'show', action: 'viewNote', label: viewIconLabel },
      },
      {
        template: 'block-icn-button',
        columnGroup: 1,
        fields: { icon: 'settings', label: 'settings', context: 'editNoteFieldFromView' },
      },
    ],
  }),

  newNoteField: (id) => ({
    template: 'block-section-service',
    fields: {
      id: id,
      secondary_heading: strings.NOTE(),
     //action: 'copyNoteToClipboard',
      class: ['note-row'],
    },
    columns: [
      {
        template: 'blok-text-group',
        columnGroup: 2,
        fields: {
          primary_html: '<span class="numbered-character-list">' + '</span>',
          primary_action: 'copyNoteToClipboard',
        },
      },

      // {
      //   template: 'block-icn-button',
      //   columnGroup: 1,
      //   fields: { icon: 'copy', action: 'copyNoteToClipboard', label: copyIconLabel },
      // },
      // {
      //   template: 'block-icn-button',
      //   columnGroup: 1,
      //   fields: { icon: 'show', action: 'viewNote', label: viewIconLabel },
      // },
      {
        template: 'block-icn-button',
        columnGroup: 1,
        fields: { icon: 'add', label: 'new', action: 'gotoEditNote' },
      },
    ],
  }),


  noteLoading: (name, key ='', id) => (
    
    {
      template: 'block-section-service',
      fields: {
        id: id,
        secondary_heading: name,
        class: ['loading-password', 'note-row'],
      },
      columns: [
        {
          template: 'block-icn-button',
          columnGroup: 1,
          fields: { icon: 'loading' },
        },
      ],
    }
  ),

  upgradeTodayButton: () => ({
    template: 'block-section-row',
    columns: [
      {
        template: 'block-text-button',
        fields: {
          class: 'cta-button',
          text: strings.UPGRADE_TODAY_BUTTON(),
          navigation_data: '1',
          navigation_template: 'upgradeToday',
          navigation_pane: '2',
        },
      },
    ],
  }),

  // Editable text (e.g. "Username" field when adding a new service/login)
  editableTextRow: ({ label, id, placeholder, onChange, helpId, initialValue, type, maxlength }) => ({
    template: 'block-section-row',
    fields: { secondary_heading: label },
    columns: [
      {
        template: 'block-editable-primary-text',
        fields: {
          id: id,
          for: id,
          action: onChange,
          placeholder: placeholder,
          primary_text: initialValue,
          type: type,
          maxlength: maxlength,
        },
      },
      // {
      //   template: 'block-icn-button',
      //   fields: {
      //     data_id: helpId,
      //     icon: 'help-solid',
      //     class: 'help-button',
      //     action: 'displayContextualHelp',
      //   },
      // },
    ],
  }),
  //markers is an array it could be a percentage or just
  //some numbers
  progressBarWithMarkers: (markers, value, fields) => ({
    template: 'block-section-row',
    fields: fields || {},
    columns: markers.map((pct) => ({
      template: 'block-progress-bar',
      fields: {
        max: '100',
        value: pct <= value ? 100 : 0,
      },
    })),
  }),

  //Primary text
  primaryText: (text, centered = false, border = false, html = false, wrapText = false) => ({
    template: 'block-primary-text',
    fields: {
      class: centered ? 'centered-text' : '',
      class2: border ? 'no-border' : '',
      class3: wrapText ? 'wrap-text' : '',
      text: html ? null : text,
      html: html ? text : null,
    },
  }),

  //Help Text
  helpTextRow: (text, centered = true, border = false, html = false, message = false) => ({
    template: 'block-section-row',
    fields: {
      class: centered ? 'centered-text' : '',
      class2: border ? '' : 'no-border',
      clsss3: message ? 'message' : '',
    },
    columns: [
      {
        template: 'block-help-text',
        fields: {
          text: html ? null : text,
          html: html ? text : null,
          class: 'message',
        },
      },
    ],
  }),
  notificationEvent : ({
    id,
    icon,
    text,
    textData,
    fieldData,
    read,
    action,
    banner,
    animation,
    data_id,
    actionData,
  }) => {
    let primaryText = text ? (typeof strings[text] === 'function' ? strings[text]() : text) : null;
    if (typeof primaryText === 'function') {
      primaryText = primaryText(textData);
    }
  
    //Add classes
    let rowClass = ['notification-row'];
    if (fieldData?.class) {
      rowClass.push(fieldData.class);
    }
  
    if (banner) {
      rowClass.push('no-border');
    }
    rowClass = rowClass.flat().length > 0 ? rowClass.flat() : null;
  
    return [
      text
        ? {
            template: 'block-section-row',
            fields: {
              fieldData,
              class: rowClass,
              id,
              action,
              navigation_data_id: data_id,
              actionDetail: actionData,
            },
            columns: [
              icon && { template: 'block-icn-button', fields: { icon, class: !read ? 'primary-text-content' : '' } },
              {
                template: 'block-text-group',
                fields: {
                  primary_text: primaryText,
                  class: !read ? 'primary-text-content' : '',
                },
              },
              {
                template: 'block-icn-button',
                fields: {
                  icon: 'chevron-right',
                  class: !read ? 'primary-text-content' : '',
                  action,
                  navigation_data_id: data_id,
                  actionDetail: actionData,
                },
              },
            ],
          }
        : {
            template: 'block-section-row',
            fields: { fieldData, class: rowClass },
            columns: [
              {
                template: 'block-insertable',
                fields: {},
              },
            ],
          },
  
      animation
        ? {
            template: 'block-animation',
            fields: {
              action,
              class: ['remove:onboarding-image', 'banner-image'],
              id: animation,
              data_id,
              actionDetail: actionData,
            },
          }
        : banner
        ? {
            template: 'block-banner',
            fields: {
              id: `${id}`,
              image: banner,
              action,
              data_id,
              class: 'remove:column',
              actionDetail: actionData,
            },
          }
        : {},
    ];
  },

  notificationNoRow: () => {
    const notes = [];
    notes.push({
      template: 'block-insertable',
      fields: {},
    });
    notes.push({
      template: 'block-section-row',
      fields: {
        class: 'no-border',
      },
      columns: [
        {
          template: 'block-icn-big',
          fields: {
            icon: 'alarm',
            class: '',
          },
        },
      ],
    });
    notes.push({
      template: 'block-section-row',
      fields: {
        class: 'no-border',
      },
      columns: [
        {
          template: 'block-primary-text',
          fields: {
            class: ['centered-text', 'wrap-text'],

            html: `<b>${strings.NO_NOTIFICATIONS_HELLO_TEXT()(window.state.launchData.user_name)}</b>`,
          },
        },
      ],
    });

    notes.push({
      template: 'block-section-row',
      fields: {
        class: 'no-border',
      },
      columns: [
        {
          template: 'block-primary-text',
          fields: {
            class: ['centered-text', 'wrap-text'],

            text: strings.NO_NOTIFICATIONS_CHECK(),
          },
        },
      ],
    });

    return notes;
  }
};
