const fetchBase64Image = require('../../action_helpers/fetchBase64Image');
const strings = require('../../../config').strings;

/**
 * Used to initialise the details for a new login to be created, from the user clicking a suggested
 * 'preset' login during the Create Account process.
 */
module.exports = ({ element, z2hApp }) => {
  const z2hTools = window.z2hTools;

  // Find which preset was clicked
  const presetId = $(element).attr('data-id');
  const presets = (window.state && window.state.topPresets) || [];
  const presetSelected = presets.find((p) => p.id === presetId);

  // Get new service details from preset
  const serviceName = presetSelected.name;
  const serviceWebsite = presetSelected.address;
  const serviceUsername = localStorage.getItem('default-username') || '';
  const iconUrl = presetSelected.url || '';

  // Initialise new service data in z2hApp.pageData
  z2hApp.pageData = z2hApp.pageData || {};
  z2hApp.pageData.service = {};
  z2hApp.pageData.service.serviceData = {};
  z2hApp.pageData.service.fieldsArray = [];
  z2hApp.pageData.service.fieldsSaved = {};

  z2hApp.pageData.service.fieldsUnsaved = {};
  z2hApp.pageData.postUpdateAction = null;

  // Get a shorter reference to the service data
  const service = z2hApp.pageData.service;

  // Store basic details
  service.serviceData.name = serviceName;
  service.serviceData.website = serviceWebsite;
  service.serviceData.username = serviceUsername;

  // Add new password field
  const newFieldId = z2hTools.getUid();
  service.fieldsArray.push(newFieldId);
  const defaultGenerationSettings = window.state.defaultGenerationSettings || {};
  service.fieldsUnsaved[newFieldId] = {
    name: strings.FIELD_TEMPLATE_PASSWORD(),
    generation_settings: {
      exclude: '',
      length: defaultGenerationSettings.length || 16,
      numbers: true,
      symbols: false,
      simple_symbols: true,
      //symbols: true,
      lowercase: true,
      uppercase: true,
      words: 0,
      version: 1,
    },
  };

  const { STOP_SIMPLE_SYMBOLS } = require('../../../config/app');
  if (STOP_SIMPLE_SYMBOLS) {
    delete service.fieldsUnsaved[newFieldId].generation_settings.simple_symbols;
  }
  // Copy from fieldsUnsaved into fieldsSaved
  service.fieldsSaved[newFieldId] = service.fieldsUnsaved[newFieldId];

  // Attempt to fetch service icon
  if (iconUrl) {
    fetchBase64Image(iconUrl)
      .then((base64Image) => {
        window.state.temporaryServiceIcon = base64Image;
        window.state.temporaryServiceIconChosen = true;
      })
      .catch((e) => {
        console.error('Error fetching icon for new service: ', e);
      });
  }
};
