
const { strings, urls } = require('../../../config');
const { infoTextRow } = require('../../page_helpers/components');


//If this is not the electron app then we need to tell them to go and 
//and download the app on their PC/MAC
module.exports = ({ nav }) => {
  return {
    template: 'block-section-page',
    fields: {
      id: 'importServices',
      active: false,
      header: strings.IMPORT_SERVICES_COMPLETE_HEADER(),
      navbar: false,
    },
    rows: [
      infoTextRow(strings.IMPORT_SERVICES_COMPLETE_NOTE(), true, false),
      {
        template: 'block-section-row',
        columns: [{
          template: 'block-icn-button',
          fields: {
            icon: 'check-solid',
            class: 'icn-button-lrg',
            navigation_data: 0,
            navigation_pane: 1,
            navigation_template: 'back',
          }
        }]

      }

    ],
  };
};
