module.exports = ({ section, z2hApp }) => {
  const groupId = z2hApp.currentGroup().id;
  const groupName = section.find('[name=group-name]').val();
  const data = { name: groupName };

  // Send data to the server
  z2hApp.requestData('post', '', 'groups/' + groupId, JSON.stringify(data)).then(() => {
    $('.login-list-page__header h1').text(groupName);

    z2hApp.updateGroup(groupId, { name: groupName })

    $('#groups-list')
      .find(`[data-id="${groupId}"]`)
      .find('.primary-text-content')
      .text(groupName);
    z2hApp.paneNavigation('groupsList', $('#pane-1'), 0);
  });
};
