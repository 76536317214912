const strings = require('../../../config').strings;

module.exports = () => ({
  template: 'block-section-page',
  fields: {
    id: 'adding-service',
    active: false,
    header: strings.SERVICE_ADDING(),
    class: 'full-colour',
  },
  rows: [
    {
      template: 'block-loading-icon-row',
      fields: {},
      columns: [],
    },
  ],
});
