module.exports = ({ element, currentTarget, z2hApp }) => {
  // Store in state
  const group = $(element).attr('data-id');

  localStorage.removeItem(group + '_doodle');

  if (window.ss) {
    window.ss.remove(
      () => {
        console.log('Stored doodle');
      },
      () => {
        console.log('Failed to remove doodle');
      },
      group + '_doodle',
    );
  }

  const paneDetails = window.getActivePaneDetails('#pane-2');

  if (window.state.addBiometricsFromSettings) {
    z2hApp.paneNavigation('enableBiometricsGroups', paneDetails.pane, 1);
  } else {
    z2hApp.paneNavigation('viewGroupDetails', paneDetails.pane, 1);
  }
  return;
};
