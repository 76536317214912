import { showToast } from 'dialog-manager';

const toggleUserPref = require('./toggleUserPreference')
const strings = require('../../../config').strings;
const showToastMessage = require('../../action_helpers/showToastMessage')

module.exports = ({ element }) => {
  let enabling = false;
  if (!localStorage.getItem('userprefs-touchid') || localStorage.getItem('userprefs-touchid') === 'false' || localStorage.getItem('userprefs-touchid') === false) {
    enabling = true;
  }
  localStorage.setItem('userprefs-touchid', enabling);

  if (enabling) {
    showToastMessage(strings.APP_PREFERENCES_TOUCH_ID_TOAST(), { center: true, html: true })
  }
  $('body').toggleClass('userprefs-touchid', enabling);
}