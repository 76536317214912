module.exports = {
  editServiceFieldAdvancedOptions: require('./editServiceFieldAdvancedOptions'),
  addNewService3_connecting: require('./addNewService3_connecting'),
  addNewService2_doodlepad: require('./addNewService2_doodlepad'),
  editServiceFieldDetails: require('./editServiceFieldDetails'),
  viewService1_doodlepad: require('./viewService1_doodlepad'),
  addNewServiceRedirect: require('./addNewServiceRedirect'),
  viewNewlyAddedService: require('./viewNewlyAddedService'),
  searchForServiceIcon: require('./searchForServiceIcon'),
  quickSelectAddLogin: require('./quickSelectAddLogin'),
  editServiceDetails: require('./editServiceDetails'),
  viewServiceDetails: require('./viewServiceDetails'),
  chooseServiceIcon: require('./chooseServiceIcon'),
  restorePassword: require('./restorePassword'),
  addNewService: require('./addNewService'),
  viewService: require('./viewService'),
  loginList: require('./loginList'),
  addService_username: require('./addService_username'),
  addService_doodle: require('./addService_doodle'),
  addService_search: require('./addService_search'),
  addService_group: require('./addService_group'),
  addService_icon: require('./addService_icon'),
  addService_username_onboard: require('./addService_username_onboard'),
  addService_doodle_confirm: require('./addService_doodle_confirm'),
  addService_onboarding_complete: require('./addService_onboarding_complete'),
  addService_newGroup: require('./addService_newGroup'),
  moveService_confirm: require('./moveService_confirm'),
  addService_website: require('./addService_website'),
  viewService1_biometrics: require('./viewService1_biometrics'),
  specialCharacters: require('./specialCharacters'),
  addService_biometrics: require('./addService_biometrics'),
  addNewService_selectPresets: require('./addNewService_selectPresets'),
  editNote: require('./editNote'),
  addUsernameToExistingService: require('./addUsernameToExistingService'),
};
