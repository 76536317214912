import { active } from 'domain';

const showToastMessage = require('../../action_helpers/showToastMessage');
const { DEFAULT_SERVICE_ICON } = require('../../../config').app;

module.exports = ({ currentTarget, z2hApp }) => {
  const paneDetails = window.getActivePaneDetails('#pane-2');

  //const addNewService = require('./addNewService');
  const moveService = require('./moveService');
  const onSuccess = () =>
    //z2hApp.paneNavigation('addService_onboarding_complete', $('.overlay'));
    z2hApp.paneNavigation('viewService', paneDetails.pane, paneDetails.activePaneIndex + 2);

  const onFailure = () => z2hApp.paneNavigation('addService_group', paneDetails.pane, paneDetails.activePaneIndex - 1);

  z2hApp.paneNavigation('moveServicePassword_loading', paneDetails.pane, paneDetails.activePaneIndex + 1);
  delete z2hApp.pageData.service.currentServiceId;
  delete z2hApp.pageData.service.currentGroupId;
  //z2hApp.pageData.service.serviceData.icon = DEFAULT_SERVICE_ICON;
  //showToastMessage('Please use test version to test this functionality');
  //z2hApp.paneNavigation('addService_group', paneDetails.pane, paneDetails.activePaneIndex - 1);

  moveService({ z2hApp, onSuccess, onFailure });
};
