const showToastMessage = require('../../action_helpers/showToastMessage');
const strings = require('../../../config').strings;

const getAccessKeys = require('../../getPageData_helpers/getAccessKeys');

const {
  infoTextRow,
  serviceField,
  unavailableServiceField,
  upgradeTodayButton,
  loadingServiceField,
} = require('../../page_helpers/components');

// =============================================================================

function resetField(fieldId, z2hApp) {
  return z2hApp.requestData('post', '', 'fields/' + fieldId + '/reset-timestamp').then((response) => {
    // If response has an error, throw it, to be caught by a future .catch block
    if (Math.floor(response.status / 100) !== 2) {
      throw new Error(
        response.user_message || response.developer_message || response.message || strings.MSG_FALLBACK_ERROR_MESSAGE(),
      );
    }
  });
}
// =============================================================================

module.exports = ({ menuTarget, z2hApp }) => {
  const copyIconLabel = strings.SERVICE_COPY_ICON_LABEL();
  const viewIconLabel = strings.SERVICE_VIEW_ICON_LABEL();
  const injectIconLabel = strings.SERVICE_INJECT_LABEL();

  const row = $(menuTarget).closest('.row');
  const fieldId = row.attr('id');

  const conf = confirm(strings.MSG_CONFIRM_RESET());
  if (!conf) return;

  const serviceData = window.z2hApp.pageData.service;
  const field = window.z2hApp.pageData.service.fields[fieldId];

  $(`#${fieldId}`).replaceWith(z2hApp.constructRow(loadingServiceField(field.name, null, field.id)));

  resetField(fieldId, z2hApp)
    .catch((err) => {
      console.error(err);
      showToastMessage(err.message);
    })
    .then((_) => {
      const service = z2hApp.pageData.service;
      window.serviceFieldPromises[service.id] = null;

      getAccessKeys(serviceData.id, serviceData.doodle).then((data) => {
        if (field) {
          field.old_access_key = field.access_key;
          field.access_key = data[fieldId].access_key;
        }

        $(`#${fieldId}`).fadeOut('slow', () => {
          $(`#${fieldId}`).replaceWith(
            z2hApp.constructRow(
              serviceField(
                field.name,
                field.access_key,
                field.id,
                { copyIconLabel, viewIconLabel, injectIconLabel },
                {
                  moving: false,
                  prefix: field.moving ? strings.VIEW_SERVICE_OLD_PREFIX() : strings.VIEW_SERVICE_NEW_PREFIX(),
                },
                field.access_key !== field.old_access_key ? field.old_access_key : null,
              ),
            ),
          );
          $(`#${fieldId}`).hide().fadeIn(1000);
        });
      });
    });
};
