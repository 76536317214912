module.exports = ({ element, section, z2hApp }) => {
  // Get username from input field
  const username = $(section).find('#add-service-username-input').val();

  // Store selected username in state
  window.z2hApp.pageData.service.serviceData.username = username;

  // const selector = window.state.userData.activeOnboarding ? '.overlay' : '#pane-3';
  // const innerSelector = window.state.userData.activeOnboarding ? '.overlay ' : '#pane-3-inner '
  // // Display/transition to 'select icon' page
  // const activePaneIndex = $(innerSelector + '.active').index();

  const paneDetails = window.getActivePaneDetails('#pane-2');

  const { biometricsOrDoodle } = require('../../actions/services');
  biometricsOrDoodle({
    currentTarget: null,
    z2hApp,
    pane: paneDetails.pane,
    newPaneIndex: paneDetails.activePaneIndex + 1,
    template: { biometrics: 'addService_biometrics', doodlepad: 'addService_doodle' },
  });
};
