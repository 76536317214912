const strings = require('../../../config').strings;

function row(label, label2) {
  return {
    template: 'block-section-row',
    fields: {},
    columns: [
      {
        template: 'block-text-group',
        fields: { primary_text: label, secondary_text: label2 || '' },
      },
    ],
  };
}

module.exports = ({ data, nav }) => {
  const devices = data && data.devices ? Object.values(data.devices) : [];

  const deviceRows = devices.filter(d=>d.id && d.os && d.ua_header).map(d => {
    
    let description = d.os;

    if (d?.ua_header.includes('Appcelerator')) {
      description += strings.DEVICES_FORGHETTI_APP();
    } else {
      description += d.ua_family ? ' - ' + d.ua_family : '';
    }
    const lastActiveStr = d.last_active && strings.DEVICES_LAST_ACTIVE() + new Date(d.last_active).toLocaleString();
    return row(description, lastActiveStr);
  });

  const page = {
    template: 'block-section-page',
    fields: {
      id: 'view-devices',
      header: strings.DEVICES_HEADER(),
      navbar: false,
      navigation_left: strings.BACK(),
      navigation_left_icon: '',
      navigation_left_data: '0',
      navigation_left_template: 'back',
    },
    rows: [
      {
        template: 'block-section-row',
        fields: {
          class: 'centered-text',
        },
        columns: [
          {
            template: 'block-text-button',
            fields: {
              class: 'cta-button',
              text: strings.DEVICES_LOG_OUT_ALL(),
              action: 'logOutOfAllDevices',
            },
          },
        ],
      },
      ...deviceRows,

    ],
  };

  return page;
};
