const showUpgradeTodayPopup = require('../../action_helpers/showUpgradeTodayPopup');
const config = require('../../../config');
const parseUrl = require('../../action_helpers/parseUrl');
const strings = require('../../../config/strings');

const setType = (type) => {
  const { z2hTools } = window;
  const newFieldId = z2hTools.getUid();
  z2hApp.pageData.service.fieldsArray.push(newFieldId);
  const defaultGenerationSettings = window.state.defaultGenerationSettings || {};
  if (type === 'PIN') {
    z2hApp.pageData.service.fieldsUnsaved[newFieldId] = {
      name: strings.FIELD_TEMPLATE_PIN(),
      generation_settings: {
        exclude: '',
        length: 4,
        numbers: true,
        symbols: false,
        simple_symbols: false,
        //symbols: true,
        lowercase: false,
        uppercase: false,
        words: 0,
        version: 1,
      },
    };
  }
  if (type === 'Word') {
    z2hApp.pageData.service.fieldsUnsaved[newFieldId] = {
      name: strings.FIELD_TEMPLATE_MEMWORD(),
      generation_settings: {
        exclude: '',
        length: 0, // No extra letters/numbers with your 'Memorable word'
        lowercase: false,
        numbers: false,
        symbols: false,
        simple_symbols: false,
        uppercase: false,
        words: 1,
        version: 1,
      },
    };
  }
  // Copy from fieldsUnsaved into fieldsSaved
  z2hApp.pageData.service.fieldsSaved[newFieldId] = z2hApp.pageData.service.fieldsUnsaved[newFieldId];
  // For whatever reason, fieldsArray contains an array of unique IDs for each field/forghettible,
  // and fieldsSaved is an object like { fieldId: fieldDetails, fieldId: fieldDetails }
  // and now we want to put all of the fieldsSaved into fieldsData where ID is in fieldsArray
  const fieldsData = [];
  z2hApp.pageData.service.fieldsArray.forEach((id) => {
    fieldsData.push(z2hApp.pageData.service.fieldsSaved[id]);
  });
};

module.exports = ({ websiteUrl, type }) => {
  const { state, z2hApp } = window;
  const { selectedGroupId } = state;
  const selectedGroup = state.groupsList.find((g) => g.id === selectedGroupId) || {};

  z2hApp.pageData = z2hApp.pageData || {};
  z2hApp.pageData.service = {
    groupId: selectedGroupId,
    serviceData: {
      name: '',
      username: '',
      website: '',
      preset_id: '',
      icon: '',
    },
    serviceUnsaved: false,
    fieldsArray: [],
    fieldsSaved: {},
    fieldsUnsaved: {},
  };
  let services = window.state.servicePresetPromise;

  if (websiteUrl && websiteUrl !== '') {
    services.then(() => {
      const service = window.state.serviceIconList.find((service) => {
        //returns the number of parts that match the hostname
        return hostnameMatch(service.address, websiteUrl) > 1;
      });
      if (service) {
        z2hApp.pageData.service.serviceData.name = service.name;
        z2hApp.pageData.service.serviceData.website = service.address;
        z2hApp.pageData.service.serviceData.icon = service.url;
      }
    });
  }

  //We need to get rid of the moving service stuff in case we've left it
  //hanging around.
  services.then(() => {
    if (window.state.movingService) delete window.state.movingService;
    window.state.selectedServiceId = 'new';

    if (type) setType(type);

    const selector = $('.overlay').is(':visible') ? '.overlay' : '#pane-2';
    const subSelector = selector === '.overlay' ? '.overlay .section-pane-wrapper' : '#pane-2-inner';
    let index = $(subSelector).children('.active').index() + 1;

    //if we have a pane which was empty from the last go.

    //check if we've already had the pane
    if (selector === '#pane-2' && $('#addService_search').length) {
      index = 0;
    }
    // Take this opportunity to clear out pane3 for a better appearance when transitioning to that pane
    if (index === 0) {
      $('#pane-2-inner').empty();
      index = 1;
    }
    // This will either display the form to add a new service, or the quickSelectAddLogin page
    if (z2hApp.pageData.service.serviceData.name !== '') {
      z2hApp.paneNavigation('addService_group', $(selector), index);
    } else {
      z2hApp.paneNavigation('addService_search', $(selector), index);
    }
  });
};

function hostnameMatch(h1, h2) {
  const url1 = parseUrl(h1).hostname;
  const url2 = parseUrl(h2).hostname;

  // Firstly check that both hostnames match if they are cut down to the same length
  const minLength = Math.min(url1.length, url2.length);
  if (url1.substr(-minLength) !== url2.substr(-minLength)) {
    return 0;
  }
  const matchingChars = url1.substr(-minLength);
  return matchingChars.split('.').length;
}
