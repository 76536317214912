const config = require('../../../config');

/// TODO: This screen shares a lot with the addNewService screen, so could
/// be DRYed out a bit.

module.exports = ({ data, nav }) => {
  const serviceNameUid = z2hTools.getUid();
  const serviceUsernameUid = z2hTools.getUid();
  const serviceWebAddressUid = z2hTools.getUid();

  window.state.selectedServiceId = 'new';

  const showSuggestions = localStorage.getItem('userprefs-suggestions') === 'false' ? false : true;

  // Helper functions
  // ======================
  const editableTextRow = (secondary_heading, id, placeholder, action, helpId) => ({
    template: 'block-section-row',
    fields: { secondary_heading },
    columns: [
      {
        template: 'block-editable-primary-text',
        fields: { for: id, id, placeholder, action },
      },
      {
        template: 'block-icn-button',
        fields: {
          data_id: helpId,
          icon: 'help-solid',
          class: 'help-button',
          action: 'displayContextualHelp',
        },
      },
    ],
  });

  return {
    template: 'block-section-page',

    fields: {
      active: false,
      id: 'create-account-add-new-custom-service',
      header: config.strings.SERVICE_HEADER_NEW(),

      navigation_left: config.strings.BACK(),
      navigation_left_icon: '',
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'back',

      navigation_right: config.strings.NEXT(),
      navigation_right_data: nav + 1,
      navigation_right_validation: 'addNewService',
      navigation_right_action: 'generateNewServiceIcon',
      navigation_right_template: 'createAccount_addCustomLogin_doodlepad',
    },

    rows: [
      {
        template: 'block-section-row',
        fields: {},
        columns: [
          {
            template: 'block-login-avatar',
            fields: {
              avatar: window.state.temporaryServiceIcon || config.app.DEFAULT_SERVICE_ICON,
              context: 'addServiceImage',
              data_id: 'temporaryServiceIcon',
            },
          },
          {
            template: 'block-editable-primary-text',
            fields: {
              id: serviceNameUid,
              for: serviceNameUid,
              action: 'newServiceName',
              placeholder: config.strings.SERVICE_NAME_PLACEHOLDER(),
              tooltip: config.strings.CREATE_ACCOUNT_SERVICE_NAME_TOOLTIP(),
            },
          },
          {
            template: 'block-icn-button',
            fields: {
              icon: 'help-solid',
              data_id: 'HELP_LOGIN_TITLE',
              action: 'displayContextualHelp',
            },
          },
        ],
      },

      // Suggested service presets (initially hidden)
      showSuggestions && {
        template: 'block-suggested-presets',
        fields: {
          service_name_id: serviceNameUid,
        },
      },

      // Username
      editableTextRow(
        config.strings.SERVICE_USERNAME_LABEL(),
        serviceUsernameUid,
        config.strings.SERVICE_EDIT_USERNAME_PLACEHOLDER(),
        'newServiceEmailOrUsername',
        'HELP_LOGIN_USERNAME',
      ),

      // Website
      editableTextRow(
        config.strings.SERVICE_WEBSITE_LABEL(),
        serviceWebAddressUid,
        config.strings.SERVICE_EDIT_WEBSITE_PLACEHOLDER(),
        'newServiceWebAddress',
        'HELP_LOGIN_WEBSITE',
      ),

      // "Add forghettible"
      {
        template: 'block-section-row',
        fields: {
          class: 'cta',
          class2: 'blue',
          context: 'newServiceAddNewField',
        },
        columns: [
          {
            template: 'block-icn-button',
            fields: { icon: 'add' },
          },
          {
            template: 'block-text-group',
            fields: { primary_text: config.strings.SERVICE_ADD_FORGHETTIBLE() },
          },
          {
            template: 'block-icn-button',
            fields: {
              icon: 'help-solid',
              data_id: 'HELP_LOGIN_ADD_FORGHETTIBLE',
              action: 'displayContextualHelp',
            },
          },
        ],
      },
    ],
  };
};
