const COLLAPSED_ROW_CLASSNAME = 'collapsed';
const ROTATE_ICON_CLASSNAME = 'rotate-180';

module.exports = ({ currentTarget, targetId = null, collapsed = null }) => {
  
  //console.log('targetId',targetId);
  const DOMTarget = $(currentTarget);
  const targetEle = targetId ? $('#'+targetId) : $('#symbolsWrapper');
  console.log('collapsed: targetId',collapsed+' '+targetId);
  if (collapsed !== null) {
    console.log('foo',collapsed);
    if (collapsed === true) {
      console.log('bar',collapsed);
      targetEle.addClass(COLLAPSED_ROW_CLASSNAME);
      return;
    }
    console.log('foobar',collapsed);
    targetEle.removeAttr('style');
    targetEle.removeClass(COLLAPSED_ROW_CLASSNAME);
    return;
  }

  //Show/Hide symbols
  if (!targetId) {
    if (targetEle.hasClass(COLLAPSED_ROW_CLASSNAME)) {
      targetEle.removeClass(COLLAPSED_ROW_CLASSNAME);
      DOMTarget.addClass(ROTATE_ICON_CLASSNAME);
    } else {
      targetEle.addClass(COLLAPSED_ROW_CLASSNAME);
      DOMTarget.removeClass(ROTATE_ICON_CLASSNAME);
    }
  }
};