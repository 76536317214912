module.exports = ({ z2hApp, menuTarget }) => {
  z2hApp.pageData = {};
  z2hApp.pageData.service = {};
  z2hApp.pageData.postUpdateAction = null;
  z2hApp.pageData.service.serviceData = {};
  z2hApp.pageData.service.fieldsArray = [];
  z2hApp.pageData.service.fieldsSaved = {};
  z2hApp.pageData.service.fieldsUnsaved = {};
  // Get ID of selected service
  const serviceId = $(menuTarget).closest('[data-id]').attr('data-id');
  window.state.selectedServiceId = serviceId;

  window.state.currentService = window.state.currentServices.find((s) => s.id === serviceId);
  if (!window.state.currentService) {
    // Check if service exists in another group
    const groupsList = window.state.groupsList;
    for (let i = 0; i < groupsList.length; i++) {
      const groupId = groupsList[i].id;
      const services = groupsList[i].services || [];
      if (services.find && services.find((s) => s.id === serviceId)) {
        window.state.currentService = services.find((s) => s.id === serviceId);
        window.state.selectedGroupId = groupId;
        window.state.currentServices = services;

        if (window.z2hApp.highlightNavElement)
          window.z2hApp.highlightNavElement($('#main-nav').find('[data-id=' + groupId + ']'));

        break;
      }
    }
  }

  z2hApp.paneNavigation('editServiceDetails', $('#pane-2'), 1);
};
