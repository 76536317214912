const config = require('../../../config');
const strings = config.strings;
const { SERVIVCE_NAME_MAXLENGTH, USERNAME_NAME_MAXLENGTH, WEBSITE_URL_MAXLENGTH } = require('../../../config/app');

module.exports = ({ nav, data }) => {
  const addFieldRow = (field) => {
    return {
      template: 'block-section-row',
      fields: {
        navigation_template: 'editServiceFieldDetails',
        navigation_data: nav + 1,
        id: field.id,
      },
      columns: [
        { template: 'block-text-group', fields: { primary_text: field.name } },
        { template: 'block-icn-button', fields: { icon: 'settings' } },
      ],
    };
  };

  const deleteButton = {
    template: 'block-section-row',
    fields: { action: 'deleteLogin', class: 'cta', class2: 'red' },
    columns: [
      {
        template: 'block-icn-button',
        fields: {
          icon: 'delete',
        },
      },
      {
        template: 'block-text-button',
        fields: {
          text: strings.SERVICE_DETAILS_DELETE(),
        },
      },
    ],
  };
  const service = data;
  const fieldsToDisplay = (service.fieldsArr || []).map((f) => addFieldRow(f));

  return {
    template: 'block-section-page',

    fields: {
      id: 'edit-service-details',
      header: strings.SERVICE_EDIT_HEADER(),

      navigation_left: strings.BACK(),
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'back',

      navigation_right: strings.SAVE(),
      navigation_right_validation: 'updateServiceDetails',
      navigation_right_action: 'updateServiceDetails',
      navbar: false,
    },

    rows: [
      {
        template: 'block-section-row',
        fields: {},
        columns: [
          {
            template: 'block-login-avatar',
            fields: {
              avatar: service.icon || config.app.DEFAULT_SERVICE_ICON,
              context: window.cordova ? 'addServiceImageCordova' : 'addServiceImage',
              data_id: service.id,
            },
          },
          {
            template: 'block-editable-primary-text',
            fields: {
              primary_text: service.name,
              name: 'edit-service-name',
              for: 'edit-service-name',
              id: 'edit-service-name',
              value: service.name,
              maxlength: SERVIVCE_NAME_MAXLENGTH,
              action: 'keyupServiceDetails'
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          secondary_heading: config.strings.SERVICE_USERNAME_LABEL(),
        },
        columns: [
          {
            template: 'block-editable-primary-text',
            fields: {
              primary_text: service.username,
              name: 'edit-service-username',
              for: 'edit-service-username',
              id: 'edit-service-username',
              value: service.username,
              placeholder: config.strings.SERVICE_EDIT_USERNAME_PLACEHOLDER(),
              maxlength: USERNAME_NAME_MAXLENGTH,
              action: 'keyupServiceDetails'
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          secondary_heading: config.strings.SERVICE_USERNAME_SECOND_LABEL(),
        },
        columns: [
          {
            template: 'block-editable-primary-text',
            fields: {
              primary_text: service.username_secondary,
              name: 'edit-service-username-secondary',
              for: 'edit-service-username-secondary',
              id: 'edit-service-username-secondary',
              value: service.username_secondary,
              placeholder: config.strings.SERVICE_EDIT_USERNAME_PLACEHOLDER(),
              maxlength: USERNAME_NAME_MAXLENGTH,
              action: 'keyupServiceDetails'
            },
          },
        ],
      },

      {
        template: 'block-section-row',
        fields: {
          secondary_heading: config.strings.SERVICE_WEBSITE_LABEL(),
        },
        columns: [
          {
            template: 'block-editable-primary-text',
            fields: {
              primary_text: service.website,
              name: 'edit-service-website',
              for: 'edit-service-website',
              id: 'edit-service-website',
              value: service.website,
              placeholder: config.strings.SERVICE_EDIT_WEBSITE_PLACEHOLDER(),
              maxlength: WEBSITE_URL_MAXLENGTH,
              action: 'keyupServiceDetails'
            },
          },
        ],
      },

      ...fieldsToDisplay,
      {
        template: 'block-section-row',
        fields: {
          class: 'cta',
          //class2: 'blue',
          context: 'newServiceAddNewField',
        },
        columns: [
          {
            template: 'block-icn-button',
            fields: { icon: 'add' },
          },
          {
            template: 'block-text-group',
            fields: { primary_text: config.strings.SERVICE_ADD_FORGHETTIBLE() },
          },
        ],
      },
      deleteButton,
    ],
  };
};
