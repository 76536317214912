const { strings } = require('../../../config');
const { USERNAME_NAME_MAXLENGTH } = require('../../../config/app');
const z2hTemplates = require('../../app/templates');
const { loadingServiceField, noteField, noteLoading } = require('../../page_helpers/components');
const _ = require('lodash');
const cryptLib = require('@skavinvarnan/cryptlib');
const overlays = require('../../app/overlays');
const JSEncrypt = require('jsencrypt').default;
const jsEncrypt = new JSEncrypt();

const fields = {
  '#renameUsername': {
    displayRow: {
      template: 'block-section-row',
      fields: {
        secondary_heading: strings.USERNAME_OR_EMAIL(),
        class: 'collapsed',
      },
      columns: [
        {
          template: 'block-primary-text',
          fields: {
            text: '%%VALUE%%',
            name: 'renameUsername',
            for: 'renameUsername',
            id: 'renameUsername',
            //icon: 'check-solid',
            //icon_action: 'saveRenameFromViewService',
          },
        },
        // {
        //   template: 'block-icn-button',
        //   fields: { icon: 'edit', action: 'focusInputRow', label: strings.EDIT() },
        // },

        {
          template: 'block-icn-button',
          fields: { icon: 'copy', action: 'copyServiceUsernameToClipboard', label: strings.SERVICE_COPY_ICON_LABEL() },
        },
      ],
    },
    editRow: {
      template: 'block-section-row',
      fields: {
        secondary_heading: strings.USERNAME_OR_EMAIL(),
      },
      columns: [
        {
          template: 'block-input-box',
          fields: {
            class: 'remove:input-box',
            id: 'renameUsername',
            value: '%%VALUE%%',
            name: 'renameUsername',
            for: 'renameUsername',
          },
        },
      ],
    },
  },
  '#renameUsernameSecondary': {
    displayRow: {
      template: 'block-section-row',
      fields: {
        secondary_heading: strings.USERNAME_OR_EMAIL_SECOND(),
        class: 'collapsed',
      },
      columns: [
        {
          template: 'block-primary-text',
          fields: {
            text: '%%VALUE%%',

            name: 'renameUsernameSecondary',
            for: 'renameUsernameSecondary',
            id: 'renameUsernameSecondary',
            class: ['toggleEditablePrimary'],
            //icon: 'check-solid',
            //icon_action: 'saveRenameFromViewService',
          },
        },
        // {
        //   template: 'block-icn-button',
        //   fields: { icon: 'edit', action: 'focusInputRow', label: strings.EDIT() },
        // },
        {
          template: 'block-icn-button',
          fields: {
            icon: 'copy',
            action: 'copyServiceUsernameSecondaryToClipboard',
            label: strings.SERVICE_COPY_ICON_LABEL(),
          },
        },
      ],
    },
    editRow: {
      template: 'block-section-row',
      fields: {
        secondary_heading: strings.USERNAME_OR_EMAIL_SECOND(),
        class: 'collapsed',
      },
      columns: [
        {
          template: 'block-input-box',
          fields: {
            class: 'remove:input-box',
            id: 'renameUsernameSecondary',
            value: '%%VALUE%%',
            name: 'renameUsernameSecondary',
            for: 'renameUsernameSecondary',
          },
        },
      ],
    },
  },
  '#renameWebsite': {
    displayRow: {
      template: 'block-section-row',
      fields: {
        secondary_heading: strings.SERVICE_WEBSITE_LABEL(),
      },
      columns: [
        {
          template: 'block-primary-text',
          fields: {
            name: 'renameWebsite',
            for: 'renameWebsite',
            id: 'renameWebsite',
            text: '%%VALUE%%',
            //icon: 'check-solid',
            //icon_action: 'saveRenameFromViewService',
          },
        },
        // {
        //   template: 'block-icn-button',
        //   fields: { icon: 'edit', action: 'focusInputRow', label: strings.EDIT() },
        // },
        {
          template: 'block-icn-button',
          fields: { icon: 'up', action: 'openWebsite', label: strings.SERVICE_OPEN_ICON_LABEL() },
        },
      ],
    },
    editRow: {
      template: 'block-section-row',
      fields: {
        secondary_heading: strings.SERVICE_WEBSITE_LABEL(),
      },
      columns: [
        {
          template: 'block-input-box',
          fields: {
            class: 'remove:input-box',
            id: 'renameWebsite',
            value: '%%VALUE%%',
            name: 'renameWebsite',
            for: 'renameWebsite',
          },
        },
      ],
    },
  },
};

const serviceFields = {
  displayRow: loadingServiceField,
  editRow: (id, value) => {
    return {
      template: 'block-section-row',
      fields: {
        secondary_heading: strings.FORGHETTIBLE(),
        class: 'service-row',
      },
      columns: [
        {
          template: 'block-input-box',
          fields: {
            class: 'remove:input-box',
            id: id,
            value: value,
            name: id,
            for: id,
          },
        },
      ],
    };
  },
};

const note = {
  displayRow: noteLoading,
  // editRow: (value) => ({
  //   template: 'block-section-row',
  //   fields: {
  //     secondary_heading: strings.NOTE(),
  //     class: 'note-row',
  //   },
  //   columns: [
  //     {
  //       template: 'block-textarea',
  //       fields: {
  //         id: 'note',
  //         value: z2hApp.pageData.service.note || '',
  //       },
  //     },
  //   ],
  // }),
};

const serviceName = {
  editRow: (value) => ({
    template: 'block-section-row',
    fields: {
      secondary_heading: strings.SERVICE_NAME(),
      class: 'service-name-row',
    },
    columns: [
      {
        template: 'block-input-box',
        fields: {
          class: 'remove:input-box',
          id: 'renameServiceName',
          value: value,
          name: 'renameServiceName',
          for: 'renameServiceName',
        },
      },
    ],
  }),
};

module.exports = ({ z2hApp, menuTarget, currentTarget }) => {
  //Current mode
  let page = $('#view-service-onboarding');
  if (page.length <= 0) {
    page = $('#view-service');
  }

  const z2hTemplates = require('../../app/templates');

  const displayMode = page.hasClass('display');

  if (displayMode) {
    $('.avatar-row').removeClass('collapsed');

    $('.row.avatar-group-row').addClass('collapsed');
    $('.note-row').addClass('collapsed')

    const $snr = $('.service-name-row');

    $snr.each((index, field) => {
      const $field = $(field);

      const value = $field
        .find('#renameService')
        .text()
        .replace(/\r?\n|\r/g, '')
        .trim();
      
      $field.replaceWith(z2hApp.constructRow(serviceName.editRow(window.z2hApp.pageData.service.name)));
    });

    for (const field in fields) {
      const toConvert = $(field);

      const value = toConvert
        .text()
        .replace(/\r?\n|\r/g, '')
        .trim();

      const str = JSON.stringify(fields[field][displayMode ? 'editRow' : 'displayRow']);
      const parsed = JSON.parse(str.replace(/%%VALUE%%/g, value));

      const block = z2hApp.constructRow(parsed);

      let $row = toConvert.closest('.row');
      block.removeClass('collapsed');
      $row.replaceWith(block);
    }

    //get all the service fields
    const $sf = $('.service-row');
    $sf.each((index, field) => {
      const $field = $(field);
      $field.replaceWith(
        z2hApp.constructRow(serviceFields.editRow($field.attr('id'), $field.find('.secondary-heading').text())),
      );
    });

    // const $nr = $('.note-row');
    // $nr.each((index, field) => {
    //   const $field = $(field);
    //   $field.replaceWith(z2hApp.constructRow(note.editRow($field.attr('id'))));
    // });
  } else {
    const saving = $(currentTarget).hasClass('nav-right');

    if (saving) {
      const service = window.z2hApp.pageData.service;

      let serviceToSend = {};
      let send = false;
      if (service) {
        if (service.name !== $('#renameServiceName').val() && $('#renameServiceName').val() !== '') {
          serviceToSend.name = $('#renameServiceName').val();
          send = true;
          service.name = serviceToSend.name;
        }

        if (service.username !== $('#renameUsername').val()) {
          serviceToSend.username = $('#renameUsername').val();
          send = true;
          service.username = serviceToSend.username;
        }

        if (service.username_secondary !== $('#renameUsernameSecondary').val()) {
          serviceToSend.username_secondary = $('#renameUsernameSecondary').val();
          send = true;
          service.username_secondary = serviceToSend.username_secondary;
        }

        if (service.website !== $('#renameWebsite').val()) {
          serviceToSend.website = $('#renameWebsite').val();
          send = true;
          service.website = serviceToSend.website;
        }

        overlays.makePaneLookBusy('#pane-2', { text : strings.LOCHY_LOADING_TEXT_UPDATE_SERVICE()});
        if (send) {
          z2hApp.requestData('POST', '', 'services/' + service.id, serviceToSend).then((response) => {
            // If response has an error, throw it, to be caught by a future .catch block
            if (Math.floor(response.status / 100) !== 2) {
              throw new Error(
                response.user_message ||
                  response.developer_message ||
                  response.message ||
                  `An error occurred! Sorry about that.`,
              );
            }
            return response;
          });
        }
        //get all the service fields
        const $sf = $('.service-row');
        $sf.each((index, field) => {
          const $field = $(field);

          const value = $field.find('.input-box').val();
          const id = $field.find('.input-box').attr('id');

          if (service.fields[id].name !== value) {
            const fieldData = _.cloneDeep(z2hApp.pageData.service.fields[id]);

            if (fieldData) {
              fieldData.name = value;
              delete fieldData.access_key;
              delete fieldData.old_access_key;
              delete fieldData.recovery_timestamps;
            }
            z2hApp.requestData('post', '', 'fields/' + id, fieldData);
            service.fields[id].name = value;
          }
        });

      //   const $nr = $('.note-row');
      //   $nr.each((index, field) => {
      //     const $field = $(field);
      //     const value = $field.find('.input-box').val();
      //     const id = $field.find('.input-box').attr('id');

      //     if ((!service.note || service.note.trim() === '') && value.trim() === '') {
      //       return;
      //     }
      //     if (service.note && service.note.trim() === '' && value && value.trim() === '') {
      //       return;
      //     }

      //     service.note = value;
      //     let hasNote = service.hasNote ? true : value !== '';

      //     const request = { service_id: service.id, shape: service.doodle || ` `, note: value.trim() };

      //     const aesKeyAsString = window.crypto.getRandomValues(new Uint32Array(10)).join();

      //     const requestStr = JSON.stringify(request);
      //     const aesEncryptedRequest = cryptLib.encryptPlainTextWithRandomIV(requestStr, aesKeyAsString);

      //     const rsaPublicKey = localStorage.getItem('pubkey') || window.state.launchData.encryption_data.public_key;

      //     jsEncrypt.setPublicKey(rsaPublicKey);
      //     const rsaEncryptedAesKey = jsEncrypt.encrypt(aesKeyAsString);

      //     const apiRequest = {
      //       data: aesEncryptedRequest,
      //       key: rsaEncryptedAesKey,
      //       encryption_version: 1,
      //       encrypted: true,
      //     };

      //     window.z2hApp.pageData.notesUpdate = z2hApp.requestData(
      //       'post',
      //       '',
      //       'services/' + service.id + '/update-note',
      //       apiRequest,
      //     );

      //     service.hasNote = z2hApp.requestData(`get`, ``, `services/${service.id}/has-note`);
      //   });
       }

      overlays.makePaneLookIdle('#pane-2');

      //get the service
    } else {
    }

    $('.avatar-row').addClass('collapsed');

    $('.row.avatar-group-row').removeClass('collapsed');
    $('.note-row').removeClass('collapsed')

    const $snr = $('.service-name-row');
    $snr.each((index, field) => {
      const $field = $(field);

      const value = $field.find('#renameServiceName').val();

      $field.addClass('collapsed');
    });

    window.z2hApp.pageData.reloadServiceInfo(window.z2hApp.pageData.service.id);

    for (const field in fields) {
      const toConvert = $(field);

      const value = toConvert
        .val()
        .replace(/\r?\n|\r/g, '')
        .trim();

      const str = JSON.stringify(fields[field][displayMode ? 'editRow' : 'displayRow']);
      const parsed = JSON.parse(str.replace(/%%VALUE%%/g, value));

      const block = z2hApp.constructRow(parsed);

      let $row = toConvert.closest('.row');
      if (value !== '') {
        block.removeClass('collapsed');
      }
      $row.replaceWith(block);
    }

    const $sf = $('.service-row');
    $sf.each((index, field) => {
      const $field = $(field);
      $field.replaceWith(
        z2hApp.constructRow(
          serviceFields.displayRow($field.find('.input-box').val(), '', $field.find('.input-box').attr('id')),
        ),
      );
    });

    window.z2hApp.pageData.reloadAccesskeys(window.z2hApp.pageData.service.id, window.z2hApp.pageData.service.doodle);

    // const $nr = $('.note-row');
    // $nr.each((index, field) => {
    //   const $field = $(field);
    //   $field.replaceWith(
    //     z2hApp.constructRow(note.displayRow($field.find('.input-box').val(), '', $field.find('.input-box').attr('id'))),
    //   );
    // });

    window.z2hApp.pageData.reloadNotes(window.z2hApp.pageData.service.id, window.z2hApp.pageData.service.doodle);
  }

  // Check if service exists in another group
  const groupsList = window.state.groupsList;
  for (let i = 0; i < groupsList.length; i++) {
    const groupId = groupsList[i].id;
    const services = groupsList[i].services || [];
    if (services.find && services.find((s) => s.id === window.z2hApp.pageData.service.id)) {
      window.state.currentService = services.find((s) => s.id === window.z2hApp.pageData.service.id);
      window.state.selectedGroupId = groupId;
      window.state.currentServices = services;
    }
  }

  page.removeClass(displayMode ? 'display' : 'edit').addClass(!displayMode ? 'display' : 'edit');

  if (displayMode) {

    page.find('[data-context="newServiceAddNewField"]').hide()
    page.find('.reset-password-row').addClass('collapsed')

    //Left button needs to go to cancel
    let button = page.find('.nav-left');
    button.text(strings.CANCEL());
    button.removeAttr('data-template', 'back');
    button.removeAttr('data-nav', '0');
    button.removeAttr('data-nav-pane', '1');
    button.attr('data-actionclick', 'changeViewServiceMode');

    //right button need to go save
    button = page.find('.nav-right');
    button.text(strings.SAVE());
    button.removeAttr('data-template');
    button.removeAttr('data-nav');
    button.removeAttr('data-nav-pane');
    button.attr('data-actionclick', 'changeViewServiceMode');
  } else {

    setTimeout(() => {
      $('.nav-left').hide()}
    , 100);
    setTimeout(() => {
      $('.nav-left').show()}
    , 1500);
    
    page.find('[data-context="newServiceAddNewField"]').show();
    page.find('.reset-password-row').removeClass('collapsed');
    //
    let button = page.find('.nav-left');
    button.text(strings.EDIT());
    button.removeAttr('data-template');
    button.removeAttr('data-nav');
    button.removeAttr('data-nav-pane');
    button.attr('data-actionclick', 'changeViewServiceMode');

    button = page.find('.nav-right');
    button.text(strings.DONE());
    button.attr('data-template', 'back');
    button.attr('data-nav', '0');
    button.attr('data-nav-pane', '1');
    button.removeAttr('data-actionclick');
  }


};
