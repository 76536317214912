const errorManager = require('../utils/errorManager');
const z2hApp = window.z2hApp;

const config = require('../../config');
const strings = config.strings;

// This validator is probably not necessary as profile name can be blank?
module.exports = (section) => {
  errorManager.clearErrors(section);
  console.log('Called editServiceField.js');

  let error = null;
  const fieldDetails = window.z2hApp.pageData.service.fieldsUnsaved[z2hApp.active.pageId] ||  window.z2hApp.pageData.service.fieldsSaved[z2hApp.active.pageId] ;

  // Check if the field exists in our list of unsaved fields. It may have just recently been removed.
  if (!fieldDetails) return;

  const generation_settings = fieldDetails.generation_settings;
  const words = generation_settings.words;
  const lettersNumbersSymbols = generation_settings.length;

  const activeTypes = $('[data-actionclick="selectLetterNumberSymbolWord"].active');
  if (activeTypes.length === 0) {
    error = true;
    errorManager.reportError(strings.SERVICE_FIELD_PASSWORD_NO_OPTIONS());
  }
  if (!error) {
    activeTypes.each((index, element) => {
      if (error) return;
      const fieldType = $(element).attr('value');
      if (fieldType === 'WORDS') {
        if (isNaN(words) || words < 1 || words > config.app.MAX_FIELD_WORDS_COUNT) {
          error = true;
          errorManager.reportError(strings.ERROR_INVALID_NUM_WORDS(), $('#wordsCustomLength'));
        }
      } else {
        if (
          isNaN(lettersNumbersSymbols) ||
          lettersNumbersSymbols < 1 ||
          lettersNumbersSymbols > config.app.MAX_PASSWORD_LENGTH
        ) {
          error = true;
          errorManager.reportError(strings.ERROR_INVALID_NUM_LETTERS(), $('#letterNumberSymbolCustomLength'));
        }
      }

      if (fieldType === 'SYMBOLS') {
        if (z2hApp.symbols === generation_settings.exclude_symbols) {
          error = true;
          errorManager.reportError(strings.SERVICE_FIELD_PASSWORD_NO_SYMBOLS_SELECTED(), $('#symbolSelector'));
        }
      }
    });
  }

  if (error) {
    const valError = new Error('Validation error');
    valError.name = 'FORGHETTI_VAL_ERROR';
    throw valError;
  }
};
