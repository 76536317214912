const { strings } = require('../../../config')
const lochyLoadingTemplate = require('./lochyLoadingTemplate');

module.exports = () => ({
  ...(lochyLoadingTemplate({
    id: 'deletingGroup_loading',
    heading: strings.DELETING_GROUP(),
    lochy: 'crying',
  }
  ))
})