module.exports = {
  onboarding_welcome: require('./onboarding_welcome'),
  onboarding_createdoodle: require('./onboarding_createdoodle'),
  onboarding_addservice: require('./onboarding_addservice'),
  onboarding_resetpassword: require('./onboarding_resetpassword'),
  onboarding_doodletutorial1: require('./onboarding_doodletutorial1'),
  onboarding_doodletutorial2: require('./onboarding_doodletutorial2'),
  onboarding_doodletutorial3: require('./onboarding_doodletutorial3'),
  onboarding_doodletutorial4: require('./onboarding_doodletutorial4'),
  onboarding_doodletutorial5: require('./onboarding_doodletutorial5'),
  onboarding_doodletutorial6: require('./onboarding_doodletutorial6'),
  onboarding_doodletutorial7: require('./onboarding_doodletutorial7'),
  onboarding_doodletutorial8: require('./onboarding_doodletutorial8'),
  onboarding_stage1: require('./onboarding_stage1'),
  onboarding_stage2: require('./onboarding_stage2'),
  onboarding_hidePage: require('./onboarding_hidePage'),
  onboarding_existingUser: require('./onboarding_existingUser'),
  legacyUserBonus: require('./legacyUserBonus'),
  onboarding_doodle: require('./onboarding_doodle'),
  onboarding_doodle_confirm: require('./onboarding_doodle_confirm'),
  onboarding_keychain: require('./onboarding_keychain'),
};
