const { strings, urls } = require('../../../config');
const { primaryText } = require('../../page_helpers/components');

module.exports = ({ nav }) => {
  setTimeout(_ => $('#healthcheck_primary_username_1').focus(), 300);

  return {
    template: 'block-section-page',
    fields: {
      id: 'healthcheck_usernames',
      active: false,
      navbar: false,
      navigation_left: strings.BACK(),
      navigation_left_data: 0,
      navigation_left_pane: 1,
      navigation_left_template: 'back',
      header: strings.HEALTHCHECK_USERNAMES_HEADER(),
    },
    rows: [
      primaryText(strings.HEALTHCHECK_USERNAMES_INFO_2(), false, false),
      {
        template: 'block-section-row',
        fields: {
          //secondary_heading_html: strings.HEALTHCHECK_USERNAMES_INFO_1,
          //class: 'third-heading',
        },

        columns: [
          {
            template: 'block-search-box',
            fields: {
              placeholder: strings.HEALTHCHECK_USERNAMES_PLACEHOLDER(),
              id: 'healthcheck_primary_username_1',
              for: 'healthcheck_primary_username_1',

              value: '',
              rules: 'email'
            },
          },
          // Tick/submit button
          {
            template: 'block-icn-button',
            fields: {
              //class: 'hidden',
              icon: 'right-solid',
              id: 'username__tick',
              action: 'healthcheck_username',
              navigation_template: 'healthcheck_password',
              navigation_data: nav + 1,
              navigation_validation: 'healthcheck_username',
            },
          },
        ],
      },

      {
        template: 'block-suggested-usernames',

        fields: {
          id: 'healthCheck_suggestedusernames',
          
          usernameAction: 'healthcheck_username',
          usernameTemplate: 'healthcheck_password',
          usernameData: nav + 1,
          rules: 'email',
        },
      },
    ],
  };
};

$('body').on('keyup', '#healthcheck_usernames #healthcheck_primary_username_1', e => {
  if (e.key === 'Enter') {
    $('#healthcheck_usernames [data-actionclick=healthcheck_username]').click();
    return;
  }

  //Suggest usernames registers a key up even which we can trigger from here to subset
  //the list of usernames.
  $('[data-id="healthCheck_suggestedusernames"]').trigger('keyup', e.target.value);
});
