const config = require('../../../config');
const strings = config.strings;
const { helpButton } = require('../../page_helpers/components');

module.exports = ({ data, nav }) => {
  return {
    template: 'block-section-page',
    fields: {
      id: 'create-group-invite',
      active: false,
      header: config.strings.INVITE_LINK_HEADING(),

      navigation_left: config.strings.BACK(),
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'back',

      navigation_right: strings.GRP_INVITE_CREATE_NAV(),
      navigation_right_icon: '',
      navigation_right_data: Math.max(nav - 1, 0),
      navigation_right_template: 'viewGroupDetails',
      navigation_right_validation: 'createGroupInvite',
    },
    rows: [
      {
        template: 'block-section-row',
        fields: {
          secondary_heading: config.strings.INVITE_SETTINGS_HEADING(),
        },
      },
      {
        template: 'block-section-row',
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_text: config.strings.LINK_EXPIRY_LABEL() },
          },
          {
            template: 'block-text-button',
            fields: {
              text: data.linkExpiryText,
              context: 'linkExpiry',
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_text: config.strings.MAX_USES_LABEL() },
          },
          {
            template: 'block-text-button',
            fields: {
              text: data.maxUsesText,
              context: 'maxUses',
            },
          },
          helpButton('HELP_GROUP_INVITE_MAX_USES'),
        ],
      },
      {
        template: 'block-section-row',
        columns: [
          {
            template: 'block-text-group',
            fields: {
              secondary_text: config.strings.GRP_INVITE_HELP_TEXT(),
            },
          },
        ],
      },
    ],
  };
};
