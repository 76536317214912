const config = require('../../../config');

module.exports = ({ currentTarget }) => {
  //console.log($(currentTarget));
  const groupsList = window.state.groupsList;
  const selectedId = $(currentTarget).attr('data-id');

  window.state.selectedGroupId = selectedId;

  const group = groupsList.find(g => g.id === selectedId);
  if (!group) return;

  // Update the selected group on-screen in the noLoginFound page
  const row = $('#no-login-found .row[data-template=extension_chooseGroup]');

  row.find('.primary-text-content').text(group.name);
  row.find('img').attr('data-id', group.id);
  row.find('img').attr('src', group.icon || config.app.DEFAULT_GROUP_ICON);
};
