const copyToClipboard = require('copy-html-to-clipboard');
const showToastMessage = require('../../action_helpers/showToastMessage');
const config = require('../../../config');

module.exports = ({ currentTarget }) => {
  const inviteCode = window.state.selectedInviteId;

  if (inviteCode) {
    const inviteUrl = config.app.FORGHETTI_ROOT_URL + '?invite=' + inviteCode;
    // const message = config.strings.GRP_INVITE_CLIPBOARD_MESSAGE(inviteCode);
    const message = `I invite you to join my forghetti group. Go to ${inviteUrl} to start sharing.`;

    if (window.cordova && window.plugins.socialsharing) {
      window.plugins.socialsharing.shareWithOptions({ message, url: inviteUrl});
      return;
    }
    else  if (navigator.share) {
      navigator.share({
        text: message,
      });
    } else {
      copyToClipboard(inviteUrl);
      showToastMessage(config.strings.COPY_INVITE_SUCCESS());
    }
  }
};
