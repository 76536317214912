module.exports = _ => {
  return Promise.resolve({
    exclude: '',
    length: 16,
    lowercase: true,
    numbers: true,
    symbols: false,
    simple_symbols: true,
    uppercase: true,
    words: 0,
  });

  /**
   * For now we are ignoring the deafult generation settings that come from the
   * users/@me?fields=default_generation API. Rollback this file to earlier commits to get that
   * back.
   */
};
