const getFullServiceDetails = require('../getPageData_helpers/getFullServiceDetails');
const INITIAL_DELAY_MS = 1000;
const RETRY_IN_MS = 500;

/// TODO rewrite with async/await

module.exports = function createLogin(serviceData, fieldsArray, groupId, icon, successCallback, failureCallback) {
  const z2hApp = window.z2hApp;

  return z2hApp
    .requestData('post', '', 'groups/' + groupId + '/new-service', serviceData)
    .then(response => {
      const newServiceId = response.status === 200 ? response.data.id : null;
      window.state.newServiceId = newServiceId;
      return newServiceId;
  })
    .then(newServiceId => {
      if (icon) {
        const data = { image: icon };
        // Upload service icon in background/asynchronously
        z2hApp.requestData('post', '', `services/${newServiceId}/update-image`, data).then(response => {
          if (response.status > 299) {
            console.error('New service icon upload failed:', response);
          }
        });
      }
      return newServiceId;
    })
    .then(newServiceId => {
      // Creating service was successful... Async loop through fields now
      const finalLoopIteration = fieldsArray.length;
      const currentLoopIteration = 0;

      setTimeout(_ => checkServiceAdded(z2hApp, successCallback, failureCallback), INITIAL_DELAY_MS);

      // Add other fields to new service
      return newServiceAsyncLoop(newServiceId, fieldsArray, finalLoopIteration, z2hApp, currentLoopIteration);
    });
};

// =================================================================================================

const checkServiceAdded = (z2hApp, successCallback, failureCallback) => {
  const newServiceStatus = window.state.newServiceStatus;
  if (newServiceStatus === 'pending') {
    // Check again after short period of time
    setTimeout(_ => checkServiceAdded(z2hApp), RETRY_IN_MS);
  } else if (newServiceStatus === 'success') {
    // Set parameter for viewService page
    window.state.selectedServiceId = window.state.newServiceId;
    // Navigate to viewService page
    getFullServiceDetails().then(serviceDetails => {
      successCallback(serviceDetails);
    });
  } else {
    // Error occurred
    failureCallback();
  }
};

// =================================================================================================

const newServiceAsyncLoop = (newServiceId, fieldsArray, finalLoopIteration, z2hApp, currentLoopIteration) => {
  if (currentLoopIteration == finalLoopIteration) {
    // Loop finished
    window.state.newServiceStatus = 'success';
    return;
  } else if (!fieldsArray[currentLoopIteration].generation_settings) {
    currentLoopIteration++;
    return newServiceAsyncLoop(newServiceId, fieldsArray, finalLoopIteration, z2hApp, currentLoopIteration);
  } else {
    // Next loop
    var currentFieldData = fieldsArray[currentLoopIteration];
    currentFieldData.generation_settings.version = z2hApp.apiConfig.version;

    const endpoint = 'services/' + newServiceId + '/new-field';

    delete currentFieldData.generation_settings.collapsed;
    delete currentFieldData.generation_settings.type;

    const data = JSON.stringify(currentFieldData);

    return z2hApp
      .requestData('post', '', endpoint, data)
      .then(response => {
        if (response.status !== 200) {
          window.state.newServiceStatus = 'failed';
          return;
        }
        currentLoopIteration++;
        return newServiceAsyncLoop(newServiceId, fieldsArray, finalLoopIteration, z2hApp, currentLoopIteration);
      })
      .catch(err => {
        window.state.newServiceStatus = 'failed';
      });
  }
};
