const errorManager = require('../utils/errorManager');
const strings = require('../../config').strings;
const { SERVIVCE_NAME_MAXLENGTH, USERNAME_NAME_MAXLENGTH, WEBSITE_URL_MAXLENGTH } = require('../../config/app')
module.exports = (section, pageData) => {
  errorManager.clearErrors(section);

  const nameFld = section.find('[data-actionkeyup=newServiceName]');
  const name = nameFld.val();
  const usernameFld = section.find('[data-actionkeyup=newServiceEmailOrUsername]');
  const username = usernameFld.val();
  const webAddressFld = section.find('[data-actionkeyup=newServiceWebAddress]');
  const webAddress = webAddressFld.val();

  const addForghettibleButton = section
    .find('[data-context=newServiceAddNewField]')
    .find('.primary-text-content');

  let error = false;
  if (name.length < 2 || name.length > SERVIVCE_NAME_MAXLENGTH) {
    error = true;
    errorManager.reportError(strings.SERVICE_NAME_LENGTH_INVALID(), nameFld);
  }
  if (username.length > USERNAME_NAME_MAXLENGTH) {
    error = true;
    errorManager.reportError(strings.SERVICE_USERNAME_LENGTH_INVALID(), usernameFld);
  }
  if (webAddress.length > WEBSITE_URL_MAXLENGTH) {
    error = true;
    errorManager.reportError(strings.SERVICE_ADDRESS_LENGTH_INVALID(), webAddressFld);
  }

  const fields = z2hApp.pageData.service.fieldsArray;
  if (fields.length === 0) {
    error = true;
    errorManager.reportError(strings.SERVICE_NO_FORGHETTIBLES(), addForghettibleButton);
  }

  if (error) {
    throw new Error('Validation error');
  }
};
