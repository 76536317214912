const overlays = require('../../app/overlays');
const z2hApp = require('../../app/z2hApp');
const cryptLib = require('@skavinvarnan/cryptlib');
const JSEncrypt = require('jsencrypt').default;
const jsEncrypt = new JSEncrypt();
const strings = require('../../../config/strings');

module.exports = (params) => {
  const z2hApp = window.z2hApp;

  const DOMTarget = $('#noteTextArea');

  const val = DOMTarget.val();

  overlays.makePaneLookBusy(2, { text: strings.SAVING_NOTE() })
  // We need to make a request to forghetti for the passwords/access keys for this service.
  // The raw request is as follows:
  const request = { service_id: z2hApp.pageData.service.id, shape: z2hApp.pageData.service.doodle || ` `, note: val };

  //update the last used timestamp on the service.

  //z2hApp.showServices(services, window.state.selectedGroupId, true);
  //z2hApp.highlightNavElement($('#main-nav').find('[data-id=' + window.state.selectedGroupId + ']'));

  // We need to generate an AES key to encrypt the data we are sending to the server
  const aesKeyAsString = window.crypto.getRandomValues(new Uint32Array(10)).join();

  // Encrypt the raw request (containing the doodle) with AES
  const requestStr = JSON.stringify(request);
  const aesEncryptedRequest = cryptLib.encryptPlainTextWithRandomIV(requestStr, aesKeyAsString);

  // Encrypt our AES key using the server's public RSA key
  const rsaPublicKey = localStorage.getItem('pubkey') || window.state.launchData.encryption_data.public_key;

  jsEncrypt.setPublicKey(rsaPublicKey);
  const rsaEncryptedAesKey = jsEncrypt.encrypt(aesKeyAsString);

  const apiRequest = {
    data: aesEncryptedRequest,
    key: rsaEncryptedAesKey,
    encryption_version: 1,
    encrypted: true,
  };

  z2hApp
    .requestData('post', '', 'services/' + z2hApp.pageData.service.id + '/update-note', apiRequest)
    .then((response) => {
      // if (response.status <= 299) {
      //   const dataString = cryptLib.decryptCipherTextWithRandomIV(response.data, aesKeyAsString);
      //   const data = JSON.parse(dataString);
      //   return data;
      // } else {
      //   throw response;
      // }
      overlays.makePaneLookIdle(2);
      console.log('response', response);

  z2hApp.pageData.postNoteUpdateAction(val, 'save');
    });
  delete window.serviceHasNotePromise[z2hApp.pageData.service.id];
};
