const config = require('../../../config');
const fb = require('../../app/firebaseUtils');

const strings = config.strings;

module.exports = ({ nav }) => {
  const user = window.state.userData;
  const title = z2hApp.pageData.service.serviceData.name;

  const groupId = window.state.selectedGroupId;
  const group = window.state.groupsList.find(g => g.id === groupId);
  const avatar = group.personal
    ? user.avatar || config.app.DEFAULT_USER_ICON
    : group.icon || config.app.DEFAULT_GROUP_ICON;

  const avatar_id = group.personal ? fb.getUid() : group.id;

  const serviceIcon = window.state.temporaryServiceIcon || config.app.DEFAULT_SERVICE_ICON;

  return {
    template: 'block-section-page',
    fields: {
      id: 'doodle-pad',
      active: false,
      header: title,
      navbar: false,
      help_id: 'HELP_DOODLEPAD',

      navigation_left: strings.BACK(),
      navigation_left_icon: '',
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'back',
    },
    rows: [
      {
        template: 'block-avatar-group',
        fields: {
          primary_heading: config.strings.SERVICE_DOODLEPAD_SUBHEADING(),
          avatar_id_1: 'temporaryServiceIcon',
          avatar_src_1: serviceIcon,
          avatar_src_2: avatar,
          avatar_id_2: avatar_id,
          class: 'no-border',
        },
      },
      {
        template: 'block-section-row',
        fields: { class: 'no-border' },
        columns: [{ template: 'block-doodlepad' }],
      },
      {
        template: 'block-doodlepad-buttons',
        fields: {
          confirm_data: String(nav + 1),
          confirm_template: 'addNewService3_connecting',
          confirm_validation: 'validateDoodlepad',
          confirm_action: 'addNewService',
        },
      },
    ],
  };
};
