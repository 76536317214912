const strings = require('../../../config').strings;
const { infoTextRow, ctaButton } = require('../../page_helpers/components');

module.exports = ({ nav }) => {
  const userData = (window.state && window.state.userData) || {};
  const currentReferralCode = userData.referral_used || '';
  !currentReferralCode &&
    setTimeout(_ => {
      $('#referral-code-edit-field').focus();
    }, 500);

  const rows = [];
  if (!currentReferralCode) {
    rows.push(infoTextRow(strings.PROFILE_REFERRAL_CODE_TEXT(), true, false));
    rows.push({
      template: 'block-section-row',
      fields: {
        secondary_heading: strings.PROFILE_REFERRAL_CODE_LABEL(),
        class: 'no-border',
      },
      columns: [{
        template: 'block-editable-primary-text',
        fields: {
          id: 'referral-code-edit-field',
          for: 'referral-code-edit-field',
          name: 'referral-code-edit-field',
          value: currentReferralCode,
          primary_text: currentReferralCode,
          placeholder: strings.PROFILE_REFERRAL_CODE_PLACEHOLDER(),
        }
      }],
    })
    rows.push(ctaButton(strings.SAVE(), 'updateReferralCode'));

  } else {
    rows.push(infoTextRow(strings.REFERRAL_CODE_APPLIED()(currentReferralCode), true, false, true));
  }

  return {
    template: 'block-section-page',
    fields: {
      id: 'referral-code',
      active: false,
      header: strings.PROFILE_REFERRAL_CODE_HEADING(),

      navigation_left: strings.BACK(),
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'back',
    },
    rows: rows
  };
};


