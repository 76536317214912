const showToastMessage = require('../../action_helpers/showToastMessage');
const config = require('../../../config');

module.exports = ({ z2hApp }) => {
  const groupId = z2hApp.currentGroup().id;
  const conf = confirm(config.strings.DELETE_GROUP_CONFIRM());
  if (conf) {
    z2hApp.removeGroup(groupId);
    z2hApp.requestData('post', '', `groups/${groupId}/leave`).then(() => {
      z2hApp.paneNavigation('groupsList', $('#pane-1'));
      showToastMessage(config.strings.LEAVE_GROUP_MESSAGE());
    });
  }
};
