const { strings } = require('../../../config');

module.exports = ({ nav, data }) => {
  const field = data;

  window.state.currentFieldId = field.id;

  let timestamps = Object.keys(field.recovery_timestamps || {}).map((s) => parseInt(s));
  let currentTimestamp = field.timestamp;
  timestamps.push(currentTimestamp);
  // Sort in descending time sequence
  timestamps.sort((a, b) => b - a);

  const row = (timestamp) => ({
    template: 'block-section-row',
    fields: {
      action: 'markFieldToBeRestored',
      navigation_data_id: timestamp,
    },
    columns: [
      {
        template: 'block-text-group',
        fields: {
          primary_text: new Date(timestamp).toLocaleString(),
          // If this is the current version of this field, label it
          secondary_text: timestamp === currentTimestamp ? strings.RESTORE_FIELD_CURRENT() : null,
        },
      },
    ],
  });

  // Create a row to display for each timestamp in our array
  const rows = timestamps.map(row);

  return {
    template: 'block-section-page',
    fields: {
      id: 'restore-password',
      active: false,
      header: strings.RESTORE_FIELD_PAGE_HEADER(),
      nabar: false,

      navigation_left: strings.CANCEL(),
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'back',
    },
    rows: [
      {
        template: 'block-section-row',
        columns: [
          {
            template: 'block-text-group',
            fields: {
              primary_text: strings.RESTORE_FIELD_SUBHEADING(),
              secondary_text: strings.RESTORE_FIELD_DESCRIPTION(),
              secondary_class: 'nocolour',
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        columns: [{ template: 'block-text-group', fields: { primary_text: strings.RESTORE_FIELD_VERSION_HISTORY() } }],
      },
      ...rows,
    ],
  };
};
