const showToastMessage = require('../../action_helpers/showToastMessage');
const strings = require('../../../config').strings;

// =============================================================================

function resetField(fieldId, z2hApp) {
  return z2hApp.requestData('post', '', 'fields/' + fieldId + '/reset-timestamp').then((response) => {
    // If response has an error, throw it, to be caught by a future .catch block
    if (Math.floor(response.status / 100) !== 2) {
      throw new Error(
        response.user_message || response.developer_message || response.message || strings.MSG_FALLBACK_ERROR_MESSAGE(),
      );
    }
  });
}
// =============================================================================

module.exports = ({ currentTarget, z2hApp }) => {
  const conf = confirm(strings.MSG_CONFIRM_RESET());
  if (!conf) return;

  const fieldId = $(currentTarget).attr('data-id');

  resetField(fieldId, z2hApp)
    .catch((err) => {
      console.error(err);
      showToastMessage(err.message);
    })
    .then((_) => {
      // Go back to doodlepad or biometrics
      //z2hApp.paneNavigation('viewService1_doodlepad', $('#pane-2'), 1, null);

      const service = z2hApp.pageData.service;
      window.serviceFieldPromises[service.id] = null;
      if (z2hApp.pageData.postUpdateAction) {
        return z2hApp.pageData.postUpdateAction(fieldId, null);
      }

      const { biometricsOrDoodle } = require('../../actions/services');
      biometricsOrDoodle({
        currentTarget: null,
        z2hApp,

        template: { biometrics: 'viewService1_biometrics', doodlepad: 'viewService1_doodlepad' },
      });
    });
};
