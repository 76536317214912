const sanitize = require('sanitize-html');

const copyToClipboard = require('copy-html-to-clipboard');

const strings = require('../../../config').strings;
const copyPasswordToClipboard = require('./copyPasswordToClipboard');
const showToastMessage = require('../../action_helpers/showToastMessage');
const z2hApp = require('../../app/z2hApp');

module.exports = ({ currentTarget }) => {
  if (!z2hApp.pageData.service.note) return;
  let message = '<strong class="toast--title">' + strings.NOTE_COPIED() + '</strong>';
  copyToClipboard(z2hApp.pageData.service.note);

  const service = window.state.currentService || {};

  window.state.copiedToClipboard = service.id;

  showToastMessage(message, { html: true, timeout: 1500, center: true });
};
