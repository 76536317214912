/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */

const z2hApp = require('../../app/z2hApp');
const _ = require('lodash');

module.exports = () => {

   
  // Get basic service details from our 'currentServices' list
  const serviceId = window.state.selectedServiceId;
  const services = window.state.currentServices;
  const service = services.find((s) => s.id === serviceId);
  window.state.currentService = service;

  //Take this opportunity to clear the copied to clipboard service id
  if (window.state.copiedToClipboard) delete window.state.copiedToClipboard;

  z2hApp.pageData = z2hApp.pageData || {};
  z2hApp.pageData.service = _.cloneDeep(service);

  z2hApp.pageData.service.fieldsSaved = {};
  z2hApp.pageData.service.fieldsUnsaved = {};
  z2hApp.pageData.service.fieldsToBeReset = {};
  z2hApp.pageData.service.fieldsToBeRestored = {};
  delete z2hApp.pageData.service.resetFromContext;

  // Check for a background request which had been made to get the fields for this service
  window.serviceFieldPromises = window.serviceFieldPromises || {};
  window.serviceFieldPromises[serviceId] =
    window.serviceFieldPromises[serviceId] || z2hApp.requestData(`get`, '', `services/${serviceId}/fields`);

  return window.serviceFieldPromises[serviceId].then((response) => {
    const fields = [];
    for (const fieldId in response.data) {
      fields.push({ ...response.data[fieldId], id: fieldId });
    }

    z2hApp.pageData.service.fields = response.data;
    z2hApp.pageData.service.fieldsArr = fields;
    // Array of field IDs
    z2hApp.pageData.service.fieldsArray = fields.map((f) => f.id);
    // Array of fields before any are deleted/added
    z2hApp.pageData.service.origFieldsArray = fields.map((f) => f.id);

    fields.forEach((f) => {
      const field = { ...f };
      if (field.generation_settings) {
        for (const s in field.generation_settings) {
          delete field[s];
        }
      }
      // Important... clone the fields from this service to ensure we don't
      // overwrite values in the window.state.currentService
      z2hApp.pageData.service.fieldsSaved[field.id] = field;
    });

    return z2hApp.pageData.service;
  });
};
