const showToastMessage = require('../../action_helpers/showToastMessage');
const fb = require('../../app/firebaseUtils');

/// TODO: I think some of this is very similar to code for migrating your account, so we
/// could deduplicate some of it.

module.exports = ({ section }) => {
  const pane = '#pane-2';
  $(pane).addClass('busy');

  return Promise.resolve()
    .then(() => {
      //const phoneNumber = firebase.auth().currentUser.phoneNumber;

      const phoneNumber = window.state.phoneNumber || fb.getCurrentUser().phoneNumber;
      console.info('Authenticating with phone number...', phoneNumber);

      const appVerifier = window.recaptchaVerifier;
      return fb.signInWithPhoneNumber(phoneNumber, appVerifier);
    })

    .then((confirmationResult) => {
      console.info('signInWithPhoneNumber: success.');

      $(pane).removeClass('busy');

      const activePaneIndex = $(pane + '-inner')
        .children('.active')
        .index();

      if (window.creds.instantVerification) {
        const actions = require('../../actions');
       return actions['deleteAccount']({ section, z2hApp })
      }

      z2hApp.paneNavigation('deleteAccountConfirmationCode', $(pane), activePaneIndex + 1);

      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      window.confirmationResult = confirmationResult;
      return confirmationResult;
    })
    .catch((error) => {
      // Error; SMS not sent
      $(pane).removeClass('busy');
      console.error('phoneNumberSignIn: error: ', error);
      showToastMessage(error);
    });
};
