const strings = require('../../../config').strings;

module.exports = ({ nav }) => {
  return {
    template: 'block-section-page',
    fields: {
      id: 'new-group-data-loading-test',
      active: false,
      header: strings.JOIN_GROUP_HEADING(),

      navigation_left: strings.BACK(),
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'back',

      navigation_right: strings.JOIN_GROUP_RIGHT_NAV(),
      navigation_right_action: 'joinGroup',
      navigation_right_template: 'back',
      navigation_right_data: 0,
    },
    rows: [
      {
        template: 'block-section-row',
        fields: {
          secondary_heading: strings.GRP_INVITE_CODE_LABEL(),
        },
        columns: [
          {
            template: 'block-editable-primary-text',
            fields: {
              value: '',
              primary_text: '',
              id: 'join-group-invite-code',
              for: 'join-group-invite-code',
              name: 'join-group-invite-code',
              placeholder: strings.JOIN_GROUP_PLACEHOLDER(),
            },
          },
        ],
      },
    ],
  };
};
