module.exports = ({ nav }) => {
  //const { name } = window.z2hApp.pageData.service.serviceData;
  return {
    template: 'block-section-page',
    fields: {
      id: 'adding-group',
      class: 'onboarding',
      active: false,
      navbar: false,
      navigation_left: 'NO ONBOARDING',
      navigation_left_action: 'hideLogin',
      navigation_right: 'GO TO ONBOARDING',
      navigation_right_template: 'onboarding_stage1',
      navigation_right_data: nav + 1,
    },
    rows: [
      {
        template: 'block-onboarding-image',
        fields: {
          imagePath: 'img/lochy/01-Lochy-Waving.gif',
          class: 'no-border',
        },
        columns: [],
      },
      {
        template: 'block-section-row',
        fields: {
          class: 'centered-text',
        },
        columns: [
          {
            template: 'block-text-button',
            fields: {
              text: 'Existing user onboarding',
              navigation_template: 'onboarding_existingUser',
              navigation_data: 1,
              class: 'cta-button',
            },
          },
        ],
      },
    ],
  };
};
