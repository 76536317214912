const config = require('../../../config');

const onboadingTemplate = require('./onboarding_template');

const { strings } = config;

const { ONBOARDING_DOODLE_HEADING, ONBOARDING_DOODLE_TEXT, ONBOARDING_HOW_IT_WORKS } = strings;

module.exports = ({ nav }) => {
  return onboadingTemplate({
    nav,
    imagePath: 'img/onboarding/onboarding2.png',
    secondary_heading: ONBOARDING_HOW_IT_WORKS(),
    primary_heading: ONBOARDING_DOODLE_HEADING(),
    text: ONBOARDING_DOODLE_TEXT(),
    nextPage: 'onboarding_addservice',
    showSkip: true,
  });
};
