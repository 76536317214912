const config = require('../../../config');

const onboadingTemplate = require('./onboarding_template');
const strings = require('../../../config').strings;

module.exports = ({ nav }) => {
  return onboadingTemplate({
    nav,
    imagePath: 'img/onboarding/onboarding3.png',
    secondary_heading: strings.ONBOARDING_HOW_IT_WORKS(),
    primary_heading: strings.ONBOARDING_ADDSERVICE_HEADING(),
    text: strings.ONBOARDING_ADDSERVICE_TEXT(),
    nextPage: 'onboarding_resetpassword',
    showSkip: true,
  });
};
