//Firebase utils - we made the decision at some point that we would not use the firebase SDK directly in our code, but instead use this wrapper.
//This is because we have a web version and a mobile version, and we are not able to use the same code for both.
/* eslint-disable no-shadow */
import {
  PhoneAuthProvider as FbPhoneAuthProvider,
  RecaptchaVerifier,
  getAuth as fbGetAuth,
  getIdToken as fbGetIdToken,
  onAuthStateChanged as fbOnAuthStateChanged,
  onIdTokenChanged as fbOnIdTokenChanged,
  signInWithPhoneNumber as fbSignInWithPhoneNumber,
  signInWithCredential as fbSignInWithCredential,
  signOut as fbSignOut,
  updatePhoneNumber as fbUpdatePhoneNumber,
  deleteUser as fbDeleteUser,
  reauthenticateWithCredential as fbReauthenticateWithCredential,
  reload,
  //currentUser as fbCurrentUser,
} from 'firebase/auth';
import {
  collection as fbFirestoreCollection,
  doc as fbFirestoreDoc,
  getFirestore as fbGetFirestore,
  onSnapshot as fbOnSnapshot,
  query as fbFirestoreQuery,
  collectionGroup as fbFirestoreCollectionGroup,
  getDocs as fbGetDocs,
  where as fbWhere,
} from 'firebase/firestore';
import { getAnalytics as fbGetAnalytics, logEvent as fbLogEvent } from 'firebase/analytics';
import {
  getToken as fbGetMessageToken,
  getMessaging as fbGetMessaging,
  isSupported as fbIsMessagingSupported,
  onMessage as fbOnMessage,
} from 'firebase/messaging';
import { getApp, getApps, initializeApp } from 'firebase/app';

const config = require('../../config');
const sharedPreferences = require('../utils/sharedPreferences');

// eslint-disable-next-line prefer-const
let { CORDOVA } = process.env;
// eslint-disable-next-line prefer-const
let { cordova } = window;

let currentUser = null;

/* 
If we are not running in a Cordova environment/app, load up the JavaScript Firebase SDK for
web applications 
*/
const onAuthStateChangedCallbacks = [];
let unsubscribeToAuthCallbacks = [];
let savedToken = null;

// Create a promise that will resolve once the device is ready
const deviceReadyPromise = new Promise((resolve) => {
  if (CORDOVA) {
    document.addEventListener('deviceready', (_) => resolve(), false);
  } else {
    resolve();
  }
}).then((_) => console.log('Device ready'));

// =================================================================================================
/* Initialise the Firebase SDK */
async function initialise() {
  console.log('Initialising Firebase utils...');
  // Wait for device to be ready first
  await deviceReadyPromise;
  let auth;
  let firebase;
  // If web application, initialise Firebase Web SDK and reCAPTCHA for phone sign-in
  if (!getApps().length) {
    console.log('Initialising Firebase Web SDK');
    firebase = initializeApp(config.app.FIREBASE);
    window.firebase = firebase;
  } else {
    console.log('Initialising with existing web app');
    firebase = getApp();
  }
  //if (!CORDOVA) {
  auth = fbGetAuth(firebase);
  //}

  if (!CORDOVA && !window.recaptchaVerifier && auth) {
    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptchaContainer',
      {
        size: 'invisible',

        // reCAPTCHA solved, allow signInWithPhoneNumber.
        callback: (_) => console.info('reCAPTCHA solved'),
      },
      fbGetAuth(firebase),
    );
  }

  // Set up an internal onAuthStateChanged event handler
  const authCallback = (user, fromCallback) => {
    // Store current user details

    if (fromCallback && user) {
      console.log("Storing user's details in localStorage", user);
      localStorage.setItem('currentUser', JSON.stringify(user));
    } else if (fromCallback) {
      console.log("CLEARING user's details in localStorage");
      localStorage.clear();
      if (process.env.CORDOVA) {
        if (window.ss) {
          window.ss.clear(
            (value) => {},
            () => {},
          );
        }
      }
    }

    const userChanged = currentUser !== user?.uid || !user;
    currentUser = user?.uid;
    onAuthStateChangedCallbacks.forEach((cb) => cb(user, userChanged, fromCallback));
  };

  const curUser = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : null;

  authCallback(curUser, false);

  if (CORDOVA) {
    console.log('CORDOVA - INITIALISING');

    unsubscribeToAuthCallbacks = FirebasePlugin.registerAuthStateChangeListener(async (signedIn) => {
      let userInfo;
      if (signedIn) {
        FirebasePlugin.getCurrentUser(
          (userInfo) => {
            console.log('onAuthStateChanged', userInfo);
            authCallback(userInfo, true);
          },
          (err) => {
            console.log('onAuthStateChanged', err);
            authCallback(null, true);
          },
        );
      } else {
        userInfo = null;
        authCallback(null, true);
        return userInfo;
      }
    });

    //first launch
    setTimeout(() => {
    FirebasePlugin.isUserSignedIn((signedIn) => {
      if (signedIn) {
        FirebasePlugin.getCurrentUser(
          (userInfo) => {
            console.log('getCurrentUser', userInfo);
            authCallback(userInfo, true);
          },
          (err) => {
            console.log('getCurrentUser', err);
            authCallback(null, true);
          },
        );
      } else {
        authCallback(null, true);
      }
    }) }, 1000);


    FirebasePlugin.setAnalyticsCollectionEnabled(true); // Enables analytics data collection
  } else {
    unsubscribeToAuthCallbacks = fbOnAuthStateChanged(fbGetAuth(firebase), () => {
      if (auth.currentUser) {
        return authCallback(auth.currentUser, true);
      }
      authCallback(null, true);
    });

    fbOnIdTokenChanged(fbGetAuth(firebase), function (user) {
      if (auth.currentUser) {
        return authCallback(auth.currentUser, true);
      }
      authCallback(null, true);
    });

    //const currUser = fbCurrentUser;
    //authCallback(currUser, true);
  }

  //Now handled by calling getIdToken on every request
  //TODO HANDLE COORDOVA
  if (CORDOVA) {
    setOnAuthStateChanged((userInfo) => {
      if (!userInfo || userInfo === null) {
        sharedPreferences.set('firebase-token', '');
      }
    });
  }
  // } else {
  //   fbOnIdTokenChanged(auth, async (user) => {
  //     console.log('onIdTokenChanged', user);

  //     if (user) {
  //       const idToken = await fbGetIdToken(user);
  //       console.log('idTokenPromise', idToken);
  //       localStorage.setItem('token', idToken);
  //     } else {
  //       localStorage.removeItem('token');
  //     }
  //   });
  // }
}

if (!CORDOVA) {
  setOnAuthStateChanged(() => {
    // FCM messaging for the web
    if (fbIsMessagingSupported()) {
      fbOnMessage(fbGetMessaging(), (payload) => {
        // 'Message received. ', payload);


        let title;
        if (typeof payload.title === 'object') {
          title = payload.title['any'];
    
          if (payload.title[localStorage.getItem('language')]) {
            title = payload.title[localStorage.getItem('language')];
          }
        } else {
          title = payload.title;
        }
        
        let body;
        if (typeof payload.body === 'object') {
          body = payload.body['any'];
    
          if (payload.body[localStorage.getItem('language')]) {
            body = payload.body[localStorage.getItem('language')];
          }
          
        } else {
          body = payload.body;
        }
    
        let button;
        if (typeof payload.button === 'object') {
          button = payload.button['any'];
    
          if (payload.button[localStorage.getItem('language')]) {
            button = payload.button[localStorage.getItem('language')];
          }
        } 
        else {
          button = payload.button;
        }
        const inapptodisplay = {
          ...payload.data,
          title: title,
          image_src: payload.image_src,
          body: body,
          action: payload.action,
          data_id: payload.data_id,
          button: button
        };

        localStorage.setItem('inappmessage', JSON.stringify(inapptodisplay));
        // ...
      });
      fbGetMessageToken(fbGetMessaging(), { vapidKey: config.app.FIREBASE_MESSAGING_VAPIDKEY }).then((currentToken) => {
        // TODO: send token to server
        window.state.inappMessages = true;
        console.log('FCM token', currentToken);
      });
    }
        // if (fbIsMessagingSupported()) {
    //   fbOnMessage(fbGetMessaging(), (payload) => {
    //     // 'Message received. ', payload);
    //     localStorage.setItem('inappmessage', JSON.stringify(payload.data));
    //     // ...
    //   });
    //   fbGetMessageToken(fbGetMessaging(), { vapidKey: config.app.FIREBASE_MESSAGING_VAPIDKEY }).then((currentToken) => {
    //     // TODO: send token to server
    //     window.state.inappMessages = true;
    //     console.log('FCM token', currentToken);
    //   });
    // }
  });
}

// =================================================================================================
/* Get details of the current logged in user */
function getCurrentUser() {
  return localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : null;
}

// =================================================================================================
function getIdToken() {
  if (CORDOVA) {
    return new Promise((resolve, reject) => {
      FirebasePlugin.getCurrentUser(
        (user) => {
          sharedPreferences.set('firebase-token', user?.idToken);
          return resolve(user?.idToken ? user?.idToken : null);
        },
        (err) => {
          return reject('No token available');
        },
      );
    });
  }
  if (!fbGetAuth()?.currentUser) {
    return Promise.reject('No token available');
  }

  return fbGetIdToken(fbGetAuth().currentUser);
}

// =================================================================================================
/* Setter for onAuthStateChanged callback */
async function setOnAuthStateChanged(callback) {
  await deviceReadyPromise;
  onAuthStateChangedCallbacks.push(callback);
}

// =================================================================================================
/* Sign out of all Firebase services */
function signOut() {
  localStorage.clear();
  if (CORDOVA) {
    return new Promise((resolve, reject) => {
      if (window.ss) {
        window.ss.clear(
          (value) => {},
          () => {},
        );
      }
      return resolve(FirebasePlugin.signOutUser());
    });
  }
  return fbSignOut(fbGetAuth());
}

function updatePhoneNumber(credential) {
  // TODO
  if (CORDOVA) {
    return new Promise((resolve, reject) => {
      FirebasePlugin.linkUserWithCredential(
        credential,
        function () {
          console.log('Successfully linked');
          resolve(true);
        },
        function (error, secondFactors) {
          console.error('Failed to link', error);
          reject(error);
        },
      );
    });
  }

  return fbUpdatePhoneNumber(fbGetAuth().currentUser, credential);
}
function PhoneAuthProvider() {
  // TODO
  if (CORDOVA) {
  }
  return new FbPhoneAuthProvider(fbGetAuth());
}

// =================================================================================================
/* Get the unique Firebase user identifier (UID) for the current logged in user */
function getUid() {
  const currentUser = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : null;
  return currentUser ? currentUser.uid : null;
}

// =================================================================================================
async function signInWithConfirmationCode(code) {
 

    
    if (CORDOVA) {
      return cordova.plugins.firebase.auth.signInWithVerificationId(window.verificationId, code);
      if (!window.creds.instantVerification) {
      window.creds.code = code;
      }
      const signin = new Promise((resolve, reject) => {
        FirebasePlugin.signInWithCredential(
          window.creds,
          (result) => {
            console.log('logged in successfully');
  
  
            z2hApp.paneNavigation('vault_loading', $('.overlay'), 5);
  
            resolve(result);
          },
          (err) => {
            console.log('error logging in');
            reject(err);
          },
        );
      });
  
      try {
        const signInCreds = await signin;
        return signInCreds;
      } catch (e) {
        console.error(e);
        return null;
      }
    
  }
  // window.confirmationResult should contain the result of firebase.auth().signInWithPhoneNumber
  return window.confirmationResult.confirm(code);
}

// =================================================================================================
async function signInWithPhoneNumber(phoneNumber, appVerifier, forceInstantVerification = false) {
  if (CORDOVA) {
     return cordova.plugins.firebase.auth
        .verifyPhoneNumber(phoneNumber, 30)
    // const getSignInCreds = new Promise((resolve, reject) => {
    // //   // FirebasePlugin.verifyPhoneNumber(
    // //   //   (creds) => {
    // //   //     console.log('Successfully verified phone number: ' + JSON.stringify(creds));
    // //   //     if (creds.instantVerification) {
    // //   //       resolve(creds);
    // //   //     } else {
    // //   //       resolve(creds);
    // //   //     }
    // //   //   },
    // //   //   (err) => {
    // //   //     console.error('Failed to verify phone number: ' + JSON.stringify(err));
    // //   //     reject();
    // //   //   },
    // //   //   phoneNumber,
    // //   //   { timeOutDuration: 0, fakeVerificationCode: '123456' },
    // //   // );
      
    // // });

    // try {
    //   const signInCreds = await getSignInCreds;
    //   window.creds = signInCreds;
    //   // fbSignInWithCredential(fbGetAuth(), signInCreds);
    // } catch (e) {
    //   console.error(e);
    // }
    // return;
  }

 
  const confirmationResult = await fbSignInWithPhoneNumber(fbGetAuth(), phoneNumber, appVerifier);
  window.confirmationResult = confirmationResult;
  window.verificationId = (confirmationResult && confirmationResult.verificationId) || null;
  return window.verificationId;
}

// =================================================================================================

async function analyticsLogEvent(event, params) {
  console.log('Analytics event', event);

  if (CORDOVA) {
    return FirebasePlugin.logEvent(event, params ? params : {});
  }

  return fbLogEvent(fbGetAnalytics(), event, params);
}

function auth() {
  if (CORDOVA) {
    return FirebasePlugin.auth;
  }
  return fbGetAuth(window.firebase);
}

function messaging() {
  if (CORDOVA) {
    return cordova.plugins.firebase.messaging;
  }
  return fbGetMessaging();
}

function analytics() {
  if (CORDOVA) {
    return FirebasePlugin.analytics;
  }
  return fbGetAnalytics();
}

function firestore() {
  if (CORDOVA) {
    return;
  }
  return new Promise((resolve) => {
    resolve(fbGetFirestore());
  });
}

function firestoreCollection(path) {
  if (CORDOVA) {
    return;
  }
  return fbFirestoreCollection(fbGetFirestore(), path);
}
function firestoreDoc(path) {
  if (CORDOVA) {
    return;
  }

  return fbFirestoreDoc(fbGetFirestore(), path);
}

function firestoreDocOnSnapshot(doc, callback) {
  if (CORDOVA) {
    /// return doc.onSnapshot({ callback});
    return;
  }
  return fbOnSnapshot(doc, callback);
}

function firestoreQuery(collection, query, collectionGroup) {
  if (CORDOVA) {
    return;
  }
  let coll = collectionGroup
    ? fbFirestoreCollectionGroup(fbGetFirestore(), collection)
    : fbFirestoreCollection(fbGetFirestore(), collection);
  const docs = fbFirestoreQuery(coll, fbWhere(query.field, query.operator, query.value));
  return fbGetDocs(docs);
}

function firestoreCollectionGroup(collection) {
  if (CORDOVA) {
    return;
  }
  return fbFirestoreCollectionGroup(collection);
}

function firestoreCollectionWhere(collection, field, operator, value) {
  if (CORDOVA) {
    return;
  }
  return fbWhere(collection, field, operator, value);
}

function getDeviceToken() {
  return new Promise((resolve, reject) => {
    if (CORDOVA) {
      FirebasePlugin.getToken(
        (token) => {
          console.log('FCM token', token);
          resolve(token);
        },
        (err) => {
          console.log('FCM token error', err);
          reject(err);
        },
      );
    } else {
      reject('Not supported');
    }
  });
}
function onDeviceTokenRefresh() {
  return new Promise((resolve, reject) => {
    if (CORDOVA) {
      FirebasePlugin.onTokenRefresh(
        (token) => {
          console.log('FCM token', token);
          resolve(token);
        },
        (err) => {
          console.log('FCM token error', err);
          reject(err);
        },
      );
    } else {
      reject('Not supported');
    }
  });
}

const reauthenticateAndRetrieveDataWithCredential = (credential) => {
  if (CORDOVA) {
    return new Promise((resolve, reject) => {
      FirebasePlugin.reauthenticateWithCredential(
        credential,
        function () {
          console.log('Successfully reauthenticated');
          resolve(true);
        },
        function (error, secondFactors) {
          console.log('Failed to reauthenticate');
          reject(error);
        },
      );
    });
  }
  return reauthenticateWithCredential(fbGetAuth().currentUser, credential);
};

const deleteAccount = () => {
  if (CORDOVA) {
    return new Promise((resolve, reject) => {
      FirebasePlugin.deleteUser(
        function () {
          console.log('Successfully deleted user');
          resolve(true);
        },
        function (error) {
          console.log('Failed to delete user');
          reject(error);
        },
      );
    });
  }
  return deleteUser(fbGetAuth().currentUser);
};

const reloadUser = () => {
  if (CORDOVA) {
    return new Promise((resolve, reject) => {
      FirebasePlugin.reloadCurrentUser(
        function (user) {
          console.log('Successfully reloaded user');
          localStorage.setItem('currentUser', JSON.stringify(user));
          resolve(true);
        },
        function (error) {
          console.log('Failed to reload user');
          reject(error);
        },
      );
    });
  }
  return reload(fbGetAuth().currentUser).then((user) => {
    localStorage.setItem('currentUser', JSON.stringify(user));
  });
};

window.getcurrentuser = module.exports = {
  getUid,
  signOut,
  getIdToken,
  initialise,
  getCurrentUser,
  setOnAuthStateChanged,
  signInWithPhoneNumber,
  signInWithConfirmationCode,
  analyticsLogEvent,
  firestore,
  auth,
  messaging,
  analytics,
  firestoreCollection,
  firestoreDoc,
  firestoreDocOnSnapshot,
  updatePhoneNumber,
  PhoneAuthProvider,
  firestoreQuery,
  firestoreCollectionGroup,
  firestoreCollectionWhere,
  getDeviceToken,
  onDeviceTokenRefresh,
  reauthenticateAndRetrieveDataWithCredential,
  deleteAccount,
  reloadUser,
};
