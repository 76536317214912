const z2hApp = require('../../app/z2hApp');

const SHOW_PASSWORD_ICON_CLASSNAME = 'f-icn-show';
const HIDE_PASSWORD_ICON_CLASSNAME = 'f-icn-hide';

module.exports = ({ currentTarget }) => {
  const service = z2hApp.pageData.service || {};
  const fields = service.fields || [];

  const row = $(currentTarget).closest('.row');
  const fieldId = row.attr('id');

  const fieldData = fields.find((f) => f.id === fieldId) || {};
  const password = fieldData.access_key;
  if (!password) return;

  const content = row.find(`['name=primary-text-content]`);

  if (row.hasClass('password-visible')) {
    content.text(
      password
        .split('')
        .map((c) => '●')
        .join(''),
    );

    row
      .find('.' + HIDE_PASSWORD_ICON_CLASSNAME)
      .removeClass(HIDE_PASSWORD_ICON_CLASSNAME)
      .addClass(SHOW_PASSWORD_ICON_CLASSNAME);
    row.removeClass('password-visible');
  } else {
    content.text(password);
    row
      .find('.' + SHOW_PASSWORD_ICON_CLASSNAME)
      .removeClass(SHOW_PASSWORD_ICON_CLASSNAME)
      .addClass(HIDE_PASSWORD_ICON_CLASSNAME);
    row.addClass('password-visible');
  }
};
