module.exports = function parseUrl(url) {
  var parser = document.createElement('a');
  if (url.toLowerCase().substr(0, 4) !== 'http') {
    url = '//' + url;
  }
  parser.href = url;

  // Get parts we want from the anchor element
  const {
    protocol, // => "http:"
    host, // => "example.com:3000"
    hostname, // => "example.com"
    port, // => "3000"
    pathname, // => "/pathname/"
    hash, // => "#hash"
    search, // => "?search=test"
    origin, // => "http://example.com:3000"
  } = parser;

  // return them in an object
  return { protocol, host, hostname, port, pathname, hash, search, origin };
};
