const config = require('../../../config');
const strings = config.strings;
const { GROUP_NAME_MAXLENGTH } = require('../../../config/app');

module.exports = ({ nav, data }) => {
  const group = data;

  const icon = group.personal
    ? window.state.userData.avatar || config.app.DEFAULT_USER_ICON
    : group.icon || config.app.DEFAULT_GROUP_ICON;

  const page = {
    template: 'block-section-page',
    fields: {
      id: 'edit-group-details',
      active: false,
      header: strings.GROUP_EDIT_HEADER(),
      navigation_left: config.strings.CANCEL(),
      navigation_left_icon: '',
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'back',

      navigation_right: strings.SAVE(),
      navigation_right_icon: '',
      navigation_right_action: 'editCurrentGroup',
      navigation_right_data: Math.max(nav - 1, 0),
      navigation_right_template: 'viewGroupDetails',
      navigation_right_validation: 'editGroupDetails',
    },
    rows: [
      {
        template: 'block-section-row',
        columns: [
          {
            template: 'block-login-avatar',
            fields: {
              context: !group.personal ? (window.cordova ? 'addImageCordova' : 'addImageDesktop') : '',
              data_id: group.id,
              avatar: icon,
            },
          },
          {
            template: 'block-editable-primary-text',
            fields: {
              primary_text: group.name,
              value: group.name,
              name: 'group-name',
              for: 'group-name',
              id: 'group-name',
              maxlength: GROUP_NAME_MAXLENGTH,
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        columns: [
          {
            template: 'block-text-group',
            fields: {
              primary_text: strings.GROUP_EDIT_MAX_MEMBERS(),
              secondary_text: strings.GROUP_EDIT_MAX_MEMBERS_SECONDARY(),
            },
          },
          {
            template: 'block-editable-primary-text',
            fields: {
              class: 'align-right',
              class2: 'small-input',
              primary_text: group.maximum_members,
              name: 'max-members',
              for: 'max-members',
              id: 'max-members',
              maxlength: 32,

            },
          },
        ],
      },
    ],
  };

  if (group.personal) {
    page.rows.push({
      template: 'block-section-row',
      fields: { class: 'centered-text' },
      columns: [
        {
          template: 'block-text-group',
          fields: {
            secondary_text: config.strings.PERSONAL_GROUP_MESSAGE(),
          },
        },
      ],
    });
  }

  return page;
};
