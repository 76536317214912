import fb from '../../app/firebaseUtils';
/**
 * Confirm migration code
 */
const showToastMessage = require('../../action_helpers/showToastMessage');

module.exports = ({ section, z2hApp }) => {
  const pane = '#pane-2';

  //const phoneNumber = $('#migration-number').intlTelInput('getNumber');
  const phoneNumber = window.iti.getNumber();

  const activePaneIndex = $(pane + '-inner')
    .children('.active')
    .index();
  $(pane).addClass('busy');

  z2hApp
    .requestData('post', '', 'users/@me/change-number', { new_number: phoneNumber })
    .then(() => {
      fb.reloadUser();
    })
    .then((_) => {
      z2hApp.paneNavigation('migratePhoneNumber3', $(pane), activePaneIndex + 1);
    })
    .catch((error) => {
      $(pane).removeClass('busy');

      // Error; SMS not sent
      if (error.code === 'auth/credential-already-in-use') {
        showToastMessage(`Error: This phone number already has a forghetti account`);
        // Go back
        z2hApp.paneNavigation('migratePhoneNumber1', $(pane), activePaneIndex - 1);
      } else if (error.code === 'auth/code-expired') {
        showToastMessage(`Incorrect verification code. Please try again`);
        // Go back
        z2hApp.paneNavigation('migratePhoneNumber1', $(pane), activePaneIndex - 1);
      } else {
        showToastMessage(error);
      }
    });
};

/*
  firebase.auth().signInWithPhoneNumber('+447759027599', window.recaptchaVerifier).then(confirmationResult => {
  // const verificationCode = prompt()
  const credential = firebase.auth.PhoneAuthProvider.credential(confirmationResult.verificationId, '221116')
  firebase.auth().currentUser.updatePhoneNumber(credential)
  })
*/
