const addNewService = require('../../actions/services/addNewService');
const { strings } = require('../../../config');
const _ = require('lodash');
const serviceTemplate = () => ({
  groupId: '',
  serviceData: {
    name: '',
    username: '',
    website: '',
    icon: '',
  },
  serviceUnsaved: false,
  fieldsArray: [],
  fieldsSaved: {},
  fieldsUnsaved: {},
});

module.exports = ({ section, z2hApp }) => {
  //Before we get started let's sort out some of the screen elements

  section.find('[data-actionclick="importCreatingServices"]').fadeOut();
  section
    .find('.column.info-text')
    .html(strings.IMPORT_SERVICES_CREATE_LOADING()(0, window.state.selectedImportedServices.length));
  const { selectedGroupId } = window.state;
  const selectedGroup = state.groupsList.find((g) => g.id === selectedGroupId) || {};
  const services = window.state.selectedImportedServices;
  window.state.currentImportedServiceIndex = 0;
  for (const s of services) {
    const service = serviceTemplate();
    const { serviceData, fieldsArray, fieldsSaved, fieldsUnsaved } = service;

    service.groupId = selectedGroupId;
    serviceData.name = s.name;
    serviceData.username = s.username;
    serviceData.website = s.address;
    serviceData.icon = s.url;
    z2hApp.pageData.service = _.cloneDeep(service);

    const onSuccess = () => {
      console.log('crated service');
      window.state.currentImportedServiceIndex += 1;
      section
        .find('.column.info-text')
        .html(
          strings.IMPORT_SERVICES_CREATE_LOADING()(
            window.state.currentImportedServiceIndex,
            window.state.selectedImportedServices.length,
          ),
        );
      if (window.state.currentImportedServiceIndex === window.state.selectedImportedServices.length) {
        const paneDetails = window.getActivePaneDetails('#pane-2');
        z2hApp.paneNavigation('importComplete', paneDetails.pane, paneDetails.activePaneIndex + 1);
      }
    };
    const onFailure = () => {};
    addNewService({ z2hApp, onSuccess, onFailure, batch: true });
  }
};
