const { map } = require("jquery");

module.exports = ({ currentTarget, z2hApp, menuTarget }) => {

  const prom = new Promise((resolve, reject) => {

    

    let biometrics;
    if (process.env.CORDOVA) {
      if (window.ss) {
        window.ss.get(
          (value) => {
            console.log('Get doodle');
            return resolve(value);
          },
          () => {
            console.log('Failed to get doodle');
            return resolve(null);
          },
          z2hApp.currentGroup().id + '_doodle',
        );
      } else {
        resolve(null);
      }
    }
  });


  const mapDoodle = (doodle) => {
    const doodleToMap = [];
    let currentArr = [];

    for (let i = 0; i < doodle.length; i++) {
      const char = doodle[i];
  
      if (char === '0') {
        //If we've got something in our array, push it to the main array
        if (currentArr.length >= 1) {
          doodleToMap.push(currentArr);
          currentArr = [];
        }
      } else {
        currentArr.push(parseInt(char));
      }
    }
  
    if (currentArr.length >= 1) {
      doodleToMap.push(currentArr);
    }

    return doodleToMap
  }
   (async ()=>{
    try {
      const doodle = await prom;
      const mappedDoodle =mapDoodle(doodle);

      if (window.currentDoodlepad.currentlyDrawing()) {
        window.currentDoodlepad.interruptPlay();
      }
  
      const checkStopped = () => {
        setTimeout(() => {
          if (window.currentDoodlepad.currentlyDrawing()) {
            checkStopped();
          } else {
            window.currentDoodlepad.play(mappedDoodle);
          }
        }, 300);
      }
      checkStopped();
    } catch (e) {}
  })()

};
