const { strings } = require('../../../config');
const { primaryText, thirdheading } = require('../../page_helpers/components');

const buildButtons = (data, nav) => {
  const buttonTemplates = [];
  if (!data.resetPassword) {
    buttonTemplates.push({
      template: 'block-cta-button',
      fields: {
        text: strings.ONBOARDING_COMPLETE_CONTINUE(),
        action: data.overlay && 'hideLogin',
        navigation_data: !data.overlay && Math.max(nav - 1, 0),
        navigation_template: !data.overlay && 'back',
      },
    });
  } else if (!data.healthCheck) {
    buttonTemplates.push({
      template: 'block-cta-button',
      fields: {
        text: strings.ONBOARDING_COMPLETE_HEALTHCHECK_BUTTON(),
        action: 'gotoHealthcheck',

        //navigation_data: !data.overlay && Math.max(nav - 1, 0),
        //navigation_template: !data.overlay && 'back',
      },
    });
  }

  buttonTemplates.push({
    template: buttonTemplates.length ? 'block-text-button' : 'block-cta-button',
    fields: {
      text: buttonTemplates.length
        ? strings.ONBOARDING_COMPLETE_REMIND_LATER()
        : strings.ONBOARDING_COMPLETE_CONTINUE(),
      action: data.overlay && 'hideLogin',
      navigation_data: !data.overlay && Math.max(nav - 1, 0),
      navigation_template: !data.overlay && 'back',
    },
  });

  return buildButtons.length && buttonTemplates;
};

module.exports = ({ nav, data }) => {
  console.log('ONBOARDING COMPLETE DATA: ', data);
  const checkListItem = ({ checked, text, action }) => ({
    template: 'block-section-row',
    fields: {
      class: 'no-border',
      class2: 'onboard__list',
      class3: checked && 'checked',
      action: action ? action : null,
    },
    columns: [
      {
        template: 'block-icn',
        fields: {
          icon: checked ? 'check-circle' : '',
        },
      },
      {
        template: 'block-info-text',
        fields: {
          text: text,
          class: action ? 'link' : null,
        },
      },
    ],
  });

  return {
    template: 'block-section-onboarding',
    fields: {
      id: 'onboard_complete',
      active: false,
      header: '',
      class: 'no-title',
    },
    rows: [
      {
        template: 'block-section-row',
        columns: [thirdheading({ text: strings.WELCOME_TO_FORGHETTI() })],
      },

      {
        template: 'block-section-row',
        fields: {
          class: 'no-border',
        },
        columns: [
          primaryText(
            $('.overlay').is(':visible')
              ? strings.ONBOARDING_COMPLETE_CONGRATULATIONS()
              : strings.ONBOARDING_COMPLETE_COMPLETE_SETUP(),
            false,
            false,
            false,
            true,
          ),
        ],
      },
      checkListItem({ checked: data.createAccount, text: strings.ONBOARDING_COMPLETE_CREATE_FORGHETTI() }),
      checkListItem({ checked: data.signatureDoodle, text: strings.ONBOARDING_COMPLETE_SIGNATURE_DOODLE() }),
      checkListItem({ checked: data.firstLogin, text: strings.ONBOARDING_COMPLETE_FIRST_LOGIN() }),
      checkListItem({
        checked: data.resetPassword,
        text: strings.ONBOARDING_COMPLETE_RESET_PASSWORD(),
        action: !data.resetPassword ? 'hideLogin' : null,
      }),
      checkListItem({
        checked: data.healthCheck,
        text: strings.ONBOARDING_COMPLETE_HEALTHCHECK(),
        action: !data.healthCheck ? 'gotoHealthcheck' : null,
      }),

      // {
      //   template: 'block-section-row',
      //   fields: {
      //     class: 'no-border',
      //   },
      //   columns: [{
      //     template: 'block-text-button',
      //     fields: {
      //       class: 'cta-button',

      //       //text: strings.ONBOARDING_COMPLETE_IMPORT_BUTTON,
      //       //action: 'import-services',
      //       //text: 'Import services (coming soon)'
      //     }
      //   }]
      // },
      //primaryText(strings.ONBOARDING_COMPLETE_NEXT_STEPS, true, false, true),
      ...buildButtons(data, nav),

      // {
      //   template: 'block-cta-button',
      //   fields: {
      //     text: strings.ONBOARDING_COMPLETE_CONTINUE,
      //     action: data.overlay && 'hideLogin',
      //     navigation_data: !data.overlay && Math.max(nav - 1, 0),
      //     navigation_template: !data.overlay && 'back',
      //   },
      // },
    ],
  };
};
