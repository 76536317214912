const showToastMessage = require('../../action_helpers/showToastMessage');
const updateServiceIcon = require('../../action_helpers/updateServiceIcon');

const strings = require('../../../config').strings;

// -----------------------------------------------------------------------------
// Helper functions
// -----------------------------------------------------------------------------

function done(service, z2hApp, pane) {
  if (service) {
    showToastMessage(strings.MSG_SERVICE_ICON_DELETED());
  }
  const activePaneIndex = pane
    .find('.section-pane-wrapper')
    .children('.active')
    .index();
  z2hApp.paneNavigation('back', pane, activePaneIndex - 1);
}

// -----------------------------------------------------------------------------

module.exports = ({ currentTarget, z2hApp }) => {
  const callback = done;
  const iconUrl = $(currentTarget)
    .find('img')
    .attr('src');

  updateServiceIcon({ currentTarget, callback, iconUrl, z2hApp });
};
