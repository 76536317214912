const getActivePaneDetails = require('../../action_helpers/getActivePaneDetails');
const showToastMessage = require('../../action_helpers/showToastMessage');
const fb = require('../../app/firebaseUtils');
const config = require('../../../config');

const { strings } = config;

module.exports = () => {
  //const phoneNumber = $('#sign-in-number').intlTelInput('getNumber');
  const phoneNumber = window.iti.getNumber();

  window.state.phoneNumber = phoneNumber;

  setTimeout((_) => sendVerificationCode(phoneNumber), 200);

  return Promise.resolve();
};

function sendVerificationCode(phoneNumber) {
  console.info('Authenticating with phone number...', phoneNumber);
  const appVerifier = window.recaptchaVerifier;
  fb.signInWithPhoneNumber(phoneNumber, appVerifier)
    .then((verificationId) => {
      // if (window.cordova && window?.creds?.instantVerification) {
      //   console.log('Instant verification enabled. Signing in with confirmation code.', window.creds);
      //   return fb.signInWithConfirmationCode(window.creds).then((result) => {
      //     if (result) {
      //       z2hApp.paneNavigation('vault_loading', $('.overlay'), 5);
      //     } else {
      //       // Go back to previous pane

      //       const { pane } = getActivePaneDetails();
      //       z2hApp.paneNavigation('verifyDevice2', $('.overlay'), 3);
      //     }
      //   });
      // }
      window.verificationId = verificationId;

      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      console.info('signInWithPhoneNumber: success.');

      // Navigate to verification code entry
      const { pane } = getActivePaneDetails();
      z2hApp.paneNavigation('verifyDevice2', pane, 3);

      // If forghetti is displayed in an iFrame, pass verification ID up to parent
      // prettier-ignore
      try { window.parent.postMessage('verificationId:' + verificationId, '*'); }
      catch (err) { /* ignore */ }

      $('.activation-input').focus();
    })
    .catch((error) => {
      console.error('signInWithPhoneNumber: error: ', error);

      const replaceMessage = (error) => {
        let message = error;
        if (typeof error === 'object') {
          message = error.message;
        }
        return message === 'TOO_LONG'
          ? strings.PHONE_NUMBER_TOO_LONG()
          : message.indexOf('The format of the phone number provided is incorrect') >= 0
          ? strings.INVALID_PHONE_NUMBER()
          : message.indexOf('We have blocked all requests from this device due to unusual activity') >= 0
          ? strings.INVALID_TOO_MANY_ATTEMPTS()
          : strings.MSG_FALLBACK_ERROR_MESSAGE();
      };
      const message = replaceMessage(error);
      showToastMessage(message);

      // Go back to previous pane
      const { pane, activePaneIndex } = getActivePaneDetails();
      z2hApp.paneNavigation('back', pane, 1);
      //setTimeout(_ => $('#sign-in-number').focus(), 1500);
    });
}
