
const { strings, urls } = require('../../../config');
const { infoTextRow } = require('../../page_helpers/components');


//If this is not the electron app then we need to tell them to go and 
//and download the app on their PC/MAC
module.exports = ({ nav }) => {

  const isElectron = window.state.electron;

  const notElectron = {
    template: 'block-section-page',
    fields: {
      id: 'importServices',
      active: false,
      header: 'Import services',
      navbar: false,
      navigation_left: strings.BACK(),
      navigation_left_icon: '',
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'back',
    },
    rows: [
      infoTextRow(strings.IMPORT_SERVICE_WELCOME_INFO(), true, false),
      {
        template: 'block-lochy',
        fields: {
          imagePath: 'img/lochy/12-Lochy-Dancing.gif',
          class: 'no-border',
        }
      },
      infoTextRow(strings.IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_1(), true, false),
      infoTextRow(strings.IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_2(), true, false),

      {
        template: 'block-section-row',
        fields: {
          class: 'centered-text',
        },
        columns: [
          {
            template: 'block-text-button',
            fields: {
              text: strings.IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_BUTTON(),

              navigation_data: nav + 1,
              action: 'openForghettiCom',
              class: 'cta-button',
            }
          }
        ]
      }
    ]
  }
  const electron = {
    template: 'block-section-page',
    fields: {
      id: 'importServices',
      active: false,
      header: 'Import services',
      navbar: false,
      navigation_left: strings.BACK(),
      navigation_left_icon: '',
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'back',
    },
    rows: [
      infoTextRow(strings.IMPORT_SERVICE_WELCOME_INFO(), true, false),
      {
        template: 'block-lochy',
        fields: {
          imagePath: 'img/lochy/12-Lochy-Dancing.gif',
          class: 'no-border',
        }
      },
      {
        template: 'block-section-row',
        fields: {
          class: 'centered-text',
        },
        columns: [
          {
            template: 'block-text-button',
            fields: {
              text: strings.IMPORT_SERVICE_WELCOME_BUTTON(),

              navigation_data: nav + 1,
              navigation_template: 'importSelectFolder',
              class: 'cta-button',
            }
          }
        ]
      }
    ],
  };

  return isElectron ? electron : notElectron;
};
