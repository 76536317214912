const uploadAndCropImage = require('../miscellaneous/uploadAndCropImage');
const showToastMessage = require('../../action_helpers/showToastMessage');
const showResponseErrorMessage = require('../../action_helpers/showResponseErrorMessage');
const justCropImage = require('../miscellaneous/justCropImage');
module.exports = async ({ z2hApp }) => {
  let callback = null;

  const cordovaCamera = window.cordova && window.navigator.camera;

  //Promisify the camera callback
  const getUploadPromise = () => {
    return new Promise((resolve, reject) => {
      const cordovaCamera = window.cordova && window.navigator.camera;
      !cordovaCamera && resolve();

      const options = {
        quality: 90,
        destinationType: Camera.DestinationType.DATA_URL,
        sourceType: Camera.PictureSourceType.PHOTOLIBRARY,
        encodingType: Camera.EncodingType.JPEG,
        mediaType: Camera.MediaType.PICTURE,
        correctOrientation: true,
        targetHeight: 250,
        targetWidth: 250,
      };

      const onSuccess = imageURI => {
        console.log('success');
        resolve(imageURI);
      };
      const onError = error => {
        console.log('failed');
        console.log(error);
        reject(error);
      };
      navigator.camera.getPicture(onSuccess, onError, options);
    });
  };

  const uplImage = await getUploadPromise();
  const img = 'data:image/png;base64,' + uplImage;
  // Firstly get the user to upload an image, and crop it if they like
  const image = cordovaCamera ? justCropImage({ img }) : uploadAndCropImage();

  image
    .then(({ base64Image, doneCallback }) => {
      // Send image to the server
      const data = { image: base64Image };
      callback = doneCallback;
      return z2hApp.requestData('post', '', 'users/@me/update-image', data);
    })
    .then(response => {
      if (Math.floor(response.status / 100) === 2) {
        const newUrl = (response.data || {}).url || '';
        const user = window.state.userData;
        user.avatar = newUrl;
        // Update any images on screen which have a data-id which matches the
        // group ID
        $(`img[data-id=${user.id}]`).attr('src', newUrl);
        $(`div[data-id=${user.id}] img`).attr('src', newUrl);

        // Also, update the displayed icon for the 'personal group'
        const personalGroup = (window.state.groupsList || []).find(g => g.personal) || {};
        personalGroup.icon = newUrl;
        $(`img[data-id=${personalGroup.id}]`).attr('src', newUrl);
        showToastMessage('Profile picture updated');
      } else {
        showResponseErrorMessage(response);
      }

      // Call the callback we were given (to close the upload tool)
      if (callback) callback();
    });
};
