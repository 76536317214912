const showToastMessage = require('../../action_helpers/showToastMessage');
const openUrl = require('../../action_helpers/openUrl');
const strings = require('../../../config').strings;

module.exports = ({ currentTarget }) => {
  let fieldData = $(currentTarget).closest('.row').find('.primary-text-content').text();

  if (!fieldData) {
    fieldData = $(currentTarget).closest('.row').find('.text-input').val();
  }
  if (fieldData) {
    if (fieldData.toLowerCase().substr(0, 4) !== 'http') {
      fieldData = 'https://' + fieldData;
    }
    showToastMessage(strings.MSG_OPENING_WEBSITE());
    openUrl(fieldData);
  }
};
