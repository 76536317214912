const config = require('../../config');
const getPageDataFuncs = require('../getPageData');
const pages = require('../pages');

function z2hTemplates() {
  // Empty constructor
}
let currentPagePromiseId;
z2hTemplates.prototype.getPageData = function getPageData(id, clickedElem, dataId) {
  //TODO check this is legit for all/most of the functions
  //Be careful with actions and templates at the same time that both define pageData (they shouldn't), but the
  //cancellable promise works on the fact that the last one to be called will be the one that resolves.
  //That means you might cancel the page you actually want to go to.

  // If we have an external function for retriving the data for this page,
  // then use that function now.
  if (getPageDataFuncs[id]) {
    currentPagePromiseId = id;
    const cancellablePromise = new Promise((resolve, reject) => {
      Promise.resolve(getPageDataFuncs[id]({ clickedElem, dataId }))
        .then((data) => {
          if (currentPagePromiseId !== id) {
            reject();
          }
          resolve(data);
        })
        .catch(reject);
    });

    return cancellablePromise;
  }

  // Otherwise...
  return Promise.resolve({});
};

// T E M P L A T E S
// p a g e
z2hTemplates.prototype.getPage = function getPage(id, nav, data, dataId) {
  if (!id) {
    throw 'No ID was referenced';
  }

  if (pages[id]) {
    try {
      return pages[id]({ id, nav, data, dataId });
    } catch (err) {
      console.error(err);
    }
  }
  return {};
};

z2hTemplates.prototype.compileBlockAttribute = function compileBlockAttribute(input) {
  // Set default values for some of block properties
  if (typeof input === 'object' && !input.fields) {
    input.fields = {};
  }

  // Helpers =======================================================================================

  const addBlockAttribute = (block, name, selector, type, input, value = '', remove = true) => {
    block.attributes = block.attributes || [];
    const newAttr = { selector, type };
    let content = value || (input && input.fields[name]);
    if (content === 0) content = '0';
    if (content) {
      newAttr.content = content;
    } else {
      newAttr.remove = remove;
    }
    block.attributes.push(newAttr);
  };

  const addStandardNavigationAttributes = (block, selector) => {
    const navSelector = selector ? selector : '';
    addBlockAttribute(block, 'navigation_data', navSelector, 'data-nav', input);
    addBlockAttribute(block, 'navigation_pane', navSelector, 'data-nav-pane', input);
    addBlockAttribute(block, 'navigation_template', navSelector, 'data-template', input);
    addBlockAttribute(block, 'navigation_action', navSelector, 'data-actionclick', input);
    addBlockAttribute(block, 'navigation_validation', navSelector, 'data-validation', input);
  };

  // ===============================================================================================

  try {
    if (!input) {
      return {};
    }

    const block = { template: input.template };

    // Page section
    if (input.template === 'block-section-page') {
      // Left/right nav options
      const selector = (dir) => `.section-pane-inner .section-pane-header .section-pane-nav .nav-${dir}`;

      const addNavOptions = (dir) => {
        addBlockAttribute(block, `navigation_${dir}`, selector(dir) + ' span', 'innerText', input);
        addBlockAttribute(block, `navigation_${dir}_id`, selector(dir), 'data-id', input);
        addBlockAttribute(block, `navigation_${dir}_data`, selector(dir), 'data-nav', input);
        addBlockAttribute(block, `navigation_${dir}_action`, selector(dir), 'data-actionclick', input);
        addBlockAttribute(block, `navigation_${dir}_template`, selector(dir), 'data-template', input);
        addBlockAttribute(block, `navigation_${dir}_pane`, selector(dir), 'data-nav-pane', input);
        addBlockAttribute(block, `navigation_${dir}_validation`, selector(dir), 'data-validation', input);
        addBlockAttribute(block, `navigation_${dir}_prevent_warning`, selector(dir), 'data-prevent', input);
        addBlockAttribute(block, `navigation_${dir}_preserve_temporary`, selector(dir), 'data-preserve', input);
        addBlockAttribute(block, `navigation_${dir}_class`, selector(dir), 'class', input);
      };

      addNavOptions('left');
      addNavOptions('right');

      addBlockAttribute(block, 'id', '', 'id', input);
      addBlockAttribute(block, 'class', '', 'class', input);
      addBlockAttribute(block, 'class2', '', 'class', input);
      addBlockAttribute(block, 'active', '', 'class', input);
      addBlockAttribute(block, 'navbar', '', 'data-navbar', input);

      const h1Selector = '.section-pane-inner .section-pane-header .section-pane-nav h1';
      addBlockAttribute(block, 'header', h1Selector, 'innerText', input);

      const helpSelector = '.section-pane-inner .section-pane-header .section-pane-nav .help-link';
      addBlockAttribute(block, 'help_id', helpSelector, 'data-id', input);
    }

    // Page section
    if (input.template === 'block-section-onboarding') {
      addBlockAttribute(block, 'id', '', 'id', input);
      addBlockAttribute(block, 'class', '', 'class', input);
      addBlockAttribute(block, 'class2', '', 'class', input);
      addBlockAttribute(block, 'active', '', 'class', input);
    }

    // Row section
    if (input.template === 'block-section-row') {
      addBlockAttribute(block, 'id', '', 'id', input);
      addBlockAttribute(block, 'class', '', 'class', input);
      addBlockAttribute(block, 'class2', '', 'class', input);
      addBlockAttribute(block, 'class3', '', 'class', input);
      addBlockAttribute(block, 'context', '', 'data-context', input);
      addBlockAttribute(block, 'data_value', '', 'data-value', input);
      addBlockAttribute(block, 'action', '', 'data-actionclick', input);

      addBlockAttribute(block, 'actionDetail', '', 'data-actiondetail', input);
      addBlockAttribute(block, 'help_id', '.help-link', 'data-id', input);
      addBlockAttribute(block, 'navigation_data_id', '', 'data-id', input);
      addBlockAttribute(block, 'navigation_prevent_warning', '', 'data-prevent', input);
      addBlockAttribute(block, 'navigation_preserve_temporary', '', 'data-preserve', input);
      if (input.fields.primary_heading_html) {
        addBlockAttribute(block, 'primary_heading_html', '.column .primary-heading', 'innerHTML', input);
      } else {
        addBlockAttribute(block, 'primary_heading', '.column .primary-heading', 'innerText', input);
      }
      addBlockAttribute(block, 'primary_heading_class', '.column .primary-heading', 'class', input);

      addBlockAttribute(block, 'column_class', '.column', 'class', input);

      if (input.fields.secondary_heading_html) {
        addBlockAttribute(block, 'secondary_heading_html', '.column .secondary-heading', 'innerHTML', input);
      } else {
        addBlockAttribute(block, 'secondary_heading', '.column .secondary-heading', 'innerText', input);
      }
      addStandardNavigationAttributes(block);

      // Left/right nav options
      /*const selector = dir => `.row-nav__${dir}`;

      const addNavOptions = dir => {
        addBlockAttribute(block, `navigation_${dir}`, selector(dir) + ' span', 'innerText', input);
        addBlockAttribute(block, `navigation_${dir}_id`, selector(dir), 'data-id', input);
        addBlockAttribute(block, `navigation_${dir}_data`, selector(dir), 'data-nav', input);
        addBlockAttribute(block, `navigation_${dir}_action`, selector(dir), 'data-actionclick', input);
        addBlockAttribute(block, `navigation_${dir}_template`, selector(dir), 'data-template', input);
        addBlockAttribute(block, `navigation_${dir}_pane`, selector(dir), 'data-nav-pane', input);
        addBlockAttribute(block, `navigation_${dir}_validation`, selector(dir), 'data-validation', input);
        addBlockAttribute(block, `navigation_${dir}_prevent_warning`, selector(dir), 'data-prevent', input);
        addBlockAttribute(block, `navigation_${dir}_preserve_temporary`, selector(dir), 'data-preserve', input);
      };
      addNavOptions('left');
      addNavOptions('right');*/
    }

    if (input.template === 'block-section-service') {
      addBlockAttribute(block, 'id', '', 'id', input);
      addBlockAttribute(block, 'class', '', 'class', input);
      addBlockAttribute(block, 'class2', '', 'class', input);
      addBlockAttribute(block, 'class3', '', 'class', input);
      addBlockAttribute(block, 'context', '', 'data-context', input);
      addBlockAttribute(block, 'data_value', '', 'data-value', input);
      addBlockAttribute(block, 'action', '', 'data-actionclick', input);
      addBlockAttribute(block, 'help_id', '.help-link', 'data-id', input);
      addBlockAttribute(block, 'navigation_data_id', '', 'data-id', input);
      addBlockAttribute(block, 'navigation_prevent_warning', '', 'data-prevent', input);
      addBlockAttribute(block, 'navigation_preserve_temporary', '', 'data-preserve', input);
      if (input.fields.primary_heading_html) {
        addBlockAttribute(block, 'primary_heading_html', '.column .primary-heading', 'innerHTML', input);
      } else {
        addBlockAttribute(block, 'primary_heading', '.column .primary-heading', 'innerText', input);
      }

      addBlockAttribute(block, 'primary_heading_action', '.column .secondary-heading', 'data-actionclick', input);
      if (input.fields.secondary_heading_html) {
        addBlockAttribute(block, 'secondary_heading_html', '.column .secondary-heading', 'innerHTML', input);
      } else {
        addBlockAttribute(block, 'secondary_heading', '.column .secondary-heading', 'innerText', input);
      }

      addBlockAttribute(block, 'secondary_heading_action', '.column .secondary-heading', 'data-actionclick', input);

      addStandardNavigationAttributes(block);
    }

    if (input.template === 'block-section-service-heading') {
      addBlockAttribute(block, 'id', '', 'id', input);
      addBlockAttribute(block, 'class', '', 'class', input);
      addBlockAttribute(block, 'class2', '', 'class', input);
      addBlockAttribute(block, 'class3', '', 'class', input);
      addBlockAttribute(block, 'context', '', 'data-context', input);
      addBlockAttribute(block, 'data_value', '', 'data-value', input);
      addBlockAttribute(block, 'action', '', 'data-actionclick', input);
      addBlockAttribute(block, 'help_id', '.help-link', 'data-id', input);
      addBlockAttribute(block, 'navigation_data_id', '', 'data-id', input);
      addBlockAttribute(block, 'navigation_prevent_warning', '', 'data-prevent', input);
      addBlockAttribute(block, 'navigation_preserve_temporary', '', 'data-preserve', input);
      if (input.fields.primary_heading_html) {
        addBlockAttribute(block, 'primary_heading_html', '.primary-heading', 'innerHTML', input);
      } else {
        addBlockAttribute(block, 'primary_heading', '.primary-heading', 'innerText', input);
      }

      addBlockAttribute(block, 'primary_heading_action', '.secondary-heading', 'data-actionclick', input);
      if (input.fields.secondary_heading_html) {
        addBlockAttribute(block, 'secondary_heading_html', '.secondary-heading', 'innerHTML', input);
      } else {
        addBlockAttribute(block, 'secondary_heading', '.secondary-heading', 'innerText', input);
      }

      addBlockAttribute(block, 'secondary_heading_action', '.secondary-heading', 'data-actionclick', input);

      addStandardNavigationAttributes(block);
    }

    if (input.template === 'block-avatar-group') {
      addBlockAttribute(block, 'class', '', 'class', input);
      addBlockAttribute(block, 'avatar_id_1', '.login-avatar:eq(0)', 'id', input);
      addBlockAttribute(block, 'avatar_id_2', '.login-avatar:eq(1)', 'id', input);
      addBlockAttribute(block, 'avatar_src_1', '.login-avatar:eq(0)', 'src', input);
      addBlockAttribute(block, 'avatar_src_2', '.login-avatar:eq(1)', 'src', input);
      addBlockAttribute(block, 'avatar_name_1', '.login-avatar-label:eq(0)', 'innerText', input);
      addBlockAttribute(block, 'avatar_name_2', '.login-avatar-label:eq(1)', 'innerText', input);
      addBlockAttribute(block, 'primary_heading', '.column .primary-heading', 'innerText', input);
      addBlockAttribute(block, 'avatar_context_1', '.login-avatar-wrapper:eq(0)', 'data-context', input);
      addBlockAttribute(block, 'avatar_context_2', '.login-avatar-wrapper:eq(1)', 'data-context', input);
      addBlockAttribute(block, 'avatar_data_id_1', '.login-avatar-wrapper:eq(0)', 'data-id', input);
      addBlockAttribute(block, 'avatar_data_id_2', '.login-avatar-wrapper:eq(1)', 'data-id', input);

      addBlockAttribute(block, 'data_id', '', 'data-id', input);
      addBlockAttribute(block, 'action', '', 'data-actionclick', input);
    }

    if (input.template === 'block-doodlepad') {
      addBlockAttribute(block, 'disabled', '.doodlepad__overlay', 'class', null, input.fields.disabled ? 'disabled' : '');

    }
    if (input.template === 'block-doodlepad-buttons' || input.template === 'block-doodlepad-confirm-only') {
      const confirmBtnSelector = input.template === 'block-doodlepad-buttons' ? 'button:eq(1)' : 'button:eq(0)';

      // Replace strings in template with strings from configuration
      const SECRET = config.strings.DOODLEPAD_SECRET();
      addBlockAttribute(block, 'SECRET', '.doodlepad-button-secret', 'innerText', null, SECRET);
      const REDOODLE = config.strings.DOODLEPAD_REDOODLE();
      addBlockAttribute(block, 'REDOODLE', '.doodlepad-button-clear', 'innerText', null, REDOODLE);

      addBlockAttribute(block, 'confirm_data', confirmBtnSelector, 'data-nav', input);

      addBlockAttribute(block, 'data_id', confirmBtnSelector, 'data-id', input);
      addBlockAttribute(block, 'confirm_template', confirmBtnSelector, 'data-template', input);
      addBlockAttribute(block, 'confirm_action', confirmBtnSelector, 'data-actionclick', input);
      addBlockAttribute(block, 'confirm_validation', confirmBtnSelector, 'data-validation', input);
      addBlockAttribute(block, 'confirm_prevent_warning', confirmBtnSelector, 'data-prevent', input);

      addBlockAttribute(block, 'icon', '.f-icn', 'class', null,  input.fields.icon ?  `f-icn-${input.fields.icon}` : 'f-icn-check-solid');
    }

    if (input.template === 'block-biometrics-buttons') {
      const confirmBtnSelector = 'button:eq(1)';

      // Replace strings in template with strings from configuration
      const TRYAGAIN = config.strings.BIOMETRICS_TRY_AGAIN();
      addBlockAttribute(block, 'SECRET', '.doodlepad-button-secret', 'innerText', null, TRYAGAIN);
      const USEDOODLE = config.strings.BIOMETRICS_USE_DOODLE();
      addBlockAttribute(block, 'REDOODLE', '.doodlepad-button-clear', 'innerText', null, USEDOODLE);

      addBlockAttribute(block, 'confirm_data', confirmBtnSelector, 'data-nav', input);

      addBlockAttribute(block, 'data_id', confirmBtnSelector, 'data-id', input);
      addBlockAttribute(block, 'confirm_template', confirmBtnSelector, 'data-template', input);
      addBlockAttribute(block, 'confirm_action', confirmBtnSelector, 'data-actionclick', input);
      addBlockAttribute(block, 'confirm_prevent_warning', confirmBtnSelector, 'data-prevent', input);
    }

    // Icon button or
    // Text button or
    // Text button (left aligned)
    if (
      input.template == 'block-icn-button' ||
      input.template == 'block-text-button' ||
      input.template == 'block-text-button-left' ||
      input.template == 'block-icn-loginlist'
    ) {
      addBlockAttribute(block, 'class', '', 'class', input);
      addBlockAttribute(block, 'class2', '', 'class2', input);

      addBlockAttribute(block, 'tooltip', '', 'data-tooltip', input);
      addBlockAttribute(block, 'text', '', 'innerText', input);
      addBlockAttribute(block, 'data_id', '', 'data-id', input);
      addBlockAttribute(block, 'context', '', 'data-context', input);
      addBlockAttribute(block, 'label', '.label', 'innerText', input);
      addBlockAttribute(block, 'action', '', 'data-actionclick', input);
      addBlockAttribute(block, 'icon', '.f-icn', 'class', null, 'f-icn-' + input.fields.icon);

      addBlockAttribute(block, 'iconClass', '.f-icn', 'class', input);

      addBlockAttribute(block, 'id', '', 'id', input);

      if (input.template == 'block-icn-button' && input.fields.label) {
        addBlockAttribute(block, 'has-label', '', 'class', null, 'has-label');
      }

      if (input.fields.accessoryDot) {
        addBlockAttribute(block, 'accessory-dot', 'div', 'class', null, 'icon-accessory-dot');
      }



      addBlockAttribute(block, 'actionDetail', '', 'data-actiondetail', input);

      addStandardNavigationAttributes(block);
    }

    // Call to action button (CTA)
    if (input.template == 'block-cta-button') {
      const selector = '.cta-button';
      addBlockAttribute(block, 'class', '', 'class', input);
      addBlockAttribute(block, 'class2', '', 'class2', input);

      addBlockAttribute(block, 'tooltip', selector, 'data-tooltip', input);
      addBlockAttribute(block, 'text', selector, 'innerText', input);
      addBlockAttribute(block, 'data_id', selector, 'data-id', input);
      addBlockAttribute(block, 'context', selector, 'data-context', input);
      addBlockAttribute(block, 'label', '.label', 'innerText', input);
      addBlockAttribute(block, 'action', selector, 'data-actionclick', input);
      addBlockAttribute(block, 'icon', '.f-icn', 'class', null, 'f-icn-' + input.fields.icon);

      addStandardNavigationAttributes(block, selector);
    }

    // Icon or Big Icon
    if (input.template == 'block-icn' || input.template == 'block-icn-big') {
      addBlockAttribute(block, 'icon', '.f-icn', 'class', null, 'f-icn-' + input.fields.icon);

      addBlockAttribute(block, 'class', '', 'class', input);
      addBlockAttribute(block, 'iconClass', '.f-icn', 'class', input);
    }

    if (input.template === 'block-icn-loginlist-lochy') {
      addBlockAttribute(block, 'id', '.badge', 'id', input);
      addBlockAttribute(block, 'image', '.badge', 'src', input);
      addBlockAttribute(block, 'action', '.badge', 'data-actionclick', input);
      addBlockAttribute(block, 'data_id', '.badge', 'data-id', input);
      addBlockAttribute(block, 'class', '', 'class', input);
      addBlockAttribute(block, 'actionid', '.badge', 'data-actionid', input);
    }

    // Primary and Secondary Text with a Tag
    if (input.template == 'block-text-group') {
      addBlockAttribute(block, 'class', '', 'class', input);

      const contentSelector = (priSec) => `.text-group-inner [name=${priSec}-text] [name=${priSec}-text-content]`;
      const contentSelectorWrapper = (priSec) => `.text-group-inner [name=${priSec}-text]`;
      if (input.fields && input.fields.primary_html) {
        addBlockAttribute(block, 'primary_html', contentSelector('primary'), 'innerHTML', input);
      } else {
        addBlockAttribute(block, 'primary_text', contentSelector('primary'), 'innerText', input);
      }

      addBlockAttribute(block, 'primary_text_label', '.primary-text-label', 'innerText', input);

      addBlockAttribute(block, 'primary_class', contentSelector('primary'), 'class', input);

      addBlockAttribute(block, 'primary_parent_class', contentSelectorWrapper('primary'), 'class', input);

      addBlockAttribute(block, 'primary_action', contentSelectorWrapper('primary'), 'data-actionclick', input);

      if (input.fields && input.fields.secondary_html) {
        addBlockAttribute(block, 'secondary_html', contentSelector('secondary'), 'innerHTML', input);
      } else {
        addBlockAttribute(block, 'secondary_text', contentSelector('secondary'), 'innerText', input);
      }
      addBlockAttribute(block, 'secondary_text_label', '.secondary-text-label', 'innerText', input);

      //secondary class
      addBlockAttribute(block, 'secondary_class', contentSelector('secondary'), 'class', input);

      addBlockAttribute(block, 'secondary_parent_class', contentSelectorWrapper('secondary'), 'class', input);

      addBlockAttribute(block, 'secondary_action', contentSelectorWrapper('secondary'), 'data-actionclick', input);

      addBlockAttribute(block, 'tag', '.text-group-inner .primary-text .tag', 'innerText', input);
    }

    // Info text
    if (input.template === 'block-info-text') {
      addBlockAttribute(block, 'class', '', 'class', input);
      if (input.fields.html) {
        addBlockAttribute(block, 'html', '', 'innerHTML', input);
      } else if (input.fields.text) {
        addBlockAttribute(block, 'text', '', 'innerText', input);
      }
    }

    // Help text
    if (input.template === 'block-help-text') {
      addBlockAttribute(block, 'class', '', 'class', input);
      const selector = '.help-text';
      if (input.fields.html) {
        addBlockAttribute(block, 'html', selector, 'innerHTML', input);
      } else if (input.fields.text) {
        addBlockAttribute(block, 'text', selector, 'innerText', input);
      }
    }

    // Info list row
    if (input.template === 'block-info-list-row') {
      addBlockAttribute(block, 'text', '.column', 'innerText', input);
    }

    // Toggle switch
    if (input.template === 'block-toggle-switch') {
      addBlockAttribute(block, 'data_id', '', 'data-id', input);
      addBlockAttribute(block, 'checked', '.switch input', 'checked', input);
      addBlockAttribute(block, 'action', '.switch input', 'data-actionclick', input);
      addBlockAttribute(block, 'value', '.switch input', 'value', input);
    }

    // Login avatar
    if (input.template === 'block-login-avatar') {
      addBlockAttribute(block, 'id', '.login-avatar', 'id', input);

      addBlockAttribute(block, 'class', '', 'class', input);
      addBlockAttribute(block, 'data_id', '', 'data-id', input);
      addBlockAttribute(block, 'context', '', 'data-context', input);
      addBlockAttribute(block, 'action', '', 'data-actionclick', input);
      addBlockAttribute(block, 'status', '.status', 'data-status', input);
      addBlockAttribute(block, 'label', '.login-avatar-label', 'innerText', input);
      addBlockAttribute(block, 'selected', '', 'data-selected', input);

      const avatar = input.fields.avatar || config.app.DEFAULT_SERVICE_ICON;
      addBlockAttribute(block, 'avatar', '.login-avatar', 'src', null, avatar);

      addStandardNavigationAttributes(block);
    }

    // Group avatar/iconπd
    if (input.template === 'block-group-avatar') {
      addBlockAttribute(block, 'id', '.login-avatar', 'id', input);
      addBlockAttribute(block, 'avatar', '.login-avatar', 'src', input);
      addBlockAttribute(block, 'action', '.login-avatar', 'data-actionclick', input);
      addBlockAttribute(block, 'data_id', '.login-avatar', 'data-id', input);

      addBlockAttribute(block, 'avatar_name', '.login-avatar-label', 'innerText', input);
    }
    // Group avatar/icon
    if (input.template === 'block-badge') {
      addBlockAttribute(block, 'id', '.badge', 'id', input);
      addBlockAttribute(block, 'image', '.badge', 'src', input);
      addBlockAttribute(block, 'action', '.badge', 'data-actionclick', input);
      addBlockAttribute(block, 'data_id', '.badge', 'data-id', input);
      addBlockAttribute(block, 'class', '', 'class', input);
      addBlockAttribute(block, 'actionid', '.badge', 'data-actionid', input);
    }

    // Primary heading
    if (input.template == 'block-primary-heading') {
      const selector = '.text-group-inner .primary-heading .primary-heading-content';
      addBlockAttribute(block, 'heading', selector, 'innerText', input);
    }

    // Primary text
    if (input.template == 'block-primary-text') {
      const selector = '.primary-text-content';
      addBlockAttribute(block, 'id', '', 'id', input);
      addBlockAttribute(block, 'class', '', 'class', input);
      addBlockAttribute(block, 'class2', '', 'class', input);
      addBlockAttribute(block, 'class3', '', 'class', input);
      if (input.fields.html) {
        addBlockAttribute(block, 'html', selector, 'innerHTML', input, '', false);
      } else if (input.fields.text) {
        addBlockAttribute(block, 'text', selector, 'innerText', input, '', false);
      }

    }

    // Radio button
    if (input.template == 'block-radio-button') {
      const { color } = input.fields;
      const { mode } = input.fields;

      addBlockAttribute(block, 'theme', '', 'class', input, mode + '__' + color);
      addBlockAttribute(block, 'theme', '', 'data-theme', input, color);
      addBlockAttribute(block, 'mode', '', 'data-mode', input, mode);

      //addBlockAttribute(block, 'active', '', 'class', input, mode + '__' + color);
    }

    // Primary text (editable)
    if (input.template === 'block-editable-primary-text') {
      const inputSelector = '.text-group-inner .primary-text .text-input';

      addBlockAttribute(block, 'for', '', 'for', input);
      addBlockAttribute(block, 'class', '', 'class', input);
      addBlockAttribute(block, 'class2', '', 'class', input);
      addBlockAttribute(block, 'tooltip', '', 'data-tooltip', input);
      addBlockAttribute(block, 'name', inputSelector, 'name', input);
      addBlockAttribute(block, 'primary_text', inputSelector, 'value', input);
      addBlockAttribute(block, 'id', inputSelector, 'id', input);
      addBlockAttribute(block, 'placeholder', inputSelector, 'placeholder', input);
      addBlockAttribute(block, 'action', inputSelector, 'data-actionkeyup', input);
      addBlockAttribute(block, 'maxlength', inputSelector, 'maxlength', input);
      addBlockAttribute(block, 'type', inputSelector, 'type', input);
      addBlockAttribute(block, 'type', inputSelector, 'inputmode', null, input.fields.type === 'number' ? 'numeric' : 'text');

      if (!input.fields.type) {
        input.fields.type = 'text';
      }
      addBlockAttribute(block, 'type', inputSelector, 'type', input);

      const lowercaseValue = input.fields.lowercase ? 'input-lowercase' : '';
      addBlockAttribute(block, 'lowercase', inputSelector, 'class', input, lowercaseValue);
      addBlockAttribute(block, 'icon_action', '[input-action]', 'data-actionclick', input);
      if (input.fields.icon) {
        addBlockAttribute(block, 'icon', '', 'class', null, 'with-action');
        addBlockAttribute(block, 'icon', '[input-action]', 'class', null, 'input-action');
        
      }

      addBlockAttribute(block, 'icon', '[input-action]', 'class', null, 'f-icn-' + input.fields.icon);
    }

    if (input.template === 'block-textarea') {
      const inputSelector = '.text-input';
      addBlockAttribute(block, 'for', '', 'for', input);
      addBlockAttribute(block, 'class', '', 'class', input);
      addBlockAttribute(block, 'class2', '', 'class', input);
      addBlockAttribute(block, 'tooltip', '', 'data-tooltip', input);
      addBlockAttribute(block, 'name', inputSelector, 'name', input);
      addBlockAttribute(block, 'value', inputSelector, 'value', input);
      addBlockAttribute(block, 'id', inputSelector, 'id', input);
      addBlockAttribute(block, 'placeholder', inputSelector, 'placeholder', input);
      addBlockAttribute(block, 'action', inputSelector, 'data-actionkeyup', input);
      addBlockAttribute(block, 'maxlength', inputSelector, 'maxlength', input);
      addBlockAttribute(block, 'type', inputSelector, 'type', input);
      addBlockAttribute(block, 'icon_action', '[input-action]', 'data-actionclick', input);
    }

    // Block activation code
    if (input.template === 'block-activation-code' || input.template === 'block-otp') {
      // Add additional attributes for unique input ids before custom data
      // Equivalent of doing:
      // block.attributes.label0 = {selector: ..., type: ..., content: ...}
      // block.attributes.input0 = {selector: ..., type: ..., content: ...}
      // block.attributes.action0 = {selector: ..., type: ..., content: ...}
      // block.attributes.label1 = {selector: ..., type: ..., content: ...}
      // ... and so on
      // for (let i = 0; i <= 6; i++) {
      //   const content = 'block-activation-code-' + i;
      //   const labelSelector = `label:eq(${i - 1})`;
      //   const inputSelector = `.activation-input:eq(${i - 1})`;
      //   addBlockAttribute(block, 'label' + i, labelSelector, 'for', null, content);
      //   addBlockAttribute(block, 'input' + i, inputSelector, 'id', null, content);
      //   addBlockAttribute(block, 'action' + i, inputSelector, 'data-actionkeyup', null, content);
      // }
      addBlockAttribute(block, 'data_name', '', 'data_name', input);
    }

    // Telephone input
    if (input.template == 'block-telephone') {
      addBlockAttribute(block, 'id', '', 'id', input);
      addBlockAttribute(block, 'placeholder', '', 'placeholder', input);
      addBlockAttribute(block, 'action', '', 'data-actionkeyup', input);
      addBlockAttribute(block, 'value', '', 'value', input);
    }

    // Insertable (section)
    if (input.template == 'block-insertable') {
      addBlockAttribute(block, 'id', '', 'id', input);
      addBlockAttribute(block, 'class', '', 'class', input);
      addBlockAttribute(block, 'html', '', 'innerHTML', input);
    }

    if (input.template == 'block-login-list-page') {
      addBlockAttribute(block, 'heading', 'h1', 'innerText', input);
      addBlockAttribute(block, 'group_icon', '.login-list-page__group-icon', 'src', input);
      addBlockAttribute(block, 'help_id', '.help-link', 'data-id', input);
      addBlockAttribute(block, 'navbar', '', 'data-navbar', input);

      // Replace stings with those from config
      const RECENT = config.strings.SERVICES_RECENT();
      const SEARCH = config.strings.SEARCH();
      const PERSONAL = config.strings.SERVICES_PERSONAL();
      const ADD_LOGIN = config.strings.SERVICES_ADD_LOGIN();
      addBlockAttribute(block, 'RECENT', '.recent h2', 'innerText', null, RECENT);
      // addBlockAttribute(block, 'PERSONAL', '.list-header h1', 'innerText', null, PERSONAL);
      addBlockAttribute(block, 'SEARCH', '.search-input.text-input', 'placeholder', null, SEARCH);
      addBlockAttribute(block, 'ADD_LOGIN', '.nav-link.icn-button span', 'innerText', null, ADD_LOGIN);
    }

    if (input.template == 'block-search-box') {
      addBlockAttribute(block, 'class', '', 'class', input);
      addBlockAttribute(block, 'id', '.text-input', 'id', input);
      addBlockAttribute(block, 'value', '.text-input', 'value', input);
      addBlockAttribute(block, 'id2', '', 'for', null, input.fields.id);
      addBlockAttribute(block, 'action', '.text-input', 'data-actionkeyup', input);
      addBlockAttribute(block, 'rules', 'input', 'data-rules', input);

      const SEARCH = input.fields.placeholder || config.strings.SEARCH();

      addBlockAttribute(block, 'SEARCH', '.search-input.text-input', 'placeholder', null, SEARCH);
      addBlockAttribute(block, 'maxlength', '.text-input', 'maxlength', input);
    }

    if (input.template === 'block-onboarding-image') {
      addBlockAttribute(block, 'class', '', 'class', input);
      addBlockAttribute(block, 'imagePath', 'img', 'src', input);

      addBlockAttribute(block, 'style', '', 'style', input);
      addBlockAttribute(block, 'imageStyle', 'img', 'style', input);
    }

    if (input.template === 'block-close') {
      addBlockAttribute(block, 'class', '', 'class', input);
      addBlockAttribute(block, 'imagePath', 'img', 'src', input);
      addBlockAttribute(block, 'action', '', 'data-actionclick', input);
      addBlockAttribute(block, 'animation', '', 'data-animation', input);
      addStandardNavigationAttributes(block);


    }
    if (input.template === 'block-onboarding-nav-right') {
      addBlockAttribute(block, 'primary_text', 'span', 'innerText', input);
      addBlockAttribute(block, 'navigation_data', '', 'data-nav', input);
      addBlockAttribute(block, 'navigation_pane', '', 'data-nav-pane', input);
      addBlockAttribute(block, 'navigation_template', '', 'data-template', input);
      addBlockAttribute(block, 'navigation_action', '', 'data-actionclick', input);
      addBlockAttribute(block, 'navigation_validation', '', 'data-validation', input);
    }

    if (input.template === 'block-lochy') {
      addBlockAttribute(block, 'class', '', 'class', input);
      addBlockAttribute(block, 'imagePath', 'img', 'src', input);
      addBlockAttribute(block, 'size', 'img', 'class', input);
    }

    if (input.template === 'block-progress-dots') {
      // Support "dots1Active: true" (etc. for dots 1 - 5) in template fields
      for (let i = 1; i <= 5; i++) {
        if (input.fields['dot' + i + 'Active']) {
          const selector = '.column:nth-child(' + i + ') .progress-dots__dot';
          addBlockAttribute(block, '', selector, 'class', null, 'active');
        }
      }
    }

    if (input.template === 'block-playstore-link' || input.template === 'block-appstore-link') {
      addBlockAttribute(block, 'url', 'a', 'href', input);
    }

    if (input.template === 'block-suggested-presets') {
      addBlockAttribute(block, 'service_name_id', '', 'data-searchinputid', input);
      addBlockAttribute(block, 'item_limit', '', 'data-itemlimit', input);
      addBlockAttribute(block, 'action', '', 'data-action', input);
      addBlockAttribute(block, 'hideOnInit', '', 'data-hideOnInit', input);
      addBlockAttribute(block, 'handleManual', '', 'data-manual', input);

      addBlockAttribute(block, 'class', '', 'class', input);
    }

    if (input.template === 'block-suggested-add-button') {
      addBlockAttribute(block, 'service_name_id', '', 'data-searchinputid', input);
      addStandardNavigationAttributes(block);
    }
    if (input.template === 'block-suggested-usernames') {
      addBlockAttribute(block, 'id', '', 'data-id', input);
      addBlockAttribute(block, 'usernameAction', '', 'data-username-action', input);

      addBlockAttribute(block, 'action', '', 'data-action', input);
      addBlockAttribute(block, 'usernameTemplate', '', 'data-username-template', input);
      addBlockAttribute(block, 'usernameData', '', 'data-username-data', input);
      addBlockAttribute(block, 'heading', '', 'data-heading', input);
      addBlockAttribute(block, 'rules', '', 'data-rules', input);
      addStandardNavigationAttributes(block);
    }

    if (input.template === 'block-country-input') {
      addBlockAttribute(block, 'id', '', 'id', input);
      addBlockAttribute(block, 'value', '', 'value', input);
      addBlockAttribute(block, 'action', '', 'data-actionchange', input);
      addBlockAttribute(block, 'languages', '', 'data-languages', input);
      addBlockAttribute(block, 'readonly', '', 'readonly', input);
    }

    if (input.template === 'block-loading-icon-row') {
      addBlockAttribute(block, 'class', '', 'class', input);
    }

    if (input.template === 'block-progress-bar') {
      addBlockAttribute(block, 'max', '', 'max', input);
      addBlockAttribute(block, 'value', '', 'value', input);
    }
    if (input.template === 'block-banner') {
      addBlockAttribute(block, 'navigation_data', '', 'data-nav', input);
      addBlockAttribute(block, 'navigation_pane', '', 'data-nav-pane', input);
      addBlockAttribute(block, 'navigation_template', '', 'data-template', input);
      addBlockAttribute(block, 'navigation_action', '', 'data-actionclick', input);
      addBlockAttribute(block, 'navigation_validation', '', 'data-validation', input);

      addBlockAttribute(block, 'context', '', 'banner-context', input);
      addBlockAttribute(block, 'action', '.image-wrapper--inner', 'data-actionclick', input);
      addBlockAttribute(block, 'id', '.image-wrapper--inner', 'data-id', input);
      addBlockAttribute(block, 'actionDetail', '.image-wrapper--inner', 'data-actiondetail', input);
      addBlockAttribute(block, 'class', '', 'class', input);
      addBlockAttribute(block, 'image', 'img', 'src', input);
      if (input.fields.html) {
        addBlockAttribute(block, 'html', '.image-wrapper--inner', 'innerHTML', input);
      }
    }

    if (input.template === 'block-input-box') {
      addBlockAttribute(block, 'class', '', 'class', input);
      addBlockAttribute(block, 'id', '.text-input', 'id', input);
      addBlockAttribute(block, 'value', '.text-input', 'value', input);
      addBlockAttribute(block, 'id2', '', 'for', null, input.fields.id);
      addBlockAttribute(block, 'action', '.text-input', 'data-actionkeyup', input);
      addBlockAttribute(block, 'type', '.input-box', 'type', input);
      addBlockAttribute(block, 'action', '.input-box', 'data-actionkeyup', input);
      addBlockAttribute(block, 'maxlength', '.text-input', 'maxlength', input);
      addBlockAttribute(block, 'placeholder', '.input-box', 'placeholder', null, input.fields.placeholder || '');
      addBlockAttribute(block, 'icon_action', '[input-action]', 'data-actionclick', input);
      addBlockAttribute(block, 'icon', '[input-action]', 'class', input);

      addBlockAttribute(block, 'label_class', '', 'class', input);
    }

    if (input.template === 'block-carousel') {
      addBlockAttribute(block, 'slick', '', 'data-slick', input);
      addBlockAttribute(block, 'id', '', 'data-id', input);
    }
    if (input.template === 'block-animation') {
      addBlockAttribute(block, 'animation', '', 'data-lottie-animation', input);
      addBlockAttribute(block, 'id', '', 'data-id', input);
      addBlockAttribute(block, 'class', '', 'class', input);
      addBlockAttribute(block, 'action', '', 'data-actionclick', input);

      addBlockAttribute(block, 'actionDetail', '', 'data-actiondetail', input);
    }

    if (input.template === 'block-insertFrame') {
      addBlockAttribute(block, 'id', '', 'id', input);
      addBlockAttribute(block, 'src', '', 'src', input);
      addBlockAttribute(block, 'blockRef', '', 'blockRef', input);
    }

    if (input.template === 'block-import-item') {
      addBlockAttribute(block, 'id', '', 'data-id', input);
      addBlockAttribute(block, 'name', '.import-name', 'innerText', input);
      addBlockAttribute(block, 'disabled', '', 'class', input);
      addBlockAttribute(block, 'username', '.import-username', 'innerText', input);
      addBlockAttribute(block, 'icon', '.f-icn', 'class', null, 'f-icn-' + input.fields.icon);

      const avatar = input.fields.avatar || config.app.DEFAULT_SERVICE_ICON;
      addBlockAttribute(block, 'avatar', '.import-avatar', 'src', null, avatar);

      addStandardNavigationAttributes(block);
    }

    if (input.template === 'block-premium-tab') {
      addBlockAttribute(block, 'id', '', 'data-id', input);
      addBlockAttribute(block, 'name', '.premium__tab_title', 'innerText', input);
      addBlockAttribute(block, 'price', '.premium__tab_price', 'innerText', input);
      addBlockAttribute(block, 'duration', '.premium__tab_duration', 'innerText', input);
      addBlockAttribute(block, 'disabled', '', 'class', input);
    }

    if (input.template === 'block-special-characters-table') {
      addBlockAttribute(block, 'id', '', 'data-id', input);
    }
    if (input.template === 'block-special-character-select-cell') {
      addBlockAttribute(block, 'id', '', 'data-id', input);
      addBlockAttribute(block, 'primary_text', '#special-character-cell-span', 'innerText', input);
      addBlockAttribute(block, 'action', '', 'data-actionclick', input);
      addBlockAttribute(block, 'selected', '', 'data-selected', input);
    }

    // Tabbed picker
    if (input.template === 'block-tabbed-picker') {
      addBlockAttribute(block, 'id', '.tabbed-picker', 'id', input);
    }
    // Tabbed picker
    if (input.template === 'block-tabbed-picker-tab') {
      addBlockAttribute(block, 'selected', '', 'data-selected', input);
      addBlockAttribute(block, 'action', '', 'data-actionclick', input);
      addBlockAttribute(block, 'primary_text', '', 'innerText', input);
      addBlockAttribute(block, 'value', '', 'data-value', input);
    }

    if (input.template === 'block-no-items-row') {
      addBlockAttribute(block, 'primary_text', '.column > span', 'innerText', input);

      addBlockAttribute(block, 'class', '', 'class', input);
    }

    return block;
  } catch (err) {
    console.error(err);
  }
};

// I N I T
window.z2hTemplates = new z2hTemplates();

module.exports = window.z2hTemplates;
