module.exports = ({ element, section, z2hApp }) => {
  // Get username from input field
  const username = $(section)
    .find('#share-username')
    .val()
    .replace(/(\s)+/g, ' ') // Replace multiple spaces and replace with one
    .trim() // Remove trailing/preceeding whitespace
    .substr(0, 50); // Limit to 50 chars

  const endpoint = 'users/@me';
  const data = JSON.stringify({ name: username });

  $('.row.profile-name .primary-text').text(username);
  window.state.userData.name = username;
  z2hApp.requestData('post', z2hApp.apiConfig.version, endpoint, data).then(response => {
    console.info('Update user response:', response);
    z2hApp.fetchFullUserDetails();
  });

  const paneDetails = window.getActivePaneDetails('#pane-2');

  z2hApp.paneNavigation('shareInvite', paneDetails.pane, paneDetails.activePaneIndex + 1);
};
