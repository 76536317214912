const viewService = require('../services/viewService');

module.exports = (...parms) => {
  $('.overlay').removeClass('busy');
  return viewService(...parms).then(data => {
    const cacheObj = { ...data, exp: Date.now() + 10000, website: window.state.currentTabUrl };
    localStorage.setItem('viewServiceExtensionCache', JSON.stringify(cacheObj));
    return data;
  });
};
