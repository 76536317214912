const z2hApp = require('../../app/z2hApp');
const _ = require('lodash');
module.exports = (params) => {
  const service = _.cloneDeep(window.z2hApp.pageData.service);
  const pageId = window.z2hApp.active.pageId;

  // const doNotSendGenSettings =
  //   JSON.stringify(service.fieldsSaved[pageId].generation_settings) ===
  //   JSON.stringify(service.fieldsUnsaved[pageId].generation_settings);
  service.fieldsSaved[pageId] = _.cloneDeep(service.fieldsUnsaved[pageId]);

  // I'm not sure what this object is for, but unless we clear it, we always get a
  // "There are unsaved changes. Are you sure you want to go back?" message.
  // z2hApp.temporary = {};

  // Update field name on Edit Service Details page
  $('#' + params.z2hApp.active.pageId)
    .find('.primary-text-content')
    .text(service.fieldsSaved[pageId].name);

  //If we hit this from edit forghettible service menu then we need to update right away
  //if (params.z2hApp.pageData.service.resetFromContext) {
  z2hApp.pageData.service.fieldsSaved[pageId].name = service.fieldsSaved[pageId].name;
  const formattedField = _.cloneDeep(service.fieldsSaved[pageId]);

  z2hApp.pageData.service.fields[pageId] = { ...service.fields[pageId], ...formattedField };

  window.state.currentService.fields[pageId] = _.cloneDeep(service.fieldsSaved[pageId]);

  z2hApp.pageData.service.fields[pageId] = formattedField;

  const fieldToSend = _.cloneDeep(formattedField);
  // Make sure we don't send any field access_key or recovery_timestamps object
  // to the server or this API request will fail (invalid request)
  delete fieldToSend.access_key;
  delete fieldToSend.old_access_key;
  delete fieldToSend.recovery_timestamps;
  // if (doNotSendGenSettings) {
  //   delete fieldToSend.generation_settings;
  // }

  window.serviceFieldPromises[service.id] = null;
  z2hApp.temporary = {};

  delete z2hApp.pageData.service.fieldsUnsaved[pageId];
  const serviceData = {
    name: z2hApp.pageData.service.name,
    username: z2hApp.pageData.service.username,
    website: z2hApp.pageData.service.website,
    username_secondary: z2hApp.pageData.service.username_secondary,
  };
  const paneDetails = window.getActivePaneDetails('#pane-2');
  z2hApp.paneNavigation('viewService_loading', paneDetails.pane, paneDetails.activePaneIndex+1 , null);
  z2hApp.requestData('post', '', 'services/' + z2hApp.pageData.service.id, serviceData).then((response) => {
    const services = window.state.currentServices;
    let updateService = services?.find((s) => s.id === z2hApp.pageData.service.id);
    for (const key in serviceData) {
      updateService[key] = serviceData[key];
    }
    window.state.currentService = updateService;
    z2hApp.requestData('post', '', 'fields/' + pageId, fieldToSend).then((response) => {
      // Go back to the doodlepad (same as if you have reset/recovered a password)
      window.serviceFieldPromises[service.id] = null;
      z2hApp.pageData.service.fields[pageId] = service.fieldsSaved[pageId];
      if (params.z2hApp.pageData.postUpdateAction) {
        return params.z2hApp.pageData.postUpdateAction(pageId, formattedField);
      } else if (params.z2hApp.pageData.service.resetFromContext) {
        const { biometricsOrDoodle } = require('../../actions/services');
        biometricsOrDoodle({
          currentTarget: null,
          z2hApp,
          pane,
          newPaneIndex: paneDetails.activePaneIndex + 1,
          template: { biometrics: 'viewService1_biometrics', doodlepad: 'viewService1_doodlepad' },
        });
      } else {
        z2hApp.paneNavigation('editServiceDetails', paneDetails.pane, paneDetails.activePaneIndex-1, null);
      }
    });
    //}
  });
};
