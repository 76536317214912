const { words } = require('lodash');

module.exports = ({ currentTarget, init = null }) => {
  const actions = require('../../actions');
  const simpleSymbols = z2hApp.simpleSymbols;

  //Called from the page
  if (init) {
    if (!init.lowercase && !init.uppercase && !init.numbers && !init.simple_symbols && !init.symbols) {
      //No letters/numbers/symbols used in this password
      //Hide the letters/numbers/symbols counter
      actions.showHideContent({
        currentTarget: null,
        targetId: 'letterNumberSymbolCustomLengthWrapper',
        collapsed: true,
      });

      $('#letterNumberSymbolCustomLength').val(init.length);
    } else {
      //There is at least one letter/number/symbol used in this password
      //Set saved length or default
      $('#letterNumberSymbolCustomLength').val(init.length > 0 ? init.length : 16);
    }
    if (!init.words) {
      //No words used in this password
      //Hide the words counter
      actions.showHideContent({ currentTarget: null, targetId: 'wordsCustomLengthWrapper', collapsed: true });

      $('#wordsCustomLength').val(0);
    } else {
      //There is at least one word used in this password
      //Set saved length or default
      $('#wordsCustomLength').val(init.words > 0 ? init.words : 3);
      //Show the words counter as it is hidden by default
      actions.showHideContent({ currentTarget: null, targetId: 'wordsCustomLengthWrapper', collapsed: false });
    }

    //Set correct switch status' based on server data
    const lettersSwitch = $('[data-actionclick="selectLetterNumberSymbolWord"][value="LETTERS"]');
    if (init.lowercase || init.uppercase) {
      //Set switch active
      lettersSwitch.attr('data-selected', true).attr('checked', 'checked').prop('checked', true).addClass('active');
    } else {
      //Set switch disabled
      lettersSwitch.attr('data-selected', false).attr('checked', '').prop('checked', false).removeClass('active');
    }
    const numbersSwitch = $('[data-actionclick="selectLetterNumberSymbolWord"][value="NUMBERS"]');
    if (init.numbers) {
      //Set switch active
      numbersSwitch.attr('data-selected', true).attr('checked', 'checked').prop('checked', true).addClass('active');
    } else {
      //Set switch disabled
      numbersSwitch.attr('data-selected', false).attr('checked', '').prop('checked', false).removeClass('active');
    }
    const symbolsSwitch = $('[data-actionclick="selectLetterNumberSymbolWord"][value="SYMBOLS"]');
    if (init.simple_symbols || init.symbols) {
      //Set switch active
      symbolsSwitch.attr('data-selected', true).attr('checked', 'checked').prop('checked', true).addClass('active');
    } else {
      //Set switch disabled
      symbolsSwitch.attr('data-selected', false).attr('checked', '').prop('checked', false).removeClass('active');

      const symbols = $('.special-character-cell');
      symbols.addClass('disabled');
    }
    const wordsSwitch = $('[data-actionclick="selectLetterNumberSymbolWord"][value="WORDS"]');
    if (init.words) {
      //Set switch active
      wordsSwitch.attr('data-selected', true).attr('checked', 'checked').prop('checked', true).addClass('active');
    } else {
      //Set switch disabled
      wordsSwitch.attr('data-selected', false).attr('checked', '').prop('checked', false).removeClass('active');
    }
    return;
  }

  const fieldData = z2hApp.pageData.service.fieldsUnsaved[z2hApp.active.pageId];
  // eslint-disable-next-line camelcase
  const { generation_settings } = fieldData;

  //Called from the event
  const DOMTarget = $(currentTarget);
  //Set current switch state
  let selected = true;
  if (DOMTarget.attr('data-selected') === 'true') {
    //Switch is currently on so turn it off
    DOMTarget.attr('data-selected', false).attr('checked', '').prop('checked', false).removeClass('active');
    selected = false;
  } else {
    //Switch is currently off so turn it on
    DOMTarget.attr('data-selected', true).attr('checked', 'checked').prop('checked', true).addClass('active');
  }

  const type = DOMTarget.val();
  const activeLetterNumberSymbolSwitches = $(
    '[data-actionclick="selectLetterNumberSymbolWord"][data-selected="true"]:not([value="WORDS"])',
  );

  // activeLetterNumberSymbolSwitches.each(function(index){
  //   console.log('activeLetterNumberSymbolSwitches: '+index,$(this).val());
  // });
  // console.log('activeLetterNumberSymbolSwitches.length',activeLetterNumberSymbolSwitches.length);

  //Show/hide the length tab
  if (type === 'LETTERS' || type === 'NUMBERS' || type === 'SYMBOLS') {
    if (activeLetterNumberSymbolSwitches.length === 0) {
      //There are no active switches
      actions.showHideContent({
        currentTarget: null,
        targetId: 'letterNumberSymbolCustomLengthWrapper',
        collapsed: true,
      });

      generation_settings.length = 0;
      generation_settings.uppercase = false;
      generation_settings.lowercase = false;
      generation_settings.numbers = false;
      generation_settings.symbols = false;
      generation_settings.simple_symbols = false;
      generation_settings.exclude = '';
      //The password therefore has to be Word based
      //Turn on the 'Words' switch
      const wordsSwitch = $('[data-actionclick="selectLetterNumberSymbolWord"][value="WORDS"]');
      wordsSwitch.attr('data-selected', true).attr('checked', 'checked').prop('checked', true).addClass('active');
      if ($('#wordsCustomLength').val() == 0) {
        $('#wordsCustomLength').val(3);
        generation_settings.words = 3;
      }
      $('#letterNumberSymbolCustomLength').val(0);
      generation_settings.length = 0;
      actions.showHideContent({
        currentTarget: null,
        targetId: 'letterNumberSymbolCustomLengthWrapper',
        collapsed: true,
      });

      //Show the word counter
      actions.showHideContent({ currentTarget: null, targetId: 'wordsCustomLengthWrapper', collapsed: false });
      // eslint-disable-next-line camelcase
      generation_settings.words = parseInt($('#wordsCustomLength').val());
    } else {
      //There is at least one active switch
      actions.showHideContent({
        currentTarget: null,
        targetId: 'letterNumberSymbolCustomLengthWrapper',
        collapsed: false,
      });


      const lettersActive = $('[data-actionclick="selectLetterNumberSymbolWord"][value="LETTERS"].active');
      const numbersActive = $('[data-actionclick="selectLetterNumberSymbolWord"][value="NUMBERS"].active');
      const symbolsActive = $('[data-actionclick="selectLetterNumberSymbolWord"][value="SYMBOLS"].active');
      const wordsActive = $('[data-actionclick="selectLetterNumberSymbolWord"][value="WORDS"].active');

      // eslint-disable-next-line eqeqeq
      if ($('#letterNumberSymbolCustomLength').val() == 0) {
        if (wordsActive.length > 0) {
          $('#letterNumberSymbolCustomLength').val(4);
          generation_settings.length = 4;
        } else {
          $('#letterNumberSymbolCustomLength').val(16);
          generation_settings.length = 16;
        }
      }


      if (lettersActive.length == 0 && numbersActive.length === 0 && symbolsActive.length === 0) {
        $('#letterNumberSymbolCustomLength').val(0);
        generation_settings.length = 0;
      }

      const activeSymbols = $('.special-character-cell[data-selected="true"]').text();
      const allSymbols = $('.special-character-cell').text();
      const excludedSymbols = allSymbols
        .split('')
        .filter((f) => activeSymbols.indexOf(f) < 0)
        .join('');

      generation_settings.length = parseInt($('#letterNumberSymbolCustomLength').val());
      generation_settings.uppercase = lettersActive.length > 0;
      generation_settings.lowercase = lettersActive.length > 0;
      generation_settings.numbers = numbersActive.length > 0;

      const isSimpleSymbols = activeSymbols === simpleSymbols.join('');

      generation_settings.symbols = symbolsActive.length > 0 && !isSimpleSymbols;
      generation_settings.simple_symbols = symbolsActive.length > 0 && isSimpleSymbols;
      generation_settings.exclude = symbolsActive.length > 0 && !isSimpleSymbols ? excludedSymbols : '';
      if (type === 'LETTERS') {
        //Turn off the 'Words' switch
        const wordsSwitch = $('[data-actionclick="selectLetterNumberSymbolWord"][value="WORDS"]');
        wordsSwitch.attr('data-selected', false).attr('checked', '').prop('checked', false).removeClass('active');
        //Hide the word counter
        actions.showHideContent({ currentTarget: null, targetId: 'wordsCustomLengthWrapper', collapsed: true });

        // eslint-disable-next-line camelcase
        generation_settings.words = 0;
        $('#wordsCustomLength').val(0);
        $('#letterNumberSymbolCustomLength').val(16);

      }
    }
  }

  if (type === 'SYMBOLS') {
    const symbols = $('.special-character-cell');
    const symbolsActive = $('[data-actionclick="selectLetterNumberSymbolWord"][value="SYMBOLS"].active');
    if (symbolsActive.length > 0) {
      symbols.removeClass('disabled');

      //If there are no symbols selected, select the default ones
      const symbolsActive = $('.special-character-cell[data-selected="true"]').text();
      if (symbolsActive.length === 0) {
        $('.special-character-cell').each((idx, elem) => {
          const elemValue = $(elem).text();
          let select = simpleSymbols.indexOf(elemValue) >= 0;
          $(elem).attr('data-selected', select);
        });
      }
    } else {
      symbols.addClass('disabled');
    }
  }

  //Show/hide the words tab
  if (type === 'WORDS') {
  
    if (!selected) {
      //'Words' switch has been turned to 'off'
      //Turn on the 'Letters' switch IF all the switches are currently off
      if (activeLetterNumberSymbolSwitches.length === 0) {
        const letters = $('[data-actionclick="selectLetterNumberSymbolWord"][value="LETTERS"]');
        letters.attr('data-selected', true).attr('checked', 'checked').prop('checked', true).addClass('active');

        //$('#letterNumberSymbolCustomLengthWrapper').show();
        actions.showHideContent({
          currentTarget: null,
          targetId: 'letterNumberSymbolCustomLengthWrapper',
          collapsed: false,
        });
        $('#letterNumberSymbolCustomLength').val(16);
        generation_settings.length = 16;
      }
      //Hide the word counter
      actions.showHideContent({ currentTarget: null, targetId: 'wordsCustomLengthWrapper', collapsed: true });
      actions.showHideContent({
        currentTarget: null,
        targetId: 'letterNumberSymbolCustomLengthWrapper',
        collapsed: false,
      });
      actions.showHideContent({
        currentTarget: null,
        collapsed: true,
      });
      // eslint-disable-next-line camelcase
      generation_settings.words = 0;
      $('#wordsCustomLength').val(0);


    } else {
      //'Words' switch has been turned to 'on'
      //Turn off the 'Letters' switch
      const letters = $('[data-actionclick="selectLetterNumberSymbolWord"][value="LETTERS"]');
      letters.attr('data-selected', false).attr('checked', '').prop('checked', false).removeClass('active');
      //Remove letters from generation_settings
      generation_settings.uppercase = false;
      generation_settings.lowercase = false;
      //Hide the letters/numbers/symbols counter IF they are all currently off
      if (activeLetterNumberSymbolSwitches.length === 0) {
        //$('#letterNumberSymbolCustomLengthWrapper').hide();
        actions.showHideContent({
          currentTarget: null,
          targetId: 'letterNumberSymbolCustomLengthWrapper',
          collapsed: true,
        });

        generation_settings.length = 0;
        generation_settings.numbers = false;
        generation_settings.symbols = false;
        generation_settings.simple_symbols = false;
        generation_settings.exclude = '';
      }

      // eslint-disable-next-line eqeqeq
      if ($('#wordsCustomLength').val() == 0) {
        $('#wordsCustomLength').val(3);
        generation_settings.words = 3;
      }

      const lettersActive = $('[data-actionclick="selectLetterNumberSymbolWord"][value="LETTERS"].active');
      const numbersActive = $('[data-actionclick="selectLetterNumberSymbolWord"][value="NUMBERS"].active');
      const symbolsActive = $('[data-actionclick="selectLetterNumberSymbolWord"][value="SYMBOLS"].active');

      if (lettersActive.length > 0 || numbersActive.length > 0 || symbolsActive.length > 0) {
        $('#letterNumberSymbolCustomLength').val(4);
        generation_settings.length = 4;
        actions.showHideContent({
          currentTarget: null,
          targetId: 'letterNumberSymbolCustomLengthWrapper',
          collapsed: false,
        });
      } else if (lettersActive.length === 0 && numbersActive.length === 0 && symbolsActive.length === 0) {
        $('#letterNumberSymbolCustomLength').val(0);
        generation_settings.length = 0;
        actions.showHideContent({
          currentTarget: null,
          targetId: 'letterNumberSymbolCustomLengthWrapper',
          collapsed: true,
        });
      }

      //$('#wordsCustomLengthWrapper').show();
      actions.showHideContent({ currentTarget: null, targetId: 'wordsCustomLengthWrapper', collapsed: false });

      // eslint-disable-next-line camelcase
      generation_settings.words = parseInt($('#wordsCustomLength').val());
    }
  }
};
