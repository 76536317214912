// References to callbacks which will be called on success/failure
let _onSuccess;
let _onFailure;

const showResponseErrorMessage = require('../../action_helpers/showResponseErrorMessage');
const getFullServiceDetails = require('../../getPageData_helpers/getFullServiceDetails');

module.exports = async ({ z2hApp, onSuccess, onFailure, batch = false, groupId = window.state.selectedGroupId }) => {
  console.log('onFailure: ', onFailure);
  // Either use the callbacks we've been give, or some defaults
  _onSuccess = onSuccess || ((_) => z2hApp.paneNavigation('viewService', $('#pane-2'), 1, null));
  _onFailure = onFailure || ((_) => hidePane3());

  // Call API to add new service
  const endpoint = `groups/${window.state.movingService.from.group}/move-service`;
  const move = {
    service_to_move: window.state.movingService.from.service.id,
    group_to_move_to: z2hApp.pageData.service.groupId,
    //group_to_move_to: groupId,
  };
  const response = await z2hApp.requestData('post', '', endpoint, move).catch(() => {
    _onFailure();
  });
  //console.log(response);

  if (!batch) {
    if (Math.floor(response.status / 100) !== 2) throw response;
    window.state.selectedServiceId = window.state.movingService.from.service.id;

    const serviceDetails = await getFullServiceDetails().catch((_) => ({}));
    // Navigate to viewService page
    // getFullServiceDetails().then(serviceDetails => {
    serviceDetails.movingService = true;

    window.state.currentService = serviceDetails;
    window.state.newlyAddedServiceDetailsToDisplay = serviceDetails;
    const groupsList = window.state.groupsList || [];
    const group = groupsList.find((g) => g.id === groupId) || {};
    const groupServices = group.services || [];
    //push keeps references in tact, so no need to copy the array
    if (!groupServices.find((s) => s.id === serviceDetails.id)) {
      groupServices.push(serviceDetails);
    }

    //Remove service from the original group
    const groupsToRemove = groupsList.find((g) => g.id === window.state.movingService.from.group);
    const groupServicesToRemove = groupsToRemove.services || [];
    //Filter creates a new shallow array, so copy what we have here.
    groupsToRemove.services = groupServicesToRemove.filter((s) => s.id !== serviceDetails.id);
  }

  // Refresh list of services displayed for group
  if (!batch) {
    z2hApp.showServicesForGroup(groupId, false, true);
  }
  _onSuccess();
};
