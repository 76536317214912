const html2canvas = require('html2canvas');

const updateGroupIcon = require('../../action_helpers/updateGroupIcon');

// =================================================================================================

// Function to convert rgb color to hex format
function rgb2hex(rgb) {
  const hexDigits = '0123456789abcdef'.split('');
  const hex = x => (isNaN(x) ? '00' : hexDigits[(x - (x % 16)) / 16] + hexDigits[x % 16]);
  const rgbParts = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
  return '#' + hex(rgbParts[1]) + hex(rgbParts[2]) + hex(rgbParts[3]);
}

// =================================================================================================

function chooseGroupIcon({ currentTarget, z2hApp }) {
  const pane = '#pane-2';
  //$(pane).addClass('busy');
  const lochyLoadingText = require('../../page_helpers/paneBusyText');
  const overlays = require('../../app/overlays');
  overlays.makePaneLookBusy(2, { text: lochyLoadingText['chooseGroupIcon']() });

  const newGroup = window.state.selectedGroupId === 'new';

  const backgroundColor = rgb2hex($(currentTarget).css('background-color'));

  const clone = $(currentTarget)
    .clone()
    .css('position', 'absolute')
    .css('left', '-10000px')
    .appendTo('body');

  clone
    .removeClass('login-avatar')
    .css('height', '150px')
    .css('width', '150px');

  // Convert the selected image to a 150x150 PNG and update the group icon with it
  html2canvas(clone[0], { backgroundColor, height: 60, width: 60 })
    .then(canvas => {
      //canvas.crossOrigin = "anonymous";
      return canvas.toDataURL('image/png');
    })
    // domtoimage
    //   .toPng(currentTarget, { height: 150, width: 150 })
    .then(dataUrl => {
      clone.remove();
      console.log(dataUrl);
      let base64Image = dataUrl.split(',')[1];
      base64Image = dataUrl;
      if (newGroup) {
        window.state.temporaryGroupIcon = base64Image;
        // Update any images on screen which have a data-id which matches the group ID
        $(`img[data-id=newGroup]`).attr('src', dataUrl);
        $(`div[data-id="newGroup"] img`).attr('src', dataUrl);
      } else {
        return updateGroupIcon(base64Image);
      }
    })
    .catch(error => console.error('oops, something went wrong!', error))
    .then(_ => {
      // Go back to previous pane
      //$(pane).removeClass('busy');
      overlays.makePaneLookIdle(2);
      const activePaneIndex = $(pane + '-inner')
        .children('.active')
        .index();
      z2hApp.paneNavigation('back', $(pane), activePaneIndex - 1);
    });
}

module.exports = chooseGroupIcon;
