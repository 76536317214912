const config = require('../../../config');
const getQueryStringParameters = require('../../utils/getQueryStringParameters');

const strings = config.strings;

const NEXT_PAGE = 'onboarding_stage1';

module.exports = ({ data, nav }) => {
  // Check if we've been given any query string parameters for deep link referral codes
  const qsParms = getQueryStringParameters();
  let deepLinkReferralCode = (qsParms.find(q => q.key.toLowerCase() === 'referral') || {}).value;

  // Set focus on referral code field
  // NOTE: This doesn't work in iOS Safari
  setTimeout(_ => {
    $('#create-account-referral-code__referral-code').focus();
  }, 2000);

  return {
    template: 'block-section-page',

    fields: {
      id: 'create-account-referral-code',
      header: strings.CREATE_ACCOUNT_REFERRAL_HEADER(),

      navigation_right: strings.SKIP(),
      navigation_right_data: parseInt(nav) + 1,
      navigation_right_template: NEXT_PAGE,
    },

    rows: [
      {
        template: 'block-section-row',
        fields: { primary_heading: strings.CREATE_ACCOUNT_REFERRAL_HEADING() },
        columns: [
          {
            template: 'block-info-text',
            fields: { text: config.strings.REFERRAL_ENTRY_MESSAGE_1() },
          },
        ],
      },
      {
        template: 'block-section-row',
        columns: [
          {
            template: 'block-editable-primary-text',
            fields: {
              for: 'create-account-referral-code__referral-code',
              id: 'create-account-referral-code__referral-code',
              name: 'referral-code',
              placeholder: strings.CREATE_ACCOUNT_REFERRAL_PLACEHOLDER(),
              primary_text: deepLinkReferralCode || '',
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: { primary_text: '' },
        columns: [
          {
            template: 'block-icn-button',
            fields: {
              icon: 'check-solid',
              class: 'icn-button-lrg',
              navigation_data: parseInt(nav) + 1,
              navigation_template: NEXT_PAGE,
              navigation_validation: 'createAccount_processReferralCode',
            },
          },
        ],
      },
    ],
  };
};
