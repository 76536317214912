const z2hApp = require('../../app/z2hApp');

module.exports = async () => {
  // Get the list of all groups
  //We're going to use the existing promise if we have it otherwise we're going to go and get from the server. For some reason this takes ages!
  const groupsList = await window.z2hApp.getGroupsList();

  const groups = await groupsList
    //TODO why do we need to do this?
    //Only show groups that have a name and users
    .filter((group) => group.name && group.users)
    .map((group) => {
      if (!group.name || !group.users) return;
      const personalTypeInGroup = (group.users[window.state.userData.id] || {}).member_type;
      const userIsAdmin = personalTypeInGroup === 1;
      const userIsOwner = personalTypeInGroup === 2;
      const userIsAdminOrOwner = userIsAdmin || userIsOwner;
      const showInviteIcon = !group.personal && userIsAdminOrOwner;
      return { ...group, showInviteIcon };
    });

  const personalGroup = await z2hApp.personalGroup();
  const myGroups = await z2hApp.myGroups();
  const otherGroups = await z2hApp.groupsOtherPeopleOwn();

  return {
    groupsList: groups,
    personalGroup,
    myGroups: myGroups.map((group) => {
      if (!group.name || !group.users) return;
      const personalTypeInGroup = (group.users[window.state.userData.id] || {}).member_type;
      const userIsAdmin = personalTypeInGroup === 1;
      const userIsOwner = personalTypeInGroup === 2;
      const userIsAdminOrOwner = userIsAdmin || userIsOwner;
      const showInviteIcon = !group.personal && userIsAdminOrOwner;
      return { ...group, showInviteIcon };
    }),

    otherGroups: otherGroups
      .map((group) => {
        let owner;
        if (group.users) {
          const usersArray = Object.entries(group.users);
          owner = usersArray.find((u) => u[1].member_type === 2);
        }
        return owner ? { owner_name: owner[1].name, ...group } : { owner_name: '', ...group };
      })
      .sort((a, b) => (a.owner_id > b.owner_id ? 1 : -1)),
    nonPersonalGroupsLength: groups.filter((g) => !g.personal).length,
  };
};
