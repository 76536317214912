module.exports = ({ currentTarget, z2hApp }) => {
  // Get source of selected img/icon
  const iconUrl = $(currentTarget)
    .find('img')
    .attr('src');

  // Store in state
  window.z2hApp.pageData.service.serviceData.icon = iconUrl;

  // Go to next page
  //const pane = $('#pane-3');
  //const activePaneIndex = pane
  //  .find('.section-pane-wrapper')
  //  .children('.active')
  //  .index();
  const paneDetails = window.getActivePaneDetails('#pane-2');

  z2hApp.paneNavigation('addService_website', paneDetails.pane, paneDetails.activePaneIndex + 1);
};
