const { strings, urls } = require('../../../config');
const { infoTextRow } = require('../../page_helpers/components');

const checkListItem = ({ text }) => ({
  template: 'block-section-row',
  fields: {
    //class: 'no-border',
    class2: 'onboard__list',
  },
  columns: [
    {
      template: 'block-icn',
      fields: {
        icon: 'cancel-solid',
      },
    },

    {
      template: 'block-info-text',
      fields: {
        text: text,
        secondary_class: 'nocolour',
      },
    },
  ],
});

const failedPasswordCriteria = errors => {
  //Output a row for each of the checks.
  const rows = [];

  for (const e of errors) {
    rows.push(checkListItem({ text: e }));
  }
  return rows;
};

module.exports = ({ nav }) => {
  const password = window.z2hApp.pageData.healthcheck.password;
  let rows = [];
  rows.push(
    password.compromised === 0
      ? infoTextRow(strings.HEALTHCHECK_DETAILS_PASSWORD_INFO_NO_BREACH(), false, true, true)
      : infoTextRow(strings.HEALTHCHECK_DETAILS_PASSWORD_INFO_X_BREACH()(password.compromised), false, true, true),
  );
  rows.push(
    password.owasp.strong
      ? infoTextRow(strings.HEALTHCHECK_DETAILS_PASSWORD_STRENGTH_INFO_STRONG(), false, true, true)
      : infoTextRow(strings.HEALTHCHECK_DETAILS_PASSWORD_STRENGTH_INFO_WEAK(), false, false, true),
  );
  if (!password.owasp.strong) rows = [...rows, ...failedPasswordCriteria(password.owasp.errors)];
  rows.push({
    template: 'block-insertable',
  });
  rows.push(infoTextRow(strings.HEALTHCHECK_DETAILS_RESET_NOTE(), true, false, true));
  rows.push({
    template: 'block-section-row',
    fields: { class: 'centered-text', class2: 'no-border' },
    columns: [
      {
        template: 'block-text-button',
        fields: {
          text: strings.HEALTHCHECK_DETAILS_RESET_BUTTON(),
          action: 'beginAddNewService',
          class: 'cta-button',
        },
      },
    ],
  });

  return {
    template: 'block-section-page',
    fields: {
      id: 'healthcheck_details_password',
      active: false,
      header: strings.HEALTHCHECK_DETAILS_PASSWORD_HEADER(),
      navigation_left: strings.BACK(),
      navigation_left_data: nav - 1,
      navigation_left_template: 'back',
      navigation_right: strings.DONE(),
      navigation_right_data: 0,
      navigation_right_template: 'back',

      navbar: false,
    },

    rows: rows,
  };
};
