const { strings } = require('../../../config')
const lochyLoadingTemplate = require('./lochyLoadingTemplate');

module.exports = () => ({
  ...(lochyLoadingTemplate({
    id: 'updatingForghettible_loading',
    heading: strings.UPDATING_FORGHETTIBLE(),
    lochy: 'confetti',
  }
  ))
})