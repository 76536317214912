module.exports = {
  sendDeleteAccountConfirmationCode: require('./sendDeleteAccountConfirmationCode'),
  openAndroidAutofillSettingsPage: require('./openAndroidAutofillSettingsPage'),
  sendMigrationVerificationCode: require('./sendMigrationVerificationCode'),
  copyReferralLinkToClipboard: require('./copyReferralLinkToClipboard'),
  openSubscriptionPricingUrl: require('./openSubscriptionPricingUrl'),
  openAccountSecurityUrl: require('./openAccountSecurityUrl'),
  updateDefaultUsername: require('./updateDefaultUsername'),
  openTermsOfServiceUrl: require('./openTermsOfServiceUrl'),
  openPrivacyPolicyUrl: require('./openPrivacyPolicyUrl'),
  openAcceptableUseUrl: require('./openAcceptableUseUrl'),
  confirmMigrationCode: require('./confirmMigrationCode'),
  toggleUserPreference: require('./toggleUserPreference'),
  uploadProfilePicture: require('./uploadProfilePicture'),
  openCookiePolicyUrl: require('./openCookiePolicyUrl'),
  updateReferralCode: require('./updateReferralCode'),
  logOutOfAllDevices: require('./logOutOfAllDevices'),
  updateUserCountry: require('./updateUserCountry'),
  emailReferralLink: require('./emailReferralLink'),
  updateProfileName: require('./updateProfileName'),
  openVideoHelpUrl: require('./openVideoHelpUrl'),
  openFeedbackUrl: require('./openFeedbackUrl'),
  deleteAccount: require('./deleteAccount'),
  openHelpUrl: require('./openHelpUrl'),
  toggleTouchId: require('./toggleTouchId'),
  reonboarding: require('./reonboarding'),
  takeProfilePicture: require('./takeProfilePicture'),
  gotoPremium: require('./gotoPremium'),
  manageSubscriptions: require('./manageSubscriptions'),
  openHowItWorksUrl: require('./openHowItWorksUrl'),
  appRating: require('./appRating'),
  inviteAFriend: require('./inviteAFriend'),
  selectBadge: require('./selectBadge'),
  legacyUserBonus: require('./legacyUserBonus'),
  reloadWithReward: require('./reloadWithReward'),
  requestPushNotifications: require('./requestPushNotifications'),
  gotoPremium2: require('./gotoPremium2'),
  deleteAccountToast: require('./deleteAccountToast'),
  migrateAccountToast: require('./migrateAccountToast'),
  confirmUpdateCountry: require('./confirmUpdateCountry'),
  storeBiometricsForGroup: require('./storeBiometricsForGroup'),
  toggleBiometricsState: require('./toggleBiometricsState'),
  gotoProfile: require('./gotoProfile'),
  enterNewNumber: require('./enterNewNumber'),
  writeInAppMessage: require('./writeInAppMessage'),

};
