function getActivePaneDetails(preSelector) {
  // Get active pane details
  //We can pass in a preselector to make sure we get the right pane.
  //If the overlay is invisible (onboarding) then we use that
  let sectionPaneWrapper = $((preSelector || '') + ' .section-pane.active').closest('.section-pane-wrapper');
  if ($('.overlay').is(':visible')) {
    sectionPaneWrapper = $('.overlay').find('.section-pane-wrapper');
  }
  if (!sectionPaneWrapper.length) {
    sectionPaneWrapper = $((preSelector || '') + ' .section-pane').closest('.section-pane-wrapper');
  }
  const pane = sectionPaneWrapper.parent();
  let activePaneIndex = sectionPaneWrapper.children('.active').index();

  if (activePaneIndex < 0) {
    activePaneIndex = sectionPaneWrapper.children('').length - 1;
  }
  const details = { sectionPaneWrapper, pane, activePaneIndex };
  //console.log('getActivePaneDetails: ', details);
  return details;
}

module.exports = getActivePaneDetails;

window.getActivePaneDetails = getActivePaneDetails;
