function getImageDims(uploadedImageUrl) {
  return new Promise(resolve => {
    console.info(Date.now() + ': Loading image...');
    let img = new Image();
    img.onload = _ => {
      console.info(Date.now() + ': Loaded image.');
      const { width, height } = img;

      resolve({ width, height });
    };
    img.src = uploadedImageUrl;
  });
}
module.exports = getImageDims;
