const { strings } = require('../../../config');
const lochyLoadingTemplate = require('./lochyLoadingTemplate');

module.exports = () => ({
  ...lochyLoadingTemplate({
    id: 'verifyPhoneNumber_loading',
    heading: strings.LOCHY_LOADING_TEXT_GROUPSLIST(),
    lochy: 'cool',
  }),
});
