const fetchBase64Image = require('../../action_helpers/fetchBase64Image');
const showToastMessage = require('../../action_helpers/showToastMessage');
const updateGroupIcon = require('../../action_helpers/updateGroupIcon');
const overlays = require('../../app/overlays');

// -----------------------------------------------------------------------------
// Helper functions
// -----------------------------------------------------------------------------

function done(z2hApp, pane) {
  const activePaneIndex = pane
    .find('.section-pane-wrapper')
    .children('.active')
    .index();
  z2hApp.paneNavigation('back', pane, activePaneIndex - 1);
}

// -----------------------------------------------------------------------------

module.exports = ({ currentTarget, z2hApp }) => {
  const lochyLoadingText = require('../../page_helpers/paneBusyText');
  
  overlays.makePaneLookBusy(2, { text: lochyLoadingText['chooseGroupIconFromSearch']() })
  
  const iconUrl = $(currentTarget)
    .find('img')
    .attr('src');

  fetchBase64Image(iconUrl)
    .then(base64Image => {
      if (window.state.selectedGroupId === "new") {
        window.state.temporaryGroupIcon = base64Image;
        // Update any images on screen which have a data-id which matches the group ID
        $(`img[data-id=newGroup]`).attr('src', base64Image);
        $(`div[data-id="newGroup"] img`).attr('src', base64Image);
      } else {
        updateGroupIcon(base64Image)
      }

    })
    .catch(err => showToastMessage('Error updating group image'))
    .then(_ => {
      overlays.makePaneLookIdle(2);
      done(z2hApp, $('#pane-2'));
    });
};
