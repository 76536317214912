module.exports = {
  createAccount_addCustomLogin_doodlepad: require('./createAccount_addCustomLogin_doodlepad'),
  createAccount_newLogin_summary: require('./createAccount_newLogin_summary'),
  createAccount_addCustomLogin: require('./createAccount_addCustomLogin'),
  createAccount_referralCode: require('./createAccount_referralCode'),
  createAccount_onboarding3: require('./createAccount_onboarding3'),
  createAccount_onboarding4: require('./createAccount_onboarding4'),
  createAccount_onboarding5: require('./createAccount_onboarding5'),
  createAccount_onboarding2: require('./createAccount_onboarding2'),
  createAccount_onboarding1: require('./createAccount_onboarding1'),
  createAccount_displayName: require('./createAccount_displayName'),
  createAccount_addLogin: require('./createAccount_addLogin'),
  createAccount_theme: require('./createAccount_theme'),
  verifyDevice1: require('./verifyDevice1'),
  verifyDevice2: require('./verifyDevice2'),
  createDevice1: require('./createDevice1'),
};
