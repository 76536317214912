const getFullServiceDetails = require('../../getPageData_helpers/getFullServiceDetails');
const overlays = require('../../app/overlays');

const INITIAL_DELAY_MS = 500;
const RETRY_IN_MS = 500;

const checkServiceAdded = (z2hApp) => {
  const newServiceStatus = window.state.newServiceStatus;
  if (newServiceStatus === 'pending') {
    // Check again after short period of time
    setTimeout((_) => checkServiceAdded(z2hApp), RETRY_IN_MS);
  } else if (newServiceStatus === 'success') {
    // Set parameter for viewService page
    window.state.selectedServiceId = window.state.newServiceId;
    // Navigate to viewService page
    getFullServiceDetails().then((serviceDetails) => {
      window.state.currentService = serviceDetails;
      window.state.newlyAddedServiceDetailsToDisplay = serviceDetails;
      z2hApp.paneNavigation('viewService', $('#pane-2'), 1, null);
    });
  } else {
    // Error occurred
    /// TODO
    alert('Error: Creating new service failed');
    // Probably want to go back to addService1 page at this point
    z2hApp.paneNavigation('addNewService', $('#pane-2'), 0, null);
  }
};

module.exports = ({ z2hApp }) => {
  // Check after one second to allow page to appear before possibly navigating
  // immediately to the service details.
  overlays.makePaneLookIdle(2);
  setTimeout((_) => checkServiceAdded(z2hApp), INITIAL_DELAY_MS);
};
