const copyToClipboard = require('copy-html-to-clipboard');

const showToastMessage = require('../../action_helpers/showToastMessage');
const strings = require('../../../config').strings;

module.exports = ({ currentTarget, noToast }) => {
  const service = z2hApp.pageData.service;

  const fields = service.fields || {};

  const target = $(currentTarget).attr('name');

  const fieldId = $(currentTarget).closest('.row').attr('id');
  const fieldData = fields[fieldId] || {};

  let password = fieldData.access_key;
  if (target === 'secondary-text') {
    password = fieldData.old_access_key;
  }
  if (!password) return;

  copyToClipboard(password);

  // Display toast/popup to tell user that data has been copied to the clipboard
  // and allow them to visit the service website.
  const name = fieldData.name === 'Password' ? 'password' : fieldData.name;
  let message = '<strong class="toast--title">' + strings.MSG_COPIED_FORGHETTIBLE()(name) + '</strong>';

  if (!noToast) showToastMessage(message, { timeout: 1500 });

  return message;
};
