const { strings, urls } = require('../../../config');
const z2hApp = require('../../app/z2hApp');
const { infoTextRow, thirdheading } = require('../../page_helpers/components');

module.exports = ({ nav, data }) => {
  const breachId = window.state.selectedBreach;
  const breach = window.z2hApp.pageData.healthcheck.breach[breachId];

  const rows = [];
  rows.push({
    template: 'block-section-row',
    columns: [
      {
        template: 'block-text-group',
        fields: {
          primary_text: strings.HEALTHCHECK_DETAILS_USER_TITLE(),
          secondary_text: breach.Name,
        },
      },
    ],
  });
  rows.push({
    template: 'block-section-row',
    columns: [
      {
        template: 'block-text-group',
        fields: {
          primary_text: strings.HEALTHCHECK_DETAILS_USER_DOMAIN(),
          secondary_text: breach.Domain,
        },
      },
    ],
  });
  rows.push({
    template: 'block-section-row',
    columns: [
      {
        template: 'block-text-group',
        fields: {
          primary_text: strings.HEALTHCHECK_DETAILS_USER_DATE(),
          secondary_text: breach.BreachDate,
        },
      },
    ],
  });
  rows.push({
    template: 'block-section-row',
    columns: [
      {
        template: 'block-text-group',
        fields: {
          primary_text: strings.HEALTHCHECK_DETAILS_USER_ACCOUNTS_PWNED(),
          secondary_text: breach.PwnCount,
        },
      },
    ],
  });
  rows.push({
    template: 'block-section-row',
    columns: [
      {
        template: 'block-info-text',
        fields: {
          html: breach.Description,
          class: 'info-text',
        },
      },
    ],
  });
  rows.push(infoTextRow(strings.HEALTHCHECK_DETAILS_RESET_NOTE(), true, false, true));
  rows.push({
    template: 'block-section-row',
    fields: { class: 'centered-text', class2: 'no-border' },
    columns: [
      {
        template: 'block-text-button',
        fields: {
          text: strings.HEALTHCHECK_DETAILS_RESET_BUTTON(),
          action: 'beginAddNewService',
          class: 'cta-button',
        },
      },
    ],
  });

  rows.push({
    template: 'block-section-row',
    columns: [
      {
        template: 'block-text-group',
        fields: {
          secondary_html: strings.HEALTHCHECK_ASSESSMENT_HIBP_LINK(),
        },
      },
    ],
  });

  return {
    template: 'block-section-page',
    fields: {
      id: 'healthcheck_details_user',
      active: false,
      header: strings.HEALTHCHECK_DETAILS_USER_HEADER(),
      navigation_left: strings.BACK(),
      navigation_left_data: nav - 2,
      navigation_left_template: 'back',
      navigation_right: strings.DONE(),
      navigation_right_data: 0,
      navigation_right_template: 'back',
      navbar: false,
    },
    rows: rows,
  };
};
