const { strings } = require('../../../config');

module.exports = (params) => {
  let fieldId = z2hApp.active.pageId;
  if (params.menuTarget) {
    const row = $(params.menuTarget).closest('.row');
    fieldId = row.attr('id');
  }
  var confirmWindow = confirm(strings.DELETE_FIELD_PROMPT());
  if (confirmWindow == true) {
    delete z2hApp.pageData.service.fields[fieldId];
    if (z2hApp.pageData.service?.fieldsSaved[fieldId]) delete z2hApp.pageData.service.fieldsSaved[fieldId];
    if (z2hApp.pageData.service?.fieldsUnsaved[fieldId]) delete z2hApp.pageData.service.fieldsUnsaved[fieldId];
    for (var i = 0; i < z2hApp.pageData.service.fieldsArray.length; i++) {
      if (z2hApp.pageData.service.fieldsArray[i] == fieldId) {
        z2hApp.pageData.service.fieldsArray.splice(i, 1);
      }
    }

    // Remove field from service details page
    $('#' + fieldId).remove();

    //section.find('.nav-left').trigger('click');

    return z2hApp.requestData('delete', '', 'fields/' + fieldId).then((response) => {
      const paneDetails = window.getActivePaneDetails('#pane-2');

      // If response has an error, throw it, to be caught by a future .catch block
      if (Math.floor(response.status / 100) !== 2) {
        throw new Error(
          response.user_message ||
            response.developer_message ||
            response.message ||
            `An error occurred! Sorry about that.`,
        );
      }

      const service = z2hApp.pageData.service;
      window.serviceFieldPromises[service.id] = null;

      if (params.menuTarget) {
        $(`#${fieldId}`).fadeOut('slow', () => {
          $(`#${fieldId}`).remove();
        });
        return;
      }

      if (z2hApp.pageData.postUpdateAction) return z2hApp.pageData.postUpdateAction(fieldId, null, 'delete');

      if (params.z2hApp.pageData.service.resetFromContext) {
        const { biometricsOrDoodle } = require('../../actions/services');
        biometricsOrDoodle({
          currentTarget: null,
          z2hApp,
          pane,
          newPaneIndex: paneDetails.activePaneIndex + 1,
          template: { biometrics: 'viewService1_biometrics', doodlepad: 'viewService1_doodlepad' },
        });
      } else {
        z2hApp.paneNavigation('editServiceDetails', paneDetails.pane, paneDetails.activePaneIndex - 1, null);
      }
    });
  }
};
