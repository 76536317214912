const { strings } = require('../../../config');
const { infoTextRow } = require('../../page_helpers/components');

module.exports = ({ nav }) => {
  const list = window.state.movingService.from.service.serviceFields;
  const service = window.state.movingService.from.service.name;
  const fromGroupId = window.z2hApp.pageData.service.currentGroupId;
  const toGroupId = window.state.selectedGroupId;

  const fromGroup = window.state.groupsList.find((g) => g.id === fromGroupId) || {};
  const toGroup = window.state.groupsList.find((g) => g.id === toGroupId) || {};
  const listString = (list) => {
    let listed = '';
    let index = 0;
    for (const listIdx in list) {
      listed += list[listIdx].name;

      if (index == Object.keys(list).length - 2) {
        listed += ` ${strings.AND()} `;
      } else if (index <= Object.keys(list).length - 2) {
        listed += ', ';
      }

      index++;
    }

    return listed;
  };

  return {
    template: 'block-section-page',
    fields: {
      id: 'moveservice-confirm',
      header: strings.MOVE_SERVICE_CONFIRM_HEADER(),
      navigation_left: strings.BACK(),
      navigation_left_data: nav - 1,
      navigation_left_template: 'back',
      navigation_right: strings.CANCEL(),
      navigation_right_data: 0,
      navigation_right_template: 'back',
      navbar: false,
    },
    rows: [
      infoTextRow(
        strings.MOVE_SERVICE_CONFIRM_NOTE()(service, fromGroup.name, toGroup.name, Object.keys(list)?.length),
        true,
        false,
        true,
      ),
      {
        template: 'block-lochy',
        fields: {
          imagePath: `img/lochy/08-Lochy-Cool.gif`,
          class: 'no-border',
          size: 'small',
        },
      },

      infoTextRow(
        strings.MOVE_SERVICE_CONFIRM_NOTE_UPDATE()(fromGroup.name, toGroup.name, listString(list), list.length),
        true,
        false,
        true,
      ),

      {
        template: 'block-section-row',
        fields: {},
        columns: [
          {
            template: 'block-icn-button',
            fields: {
              class: 'icn-big',
              icon: 'right-solid',
              id: 'confirm_moveService',
              action: 'moveService_confirm',
            },
          },
        ],
      },
    ],
  };
};
