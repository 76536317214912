const { PREMIUM_NO_PRODUCT_SELCTED, UNABLE_TO_PURCHASE } = require('../../../config/strings');

const showToastMessage = require('../../action_helpers/showToastMessage');

function purchaseSelectedProduct({ currentTarget }) {
  const {
    purchase,
  } = require('../../app/iap');

  const prodId = $(currentTarget).attr('data-id');

  if (prodId === '') {
    showToastMessage(PREMIUM_NO_PRODUCT_SELCTED(), { center: true });
  }
  if (!window.cordova) return;

  purchase(prodId)
    .then((result) => {})
    .catch((err) => {
      showToastMessage(UNABLE_TO_PURCHASE(), { center: true });
    });
}

module.exports = purchaseSelectedProduct;
