const showToastMessage = require('../../action_helpers/showToastMessage');

/************
Example response from groups/join:
{
	status: 200,
	data: {
		current_uses: 1, expiry_date: 1541515827138, group_id: "-LHchgizO_LdwlQRrKI0", id: "5E_CyUprW",…}
		current_uses: 1
		expiry_date: 1541515827138
		group_id: "-LHchgizO_LdwlQRrKI0"
		id: "5E_CyUprW"
		link_creation_date: 1541429427138
		link_creator_id: "gxjOWLvAkBO8QSDhMBLdk5eAVYD2"
		maximum_uses: 1
		temporary_redirect: "https://test-z2h-invite.firebaseapp.com/5E_CyUprW---this-link-was-actually-successful-but-this-is-just-a-placeholder-path"
	}
}
*************/

function checkResponseForError(response) {
  // Check that response has a status code of 2xx
  if (Math.floor(response.status / 100) !== 2) {
    // If that failed, return the error message we received in the response or a generic one
    throw new Error(
      response.user_message ||
        response.developer_message ||
        response.message ||
        `Sorry. There was an error with your group invite link. Please try again.`,
    );
  }
}

module.exports = async ({ inviteCode }) => {
  if (!inviteCode) return;

  try {
    // Join the group using the provided group invite code
    const response = await z2hApp.requestData('post', '', 'groups/join', { invite_id: inviteCode });
    checkResponseForError(response);
    groupId = response.data.group_id;

    // Now get the group details so that we can display the name of the group that you have just joined
    const getGroupResponse = await z2hApp.requestData('get', '', `groups/${groupId}?fields=name,maximum_members,icon`);
    checkResponseForError(getGroupResponse);

    const group = response.data;
    group.id = groupId;

    // Display message to the user
    if (group.name) showToastMessage(`You have joined the "${group.name}" group`);

    // Add this new groups to our global list of groups
    window.state.groupsList.push(group);

    // Refresh groups list in navigation bar
    //z2hApp.refreshGroupsInNav();
    //z2hApp.reloadUpdateSections();
    //await z2hApp.reloadFullGroupsList();

    // Navigate to the groups list page
    z2hApp.paneNavigation('groupsList', $('#pane-1'), 0, null);
  } catch (err) {
    showToastMessage(err.message);
  }
};
