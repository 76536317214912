module.exports = async ({}) => {
  function isFingerprintAvailable() {
    return new Promise(resolve => {
      if (!window.cordova) return resolve(false);
      const onSuccess = type => {
        console.log('Touch ID is available: ', type);
        return resolve(true);
      };
      const onFailure = error => {
        console.log('Touch ID not available: ', error);
        return resolve(false);
      };
      return window.Fingerprint.isAvailable(onSuccess, onFailure);
    });
  }

  function isPushAvailable() {
    return new Promise((resolve, reject) => {
      if (!window.cordova) return resolve({ available: false });
      // FCM.hasPermission().then(value => {
      //   return resolve({ available: true, permission: value });
      // });
      return resolve({ available: false });
    });
  }

  const touchIdAvailable = await isFingerprintAvailable();
  const notifications = await isPushAvailable();

  return { touchIdAvailable: touchIdAvailable, notifications };
};
