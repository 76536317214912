const strings = require('../../../config').strings;

module.exports = ({ nav, data }) => {
  const row = icon => ({
    template: 'block-section-row',
    fields: {
      action: 'chooseServiceIcon',
    },
    columns: [
      {
        template: 'block-login-avatar',
        fields: { avatar: icon.url },
      },
      {
        template: 'block-text-group',
        fields: { primary_text: icon.name },
      },
    ],
  });

  const rows = (data.icons || []).map(row);

  return {
    template: 'block-section-page',
    fields: {
      id: 'chooseServiceIcon',
      active: false,
      header: strings.SERVICE_CHOOSE_ICON(),
      navigation_left: strings.CANCEL(),
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'back',
    },
    rows: [
      {
        template: 'block-search-box',
        fields: {
          action: 'searchForServiceIcons',
          id: 'service-icon-search',
        },
      },
      ...rows,
    ],
  };
};
