const strings = require('../../../config').strings;

module.exports = (index, headerIn, t, image, nextTemplate, prevTemplate, nextData, prevData, hideSkip) => {
  const MOBILE_VIEW_BREAKPOINT = window.z2hApp.MOBILE_VIEW_BREAKPOINT;
  const mobile = $('body').width() <= MOBILE_VIEW_BREAKPOINT;

  const imagePath = mobile ? 'img/onboarding/mobile/' + index + '.jpg' : 'img/onboarding/desktop/' + index + '.jpg';

  let text, header;
  if (mobile) {
    text = headerIn;
  } else {
    header = headerIn;
  }

  let skipButton =
    hideSkip !== true
      ? {
        navigation_right: strings.SKIP(),
        navigation_right_data: nextData,
        navigation_right_template: 'createAccount_addLogin',
      }
      : {};

  let progressDotsFields = {};
  progressDotsFields['dot' + index + 'Active'] = true;

  return {
    template: 'block-section-page',

    fields: {
      id: 'create-account-onboarding-' + index,
      class: 'onboarding',

      header: header,

      ...skipButton,

      navigation_left: prevTemplate && strings.PREVIOUS(),
      navigation_left_data: prevData,
      navigation_left_template: prevTemplate,
    },

    rows: [
      {
        template: 'block-section-row',
        fields: {
          class: 'no-border',
          class2: 'centered-text',
          primary_heading: text,
        },
      },
      {
        template: 'block-onboarding-image',
        fields: { class: 'no-border', imagePath },
      },
      {
        template: 'block-progress-dots',
        fields: progressDotsFields,
      },
      {
        template: 'block-section-row',
        fields: { primary_text: '' },
        columns: [
          {
            template: 'block-icn-button',
            fields: {
              icon: 'right-solid',
              class: 'icn-button-lrg',
              navigation_data: nextData,
              navigation_template: nextTemplate,
            },
          },
        ],
      },
    ],
  };
};
