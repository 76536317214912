const { ADD_SERVICE_GENERATING_FORGHETTI_ONBOARD } = require('../../../config/strings');
const lochyLoadingTemplate = require('./lochyLoadingTemplate');
const {ADD_SERVICE_LOADING_PLEASE_WAIT} = require('../../../config/strings');

module.exports = () => {
  const onboarding = window.state.userData.activeOnboarding;

  const { name } = window.z2hApp.pageData.service.serviceData;

  return ( 
    lochyLoadingTemplate({
      id: onboarding ? 'addServicePassword_loading-onboarding' : 'addServicePassword_loading',
      heading: ADD_SERVICE_GENERATING_FORGHETTI_ONBOARD()(name),
      lochy: 'safe',
    },   {
      template: 'block-section-row',
      fields: {
        id: 'addservicePasswordLoading_text',
        class: 'centered-text',
        //secondary_heading: ADD_SERVICE_LOADING_PLEASE_WAIT,
      },
      columns: [{
        template: 'block-text-group',
        fields: {
          secondary_text: ADD_SERVICE_LOADING_PLEASE_WAIT(),
        }
      }
        
      ]
    })
  

  )
}