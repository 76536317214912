const showToastMessage = require('../../action_helpers/showToastMessage');
const gotoPremium = require('../profile/gotoPremium');
const showUpgradeTodayPopup = require('../../action_helpers/showUpgradeTodayPopup');
const { NEW_GROUP_REQUIRES_PREMIUM } = require('../../../config/strings');
module.exports = ({ z2hApp, section }) => {
  //showToastMessage(NEW_GROUP_REQUIRES_PREMIUM);

  if (!window.state.launchData.home.premium) {
    showUpgradeTodayPopup(NEW_GROUP_REQUIRES_PREMIUM());
  } else {
    pane = 'newGroup';

    const paneDetails = window.getActivePaneDetails('#pane-2');
    z2hApp.paneNavigation(pane, $('#pane-2'), paneDetails.activePaneIndex + 1);
  }
  //gotoPremium({ z2hApp, section });
};
