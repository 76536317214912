const { PREMIUM_LOADING_REGISTERED } = require('../../../config/strings.base');
const z2hApp = require('../../app/z2hApp');

const { strings, urls } = require('../../../config');

module.exports = async ({}) => {
  if (window.state.userDataPromise) {
    await window.state.userDataPromise;
  } else {
    await z2hApp.fetchFullUserDetails();
  }


  const {onDeviceReady} = require('../../app/iap')
  onDeviceReady();

  const subscription = (window.state && window.state.userData && window.state.userData.subscription) || {};

  if (window.state?.userData?.subscription?.subscription_type === 'premium_trial') {
    const logs = Object.values(window.state?.userData?.subscription?.subscription_logs);

    if (logs.length >= 1 && logs[logs.length - 1].reference_code === 'csl') {
      return Promise.resolve({ type: 'CSL', header: strings.CSL_SUBSCRIPTION_HEADER() });
    }
  }
  if (subscription.subscription_type === 'partner') {
    // const partnerUrl = subscription.partner.text.subscribed
    //   .replace('{{LANGUAGE}}', localStorage.getItem('language'))
    //   .replace('"', '');
    //const response = await z2hApp.requestData('get', '', `partner/text?url=${partnerUrl}`);
    const response = await z2hApp.requestData(
      'get',
      '',
      `partner/text/?partner=${subscription.partner.partner}&action=subscribed&language=${localStorage.getItem(
        'language',
      )}`,
    );
    return Promise.resolve({ type: 'partner', text: response.data });
  } else if (window.state.userData.partner) {
    // const partnerUrl = window.state.userData.partner.text.applicable
    //   .replace('{{LANGUAGE}}', localStorage.getItem('language'))
    //   .replace('"', '');
    // const response = await z2hApp.requestData('get', '', `partner/text?url=${partnerUrl}`);
    const response = await z2hApp.requestData(
      'get',
      '',
      `partner/text?partner=${
        window.state.userData.partner.partner
      }&=action=applicable&=language=${localStorage.getItem('language')}`,
    );
    return Promise.resolve({ type: 'partner_upgrade', text: response.data });
  } else {
    return Promise.resolve();
  }
};
