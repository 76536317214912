module.exports = ({ currentTarget, z2hApp }) => {
  const personalGroup = window.state.groupsList.find((group) => group.personal);
  localStorage.setItem(personalGroup.id + '_doodle', window.state.doodlepadToConfirm);
  localStorage.setItem('bio', true);
  const saveIt = new Promise((resolve, reject) => {
    let biometrics;
    if (window.ss) {
      window.ss.set(
        () => {
          console.log('Stored doodle');

          localStorage.setItem(personalGroup.id + '_doodle', true);
          window.ss.get(
            (value) => {
              console.log('Get doodle');
              return resolve(value);
            },
            () => {
              console.log('Failed to get doodle');
              return resolve(null);
            },
            personalGroup.id + '_doodle',
          );

       
          resolve(null);
        },
        () => {
          console.log('Failed to store doodle');
          resolve(false)
        },
        personalGroup.id + '_doodle',
        window.state.doodlepadToConfirm,
      );
    } else {
      resolve(false)
    }
  });

  saveIt.then((biometrics) => {
    const paneDetails = window.getActivePaneDetails('.overlay');
    z2hApp.paneNavigation('addNewService_selectPresets', paneDetails.pane, paneDetails.activePaneIndex + 1);
  });
};
