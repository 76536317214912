
///Do not modify this file! It is generates by scripts/generateStringsJS
///To add translations (in Engloish) go to strings.base.js
///And also don't forget the translations for other languages
const baseObj = require('./strings.base');
const locales = require('./locales');
let localeObj = {...baseObj};
let locale = 'en';

const t = (key) => {
  return localeObj[key];
}

module.exports = { 
setLocale: (locale)=>{
    localeObj = {...baseObj, ...locales[locale] }
  },
YOU: () => t("YOU"), 
BACK: () => t("BACK"), 
NEXT: () => t("NEXT"), 
CONTINUE: () => t("CONTINUE"), 
SAVE: () => t("SAVE"), 
ADD: () => t("ADD"), 
SKIP: () => t("SKIP"), 
CANCEL: () => t("CANCEL"), 
PREVIOUS: () => t("PREVIOUS"), 
EDIT: () => t("EDIT"), 
NONE: () => t("NONE"), 
START: () => t("START"), 
SEARCH: () => t("SEARCH"), 
CLOSE: () => t("CLOSE"), 
SEE_DETAILS: () => t("SEE_DETAILS"), 
UNAVAILABLE: () => t("UNAVAILABLE"), 
DONE: () => t("DONE"), 
WATCH_AGAIN: () => t("WATCH_AGAIN"), 
NEW: () => t("NEW"), 
UNAVAILABLE_FORGHETTIBLES_MESSAGE: () => t("UNAVAILABLE_FORGHETTIBLES_MESSAGE"), 
CREATE_ACCOUNT: () => t("CREATE_ACCOUNT"), 
HAVE_ACCOUNT: () => t("HAVE_ACCOUNT"), 
COMBOBULATING: () => t("COMBOBULATING"), 
CREATE_ACCOUNT_1_HEADER: () => t("CREATE_ACCOUNT_1_HEADER"), 
CREATE_ACCOUNT_1_HEADING: () => t("CREATE_ACCOUNT_1_HEADING"), 
CREATE_ACCOUNT_1_MSG: () => t("CREATE_ACCOUNT_1_MSG"), 
CREATE_ACCOUNT_1_PLACEHOLDER: () => t("CREATE_ACCOUNT_1_PLACEHOLDER"), 
CREATE_ACCOUNT_1_MSG_2: () => t("CREATE_ACCOUNT_1_MSG_2"), 
CREATE_ACCOUNT_1_MSG_3: () => t("CREATE_ACCOUNT_1_MSG_3"), 
ERROR_TEL_NUM_EMPTY: () => t("ERROR_TEL_NUM_EMPTY"), 
MAIN_NAV_GROUP: () => t("MAIN_NAV_GROUP"), 
MAIN_NAV_PROFILE: () => t("MAIN_NAV_PROFILE"), 
MAIN_NAV_HEALTHCHECK: () => t("MAIN_NAV_HEALTHCHECK"), 
MAIN_NAV_IMPORT: () => t("MAIN_NAV_IMPORT"), 
MAIN_NAV_LOGOUT: () => t("MAIN_NAV_LOGOUT"), 
NAVBAR_JOIN_GROUP: () => t("NAVBAR_JOIN_GROUP"), 
NAVBAR_NEW_GROUP: () => t("NAVBAR_NEW_GROUP"), 
NAVBAR_NEW_PASSWORD: () => t("NAVBAR_NEW_PASSWORD"), 
NAVBAR_NEW_PIN: () => t("NAVBAR_NEW_PIN"), 
NAVBAR_NEW_WORD: () => t("NAVBAR_NEW_WORD"), 
NAVBAR_HOME: () => t("NAVBAR_HOME"), 
NAVBAR_GROUPS: () => t("NAVBAR_GROUPS"), 
NAVBAR_HEALTHCHECK: () => t("NAVBAR_HEALTHCHECK"), 
NAVBAR_PROFILE: () => t("NAVBAR_PROFILE"), 
LOCHY_LOADING_TEXT_DEFAULT: () => t("LOCHY_LOADING_TEXT_DEFAULT"), 
LOCHY_LOADING_TEXT_GROUP: () => t("LOCHY_LOADING_TEXT_GROUP"), 
LOCHY_LOADING_TEXT_GROUPSLIST: () => t("LOCHY_LOADING_TEXT_GROUPSLIST"), 
LOCHY_LOADING_TEXT_HEALTHCHECK: () => t("LOCHY_LOADING_TEXT_HEALTHCHECK"), 
LOCHY_LOADING_TEXT_CHOOSE_SEARCH_ICON: () => t("LOCHY_LOADING_TEXT_CHOOSE_SEARCH_ICON"), 
LOCHY_LOADING_TEXT_CHOOSE_CHOOSE_GROUP_ICON: () => t("LOCHY_LOADING_TEXT_CHOOSE_CHOOSE_GROUP_ICON"), 
LOCHY_LOADING_TEXT_CREATE_GROUP_INVITE: () => t("LOCHY_LOADING_TEXT_CREATE_GROUP_INVITE"), 
LOCHY_LOADING_TEXT_EDIT_SERVICE: () => t("LOCHY_LOADING_TEXT_EDIT_SERVICE"), 
LOCHY_LOADING_TEXT_UPDATE_SERVICE: () => t("LOCHY_LOADING_TEXT_UPDATE_SERVICE"), 
LOCHY_LOADING_TEXT_PROFILE: () => t("LOCHY_LOADING_TEXT_PROFILE"), 
LOCHY_LOADING_TEXT_SUBSCRIPTION: () => t("LOCHY_LOADING_TEXT_SUBSCRIPTION"), 
CREATE_ACCOUNT_2_HEADER: () => t("CREATE_ACCOUNT_2_HEADER"), 
CREATE_ACCOUNT_2_MSG: () => t("CREATE_ACCOUNT_2_MSG"), 
CREATE_ACCOUNT_2_LINK_BACK: () => t("CREATE_ACCOUNT_2_LINK_BACK"), 
CREATE_ACCOUNT_2_MSG_2: () => t("CREATE_ACCOUNT_2_MSG_2"), 
ERROR_TEL_NUM_INVALID: () => t("ERROR_TEL_NUM_INVALID"), 
ERROR_ACT_CODE_EMPTY: () => t("ERROR_ACT_CODE_EMPTY"), 
ERROR_ACT_CODE_INVALID: () => t("ERROR_ACT_CODE_INVALID"), 
CREATE_ACCOUNT_VERIFYING: () => t("CREATE_ACCOUNT_VERIFYING"), 
CREATE_ACCOUNT_ONBOARDING_5_LINE_1: () => t("CREATE_ACCOUNT_ONBOARDING_5_LINE_1"), 
CREATE_ACCOUNT_ONBOARDING_5_LINE_2: () => t("CREATE_ACCOUNT_ONBOARDING_5_LINE_2"), 
CREATE_ACCOUNT_ONBOARDING_5_LINE_3: () => t("CREATE_ACCOUNT_ONBOARDING_5_LINE_3"), 
CREATE_ACCOUNT_ONBOARDING_5_LINE_4: () => t("CREATE_ACCOUNT_ONBOARDING_5_LINE_4"), 
CREATE_ACCOUNT_REFERRAL_HEADER: () => t("CREATE_ACCOUNT_REFERRAL_HEADER"), 
CREATE_ACCOUNT_REFERRAL_HEADING: () => t("CREATE_ACCOUNT_REFERRAL_HEADING"), 
CREATE_ACCOUNT_REFERRAL_PLACEHOLDER: () => t("CREATE_ACCOUNT_REFERRAL_PLACEHOLDER"), 
REFERRAL_ENTRY_MESSAGE_1: () => t("REFERRAL_ENTRY_MESSAGE_1"), 
CREATE_ACCOUNT_3_HEADER: () => t("CREATE_ACCOUNT_3_HEADER"), 
CREATE_ACCOUNT_3_HEADING: () => t("CREATE_ACCOUNT_3_HEADING"), 
CREATE_ACCOUNT_3_TEXT: () => t("CREATE_ACCOUNT_3_TEXT"), 
CREATE_ACCOUNT_3_LABEL: () => t("CREATE_ACCOUNT_3_LABEL"), 
CREATE_ACCOUNT_3_TEXT_2: () => t("CREATE_ACCOUNT_3_TEXT_2"), 
CREATE_ACCOUNT_ADD_LOGIN_MSG: () => t("CREATE_ACCOUNT_ADD_LOGIN_MSG"), 
CREATE_ACCOUNT_FINAL_HEADER: () => t("CREATE_ACCOUNT_FINAL_HEADER"), 
CREATE_ACCOUNT_FINAL_MSG: () => t("CREATE_ACCOUNT_FINAL_MSG"), 
CREATE_ACCOUNT_FINAL_MSG_2: () => t("CREATE_ACCOUNT_FINAL_MSG_2"), 
CREATE_ACCOUNT_FINAL_MSG_3: () => t("CREATE_ACCOUNT_FINAL_MSG_3"), 
CREATE_ACCOUNT_FINAL_LINK: () => t("CREATE_ACCOUNT_FINAL_LINK"), 
CREATE_ACCOUNT_ADD_CUSTOM_LOGIN: () => t("CREATE_ACCOUNT_ADD_CUSTOM_LOGIN"), 
CREATE_ACCOUNT_SERVICE_NAME_TOOLTIP: () => t("CREATE_ACCOUNT_SERVICE_NAME_TOOLTIP"), 
CREATE_ACCOUNT_CUSTOM_LOGIN_ADD_PASSWORD_TOOLTIP: () => t("CREATE_ACCOUNT_CUSTOM_LOGIN_ADD_PASSWORD_TOOLTIP"), 
CREATE_ACCOUNT_CUSTOM_LOGIN_NEXTPAGE_TOOLTIP: () => t("CREATE_ACCOUNT_CUSTOM_LOGIN_NEXTPAGE_TOOLTIP"), 
CREATE_ACCOUNT_NEW_LOGIN_DOODLEPAD_MSG: () => t("CREATE_ACCOUNT_NEW_LOGIN_DOODLEPAD_MSG"), 
CREATE_ACCOUNT_ADD_ANOTHER_LOGIN_TOOLTIP: () => t("CREATE_ACCOUNT_ADD_ANOTHER_LOGIN_TOOLTIP"), 
ADD_SERVICE_SEARCH_HEADER: () => t("ADD_SERVICE_SEARCH_HEADER"), 
ADD_SERVICE_SEARCH: () => t("ADD_SERVICE_SEARCH"), 
ADD_SERVICE_SUGGESTED: () => t("ADD_SERVICE_SUGGESTED"), 
ADD_SERVICE_CREATE: () => t("ADD_SERVICE_CREATE"), 
ADD_SERVICE_SELECT_ICON_HEADER: () => t("ADD_SERVICE_SELECT_ICON_HEADER"), 
ADD_SERVICE_SELECT_ICON: () => t("ADD_SERVICE_SELECT_ICON"), 
ADD_SERVICE_SELECT_GROUP_HEADER: () => t("ADD_SERVICE_SELECT_GROUP_HEADER"), 
ADD_SERVICE_YOUR_GROUP: () => t("ADD_SERVICE_YOUR_GROUP"), 
ADD_SERVICE_SELECT_GROUP: () => t("ADD_SERVICE_SELECT_GROUP"), 
ADD_SERVICE_SELECT_GROUP_SELECT: () => t("ADD_SERVICE_SELECT_GROUP_SELECT"), 
MOVE_SERVICE_SELECT_GROUP: () => t("MOVE_SERVICE_SELECT_GROUP"), 
MOVE_SERVICE_LOADING: () => t("MOVE_SERVICE_LOADING"), 
MOVE_SERVICE_CONFIRM_HEADER: () => t("MOVE_SERVICE_CONFIRM_HEADER"), 
MOVE_SERVICE_CONFIRM_NOTE: () => t("MOVE_SERVICE_CONFIRM_NOTE"), 
MOVE_SERVICE_CONFIRM_NOTE_UPDATE: () => t("MOVE_SERVICE_CONFIRM_NOTE_UPDATE"), 
MOVE_SERVICE_CONFIRM: () => t("MOVE_SERVICE_CONFIRM"), 
ADD_SERVICE_SELECT_GROUP_ONBOARD: () => t("ADD_SERVICE_SELECT_GROUP_ONBOARD"), 
ADD_SERVICE_USERNAME: () => t("ADD_SERVICE_USERNAME"), 
ADD_SERVICE_USERNAME_ONBOARD: () => t("ADD_SERVICE_USERNAME_ONBOARD"), 
ADD_SERVICE_USERNAME_HEADER: () => t("ADD_SERVICE_USERNAME_HEADER"), 
ADD_SERVICE_SEARCH_FOR_SERVICE: () => t("ADD_SERVICE_SEARCH_FOR_SERVICE"), 
ADD_SERVICE_SEARCH_FOR_ICONS: () => t("ADD_SERVICE_SEARCH_FOR_ICONS"), 
ADD_SERVICE_SUGGESTED_USERNAMES: () => t("ADD_SERVICE_SUGGESTED_USERNAMES"), 
ADD_SERVICE_DRAW_DOODLE: () => t("ADD_SERVICE_DRAW_DOODLE"), 
ADD_SERVICE_USERNAME_BLANK: () => t("ADD_SERVICE_USERNAME_BLANK"), 
ADD_SERVICE_USERNAME_TOO_LONG: () => t("ADD_SERVICE_USERNAME_TOO_LONG"), 
ADD_SERVICE_WEBSITE_HEADER: () => t("ADD_SERVICE_WEBSITE_HEADER"), 
ADD_SERVICE_WEBSITE: () => t("ADD_SERVICE_WEBSITE"), 
ADD_SERVICE_WEBSITE_PLACEHOLDER: () => t("ADD_SERVICE_WEBSITE_PLACEHOLDER"), 
ADD_SERVICE_SEARCH_ONBOARD_LETS_START: () => t("ADD_SERVICE_SEARCH_ONBOARD_LETS_START"), 
ADD_SERVICE_SEARCH_ONBOARD_NOW_ITS_YOUR_TURN: () => t("ADD_SERVICE_SEARCH_ONBOARD_NOW_ITS_YOUR_TURN"), 
ADD_SERVICE_DOODLE_DO_IT_AGAIN: () => t("ADD_SERVICE_DOODLE_DO_IT_AGAIN"), 
ADD_SERVICE_DOODLE_ONBOARD_NOW_ITS_YOUR_TURN: () => t("ADD_SERVICE_DOODLE_ONBOARD_NOW_ITS_YOUR_TURN"), 
ADD_SERVICE_DOODLE_CREATE_DOODLE: () => t("ADD_SERVICE_DOODLE_CREATE_DOODLE"), 
ADD_SERVICE_DOODLE_CONFIRM_DOODLE: () => t("ADD_SERVICE_DOODLE_CONFIRM_DOODLE"), 
ADD_SERVICE_GENERATING_FORGHETTI_ONBOARD: () => t("ADD_SERVICE_GENERATING_FORGHETTI_ONBOARD"), 
VIEW_SERVICE_GENERATING_FORGHETTI: () => t("VIEW_SERVICE_GENERATING_FORGHETTI"), 
VIEW_SERVICE_RESET_PASSWORD: () => t("VIEW_SERVICE_RESET_PASSWORD"), 
VIEW_SERVICE_PASSWORD_HELP: () => t("VIEW_SERVICE_PASSWORD_HELP"), 
VIEW_SERVICE_PASSWORD_HELP_MOVE: () => t("VIEW_SERVICE_PASSWORD_HELP_MOVE"), 
VIEW_SERVICE_OLD_PREFIX: () => t("VIEW_SERVICE_OLD_PREFIX"), 
VIEW_SERVICE_NEW_PREFIX: () => t("VIEW_SERVICE_NEW_PREFIX"), 
HIDE_QUICK_SELECT: () => t("HIDE_QUICK_SELECT"), 
HIDE_QUICK_SELECT_CONFIRM_TITLE: () => t("HIDE_QUICK_SELECT_CONFIRM_TITLE"), 
HIDE_QUICK_SELECT_CONFIRM: () => t("HIDE_QUICK_SELECT_CONFIRM"), 
ADD_SERVICE_DODODLES_DO_NOT_MATCH: () => t("ADD_SERVICE_DODODLES_DO_NOT_MATCH"), 
THEME_COLOUR: () => t("THEME_COLOUR"), 
THEME_COLOURS: () => t("THEME_COLOURS"), 
THEME_MODES: () => t("THEME_MODES"), 
THEME_BLUE: () => t("THEME_BLUE"), 
DELETE_ACCOUNT_CONTINUE: () => t("DELETE_ACCOUNT_CONTINUE"), 
DELETE_ACCOUNT_HEADER: () => t("DELETE_ACCOUNT_HEADER"), 
DELETE_ACCOUNT_HEADING: () => t("DELETE_ACCOUNT_HEADING"), 
DELETE_ACCOUNT_LINE_1: () => t("DELETE_ACCOUNT_LINE_1"), 
DELETE_ACCOUNT_LINE_2: () => t("DELETE_ACCOUNT_LINE_2"), 
DELETE_ACCOUNT_LINE_3: () => t("DELETE_ACCOUNT_LINE_3"), 
DELETE_ACCOUNT_LINE_4: () => t("DELETE_ACCOUNT_LINE_4"), 
DELETE_ACCOUNT_LINE_5: () => t("DELETE_ACCOUNT_LINE_5"), 
DELETE_ACCOUNT_CHANGE_INSTEAD: () => t("DELETE_ACCOUNT_CHANGE_INSTEAD"), 
DELETE_ACCOUNT_CONFIRM_MESSAGE: () => t("DELETE_ACCOUNT_CONFIRM_MESSAGE"), 
DELETE_ACCOUNT_CONFIRM_BUTTON: () => t("DELETE_ACCOUNT_CONFIRM_BUTTON"), 
DEVICES_HEADER: () => t("DEVICES_HEADER"), 
DEVICES_LAST_ACTIVE: () => t("DEVICES_LAST_ACTIVE"), 
DEVICES_FORGHETTI_APP: () => t("DEVICES_FORGHETTI_APP"), 
DEVICES_LOG_OUT_ALL: () => t("DEVICES_LOG_OUT_ALL"), 
SERVICES_PERSONAL: () => t("SERVICES_PERSONAL"), 
SERVICES_ADD_LOGIN: () => t("SERVICES_ADD_LOGIN"), 
SERVICES_RECENT: () => t("SERVICES_RECENT"), 
SEARCH_INTERNET: () => t("SEARCH_INTERNET"), 
SERVICE_HEADER_NEW: () => t("SERVICE_HEADER_NEW"), 
SERVICE_NAME_PLACEHOLDER: () => t("SERVICE_NAME_PLACEHOLDER"), 
SERVICE_DOODLEPAD_SUBHEADING: () => t("SERVICE_DOODLEPAD_SUBHEADING"), 
DOODLEPAD_SECRET: () => t("DOODLEPAD_SECRET"), 
DOODLEPAD_SHOW: () => t("DOODLEPAD_SHOW"), 
DOODLEPAD_REDOODLE: () => t("DOODLEPAD_REDOODLE"), 
ERROR_NO_DOODLE: () => t("ERROR_NO_DOODLE"), 
SERVICE_EDIT_FIELD_HEADER: () => t("SERVICE_EDIT_FIELD_HEADER"), 
FIELD_TYPES: () => t("FIELD_TYPES"), 
FIELD_TEMPLATE_PIN: () => t("FIELD_TEMPLATE_PIN"), 
FIELD_TEMPLATE_COMPLEX_PASSWORD: () => t("FIELD_TEMPLATE_COMPLEX_PASSWORD"), 
FIELD_TEMPLATE_PASSWORD: () => t("FIELD_TEMPLATE_PASSWORD"), 
FIELD_TEMPLATE_MEMWORD: () => t("FIELD_TEMPLATE_MEMWORD"), 
FIELD_TEMPLATE_FORGHETTIBLE: () => t("FIELD_TEMPLATE_FORGHETTIBLE"), 
SERVICE_WEBSITE_LABEL: () => t("SERVICE_WEBSITE_LABEL"), 
SERVICE_EDIT_HEADER: () => t("SERVICE_EDIT_HEADER"), 
SERVICE_EDIT_WEBSITE_PLACEHOLDER: () => t("SERVICE_EDIT_WEBSITE_PLACEHOLDER"), 
SERVICE_ADD_FORGHETTIBLE: () => t("SERVICE_ADD_FORGHETTIBLE"), 
SERVICE_DFT_FORGHETTIBLE_NAME: () => t("SERVICE_DFT_FORGHETTIBLE_NAME"), 
SERVICE_NAME_LENGTH_INVALID: () => t("SERVICE_NAME_LENGTH_INVALID"), 
SERVICE_USERNAME_LENGTH_INVALID: () => t("SERVICE_USERNAME_LENGTH_INVALID"), 
SERVICE_ADDRESS_LENGTH_INVALID: () => t("SERVICE_ADDRESS_LENGTH_INVALID"), 
SERVICE_NO_FORGHETTIBLES: () => t("SERVICE_NO_FORGHETTIBLES"), 
SERVICE_CHOOSE_ICON: () => t("SERVICE_CHOOSE_ICON"), 
SERVICE_DOODLEPAD_HEADING: () => t("SERVICE_DOODLEPAD_HEADING"), 
SERVICE_DOODLEPAD_RIGHT_NAV: () => t("SERVICE_DOODLEPAD_RIGHT_NAV"), 
SERVICE_ADDING: () => t("SERVICE_ADDING"), 
SERVICE_CONNECTING: () => t("SERVICE_CONNECTING"), 
FIELD_DETAILS_HEADER: () => t("FIELD_DETAILS_HEADER"), 
FIELD_NAME: () => t("FIELD_NAME"), 
FIELD_NAME_PLACEHOLDER: () => t("FIELD_NAME_PLACEHOLDER"), 
FIELD_TYPE: () => t("FIELD_TYPE"), 
FIELD_LENGTH: () => t("FIELD_LENGTH"), 
FIELD_LENGTH_PICKER_CUSTOM: () => t("FIELD_LENGTH_PICKER_CUSTOM"), 
FIELD_RIGHT_NAV: () => t("FIELD_RIGHT_NAV"), 
FIELD_OPTIONS_HEADING: () => t("FIELD_OPTIONS_HEADING"), 
ADVANCED_OPTIONS: () => t("ADVANCED_OPTIONS"), 
RESET_FIELD: () => t("RESET_FIELD"), 
DELETE_FIELD: () => t("DELETE_FIELD"), 
RESTORE_FIELD: () => t("RESTORE_FIELD"), 
FIELD_ADVANCED_OPTIONS_HEADER: () => t("FIELD_ADVANCED_OPTIONS_HEADER"), 
RESTORE_FIELD_PAGE_HEADER: () => t("RESTORE_FIELD_PAGE_HEADER"), 
RESTORE_FIELD_SUBHEADING: () => t("RESTORE_FIELD_SUBHEADING"), 
RESTORE_FIELD_DESCRIPTION: () => t("RESTORE_FIELD_DESCRIPTION"), 
RESTORE_FIELD_VERSION_HISTORY: () => t("RESTORE_FIELD_VERSION_HISTORY"), 
RESTORE_FIELD_CURRENT: () => t("RESTORE_FIELD_CURRENT"), 
SERVICE_VIEW_HEADER: () => t("SERVICE_VIEW_HEADER"), 
SERVICE_VIEW_RIGHT_NAV: () => t("SERVICE_VIEW_RIGHT_NAV"), 
SERVICE_SUMMARY_MESSAGE: () => t("SERVICE_SUMMARY_MESSAGE"), 
SERVICE_SUMMARY_FIRST_LOGIN_MSG1: () => t("SERVICE_SUMMARY_FIRST_LOGIN_MSG1"), 
SERVICE_SUMMARY_FIRST_LOGIN_MSG2: () => t("SERVICE_SUMMARY_FIRST_LOGIN_MSG2"), 
SERVICE_SUMMARY_FIRST_LOGIN_PASSWORD_TOOLTIP: () => t("SERVICE_SUMMARY_FIRST_LOGIN_PASSWORD_TOOLTIP"), 
SERVICE_DETAILS_HEADER: () => t("SERVICE_DETAILS_HEADER"), 
SERVICE_DETAILS_RIGHT_NAV: () => t("SERVICE_DETAILS_RIGHT_NAV"), 
SERVICE_DETAILS_DELETE: () => t("SERVICE_DETAILS_DELETE"), 
PROFILE_HEADER: () => t("PROFILE_HEADER"), 
PROFILE_WIZARD_COMPLETE: () => t("PROFILE_WIZARD_COMPLETE"), 
PROFILE_WIZARD_SEE_DETAILS: () => t("PROFILE_WIZARD_SEE_DETAILS"), 
PROFILE_PREMIUM_BANNER_HEADING: () => t("PROFILE_PREMIUM_BANNER_HEADING"), 
PROFILE_PREMIUM_BANNER_DETAIL: () => t("PROFILE_PREMIUM_BANNER_DETAIL"), 
PROFILE_THEME_MODE_LABEL: () => t("PROFILE_THEME_MODE_LABEL"), 
PROFILE_THEME_COLOUR_LABEL: () => t("PROFILE_THEME_COLOUR_LABEL"), 
PROFILE_BADGES: () => t("PROFILE_BADGES"), 
PROFILE_LINK_ACCOUNT: () => t("PROFILE_LINK_ACCOUNT"), 
PROFILE_LINK_PREMIUM: () => t("PROFILE_LINK_PREMIUM"), 
PROFILE_ENABLE_AUTOFILL: () => t("PROFILE_ENABLE_AUTOFILL"), 
PROFILE_LINK_USER_PREFERENCES: () => t("PROFILE_LINK_USER_PREFERENCES"), 
PROFILE_YOUR_SUBSCRIPTION: () => t("PROFILE_YOUR_SUBSCRIPTION"), 
PROFILE_LINK_INVITE: () => t("PROFILE_LINK_INVITE"), 
PROFILE_REFERRAL_CODE: () => t("PROFILE_REFERRAL_CODE"), 
PROFILE_REFERRAL_CODE_SECONDARY: () => t("PROFILE_REFERRAL_CODE_SECONDARY"), 
PROFILE_LINK_DEVICES: () => t("PROFILE_LINK_DEVICES"), 
PROFILE_LINK_HELP: () => t("PROFILE_LINK_HELP"), 
PROFILE_LINK_FEEDBACK: () => t("PROFILE_LINK_FEEDBACK"), 
PROFILE_LINK_ABOUT: () => t("PROFILE_LINK_ABOUT"), 
PROFILE_LINK_LOGOUT: () => t("PROFILE_LINK_LOGOUT"), 
PROFILE_ACCOUNT_HEADER: () => t("PROFILE_ACCOUNT_HEADER"), 
PROFILE_ACCOUNT_LINK_SECURITY: () => t("PROFILE_ACCOUNT_LINK_SECURITY"), 
PROFILE_ACCOUNT_LINK_MIGRATE: () => t("PROFILE_ACCOUNT_LINK_MIGRATE"), 
PROFILE_ACCOUNT_LINK_DELETE: () => t("PROFILE_ACCOUNT_LINK_DELETE"), 
PROFILE_ACCOUNT_LINK_SUBSCRIPTION: () => t("PROFILE_ACCOUNT_LINK_SUBSCRIPTION"), 
PROFILE_REONBOARDING: () => t("PROFILE_REONBOARDING"), 
PROFILE_GET_ONLINE_HELP: () => t("PROFILE_GET_ONLINE_HELP"), 
PROFILE_GET_VIDEO_HELP: () => t("PROFILE_GET_VIDEO_HELP"), 
PROFILE_HELP_HEADER: () => t("PROFILE_HELP_HEADER"), 
PROFILE_EDIT_NAME_HEADER: () => t("PROFILE_EDIT_NAME_HEADER"), 
PROFILE_EDIT_NAME_LABEL: () => t("PROFILE_EDIT_NAME_LABEL"), 
PROFILE_EDIT_TEL_LABEL: () => t("PROFILE_EDIT_TEL_LABEL"), 
PROFILE_NAME_BLANK: () => t("PROFILE_NAME_BLANK"), 
PROFILE_NAME_TOO_LONG: () => t("PROFILE_NAME_TOO_LONG"), 
PROFILE_NAME_INVALID: () => t("PROFILE_NAME_INVALID"), 
PROFILE_MIGRATE_1_HEADER: () => t("PROFILE_MIGRATE_1_HEADER"), 
PROFILE_MIGRATE_1_HEADING: () => t("PROFILE_MIGRATE_1_HEADING"), 
PROFILE_MIGRATE_1_INFO: () => t("PROFILE_MIGRATE_1_INFO"), 
PROFILE_MIGRATE_1_PLACEHOLDER: () => t("PROFILE_MIGRATE_1_PLACEHOLDER"), 
PROFILE_MIGRATE_1_BUTTON: () => t("PROFILE_MIGRATE_1_BUTTON"), 
PROFILE_MIGRATE_2_HEADER: () => t("PROFILE_MIGRATE_2_HEADER"), 
PROFILE_MIGRATE_2_HEADING: () => t("PROFILE_MIGRATE_2_HEADING"), 
PROFILE_MIGRATE_2_HEADING2: () => t("PROFILE_MIGRATE_2_HEADING2"), 
PROFILE_MIGRATE_2_INFO: () => t("PROFILE_MIGRATE_2_INFO"), 
PROFILE_MIGRATE_2_BUTTON: () => t("PROFILE_MIGRATE_2_BUTTON"), 
PROFILE_MIGRATE_3_HEADER: () => t("PROFILE_MIGRATE_3_HEADER"), 
PROFILE_MIGRATE_3_MSG: () => t("PROFILE_MIGRATE_3_MSG"), 
PROFILE_MIGRATE_3_HEADING: () => t("PROFILE_MIGRATE_3_HEADING"), 
PROFILE_ABOUT_HEADER: () => t("PROFILE_ABOUT_HEADER"), 
PROFILE_ABOUT_PRIVACY: () => t("PROFILE_ABOUT_PRIVACY"), 
PROFILE_ABOUT_COOKIE: () => t("PROFILE_ABOUT_COOKIE"), 
PROFILE_ABOUT_TERMS: () => t("PROFILE_ABOUT_TERMS"), 
PROFILE_ABOUT_ACCEPTABLE: () => t("PROFILE_ABOUT_ACCEPTABLE"), 
PROFILE_ABOUT_VERSION_HEADING: () => t("PROFILE_ABOUT_VERSION_HEADING"), 
PROFILE_REFERRAL_CODE_HEADING: () => t("PROFILE_REFERRAL_CODE_HEADING"), 
PROFILE_REFERRAL_CODE_LABEL: () => t("PROFILE_REFERRAL_CODE_LABEL"), 
PROFILE_REFERRAL_CODE_PLACEHOLDER: () => t("PROFILE_REFERRAL_CODE_PLACEHOLDER"), 
PROFILE_EDIT_REFERRAL_CODE_HEADING: () => t("PROFILE_EDIT_REFERRAL_CODE_HEADING"), 
PROFILE_REFERRAL_CODE_TEXT: () => t("PROFILE_REFERRAL_CODE_TEXT"), 
PROFILE_PREMIUM_TYPE: () => t("PROFILE_PREMIUM_TYPE"), 
APP_PREFERENCES_HEADER: () => t("APP_PREFERENCES_HEADER"), 
APP_PREFERENCES_THEME: () => t("APP_PREFERENCES_THEME"), 
APP_PREFERENCES_QUICK_SELECT: () => t("APP_PREFERENCES_QUICK_SELECT"), 
APP_PREFERENCES_SHOW_QUICK_SELECT: () => t("APP_PREFERENCES_SHOW_QUICK_SELECT"), 
APP_PREFERENCES_SECRET_DOODLE: () => t("APP_PREFERENCES_SECRET_DOODLE"), 
APP_PREFERENCES_SHOW_TOOLTIPS: () => t("APP_PREFERENCES_SHOW_TOOLTIPS"), 
APP_PREFERENCES_REQUIRE_TOUCH_ID: () => t("APP_PREFERENCES_REQUIRE_TOUCH_ID"), 
APP_PREFERENCES_NOTIFICATIONS: () => t("APP_PREFERENCES_NOTIFICATIONS"), 
APP_PREFERENCES_COUNTRY: () => t("APP_PREFERENCES_COUNTRY"), 
APP_PREFERENCES_TOUCH_ID_TOAST: () => t("APP_PREFERENCES_TOUCH_ID_TOAST"), 
PREMIUM_HEADER: () => t("PREMIUM_HEADER"), 
YOUR_SUBSCRIPTION_HEADER: () => t("YOUR_SUBSCRIPTION_HEADER"), 
YOUR_SUBSCRIPTION_SUBHEADING: () => t("YOUR_SUBSCRIPTION_SUBHEADING"), 
YOUR_SUBSCRIPTION_PREMIUM_TRIAL_TEXT_1: () => t("YOUR_SUBSCRIPTION_PREMIUM_TRIAL_TEXT_1"), 
YOUR_SUBSCRIPTION_PREMIUM_TRIAL_TEXT_2: () => t("YOUR_SUBSCRIPTION_PREMIUM_TRIAL_TEXT_2"), 
YOUR_SUBSCRIPTION_FREE_TEXT_1: () => t("YOUR_SUBSCRIPTION_FREE_TEXT_1"), 
YOUR_SUBSCRIPTION_FREE_TEXT_2: () => t("YOUR_SUBSCRIPTION_FREE_TEXT_2"), 
YOUR_SUBSCRIPTION_FREE_TEXT_3: () => t("YOUR_SUBSCRIPTION_FREE_TEXT_3"), 
YOUR_SUBSCRIPTION_FREE_DOWNLOAD: () => t("YOUR_SUBSCRIPTION_FREE_DOWNLOAD"), 
YOUR_SUBSCRIPTION_PREMIUM_TEXT_1: () => t("YOUR_SUBSCRIPTION_PREMIUM_TEXT_1"), 
YOUR_SUBSCRIPTION_PREMIUM_TEXT_2: () => t("YOUR_SUBSCRIPTION_PREMIUM_TEXT_2"), 
YOUR_SUBSCRIPTION_PREMIUM_NO_DATE_TEXT_1: () => t("YOUR_SUBSCRIPTION_PREMIUM_NO_DATE_TEXT_1"), 
YOUR_SUBSCRIPTION_PREMIUM_LIFE_TEXT_1: () => t("YOUR_SUBSCRIPTION_PREMIUM_LIFE_TEXT_1"), 
YOUR_SUBSCRIPTION_PREMIUM_LIFE_TEXT_2: () => t("YOUR_SUBSCRIPTION_PREMIUM_LIFE_TEXT_2"), 
YOUR_SUBSCRIPTION_FREE_TEXT_APP_3: () => t("YOUR_SUBSCRIPTION_FREE_TEXT_APP_3"), 
YOUR_SUBSCRIPTION_FREE_TEXT_APP_1: () => t("YOUR_SUBSCRIPTION_FREE_TEXT_APP_1"), 
YOUR_SUBSCRIPTION_FREE_TEXT_APP_2: () => t("YOUR_SUBSCRIPTION_FREE_TEXT_APP_2"), 
YOUR_SUBSCRIPTION_PREMIUM_TRIAL_BUTTON_LABEL: () => t("YOUR_SUBSCRIPTION_PREMIUM_TRIAL_BUTTON_LABEL"), 
YOUR_SUBSCRIPTION_PREMIUM_TRIAL_CORDOVA_BUTTON_LABEL: () => t("YOUR_SUBSCRIPTION_PREMIUM_TRIAL_CORDOVA_BUTTON_LABEL"), 
YOUR_SUBSCRIPTION_FREE_BUTTON_LABEL: () => t("YOUR_SUBSCRIPTION_FREE_BUTTON_LABEL"), 
PREMIUM_NO_PRODUCT_SELCTED: () => t("PREMIUM_NO_PRODUCT_SELCTED"), 
PREMIUM_PURCHASE_BUTTON: () => t("PREMIUM_PURCHASE_BUTTON"), 
PREMIUM_PURCHASE_BUTTON_NA: () => t("PREMIUM_PURCHASE_BUTTON_NA"), 
COMING_SOON: () => t("COMING_SOON"), 
PREMIUM_PRICE_NONE: () => t("PREMIUM_PRICE_NONE"), 
PREMIUM_PURCHASE_BUTTON_OWNED: () => t("PREMIUM_PURCHASE_BUTTON_OWNED"), 
PREMIUM_PRUCHASE_REFRESH_PURCAHSE: () => t("PREMIUM_PRUCHASE_REFRESH_PURCAHSE"), 
PREMIUM_MONTHLY_HEADER: () => t("PREMIUM_MONTHLY_HEADER"), 
PREMIUM_MONTHLY_LIST: () => t("PREMIUM_MONTHLY_LIST"), 
PREMIUM_ANNUAL_ALIAS: () => t("PREMIUM_ANNUAL_ALIAS"), 
PREMIUM_MONTHLY_ALIAS: () => t("PREMIUM_MONTHLY_ALIAS"), 
PREMIUM_ONE_OFF_ALIAS: () => t("PREMIUM_ONE_OFF_ALIAS"), 
PREMIUM_LOADING_OWNED: () => t("PREMIUM_LOADING_OWNED"), 
PREMIUM_LOADING_RELOAD: () => t("PREMIUM_LOADING_RELOAD"), 
PREMIUM_LOADING_APPROVED: () => t("PREMIUM_LOADING_APPROVED"), 
PREMIUM_LOADING_REGISTERED: () => t("PREMIUM_LOADING_REGISTERED"), 
PREMIUM_MANAGE_SUBSCRIPTIONS: () => t("PREMIUM_MANAGE_SUBSCRIPTIONS"), 
PREMIUM_IMPOSSIBLE_TO_VERIFY: () => t("PREMIUM_IMPOSSIBLE_TO_VERIFY"), 
PREMIUM_RESTORE_PURCHASE: () => t("PREMIUM_RESTORE_PURCHASE"), 
PREMIUM_YEARLY_HEADER: () => t("PREMIUM_YEARLY_HEADER"), 
PREMIUM_YEARLY_LIST: () => t("PREMIUM_YEARLY_LIST"), 
PREMIUM_LIFETIME_HEADER: () => t("PREMIUM_LIFETIME_HEADER"), 
PREMIUM_LIFETIME_LIST: () => t("PREMIUM_LIFETIME_LIST"), 
ENABLE_ANDROID_AUTOFILL_HEADING: () => t("ENABLE_ANDROID_AUTOFILL_HEADING"), 
ENABLE_ANDROID_AUTOFILL_INSTRUCTION_1: () => t("ENABLE_ANDROID_AUTOFILL_INSTRUCTION_1"), 
ENABLE_ANDROID_AUTOFILL_INSTRUCTION_2: () => t("ENABLE_ANDROID_AUTOFILL_INSTRUCTION_2"), 
ENABLE_ANDROID_AUTOFILL_INSTRUCTION_3: () => t("ENABLE_ANDROID_AUTOFILL_INSTRUCTION_3"), 
ENABLE_ANDROID_AUTOFILL_INSTRUCTION_4: () => t("ENABLE_ANDROID_AUTOFILL_INSTRUCTION_4"), 
ENABLE_ANDROID_AUTOFILL_INSTRUCTION_5: () => t("ENABLE_ANDROID_AUTOFILL_INSTRUCTION_5"), 
ENABLE_ANDROID_AUTOFILL_BUTTON: () => t("ENABLE_ANDROID_AUTOFILL_BUTTON"), 
ENABLE_ANDROID_AUTOFILL_BUTTON_NO: () => t("ENABLE_ANDROID_AUTOFILL_BUTTON_NO"), 
MAKE_ADMIN_MESSAGE: () => t("MAKE_ADMIN_MESSAGE"), 
MAKE_OWNER_MESSAGE: () => t("MAKE_OWNER_MESSAGE"), 
REMOVE_ADMIN_MESSAGE: () => t("REMOVE_ADMIN_MESSAGE"), 
DELETE_GROUP_CONFIRM: () => t("DELETE_GROUP_CONFIRM"), 
LEAVE_GROUP_CONFIRM: () => t("LEAVE_GROUP_CONFIRM"), 
GROUP_DELETED_MESSAGE: () => t("GROUP_DELETED_MESSAGE"), 
REMOVE_MEMBER_MESSAGE: () => t("REMOVE_MEMBER_MESSAGE"), 
REMOVE_MEMBER_SUCCESS: () => t("REMOVE_MEMBER_SUCCESS"), 
GROUP_SHARED_BY: () => t("GROUP_SHARED_BY"), 
ERROR_INVALID_NUM_WORDS: () => t("ERROR_INVALID_NUM_WORDS"), 
ERROR_INVALID_NUM_LETTERS: () => t("ERROR_INVALID_NUM_LETTERS"), 
UPGRADE_REQUIRED: () => t("UPGRADE_REQUIRED"), 
ADD_LOGIN_NOT_ALLOWED: () => t("ADD_LOGIN_NOT_ALLOWED"), 
UPGRADE_TODAY_MESSAGE: () => t("UPGRADE_TODAY_MESSAGE"), 
UPGRADE_TODAY_BUTTON: () => t("UPGRADE_TODAY_BUTTON"), 
UPGRADE_TODAY_PAGE_HEADING: () => t("UPGRADE_TODAY_PAGE_HEADING"), 
UPGRADE_TODAY_MSG_1: () => t("UPGRADE_TODAY_MSG_1"), 
UPGRADE_TODAY_PRICE: () => t("UPGRADE_TODAY_PRICE"), 
UPGRADE_TODAY_MSG_2: () => t("UPGRADE_TODAY_MSG_2"), 
UPGRADE_TODAY_MSG_3: () => t("UPGRADE_TODAY_MSG_3"), 
UPGRADE_TODAY_MSG_4: () => t("UPGRADE_TODAY_MSG_4"), 
INVITE_FRIEND_HEADER: () => t("INVITE_FRIEND_HEADER"), 
INVITE_FRIEND_REFERRAL_LABEL: () => t("INVITE_FRIEND_REFERRAL_LABEL"), 
INVITE_FRIEND_REFERRAL_COPY: () => t("INVITE_FRIEND_REFERRAL_COPY"), 
INVITE_FRIEND_REFERRAL_EMAIL: () => t("INVITE_FRIEND_REFERRAL_EMAIL"), 
INVITE_FRIEND_PREAMBLE: () => t("INVITE_FRIEND_PREAMBLE"), 
INVITE_FRIEND_TEXT: () => t("INVITE_FRIEND_TEXT"), 
INVITE_FRIEND_BENEFIT: () => t("INVITE_FRIEND_BENEFIT"), 
GROUPS_LIST_HEADER: () => t("GROUPS_LIST_HEADER"), 
GROUPS_LIST_INFO_MESSAGE: () => t("GROUPS_LIST_INFO_MESSAGE"), 
GROUPS_LIST_PERSONAL_HEADING: () => t("GROUPS_LIST_PERSONAL_HEADING"), 
GROUPS_LIST_OTHER_GROUPS_HEADING: () => t("GROUPS_LIST_OTHER_GROUPS_HEADING"), 
GROUPS_LIST_ADD_NEW_GROUP: () => t("GROUPS_LIST_ADD_NEW_GROUP"), 
GROUPS_LIST_CURRENTLY_ACTIVE: () => t("GROUPS_LIST_CURRENTLY_ACTIVE"), 
GROUPS_NEW_HEADER: () => t("GROUPS_NEW_HEADER"), 
GROUPS_NEW_RIGHT_NAV: () => t("GROUPS_NEW_RIGHT_NAV"), 
GROUPS_NEW_NAME_PLACEHOLDER: () => t("GROUPS_NEW_NAME_PLACEHOLDER"), 
GROUPS_ADD_GROUP_HEADER: () => t("GROUPS_ADD_GROUP_HEADER"), 
GROUPS_JOIN_GROUP: () => t("GROUPS_JOIN_GROUP"), 
GROUPS_JOIN_GROUP_TEXT: () => t("GROUPS_JOIN_GROUP_TEXT"), 
GROUPS_CREATE_GROUP: () => t("GROUPS_CREATE_GROUP"), 
GROUPS_CREATE_GROUP_TEXT: () => t("GROUPS_CREATE_GROUP_TEXT"), 
GROUPS_FREE_JOIN_WARNING: () => t("GROUPS_FREE_JOIN_WARNING"), 
GROUPS_ADD_UPGRADE: () => t("GROUPS_ADD_UPGRADE"), 
GROUP_NEW_INVITE_GENERATING: () => t("GROUP_NEW_INVITE_GENERATING"), 
LEAVE_GROUP_MESSAGE: () => t("LEAVE_GROUP_MESSAGE"), 
GROUP_DETAIL_LOADING: () => t("GROUP_DETAIL_LOADING"), 
GRP_SHARING_HEADING: () => t("GRP_SHARING_HEADING"), 
GRP_MEMBERS_HEADING: () => t("GRP_MEMBERS_HEADING"), 
GRP_SETTINGS_HEADING: () => t("GRP_SETTINGS_HEADING"), 
GROUP_VIEW_HEADER: () => t("GROUP_VIEW_HEADER"), 
GROUP_VIEW_RIGHT_NAV: () => t("GROUP_VIEW_RIGHT_NAV"), 
GROUP_VIEW_USER_ADMIN: () => t("GROUP_VIEW_USER_ADMIN"), 
GROUP_VIEW_USER_OWNER: () => t("GROUP_VIEW_USER_OWNER"), 
GROUP_VIEW_USER_YOU: () => t("GROUP_VIEW_USER_YOU"), 
GROUP_VIEW_USER_READ_ONLY: () => t("GROUP_VIEW_USER_READ_ONLY"), 
GROUP_VIEW_SWITCH_TO: () => t("GROUP_VIEW_SWITCH_TO"), 
GROUP_VIEW_LEAVE: () => t("GROUP_VIEW_LEAVE"), 
GROUP_VIEW_DELETE: () => t("GROUP_VIEW_DELETE"), 
GROUP_VIEW_PREMIUM: () => t("GROUP_VIEW_PREMIUM"), 
GROUP_VIEW_FREE: () => t("GROUP_VIEW_FREE"), 
GROUP_DETAIL_SAVE_LOADING: () => t("GROUP_DETAIL_SAVE_LOADING"), 
GROUP_EDIT_HEADER: () => t("GROUP_EDIT_HEADER"), 
GROUP_EDIT_MAX_MEMBERS: () => t("GROUP_EDIT_MAX_MEMBERS"), 
GROUP_EDIT_MAX_MEMBERS_SECONDARY: () => t("GROUP_EDIT_MAX_MEMBERS_SECONDARY"), 
PERSONAL_GROUP_MESSAGE: () => t("PERSONAL_GROUP_MESSAGE"), 
GROUP_CHOOSE_ICON: () => t("GROUP_CHOOSE_ICON"), 
GROUP_NAME_BLANK: () => t("GROUP_NAME_BLANK"), 
GROUP_NAME_TOO_LONG: () => t("GROUP_NAME_TOO_LONG"), 
GRP_MAX_MEMBERS_ERROR: () => t("GRP_MAX_MEMBERS_ERROR"), 
JOIN_GROUP_PLACEHOLDER: () => t("JOIN_GROUP_PLACEHOLDER"), 
JOIN_GROUP_HEADING: () => t("JOIN_GROUP_HEADING"), 
GRP_INVITE_CODE_LABEL: () => t("GRP_INVITE_CODE_LABEL"), 
GRP_INVITE_CODE_NOTE: () => t("GRP_INVITE_CODE_NOTE"), 
JOIN_GROUP_RIGHT_NAV: () => t("JOIN_GROUP_RIGHT_NAV"), 
REFERRAL_COPY_MESSAGE: () => t("REFERRAL_COPY_MESSAGE"), 
CREATE_GROUP_INVITE_TEXT_1: () => t("CREATE_GROUP_INVITE_TEXT_1"), 
INVITE_NEW_MEMBER: () => t("INVITE_NEW_MEMBER"), 
GRP_INVITE_HELP_TEXT: () => t("GRP_INVITE_HELP_TEXT"), 
GRP_INVITE_CLIPBOARD_MESSAGE: () => t("GRP_INVITE_CLIPBOARD_MESSAGE"), 
INVITE_LINK_HEADING: () => t("INVITE_LINK_HEADING"), 
INVITE_SETTINGS_HEADING: () => t("INVITE_SETTINGS_HEADING"), 
LINK_EXPIRY_LABEL: () => t("LINK_EXPIRY_LABEL"), 
MAX_USES_LABEL: () => t("MAX_USES_LABEL"), 
LINK_EXPIRY_VALUE: () => t("LINK_EXPIRY_VALUE"), 
LINK_MAX_USES_VALUE: () => t("LINK_MAX_USES_VALUE"), 
INVITE_NO_LIMIT: () => t("INVITE_NO_LIMIT"), 
INVITE_EXPIRY_NEVER: () => t("INVITE_EXPIRY_NEVER"), 
DEACTIVATE_GRP_LINK_CONFIRM: () => t("DEACTIVATE_GRP_LINK_CONFIRM"), 
DEACTIVATE_GRP_LINK_SUCCESS: () => t("DEACTIVATE_GRP_LINK_SUCCESS"), 
GRP_INVITE_CREATE_NAV: () => t("GRP_INVITE_CREATE_NAV"), 
SHARE_INVITE_HEADING: () => t("SHARE_INVITE_HEADING"), 
CPY_GRP_INVITE: () => t("CPY_GRP_INVITE"), 
DEACTIVATE_LINK: () => t("DEACTIVATE_LINK"), 
SHARE_INVITE_VIA_EMAIL: () => t("SHARE_INVITE_VIA_EMAIL"), 
COPY_INVITE_SUCCESS: () => t("COPY_INVITE_SUCCESS"), 
ONBOARDING_HEADER_1: () => t("ONBOARDING_HEADER_1"), 
ONBOARDING_HEADER_2: () => t("ONBOARDING_HEADER_2"), 
ONBOARDING_HEADER_3: () => t("ONBOARDING_HEADER_3"), 
ONBOARDING_HEADER_4: () => t("ONBOARDING_HEADER_4"), 
ONBOARDING_HEADER_5: () => t("ONBOARDING_HEADER_5"), 
ONBOARDING_WELCOME_HEADING: () => t("ONBOARDING_WELCOME_HEADING"), 
ONBOARDING_WELCOME_TEXT: () => t("ONBOARDING_WELCOME_TEXT"), 
ONBOARDING_DOODLE_HEADING: () => t("ONBOARDING_DOODLE_HEADING"), 
ONBOARDING_DOODLE_TEXT: () => t("ONBOARDING_DOODLE_TEXT"), 
ONBOARDING_HOW_IT_WORKS: () => t("ONBOARDING_HOW_IT_WORKS"), 
ONBOARDING_ADDSERVICE_HEADING: () => t("ONBOARDING_ADDSERVICE_HEADING"), 
ONBOARDING_ADDSERVICE_TEXT: () => t("ONBOARDING_ADDSERVICE_TEXT"), 
ONBOARDING_RESETPASSWORD_HEADING: () => t("ONBOARDING_RESETPASSWORD_HEADING"), 
ONBOARDING_RESETPASSWORD_TEXT: () => t("ONBOARDING_RESETPASSWORD_TEXT"), 
ONBOARDING_SECURESHARING_HEADING: () => t("ONBOARDING_SECURESHARING_HEADING"), 
ONBOARDING_SECURESHARING_TEXT: () => t("ONBOARDING_SECURESHARING_TEXT"), 
ONBOARDING_UNLIKE_OTHERS_HEADING: () => t("ONBOARDING_UNLIKE_OTHERS_HEADING"), 
ONBOARDING_UNLIKE_OTHERS_TEXT: () => t("ONBOARDING_UNLIKE_OTHERS_TEXT"), 
ONBOARDING_DOODLETUTORIAL1_HEADING: () => t("ONBOARDING_DOODLETUTORIAL1_HEADING"), 
ONBOARDING_SHOWMEHOW: () => t("ONBOARDING_SHOWMEHOW"), 
ONBOARDING_DOODLETUTORIAL2_HEADING: () => t("ONBOARDING_DOODLETUTORIAL2_HEADING"), 
ONBOARDING_DOODLETUTORIAL: () => t("ONBOARDING_DOODLETUTORIAL"), 
ONBOARDING_DOODLETUTORIAL3_HEADING: () => t("ONBOARDING_DOODLETUTORIAL3_HEADING"), 
ONBOARDING_DOODLETUTORIAL4_HEADING: () => t("ONBOARDING_DOODLETUTORIAL4_HEADING"), 
ONBOARDING_DOODLETUTORIAL5_HEADING: () => t("ONBOARDING_DOODLETUTORIAL5_HEADING"), 
ONBOARDING_DOODLETUTORIAL6_HEADING: () => t("ONBOARDING_DOODLETUTORIAL6_HEADING"), 
ONBOARDING_DOODLETUTORIAL7_HEADING: () => t("ONBOARDING_DOODLETUTORIAL7_HEADING"), 
ONBOARDING_DOODLETUTORIAL8_HEADING: () => t("ONBOARDING_DOODLETUTORIAL8_HEADING"), 
ONBOARDING_YOURTURN: () => t("ONBOARDING_YOURTURN"), 
ONBOARDING_COMPLETE_WELCOME: () => t("ONBOARDING_COMPLETE_WELCOME"), 
ONBOARDING_COMPLETE_COMPLETE_SETUP: () => t("ONBOARDING_COMPLETE_COMPLETE_SETUP"), 
ONBOARDING_COMPLETE_CREATE_FORGHETTI: () => t("ONBOARDING_COMPLETE_CREATE_FORGHETTI"), 
ONBOARDING_COMPLETE_SIGNATURE_DOODLE: () => t("ONBOARDING_COMPLETE_SIGNATURE_DOODLE"), 
ONBOARDING_COMPLETE_RESET_PASSWORD: () => t("ONBOARDING_COMPLETE_RESET_PASSWORD"), 
ONBOARDING_COMPLETE_IMPORT: () => t("ONBOARDING_COMPLETE_IMPORT"), 
ONBOARDING_COMPLETE_HEALTHCHECK: () => t("ONBOARDING_COMPLETE_HEALTHCHECK"), 
ONBOARDING_COMPLETE_IMPORT_BUTTON: () => t("ONBOARDING_COMPLETE_IMPORT_BUTTON"), 
ONBOARDING_COMPLETE_REMIND_LATER: () => t("ONBOARDING_COMPLETE_REMIND_LATER"), 
ONBOARDING_COMPLETE_CONTINUE: () => t("ONBOARDING_COMPLETE_CONTINUE"), 
ONBOARDING_COMPLETE_HEALTHCHECK_BUTTON: () => t("ONBOARDING_COMPLETE_HEALTHCHECK_BUTTON"), 
ONBOARDING_COMPLETE_NEXT_STEPS: () => t("ONBOARDING_COMPLETE_NEXT_STEPS"), 
ONBOARDING_EXISTING_TITLE: () => t("ONBOARDING_EXISTING_TITLE"), 
ONBOARDING_EXISTING_WELCOME_HEADING: () => t("ONBOARDING_EXISTING_WELCOME_HEADING"), 
ONBOARDING_EXISTING_WELCOME_TEXT: () => t("ONBOARDING_EXISTING_WELCOME_TEXT"), 
ONBOARDING_EXISTING_MOVE_SERVICES_HEADING: () => t("ONBOARDING_EXISTING_MOVE_SERVICES_HEADING"), 
ONBOARDING_EXISTING_MOVE_SERVICES_TEXT: () => t("ONBOARDING_EXISTING_MOVE_SERVICES_TEXT"), 
ONBOARDING_EXISTING_HEALTHCHECK_HEADING: () => t("ONBOARDING_EXISTING_HEALTHCHECK_HEADING"), 
ONBOARDING_EXISTING_HEALTHCHECK_TEXT: () => t("ONBOARDING_EXISTING_HEALTHCHECK_TEXT"), 
ONBOARIDNG_EXISTING_SHARING_HEADING: () => t("ONBOARIDNG_EXISTING_SHARING_HEADING"), 
ONBOARDING_EXISTING_SHARING_TEXT: () => t("ONBOARDING_EXISTING_SHARING_TEXT"), 
ONBOARDING_EXISTING_SWITCHING_HEADING: () => t("ONBOARDING_EXISTING_SWITCHING_HEADING"), 
ONBOARDING_EXISTING_SWTICHING_TEXT: () => t("ONBOARDING_EXISTING_SWTICHING_TEXT"), 
ONBOARDING_EXISTING_PRICING_HEADING: () => t("ONBOARDING_EXISTING_PRICING_HEADING"), 
ONBOARDING_EXISTING_PRICING_TEXT: () => t("ONBOARDING_EXISTING_PRICING_TEXT"), 
ACCOUNT_SECURITY_HEADER: () => t("ACCOUNT_SECURITY_HEADER"), 
ACCOUNT_SECURITY_HEADING: () => t("ACCOUNT_SECURITY_HEADING"), 
ACCOUNT_SECURITY_SECONDARY_HEADING: () => t("ACCOUNT_SECURITY_SECONDARY_HEADING"), 
ACCOUNT_SECURITY_LEARN_MORE: () => t("ACCOUNT_SECURITY_LEARN_MORE"), 
MSG_FALLBACK_ERROR_MESSAGE: () => t("MSG_FALLBACK_ERROR_MESSAGE"), 
MSG_GROUP_ICON_UPDATED: () => t("MSG_GROUP_ICON_UPDATED"), 
MSG_COPIED_FORGHETTIBLE: () => t("MSG_COPIED_FORGHETTIBLE"), 
MSG_OPEN_FORGHETTIBLE: () => t("MSG_OPEN_FORGHETTIBLE"), 
MSG_DELETE_LOGIN_CONFIRM: () => t("MSG_DELETE_LOGIN_CONFIRM"), 
MSG_LOGIN_DELETED: () => t("MSG_LOGIN_DELETED"), 
MSG_SERVICE_ICON_DELETED: () => t("MSG_SERVICE_ICON_DELETED"), 
MSG_LOGOUT_CONFIRM: () => t("MSG_LOGOUT_CONFIRM"), 
MSG_LOGOUT_ALL_DEVICES_CONFIRM: () => t("MSG_LOGOUT_ALL_DEVICES_CONFIRM"), 
MSG_USER_PROMOTED_ADMIN: () => t("MSG_USER_PROMOTED_ADMIN"), 
MSG_USER_PROMOTED_OWNER: () => t("MSG_USER_PROMOTED_OWNER"), 
MSG_CONFIRM_RESET: () => t("MSG_CONFIRM_RESET"), 
MSG_CONFIRM_RESTORE: () => t("MSG_CONFIRM_RESTORE"), 
MSG_NEW_GROUP_CREATED: () => t("MSG_NEW_GROUP_CREATED"), 
MSG_OPENING_WEBSITE: () => t("MSG_OPENING_WEBSITE"), 
MSG_REMOVED_ADMIN: () => t("MSG_REMOVED_ADMIN"), 
MSG_UNSAVED_CONFIRM: () => t("MSG_UNSAVED_CONFIRM"), 
EXTENSION_NONE_FOUND: () => t("EXTENSION_NONE_FOUND"), 
EXTENSION_GO_TO_FORGHETTI: () => t("EXTENSION_GO_TO_FORGHETTI"), 
EXTENSION_NOACCESS_HEADER: () => t("EXTENSION_NOACCESS_HEADER"), 
EXTENSION_UPGRADE: () => t("EXTENSION_UPGRADE"), 
EXTENSION_NOLOGINFOUND_HEADER: () => t("EXTENSION_NOLOGINFOUND_HEADER"), 
EXTENSION_NOLOGINFOUND_MSG: () => t("EXTENSION_NOLOGINFOUND_MSG"), 
CONTEXT_EDIT_LOGIN_LIST_ITEM: () => t("CONTEXT_EDIT_LOGIN_LIST_ITEM"), 
CONTEXT_MOVE_LOGIN_LIST_ITEM: () => t("CONTEXT_MOVE_LOGIN_LIST_ITEM"), 
CONTEXT_ADD_LOGIN_LIST_ITEM: () => t("CONTEXT_ADD_LOGIN_LIST_ITEM"), 
CONTEXT_RESET_LOGIN_LIST_ITEM: () => t("CONTEXT_RESET_LOGIN_LIST_ITEM"), 
CONTEXT_DELETE_LOGIN_LIST_ITEM: () => t("CONTEXT_DELETE_LOGIN_LIST_ITEM"), 
CONTEXT_UPLOAD_GROUP_PHOTO: () => t("CONTEXT_UPLOAD_GROUP_PHOTO"), 
CONTEXT_UPLOAD_GROUP_IMAGE: () => t("CONTEXT_UPLOAD_GROUP_IMAGE"), 
CONTEXT_CHOOSE_GROUP_IMAGE: () => t("CONTEXT_CHOOSE_GROUP_IMAGE"), 
CONTEXT_TAKE_PROFILE_PIC: () => t("CONTEXT_TAKE_PROFILE_PIC"), 
CONTEXT_UPLOAD_PROFILE_PIC: () => t("CONTEXT_UPLOAD_PROFILE_PIC"), 
CONTEXT_UPLOAD_SERVICE_IMAGE: () => t("CONTEXT_UPLOAD_SERVICE_IMAGE"), 
CONTEXT_CHOOSE_SERVICE_IMAGE: () => t("CONTEXT_CHOOSE_SERVICE_IMAGE"), 
CONTEXT_SERVICE_IMAGE_WEBSEARCH: () => t("CONTEXT_SERVICE_IMAGE_WEBSEARCH"), 
CONTEXT_MAKE_ADMIN: () => t("CONTEXT_MAKE_ADMIN"), 
CONTEXT_MAKE_OWNER: () => t("CONTEXT_MAKE_OWNER"), 
CONTEXT_REMOVE_FROM_GROUP: () => t("CONTEXT_REMOVE_FROM_GROUP"), 
CONTEXT_REVOKE_ADMIN: () => t("CONTEXT_REVOKE_ADMIN"), 
CONTEXT_NEW_GROUP_MEMBERS: () => t("CONTEXT_NEW_GROUP_MEMBERS"), 
CONTEXT_SELECT_FIELD_TYPE: () => t("CONTEXT_SELECT_FIELD_TYPE"), 
CONTEXT_FIELD_TYPE_LNS: () => t("CONTEXT_FIELD_TYPE_LNS"), 
CONTEXT_FIELD_TYPE_WNS: () => t("CONTEXT_FIELD_TYPE_WNS"), 
CONTEXT_FIELD_TYPE_LNX: () => t("CONTEXT_FIELD_TYPE_LNX"), 
CONTEXT_FIELD_TYPE_WNX: () => t("CONTEXT_FIELD_TYPE_WNX"), 
CONTEXT_FIELD_TYPE_LN: () => t("CONTEXT_FIELD_TYPE_LN"), 
CONTEXT_FIELD_TYPE_WN: () => t("CONTEXT_FIELD_TYPE_WN"), 
CONTEXT_FIELD_TYPE_L: () => t("CONTEXT_FIELD_TYPE_L"), 
CONTEXT_FIELD_TYPE_W: () => t("CONTEXT_FIELD_TYPE_W"), 
CONTEXT_FIELD_TYPE_N: () => t("CONTEXT_FIELD_TYPE_N"), 
CONTEXT_ADD_FIELD: () => t("CONTEXT_ADD_FIELD"), 
CONTEXT_ADD_FIELD_PASS: () => t("CONTEXT_ADD_FIELD_PASS"), 
CONTEXT_ADD_FIELD_COMPLEX_PASS: () => t("CONTEXT_ADD_FIELD_COMPLEX_PASS"), 
CONTEXT_ADD_FIELD_PIN: () => t("CONTEXT_ADD_FIELD_PIN"), 
CONTEXT_ADD_FIELD_MEMWRD: () => t("CONTEXT_ADD_FIELD_MEMWRD"), 
CONTEXT_ADD_FIELD_FORGHETTIBLE: () => t("CONTEXT_ADD_FIELD_FORGHETTIBLE"), 
CONTEXT_LINK_EXPIRE_15MINS: () => t("CONTEXT_LINK_EXPIRE_15MINS"), 
CONTEXT_LINK_EXPIRE_30MINS: () => t("CONTEXT_LINK_EXPIRE_30MINS"), 
CONTEXT_LINK_EXPIRE_1HOUR: () => t("CONTEXT_LINK_EXPIRE_1HOUR"), 
CONTEXT_LINK_EXPIRE_6HOURS: () => t("CONTEXT_LINK_EXPIRE_6HOURS"), 
CONTEXT_LINK_EXPIRE_12HOURS: () => t("CONTEXT_LINK_EXPIRE_12HOURS"), 
CONTEXT_LINK_EXPIRE_1DAY: () => t("CONTEXT_LINK_EXPIRE_1DAY"), 
CONTEXT_LINK_EXPIRE_NEVER: () => t("CONTEXT_LINK_EXPIRE_NEVER"), 
CONTEXT_LINK_EXPIRE_CANCEL: () => t("CONTEXT_LINK_EXPIRE_CANCEL"), 
CONTEXT_LINK_USES_1: () => t("CONTEXT_LINK_USES_1"), 
CONTEXT_LINK_USES_2: () => t("CONTEXT_LINK_USES_2"), 
CONTEXT_LINK_USES_5: () => t("CONTEXT_LINK_USES_5"), 
CONTEXT_LINK_USES_10: () => t("CONTEXT_LINK_USES_10"), 
CONTEXT_LINK_USES_20: () => t("CONTEXT_LINK_USES_20"), 
CONTEXT_LINK_USES_50: () => t("CONTEXT_LINK_USES_50"), 
CONTEXT_LINK_USES_NO_LIMIT: () => t("CONTEXT_LINK_USES_NO_LIMIT"), 
CONTEXT_LINK_USES_CANCEL: () => t("CONTEXT_LINK_USES_CANCEL"), 
FINGERPRINT_TITLE: () => t("FINGERPRINT_TITLE"), 
FINGERPRINT_DESCRIPTION: () => t("FINGERPRINT_DESCRIPTION"), 
FINGERPRINT_DESCRIPTION_FACE: () => t("FINGERPRINT_DESCRIPTION_FACE"), 
GENERATING_GROUP: () => t("GENERATING_GROUP"), 
GENERATING_PASSWORD: () => t("GENERATING_PASSWORD"), 
DELETING_GROUP: () => t("DELETING_GROUP"), 
LOADING_SERVICE: () => t("LOADING_SERVICE"), 
UNABLE_TO_PURCHASE: () => t("UNABLE_TO_PURCHASE"), 
HEALTHCHECK_WELCOME_HEADER: () => t("HEALTHCHECK_WELCOME_HEADER"), 
HEALTHCHECK_WELCOME_NOTE: () => t("HEALTHCHECK_WELCOME_NOTE"), 
HEALTHCHECK_WELCOME_BUTTON: () => t("HEALTHCHECK_WELCOME_BUTTON"), 
HEALTHCHECK_USERNAMES_HEADER: () => t("HEALTHCHECK_USERNAMES_HEADER"), 
HEALTHCHECK_USERNAMES_INFO_1: () => t("HEALTHCHECK_USERNAMES_INFO_1"), 
HEALTHCHECK_USERNAMES_INFO_2: () => t("HEALTHCHECK_USERNAMES_INFO_2"), 
HEALTHCHECK_USERNAMES_PRIMARY_SUBHEAD: () => t("HEALTHCHECK_USERNAMES_PRIMARY_SUBHEAD"), 
HEALTHCHECK_USERNAMES_SECONDARY_SUBHEAD: () => t("HEALTHCHECK_USERNAMES_SECONDARY_SUBHEAD"), 
HEALTHCHECK_USERNAMES_PLACEHOLDER: () => t("HEALTHCHECK_USERNAMES_PLACEHOLDER"), 
HEALTHCHECK_PASSWORD_HEADER: () => t("HEALTHCHECK_PASSWORD_HEADER"), 
HEALTHCHECK_PASSWORD_INFO_1: () => t("HEALTHCHECK_PASSWORD_INFO_1"), 
HEALTHCHECK_PASSWORD_INFO_2: () => t("HEALTHCHECK_PASSWORD_INFO_2"), 
HEALTHCHECK_PASSWORD_INFO_3: () => t("HEALTHCHECK_PASSWORD_INFO_3"), 
HEALTHCHECK_PASSWORD_SUBHEAD: () => t("HEALTHCHECK_PASSWORD_SUBHEAD"), 
HEALTHCHECK_PASSWORD_OPTIONAL: () => t("HEALTHCHECK_PASSWORD_OPTIONAL"), 
HEALTHCHECK_PASSWORD_PLACEHOLDER: () => t("HEALTHCHECK_PASSWORD_PLACEHOLDER"), 
HEALTHCHECK_PASSWORD_VAL_TOO_LONG: () => t("HEALTHCHECK_PASSWORD_VAL_TOO_LONG"), 
HEALTHCHECK_RUNNING: () => t("HEALTHCHECK_RUNNING"), 
HEALTHCHECK_USERNAME_VAL_FIELD_BLANK: () => t("HEALTHCHECK_USERNAME_VAL_FIELD_BLANK"), 
HEALTHCHECK_USERNAME_VAL_FIELD_DUPLICATE: () => t("HEALTHCHECK_USERNAME_VAL_FIELD_DUPLICATE"), 
HEALTHCHECK_USERNAME_VAL_FIELD_TOO_SHORT: () => t("HEALTHCHECK_USERNAME_VAL_FIELD_TOO_SHORT"), 
HEALTHCHECK_USERNAME_VAL_FIELD_TOO_LONG: () => t("HEALTHCHECK_USERNAME_VAL_FIELD_TOO_LONG"), 
HEALTHCHECK_ASSESSMENT_HEADER: () => t("HEALTHCHECK_ASSESSMENT_HEADER"), 
HEALTHCHECK_ASSESSMENT_EMAIL: () => t("HEALTHCHECK_ASSESSMENT_EMAIL"), 
HEALTHCHECK_ASSESSMENT_INFO_1: () => t("HEALTHCHECK_ASSESSMENT_INFO_1"), 
HEALTHCHECK_ASSESSMENT_INFO_2: () => t("HEALTHCHECK_ASSESSMENT_INFO_2"), 
HEALTHCHECK_ASSESSMENT_PASSWORD: () => t("HEALTHCHECK_ASSESSMENT_PASSWORD"), 
HEALTHCHECK_ASSESSMENT_COMPROMISED_HEAD: () => t("HEALTHCHECK_ASSESSMENT_COMPROMISED_HEAD"), 
HEALTHCHECK_ASSESSMENT_NO_BREACH: () => t("HEALTHCHECK_ASSESSMENT_NO_BREACH"), 
HEALTHCHECK_ASSESSMENT_PASSWORD_STRENGTH: () => t("HEALTHCHECK_ASSESSMENT_PASSWORD_STRENGTH"), 
HEALTHCHECK_ASSESSMENT_PASSWORD_WEAK: () => t("HEALTHCHECK_ASSESSMENT_PASSWORD_WEAK"), 
HEALTHCHECK_ASSESSMENT_PASSWORD_STRONG: () => t("HEALTHCHECK_ASSESSMENT_PASSWORD_STRONG"), 
HEALTHCHECK_ASSESSMENT_PASSWORD_COMP: () => t("HEALTHCHECK_ASSESSMENT_PASSWORD_COMP"), 
HEALTHCHECK_ASSESSMENT_HIBP_LINK: () => t("HEALTHCHECK_ASSESSMENT_HIBP_LINK"), 
HEALTHCHECK_ASSESMENT_UPGRADE_MESSAGE: () => t("HEALTHCHECK_ASSESMENT_UPGRADE_MESSAGE"), 
HEALTHCHECK_DETAILS_PASSWORD_HEADER: () => t("HEALTHCHECK_DETAILS_PASSWORD_HEADER"), 
HEALTHCHECK_DETAILS_USER_HEADER: () => t("HEALTHCHECK_DETAILS_USER_HEADER"), 
HEALTHCHECK_DETAILS_USER_TITLE: () => t("HEALTHCHECK_DETAILS_USER_TITLE"), 
HEALTHCHECK_DETAILS_USER_DOMAIN: () => t("HEALTHCHECK_DETAILS_USER_DOMAIN"), 
HEALTHCHECK_DETAILS_USER_DATE: () => t("HEALTHCHECK_DETAILS_USER_DATE"), 
HEALTHCHECK_DETAILS_USER_ACCOUNTS_PWNED: () => t("HEALTHCHECK_DETAILS_USER_ACCOUNTS_PWNED"), 
HEALTHCHECK_DETAILS_PASSWORD_INFO_X_BREACH: () => t("HEALTHCHECK_DETAILS_PASSWORD_INFO_X_BREACH"), 
HEALTHCHECK_DETAILS_PASSWORD_INFO_NO_BREACH: () => t("HEALTHCHECK_DETAILS_PASSWORD_INFO_NO_BREACH"), 
HEALTHCHECK_DETAILS_PASSWORD_STRENGTH_INFO_STRONG: () => t("HEALTHCHECK_DETAILS_PASSWORD_STRENGTH_INFO_STRONG"), 
HEALTHCHECK_DETAILS_PASSWORD_STRENGTH_INFO_WEAK: () => t("HEALTHCHECK_DETAILS_PASSWORD_STRENGTH_INFO_WEAK"), 
HEALTHCHECK_DETAILS_RESET_NOTE: () => t("HEALTHCHECK_DETAILS_RESET_NOTE"), 
HEALTHCHECK_DETAILS_RESET_BUTTON: () => t("HEALTHCHECK_DETAILS_RESET_BUTTON"), 
HEALTHCHECK_DETAILS_HIBP_LINK: () => t("HEALTHCHECK_DETAILS_HIBP_LINK"), 
HEALTHCHECK_LOADING_DETAIL: () => t("HEALTHCHECK_LOADING_DETAIL"), 
IMPORT_SERVICE_WELCOME_BUTTON: () => t("IMPORT_SERVICE_WELCOME_BUTTON"), 
IMPORT_SERVICE_WELCOME_INFO: () => t("IMPORT_SERVICE_WELCOME_INFO"), 
IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_1: () => t("IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_1"), 
IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_2: () => t("IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_2"), 
IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_BUTTON: () => t("IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_BUTTON"), 
IMPORT_SERVICES_LIST_HEADER: () => t("IMPORT_SERVICES_LIST_HEADER"), 
IMPORT_SERVICES_LIST_ERROR_NONE_SELECTED: () => t("IMPORT_SERVICES_LIST_ERROR_NONE_SELECTED"), 
IMPORT_SERVICES_YOUR_IMPORTED_SERVICES: () => t("IMPORT_SERVICES_YOUR_IMPORTED_SERVICES"), 
IMPORT_SERVICES_LIST_NOTE: () => t("IMPORT_SERVICES_LIST_NOTE"), 
IMPORT_SERVICES_GROUP_NOTE: () => t("IMPORT_SERVICES_GROUP_NOTE"), 
IMPORT_SERVICES_GROUP_HEADER: () => t("IMPORT_SERVICES_GROUP_HEADER"), 
IMPORT_SERVICES_USERNAME_HEADER: () => t("IMPORT_SERVICES_USERNAME_HEADER"), 
IMPORT_SERVICES_USERNAME_NOTE: () => t("IMPORT_SERVICES_USERNAME_NOTE"), 
IMPORT_SERVICES_CREATE_HEADER: () => t("IMPORT_SERVICES_CREATE_HEADER"), 
IMPORT_SERVICES_CREATE_BUTTON: () => t("IMPORT_SERVICES_CREATE_BUTTON"), 
IMPORT_SERVICES_CREATE_HOW_MANY: () => t("IMPORT_SERVICES_CREATE_HOW_MANY"), 
IMPORT_SERVICES_CREATE_LOADING: () => t("IMPORT_SERVICES_CREATE_LOADING"), 
IMPORT_SERVICES_COMPLETE_HEADER: () => t("IMPORT_SERVICES_COMPLETE_HEADER"), 
IMPORT_SERVICES_COMPLETE_NOTE: () => t("IMPORT_SERVICES_COMPLETE_NOTE"), 
IMPORT_FOLDER_SELECT_HEADER: () => t("IMPORT_FOLDER_SELECT_HEADER"), 
IMPORT_FOLDER_SELECT_CHROME: () => t("IMPORT_FOLDER_SELECT_CHROME"), 
IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC: () => t("IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC"), 
IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_2: () => t("IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_2"), 
IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_3: () => t("IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_3"), 
IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_4: () => t("IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_4"), 
IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_5: () => t("IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_5"), 
IMPORT_FOLDER_SELECT_CHROME_BUTTON: () => t("IMPORT_FOLDER_SELECT_CHROME_BUTTON"), 
IMPORT_GROUP_HEADER: () => t("IMPORT_GROUP_HEADER"), 
IMPORT_GROUP_NOTE: () => t("IMPORT_GROUP_NOTE"), 
IMPORT_GROUP_NOTE_2: () => t("IMPORT_GROUP_NOTE_2"), 
IMPORT_GROUP_NOTE_3: () => t("IMPORT_GROUP_NOTE_3"), 
IMPORT_GROUP_BUTTON: () => t("IMPORT_GROUP_BUTTON"), 
IMPORT_SERVICE_IMPORT_NODATA_HEADER: () => t("IMPORT_SERVICE_IMPORT_NODATA_HEADER"), 
IMPORT_SERVICE_IMPORT_NODATA_NOTE: () => t("IMPORT_SERVICE_IMPORT_NODATA_NOTE"), 
IMPORT_MOVE_SERVICE_HEADER: () => t("IMPORT_MOVE_SERVICE_HEADER"), 
IMPORT_MOVE_SERVICE_NOTE_1: () => t("IMPORT_MOVE_SERVICE_NOTE_1"), 
IMPORT_MOVE_SERVICE_BUTTON: () => t("IMPORT_MOVE_SERVICE_BUTTON"), 
IMPORT_SERVICE_LOADING: () => t("IMPORT_SERVICE_LOADING"), 
IMPORT_SERVICE_GROUP_NAME: () => t("IMPORT_SERVICE_GROUP_NAME"), 
NEW_GROUP_REQUIRES_PREMIUM: () => t("NEW_GROUP_REQUIRES_PREMIUM"), 
IMPORT_TYPE_HEADER: () => t("IMPORT_TYPE_HEADER"), 
IMPORT_TYPE_NOTE_1: () => t("IMPORT_TYPE_NOTE_1"), 
IMPORT_TYPE_BUTTON_1: () => t("IMPORT_TYPE_BUTTON_1"), 
IMPORT_TYPE_NOTE_2: () => t("IMPORT_TYPE_NOTE_2"), 
IMPORT_TYPE_BUTTON_2: () => t("IMPORT_TYPE_BUTTON_2"), 
IMPORT_TYPE_NOTE_3: () => t("IMPORT_TYPE_NOTE_3"), 
IMPORT_UPLOAD_HEADER: () => t("IMPORT_UPLOAD_HEADER"), 
IMPORT_UPLOAD_NOTE_1: () => t("IMPORT_UPLOAD_NOTE_1"), 
IMPORT_UPLOAD_BUTTON: () => t("IMPORT_UPLOAD_BUTTON"), 
IMPORT_UPLOAD_NOTE_2: () => t("IMPORT_UPLOAD_NOTE_2"), 
IMPORT_UPLOAD_SAMPLE_CSV: () => t("IMPORT_UPLOAD_SAMPLE_CSV"), 
IMPORT_UPLOAD_SAMPLE_XLS: () => t("IMPORT_UPLOAD_SAMPLE_XLS"), 
NO_LOGINS_MESSAGE: () => t("NO_LOGINS_MESSAGE"), 
LOGIN_RECENT: () => t("LOGIN_RECENT"), 
HELP_LOGIN_TITLE: () => t("HELP_LOGIN_TITLE"), 
HELP_LOGIN_USERNAME: () => t("HELP_LOGIN_USERNAME"), 
HELP_LOGIN_WEBSITE: () => t("HELP_LOGIN_WEBSITE"), 
HELP_LOGIN_ADD_FORGHETTIBLE: () => t("HELP_LOGIN_ADD_FORGHETTIBLE"), 
HELP_GROUPS_LIST_HEADER: () => t("HELP_GROUPS_LIST_HEADER"), 
HELP_GROUPS_LIST_PERSONAL: () => t("HELP_GROUPS_LIST_PERSONAL"), 
HELP_GROUPS_LIST_OTHER_GROUPS: () => t("HELP_GROUPS_LIST_OTHER_GROUPS"), 
HELP_DOODLEPAD: () => t("HELP_DOODLEPAD"), 
HELP_SERVICE_SUMMARY: () => t("HELP_SERVICE_SUMMARY"), 
HELP_FIELD_SETTINGS_LENGTH: () => t("HELP_FIELD_SETTINGS_LENGTH"), 
HELP_FIELD_SETTINGS_TYPE: () => t("HELP_FIELD_SETTINGS_TYPE"), 
HELP_FIELD_SETTINGS_NAME: () => t("HELP_FIELD_SETTINGS_NAME"), 
HELP_FIELD_SETTINGS_RESET: () => t("HELP_FIELD_SETTINGS_RESET"), 
HELP_RESTORE_PAGE: () => t("HELP_RESTORE_PAGE"), 
HELP_LOGINS_LIST: () => t("HELP_LOGINS_LIST"), 
HELP_GROUP_PAGE: () => t("HELP_GROUP_PAGE"), 
HELP_GROUP_SHARING: () => t("HELP_GROUP_SHARING"), 
HELP_GROUP_MEMBERS: () => t("HELP_GROUP_MEMBERS"), 
HELP_GROUP_INVITE_MAX_USES: () => t("HELP_GROUP_INVITE_MAX_USES"), 
REFERRAL_EMAIL_SUBJECT: () => t("REFERRAL_EMAIL_SUBJECT"), 
REFERRAL_EMAIL_BODY: () => t("REFERRAL_EMAIL_BODY"), 
GRP_INVITE_EMAIL_SUBJECT: () => t("GRP_INVITE_EMAIL_SUBJECT"), 
GRP_INVITE_EMAIL_BODY: () => t("GRP_INVITE_EMAIL_BODY"), 
APPRATE_TITLE: () => t("APPRATE_TITLE"), 
APPRATE_MESSAGE: () => t("APPRATE_MESSAGE"), 
APPRATE_CANCEL: () => t("APPRATE_CANCEL"), 
APPRATE_LATER: () => t("APPRATE_LATER"), 
APPRATE_RATE: () => t("APPRATE_RATE"), 
APPRATE_YES: () => t("APPRATE_YES"), 
APPRATE_NO: () => t("APPRATE_NO"), 
APPRATE_PROMPT_TITLE: () => t("APPRATE_PROMPT_TITLE"), 
APPRATE_FEEDBACK: () => t("APPRATE_FEEDBACK"), 
BADGE_HEALTHCHECK_HEADER: () => t("BADGE_HEALTHCHECK_HEADER"), 
BADGE_HEALTHCHECK_INFO: () => t("BADGE_HEALTHCHECK_INFO"), 
BADGE_HEALTHCHECK_COMPLETE_INFO: () => t("BADGE_HEALTHCHECK_COMPLETE_INFO"), 
BADGE_HEALTHCHECK_REWARD: () => t("BADGE_HEALTHCHECK_REWARD"), 
BADGE_HEALTHCHECK_CTA: () => t("BADGE_HEALTHCHECK_CTA"), 
BADGE_RATING_HEADER: () => t("BADGE_RATING_HEADER"), 
BADGE_RATING_INFO: () => t("BADGE_RATING_INFO"), 
BADGE_RATING_COMPLETE_INFO: () => t("BADGE_RATING_COMPLETE_INFO"), 
BADGE_RATING_REWARD: () => t("BADGE_RATING_REWARD"), 
BADGE_RATING_CTA: () => t("BADGE_RATING_CTA"), 
BADGE_PROFILECOMPLETE_HEADER: () => t("BADGE_PROFILECOMPLETE_HEADER"), 
BADGE_PROFILECOMPLETE_INFO: () => t("BADGE_PROFILECOMPLETE_INFO"), 
BADGE_PROFILECOMPLETE__COMPLETE_INFO: () => t("BADGE_PROFILECOMPLETE__COMPLETE_INFO"), 
BADGE_PROFILECOMPLETE_REWARD: () => t("BADGE_PROFILECOMPLETE_REWARD"), 
BADGE_PROFILECOMPLETE_CTA: () => t("BADGE_PROFILECOMPLETE_CTA"), 
BADGE_SHARING_HEADER: () => t("BADGE_SHARING_HEADER"), 
BADGE_SHARING_INFO: () => t("BADGE_SHARING_INFO"), 
BADGE_SHARING_COMPLETE_INFO: () => t("BADGE_SHARING_COMPLETE_INFO"), 
BADGE_SHARING_REWARD: () => t("BADGE_SHARING_REWARD"), 
BADGE_SHARING_CTA: () => t("BADGE_SHARING_CTA"), 
BADGE_IMPORT_HEADER: () => t("BADGE_IMPORT_HEADER"), 
BADGE_IMPORT_INFO: () => t("BADGE_IMPORT_INFO"), 
BADGE_IMPORT_COMPLETE_INFO: () => t("BADGE_IMPORT_COMPLETE_INFO"), 
BADGE_IMPORT_REWARD: () => t("BADGE_IMPORT_REWARD"), 
BADGE_IMPORT_CTA: () => t("BADGE_IMPORT_CTA"), 
BADGE_SUPERUSER_HEADER: () => t("BADGE_SUPERUSER_HEADER"), 
BADGE_SUPERUSER_INFO: () => t("BADGE_SUPERUSER_INFO"), 
BADGE_SUPERUSER_COMPLETE_INFO: () => t("BADGE_SUPERUSER_COMPLETE_INFO"), 
BADGE_SUPERUSER_REWARD: () => t("BADGE_SUPERUSER_REWARD"), 
BADGE_SUPERUSER_CTA: () => t("BADGE_SUPERUSER_CTA"), 
BADGE_SERVICES_50_HEADER: () => t("BADGE_SERVICES_50_HEADER"), 
BADGE_SERVICES_50_INFO: () => t("BADGE_SERVICES_50_INFO"), 
BADGE_SERVICES_50_COMPLETE_INFO: () => t("BADGE_SERVICES_50_COMPLETE_INFO"), 
BADGE_SERVICES_50_REWARD: () => t("BADGE_SERVICES_50_REWARD"), 
BADGE_SERVICES_50_CTA: () => t("BADGE_SERVICES_50_CTA"), 
BADGE_SERVICES_150_HEADER: () => t("BADGE_SERVICES_150_HEADER"), 
BADGE_SERVICES_150_INFO: () => t("BADGE_SERVICES_150_INFO"), 
BADGE_SERVICES_150_COMPLETE_INFO: () => t("BADGE_SERVICES_150_COMPLETE_INFO"), 
BADGE_SERVICES_150_REWARD: () => t("BADGE_SERVICES_150_REWARD"), 
BADGE_SERVICES_150_CTA: () => t("BADGE_SERVICES_150_CTA"), 
BADGE_SERVICES_300_HEADER: () => t("BADGE_SERVICES_300_HEADER"), 
BADGE_SERVICES_300_INFO: () => t("BADGE_SERVICES_300_INFO"), 
BADGE_SERVICES_300_COMPLETE_INFO: () => t("BADGE_SERVICES_300_COMPLETE_INFO"), 
BADGE_SERVICES_300_REWARD: () => t("BADGE_SERVICES_300_REWARD"), 
BADGE_SERVICES_300_CTA: () => t("BADGE_SERVICES_300_CTA"), 
BADGE_SERVICES_500_HEADER: () => t("BADGE_SERVICES_500_HEADER"), 
BADGE_SERVICES_500_INFO: () => t("BADGE_SERVICES_500_INFO"), 
BADGE_SERVICES_500_COMPLETE_INFO: () => t("BADGE_SERVICES_500_COMPLETE_INFO"), 
BADGE_SERVICES_500_REWARD: () => t("BADGE_SERVICES_500_REWARD"), 
BADGE_SERVICES_500_CTA: () => t("BADGE_SERVICES_500_CTA"), 
BADGE_SHARING_1_HEADER: () => t("BADGE_SHARING_1_HEADER"), 
BADGE_SHARING_1_INFO: () => t("BADGE_SHARING_1_INFO"), 
BADGE_SHARING_1_COMPLETE_INFO: () => t("BADGE_SHARING_1_COMPLETE_INFO"), 
BADGE_SHARING_1_REWARD: () => t("BADGE_SHARING_1_REWARD"), 
BADGE_SHARING_1_CTA: () => t("BADGE_SHARING_1_CTA"), 
BADGE_SHARING_5_HEADER: () => t("BADGE_SHARING_5_HEADER"), 
BADGE_SHARING_5_INFO: () => t("BADGE_SHARING_5_INFO"), 
BADGE_SHARING_5_COMPLETE_INFO: () => t("BADGE_SHARING_5_COMPLETE_INFO"), 
BADGE_SHARING_5_REWARD: () => t("BADGE_SHARING_5_REWARD"), 
BADGE_SHARING_5_CTA: () => t("BADGE_SHARING_5_CTA"), 
BADGE_SHARING_10_HEADER: () => t("BADGE_SHARING_10_HEADER"), 
BADGE_SHARING_10_INFO: () => t("BADGE_SHARING_10_INFO"), 
BADGE_SHARING_10_COMPLETE_INFO: () => t("BADGE_SHARING_10_COMPLETE_INFO"), 
BADGE_SHARING_10_REWARD: () => t("BADGE_SHARING_10_REWARD"), 
BADGE_SHARING_10_CTA: () => t("BADGE_SHARING_10_CTA"), 
BADGE_SHARING_25_HEADER: () => t("BADGE_SHARING_25_HEADER"), 
BADGE_SHARING_25_INFO: () => t("BADGE_SHARING_25_INFO"), 
BADGE_SHARING_25_COMPLETE_INFO: () => t("BADGE_SHARING_25_COMPLETE_INFO"), 
BADGE_SHARING_25_REWARD: () => t("BADGE_SHARING_25_REWARD"), 
BADGE_SHARING_25_CTA: () => t("BADGE_SHARING_25_CTA"), 
BADGE_SHARING_50_HEADER: () => t("BADGE_SHARING_50_HEADER"), 
BADGE_SHARING_50_INFO: () => t("BADGE_SHARING_50_INFO"), 
BADGE_SHARING_50_COMPLETE_INFO: () => t("BADGE_SHARING_50_COMPLETE_INFO"), 
BADGE_SHARING_50_REWARD: () => t("BADGE_SHARING_50_REWARD"), 
BADGE_SHARING_50_CTA: () => t("BADGE_SHARING_50_CTA"), 
BADGE_GROUP_1_HEADER: () => t("BADGE_GROUP_1_HEADER"), 
BADGE_GROUP_1_INFO: () => t("BADGE_GROUP_1_INFO"), 
BADGE_GROUP_1_COMPLETE_INFO: () => t("BADGE_GROUP_1_COMPLETE_INFO"), 
BADGE_GROUP_1_REWARD: () => t("BADGE_GROUP_1_REWARD"), 
BADGE_GROUP_1_CTA: () => t("BADGE_GROUP_1_CTA"), 
BADGE_GROUP_5_HEADER: () => t("BADGE_GROUP_5_HEADER"), 
BADGE_GROUP_5_INFO: () => t("BADGE_GROUP_5_INFO"), 
BADGE_GROUP_5_COMPLETE_INFO: () => t("BADGE_GROUP_5_COMPLETE_INFO"), 
BADGE_GROUP_5_REWARD: () => t("BADGE_GROUP_5_REWARD"), 
BADGE_GROUP_5_CTA: () => t("BADGE_GROUP_5_CTA"), 
BADGE_LEGACY_HEADING: () => t("BADGE_LEGACY_HEADING"), 
BADGE_LEGACY_INFO_COMPLETE: () => t("BADGE_LEGACY_INFO_COMPLETE"), 
JOIN_GORUP_ALERT_MESSAGE: () => t("JOIN_GORUP_ALERT_MESSAGE"), 
GROUP_UNAVAILABLE: () => t("GROUP_UNAVAILABLE"), 
GROUP_PERSONAL_UNAVAILABLE: () => t("GROUP_PERSONAL_UNAVAILABLE"), 
GROUP_NONE_AVAILABLE: () => t("GROUP_NONE_AVAILABLE"), 
SHARE_INVITE_USERNAME_HEADING: () => t("SHARE_INVITE_USERNAME_HEADING"), 
SHARE_INVITE_USERNAME_INFO: () => t("SHARE_INVITE_USERNAME_INFO"), 
SHARE_INVITE_USERNAME_PLACEHOLDER: () => t("SHARE_INVITE_USERNAME_PLACEHOLDER"), 
LEGACY_USER_ONBOARDING_HEADING: () => t("LEGACY_USER_ONBOARDING_HEADING"), 
LEGACY_USER_ONBOARDING_INFO: () => t("LEGACY_USER_ONBOARDING_INFO"), 
CLAIM_PREMIUM: () => t("CLAIM_PREMIUM"), 
LEGACY_USER_RELOAD: () => t("LEGACY_USER_RELOAD"), 
SERVICE_OPEN_ICON_LABEL: () => t("SERVICE_OPEN_ICON_LABEL"), 
SERVICE_VIEW_ICON_LABEL: () => t("SERVICE_VIEW_ICON_LABEL"), 
SERVICE_HIDE_ICON_LABEL: () => t("SERVICE_HIDE_ICON_LABEL"), 
SERVICE_WEBSITE_ICON_LABEL: () => t("SERVICE_WEBSITE_ICON_LABEL"), 
SERVICE_COPY_ICON_LABEL: () => t("SERVICE_COPY_ICON_LABEL"), 
GROUPSLIST_SWITCH_LABEL: () => t("GROUPSLIST_SWITCH_LABEL"), 
GROUPSLIST_SHARE_LABEL: () => t("GROUPSLIST_SHARE_LABEL"), 
GROUPSLIST_SETTINGS_LABEL: () => t("GROUPSLIST_SETTINGS_LABEL"), 
FORGHETTI_GROUP_PERMISSIONS: () => t("FORGHETTI_GROUP_PERMISSIONS"), 
SUBSCRIPTION_TANDC: () => t("SUBSCRIPTION_TANDC"), 
SUBSCRIPTION_TANDC_LIFETIME: () => t("SUBSCRIPTION_TANDC_LIFETIME"), 
SUBSCRIPTION_ERROR: () => t("SUBSCRIPTION_ERROR"), 
SUBSCRIPTION_TANDC_HEADER: () => t("SUBSCRIPTION_TANDC_HEADER"), 
SERVICE_INJECT_LABEL: () => t("SERVICE_INJECT_LABEL"), 
PHONE_NUMBER_TOO_LONG: () => t("PHONE_NUMBER_TOO_LONG"), 
ADD_SERVICE_LOADING_PLEASE_WAIT: () => t("ADD_SERVICE_LOADING_PLEASE_WAIT"), 
ADD_SERVICE_LOADING_GENERATING_SERVICE: () => t("ADD_SERVICE_LOADING_GENERATING_SERVICE"), 
ADD_SERVICE_LOADING_GENERATING_PASSWORD: () => t("ADD_SERVICE_LOADING_GENERATING_PASSWORD"), 
ADD_SERVICE_LOADING_LOADING_SERVICE_DETAILS: () => t("ADD_SERVICE_LOADING_LOADING_SERVICE_DETAILS"), 
VIEW_SERVICE_LOADING_LOADING_SERVICE_DETAIL: () => t("VIEW_SERVICE_LOADING_LOADING_SERVICE_DETAIL"), 
VIEW_SERVICE_LOADING_LOADING_SERVICE_FORGHETTIBLE: () => t("VIEW_SERVICE_LOADING_LOADING_SERVICE_FORGHETTIBLE"), 
MIGRATION_ALERT_HEADING: () => t("MIGRATION_ALERT_HEADING"), 
MIGRATION_ALERT_MESSAGE: () => t("MIGRATION_ALERT_MESSAGE"), 
DELETE_ALERT_HEADING: () => t("DELETE_ALERT_HEADING"), 
DELETE_ALERT_MESSAGE: () => t("DELETE_ALERT_MESSAGE"), 
REFERRAL_CODE_UPDATE_NOT_ALLOWED: () => t("REFERRAL_CODE_UPDATE_NOT_ALLOWED"), 
REFERRAL_CODE_APPLIED: () => t("REFERRAL_CODE_APPLIED"), 
INVALID_PHONE_NUMBER: () => t("INVALID_PHONE_NUMBER"), 
INVALID_ACTIVATION_CODE: () => t("INVALID_ACTIVATION_CODE"), 
INVALID_TOO_MANY_ATTEMPTS: () => t("INVALID_TOO_MANY_ATTEMPTS"), 
REONBOARDING_POPUP_GO_AGAIN: () => t("REONBOARDING_POPUP_GO_AGAIN"), 
REONBOARDING_POPUP_BUTTON_GO: () => t("REONBOARDING_POPUP_BUTTON_GO"), 
REONBOARDING_POPUP_BUTTON_CANCEL: () => t("REONBOARDING_POPUP_BUTTON_CANCEL"), 
REONBOARDING_POPUP_HEADING: () => t("REONBOARDING_POPUP_HEADING"), 
HELP_QUICKSTART: () => t("HELP_QUICKSTART"), 
YOUR_BADGES: () => t("YOUR_BADGES"), 
GROUP_NOT_OWNER: () => t("GROUP_NOT_OWNER"), 
GROUP_NOT_OWNER_TITLE: () => t("GROUP_NOT_OWNER_TITLE"), 
VIEWSERVICE_NOT_COPIED_TO_CLIPBOARD: () => t("VIEWSERVICE_NOT_COPIED_TO_CLIPBOARD"), 
EXTENSION_ATTEMPT: () => t("EXTENSION_ATTEMPT"), 
EXTENSION_ATTEMPT_FAIL: () => t("EXTENSION_ATTEMPT_FAIL"), 
UNIVERSAL_LINK_NEW_LOGIN: () => t("UNIVERSAL_LINK_NEW_LOGIN"), 
UNIVERSAL_LINK_NEW_LOGIN_NO_URL: () => t("UNIVERSAL_LINK_NEW_LOGIN_NO_URL"), 
CONFIRM_COUNTRY_UPDATE_HEADER: () => t("CONFIRM_COUNTRY_UPDATE_HEADER"), 
CONFIRM_COUNTRY_UPDATE_NOTE: () => t("CONFIRM_COUNTRY_UPDATE_NOTE"), 
CONFIRM_BUTTON: () => t("CONFIRM_BUTTON"), 
FORGHETTI_LOGIN: () => t("FORGHETTI_LOGIN"), 
FORGHETTI_LOGIN_CREATE: () => t("FORGHETTI_LOGIN_CREATE"), 
FORGHETTI_LOGIN_ALREADY_HAVE: () => t("FORGHETTI_LOGIN_ALREADY_HAVE"), 
FORGHETTI_SELECT_LANGUAGE_INFO: () => t("FORGHETTI_SELECT_LANGUAGE_INFO"), 
MUST_BE_LOGGED_IN_ACTION: () => t("MUST_BE_LOGGED_IN_ACTION"), 
UPDATING_FORGHETTIBLE: () => t("UPDATING_FORGHETTIBLE"), 
FORGHETTI_ON_MAC: () => t("FORGHETTI_ON_MAC"), 
FORGHETTI_ON_WINDOWS: () => t("FORGHETTI_ON_WINDOWS"), 
REALTIME_NEW_SERVICE_FOR_GROUP: () => t("REALTIME_NEW_SERVICE_FOR_GROUP"), 
REALTIME_REMOVE_SERVICE_FOR_GROUP: () => t("REALTIME_REMOVE_SERVICE_FOR_GROUP"), 
REALTIME_KICK_SERVICE_FOR_GROUP: () => t("REALTIME_KICK_SERVICE_FOR_GROUP"), 
REALTIME_BAN_SERVICE_FOR_GROUP: () => t("REALTIME_BAN_SERVICE_FOR_GROUP"), 
REALTIME_JOIN_SERVICE_FOR_GROUP: () => t("REALTIME_JOIN_SERVICE_FOR_GROUP"), 
REALTIME_REMOVE_GROUP: () => t("REALTIME_REMOVE_GROUP"), 
PERSONAL_GROUP: () => t("PERSONAL_GROUP"), 
SHAREABLE_GROUP: () => t("SHAREABLE_GROUP"), 
IMPORT_MENU: () => t("IMPORT_MENU"), 
IMPORT_GROUP: () => t("IMPORT_GROUP"), 
MONTHS: () => t("MONTHS"), 
YOUR_SUBSCRIPTION_PARTNER_PREMIUM_TEXT_1: () => t("YOUR_SUBSCRIPTION_PARTNER_PREMIUM_TEXT_1"), 
YOUR_SUBSCRIPTION_PARTNER_PREMIUM_TEXT_2: () => t("YOUR_SUBSCRIPTION_PARTNER_PREMIUM_TEXT_2"), 
PARTNER_PREMIUM_HEADING: () => t("PARTNER_PREMIUM_HEADING"), 
PARTNER_PREMIUM_MESSAGE: () => t("PARTNER_PREMIUM_MESSAGE"), 
PARNTER_PREMIUM_BUTTON: () => t("PARNTER_PREMIUM_BUTTON"), 
CSL_SUBSCRIPTION_HEADER: () => t("CSL_SUBSCRIPTION_HEADER"), 
CSL_SUBSCRIPTION_1: () => t("CSL_SUBSCRIPTION_1"), 
CSL_SUBSCRIPTION_2: () => t("CSL_SUBSCRIPTION_2"), 
LICENCE_KEY_INVALID: () => t("LICENCE_KEY_INVALID"), 
LICENCE_KEY_USED: () => t("LICENCE_KEY_USED"), 
LICENCE_KEY_ALREADY_APPLIED: () => t("LICENCE_KEY_ALREADY_APPLIED"), 
SERVICE_EMAIL_LABEL: () => t("SERVICE_EMAIL_LABEL"), 
SERVICE_EMAIL_PLACEHOLDER: () => t("SERVICE_EMAIL_PLACEHOLDER"), 
SERVICE_EDIT_EMAIL_PLACEHOLDER: () => t("SERVICE_EDIT_EMAIL_PLACEHOLDER"), 
PROFILE_USE_BIOMETRICS: () => t("PROFILE_USE_BIOMETRICS"), 
BIOMETRICS_GROUPS_HEADER: () => t("BIOMETRICS_GROUPS_HEADER"), 
BIOMETRICS_SELECT_GROUP: () => t("BIOMETRICS_SELECT_GROUP"), 
BIOMETRICS: () => t("BIOMETRICS"), 
BIOMETRICS_ENABLE: () => t("BIOMETRICS_ENABLE"), 
BIOMETRICS_DISABLE: () => t("BIOMETRICS_DISABLE"), 
BIOMETRICS_SECURE: () => t("BIOMETRICS_SECURE"), 
BIOMETRICS_SECURE_MESSAGE: () => t("BIOMETRICS_SECURE_MESSAGE"), 
BIOMETRICS_USE_DOODLE: () => t("BIOMETRICS_USE_DOODLE"), 
BIOMETRICS_TRY_AGAIN: () => t("BIOMETRICS_TRY_AGAIN"), 
NOTES: () => t("NOTES"), 
SELECT_SPECIAL_CHARACTERS: () => t("SELECT_SPECIAL_CHARACTERS"), 
WORDS: () => t("WORDS"), 
LETTERS: () => t("LETTERS"), 
NUMBERS: () => t("NUMBERS"), 
SYMBOLS: () => t("SYMBOLS"), 
SELECT_PASSWORD_TYPE: () => t("SELECT_PASSWORD_TYPE"), 
SELECT_PASSWORD_NUMBERS: () => t("SELECT_PASSWORD_NUMBERS"), 
SELECT_PASSWORD_WORDS: () => t("SELECT_PASSWORD_WORDS"), 
ALL: () => t("ALL"), 
SIMPLE: () => t("SIMPLE"), 
EDIT_PASSWORD_HEADING: () => t("EDIT_PASSWORD_HEADING"), 
SERVICE_FIELD_PASSWORD_NO_OPTIONS: () => t("SERVICE_FIELD_PASSWORD_NO_OPTIONS"), 
SERVICE_FIELD_PASSWORD_NO_SYMBOLS_SELECTED: () => t("SERVICE_FIELD_PASSWORD_NO_SYMBOLS_SELECTED"), 
SUBSCRIPTION_ENDS: () => t("SUBSCRIPTION_ENDS"), 
SUBSCRIPTION_ENDS_TOMORROW: () => t("SUBSCRIPTION_ENDS_TOMORROW"), 
SUBSCRIPTION_ENDS_TODAY: () => t("SUBSCRIPTION_ENDS_TODAY"), 
NOTIFICATIONS: () => t("NOTIFICATIONS"), 
CONTEXT_CHANGE_TYPE: () => t("CONTEXT_CHANGE_TYPE"), 
CONTEXT_CHANGE_LENGTH: () => t("CONTEXT_CHANGE_LENGTH"), 
CONTEXT_RENAME: () => t("CONTEXT_RENAME"), 
NEW_FORGHETTIBLE: () => t("NEW_FORGHETTIBLE"), 
OLD_FORGHETTIBLE: () => t("OLD_FORGHETTIBLE"), 
DELETE_FIELD_PROMPT: () => t("DELETE_FIELD_PROMPT"), 
ONBOARDING2_WELCOME_HEADING: () => t("ONBOARDING2_WELCOME_HEADING"), 
ONBOARDING2_WELCOME_TEXT: () => t("ONBOARDING2_WELCOME_TEXT"), 
ONBOARDING2_DOODLE_HEADING: () => t("ONBOARDING2_DOODLE_HEADING"), 
ONBOARDING2_DOODLE_TEXT: () => t("ONBOARDING2_DOODLE_TEXT"), 
ONBOARDIN2_HOWITWORKS: () => t("ONBOARDIN2_HOWITWORKS"), 
ONBOARDING_CONFIRM_DOODLE: () => t("ONBOARDING_CONFIRM_DOODLE"), 
ONBOARDING_BIOMETRICS_CONFIRM: () => t("ONBOARDING_BIOMETRICS_CONFIRM"), 
AND: () => t("AND"), 
NEW_GROUP: () => t("NEW_GROUP"), 
LETS_START_SELECT: () => t("LETS_START_SELECT"), 
CREATE_SERVICES: () => t("CREATE_SERVICES"), 
CREATING_SERVICES: () => t("CREATING_SERVICES"), 
MUST_SELECT_MORE_THAN_ONE_SERVICES: () => t("MUST_SELECT_MORE_THAN_ONE_SERVICES"), 
ADD_NOTE: () => t("ADD_NOTE"), 
EDIT_NOTE: () => t("EDIT_NOTE"), 
NOTE: () => t("NOTE"), 
NOTE_COPIED: () => t("NOTE_COPIED"), 
DELETE_NOTE: () => t("DELETE_NOTE"), 
FORGHETTI_LOGIN_TAGLINE: () => t("FORGHETTI_LOGIN_TAGLINE"), 
FORGHETTI_LOGIN_TAGLINE_2: () => t("FORGHETTI_LOGIN_TAGLINE_2"), 
CREATE_ACCOUNT_1_MSG_4: () => t("CREATE_ACCOUNT_1_MSG_4"), 
CREATE_ACCOUNT_1_HEADER_2: () => t("CREATE_ACCOUNT_1_HEADER_2"), 
CREATE_ACCOUNT_2_HEADING: () => t("CREATE_ACCOUNT_2_HEADING"), 
CREATE_ACCOUNT_FINAL_BUTTON: () => t("CREATE_ACCOUNT_FINAL_BUTTON"), 
ONBOARDING_DOODLETUTORIAL2_HEADING_2: () => t("ONBOARDING_DOODLETUTORIAL2_HEADING_2"), 
ONBOARDING_DOODLETUTORIAL3_HEADING_2: () => t("ONBOARDING_DOODLETUTORIAL3_HEADING_2"), 
ONBOARDING_DOODLETUTORIAL4_HEADING_2: () => t("ONBOARDING_DOODLETUTORIAL4_HEADING_2"), 
ONBOARDING_DOODLETUTORIAL5_HEADING_2: () => t("ONBOARDING_DOODLETUTORIAL5_HEADING_2"), 
ONBOARDING_DOODLETUTORIAL6_HEADING_2: () => t("ONBOARDING_DOODLETUTORIAL6_HEADING_2"), 
ONBOARDING_DOODLETUTORIAL7_HEADING_2: () => t("ONBOARDING_DOODLETUTORIAL7_HEADING_2"), 
ONBOARDING_DOODLETUTORIAL8_HEADING_2: () => t("ONBOARDING_DOODLETUTORIAL8_HEADING_2"), 
SECURE_DOODLE_TUTORIAL: () => t("SECURE_DOODLE_TUTORIAL"), 
DOODLE_TRY_AGAIN: () => t("DOODLE_TRY_AGAIN"), 
WELCOME_TO_FORGHETTI: () => t("WELCOME_TO_FORGHETTI"), 
ONBOARDING_COMPLETE_CONGRATULATIONS: () => t("ONBOARDING_COMPLETE_CONGRATULATIONS"), 
LETS_START_ADDING_DETAILS: () => t("LETS_START_ADDING_DETAILS"), 
USERNAME_OR_EMAIL: () => t("USERNAME_OR_EMAIL"), 
USERNAME_OR_EMAIL_SECOND: () => t("USERNAME_OR_EMAIL_SECOND"), 
SERVICE_EDIT_USERNAME_PLACEHOLDER: () => t("SERVICE_EDIT_USERNAME_PLACEHOLDER"), 
SERVICE_USERNAME_LABEL: () => t("SERVICE_USERNAME_LABEL"), 
SERVICE_USERNAME_SECOND_LABEL: () => t("SERVICE_USERNAME_SECOND_LABEL"), 
ADD_SERVICE_USERNAME_PLACEHOLDER: () => t("ADD_SERVICE_USERNAME_PLACEHOLDER"), 
SERVICE_USERNAME_PLACEHOLDER_SECOND: () => t("SERVICE_USERNAME_PLACEHOLDER_SECOND"), 
FREE_USER_UPGRADE: () => t("FREE_USER_UPGRADE"), 
LETS_START_SELECT_COUNT: () => t("LETS_START_SELECT_COUNT"), 
TOAST_TOO_MANY_SERVICES_SELECTED: () => t("TOAST_TOO_MANY_SERVICES_SELECTED"), 
LOGIN_ACCOUNT_1_HEADER_2: () => t("LOGIN_ACCOUNT_1_HEADER_2"), 
LOGIN_ACCOUNT_1_MSG: () => t("LOGIN_ACCOUNT_1_MSG"), 
KEYCHAIN_HEADING: () => t("KEYCHAIN_HEADING"), 
KEYCHAIN_MESSAGE: () => t("KEYCHAIN_MESSAGE"), 
KEYCHAIN_MESSAGE_2: () => t("KEYCHAIN_MESSAGE_2"), 
KEYCHAIN_MESSAGE_3: () => t("KEYCHAIN_MESSAGE_3"), 
KEYCHAIN_YES: () => t("KEYCHAIN_YES"), 
KEYCHAIN_NO: () => t("KEYCHAIN_NO"), 
SETUP_NEW_SERVICE_MESSAGE: () => t("SETUP_NEW_SERVICE_MESSAGE"), 
LOADING_VAULT: () => t("LOADING_VAULT"), 
ONBOARDING_COMPLETE_FIRST_LOGIN: () => t("ONBOARDING_COMPLETE_FIRST_LOGIN"), 
STILL_LOADING: () => t("STILL_LOADING"), 
STILL_LOADING_2: () => t("STILL_LOADING_2"), 
CREATE_ACCOUNT_VERIFYING_PHONE: () => t("CREATE_ACCOUNT_VERIFYING_PHONE"), 
NOTIFICATION_MESSAGE_BAR: () => t("NOTIFICATION_MESSAGE_BAR"), 
NO_NOTIFICATIONS_HELLO_TEXT: () => t("NO_NOTIFICATIONS_HELLO_TEXT"), 
NO_NOTIFICATIONS_CHECK: () => t("NO_NOTIFICATIONS_CHECK"), 
REPLAY_DOODLE_HEADING: () => t("REPLAY_DOODLE_HEADING"), 
FORGHETTIBLE: () => t("FORGHETTIBLE"), 
SERVICE_NO_NOTE: () => t("SERVICE_NO_NOTE"), 
SERVICE_NOTE: () => t("SERVICE_NOTE"), 
SERVICE_BAD_NOTE: () => t("SERVICE_BAD_NOTE"), 
SERVICE_NAME: () => t("SERVICE_NAME"), 
REPLAY_DOODLE: () => t("REPLAY_DOODLE"), 
ENABLE_BIOMETRICS: () => t("ENABLE_BIOMETRICS"), 
DISABLE_BIOMETRICS: () => t("DISABLE_BIOMETRICS"), 
WEB_DELAY: () => t("WEB_DELAY"), 
WEB_WAITING: () => t("WEB_WAITING"), 
RATE_US: () => t("RATE_US"), 
PROFILE_MIGRATE_2_INFO_2: () => t("PROFILE_MIGRATE_2_INFO_2"), 
MIGRATE: () => t("MIGRATE"), 
MONTHLY: () => t("MONTHLY"), 
YEARLY: () => t("YEARLY"), 
UNLOCK_BIOMETRICS: () => t("UNLOCK_BIOMETRICS"), 
UNLOCK_DESCRIPTION: () => t("UNLOCK_DESCRIPTION"), 
UNLOCK_SERVICE: () => t("UNLOCK_SERVICE"), 
UNLOCK_SERVICE_DESCRIPTION: () => t("UNLOCK_SERVICE_DESCRIPTION"), 
UNLOCK_FALLBACK: () => t("UNLOCK_FALLBACK"), 
UNLOCK_CANCEL: () => t("UNLOCK_CANCEL"), 
FORGHETTI_TAGLINE: () => t("FORGHETTI_TAGLINE"), 
SAVING_NOTE: () => t("SAVING_NOTE"), 
}