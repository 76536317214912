const DialogMgr = require('dialog-manager')

/**
 * Displays a message to the user for a short duration.
 * @param {string} msg Message to be displayed to user.
 * @param {boolean} html If true, message will be treated as HTML
 */
module.exports = (msg, options = { html: false, timeout: 1500, center: false }) => {
  // $('body').on('click', () => {
  //   $('.dialogMgr_toastRoot').removeClass('dialogMgr_fadeIn').addClass('dialogMgr_fadeOut');

  //   $('.dialogMgr_toastRoot').removeClass('dialogMgr_fadeOut');
  // })
  DialogMgr.showToast({ msgText: !options.html ? msg : 'Test ', duration: options.timeout || 1500 })
  const toast = $('.dialogMgr_toastBox');

  if (options.html) {
    toast.html($('<div style="overflow: hidden; white-space: pre-wrap"></div>').append(msg));
  }
  options.center ? toast.parent().addClass('verticalAlign') :
    toast.parent().removeClass('verticalAlign')


}