//import { lastEventId } from '@sentry/browser/dist/sdk';

/* eslint-disable */
const md5 = require('md5');
const $ = require('jquery');

const autoBind = require('auto-bind');

const animations = require('../animations');
//const Sentry = require('@sentry/browser');

const fb = require('./firebaseUtils');

const config = require('../../config');
const { strings } = config;
const { LOGROCKET_ID, MOBILE_VIEW_BREAKPOINT, COMPACT_VIEW_BREAKPOINT, THEME_LIGHT_RGB, THEME_DARK_RGB } = config.app;

const getDefaultGenerationSettings = require('../getPageData_helpers/getDefaultGenerationSettings');
const openUrl = require('../action_helpers/openUrl');
const z2hTools = require('./tools');
const SuggestedPresetsComponent = require('./suggestedPresets');

const showToastMessage = require('../action_helpers/showToastMessage');
const { ACCOUNT_SECURITY_SECONDARY_HEADING } = require('../../config/strings.base');
const { set } = require('lodash');
const apiConfig = { url: config.app.FORGHETTI_API_URL, version: 1 };

function z2hApp() {
  // Variables
  this.active = { serviceId: '', groupId: '', pageId: '' };
  this.requestingPage = '';
  this.apiConfig = apiConfig;
  this.temporary = {};
  //this.apiConfig = { url: 'https://api.forghetti.app', version: 1 };
  //this.apiConfig = { url: config.app.FORGHETTI_API_URL, version: 1 };
  this.pageData = { service: { fieldsArray: [], fieldsSaved: {}, fieldsUnsaved: {} } };
  this.MOBILE_VIEW_BREAKPOINT = MOBILE_VIEW_BREAKPOINT;
  this.COMPACT_VIEW_BREAKPOINT = COMPACT_VIEW_BREAKPOINT;

  // Global state
  // ======================================
  // Create a global state object.
  window.state = window.state || {};

  // Ensure that 'this' refers to z2hApp within all methods
  autoBind.default(this);
}

z2hApp.prototype.onClickMainNavItem = function (event) {
  const navItem = $(event.currentTarget);

  const desktopView = $('body').width() > MOBILE_VIEW_BREAKPOINT;

  //const landingPageButton = navItem.hasClass('landing-page-button');

  // When user clicks landing page button, display last viewed group in
  // left-hand pane
  //if (desktopView && landingPageButton) {
  //  const groupId = window.state.selectedGroupId;
  //  if (groupId) {
  //    $('li[data-id=' + groupId + ']')
  //      .eq(0)
  //      .click();
  //  }
  //}
  const landingPage = $('#pane-2').find('#notifications');

  let landingPageActive = landingPage.hasClass('active');

  // If user clicked on any link that wasn't the landing page itself, load
  // the landing page in the second pane
  if (desktopView && !landingPageActive) {
    this.temporary.pageNavigationDisabled = false;
    // Animated = false
    this.paneNavigation('notifications', $('#pane-2'), 0, null, null, null, null, null, false);
    this.temporary.pageNavigationDisabled = false;

    landingPageActive = true;

    $('.nav-bar').show();
  }
  //We never want the landing page being
  landingPage.removeClass('active');
  if (landingPageActive) {
    landingPage.removeClass('active');
    this.setSectionPaneActive(1);



   
  }

  //if (!desktopView && landingPageButton) {
  //  // Show landing page button as active when clicked on. Not sure why
  //  // it needs to be treated differently from the others? TODO
  //  navItem
  //    .addClass('active')
  //    .siblings()
  //    .removeClass('active');
  // }
};

// M A I N   F U N C T I O N S

z2hApp.prototype.setSectionPaneActive = function (paneIndex) {
  if (!paneIndex || isNaN(paneIndex)) return;
  $('body').removeClass('pane-1-active pane-2-active pane-3-active');

  $('body').addClass('pane-' + paneIndex + '-active');
  $('#pane-' + paneIndex).addClass('active');
  if (paneIndex !== 3) {
    $('#pane-' + paneIndex)
      .siblings()
      .removeClass('active');
  }
};

z2hApp.prototype.searchInputChange = function (event) {
  const searchTerm = $(event.currentTarget).val();

  const loginList = $('.login-list');
  if (searchTerm === '') {
    //loginList.attr('searchType', 'standard');
    return this.showServices(window.state.currentServices, window.state.selectedGroupId, true);
  }
  loginList.attr('searchType', 'acrossGroups');
  $('#login-list').find('.search__wrapper').find('button').fadeIn();

  if (searchTerm) {
    //Hide all alpha list headings to begin with
    loginList.find('.alpha-list-heading').hide();
    loginList.find('.login-item').hide();
    loginList.find('#recently-used-services').hide();
  } else {
    //Reset
    loginList.find('.alpha-list-heading').show();
    loginList.find('.login-item').show();
    loginList.find('#recently-used-services').show();
    return;
  }

  const simplifiedSearchTerm = searchTerm.trim().toLowerCase().replace(' ', '');

  // Function for determining match between search term and item name
  const match = (loginItemName, preset_id) => {
    const simplifiedItemName = loginItemName.toLowerCase().replace(' ', '');
    let preset = false;
    if (preset_id === simplifiedSearchTerm) {
      preset = true;
    }
    return preset || simplifiedItemName.indexOf(simplifiedSearchTerm) >= 0;
  };

  loginList.find('.no-items-row').empty();

  // Go through each login list item
  loginList.find('.login-item').each((i, elem) => {
    const $elem = $(elem);
    // If login list item name contains the search term, show it
    if (match($elem.find('.login-name').text())) {
      $elem.show();
    }
  });

  // ------------------------------------------------------------
  // Helpers
  const selectorTypeContent = (selector, type, content) => ({ selector, type, content });
  const loginItemFromTemplate = (template, data, groupMemberType, selected) => ({
    template: template,
    selector: 'li',
    attributes: [
      selectorTypeContent('', 'data-actionclick', 'newLoginOrDoodle'),
      selectorTypeContent('', 'data-id', data.id),
      //selectorTypeContent('', 'data-template', 'viewService1_doodlepad'),
      //selectorTypeContent('', 'data-nav', '1'),
      //selectorTypeContent('', 'data-nav-pane', '2'),
      selectorTypeContent('.login-name', 'innerText', data.name),
      selectorTypeContent('.login-username', 'innerText', data.username || data.username_secondary),
      selectorTypeContent('.login-avatar', 'src', data.icon || config.app.DEFAULT_SERVICE_ICON),
      selectorTypeContent('.login-avatar', 'data-id', data.id),
      selectorTypeContent('.login-avatar', 'data-letter', '*'),
      selectorTypeContent(
        '.icn-button',
        'data-context',
        groupMemberType === 2 ? 'loginListItemOwner' : groupMemberType === 1 ? 'loginListItemAdmin' : '',
      ),
      selectorTypeContent('.f-icn', 'class', groupMemberType >= 1 ? 'f-icn-dots' : ''),

      selectorTypeContent('.login-avatar-wrapper', 'data-selected', selected),
    ],
  });
  // ------------------------------------------------------------

  // Now search across all other groups.
  // Get list of all groups

  let groupsList = window.state.groupsList;
  groupsList.forEach((g) => {
    if (g.id === window.state.selectedGroupId) {
      return;
    }

    //Don't search in the import services group.
    //If this is the import services group don't search other groups
    if (g.importServices) return;

    const groupId = g.id;
    const services = g.services || [];
    const groupMemberType = g.personal ? 2 : (g.users[window.state.userData.id] || {}).member_type;

    const filteredList = (services || []).filter((s) => match(s.name, s?.preset_id));
    if (filteredList.length === 0) return;

    let groupServicesList = $('.alpha-list[data-id=' + groupId + ']');
    if (groupServicesList.length === 0) {
      const alphaHeaderHtml = `
        <li class="alpha-list" data-id="${g.id}">
          <h2 class="alpha-list-heading">${g.name}</h2>
          <ul class="alpha-list-content"></ul>
        </li>
      `;
      groupServicesList = $(alphaHeaderHtml);
      $('.login-list-wrapper .login-list').eq(0).append(groupServicesList);
    }
    groupServicesList.find('.alpha-list-content').empty();
    groupServicesList.find('.alpha-list-heading').show();

    filteredList.forEach((s) => {
      // Construct a login item block
      // Create the template for a login item and append it to the last created alpha list
      var loginBlock = loginItemFromTemplate('login-item', s, groupMemberType, s.last_used === 0);
      var loginItem = this.constructBlock(loginBlock);
      groupServicesList.find('.alpha-list-content').append($(loginItem));
    });
  });

  window.state.groupsList.forEach((group) => {
    //10 minutes
    if (!group.retrieved) group.retrieved = 0;
    if (group.retrieved + 600000 < new Date().getTime()) {
      this.fetchAndShowServices(group.id, false, true);
      group.retrieved = new Date().getTime();
    }
  });
};

/**
 * Handler for any on-screen component that accepts a 'click' event from the
 * user. e.g. data-nav components.
 */
let onComponentClickDisabled = false;
z2hApp.prototype.onComponentClick = function (event) {
  // Prevent this click from being handled by any parent components
  event.stopPropagation();

  const errorManager = require('../utils/errorManager');
  const validations = require('../validations');

  const element = event.currentTarget;
  const elem = $(event.currentTarget);
  const sectionPane = elem.closest('.section-pane');

  let prom = Promise.resolve();

  // If user clicks a component with validation to perform, invoke the
  // validation first. This may prevent any [data-nav] navigation or
  // [data-actionclick] action.
  if (elem.attr('data-validation')) {
    const validationName = elem.attr('data-validation');
    const validator = validations[validationName || ''];
    if (validator) {
      prom = prom
        .then((_) => validator(sectionPane))
        .catch((e) => {
          let err = new Error();
          err.name = 'FORGHETTI_VAL_ERROR';
          throw err;
        });
    }
  }

  // If user clicks a component with an actionclick, invoke the action
  if (elem.attr('data-actionclick')) {
    // NOTE: The result of the action is not returned, therefore we do
    // not wait for the result of the action before we invoke any
    // navigation for this component.
    prom = prom.then((_) => {
      this.actionClickChange(element);
    });
  }

  // If component has data-nav attribute, invoke navigation.
  // Do this after any action, if there was an action to invoke which returned
  // a promise.
  if (elem.attr('data-nav')) {
    prom = prom.then(() => {
      errorManager.clearErrors(sectionPane);
      this.paneNavigationFromComponentClick(elem);
    });
  }

  // Catch any validation errors as these would have been handled already
  prom.catch((err) => {
    if (err.name !== 'FORGHETTI_VAL_ERROR') {
      this.onError('Error handling component click', err);
      throw err;
    }
  });
};

z2hApp.prototype.onError = function (message, error = {}, extraData) {
  let fullLogMessage = 'Error occurred';
  if (typeof error === 'object') {
    const errMsg = error.message || '';
    fullLogMessage = message + (errMsg ? ': ' + errMsg : '');
    // Sentry.captureException({ ...error, message: fullLogMessage });
  } else {
    fullLogMessage = message + (error ? ': ' + error : '');
    // Sentry.captureException({ message: fullLogMessage }, extraData);
  }
  console.error(fullLogMessage);
};

z2hApp.prototype.paneNavigationFromComponentClick = function (element) {
  try {
    // Set current page variables
    var $this = $(element);

    const $templateId = $this.attr('data-template');

    if (this.temporary.pageNavigationDisabled == true && $templateId.indexOf('_loading') < 0) {
      // Early return if navigation movements are disabled

      return;
    }

    const $dataId = $this.attr('data-id');
    // If clicked element had data-animate=false on it then we disable the
    // pane animation.
    let $animate = !($this.attr('data-animation') === 'false');

    // Determine which main pane we are going to display the content in
    // (e.g. "pane-2"). The clicked link might tell us.
    var $pane = $this.attr('data-nav-pane');
    if (!$pane) {
      // Find the root section for the item that was clicked
      $pane = $this.closest('.section-pane-wrapper').parent();
    }

    const $dataNav = parseInt($this.attr('data-nav'));
    this.paneNavigation($templateId, $pane, $dataNav, $this, $dataId, $animate);
  } catch (err) {
    this.temporary = {};
    this.onError('Pane navigation clicks have been re-enabled following a navigation error', err);
  }
};

// p a g e
// navigate between pages

z2hApp.prototype.paneNavigationFromObject = function (obj) {
  const { templateId, pane, dataNav, clickedElem, dataId, animated, callback, changeActiveSection, data } = obj;
  this.paneNavigation(templateId, pane, dataNav, clickedElem, dataId, animated, callback, changeActiveSection, data);
};

z2hApp.prototype.paneNavigation = function (
  $templateId,
  $pane,
  $dataNav = 0,
  $clickedElem,
  $dataId,
  animated = true,
  callback,
  changeActiveSection = true,
  $data,
) {
  try {
    // If we've been given the index for a pane (e.g. "2") then get the pane
    // based on it's ID
    let paneIndex;
    if (typeof $pane === 'string' || typeof $pane === 'number') {
      paneIndex = parseInt($pane);
      $pane = $('#pane-' + $pane);
    } else {
      paneIndex = parseInt(($pane.attr('id') || '').substr(-1));
    }

    if ($pane.length === 0) {
      console.error('Cannot display page "' + $templateId + '". Cannot find container "' + $pane + '".');
      return;
    }

    console.info(`Navigating to ${$templateId}...`);

    let animate = animated;
    let setSectionPane = 0;
    if (changeActiveSection && paneIndex && !isNaN(paneIndex)) {
      // Before we change the active section pane, check if we are switching
      // from pane 1 to pane 2. If we are, don't animate the incoming pane.
      if ($('body').width() <= MOBILE_VIEW_BREAKPOINT && $pane.is($('#pane-2')) && $('#pane-1').hasClass('active')) {
        animate = false;
      }
      setSectionPane = paneIndex;
    }

    if ($templateId == 'null' || !$templateId) {
      throw 'data-template attribute is null';
    }

    // If user clicks back to go to the first pane in section pane 2, then just
    // show section pane 1
    if (
      $('body').width() <= MOBILE_VIEW_BREAKPOINT &&
      $templateId === 'back' &&
      parseInt($dataNav) === 0 &&
      !isNaN(paneIndex)
    ) {
      setSectionPane = 1;
    }

    // If the button is going backwards, confirm with user that they want to
    // go back if there are unsaved changes in temporary
    var goingBackWhileUnsaved = false;
    var confirmNeeded = false;
    var confirmGoingBackResponse = false;
    if ($templateId == 'back') {
      if (!$clickedElem || !$clickedElem.attr('data-prevent')) {
        for (let action in this.temporary) {
          if (this.temporary[action].unsaved) {
            goingBackWhileUnsaved = true;
            break;
          }
        }
      }

      if (goingBackWhileUnsaved) {
        confirmNeeded = true;
        confirmGoingBackResponse = confirm(strings.MSG_UNSAVED_CONFIRM());
      }
    }

    if (!confirmNeeded || confirmGoingBackResponse) {
      if (setSectionPane > 0) {
        this.setSectionPaneActive(paneIndex);
        $pane = $(`#pane-${setSectionPane}`);
      }
      this.continuePaneNavigation($templateId, $pane, $dataNav, $clickedElem, $dataId, animate, callback, $data);
    }
  } catch (err) {
    //Re-enable navigation & clear temporary data
    this.temporary = {};
    this.onError('Pane navigation clicks have been re-enabled following a navigation error', err);
  }
};

z2hApp.prototype.continuePaneNavigation = function (
  $templateId,
  $rootSection,
  $dataNav,
  $clickedElem,
  $dataId,
  animated = true,
  callback,
  $data,
) {
  const z2hTemplates = require('./templates');

  if ($templateId.indexOf('_loading') < 0) {
    this.requestingPage = $templateId;
  }
  // Clear any errors currently displayed
  const errorManager = require('../utils/errorManager');
  errorManager.clearAllErrors();

  // Disabled navigation & clear temporary data
  // console.info('Page navigation disabled');
  this.temporary.pageNavigationDisabled = true;

  // Get section that page is going to be put into
  const section = $rootSection || $('#pane-2');
  const sectionPaneWrapper = section.find('.section-pane-wrapper');

  const paneId = section.attr('id');
  let paneNumber = 2;
  if (paneId === 'pane-1') {
    paneNumber = 1;
  } else if (paneId === 'pane-3') {
    paneNumber = 3;
  } else if (paneId === 'overlay') {
    paneNumber = 0;
  }

  this.setSectionPaneActive(paneNumber);

  const lochyLoadingText = require('../page_helpers/paneBusyText');
  const text = lochyLoadingText[$templateId] ? lochyLoadingText[$templateId]() : '';

  const overlays = require('./overlays');

  overlays.makePaneLookBusy(paneNumber, { text });
  if (paneNumber === 1) {
    const nav = $('.nav-bar li');
    nav.removeClass('active');
  }

  // -----------------------------------------------------------------------------------------------
  // Get the 'data' for the new page
  // This involves calling any getData function we have that matches the page name.

  const getData = $data ? Promise.resolve($data) : z2hTemplates.getPageData($templateId, $clickedElem, $dataId);

  // Async: Get next page data - partial transition animation start here / loading animation
  getData
    .then((templateIdData) => {
      //make sure the page is still active, otherwise we don't want to do anything
      if (this.requestingPage !== $templateId && $templateId.indexOf('_loading') < 0) {
        return;
      }

      this.temporary.pageNavigationDisabled = false;

      const $currentPane = sectionPaneWrapper.children('.active');
      const currentPaneIndex = $currentPane.index();

      let animate = animated !== false && currentPaneIndex !== $dataNav;

      // If this new pane is being displayed within pane-1, hide pane-3
      if (sectionPaneWrapper.closest('#pane-1').length) {
        $('#pane-3').removeClass('active');
        if ($('body').width() <= MOBILE_VIEW_BREAKPOINT) {
          $('#pane-2-inner').empty();

          $('#pane-2').removeClass('active');
        }
        //TODO
        $('#pane-3-inner').empty();
      }

      // If this new pane is being displayed within pane-2, hide pane-3
      if (sectionPaneWrapper.closest('#pane-2').length) {
        $('#pane-3').removeClass('active');
        //TODO
        $('#pane-3-inner').empty();
      }
      // If this new pane is being displayed within pane-3, display pane-3
      else if (sectionPaneWrapper.closest('#pane-3').length) {
        $('#pane-2').removeClass('active');
        $('#pane-2-inner').empty();
      }

      if (currentPaneIndex >= $dataNav) {
        const sections = $('#pane-2-inner').children();

        for (let i = $dataNav + 1; i < sections.length; i++) {
          $(sections[i]).remove();
        }
        const overlays = $('.overlay').children();

        for (let i = $dataNav + 1; i < overlays.length; i++) {
          $(overlays[i]).remove();
        }
      }

      // ---------------------------------------------------------------------------------------------
      // Create a new page from template

      let template = null;
      let page = null;
      if ($templateId === 'back') {
        console.info(`Going to previous pane in section...`);
      } else {
        const pageIndex = $dataNav;
        console.info(`Getting template (id: ${$templateId})...`);
        template = z2hTemplates.getPage($templateId, pageIndex, templateIdData, $dataId);

        page = this.createPageFromTemplate(template);

        // -------------------------------------------------------------------------------------------
        // Append new page into the section's internal pane wrapper
        // in the correct position in the list of children
        // (replace child of sectionPaneWrapper at position $dataNav with page)

        // Insert blank section panes before our new pane if they don't already exist
        // e.g. if we are going to be inserting our new page in position 2, there needs to be
        // pages in positions 0 and 1 first.

        page.data('navbar', template.fields.navbar);
        page.data('templateId', $templateId);

        while (sectionPaneWrapper.children().length < $dataNav) {
          sectionPaneWrapper.append($('<div class="section-pane"/>'));
        }

        // Remove page currently in the new page's position
        if (sectionPaneWrapper.children()[$dataNav]) {
          sectionPaneWrapper.children().eq($dataNav).remove();
        }

        // If the page is to sweep in from the left, the page must be given
        // a class of 'left' before it is added to the DOM
        if (currentPaneIndex > $dataNav) {
          page.addClass('left');
        }

        if (!animate && $templateId !== 'notifications') {
          page.addClass('active');
        }

        if ($dataNav === 0) {
          sectionPaneWrapper.prepend(page);
        } else {
          sectionPaneWrapper
            .children()
            .eq($dataNav - 1)
            .after(page);
        }
      }

      // Set new page variables
      var $paneToOpen = $dataNav;

      //Did we go back to a differnt pane

      $('#recaptchaContainer').hide();

      console.info('Displaying position ', $dataNav);

      var $newPane = sectionPaneWrapper.children('.section-pane').eq($paneToOpen);

      // ---------------------------------------------------------------------------------------------
      // Here we initialise any complex components in the new page that may need initialising

      // Sync any radio buttons to the current theme
      this.setAllRadioButtonsToCurrentTheme();

      // Initialise any telephone number inputs on the new pane
      this.initTelephoneBlockByPage($newPane);

      this.initUpdatesPage($newPane);

      // Initialise any doodlepads on the new pane
      this.initDoodlepadsByPage($newPane);

      this.initSuggestedPresetBlockByPage($newPane);

      this.initCountryInputsByPage($newPane);

      this.initTooltips($newPane);

      this.initPremiumPage($newPane);

      // Initialise an idle timer for the page if it contains passwords
      this.initPasswordsPageTimeout($newPane);

      this.contextifyNavbar($newPane);

      this.rememberGroup({ templateId: $newPane.data('templateId') });

      this.refreshGroupsInNav();

      //Reset so that the app doesn't quit on one back button hit

      window.state.lastTemplateId = $newPane.data('templateId');

      $('body').trigger('z2hApp.pagedisplayed', { newPane: $newPane });

      if (!window.state.review) {
        try {
          window.state.review = parseInt(localStorage.getItem('reviewCount'));
        } catch {
          window.state.review = 0;
        }
        if (!window.state.review) {
          window.state.review = 0;
        }
      }
      if (window.state.review && window.state.review >= 3) {
        const allowedTemplates = [
          'loginList',
          'groupsList',
          'healthcheck_welcome',
          'viewProfile',
          'viewGroupDetails',
          'viewServiceDetails',
          'viewProfileAccount',
        ];
        if (allowedTemplates.indexOf($templateId) >= 0) {
          $('body').trigger('z2hApp.rate');
          window.state.review = 0;
          localStorage.setItem('reviewCount', 0);
          console.log('Review trigger');
        }
      }

      const landingPage = $templateId === 'notifications';
      //We never want the landing page being active, so let's ensure
      //we don't have an active class against the landingPage or the
      //pane-2. Instead we will set page 1 as active.
      if (landingPage) {
        $newPane.removeClass('active');
        this.setSectionPaneActive(1);
        animate = false;
      }

      // Async data has loaded, continue full transition / end loading animation
      overlays.makePaneLookIdle(paneNumber);
      // ---------------------------------------------------------------------------------------------
      // If the user is using Safari on iOS, we cannot animate the pane into
      // position and then set focus on a particular field, so we will disable
      // the animation.

      const focusOnId = template && template.fields && template.fields.focus_on;

      // See: https://stackoverflow.com/a/16601288/757237
      if (focusOnId && z2hTools.isIOS() && z2hTools.isSafari()) {
        animate = false;
      }

      // ---------------------------------------------------------------------------------------------
      // Switch to new pane (possibly with animation/transition)

      const switchToNewPane = (_) => {
        $newPane.prevAll().removeClass('active right').addClass('left');
        $newPane.nextAll().removeClass('active left').addClass('right');

        if ($newPane.attr('id') === 'landing-page') {
          $newPane.removeClass('left right').removeClass('active');
          this.setSectionPaneActive(1);
        } else {
          $newPane.removeClass('left right').addClass('active');
        }
      };

      if (animate) {
        // It seems we have to have a slight delay in here. It can't be 1ms, as the pane sometimes
        // isn't ready that quickly, and the animation doesn't happen, so the pane just pops
        // immediately into view rather than sliding in.
        setTimeout(switchToNewPane, 100);
      } else {
        switchToNewPane();

        $newPane.prevAll().removeClass('active left');
        $newPane.nextAll().removeClass('active right');
        $newPane.removeClass('left right').addClass('active');
      }

      // ---------------------------------------------------------------------------------------------
      // The following code is to be executed after the page has transitioned into position

      const afterRender = () => {
        // Focus on a field if specified in template
        if (focusOnId) {
          $('#' + focusOnId).focus();
        }

        if (!($clickedElem && $clickedElem.attr('data-preserve'))) {
          // Delete all temporary page data
          this.temporary = {};
        }

        delete this.temporary.pageNavigationDisabled;

        // remove current pane from DOM if navigating backwards

        //unless we are about to clear pane 1, in which case probably best
        //to hold off.
        if ($templateId === 'back' && !$dataNav == 0) {
          $currentPane.remove();
        }

        // Call any callback we've been given
        if (callback) callback();
      };

      if (animate) {
        setTimeout(afterRender, 300);
      } else {
        afterRender();
      }
    })
    .catch((err) => {
      this.temporary.pageNavigationDisabled = false;
      //console.error(err);
    });
};

z2hApp.prototype.contextifyNavbar = function (pane) {
  setTimeout(() => {
    const paneId = pane.attr('id');
    if (!paneId || paneId === '') return;
    const navItem = $(`.nav-bar li[context="${paneId}"]`);
    if (navItem.length) {
      $('.nav-bar li').removeClass('active');
      navItem.addClass('active');
      const context = navItem.attr('context');
      $('.nav-bar').attr('activeContext', context);
    }

    // nav.each(function() {
    //   const $this = $(this);
    //   const context = $this.attr('context');
    //   $('#' + context + '.active').length >= 1 && $this.addClass('active');
    // }, 500);
  });
};

//Called from android phones.

z2hApp.prototype.backButton = function (e) {
  //Look for the active sections within active panes
  //we're going to 'click' the left hand button
  //if we don't find any active panes were
  let sectionPanesActive = $('.overlay').is(':visible')
    ? $('.overlay')
    : $('main > .section-pane.active').not('#pane-1');
  if ($('.context-menu--active').length > 0) {
    return;
  } else if ($('.nav-bar__overlay').is(':visible')) {
    $('.nav-bar__overlay').click();
  } else if (sectionPanesActive.length) {
    let navLeft = sectionPanesActive.find('.active').find('.section-pane-nav .nav-left');
    let navRight = sectionPanesActive.find('.active').find('.section-pane-nav .nav-right');

    try {
      if (navRight.length <= 1 && navRight[0].innerText === strings.DONE()) {
        navRight.click();
      }
      if (navLeft.length <= 1 && navLeft[0].innerText !== '') {
        navLeft.click();
      }
    } catch (e) {}
  } else if ($('#pane-1').find('.active').attr('id') === 'login-list') {
    window.navigator.app.exitApp();
  } else if (!window.state.backButtonHit && $('#pane-1').find('.active')) {
    this.paneNavigation('loginList', $('#pane-1'), 0, null, null, false, (_) => {
      this.fetchAndShowServices(this.currentGroup()?.id, true);
    });
  }
};

z2hApp.prototype.setTheme = function (mode, colour) {
  const body = $('body');
  const themeClass = 'theme-colour-' + colour;
  const modeClass = 'theme-' + mode;

  if (body.hasClass(themeClass) && body.hasClass(modeClass)) {
    return;
  }

  // Remove any of the "theme-" classes that we've added to the body
  const classNames = body.attr('class').split(/\s+/);
  classNames.filter((cn) => cn.includes('theme-')).forEach((cn) => body.removeClass(cn));

  // Add correct theme colour/mode classes
  body.addClass(themeClass).addClass(modeClass);
  ///in cordova we can set the colour of the status bar.
  this.setStatusBar(mode);
};

const THEME_MODES = ['light', 'dark'];
const THEME_MODE_NAMES = ['Day', 'Night'];
const THEME_COLOURS = ['blue', 'green', 'red', 'orange', 'yellow'];
const THEME_COLOUR_NAMES = ['Flawless Blue', 'Fresh Green', 'Fearless Red', 'Feel-good Orange', 'Friendly Yellow'];

z2hApp.prototype.getThemeFromIntValue = function (val) {
  return THEME_MODES[val] || THEME_MODES[0];
};
z2hApp.prototype.getThemeColourFromIntValue = function (val) {
  return THEME_COLOURS[val] || THEME_COLOURS[0];
};
z2hApp.prototype.getThemeIntValue = function (val) {
  return THEME_MODES.indexOf(val) >= 0 ? THEME_MODES.indexOf(val) : 0;
};
z2hApp.prototype.getThemeColourIntValue = function (val) {
  return THEME_COLOURS.indexOf(val) >= 0 ? THEME_COLOURS.indexOf(val) : 0;
};

// l o a d   g r o u p s
// based on user/@me
z2hApp.prototype.getInitialLaunchData = function (event) {
  console.info('Loading initial launch data...');

  const launchData = this.fetchInitialLaunchData();

  // Attempt to get launch data from cache
  const cachedLaunchData = this.getCachedLaunchData();
  //console.log('cachedLaunchData: ', cachedLaunchData);
  if (cachedLaunchData) {
    console.info('Using cached launch data...');
    this.handleInitialLaunchData(cachedLaunchData);

    // Check for cached lists of services for each group
    this.getCachedGroupServices();

    // Make a background request to get the actual launch data from the server
    // We will used the cached data for now, but load fresh data in the background

    return Promise.resolve(cachedLaunchData);
  }

  return launchData;
};

z2hApp.prototype.launchDataPostActions = function (event) {
  // Take this opportunity to fire off a background request to get the
  // "full" list of user groups. This gives us another list of all groups,
  // but with a full list of users within each group and (most importantly)
  // their names.
  //this.reloadFullGroupsList();

  // Get default login password generation settings from server
  // ==========================================================
  getDefaultGenerationSettings({ forceRefresh: true }).then((settings) => {
    window.state.defaultGenerationSettings = settings;
  });

  // Load service icons and presets list from server
  this.fetchServicePresetsList();

  // Also, make a background request to load the full user details. As of
  // writing, this is only used to get the devices list for the profile->
  // devices page.

  this.fetchFullUserDetails();

  if (localStorage.getItem('updatedLanguage')) {
    this.requestData('post', '', 'users/@me', { region: localStorage.getItem('updatedLanguage') });
    localStorage.removeItem('updatedLanguage');
  } else {
    this.fetchRegion();
  }

  this.fetchUserTheme();

  //this.checkPartnerSubscription();

  this.reloadUpdateSections();

  $('body').trigger('z2hApp.launched');
};

z2hApp.prototype.fetchRegion = function () {
  const fetchDetails = window.state.userDataPromise || this.fetchFullUserDetails();
  return fetchDetails.then((response) => {
    //window.state.userData.region = response.data.region;
    localStorage.setItem('language', response.region);
    require('../../config/strings').setLocale(response.region || 'gb');
  });
};

z2hApp.prototype.fetchUserTheme = function () {
  //just defailt to dark mode green to start
  // First, use cached details if available
  let theme_settings;
  if (!localStorage.getItem('theme_settings')) {
    console.log('no theme_settings, setting to dark green (default)');
    this.setTheme('dark', 'green');
  }

  try {
    theme_settings = JSON.parse(localStorage.getItem('theme_settings'));

    if (theme_settings && theme_settings.colour !== null && theme_settings.mode !== null) {
      const { colour, mode } = theme_settings;
      this.setTheme(this.getThemeFromIntValue(mode), this.getThemeColourFromIntValue(colour));
    }
  } catch (err) {
    // Could not get theme from cache
  }

  const fetchDetails = window.state.userDataPromise || this.fetchFullUserDetails();
  return fetchDetails.then((response) => {
    theme_settings = response.theme_settings;
    localStorage.setItem('theme_settings', JSON.stringify(theme_settings));
    const { colour, mode } = theme_settings;
    this.setTheme(this.getThemeFromIntValue(mode), this.getThemeColourFromIntValue(colour));
  });
};

z2hApp.prototype.fetchFullUserDetails = function () {
  window.state.userDataPromise = this.requestData('get', '', 'users/@me').then((response) => {
    if (Math.floor(response.status / 100) === 2) {
      const fullUserData = response.data;
      window.state.userData = Object.assign({}, window.state.userData, fullUserData);
    }
    localStorage.setItem('subscription_type', window.state.userData.subscription.subscription_type);

    return window.state.userData;
  });
  return window.state.userDataPromise;
};

// Check for cached lists of services for each group
z2hApp.prototype.getCachedGroupServices = function () {
  try {
    const groups = window.state.groupsList || [];
    groups.forEach((group) => {
      // Attempt to get the service list for this group from cache
      const cachedServices_json = localStorage.getItem('services-' + group.id);
      const cachedServices = JSON.parse(cachedServices_json);
      window.state.groupsList.find((g) => g.id === group.id).services = cachedServices;
    });
  } catch (err) {
    console.error(err);
  }
};

z2hApp.prototype.getCachedLaunchData = function () {
  // Attempt to get launch data from cache
  try {
    const cachedLaunchData_json = localStorage.getItem('launch');
    const cachedLaunchData = JSON.parse(cachedLaunchData_json);
    //console.log('cachedLaunchData.uid: ', cachedLaunchData.uid);
    //console.log('fb.getUid(): ', fb.getUid());
    if (cachedLaunchData && cachedLaunchData.uid === fb.getUid()) {
      return cachedLaunchData;
    }
    // Clear cached data if we appear to have cache from a different user ID
    if (cachedLaunchData && cachedLaunchData.uid && cachedLaunchData.uid !== fb.getUid()) {
      this.clearCachedData();
    }
  } catch (err) {
    console.info('Could not load cached initial launch data:', err);
  }
  return null;
};

z2hApp.prototype.clearCachedData = function () {
  console.log('CLEARING CACHED DATA');
  localStorage.clear();
};

z2hApp.prototype.fetchInitialLaunchData = async function () {
  //Don't call unless we've got a user
  if (!fb.getUid()) return Promise.reject();

  let done = false;
  setTimeout(() => {
    if (!done) {
      $('#splash-screen .loading-msg-delay').show();
    }
  }, 2000);

  try {
    return (window.state.launchDataPromise = this.requestData('get', apiConfig.version, 'launch')
      .then(async (response) => {
        done = true;
        $('#splash-screen .loading-msg-delay').hide();
        if (window.loading) {
          clearInterval(window.loading);
          window.loading = null;
        }
        // If we got back an error, try again after a couple of seconds
        if (Math.floor(response.status / 100) !== 2) {
          this.onError('Loading of launch data failed (retrying in two seconds)', {}, { response });
          return new Promise((resolve) => {
            setTimeout((_) => resolve(this.fetchInitialLaunchData()), 2000);
          });
        }
        let launchData = response.data;
        launchData.uid = fb.getUid();

        // Cache the initial launch data to improve future load times
        localStorage.setItem('launch', JSON.stringify(launchData));

        // Preload/cache the services for each group to save time later
        const groups = launchData.groups_list || [];
        if (!window.state.groupsList) {
          window.state.groupsList = groups;
        }

        const loginList = $('.login-list');
        let wasDoingAcrossSearch = false;
        if (loginList.length > 0) {
          wasDoingAcrossSearch = $('#search-input-123').val() !== '';
        }

        if (wasDoingAcrossSearch) {
          groups.forEach((group) => this.fetchAndShowServices(group.id, false, true));
        } else {
          const group = window.state.selectedGroupId || window.state.groupsList.find((g) => g.personal)?.id;
          if (window.state.selectedGroupId === null) window.state.selectedGroupId = group;
          this.fetchAndShowServices(group, true, false);

          this.fetchAllServices(group);
        }

        this.handleInitialLaunchData(launchData);
      })
      .catch((err) => {
        done = true;
        $('#splash-screen .loading-msg-delay').hide();
        this.onError('Error getting initial launch data', err);
        return new Promise((resolve) => {
          setTimeout((_) => resolve(this.fetchInitialLaunchData()), 2000);
        });
      }));
  } catch (err) {
    return new Promise((resolve) => {
      window.state.launchDataPromise = null;
      setTimeout((_) => resolve(this.fetchInitialLaunchData()), 2000);
    });
  }
};

z2hApp.prototype.handleInitialLaunchData = function (launchData) {
  window.state.launchData = launchData;

  let user = {
    name: launchData.user_name,
    avatar: launchData.user_avatar,
  };

  this.userData = {
    id: fb.getUid(),
    newAccountStep: launchData.new_account_step,
    themeSettings: {
      mode: this.getThemeFromIntValue(launchData.theme_settings.mode),
      colour: this.getThemeColourFromIntValue(launchData.theme_settings.colour),
    },
    gridSettings: {
      lines: launchData.grid_settings.lines,
      numbers: launchData.grid_settings.numbers,
    },
    referral_used: launchData.home.referral_used,
    ...user,
  };

  window.state.userData = Object.assign({}, window.state.userData || {}, this.userData);

  if (launchData.encryption_data) {
    localStorage.setItem('pubkey', launchData.encryption_data.public_key);
  }

  // Update user identification in logrocket
  // if (LOGROCKET_ID && logrocket && logrocket.identify) {
  //   logrocket.identify(this.userData.id, {
  //     name: this.userData.name,
  //     // Custom user variables:
  //     themeColour: launchData.theme_settings.colour,
  //     themeMode: launchData.theme_settings.mode,
  //     phone: fb.getCurrentUser().phoneNumber,
  //   });
  // }

  // Sentry.configureScope(scope => {
  //   scope.setUser({ username: this.userData.name, id: fb.getCurrentUser().phoneNumber });
  // });

  // const themeColour = this.userData.themeSettings.colour;
  // const themeMode = this.userData.themeSettings.mode;
  // this.setTheme(themeMode, themeColour);

  if (launchData.home.premium) document.body.classList.add('premium');

  if (localStorage.getItem('userprefs-secretdoodle') === 'true') {
    $('.doodlepad').addClass('lines-hidden');
  }
  if (localStorage.getItem('userprefs-secretdoodle') === 'true') {
    $('.doodlepad').addClass('numbers-hidden');
  }

  localStorage.setItem('newAccountStep', this.userData.newAccountStep);
  //localStorage.setItem('themeSettings', JSON.stringify(this.userData.themeSettings));
  localStorage.setItem('gridSettings', JSON.stringify(this.userData.gridSettings));
  localStorage.setItem('subscription', JSON.stringify(this.userData.subscription));

  this.updateGroupsList(launchData.groups_list);

  const groupsList = window.state.groupsList;

  const personalGroup = groupsList.find((g) => g.personal);
  if (personalGroup && !window.state.selectedGroupId) {
    window.state.selectedGroupId = personalGroup.id;
    personalGroup.services = launchData.personal_group_services_list;

    if ($('#pane-1-inner').has('section').length < 1) {
      this.showServicesForGroup(personalGroup.id, true);
    }
  }

  return launchData;
};

z2hApp.prototype.fetchServicePresetsList = function () {
  console.log('fetchServicePresetsList: ', 1);
  window.state.serviceIconList = [];
  window.state.topPresets = [];

  // Fetch presets/services list from server
  // =======================================
  // Fetch services with icons at 180x180px (double the size we generally display the icons at,
  // to look good on retina displays)
  window.state.servicePresetPromise = this.requestData('get', '', 'launch/presets?scale=3')
    .then((response) => {
      if (response.status < 300 && response.data.presets) {
        const mapPresetToServiceIcon = (p) => ({
          id: p.id,
          name: p.name,
          address: p.url,
          url: p.icon || '',
        });
        const { top, presets } = response.data;
        const topPresetsFromServer = top.filter((t) => presets[t]).map((t) => presets[t]);
        Object.keys(response.data.presets).forEach((k) => {
          window.state.serviceIconList.push(mapPresetToServiceIcon(response.data.presets[k]));
        });
        window.state.topPresets = topPresetsFromServer.map(mapPresetToServiceIcon) || [];
        localStorage.setItem('top-presets', JSON.stringify(window.state.topPresets));
        // We'll pre-fetch all the icons for the top services to improve the loading time
        // for the preset service selection page
        window.state.topPresets.forEach((tp) => {
          if (tp.url) fetch(tp.url);
        });
        return window.state.serviceIconList;
      }
    })
    .catch((err) => console.error('Error fetching service icon list: ', err))
    .then((_) => {
      // Fallback option, use original local service icon list
      // =================================================================
      if (!window.state.serviceIconList.length) {
        window.state.serviceIconList = require('../getPageData_helpers/serviceIconList');
        // Give each service an ID
        window.state.serviceIconList = window.state.serviceIconList.map((s) => ({ ...s, id: 'service-' + s.name }));
        // Create a list of top presets
        window.state.topPresets = window.state.serviceIconList.filter((s) =>
          [
            'amazon',
            'barclays',
            'ebay',
            'facebook',
            'gmail',
            'instagram',
            'itunes',
            'nationwide',
            'netflix',
            'paypal',
            'spotify',
            'twitter',
          ].includes(s.name.toLowerCase()),
        );
      }
    });
  return window.state.servicePresetPromise;
};

z2hApp.prototype.reloadFullGroupsList = function (reloadJustInCase) {
  const reloaded = window.state.fullUserGroupsLastReload;
  if (reloadJustInCase && Date.now() - reloaded < 60000) return window.state.fullUserGroupsPromise;
  if (reloaded >= Date.now() - 1000) return window.state.fullUserGroupsPromise;

  window.state.fullUserGroupsLastReload = Date.now();

  window.state.fullUserGroupsPromise = this.requestData('get', '', 'users/@me/groups-full').then((response) => {
    if (Math.floor(response.status / 100) !== 2) return window.state.groupsList;
    this.updateGroupsList(response.data, true);
    localStorage.setItem('groupsList', JSON.stringify(window.state.groupsList));

    return window.state.groupsList;
  });
  return window.state.fullUserGroupsPromise;
};

z2hApp.prototype.updateGroupsList = function (groups, remove) {
  const currentGroupsList = window.state.groupsList || [];

  const groupsArray = Object.values(groups);
  const groupsToAdd = [];
  //find groups to remove...
  if (remove) {
    for (const cg of currentGroupsList) {
      if (groupsArray.find((g) => g.id === cg.id)) {
        groupsToAdd.push(cg);
      }
    }
  }

  const newGroupsList = [...groupsToAdd];
  for (let i in groups) {
    const group = groups[i];

    group.name =
      require('../../config/app').PERSONAL_GROUP_BASE === group.name.toLowerCase()
        ? strings.PERSONAL_GROUP()
        : group.name;
    group.name =
      require('../../config/app').SHAREABLE_GROUP_BASE === group.name.toLowerCase()
        ? strings.SHAREABLE_GROUP()
        : group.name;
    group.name =
      require('../../config/app').IMPORT_GROUP_BASE === group.name.toLowerCase() ? strings.IMPORT_GROUP() : group.name;

    // If the group contains a list of services like:
    //   {-LSBG2MG88ul_DL9qmOJ: true, -LSBGqUDmd_lA70nbHoV: true}
    // ...then delete it. We don't want it.
    if (group.services && !group.services.map) delete group.services;

    //

    if (!group.name) continue;

    const existingGroupIndex = newGroupsList && newGroupsList.findIndex((g) => g.id === group.id);

    const existingGroupDetails = newGroupsList[existingGroupIndex];

    if (existingGroupDetails) {
      // Update the group details
      newGroupsList[existingGroupIndex] = { ...existingGroupDetails, ...group };
    } else {
      // Or add it if we don't have it in our list yet

      newGroupsList.push(group);
      //If we are running in realtime we will get a seperate realtime action.
      //if (!config.REALTIME) {
      //this.fetchServices(group.id);
      //}
    }

    //this.fetchServices(group.id);
  }

  // Sort by name
  newGroupsList.sort((a, b) => (a.name > b.name ? 1 : -1));
  // Put personal group at the top
  newGroupsList.sort((a, b) => (a.personal ? -1 : 0));
  window.state.groupsList = newGroupsList;

  //Update our list of groups
  this.refreshGroupsInNav();
  //This will add the services back in from the cache.
  this.getCachedGroupServices();
};

z2hApp.prototype.highlightNavElement = function (element) {
  $('#main-nav').find('li.active').removeClass('active');
  element.addClass('active');
};

z2hApp.prototype.refreshGroupsInNav = function () {
  if (!window.state || !window.state.launchData) return;

  let groupsList = window.state.groupsList;

  let personalGroup = groupsList.find((g) => g.personal);
  let myGroups = groupsList.filter((g) => window.state.userData.id === g.owner_id && !g.personal);
  let otherGroups = groupsList.filter((g) => g.owner_id && window.state.userData.id !== g.owner_id && !g.personal);

  // Sort by name
  myGroups.sort((a, b) => (a.name > b.name ? 1 : -1));

  //other groups need sorting by group owner name and own name name
  otherGroups.sort((a, b) => (a.name > b.name ? 1 : -1));
  otherGroups = otherGroups.map((group) => {
    let owner;
    if (group.users) {
      const usersArray = Object.entries(group.users);
      owner = usersArray.find((u) => u[1].member_type === 2);
    }
    return owner ? { owner_name: owner[1].name, ...group } : { owner_name: '', ...group };
  });
  otherGroups.sort((a, b) => (a.owner_id > b.owner_id ? 1 : -1));

  const premium = window.state.launchData.home.premium;

  // For non-premium users...
  // if (!premium) {
  //   const userId = window.state.userData.id;
  //   // Filter out groups that user is an owner of.
  //   // If the group doesn't have a list of users at all, then we hide it.
  //   // This suggests we haven't got the result of GET 'users/@me/groups-full'
  //   otherGroups = otherGroups
  //     .filter(g => g.users)
  //     .filter(g => {
  //       return ((g.users && g.users[userId]) || {}).member_type !== 2;
  //     });
  // }

  const groupItemBlock = (grp, icon) => ({
    template: 'main-nav-group-avatar',
    selector: 'li',
    attributes: [
      { selector: '', type: 'data-id', content: grp.id },
      { selector: '', type: 'class', content: grp.personal ? 'personal' : 'nonpersonal' },
      { selector: '.icn-button-name', type: 'innerText', content: grp.name },
      { selector: '.login-avatar', type: 'src', content: icon },
      { selector: '.login-avatar', type: 'data-id', content: grp.id },
    ],
  });
  const selectorTypeContent = (selector, type, content) => ({ selector, type, content });
  const createGroupItem = (grp) => {
    const block = groupItemBlock(grp, grp.icon);
    grp.tag && block.attributes.push({ selector: '.hasTag', type: 'innerText', content: grp.tag });
    grp.tag && block.attributes.push({ selector: '.hasTag', type: 'class', content: 'tag' });

    const groupItem = this.constructBlock(block);
    // Highlight if this is the selected group
    if (grp.id === window.state.selectedGroupId) {
      groupItem.addClass('active');
    }
    return groupItem;
  };

  const { DEFAULT_USER_ICON, DEFAULT_GROUP_ICON } = config.app;
  const personalIcon = window.state.userData.avatar || DEFAULT_USER_ICON;

  // Get a simplified list of the groups we are going to display
  const groupsToDisplay = [personalGroup, ...myGroups, ...otherGroups].map((g) => {
    const newGroup = localStorage.getItem(`newGroup_${g.id}`);
    let tag = newGroup && strings.NEW();
    if (newGroup && Date.now() - newGroup >= 600000) {
      localStorage.removeItem(`newGroup_${g.id}`);
      tag = '';
    }

    return {
      // For each group, ensure the icon is something sensical
      icon: g.personal ? personalIcon : g.icon || DEFAULT_GROUP_ICON,
      personal: g.personal,
      name: g.name,
      id: g.id,
      owner: g.owner_name || '',
      owner_id: g.owner_id || window.state.userData.id,
      tag: tag,
    };
  });

  // Check if we need to refresh the list of groups or they can remain as they are
  const hashOfDisplayedData = $('#groups-nav').data('hash');
  const hashOfNewData = md5(JSON.stringify(groupsToDisplay));
  if (hashOfDisplayedData === hashOfNewData) {
    return;
  }
  $('#groups-nav').data('hash', hashOfNewData);

  $('#groups-nav').empty();
  let lastOwner = window.state.userData.id;
  for (let i = 0; i < groupsToDisplay.length; i++) {
    const groupOwnerTemplate = (groupName) => ({
      template: 'block-text-group',
      fields: {
        primary_text: config.strings.GROUP_SHARED_BY(),
        secondary_text: groupName,
      },
    });
    if (groupsToDisplay[i].owner && lastOwner !== groupsToDisplay[i].owner_id) {
      const groupOwner = this.constructBlock(
        z2hTemplates.compileBlockAttribute(groupOwnerTemplate(groupsToDisplay[i].owner)),
      );
      $('#groups-nav').append(groupOwner);
    }
    $('#groups-nav').append(createGroupItem(groupsToDisplay[i]));
    lastOwner = groupsToDisplay[i].owner_id || '';
  }
  if ($('#groups-list').hasClass('active')) {
    this.paneNavigation('groupsList', $('#pane-1'), 0, null);
  }

  // const otherItemBlock = (icon, name) => ({
  //   template: 'main-nav-group-otheritem',
  //   selector: 'li',
  //   attributes: [
  //     { selector: '', type: 'data-id', content: name },
  //     //{ selector: '', type: 'class', content: grp.personal ? 'personal' : 'nonpersonal' },
  //     { selector: '.icn-button-name', type: 'innerText', content: name },
  //     { selector: '.login-avatar', type: 'src', content: icon },
  //     //{ selector: '.login-avatar', type: 'data-id', content: grp.id },
  //   ],
  // });
  // const otherItems = this.constructBlock(otherItemBlock)

  // $('#groups-nav').append(otherItems);
};

// l o a d   s e r v i c e s
// based on data-id of clicked group
z2hApp.prototype.showServicesForGroup = function (eventOrGroupId, useCache = true, silent = false) {
  try {
    let groupId;
    // Check if this is being run as an event or an ID and declare the group ID
    if (eventOrGroupId && eventOrGroupId.currentTarget) {
      groupId = eventOrGroupId.currentTarget.getAttribute('data-id');
    } else if (eventOrGroupId) {
      groupId = eventOrGroupId;
    } else {
      // Just carry on with the current group ID
      groupId = window.state.selectedGroupId;
    }

    this.highlightNavElement($('#main-nav').find('[data-id=' + groupId + ']'));

    // If the login list page is already displayed (for the correct group) just fetch the services
    if (
      (window.state.selectedGroupId === groupId &&
        $('.login-list-page').length &&
        (!eventOrGroupId || !eventOrGroupId.currentTarget)) ||
      silent
    ) {
      this.fetchAndShowServices(groupId, useCache);
      return;
    }

    // Set active group ID
    this.setCurrentGroup(groupId);

    // Load login-list page
    this.paneNavigation('loginList', $('#pane-1'), 0, null, null, false, (_) => {
      this.fetchAndShowServices(groupId, useCache);
    });
  } catch (err) {
    this.onError('Error in showServicesForGroup', err);
  }
};

z2hApp.prototype.fetchAndShowServices = function (groupId, useCache = true, lazy = false) {
  const overlays = require('./overlays');

  console.log('FETCH AND SHOW SERVICES', groupId);

  // Check if we have the services to display already
  let loadedFromCache = false;
  if (useCache) {
    try {
      const services = window.state.groupsList.find((g) => g.id === groupId).services || [];
      if (services.length > 0) {
        this.showServices(services, groupId);
        loadedFromCache = true;
      }
    } catch (err) {
      console.log('Failed to load services from cache: ', err);
    }
  }

  //console.log('loadedFromCache: ', loadedFromCache);
  // Get partial service via API and wait for the response before continuing
  if (!loadedFromCache) {
    const { LOCHY_LOADING_TEXT_GROUP, NEW_GROUP } = require('../../config/strings');
    let name = strings.NEW_GROUP();
    let group = window.state.groupsList.find((g) => g.id === groupId);
    if (group) {
      name = group.name;
    }

    if (!lazy) {
      overlays.makePaneLookBusy(1, { text: LOCHY_LOADING_TEXT_GROUP()(name) });
    }
    //TODO this causes chaos, why did I put it here?
    //lazy = false;
  }

  this.fetchServices(groupId)
    .then((services) => {
      this.showServices(services, groupId, false, lazy);
      if (!lazy) {
        overlays.makePaneLookIdle(1);
      }
    })
    .catch((err) => {
      console.error(err);
      overlays.makePaneLookIdle(1);
    });
};
z2hApp.prototype.fetchServices = function (groupId) {
  console.info(`Fetching services for group ${groupId}...`);
  const serviceForGroupPromise = this.requestData('get', '', 'groups/' + groupId + '/services').then((response) => {
    const services = response.data || [];
    //console.log('fetchServices complete:', groupId);
    localStorage.setItem('services-' + groupId, JSON.stringify(services));
    // Store in state
    const groupsList = window.state.groupsList || [];
    const group = groupsList.find((g) => g.id === groupId) || {};
    group.services = services;
    return services;
  });

  return serviceForGroupPromise;
};

z2hApp.prototype.fetchAllServices = function (excludeGroupIds = '') {
  console.info(`Fetching all service for all groups for group...`);
  const servicesAllPromise = this.requestData('get', '', `users/@me/services?excludeGroupIds=${excludeGroupIds}`).then(
    (response) => {
      const groups = response.data || [];
      const groupsList = window.state.groupsList || [];

      for (const groupId in groups) {
        //find the existing service if we already have it.
        const g = groupsList.find((g) => g.id === groupId) || {};
        if (g) {
          //loop over the services in the group
          for (const service of groups[groupId].services) {
            //find the existing service if we already have it.
            const s = g?.services?.find((s) => s.id === service.id);
            if (s) {
              if (s.fields) {
                service.fields = s.fields;
              }
            }
          }
          g.services = groups[groupId].services;
        } else {
          groupsList.push(groups[groupId]);
        }
        localStorage.setItem('services-' + groupId, JSON.stringify(groups[groupId].services));
      }
      //console.log('fetchServices complete:', groupId);
      // Store in state
      return groupsList;
    },
  );

  return servicesAllPromise;
};

z2hApp.prototype.showServices = function (data, groupId, forceUpdate = false, lazy) {
  // Check if we still need to display this fetched list of services, or if the user
  // has switched group already

  if (localStorage.getItem('waitingForData')) {
    //call this again in 100ms

    const lochyLoadingText = require('../page_helpers/paneBusyText');
    const text = lochyLoadingText['loginlist'] ? lochyLoadingText['loginlist']() : '';

    const overlays = require('./overlays');

    overlays.makePaneLookBusy(1, { text });

    return setTimeout(() => {
      overlays.makePaneLookIdle(1);
      this.showServices(data, groupId, forceUpdate, lazy);
    }, 300);
  }

  const loginList = $('.login-list');
  let wasDoingAcrossSearch = loginList.attr('searchType') === 'acrossGroups';

  // -----------------------------------------------------------------------------------------------
  // If the user is no longer displaying a login list panel, we can give up now

  if (!loginList.length) return;
  if (!wasDoingAcrossSearch && groupId && window.state.selectedGroupId !== groupId) return;

  // Map the list of services we receive to only include the properties we are using
  const services = (data || []).map(({ id, name, username, username_secondary, icon, last_used }) => ({
    last_used,
    id,
    name,
    username,
    username_secondary,
    icon,
  }));
  const servicesWithoutLastUsed = (data || []).map(({ last_used, id, name, username, username_secondary, icon }) => ({
    id,
    name,
    username,
    username_secondary,
    icon,
    lu: last_used > 0 ? false : true,
  }));

  //We want a list of the recently accessed services...
  const favourites = services.sort((a, b) => (a.last_used > b.last_used ? -1 : 1)).slice(0, 6);

  this.sortArrayOfObjects(services, 'name'); // sort services by name

  //let wasDoingAcrossSearch = loginList.attr('searchType') === 'acrossGroups';

  // -----------------------------------------------------------------------------------------------
  // Check if we need to refresh the currently displayed list by comparing an MD5 hash of the
  // currently displayed list of services to that of the newly received list

  let hashOfDisplayedData = loginList.data('hash');
  const hashOfNewData = md5(JSON.stringify(services));
  // If not, leave now
  if (!forceUpdate && !wasDoingAcrossSearch && hashOfNewData === hashOfDisplayedData) return;

  !lazy && loginList.data('hash', hashOfNewData);

  //We have two types of search, one where we are looking just at the group
  // (this one), and the other kind which searches across groups (aka acrossGroups).
  //The search type defines whether or not we have a cancel button displayed.
  let forceRefresh = false;
  if (wasDoingAcrossSearch) {
    const searchTerm = $('.search-input.text-input').val();
    if (!searchTerm || searchTerm === '') {
      loginList.attr('searchType', 'standard');
      $('#login-list').find('.search__wrapper').find('button').fadeOut();
      wasDoingAcrossSearch = false;
      forceRefresh = true;
      $('.login-list').empty();
    }
  }

  // -----------------------------------------------------------------------------------------------

  // Save group services in state and localStorage
  let groupMemberType = 0;
  let group;
  if (groupId) {
    group = window.state.groupsList.find((g) => g.id === groupId) || {};
    group.services = data;
    if (group.personal) {
      groupMemberType = 2;
    } else {
      groupMemberType = group.users
        ? window.state.launchData.uid && (group.users[window.state.launchData.uid] || {}).member_type
        : 0;
    }
  } else {
    group = window.state.groupsList.find((g) => g.id === window.state.selectedGroupId) || {};
    if (group.personal) {
      groupMemberType = 2;
    } else {
      groupMemberType = (group.users[window.state.launchData.uid] || {}).member_type;
    }
  }

  //We might have changed groups without going to login list
  const header = $('#login-list .list-header');
  if (!lazy) {
    header.find('h1')[0].innerHTML = group.name;

    const inviteButton1 = header.find('#login-list-button-1');

    const inviteButton2 = header.find('#login-list-button-2');

    const lochyBadgeTemplate = (premium) => {
      const lochys = {
        free: 'img/lochy/Lochy-free.gif',
        premium_paid: 'img/lochy/Lochy-annual.gif',
        premium_lifetime: 'img/lochy/Lochy-lifetime.gif',
        premium_trial: 'img/lochy/Lochy-monthly.gif',
        premium: 'img/lochy/Lochy-monthly.gif',
        partner: 'img/lochy/Lochy-monthly.gif',
      };

      let subType = localStorage.getItem('subscription_type');

      if (!subType) {
        const subscription = (window.state && window.state.userData && window.state.userData.subscription) || {};
        subType = subscription.subscription_type;
      }
      const badge = subType ? lochys[subType] : lochys[premium ? 'premium' : 'free'];

      return {
        template: 'block-icn-loginlist-lochy',
        fields: {
          image: badge,
          action: 'gotoPremium',

          class: '',
        },
      };
    };
    const buildBadge = (premium) => {
      return this.constructBlock(z2hTemplates.compileBlockAttribute(lochyBadgeTemplate(premium)));
    };

    const buildInvite = ({ id = 'login-list-button-2' }) => {
      return this.constructBlock(
        z2hTemplates.compileBlockAttribute({
          template: 'block-icn-loginlist',
          fields: {
            id: id,
            icon: 'group-share',
            action: 'createGroupInvite',
            navigation_data: 1,
            navigation_pane: 2,
            navigation_template: 'newInvite_loading',
          },
        }),
      );
    };

    const buildNotification = ({ withDot = true, id = 'login-list-button-1' }) => {
      return this.constructBlock(
        z2hTemplates.compileBlockAttribute({
          template: 'block-icn-loginlist',
          fields: {
            id: id,
            icon: 'alarm',
            navigation_data: 1,
            navigation_pane: 2,
            navigation_template: 'notificationsFromBell',
            //TODO default for now
            accessoryDot: withDot,
          },
        }),
      );
    };

    inviteButton1.empty();
    inviteButton2.empty();

    inviteButton1.append(
      buildNotification({ withDot: !localStorage.getItem('notifications_read'), id: 'login-list-button-1' }),
    );

    setInterval(() => {
      if (localStorage.getItem('notifications_read')) {
        inviteButton1.find('.icon-accessory-dot').remove();
      } else {
        inviteButton1.find('.icon-accessory-dot').length === 0 &&
          inviteButton1.empty().append(buildNotification({ withDot: true, id: 'login-list-button-1' }));
      }
    }, 500);
    // const accessory = $('<div>').addClass('icon-accessory-dot');
    // const bell = $('<i>').addClass('f-icn').addClass('f-icn-alarm');

    // if (!this.unread_notifications) {
    //   bell.append(accessory);
    // }

    // inviteButton1.append(bell);

    if ((groupMemberType === 2 || groupMemberType === 1) && !group.personal && !group.importServices) {
      inviteButton2.append(buildInvite({ id: 'login-list-button-2' }));
    } else if (group.personal) {
      inviteButton2.append(buildBadge(window.state.launchData.home.premium));
    } else {
      //don't do anything.
    }
    const { DEFAULT_USER_ICON, DEFAULT_GROUP_ICON } = config.app;
    let icon = group.icon || DEFAULT_GROUP_ICON;
    if (group.personal) {
      icon = window.state.userData.avatar || DEFAULT_USER_ICON;
    }
    header.find('.login-list-page__group-icon').attr('src', icon);

    // Store current list of services in state
    if (!wasDoingAcrossSearch) {
      window.state.currentServices = data;
    }

    // // Remove old data
    // if (!wasDoingAcrossSearch) {
    //   $('.login-list').empty(); // Full list
    //   $('.recent ul').empty(); // Recent
    // }
  }
  var letterArray = [];

  // Helpers
  // =======
  const selectorTypeContent = (selector, type, content) => ({ selector, type, content });
  const loginItemFromTemplate = (template, service, letter, selected) => ({
    template: template,
    selector: 'li',
    attributes: [
      selectorTypeContent('', 'data-actionclick', 'newLoginOrDoodle'),
      selectorTypeContent('', 'data-id', service.id),
      //selectorTypeContent('', 'data-template', 'viewService1_doodlepad'),
      //selectorTypeContent('', 'data-nav', '1'),
      //selectorTypeContent('', 'data-nav-pane', '2'),
      selectorTypeContent('.login-name', 'innerText', service.name),
      selectorTypeContent('.login-username', 'innerText', service.username || service.username_secondary),
      selectorTypeContent('.login-avatar', 'src', service.icon || config.app.DEFAULT_SERVICE_ICON),
      selectorTypeContent('.login-avatar', 'data-id', service.id),
      selectorTypeContent('', 'data-letter', letter),
      selectorTypeContent(
        '.icn-button',
        'data-context',
        groupMemberType === 2 ? 'loginListItemOwner' : groupMemberType === 1 ? 'loginListItemAdmin' : '',
      ),
      selectorTypeContent('.f-icn', 'class', groupMemberType >= 1 ? 'f-icn-dots' : ''),

      selectorTypeContent('.login-avatar-wrapper', 'data-selected', selected),
    ],
  });
  const noLoginsRow = (_) => ({
    template: 'block-no-items-row',
    selector: 'section',
    attributes: [
      selectorTypeContent('span', 'innerText', config.strings.NO_LOGINS_MESSAGE()),
      selectorTypeContent('', 'data-id', 'no-logins-row'),
    ],
  });

  const loading = (_) => ({
    template: 'block-no-items-row',
    selector: 'section',
    attributes: [
      selectorTypeContent('span', 'innerText', 'Loading...'),
      selectorTypeContent('', 'data-id', 'no-logins-row'),
    ],
  });

  // Display notice if no logins to show
  // -----------------------------------------------------------------------------------------------
  if (!lazy && services.length === 0) {
    $('[data-id="no-logins-row').empty();
    $(`#recently-used-services`).empty();
    $('.login-list').empty();
    var row = this.constructBlock(noLoginsRow());
    $('.login-list').append(row);
    return;
  } else if (!lazy) {
    $('[data-id="no-logins-row').empty();
  } else {
    $('[data-id="no-logins-row').empty();
  }

  // Services list
  // -----------------------------------------------------------------------------------------------
  // For each item in the array, check if an alpha heading for the letter
  // exists and put an item into the latest one.

  //First of all lets put the favourites in.
  if (favourites.length && !wasDoingAcrossSearch) {
    $(`#recently-used-services`).empty();
    const favouriteBlock = {
      template: 'alpha-list',
      selector: 'li',
      attributes: [
        selectorTypeContent('', 'id', 'recently-used-services'),
        selectorTypeContent('.alpha-list-heading', 'innerText', config.strings.LOGIN_RECENT()),
      ],
    };

    const favouriteItem = this.constructBlock(favouriteBlock);

    $('.login-list').prepend(favouriteItem);

    const cols = [];
    const faveRowTemplate = {
      template: 'block-section-row',
      fields: { class: 'recents' },
      cols: [],
    };
    for (const favourite of favourites) {
      const faveColTemplate = {
        template: 'block-login-avatar',
        fields: {
          avatar: favourite.icon,
          label: favourite.name,
          data_id: favourite.id,
          action: 'newLoginOrDoodle',

          //navigation_pane: 2,
          //navigation_data: 1,
        },
      };

      if (favourite.last_used === 0) {
        faveColTemplate.fields.selected = true;
      }
      cols.push(faveColTemplate);

      // Create the template for a login item and append it to the last created alpha list
      //const loginBlock = loginItemFromTemplate('login-item', favourite);
    }
    const loginItem = this.constructBlock(z2hTemplates.compileBlockAttribute(faveRowTemplate));
    for (const i of cols) {
      var blockItem = this.constructBlock(z2hTemplates.compileBlockAttribute(i));
      // Append to new element
      loginItem.children('.column').children('.column-group').append(blockItem);
    }

    //Only display recent services if there are more than one
    if (favourites.length > 1) {
      $('#recently-used-services').show();
      $('#recently-used-services .alpha-list-content').append(loginItem);
    } else {
      $('#recently-used-services').hide();
    }
  }
  if (!wasDoingAcrossSearch) {
    const hashWithoutLastUsed = md5(JSON.stringify(servicesWithoutLastUsed));
    const hashOfLastLastUsedData = loginList.data('hasWOtLastUsed');
    if (hashWithoutLastUsed === hashOfLastLastUsedData && !forceUpdate) {
      return;
    }
    !lazy && loginList.data('hasWOtLastUsed', hashWithoutLastUsed);
    $('[data-letter]').empty();
    for (let i = 0; i < services.length; i++) {
      const service = services[i];
      // Header
      // ======
      let firstLetter = service.name.charAt(0).toUpperCase(); // Get the first letter of the item
      if (firstLetter > 'Z' || firstLetter < 'A') {
        firstLetter = '#';
      }
      if (letterArray.indexOf(firstLetter) == -1) {
        // If the letter doesn't already exist as a section...
        letterArray.push(firstLetter); // ... add the letter to the letterArray
        // Then create the template and append it
        var alphaBlock = {
          template: 'alpha-list',
          selector: 'li',
          attributes: [
            selectorTypeContent('', 'data-letter', firstLetter),
            selectorTypeContent('.alpha-list-heading', 'innerText', firstLetter),
          ],
        };
        var alphaItem = this.constructBlock(alphaBlock);
        $('.login-list').append(alphaItem);
      }

      // Create the template for a login item and append it to the last created alpha list
      var loginBlock = loginItemFromTemplate('login-item', service, firstLetter, service.last_used === 0);
      var loginItem = this.constructBlock(loginBlock);
      $(".alpha-list[data-letter='" + firstLetter + "'] .alpha-list-content").append(loginItem);
    }
  } else {
    const searchTerm = $('.search-input.text-input').val();

    //find if the search
    const simplifiedSearchTerm = searchTerm.trim().toLowerCase().replace(' ', '');

    for (let i = 0; i < services.length; i++) {
      if (simplifiedSearchTerm) {
        let simplifiedServiceName = services[i].name.toLowerCase().replace(' ', '');
        let foundPreset = false;
        if (services[i]?.preset_id !== '' && services[i].preset_id === simplifiedSearchTerm) {
          foundPreset = true;
        }

        if (!foundPreset && simplifiedServiceName.indexOf(simplifiedSearchTerm) < 0) {
          continue;
        }
      }
      const exists = $(`.login-item[data-id="${services[i].id}"]`);

      //$('.alpha-list[data-id=' + groupId + ']').empty();
      if (exists.length < 1) {
        let groupServicesList = $('.alpha-list[data-id=' + groupId + ']');
        if (groupServicesList.length === 0) {
          const alphaHeaderHtml = `
          <li class="alpha-list" data-letter="*" data-id="${group.id}">
            <h2 class="alpha-list-heading">${group.name}</h2>
            <ul class="alpha-list-content"></ul>
          </li>
        `;
          groupServicesList = $(alphaHeaderHtml);
          $('.login-list-wrapper .login-list').eq(0).append(groupServicesList);
        } else {
          groupServicesList.find('.alpha-list-heading').show();
        }
        // Construct a login item block
        // Create the template for a login item and append it to the last created alpha list
        var loginBlock = loginItemFromTemplate('login-item', services[i], null, services[i].last_used === 0);
        var loginItem = this.constructBlock(loginBlock);
        groupServicesList.find('.alpha-list-content').append($(loginItem));
      }
    }
  }
};

// h e l p e r   f u n c t i o n s
// API REQUEST
// request/submit data from api path
z2hApp.prototype.requestData = function (type, version, path, data) {
  //We still haven't got an auth state, so wait a bit and try again
  if (window.waitingForFirstAuthStateChange) {
    return new Promise((resolve) => {
      if (window.waitingForFirstAuthStateChangeTries > 50) {
        window.location.reload();
      }

      if (window.waitingForFirstAuthStateChangeTries >= 5) {
        $('#splash-screen .loading-msg-delay').show();
      }
      if (path === 'launch') {
        window.waitingForFirstAuthStateChangeTries++;
      }
      console.log('Waiting for first auth state change before making API request');

      return setTimeout((_) => resolve(this.requestData(type, version, path, data)), 500);
    });
  }

  try {
    var requestData = typeof data === 'string' ? data : JSON.stringify(data);

    // We need a query parmeter (unique to the current hostname) for our API requests to
    // circumvent an error whereby we get back a cached OPTIONS/preflight response which
    // tells us that our hostname is invalid (for CORS) because this API has been called
    // from this browser when using forghetti on a different hostname.
    const cachbust = (path.includes('?') ? '&' : '?') + encodeURIComponent(window.location.hostname);
    const apiVersion = version || apiConfig.version;
    const requestMethod = type.toUpperCase();
    const requestPath = `${apiConfig.url}/v${apiVersion}/${path + cachbust}`;

    return new Promise(async function (resolve, reject) {
      let token;
      try {
        token = await fb.getIdToken();
        if (!token) {
          return reject('no token');
        }
      } catch (err) {
        reject(err);
        return console.log('path', path);
      }

      try {
        const result = await fetch(requestPath, {
          method: requestMethod,
          headers: {
            Authorization: 'Bearer ' + token,
          },
          body: requestData ? requestData : null,
        });
        if (result.status <= 299) {
          const json = await result.json();
          resolve(json);
        } else {
          reject(result);
        }
      } catch (err) {
        reject(err);
        console.log(err);
      }
    });
  } catch (err) {
    this.onError('Error getting data from server', err);
  }
};

z2hApp.prototype.constructRow = function (rowTemplate) {
  var rowItem = this.constructBlock(z2hTemplates.compileBlockAttribute(rowTemplate));
  const columns = rowTemplate.columns || [];
  // Create columns and append to row
  for (var j = 0; j < columns.length; j++) {
    if (!columns[j]) continue;
    var pageBlock = columns[j];
    var blockItem = this.constructBlock(z2hTemplates.compileBlockAttribute(pageBlock));
    var columnGroup = columns[j].columnGroup ? columns[j].columnGroup - 1 : 0;
    // Append to new element
    rowItem.children('.column').children('.column-group').eq(columnGroup).append(blockItem);
  }
  return rowItem;
};

// CREATE PAGE FROM TEMPLATE
// z2hApp.createPresetPage(z2hApp.getTestPageData());
z2hApp.prototype.createPageFromTemplate = function (data) {
  const z2hTemplates = require('./templates');
  try {
    if (!data) {
      throw 'No row data was defined';
    }
    // Create page from data
    var pageItem = this.constructBlock(z2hTemplates.compileBlockAttribute(data));
    const rows = data.rows || [];
    // Create rows and append to page
    for (var i = 0; i < rows.length; i++) {
      if (!rows[i]) continue;

      pageItem.children('.section-pane-inner').children('.table-view').append(this.constructRow(rows[i]));
    }
    return pageItem;
  } catch (err) {
    this.onError('Error in createPageFromTemplate', err);
  }
};

// SINGLE BLOCK
// Construct a single template/block
z2hApp.prototype.constructBlock = function (block) {
  try {
    if (!block) {
      console.info('Empty block in template');
      return null;
    }
    // vars
    var template = block.template;
    var attributes = block.attributes;
    var item, attribute;
    // item element
    const templateElem = $('#' + template).prop('content');
    item = $(templateElem).children().first().clone();
    // item data
    for (var i in attributes) {
      attribute = attributes[i];
      // If there is no specific element to target, make the target the root element
      if (attribute.selector == '') {
        var element = item[0];
      } else {
        var element = item.find(attribute.selector)[0];
        if (!element) {
          continue;
        }
      }

      // Set the defined attribute
      if (attribute.type == 'innerText') {
        if (attribute.remove == false) {
          //
        } else {
          if (attribute.remove == true) {
            element.remove();
          } else {
            element.innerText = z2hTools.esc(attribute.content);
          }
        }
        continue;
      }
      if (attribute.type == 'innerHTML') {
        if (attribute.remove == false) {
          //
        } else {
          if (attribute.remove == true) {
            element.remove();
          } else {
            element.innerHTML = z2hTools.esc(attribute.content);
          }
        }
        continue;
      }
      if (attribute.type == 'src') {
        if (attribute.remove == true) {
          element.src = 'img/placeholder-image.jpg';
        } else {
          element.src = z2hTools.esc(attribute.content);
        }
        continue;
      }
      if (attribute.type == 'id') {
        if (attribute.remove == true) {
          //
        } else {
          element.id = z2hTools.esc(attribute.content);
        }
        continue;
      }
      if (attribute.type == 'class') {
        if (attribute.remove == true) {
          //
        } else if (attribute.content) {
          //If you pass in an array of classes it will add each of them
          //If you prefix a class with remove: it will remove that class (a little hacky, but it means not having to create/update every template)
          const isArray = typeof attribute.content === 'object' && attribute.content.length;

          if (isArray) {
            attribute.content.forEach((c) => {
              if (c.indexOf('remove:') === 0) {
                element.classList.remove(c.replace('remove:', ''));
              } else {
                element.classList.add(z2hTools.esc(c));
              }
            });
          } else {
            if (attribute.content.indexOf('remove:') === 0) {
              element.classList.remove(z2hTools.esc(attribute.content.replace('remove:', '')));
            } else {
              element.classList.add(z2hTools.esc(attribute.content));
            }
          }
        }
        continue;
      }
      if (attribute.type == 'class.remove') {
        if (attribute.remove == true) {
          //
        } else if (attribute.content) {
          typeof attribute.content === 'object'
            ? attribute.content.forEach((c) => {
                element.classList.remove(z2hTools.esc(c));
              })
            : element.classList.remove(z2hTools.esc(attribute.content));
        }
        continue;
      }

      if (attribute.type == 'value') {
        if (attribute.remove == true) {
          //
        } else {
          element.value = z2hTools.esc(attribute.content);
        }
        continue;
      }
      // If it isn't a specific case, set it as an attribute (e.g. data-name, placeholder, for, checked)
      if (attribute.remove == true) {
        //
      } else {
        element.setAttribute(
          z2hTools.esc(attribute.type),
          attribute.content == '' ? '' : z2hTools.esc(attribute.content),
        );
      }
      continue;
    }
    return item;
  } catch (err) {
    this.onError('Error in constructBlock', err);
  }
};

z2hApp.prototype.sortArrayOfObjects = function (data, sortByKey, reverse) {
  try {
    if (data == null || sortByKey == null) {
      throw 'No array data or key was defined';
    }
    data.sort(function (a, b) {
      var keyA = String(a[sortByKey]).toUpperCase(); // ignore upper and lowercase
      var keyB = String(b[sortByKey]).toUpperCase(); // ignore upper and lowercase
      if (reverse == null) {
        if (keyA < keyB) {
          return -1;
        }
        if (keyA > keyB) {
          return 1;
        }
      } else {
        if (keyA < keyB) {
          return 1;
        }
        if (keyA > keyB) {
          return -1;
        }
      }
      return 0; // keys must be equal
    });
  } catch (err) {
    this.onError('Error in sortArrayOfObjects', err);
  }
};

z2hApp.prototype.setAllRadioButtonsToCurrentTheme = function () {
  if (window.state.userData && window.state.userData.themeSettings) {
    const themeSettings = window.state.userData.themeSettings;
    $('.radio-button').removeClass('active');
    $('.radio-button[data-mode=' + themeSettings.mode + '][data-theme=' + themeSettings.colour + ']').addClass(
      'active',
    );
    //$('.radio-button[data-mode=' + themeSettings.mode + ']').addClass('active');
    this.updateThemeSelectionLabels();
  }
};

z2hApp.prototype.updateThemeSelectionLabels = function () {
  if (window.state.userData && window.state.userData.themeSettings) {
    const themeSettings = window.state.userData.themeSettings;
    const modeName = THEME_MODE_NAMES[this.getThemeIntValue(themeSettings.mode)];
    $('.radio-button[data-mode=' + themeSettings.mode + ']')
      .closest('.row')
      .find('.secondary-text-content')
      .text(modeName);
    const themeName = THEME_COLOUR_NAMES[this.getThemeColourIntValue(themeSettings.colour)];
    $('.radio-button[data-theme=' + themeSettings.colour + ']')
      .closest('.row')
      .find('.secondary-text-content')
      .text(themeName);
  }
};

let prevHtml = {};
z2hApp.prototype.initUpdatesPage = function (page, element = 'forghetti-updates-static', location = 'updates/html') {
  //if (page !== 'main-nav-banner' && page && page.attr('id') !== element) return;
  //this.reloadUpdateSections();
  // if (page !== 'main-nav-banner' && page && page.attr('id') !== element) return;
  // // Function to inject HTML into the iFrame
  // const updateIFrame = html => {
  //   const iFrameContainer = $(`#${element}`);
  //   const iFrame = iFrameContainer.find('iframe');
  //   iFrame.attr('src', 'data:text/html;charset=utf-8,' + encodeURIComponent(html));
  //   // Hack to allow iFrame to scroll on iOS devices
  //   setTimeout(_ => iFrameContainer.attr('style', ''), 100);
  //   setTimeout(_ => iFrameContainer.attr('style', '-webkit-overflow-scrolling: touch;'), 200);
  //   setTimeout(_ => iFrameContainer.attr('style', ''), 1000);
  //   setTimeout(_ => iFrameContainer.attr('style', '-webkit-overflow-scrolling: touch;'), 1100);
  // };
  // // Initially just display the previous updates HTML that we have, because
  // // it probably doesn't need to be refreshed
  // if (typeof prevHtml[element] === 'string') {
  //   //updateIFrame(prevHtml[element]);
  // }
  // // In the background, get fresh HTML and update the displayed content if it
  // // has changed.
  // {
  //   this.requestData('get', '', location).then(response => {
  //     const newHtml = response && response.data && response.data.html;
  //     if (!newHtml || prevHtml[element] === newHtml) return;
  //     // Push the HTML into the iFrame
  //     prevHtml[element] = newHtml;
  //     //updateIFrame(newHtml);
  //   });
  // }
};

/**
 * Handler for events coming from the Updates page, such as clicking on links
 * to take the user to other parts of the application.
 */
z2hApp.prototype.updatesInternalHandler = function (event) {
  if (!event || !event.data) return;

  const eventName = event.data.eventName;
  const params = event.data.data;

  if (params && params.url) {
    openUrl(params.url);
  } else if (eventName === 'invite-friend') {
    this.paneNavigation('inviteAFriend', '2', 1);
  } else if (eventName === 'update-profile-image') {
    this.viewProfile();
  } else if (eventName === 'download-multiple' || eventName === 'rate-forghetti') {
    openUrl(config.urls.DOWNLOADS);
  } else if (eventName === 'premium') {
    this.paneNavigation('viewYourSubscription', '2', 1);
  }
};

z2hApp.prototype.initTelephoneBlockByPage = function (page) {
  const intlTelInput = require('intl-tel-input');

  const telephoneElements = page.find('input[type=tel]');

  // Leave now if no telephone blocks
  if (!telephoneElements.length) return;

  telephoneElements.each((i, elem) => {
    const $elem = $(elem);
    const telephoneId = $elem.attr('id');
    const hasFocus = $elem.is(':focus');

    $elem.removeAttr('disabled');
    //the dropdown will only appear in the right position
    //if the plugin is loaded after the input element is rendered.

    setTimeout(() => {
      //if (window.intlTelInputGlobals.getInstance(document.querySelector('input[type=tel]'))) return;
      if (window.intlTelInputGlobals.getInstance(document.querySelector(`#${telephoneId}`))) return;

      window.iti = intlTelInput(document.querySelector(`#${telephoneId}`), {
        preferredCountries: ['gb', 'us'],
        separateDialCode: true,
        initialCountry: 'gb',
      });
      this.getCountryCode()
        .then((countryCode) => {
          window.iti.setCountry(countryCode);
        })
        .catch((err) => {
          console.info('Could not get country code for telephone number: ', err);
        })
        .then((_) => {
          // Restore focus
          if (hasFocus) elem.focus();
        });
    }, 1);
  });

  // Tweak HTML generated by intlTelInput
  // telephoneElements.wrap($('<label>'));
  // telephoneElements
  //   .closest('.intl-tel-input')
  //   .addClass('column-group')
  //   .children()
  //   .addClass('column');
};

z2hApp.prototype.initCountryInputsByPage = function (page) {
  const countryInputs = page.find('.country-input');
  countryInputs.each((i, e) => {
    //check if we should have a limited list of countries
    const limitLanguages = $(e).attr('data-languages');

    //console.log(e);
    // Grab current field value
    let value = $(e).val() || '';
    // Blank out the text field itself
    $(e).val('');
    // If server gives us country code 'uk', treat it as the ISO 3166-1 alpha-2 code 'gb'
    if (value === 'uk') {
      value = 'gb';
    }

    // Convert input field into country selector
    const options = { preferredCountries: [] };

    const { SUPPORTED_LANGUAGES } = require('../../config/app');
    if (limitLanguages) {
      options['onlyCountries'] = SUPPORTED_LANGUAGES;
      ///if (SUPPORTED_LANGUAGES.indexOf(value) < 0) {
      //  options['onlyCountries'].push(value);
      // }
    }
    const languages = require('../../config/app').LANGUAGES;

    $.fn.countrySelect.setCountryData(languages);
    $(e).countrySelect(options);
    // Set val
    //if (require('../../config/app').LANGUAGES.indexOf(value) >= 0) {
    const myLanguage = languages.find((l) => l.iso2 === value);

    myLanguage && $(e).countrySelect('selectCountry', value);
    //}
  });
};

z2hApp.prototype.initDoodlepadsByPage = function (page) {
  if (page.find('.doodlepad__svg').length) {
    if (window.currentDoodlepad) {
      window.currentDoodlepad.reset();
    }

    const doodlepad = new window.doodlepad(page.find('.doodlepad__svg'), {});
    window.currentDoodlepad = doodlepad;

    const secretDoodleByDefault = localStorage.getItem('userprefs-secretdoodle') === 'true' ? true : false;
    if (secretDoodleByDefault) {
      this.toggleSecretDoodlepad();
    }
  }
};

z2hApp.prototype.initPasswordsPageTimeout = function (page) {
  if (page.attr('id') === 'view-service' && !window.state.userData.activeOnboarding) {
    let timeoutInMS = (config.app.SERVICE_DETAILS_IDLE_TIMEOUT_SECONDS || 120) * 1000;

    const closePage = (_) => {
      if (!page.closest('body').length) {
        return;
      }
      //never close the page on the edit screen
      if (page.hasClass('edit')) {
        return;
      }

      console.log('Closing service details page');
      z2hApp.pageData = {};
      z2hApp.pageData.service = {};
      page.find('[data-template=back]').click();
    };

    let timeout;
    const resetTimeout = (_) => {
      console.log('Resetting timeout');
      clearTimeout(timeout);
      timeout = setTimeout(closePage, timeoutInMS);
    };

    $(page).on('mouseup', resetTimeout); // Reset the idle-timeout on mouse click
    $(page).one('touchend', resetTimeout); // Reset the idle-timeout on touch

    resetTimeout(); // Start it now
  }
};

z2hApp.prototype.initSuggestedPresetBlockByPage = function (page) {
  const suggPresetBlocks = page.find('.suggested-presets');

  if (suggPresetBlocks.length) {
    suggPresetBlocks.each((i, elem) => {
      if (!$(elem).attr('data-manual')) {
        const searchInputId = $(elem).attr('data-searchinputid');
        const searchInput = $(`#${searchInputId}`);
        const itemLimit = Number($(elem).attr('data-itemlimit')) || 3;
        const hideOnInit = $(elem).attr('data-hideOnInit') || false;
        SuggestedPresetsComponent.init(elem, searchInput, itemLimit, hideOnInit);
      }
    });
  }
};

z2hApp.prototype.initTooltips = function (page) {
  setTimeout((_) => {
    $(page)
      .find('[data-tooltip]')
      .each((i, elem) => {
        const tooltipContent = $(elem).attr('data-tooltip');
        this.displayTooltip($(elem), tooltipContent);
      });
  }, 800);
};

z2hApp.prototype.displayTooltip = function ($elem, content, otherParams) {
  // Check if tooltips have been disabled in user preferences
  if (localStorage.getItem('userprefs-tooltips') === 'false') {
    return;
  }
  $elem
    .tooltipster({
      content,
      theme: ['tooltipster-borderless', 'tooltipster-borderless-info'],
      trigger: 'custom',
      triggerClose: {
        click: true,
        scroll: true,
      },
      side: ['bottom', 'top'],
      ...otherParams,
    })
    .tooltipster('open');
};

z2hApp.prototype.toggleSecretDoodlepad = function (event) {
  // Get button from event or active pane
  if (window.currentDoodlepad) {
    const button = event ? $(event.currentTarget) : $('.section-pane.active .doodlepad-button-secret');
    window.currentDoodlepad.toggleLines();
    const doodlepadSvg = button.closest('.section-pane').find('.doodlepad svg');
    const linesHidden = doodlepadSvg.hasClass('lines-hidden');
    if (linesHidden) {
      button.text(strings.DOODLEPAD_SHOW());
    } else {
      button.text(strings.DOODLEPAD_SECRET());
    }
  }
};

z2hApp.prototype.clearCurrentDoodlepad = function () {
  if (window.currentDoodlepad) {
    window.currentDoodlepad.reset();
  }
};

z2hApp.prototype.biometricsTryAgain = function () {
  if (window.currentDoodlepad) {
    window.currentDoodlepad.reset();
  }
};
z2hApp.prototype.biometricsUseDoodle = function () {
  this.paneNavigation('viewService1_doodlepad', 2, 1, null, window.state.selectedServiceId);
};

z2hApp.prototype.initPremiumPage = function (page) {
  const $page = $(page);

  $page.on('click', '.premium__tab', (e) => {
    // Mark tab as active and other tabs as inactive
    window.state.premiumActiveTab = $(e.currentTarget).attr('data-id');

    $(e.currentTarget).addClass('premium__tab--active').siblings().removeClass('premium__tab--active');
    // Refresh displayed contents
    showActiveTab();
  });
};

function showActiveTab() {
  const tabs = $('.premium__tab').toArray();
  const tabContents = $('.premium__tab-content').toArray();

  tabContents.forEach((elem, i) => {
    const tab = $(tabs[i]);
    if (tab.hasClass('premium__tab--active')) {
      setTimeout((_) => $(elem).addClass('premium__tab-content--active'), 100);
    } else {
      $(elem).removeClass('premium__tab-content--active');
    }
  });
}

z2hApp.prototype.colourRadioButton = function (event) {
  //$('.radio-button').click(function(){
  var $this = $(event.currentTarget);
  var $radioButtons = $this.siblings('.radio-button');
  var $value = $this.attr('data-theme');
  var $mode = $this.attr('data-mode');
  $radioButtons.removeClass('active');
  $this.addClass('active');

  let { themeSettings } = window.state.userData;

  if (THEME_COLOURS.includes($value)) {
    themeSettings.colour = $value;
    // Remove current theme class from body
    THEME_COLOURS.forEach((colour) => $('body').removeClass('theme-colour-' + colour));
    $('body').addClass('theme-colour-' + $value);
  }

  if (THEME_MODES.includes($mode)) {
    themeSettings.mode = $mode;
    // Remove current theme class from body
    THEME_MODES.forEach((mode) => $('body').removeClass('theme-' + mode));
    $('body').addClass('theme-' + $mode);
    this.setStatusBar($mode);
  }

  this.updateThemeSelectionLabels();

  // Update user theme in local state and on server
  this.requestData('post', '', 'users/@me', {
    theme_settings: {
      mode: this.getThemeIntValue(themeSettings.mode),
      colour: this.getThemeColourIntValue(themeSettings.colour),
    },
  }).then((_) => {
    this.initUpdatesPage();
  });
};

// a u t h
// ACTIVATION BOXES (validate phone number)
// Authentication boxes
z2hApp.prototype.inputFieldFocus = function (event) {
  const $input = $(event.target);
  const $label = $($input[0].labels[0]);
  $label.addClass('focussed current-focus');

  const noBlur = $label.hasClass('no-blur');

  if (!noBlur) {
    $input.one('blur', (_) => {
      $label.removeClass('focussed current-focus');
    });
  }
};

z2hApp.prototype.activationBoxesKeydown = function (event) {
  let $input = $(event.target);
  let prevVal = $input.val();

  const value = event.key;
  const isNumberKey = /^([0-9])$/.test(event.key);

  // Important - We override all key presses in these fields
  event.preventDefault();

  const activationGroup = $input.closest('.activation-group');
  const index = activationGroup.find('input').toArray().indexOf(event.target);

  if (event.keyCode == 39) {
    // Right arrow key was pressed
    var $next = $input.parent().nextAll('label').eq(0),
      $nextInput = $next.find('input');
    if ($next.length) {
      $next.siblings().removeClass('current-focus');
      $nextInput.select().focus();
    } else {
      $input.blur();
    }
  } else if (event.keyCode === 37) {
    // Left arrow key was pressed
    var $previous = $input.parent().prevAll('label').eq(0),
      $previousInput = $previous.find('input');
    if ($previous.length) {
      $previous.siblings().removeClass('current-focus');
      $previousInput.select().focus();
    } else {
      $input.blur();
    }
  } else if (event.keyCode === 8) {
    if (prevVal) {
      $input.val('');
      event.preventDefault();
    } else {
      activationGroup
        .find('input')
        .eq(index - 1)
        .val('')
        .focus();
    }
  } else if (isNumberKey) {
    // Other key was pressed
    $input.val(value);
    event.preventDefault();

    var $next = $input.parent().nextAll('label').eq(0),
      $nextInput = $next.find('input');

    if ($next.length) {
      $next.siblings().removeClass('current-focus');
      $nextInput.focus().select();
    } else {
      $input.blur();
    }
  }
};

z2hApp.prototype.activationBoxesClick = function (event) {
  // $('.activation-input').click(function () {
  var $this = $(event.target);
  $this.select();
};

// GEOCODE
z2hApp.prototype.getCountryCode = function () {
  return Promise.resolve('GB');
};

// a c t i o n s
// CLICK

z2hApp.prototype.actionClickChange = function (element) {
  try {
    const $element = $(element);
    var $currentPane = $element.closest('.section-pane');

    // Declare variables
    var actionName = $element.attr('data-actionclick');

    // Firstly check if we have an action in the global 'actions' list
    const actions = require('../actions');
    if (actions[actionName]) {
      return (
        actions[actionName]({
          currentTarget: element,
          element: element,
          section: $currentPane,
          z2hApp: this,
        }) || Promise.resolve({})
      );
    }

    // =======================================================================

    console.warn(`Action ${actionName} does not exist in /actions directory.`);

    // Get input type
    if (element.tagName == 'INPUT') {
      if (element.type == 'checkbox') {
        // SLIDER
        // On first click, set the initial value to the opposite of the new (current) value
        if (!this.temporary.hasOwnProperty(actionName)) {
          this.temporary[actionName] = {
            initial_value: element.checked ? false : true,
          };
        }

        // Set the current value
        this.temporary[actionName].current_value = element.checked;

        // Check if the current value matches the initial value when checking if it is unsaved or not
        if (this.temporary[actionName].initial_value == this.temporary[actionName].current_value) {
          this.temporary[actionName].unsaved = false;
        } else {
          this.temporary[actionName].unsaved = true;
        }
      }
    }

    if (element.tagName == 'BUTTON') {
      if ($(element).hasClass('text-button')) {
        // TEXT BUTTON
        if (actionName == 'newServiceFieldLength') {
          if (!this.temporary.hasOwnProperty(actionName)) {
            this.temporary[actionName] = {
              initial_value: element.innerText,
            };
          }
          let details;
          startPrompt();
          function startPrompt() {
            var promptValue = prompt('Enter a custom amount');
            if (promptValue == null) {
              details = this.temporary.newServiceFieldLength.current_value;
            } else {
              if (!(1 <= Number(promptValue) && Number(promptValue) <= 64)) {
                alert('Must be a number between 1 and 64');
                startPrompt();
              } else {
                details = Number(promptValue);
              }
            }
          }
          //==========
          // Set temporary initial detail if it doesn't exist yet
          if (!this.temporary.newServiceFieldLength) {
            this.temporary.newServiceFieldLength = {
              initial_value: null,
            };
          }
          // Set temporary details
          this.temporary.newServiceFieldLength.current_value = details;
          if (
            this.temporary.newServiceFieldLength.current_value == this.temporary.newServiceFieldLength.initial_value
          ) {
            this.temporary.newServiceFieldLength.unsaved = false;
          } else {
            this.temporary.newServiceFieldLength.unsaved = true;
          }
          // Set "unsaved" data
          this.pageData.service.fieldsUnsaved[this.active.pageId].generation_settings.length =
            this.temporary.newServiceFieldLength.current_value;

          const fieldType = $(element)
            .closest('.section-pane-inner')
            .find('[data-context=newServiceType]')
            .attr('value');
          // If user changes length, but the user is creating a 'memorable words' type
          // of field, then we store the length as the number of words that the field
          // will contain.
          if (fieldType.includes('w')) {
            this.pageData.service.fieldsUnsaved[this.active.pageId].generation_settings.words =
              this.temporary.newServiceFieldLength.current_value;
          } else {
            this.pageData.service.fieldsUnsaved[this.active.pageId].generation_settings.words = 0;
          }

          //==========
          $('[data-actionclick=newServiceFieldLength]')[0].innerHTML = details;
          if (this.temporary.newServiceFieldLength.initial_value == details) {
            this.temporary.newServiceFieldLength.unsaved = false;
          } else {
            this.temporary.newServiceFieldLength.unsaved = true;
          }
        }

        if (actionName == 'newGroupMaxMembers') {
          // On first click, set the initial value to what's currently displayed
          if (!this.temporary.hasOwnProperty(actionName)) {
            this.temporary[actionName] = {
              initial_value: element.innerText,
            };
          }

          // Set the current value
          this.temporary[actionName].current_value = element.innerText;

          // Check if the current value matches the initial value when checking if it is unsaved or not
          if (this.temporary[actionName].initial_value == this.temporary[actionName].current_value) {
            this.temporary[actionName].unsaved = false;
          } else {
            this.temporary[actionName].unsaved = true;
          }
        }
      }
    }
  } catch (err) {
    this.onError('Error handling component click action', err);
  }

  // If we have not returned a promise by this point, return a self-resolving
  // promise now.
  return Promise.resolve();
};

// KEYUP
let lNewLoginAddPasswordTooltipTimeout = null;
z2hApp.prototype.actionKeyupChange = function (event) {
  try {
    // Declare variables
    var element = event.currentTarget;
    var actionName = element.getAttribute('data-actionkeyup') || element.getAttribute('data-actionchange');

    // Firstly check if we have an action in the global 'actions' list
    const actions = require('../actions');
    if (actions[actionName]) {
      return (
        actions[actionName]({
          currentTarget: element,
          element: element,
          z2hApp: this,
        }) || Promise.resolve({})
      );
      // Action should return a promise. If not, return an empty one.
    }

    // =============================================================================================
    // Below are all of the legacy action handlers which haven't been split out into separate
    // functions in the /actions/ directory
    // =============================================================================================

    // Get input type
    // Text input
    if (element.type == 'text') {
      if ($(element).hasClass('text-input')) {
        // TEXT INPUT
        // Generic tracking for input data

        // On first type, set the initial value to what's currently displayed
        if (!this.temporary.hasOwnProperty(actionName)) {
          this.temporary[actionName] = {
            initial_value: element.getAttribute('data-initial'),
          };
        }

        // On type, edit the unsaved version of page details

        // Set the current value
        this.temporary[actionName].current_value = element.value;

        // Check if the current value matches the initial value (or blank) when checking if it is unsaved or not
        if (
          this.temporary[actionName].initial_value === this.temporary[actionName].current_value ||
          this.temporary[actionName].current_value === ''
        ) {
          this.temporary[actionName].unsaved = false;
        } else {
          this.temporary[actionName].unsaved = true;
        }
      }

      // New Service
      // ===========

      // Initialise page data if necessary
      this.pageData = this.pageData || {};
      this.pageData.service = this.pageData.service || {};
      this.pageData.service.serviceData = this.pageData.service.serviceData || {};
      this.pageData.service.fieldsUnsaved = this.pageData.service.fieldsUnsaved || {};

      const currValue = (this.temporary[actionName] || {}).current_value;

      const removeError = (element) => {
        if ($(element).hasClass('tooltipstered')) {
          $(element).tooltipster('close');
          $(element).removeClass('field-in-error');
        }
      };

      if (actionName === 'newServiceName') {
        this.pageData.service.serviceData.name = currValue;
        if (currValue.length >= 2 && currValue.length <= 32) {
          removeError(element);
        }

        // If we are on the 'Add Login' page within the Create Account process, and the user
        // hasn't added a field yet, take this opportunity to display a tooltip directing them
        // towards the "Add a forghettible" button.
        if (
          $(element).closest('#create-account-add-new-custom-service').length &&
          $('[data-template="editServiceFieldDetails"]'.length === 0)
        ) {
          clearTimeout(lNewLoginAddPasswordTooltipTimeout);
          // We use a timeout to ensure we only display this tooltip when the user has finished
          // typing for a second.
          lNewLoginAddPasswordTooltipTimeout = setTimeout((_) => {
            $('[data-context="newServiceAddNewField"]')
              .tooltipster({
                content: config.strings.CREATE_ACCOUNT_CUSTOM_LOGIN_ADD_PASSWORD_TOOLTIP(),
                theme: ['tooltipster-borderless', 'tooltipster-borderless-info'],
                trigger: 'custom',
                triggerClose: {
                  click: true,
                  scroll: true,
                },
                side: ['bottom'],
              })
              .tooltipster('open');
          }, 1000);
        }
      }

      if (actionName === 'newServiceEmailOrUsername') {
        this.pageData.service.serviceData.username = currValue;
        if (currValue.length < 64) removeError(element);
      }
      if (actionName === 'newServiceEmailOrUsername2') {
        this.pageData.service.serviceData.email = currValue;
      }
      if (actionName === 'newServiceWebAddress') {
        this.pageData.service.serviceData.website = currValue;
        if (currValue.length < 64) removeError(element);
      }

      // New Service field name
      if (actionName === 'newServiceFieldName') {
        this.pageData.service.fieldsUnsaved[this.active.pageId].name = this.temporary[actionName].current_value;
        // $("#"+this.active.pageId).find(".primary-text-content")[0].innerText = this.pageData.service.fieldsUnsaved[this.active.pageId].name;
      }
    }

    // Telephone input (needs to get area code too);
    if (element.type == 'tel') {
      if ($(element).hasClass('text-input')) {
        // TEXT INPUT
        // On first click, set the initial value to what's currently displayed
        if (!this.temporary.hasOwnProperty(actionName)) {
          this.temporary[actionName] = {
            initial_value: element.placeholder,
          };
        }

        // Set the current value
        this.temporary[actionName].current_value = element.value;

        // Check if the current value matches the initial value (or blank) when checking if it is unsaved or not
        if (
          this.temporary[actionName].initial_value == this.temporary[actionName].current_value ||
          this.temporary[actionName].current_value == ''
        ) {
          this.temporary[actionName].unsaved = false;
        } else {
          this.temporary[actionName].unsaved = true;
        }
      }
    }
  } catch (err) {
    this.onError('Error handling component key-up', err);
  }
};

z2hApp.prototype.rememberGroup = function ({ templateId, refreshNav = false }) {
  const groupsVisited = window.state.groupsVisited || [];
  const currentGroup = window.state.selectedGroupId;
  let groupIndex;

  groupIndex = groupsVisited.findIndex((g) => g.template === templateId);

  if (groupIndex < 0) {
    groupsVisited.unshift({ template: templateId, group: currentGroup });
  } else {
    const groupChanged = groupsVisited[groupIndex].group !== currentGroup;
    const moveGroupToTop = groupsVisited.splice(groupIndex, 1);
    //The group has changed since we last visited this screen. So what do we do?
    //If we had a proper group Id, and we now have a new then we want to put the
    //the old group back
    if (groupChanged && currentGroup === 'new') {
      window.state.selectedGroupId = moveGroupToTop[0].group;
    } else {
      moveGroupToTop.group = currentGroup;
    }
    groupsVisited.unshift(moveGroupToTop[0]);
  }
  window.state.groupsVisited = [...groupsVisited];
};

z2hApp.prototype.getPreviousGroup = function (currentGroup) {
  return window.state.groupsVisited.find((g) => g.group !== 'new' && g.group !== currentGroup).group || '';
};
z2hApp.prototype.getGroupFromTemplate = function (template) {
  return window.state.groupsVisited.find((g) => g.template == template).group || '';
};
z2hApp.prototype.setStatusBar = function () {
  if (!window.cordova) return;
  setTimeout(() => {
    const displayingMain = $('.main').is(':visible');
    const displayingOnboarding = $('.overlay').is(':visible');
    const isDarkMode = $('body').hasClass('theme-dark');

    const dark = (_) => {
      window.StatusBar.overlaysWebView(false);
      window.StatusBar.styleLightContent();
      window.StatusBar.backgroundColorByHexString(THEME_DARK_RGB);
      window.NavigationBar.backgroundColorByHexString('#15151f');
    };
    const light = (_) => {
      window.StatusBar.overlaysWebView(false);
      window.StatusBar.styleDefault();
      window.StatusBar.backgroundColorByHexString(THEME_LIGHT_RGB);
      window.NavigationBar.backgroundColorByHexString('#505064');
    };

    if (displayingOnboarding) {
      dark();
    } else if (displayingMain) {
      isDarkMode ? dark() : light();
    }
  }, 300);
};
z2hApp.prototype.reloadUpdateSections = function () {
  if (window.state.lastUpdateSections >= Date.now() - 2000) return window.state.updateSectionsPromise;
  window.state.lastUpdateSections = Date.now();

  window.state.updateSectionsPromise = this.requestData('get', '', 'updates/sections').then((response) => {
    if (Math.floor(response.status / 100) === 2) {
      const sections = response.data;

      window.state.updateSections = Object.assign([], window.state.updateSections, sections);

      localStorage.setItem('updateSections', window.state.updateSections);
    }
    return window.state.updateSections;
  });
  return window.state.updateSectionsPromise;
};

// prettier-ignore
z2hApp.prototype.symbols = [
  '-',
  '!',
  '$',
  '^',
  '*',
  '_',
  '+',
  '[',
  ']',
  ,
  '.',
  ',',
  '%',
  '&',
  '~',
  '=',
  ':',
  ';',
  '?',
  '(',
  ')',
  '{',
  '}',
  '<',
  '>',
];
// prettier-ignore
z2hApp.prototype.simpleSymbols = ['-', '!', '$', '^', '*', '_', '+', '[', ']', '.'];

// =================================================================================================
// Shortcuts
// Primarily created for desktop app menu hooks
// =================================================================================================

z2hApp.prototype.newLogin = function () {
  if (fb.getCurrentUser()) {
    const actions = require('../actions');
    return actions['beginAddNewService']({ z2hApp: this });
    // this.paneNavigation('addNewServiceRedirect', '3', 0);
  }
  const { MUST_BE_LOGGED_IN_ACTION } = require('../../config/strings');
  showToastMessage(MUST_BE_LOGGED_IN_ACTION());
};

z2hApp.prototype.newGroup = function () {
  if (fb.getCurrentUser()) {
    return this.paneNavigation('addGroup', '2', 1);
  }
  const { MUST_BE_LOGGED_IN_ACTION } = require('../../config/strings');
  showToastMessage(MUST_BE_LOGGED_IN_ACTION());
};

z2hApp.prototype.viewProfile = function () {
  if (fb.getCurrentUser()) {
    return this.paneNavigation('viewProfile', '1', 0);
  }
  const { MUST_BE_LOGGED_IN_ACTION } = require('../../config/strings');
  showToastMessage(MUST_BE_LOGGED_IN_ACTION());
};

z2hApp.prototype.viewUpdates = function () {
  if (fb.getCurrentUser()) {
    return this.paneNavigation('notifications', '2', 0);
  }
  const { MUST_BE_LOGGED_IN_ACTION } = require('../../config/strings');
  showToastMessage(MUST_BE_LOGGED_IN_ACTION());
};

z2hApp.prototype.healthcheck = function () {
  if (fb.getCurrentUser()) {
    return this.paneNavigation('healthcheck_welcome', '1', 0);
  }
  const { MUST_BE_LOGGED_IN_ACTION } = require('../../config/strings');
  showToastMessage(MUST_BE_LOGGED_IN_ACTION());
};

z2hApp.prototype.logout = function () {
  if (fb.getCurrentUser()) {
    const actions = require('../actions');
    return actions['logout']({ z2hApp: this });
  }
  const { MUST_BE_LOGGED_IN_ACTION } = require('../../config/strings');
  showToastMessage(MUST_BE_LOGGED_IN_ACTION());
};

z2hApp.prototype.importServices = function () {
  if (fb.getCurrentUser()) {
    const actions = require('../actions');
    return actions['logout']({ z2hApp: this });
  }
  const { MUST_BE_LOGGED_IN_ACTION } = require('../../config/strings');
  showToastMessage(MUST_BE_LOGGED_IN_ACTION());
};

z2hApp.prototype.applyPartnerLicenceKey = function (data) {
  return this.requestData('post', '', `users/@me/apply-licence`, {
    deepLink: data.deepLink,
  });
};
z2hApp.prototype.applyPartnerSubscription = async function (partner) {
  await this.requestData('post', '', `users/@me/apply-partner-premium/${partner.name}`)
    .then((res) => {
      if (res.status === 200) {
        this.fetchFullUserDetails();
        //if (res.data.success && res.data.applied) {
        //Register a deeplink to open the partner subscriber
        localStorage.setItem('pendingEvent', 'partnerPremium');
        localStorage.setItem('pendingEventData', res.data.text.applied);
      }
      //}
    })
    .catch(() => {});
};

z2hApp.prototype.checkPartnerSubscription = async function () {
  // const res = await this.requestData('get', '', `launch/check-for-subscription-partners`);
  // if (res.data.success && res.data.applicable && res.data.subscribed && !res.data.applied) {
  //   this.applyPartnerSubscription(res.data.partner);
  // }
  //this.applyPartnerSubscription({ name: 'CSL' });
  return Promise.resolve();
};

z2hApp.prototype.initialisetData = function (id) {
  this.data = this.data || {};
  this.data[id] = this.data[id] || {};
};

z2hApp.prototype.getCurrentData = function (id) {
  return this.data[id];
};
z2hApp.prototype.clearData = function (id) {
  delete this.data[id];
};

z2hApp.prototype.initialiseServiceData = function (id, service) {};
z2hApp.prototype.setServiceData = function (id, data) {};
z2hApp.prototype.setHealthcheckData = function (id, breach) {
  this.initialisetData('healthcheck');
  if (!this.data.healthcheck.breach) this.data.healthcheck.breach = [];
  let b = this.data['healthcheck'].breach[id] || {};
  b = breach;
};
z2hApp.prototype.setCurrentBreachId = function (id) {
  this.initialisetData('healthcheck');
  this.data[healthcheck].currentBreachId = id;
};
z2hApp.prototype.currentBreachId = function () {
  this.initialisetData('healthcheck');
  this.data[healthcheck].currentBreachId = id;
};
z2hApp.prototype.currentBreach = function () {
  this.initialisetData('healthcheck');
  if (!this.data.healthcheck.breach) this.data.healthcheck.breach = [];
  return this.data['healthcheck'].breach[this.data['healthcheck'].currentBreachId];
};
z2hApp.prototype.setHealthcheckPasswordStrength = function (password) {
  this.initialisetData('healthcheck');
  if (!this.data.healthcheck.password) this.data.healthcheck.password = {};
  this.data[healthcheck].password = password;
};
z2hApp.prototype.setHealthcheckPasswordStrength = function () {
  this.initialisetData('healthcheck');
  if (!this.data.healthcheck.password) this.data.healthcheck.password = {};
  return this.data[healthcheck].password;
};

z2hApp.prototype.initialiseNewPage = function () {
  delete window.state.selectedServiceId;
  delete window.state.currentGroupId;
  delete window.state.currentGroup;
  delete window.state.currentServices;
  delete window.state.currentService;
};

z2hApp.prototype.initialisePageData = function () {
  this.pageData = {};
  this.pageData.service = {};

  this.pageData.service.serviceData = {};
  this.pageData.service.fieldsArray = [];
  this.pageData.service.fieldsSaved = {};
  this.pageData.service.fieldsUnsaved = {};
};

//User!!!
z2hApp.prototype.userId = function () {
  return window.state.userData.id;
};
z2hApp.prototype.userAvatar = function () {
  return window.state.userData.avatar;
};

//Groups!!
z2hApp.prototype.getGroupsList = async function (refreshFromServer) {
  if (!window.state.fullUserGroupsPromise || refreshFromServer) {
    this.reloadFullGroupsList();
  }

  if (localStorage.getItem('groupsList') !== null) {
    return new Promise((resolve) => {
      return resolve(JSON.parse(localStorage.getItem('groupsList')));
    });
  } else {
    return window.state.fullUserGroupsPromise;
  }
};
z2hApp.prototype.groupsListUpdates = async function (existingGroups) {
  //const existingGroups = await this.getGroupsList();
  if (window.state.fullUserGroupsPromise) {
    this.reloadFullGroupsList();
  }
  const groups = await window.state.fullUserGroupsPromise;

  //compare the two lists and return the differences
  const newGroups = groups.filter((g) => !existingGroups.find((eg) => eg.id === g.id));
  const deletedGroups = existingGroups.filter((eg) => !groups.find((g) => g.id === eg.id));
  const updatedGroups = groups.filter((g) => {
    const eg = existingGroups.find((eg) => eg.id === g.id);
    return eg && eg.name !== g.name;
  });
  return { newGroups, deletedGroups, updatedGroups };
};

z2hApp.prototype.personalGroup = async function () {
  const groups = await this.getGroupsList();
  return groups.find((g) => g.personal);
};

z2hApp.prototype.myGroups = async function () {
  //await this.reloadFullGroupsList();
  const groups = await this.getGroupsList();
  const userId = window.state.userData.id;
  return groups.filter((g) => userId === g.owner_id && !g.personal).sort((a, b) => a.name.localeCompare(b.name));
};
z2hApp.prototype.groupsOtherPeopleOwn = async function () {
  //await this.reloadFullGroupsList();
  const groups = await this.getGroupsList();
  const userId = window.state.userData.id;
  return groups.filter((g) => userId !== g.owner_id).sort((a, b) => a.name.localeCompare(b.name));
};

z2hApp.prototype.setCurrentGroup = function (groupId) {
  window.state.selectedGroupId = groupId;
  const currentGroup = window.state.groupsList.find((g) => g.id === groupId);
  window.state.currentGroup = currentGroup;
  window.state.selectedGroupId = groupId;
  return currentGroup;
};

z2hApp.prototype.currentGroup = function () {
  return window.state.currentGroup;
};
z2hApp.prototype.setPreviouslySelectedGroupId = function (groupId) {
  window.state.previouslySelectedGroupId = groupId;
};
z2hApp.prototype.updateGroup = function (groupId, updateFields) {
  window.state.fullUserGroupsPromise = null;
  localStorage.removeItem('groupsList');
  let group = window.state.groupsList.find((g) => g.id === groupId);
  group = { ...group, ...updateFields };
  window.state.groupsList.sort((a, b) => a.name.localeCompare(b.name));
  // Update groupslist in cache
  const launchData = window.state.launchData || {};
  const launchGroupsList = launchData.groups_list || [];
  const launchGroup = launchGroupsList.find((g) => g.id === groupId);
  if (launchGroup) {
    launchGroup.name = group.name;
    localStorage.setItem('launch', JSON.stringify(launchData));
  }
};
z2hApp.prototype.removeGroup = async function (groupId) {
  window.state.fullUserGroupsPromise = null;
  localStorage.removeItem('groupsList');
  window.state.groupsList = window.state.groupsList.filter((g) => g.id !== groupId);
  const launchData = window.state.launchData || {};
  const launchGroupsList = launchData.groups_list || [];
  const launchGroup = launchGroupsList.filter((g) => g.id !== groupId);
  if (launchGroup) {
    localStorage.setItem('launch', JSON.stringify(launchData));
  }
  z2hApp.prototype.setCurrentGroup(await z2hApp.prototype.personalGroup().id);
};

z2hApp.prototype.isPageActive = function (pageId) {
  const id = $('.section-pane.active').find('.section-pane.active').attr('id');
  return !id || id === pageId || id.indexOf('loading') > -1;
};

//Group invite
z2hApp.prototype.setCurrentInviteId = function (inviteId) {
  window.state.selectedInviteId = inviteId;
  if (!window.state.currentGroup?.invites) {
    window.state.currentGroup.invites = {};
  }
  const currentInvite = window.state.currentGroup.invites[inviteId];
  window.state.currentInvite = currentInvite;

  return currentInvite;
};
z2hApp.prototype.addInviteToCurrentGroup = function (invite, groupId) {
  window.state.selectedGroupId = groupId || window.state.selectedGroupId;
  const group = window.state.groupsList.find((g) => g.id === groupId);
  group.invites = group?.invites || {};
  group.invites[invite.id] = invite;
  window.state.currentGroup = group;
};
z2hApp.prototype.setGroupInviteParameters = function (expiry, maxUses) {
  const _expiry = config.app.DEFAULT_GRP_LINK_EXPIRY_MINS;
  const _maxUses = config.app.DEFAULT_GRP_LINK_MAX_USES;

  window.state.groupInviteMaxUses = maxUses ? maxUses : _maxUses;
  window.state.groupInviteLinkExpiry = expiry ? expiry : _expiry;
  return {
    maxUses: window.state.groupInviteMaxUses,
    expiry: window.state.groupInviteLinkExpiry,
  };
};
z2hApp.prototype.groupInviteParameters = function (groupId, email) {
  return {
    maxUses: window.state.groupInviteMaxUses,
    expiry: window.state.groupInviteLinkExpiry,
  };
};

z2hApp.prototype.setCurrentService = function (serviceId, _groupId) {
  let groupId = _groupId;
  if (!groupId) {
    groupId = window.state.selectedGroupId;
  }
  window.state.selectedServiceId = serviceId;
  window.state.selectedGroupId = groupId;
  const currentGroup = window.state.groupsList.find((g) => g.id === groupId);
  const currentService = currentGroup.services.find((s) => s.id === serviceId);
  window.state.currentGroup = currentGroup;
  window.state.currentServices = currentGroup.services;
  window.state.currentService = currentService;
};

z2hApp.prototype.updateCurrentService = function (serviceId, groupId, updateFields) {
  this.setCurrentService(serviceId, groupId);
  window.state.currentService = { ...window.state.currentService, ...updateFields };
};

z2hApp.prototype.getNotifications = function () {
  window.state.animations = window.state.animations || {};

  const frontendNotifications = () => {
    //

    const notifications = [];

    //subscription end date check.
    if (window.state.launchData?.subscribed) {
      const nowTime = new Date().getTime();
      const fiveDaysAgo = nowTime - 5 * 24 * 60 * 60 * 1000;
      const twoDaysAgo = nowTime - 2 * 24 * 60 * 60 * 1000;

      const oneDayAgo = nowTime - 2 * 24 * 60 * 60 * 1000;

      if (window.state.launchData.subscription_end_date < oneDayAgo) {
        notifications.push({
          id: 'subscription_ends',
          icon: 'alarm',
          text: 'SUBSCRIPTION_ENDS_TODAY',
          fieldData: {
            date: new Date(window.state.launchData.subscription_end_date),
          },
          read: false,
          priority: 3,
          textData: 0,
        });
      }
      if (window.state.launchData.subscription_end_date < twoDaysAgo) {
        notifications.push({
          id: 'subscription_ends',
          icon: 'alarm',
          text: 'SUBSCRIPTION_ENDS_TOMORROW',
          fieldData: {
            date: new Date(window.state.launchData.subscription_end_date),
          },
          read: false,
          priority: 3,
          textData: 2,
        });
      } else if (window.state.launchData.subscription_end_date < fiveDaysAgo) {
        notifications.push({
          id: 'subscription_ends',
          icon: 'alarm',
          text: 'SUBSCRIPTION_ENDS',
          fieldData: {
            date: new Date(window.state.launchData.subscription_end_date),
          },
          read: false,
          priority: 3,
          textData: 2,
        });
      }
    } else {
    }
    return Promise.resolve(notifications);
  };

  function backendNotifications() {
    if (window.state.notifications) {
      for (const notification of window.state.notifications) {
        if (notification.animation && notification.animation !== '') {
          let animLocale = null;

          if (typeof notification.animation === 'object' && !notification.animation.speed) {
            //if we have a locale for the animation, use that

            if (notification.animation['any']) {
              animLocale = notification.animation['any'];
            }
            if (notification.animation[localStorage.getItem('language')]) {
              animLocale = notification.animation[localStorage.getItem('language')];
            }
            window.state.animations[animLocale] = {
              id: notification.animation,
              path: animations[animLocale] ? animations[animLocale] : animLocale,
              loop: true,
              autoplay: true,
              speed: 1,
            };
            notification.animation = animLocale;
          }
        }
        if (notification.banner && notification.banner !== '') {
          let bannerLocale = null;
          //if we have a locale for the animation, use that

          if (typeof notification.banner === 'object') {
            if (notification.banner['any']) {
              bannerLocale = notification.banner['any'];
            }

            if (notification.banner[localStorage.getItem('language')]) {
              bannerLocale = notification.banner[localStorage.getItem('language')];
            }
          } else {
            bannerLocale = notification.banner;
          }

          notification.banner = bannerLocale;
        }

        if (notification.text && notification.text !== '') {
          let textLocale = null;
          //if we have a locale for the animation, use that

          if (typeof notification.text === 'object') {
            if (notification.text['any']) {
              textLocale = notification.text['any'];
            }

            if (notification.text[localStorage.getItem('language')]) {
              textLocale = notification.text[localStorage.getItem('language')];
            }
          } else {
            textLocale = notification.text;
          }
          notification.text = textLocale;
        }
      }
    }
    return Promise.resolve(window.state.notifications || []);
  }

  const fenPromise = frontendNotifications();
  const benPromise = backendNotifications();

  const orderedNotifications = (fen, ben) => {
    let notifications = [];
    if (fen) {
      notifications = [...notifications, ...fen];
    }
    if (ben) {
      notifications = [...notifications, ...ben];
    }

    notifications.sort((a, b) => {
      if (a.priority < b.priority) {
        return -1;
      }
      if (a.priority > b.priority) {
        return 1;
      }
      if (a.sequence_number < b.sequence_number) {
        return -1;
      }
      if (a.sequence_number > b.sequence_number) {
        return 1;
      }
      return 0;
    });

    return notifications;
  };

  return Promise.all([fenPromise, benPromise]).then((values) => {
    return { notifications: orderedNotifications(values[0], values[1]) };
  });
};
// =================================================================================================
// I N I T
window.z2hApp = new z2hApp();
module.exports = window.z2hApp;
