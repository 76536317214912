const config = require('../../../config');

module.exports = ({ section }) => {
  const referralCode = window.state.launchData.home.referral_id;
  const profileName = window.state.userData.name;
  const referralUrl = config.urls.REFERRAL_LINK(referralCode, profileName || window.state.userData.id);

  const emailSubject = encodeURIComponent(config.strings.REFERRAL_EMAIL_SUBJECT());
  const emailBody = encodeURIComponent(config.strings.REFERRAL_EMAIL_BODY()(referralUrl) + '\n\n\n');

  window.location.href = `mailto:?subject=${emailSubject}&body=${emailBody}`;
};
