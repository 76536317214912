const config = require('../../../config');
const fb = require('../../app/firebaseUtils');

const { strings } = config;

module.exports = ({}) => {
  // Set focus on the activate code
  setTimeout((_) => $('#block-activation-code-1').focus(), 1000);
  const phoneNumber = window.state.phoneNumber || fb.getCurrentUser().phoneNumber;

  return {
    template: 'block-section-page',
    fields: {
      id: 'delete-account-confirmation-code',
      header: config.strings.DELETE_ACCOUNT_HEADER(),

      navigation_left: config.strings.CANCEL(),
      navigation_left_data: 0,
      navigation_left_template: 'back',
    },
    rows: [
      {
        template: 'block-section-row',
        fields: { primary_text: '' },
        columns: [
          {
            template: 'block-info-text',
            fields: {
              text: strings.DELETE_ACCOUNT_CONFIRM_MESSAGE()(phoneNumber),
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: { primary_text: '' },
        columns: [
          {
            template: 'block-activation-code',
            fields: {
              data_name: 'delete-account-confirmation-code',
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          class: 'centered-text',
        },
        columns: [
          {
            template: 'block-text-button',
            fields: {
              text: strings.DELETE_ACCOUNT_CONFIRM_BUTTON(),
              class: 'cta-button',
              action: 'deleteAccount',
              navigation_template: '',
              navigation_validation: 'validateActivationCode',
            },
          },
        ],
      },
    ],
  };
};
