function convertBlobToBase64(blob) {
	return new Promise(resolve => {
		var reader = new FileReader();
		reader.onloadend = function() {
			const base64data = reader.result;
			resolve(base64data);
		};
		reader.readAsDataURL(blob);
	});
}
module.exports = convertBlobToBase64