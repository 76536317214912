const strings = require('../../../config').strings;

module.exports = ({ z2hApp }) => {
  if (!confirm(strings.HIDE_QUICK_SELECT_CONFIRM())) {
    return;
  }

  localStorage.setItem('userprefs-quickselect', false);

  const paneSelector = '#pane-2';
  z2hApp.paneNavigation(
    'addNewService',
    $(paneSelector),
    $(paneSelector + '-inner')
      .children('.active')
      .index() + 1,
  );
};
