module.exports = ({ currentTarget }) => {
  const selectedGroup = $(currentTarget).attr('data-id')
  window.state.selectedGroupId = selectedGroup;
  // Refresh group details
  const pane = '#pane-2';
  const activePaneIndex = $(pane + '-inner')
    .children('.active')
    .index();
  z2hApp.paneNavigation('viewGroupDetails', $(pane), activePaneIndex);

};
