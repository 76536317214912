const generateNewServiceIcon = require('../../actions/services/generateNewServiceIcon');
const parseUrl = require('../../action_helpers/parseUrl');

function getSubdomain(hostname) {
  var regexParse = new RegExp('[a-z-0-9]{2,63}.[a-z.]{2,5}$');
  var urlParts = regexParse.exec(hostname) || ['Unknown'];
  return hostname.replace(urlParts[0], '').slice(0, -1);
}

module.exports = ({}) => {
  const groupsList = window.state.groupsList;
  const personalGroupId = groupsList.find(g => g.personal).id;
  // TODO: The group to add the new login to should default to the last group that was
  // selected when adding a new login via the extension (i.e. it should be saved in
  // localStorage ). This relies on having the option to change the group which the
  // new login will be created in.
  window.state.selectedGroupId = personalGroupId;
  const selectedGroup = groupsList.find(g => g.id === window.state.selectedGroupId);

  const currentTabUrl = window.state.currentTabUrl;
  const parsedUrl = parseUrl(currentTabUrl);
  const website = parsedUrl.hostname;
  const subdomain = getSubdomain(website);

  // If website hostname = www.britishairways.com, we want to use
  // britishairways.com as the service name, so remove the subdomain.
  const serviceName =
    subdomain.length > 0 ? parsedUrl.hostname.substr(subdomain.length + 1) : parsedUrl.hostname || 'Unknown';

  // generateNewServiceIcon will store a new icon in window.state.temporaryServiceIcon
  return generateNewServiceIcon({ serviceName }).then(_ => selectedGroup);
};
