module.exports = async ({ z2hApp, currentTarget }) => {
  const z2hTemplates = require('../../app/templates');

  const row = $(currentTarget).closest('.row ');



    $(row).find('label').addClass('focussed current-focus');
    $(row).find('input').focus();

    $(row).find('[data-actionclick="focusInputRow"]').hide()

  
};
