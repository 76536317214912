module.exports = ({ z2hApp, menuTarget }) => {
  const sectionPaneWrapper = $(menuTarget).closest('.section-pane-wrapper');
  const parent = sectionPaneWrapper.parent();

  window.state.searchingForGroupIcon = false;
  window.state.searchingForServiceIcon = true;

  // The action to perform whenever an icon in the search results is clicked
  window.state.webIconSearchAction = 'chooseServiceIcon';

  z2hApp.paneNavigation('searchForServiceIcon', parent, sectionPaneWrapper.children('.active').index() + 1);
};
