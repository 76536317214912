const config = require('../../../config');
const strings = config.strings;

const { infoTextRow, primaryText } = require('../../page_helpers/components');

module.exports = ({ nav, data, dataId, noHeading, confirmAction }) => {
  console.log('DATA: ', dataId);
  const group = window.state.groupsList.find((g) => g.id === dataId);

  const groupIcon = group.icon || config.app.DEFAULT_GROUP_ICON;

  const groupName = group.name || '';

  const memberType = group.personal ? 2 : (group.users[window.state.userData.id] || {}).member_type;

  z2hApp.setCurrentGroup(dataId)
  window.currentGroup = z2hApp.setCurrentGroup(dataId);
  const doodle = data.biometrics;

  const doodleToMap = [];

  let currentArr = [];

  for (let i = 0; i < doodle.length; i++) {
    const char = doodle[i];

    if (char === '0') {
      //If we've got something in our array, push it to the main array
      if (currentArr.length >= 1) {
        doodleToMap.push(currentArr);
        currentArr = [];
      }
    } else {
      currentArr.push(parseInt(char));
    }
  }

  if (currentArr.length >= 1) {
    doodleToMap.push(currentArr);
  }
  try {
    setTimeout(() => {
      window.currentDoodlepad.play(doodleToMap);
    }, 1000);
  } catch (e) {}

  // =================================================================================================

  return {
    template: 'block-section-page',
    fields: {
      id: 'doodle-pad',
      active: false,
      header: strings.REPLAY_DOODLE_HEADING(),

      navigation_left: config.strings.BACK(),
      navigation_left_icon: '',
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'back',

      //navigation_left_pane: 1,

      //if we can edit the service lets do that.
 

      navbar: false,
    },
    rows: [
      {
        template: 'block-section-row',
        fields: { class: ['no-border', 'flex-grow'] },
        columns: [{ template: 'block-doodlepad', fields: {
          disabled: true,
        } }],
      },

      {
        template: 'block-doodlepad-confirm-only',
        fields: {
          confirm_action: 'replayDoodleDrawing',
          icon: 'reset',
        },
      },
    ],
  };
};
