const DialogMgr = require('dialog-manager');

function showAlertMessage(msg, html = false, title) {
  if (html) {
    DialogMgr.showMsg({ msgHtml: msg, duration: 3000, titleText: title });
  } else {
    DialogMgr.showMsg({ msgText: msg, duration: 3000, titleText: title });
  }
}

module.exports = showAlertMessage;
