const _ = require('lodash');
module.exports = async ({ z2hApp, currentTarget }) => {
  const row = $(currentTarget).closest('.row ');
  const newName = row.find('[name=renameForghettible]').val();

  const fieldId = row.attr('id');

  z2hApp.pageData.service.fields[fieldId].name = newName;
  z2hApp.pageData.service.fieldsSaved[fieldId] = _.cloneDeep(z2hApp.pageData.service.fields[fieldId]);
  const fieldData = _.cloneDeep(z2hApp.pageData.service.fields[fieldId]);

  if (fieldData) {
    fieldData.name = newName;

    delete fieldData.access_key;
    delete fieldData.old_access_key;
    delete fieldData.recovery_timestamps;
  }

  return z2hApp.requestData('post', '', 'fields/' + fieldId, fieldData).then((response) => {
    const paneDetails = window.getActivePaneDetails('#pane-2');

    const service = z2hApp.pageData.service;
    window.serviceFieldPromises[service.id] = null;

    // If response has an error, throw it, to be caught by a future .catch block
    if (Math.floor(response.status / 100) !== 2) {
      throw new Error(
        response.user_message ||
          response.developer_message ||
          response.message ||
          `An error occurred! Sorry about that.`,
      );
    }

    if (z2hApp.pageData.saveRenameForghettible) {
      return z2hApp.pageData.saveRenameForghettible(fieldId);
    }
    if (z2hApp.pageData.service.resetFromContext) {
      const { biometricsOrDoodle } = require('../../actions/services');
      biometricsOrDoodle({
        currentTarget: null,
        z2hApp,
        pane,
        newPaneIndex: paneDetails.activePaneIndex + 1,
        template: { biometrics: 'viewService1_biometrics', doodlepad: 'viewService1_doodlepad' },
      });
    } else {
      z2hApp.paneNavigation('editServiceDetails', paneDetails.pane, paneDetails.activePaneIndex - 1, null);
    }

    return response;
  });
};
