module.exports = ({ currentTarget, init = null }) => {
  //Initialisation
  if (init) {
    if (!init.words) {
      $('#wordCountSelectorWrapper').hide();
      $('#wordsCustomLengthWrapper').hide();
      $('#wordsCustomLength').val(init.words);
      return;
    }
    const tabs = $('#wordCountSelector').children();

    let selected = false;
    tabs.each((idx, elem) => {
      const elemVal = $(elem).attr('data-value');
      if (elemVal === 'CUSTOM') {
        if (!selected) {
          $(elem).addClass('active');
          $(elem).attr('data-selected', true);

          $('#wordsCustomLengthWrapper').show();
          $('#wordsCustomLength').val(init.words > 0 ? init.words : 1);
          
        }
        return;
      }
      const select = init.words === parseInt(elemVal);

      if (select) {
        $(elem).addClass('active');
        $(elem).attr('data-selected', true);
        $('#wordsCustomLength').val(init.words);
        selected = true;
      } else {
        $(elem).removeClass('active');
        $(elem).attr('data-selected', false);
      }
    });
    return;
  }

  //Tab click
  const DOMTarget = $(currentTarget);

  const tabs = $('#wordCountSelector').children();

  tabs.each((idx, elem) => {
    $(elem).removeClass('active');
    $(elem).attr('data-selected', false);
  });

  DOMTarget.attr('data-selected', true);
  DOMTarget.addClass('active');

  if (DOMTarget.attr('data-value') === 'CUSTOM') {
    $('#wordsCustomLengthWrapper').show();
  } else {
    $('#wordsCustomLengthWrapper').hide();

    $('#wordsCustomLength').val(Number(DOMTarget.attr('data-value')));
    const fieldData = z2hApp.pageData.service.fieldsUnsaved[z2hApp.active.pageId];

    // eslint-disable-next-line camelcase
    const { generation_settings } = fieldData;
    // eslint-disable-next-line camelcase
    generation_settings.words = Number(DOMTarget.attr('data-value'));
  }
};
