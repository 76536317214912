const { thirdheading } = require('../../page_helpers/components');
const strings = require('../../../config').strings;

const onboadingTemplate = require('./onboarding_template');

const template = ({
  imagePath = null,
  primary_heading,
  center = null,
  button = null,
  button_nav = null,
  secondary_heading,
  text,
  chapter = null,
  nextLink,
  nextPage,
  nav,
}) => ({
  rows: [
    imagePath
      ? {
          template: 'block-onboarding-image',
          fields: { imagePath },
        }
      : {},

    chapter ? thirdheading({ text: chapter || '' }) : {},
    {
      template: 'block-section-row',
      fields: {
        class: ['no-border', 'grow'],
        class2: center ? '' : 'align-left',
        class3: 'onboarding-text',
        column_class: 'justify-center',
        primary_heading_html: primary_heading,
      },
      columns: [
        text && {
          template: 'block-info-text',
          fields: { text },
        },
      ],
    },

    button
      ? {
          template: 'block-section-row',
          fields: {
            class: 'no-border',
          },
          columns: [
            {
              template: 'block-text-button',
              fields: {
                text: button.toUpperCase(),
                ...button_nav,
              },
            },
          ],
        }
      : {},
  ],
});

module.exports = ({ nav }) => {
  const reonboarding = window.state.userData.reonboarding;

  const templates = [];
  let t = {
    imagePath: 'img/onboarding/waving350.gif',
    primary_heading: strings.ONBOARDING2_WELCOME_HEADING(),
    text: strings.ONBOARDING2_WELCOME_TEXT(),
  };
  templates.push(t);
  t = {
    imagePath: 'img/onboarding/doodle350-once.gif',
    chapter: strings.ONBOARDIN2_HOWITWORKS(),
    primary_heading: strings.ONBOARDING2_DOODLE_HEADING(),
    text: strings.ONBOARDING2_DOODLE_TEXT(),
  };
  templates.push(t);
  t = {
    primary_heading: strings.ONBOARDING_DOODLETUTORIAL1_HEADING(),
    center: true,
    button: strings.CREATE_ACCOUNT_FINAL_BUTTON(),
    button_nav: { navigation_template: 'onboarding_stage2', navigation_data: nav + 1 },
  };
  templates.push(t);

  //Generate templates for each page and push them to
  //window.state.carousel for later generation
  const pages = [];
  templates.forEach((t) => {
    pages.push(template(t));
  });

  const carousel = window.state.carousel || {};
  const id = { template: 'onboarding_stage1' };

  id.carousel = `${id.template}_carousel`;
  id.arrows = `${id.template}_arrows`;
  id.navbar = `${id.template}_navbar`;

  $('body').on('click', `#onboarding_stage1 .onboarding-close`, () => {
    $(`[data-id="${id.carousel}"]`).slick('slickGoTo', templates.length - 1);
  });

  $('body').on('beforeChange', `[data-id="${id.carousel}"]`, function (event, slick, currentSlide, nextSlide) {
    const toLastSlide = nextSlide === slick.slideCount - 1;
    if (toLastSlide) {
      const $nextPageButton = $(`[data-id="${id.carousel}"] .slick-slide`)
        .eq(nextSlide)
        .find('.cta-button:not([data-nav])');
      $nextPageButton
        .attr('data-nav', 3)
        .attr('data-template', 'onboarding_doodletutorial1')
        .attr('tabindex', '-1')
        .html(strings.START());
    }
    $('#onboarding_stage1 .onboarding-close').hide();
  });
  $('body').on('afterChange', `[data-id="${id.carousel}"]`, function (event, slick, currentSlide) {
    console.log('currentSlide', currentSlide, slick.slideCount);
    if (currentSlide !== slick.slideCount - 1) {
      $('#onboarding_stage1 .onboarding-close').fadeIn();
    }
  });
  //We build our page with a carousel (components/carousel.js)
  //and after it's loaded the content will be insterted
  //by 'compiling the blocks as above'.
  carousel[id.carousel] = [...pages];
  window.state.carousel = carousel;
  return {
    template: 'block-section-onboarding',
    fields: {
      id: id.template,
      class: 'carousel',
      navbar: false,

      //navigation_right: reonboarding && strings.CLOSE,
      //navigation_right_action: reonboarding && 'hideLogin',
    },
    rows: [
      {
        template: 'block-onboarding-nav-right',
        fields: {
          primary_text: strings.SKIP().toUpperCase(),
        },
      },

      {
        template: 'block-insertable',
        fields: {
          id: id.arrows,
          class: 'slick-arrows',
        },
      },
      {
        template: 'block-carousel',
        fields: {
          id: id.carousel,
          navbar: false,
          //the data-slick attribute allows us to pass any options the slick carousel can define
          slick: JSON.stringify({
            dots: true,
            infinite: false,
            mobileFirst: false,
            dotsClass: 'slick-custom__dots',
            easing: 'ease-in',
            arrows: true,
            touchThreshold: 15, //To advance slides, the user must swipe a length of (1/touchThreshold) * the width of the slider
            waitForAnimate: true,
            prevArrow: `<button class="slick-arrow slick-arrow-prev"><i class="f-icn f-icn-chevron-left"></i></button>`,
            nextArrow: `<button class="slick-arrow slick-arrow-next"><i class="f-icn f-icn-chevron-right"></i></button>`,
            appendArrows: `#${id.arrows}`,
            appendDots: `#${id.navbar}`,
          }),
        },
      },
      {
        template: 'block-insertable',
        fields: {
          id: id.navbar,
          class: 'slick-nav-bar',
        },
      },
    ],
  };
};
