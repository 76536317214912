
const { strings, urls } = require('../../../config');
const { infoTextRow } = require('../../page_helpers/components');


//If this is not the electron app then we need to tell them to go and 
//and download the app on their PC/MAC
module.exports = ({ nav }) => {
  return {
    template: 'block-section-page',
    fields: {
      id: 'importMoveServicesWelcome',
      active: false,
      header: strings.IMPORT_MOVE_SERVICE_HEADER(),
      navbar: false,
      navigation_right: strings.CANCEL(),
      navigation_right_icon: '',
      navigation_right_data: 0,
      navigation_right_template: 'back',
    },
    rows: [

      infoTextRow(strings.IMPORT_MOVE_SERVICE_NOTE_1()(window.state.selectedImportedServices.length), false, false),
      {
        template: 'block-section-row',
        fields: {
          class: 'centered-text',
        },
        columns: [
          {
            template: 'block-text-button',
            fields: {
              text: strings.IMPORT_MOVE_SERVICE_BUTTON(),
              navigation_template: 'importMoveServicesWizard',
              navigation_data: nav + 1,
              //navigation_data: nav + 1,
              class: 'cta-button',
            }
          }
        ]
      }
    ],
  };
};
