const { strings } = require('../../../config');
const lochyLoadingTemplate = require('./lochyLoadingTemplate');

module.exports = () => ({
  ...lochyLoadingTemplate({
    id: 'creatingGroup_loading',
    heading: strings.CREATING_SERVICES(),
    lochy: 'confetti',
  }),
});
