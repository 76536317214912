const uploadAndCropImage = require('../miscellaneous/uploadAndCropImage');
const updateGroupIcon = require('../../action_helpers/updateGroupIcon');
const justCropImage = require('../../actions/miscellaneous/justCropImage');

module.exports = async ({}) => {
  //The file uploader doesn't play nice with cordova IOS, the (non-removable) camera option
  //crashes the app, so we need to use the camera plugin to get the pictures from
  //the photo library using the camera plugin.
  const cordovaCamera = window.cordova && window.navigator.camera;

  //Promisify the camera callback
  const getUploadPromise = () => {
    return new Promise((resolve, reject) => {
      const cordovaCamera = window.cordova && window.navigator.camera;
      !cordovaCamera && resolve();

      const options = {
        quality: 100,
        destinationType: Camera.DestinationType.DATA_URL,
        sourceType: Camera.PictureSourceType.PHOTOLIBRARY,
        encodingType: Camera.EncodingType.JPEG,
        mediaType: Camera.MediaType.PICTURE,
        correctOrientation: true,
        targetHeight: 250,
        targetWidth: 250,
      };

      const onSuccess = imageURI => {
        console.log('success');
        resolve(imageURI);
      };
      const onError = error => {
        console.log('failed');
        console.log(error);
        reject(error);
      };
      navigator.camera.getPicture(onSuccess, onError, options);
    });
  };

  const uploadImage = ({ base64Image, doneCallback }) => {
    if (window.state.selectedGroupId === 'new') {
      window.state.temporaryGroupIcon = base64Image;

      $(`img[data-id="newGroup"`).attr('src', base64Image);
      $(`div[data-id="newGroup"] img`).attr('src', base64Image);
      if (doneCallback) doneCallback();
    } else {
      updateGroupIcon(base64Image).then(_ => {
        // Call the callback we were given (to close the upload tool)
        if (doneCallback) doneCallback();
      });
    }
  };

  const base64Img = await getUploadPromise();
  const img = 'data:image/png;base64,' + base64Img;
  //Just crop image is just a version of uploadAndCropImage with the file uploader references removed.
  //TODO: Remove redundancy in these scripts.
  const cropper = cordovaCamera ? await justCropImage({ img }) : await uploadAndCropImage();
  uploadImage(cropper);
};
