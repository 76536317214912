const config = require('../../../config');
const { infoTextRow, ctaButton } = require('../../page_helpers/components');

const { strings } = config;
const { BACK, PREMIUM_HEADER } = strings;

module.exports = ({ nav }) => {
  setTimeout(() => {
    const { refreshUI } = require('../../app/iap');
    refreshUI();
  }, 100);

  //Set the active tab to the second pane...
  setTimeout(() => {
    const tabs = $('#premium').find('.premium__tab');
    if (tabs.length >= 3) {
      tabs.eq(1).click();
    } else {
      tabs.eq(0).click();
    }
  }, 300);
  return {
    template: 'block-section-page',
    fields: {
      id: 'premium',
      header: PREMIUM_HEADER(),

      navigation_left: BACK(),
      navigation_left_icon: '',
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'back',
      navbar: false,
    },
    rows:
      //window.cordova && window.store
      true
        ? [
          {
            template: 'block-section-premium',
            fields: {
              class: 'no-border',
            },
          },
        ]
        : [
          {
            template: 'block-section-row',
            fields: {},
            columns: [
              {
                template: 'block-info-text',
                fields: {
                  text: 'Download the app to go premium',
                },
              },
            ],
          },
        ],
  };
};
