const config = require('../../../config');
const strings = config.strings;
const { SERVIVCE_NAME_MAXLENGTH } = require('../../../config/app');
const { subheading } = require('../../page_helpers/components');

module.exports = ({ id, nav, data }) => {
  const z2hTemplates = require('../../app/templates');

  var fieldNameUid = window.z2hTools.getUid();
  let genSettings = z2hApp.pageData.service.fieldsSaved[data.fieldId].generation_settings || {};

  let letterNumberSymbolCustomLength = genSettings.length ? genSettings.length : 0;
  //Show the letters/numbers/symbols counter by default
  let letterNumberSymbolCustomLengthShown = true;
  let wordCustomLength = genSettings.words ? genSettings.words : 0;
  //Hide the word counter by default
  let wordCustomLengthShown = false;

  // Hide delete button if this is the only field in this service
  let showDelete = true;
  let existingField = false;
  if (z2hApp.pageData.service.origFieldsArray && z2hApp.pageData.service.origFieldsArray.includes(data.fieldId)) {
    existingField = true;
  }

  const navRight = (contextMenu) => {
    return contextMenu
      ? {
          navigation_right: 'Save',
          // navigation_right_icon: 'tick',
          navigation_right_template: 'updatingForghettible_loading',
          navigation_right_prevent_warning: true,
          navigation_right_preserve_temporary: true,
          navigation_right_data: Math.max(nav + 1, 0),
          navigation_right_validation: 'editServiceField',
          navigation_right_action: 'saveFieldChanges',
        }
      : {
          navigation_right: strings.SAVE(),
          // navigation_right_icon: 'tick',
          //navigation_right_template: 'back',
          //navigation_right_prevent_warning: true,
          navigation_right_preserve_temporary: true,
          //navigation_right_data: Math.max(nav - 1, 0),
          navigation_right_validation: 'editServiceField',
          navigation_right_action: 'saveFieldChanges',
        };
  };

  //Special characters table
  const specialCharacterTable = (mode) => {
    const table = z2hApp.constructBlock(
      z2hTemplates.compileBlockAttribute({
        template: 'block-special-character-select-table',
      }),
    );

    const specialChars = () => {
      //TODO get these symbols from the server!!
      //This is the preferred order to match the grid
      const simpleSymbols = z2hApp.simpleSymbols;
      //TODO get this from server!!
      //This is the preferred order to make the grid look as neat as possible
      const allSymbolsFlat = z2hApp.symbols;
      //Add selected flag to the symbols (set default to simple, which will get overridden if complex)
      const allSymbolsObj = allSymbolsFlat.map((aSF) => ({
        symbol: aSF,
        selected: simpleSymbols.includes(aSF),
      }));
      return allSymbolsObj;
    };

    const chars = specialChars();
    //Create the HTML for the symbol block
    chars.forEach((cell) => {
      const c = $(
        z2hApp.constructBlock(
          z2hTemplates.compileBlockAttribute({
            template: 'block-special-character-select-cell',
            fields: {
              action: 'selectSymbol',
              primary_text: cell.symbol,
              selected: cell.selected,
            },
          }),
        ),
      );
      table.append(c);
    });

    //Just chuck our table in a div so we can .html() it and send it to our insertable
    const wrapper = $('<div>');
    wrapper.append(table);
    return wrapper;
  };

  const page = {
    template: 'block-section-page',
    fields: {
      id: 'edit-service-field-details',
      active: false,
      header: strings.FIELD_DETAILS_HEADER(),
      navbar: false,
      navigation_left_template: 'back',
      navigation_left: strings.BACK(),
      // navigation_left_prevent_warning: true,
      // navigation_left_preserve_temporary: true,
      navigation_left_data: Math.max(nav - 1, 0),
      // navigation_left_validation: 'editServiceField',
      // navigation_left_action: 'saveFieldChanges',

      //two possibilities here, either we're coming from the edit screen, or we're coming
      //from the reset forghettible context menu.
      //If edit screen we want to just go back to the edit screen, if from the context menu we want to do
      //an update and go forward to the doodle screen

      ...navRight(z2hApp.pageData.service.resetFromContext),
    },
    rows: [
      // Field name ================================================================================
      {
        template: 'block-section-row',
        fields: { secondary_heading: config.strings.FIELD_NAME() },
        columns: [
          {
            template: 'block-editable-primary-text',
            fields: {
              primary_text: data.name ? data.name : strings.SERVICE_DFT_FORGHETTIBLE_NAME(),
              placeholder: config.strings.FIELD_NAME_PLACEHOLDER(),
              action: 'newServiceFieldName',
              for: fieldNameUid,
              id: fieldNameUid,
              maxlength: SERVIVCE_NAME_MAXLENGTH,
            },
          },
        ],
      },
      subheading({
        text: strings.FIELD_OPTIONS_HEADING(),
        additionalClass: 'space-above',
      }),
      {
        template: 'block-section-row',
        fields: {
          id: 'wordSelector',
        },
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_html: strings['WORDS']() },
          },
          {
            template: 'block-toggle-switch',
            fields: {
              value: 'WORDS',
              data_id: 'WORDS',
              checked: true,
              action: 'selectLetterNumberSymbolWord',
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          id: 'wordsCustomLengthWrapper',
          class: 'transition-collapsible-row',
          class2: wordCustomLengthShown ? '' : 'collapsed',
        },
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_text: config.strings.SELECT_PASSWORD_WORDS() },
          },
          {
            template: 'block-editable-primary-text',
            fields: {
              class: 'align-right',
              class2: 'small-input',
              primary_text: wordCustomLength,
              action: 'selectWordCustomLength',
              name: 'wordsCustomLength',
              for: 'wordsCustomLength',
              id: 'wordsCustomLength',
              type: 'number'
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          id: 'letterSelector',
        },
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_html: strings['LETTERS']() },
          },
          {
            template: 'block-toggle-switch',
            fields: {
              value: 'LETTERS',
              data_id: 'LETTERS',
              checked: true,
              action: 'selectLetterNumberSymbolWord',
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          id: 'numberSelector',
        },
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_html: strings['NUMBERS']() },
          },
          {
            template: 'block-toggle-switch',
            fields: {
              value: 'NUMBERS',
              data_id: 'NUMBERS',
              checked: true,
              action: 'selectLetterNumberSymbolWord',
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          id: 'symbolSelector',
          class: 'no-border',
        },
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_html: strings['SYMBOLS']() },
          },
          {
            template: 'block-icn-button',
            fields: {
              icon: 'chevron-down',
              action: 'showHideContent',
              data_id: 'symbolsWrapper',
            },
          },
          {
            template: 'block-toggle-switch',
            fields: {
              value: 'SYMBOLS',
              data_id: 'SYMBOLS',
              checked: true,
              action: 'selectLetterNumberSymbolWord',
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          id: 'symbolsWrapper',
          class: 'transition-collapsible-row',
          class2: 'collapsed',
          secondary_heading: config.strings.SELECT_SPECIAL_CHARACTERS(),
        },
        columns: [
          {
            template: 'block-insertable',
            fields: {
              class: 'column',
              html: specialCharacterTable().html(),
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          id: 'letterNumberSymbolCustomLengthWrapper',
          class: 'transition-collapsible-row',
          class2: letterNumberSymbolCustomLengthShown ? '' : 'collapsed',
        },
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_text: config.strings.SELECT_PASSWORD_NUMBERS() },
          },
          {
            template: 'block-editable-primary-text',
            fields: {
              class: 'align-right',
              class2: 'small-input',
              primary_text: letterNumberSymbolCustomLength,
              action: 'selectLetterNumberSymbolCustomLength',
              name: 'letterNumberSymbolCustomLength',
              for: 'letterNumberSymbolCustomLength',
              id: 'letterNumberSymbolCustomLength',
              type: 'number'
            },
          },
        ],
      },
    ],
  };

  // Field options heading =========================================================================
  // if (existingField || showDelete) {
  //   page.rows.push({
  //     template: 'block-section-row',
  //     fields: {
  //       secondary_heading: strings.FIELD_OPTIONS_HEADING(),
  //     },
  //   });
  // }

  // If this is an existing field, we can show the option to restore the field
  // to a different version
  if (existingField) {
    page.rows.push({
      template: 'block-section-row',
      fields: {
        class: 'cta',
        action: 'markFieldToBeReset',
        navigation_data_id: data.fieldId,
      },
      columns: [
        { template: 'block-icn-button', fields: { icon: 'reset' } },
        { template: 'block-text-button', fields: { text: config.strings.RESET_FIELD() } },
      ],
    });

    data.recovery_timestamps &&
      page.rows.push({
        template: 'block-section-row',
        fields: {
          class: 'cta',
          navigation_data: nav + 1,
          navigation_data_id: data.fieldId,
          navigation_template: 'restorePassword',
        },
        columns: [
          { template: 'block-icn-button', fields: { icon: 'restore' } },
          { template: 'block-text-button', fields: { text: config.strings.RESTORE_FIELD() } },
        ],
      });
  }

  if (showDelete) {
    page.rows.push({
      template: 'block-section-row',
      fields: {
        class: 'cta',
        class2: 'red',
        action: 'deleteField',
      },
      columns: [
        { template: 'block-icn-button', fields: { icon: 'delete' } },
        { template: 'block-text-button', fields: { text: config.strings.DELETE_FIELD() } },
      ],
    });
  }

  //Tick over to render DOM then call the actions to set the initial state
  setTimeout(() => {
    const actions = require('../../actions');
    actions.selectLetterNumberSymbolWord({ currentTarget: null, init: genSettings });
    actions.selectSymbol({ currentTarget: null, init: genSettings });
  }, 1);
  return page;
};
