const $ = require('jquery');
const z2hApp = require('../../app/z2hApp');
const { serviceField } = require('../../page_helpers/components');
const { strings } = require('../../../config');

module.exports = function usePasswordInVerify({ currentTarget }) {

  const copyIconLabel = strings.SERVICE_COPY_ICON_LABEL();
  const viewIconLabel = strings.SERVICE_VIEW_ICON_LABEL();
  const injectIconLabel = strings.SERVICE_INJECT_LABEL();


  const service = window.state.currentService || {};
  const fields = service.fields || {};
  const fieldId = $(currentTarget).closest('.row').attr('id');
  if (!fieldId) return;

  const fieldData = fields[fieldId] || {};
  const password = fieldData.access_key;
  if (!password || !window.parent) return;

  const veriftData = JSON.parse(localStorage.getItem('verifyRequest'));
  z2hApp.requestData('post', '', `partner-access/push-to-socket`, {
    id: veriftData.id,
    password: fieldData.access_key,
    userServiceId: service.id,
    userGroupId: window.state.selectedGroupId,
    serviceName: service.name,
    username: service.username,
  });
  localStorage.removeItem('verifyRequest');
  window.serviceFieldPromises[service.id] = null;
  const serviceData = window.z2hApp.pageData.service;

  const field = window.z2hApp.pageData.service.fields[fieldId];

  for (let fieldId in window.z2hApp.pageData.service.fields) {
    //If we're moving a service we need to show the old passwords...
    $(`#view-service #${fieldId}`).fadeOut('slow', () => {
      $(`#view-service #${fieldId}`).replaceWith(
        z2hApp.constructRow(
          serviceField(
            field.name,
            field.access_key,
            field.id,
            { copyIconLabel, viewIconLabel, injectIconLabel },
            {
              moving: false,
            
            },
            field.access_key !== field.old_access_key ? field.old_access_key : null,
          ),
        ),
      );

      //if (window.state.movingService) delete window.state.movingService;
      //if (window.z2hApp.pageData.service.movingService) delete window.z2hApp.pageData.service.movingService;
      $(`#${fieldId}`).hide().fadeIn(1000);
    });
  }
};
