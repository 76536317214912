const config = require('../../../config');
const { infoTextRow, thirdheading } = require('../../page_helpers/components');
const actions = require('../../actions');
const { USERNAME_NAME_MAXLENGTH } = require('../../../config/app');
const strings = require('../../../config').strings;

module.exports = ({ nav, data }) => {
  // Focus on input field after 1 second
  setTimeout((_) => $('#addService_username-onboarding .service-username').focus(), 1000);

  const serviceName = window.z2hApp.pageData.service.serviceData.name;

  const reonboarding = window.state.userData.reonboarding;

  return {
    template: 'block-section-page',
    fields: {
      id: 'addService_username-onboarding',
      class: 'no-title',
      class2: 'onboarding',
    },
    rows: [
      reonboarding && {
        template: 'block-onboarding-close',
      },
      {
        template: 'block-section-row',
        fields: {
          primary_heading_html: strings.ADD_SERVICE_USERNAME_ONBOARD()(serviceName),
          class: 'no-border',
        },
        columns: [
          {
            template: 'block-input-box',
            fields: {
              id: 'add-service-username-input',
              value: '',
              placeholder: strings.ADD_SERVICE_USERNAME_PLACEHOLDER(),
              maxlength: USERNAME_NAME_MAXLENGTH,
            },
          },
        ],
      },

      {
        template: 'block-section-row',
        fields: {
          class: 'no-border',
        },
        columns: [
          {
            template: 'block-icn-button',
            fields: {
              icon: 'check-solid',
              class: 'active',
              class: 'icn-button-lrg',
              action: 'addService_submitUsername',
              data_id: 'addService_username-onboarding',
              navigation_validation: 'addService_username_onboard',
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {},
        columns: [
          {
            template: 'block-text-button',
            fields: {
              navigation_template: 'addService_doodle',
              navigation_data: nav + 1,
              text: strings.SKIP(),
            },
          },
        ],
      },

      /*thirdheading({ text: ADD_SERVICE_SUGGESTED_USERNAMES }),
      {
        template: 'block-suggested-usernames',
        fields: {
          action: 'addService_selectUsername', // When a username is clicked
        },
      },*/
    ],
  };
};

$('body').on('keyup', '#addService_username-onboarding #add-service-username-input', (e) => {
  if (e.key === 'Enter') {
    $('#add-service-username-input [data-actionclick=addService_submitUsername]').click();
  }

  // const tickIcon = document.querySelector('[data-actionclick="addService_submitUsername"');
  // e.target.value === "" ?
  //   tickIcon.classList.remove('active') :
  //   tickIcon.classList.add('active');
});
