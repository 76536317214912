const strings = require('../../../config').strings;

// =============================================================================

function restoreField(fieldId, timestamp, z2hApp) {
  return request('post', 'fields/' + fieldId + '/revert-timestamp', { timestamp: parseInt(timestamp) }, z2hApp);
}

// =============================================================================

function request(method, endpoint, data, z2hApp) {
  return z2hApp.requestData(method, '', endpoint, data).then((response) => {
    // If response has an error, throw it, to be caught by a future .catch block
    if (Math.floor(response.status / 100) !== 2) {
      throw new Error(
        response.user_message || response.developer_message || response.message || strings.MSG_FALLBACK_ERROR_MESSAGE(),
      );
    }
  });
}

// =============================================================================

module.exports = ({ currentTarget, z2hApp }) => {
  const conf = confirm(strings.MSG_CONFIRM_RESTORE());
  if (!conf) return;

  const fieldId = window.state.currentFieldId;
  const timestamp = $(currentTarget).attr('data-id');

  restoreField(fieldId, timestamp, z2hApp)
    .catch((err) => {
      console.error(err);
      showToastMessage(err.message);
    })
    .then((_) => {
      // Go back to doodlepad
      const service = z2hApp.pageData.service;
      window.serviceFieldPromises[service.id] = null;
      if (z2hApp.pageData.postUpdateAction) {
        return z2hApp.pageData.postUpdateAction(fieldId);
      }
      const { biometricsOrDoodle } = require('../../actions/services');
      biometricsOrDoodle({
        currentTarget: null,
        z2hApp,

        template: { biometrics: 'viewService1_biometrics', doodlepad: 'viewService1_doodlepad' },
      });
    });
};
