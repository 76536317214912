const domtoimage = require('dom-to-image');

const config = require('../../../config');

module.exports = ({ serviceName, z2hApp }) => {
  if (window.state.temporaryServiceIconChosen) return;

  const _serviceName = serviceName || z2hApp.pageData.service.serviceData.name;
  const initial = _serviceName.toUpperCase().substr(0, 1);

  // TODO put in config rather than here
  const colours = config.app.BRAND_COLOURS;

  const randColour = colours[Math.floor(Math.random() * colours.length)];
  const styling = `
		background-color: ${randColour}; 
		
		color: #505064;
		font-size: 60px;
		text-align: center;
		line-height: 150px;

		min-height: 150px; 
		max-height: 150px; 
		min-width: 150px;
		max-width: 150px; 
		font-family: 'Muli',
		
		border-radius: 50%; 
		position:absolute; 
		z-index: -1000;
		left: 0px; 
		top: 0px;

	`;
  const circle = $(`<div style="${styling}"/>`)
    .text(initial)
    .appendTo('body');

  // Convert the selected image to a 150x150 PNG and update the group icon with it
  return domtoimage
    .toPng(circle[0], { height: 150, width: 150 })
    .then(dataUrl => {
      window.state.temporaryServiceIcon = dataUrl;
      // Update any images on screen which have a data-id which matches the group ID
      $(`img[data-id=temporaryServiceIcon]`).attr('src', dataUrl);
      $(`img[id=temporaryServiceIcon]`).attr('src', dataUrl);
    })
    .catch(error => console.error('oops, something went wrong!', error))
    .then(_ => {
      // // Go back to previous pane
      // $(pane).removeClass('busy');
      // const activePaneIndex = $(pane+'-inner').children('.active').index();
      // z2hApp.paneNavigation('back', $(pane), activePaneIndex - 1);
    });
};
