const { CSL_SUBSCRIPTION_HEADER } = require('../strings.base');

const LINK_EXPIRY_VALUES = {
  15: '15分鐘',
  30: '30分鐘',
  60: '1小時',
  360: '6小時',
  720: '12小時',
  1440: '1天',
  '-1': '永不',
};

const LINK_MAX_USE_VALUES = {
  1: '1次',
  2: '2次',
  5: '5次',
  10: '10次',
  20: '20次',
  50: '50次',
  '-1': '沒有限制',
};

module.exports = {
  YOU: '您',
  BACK: '返回',
  NEXT: '下一個',
  CONTINUE: '繼續',
  SAVE: '儲存',
  ADD: '新增',
  SKIP: '略過',
  CANCEL: '取消',
  PREVIOUS: '前一個',
  EDIT: '編輯',
  NONE: '沒有',
  START: '開始',
  SEARCH: '搜索',
  CLOSE: '關閉',
  SEE_DETAILS: '詳情',
  UNAVAILABLE: '不可用',
  DONE: '完成',
  WATCH_AGAIN: '再次觀看',
  NEW: '新的',

  UNAVAILABLE_FORGHETTIBLES_MESSAGE:
    '因為您已經超過了免費額度，有部分forghettibles暫不可用。請註冊成為高級用戶，享受無限量的forghettibles！',

  FORGHETTI_TAGLINE: '不用再記住你的密碼',
  CREATE_ACCOUNT: '創建一個新帳戶',
  HAVE_ACCOUNT: '我已經有一個帳戶',

  COMBOBULATING: '... 正在產生您的forghettibles 密碼 ...',

  CREATE_ACCOUNT_1_HEADER: '輸入號碼',
  CREATE_ACCOUNT_1_HEADING: '驗證您的裝置',
  CREATE_ACCOUNT_1_MSG: '請輸入您的手機號碼，通過短信接收<strong>一次性驗證碼</strong>。',
  CREATE_ACCOUNT_1_PLACEHOLDER: '您的號碼',
  CREATE_ACCOUNT_1_MSG_2: '請更改您的國家代碼(如適用)，並輸入您的手機號碼。 \n 比如 07795123456。',
  CREATE_ACCOUNT_1_MSG_3: '驗證短信只會在驗證您的裝置和必要的應用程式更新時才會發送。',

  ERROR_TEL_NUM_EMPTY: '必須提供手機號碼',

  MAIN_NAV_GROUP: '群組',
  MAIN_NAV_PROFILE: '個人資料',
  MAIN_NAV_HEALTHCHECK: '安全檢查',
  MAIN_NAV_IMPORT: '匯入服務',
  MAIN_NAV_LOGOUT: '登出',
  NAVBAR_JOIN_GROUP: '加入群组',
  NAVBAR_NEW_GROUP: '新增群组',
  NAVBAR_NEW_PASSWORD: '新的密碼',
  NAVBAR_NEW_PIN: '新的PIN',
  NAVBAR_NEW_WORD: '新的提示詞',
  NAVBAR_HOME: '首頁',
  NAVBAR_GROUPS: '群组',
  NAVBAR_HEALTHCHECK: '安全檢查',
  NAVBAR_PROFILE: '個人資料',

  /* Lochy loading - paneBusyText.js */
  LOCHY_LOADING_TEXT_DEFAULT: '載入中...',
  LOCHY_LOADING_TEXT_GROUP: (group) => `為<em> ${group}</em>加載服務`,
  LOCHY_LOADING_TEXT_GROUPSLIST: '加載群组',
  LOCHY_LOADING_TEXT_HEALTHCHECK: '加載密碼安全檢測',
  LOCHY_LOADING_TEXT_CHOOSE_SEARCH_ICON: '搜索網絡的服務圖標',
  LOCHY_LOADING_TEXT_CHOOSE_CHOOSE_GROUP_ICON: '上傳群組圖標',
  LOCHY_LOADING_TEXT_CREATE_GROUP_INVITE: '創建群組邀請',
  LOCHY_LOADING_TEXT_EDIT_SERVICE: '編輯服務細節',
  LOCHY_LOADING_TEXT_UPDATE_SERVICE: '儲存服務細節',
  LOCHY_LOADING_TEXT_PROFILE: '加載個人資料',
  LOCHY_LOADING_TEXT_SUBSCRIPTION: '加載您已訂閱的資料',

  /*                                 */

  CREATE_ACCOUNT_2_HEADER: '輸入代碼',
  CREATE_ACCOUNT_2_HEADING: '輸入您的驗證碼',
  CREATE_ACCOUNT_2_MSG: (telNumber) => `請輸入發送至${telNumber}的驗證碼。`,
  CREATE_ACCOUNT_2_LINK_BACK: '我還沒有收到代碼',
  CREATE_ACCOUNT_2_MSG_2: (termsofserviceurl) =>
    `通過輸入驗證碼，即表示您同意我們的<a href="${termsofserviceurl}" target="_new">服務條款</a>。`,

  ERROR_TEL_NUM_INVALID: '請輸入有效的電話號碼',
  ERROR_ACT_CODE_EMPTY: '請輸入已發送給您的激活碼',
  ERROR_ACT_CODE_INVALID: '請輸入一個有效的激活碼',
  ERROR_ACT_CODE_INVALID: '激活碼不正確。請再試一次',

  CREATE_ACCOUNT_VERIFYING: '檢查驗證碼',
  CREATE_ACCOUNT_VERIFYING_PHONE: '驗證您的手機號碼',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_1: '未曾儲存的密碼',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_2: '您只需要記住您的塗鴉',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_3: '您可以在所有的裝置上使用forghetti',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_4: '升級後能夠在群組內安全地共享密碼',

  CREATE_ACCOUNT_REFERRAL_HEADER: '推薦代碼',
  CREATE_ACCOUNT_REFERRAL_HEADING: '輸入推薦代碼',
  CREATE_ACCOUNT_REFERRAL_PLACEHOLDER: '推薦代碼',
  REFERRAL_ENTRY_MESSAGE_1: `您是否有朋友的推薦代碼？如果是，請在此輸入。否則，您可以跳過這一步。`,

  CREATE_ACCOUNT_3_HEADER: '編輯個人資料',
  CREATE_ACCOUNT_3_HEADING: '編輯您的個人資料',
  CREATE_ACCOUNT_3_TEXT: `您可以在此處為您的個人資料設定名稱和圖標。 如果您決定共享訪問權限或被邀請加入其他用戶的群組，這將有助於識別您的身份。`,
  CREATE_ACCOUNT_3_LABEL: '您的名字',
  CREATE_ACCOUNT_3_TEXT_2: `如果您希望稍後才設定名字和照片，您可以跳過這一步。`,

  CREATE_ACCOUNT_ADD_LOGIN_MSG: `從這些熱門的應用程式中選擇要更新的服務`,

  CREATE_ACCOUNT_FINAL_HEADER: '完成！',
  CREATE_ACCOUNT_FINAL_MSG: `您的forghetti已可使用！`,
  CREATE_ACCOUNT_FINAL_MSG_2: `你將會為你的帳戶提供安全保護和不用為記住密碼而煩惱！`,
  CREATE_ACCOUNT_FINAL_MSG_3: `現在，我們將引導您通過必要的步驟去更新您現有的密碼成為forghettible。`,
  CREATE_ACCOUNT_FINAL_BUTTON: '讓我知怎樣做！',
  CREATE_ACCOUNT_FINAL_LINK: `我已經掌握forghetti的使用方法`,

  CREATE_ACCOUNT_ADD_CUSTOM_LOGIN: `或者是其他...`,
  CREATE_ACCOUNT_SERVICE_NAME_TOOLTIP: `要添加不同的服務，您至少需要提供服務名稱。\n\n電郵/用戶名和網址可不需輸入，但我們建議您也添加這些呢！`,
  CREATE_ACCOUNT_CUSTOM_LOGIN_ADD_PASSWORD_TOOLTIP: `您將需要添加至少一種類型的密碼。 \n\n 因為一些服務需要特定類型的密碼，您有幾種不同的密碼選擇。`,
  CREATE_ACCOUNT_CUSTOM_LOGIN_NEXTPAGE_TOOLTIP: `若滿意，請按“下一步”繼續`,
  CREATE_ACCOUNT_NEW_LOGIN_DOODLEPAD_MSG: `您的塗鴉將被用於找回您需要的密碼，所以盡情發揮吧！`,
  CREATE_ACCOUNT_ADD_ANOTHER_LOGIN_TOOLTIP: `要添加其他服務到您的帳戶，請點擊這裡再次進入新的登錄界面。`,

  ADD_SERVICE_SEARCH_HEADER: `添加新的服務`,
  ADD_SERVICE_SEARCH: `從這些熱門的應用程序選擇一個服務或開始輸入其他名稱`,
  ADD_SERVICE_SUGGESTED: `推薦的服務`,
  ADD_SERVICE_CREATE: (name) => `創建 “${name}”`,
  ADD_SERVICE_SELECT_ICON_HEADER: `請選擇一個圖標`,
  ADD_SERVICE_SELECT_ICON: (serviceName) => `請為${serviceName}選擇一個圖標`,
  ADD_SERVICE_SELECT_GROUP_HEADER: `請選擇群組`,

  ADD_SERVICE_YOUR_GROUP: (serviceName, groupName) => `<em> ${serviceName} </em>將被添加到 <em>${groupName} </em>`,
  ADD_SERVICE_SELECT_GROUP: (serviceName) => `請選擇要添加<br /> <em> ${serviceName} </em>到那一個群組`,
  ADD_SERVICE_SELECT_GROUP_SELECT: (serviceName) => `或者選擇一個不同的群組以添加<br /> <em> ${serviceName} </em>`,
  MOVE_SERVICE_SELECT_GROUP: (serviceName) => `請選擇要移動<br /> <em> ${serviceName} </em>到那一個群組`,
  MOVE_SERVICE_LOADING: '移動您的服務排位',
  MOVE_SERVICE_CONFIRM_HEADER: '移動您的服務排位',
  MOVE_SERVICE_CONFIRM_NOTE: (
    service,
    fromGroup,
    toGroup,
    numFields,
  ) => `這個服務<em> ${service}</em>將會從<em> ${fromGroup} </em>群組移到<em> ${toGroup} </em>群組。
<br/>此服務有<em>${numFields}</em> forghettible${numFields > 1 ? 's' : ''}。`,
  MOVE_SERVICE_CONFIRM_NOTE_UPDATE: (
    fromGroup,
    toGroup,
    list,
    listLength,
  ) => `您在<em>${toGroup}</em>中畫新的塗鴉將生成一個新的<em>${list}</em> forghettible${
    listLength > 1 ? 's' : ''
  } 。如果您想取回舊的forghettible ${listLength > 1 ? 's' : ''}, 您只需要從<em>${fromGroup}</em>畫您原來的塗鴉便可。
`,
  MOVE_SERVICE_CONFIRM: '確認移動',
  ADD_SERVICE_SELECT_GROUP_ONBOARD: (serviceName) => `添加您的<em>${serviceName}</em>到一個群組`,
  ADD_SERVICE_USERNAME: (serviceName) => `為<em> ${serviceName}</ em>輸入用戶名，或從以下列表中選擇。`,
  ADD_SERVICE_USERNAME_ONBOARD: (serviceName) => `請輸入您在<em> ${serviceName}</em>中使用的電郵地址或用戶名`,
  ADD_SERVICE_USERNAME_HEADER: `選擇用戶名`,
  ADD_SERVICE_USERNAME_PLACEHOLDER: `電郵地址或用戶名`,
  ADD_SERVICE_SEARCH_FOR_SERVICE: `請輸入您在這裡使用的服務`,
  ADD_SERVICE_SEARCH_FOR_ICONS: '搜索圖標',
  ADD_SERVICE_SUGGESTED_USERNAMES: `建議的用戶名`,
  ADD_SERVICE_DRAW_DOODLE: (groupName) => `為您的 <em>${groupName}</em> 群組繪製塗鴉`,
  ADD_SERVICE_USERNAME_BLANK: `您必須輸入用戶名或電郵地址`,
  ADD_SERVICE_USERNAME_TOO_LONG: '您的用戶名必須少於64個字符',

  ADD_SERVICE_WEBSITE_HEADER: '進入網站',
  ADD_SERVICE_WEBSITE: (serviceName) =>
    `請輸入 <em>${serviceName}</em> 的網站網址。 您也可跳過此步驟，稍後才輸入網站網址。`,
  ADD_SERVICE_WEBSITE_PLACEHOLDER: `網站網址`,

  ADD_SERVICE_SEARCH_ONBOARD_LETS_START: `讓我們從選擇這些熱門的服務開始，或者...`,
  ADD_SERVICE_SEARCH_ONBOARD_NOW_ITS_YOUR_TURN: `現在輪到您了`,
  ADD_SERVICE_DOODLE_DO_IT_AGAIN: `讓我們再來一次`,
  ADD_SERVICE_DOODLE_ONBOARD_NOW_ITS_YOUR_TURN: `現在輪到您了`,
  ADD_SERVICE_DOODLE_CREATE_DOODLE: '創建有您個性的塗鴉',
  ADD_SERVICE_DOODLE_CONFIRM_DOODLE: `讓我們來看看你能否記住它...請<em>再一次</em>畫您的塗鴉`,
  ADD_SERVICE_GENERATING_FORGHETTI_ONBOARD: (serviceName) =>
    `恭喜您，forghetti現在正為<em>${serviceName} </em>生成新的密碼`,
  VIEW_SERVICE_GENERATING_FORGHETTI: (serviceName) => `正在為<em>${serviceName} </em>生成您的forghetti密碼`,
  VIEW_SERVICE_RESET_PASSWORD: `現在重置您的密碼`,
  VIEW_SERVICE_PASSWORD_HELP: (serviceName) =>
    `我們為<em>${serviceName}</em>已經生成了一個超強的密碼。現在是時候把您的舊密碼重新設置。`,
  VIEW_SERVICE_PASSWORD_HELP_MOVE: (serviceName, groupName) =>
    `我們已轉移 <em>${serviceName}</em> 到 <em>${groupName}</em>。如果您已改變這個服務的塗鴉，您將需要更新您的密碼。`,
  VIEW_SERVICE_OLD_PREFIX: '舊的',
  VIEW_SERVICE_NEW_PREFIX: '新的',
  HIDE_QUICK_SELECT: `不需要再告訴我`,
  HIDE_QUICK_SELECT_CONFIRM_TITLE: `隱藏此頁面？`,
  HIDE_QUICK_SELECT_CONFIRM: `此頁面以後將不會再顯示，添加新服務會直接將您帶到“添加其他服務”頁面。\n\n您可以隨時在“用戶選項”下的設置中還原設定。`,
  ADD_SERVICE_DODODLES_DO_NOT_MATCH: `您的塗鴉不匹配。再試一次。`,
  THEME_COLOUR: '顏色',
  THEME_COLOURS: ['Flawless Blue', 'Fresh Green', 'Fearless Red', 'Feel-good Orange', 'Friendly Yellow'],
  THEME_BLUE: '藍色',

  DELETE_ACCOUNT_CONTINUE: '繼續',
  DELETE_ACCOUNT_HEADER: '刪除帳戶',
  DELETE_ACCOUNT_HEADING: '刪除我的賬戶',
  DELETE_ACCOUNT_LINE_1: '刪除您的帳戶將會：',
  DELETE_ACCOUNT_LINE_2: '•刪除您的forghetti帳戶',
  DELETE_ACCOUNT_LINE_3: '•刪除forghetti根據您的個人資料而保存的所有信息',
  DELETE_ACCOUNT_LINE_4: '•從所有forghetti的群組中刪除您',
  DELETE_ACCOUNT_LINE_5: '•從所有已登錄的裝置中刪除對您帳戶的訪問權限',
  DELETE_ACCOUNT_CHANGE_INSTEAD: '需要更換到另一部手機？',

  DELETE_ACCOUNT_CONFIRM_MESSAGE: (telNumber) =>
    '我們已經發出一個確認碼到您的手機 (' + telNumber + '). ' + '請輸入確認碼以確認您將要刪除自己的帳戶：',
  DELETE_ACCOUNT_CONFIRM_BUTTON: '確認',

  DEVICES_HEADER: '裝置管理',
  DEVICES_LAST_ACTIVE: '上一次登錄： ',
  DEVICES_FORGHETTI_APP: '  - forghetti應用程式',
  DEVICES_LOG_OUT_ALL: '退出所有裝置',

  SERVICES_PERSONAL: '個人的',
  SERVICES_ADD_LOGIN: '添加登錄',
  SERVICES_RECENT: '最近的', // Not currently used

  SEARCH_INTERNET: '在互聯網上搜索圖像',

  SERVICE_HEADER_NEW: '添加登錄',
  SERVICE_NAME_PLACEHOLDER: '命名您的登錄',

  SERVICE_DOODLEPAD_SUBHEADING: '在屏幕上畫上塗鴉',
  DOODLEPAD_SECRET: '隱藏',
  DOODLEPAD_SHOW: '顯示',
  DOODLEPAD_REDOODLE: '清除',
  ERROR_NO_DOODLE: '請畫您的塗鴉',

  SERVICE_EDIT_FIELD_HEADER: '設置',

  FIELD_TYPES: {
    lns: 'ABC123_*.',
    wns: 'Words123_ *。',
    lnx: 'ABC123〜？ ％',
    wnx: 'Words123〜？ ％',
    ln: 'ABC123',
    wn: 'Words123',
    l: 'ABC',
    w: '英文字母',
    n: '123',
  },

  FIELD_TEMPLATE_PIN: 'P數字密碼IN',
  FIELD_TEMPLATE_COMPLEX_PASSWORD: '複雜的密碼',
  FIELD_TEMPLATE_PASSWORD: '密碼',
  FIELD_TEMPLATE_MEMWORD: '協助找回記憶的提示詞',
  FIELD_TEMPLATE_FORGHETTIBLE: 'Forghettible字詞',

  SERVICE_USERNAME_LABEL: '電郵地址或用戶名',
  SERVICE_WEBSITE_LABEL: '網址',

  SERVICE_EDIT_HEADER: '詳情',
  SERVICE_EDIT_USERNAME_PLACEHOLDER: '輸入登錄方法',
  SERVICE_EDIT_WEBSITE_PLACEHOLDER: 'www.webaddress.com',

  SERVICE_ADD_FORGHETTIBLE: '添加一個forghettible',
  SERVICE_DFT_FORGHETTIBLE_NAME: '密碼',

  SERVICE_NAME_LENGTH_INVALID: '服務名稱的長度應介於 2 到 32 個字符之間',
  SERVICE_USERNAME_LENGTH_INVALID: '服務所使用的電郵地址/用戶名長度應少於 64 個字符',
  SERVICE_ADDRESS_LENGTH_INVALID: '服務的網址長度應不超過128個字符',
  SERVICE_NO_FORGHETTIBLES: '您必須添加至少一個forghettible。例如，一個密碼。',

  SERVICE_CHOOSE_ICON: '選擇圖標',

  SERVICE_DOODLEPAD_HEADING: '在屏幕上畫上塗鴉',
  SERVICE_DOODLEPAD_RIGHT_NAV: '詳情',

  SERVICE_ADDING: '正在添加服務',

  SERVICE_CONNECTING: '正在連接',

  FIELD_DETAILS_HEADER: '設置',
  FIELD_NAME: '名稱',
  FIELD_NAME_PLACEHOLDER: '命名您的forghettible',
  FIELD_TYPE: '類型',
  FIELD_LENGTH: '長度',
  FIELD_LENGTH_PICKER_CUSTOM: '個人化',

  FIELD_RIGHT_NAV: '好的',

  FIELD_OPTIONS_HEADING: '選項',

  ADVANCED_OPTIONS: '高級選項',
  RESET_FIELD: '重置forghettible',
  DELETE_FIELD: '刪除',
  RESTORE_FIELD: '恢復forghettible',

  FIELD_ADVANCED_OPTIONS_HEADER: '高級選項',

  RESTORE_FIELD_PAGE_HEADER: '恢復',
  RESTORE_FIELD_SUBHEADING: '需要使用舊密碼？',
  RESTORE_FIELD_DESCRIPTION: '您可以在此處返回以前的版本。 只需選擇您要恢復的版本。',
  RESTORE_FIELD_VERSION_HISTORY: '版本歷史記錄',
  RESTORE_FIELD_CURRENT: '當前的',

  SERVICE_VIEW_HEADER: '概要',
  SERVICE_VIEW_RIGHT_NAV: '完成',

  SERVICE_SUMMARY_MESSAGE: `您的forghettibles已經產生。請在下面選擇複製，查看和訪問此登錄。`,
  SERVICE_SUMMARY_FIRST_LOGIN_MSG2: `你現在需要用這個新密碼和讓它生效，以取代現有的密碼。`,
  SERVICE_SUMMARY_FIRST_LOGIN_PASSWORD_TOOLTIP: `當您準備好後，您可以通過點擊此圖標將新密碼複製到剪貼板。`,

  SERVICE_DETAILS_HEADER: '詳情',
  SERVICE_DETAILS_RIGHT_NAV: '編輯',
  SERVICE_DETAILS_DELETE: '刪除登錄',

  PROFILE_HEADER: '個人資料',
  PROFILE_WIZARD_COMPLETE: '完成您的forghetti設置。',
  PROFILE_WIZARD_SEE_DETAILS: '查看詳情>',
  PROFILE_PREMIUM_BANNER_HEADING: '嘗試高級用戶版',
  PROFILE_PREMIUM_BANNER_DETAIL: '解鎖功能使在線更加安全。',
  PROFILE_THEME_MODE_LABEL: '模式',
  PROFILE_THEME_COLOUR_LABEL: '顏色',
  PROFILE_BADGES: '您的徽章',
  PROFILE_LINK_ACCOUNT: '您的帳戶',
  PROFILE_LINK_PREMIUM: '高級用戶版',
  PROFILE_ENABLE_AUTOFILL: '啟用自動填充',
  PROFILE_LINK_USER_PREFERENCES: '應用程式設置偏好',
  PROFILE_YOUR_SUBSCRIPTION: '您的訂閱',
  PROFILE_LINK_INVITE: '與朋友們分享forghetti',
  /*PROFILE_LINK_INVITE_SECONDARY: '與朋友們分享來賺取免費登錄，*/
  PROFILE_REFERRAL_CODE: '推薦代碼',
  PROFILE_REFERRAL_CODE_SECONDARY: '',
  PROFILE_LINK_DEVICES: '設備管理',
  PROFILE_LINK_HELP: '尋求幫助',
  PROFILE_LINK_FEEDBACK: '向我們反饋您的意見',
  PROFILE_LINK_ABOUT: '關於',
  PROFILE_LINK_LOGOUT: '登出',

  PROFILE_ACCOUNT_HEADER: '您的帳戶',
  PROFILE_ACCOUNT_LINK_SECURITY: '安全',
  PROFILE_ACCOUNT_LINK_MIGRATE: '更改號碼',
  PROFILE_ACCOUNT_LINK_DELETE: '刪除我的賬戶',
  PROFILE_ACCOUNT_LINK_SUBSCRIPTION: '您的訂閱',

  PROFILE_REONBOARDING: '了解更多關於forghetti',
  PROFILE_GET_ONLINE_HELP: '尋求網上幫助',
  PROFILE_GET_VIDEO_HELP: '尋求網上視頻幫助',
  PROFILE_HELP_HEADER: '幫助',

  PROFILE_EDIT_NAME_HEADER: '詳細個人資料',
  PROFILE_EDIT_NAME_LABEL: '您的名字',
  PROFILE_EDIT_TEL_LABEL: '手機號碼',

  PROFILE_NAME_BLANK: '個人資料名稱不能為空',
  PROFILE_NAME_TOO_LONG: '個人資料名稱不能超過32個字符',

  PROFILE_NAME_INVALID: '個人資料名稱只能包含字母，數字和符號',

  PROFILE_MIGRATE_1_HEADER: '更改號碼',
  PROFILE_MIGRATE_1_HEADING: '當前手機號碼',
  PROFILE_MIGRATE_1_INFO: '請輸入您新的國家代碼和手機號碼：',
  PROFILE_MIGRATE_1_PLACEHOLDER: '手機號碼',
  PROFILE_MIGRATE_1_BUTTON: '發送驗證碼',

  PROFILE_MIGRATE_2_HEADER: '輸入驗證碼',
  PROFILE_MIGRATE_2_HEADING: '當前手機號碼',
  PROFILE_MIGRATE_2_HEADING2: '新的手機號碼',
  PROFILE_MIGRATE_2_INFO: '現在在您的新手機上輸入您收到的驗證碼：',
  PROFILE_MIGRATE_2_BUTTON: '確認',

  PROFILE_MIGRATE_3_HEADER: '完成',
  PROFILE_MIGRATE_3_MSG: '您的手機號碼已更新',
  PROFILE_MIGRATE_3_HEADING: '新的手機號碼',

  PROFILE_ABOUT_HEADER: '關於',
  PROFILE_ABOUT_PRIVACY: '個人隱私政策',
  PROFILE_ABOUT_COOKIE: '網絡Cookie政策',
  PROFILE_ABOUT_TERMS: '服務條款',
  PROFILE_ABOUT_ACCEPTABLE: '可接受的使用政策',
  PROFILE_ABOUT_VERSION_HEADING: '版本',

  PROFILE_REFERRAL_CODE_HEADING: '推薦代碼',
  PROFILE_REFERRAL_CODE_LABEL: '推薦代碼',
  PROFILE_REFERRAL_CODE_PLACEHOLDER: '輸入您的推薦代碼',
  PROFILE_EDIT_REFERRAL_CODE_HEADING: '編輯推薦代碼',
  PROFILE_REFERRAL_CODE_TEXT: '如果您收到一個推薦代碼請在下面輸入。',

  PROFILE_PREMIUM_TYPE: {
    premium_trial: '高級用戶試用者',
    premium_life: '終身用戶',
    free: '免費用戶',
    premium: '高級用戶訂閱者',
    premium_paid: '高級用戶訂閱者',
  },
  APP_PREFERENCES_HEADER: '應用程式偏好設置',
  APP_PREFERENCES_THEME: '主題',
  APP_PREFERENCES_QUICK_SELECT: '顯示工具提示',
  APP_PREFERENCES_SHOW_QUICK_SELECT: '顯示快速添加登錄界面',
  APP_PREFERENCES_SECRET_DOODLE: '默認的秘密塗鴉',
  APP_PREFERENCES_SHOW_TOOLTIPS: '顯示工具提示',
  APP_PREFERENCES_REQUIRE_TOUCH_ID: '需要生物識別技術開啟應用程式',
  APP_PREFERENCES_NOTIFICATIONS: '通知',
  APP_PREFERENCES_COUNTRY: '語言',
  APP_PREFERENCES_TOUCH_ID_TOAST: `請確保生物識別ID已被登記在設備上`,

  PREMIUM_HEADER: 'Forghetti高級用戶',

  YOUR_SUBSCRIPTION_HEADER: '您的訂閱',
  YOUR_SUBSCRIPTION_SUBHEADING: '高級用戶試用',

  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_TEXT_1: (date) =>
    `您目前正在免費試用高級用戶版。 您的試用期將於 ${date} 到期。 屆時，您可以繼續作為免費用戶使用（但會失去所有高級用戶福利）或在應用程式內訂閱成為高級用戶。`,
  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_TEXT_2: (date) => `我們衷心希望您享受使用forghetti。`,

  YOUR_SUBSCRIPTION_FREE_TEXT_1: (date) =>
    `您目前是免費用戶。今天升級訂閱開始享受無限登錄，無限量與朋友、家人和同事創建小組，並獲得詳細的密碼安全檢測報告。`,
  YOUR_SUBSCRIPTION_FREE_TEXT_2: (date) => `我們衷心希望您享受使用forghetti。`,
  YOUR_SUBSCRIPTION_FREE_TEXT_3: '如果你已經訂閱高級用戶版，請點擊恢復訂閱以繼續。',
  YOUR_SUBSCRIPTION_FREE_DOWNLOAD: `尚未安裝此應用？請您從應用商店立即下載`,

  YOUR_SUBSCRIPTION_PREMIUM_TEXT_1: (date) =>
    `您目前是高級用戶。 您的訂閱將於 ${date} 到期。 當您購買訂閱時，它會設置為自動續訂。 您也可以在應用商店帳戶中關閉此功能。`,
  YOUR_SUBSCRIPTION_PREMIUM_TEXT_2: (date) => `我們衷心希望您享受使用forghetti。`,

  YOUR_SUBSCRIPTION_PREMIUM_NO_DATE_TEXT_1: `您目前是高級用戶。 當您購買訂閱時，它會設置為自動續訂。 您也可以在應用商店帳戶中關閉此功能。`,

  YOUR_SUBSCRIPTION_PREMIUM_LIFE_TEXT_1: (date) => `您的終身高級用戶資格將永不過期。您可以永遠繼續使用forghetti！`,
  YOUR_SUBSCRIPTION_PREMIUM_LIFE_TEXT_2: (date) => `我們衷心希望您享受使用forghetti。`,
  YOUR_SUBSCRIPTION_FREE_TEXT_APP_3: '如果你已經訂閱高級用戶版，請點擊恢復訂閱以繼續。',

  YOUR_SUBSCRIPTION_FREE_TEXT_APP_1: `您目前是免費用戶。 立即訂閱成為高級用戶即可享受無限登錄、創建無限群組的能力，您可以在朋友、家人和同事之間共享這些群組，並獲得詳細的密碼安全檢測報告。`,

  YOUR_SUBSCRIPTION_FREE_TEXT_APP_2: '請用手機掃描QR代碼來訂閱高級用戶服務。',
  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_BUTTON_LABEL: '查看收費價格',
  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_CORDOVA_BUTTON_LABEL: '查看訂閱詳情',

  YOUR_SUBSCRIPTION_FREE_BUTTON_LABEL: '升級',

  PREMIUM_NO_PRODUCT_SELCTED: '無效的產品選擇',
  PREMIUM_PURCHASE_BUTTON: '選購',
  PREMIUM_PURCHASE_BUTTON_NA: '敬請期待',
  COMING_SOON: '敬請期待',
  PREMIUM_PRICE_NONE: 'N/A',
  PREMIUM_PRUCHASE_REFRESH_PURCAHSE: '重新檢查你的購買',
  PREMIUM_MONTHLY_HEADER: '月費高級用戶',
  PREMIUM_MONTHLY_LIST: [
    {
      description: '無限量群組',
    },
    {
      description: '無限登錄',
    },
    {
      description: '優先支援',
    },
  ],
  PREMIUM_ANNUAL_ALIAS: '年費計劃',
  PREMIUM_MONTHLY_ALIAS: '月費計劃',
  PREMIUM_ONE_OFF_ALIAS: '一次性購買',
  PREMIUM_LOADING_OWNED: '恭喜您！您現在已成為高級用戶！',
  PREMIUM_LOADING_RELOAD: '稍後forghetti將重新加載並開啟您新的訂閱。',
  PREMIUM_LOADING_APPROVED: '請稍候，我們正在通過應用商店確認您的訂閱！',
  PREMIUM_LOADING_REGISTERED: '請稍候，我們正在加載可選購的訂閱…',
  PREMIUM_MANAGE_SUBSCRIPTIONS: '管理訂閱',
  PREMIUM_IMPOSSIBLE_TO_VERIFY: '對不起，一直無法確認您的購買',
  PREMIUM_RESTORE_PURCHASE: '恢復訂閱',
  PREMIUM_YEARLY_HEADER: '全年的高級用戶',
  PREMIUM_YEARLY_LIST: [
    {
      description: '無限量群組',
    },
    {
      description: '無限登錄',
    },
    {
      description: '優先支援',
    },
  ],
  PREMIUM_LIFETIME_HEADER: '終身高級用戶',
  PREMIUM_LIFETIME_LIST: [
    {
      description: '無限量群組',
    },
    {
      description: '無限登錄',
    },
    {
      description: '優先支援',
    },
  ],
  ENABLE_ANDROID_AUTOFILL_HEADING: '啟用自動填寫功能',
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_1: `forghetti使用Android的可訪問性服務來實現自動填充功能，當您在設備上的任何應用中提示時，它有助於獲取密碼。`,
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_2:
    '我們向您保證，forghettii不會讀取或訪問任何其他屏幕內容。我們僅確定何時在屏幕上出現密碼字段以進行自動填充。',
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_3: `要使用此服務，需要使用用戶同意。通過單擊“同意”按鈕，您可以允許使用自動填充的可訪問性服務。如果您選擇拒絕同意，則將禁用Autofill功能，但您可以在沒有此功能的情況下繼續使用Mforghetti。`,
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_4: `請注意，從此屏幕上導航並不是作為給予同意，我們也不會使用自動消耗的消息來收集同意。`,
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_5: `有關如何實施和確保我們對可訪問性API的使用以及我們處理用戶數據的更多信息，請查看我們的隱私政策。`,

  ENABLE_ANDROID_AUTOFILL_BUTTON: '同意',
  ENABLE_ANDROID_AUTOFILL_BUTTON_NO: '現在不要',

  MAKE_ADMIN_MESSAGE: '您確定將該用戶變成本群組的管理員嗎？',
  MAKE_OWNER_MESSAGE: '您確定將這個群組的擁有權轉移給這個用戶嗎？',

  REMOVE_ADMIN_MESSAGE: '您確定刪除此用戶的管理狀態嗎？',
  DELETE_GROUP_CONFIRM: '您確定要刪除該群組嗎？',
  LEAVE_GROUP_CONFIRM: '你希望離開這個群組嗎？',
  GROUP_DELETED_MESSAGE: '該群組已被刪除。',
  REMOVE_MEMBER_MESSAGE: '您希望把這用戶從此群組中刪除嗎？',
  REMOVE_MEMBER_SUCCESS: '此用戶已從群組中刪除',
  GROUP_SHARED_BY: '群組被共享',
  ERROR_INVALID_NUM_WORDS: '英文單詞的數目必須在1和10之間',
  ERROR_INVALID_NUM_LETTERS: '英文字符的數目必須在 1 到 64 之間',

  UPGRADE_REQUIRED: '需要升級',
  ADD_LOGIN_NOT_ALLOWED: `免費用戶只可以添加服務登錄到您的個人群組。今天升級到高級用戶版，可以創建群組與朋友、家人和同事和共享服務登錄！`,
  UPGRADE_TODAY_MESSAGE: `免費用戶可以加入一個群組。
今天升級到高級用戶版，可以創建群組與朋友、家人和同事和共享服務登錄！`,

  UPGRADE_TODAY_BUTTON: '從今天起升級到高級用戶版',
  UPGRADE_TODAY_PAGE_HEADING: '從今天起升級到高級用戶版',
  UPGRADE_TODAY_MSG_1: '成為forghetti的高級用戶',
  UPGRADE_TODAY_PRICE: '每年£23.99',
  UPGRADE_TODAY_MSG_2: '開放forghetti所有的功能',
  UPGRADE_TODAY_MSG_3: '免費用戶可以在應用程式內購買升級，成為高級用戶：',

  UPGRADE_TODAY_MSG_4: '年度訂閱讓您成為高級用戶。從此享受創建無限服務登錄，無限forghettible和群組。',

  INVITE_FRIEND_HEADER: '獲取免費服務',
  INVITE_FRIEND_REFERRAL_LABEL: '推薦鏈接',
  INVITE_FRIEND_REFERRAL_COPY: '複製推薦鏈接到剪貼板',
  INVITE_FRIEND_REFERRAL_EMAIL: '發送推薦鏈接到電子郵件',
  INVITE_FRIEND_PREAMBLE: '與朋友分享forghetti',
  INVITE_FRIEND_TEXT: `分享您的個人鏈接`,
  INVITE_FRIEND_BENEFIT:
    '推薦給一位朋友，當好友註冊成功，您可以得到再添加五個服務登錄到您的個人群組，當五個好友註冊成功時，您將獲得15個額外的服務登錄！',

  GROUPS_LIST_HEADER: '您的個人群組',
  GROUPS_LIST_INFO_MESSAGE: '管理您的群組或組間進行切換',
  GROUPS_LIST_PERSONAL_HEADING: '您的個人群組',
  GROUPS_LIST_OTHER_GROUPS_HEADING: '您的其他群組',
  GROUPS_LIST_ADD_NEW_GROUP: '添加新群組',
  GROUPS_LIST_CURRENTLY_ACTIVE: '當前使用中',
  GROUPS_NEW_HEADER: '新的群組',
  GROUPS_NEW_RIGHT_NAV: '創造',
  GROUPS_NEW_NAME_PLACEHOLDER: '輸入標題',

  GROUPS_ADD_GROUP_HEADER: '添加群組',
  GROUPS_JOIN_GROUP: '加入群組',
  GROUPS_JOIN_GROUP_TEXT: '使用邀請鏈接加入群組',
  GROUPS_CREATE_GROUP: '創建群組',
  GROUPS_CREATE_GROUP_TEXT: '創建新群組，與他人分享',
  GROUPS_FREE_JOIN_WARNING: '免費用戶可以加入一個群組。',
  GROUPS_ADD_UPGRADE: '今天升級到高級用戶版，可以創建群組與朋友、家人和同事和共享服務登錄！',
  GROUP_NEW_INVITE_GENERATING: '正在生成您的群組邀請',
  LEAVE_GROUP_MESSAGE: '您現在已被從群組中刪除。',
  GROUP_DETAIL_LOADING: `重新獲取群組詳細資料`,
  GRP_SHARING_HEADING: '分享',
  GRP_MEMBERS_HEADING: '用戶',
  GRP_SETTINGS_HEADING: '群組設定',

  GROUP_VIEW_HEADER: '群組詳細資料',
  GROUP_VIEW_RIGHT_NAV: '編輯',
  GROUP_VIEW_USER_ADMIN: '管理員',
  GROUP_VIEW_USER_OWNER: '擁有者',
  GROUP_VIEW_USER_YOU: '您',
  GROUP_VIEW_USER_READ_ONLY: '唯讀',
  GROUP_VIEW_SWITCH_TO: '切換到該群組',
  GROUP_VIEW_LEAVE: '離開此群組',
  GROUP_VIEW_DELETE: '刪除群組',
  GROUP_VIEW_PREMIUM: '高級用戶',
  GROUP_VIEW_FREE: '免費用戶',
  GROUP_DETAIL_SAVE_LOADING: `更新您的群組詳細資料`,
  GROUP_EDIT_HEADER: '組詳細信息',
  GROUP_EDIT_MAX_MEMBERS: '最大成員數量',
  GROUP_EDIT_MAX_MEMBERS_SECONDARY: '包括您',
  PERSONAL_GROUP_MESSAGE: `這是您的個人群組。這個人群組不會與任何人分享。要更改這個群組的圖標，前去您的個人資料設定，更改個人資料照片。`,

  GROUP_CHOOSE_ICON: '選擇圖標',

  GROUP_NAME_BLANK: '群組名稱不能為空',
  GROUP_NAME_TOO_LONG: '群組名稱不能超過32個字符',

  GRP_MAX_MEMBERS_ERROR: '最大成員數必須是1和50之間的整數',

  JOIN_GROUP_PLACEHOLDER: '輸入您的邀請鏈接',
  JOIN_GROUP_HEADING: '加入群組',
  GRP_INVITE_CODE_LABEL: '群組邀請鏈接',
  GRP_INVITE_CODE_NOTE: (group) => `通過分享此鏈接邀請成員加入 <em>${group}</em>`,
  JOIN_GROUP_RIGHT_NAV: '加入',

  REFERRAL_COPY_MESSAGE: (url) => `我想邀請你使用forghetti。 下載應用程式，然後永遠不用記住密碼。 請點擊 ${url}`,

  CREATE_GROUP_INVITE_TEXT_1: `請將此代碼發送給您的家人，朋友或團隊成員、方便他們訪問該群組。`,

  INVITE_NEW_MEMBER: '邀請新成員',
  GRP_INVITE_HELP_TEXT: `所有群組成員均可以創建新的服務登錄，但只有群組管理員有權刪除它們。管理員還可以刪除成員，但是如果該群組是您創建的，您永遠不能被另一個管理員刪除。`,

  GRP_INVITE_CLIPBOARD_MESSAGE: (code) => `${code}`,

  INVITE_LINK_HEADING: '邀請',
  INVITE_SETTINGS_HEADING: '設定',
  LINK_EXPIRY_LABEL: '鏈接到期日',
  MAX_USES_LABEL: '最大使用數',

  LINK_EXPIRY_VALUE: (mins) => LINK_EXPIRY_VALUES[String(mins)],
  LINK_MAX_USES_VALUE: (num) => LINK_MAX_USE_VALUES[String(num)],
  INVITE_NO_LIMIT: LINK_MAX_USE_VALUES['-1'],
  INVITE_EXPIRY_NEVER: LINK_EXPIRY_VALUES['-1'],

  DEACTIVATE_GRP_LINK_CONFIRM: '您確定要刪除此邀請嗎？',
  DEACTIVATE_GRP_LINK_SUCCESS: '加入群組的邀請已被成功刪除。',

  GRP_INVITE_CREATE_NAV: '創建',

  // Share Group Invite page
  SHARE_INVITE_HEADING: '邀請成員',
  GRP_INVITE_CODE_LABEL: '邀請鏈接',
  CPY_GRP_INVITE: '複製邀請到剪貼板',
  DEACTIVATE_LINK: '刪除邀請',
  SHARE_INVITE_VIA_EMAIL: '通過電子郵件分享邀請',
  COPY_INVITE_SUCCESS: `複製群組邀請到剪貼板。`,

  ONBOARDING_HEADER_1: '為您喜愛的網站創建服務登錄',
  ONBOARDING_HEADER_2: '畫出您的塗鴉',
  ONBOARDING_HEADER_3: '您的密碼已生成',
  ONBOARDING_HEADER_4: '複製並前往！',
  ONBOARDING_HEADER_5: '就是這樣！',
  ONBOARDING_WELCOME_HEADING: `Hi，我叫Locky<br>歡迎使用forghetti`,
  ONBOARDING_WELCOME_TEXT: '使用 forghetti，您可以通過一個安全的塗鴉登錄您的所有服務。',
  ONBOARDING_DOODLE_HEADING: '您將需要一個有您個性的塗鴉',
  ONBOARDING_DOODLE_TEXT: '從線到點，再到隨機的波浪線或數字序列。 您獨特的組合會生成一個超強密碼。',
  ONBOARDING_HOW_IT_WORKS: '這個怎麼運作',
  ONBOARDING_ADDSERVICE_HEADING: '任何服務都適合',
  ONBOARDING_ADDSERVICE_TEXT: '每當您添加服務時，我們都會生成一個獨特且超強的密碼。 您甚至還可以生成數字代碼和提示詞。',
  ONBOARDING_RESETPASSWORD_HEADING: '重置和交換舊密碼',
  ONBOARDING_RESETPASSWORD_TEXT: '打開網站重置您的舊密碼。用新的和超安全的forghetti密碼替換它。',
  ONBOARDING_SECURESHARING_HEADING: '安全的密碼共享',
  ONBOARDING_SECURESHARING_TEXT: '利用群組與朋友、家人和同事安全地共享自己喜愛的服務。',
  ONBOARDING_UNLIKE_OTHERS_HEADING: '不像其他的密碼管理器 ...',
  ONBOARDING_UNLIKE_OTHERS_TEXT:
    '我們不在數據庫保存任何密碼。它們隨您的使用即時生成。他們使用您的塗鴉作為密鑰計算。超級安全，只有您知道。',
  ONBOARDING_DOODLETUTORIAL1_HEADING: '現在，創造有您個性的塗鴉！需要怎麼做呢？',
  ONBOARDING_SHOWMEHOW: '讓我引導您如何開始！',
  ONBOARDING_DOODLETUTORIAL2_HEADING: '首先，您可以畫一個圖案...',
  ONBOARDING_DOODLETUTORIAL: '安全塗鴉生成教程',
  ONBOARDING_DOODLETUTORIAL3_HEADING: '或一系列的線',
  ONBOARDING_DOODLETUTORIAL4_HEADING: '點也是好主意...',
  ONBOARDING_DOODLETUTORIAL5_HEADING: '或者將它們全部組合在一起',
  ONBOARDING_DOODLETUTORIAL6_HEADING: '但要記住它們的次序哦，',
  ONBOARDING_DOODLETUTORIAL7_HEADING: '畫形狀字母A',
  ONBOARDING_DOODLETUTORIAL8_HEADING: '和塗鴉字母B形狀就不一樣',
  ONBOARDING_YOURTURN: `現在輪到您了`,

  ONBOARDING_COMPLETE_WELCOME: '歡迎使用forghetti',
  ONBOARDING_COMPLETE_CONGRATULATIONS: '恭喜您！您邁出了第一步，確保您的在線身份安全',
  ONBOARDING_COMPLETE_COMPLETE_SETUP: '設定',
  ONBOARDING_COMPLETE_CREATE_FORGHETTI: '創建forghetti帳戶',
  ONBOARDING_COMPLETE_SIGNATURE_DOODLE: '創建有您個性的塗鴉',
  ONBOARDING_COMPLETE_FIRST_LOGIN: '添加您第一個服務',
  ONBOARDING_COMPLETE_RESET_PASSWORD: '生成一個安全的密碼',
  ONBOARDING_COMPLETE_IMPORT: '匯入服務',
  ONBOARDING_COMPLETE_HEALTHCHECK: '開始您網上帳戶的密碼安全檢測',
  ONBOARDING_COMPLETE_IMPORT_BUTTON: '匯入服務',
  ONBOARDING_COMPLETE_REMIND_LATER: '稍後提醒我',
  ONBOARDING_COMPLETE_CONTINUE: `我們開始吧`,
  ONBOARDING_COMPLETE_RESET_PASSWORD: '重置不安全的密碼',
  ONBOARDING_COMPLETE_HEALTHCHECK_BUTTON: '執行密碼安全檢測',
  ONBOARDING_COMPLETE_NEXT_STEPS: `<h2>為什麼現在不能：</h2> <ul> <li>為您的在線帳戶執行密碼安全檢測？ </li> <li>或者更新forghetti的其他服務。 </ li> <li>或者只是閒著... </ li> </ ul>`,

  ONBOARDING_EXISTING_TITLE: '新的功能',
  ONBOARDING_EXISTING_WELCOME_HEADING: '非常感謝你',
  ONBOARDING_EXISTING_WELCOME_TEXT: `forghetti 的團隊一直忙於改進。 立即體驗我們的新功能吧。`,
  ONBOARDING_EXISTING_MOVE_SERVICES_HEADING: '轉移服務',
  ONBOARDING_EXISTING_MOVE_SERVICES_TEXT:
    'forghetti現在讓您把一個服務從一個群組移動到另一個。只需點擊各服務上的三點圖標並選擇移動。',
  ONBOARDING_EXISTING_HEALTHCHECK_HEADING: '密碼安全檢測',
  ONBOARDING_EXISTING_HEALTHCHECK_TEXT:
    'forghetti現在包括密碼安全檢測，該功能讓您主動監測您的用戶名和密碼是否已被外洩。',
  ONBOARIDNG_EXISTING_SHARING_HEADING: '快速群組共享',
  ONBOARDING_EXISTING_SHARING_TEXT: '一鍵點擊便可分享您的forghetti群組與您的朋友、家人和同事。',
  ONBOARDING_EXISTING_SWITCHING_HEADING: '快速切換群組',
  ONBOARDING_EXISTING_SWTICHING_TEXT:
    '與您的團隊合作變得前所未有的輕鬆。 在群組內添加新服務比以往任何時候都更快、更容易。',
  ONBOARDING_EXISTING_PRICING_HEADING: '價格更新',
  ONBOARDING_EXISTING_PRICING_TEXT: 'forghetti現在提供更多的訂閱計劃選擇，以滿足您的需求。',

  ACCOUNT_SECURITY_HEADER: '安全',
  ACCOUNT_SECURITY_HEADING: '沒有什麼比這更安全',
  ACCOUNT_SECURITY_SECONDARY_HEADING:
    '我們將數據安全視為優先事項。 forghetti 讓您能夠使用單一塗鴉為多個服務生成登錄密碼。 另一妙之處在於，與其他所有密碼管理應用程式不同，forghetti 的數據庫中並不會儲存您任何一個密碼。   ',
  ACCOUNT_SECURITY_LEARN_MORE: '了解更多有關 Forghetti 的保安政策',

  MSG_FALLBACK_ERROR_MESSAGE: `發生錯誤！很抱歉。`,

  MSG_GROUP_ICON_UPDATED: '更新圖標',
  MSG_COPIED_FORGHETTIBLE: (name) => `已複製${name}到剪貼板`,
  MSG_OPEN_FORGHETTIBLE: `點擊這裡打開`,
  MSG_DELETE_LOGIN_CONFIRM: '你確定要刪除此服務登錄？',
  MSG_LOGIN_DELETED: (name) => `服務登錄'${name}'已被刪除`,
  MSG_SERVICE_ICON_DELETED: '服務圖標更新',
  MSG_LOGOUT_CONFIRM: '您確定要登出嗎？',
  MSG_LOGOUT_ALL_DEVICES_CONFIRM: '您確定嗎？',
  MSG_USER_PROMOTED_ADMIN: '用戶已晉升為管理員。',
  MSG_USER_PROMOTED_OWNER: '擁有權已轉移',

  MSG_CONFIRM_RESET: '請確認您想重置這密碼',
  MSG_CONFIRM_RESTORE: '請確認您想恢復這個以前的版本？',
  MSG_NEW_GROUP_CREATED: (name) => `新群組已創建：${name}`,
  MSG_OPENING_WEBSITE: '打開網站...',
  MSG_REMOVED_ADMIN: '該用戶的管理員狀態已被撤銷',
  MSG_UNSAVED_CONFIRM: '有未保存的更改。您確定想返回嗎？',

  EXTENSION_NONE_FOUND: '找不到此網址的forghettibles',
  EXTENSION_GO_TO_FORGHETTI: '轉到forghetti',
  EXTENSION_NOACCESS_HEADER: '從今天起升級到高級用戶版',
  EXTENSION_UPGRADE: `您目前是免費用戶。 立即升級為高級用戶，您便可使用 forghetti Chrome 擴展程序，並受益於無限制服務登錄以及創建群組和在朋友、家人和同事之間共享登錄資料的能力。 如果您想升級到高級用戶，請點擊下面按鈕升級。`,
  EXTENSION_NOLOGINFOUND_HEADER: `在forghetti中找不到`,
  EXTENSION_NOLOGINFOUND_MSG:
    '沒有找到該網站的forghettible。請在下面畫一個塗鴉來快速為這個網站創建一個新的forghettible',

  CONTEXT_EDIT_LOGIN_LIST_ITEM: '編輯',
  CONTEXT_MOVE_LOGIN_LIST_ITEM: '移動',
  CONTEXT_ADD_LOGIN_LIST_ITEM: '添加forghettible',
  CONTEXT_RESET_LOGIN_LIST_ITEM: '重置forghettible',
  CONTEXT_DELETE_LOGIN_LIST_ITEM: '刪除',
  CONTEXT_UPLOAD_GROUP_PHOTO: '拍照',
  CONTEXT_UPLOAD_GROUP_IMAGE: '上傳圖片',
  CONTEXT_CHOOSE_GROUP_IMAGE: '選擇圖片',
  CONTEXT_TAKE_PROFILE_PIC: '拍照',
  CONTEXT_UPLOAD_PROFILE_PIC: '上傳圖片',
  CONTEXT_UPLOAD_SERVICE_IMAGE: '上傳圖片',
  CONTEXT_CHOOSE_SERVICE_IMAGE: '選擇圖片',
  CONTEXT_SERVICE_IMAGE_WEBSEARCH: '網絡搜索',
  CONTEXT_MAKE_ADMIN: '設為管理員',
  CONTEXT_MAKE_OWNER: '設為擁有者',

  CONTEXT_REMOVE_FROM_GROUP: '從群組中刪除',
  CONTEXT_REVOKE_ADMIN: '撤銷管理員',
  CONTEXT_NEW_GROUP_MEMBERS: '新群組最大成員數',
  CONTEXT_SELECT_FIELD_TYPE: '選擇類型',
  CONTEXT_FIELD_TYPE_LNS: '英文字母，數字和符號',
  CONTEXT_FIELD_TYPE_WNS: '英文單詞 ，數字和符號',
  CONTEXT_FIELD_TYPE_LNX: '英文字母，數字和複雜的符號',
  CONTEXT_FIELD_TYPE_WNX: '英文單詞，數字和複雜的符號',
  CONTEXT_FIELD_TYPE_LN: '字母和數字',
  CONTEXT_FIELD_TYPE_WN: '英文單詞和數字',
  CONTEXT_FIELD_TYPE_L: '只使用英文字母',
  CONTEXT_FIELD_TYPE_W: '只使用英文單詞',
  CONTEXT_FIELD_TYPE_N: '只使用數字',
  CONTEXT_ADD_FIELD: '請選擇下面預先定義的字段。 如果需要，您可以在之後自行設置定義字段。',
  CONTEXT_ADD_FIELD_PASS: '一般密碼（字母，數字和符號）',
  CONTEXT_ADD_FIELD_COMPLEX_PASS: '強化密碼（字母，數字和複雜的符號）',

  CONTEXT_ADD_FIELD_PIN: '數字密碼（僅限數字）',
  CONTEXT_ADD_FIELD_MEMWRD: '提示詞（只可以使用英文單詞）',
  CONTEXT_ADD_FIELD_FORGHETTIBLE: 'Forghettible提示字（只可以使用英文字母）',
  CONTEXT_LINK_EXPIRE_15MINS: '15分鐘',
  CONTEXT_LINK_EXPIRE_30MINS: '30分鐘',
  CONTEXT_LINK_EXPIRE_1HOUR: '1小時',
  CONTEXT_LINK_EXPIRE_6HOURS: '6小時',
  CONTEXT_LINK_EXPIRE_12HOURS: '12小時',
  CONTEXT_LINK_EXPIRE_1DAY: '1天',
  CONTEXT_LINK_EXPIRE_NEVER: '永不',
  CONTEXT_LINK_EXPIRE_CANCEL: '取消',
  CONTEXT_LINK_USES_1: '1次',
  CONTEXT_LINK_USES_2: '2次',
  CONTEXT_LINK_USES_5: '5次',
  CONTEXT_LINK_USES_10: '10次',
  CONTEXT_LINK_USES_20: '20次',
  CONTEXT_LINK_USES_50: '50次',
  CONTEXT_LINK_USES_NO_LIMIT: '沒有限制',
  CONTEXT_LINK_USES_CANCEL: '取消',

  FINGERPRINT_TITLE: '歡迎使用forghetti',
  FINGERPRINT_DESCRIPTION: '掃描您的指紋解鎖forghetti',
  FINGERPRINT_DESCRIPTION_FACE: '使用面部識別解鎖forghetti',

  GENERATING_GROUP: '正在生成您的群組',
  GENERATING_PASSWORD: '正在生成您的密碼',
  DELETING_GROUP: '正在刪除群組',
  LOADING_SERVICE: '正在載入您的服務',
  UNABLE_TO_PURCHASE: '目前無法購買高級用戶版的訂閱。請稍後再試。',

  HEALTHCHECK_WELCOME_HEADER: '密碼安全檢測',
  HEALTHCHECK_WELCOME_NOTE: '執行forghetti密碼安全檢測評估，查看您在網上的個人資訊是否已被洩露和不安全。',
  HEALTHCHECK_WELCOME_BUTTON: '執行密碼安全檢測',
  HEALTHCHECK_USERNAMES_HEADER: `讓我們先從您的電子郵件開始`,
  HEALTHCHECK_USERNAMES_INFO_1: '檢查您的電子郵件地址',
  HEALTHCHECK_USERNAMES_INFO_2: '請讓我們知道您的電子郵件地址，我們將根據任何新的或以前的網上黑客資訊進行檢查。',
  HEALTHCHECK_USERNAMES_PRIMARY_SUBHEAD: '您的常用電子郵件地址',
  HEALTHCHECK_USERNAMES_SECONDARY_SUBHEAD: '您的次要電子郵件地址',
  HEALTHCHECK_USERNAMES_PLACEHOLDER: '電子郵件地址',
  HEALTHCHECK_PASSWORD_HEADER: `讓我們來檢測您的密碼`,
  HEALTHCHECK_PASSWORD_INFO_1: '經常使用相同的密碼嗎？',
  HEALTHCHECK_PASSWORD_INFO_2: `使用forghetti之前，你有沒有經常使用相同的密碼？
讓我們來檢測是否已有密碼洩露。`,
  HEALTHCHECK_PASSWORD_INFO_3:
    '你可選擇是否進行此檢查。 Forghetti 不會存儲或發送您的密碼。 我們將立即生成您密碼的安全散列代码。 本應用程式僅傳輸您密碼的部分安全散列代码，因此我們可以確保您的密碼是安全的。',

  HEALTHCHECK_PASSWORD_SUBHEAD: '您的密碼',
  HEALTHCHECK_PASSWORD_OPTIONAL: '這是可自選的。',
  HEALTHCHECK_PASSWORD_PLACEHOLDER: '密碼',
  HEALTHCHECK_PASSWORD_VAL_TOO_LONG: '密碼太長',

  HEALTHCHECK_RUNNING: '執行您的密碼安全檢測',
  HEALTHCHECK_USERNAME_VAL_FIELD_BLANK: '電子郵件地址不能為空',
  HEALTHCHECK_USERNAME_VAL_FIELD_DUPLICATE: '次要電子郵件地址不能和第一相同',
  HEALTHCHECK_USERNAME_VAL_FIELD_TOO_SHORT: '電子郵件地址太短',
  HEALTHCHECK_USERNAME_VAL_FIELD_TOO_LONG: '電子郵件地址太長',

  HEALTHCHECK_ASSESSMENT_HEADER: '密碼安全檢測',
  HEALTHCHECK_ASSESSMENT_EMAIL: (email) => `<em>被洩露的${email} </em>`,
  HEALTHCHECK_ASSESSMENT_INFO_1: '您的密碼安全檢測結果顯示如下。',
  HEALTHCHECK_ASSESSMENT_INFO_2: '高級用戶可以點擊有關的安全洩露，以了解更多信息。',

  HEALTHCHECK_ASSESSMENT_PASSWORD: '<em>您的密碼檢測結果</em>',
  HEALTHCHECK_ASSESSMENT_COMPROMISED_HEAD: `受影響的服務`,
  HEALTHCHECK_ASSESSMENT_NO_BREACH: '此帳號沒有出現安全洩露的服務登錄',
  HEALTHCHECK_ASSESSMENT_PASSWORD_STRENGTH: '強度',
  HEALTHCHECK_ASSESSMENT_PASSWORD_WEAK: '弱',
  HEALTHCHECK_ASSESSMENT_PASSWORD_STRONG: '強',
  HEALTHCHECK_ASSESSMENT_PASSWORD_COMP: '出現數據洩露',
  HEALTHCHECK_ASSESSMENT_HIBP_LINK: '來源：<a data-actionclick="openHIBP"> haveIbeenpwned </a>',
  HEALTHCHECK_ASSESMENT_UPGRADE_MESSAGE: '洩露細節只提供給高級用戶',
  HEALTHCHECK_DETAILS_PASSWORD_HEADER: '密碼檢測結果',
  HEALTHCHECK_DETAILS_USER_HEADER: `密碼洩露的細節`,
  HEALTHCHECK_DETAILS_USER_TITLE: '名稱',
  HEALTHCHECK_DETAILS_USER_DOMAIN: '域名',
  HEALTHCHECK_DETAILS_USER_DATE: '洩露日期',
  HEALTHCHECK_DETAILS_USER_ACCOUNTS_PWNED: '帳戶已被洩露',
  HEALTHCHECK_DETAILS_PASSWORD_INFO_X_BREACH: (breach) =>
    `您的密碼已被列入的<em> ${breach}</ em>數據可能已被洩露名單。這並不一定意味著您的帳號已被洩露。您應該盡快考慮使用<em> forghetti </ em>生成超級安全的密碼。`,
  HEALTHCHECK_DETAILS_PASSWORD_INFO_NO_BREACH: `好消息！您的密碼沒有在任何公開的數據洩露中被發現。但這並不能保證您的帳戶不會被攻破。請考慮使用<em> forghetti </em>生成一個新的超級安全的密碼。`,
  HEALTHCHECK_DETAILS_PASSWORD_STRENGTH_INFO_STRONG: `您的密碼是的<em>很安全的</ em>。這意味著它已通過由forghetti密碼強度測試的所有標準`,
  HEALTHCHECK_DETAILS_PASSWORD_STRENGTH_INFO_WEAK: `您的密碼強度是<em>弱</ em>。這意味著它沒有通過以下forghetti密碼強度測試標準：`,
  HEALTHCHECK_DETAILS_RESET_NOTE: '現在就使用forghetti生成超級安全的密碼吧',
  HEALTHCHECK_DETAILS_RESET_BUTTON: '創建一個新的服務登錄',
  HEALTHCHECK_DETAILS_HIBP_LINK: '來源：haveIbeenpwned',
  HEALTHCHECK_LOADING_DETAIL: '加載洩露細節',

  IMPORT_SERVICE_WELCOME_BUTTON: '開始匯入服務登錄',
  IMPORT_SERVICE_WELCOME_INFO: '匯入現有的服務登錄資料到forghetti',
  IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_1: '請打開 forghetti 桌面應用程式以匯入服務登錄。',
  IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_2: '如果您沒有forghetti桌面應用程式，請點擊下面鏈結',
  IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_BUTTON: '下載forghetti',
  IMPORT_SERVICES_LIST_HEADER: '請選擇要匯入的服務登錄',
  IMPORT_SERVICES_LIST_ERROR_NONE_SELECTED: '沒有選擇要匯入的服務登錄',
  IMPORT_SERVICES_YOUR_IMPORTED_SERVICES: '已匯入的服務登錄',
  IMPORT_SERVICES_LIST_NOTE: `請選擇要匯入到forghetti的服務登錄`,
  IMPORT_SERVICES_GROUP_NOTE: '請選擇要將服務登錄移至的群組',
  IMPORT_SERVICES_GROUP_HEADER: '請選擇群組',
  IMPORT_SERVICES_USERNAME_HEADER: '輸入用戶名',
  IMPORT_SERVICES_USERNAME_NOTE: '檢查每個帳戶相關聯的用戶名',
  IMPORT_SERVICES_CREATE_HEADER: '創建服務登錄',
  IMPORT_SERVICES_CREATE_BUTTON: '開始創建服務登錄',
  IMPORT_SERVICES_CREATE_HOW_MANY: (number) => `<em> ${number}</em>創造服務`,
  IMPORT_SERVICES_CREATE_LOADING: (current, total) => `匯入 <em>${current}</em>的<em> ${total}</em> 服務登錄`,
  IMPORT_SERVICES_COMPLETE_HEADER: '匯入完成',
  IMPORT_SERVICES_COMPLETE_NOTE: `恭喜！您的服務登錄已匯入完成`,
  IMPORT_FOLDER_SELECT_HEADER: '從Chrome中匯入',
  IMPORT_FOLDER_SELECT_CHROME: '從Chrome中匯入服務登錄',
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC: `forghetti將需要訪問存儲在您裝置上的登錄資料。`,
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_2: `準備好後，請點擊下面的“選擇您的 chrome 文件夾”按鈕。 一個對話框將在您的 Chrome 登錄資料所在位置彈出。 只需選擇“打開”即可讓 forghetti 訪問此文件夾。`,
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_3:
    '如果您想手動找出此文件夾，只需前往 Library/Application Support/Google/Chrome',
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_4:
    '溫馨提示: 您現有的密碼不會被 forghetti 解密。 forghetti 只會檢索您的登錄資料。',
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_5:
    '一旦您的登錄數據可讀您可以選擇想匯入的服務到forghetti。在讀取您的登錄資料後，您將可以選擇那些服務要匯入到 forghetti。',

  IMPORT_FOLDER_SELECT_CHROME_BUTTON: '請選擇您的Chrome文件夾',

  IMPORT_GROUP_HEADER: '您已匯入的服務群組',
  IMPORT_GROUP_NOTE: (importingServices) =>
    `一個特別的群組<em>匯入服務登錄</ em>已自動為您創建。該組將包含<em> ${importingServices}</em>你選擇的匯入服務登錄。`,
  IMPORT_GROUP_NOTE_2:
    '該群組中的服務登錄尚未啟用。 要啟用服務登錄，您需要將服務登錄移動到另一個群組。 點擊繼續將帶您進入匯入服務登錄嚮導。 匯入服務登錄嚮導將使您的匯入服務登錄轉移過程變得快捷和簡單。',
  IMPORT_GROUP_NOTE_3:
    '如果您現在沒有時間將所有的服務登錄匯入，不用擔心。您可以隨時通過匯入服務登錄嚮導，移動您的服務登錄到任何一個裝置上。',
  IMPORT_GROUP_BUTTON: '繼續',

  IMPORT_SERVICE_IMPORT_NODATA_HEADER: '無法找到登錄資料',
  IMPORT_SERVICE_IMPORT_NODATA_NOTE: 'forghetti沒有發現任何可以匯入的登錄資料',

  IMPORT_MOVE_SERVICE_HEADER: '開始移動您的服務登錄',
  IMPORT_MOVE_SERVICE_NOTE_1: (services) =>
    `您有${services}在您的群組。要開始激活這些服務登錄，您只需要將它們移動到其他群組。`,
  IMPORT_MOVE_SERVICE_BUTTON: '繼續',
  IMPORT_SERVICE_LOADING: '正在匯入服務登錄',
  IMPORT_SERVICE_GROUP_NAME: '匯入服務登錄',
  NEW_GROUP_REQUIRES_PREMIUM: '創建新群組需要成為高級用戶。',

  IMPORT_TYPE_HEADER: '選擇匯入類型',
  IMPORT_TYPE_NOTE_1: `Forghetti可以自動從您的Chrome瀏覽器的登錄資料匯入服務登錄。如果你希望匯入服務登錄請點擊下面的“匯入Chrome瀏覽器”按鈕。`,
  IMPORT_TYPE_BUTTON_1: `匯入Chrome瀏覽器`,
  IMPORT_TYPE_NOTE_2: `另外，您也可以通過上傳CSV或Excel（XLSX）文件匯入您的登錄資料，要開始匯入請點擊下面的“上傳文件”按鈕。`,
  IMPORT_TYPE_BUTTON_2: '上傳文件',
  IMPORT_TYPE_NOTE_3: `如果您主要使用 safari瀏覽器，您可能希望使用上傳文件功能來匯入您的服務登錄。 要查找您的 safari 密碼鑰匙圈，請在 safari 瀏覽器中打開“偏好設定”（command +“,”）並選擇“密碼”選項，`,

  IMPORT_UPLOAD_HEADER: '上傳文件',
  IMPORT_UPLOAD_NOTE_1: '上傳CSV或電子表格（XLSX）並開始匯入服務登錄。最起碼您需要提供一個服務登錄的名稱。',
  IMPORT_UPLOAD_BUTTON: '上傳您的文件',
  IMPORT_UPLOAD_NOTE_2: '下載CSV 或 excel 示例以開始使用。',
  IMPORT_UPLOAD_SAMPLE_CSV: '下載CSV示例',
  IMPORT_UPLOAD_SAMPLE_XLS: '下載Excel示例',

  // ================================================ ===========================
  //説明文本
  // ================================================ ===========================

  //NO_LOGINS_MESSAGE: `首先，點擊右上角的“添加登錄”以添加您使用的服務（例如亞馬遜、Facebook等）的登錄資料。

  NO_LOGINS_MESSAGE: `添加服務登錄到該群組，請點擊 +（加號）圖標

想與朋友、同事或家人分享此群組，請點擊右上角的共享圖標

在群組的設置裡，您可以更改該群組的名稱和訪問權限

`,

  LOGIN_RECENT: '最近的',
  HELP_LOGIN_TITLE:
    '為您的服務登錄輸入一個名稱（例如亞馬遜）。通過點擊相機圖標添加服務登錄的圖標。如果您不選擇圖標，系統會為您創建一個。',
  HELP_LOGIN_USERNAME:
    '請輸入您用於服務登錄的用戶名。這可能是您的電子郵件地址。 這將允許您從服務登錄摘要頁輕鬆複製和貼上相關登錄資料。',
  HELP_LOGIN_WEBSITE: '或者，您也可輸入登錄的網站地址。 如果這樣做，這將允許您直接從服務登錄摘要頁打開網站。',
  HELP_LOGIN_ADD_FORGHETTIBLE: `在這裡添加一個標準的密碼（默認設置為複雜和長的，您可以不需要編輯這個密碼），提示詞或數字密碼（PIN）。如果您想要更多的設置，可添加自定義forghettible。

您必須添加至少一個forghettible以便繼續。請點擊屏幕右上角"下一步"來繼續。`,

  HELP_GROUPS_LIST_HEADER: `點擊 + （加號）創建或加入一個群組。免費用戶只能加入付費用戶的一個群組。

您可以使用群組來管理不同類別的服務登錄或共享一個群組中的所有服務登錄。

請記住，您需要與所有成員共享群組塗鴉，以便他們生成與您相同的密碼。因此，請考慮使用一個容易記起的單詞，短語或代碼。
`,

  HELP_GROUPS_LIST_PERSONAL: `這是您專用的個人群組。您無法將其刪除或與其他人分享。`,
  HELP_GROUPS_LIST_OTHER_GROUPS: `這些群組是您已經作為一個高級用戶所創建，或經他人邀請而加入`,

  HELP_DOODLEPAD: `使用連接的線，點或兩者的組合來畫一個您可以記住的塗鴉。構成塗鴉的組合越多，就越安全。您確實需要記住它，因為這是讓您取回密碼的關鍵。

擔心被人窺視？ 您可點擊“秘密塗鴉”，不留痕跡地畫塗鴉。如畫錯了，您可以通過點擊“重畫塗鴉”重新開始。 然後按勾號圖標生成您的密碼。`,

  HELP_SERVICE_SUMMARY: `點擊眼睛圖標查看密碼。 點擊您的密碼將其複製到剪貼板。 然後轉到該服務的網站並使用這個新密碼重置您當前的密碼。

然後你就不需要記住新密碼啦。 下次需要密碼時，只需畫塗鴉，forghetti 就會再次生成它。 密碼不會保存在任何地方，而是在您畫出正確的塗鴉後才會時生成。
`,

  HELP_FIELD_SETTINGS_LENGTH: `選擇forghettible的長度， 比如 - 用於標準的密碼或PIN的長度 - 或字的數目（如果使用提示詞）。`,
  HELP_FIELD_SETTINGS_TYPE: `選擇如字母，符號，數字和英文單詞的組合作為forghettible類型。`,
  HELP_FIELD_SETTINGS_NAME: `為您forghettible密碼輸入一個描述性的名稱。請注意，這不是密碼本身，因為這是forghetti自動生成的。`,
  HELP_FIELD_SETTINGS_RESET: `如果您需要更改forghetti生成的密碼，只需點擊重置，下一次您畫的塗鴉將生成的新的密碼。`,
  HELP_RESTORE_PAGE: `對於舊版本forghetti密碼的設置，可以通過點擊日期來恢復。

如果你這樣做，那麼當您當畫出塗鴉後，舊密碼將會產生。

您可以根據需要來回切換！
`,
  HELP_LOGINS_LIST: `點擊登錄即可生成密碼。

使用搜索工具找到您需要的登錄。
`,
  HELP_GROUP_PAGE: `滾動至底部去刪除群組，切換到查看該群組的登錄，或離開您不再想成為成員的群組。`,
  HELP_GROUP_SHARING: `邀請一個朋友或同事共享。`,
  HELP_GROUP_MEMBERS: `管理用戶組的權限，使他們成為管理用戶，或者完全刪除他們。`,
  HELP_GROUP_INVITE_MAX_USES: `先控制多長時間和多少次他人可以使用您的邀請，再發送出去給朋友和同事共享。`,

  // ================================================ ===========================
  //電子郵件
  // ================================================ ===========================

  REFERRAL_EMAIL_SUBJECT: '邀請使用forghetti，因為沒有什麼比使用它更安全的',
  REFERRAL_EMAIL_BODY: (url) =>
    `Hi,

我使用了一個名為forghetti的偉大應用程式。它幫助我記住所有的密碼，一輩子哦！而且您的密碼不會被存儲在任何地方。

通過畫塗鴉來生成您的密碼。畫同樣的塗鴉，每次您會得到相同的密碼。如此簡單，又如此聰明。

使用此推介鏈接註冊，你和我同時獲得更多的功能！
${url}

希望您也喜歡！`,

  // ================================================ ===========================

  GRP_INVITE_EMAIL_SUBJECT: 'forghetti的群組邀請',
  GRP_INVITE_EMAIL_BODY: (inviteUrl, referralUrl, referralCode) =>
    `您好，我想與大家分享我的forghetti群組。請點擊以下鏈接，打開Forghetti並加入我的群組：
${inviteUrl}

如果您沒有forghetti，請添加此推推廣代碼${referralCode} 到您的應用程式中的個人資料，並獲得更多使用時間！永遠忘記您的密碼！`,

  APPRATE_TITLE: '請給forghetti點個贊和留言',
  APPRATE_MESSAGE: `這將不會超過一分鐘，有助於改進我們的應用程式。謝謝您的支持`,
  APPRATE_CANCEL: '不用了，謝謝',
  APPRATE_LATER: '稍後提醒我',
  APPRATE_RATE: '給forghetti評分吧',
  APPRATE_YES: '是',
  APPRATE_NO: '並不是的',
  APPRATE_PROMPT_TITLE: '您喜歡使用forghetti嗎',
  APPRATE_FEEDBACK: '介意給我們一些建議嗎？',

  BADGE_HEALTHCHECK_HEADER: '運行數據安全健康檢查',
  BADGE_HEALTHCHECK_INFO: '通過完成forghetti用戶名和密碼，贏取數據安全健康檢查徽章',
  BADGE_HEALTHCHECK_COMPLETE_INFO: '恭喜！您已經通過完成forghetti數據安全健康檢查獲得了這個徽章',
  BADGE_HEALTHCHECK_REWARD: (premium) => (premium ? '為您的個人群組獲得2個額外的服務登錄' : ''),
  BADGE_HEALTHCHECK_CTA: '現在運行數據安全健康檢查',

  BADGE_RATING_HEADER: '給forghetti打分',
  BADGE_RATING_INFO: '為forghetti打分來贏取徽章',
  BADGE_RATING_COMPLETE_INFO: '恭喜！您贏得了這個徽章因為您為forghetti打了分',
  BADGE_RATING_REWARD: (premium) => (premium ? '為您的個人群組獲得2個額外的服務登錄' : ''),
  BADGE_RATING_CTA: '現在給forghetti打分',

  BADGE_PROFILECOMPLETE_HEADER: '填寫您的個',
  BADGE_PROFILECOMPLETE_INFO: '填寫您的forghetti個',
  BADGE_PROFILECOMPLETE__COMPLETE_INFO: '恭喜！通過填寫您的forghetti個',
  BADGE_PROFILECOMPLETE_REWARD: (premium) => (premium ? '為您的個人群組獲得2個額外的服務登錄' : ''),
  BADGE_PROFILECOMPLETE_CTA: '現在填寫您的個',

  BADGE_SHARING_HEADER: '在Facebook上共享forghetti',
  BADGE_SHARING_INFO: '給forghetti點贊並分享在您的社交網絡可以贏取徽章',
  BADGE_SHARING_COMPLETE_INFO: '恭喜！您贏得這個徽章， 因為給forghetti點贊並分享在您的社交網絡',
  BADGE_SHARING_REWARD: (premium) => (premium ? '為您的個人群組獲得2個額外的服務登錄' : ''),
  BADGE_SHARING_CTA: '在Facebook上分享forghetti',

  BADGE_IMPORT_HEADER: '匯入服務',
  BADGE_IMPORT_INFO: '當您從您的手機或電腦秘密鑰匙keychain 匯入服務後，便可以獲得這個徽章',
  BADGE_IMPORT_COMPLETE_INFO: '恭喜！您贏得了這個徽章，因為您從您的手機或電腦秘密鑰匙keychain 匯入了服務',
  BADGE_IMPORT_REWARD: (premium) => (premium ? '為您的個人群組獲得2個額外的服務登錄' : ''),
  BADGE_IMPORT_CTA: '現在開始匯入服務 ',

  BADGE_SUPERUSER_HEADER: '成為超級用戶',
  BADGE_SUPERUSER_INFO: `要獲得這個徽章，需要您成功與5個朋友共享forghetti，擁有兩個以上的群組，成為高級用戶，並在您的forghetti帳戶擁有超過50個服務才可。`,
  BADGE_SUPERUSER_COMPLETE_INFO: `恭喜！您獲得了這個徽章：成功與5個朋友共享forghetti，擁有兩個以上的群組，成為高級用戶，並在您的forghetti帳戶擁有超過50個服務。`,
  BADGE_SUPERUSER_REWARD: (premium) => (premium ? '為您的個人群組獲得2個額外的服務' : ''),
  BADGE_SUPERUSER_CTA: '與朋友分享forghetti',

  BADGE_SERVICES_50_HEADER: '50個服務！',
  BADGE_SERVICES_50_INFO: '要獲得這個徽章，需要在您的forghetti帳戶創建50個服務',
  BADGE_SERVICES_50_COMPLETE_INFO: '恭喜！您已經在您的forghetti帳戶創造了超過50個服務，成功獲得了這個徽章',
  BADGE_SERVICES_50_REWARD: (premium) => (premium ? '為您的個人群組獲得2個額外的服務登錄' : ''),
  BADGE_SERVICES_50_CTA: '請現在創建新的服務登錄',

  BADGE_SERVICES_150_HEADER: '150個服務登錄！',
  BADGE_SERVICES_150_INFO: '要獲得這個徽章，需要在您的forghetti帳戶創建150個服務登錄',
  BADGE_SERVICES_150_COMPLETE_INFO: '恭喜！您已經在您的forghetti帳戶創造了超過150個服務登錄，成功獲得了這個徽章',
  BADGE_SERVICES_150_REWARD: (premium) => (premium ? '為您的個人群組獲得2個額外的服務登錄' : ''),
  BADGE_SERVICES_150_CTA: '請現在創建新的服務登錄',

  BADGE_SERVICES_300_HEADER: '300個服務登錄！',
  BADGE_SERVICES_300_INFO: '要獲得這個徽章，需要在您的forghetti帳戶創建300個服務登錄',
  BADGE_SERVICES_300_COMPLETE_INFO: '恭喜！您已經在您的forghetti帳戶創造了超過300個服務登錄，成功獲得了這個徽章',
  BADGE_SERVICES_300_REWARD: (premium) => (premium ? '為您的個人群組獲得2個額外的服務登錄' : ''),
  BADGE_SERVICES_300_CTA: '請現在創建新的服務登錄',

  BADGE_SERVICES_500_HEADER: '500個服務登錄！',
  BADGE_SERVICES_500_INFO: '要獲得這個徽章，需要在您的forghetti帳戶創建500個服務登錄',
  BADGE_SERVICES_500_COMPLETE_INFO: '恭喜！您已經在您的forghetti帳戶創造了超過500個服務登錄，成功獲得了這個徽章',
  BADGE_SERVICES_500_REWARD: (premium) => (premium ? '為您的個人群組獲得2個額外的服務登錄' : ''),
  BADGE_SERVICES_500_CTA: '請現在創建新的服務登錄',

  BADGE_SHARING_1_HEADER: '請與您的朋友分享forghetti',
  BADGE_SHARING_1_INFO: '當您推薦forghetti給您的朋友後，他們也成功創建一個帳戶，您便會獲得這個徽章！',
  BADGE_SHARING_1_COMPLETE_INFO:
    '恭喜！因您推薦forghetti給您的朋友後，他們成功地創建了一個帳戶，您成功獲得了這個徽章！',
  BADGE_SHARING_1_REWARD: (premium) => (premium ? '為您的個人群組獲得2個額外的服務登錄' : ''),
  BADGE_SHARING_1_CTA: '請與您的朋友分享forghetti',

  BADGE_SHARING_5_HEADER: '請您與 5 位朋友分享 Forghetti',
  BADGE_SHARING_5_INFO: '當您推薦forghetti給5位朋友後，而他們成功創建一個帳戶，便會獲得這個徽章！',
  BADGE_SHARING_5_COMPLETE_INFO: '恭喜！因您推薦forghetti給5位朋友後，他們成功地創建了一個帳戶，您獲得了這個徽章！',
  BADGE_SHARING_5_REWARD: (premium) => (premium ? '為您的個人群組獲得2個額外的服務登錄' : ''),
  BADGE_SHARING_5_CTA: '請與您的朋友分享forghetti',

  BADGE_SHARING_10_HEADER: '請您與 10 位朋友分享 Forghetti',
  BADGE_SHARING_10_INFO: '當您推薦給10個朋友forghetti後他們成功創建一個帳戶，便會獲得這個徽章！',
  BADGE_SHARING_10_COMPLETE_INFO: '恭喜！因您推薦forghetti給10位朋友後，他們成功地創建了一個帳戶，您獲得了這個徽章！',
  BADGE_SHARING_10_REWARD: (premium) => (premium ? '為您的個人群組獲得2個額外的服務登錄' : ''),
  BADGE_SHARING_10_CTA: '請與您的朋友分享forghetti',

  BADGE_SHARING_25_HEADER: '請您與 25 位朋友分享 Forghetti',
  BADGE_SHARING_25_INFO: '當您推薦給25位朋友forghetti後他們成功創建一個帳戶，獲得這個徽章！',
  BADGE_SHARING_25_COMPLETE_INFO: '恭喜！因您推薦forghetti給25位朋友後，他們成功地創建了一個帳戶，您獲得了這個徽章！',
  BADGE_SHARING_25_REWARD: (premium) => (premium ? '為您的個人群組獲得2個額外的服務登錄' : ''),
  BADGE_SHARING_25_CTA: '請與您的朋友分享forghetti',

  BADGE_SHARING_50_HEADER: '請您與 50 位朋友分享 Forghetti',
  BADGE_SHARING_50_INFO: '當您推薦給50位朋友forghetti後他們成功創建一個帳戶，便會獲得這個徽章！',
  BADGE_SHARING_50_COMPLETE_INFO: '恭喜！因您推薦forghetti給50位朋友後，他們成功地創建了一個帳戶，您獲得了這個徽章！',
  BADGE_SHARING_50_REWARD: (premium) => (premium ? '為您的個人群組獲得2個額外的服務登錄' : ''),
  BADGE_SHARING_50_CTA: '請與您的朋友分享forghetti',

  BADGE_GROUP_1_HEADER: '創建一個forghetti新群組',
  BADGE_GROUP_1_INFO: '當您使用forghetti創建和共享一個新的群組，便會獲得這個徽章。',
  BADGE_GROUP_1_COMPLETE_INFO: '恭喜！因為您使用forghetti創建和共享一個新的群組，獲得這個徽章。',
  BADGE_GROUP_1_REWARD: (premium) => (premium ? '為您的個人群組獲得2個額外的服務登錄' : ''),
  BADGE_GROUP_1_CTA: '現在創建一個群組',

  BADGE_GROUP_5_HEADER: '創建5個forghetti新群組',
  BADGE_GROUP_5_INFO: '當您使用forghetti創建和共享5個新的群組，便會獲得這個徽章。',
  BADGE_GROUP_5_COMPLETE_INFO: '恭喜！因為您使用forghetti創建和共享5個新的群組，獲得這個徽章。',
  BADGE_GROUP_5_REWARD: (premium) => (premium ? '為您的個人群組獲得2個額外的服務登錄' : ''),
  BADGE_GROUP_5_CTA: '現在創建一個群組',

  BADGE_LEGACY_HEADING: `您是一個超級巨星！`,
  BADGE_LEGACY_INFO_COMPLETE: `非常感謝您作為一位長期訂閱forghetti的用戶。 為了表達我們的感激之情，您獲得了這個特殊徽章和 3 個月免費高級用戶版的訂閱！`,

  JOIN_GORUP_ALERT_MESSAGE: `我們將您加入這個小組。當它完成時我們將通知您。`,
  GROUP_UNAVAILABLE: (groupName) =>
    `已經超過免費forghettibles的數量<em> ${groupName}</ em>。為了添加更多的服務今天升級吧。`,
  GROUP_PERSONAL_UNAVAILABLE: (groupName) =>
    `已經超過您的免費forghettibles個人群組數量。為了添加更多的服務登錄，不如今天升級為高級會員吧。`,
  GROUP_NONE_AVAILABLE: '您所擁有的群組都已經超過了允許的數量',

  SHARE_INVITE_USERNAME_HEADING: '在您開始分享前',
  SHARE_INVITE_USERNAME_INFO: '為了讓您的朋友可以識別您要共享的群組，請提供一個您的個人資料名稱',
  SHARE_INVITE_USERNAME_PLACEHOLDER: '您的名字',

  LEGACY_USER_ONBOARDING_HEADING: '我們看重您的長期支持',
  LEGACY_USER_ONBOARDING_INFO:
    '作為回報我們送給您3個月高級用戶版(完全免費)！我們希望比起前一個版本，您更享受forghetti的新版本。',
  CLAIM_PREMIUM: '領取您的獎勵',
  LEGACY_USER_RELOAD: '為了使高級用戶版生效，forghetti將在幾秒鐘內自動重新加載。',

  SERVICE_OPEN_ICON_LABEL: '打開',
  SERVICE_VIEW_ICON_LABEL: '看',
  SERVICE_HIDE_ICON_LABEL: '隱藏',
  SERVICE_WEBSITE_ICON_LABEL: '打開',
  SERVICE_COPY_ICON_LABEL: '複製',

  GROUPSLIST_SWITCH_LABEL: '切換',
  GROUPSLIST_SHARE_LABEL: '邀請',
  GROUPSLIST_SETTINGS_LABEL: '設置',

  FORGHETTI_GROUP_PERMISSIONS: `點擊此<a data-actionclick="openPermissionsLink">鏈接</a>來了解更多有關群組的權限。`,

  SUBSCRIPTION_TANDC: `請注意，除非您在當前訂閱期結束日前取消，否則您的訂閱將自動續訂。您可在App Store帳戶內的已購買設置中，管理和關閉自動續訂來取消您的訂閱。如欲了解更多資訊，請看看我們的<a data-actionclick="openTermsOfServiceUrl">服務條款</a> 和我們的 <a data-actionclick="openPrivacyPolicyUrl">隱私政策</a>`,
  SUBSCRIPTION_TANDC_LIFETIME: `請注意，forghetti高級用戶版將終身賦予您...如欲了解更多資訊，請看看我們的<a data-actionclick="openTermsOfServiceUrl">服務條款</a> 和我們的<a data-actionclick="openPrivacyPolicyUrl" >隱私政策</a>`,
  SUBSCRIPTION_ERROR: '在驗證您的訂閱時出現錯誤。請稍後再試。',

  SUBSCRIPTION_TANDC_HEADER: '您的訂閱資訊',
  SERVICE_INJECT_LABEL: '採用',
  PHONE_NUMBER_TOO_LONG: '無效的電話號碼',

  ADD_SERVICE_LOADING_PLEASE_WAIT: '我們正在生成您的forghetti服務，請等等',
  ADD_SERVICE_LOADING_GENERATING_SERVICE: '正在生成您的新服務',
  ADD_SERVICE_LOADING_GENERATING_PASSWORD: '正在生成一個超級安全的密碼',
  ADD_SERVICE_LOADING_LOADING_SERVICE_DETAILS: '正在載入您的新forghetti服務',

  VIEW_SERVICE_LOADING_LOADING_SERVICE_DETAIL: '我們正在加載您的服務，請等等',
  VIEW_SERVICE_LOADING_LOADING_SERVICE_FORGHETTIBLE: '正在建立您的forghettible資料',

  MIGRATION_ALERT_HEADING: '遷移您的帳戶',
  MIGRATION_ALERT_MESSAGE: `要更改您的手機號碼，請前往我們的Web應用程式，網址為<a href="https://app.forghetti.com" target="_new">https://app.forghetti.com</a>。`,
  DELETE_ALERT_HEADING: '刪除您的帳戶',
  DELETE_ALERT_MESSAGE: `要刪除您的帳戶，請前往我們的Web應用程式，網址為<a href="https://app.forghetti.com" target="_new"> https://app.forghetti.com </a>。`,
  REFERRAL_CODE_UPDATE_NOT_ALLOWED: `您的帳戶已經使用了一個推薦代碼`,
  REFERRAL_CODE_APPLIED: (code) => `您的帳戶已經使用了這個推薦代碼<em> ${code}</em>。`,
  INVALID_PHONE_NUMBER: '手機號碼無效。請再試一次。',
  INVALID_ACTIVATION_CODE: '激活碼無效',
  INVALID_TOO_MANY_ATTEMPTS: `嘗試使用此號碼登錄的失敗次數過多。請稍後再試。`,

  REONBOARDING_POPUP_GO_AGAIN: `我們改進了forghetti。您想熟悉一下forghetti強大的功能嗎？`,
  REONBOARDING_POPUP_BUTTON_GO: `讓我看看`,
  REONBOARDING_POPUP_BUTTON_CANCEL: '不用了，謝謝',
  REONBOARDING_POPUP_HEADING: `新的forghetti 2.0`,
  HELP_QUICKSTART: '快速入門',
  YOUR_BADGES: '您的徽章',
  GROUP_NOT_OWNER: '您只能將服務登錄移動到您擁有的群組。',
  GROUP_NOT_OWNER_TITLE: '移動服務',
  VIEWSERVICE_NOT_COPIED_TO_CLIPBOARD: `<span class="toast--title">您將需要您的新密碼。 </span><span class="toast--text">點擊密碼字段中的複制按鈕將新密碼添加到剪貼板。 </span>`,
  EXTENSION_ATTEMPT: (website) =>
    `<strong class="toast--title">正在搜索這網站的登入資料 </strong><span class="toast--clipboard-item">${website}</span>`,
  EXTENSION_ATTEMPT_FAIL: (website) => `<span class="toast--title">未找到任何匹配的登入資料</span>`,

  UNIVERSAL_LINK_NEW_LOGIN: (url) => `嘗試找尋匹配這網站的服務登錄${url}`,
  UNIVERSAL_LINK_NEW_LOGIN_NO_URL: `推出新的服務登錄嚮導`,

  CONFIRM_COUNTRY_UPDATE_HEADER: '更改您的語言',
  CONFIRM_BUTTON: '重載forghetti',

  FORGHETTI_LOGIN: 'forghetti',
  FORGHETTI_LOGIN_TAGLINE: '不用再記住你的密碼',
  FORGHETTI_LOGIN_CREATE: '創建一個帳戶',
  FORGHETTI_LOGIN_ALREADY_HAVE: '我已經有一個帳戶',

  MUST_BE_LOGGED_IN_ACTION: '此操作僅在登錄時可用',
  UPDATING_FORGHETTIBLE: '正在更新您的forghettible',
  FORGHETTI_ON_MAC: `forghetti 電腦應用程式現已支援Mac。<a data-actionclick="openForghettiCom">點擊這裡</a>`,
  FORGHETTI_ON_WINDOWS: `forghetti 電腦應用程式現已支援Windows。<a data-actionclick="openForghettiCom">點擊這裡</a>`,

  REALTIME_NEW_SERVICE_FOR_GROUP: (group) => `一個新的服務登錄被添加到這個共享群組${group}`,
  REALTIME_REMOVE_SERVICE_FOR_GROUP: (group) => `一個服務登錄從這個共享群組${group}刪除`,
  REALTIME_KICK_SERVICE_FOR_GROUP: (group) => `你已被踢出這群組${group}`,
  REALTIME_BAN_SERVICE_FOR_GROUP: (group) => `你已被禁止加入這群組${group}`,
  REALTIME_JOIN_SERVICE_FOR_GROUP: (group) => `一位新用戶已加入${group}群組`,
  REALTIME_REMOVE_GROUP: (group) => `該群組${group}已被擁有者刪除`,

  PERSONAL_GROUP: '個人群組',
  SHAREABLE_GROUP: '共享群組',
  IMPORT_GROUP: '匯入服務',
  IMPORT_MENU: '已匯入的服務',

  THEME_MODES: ['白天', '晚上'],

  MONTHS: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],

  CSL_SUBSCRIPTION_HEADER: `高級用戶服務已生效`,
  CSL_SUBSCRIPTION_1: `多謝你啟用forghetti高級用戶服務。當本訂閱到期時，你可以免費用戶身份繼續使用（沒有高級用戶服務功能），或可以續訂此高級用戶服務。`,
  CSL_SUBSCRIPTION_2: `我們衷心希望你享受使用forghetti。`,
  LICENCE_KEY_INVALID: (partner) => `抱歉，你的申请未被接納。`,
  LICENCE_KEY_USED: (partner) => `抱歉，你的申请未被接納。`,
  LICENCE_KEY_ALREADY_APPLIED: (partner) => `您已成功申請服務。
  感謝您使用此服務。`,

  AND: '和',
  NEW_GROUP: '新小組',
  LETS_START_SELECT: '讓我們從選擇其中一種或多種流行服務開始...',
  CREATE_SERVICES: '創建服務',
  CREATING_SERVICES: '我們現在正在生成您的服務s',
  MUST_SELECT_MORE_THAN_ONE_SERVICES: '請選擇一項或多項服務',
  ADD_NOTE: '添加新註釋',
  EDIT_NOTE: '編輯註釋',
  NOTE: '筆記',
  NOTE_COPIED: '注意複製到剪貼板',
  DELETE_NOTE: '刪除註釋',

  FORGHETTI_LOGIN_TAGLINE: '忘記密碼。',
  FORGHETTI_LOGIN_TAGLINE_2: '永遠。',
  CREATE_ACCOUNT_1_HEADER_2: '創建您的Boodhetti帳戶。',

  CREATE_ACCOUNT_2_HEADING: '輸入您的驗證代碼。',
  CREATE_ACCOUNT_FINAL_BUTTON: '告訴我如何',

  ONBOARDING_DOODLETUTORIAL2_HEADING_2: '畫一個圖案',
  ONBOARDING_DOODLETUTORIAL3_HEADING_2: '多行',
  ONBOARDING_DOODLETUTORIAL4_HEADING_2: '點也...',
  ONBOARDING_DOODLETUTORIAL5_HEADING_2: '或一起',
  ONBOARDING_DOODLETUTORIAL6_HEADING_2: '但是請記住，訂單很重要！',
  ONBOARDING_DOODLETUTORIAL7_HEADING_2: '塗鴉A，',
  ONBOARDING_DOODLETUTORIAL8_HEADING_2: 'Doodle A，與Doodle B不同',
  SECURE_DOODLE_TUTORIAL: '安全塗鴉教程',
  DOODLE_TRY_AGAIN: '再試一次',
  WELCOME_TO_FORGHETTI: '歡迎來到寬恕',

  ONBOARDING_COMPLETE_CONGRATULATIONS: `恭喜！您只是採取了第一步來確保您的在線身份。`,

  ONBOARDING_COMPLETE_CREATE_FORGHETTI: '創建一個寬恕帳戶。',
  ONBOARDING_COMPLETE_SIGNATURE_DOODLE: '創建您的簽名塗鴉。',

  ONBOARDING_COMPLETE_RESET_PASSWORD: '重置一個不安全的密碼。',

  ONBOARDING_COMPLETE_HEALTHCHECK: '在您的在線帳戶上運行健康檢查。',

  ONBOARDING_COMPLETE_IMPORT: '導入服務',
  ONBOARDING_COMPLETE_IMPORT_BUTTON: '導入服務',
  ONBOARDING_COMPLETE_REMIND_LATER: '稍後提醒我',
  ONBOARDING_COMPLETE_HEALTHCHECK_BUTTON: '運行健康檢查',

  USERNAME_OR_EMAIL: '用戶名',
  USERNAME_OR_EMAIL_SECOND: '電子郵件',
  SERVICE_EDIT_USERNAME_PLACEHOLDER: '輸入任何其他登錄詳細信息',

  SERVICE_USERNAME_LABEL: '用戶名',
  SERVICE_USERNAME_SECOND_LABEL: '電子郵件',

  ADD_SERVICE_USERNAME_PLACEHOLDER: `用戶名`,
  SERVICE_USERNAME_PLACEHOLDER_SECOND: `電子郵件`,
  FREE_USER_UPGRADE: '升級到高級',
  LOGIN_ACCOUNT_1_HEADER_2: '登錄到您的bonghetti帳戶.',
  KEYCHAIN_HEADING: '保存你的塗鴉',
  KEYCHAIN_MESSAGE: '您可以將塗鴉牢固地保存到此設備中，以使生成密碼更加容易。',
  KEYCHAIN_MESSAGE_2: '您想把塗鴉保存到此設備上嗎？',
  KEYCHAIN_MESSAGE_3: '您可以隨時在設置中進行更改。',
  KEYCHAIN_YES: '是的，保存我的塗鴉',
  KEYCHAIN_NO: '不，我每次都會畫塗鴉',

  LOADING_VAULT: '加載保管庫',
  ONBOARDING_COMPLETE_FIRST_LOGIN: '添加服務。',
  ONBOARDING_COMPLETE_CONTINUE: `現在重置密碼`,
  STILL_LOADING: '我們正在加載您的帳戶',
  STILL_LOADING_2: '您的帳戶已驗證',

  CREATE_ACCOUNT_VERIFYING_PHONE: '我們正在驗證您的電話號碼',
  NOTIFICATION_MESSAGE_BAR: `單擊服務上的“使用”，將密碼發送到{{service}}`,
  NO_NOTIFICATIONS_HELLO_TEXT: (name) => `你好 ${name}！您還沒有任何通知。`,
  NO_NOTIFICATIONS_CHECK: '請查看Reguarly以獲取更新。',
  REPLAY_DOODLE_HEADING: '重播',
  FORGHETTIBLE: '寬恕',
  SERVICE_NO_NOTE: '沒有筆記',
  SERVICE_NOTE: '筆記',
  SERVICE_BAD_NOTE: '要解鎖此註釋，請重新塗鴉',
  SERVICE_NAME: '服務名稱',
  REPLAY_DOODLE: '重播塗鴉',
  ENABLE_BIOMETRICS: '啟用生物識別技術',
  DISABLE_BIOMETRICS: '禁用生物識別技術',
  WEB_DELAY: '建立連接並加載您的詳細信息。如果這需要太長，請檢查您的連接。',
  WEB_WAITING: '仍在工作，請等待...',
  PROFILE_MIGRATE_2_INFO_2: '現在輸入您在舊手機上收到的驗證代碼:',
  MIGRATE: '遷移',
  MONTHLY: '月',
  YEARLY: '年',
  UNLOCK_BIOMETRICS: '用生物識別技術解鎖',
  UNLOCK_DESCRIPTION: '用生物識別學解鎖您的bonghetti帳戶',
  UNLOCK_SERVICE: (service) => `解鎖 ${service}`,
  UNLOCK_SERVICE_DESCRIPTION: (service) => `解鎖 ${service}使用生物識別技術或重新塗鴉`,
  UNLOCK_FALLBACK: '使用塗鴉',
  UNLOCK_CANCEL: '取消',
  RATE_US: '評價我們',
  SETUP_NEW_SERVICE_MESSAGE: `让我们通过提供一些细节来设置您的新服务。`,
  LOGIN_ACCOUNT_1_MSG: `請輸入您的手機號碼以通過SMS接收一次性驗證代碼。`,
  LETS_START_SELECT_COUNT: (count) =>
    `讓我們從選擇這些流行的服務開始...您可以在免費層上選擇<em data-count>${count}</em>。`,
  TOAST_TOO_MANY_SERVICES_SELECTED: (count) => `您選擇了太多服務。請選擇最多的 ${count} 服務。`,
  LETS_START_ADDING_DETAILS: `讓我們添加更多細節`,
  ONBOARDING_COMPLETE_NEXT_STEPS: `<h2>現在為什麼不：：</h2><ul><li>對您的在線帳戶進行健康檢查？</li><li>或更新formhetti上的另一個服務。</li><li> 或者只是環顧四周...</li></ul>`,
  ONBOARDING_COMPLETE_CONGRATULATIONS: '恭喜！您只是採取了第一步來確保您的在線身份。',
  CREATE_ACCOUNT_1_MSG_4:
    '<b>注意：</br>我們只會使用您的電話號碼來創建/支持您的formhetti帳戶。您將收到一次性設備驗證代碼。',
  MOVE_SERVICE_CONFIRM_NOTE_UPDATE: (fromGroup, toGroup, list, listLength) =>
    `新 <em>${list}</em>  將從 ${toGroup} 生成您的塗鴉。如果您想訪問舊的寬容，只需繪製您的 ${fromGroup} doodle即可。`,
  YOUR_SUBSCRIPTION_PARTNER_PREMIUM_TEXT_1: (partner, link) =>
    `我們的合作夥伴在<em> ${partner} </em>為您提供寬恕。您可以在此處找到有關您的訂閱的更多信息：<a href="${link}“target="_new"> ${link} </a>`,
  YOUR_SUBSCRIPTION_PARTNER_PREMIUM_TEXT_2: `我們希望您喜歡使用nomhetti.`,
  PARTNER_PREMIUM_HEADING: `恭喜!`,
  PARTNER_PREMIUM_MESSAGE: (partner) =>
    `作為Boomhetti合作夥伴，<em> ${partner} </em>成員獲得了Premium nomhetti。您無需做任何事情，訂閱將自動應用於您的帳戶。為了更改，立即單擊“重新加載formhetti”。`,
  PARNTER_PREMIUM_BUTTON: `重新加載寬恕`,

  CSL_SUBSCRIPTION_HEADER: `高級激活`,
  CSL_SUBSCRIPTION_1: `您已經激活了我們的高級服務。謝謝。當此訂閱過期時，您可以作為免費用戶繼續執行（並失去所有優質利益）或續訂訂閱.`,
  CSL_SUBSCRIPTION_2: `我們希望您喜歡使用Formhetti。`,
  LICENCE_KEY_INVALID: (partner) => `抱歉，您的申請請求無效.`,
  LICENCE_KEY_USED: (partner) => ` 抱歉，您的申請請求無效。 `,
  LICENCE_KEY_ALREADY_APPLIED: (partner) => `您已成功訂閱。感謝您使用此服務。`,

  SERVICE_EMAIL_LABEL: '電子郵件',
  SERVICE_EMAIL_PLACEHOLDER: 'user@email.com',
  SERVICE_EDIT_EMAIL_PLACEHOLDER: 'user@email.com',
  PROFILE_USE_BIOMETRICS: '用生物識別技術存儲塗鴉',
  BIOMETRICS_GROUPS_HEADER: '啟用生物識別技術',
  BIOMETRICS_SELECT_GROUP: '選擇一個用生物識別技術存儲塗鴉的組',
  BIOMETRICS: '生物識別技術',
  BIOMETRICS_ENABLE: '使能夠',
  BIOMETRICS_DISABLE: '禁用',
  BIOMETRICS_SECURE: '使用生物識別技術固定',
  BIOMETRICS_SECURE_MESSAGE: (group) => `<em> ${group} </em>的塗鴉將使用生物識別牢固存儲`,
  BIOMETRICS_USE_DOODLE: '使用塗鴉',
  BIOMETRICS_TRY_AGAIN: '再試一次',
  NOTES: '筆記',
  SELECT_SPECIAL_CHARACTERS: '選擇包含哪些符號',
  WORDS: '單詞<span class=“單拼”>示例</span>',
  LETTERS: '字母<span class=“ Monospace”>ABC</span>',
  NUMBERS: '數字<span class=“ Monospace”>123</span>',
  SYMBOLS: '符號<span class=“單拼”>！$％</span>',
  SELECT_PASSWORD_TYPE: (forghettibleName) => `${forghettibleName}選項`,
  SELECT_PASSWORD_NUMBERS: '總字母 /數字 /符號',
  SELECT_PASSWORD_WORDS: '總詞',
  ALL: '全部',
  SIMPLE: '簡單的',
  EDIT_PASSWORD_HEADING: '設置',
  SERVICE_FIELD_PASSWORD_NO_OPTIONS: '至少必須選擇一個密碼選項',
  SERVICE_FIELD_PASSWORD_NO_SYMBOLS_SELECTED: '至少必須選擇一個符號',
  SUBSCRIPTION_ENDS: (days) => `您的訂閱將在 ${days}天到期.`,
  SUBSCRIPTION_ENDS_TOMORROW: '您的訂閱將於明天到期。',
  SUBSCRIPTION_ENDS_TODAY: '您的訂閱將於今天到期。',
  NOTIFICATIONS: '通知',
  CONTEXT_CHANGE_TYPE: '更改選項',
  CONTEXT_CHANGE_LENGTH: '更改長度',
  CONTEXT_RENAME: '重命名寬恕',
  NEW_FORGHETTIBLE: '新的寬恕',
  OLD_FORGHETTIBLE: '舊的',
  DELETE_FIELD_PROMPT: '您確定要刪除此字段嗎？',
  ONBOARDING2_WELCOME_HEADING: '歡迎來到寬恕',
  ONBOARDING2_WELCOME_TEXT: '使用一個安全的塗鴉登錄您用戶用戶使用的所有服務。',
  ONBOARDING2_DOODLE_HEADING: '創建您的簽名塗鴉',
  ONBOARDING2_DOODLE_TEXT: '從線或點到隨機彎曲或數字序列。您獨特的組合會產生一個超強密碼。',
  ONBOARDIN2_HOWITWORKS: '怎麼運行的',
  ONBOARDING_CONFIRM_DOODLE: '確認你的塗鴉',
  ONBOARDING_BIOMETRICS_CONFIRM: '您想將其保存到鑰匙扣嗎？',
  SAVING_NOTE: '加密筆記',
};
