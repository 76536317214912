const config = require('../../../config');
const { infoTextRow } = require('../../page_helpers/components');

const { strings } = config;
const { IMPORT_SERVICES_YOUR_IMPORTED_SERVICES, IMPORT_SERVICES_GROUP_NOTE, BACK, IMPORT_SERVICES_GROUP_HEADER } = strings;

module.exports = ({ nav }) => {
  const { groupsList } = window.state;

  const page = {
    template: 'block-section-page',
    fields: {
      id: 'add-service-group',


      header: IMPORT_SERVICES_GROUP_HEADER(),

      navigation_left: BACK(),
      navigation_left_template: 'back',
      navigation_left_data: Math.max(nav - 1, 0),

      navbar: false,
    },
    rows: [
      infoTextRow(IMPORT_SERVICES_GROUP_NOTE(), true, false, true)],
  };

  const action = 'importSelectGroup';

  //filter out groups you're not authorised to add to
  const filteredGroupList = groupsList.filter((group) => {
    return group.personal || !group.users || (group.users[window.state.userData.id] || {}).member_type >= 1
  })

  const numRows = Math.ceil(filteredGroupList.length / 4);
  for (let r = 0; r < numRows; r++) {
    const row = {
      template: 'block-section-row',
      fields: {
        class: 'icon-row',
        class2: 'no-border',
      },
      columns: [],
    };

    for (let c = r * 4; c < (r * 4 + 4); c++) {
      //make the rows 4 long, that means if we have an odd number 
      //we're going to add in some blank columns
      //if we've got less than 4 items, don't do this.
      if (numRows > 1 && c >= filteredGroupList.length) {
        row.columns.push({
          template: 'block-no-item-column',
        })
        continue;
      }
      const group = filteredGroupList[c];
      //Only owners and admins can add logins.
      if (group) {
        const icon = group.personal
          ? window.state.userData.avatar || config.app.DEFAULT_USER_ICON
          : group.icon || config.app.DEFAULT_GROUP_ICON;
        row.columns.push({
          template: 'block-login-avatar',
          fields: {
            avatar: icon,
            data_id: group.id,
            label: group.name,
            action,
          },
        });
      }
    }
    page.rows.push(row);
  }

  //TODO
  // const addNewGroup = () => ({
  //   template: 'block-section-row',
  //   columns: [{
  //     template: 'block-text-button',
  //     fields: {
  //       text: strings.GROUPS_LIST_ADD_NEW_GROUP,
  //       action: 'addService_newGroup',
  //     }
  //   }
  //   ]
  // })

  //page.rows.push(window.state.launchData.home.premium && addNewGroup());

  return page;
};
