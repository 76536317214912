const strings = require('../../../config').strings;

module.exports = ({ nav, data }) => {
  const avatarBlock = icon => ({
    template: 'block-group-avatar',
    //Cordova doesn't want a forward slash, web does
    fields: { avatar: `${window.cordova ? '' : '/'}` + icon.filename, action: 'chooseGroupIcon' },
  });

  // Helper to create a row of icons for a particular category
  const row = category => {
    const icons = data.icons.filter(i => i.category === category);
    return {
      template: 'block-section-row',
      columns: [
        {
          template: 'block-text-group',
          fields: { primary_text: category },
        },
        ...icons.map(avatarBlock),
      ],
    };
  };

  // Get list of categories from the list of icons
  const categories = (data.icons || []).reduce((prev, icon) => {
    if (!prev.includes(icon.category)) {
      return [...prev, icon.category];
    } else {
      return prev;
    }
  }, []);

  // Display each category as a row
  const rows = categories.map(row);

  return {
    template: 'block-section-page',
    fields: {
      id: 'chooseGroupIcon',
      active: false,
      header: strings.GROUP_CHOOSE_ICON(),
      navigation_left: strings.CANCEL(),
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'back',
      navbar: false,
    },
    rows: rows,
  };
};
