const showToastMessage = require('../../action_helpers/showToastMessage');
const fb = require('../../app/firebaseUtils');
module.exports = ({ section, z2hApp }) => {

  let verificationCode = $(section).find('[data_name="delete-account-confirmation-code"] input').val() || null;
  let credential = null;

  if (window.creds && !window.creds.instantVerification) {
    window.creds.code = verificationCode;
    credential = window.creds;
  } else if (window.creds && window.creds.instantVerification){
      credential = window.creds
  } else {
    credential = fb.PhoneAuthProvider(window.verificationId, verificationCode);
  }
  $('#pane-2').addClass('busy');
  fb.reauthenticateAndRetrieveDataWithCredential(credential)
    .then(_ => fb.deleteAccount())
    .then(_=> z2hApp.clearCachedData())
    .then(_ => fb.signOut())
    .then(_ => window.location.reload(true))
    .catch(err => {
      // If an error occurred, take user back to initial pane
      showToastMessage('Sorry, an error occurred: ' + err);
      $('#pane-2').removeClass('busy');
      z2hApp.paneNavigation('back', $('#pane-2'), 0);
    })
};
