const sanitize = require('sanitize-html');

const strings = require('../../../config').strings;
const copyPasswordToClipboard = require('./copyPasswordToClipboard');
const showToastMessage = require('../../action_helpers/showToastMessage');

module.exports = ({ currentTarget }) => {
  let message = copyPasswordToClipboard({ currentTarget, noToast: true });

  if (!message) return;

  const service = window.z2hApp.pageData.service || {};

  window.state.copiedToClipboard = service.id;

  let html = false;
  // Prevent XSS injection
  let website = sanitize(service.website);
  if (website) {
    if (website.toLowerCase().substr(0, 4) !== 'http') {
      website = 'https://' + website;
    }
    message += `<a href="${website}" target="_blank" class="toast--link"><span class="toast--text">` + strings.MSG_OPEN_FORGHETTIBLE() + `</span><br>${website}</a>`;
    html = true;
  }

  showToastMessage(message, { html: true, timeout: 1500, center: true });
};
