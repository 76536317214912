import { active } from 'domain';

const showToastMessage = require('../../action_helpers/showToastMessage');
const strings = require('../../../config').strings;
const getAccessKeys = require('../../getPageData_helpers/getAccessKeys');

module.exports = async ({ currentTarget, z2hApp }) => {
  // Get doodle that user drew
  let doodle;
  let doodleProm;

  if (!window.state.biometrics) {
    doodleProm = new Promise((resolve, reject) => {
      const shape = window.currentDoodlepad.getShape().shape;
      return resolve(shape);
    });
  } else {
    doodleProm = new Promise((resolve, reject) => {
      if (process.env.CORDOVA) {
        if (window.ss) {
          window.ss.get(
            (value) => {
              console.log('Stored doodle');
              return resolve(value);
            },
            () => {
              console.log('Failed to store doodle');
              return resolve(null);
            },
            window.state.selectedGroupId + '_doodle',
          );
        }
      } else {
        return resolve(false);
      }
    });
  }
  doodle = await doodleProm;
  let error = false;

  // Check that code is not empty
  if (!doodle) {
    showToastMessage(strings.ERROR_NO_DOODLE());
    error = true;
  }
  window.state.movingService.from.service.doodle = doodle;

  const fromService = window.state.movingService.from.service;
  const serviceId = window.state.movingService.from.service.id;
  //We have to get the service accces data now because we are about
  //to move it!
  window.serviceFieldPromises = window.serviceFieldPromises || {};
  window.serviceFieldPromises[serviceId] =
    window.serviceFieldPromises[serviceId] || z2hApp.requestData(`get`, '', `services/${serviceId}/fields`);
  const fields = [];
  window.serviceFieldPromises[serviceId]
    .then((response) => {
      for (const fieldId in response.data) {
        fields.push({ ...response.data[fieldId], id: fieldId });
      }
      window.state.movingService.from.service.serviceFields = fields;
      return getAccessKeys(serviceId, doodle);
    })
    .then((data) => {
      for (let fieldId in data) {
        const field = fields.find((f) => f.id === fieldId);
        if (field) {
          field.access_key = data[fieldId].access_key;
        }
      }
      window.state.movingService.from.service.serviceFields = fields;
    });


  window.serviceFieldPromises[serviceId].then(() => {

  const paneDetails = window.getActivePaneDetails('#pane-2');
    const { biometricsOrDoodle } = require('../../actions/services');
    biometricsOrDoodle({
      currentTarget: null,
      z2hApp,
      pane: paneDetails.pane,
      newPaneIndex: error ? paneDetails.activePaneIndex - 1 : paneDetails.activePaneIndex + 1,
      template: { biometrics: 'addService_biometrics', doodlepad: 'addService_doodle' },
      groupId: window.state.addService_groupId,
    });
  });
 
};
