const config = require('../../../config');
const strings = config.strings;

module.exports = ({ data, nav }) => {
  const referralCode = data.referralCode;
  const referralUrl = config.urls.REFERRAL_LINK(referralCode, window.state.userData.name || window.state.userData.id);

  const page = {
    template: 'block-section-page',
    fields: {
      id: 'invite-a-friend',
      active: false,
      header: strings.INVITE_FRIEND_HEADER(),
      navbar: false,
      navigation_left: strings.BACK(),
      navigation_left_icon: '',
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'back',
    },
    rows: [
      {
        template: 'block-section-row',
        fields: {
          class: 'no-border',
        },
        columns: [
          {
            template: 'block-lochy',
            fields: {
              imagePath: `img/lochy/07-Lochy-Excited.gif`,
              class: 'no-border',
              size: 'small',
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: { class: 'centered-text', class2: 'no-border' },
        columns: [
          {
            template: 'block-primary-heading',
            fields: {
              heading: config.strings.INVITE_FRIEND_PREAMBLE(),
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: { class: 'centered-text', class2: 'no-border' },
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_text: config.strings.INVITE_FRIEND_BENEFIT(), secondary_class: 'nocolour' },
          },
        ],
      },

      {
        template: 'block-section-row',
        fields: { class: 'centered-text', class2: 'no-border' },
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_text: config.strings.INVITE_FRIEND_TEXT(), secondary_class: 'nocolour' },
          },
        ],
      },

      {
        template: 'block-section-row',
        fields: {
          class: 'no-border',
          class2: 'centered-text',
          action: 'copyReferralLinkToClipboard',
        },
        //fields: {
        //  secondary_heading: strings.INVITE_FRIEND_REFERRAL_LABEL,
        //  action: 'copyReferralLinkToClipboard',
        //},
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_html: `<em>${referralUrl}</em>` },
          },
          //{
          //  template: 'block-icn-button',
          //  fields: { icon: 'copy', action: 'copyReferralLinkToClipboard' },
          //},
        ],
      },

      //!window.cordova && {
      // template: 'block-section-row',
      // fields: { class: 'cta', class2: 'no-border' },
      // columns: [
      //   {
      //     template: 'block-text-button',
      //     fields: {
      //       text: strings.INVITE_FRIEND_REFERRAL_COPY,
      //       action: 'copyReferralLinkToClipboard',
      //     },
      //   },
      // ],
      //},
      !window.cordova && {
        template: 'block-section-row',
        fields: { class: 'cta' },
        columns: [
          {
            template: 'block-text-button',
            fields: {
              text: strings.INVITE_FRIEND_REFERRAL_EMAIL(),
              action: 'emailReferralLink',
            },
          },
        ],
      },
    ],
  };

  return page;
};
