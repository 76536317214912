// const updateServiceIcon = require('../../action_helpers/updateServiceIcon');

module.exports = ({ element, section, z2hApp }) => {

  const clickedAvatar = $(element).closest('.login-avatar-wrapper');

  const serviceName = $(clickedAvatar)
    .find('.login-avatar-label')
    .text();

  const { serviceIconList } = window.state;
  const serviceDetails = serviceIconList.find(s => s.name === serviceName);
  window.z2hApp.pageData.service.serviceData.name = serviceName;
  window.z2hApp.pageData.service.serviceData.website = serviceDetails.address;
  window.z2hApp.pageData.service.serviceData.icon = serviceDetails.url;
  window.z2hApp.pageData.service.serviceData.preset_id = serviceDetails.id;

  const paneDetails = window.getActivePaneDetails('#pane-2');
  z2hApp.paneNavigation('addService_group', paneDetails.pane, paneDetails.activePaneIndex + 1);
};
