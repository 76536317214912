const DialogMgr = require('dialog-manager');
const strings = require('../../../config/strings');

module.exports = ({ currentTarget, z2hApp }) => {
  const node = $('<div>');
  const z2hTemplates = require('../../app/templates');
  const id = $(currentTarget).attr('data-id');

  node.append(
    z2hApp.constructBlock(
      z2hTemplates.compileBlockAttribute({
        template: 'block-text-group',
        fields: {
          primary_html:
            id === 'com.forghetti.forghettiapp.lifetime'
              ? strings.SUBSCRIPTION_TANDC_LIFETIME()
              : strings.SUBSCRIPTION_TANDC(),
        },
      }),
    ),
  );
  const row = z2hApp.constructBlock(
    z2hTemplates.compileBlockAttribute({
      template: 'block-section-row',
      fields: {
        class: 'centered-text',
      },
    }),
  );

  row.find('.column-group').append(
    z2hApp.constructBlock(
      z2hTemplates.compileBlockAttribute({
        template: 'block-text-button',
        fields: {
          action: 'purchaseSelectedProduct',
          data_id: id,
          text: strings.PREMIUM_PURCHASE_BUTTON(),
          class: 'cta-button',
          class2: 'centered-text',
        },
      }),
    ),
  );
  node.append(row);
  $('body').on('click', '[data-actionclick="purchaseSelectedProduct"]', () => {
    $('.dialogMgr_root').click();
  });

  DialogMgr.showMsg({ closeEnabled: true, msgNode: node[0], titleText: strings.SUBSCRIPTION_TANDC_HEADER() });
};
