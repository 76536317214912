const config = require('../../../config');
const { infoTextRow, thirdheading } = require('../../page_helpers/components');
const actions = require('../../actions');

const { strings } = config;
const {
  BACK,
  ADD_SERVICE_WEBSITE_PLACEHOLDER,
  ADD_SERVICE_WEBSITE,
  SEARCH,
  CANCEL,
  ADD_SERVICE_WEBSITE_HEADER,
  SKIP,
} = strings;
const { WEBSITE_URL_MAXLENGTH } = require('../../../config/app');


module.exports = ({ nav, data }) => {
  // Focus on input field after 1 second
  setTimeout(_ => $('#addService_website .service-website').focus(), 1000);

  const serviceName = window.z2hApp.pageData.service.serviceData.name;
  const onboarding = window.state.userData.activeOnboarding;
  window.z2hApp.pageData.service.serviceData.website = '';

  return {
    template: 'block-section-page',
    fields: {
      id: 'addService_website',
      header: ADD_SERVICE_WEBSITE_HEADER(),
      navigation_left: BACK(),

      class2: onboarding && 'onboarding',
      //navigation_left_action: 'hidePane3',
      navigation_left_template: 'back',
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_right: SKIP(),
      navigation_right_template: 'addService_group',
      navigation_right_data: Math.max(nav + 1, 0),

      navbar: false,
    },
    rows: [
      infoTextRow(ADD_SERVICE_WEBSITE()(serviceName), true, false, true),
      {
        template: 'block-section-row',
        fields: {
          class: 'no-border',
        },
        columns: [
          {
            template: 'block-search-box',
            fields: {
              placeholder: ADD_SERVICE_WEBSITE_PLACEHOLDER(),
              id: 'add-service-website-input',
              value: '',
              maxlength: WEBSITE_URL_MAXLENGTH,

            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {},
        columns: [
          {
            template: 'block-icn-button',
            fields: {
              icon: 'check-solid',
              class: 'icn-button-lrg',
              action: 'addService_submitWebsite',
              data_id: 'addService_website',
              //navigation_validation: 'addService_username_onboard',
            },
          },
        ],
      },
    ],
  };
};

$('body').on('keyup', '#addService_website #add-service-website-input', e => {
  if (e.key === 'Enter') {
    $('#addService_website [data-actionclick=addService_submitWebsite]').click();
    return;
  }
});
