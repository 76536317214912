module.exports = async ({ z2hApp, currentTarget }) => {
  const z2hTemplates = require('../../app/templates');

  const row = $(currentTarget).closest('.row ');
  const newName = row.find('[name=renameWebsite]').val();

  const service = z2hApp.pageData.service;
  const serviceId = service.id;

  service.website = newName;

  const serviceData = {
    name: service.name,
    username: service.username,
      website: service.website,
  };

  setTimeout(()=>{

    $(currentTarget).closest('label').removeClass('focussed current-focus').blur();
  },0)
  return z2hApp.requestData('post', '', 'services/' + serviceId, serviceData).then((response) => {
    row.find('input').blur();

    return response;
  });
};
