const config = require('../../../config');
const strings = config.strings;

// TODO: Mostly a copy of createAccount_addLogin. Could do with reducing duplicate code.

module.exports = ({ data, nav }) => {
  const services = data || [];

  const perRow = Math.ceil(services.length / 3);
  const firstRowServices = services.slice(0, perRow);
  const secondRowServices = services.slice(perRow, perRow * 2);
  const thirdRowServices = services.slice(perRow * 2);

  const serviceRow = servicesForRow => ({
    template: 'block-section-row',
    fields: { class: 'centered-text', class2: 'no-border' },
    columns: servicesForRow.map(s => ({
      template: 'block-login-avatar',
      fields: {
        avatar: s.url || '',
        label: s.name,
        action: 'newLoginFromSuggestedPreset',
        data_id: s.id,
        navigation_data: nav + 1,
        navigation_template: 'createAccount_addCustomLogin_doodlepad',
      },
    })),
  });

  return {
    template: 'block-section-page',

    fields: {
      id: 'create-account-add-login',
      header: strings.SERVICES_ADD_LOGIN(),

      navigation_left: config.strings.BACK(),
      navigation_left_action: 'hidePane3',
    },

    rows: [
      {
        template: 'block-section-row',
        fields: { primary_heading: strings.CREATE_ACCOUNT_ADD_LOGIN_MSG() },
      },
      serviceRow(firstRowServices),
      serviceRow(secondRowServices),
      serviceRow(thirdRowServices),
      {
        template: 'block-section-row',
        fields: {
          class: 'no-border',
        },
        columns: [
          {
            template: 'block-icn-button',
            fields: {
              icon: 'add-solid',
              class: 'icn-button-lrg',
              navigation_data: nav + 1,
              navigation_template: 'addNewService',
              label: strings.CREATE_ACCOUNT_ADD_CUSTOM_LOGIN(),
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        columns: [
          {
            template: 'block-text-button',
            fields: {
              text: strings.HIDE_QUICK_SELECT(),
              action: 'hideQuickSelectAddLoginScreen',
            },
          },
        ],
      },
    ],
  };
};
