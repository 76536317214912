const config = require('../../../config');
const { infoTextRow, thirdheading } = require('../../page_helpers/components');
const actions = require('../../actions');

const { strings } = config;
const {
  ADD_SERVICE_SEARCH_FOR_SERVICE,
  ADD_SERVICE_SEARCH,
  ADD_SERVICE_SEARCH_ONBOARD_NOW_ITS_YOUR_TURN,
  ADD_SERVICE_SEARCH_ONBOARD_LETS_START,
  SEARCH,
  CANCEL,
  ADD_SERVICE_SEARCH_HEADER,
  ADD_SERVICE_SUGGESTED,
} = strings;
const { SERVIVCE_NAME_MAXLENGTH } = require('../../../config/app');


module.exports = ({ nav, data }) => {
  // Focus on input field after 1 second
  setTimeout(_ => $('#addService_search .search-input').focus(), 1000);
  const onboarding = window.state.userData.activeOnboarding;
  const reonboarding = window.state.userData.reonboarding;
  const header_fields = () => {
    //onboarding... no header
    let fields = {
      id: window.state.userData.activeOnboarding ? 'addService_search-onboarding' : 'addService_search',
      active: false,
      class: window.state.userData.activeOnboarding && 'no-title',
      navbar: false,
    };
    if (!window.state.userData.activeOnboarding) {
      fields = {
        ...fields,
        header: ADD_SERVICE_SEARCH_HEADER(),
        navigation_left: CANCEL(),
        navigation_left_template: 'back',
        navigation_left_data: Math.max(nav - 1, 0),
      };
    }
    return fields;
  };

  const rows = [];
  //onboarding && rows.push(thirdheading({ text: ADD_SERVICE_SEARCH_ONBOARD_NOW_ITS_YOUR_TURN }));
  window.state.userData.activeOnboarding && rows.push(infoTextRow(ADD_SERVICE_SEARCH_ONBOARD_LETS_START(), false, false));

  //!onboarding && rows.push(infoTextRow(ADD_SERVICE_SEARCH, true, false))
  reonboarding && rows.push(
    {
      template: 'block-onboarding-close',
    }
  )
  rows.push(
    {
      template: 'block-search-box',
      fields: {
        placeholder: ADD_SERVICE_SEARCH_FOR_SERVICE(),
        id: 'add-service-search-input',
        value: '',
        maxlength: SERVIVCE_NAME_MAXLENGTH,
      },
    },
    thirdheading({ text: ADD_SERVICE_SUGGESTED() }),
    {
      template: 'block-loading-icon-row',
      fields: {
        class: 'hidden',
      },
    },
    {
      template: 'block-suggested-add-button',
      fields: {
        service_name_id: 'add-service-search-input',
        navigation_action: 'addService_selectName',

        nav,
      },
    },
    {
      template: 'block-suggested-presets',
      fields: {
        service_name_id: 'add-service-search-input',
        item_limit: 12,
        action: 'addService_selectSuggested',
        navigation_template: 'addService_group',
        navigation_data: nav + 1,
      },
    },
  );
  // onboarding && rows.push({
  //   template: 'block-section-row',
  //   columns: [
  //     {
  //       template: 'block-text-button',
  //       fields: {
  //         text: 'Import services',
  //         class: 'cta-button',
  //         //action: 'switchToGroup',

  //       },
  //     }
  //   ]
  // })
  return {
    template: onboarding ? 'block-section-onboarding' : 'block-section-page',
    fields: header_fields(),
    rows: rows,
  };
};

// $('body').on('keypress', '#addService_search .search-input', e => {
//   if (e.key === 'Enter') {
//     actions.webSearchForServiceIcons({ immediately: true, currentTarget: e.currentTarget });
//   }
// });
