module.exports = ({ section, z2hApp }) => {
  const element = section.find('.text-input').eq(0);

  const value = $(element)
    .val()
    // .replace(/[^\w\d\s-]/g, '')  // Remove non-alphabet/digit characters - doesn't work for international letters
    .replace(/(\s)+/g, ' ') // Replace multiple spaces and replace with one
    .trim() // Remove trailing/preceeding whitespace
    .substr(0, 50); // Limit to 50 chars

  if (!value) {
    return;
  }

  const endpoint = 'users/@me';
  const data = JSON.stringify({ name: value });

  window.state.userData.name = value;
  return z2hApp.requestData('post', z2hApp.apiConfig.version, endpoint, data).then(response => {
    console.info('Update user response:', response);
    // Update profile name on screen, if it's visible
    $('.row.profile-name .primary-text').text(value);
  });
};
