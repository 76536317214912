const strings = require('../../config/strings');

module.exports = {
  groupsList: strings.LOCHY_LOADING_TEXT_GROUPSLIST,
  healthcheck_welcome: strings.LOCHY_LOADING_TEXT_HEALTHCHECK,

  chooseGroupIcon: strings.LOCHY_LOADING_TEXT_CHOOSE_CHOOSE_GROUP_ICON,
  searchForServiceIcon: strings.LOCHY_LOADING_TEXT_CHOOSE_SEARCH_ICON,
  chooseGroupIconFromSearch: strings.LOCHY_LOADING_TEXT_CHOOSE_CHOOSE_GROUP_ICON,
  createGroupInvite: strings.LOCHY_LOADING_TEXT_CREATE_GROUP_INVITE,
  editServiceDetails: strings.LOCHY_LOADING_TEXT_EDIT_SERVICE,
  updateServiceDetails: strings.LOCHY_LOADING_TEXT_UPDATE_SERVICE,
  viewProfile: strings.LOCHY_LOADING_TEXT_PROFILE,
  viewYourSubscription: strings.LOCHY_LOADING_TEXT_SUBSCRIPTION,
};
