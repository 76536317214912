const config = require('../../../config');
const strings = config.strings;

const { infoTextRow, primaryText } = require('../../page_helpers/components');

module.exports = ({ nav, data, dataId, noHeading, confirmAction }) => {
  console.log('DATA: ', dataId);
  const group = window.state.groupsList.find((g) => g.id === dataId);

  const groupIcon = group.icon || config.app.DEFAULT_GROUP_ICON;

  const groupName = group.name || '';

  const memberType = group.personal ? 2 : (group.users[window.state.userData.id] || {}).member_type;

  // =================================================================================================

  return {
    template: 'block-section-page',
    fields: {
      id: 'doodle-pad',
      active: false,
      header: strings.BIOMETRICS_GROUPS_HEADER(),

      navigation_left: config.strings.BACK(),
      navigation_left_icon: '',
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'back',

      //navigation_left_pane: 1,

      //if we can edit the service lets do that.
      navigation_right_template: 'back',
      navigation_right: strings.CANCEL(),
      navigation_right_data: 0,

      navbar: false,
    },
    rows: [
      {
        template: 'block-group-avatar',
        fields: {
          avatar: groupIcon,
          class: 'no-border',
        },
      },
      primaryText(strings.ADD_SERVICE_DRAW_DOODLE()(group.name || ''), true, false, true),
      {
        template: 'block-section-row',
        fields: { class: 'no-border' },
        columns: [{ template: 'block-doodlepad' }],
      },
      {
        template: 'block-doodlepad-buttons',
        fields: {
          confirm_data: nav + 1,
          data_id: dataId,
          confirm_template: 'enableBiometricsConfirm',
          confirm_validation: 'validateDoodlepad',
          //confirm_action: confirmAction || 'goToServiceCodesAfterDelay',
        },
      },
    ],
  };
};
