import { active } from 'domain';

const showToastMessage = require('../../action_helpers/showToastMessage');
const strings = require('../../../config').strings;

module.exports = async ({ currentTarget, z2hApp }) => {
  // Get doodle that user drew
  let doodle;
  let doodleProm;
  if (!window.state.biometrics) {
    doodleProm = new Promise((resolve, reject) => {
      const shape = window.currentDoodlepad.getShape().shape;
      return resolve(shape);
    });
  } else {
    doodleProm = new Promise((resolve, reject) => {
      if (process.env.CORDOVA) {
        if (window.ss) {
          window.ss.get(
            (value) => {
              console.log('Stored doodle');
              return resolve(value);
            },
            () => {
              console.log('Failed to store doodle');
              return resolve(null);
            },
            window.state.selectedGroupId + '_doodle',
          );
        }
      } else {
        console.log("window.ss doesn't exist");
        doodle = localStorage.getItem(window.state.selectedGroupId + '_doodle');
        return resolve(doodle);
      }
    });
  }
  doodle = await doodleProm;

  let error = false;

  // Check that code is not empty
  if (!doodle) {
    showToastMessage(strings.ERROR_NO_DOODLE());
    error = true;
  }

  const paneDetails = window.getActivePaneDetails('#pane-2');
  if (error) {
    z2hApp.paneNavigation('addService_doodle', paneDetails.pane, paneDetails.activePaneIndex - 1);
  } else {
    const addNewService = require('./addNewService');

    const onSuccess = () =>
      //z2hApp.paneNavigation('addService_onboarding_complete', $('.overlay'));
      z2hApp.paneNavigation('viewService', paneDetails.pane, paneDetails.activePaneIndex + 2);

    const onFailure = () =>
      z2hApp.paneNavigation('addService_search', paneDetails.pane, paneDetails.activePaneIndex - 1);

    z2hApp.paneNavigation('addServicePassword_loading', paneDetails.pane, paneDetails.activePaneIndex + 1);
    addNewService({ z2hApp, onSuccess, onFailure });
  }
};
