module.exports = ({ element, section, z2hApp }) => {
  // Get username from row that was clicked on
  const website = $(section)
    .find('#add-service-website-input')
    .val();

  // Store selected username in state
  window.z2hApp.pageData.service.serviceData.website = website;

  // Go to next page
  const pane = 'addService_group';
  const selector = window.state.userData.activeOnboarding ? '.overlay' : '#pane-2';
  const innerSelector = window.state.userData.activeOnboarding ? '.overlay ' : '#pane-2-inner';
  // Display/transition to 'select icon' page
  const paneDetails = window.getActivePaneDetails(selector);

  z2hApp.paneNavigation(pane, paneDetails.pane, paneDetails.activePaneIndex + 1);
};
