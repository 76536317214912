const config = require('../../../config');
const strings = config.strings;

const { centeredMessage } = require('../../page_helpers/components');
// const { infoTextRow } = require('../../page_helpers/components');

/// TODO: This is a copy-and-tweak version of viewService.js. Most of it is repeated code that
/// could be DRYed up.

function fieldRow(name, key = '', id, first) {
  return {
    template: 'block-section-row',
    fields: {
      id: id,
      secondary_heading: name,
      action: 'copyPasswordAndGiveLink',
    },
    columns: [
      {
        template: 'block-text-group',
        fields: {
          class: 'monospace',
          primary_text: key
            .split('')
            .map(c => '●')
            .join(''),
        },
      },
      {
        template: 'block-icn-button',
        fields: {
          icon: 'copy',
          action: 'copyPasswordAndGiveLink',
          // Only show the 'Click here to copy...' tooltip for the first forghettible on the screen
          tooltip: first ? strings.SERVICE_SUMMARY_FIRST_LOGIN_PASSWORD_TOOLTIP() : null,
        },
      },
      {
        template: 'block-icn-button',
        fields: { icon: 'show', action: 'startViewPasswordTimer' },
      },
    ],
  };
}

module.exports = ({ data, doneButton }) => {
  const z2hApp = window.z2hApp;

  // After a couple of seconds, show a tooltip on the 'Add login' button
  setTimeout(_ => {
    z2hApp.displayTooltip(
      $('[data-actionclick="beginAddNewService"] span'),
      config.strings.CREATE_ACCOUNT_ADD_ANOTHER_LOGIN_TOOLTIP(),
    );
  }, 3000);

  const fieldsToDisplay = [];

  // Add rows for each field/forghettible to be shown
  const fields = data.fields || [];
  fields.forEach((f, i) => {
    fieldsToDisplay.push(fieldRow(f.name, f.access_key, f.id, i === 0));
  });

  // Either display a 'Back' button on the left, or a 'Done' button
  // on the right.
  let templateFields = {
    id: 'view-service',
    active: false,
    header: strings.SERVICE_VIEW_HEADER(),
    help_id: 'HELP_SERVICE_SUMMARY',
  };

  if (doneButton) {
    templateFields = {
      ...templateFields,
      navigation_left: strings.BACK(),
      navigation_left_data: 0,
      navigation_left_template: 'back',
    };
  } else {
    templateFields = {
      ...templateFields,
      navigation_right: strings.SERVICE_VIEW_RIGHT_NAV(),
      navigation_right_data: 0,
      navigation_right_template: 'back',
    };
  }

  const groupsList = window.state.groupsList || [];
  const personalServices = (groupsList.find(g => g.personal) || {}).services || [];
  const isFirstLogin = personalServices.length === 0;
  return {
    template: 'block-section-page',
    fields: templateFields,
    rows: [
      isFirstLogin && centeredMessage(config.strings.SERVICE_SUMMARY_FIRST_LOGIN_MSG1()),
      centeredMessage(config.strings.SERVICE_SUMMARY_FIRST_LOGIN_MSG2()),
      data.username && {
        template: 'block-section-row',
        fields: {
          secondary_heading: strings.SERVICE_USERNAME_LABEL(),
          action: 'copyServiceUsernameToClipboard',
        },
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_text: data.username },
          },
          {
            template: 'block-icn-button',
            fields: { icon: 'copy', action: 'copyServiceUsernameToClipboard' },
          },
        ],
      },
      data.website && {
        template: 'block-section-row',
        fields: {
          secondary_heading: strings.SERVICE_WEBSITE_LABEL(),
          action: 'openWebsite',
        },
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_text: data.website },
          },
          {
            template: 'block-icn-button',
            fields: { icon: 'up', action: 'openWebsite' },
          },
        ],
      },
      ...fieldsToDisplay,
    ],
  };
};
