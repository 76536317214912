const { app, urls, strings } = require('../../config');

// =================================================================================================
const hasLegacy = (data, badge, noMaxCheck) => {
  return app.LEGACY_USER_TIMESTAMP >= data.user.joined_at;
};
const hasHealthCheck = (data, badge, noMaxCheck) => {
  return localStorage.getItem('healthcheck-complete') || data.user.healthcheck;
};

const hasProfileComplete = (data, badge, noMaxCheck) => {
  //fix this later
  let score = 0;
  //Create account
  score += 1;
  //signature doodle
  if (data.myServicesLength > 0) score += 1;
  //first login
  if (data.myServicesLength > 0) score += 1;
  //reset password
  //if (window.state.userData.resetPasswordSelected) score += 1;
  score += 1;
  //healthcheck
  if (localStorage.getItem('healthcheck-complete') || data.user.healthcheck) score += 1;
  return score >= 5;
};
const hasRating = (data, badge, noMaxCheck) => {
  return data.launchData.home.step_rated_on_store || localStorage.getItem('step_rated_on_store');
};
const hasImport = (data, badge, noMaxCheck) => {
  return false;
};

const hasSuperUser = (data, badge, noMaxCheck) => {
  return (
    data.sections.referred_amount >= 5 &&
    data.mySharedGroups >= 2 &&
    data.launchData.home.premium &&
    data.myServicesLength >= 50
  );
};

const hasServices = (data, badge, noMaxCheck) => {
  return noMaxCheck
    ? data.myServicesLength >= badge.checkMin
    : (!badge.checkMin || data.myServicesLength >= badge.checkMin) &&
        (!badge.checkMax || data.myServicesLength <= badge.checkMax);
};
const hasSharing = (data, badge, noMaxCheck) => {
  return noMaxCheck
    ? data.sections.referred_amount >= badge.checkMin
    : (!badge.checkMin || data.sections.referred_amount >= badge.checkMin) &&
        (!badge.checkMax || data.sections.referred_amount <= badge.checkMax);
};

const hasSocialSharing = (data, badge, noMaxCheck) => {
  return localStorage.getItem('social-sharing') || data.launchData.home.step_shared_on_social_media;
};

const hasSharedGroups = (data, badge, noMaxCheck) => {
  let groups = noMaxCheck
    ? data.mySharedGroups >= badge.checkMin
    : (!badge.checkMin || data.mySharedGroups >= badge.checkMin) &&
      (!badge.checkMax || data.mySharedGroups <= badge.checkMax);
  return groups;
};

const badges = [
  {
    id: 'legacy',
    heading: strings.BADGE_LEGACY_HEADING,
    info: '', //strings.BADGE_LEGACY_INFO(),
    info_complete: strings.BADGE_LEGACY_INFO_COMPLETE,
    reward: '',
    cta_text: strings.BADGE_SHARING_CTA,
    badge_active: 'active/badge19.gif',
    active: hasLegacy,
  },
  {
    id: 'super_user',
    heading: strings.BADGE_SUPERUSER_HEADER,
    info: strings.BADGE_SUPERUSER_INFO,
    info_complete: strings.BADGE_SUPERUSER_COMPLETE_INFO,
    reward: strings.BADGE_SUPERUSER_REWARD,
    cta_text: strings.BADGE_SUPERUSER_CTA,
    badge_active: 'active/18.gif',
    badge_disabled: 'disabled/18.png',

    active: hasSuperUser,
    cta: 'badgeGotoAction',
    cta_data: 'inviteAFriend',
  },
  {
    id: 'services_50',
    heading: strings.BADGE_SERVICES_50_HEADER,
    info: strings.BADGE_SERVICES_50_INFO,
    info_complete: strings.BADGE_SERVICES_50_COMPLETE_INFO,
    reward: strings.BADGE_SERVICES_50_REWARD,

    cta_text: strings.BADGE_SERVICES_50_CTA,
    badge_active: 'active/13.gif',
    badge_disabled: 'disabled/13.png',
    group: 'services',
    checkMin: 50,
    checkMax: 149,
    active: hasServices,
    cta: 'badgeGotoAction',

    cta_data: 'addNewService',
  },
  {
    id: 'services_150',
    heading: strings.BADGE_SERVICES_150_HEADER,
    info: strings.BADGE_SERVICES_150_INFO,
    info_complete: strings.BADGE_SERVICES_150_COMPLETE_INFO,
    reward: strings.BADGE_SERVICES_150_REWARD,

    cta_text: strings.BADGE_SERVICES_150_CTA,
    badge_active: 'active/14.gif',
    badge_disabled: 'disabled/14.png',
    group: 'services',
    checkMin: 150,
    checkMax: 299,
    active: hasServices,
    cta: 'badgeGotoAction',

    cta_data: 'addNewService',
  },
  {
    id: 'services_300',
    heading: strings.BADGE_SERVICES_300_HEADER,
    info: strings.BADGE_SERVICES_300_INFO,
    info_complete: strings.BADGE_SERVICES_300_COMPLETE_INFO,

    reward: strings.BADGE_SERVICES_300_REWARD,

    cta_text: strings.BADGE_SERVICES_300_CTA,
    badge_active: 'active/15.gif',
    badge_disabled: 'disabled/15.png',
    group: 'services',
    checkMin: 300,
    checkMax: 499,
    active: hasServices,
    cta: 'badgeGotoAction',
    cta_data: 'addNewService',
  },
  {
    id: 'services_500',
    heading: strings.BADGE_SERVICES_500_HEADER,
    info: strings.BADGE_SERVICES_500_INFO,
    info_complete: strings.BADGE_SERVICES_500_COMPLETE_INFO,
    reward: strings.BADGE_SERVICES_500_REWARD,

    cta_text: strings.BADGE_SERVICES_500_CTA,
    badge_active: 'active/16.gif',
    badge_disabled: 'disabled/16.png',
    group: 'services',
    checkMin: 500,
    active: hasServices,
    cta: 'badgeGotoAction',
    cta_data: 'addNewService',
  },
  {
    id: 'sharing_1',
    heading: strings.BADGE_SHARING_1_HEADER,
    info: strings.BADGE_SHARING_1_INFO,
    info_complete: strings.BADGE_SHARING_1_COMPLETE_INFO,
    reward: strings.BADGE_SHARING_1_REWARD,

    cta_text: strings.BADGE_SHARING_1_CTA,
    badge_active: 'active/02.gif',
    badge_disabled: 'disabled/02.png',
    group: 'sharing',
    checkMin: 1,
    checkMax: 4,
    active: hasSharing,
    cta: 'badgeGotoAction',
    cta_data: 'inviteAFriend',
  },
  {
    id: 'sharing_5',
    heading: strings.BADGE_SHARING_5_HEADER,
    info: strings.BADGE_SHARING_5_INFO,
    info_complete: strings.BADGE_SHARING_5_COMPLETE_INFO,
    reward: strings.BADGE_SHARING_5_REWARD,

    cta_text: strings.BADGE_SHARING_5_CTA,
    badge_active: 'active/03.gif',
    badge_disabled: 'disabled/03.png',
    group: 'sharing',
    checkMin: 5,
    checkMax: 9,
    active: hasSharing,

    cta: 'badgeGotoAction',
    cta_data: 'inviteAFriend',
  },
  {
    id: 'sharing_10',
    heading: strings.BADGE_SHARING_10_HEADER,
    info: strings.BADGE_SHARING_10_INFO,
    info_complete: strings.BADGE_SHARING_10_COMPLETE_INFO,
    reward: strings.BADGE_SHARING_10_REWARD,

    cta_text: strings.BADGE_SHARING_10_CTA,
    badge_active: 'active/04.gif',
    badge_disabled: 'disabled/04.png',
    group: 'sharing',
    checkMin: 10,
    checkMax: 24,
    active: hasSharing,

    cta: 'badgeGotoAction',
    cta_data: 'inviteAFriend',
  },
  {
    id: 'sharing_25',
    heading: strings.BADGE_SHARING_25_HEADER,
    info: strings.BADGE_SHARING_25_INFO,
    info_complete: strings.BADGE_SHARING_25_COMPLETE_INFO,
    reward: strings.BADGE_SHARING_25_REWARD,

    cta_text: strings.BADGE_SHARING_25_CTA,
    badge_active: 'active/05.gif',
    badge_disabled: 'disabled/05.png',
    group: 'sharing',
    checkMin: 25,
    checkMax: 49,
    active: hasSharing,

    cta: 'badgeGotoAction',
    cta_data: 'inviteAFriend',
  },
  {
    id: 'sharing_50',
    heading: strings.BADGE_SHARING_50_HEADER,
    info: strings.BADGE_SHARING_50_INFO,
    info_complete: strings.BADGE_SHARING_50_COMPLETE_INFO,
    reward: strings.BADGE_SHARING_50_REWARD,

    cta_text: strings.BADGE_SHARING_50_CTA,
    badge_active: 'active/06.gif',
    badge_disabled: 'disabled/06.png',
    group: 'sharing',
    checkMin: 50,
    active: hasSharing,

    cta: 'badgeGotoAction',
    cta_data: 'inviteAFriend',
  },
  {
    id: 'group_1',
    heading: strings.BADGE_GROUP_1_HEADER,
    info: strings.BADGE_GROUP_1_INFO,
    info_complete: strings.BADGE_GROUP_1_COMPLETE_INFO,
    reward: strings.BADGE_GROUP_1_REWARD,

    cta_text: strings.BADGE_GROUP_1_CTA,
    badge_active: 'active/10.gif',
    badge_disabled: 'disabled/10.png',
    group: 'sharedGroups',
    checkMin: 1,
    checkMax: 4,
    active: hasSharedGroups,
    cta: 'badgeGotoAction',
    cta_data: 'newGroupNavigate',
  },
  {
    id: 'group_5',
    heading: strings.BADGE_GROUP_5_HEADER,
    info: strings.BADGE_GROUP_5_INFO,
    info_complete: strings.BADGE_GROUP_5_COMPLETE_INFO,
    reward: strings.BADGE_GROUP_5_REWARD,
    cta_text: strings.BADGE_GROUP_5_CTA,
    badge_active: 'active/11.gif',
    badge_disabled: 'disabled/11.png',
    group: 'sharedGroups',
    checkMin: 5,
    active: hasSharedGroups,
    cta: 'badgeGotoAction',
    cta_data: 'newGroupNavigate',
  },
  {
    id: 'healthcheck',
    heading: strings.BADGE_HEALTHCHECK_HEADER,
    info: strings.BADGE_HEALTHCHECK_INFO,
    info_complete: strings.BADGE_HEALTHCHECK_COMPLETE_INFO,
    cta_text: strings.BADGE_HEALTHCHECK_CTA,
    badge_active: 'active/08.gif',
    badge_disabled: 'disabled/08.png',

    active: hasHealthCheck,
    cta: 'badgeGotoPane2Template',
    cta_data: 'healthcheck_usernames',
  },
  {
    id: 'rating',
    heading: strings.BADGE_RATING_HEADER,
    info: strings.BADGE_RATING_INFO,
    info_complete: strings.BADGE_RATING_COMPLETE_INFO,
    reward: strings.BADGE_RATING_REWARD,
    cta_text: strings.BADGE_RATING_CTA,
    badge_active: 'active/07.gif',
    badge_disabled: 'disabled/07.png',

    active: hasRating,
    cta: window.cordova ? 'appRating' : 'openBannerUrlRate',
    cta_data: 'https://uk.trustpilot.com/review/www.forghetti.com',
  },
  {
    id: 'profile_complete',
    heading: strings.BADGE_PROFILECOMPLETE_HEADER,
    info: strings.BADGE_PROFILECOMPLETE_INFO,
    info_complete: strings.BADGE_PROFILECOMPLETE__COMPLETE_INFO,
    reward: strings.BADGE_PROFILECOMPLETE_REWARD,
    cta_text: strings.BADGE_PROFILECOMPLETE_CTA,
    badge_active: 'active/09.gif',
    badge_disabled: 'disabled/09.png',

    active: hasProfileComplete,
    cta: 'badgeGotoPane2Template',
    cta_data: 'viewProfile',
  },
  {
    id: 'sharing',
    heading: strings.BADGE_SHARING_HEADER,
    info: strings.BADGE_SHARING_INFO,
    info_complete: strings.BADGE_SHARING_COMPLETE_INFO,
    reward: strings.BADGE_SHARING_REWARD,
    cta_text: strings.BADGE_SHARING_CTA,
    badge_active: 'active/12.gif',
    badge_disabled: 'disabled/12.png',
    active: hasSocialSharing,
    cta: 'openBannerUrlSocial',
    cta_data: 'https://www.facebook.com/forghetti/',
  },

  // {
  //   id: 'import',
  //   heading: strings.BADGE_IMPORT_HEADER,
  //   info: strings.BADGE_IMPORT_INFO,
  //   info_complete: strings.BADGE_IMPORT_COMPLETE_INFO,
  //   reward: strings.BADGE_IMPORT_REWARD,
  //   cta_text: strings.BADGE_IMPORT_CTA,
  //   badge_active: 'active/17.gif',
  //   badge_disabled: 'disabled/17.png',
  //   active: hasImport,
  //   cta: '',
  // },
];
// =================================================================================================

function initialise() {
  //We're going to need a bunch of things so we can work out the state of the badges.

  //First of all, and most importantly launch data...
  const userPromise = window.state.userDataPromise || z2hApp.fetchFullUserDetails();

  const groupsPromise = window.state.fullUserGroupsPromise || z2hApp.reloadFullGroupsList();

  const launchPromise = window.state.launchDataPromise || z2hApp.fetchInitialLaunchData();

  const sectionsPromise = window.state.updateSectionsPromise || z2hApp.reloadUpdateSections();

  window.state.badges = badges;

  const allPromises = Promise.all([userPromise, groupsPromise, launchPromise, sectionsPromise]);
  return allPromises.then((values) => {
    const myGroups = window.state.groupsList.filter((g) => window.state.userData.id === g.owner_id);
    const myServicesLength = window.state.updateSections.your_journey_data.services;

    //const myServicesLength = 301;
    //From all your groups, the number of users who are not you!
    const mySharedGroups = myGroups.length
      ? myGroups
          .map((g) => g.users)
          .filter((u) => {
            if (!u) return false;
            return Object.keys(u).length > 1;
          }).length
      : 0;

    //console.log(window.state.updateSections.your_journey_data);
    return {
      badges: window.state.badges,
      myGroups,
      myServicesLength,
      mySharedGroups,
      user: window.state.userData,
      launchData: window.state.launchData,
      sections: window.state.updateSections && window.state.updateSections.your_journey_data,
    };
  });
}

const buildBadge = (badge, active) => ({
  template: 'block-badge',
  selector: '',
  attributes: [
    {
      selector: '.badge',
      type: 'src',
      content: active ? `img/badges/${badge.badge_active}` : `img/badges/${badge.badge_disabled}`,
    },
    { selector: '.badge', type: 'data-id', content: badge.id },

    { selector: '.badge', type: 'data-actionid', content: badge.cta_data },
    { selector: '.badge', type: 'data-actionclick', content: 'selectBadge' },
  ],
});

async function displayBadges() {
  if (!window.state.groupsList) return;
  const data = await initialise();
  const badges = window.state.badges;

  const container = $('#main-nav-badges').find('.column-group');
  container.empty();

  let activeBadges = [];
  let disabledBadges = [];

  //Do the menu... we're going to split our badges
  //into active and disabled badges (not the parking kind)
  for (const b of badges) {
    if ((typeof b.active === 'function' && b.active(data, b)) || (typeof b.active === 'boolean' && b.active)) {
      activeBadges.push(b);
      //Don't include badges which don't have a disabled.
    } else if (b.badge_disabled) {
      disabledBadges.push(b);
    }
  }

  //Work out a list of groups based on our array...
  const groups = badges.map((b) => b.group).filter((b, i, groups) => groups.indexOf(b) === i);
  //Now get a list of groups which are active and msissing...
  //Not here we are keeping the index for our check below!

  //In the menu we only want to show one of badges that belong to the group

  //e.g. for services we have 50,150,300,500 and we only
  //want to show the 500 services badge and not all the others...
  const activeGroups = activeBadges.map((b) => b.group);

  //indexes... old school baby!
  let index = 0;
  let added = 0;
  for (const b of activeBadges) {
    if (added == 5) break;
    if (!b.group) {
      added += 1;
      container.append(window.z2hApp.constructBlock(buildBadge(b, true)));
    } else if (index === activeGroups.lastIndexOf(b.group)) {
      added += 1;
      container.append(window.z2hApp.constructBlock(buildBadge(b, true)));
    }
    index += 1;
  }

  //Now we want to make sure all the groups have a displayed badge (up to 5 groups)
  //We're going to find out what groups are missing (groups take precedence over non groups badges),
  //Loop through our 'disabled' badges and add THE FIRST ONE that
  //belong to a group. THE FIRST ONE is the lowest value

  //e.g. for service the user has 10 services, we want to display the
  //50 service badge disabled, and not the 150,300,500...
  const menuBadges = [...activeBadges];
  let missingGroups = groups.filter((g) => activeBadges.map((e) => e.group).indexOf(g) < 0);
  for (const b of disabledBadges) {
    if (menuBadges.length >= 5) {
      break;
    }
    if (missingGroups.indexOf(b.group) >= 0) {
      menuBadges.push(b);
      container.append(window.z2hApp.constructBlock(buildBadge(b, false)));
      missingGroups = groups.filter((g) => menuBadges.map((e) => e.group).indexOf(g) < 0);
    }
  }
  //Now let's add in some of the disabled non group badges
  //so we make it up to 5.
  if (menuBadges.length < 5) {
    for (const b of badges) {
      if (menuBadges.length >= 5) {
        break;
      }
      if (!b.badge_disabled) continue;
      if (!b.group && menuBadges.length < 5) {
        if (menuBadges.map((m) => m.id).indexOf(b.id) < 0) {
          menuBadges.push(b);
          container.append(window.z2hApp.constructBlock(buildBadge(b, false)));
        }
      }
    }
  }
  $('#main-nav-badges').removeClass('loading-nav-badges');

  //We've done the menu stuff
  //It's time to sort out the badges screen
  //from profile...
  //This should be made generic.
  activeBadges = [];
  disabledBadges = [];

  for (const b of badges) {
    if ((typeof b.active === 'function' && b.active(data, b, true)) || (typeof b.active === 'boolean' && b.active)) {
      activeBadges.push(b);
    } else if (b.badge_disabled) {
      disabledBadges.push(b);
    }
  }

  const pages = $('.profile-badges');
  pages.each((i, elem) => {
    $(elem).removeClass('loading-wheel');
    const $elem = $(elem).find('.column-group');
    $elem.empty();
    for (const b of activeBadges) {
      $elem.append(window.z2hApp.constructBlock(buildBadge(b, true)));
    }
    for (const b of disabledBadges) {
      $elem.append(window.z2hApp.constructBlock(buildBadge(b, false)));
    }
  });
}

function getBadgeJSON() {
  return badges;
}
function findBadgeById(id) {
  return getBadgeJSON().find((b) => b.id === id);
}
async function isBadgeActive(id) {
  const data = await initialise();
  const badge = getBadgeJSON().find((b) => b.id === id);
  return badge.active(data, badge, true);
}
// Whenever a new page is displayed, check if it contains any components we need to initialise
$('body').on('z2hApp.launched', () => {
  displayBadges();
});

module.exports = { getBadgeJSON, isBadgeActive, findBadgeById };
