const config = require('../../../config');
const strings = config.strings;

module.exports = ({ data, nav }) => {
  return {
    template: 'block-section-page',
    fields: {
      id: 'view-help-list',
      header: strings.PROFILE_HELP_HEADER(),
      navigation_left: strings.BACK(),
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'back',
    },
    rows: [
      // Get online help =======================================================
      {
        template: 'block-section-row',
        fields: {
          action: 'openHowItWorksUrl',
        },
        columns: [
          {
            template: 'block-text-group',
            fields: {
              primary_text: strings.PROFILE_REONBOARDING(),
            },
          },
        ],
      },
      // Get online help =======================================================
      {
        template: 'block-section-row',
        fields: {
          action: 'gotoReonboarding',
        },
        columns: [
          {
            template: 'block-text-group',
            fields: {
              primary_text: strings.HELP_QUICKSTART(),
            },
          },
        ],
      },

      // Get online help =======================================================
      {
        template: 'block-section-row',
        fields: {
          action: 'openHelpUrl',
        },
        columns: [
          {
            template: 'block-text-group',
            fields: {
              primary_text: strings.PROFILE_GET_ONLINE_HELP(),
            },
          },
        ],
      },
      // // Get video online help =================================================
      // {
      //   template: 'block-section-row',
      //   fields: {
      //     action: 'openVideoHelpUrl',
      //   },
      //   columns: [
      //     {
      //       template: 'block-text-group',
      //       fields: {
      //         primary_text: strings.PROFILE_GET_VIDEO_HELP(),
      //       },
      //     },
      //   ],
      // },
    ],
  };
};
