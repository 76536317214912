const strings = require('../../../config').strings;

// =================================================================================================
/**
 * Row to be displayed per user preference
 * @param {string} name Name of user preference as held in local storage
 * @param {string} label Label text to be displayed next to toggle/switch
 */
function prefRow(name, label, dflt = true) {
  let enabled = dflt;
  const val = localStorage.getItem('userprefs-' + name);
  if (val === 'true' || val === true) enabled = true;
  if (val === 'false' || val === false) enabled = false;

  return {
    template: 'block-section-row',
    columns: [
      {
        template: 'block-text-group',
        fields: { primary_text: label },
      },
      {
        template: 'block-toggle-switch',
        fields: {
          data_id: name,
          checked: enabled,
          action: name === 'touchid' ? 'toggleTouchId' : 'toggleUserPreference',
        },
      },
    ],
  };
}
// =================================================================================================

module.exports = ({ data }) => {
  //console.log('data: ', data);
  const { touchIdAvailable } = data || {};
  //TODO add notification state
  const { notifications } = data || {};
  let region = localStorage.getItem('language');

  //window.state && window.state.userData && window.state.userData.region;
  //region = (region || '').trim();
  //region = region || 'gb';

  const hideLanguage = false;

  const touch = localStorage.getItem('userprefs-touchid');
  const touchAlreadySet = touch === 'true' || touch === true;

  return {
    template: 'block-section-page',
    fields: {
      id: 'view-user-preferences',
      header: strings.APP_PREFERENCES_HEADER(),

      navigation_left: strings.BACK(),
      navigation_left_data: '0',
      navigation_left_template: 'back',
      navbar: false
    },
    rows: [
      // {
      //   template: 'block-section-row',
      //   fields: { secondary_heading: strings.APP_PREFERENCES_DEFAULT_USERNAME },
      //   columns: [
      //     {
      //       template: 'block-editable-primary-text',
      //       fields: {
      //         placeholder: strings.USER_PREFERENCES_DEFAULT_USERNAME_PLACEHOLDER,
      //         primary_text: localStorage.getItem('default-username') || '',
      //         action: 'updateDefaultUsername',
      //         for: 'default-username',
      //         id: 'default-username',
      //       },
      //     },
      //   ],
      // },

      {
        template: 'block-section-row',
        fields: {
          secondary_heading: strings.APP_PREFERENCES_THEME(),
        },
        columns: [
          // Output radio buttons
          ...[
            { mode: 'dark', color: 'green' },
            { mode: 'dark', color: 'yellow' },
            { mode: 'dark', color: 'blue' },
            { mode: 'dark', color: 'red' },
            { mode: 'dark', color: 'orange' },
            { mode: 'light', color: 'blue' },
            { mode: 'light', color: 'red' },
            { mode: 'light', color: 'orange' },
          ].map((theme) => ({
            template: 'block-radio-button',
            fields: { gradient: true, color: theme.color, mode: theme.mode },
          })),
        ],
      },

      !hideLanguage && {
        template: 'block-section-row',
        fields: { secondary_heading: strings.APP_PREFERENCES_COUNTRY() },
        columns: [
          {
            template: 'block-country-input',
            fields: {
              id: 'userpref-country',
              value: region,
              action: 'confirmUpdateCountry',
              languages: true,
              readonly: true,
            },
          },
        ],
      },
      prefRow('secretdoodle', strings.APP_PREFERENCES_SECRET_DOODLE(), false),
      //prefRow('quickselect', strings.APP_PREFERENCES_SHOW_QUICK_SELECT),
      //prefRow('suggestions', strings.APP_PREFERENCES_SHOW_SUGGESTIONS),
      (touchIdAvailable || touchAlreadySet) && prefRow('touchid', strings.APP_PREFERENCES_REQUIRE_TOUCH_ID(), false),
      notifications &&
        notifications.permission == false && {
          template: 'block-section-row',
          fields: {
            navigation_action: 'requestPushNotifications',
          },
          columns: [
            { template: 'block-text-group', fields: { primary_text: strings.APP_PREFERENCES_NOTIFICATIONS() } },
            { template: 'block-icn-button', fields: { icon: 'chevron-right' } },
          ],
        },
    ],
  };
};
