const config = require('../../../config');

module.exports = ({ data, nav }) => {
  return {
    template: 'block-section-page',
    fields: {
      id: 'account-security',
      header: config.strings.ACCOUNT_SECURITY_HEADER(),
      navigation_left: config.strings.BACK(),
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'back',
      navbar: false,
    },
    rows: [
      {
        template: 'block-section-row',
        fields: {
          class: 'no-border',
          class2: 'centered-text',
        },
        columns: [
          {
            template: 'block-icn-big',
            fields: { icon: 'lock' },
          },
        ],
      },
      {
        template: 'block-section-row',
        columns: [
          {
            template: 'block-primary-heading',
            fields: { heading: config.strings.ACCOUNT_SECURITY_HEADING() },
          },
        ],
      },
      {
        template: 'block-section-row',
        columns: [
          {
            template: 'block-text-group',
            fields: {
              primary_text: config.strings.ACCOUNT_SECURITY_SECONDARY_HEADING(),
              secondary_class: 'nocolour',
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        columns: [
          {
            template: 'block-text-button',
            fields: {
              text: config.strings.ACCOUNT_SECURITY_LEARN_MORE(),
              action: 'openAccountSecurityUrl',
            },
          },
        ],
      },
    ],
  };
};
