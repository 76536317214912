const getAccessKeys = require('./getAccessKeys');

module.exports = (id) => {
  let z2hApp = window.z2hApp;
  // Get selected service ID and doodle
  const serviceId = id || window.state.selectedServiceId;
  let service = { fields: {} };

  // Fetch service details from server, along with fields and access keys
  return z2hApp
    .requestData(`get`, ``, `services/${serviceId}`)
    .then((response) => {
      service = response.data || {};
      service.id = serviceId;
      service.fields = {};
      if (!window.serviceFieldPromises) window.serviceFieldPromises = {};
      window.serviceFieldPromises[serviceId] = z2hApp.requestData(`get`, ``, `services/${serviceId}/fields`);
      return window.serviceFieldPromises[serviceId];
    })
    .then((response) => {
      service.fields = response.data;
      //return getAccessKeys(serviceId, doodle);
      z2hApp.pageData.service = service;
      return Promise.resolve(service);
    });
};
