const { paneNavigation } = require('../../app/z2hApp');

module.exports = ({ element, section, z2hApp }) => {
  // Get username from row that was clicked on
  let username = null;

  username = $('#add-service-username-input').val();

  let username_secondary = null;

  username_secondary = $('#add-service-username-input-secondary').val();

  // Store selected username in state
  window.z2hApp.pageData.service.serviceData.username = username;
  window.z2hApp.pageData.service.serviceData.username_secondary = username_secondary;

  // Display/transition to 'select icon' page
  let activePaneIndex = $('#pane-2-inner .active').index();
  let pane = '#pane-2';
  if ($('.overlay').is(':visible')) {
    pane = '.overlay';
    activePaneIndex = $('#overlay .active').index();
  }

  const { biometricsOrDoodle } = require('../../actions/services');
  biometricsOrDoodle({
    currentTarget: null,
    z2hApp,
    pane: $(pane),
    newPaneIndex: activePaneIndex + 1,
    template: { biometrics: 'addService_biometrics', doodlepad: 'addService_doodle' },
  });
};
