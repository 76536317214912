const config = require('../../../config');
const { infoTextRow, thirdheading, primaryText } = require('../../page_helpers/components');

const strings = require('../../../config').strings;

module.exports = ({ nav }) => {
  window.state.biometrics = true;
  const user = window.state.userData;
  const movingService = window.z2hApp.pageData.service.movingService;
  const movingFromTo = window.state.movingService && window.state.movingService.from.service.doodle ? 'to' : 'from';

  const groupId =
    movingService && movingFromTo === 'from'
      ? window.z2hApp.pageData.service.currentGroupId
      : window.state.selectedGroupId;
  const group = window.state.groupsList.find((g) => g.id === groupId) || {};
  const service = window.z2hApp.pageData.service.serviceData;
  const onboarding = window.state.userData.activeOnboarding;

  const serviceName = service.name;
  const serviceIcon = service.icon || config.app.DEFAULT_SERVICE_ICON;

  const groupIcon = group.personal
    ? user.avatar || config.app.DEFAULT_USER_ICON
    : group.icon || config.app.DEFAULT_GROUP_ICON;

  const groupIconId = group.personal ? window.state.userData.id : group.id;
  const groupName = group.name || '';

  const showFingerint = () => {
    if (process.env.CORDOVA) {
      window.Fingerprint.show(
        {
          disableBackup: true,
          title: strings.UNLOCK_SERVICE()(serviceName),
          description: strings.UNLOCK_SERVICE_DESCRIPTION()(serviceName),
          disableBackup: true,
          fallbackButtonTitle: strings.UNLOCK_FALLBACK(),
          cancelButtonTitle: strings.UNLOCK_CANCEL()
        },
        fingerprintAuthSuccessCallback,
        fingerprintAuthErrorCallback,
      );
    }
  };
  showFingerint();

  function fingerprintAuthSuccessCallback(result) {
    const action = onboarding
      ? 'confirmDoodlePad_1'
      : movingService
      ? `moveService_${movingFromTo}DoodleSubmit`
      : 'doodlepad_submit';

    console.log('Fingerprint/touch ID success: ' + JSON.stringify(result));
    const actions = require('../../actions');
    z2hApp.paneNavigation('viewService_loading', 2, nav + 1);
    return actions[action]({
      z2hApp: window.z2hApp,
    });
  }

  function fingerprintAuthErrorCallback(error) {
    z2hApp.biometricsUseDoodle();
  }

  const biometricButtonRow = () => {
    const action = onboarding
      ? 'confirmDoodlePad_1'
      : movingService
      ? `moveService_${movingFromTo}DoodleSubmit`
      : 'doodlepad_submit';

    return !window.Fingerprint
      ? {
          template: 'block-biometrics-buttons',
          fields: {
            confirm_validation: 'validateDoodlepad',
            confirm_action: action,
          },
        }
      : {};
  };

  const headerFields = () => {
    let fields = {
      id: onboarding ? 'doodle-pad-onboarding' : movingService ? `moveService_${movingFromTo}Doodle` : 'doodle-pad',
      class: 'no-title',
      navbar: false,
    };
    if (!onboarding) {
      fields = {
        ...fields,
        header: serviceName,

        navigation_left: strings.BACK(),
        navigation_left_data: Math.max(nav - 1, 0),
        navigation_left_template: 'back',
      };
    }
    return fields;
  };

  const rows = [];

  //onboarding && rows.push(thirdheading({ text: strings.ADD_SERVICE_DOODLE_ONBOARD_NOW_ITS_YOUR_TURN, additionalClass: 'centered-text' }));
  onboarding && rows.push(infoTextRow(strings.ADD_SERVICE_DOODLE_CREATE_DOODLE(), true, false));

  rows.push({
    template: 'block-avatar-group',
    fields: {
      avatar_src_1: serviceIcon,
      avatar_src_2: groupIcon,
      avatar_id_1: service.id,
      avatar_id_2: groupIconId,
      avatar_name_1: serviceName,
      avatar_name_2: groupName,
      class: 'with-arrow',
    },
  });

  !onboarding && rows.push(primaryText(strings.ADD_SERVICE_DRAW_DOODLE()(groupName || ''), true, false, true));

  rows.push(
    {
      template: 'block-onboarding-image',
      fields: {
        imagePath: 'img/icons/colour-1/forghetti-ui_fingerprint_normal.svg',
        class: 'doodlepad',
        style: JSON.stringify({ flexGrow: 0 }),
      },
    },
    biometricButtonRow(),
  );

  return {
    template: onboarding ? 'block-section-onboarding' : 'block-section-page',
    fields: headerFields(),
    rows: rows,
  };
};
