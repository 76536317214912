const cryptLib = require('@skavinvarnan/cryptlib');
const JSEncrypt = require('jsencrypt').default;
const jsEncrypt = new JSEncrypt();

module.exports = (serviceId, doodle) => {
  const z2hApp = window.z2hApp;

  // We need to make a request to forghetti for the passwords/access keys for this service.
  // The raw request is as follows:
  const request = { service_id: serviceId, shape: doodle || ` ` };

  //update the last used timestamp on the service.
  const group = window.state.groupsList.find((group) => group.id === window.state.selectedGroupId);
  const services = group.services || [];
  const servObj = services.find((service) => service.id === serviceId);
  if (servObj) servObj.last_used = Date.now();
  //z2hApp.showServices(services, window.state.selectedGroupId, true);
  //z2hApp.highlightNavElement($('#main-nav').find('[data-id=' + window.state.selectedGroupId + ']'));

  // We need to generate an AES key to encrypt the data we are sending to the server
  const aesKeyAsString = window.crypto.getRandomValues(new Uint32Array(10)).join();

  // Encrypt the raw request (containing the doodle) with AES
  const requestStr = JSON.stringify(request);
  const aesEncryptedRequest = cryptLib.encryptPlainTextWithRandomIV(requestStr, aesKeyAsString);

  // Encrypt our AES key using the server's public RSA key
  const rsaPublicKey = localStorage.getItem('pubkey') || window.state.launchData.encryption_data.public_key;

  jsEncrypt.setPublicKey(rsaPublicKey);
  const rsaEncryptedAesKey = jsEncrypt.encrypt(aesKeyAsString);

  const apiRequest = {
    data: aesEncryptedRequest,
    key: rsaEncryptedAesKey,
    encryption_version: 1,
    encrypted: true,
  };

  console.info('Calling access/generate endpoint...');
  // Send the request and decrypt the response using the same AES key we generated earlier
  return z2hApp.requestData(`post`, ``, `services/${serviceId}/retrieve-note`, apiRequest).then((response) => {
    if (response.status <= 299) {
      const dataString = cryptLib.decryptCipherTextWithRandomIV(response.data, aesKeyAsString);
      const data = JSON.parse(dataString);

      return data?.note;
    } else {
      throw response;
    }
  });
};
