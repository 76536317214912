module.exports = id => {
	let target = document.getElementById(id);
	
	if (!target) { return; }
	
  const style = target.style;
  if (!target.classList.contains('collapsed')) {
    style.maxHeight = target.scrollHeight + 'px';
    setTimeout(() => {
      target.classList.add('collapsed');
      style.overflow = 'hidden';
    }, 1);
	} 
	else {
    target.classList.remove('collapsed');
    style.maxHeight = 0;
    style.overflow = 'hidden';
    style.borderBottomColor = 'transparent';
    setTimeout(() => {
      style.maxHeight = target.scrollHeight + 'px';
      style.borderBottomColor = null;
      setTimeout(() => {
        style.maxHeight = null;
        style.overflow = 'inherit';
      }, 300);
    }, 1);
  }
};
