const z2hApp = require('../../app/z2hApp');
const strings = require('../../../config/strings');
const { type } = require('jquery');
const components = require('../../page_helpers/components');

module.exports = ({ data }) => {
  const notifications = data.notifications;
  const notes = notifications.map((notification) => {
    return components.notificationEvent(notification);
  });
  $('body').on('click', '[data-template="notificationsFromBell"]', (e) => {
    const id = $(e.currentTarget).attr('id');
    z2hApp.requestData('post', '', `notifications/mark-all-as-read`, {});
  });

  if (notes.length === 0) {
    notes.push(...components.notificationNoRow())
  }
  return {
    template: 'block-section-page',
    fields: {
      id: 'notifications_copy',
      header: strings.NOTIFICATIONS(),
      navigation_left: strings.BACK(),
      navigation_left_template: 'back',
      navigation_left_data: 0,
      navbar: false,
    },
    rows: notes.flat(),
  };
};
