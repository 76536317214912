module.exports = ({ z2hApp, section }) => {
  
  const promise = window.state.userDataPromise || z2hApp.fetchFullUserDetails();

  promise.then(() => {
    //let template = premium || !isAppStore ? 'viewYourSubscription' : 'premium';
    let template = 'premium';

    //if (window.state.userData.subscription.subscription_type === 'premium_trial') template = 'premium';
    const {restorePurchases, refreshUI} = require('../../app/iap');


      restorePurchases().then((purchases) => {
      console.log('RESTORE PURCHASES', purchases);
        refreshUI();
    });

    if (section.length) {
      const paneDetails = window.getActivePaneDetails('#pane-2');
      z2hApp.paneNavigation(template, $('#pane-2'), paneDetails.activePaneIndex + 1);
    } else {
      z2hApp.paneNavigation(template, $('#pane-2'), 1);
    }

  });
};
