

module.exports = () => {
  const z2hApp = window.z2hApp;

  return z2hApp.getGroupsList().then((groupsList) => {
    const groups = groupsList
      .filter((group) => group.name && group.users)
    return { groupsList: groups };
  });
};
