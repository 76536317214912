/// TODO: This file shares a lot in common with getPageData/viewService. Duplication could be reduced.
/// Perhaps with an improved getFullServiceDetails(?doodle) helper function

module.exports = ({}) => {
  // Get basic service details from our 'currentServices' list
  const serviceId = window.state.selectedServiceId;
  const services = window.state.currentServices;
  const service = services.find(s => s.id === serviceId);

  // Set this service as 'current'
  window.state.currentService = service;

  // Check for a background request which had been made to get the fields for this service
  window.serviceFieldPromises = window.serviceFieldPromises || {};
  window.serviceFieldPromises[serviceId] =
    window.serviceFieldPromises[serviceId] || z2hApp.requestData(`get`, '', `services/${serviceId}/fields`);

  return window.serviceFieldPromises[serviceId]
    .then(response => {
      service.fields = [];
      for (let fieldId in response.data) {
        service.fields.push({ ...response.data[fieldId], id: fieldId });
      }
      return service;
    })
    .then(service => {
      // We need to know if user can edit this service. To determine that we need to have a full
      // list of groups and their services.
      if (!window.state.fullUserGroupsPromise) {
        return Promise.resolve(service);
      }

      return window.state.fullUserGroupsPromise.then(groupsList => {
        // Array of objects. Each has 'services' array of objects. Find service with id of service.id
        const serviceId = service.id;
        const currentUserId = window.state.userData.id;
        const serviceGroup =
          (groupsList || []).find(g => {
            const groupServices = g.services;
            if (groupServices instanceof Array) {
              return groupServices.find(s => s.id === serviceId);
            }
            if (groupServices instanceof Object) {
              return groupServices[serviceId];
            }
            return false;
          }) || {};

        const serviceGroupUsers = serviceGroup.users || {};
        // Check if current user is in group.users (object) with member_type of 1 or 2 (numeric)
        const userMemberType = (serviceGroupUsers[currentUserId] || {}).member_type;
        if (userMemberType > 0) {
          service.editable = true;
        }
        return service;
      });
    });
};
