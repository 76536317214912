// This validator is probably not necessary as profile name can be blank?
module.exports = (section) => {
	errorManager.clearErrors(section)
	let error = false
	
	const nameField = $(section).find('[name=profile-name]')

	const value = nameField.val().trim()
	
	if(!value) {
		// TODO:
		errorManager.reportError('', nameField)
		error = true
	}
	
	if(error) {
		throw new Error("Validation error")
	}
}