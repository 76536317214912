const quickSelectAddLogin = require('./quickSelectAddLogin');
const addNewService = require('./addNewService');

module.exports = params => {
  // Take this opportunity to clear out pane3 for a better appearance when transitioning to that pane
  $('#pane-2-inner').empty();

  const quickSelect = localStorage.getItem('userprefs-quickselect') === 'false' ? false : true;
  if (quickSelect) {
    return quickSelectAddLogin(params);
  } else {
    return addNewService(params);
  }
};
