// =================================================================================================
// =================================================================================================
/// TODO: This screen shares a lot with the editServiceDetails screen, so could
/// be DRYed out a bit.
// =================================================================================================
// =================================================================================================

const config = require('../../../config');
const { WEBSITE_URL_MAXLENGTH, SERVIVCE_NAME_MAXLENGTH } = require('../../../config/app');

const { editableTextRow } = require('../../page_helpers/components');

const {
  SERVICE_EDIT_USERNAME_PLACEHOLDER,
  SERVICE_EDIT_WEBSITE_PLACEHOLDER,
  SERVICE_NAME_PLACEHOLDER,
  SERVICE_ADD_FORGHETTIBLE,
  SERVICE_USERNAME_LABEL,
  SERVICE_WEBSITE_LABEL,
  SERVICE_HEADER_NEW,
  BACK,
  NEXT,
  SERVICE_EDIT_EMAIL_PLACEHOLDER,
  SERVICE_EMAIL_LABEL,
  SERVICE_EMAIL_PLACEHOLDER,
} = config.strings;

// =================================================================================================

module.exports = ({ data, nav }) => {
  const serviceNameUid = window.z2hTools.getUid();
  const serviceUsernameUid = window.z2hTools.getUid();
  const serviceWebAddressUid = window.z2hTools.getUid();

  window.state.selectedServiceId = 'new';

  // Check if user wants to be shown service suggestions when typing login name
  const showSuggestions = localStorage.getItem('userprefs-suggestions') === 'false' ? false : true;

  // Get/set default username value
  const defaultUsername = localStorage.getItem('default-username') || '';
  z2hApp.pageData.service.serviceData.username = defaultUsername;

  return {
    template: 'block-section-page',

    fields: {
      active: false,
      id: 'add-new-service',
      header: SERVICE_HEADER_NEW(),

      navigation_left: BACK(),
      //navigation_left_action: 'hidePane3',
      navigation_left_template: 'back',
      navigation_left_data: Math.max(nav - 1, 0),

      navigation_right: NEXT(),
      navigation_right_data: nav + 1,
      navigation_right_validation: 'addNewService',
      navigation_right_action: 'generateNewServiceIcon',
      navigation_right_template: 'addNewService2_doodlepad',
    },

    rows: [
      {
        template: 'block-section-row',
        fields: {},
        columns: [
          {
            template: 'block-login-avatar',
            fields: {
              avatar: window.state.temporaryServiceIcon || config.app.DEFAULT_SERVICE_ICON,
              context: 'addServiceImage',
              data_id: 'temporaryServiceIcon',
            },
          },
          {
            template: 'block-editable-primary-text',
            fields: {
              id: serviceNameUid,
              for: serviceNameUid,
              action: 'newServiceName',
              placeholder: SERVICE_NAME_PLACEHOLDER(),
              maxlength: SERVIVCE_NAME_MAXLENGTH,
            },
          },
          {
            template: 'block-icn-button',
            fields: {
              icon: 'help-solid',
              data_id: 'HELP_LOGIN_TITLE',
              action: 'displayContextualHelp',
            },
          },
        ],
      },

      showSuggestions && {
        template: 'block-suggested-presets',
        fields: {
          service_name_id: serviceNameUid,
        },
      },

      // Username
      editableTextRow({
        label: SERVICE_USERNAME_LABEL(),
        id: serviceUsernameUid,
        placeholder: SERVICE_EDIT_USERNAME_PLACEHOLDER(),
        onChange: 'newServiceEmailOrUsername',
        helpId: 'HELP_LOGIN_USERNAME',
        initialValue: defaultUsername,
        maxlength: SERVIVCE_NAME_MAXLENGTH,
      }),

      // Email
      editableTextRow({
        label: SERVICE_EMAIL_LABEL(),
        id: serviceUsernameUid,
        placeholder: SERVICE_EDIT_EMAIL_PLACEHOLDER(),
        onChange: 'newServiceEmailOrUsername2',
        helpId: 'HELP_LOGIN_USERNAME',
        initialValue: defaultUsername,
        maxlength: SERVIVCE_NAME_MAXLENGTH,
      }),

      // Website
      editableTextRow({
        label: SERVICE_WEBSITE_LABEL(),
        id: serviceWebAddressUid,
        placeholder: SERVICE_EDIT_WEBSITE_PLACEHOLDER(),
        onChange: 'newServiceWebAddress',
        helpId: 'HELP_LOGIN_WEBSITE',
        maxlength: WEBSITE_URL_MAXLENGTH,
      }),

      // Add field ("forghettible", e.g. password, pin)
      {
        template: 'block-section-row',
        fields: {
          class: 'cta',
          class2: 'blue',
          context: 'newServiceAddNewField',
        },
        columns: [
          {
            template: 'block-icn-button',
            fields: { icon: 'add' },
          },
          {
            template: 'block-text-group',
            fields: { primary_text: SERVICE_ADD_FORGHETTIBLE() },
          },
          {
            template: 'block-icn-button',
            fields: {
              icon: 'help-solid',
              data_id: 'HELP_LOGIN_ADD_FORGHETTIBLE',
              action: 'displayContextualHelp',
            },
          },
        ],
      },
    ],
  };
};
