const config = require('../../../config');
const strings = config.strings;

module.exports = ({ nav }) => {
  const index = 5;

  const prevTemplate = 'onboarding4';
  const prevData = parseInt(nav) - 1;

  const MOBILE_VIEW_BREAKPOINT = window.z2hApp.MOBILE_VIEW_BREAKPOINT;
  const mobile = $('body').width() <= MOBILE_VIEW_BREAKPOINT;

  let text, header;
  if (mobile) {
    text = config.strings.ONBOARDING_HEADER_5();
  } else {
    header = config.strings.ONBOARDING_HEADER_5();
  }

  const row = (icon, text) => ({
    template: 'block-section-row',
    fields: {},
    columns: [
      {
        template: 'block-icn',
        fields: { icon },
      },
      { template: 'block-text-group', fields: { primary_text: text } },
    ],
  });

  const page = {
    template: 'block-section-page',

    fields: {
      id: 'create-account-onboarding-' + index,

      header: header,

      navigation_left: strings.PREVIOUS(),
      navigation_left_data: prevData,
      navigation_left_template: prevTemplate,
    },

    rows: [
      row('password', strings.CREATE_ACCOUNT_ONBOARDING_5_LINE_1()),
      row('doodlepad-drawn', strings.CREATE_ACCOUNT_ONBOARDING_5_LINE_2()),
      row('mobile', strings.CREATE_ACCOUNT_ONBOARDING_5_LINE_3()),
      row('members', strings.CREATE_ACCOUNT_ONBOARDING_5_LINE_4()),
      {
        template: 'block-progress-dots',
        fields: { dot5Active: true },
      },
    ],
  };

  // Add the heading for this page as the first row
  if (mobile) {
    page.rows.unshift({
      template: 'block-section-row',
      fields: { primary_heading: text },
    });
  }

  return page;
};
