const showToastMessage = require('../../action_helpers/showToastMessage');
const strings = require('../../../config').strings;
const { analyticsLogEvent } = require('../../app/firebaseUtils');
module.exports = ({ currentTarget, section, z2hApp }) => {
  // Get details that have been entered
  const groupName = section.find('#new-group-name-field').val();
  let numberOfMembers = parseInt(section.find('[name=max-members]').val());

  // Default to 50 members per group
  if (!numberOfMembers || isNaN(numberOfMembers)) {
    numberOfMembers = 50;
  }

  const data = {
    name: groupName,
    maximum_members: numberOfMembers,
    mfa_level: 0,
  };

  let newGroupId = null;

  return z2hApp
    .requestData('post', '', 'groups/new', data)
    .then((response) => {
      if (response.status >= 209) {
        showToastMessage(response.user_message);
        z2hApp.paneNavigation('loginList', $('#pane-1'), 0, null, null, false, (_) => {
          z2hApp.fetchAndShowServices(window.state.selectedGroupId, false);
        });
        throw response.user_message;
      }

      newGroupId = response.data.id;
      analyticsLogEvent('new group');

      localStorage.setItem(`newGroup_${newGroupId}`, Date.now());

      data.id = newGroupId;
      window.state.groupsList.push(data);
      // Update group name in state
      //const group = window.state.groupsList.find(g => g.id === window.state.selectedGroupId) || {};

      //group.name = groupName;
      //group.id = newGroupId;
    })
    .then((_) => {
      if (window.state.temporaryGroupIcon) {
        const data = { image: window.state.temporaryGroupIcon };
        return z2hApp.requestData('post', '', `groups/${newGroupId}/update-image`, data).then((response) => {
          if (response.status !== 200) {
            console.error('New group icon upload failed:', response);
          }
        });
      }
    })
    .then((_) => {
      // Navigate to/reload groups list
      //showToastMessage(strings.MSG_NEWi_GROUP_CREATED(groupName));
      //return z2hApp.reloadFullGroupsList();

      // window.state.fullUserGroupsPromise = z2hApp.requestData('get', '', 'users/@me/groups-full').then(response => {
      //   if (Math.floor(response.status / 100) === 2) {
      //     window.state.groupsList = [];
      //     for (let i in response.data) {
      //       window.state.groupsList.push(response.data[i]);
      //     }
      //   }
      //   return window.state.groupsList;
      // });
      // return window.state.fullUserGroupsPromise;
      window.state.selectedGroupId = newGroupId;
      z2hApp.paneNavigation('loginList', $('#pane-1'), 0, null, null, false, (_) => {
        //z2hApp.fetchAndShowServices(window.state.selectedGroupId, false);
      });
    })
    .then((groupsList) => {
      //z2hApp.reloadUpdateSections();
      //z2hApp.refreshGroupsInNav();

      z2hApp.showServicesForGroup();
    });
};
