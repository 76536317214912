module.exports = {
  createGroupInvite: require('./createGroupInvite'),
  editGroupDetails: require('./editGroupDetails'),
  viewGroupDetails: require('./viewGroupDetails'),
  chooseGroupIcon: require('./chooseGroupIcon'),
  shareInvite: require('./shareInvite'),
  groupsList: require('./groupsList'),
  joinGroup: require('./joinGroup'),
  addGroup: require('./addGroup'),
  newGroup: require('./newGroup'),
  shareInviteUsername: require('./shareInviteUsername'),
};
