const z2hApp = require('../../app/z2hApp');
const strings = require('../../../config/strings');
const components = require('../../page_helpers/components');

module.exports = ({ data }) => {
  const notifications = data.notifications;
  const notes = notifications.map((notification) => {
    return components.notificationEvent(notification);
  });
  if (notes.length === 0) {
    notes.push(...components.notificationNoRow())
  }
  return {
    template: 'block-section-page',
    fields: {
      id: 'notifications',
      header: strings.NOTIFICATIONS(),
    },
    rows: notes.flat(),
  };
};
