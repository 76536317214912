module.exports = ({ currentTarget, z2hApp }) => {
  const value = $(currentTarget).val();
  if (!value) return;

  // Update "unsaved" data
  const fieldData = z2hApp.pageData.service.fieldsUnsaved[z2hApp.active.pageId];
  const generation_settings = fieldData.generation_settings;

  const wordsType = $(currentTarget)
    .closest('.section-pane-inner')
    .find('[data-context=newServiceType]')
    .attr('data-value')
    .includes('w');

  // If user changes length, but the user is creating a 'memorable words' type
  // of field, then we store the length as the number of words that the field
  // will contain.
  if (wordsType) {
    generation_settings.words = parseInt(value);
  } else {
    generation_settings.words = 0;
    generation_settings.length = parseInt(value);
  }
};
