const config = require('../../../config');
const onboardingPageTemplate = require('./createAccount_onboarding_template');

module.exports = ({ data, nav }) => {
  return onboardingPageTemplate(
    4,
    config.strings.ONBOARDING_HEADER_4(),
    config.strings.ONBOARDING_MESSAGE_4(),
    'img/onboarding/desktop/4.jpg',
    'createAccount_onboarding5',
    'createAccount_onboarding3',
    parseInt(nav) + 1,
    parseInt(nav) - 1,
  );
};
