const _ = require('lodash');

module.exports = ({ currentTarget, z2hApp, pane, newPaneIndex, template, dataId, groupId }) => {
  // Get source of selected img/icon

  //const groupId = group || window.state.selectedGroupId;

  const serviceId = dataId || $(currentTarget).attr('data-id') || window.state.selectedServiceId;

  window.state.selectedServiceId = serviceId;

  if (serviceId !== 'new') {
    window.state.currentService = window.state?.currentServices?.find((s) => s.id === serviceId);
  } else {
    window.state.currentService = null;
  }

  if (!window.state.currentService) {
    //look in other groups
    const groupsList = window.state.groupsList;
    for (const group of groupsList) {

      if (groupId) {
        if (group.id === groupId) {
          const service = group.services.find((s) => s.id === serviceId);
          if (service) {
            window.state.currentService = service;
            break;
          }
        }
      
      }
      if (group.services) {
        const service = group.services.find((s) => s.id === serviceId);
        if (service) {
          window.state.currentService = service;
          break;
        }
      }
    }
  }
      

  window.z2hApp.pageData.postUpdateAction = null;

  window.z2hApp.pageData.service = _.cloneDeep(window.state.currentService);

  if (!groupId && serviceId !== 'new') {
    const group = window.state.groupsList.find((g) => {
      if (g.services) {
        return g?.services?.find((s) => s.id === window.state.currentService.id);
      }
      return false;
    });
    window.state.selectedGroupId = groupId = group.id;
  } else if (serviceId == 'new') {
    groupId = window.state.selectedGroupId;
  } else {
    window.state.selectedGroupId = groupId;
  }

  if (!window.state.currentService) {
    // Check if service exists in another group
    const groupsList = window.state.groupsList;
    for (let i = 0; i < groupsList.length; i++) {
      const groupId = groupsList[i].id;

      const services = groupsList[i].services || [];
      if (services.find && services.find((s) => s.id === serviceId)) {
        window.state.currentService = services.find((s) => s.id === serviceId);
        window.state.selectedGroupId = groupId;
        window.state.currentServices = services;

        if (window.z2hApp.highlightNavElement)
          window.z2hApp.highlightNavElement($('#main-nav').find('[data-id=' + groupId + ']'));

        break;
      }
    }
  }

  if (!window.state.currentService) {
    return false;
  }
  if (window.state.currentService.last_used === 0) {
    return z2hApp.paneNavigation('addUsernameToExistingService', 2, 1, null, serviceId);
  }
  const { biometricsOrDoodle } = require('./');
  biometricsOrDoodle({
    currentTarget: null,
    z2hApp,
    groupId,
    template: { biometrics: 'viewService1_biometrics', doodlepad: 'viewService1_doodlepad' },
  });
  return true;
};
