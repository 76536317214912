const quickSelectAddLogin = require('./quickSelectAddLogin');
const addNewService = require('./addNewService');

module.exports = params => {
  // ===============================================================================================
  // ===============================================================================================
  // Redirect to Quick Select (quick add) page if enabled in user preferences
  const quickSelect = localStorage.getItem('userprefs-quickselect') === 'false' ? false : true;
  if (quickSelect) {
    return quickSelectAddLogin(params);
  } else {
    return addNewService(params);
  }
  // ===============================================================================================
  // ===============================================================================================
};
