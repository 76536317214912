const config = require('../../../config');
const z2hApp = require('../../app/z2hApp');
const strings = config.strings;

const { infoTextRow, primaryText } = require('../../page_helpers/components');

module.exports = ({ nav, data, dataId, noHeading, confirmAction }) => {
  // This eventually needs to be done by the getPageData for this page,
  // and probably get the data from the server, as we will also need the image
  // for this service and perhaps other info, which means we'll also need an initial
  // "connecting" page before this one.

  // =================================================================================================
  // let loginItem = $('.login-item[data-id=' + dataId + ']');
  // let serviceName = '';
  // if (loginItem[0]) {
  //   serviceName = loginItem.find('.login-name').text();
  // }
  // const service = data;
  // const user = window.state.userData;
  // if (!serviceName) serviceName = service.name;
  // const groupId = window.state.selectedGroupId;
  // const group = window.state.groupsList.find(g => g.id === groupId);
  // const avatar = group.personal
  //   ? user.avatar || config.app.DEFAULT_USER_ICON
  //   : group.icon || config.app.DEFAULT_GROUP_ICON;
  // const avatar_id = group.personal ? window.state.userData.id : group.id;

  // =================================================================================================

  const serviceId = dataId || window.state.selectedServiceId;
  const user = window.state.userData;
  const groupId = window.state.selectedGroupId;

  // Store in state
  window.state.biometrics = true;

  // let group = window.state.groupsList.find(g => g.id === groupId) || {};
  // if (!group.services) {
  //   console.log('No group');
  //   const findGroup = () => {
  //     return window.state.groupsList.find(g => g.id === groupId) || {};
  //   };

  //   function timeout(ms) {
  //     return new Promise(resolve => setTimeout(resolve, ms));
  //   }
  //   async function sleep(fn) {
  //     await timeout(500);
  //     group = fn();
  //   }
  //   sleep(findGroup);

  //   //We've lost the services of the group. Try again
  // }
  // const service =
  //   (serviceId && group.services.find(s => s.id === serviceId)) || window.z2hApp.pageData.service.serviceData || {};

  const group = window.state.groupsList.find((g) => g.id === data.group);
  const service = data.service;
  const serviceName = service.name;
  const serviceIcon = service.icon || config.app.DEFAULT_SERVICE_ICON;

  const groupIcon = group.personal
    ? user.avatar || config.app.DEFAULT_USER_ICON
    : group.icon || config.app.DEFAULT_GROUP_ICON;

  const groupName = group.name || '';

  const memberType = group.personal ? 2 : (group.users[window.state.userData.id] || {}).member_type;

  const showFingerint = () => {
    if (process.env.CORDOVA) {
      window.Fingerprint.show(
        {
          disableBackup: true,
          title: strings.UNLOCK_SERVICE()(serviceName),
          description: strings.UNLOCK_SERVICE_DESCRIPTION()(serviceName),
          disableBackup: true,
          fallbackButtonTitle: strings.UNLOCK_FALLBACK(),
          cancelButtonTitle: strings.UNLOCK_CANCEL()
        },
        fingerprintAuthSuccessCallback,
        fingerprintAuthErrorCallback,
      );
    }
  };
  showFingerint();

  function fingerprintAuthSuccessCallback(result) {
    console.log('Fingerprint/touch ID success: ' + JSON.stringify(result));
    const actions = require('../../actions');
    z2hApp.paneNavigation('viewService_loading', 2, nav + 1);
    return actions[confirmAction || 'goToServiceCodesAfterDelay']({
      z2hApp: window.z2hApp,
    });
  }

  function fingerprintAuthErrorCallback(error) {
    z2hApp.biometricsUseDoodle();
  }

  const biometricButtonRow = () => {
    return !window.Fingerprint
      ? {
          template: 'block-biometrics-buttons',
          fields: {
            confirm_data: String(nav + 1),
            confirm_template: 'viewService_loading',
            confirm_validation: 'validateDoodlepad',
            confirm_action: confirmAction || 'goToServiceCodesAfterDelay',
          },
        }
      : {};
  };

  // =================================================================================================

  return {
    template: 'block-section-page',
    fields: {
      id: 'doodle-pad',
      active: false,
      header: serviceName,

      help_id: 'HELP_DOODLEPAD',

      navigation_left: config.strings.BACK(),
      navigation_left_icon: '',
      navigation_left_data: 0, //Math.max(nav - 1, 0),
      navigation_left_template: 'back',
      //navigation_left_pane: 1,

      //if we can edit the service lets do that.

      navigation_right: memberType > 0 ? strings.SERVICE_DETAILS_RIGHT_NAV() : strings.SERVICE_DOODLEPAD_RIGHT_NAV(),
      navigation_right_data: String(nav + 1),
      navigation_right_template: memberType > 0 ? 'editServiceDetails' : 'viewServiceDetails',

      navbar: false,
    },
    rows: [
      {
        template: 'block-avatar-group',
        fields: {
          avatar_src_1: serviceIcon,
          avatar_src_2: groupIcon,
          avatar_name_1: serviceName,
          avatar_name_2: groupName,
          class: 'no-border',
        },
      },

      {
        template: 'block-onboarding-image',
        fields: {
          imagePath: 'img/icons/colour-1/forghetti-ui_fingerprint_normal.svg',
          class: 'doodlepad',
          style: JSON.stringify({ flexGrow: 0 }),
        },
      },
      biometricButtonRow(),
    ],
  };
};
