const showAlertMessage = require('../action_helpers/showAlertMessage');
const config = require('../../config');
const strings = config.strings;
const urls = config.urls;

module.exports = (msg) => {
  const message = `${msg || strings.UPGRADE_TODAY_MESSAGE()}
    <br/>
      <button class="column text-button"  data-actionclick="gotoPremium">${strings.UPGRADE_TODAY_BUTTON()}</button>`;

  showAlertMessage(message, true, strings.UPGRADE_REQUIRED());
  //The disalog box
  $('body').on('z2hApp.pagedisplayed', () => {
    $('.dialogMgr_root').click();
  });
};
