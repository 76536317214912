module.exports = ({ element }) => {
  const cordova = window.cordova;
  if (!cordova || !cordova.exec) return;

  cordova.exec(
    result => {
      console.log(result);
    },
    err => {
      console.error(err);
    },
    'AndroidAutofillPlugin',
    'openSettings',
  );
};
