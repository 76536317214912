module.exports = ({ z2hApp, currentTarget }) => {
  const data = $(currentTarget).attr('data-id');
  //console.log(data);

  async function getPwned() {
    const fetchBreach = (breach) => {
      //TODO move this to the backend...

      return z2hApp.requestData('get', '', `pwned/${breach}/breach`);

      //fetch(`https://cors-anywhere.herokuapp.com/https://haveibeenpwned.com/api/v3/breach/${breach}`, options);
    };
    //fire off a request to get a user. Store the promise but we're not going
    //to wait for the result here, we will do that on the loading screen.
    const breachRes = await fetchBreach(data);
    //console.log(breachRes);
    const breach = breachRes && breachRes.status === 200 ? await breachRes.data : {};
    //console.log(breach);
    await new Promise((resolve) => setTimeout(resolve, 500));
    const paneDetails = window.getActivePaneDetails('#pane-2');
  
    window.z2hApp.pageData.healthcheck.breach = {};
    window.z2hApp.pageData.healthcheck.breach[data] = breach;
    window.state.selectedBreach = data;

    //window.state.selectedBreach = data;
    z2hApp.paneNavigation('healthcheck_details_user', paneDetails.pane, paneDetails.activePaneIndex + 1);
  }
  getPwned();
};
