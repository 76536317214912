const config = require('../../../config');
const { infoTextRow, thirdheading } = require('../../page_helpers/components');
const actions = require('../../actions');

const { strings } = config;
const {
  BACK,
  ADD_SERVICE_USERNAME_PLACEHOLDER,
  ADD_SERVICE_USERNAME,
  SEARCH,
  CANCEL,
  SKIP,
  NEXT,
  ADD_SERVICE_USERNAME_HEADER,
  ADD_SERVICE_SUGGESTED_USERNAMES,
  SERVICE_USERNAME_PLACEHOLDER_SECOND,
} = strings;
const { USERNAME_NAME_MAXLENGTH } = require('../../../config/app');
const { ADD_SERVICE_USERNAME_PLACEHOLDER_SECOND } = require('../../../config/strings.base');

module.exports = ({ nav, data }) => {
  // Focus on input field after 1 second
  setTimeout((_) => $('#addService_username .service-username').focus(), 1000);
  window.state.usernameFocused = 'add-service-username-input';

  const serviceName = window.z2hApp.pageData.service.serviceData.name;
  window.z2hApp.pageData.service.serviceData.username = '';
  window.z2hApp.pageData.service.serviceData.username_secondary = '';

  return {
    template: 'block-section-page',
    fields: {
      id: 'addService_username',
      header: ADD_SERVICE_USERNAME_HEADER(),
      navigation_left: BACK(),
      //navigation_left_action: 'hidePane3',
      navigation_left_template: 'back',
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_right: NEXT(),
      //navigation_left_action: 'hidePane3',
      navigation_right_action: 'addService_selectUsername',
      navigation_right_data: nav + 1,
      navbar: false,
    },

    
    rows: [
      infoTextRow(ADD_SERVICE_USERNAME()(serviceName), true, false, true),
      {
        template: 'block-section-row',
        fields: {
          secondary_heading: 'Username',
        },
        columns: [
          {
            template: 'block-search-box',
            fields: {
              placeholder: ADD_SERVICE_USERNAME_PLACEHOLDER(),
              id: 'add-service-username-input',
              value: '',
              maxlength: USERNAME_NAME_MAXLENGTH,
              rules: 'notemail'
            },
          },
          // Tick/submit button
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          secondary_heading: 'Email',
        },
        columns: [
          {
            template: 'block-search-box',
            fields: {
              placeholder: SERVICE_USERNAME_PLACEHOLDER_SECOND(),
              id: 'add-service-username-input-secondary',
              value: '',
              maxlength: USERNAME_NAME_MAXLENGTH,
              rules: 'email'
            },
          },
          // Tick/submit button
        ],
      },
      {
        template: 'block-suggested-usernames',

        fields: {
          id: 'suggestedusernames',
          usernameAction: 'addUsernameToFocussed', // When a username is clicked,
          heading: ADD_SERVICE_SUGGESTED_USERNAMES(),
          rules: 'notemail'
       
        },
      },
    ],
  };
};

$('body').on('keyup', '#addService_username #add-service-username-input', (e) => {
  // if (e.key === 'Enter') {
  //   $('#addService_username [data-actionclick=addService_submitUsername]').click();
  //   return;
  // }

  // const tickIcon = document.querySelector('[data-actionclick="addService_submitUsername"');
  // e.target.value === '' ? tickIcon.classList.remove('active') : tickIcon.classList.add('active');

  $('[data-id="suggestedusernames"]').trigger('keyup', e.target.value);
});

$('body').on('keyup', '#addService_username #add-service-username-input-secondary', (e) => {
  // if (e.key === 'Enter') {
  //   $('#addService_username [data-actionclick=addService_submitUsername]').click();
  //   return;
  // }

  // const tickIcon = document.querySelector('[data-actionclick="addService_submitUsername"');
  // e.target.value === '' ? tickIcon.classList.remove('active') : tickIcon.classList.add('active');

  $('[data-id="suggestedusernames"]').trigger('keyup', e.target.value);
});

$('body').on('focus', '#addService_username #add-service-username-input', (e) => {
  // if (e.key === 'Enter') {
  //   $('#addService_username [data-actionclick=addService_submitUsername]').click();
  //   return;
  // }

  // const tickIcon = document.querySelector('[data-actionclick="addService_submitUsername"');
  // e.target.value === '' ? tickIcon.classList.remove('active') : tickIcon.classList.add('active');
  window.state.usernameFocused = 'add-service-username-input';
  $('[data-id="suggestedusernames"]').trigger('keyup', $('#add-service-username-input').val());
});

$('body').on('focus', '#addService_username #add-service-username-input-secondary', (e) => {
  // if (e.key === 'Enter') {
  //   $('#addService_username [data-actionclick=addService_submitUsername]').click();
  //   return;
  // }

  // const tickIcon = document.querySelector('[data-actionclick="addService_submitUsername"');
  // e.target.value === '' ? tickIcon.classList.remove('active') : tickIcon.classList.add('active');
  window.state.usernameFocused = 'add-service-username-input-secondary';
  $('[data-id="suggestedusernames"]').trigger('keyup', $('#add-service-username-input-secondary').val());
});
