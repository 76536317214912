module.exports = {
  copyGroupInviteToClipboard: require('./copyGroupInviteToClipboard'),
  chooseGroupIconFromSearch: require('./chooseGroupIconFromSearch'),
  goToSearchForGroupIcon: require('./goToSearchForGroupIcon'),
  emailGroupInviteLink: require('./emailGroupInviteLink'),
  joinGroupViaDeeplink: require('./joinGroupViaDeeplink'),
  goToChooseGroupIcon: require('./goToChooseGroupIcon'),
  createGroupInvite: require('./createGroupInvite'),
  removeGroupMember: require('./removeGroupMember'),
  editCurrentGroup: require('./editCurrentGroup'),
  focusOnGroupName: require('./focusOnGroupName'),
  uploadGroupIcon: require('./uploadGroupIcon'),
  chooseGroupIcon: require('./chooseGroupIcon'),
  deactivateLink: require('./deactivateLink'),
  newGroupCreate: require('./newGroupCreate'),
  setLinkMaxUses: require('./setLinkMaxUses'),
  setLinkExpiry: require('./setLinkExpiry'),
  switchToGroup: require('./switchToGroup'),
  removeAdmin: require('./removeAdmin'),
  deleteGroup: require('./deleteGroup'),
  leaveGroup: require('./leaveGroup'),
  makeAdmin: require('./makeAdmin'),
  joinGroup: require('./joinGroup'),
  selectServiceGroup: require('./selectServiceGroup'),
  addNewGroup: require('./addNewGroup'),
  takePhotoGroupIcon: require('./takePhotoGroupIcon'),
  newGroupGotoPremium: require('./newGroupGotoPremium'),
  newGroupNavigate: require('./newGroupNavigate'),
  makeOwner: require('./makeOwner'),
  shareInvite_submit: require('./shareInvite_submit'),
  openPermissionsLink: require('./openPermissionsLink'),
};
