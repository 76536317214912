const { infoTextRow } = require('../../page_helpers/components');
const config = require('../../../config');
const strings = config.strings;

module.exports = ({ nav }) => {
  const premium = window.state.launchData.home.premium;
  const userId = window.state.userData.id;
  const groupsUserHasJoined = window.state.groupsList.filter(g => {
    return !g.personal && (g.users[userId] || {}).member_type !== 2;
  });
  const canJoinGroup = premium || groupsUserHasJoined.length === 0;

  const linkRow = ({ primary_text, secondary_text, template, nav, className }) => ({
    template: 'block-section-row',
    fields: {
      navigation_data: String(nav + 1),
      navigation_template: template,
      class: className,
    },
    columns: [
      {
        template: 'block-text-group',
        fields: {
          primary_text: primary_text,
          secondary_text: secondary_text,
        },
      },
      {
        template: 'block-icn-button',
        fields: { icon: 'chevron-right' },
      },
    ],
  });

  const page = {
    template: 'block-section-page',
    fields: {
      id: 'add-group',

      header: strings.GROUPS_ADD_GROUP_HEADER(),
    },
    rows: [
      linkRow({
        primary_text: strings.GROUPS_JOIN_GROUP(),
        secondary_text: strings.GROUPS_JOIN_GROUP_TEXT(),
        template: canJoinGroup ? 'joinGroup' : '',
        className: canJoinGroup ? '' : 'disabled',
        nav,
      }),
      linkRow({
        primary_text: strings.GROUPS_CREATE_GROUP(),
        secondary_text: strings.GROUPS_CREATE_GROUP_TEXT(),
        template: premium ? 'newGroup' : '',
        className: premium ? '' : 'disabled',
        nav,
      }),
    ],
  };

  // If not premium, insert messaging stating that they can only join ONE group
  // and provide a link to go upgrade.
  if (!premium) {
    page.rows[2] = page.rows[1];
    page.rows[1] = infoTextRow(strings.GROUPS_FREE_JOIN_WARNING(), true);
    page.rows.push(infoTextRow(strings.GROUPS_ADD_UPGRADE(), true, false));
    page.rows.push({
      template: 'block-section-row',
      fields: {},
      columns: [
        {
          template: 'block-text-button',
          fields: {
            class: 'cta-button',
            text: config.strings.UPGRADE_TODAY_BUTTON(),
            navigation_data: nav + 1,
            navigation_template: 'upgradeToday',
            navigation_pane: '2',
          },
        },
      ],
    });
  }

  return page;
};
