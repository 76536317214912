/********************************************************************************
 ********************************************************************************
 

   This page is not currently used, but we've kept it in case we need it later.

 
 ********************************************************************************
 ********************************************************************************/

module.exports = _ => ({
  template: 'block-section-page',
  fields: {
    id: 'profile-notifications',
    active: false,
    header: 'Notifications',
    navigation_left: 'Profile',
    navigation_left_icon: '',
    navigation_left_data: String(nav - 1) == '-1' ? 'null' : String(nav - 1),
    navigation_left_template: 'back',
  },
  rows: [
    {
      template: 'block-section-row',
      columns: [
        {
          template: 'block-text-group',
          fields: {
            primary_text: 'Sounds',
            secondary_text: 'Play sounds when receiving notifications',
          },
        },
        {
          template: 'block-toggle-switch',
          fields: {
            checked: true,
          },
        },
      ],
    },
    {
      template: 'block-section-row',
      fields: {
        secondary_heading: 'Group Notifications',
      },
      columns: [
        {
          template: 'block-text-group',
          fields: {
            primary_text: 'New members',
            secondary_text: 'Receive a notification when a new member joins one of your groups',
          },
        },
        {
          template: 'block-toggle-switch',
          fields: {
            checked: true,
          },
        },
      ],
    },
    {
      template: 'block-section-row',
      fields: {},
      columns: [
        {
          template: 'block-text-group',
          fields: {
            primary_text: 'Logins removed',
            secondary_text: 'Receive a notification when a login is removed by another group admin',
          },
        },
        {
          template: 'block-toggle-switch',
          fields: {
            checked: true,
          },
        },
      ],
    },
  ],
});
