
module.exports = ({ link, details, task, menuTarget, z2hApp }) => {

  //Just make sure... I know, I know
  if (task === "selectLanguage") {
    localStorage.setItem('language', details)
    localStorage.setItem('updatedLanguage', details)
    window.location.reload();

  }

}