const config = require('../../../config');
const { strings } = config;
const { infoTextRow } = require('../../page_helpers/components');
const fb = require('../../app/firebaseUtils');

module.exports = ({ nav, data }) => {
  // Set focus on the activate code
  setTimeout((_) => $('#block-activation-code-1').focus(), 1000);
  const phoneNumber = window.state.phoneNumber || fb.getCurrentUser().phoneNumber;
  return {
    template: 'block-section-page',
    fields: {
      id: 'migrate-phone-number-2',
      header: strings.PROFILE_MIGRATE_2_HEADER(),

      focus_on: 'block-activation-code-1',

      navigation_left: config.strings.BACK(),
      navigation_left_template: 'back',
      navigation_left_data: Math.max(nav - 1, 0),
      navbar: false,
    },
    rows: [
      {
        template: 'block-section-row',
        fields: { secondary_heading: strings.PROFILE_MIGRATE_2_HEADING() },
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_text: phoneNumber },
          },
        ],
      },
      // {
      //   template: 'block-section-row',
      //   fields: { secondary_heading: strings.PROFILE_MIGRATE_2_HEADING2() },
      //   columns: [
      //     {
      //       template: 'block-text-group',
      //       fields: { primary_text: window.state.newPhoneNumber },
      //     },
      //   ],
      // },
      infoTextRow(strings.PROFILE_MIGRATE_2_INFO_2()),
      {
        template: 'block-section-row',
        fields: { primary_text: '' },
        columns: [
          {
            template: 'block-activation-code',
            fields: {
              data_name: 'new-user-activation-code',
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: { class: 'centered-text' },
        columns: [
          {
            template: 'block-text-button',
            fields: {
              class: 'cta-button',
              text: strings.PROFILE_MIGRATE_2_BUTTON(),
              action: 'confirmMigrationCode',
              navigation_template: '',
              navigation_validation: 'validateActivationCode',
            },
          },
        ],
      },
    ],
  };
};
