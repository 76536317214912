module.exports = async ({ z2hApp, currentTarget }) => {
  const z2hTemplates = require('../../app/templates');

  const row = $(currentTarget).closest('.row ');
  const newField = row.find('input'); 
  const newVal = newField.val();
  const fieldName = newField.attr('id');



  
  
  const service = z2hApp.pageData.service;
  
  const serviceId = service.id;

  const postData = {}

  if (fieldName === 'renameUsername') {
    service.username = newVal;
    postData.username = newVal;
  } else if (fieldName === 'renameWebsite') {
    service.website = newVal;
    postData.website= newVal;
  } else if (fieldName === 'renameUsernameSecondary') {
    service.username_secondary = newVal;
    postData.username_secondary = newVal;
  }

   

  z2hApp.updateCurrentService(serviceId, null, postData );
  setTimeout(()=>{

    $(currentTarget).closest('label').removeClass('focussed current-focus').blur();
    
    $(currentTarget).closest('label').find('input').blur()

    $(row).find('[data-actionclick="focusInputRow"]').show()

  },100)
  return z2hApp.requestData('post', '', 'services/' + serviceId, postData).then((response) => {
    return response;
  });
};
