const z2hApp = require('../../app/z2hApp');

const config = require('../../../config');

module.exports = async () => {
  const groupId = z2hApp.currentGroup().id;
  z2hApp.setPreviouslySelectedGroupId(z2hApp.currentGroup().id);
  z2hApp.setCurrentGroup(groupId);
  // To display the full group details, we need to have retrieved the full group details from the
  // server. We make this API request from z2hApp early on in the background, but it might not have
  // finished yet.
  //z2hApp.reloadFullGroupsList();
  const groupList = await z2hApp.getGroupsList();
  //z2hApp.reloadUpdateSections();

  const group = groupList.find((g) => g.id === groupId);

  const personalTypeInGroup = (group.users[z2hApp.userId()] || {}).member_type;
  const userIsPremium = (group.users[z2hApp.userId()] || {}).premium;

  const icon = group.personal
    ? z2hApp.userAvatar() || config.app.DEFAULT_USER_ICON
    : group.icon || config.app.DEFAULT_GROUP_ICON;

  const canEditGroup = !group.personal && (personalTypeInGroup === 1 || personalTypeInGroup === 2);
  return {
    group: group,
    icon,
    personalTypeInGroup,
    userIsAdmin: personalTypeInGroup === 1,
    userIsOwner: personalTypeInGroup === 2,
    userIsAdminOrOwner:  (personalTypeInGroup === 1 || personalTypeInGroup === 2),
    userIsPremium,
    canEditGroup,
  };
};
