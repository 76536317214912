module.exports = ({ element, section, z2hApp }) => {
  // element = <section class="row suggested-add-button"...

  const serviceName = $(element)
    .find('.primary-text-content')
    .data('value');

  // Store service name in state
  window.z2hApp.pageData.service.serviceData.name = serviceName;

  window.z2hApp.pageData.service.serviceData.preset_id = serviceName.toLowerCase().replace(' ','').replace(',','');

  console.log('serviceName: ', serviceName);

  // Display/transition to 'select icon' page
  //const activePaneIndex = $('#pane-3-inner .active').index();
  const paneDetails = window.getActivePaneDetails('#pane-2');

  z2hApp.paneNavigation('addService_icon', paneDetails.pane, paneDetails.activePaneIndex + 1);
};
