const { strings, urls } = require('../../../config');
const { infoTextRow, primaryText, thirdheading } = require('../../page_helpers/components');

const userRows = ({ heading, breaches, userId, nav, premium }) => {
  const rows = [];
  for (const breach of breaches) {
    rows.push({
      template: 'block-section-row',
      fields: premium
        ? {
          action: 'healthcheck_details_user',
          navigation_data_id: breach.Name,
          navigation_data: nav + 1,
          navigation_template: 'healthcheck_details_loading',
        }
        : {
          action: 'showMustBePremiumToast',
        },
      columns: [
        {
          template: 'block-text-group',
          fields: {
            primary_html: `${breach.Name}`,
          },
        },
        premium
          ? {
            template: 'block-icn-button',
            fields: {
              icon: 'right-colour',
            },
          }
          : {
            template: 'block-icn-button',
            fields: {
              icon: 'lock',
            },
          },
      ],
    });
  }
  if (rows.length < 1) {
    rows.push({
      template: 'block-section-row',
      columns: [
        {
          template: 'block-text-group',
          fields: {
            primary_text: strings.HEALTHCHECK_ASSESSMENT_NO_BREACH(),
          },
        },
      ],
    });
  }
  return rows;
};

module.exports = ({ nav }) => {
  const user1 = window.z2hApp.pageData.healthcheck.username.username1;
  //const user2 = window.z2hApp.pageData.healthcheck.username.username2;
  const password = window.z2hApp.pageData.healthcheck.password;

  const premium = window.state.launchData.home.premium;
  let rows = [];

  rows.push(
    infoTextRow(strings.HEALTHCHECK_ASSESSMENT_INFO_1() + ' ' + strings.HEALTHCHECK_ASSESSMENT_INFO_2(), false, false),
  );
  //rows.push(infoTextRow(, true, false));

  if (user1.data && user1.data !== '') {
    rows.push(primaryText(strings.HEALTHCHECK_ASSESSMENT_EMAIL()(user1.data), false, true, true));
    //rows.push();
    rows = [...rows, ...userRows({ breaches: user1.breaches, userId: 'username1', nav, premium })];
  }
  //if (user2.data && user2.data !== "") {
  //  rows.push(thirdheading({ text: user2.data }));
  //  rows = [...rows, ...userRows({ breaches: user2.breaches, userId: 'username2', nav })]

  //}
  if (password.data && password.data !== '') {
    rows.push({
      template: 'block-section-row',
      fields: { class: 'no-border' },
      columns: [{ template: 'block-insertable' }],
    });
    rows.push(primaryText(strings.HEALTHCHECK_ASSESSMENT_PASSWORD(), false, true, true));
    rows.push({
      template: 'block-section-row',
      fields: {
        navigation_data: nav + 1,
        navigation_template: 'healthcheck_details_password',
      },
      columns: [
        {
          template: 'block-text-group',
          fields: {
            primary_html: `${strings.HEALTHCHECK_ASSESSMENT_PASSWORD_STRENGTH()}`,
          },
        },
        {
          template: 'block-text-button',
          fields: {
            text: password.owasp.strong
              ? strings.HEALTHCHECK_ASSESSMENT_PASSWORD_STRONG()
              : strings.HEALTHCHECK_ASSESSMENT_PASSWORD_WEAK(),
          },
        },
      ],
    });
    rows.push({
      template: 'block-section-row',
      fields: {
        navigation_data: nav + 1,
        navigation_template: 'healthcheck_details_password',
      },
      columns: [
        {
          template: 'block-text-group',
          fields: {
            primary_html: `${strings.HEALTHCHECK_ASSESSMENT_PASSWORD_COMP()}`,
          },
        },
        {
          template: 'block-text-button',
          fields: {
            text: password.compromised,
          },
        },
      ],
    });
  }
  rows.push({
    template: 'block-section-row',
    columns: [
      {
        template: 'block-text-group',
        fields: {
          secondary_html: strings.HEALTHCHECK_ASSESSMENT_HIBP_LINK(),
        },
      },
    ],
  });
  // rows.push({
  //   template: 'block-section-row',
  //   fields: {
  //     navigation_right: strings.SEE_DETAILS,
  //   },
  //   columns: [{

  //   }]
  // })

  return {
    template: 'block-section-page',
    fields: {
      id: 'healthcheck_assesment',
      header: strings.HEALTHCHECK_ASSESSMENT_HEADER(),
      navigation_left: strings.BACK(),
      navigation_left_data: 1,
      navigation_left_template: 'back',
      navigation_right: strings.DONE(),
      navigation_right_data: 0,
      navigation_right_template: 'back',
      navbar: false,
    },
    rows: rows,
  };
};
