const sanitize = require('sanitize-html');

const strings = require('../../../config').strings;
const copyPasswordToClipboard = require('./copyPasswordToClipboard');
const showToastMessage = require('../../action_helpers/showToastMessage');
const z2hApp = require('../../app/z2hApp');

module.exports = ({ currentTarget }) => {
  //let message = editServiceFieldDetails({ currentTarget, noToast: true });

  //if (!message) return;

  const target = $(currentTarget);

  if (target.attr('name') === 'edit-service-username') {
    z2hApp.pageData.service.username = target.val();
  }
  else if (target.attr('name') === 'edit-service-username-secondary') {
    z2hApp.pageData.service.username_secondary = target.val();
  } else if (target.attr('name') === 'edit-service-website') {
    z2hApp.pageData.service.website = target.val();
  } else if (target.attr('name') === 'edit-service-name') {
    z2hApp.pageData.service.name = target.val();
  }
};
