const config = require('../../../config');
const strings = config.strings;
const { GROUP_NAME_MAXLENGTH } = require('../../../config/app');

module.exports = ({ nav }) => {
  window.state.previouslySelectedGroup = window.state.selectedGroupId;

  window.state.selectedGroupId = 'new';
  window.state.temporaryGroupIcon = config.app.DEFAULT_GROUP_ICON;



  return {
    template: 'block-section-page',
    fields: {
      id: 'new-group',
      active: false,
      header: strings.GROUPS_NEW_HEADER(),

      navigation_left: strings.BACK(),
      navigation_left_data: Math.max(nav - 1, 0),
      navigation_left_template: 'back',

      navigation_right: strings.GROUPS_NEW_RIGHT_NAV(),
      navigation_right_icon: '',
      navigation_right_action: 'newGroupCreate',
      navigation_right_validation: 'newGroup',
      navigation_right_template: 'creatingGroup_loading',
      navigation_right_data: nav + 1,
      navbar: false,
    },
    rows: [
      {
        template: 'block-section-row',
        fields: {},
        columns: [
          {
            template: 'block-login-avatar',
            fields: {
              avatar: window.state.temporaryGroupIcon || config.app.DEFAULT_GROUP_ICON,
              status: 'secure',
              context: window.cordova ? 'addImageCordova' : 'addImageDesktop',
              data_id: 'newGroup',
            },
          },

          {
            template: 'block-editable-primary-text',
            fields: {
              value: '',
              primary_text: '',
              id: 'new-group-name-field',
              for: 'new-group-name-field',
              name: 'new-group-name-field',
              placeholder: strings.GROUPS_NEW_NAME_PLACEHOLDER(),
              maxlength: GROUP_NAME_MAXLENGTH,
            },
          },
        ],
      },

      {
        template: 'block-section-row',
        columns: [
          {
            template: 'block-text-group',
            fields: {
              primary_text: strings.GROUP_EDIT_MAX_MEMBERS(),
              secondary_text: strings.GROUP_EDIT_MAX_MEMBERS_SECONDARY(),
            },
          },
          {
            template: 'block-text-group',
            fields: {
              class: 'align-right',
              class2: 'small-input',
              primary_text: 50,
              name: 'max-members',
              for: 'max-members',
              id: 'max-members',
            },
          },
        ],
      },
    ],
  };
};
