const copyServiceUsernameSecondaryToClipboard = require('./copyServiceUsernameSecondaryToClipboard');

module.exports = {
  copyServiceUsernameToClipboard: require('./copyServiceUsernameToClipboard'),
  newLoginFromSuggestedPreset: require('./newLoginFromSuggestedPreset'),
  updateNewServiceFieldLength: require('./updateNewServiceFieldLength'),
  goToServiceCodesAfterDelay: require('./goToServiceCodesAfterDelay'),
  goToSearchForServiceIcon: require('./goToSearchForServiceIcon'),
  webSearchForServiceIcons: require('./webSearchForServiceIcons'),
  copyPasswordAndGiveLink: require('./copyPasswordAndGiveLink'),
  copyPasswordToClipboard: require('./copyPasswordToClipboard'),
  generateNewServiceIcon: require('./generateNewServiceIcon'),
  startViewPasswordTimer: require('./startViewPasswordTimer'),
  chooseSuggestedPreset: require('./chooseSuggestedPreset'),
  goToChooseServiceIcon: require('./goToChooseServiceIcon'),
  goToEditLoginListItem: require('./goToEditLoginListItem'),
  markFieldToBeRestored: require('./markFieldToBeRestored'),
  searchForServiceIcons: require('./searchForServiceIcons'),
  updateServiceDetails: require('./updateServiceDetails'),
  uploadServicePicture: require('./uploadServicePicture'),
  beginAddNewService: require('./beginAddNewService'),
  markFieldToBeReset: require('./markFieldToBeReset'),
  checkServiceAdded: require('./checkServiceAdded'),
  chooseServiceIcon: require('./chooseServiceIcon'),
  saveFieldChanges: require('./saveFieldChanges'),
  showHidePassword: require('./showHidePassword'),
  addNewService: require('./addNewService'),
  deleteField: require('./deleteField'),
  deleteLogin: require('./deleteLogin'),
  openWebsite: require('./openWebsite'),

  addService_selectSuggested: require('./addService_selectSuggested'),
  addService_submitUsername: require('./addService_submitUsername'),
  addService_selectUsername: require('./addService_selectUsername'),
  addService_selectGroup: require('./addService_selectGroup'),
  addService_selectName: require('./addService_selectName'),
  addService_selectIcon: require('./addService_selectIcon'),
  confirmDoodlePad_1: require('./confirmDoodlePad_1'),
  confirmDoodlePad_2: require('./confirmDoodlePad_2'),
  addService_onboarding_complete: require('./addService_onboarding_complete'),
  goToDeleteLoginListItem: require('./goToDeleteLoginListItem'),
  doodlepad_submit: require('./doodlepad_submit'),
  showServices: require('./showServices'),
  addService_newGroupCreate: require('./addService_newGroupCreate'),
  addService_newGroup: require('./addService_newGroup'),
  viewGroupDetails: require('./viewGroupDetails'),
  openServiceUrl: require('./openServiceUrl'),
  registerAndOpenServiceUrl: require('./registerAndOpenServiceUrl'),
  goToMoveLoginListItem: require('./goToMoveLoginListItem'),
  moveService_fromDoodleSubmit: require('./moveService_fromDoodleSubmit'),
  moveService_toDoodleSubmit: require('./moveService_toDoodleSubmit'),
  moveService: require('./moveService'),
  moveService_confirm: require('./moveService_confirm'),
  addService_submitWebsite: require('./addService_submitWebsite'),
  groupNotAvailable: require('./groupNotAvailable'),
  addNewServiceBatch: require('./addNewServicesBatch'),
  beginAddNewPIN: require('./beginAddNewPIN'),
  beginAddNewWord: require('./beginAddNewWord'),
  goToResetForghettible: require('./goToResetForghettible'),
  editServiceFieldDetails: require('./editServiceFieldDetails'),
  biometricsOrDoodle: require('./biometricsOrDoodle'),
  selectSymbol: require('./selectSymbol'),
  selectLetterNumberSymbolWord: require('./selectLetterNumberSymbolWord'),
  selectLetterNumberSymbolCountSelector: require('./selectLetterNumberSymbolCountSelector'),
  selectWordCountSelector: require('./selectWordCountSelector'),
  updateLetterNumberSymbolCustomLength: require('./updateLetterNumberSymbolCustomLength'),
  updateWordsCustomLength: require('./updateWordCustomLength'),
  selectAllOrSimpleSelector: require('./selectAllOrSimpleSelector'),
  gotoPasswordSettings: require('./gotoPasswordSettings'),
  savePasswordDetails: require('./savePasswordDetails'),
  selectLetterNumberSymbolCustomLength: require('./selectLetterNumberSymbolCustomLength'),
  selectWordCustomLength: require('./selectWordCustomLength'),
  showHideContent: require('./showHideContent'),
  resetForghettibleFromView: require('./resetForghettibleFromView'),
  gotoEditLoginListItemFromView: require('./gotoEditLoginListItemFromView'),
  renameForghettibleMakeInput: require('./renameForghettibleMakeInput'),
  saveRenameForghettible: require('./saveRenameForghettible'),
  saveRenameUsername: require('./saveRenameUsername'),
  saveRenameWebsite: require('./saveRenameWebsite'),
  addForghettibleFromService: require('./addForghettibleFromService'),
  addService_biometrics: require('./addService_biometrics'),
  gotoGroups: require('./gotoGroups'),
  confirmDoodlepad_onboarding: require('./confirmDoodlepad_onboarding'),
  selectPreset: require('./selectPreset'),
  createServicesBatch: require('./createServicesBatch'),
  gotoEditNote: require('./gotoEditNote'),
  saveNote: require('./saveNote'),
  viewNote: require('./viewNote'),
  copyNoteToClipboard: require('./copyNoteToClipboard'),
  deleteNote: require('./deleteNote'),
  newLoginOrDoodle: require('./newLoginOrDoodle'),
  saveUsername: require('./saveUsername'),
  saveRenameUsernameSecondary: require('./saveRenameUsernameSecondary'),
  copyServiceUsernameSecondaryToClipboard: require('./copyServiceUsernameSecondaryToClipboard'),
  addUsernameToFocussed: require('./addUsernameToFocussed'),
  focusInputRow: require('./focusInputRow'),
  saveRenameFromViewService: require('./saveRenameFromViewService'),
  keychain_yes: require('./keychain_yes'),
  keychain_no: require('./keychain_no'),
  keyupServiceDetails: require('./keyupServiceDetails'),
  replayDoodleDrawing: require('./replayDoodleDrawing'),
  changeViewServiceMode: require('./changeViewServiceMode'),
};
