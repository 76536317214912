const uploadAndCropImage = require('../miscellaneous/uploadAndCropImage');
const updateGroupIcon = require('../../action_helpers/updateGroupIcon');
const justCropImage = require('../../actions/miscellaneous/justCropImage');

module.exports = ({}) => {
  const options = {
    quality: 90,
    destinationType: Camera.DestinationType.DATA_URL,
    sourceType: Camera.PictureSourceType.CAMERA,
    encodingType: Camera.EncodingType.JPEG,
    mediaType: Camera.MediaType.PICTURE,
    correctOrientation: true,
    targetHeight: 250,
    targetWidth: 250,
  };

  const onSuccess = imageURI => {
    const img = 'data:image/png;base64,' + imageURI;
    justCropImage({ img }).then(({ base64Image, doneCallback }) => {
      if (window.state.selectedGroupId === 'new') {
        window.state.temporaryGroupIcon = base64Image;

        $(`img[data-id="newGroup"`).attr('src', base64Image);
        $(`div[data-id="newGroup"] img`).attr('src', base64Image);
        if (doneCallback) doneCallback();
      } else {
        updateGroupIcon(base64Image).then(_ => {
          // Call the callback we were given (to close the upload tool)
          if (doneCallback) doneCallback();
        });
      }
    });
  };
  const onError = error => {
    console.log('failed');
    console.log(error);
  };

  navigator.camera.getPicture(onSuccess, onError, options);
};
