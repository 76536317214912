const showToastMessage = require('../../action_helpers/showToastMessage');

module.exports = ({ currentTarget, section, z2hApp }) => {
  // Get details that have been entered
  const groupName = section.find('#new-group-name-field').val();
  let numberOfMembers = parseInt(section.find('[name=max-members]').val());

  // Default to 50 members per group
  if (!numberOfMembers || isNaN(numberOfMembers)) {
    numberOfMembers = 50;
  }

  const data = {
    name: groupName,
    maximum_members: numberOfMembers,
    mfa_level: 0,
  };

  let newGroupId = null;

  return z2hApp
    .requestData('post', '', 'groups/new', data)
    .then(response => {
      newGroupId = response.data.id;

      localStorage.setItem(`newGroup_${newGroupId}`, Date.now());

      // Update group name in state
      const group = window.state.groupsList.find(g => g.id === window.state.selectedGroupId) || {};
      group.name = groupName;
      group.id = newGroupId;
    })
    .then(_ => {
      if (window.state.temporaryGroupIcon) {
        const data = { image: window.state.temporaryGroupIcon };
        return z2hApp.requestData('post', '', `groups/${newGroupId}/update-image`, data).then(response => {
          if (response.status !== 200) {
            console.error('New group icon upload failed:', response);
          }
        });
      }
    })
    .then(_ => {
      return window.state.fullUserGroupsPromise;
    })
    .then(groupsList => {
      window.state.selectedGroupId = newGroupId;
      //z2hApp.reloadUpdateSections();
      //z2hApp.refreshGroupsInNav();
      const paneDetails = window.getActivePaneDetails('#pane-2');
      z2hApp.paneNavigation('addService_username', paneDetails.pane, paneDetails.activePaneIndex + 1, null);
    });
};
