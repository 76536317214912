module.exports = ({ currentTarget }) => {
  const actionToDo = $(currentTarget).attr('data-id');
  //const paneDetails = window.getActivePaneDetails('#pane-2');
  $('.dialogMgr_root').click();

  if (actionToDo === 'addNewService') {
    const action = require('../services/beginAddNewService');
    action({ currentTarget, z2hApp });
  } else if (actionToDo === 'inviteAFriend') {
    const action = require('../profile/inviteAFriend');
    action({ currentTarget, z2hApp });

  } else if (actionToDo === 'newGroupNavigate') {
    const action = require('../groups/newGroupNavigate');
    action({ currentTarget, z2hApp });
  } 
};
