const errorManager = require('../../utils/errorManager');
const strings = require('../../../config/strings');
const fb = require('../../app/firebaseUtils');
const showToastMessage = require('../../action_helpers/showToastMessage');

function getActivePaneDetails() {
  // Get active pane details
  const sectionPaneWrapper = $('.section-pane.active').closest('.section-pane-wrapper');
  const pane = sectionPaneWrapper.parent();
  const activePaneIndex = sectionPaneWrapper.children('.active').index();
  return { sectionPaneWrapper, pane, activePaneIndex };
}

/**
 * Confirm activation code
 */
module.exports = ({ section, z2hApp }) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      console.time('signInWithConfirmationCode');
      Promise.resolve()
        .then(() => {
          // Get entered activation code
          let val = '';
          $('.activation-input').each((index, element) => {
            val += $(element).val();
          });

          return fb.signInWithConfirmationCode(val);
        })
        .then((result) => {

          // if (!result) {
          //   throw new Error('No result');
          // }
          // User signed in successfully.
          console.info('Code verified successfully.');
          console.timeEnd('signInWithConfirmationCode');

          z2hApp.paneNavigation('vault_loading', $('.overlay'), 5);
          // Get root section
          // let $pane = section.closest('.section-pane-wrapper').parent();
          // z2hApp.paneNavigation('createAccount_displayName', $pane, '4')
          resolve(result);
        })
        .catch((error) => {
          // User couldn't sign in (bad activation code?)
          // Log it and re-throw the error
          console.error('confirmActivationCode: error: ', error);
          // Go back

          const pane = $('.overlay');
          const index = $('.overlay').find('#verify-device-2').index();
          z2hApp.paneNavigation('back', pane, index);
          // Display error after pane 2 has been displayed
          setTimeout(() => {
            if ($('.activation-input').is(':visible')) {
              const actCodeField = $('.activation-input').first();

              errorManager.reportError(strings.ERROR_ACT_CODE_INVALID(), actCodeField);
            }
            showToastMessage(strings.INVALID_ACTIVATION_CODE());
          }, 2000);

          // throw error;
        });
    }, 200);
  });
