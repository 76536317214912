module.exports = ({ dataId }) => {
  z2hApp.pageData = z2hApp.pageData || {};
  z2hApp.pageData.service = {};

  const serviceId = dataId || window.state.selectedServiceId;
  window.state.selectedServiceId = serviceId;

  window.state.currentService = window.state.currentServices.find((s) => s.id === serviceId);

  if (!window.state.currentService) {
    // Check if service exists in another group
    const groupsList = window.state.groupsList;
    for (let i = 0; i < groupsList.length; i++) {
      const groupId = groupsList[i].id;

      const services = groupsList[i].services || [];
      if (services.find && services.find((s) => s.id === serviceId)) {
        window.state.currentService = services.find((s) => s.id === serviceId);
        window.state.selectedGroupId = groupId;
        window.state.currentServices = services;

        if (window.z2hApp.highlightNavElement)
          window.z2hApp.highlightNavElement($('#main-nav').find('[data-id=' + groupId + ']'));

        break;
      }
    }
  }
  //We need to get rid of the moving service stuff in case we've left it
  //hanging around.
  if (window.state.movingService) delete window.state.movingService;
  if (window.z2hApp.pageData.service.movingService) delete window.z2hApp.pageData.service.movingService;
  //We need to get rid of the newly added service stuff in case we've left it
  //hanging around.
  if (window.state.newlyAddedServiceDetailsToDisplay) delete window.state.newlyAddedServiceDetailsToDisplay;

  // Send off a background request to get the fields for this service
  window.serviceFieldPromises = window.serviceFieldPromises || {};
  window.serviceFieldPromises[serviceId] = z2hApp.requestData(`get`, '', `services/${serviceId}/fields`);

  return Promise.resolve({ service: window.state.currentService, group: window.state.selectedGroupId });
};
