const sanitize = require('sanitize-html');
const copyToClipboard = require('copy-html-to-clipboard');

const showToastMessage = require('../../action_helpers/showToastMessage');

module.exports = () => {
  const service = window.state.currentService || {};
  window.state.copiedToClipboard = service.id;

  let username = service.username;

  if (!username) return;

  copyToClipboard(service.username);

  /// TODO: Very similar code as in copyPasswordToClipboard:

  // Display toast/popup to tell user that data has been copied to the clipboard
  // and allow them to visit the service website.
  const trimmedData = username.length > 30 ? username.substr(0, 27) + '...' : username;
  let message = `<strong class="toast--title">Copied to clipboard:</strong><span class="toast--clipboard-item">${trimmedData}</span>`;
  let html = false;

  let website = sanitize(service.website);
  if (website) {
    if (website.toLowerCase().substr(0, 4) !== 'http') {
      website = '//' + website;
    }
    message += `<a href="${website}" target="_blank" class="toast--link"><span class="toast--text">Click here to open</span><br>${website}</a>`;
    html = true;
  }

  showToastMessage(message, { html: true, timeout: 1500, center: true });
};
