const errorManager = require('../utils/errorManager');
const strings = require('../../config').strings;

// This validator is probably not necessary as profile name can be blank?
module.exports = section => {
  errorManager.clearErrors(section);

  const passwordLength = window.z2hApp.pageData.healthcheck.password.passwordLength;


  let error = false;

  // Check for empty profile name
  if (value.trim().length > 256) {
    error = true;
    errorManager.reportError(strings.HEALTHCHECK_PASSWORD_VAL_TOO_LONG(),);
  }



  if (error) {
    throw new Error('Validation error');
  }
};
