const errorManager = require('../utils/errorManager')
const z2hApp = window.z2hApp
const { GROUP_NAME_MAXLENGTH } = require('../../config/app');

const strings = require('../../config').strings

// This validator is probably not necessary as profile name can be blank?
module.exports = (section) => {
  errorManager.clearErrors(section)

  const groupNameField = section.find('[name=group-name]')
  const groupName = groupNameField.val()

  const maxMembersField = section.find('[name=max-members]')
  const maxMembers = parseInt(maxMembersField.val())

  let error = false


  if (groupName.length === 0) {
    error = true
    errorManager.reportError(strings.GROUP_NAME_BLANK(), groupNameField)
  }
  if (groupName.length > GROUP_NAME_MAXLENGTH) {
    error = true;
    errorManager.reportError(strings.GROUP_NAME_TOO_LONG(), groupNameField);

  }
  if (isNaN(maxMembers) || maxMembers < 1 || maxMembers > 50) {
    error = true
    errorManager.reportError(strings.GRP_MAX_MEMBERS_ERROR(), maxMembersField)
  }


  if (error) {
    throw new Error("Validation error")
  }

}