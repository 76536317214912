module.exports = props => {
  let serviceName = state.currentService && state.currentService.name;
  // If a service is being edited, get the current name in the input field
  if ($('#edit-service-details').hasClass('active')) {
    serviceName = $('#edit-service-name').val();
  }
  // If this is a new service, get the current name for the new service
  else if ($('#pane-2').hasClass('active')) {
    serviceName = $('#add-new-service')
      .find('[data-actionkeyup="newServiceName"]')
      .val();
  }

  const initialSearchQuery = serviceName || '';
  return Promise.resolve({ initialSearchQuery });
};
