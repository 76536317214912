const LINK_EXPIRY_VALUES = {
  15: '15 minute',
  30: '30 minute',
  60: '1 ora',
  360: '6 ore',
  720: '12 ore',
  1440: 'O zi',
  '-1': 'Niciodată',
};

const LINK_MAX_USE_VALUES = {
  1: 'O dată',
  2: 'De două ori',
  5: 'De cinci ori',
  10: 'De zece ori',
  20: 'De douăzeci de ori',
  50: 'De cincizeci de ori',
  '-1': 'Nelimitat',
};

module.exports = {
  YOU: 'Tu',
  BACK: 'Înapoi',
  NEXT: 'Următorul',
  CONTINUE: 'Continuă',
  SAVE: 'Salvează',
  ADD: 'Adaugă',
  SKIP: 'Sari peste',
  CANCEL: 'Anulează',
  PREVIOUS: 'Anterior',
  EDIT: 'Editează',
  NONE: 'Nici unul/Nici una',
  START: 'Start/Începe',
  SEARCH: 'Caută',
  CLOSE: 'Închide',
  SEE_DETAILS: 'Vezi detalii/Detalii',
  UNAVAILABLE: 'Indisponibil',
  DONE: 'Finalizat',
  WATCH_AGAIN: 'Uită-te din nou',
  NEW: 'Nou',

  UNAVAILABLE_FORGHETTIBLES_MESSAGE:
    'Câteva din forghettible-urile tale nu sunt disponibile, deoarece ai trecut de limita oferită de abonamentul gratuit. Înregistrează-te cu un plan premium și beneficiază de forghettible-uri nelimitate.',

  FORGHETTI_TAGLINE: 'Uită-ți parolele. Pentru totdeauna/Uită de parole. Pentru totdeauna.',
  CREATE_ACCOUNT: 'Creează cont',
  HAVE_ACCOUNT: 'Am deja un cont',

  COMBOBULATING: 'Îți combobulăm forghettible-urile',

  CREATE_ACCOUNT_1_HEADER: 'Introdu numărul de telefon',
  CREATE_ACCOUNT_1_HEADING: 'Verifică-ți dispozitivul',
  CREATE_ACCOUNT_1_MSG:
    'Te rugăm introdu numărul de telefon pentru a primi un <strong>cod de verificare</strong> prin SMS.',
  CREATE_ACCOUNT_1_PLACEHOLDER: 'Numărul tău',
  CREATE_ACCOUNT_1_MSG_2:
    'Dacă ai nevoie, schimbă codul internațional și apoi introdu numărul tău de telefon\ne.g. 07795123456',
  CREATE_ACCOUNT_1_MSG_3:
    'SMS urile vor fi trimise doar când solicitați un cod de verificare sau pentru optimizările esențiale aduse aplicației',

  ERROR_TEL_NUM_EMPTY: 'Este obligatoriu să introduceți un număr de telefon',

  MAIN_NAV_GROUP: 'Grupuri',
  MAIN_NAV_PROFILE: 'Profil',
  MAIN_NAV_HEALTHCHECK: 'Control de Securitate',
  MAIN_NAV_IMPORT: 'Importare servicii',
  MAIN_NAV_LOGOUT: 'Delogare',
  NAVBAR_JOIN_GROUP: 'Alătură-te unui grup',
  NAVBAR_NEW_GROUP: 'Grup nou',
  NAVBAR_NEW_PASSWORD: 'Parolă nouă',
  NAVBAR_NEW_PIN: 'PIN nou',
  NAVBAR_NEW_WORD: 'Cuvânt memorabil nou',
  NAVBAR_HOME: 'Acasă',
  NAVBAR_GROUPS: 'Grupuri',
  NAVBAR_HEALTHCHECK: 'Control de Securitate',
  NAVBAR_PROFILE: 'Profil',

  /* Lochy loading - paneBusyText.js */
  LOCHY_LOADING_TEXT_DEFAULT: 'Se încarcă',
  LOCHY_LOADING_TEXT_GROUP: (group) => `Încarc servicii pentru <em>${group}</em>`,
  LOCHY_LOADING_TEXT_GROUPSLIST: 'Încărcare grupuri',
  LOCHY_LOADING_TEXT_HEALTHCHECK: 'Încărcare control securitate',
  LOCHY_LOADING_TEXT_CHOOSE_SEARCH_ICON: 'Căutare pe web a pictogramelor de servicii',
  LOCHY_LOADING_TEXT_CHOOSE_CHOOSE_GROUP_ICON: 'Încărcare pictograma grupului',
  LOCHY_LOADING_TEXT_CREATE_GROUP_INVITE: 'Creare invițație pt grup',
  LOCHY_LOADING_TEXT_EDIT_SERVICE: 'Editeaza detalii serviciu',
  LOCHY_LOADING_TEXT_UPDATE_SERVICE: 'Salvare detalii serviciu',
  LOCHY_LOADING_TEXT_PROFILE: 'Încărcare profil',
  LOCHY_LOADING_TEXT_SUBSCRIPTION: 'Încărcare detalii abonament',

  /*                                 */

  CREATE_ACCOUNT_2_HEADER: 'Introdu codul',
  CREATE_ACCOUNT_2_HEADING: 'Introdu codul de verificare',
  CREATE_ACCOUNT_2_MSG: (telNumber) => `Vă rugăm introduceți codul de verificare trimis la ${telNumber}.`,
  CREATE_ACCOUNT_2_LINK_BACK: 'Nu am primit un cod',
  CREATE_ACCOUNT_2_MSG_2: (termsofserviceurl) =>
    `Prin introducerea codului de verificare, sunteți de acord cu <a href="${termsofserviceurl}" target="_new">termenii serviciului</a>`,

  ERROR_TEL_NUM_INVALID: 'Vă rugăm introduceți un număr de telefon valid',
  ERROR_ACT_CODE_EMPTY: 'Vă rugăm introduceți codul de activare trimis',
  ERROR_ACT_CODE_INVALID: 'Vă rugăm introduceți un cod de activare valid',
  ERROR_ACT_CODE_INVALID: 'Codul de activare este incorect. Vă rugăm încercați din nou.',

  CREATE_ACCOUNT_VERIFYING: 'Verificare cod',
  CREATE_ACCOUNT_VERIFYING_PHONE: 'Verificare număr de telefon',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_1: 'Parolele nu sunt salvate niciodată',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_2: 'Trebuie sa ții minte doar doodle-ul',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_3: 'Poți folosi forghetti pe toate device-urile tale',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_4: 'Treceți la premium pentru a putea dezvălui parole și în grupuri',

  CREATE_ACCOUNT_REFERRAL_HEADER: 'Cod de recomandare',
  CREATE_ACCOUNT_REFERRAL_HEADING: 'Introdu codul de recomandare',
  CREATE_ACCOUNT_REFERRAL_PLACEHOLDER: 'Cod de recomandare',
  REFERRAL_ENTRY_MESSAGE_1: `Ai primit un cod de recomandare de la un prieten? Te rugăm introdu-l aici. Dacă nu, poți sări acest pas.`,

  CREATE_ACCOUNT_3_HEADER: 'Editare profil',
  CREATE_ACCOUNT_3_HEADING: 'Editare profil',
  CREATE_ACCOUNT_3_TEXT: `Poți seta un nume si o pictogramă pentru profilul tău aici. Te va ajuta să fii identificat când mai mulți utilizatori au acces, sau când vei fi invitat într-un grup.`,
  CREATE_ACCOUNT_3_LABEL: 'Numele tau',
  CREATE_ACCOUNT_3_TEXT_2: `Poți sări acest pas dacă dorești setarea numelui și a pictogramei mai târziu`,

  CREATE_ACCOUNT_ADD_LOGIN_MSG: `Selectează un serviciu pentru actualizare dintre aceste aplicații binecunoscute`,

  CREATE_ACCOUNT_FINAL_HEADER: 'Gata!',
  CREATE_ACCOUNT_FINAL_MSG: `Ești gata să forghetti!`,
  CREATE_ACCOUNT_FINAL_MSG_2: `Ești pe drumul cel bun spre a-ți securiza contul și spre a uita de stresul parolelor pentru totdeauna!`,
  CREATE_ACCOUNT_FINAL_MSG_3: `Te vom purta prin pașii necesari pentru a actualiza o parola de a ta într-o parolă forghettible`,
  CREATE_ACCOUNT_FINAL_BUTTON: 'Arată-mi cum!',
  CREATE_ACCOUNT_FINAL_LINK: `Știu deja sa folosesc forghetti.`,

  CREATE_ACCOUNT_ADD_CUSTOM_LOGIN: `Sau altceva...`,
  CREATE_ACCOUNT_SERVICE_NAME_TOOLTIP: `Pentru a adăuga un serviciu diferit, va trebui să furnizați cel puțin numele serviciului. Câmpurile de \n\ne-mail / nume de utilizator și adresă web sunt opționale, dar vă sugerăm să le adăugați și pe acestea!`,
  CREATE_ACCOUNT_CUSTOM_LOGIN_ADD_PASSWORD_TOOLTIP: `Va trebui să adăugați cel puțin un tip de parolă pentru a fi generată.\n\nExistă câteva opțiuni diferite pentru a alege, deoarece unele servicii necesită cerințe specifice de parolă.`,
  CREATE_ACCOUNT_CUSTOM_LOGIN_NEXTPAGE_TOOLTIP: `După ce sunteți mulțumit de toate, faceți clic pe „Următorul” pentru a continua.`,
  CREATE_ACCOUNT_NEW_LOGIN_DOODLEPAD_MSG: `Doodle-ul dvs. va fi folosit de fiecare dată când doriți să vă recuperați parola în viitor, așa că faceți-l să conteze!`,
  CREATE_ACCOUNT_ADD_ANOTHER_LOGIN_TOOLTIP: `Pentru a adăuga un alt serviciu la contul dvs., faceți clic aici pentru a afișa din nou noul ecran de conectare.`,

  ADD_SERVICE_SEARCH_HEADER: `Adăugați un serviciu nou`,
  ADD_SERVICE_SEARCH: `Selectați un serviciu din aceste aplicații populare sau începeți să tastați numele`,
  ADD_SERVICE_SUGGESTED: `Servicii sugerate`,
  ADD_SERVICE_CREATE: (name) => `Crează "${name}" "${name}"`,
  ADD_SERVICE_SELECT_ICON_HEADER: `Alege pictograma`,
  ADD_SERVICE_SELECT_ICON: (serviceName) => `Alege pictograma pentru ${serviceName}`,
  ADD_SERVICE_SELECT_GROUP_HEADER: `Alege grupul`,

  ADD_SERVICE_YOUR_GROUP: (serviceName, groupName) =>
    `<em>${serviceName}</em> <em>${serviceName}</em> va fi adăugat la <em>${groupName}</em> <em>${groupName}</em>`,
  ADD_SERVICE_SELECT_GROUP: (serviceName) => `Selectează un grup pentru a adăuga <br /><em>${serviceName}</em> la el`,
  ADD_SERVICE_SELECT_GROUP_SELECT: (serviceName) =>
    `Sau selectează un grup diferit pentru a adăuga <br /><em>${serviceName}</em> la el`,
  MOVE_SERVICE_SELECT_GROUP: (serviceName) => `Selectează un grup în care sa muți <br /><em>${serviceName}</em>`,
  MOVE_SERVICE_LOADING: 'Mutare serviciu',
  MOVE_SERVICE_CONFIRM_HEADER: 'Mutare serviciu',
  MOVE_SERVICE_CONFIRM_NOTE: (
    service,
    fromGroup,
    toGroup,
    numFields,
  ) => `<em>${service}</em> va fi mutat din <em>${fromGroup}</em> în <em>${toGroup}</em>
Acest serviciu are <em>${numFields}</em> forghettible${numFields > 1 ? 's' : ''}.`,
  MOVE_SERVICE_CONFIRM_NOTE_UPDATE: (fromGroup, toGroup, list, listLength) =>
    `Un nou <em>${list}</em> forghettible${
      listLength > 1 ? 's' : ''
    } va fi generat din Doodle-ul <em>${toGroup}</em>. Dacă doriți să accesați vechiul forghettible${
      listLength > 1 ? 's' : ''
    }, pur și simplu desenați Doodle-ul <em>${fromGroup}</em> în loc.`,
  MOVE_SERVICE_CONFIRM: 'Confirmare mutare',
  ADD_SERVICE_SELECT_GROUP_ONBOARD: (serviceName) => `Confirmare mutare`,
  ADD_SERVICE_USERNAME: (serviceName) => `Adăugați <em>${serviceName}</em> la un grup`,
  ADD_SERVICE_USERNAME_ONBOARD: (serviceName) =>
    `Introduceți adresa e-mail a <em>${serviceName}</em> sau numele de utilizator`,
  ADD_SERVICE_USERNAME_HEADER: `Selectați numele de utilizator`,
  ADD_SERVICE_USERNAME_PLACEHOLDER: `E-mail sau nume de utilizator`,
  ADD_SERVICE_SEARCH_FOR_SERVICE: `Introduceți un serviciu pe care îl folosiți, aici`,
  ADD_SERVICE_SEARCH_FOR_ICONS: 'Caută pictograme',
  ADD_SERVICE_SUGGESTED_USERNAMES: `Nume de utilizator sugerate`,
  ADD_SERVICE_DRAW_DOODLE: (groupName) => `Desenați doodle pentru grupul <em>${groupName}</em>`,
  ADD_SERVICE_USERNAME_BLANK: `Trebuie să introduceți o adresă de e-mail sau numele de utilizator`,
  ADD_SERVICE_USERNAME_TOO_LONG: 'Numele dvs. de utilizator trebuie să fie mai mic de 64 de caractere',

  ADD_SERVICE_WEBSITE_HEADER: 'Introdu site',
  ADD_SERVICE_WEBSITE: (serviceName) =>
    `Introduceți URL-ul pentru <em>${serviceName}</em>. Puteți sări peste acest pas și să introduceți o adresă mai târziu.`,
  ADD_SERVICE_WEBSITE_PLACEHOLDER: `Website URL`,

  ADD_SERVICE_SEARCH_ONBOARD_LETS_START: `Să începem prin selectarea uneia dintre aceste servicii populare sau ...`,
  ADD_SERVICE_SEARCH_ONBOARD_NOW_ITS_YOUR_TURN: `ACUM E RÂNDUL TĂU`,
  ADD_SERVICE_DOODLE_DO_IT_AGAIN: `SĂ O FACEM DIN NOU`,
  ADD_SERVICE_DOODLE_ONBOARD_NOW_ITS_YOUR_TURN: `ACUM E RÂNDUL TĂU`,
  ADD_SERVICE_DOODLE_CREATE_DOODLE: 'Creați Doodle-ul',
  ADD_SERVICE_DOODLE_CONFIRM_DOODLE: `Să verificăm dacă vă puteți aminti ... desenați Doodle-ul <em>din nou</em>`,
  ADD_SERVICE_GENERATING_FORGHETTI_ONBOARD: (serviceName) =>
    `Felicitări, forghetti generează acum o nouă parolă pentru <em>${serviceName}</em>`,
  VIEW_SERVICE_GENERATING_FORGHETTI: (serviceName) => `Generarea parolei forghetti pentru <em>${serviceName}</em>`,
  VIEW_SERVICE_RESET_PASSWORD: `Resetați parola`,
  VIEW_SERVICE_PASSWORD_HELP: (serviceName) =>
    `Am generat o parolă foarte puternică pentru <em>${serviceName}</em>. Acum este timpul pentru a reseta parola veche.`,
  VIEW_SERVICE_PASSWORD_HELP_MOVE: (serviceName, groupName) =>
    `Am mutat <em>${serviceName}</em> în <em>${groupName}</em>. În cazul în care doodle-ul pentru acest serviciu s-a schimbat, va trebui să actualizați parola..`,
  VIEW_SERVICE_OLD_PREFIX: 'Vechi',
  VIEW_SERVICE_NEW_PREFIX: 'Nou',
  HIDE_QUICK_SELECT: `Nu-mi arăta acest lucru din nou`,
  HIDE_QUICK_SELECT_CONFIRM_TITLE: `Ascunde acest ecran?`,
  HIDE_QUICK_SELECT_CONFIRM: `Acest lucru nu va mai arăta această pagină în viitor, și adăugarea unui nou serviciu vă va duce direct la pagina de „adaugă un alt serviciu“ în loc.\n\nAcest lucru este reversibil din „preferințele utilizatorului“ oricând.`,
  ADD_SERVICE_DODODLES_DO_NOT_MATCH: `Doodle-urile dvs. nu se potrivesc. Încearcă din nou.`,
  THEME_COLOUR: 'Culoare',
  THEME_COLOURS: ['Flawless Blue', 'Fresh Green', 'Fearless Red', 'Feel-good Orange', 'Friendly Yellow'],
  THEME_MODES: ['Zi', 'Noapte'],
  THEME_BLUE: 'Albastru Perfect',

  DELETE_ACCOUNT_CONTINUE: 'Continuă',
  DELETE_ACCOUNT_HEADER: 'Șterge cont',
  DELETE_ACCOUNT_HEADING: 'Sterge-mi contul',
  DELETE_ACCOUNT_LINE_1: 'Ștergerea contului dvs. va:',
  DELETE_ACCOUNT_LINE_2: '• Ștergeți contul dvs. din forghetti',
  DELETE_ACCOUNT_LINE_3: '• Ștergeți toate informațiile pe care forghetti le deține sub profilul tău',
  DELETE_ACCOUNT_LINE_4: '• Șterge-te din toate grupurile forghetti',
  DELETE_ACCOUNT_LINE_5: '• Eliminați accesul la contul dvs. de pe toate dispozitivele logate',
  DELETE_ACCOUNT_CHANGE_INSTEAD: 'Doriți să schimbați telefonul?',

  DELETE_ACCOUNT_CONFIRM_MESSAGE: (telNumber) =>
    `Am trimis un cod de confirmare către numărul tău de telefon (${telNumber}). Vă rugăm să introduceți acest cod pentru a confirma ștergerea contului:`,
  DELETE_ACCOUNT_CONFIRM_BUTTON: 'Confirmă',

  DEVICES_HEADER: 'Gestionare dispozitive',
  DEVICES_LAST_ACTIVE: 'Ultima oara active: ',
  DEVICES_FORGHETTI_APP: ' - Aplicația forghetti',
  DEVICES_LOG_OUT_ALL: 'Deconectați-vă de pe toate dispozitivele',

  SERVICES_PERSONAL: 'Personal',
  SERVICES_ADD_LOGIN: 'Adăugați un login',
  SERVICES_RECENT: 'Recent', // Not currently used

  SEARCH_INTERNET: 'Căutați pe Internet pentru o imagine',

  SERVICE_HEADER_NEW: 'Adăugați o autentificare',
  SERVICE_NAME_PLACEHOLDER: 'Denumește-ți login-ul',

  SERVICE_DOODLEPAD_SUBHEADING: 'Desenați Doodle pe doodlepad',
  DOODLEPAD_SECRET: 'Ascunde',
  DOODLEPAD_SHOW: 'Arată',
  DOODLEPAD_REDOODLE: 'Curăță',
  ERROR_NO_DOODLE: 'Vă rugăm să desenați Doodle-ul dvs.',

  SERVICE_EDIT_FIELD_HEADER: 'Setări',

  FIELD_TYPES: {
    lns: 'AbC123_*.',
    wns: 'Cuvinte123_ *.',
    lnx: 'AbC123 ~?%',
    wnx: 'Cuvinte123 ~?%',
    ln: 'AbC123',
    wn: 'Cuvinte123',
    l: 'AbC',
    w: 'Cuvinte',
    n: '123',
  },

  FIELD_TEMPLATE_PIN: 'PIN',
  FIELD_TEMPLATE_COMPLEX_PASSWORD: 'Parolă complexă',
  FIELD_TEMPLATE_PASSWORD: 'Parolă',
  FIELD_TEMPLATE_MEMWORD: 'Cuvânt memorabil',
  FIELD_TEMPLATE_FORGHETTIBLE: 'Numai litere',

  SERVICE_USERNAME_LABEL: 'Adresă de e-mail sau nume de utilizator',
  SERVICE_WEBSITE_LABEL: 'Adresă Web',

  SERVICE_EDIT_HEADER: 'Detalii',
  SERVICE_EDIT_USERNAME_PLACEHOLDER: 'Introduceți metoda de autentificare',
  SERVICE_EDIT_WEBSITE_PLACEHOLDER: 'www.webaddress.com',

  SERVICE_ADD_FORGHETTIBLE: 'Adăugați un forghettible',
  SERVICE_DFT_FORGHETTIBLE_NAME: 'Parolă',

  SERVICE_NAME_LENGTH_INVALID: 'Numele serviciului trebuie să fie între 2 și 32 de caractere',
  SERVICE_USERNAME_LENGTH_INVALID: 'Adresa e-mail / nume de utilizator ar trebui să fie mai mic(ă) de 64 de caractere',
  SERVICE_ADDRESS_LENGTH_INVALID: 'Adresa web ar trebui să fie mai mică de 128 de caractere',
  SERVICE_NO_FORGHETTIBLES: 'Trebuie să adăugați cel puțin un forghettible. O parolă, de exemplu.',

  SERVICE_CHOOSE_ICON: 'Alegeți pictograma',

  SERVICE_DOODLEPAD_HEADING: 'Desenați doodle pe doodlepad',
  SERVICE_DOODLEPAD_RIGHT_NAV: 'Detalii',

  SERVICE_ADDING: 'Adăugare serviciu',

  SERVICE_CONNECTING: 'Conectare',

  FIELD_DETAILS_HEADER: 'Setări',
  FIELD_NAME: 'Nume',
  FIELD_NAME_PLACEHOLDER: 'Denumiți-vă forghettible-urile',
  FIELD_TYPE: 'Tip',
  FIELD_LENGTH: 'Lungime',
  FIELD_LENGTH_PICKER_CUSTOM: 'Personalizat',

  FIELD_RIGHT_NAV: 'OK',

  FIELD_OPTIONS_HEADING: 'Opțiuni',

  ADVANCED_OPTIONS: 'Opțiuni avansate',
  RESET_FIELD: 'Resetați forghettible',
  DELETE_FIELD: 'Șterge',
  RESTORE_FIELD: 'Restaurează forghettible',

  FIELD_ADVANCED_OPTIONS_HEADER: 'Opțiuni avansate',

  RESTORE_FIELD_PAGE_HEADER: 'Restaurează',
  RESTORE_FIELD_SUBHEADING: 'Aveți nevoie de o parolă veche?',
  RESTORE_FIELD_DESCRIPTION:
    'Puteți reveni la o versiune anterioară aici. Pur și simplu selectați versiunea pe care doriți să o restabiliți.',
  RESTORE_FIELD_VERSION_HISTORY: 'Istoria versiunilor',
  RESTORE_FIELD_CURRENT: 'Actual',

  SERVICE_VIEW_HEADER: 'Rezumat',
  SERVICE_VIEW_RIGHT_NAV: 'Terminat',

  SERVICE_SUMMARY_MESSAGE: `Forghettible-urile dvs. au fost generate. Vă rugăm să selectați mai jos pentru a copia, vizualiza și accesa aceste date de conectare.`,
  SERVICE_SUMMARY_FIRST_LOGIN_MSG1: `Felicitări! Tocmai ați generat prima parolă forghetti!`,
  SERVICE_SUMMARY_FIRST_LOGIN_MSG2: `Trebuie să înlocuiți parola existentă cu aceasta nouă pentru ca aceasta să intre în efect.`,
  SERVICE_SUMMARY_FIRST_LOGIN_PASSWORD_TOOLTIP: `Când sunteți gata, puteți copia noua parolă în clipboard, făcând clic pe această iconiță`,

  SERVICE_DETAILS_HEADER: 'Detalii',
  SERVICE_DETAILS_RIGHT_NAV: 'Editați',
  SERVICE_DETAILS_DELETE: 'Șterge Autentificare',

  PROFILE_HEADER: 'Profil',
  PROFILE_WIZARD_COMPLETE: 'Completează-ți setarea forghetti',
  PROFILE_WIZARD_SEE_DETAILS: 'Vezi detalii > >',
  PROFILE_PREMIUM_BANNER_HEADING: 'Încercați Premium',
  PROFILE_PREMIUM_BANNER_DETAIL: 'Deblochează noi caracteristici și fii mai în siguranță online.',
  PROFILE_THEME_MODE_LABEL: 'Modificați numărul de telefon',
  PROFILE_THEME_COLOUR_LABEL: 'Culoare',
  PROFILE_BADGES: 'Insignele tale',
  PROFILE_LINK_ACCOUNT: 'Contul tău',
  PROFILE_LINK_PREMIUM: 'Premium',
  PROFILE_ENABLE_AUTOFILL: 'Activează completarea automată',
  PROFILE_LINK_USER_PREFERENCES: 'Preferințe App',
  PROFILE_YOUR_SUBSCRIPTION: 'Abonamentul tău',
  PROFILE_LINK_INVITE: 'Distribuie forghetti unui prieten',
  /*PROFILE_LINK_INVITE_SECONDARY: 'Informează-ți prietenii despre forghetti și câștigă login-uri gratuite',*/
  PROFILE_REFERRAL_CODE: 'Codul de recomandare',
  PROFILE_REFERRAL_CODE_SECONDARY: '',
  PROFILE_LINK_DEVICES: 'Gestionarea dispozitivelor',
  PROFILE_LINK_HELP: 'Obține ajutor',
  PROFILE_LINK_FEEDBACK: 'Trimiteți-ne feedback',
  PROFILE_LINK_ABOUT: 'Despre',
  PROFILE_LINK_LOGOUT: 'Deconectare',

  PROFILE_ACCOUNT_HEADER: 'Contul tău',
  PROFILE_ACCOUNT_LINK_SECURITY: 'Securitate',
  PROFILE_ACCOUNT_LINK_MIGRATE: 'Modificați numărul de telefon',
  PROFILE_ACCOUNT_LINK_DELETE: 'Șterge contul meu',
  PROFILE_ACCOUNT_LINK_SUBSCRIPTION: 'Abonamentul dvs.',

  PROFILE_REONBOARDING: 'Aflați cum funcționează forghetti',
  PROFILE_GET_ONLINE_HELP: 'Obțineți ajutor online',
  PROFILE_GET_VIDEO_HELP: 'Obțineți ajutor video online',
  PROFILE_HELP_HEADER: 'Ajutor',

  PROFILE_EDIT_NAME_HEADER: 'Detalii Profil ',
  PROFILE_EDIT_NAME_LABEL: 'Numele dumneavoastră',
  PROFILE_EDIT_TEL_LABEL: 'Număr de telefon',

  PROFILE_NAME_BLANK: 'Numele profilului nu poate rămâne necompletat',
  PROFILE_NAME_TOO_LONG: 'Numele profilului nu poate fi mai lung de 32 de caractere',

  PROFILE_NAME_INVALID: 'Numele profilului poate conține numai litere, cifre și simboluri',

  PROFILE_MIGRATE_1_HEADER: 'Schimbarea Numărului',
  PROFILE_MIGRATE_1_HEADING: 'Numărul de telefon actual',
  PROFILE_MIGRATE_1_INFO: 'Vă rugăm să introduceți noul cod de țară și numărul de telefon:',
  PROFILE_MIGRATE_1_PLACEHOLDER: 'Număr de mobil',
  PROFILE_MIGRATE_1_BUTTON: 'Trimite codul de verificare',

  PROFILE_MIGRATE_2_HEADER: 'Introdu codul',
  PROFILE_MIGRATE_2_HEADING: 'Numărul de telefon actual',
  PROFILE_MIGRATE_2_HEADING2: 'Numărul de telefon nou',
  PROFILE_MIGRATE_2_INFO: 'Acum, introduceți codul de verificare primit pe noul telefon:',
  PROFILE_MIGRATE_2_BUTTON: 'Confirmă',

  PROFILE_MIGRATE_3_HEADER: 'Gata!',
  PROFILE_MIGRATE_3_MSG: 'Numărul dvs. de telefon a fost modificat',
  PROFILE_MIGRATE_3_HEADING: 'Numărul de telefon nou',

  PROFILE_ABOUT_HEADER: 'Despre',
  PROFILE_ABOUT_PRIVACY: 'Politica de Confidențialitate',
  PROFILE_ABOUT_COOKIE: 'Politica Cookie',
  PROFILE_ABOUT_TERMS: 'Termenii serviciului',
  PROFILE_ABOUT_ACCEPTABLE: 'Politica de utilizare acceptabilă',
  PROFILE_ABOUT_VERSION_HEADING: 'Versiune',

  PROFILE_REFERRAL_CODE_HEADING: 'Codul de recomandare',
  PROFILE_REFERRAL_CODE_LABEL: 'Codul de recomandare',
  PROFILE_REFERRAL_CODE_PLACEHOLDER: 'Introduceți codul dvs. de recomandare',
  PROFILE_EDIT_REFERRAL_CODE_HEADING: 'Editați codul de trimitere',
  PROFILE_REFERRAL_CODE_TEXT: 'Dacă ați primit un cod de recomandare vă rugăm să-l introduceți mai jos.',

  PROFILE_PREMIUM_TYPE: {
    premium_trial: 'Utilizator premium trial',
    premium_life: 'Utilizator pe viață',
    free: 'Utilizator gratuit',
    premium: 'Abonat Premium',
    premium_paid: 'Abonat Premium',
  },
  APP_PREFERENCES_HEADER: 'Preferințe App',
  APP_PREFERENCES_THEME: 'Temă',
  APP_PREFERENCES_QUICK_SELECT: 'Afișați sfaturi',
  APP_PREFERENCES_SHOW_QUICK_SELECT: 'Arată ecranul pentru adăugare rapidă a login-urilor.',
  APP_PREFERENCES_SECRET_DOODLE: 'Doodle Secret implicit',
  APP_PREFERENCES_SHOW_TOOLTIPS: 'Afișați sfaturi',
  APP_PREFERENCES_REQUIRE_TOUCH_ID: 'Cere biometrice integrate pentru a deschide aplicația',
  APP_PREFERENCES_NOTIFICATIONS: 'Notificări',
  APP_PREFERENCES_COUNTRY: 'Limbă',
  APP_PREFERENCES_TOUCH_ID_TOAST: `Asigurați-vă că dispozitivul are ID biometric activat.`,

  PREMIUM_HEADER: 'Forghetti Premium',

  YOUR_SUBSCRIPTION_HEADER: 'Abonamentul dvs.',
  YOUR_SUBSCRIPTION_SUBHEADING: 'Premium Trial',

  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_TEXT_1: (date) =>
    `În prezent, sunteți pe Premium Trial gratis. Perioada de încercare va expira la ${date}. La acel moment, puteți fie continua ca un utilizator gratuit (și pierde toate beneficiile premium) sau să cumpărați un abonament premium.`,
  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_TEXT_2: (date) => `Sperăm că sunteți încântat să folosiți forghetti.`,

  YOUR_SUBSCRIPTION_FREE_TEXT_1: (date) =>
    `În prezent, sunteți un utilizator gratuit. Abonați-vă acum pentru a beneficia de login-uri nelimitate, abilitatea de a crea grupuri nelimitate pe care le puteți distribui între prieteni, familie și colegi, dar mai ales veți avea acces la rapoarte speciale de securitate pentru a verifica dacă unul din conturile dvs. a fost compromis.`,
  YOUR_SUBSCRIPTION_FREE_TEXT_2: (date) => `Sperăm că sunteți încântat să folosiți forghetti.`,
  YOUR_SUBSCRIPTION_FREE_TEXT_3:
    'Dacă aveți deja un abonament de pe app store, faceți clic pe "restaurează abonament".',
  YOUR_SUBSCRIPTION_FREE_DOWNLOAD: `Nu aveți aplicația? Descărcați de pe app store acum.`,

  YOUR_SUBSCRIPTION_PREMIUM_TEXT_1: (date) =>
    `În prezent, sunteți un utilizator premium. Abonamentul expiră la ${date}. Când achiziționați un abonament este setat să se reînnoiască automat. Dacă doriți, puteți să dezactivați această opțiune în contul dvs. de pe App Store.`,
  YOUR_SUBSCRIPTION_PREMIUM_TEXT_2: (date) => `Sperăm că sunteți încântat să folosiți forghetti.`,

  YOUR_SUBSCRIPTION_PREMIUM_NO_DATE_TEXT_1: `În prezent, sunteți un utilizator premium. Când achiziționați un abonament este setat să se reînnoiască automat. Dacă doriți, puteți să dezactivați această opțiune în contul dvs. de pe App Store.`,

  YOUR_SUBSCRIPTION_PREMIUM_LIFE_TEXT_1: (date) =>
    `Abonamentul premium pe viață nu va expira niciodată. Puteți continua folosind forghetti premium pentru totdeauna!`,
  YOUR_SUBSCRIPTION_PREMIUM_LIFE_TEXT_2: (date) => `Sperăm că sunteți încântat să folosiți forghetti.`,
  YOUR_SUBSCRIPTION_FREE_TEXT_APP_3:
    'Dacă aveți deja un abonament de pe app store, faceți clic pe "restaurează abonament".',

  YOUR_SUBSCRIPTION_FREE_TEXT_APP_1: `În prezent, sunteți un utilizator gratuit. Abonați-vă acum pentru a beneficia de login-uri nelimitate, abilitatea de a crea grupuri nelimitate pe care le puteți distribui între prieteni, familie și colegi, dar mai ales veți avea acces la rapoarte speciale de securitate pentru a verifica dacă unul din conturile dvs. a fost compromis.`,

  YOUR_SUBSCRIPTION_FREE_TEXT_APP_2: 'Scanați codul QR cu telefonul pentru a vă abona la serviciul Premium.',
  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_BUTTON_LABEL: 'Vizualizați prețurile',
  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_CORDOVA_BUTTON_LABEL: 'Vezi abonamente',

  YOUR_SUBSCRIPTION_FREE_BUTTON_LABEL: 'Treceți la premium',

  PREMIUM_NO_PRODUCT_SELCTED: 'Selecție produs nevalidă',
  PREMIUM_PURCHASE_BUTTON: 'Cumpărare',
  PREMIUM_PURCHASE_BUTTON_NA: 'În curând',
  COMING_SOON: 'În curând',
  PREMIUM_PRICE_NONE: 'Nevalabil',
  PREMIUM_PURCHASE_BUTTON_OWNED: 'Abonamentul curent',
  PREMIUM_PRUCHASE_REFRESH_PURCAHSE: 'Reactualizează achiziția',
  PREMIUM_MONTHLY_HEADER: 'Premium în fiecare lună',
  PREMIUM_MONTHLY_LIST: [
    {
      description: 'Grupuri Nelimitate',
    },
    {
      description: 'Conectări Nelimitate',
    },
    {
      description: 'Sprijin prioritar',
    },
  ],
  PREMIUM_ANNUAL_ALIAS: 'Planul anual',
  PREMIUM_MONTHLY_ALIAS: 'Planul lunar',
  PREMIUM_ONE_OFF_ALIAS: 'Cumpărare o dată.',
  PREMIUM_LOADING_OWNED: 'Congatulations! Acum sunteți un utilizator premium!',
  PREMIUM_LOADING_RELOAD: 'La scurt timp forghetti se va reîncărca cu noul abonament.',
  PREMIUM_LOADING_APPROVED: 'Vă rugăm să așteptați în timp ce noi confirmăm abonamentul cu app store!',
  PREMIUM_LOADING_REGISTERED: 'Vă rugăm să așteptați cât încărcăm abonamentele disponibile ...',
  PREMIUM_MANAGE_SUBSCRIPTIONS: 'Gestionaţi abonamentul',
  PREMIUM_IMPOSSIBLE_TO_VERIFY: 'Achiziția nu a putut fi verificată',
  PREMIUM_RESTORE_PURCHASE: 'Restaurare abonament',
  PREMIUM_YEARLY_HEADER: 'Premium pentru întregul an',
  PREMIUM_YEARLY_LIST: [
    {
      description: 'Grupuri nelimitate',
    },
    {
      description: 'Login-uri nelimitate',
    },
    {
      description: 'Sprijin prioritar',
    },
  ],
  PREMIUM_LIFETIME_HEADER: 'Premium pentru totdeauna',
  PREMIUM_LIFETIME_LIST: [
    {
      description: 'Grupuri Nelimitat',
    },
    {
      description: 'Login-uri nelimitate',
    },
    {
      description: 'Sprijin prioritar',
    },
  ],
  ENABLE_ANDROID_AUTOFILL_HEADING: 'Activaţi completarea automată',
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_1: `Forghetti folosește serviciul de accesibilitate Android pentru funcția AutoFill, care ajută la preluarea parolelor atunci când este solicitată în orice aplicație de pe dispozitiv.`,
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_2:
    'Vă asigurăm, Forghetti nu citește și nu accesează niciun alt conținut de ecran. Identificăm doar când apare un câmp de parolă pe ecran pentru autofill.',
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_3: `Pentru a utiliza acest serviciu, este necesar consimțământul utilizatorului. Făcând clic pe butonul „Acord”, ne acordați permisiunea de a utiliza serviciul de accesibilitate pentru autofill. Dacă alegeți să refuzați consimțământul, funcția AutoFill va fi dezactivată, dar puteți continua să utilizați Forghetti fără această funcționalitate.`,
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_4: `Vă rugăm să rețineți că navigarea departe de acest ecran nu este luată ca acordând consimțământul și nu folosim mesaje de reducere automată pentru a aduna consimțământul.`,
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_5: `Pentru mai multe informații despre modul în care utilizarea API -ului nostru de accesibilitate este implementată și securizată, precum și gestionarea datelor noastre de utilizator, vă rugăm să consultați Politica noastră de confidențialitate.`,

  ENABLE_ANDROID_AUTOFILL_BUTTON: 'De acord',
  ENABLE_ANDROID_AUTOFILL_BUTTON_NO: 'Nu acum',


  MAKE_ADMIN_MESSAGE: 'Doriți să faceți acest utilizator administrator al acestui grup?',
  MAKE_OWNER_MESSAGE: 'Doriți să transferați calitatea de proprietar al acestui grup către acest utilizator?',

  REMOVE_ADMIN_MESSAGE: 'Doriți să eliminați calitatea de administrator pentru acest utilizator?',
  DELETE_GROUP_CONFIRM: 'Sunteți sigur că doriți să ștergeți acest grup?',
  LEAVE_GROUP_CONFIRM: 'Doriți să părăsiți acest grup?',
  GROUP_DELETED_MESSAGE: 'Grupul a fost șters acum.',
  REMOVE_MEMBER_MESSAGE: 'Doriți să eliminați această persoană din grup?',
  REMOVE_MEMBER_SUCCESS: 'Membrul grupului a fost înlăturat.',
  GROUP_SHARED_BY: 'Grupuri partajate de',
  ERROR_INVALID_NUM_WORDS: 'Numărul de cuvinte trebuie să fie între 1 și 10',
  ERROR_INVALID_NUM_LETTERS: 'Numărul de caractere trebuie să fie între 1 și 64',

  UPGRADE_REQUIRED: 'Este necesar upgrade-ul.',
  ADD_LOGIN_NOT_ALLOWED: `Ca utilizator gratuit, este permisă doar adăugarea login-urilor la grupul dvs. personal. Treceți la premium astăzi pentru a crea grupuri și login-uri între prieteni, familie și colegi!`,
  UPGRADE_TODAY_MESSAGE: `Ca utilizator gratuit, vă puteți alătura unui singur grup.
Treceți la premium astăzi pentru a crea grupuri și login-uri între prieteni, familie și colegi!`,

  UPGRADE_TODAY_BUTTON: 'Treceți la premium acum!',
  UPGRADE_TODAY_PAGE_HEADING: 'Treceți la premium acum!',
  UPGRADE_TODAY_MSG_1: 'Devino un utilizator forghetti Premium',
  UPGRADE_TODAY_PRICE: '£ 23.99 pe an',
  UPGRADE_TODAY_MSG_2: 'Eliberați toate ofertele forghetti de funcționalitate',
  UPGRADE_TODAY_MSG_3:
    'Abonamentul Premium este disponibil ca o achiziție în aplicație, în oricare dintre aplicațiile noastre mobile:',

  UPGRADE_TODAY_MSG_4:
    'Un abonament anual vă permite să deveniți un utilizator premium cu abilitatea de a crea login-uri nelimitate, forghettible-uri si grupuri.',

  INVITE_FRIEND_HEADER: 'Obțineți servicii gratuite',
  INVITE_FRIEND_REFERRAL_LABEL: 'Link de recomandare',
  INVITE_FRIEND_REFERRAL_COPY: 'Copiere link de recomandare',
  INVITE_FRIEND_REFERRAL_EMAIL: 'E-mail cu link-ul de recomandare',
  INVITE_FRIEND_PREAMBLE: 'Distribuie forghetti unui prieten',
  INVITE_FRIEND_TEXT: `Distribuie doar link-ul tau personal`,
  INVITE_FRIEND_BENEFIT:
    'Recomandă forghetti unui prieten și obține înca 5 login-uri gratuite adăugate la grupul dvs. Personal. Convinge 5 prieteni sa își ia forghetti și vei câștiga încă 15 login-uri în plus.',

  GROUPS_LIST_HEADER: 'Grupurile tale',
  GROUPS_LIST_INFO_MESSAGE: 'Gestionați grupurile sau schimbă între grupuri',
  GROUPS_LIST_PERSONAL_HEADING: 'Grupul tău personal',
  GROUPS_LIST_OTHER_GROUPS_HEADING: 'Celelalte grupuri ale tale',
  GROUPS_LIST_ADD_NEW_GROUP: 'Adaugă un grup nou',
  GROUPS_LIST_CURRENTLY_ACTIVE: 'ACTIVE CURENT',
  GROUPS_NEW_HEADER: 'Grup nou',
  GROUPS_NEW_RIGHT_NAV: 'Crează',
  GROUPS_NEW_NAME_PLACEHOLDER: 'Introduceți un titlu',

  GROUPS_ADD_GROUP_HEADER: 'Adăugare grup',
  GROUPS_JOIN_GROUP: 'Alătură-te grupului',
  GROUPS_JOIN_GROUP_TEXT: 'Alăturați-vă unui grup folosind un link de invitație',
  GROUPS_CREATE_GROUP: 'Creare grup',
  GROUPS_CREATE_GROUP_TEXT: 'Creați un grup nou pentru a distribui altora',
  GROUPS_FREE_JOIN_WARNING: 'Ca un utilizator gratuit, vă puteți alătura unui singur grup.',
  GROUPS_ADD_UPGRADE: 'Treceți la premium astăzi pentru a crea grupuri și login-uri între prieteni, familie și colegi!',
  GROUP_NEW_INVITE_GENERATING: 'Generare invitație pt, grup',
  LEAVE_GROUP_MESSAGE: 'Ați fost eliminat din grup.',
  GROUP_DETAIL_LOADING: `Regăsire detalii grup`,
  GRP_SHARING_HEADING: 'Distribuire',
  GRP_MEMBERS_HEADING: 'Membri',
  GRP_SETTINGS_HEADING: 'Setări grup',

  GROUP_VIEW_HEADER: 'Detalii grup',
  GROUP_VIEW_RIGHT_NAV: 'Editare',
  GROUP_VIEW_USER_ADMIN: 'Administrator',
  GROUP_VIEW_USER_OWNER: 'Proprietar',
  GROUP_VIEW_USER_YOU: 'Tu',
  GROUP_VIEW_USER_READ_ONLY: 'Read-only',
  GROUP_VIEW_SWITCH_TO: 'Schimbă-te la acest grup',
  GROUP_VIEW_LEAVE: 'Părăsiți acest grup',
  GROUP_VIEW_DELETE: 'Șterge grup',
  GROUP_VIEW_PREMIUM: 'Utilizator premium',
  GROUP_VIEW_FREE: 'Utilizator gratuit',
  GROUP_DETAIL_SAVE_LOADING: `Actualizați detalii grup`,
  GROUP_EDIT_HEADER: 'Detalii grup',
  GROUP_EDIT_MAX_MEMBERS: 'Numărul maxim de membri',
  GROUP_EDIT_MAX_MEMBERS_SECONDARY: 'Inclusiv tu',
  PERSONAL_GROUP_MESSAGE: `Acesta este grupul dvs. personal. Acest grup nu este niciodată împărțit cu altcineva. Pentru a schimba pictograma pentru acest grup, du-te la profilul tău și schimbă fotografia de profil.`,

  GROUP_CHOOSE_ICON: 'Alegeți Pictograma',

  GROUP_NAME_BLANK: 'Numele grupului nu poate rămâne necompletat',
  GROUP_NAME_TOO_LONG: 'Numele grupului nu poate fi mai lung de 32 de caractere',

  GRP_MAX_MEMBERS_ERROR: 'Numărul maxim de membri trebuie să fie un număr cuprins între 1 și 50',

  JOIN_GROUP_PLACEHOLDER: 'Introduceți link-ul de invitație',
  JOIN_GROUP_HEADING: 'Alătură-te grupului',
  GRP_INVITE_CODE_LABEL: 'Link de invitație în grup',
  GRP_INVITE_CODE_NOTE: (group) => `Invită membri în <em>${group}</em> cu acest link`,
  JOIN_GROUP_RIGHT_NAV: 'Alătură-te',

  REFERRAL_COPY_MESSAGE: (url) =>
    `Te invit să folosești forghetti . Descarcă aplicația și apoi uită-ți parolele pentru totdeauna. Intră pe ${url}`,

  CREATE_GROUP_INVITE_TEXT_1: `Trimite acest cod familiei, prietenilor sau colegilor pentru a le oferi acces la acest grup.

Pentru a vă alătura unui grup, faceți clic pe „Adăugați", apoi pe "Alătură-te grupului" și inserați link-ul invitației.`,

  INVITE_NEW_MEMBER: 'Invitați un nou membru',
  GRP_INVITE_HELP_TEXT: `Toți membrii grupului au capacitatea de a crea noi login-uri, dar numai administratorii au capacitatea de a le șterge. Administratorii pot elimina, de asemenea, membri, cu toate acestea, dacă creați un grup, niciodată nu vei putea fi scos de către un alt administrator.`,

  // Template for text that is copied to clipboard when you choose to copy a group invite code to clipboard
  GRP_INVITE_CLIPBOARD_MESSAGE: (code) => `${code}`,

  INVITE_LINK_HEADING: 'Invitație',
  INVITE_SETTINGS_HEADING: 'Setări',
  LINK_EXPIRY_LABEL: 'Link-ul expiră după',
  MAX_USES_LABEL: 'Numărul maxim de utilizări',

  LINK_EXPIRY_VALUE: (mins) => LINK_EXPIRY_VALUES[String(mins)],
  LINK_MAX_USES_VALUE: (num) => LINK_MAX_USE_VALUES[String(num)],
  INVITE_NO_LIMIT: LINK_MAX_USE_VALUES['-1'],
  INVITE_EXPIRY_NEVER: LINK_EXPIRY_VALUES['-1'],

  DEACTIVATE_GRP_LINK_CONFIRM: 'Sunteți sigur că doriți să ștergeți această invitație?',
  DEACTIVATE_GRP_LINK_SUCCESS: 'invitația de grup a fost ștearsă cu succes.',

  GRP_INVITE_CREATE_NAV: 'Crează',

  // Share Group Invite page
  SHARE_INVITE_HEADING: 'Invită membri',
  GRP_INVITE_CODE_LABEL: 'Link de invitație',
  CPY_GRP_INVITE: 'Copiați în clipboard invitația',
  DEACTIVATE_LINK: 'Ștergeți invitația',
  SHARE_INVITE_VIA_EMAIL: 'Trimite invitația prin e-mail',
  COPY_INVITE_SUCCESS: `Invitația de grup e copiată`,

  ONBOARDING_HEADER_1: 'Creați un login la un site favorit',
  ONBOARDING_HEADER_2: 'Desenați Doodle-ul dvs.',
  ONBOARDING_HEADER_3: 'Parola dvs. este generată',
  ONBOARDING_HEADER_4: 'Copiază și GO!',
  ONBOARDING_HEADER_5: 'Asta e!',
  ONBOARDING_WELCOME_HEADING: `Bună, numele meu este Locky <br> Bine ati venit pe forghetti`,
  ONBOARDING_WELCOME_TEXT:
    'Folosind forghetti, vă puteți conecta la toate serviciile pe care le utilizați, cu un singur desen doodle securizat.',
  ONBOARDING_DOODLE_HEADING: 'Vei avea nevoie de o semnătură doodle.',
  ONBOARDING_DOODLE_TEXT:
    'Linii, puncte sau desene. Forme, litere sau numere aleatoarii. Combinația dvs. unică generează o parolă foarte puternică.',
  ONBOARDING_HOW_IT_WORKS: 'Cum funcționează',
  ONBOARDING_ADDSERVICE_HEADING: 'Super pentru orice serviciu',
  ONBOARDING_ADDSERVICE_TEXT:
    'De fiecare dată când adăugați un serviciu, vom genera o parolă puternică unică. Puteți genera chiar și numere PIN sau cuvinte memorabile.',
  ONBOARDING_RESETPASSWORD_HEADING: 'Resetează și schimbă parola veche.',
  ONBOARDING_RESETPASSWORD_TEXT:
    'Deschideți site-ul și resetați vechea parolă, înlocuind-o cu noua parolă forghetti, ultra safe..',
  ONBOARDING_SECURESHARING_HEADING: 'Secure password sharing',
  ONBOARDING_SECURESHARING_TEXT:
    'Utilizați grupuri pentru a împărți accesul la serviciile preferate în siguranță, cu prietenii, familia și colegii.',
  ONBOARDING_UNLIKE_OTHERS_HEADING: 'Spre deosebire de alte aplicații de gestionat parole...',
  ONBOARDING_UNLIKE_OTHERS_TEXT:
    'Noi nu stocăm o bază de date de parole. Noi le generăm, atunci când aveți nevoie de ele, pe moment. Acestea sunt calculate folosind desenul doodle drept cheie. Super sigur și cunoscut doar de tine.',
  ONBOARDING_DOODLETUTORIAL1_HEADING: 'Acum, crearea doodle-ului! Cum se face?',
  ONBOARDING_SHOWMEHOW: 'Să-ți arăt cum!',
  ONBOARDING_DOODLETUTORIAL2_HEADING: 'În primul rând, puteți desena un model ...',
  ONBOARDING_DOODLETUTORIAL: 'Tutorial Doodle Securizat',
  ONBOARDING_DOODLETUTORIAL3_HEADING: 'sau o serie de linii',
  ONBOARDING_DOODLETUTORIAL4_HEADING: 'chiar și punctele sunt OK ...',
  ONBOARDING_DOODLETUTORIAL5_HEADING: 'Sau o combinație a acestora împreună',
  ONBOARDING_DOODLETUTORIAL6_HEADING: 'Dar, luați aminte, ordinea desenului contează!',
  ONBOARDING_DOODLETUTORIAL7_HEADING: 'Desenarea Doodle-ului A',
  ONBOARDING_DOODLETUTORIAL8_HEADING: 'nu este același lucru cu desenarea doodle-ului B',
  ONBOARDING_YOURTURN: `Acum e rândul tău`,

  ONBOARDING_COMPLETE_WELCOME: 'Bine ati venit pe forghetti',
  ONBOARDING_COMPLETE_CONGRATULATIONS:
    'Felicitări! Tocmai ai făcut primii pași pentru securizarea identității tale online',
  ONBOARDING_COMPLETE_COMPLETE_SETUP: 'Finalizează configurarea forghetti',
  ONBOARDING_COMPLETE_CREATE_FORGHETTI: 'Creați un cont forghetti',
  ONBOARDING_COMPLETE_SIGNATURE_DOODLE: 'Creați desenul semnatură doodle.',
  ONBOARDING_COMPLETE_FIRST_LOGIN: 'Adăugați primul serviciu',
  ONBOARDING_COMPLETE_RESET_PASSWORD: 'Am generat o parolă securizată',
  ONBOARDING_COMPLETE_IMPORT: 'Importează servicii/conturi.',
  ONBOARDING_COMPLETE_HEALTHCHECK: 'Efectuați un Control de Securitate al conturilor tale online.',
  ONBOARDING_COMPLETE_IMPORT_BUTTON: 'Importează servicii/conturi.',
  ONBOARDING_COMPLETE_REMIND_LATER: 'Amintește-mi mai tarziu',
  ONBOARDING_COMPLETE_CONTINUE: `Să pornim`,
  ONBOARDING_COMPLETE_RESET_PASSWORD: 'Resetați o parolă compromisă',
  ONBOARDING_COMPLETE_HEALTHCHECK_BUTTON: 'Efectuați un Control de Securitate.',
  ONBOARDING_COMPLETE_NEXT_STEPS: `<h2>Acum, de ce nu încercați să:</h2><ul><li>Efectuați un Control de Securitate al conturilor dvs. online?</li><li>Sau să actualizați un alt serviciu pe forghetti.</li><li>Sau doar să aruncați o privire prin aplicație ...</li></ul>`,

  ONBOARDING_EXISTING_TITLE: 'Funcții noi',
  ONBOARDING_EXISTING_WELCOME_HEADING: 'Un mare mulțumesc',
  ONBOARDING_EXISTING_WELCOME_TEXT: `Echipa forghetti a muncit din greu. Am făcut o mulțime de îmbunătățiri. Aruncati o privire la unele dintre noile caracteristici.`,
  ONBOARDING_EXISTING_MOVE_SERVICES_HEADING: 'Mutare servicii',
  ONBOARDING_EXISTING_MOVE_SERVICES_TEXT:
    'forghetti acum vă permite să mutați servicii de la un grup la altul. Pur și simplu faceți clic pe cele trei puncte lângă fiecare serviciu și selectați mutarea..',
  ONBOARDING_EXISTING_HEALTHCHECK_HEADING: 'Control de Securitate',
  ONBOARDING_EXISTING_HEALTHCHECK_TEXT:
    'forghetti include acum un Control de Securitate, o caracteristică care vă permite să monitorizați în mod activ numele de utilizator și parolele verificate intr-o bază de date compromise..',
  ONBOARIDNG_EXISTING_SHARING_HEADING: 'Partajare rapidă a grupurilor',
  ONBOARDING_EXISTING_SHARING_TEXT:
    'Împărtășiți grupurile forghetti cu prietenii, familia și colegii, cu o simplă apăsare a unui buton.',
  ONBOARDING_EXISTING_SWITCHING_HEADING: 'Schimbă între grupuri rapid',
  ONBOARDING_EXISTING_SWTICHING_TEXT:
    'Lucrul cu grupurile dvs. a devenit mai ușor ca niciodată. Adăugarea de noi servicii la un grup este mai rapidă și mai ușoară ca oricând.',
  ONBOARDING_EXISTING_PRICING_HEADING: 'Prețuri noi',
  ONBOARDING_EXISTING_PRICING_TEXT:
    'forghetti oferă acum mai multe planuri de abonament pentru a se potrivi nevoilor dumneavoastră.',

  ACCOUNT_SECURITY_HEADER: 'Securitate',
  ACCOUNT_SECURITY_HEADING: 'Nimic mai sigur',
  ACCOUNT_SECURITY_SECONDARY_HEADING:
    'Noi tratăm securitatea datelor ca pe o prioritate. forghetti vă permite să generați login-uri pentru mai multe servicii folosind un singur desen doodle. Partea inteligentă este faptul că, forghetti nu stochează parole stocate .... nici măcar una. Spre deosebire de orice altă aplicație de gestionare a parolei, și sunt multe, noi nu salvăm parolele tale nicăieri.  ',
  ACCOUNT_SECURITY_LEARN_MORE: 'Aflați mai multe despre siguranța forghetti',

  MSG_FALLBACK_ERROR_MESSAGE: `A apărut o eroare! Ne cerem scuze.`,

  MSG_GROUP_ICON_UPDATED: 'Pictogramă actualizată',
  MSG_COPIED_FORGHETTIBLE: (name) => `${name} copiat în clipboard`,
  MSG_OPEN_FORGHETTIBLE: `Click aici pentru a deschide`,
  MSG_DELETE_LOGIN_CONFIRM: 'Sunteți sigur că doriți să ștergeți acest login?',
  MSG_LOGIN_DELETED: (name) => `Loginul ${name} a fost șters.`,
  MSG_SERVICE_ICON_DELETED: 'Pictograma de serviciu actualizat',
  MSG_LOGOUT_CONFIRM: 'Sunteți sigur că doriți să vă deconectații?',
  MSG_LOGOUT_ALL_DEVICES_CONFIRM: 'Ești sigur?',
  MSG_USER_PROMOTED_ADMIN: 'Utilizatorul a fost promovat la admininistrator',
  MSG_USER_PROMOTED_OWNER: 'Drepturile de proprietar au fost transferate',

  MSG_CONFIRM_RESET: 'Vă rugăm să confirmați că doriți să resetați această parolă',
  MSG_CONFIRM_RESTORE: 'Vă rugăm să confirmați că doriți să restabiliți această versiune anterioară?',
  MSG_NEW_GROUP_CREATED: (name) => `Grup nou a fost creat: ${name}`,
  MSG_OPENING_WEBSITE: 'Deschid site-ul...',
  MSG_REMOVED_ADMIN: 'Drepturile de administrator au fost revocate pentru acest utilizator',
  MSG_UNSAVED_CONFIRM: 'Există modificări nesalvate. Sunteți sigur că ați dori să mergeți înapoi?',

  EXTENSION_NONE_FOUND: 'Nu a fost gasit forghettible pentru acest website.',
  EXTENSION_GO_TO_FORGHETTI: 'Du-te la forghetti',
  EXTENSION_NOACCESS_HEADER: 'Treceți la premium astăzi',
  EXTENSION_UPGRADE: `În prezent, sunteți un utilizator gratuit. Treceți la premium astăzipentru a utiliza extensia forghetti Chrome și poți beneficia de un număr nelimitat de login-uri și abilitatea de a crea grupuri și login-uri între prieteni, familie și colegi!. Dacă doriți să treceți la un abonament premium vă rugăm să selectați butonul Treceți la Premium de mai jos.`,
  EXTENSION_NOLOGINFOUND_HEADER: `Nu a fost găsit în forghetti`,
  EXTENSION_NOLOGINFOUND_MSG:
    'Nu a fost gasit niciun forghettible pt acest website.. Desenați un doodle mai jos pentru a crea rapid un nou forghettible pentru acest site.',

  CONTEXT_EDIT_LOGIN_LIST_ITEM: 'Editează',
  CONTEXT_MOVE_LOGIN_LIST_ITEM: 'Mută',
  CONTEXT_ADD_LOGIN_LIST_ITEM: 'Adăugați forghettible',
  CONTEXT_RESET_LOGIN_LIST_ITEM: 'Resetați forghettible',
  CONTEXT_DELETE_LOGIN_LIST_ITEM: 'Șterge',
  CONTEXT_UPLOAD_GROUP_PHOTO: 'Fă o poză',
  CONTEXT_UPLOAD_GROUP_IMAGE: 'Încarcă imaginea',
  CONTEXT_CHOOSE_GROUP_IMAGE: 'Alegeți o imagine',
  CONTEXT_TAKE_PROFILE_PIC: 'Fă o poză',
  CONTEXT_UPLOAD_PROFILE_PIC: 'Încarcă fotografia',
  CONTEXT_UPLOAD_SERVICE_IMAGE: 'Încarcă imaginea',
  CONTEXT_CHOOSE_SERVICE_IMAGE: 'Alegeți o imagine',
  CONTEXT_SERVICE_IMAGE_WEBSEARCH: 'Căutare pe internet',
  CONTEXT_MAKE_ADMIN: 'Setează Admin',
  CONTEXT_MAKE_OWNER: 'Setează Proprietar',

  CONTEXT_REMOVE_FROM_GROUP: 'Eliminați din grup',
  CONTEXT_REVOKE_ADMIN: 'Revocați Admin',
  CONTEXT_NEW_GROUP_MEMBERS: 'Numărul maxim de membri al noului grup',
  CONTEXT_SELECT_FIELD_TYPE: 'Selectați tipul',
  CONTEXT_FIELD_TYPE_LNS: 'Litere, cifre și simboluri',
  CONTEXT_FIELD_TYPE_WNS: 'Cuvinte, numere și simboluri',
  CONTEXT_FIELD_TYPE_LNX: 'Litere, cifre și simboluri complexe',
  CONTEXT_FIELD_TYPE_WNX: 'Cuvinte, numere și simboluri complexe',
  CONTEXT_FIELD_TYPE_LN: 'Litere și cifre',
  CONTEXT_FIELD_TYPE_WN: 'Cuvinte și numere',
  CONTEXT_FIELD_TYPE_L: 'Numai litere',
  CONTEXT_FIELD_TYPE_W: 'Numai cuvinte',
  CONTEXT_FIELD_TYPE_N: 'Numai numere',
  CONTEXT_ADD_FIELD: 'Selectați un câmp pre-definit de mai jos. Puteți personaliza setările de câmp ulterior.',
  CONTEXT_ADD_FIELD_PASS: 'Parolă (litere, cifre și simboluri)',
  CONTEXT_ADD_FIELD_COMPLEX_PASS: 'Parolă avansată (litere, cifre și simboluri complexe)',

  CONTEXT_ADD_FIELD_PIN: 'PIN (Numai numere)',
  CONTEXT_ADD_FIELD_MEMWRD: 'Cuvânt memorabil (numai cuvinte)',
  CONTEXT_ADD_FIELD_FORGHETTIBLE: 'Numai litere',
  CONTEXT_LINK_EXPIRE_15MINS: '15 minute',
  CONTEXT_LINK_EXPIRE_30MINS: '30 minute',
  CONTEXT_LINK_EXPIRE_1HOUR: '1 oră',
  CONTEXT_LINK_EXPIRE_6HOURS: '6 ore',
  CONTEXT_LINK_EXPIRE_12HOURS: '12 ore',
  CONTEXT_LINK_EXPIRE_1DAY: '1 zi',
  CONTEXT_LINK_EXPIRE_NEVER: 'Niciodată',
  CONTEXT_LINK_EXPIRE_CANCEL: 'Anulare',
  CONTEXT_LINK_USES_1: 'O singură dată',
  CONTEXT_LINK_USES_2: 'De două ori',
  CONTEXT_LINK_USES_5: '5 ori',
  CONTEXT_LINK_USES_10: '10 ori',
  CONTEXT_LINK_USES_20: 'de 20 de ori',
  CONTEXT_LINK_USES_50: 'de 50 de ori',
  CONTEXT_LINK_USES_NO_LIMIT: 'Fără limită',
  CONTEXT_LINK_USES_CANCEL: 'Anulare',

  FINGERPRINT_TITLE: 'Bine ați venit la forghetti',
  FINGERPRINT_DESCRIPTION: 'Scanați amprenta pentru a debloca forghetti',
  FINGERPRINT_DESCRIPTION_FACE: 'Utilizați ID-ul Feței pentru a debloca forghetti',

  GENERATING_GROUP: 'Generez grupul',
  GENERATING_PASSWORD: 'Generez parola',
  DELETING_GROUP: 'Șterg grupul',
  LOADING_SERVICE: 'Se încarcă serviciul',
  UNABLE_TO_PURCHASE: 'Nu se poate achiziționa abonament premium în prezent. Vă rugăm să încercați mai târziu',

  HEALTHCHECK_WELCOME_HEADER: 'Control de Securitate',
  HEALTHCHECK_WELCOME_NOTE:
    'Pornește Controlul de Securitate al forghetti află dacă detaliile tale  au fost compromise într-o încălcare a securității datelor.',
  HEALTHCHECK_WELCOME_BUTTON: 'Efectuați un Control de Securitate',
  HEALTHCHECK_USERNAMES_HEADER: `Să începem cu adresa ta de e-mail`,
  HEALTHCHECK_USERNAMES_INFO_1: 'Verificați adresa de e-mail',
  HEALTHCHECK_USERNAMES_INFO_2:
    'Spune-ne adresa ta de e-mail și noi o vom verifica împotriva oricăror spargeri online noi sau anterioare.',
  HEALTHCHECK_USERNAMES_PRIMARY_SUBHEAD: 'Adresa dvs. de e-mail principală',
  HEALTHCHECK_USERNAMES_SECONDARY_SUBHEAD: 'Adresa de e-mail secundară',
  HEALTHCHECK_USERNAMES_PLACEHOLDER: 'Adresa de email',
  HEALTHCHECK_PASSWORD_HEADER: `Să verificăm parola`,
  HEALTHCHECK_PASSWORD_INFO_1: 'Utilizați aceeași parolă de multe ori?',
  HEALTHCHECK_PASSWORD_INFO_2: `Înainte de a utiliza forghetti, ai folosit aceeași parolă de multe ori?
Să vedem dacă a fost expusă într-o spargere a securității datelor.`,
  HEALTHCHECK_PASSWORD_INFO_3:
    'Această verificare este opțională. Forghetti nu stochează sau transmite parola. Forghetti genereaza un hash securizat al parolei tale. Numai o parte a hash-ului parolei este transmisă de aplicație, astfel încât să puteți fi sigur că parola este în siguranță cu noi.',

  HEALTHCHECK_PASSWORD_SUBHEAD: 'Parola ta',
  HEALTHCHECK_PASSWORD_OPTIONAL: 'Acest lucru este opțional.',
  HEALTHCHECK_PASSWORD_PLACEHOLDER: 'Parolă',
  HEALTHCHECK_PASSWORD_VAL_TOO_LONG: 'Câmpul pentru parolă este prea lung',

  HEALTHCHECK_RUNNING: 'Rulez Control de Securitate',
  HEALTHCHECK_USERNAME_VAL_FIELD_BLANK: 'Adresa de e-mail nu poate fi lăsată necompletată',
  HEALTHCHECK_USERNAME_VAL_FIELD_DUPLICATE: 'Adresa de e-mail secundară nu poate fi la fel ca cea primară',
  HEALTHCHECK_USERNAME_VAL_FIELD_TOO_SHORT: 'Adresa de e-mail prea scurtă',
  HEALTHCHECK_USERNAME_VAL_FIELD_TOO_LONG: 'Adresa de e-mail prea lungă',

  HEALTHCHECK_ASSESSMENT_HEADER: 'Control de Securitate',
  HEALTHCHECK_ASSESSMENT_EMAIL: (email) => `<em>Spargeri pe ${email}</em>`,
  HEALTHCHECK_ASSESSMENT_INFO_1: 'Rezultatele Controlului de Securitate sunt afișate mai jos.',
  HEALTHCHECK_ASSESSMENT_INFO_2: 'Utilizatorii Premium pot face clic pe spargere pentru a afla mai multe informații.',

  HEALTHCHECK_ASSESSMENT_PASSWORD: '<em><em>Rezultate pentru parola ta</em></em>',
  HEALTHCHECK_ASSESSMENT_COMPROMISED_HEAD: `Servicii compromise`,
  HEALTHCHECK_ASSESSMENT_NO_BREACH: 'Nu sunt servicii sparte cu acest cont.',
  HEALTHCHECK_ASSESSMENT_PASSWORD_STRENGTH: 'Putere',
  HEALTHCHECK_ASSESSMENT_PASSWORD_WEAK: 'Slab',
  HEALTHCHECK_ASSESSMENT_PASSWORD_STRONG: 'Puternic',
  HEALTHCHECK_ASSESSMENT_PASSWORD_COMP: 'Apare într-o spargere a securității datelor',
  HEALTHCHECK_ASSESSMENT_HIBP_LINK: 'Sursa: <a data-actionclick="openHIBP">haveIbeenpwned</a>',
  HEALTHCHECK_ASSESMENT_UPGRADE_MESSAGE:
    'Detaliile de încălcare a securității sunt disponibile doar utilizatorilor premium',
  HEALTHCHECK_DETAILS_PASSWORD_HEADER: 'Rezultate parolă',
  HEALTHCHECK_DETAILS_USER_HEADER: `Detalii spargere`,
  HEALTHCHECK_DETAILS_USER_TITLE: 'Nume',
  HEALTHCHECK_DETAILS_USER_DOMAIN: 'Domeniu',
  HEALTHCHECK_DETAILS_USER_DATE: 'Data spargerii',
  HEALTHCHECK_DETAILS_USER_ACCOUNTS_PWNED: 'Conturi sparte',
  HEALTHCHECK_DETAILS_PASSWORD_INFO_X_BREACH: (breach) =>
    `Parola dvs. apare în <em>${breach}</em>. Acest lucru nu înseamnă neapărat că aceste conturi au fost compromise. Recomandăm o generare a unei parole super puternice cu <em>forghetti</em>.`,
  HEALTHCHECK_DETAILS_PASSWORD_INFO_NO_BREACH: `Vesti bune! Parola dvs. nu este listată pe nicio spargere. Acest lucru nu garantează că conturile dvs. nu au fost compromise. Recomandăm o generare a unei parole super puternice cu <em>forghetti</em>.`,
  HEALTHCHECK_DETAILS_PASSWORD_STRENGTH_INFO_STRONG: `Parola ta e <em>puternică</em>. Acest lucru înseamnă că a trecut toate criteriile stabilite de testul de rezistență`,
  HEALTHCHECK_DETAILS_PASSWORD_STRENGTH_INFO_WEAK: `Parola ta este <em>slabă</em>. Acest lucru înseamnă că nu îndeplinește criteriile stabilite de testul de rezistență forghetti.`,
  HEALTHCHECK_DETAILS_RESET_NOTE: 'Generez o parola super puternică cu forghetti acum',
  HEALTHCHECK_DETAILS_RESET_BUTTON: 'Crează un nou serviciu',
  HEALTHCHECK_DETAILS_HIBP_LINK: 'Sursa: haveIbeenpwned',
  HEALTHCHECK_LOADING_DETAIL: 'Încarc detaliile spargerii',

  IMPORT_SERVICE_WELCOME_BUTTON: 'Începe importarea serviciilor',
  IMPORT_SERVICE_WELCOME_INFO: 'Importeaza login-urile existente, pe forghetti',
  IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_1: 'Pentru a importa serviciile deschide aplicația forghetti de pe desktop.',
  IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_2:
    'Dacă nu aveți aplicație pentru desktop forghetti, faceți clic pe link-ul de mai jos',
  IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_BUTTON: 'Descărcați forghetti',
  IMPORT_SERVICES_LIST_HEADER: 'Selectați serviciile pe care vrei să le importezi',
  IMPORT_SERVICES_LIST_ERROR_NONE_SELECTED: 'Nu ați selectat niciun serviciu pt. importare',
  IMPORT_SERVICES_YOUR_IMPORTED_SERVICES: 'Serviciile tale importate',
  IMPORT_SERVICES_LIST_NOTE: `Alegeți serviciile pe care doriți să le importați în forghetti.`,
  IMPORT_SERVICES_GROUP_NOTE: 'Selectați un grup în care doriți să mutați serviciile dvs.',
  IMPORT_SERVICES_GROUP_HEADER: 'Selectați grupul',
  IMPORT_SERVICES_USERNAME_HEADER: 'Introdu numele de utilizator',
  IMPORT_SERVICES_USERNAME_NOTE: 'Verificați numele de utilizator asociat pentru fiecare cont',
  IMPORT_SERVICES_CREATE_HEADER: 'Crează servicii',
  IMPORT_SERVICES_CREATE_BUTTON: 'Începe crearea de servicii',
  IMPORT_SERVICES_CREATE_HOW_MANY: (number) => `<em>${number}</em> servicii pentru a fi crea`,
  IMPORT_SERVICES_CREATE_LOADING: (current, total) => `Importate <em>${current}</em> din <em> ${total}</em> servicii`,
  IMPORT_SERVICES_COMPLETE_HEADER: 'Importare finalizată',
  IMPORT_SERVICES_COMPLETE_NOTE: `Felicitări! Serviciile dvs. au fost importate`,
  IMPORT_FOLDER_SELECT_HEADER: 'Importare din Chrome',
  IMPORT_FOLDER_SELECT_CHROME: 'Importează servicii din Chrome',
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC: `forghetti va trebui să acceseze datele tale de autentificare stocate pe computer.`,
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_2: `Când sunteți gata, faceți clic pe butonul „Select folderul de Chrome“ de mai jos. O căsuță va apărea la locația login-ului Chrome. Pur și simplu selectați „Open“ pentru a da acces lui forghetti la acest dosar.`,
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_3:
    'Dacă doriți să localizați manual acest dosar, mergeți la Library/Application Suppor/Google/Chrome',
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_4:
    'Luați aminte, parolele existente nu sunt decriptate de forghetti. forghetti preia doar datele de autentificare.',
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_5:
    'Odată ce datele de autentificare sunt citite, veți putea selecta serviciile pe care doriți să le importați în forghetti.',

  IMPORT_FOLDER_SELECT_CHROME_BUTTON: 'Selectați dosarul Chrome',

  IMPORT_GROUP_HEADER: 'Grupul dvs. de servicii importate',
  IMPORT_GROUP_NOTE: (importingServices) =>
    `Un grup special de <em>servicii importate</em> este creat în mod automat pentru tine. Grupul va conține <em>${importingServices}</em> serviciile selectate pentru import.`,
  IMPORT_GROUP_NOTE_2:
    'Serviciile din acest grup nu sunt activate. Pentru a activa serviciile, ele trebuiesc mutate în alt grup. Făcând clic în continuare vă va duce la asistentul de mutat serviciit',
  IMPORT_GROUP_NOTE_3:
    'Dacă nu aveți timp pentru a muta toate serviciile importate, le puteți muta prin intermediul serviciilor din asistentul de importare oricând și pe orice dispozitiv..',
  IMPORT_GROUP_BUTTON: 'Continuă',

  IMPORT_SERVICE_IMPORT_NODATA_HEADER: 'Nu se pot găsi date de login',
  IMPORT_SERVICE_IMPORT_NODATA_NOTE: 'forghetti nu a găsit date de conectare pentru importare.',

  IMPORT_MOVE_SERVICE_HEADER: 'Începe mutarea serviciilor',
  IMPORT_MOVE_SERVICE_NOTE_1: (services) =>
    `Ai ${services} servicii în grupul de servicii din import. Pentru a începe să activați serviciile importate, trebuie să le mutați într-un alt grup.`,
  IMPORT_MOVE_SERVICE_BUTTON: 'Continuă',
  IMPORT_SERVICE_LOADING: 'Importez serviciile',
  IMPORT_SERVICE_GROUP_NAME: 'Importează servicii',
  NEW_GROUP_REQUIRES_PREMIUM: 'Crearea unui nou grup necesită abonament premium.',

  IMPORT_TYPE_HEADER: 'Selectați tipul de import',
  IMPORT_TYPE_NOTE_1: `Forghetti poate importa în mod automat serviciile din loginurile de pe Google Chrome. Dacă utilizați Google Chrome și doriți importarea utilizând această metodă, faceți clic pe butonul „Import Google Chrome“ de mai jos.`,
  IMPORT_TYPE_BUTTON_1: `Import Google Chrome`,
  IMPORT_TYPE_NOTE_2: `De asemenea, puteți importa date de autentificare prin încărcarea unui fișier CSV sau Excel (XLSX). Pentru a importa utilizând această funcție faceți clic pe butonul „Încărcați documentul“`,
  IMPORT_TYPE_BUTTON_2: 'Încărcați documentul',
  IMPORT_TYPE_NOTE_3: `Dacă utilizați în principal Safari, este posibil să doriți utilizarea funcționalității de upload document pentru a importa serviciile. Pentru a găsi parolele Keychain Safari deschideți „Preferințe“ (command + ',') în browser-ul Safari și navigați la „Parole“,`,

  IMPORT_UPLOAD_HEADER: 'Încărcați documentul',
  IMPORT_UPLOAD_NOTE_1:
    'Încărcați un fișier CSV sau spreadsheet(xlsx) pentru a începe importarea serviciilor. Este necesar măcar numele serviciului.',
  IMPORT_UPLOAD_BUTTON: 'Încărcați documentul',
  IMPORT_UPLOAD_NOTE_2: 'Descarcă modelul CSV sau Excel pentru a începe.',
  IMPORT_UPLOAD_SAMPLE_CSV: 'Descărcare model CSV ',
  IMPORT_UPLOAD_SAMPLE_XLS: 'Descărcați modelul Excel',

  // ================================================ ===========================
  // Text de ajutor
  // ================================================ ===========================

  //NO_LOGINS_MESSAGE: `Pentru a începe, apăsați pe „Adăugați un login" în colțul din dreapta sus pentru a adăuga o autentificare a unui serviciu folosit (de exemplu, Amazon, Facebook, Barclays).

  NO_LOGINS_MESSAGE: `Adăugați servicii la acest grup făcând clic pe + (plus)

Distribuiți acest grup, cu prietenii, colegii sau familia, făcând clic pe iconița de distribuire (dreapta sus)

Puteți schimba numele acestui grup și gestiona cine are acces în setările grupului

`,

  LOGIN_RECENT: 'Recent',
  HELP_LOGIN_TITLE:
    'Introduceți un titlu pentru login (de exemplu, Amazon). Adăugați un logo apăsând pe iconița camerei. Dacă nu selectați o pictogramă, una prestabilită va fi creată pentru tine.',
  HELP_LOGIN_USERNAME:
    'Introduceți numele de utilizator pe care îl utilizați pentru datele de autentificare. Acest lucru ar putea fi adresa de e-mail, de exemplu. Acest lucru vă va permite să copiați cu ușurință de pe ecranul de sumar de conectare..',
  HELP_LOGIN_WEBSITE:
    'Opțional, introduceți adresa site-ului login-ului. Acest lucru vă va permite să deschideți site-ul web direct de pe ecranul de sumar de conectare.',
  HELP_LOGIN_ADD_FORGHETTIBLE: `Adăugați fie o parolă standard (ea este deja destul de complexă și de lungă, nu are nevoie de editare), fie cuvânt Memorabil sau PIN-ul aici. Dacă doriți mai mult control adăugați un forghettible personalizat.

Trebuie să adăugați cel puțin un forghettible pentru a continua. Apoi apăsați pe Next în colțul din dreapta sus al ecranului.`,

  HELP_GROUPS_LIST_HEADER: `Apăsați pe semnul + pentru a crea sau a vă alătura unui grup. utilizatorii gratis se pot alătura doar unui grup al unui abonat premium.

Puteți utiliza grupurile pentru a gestiona diferite categorii de login-uri sau pentru a distribui un set de login-uri..

Luați aminte, va trebui să împărtășiți desenul doodle cu toți membrii grupului, astfel încât aceștia să poată genera aceleași parole ca tine. Deci, ia în considerare utilizarea unui cuvânt memorabil, o expresie sau un cod care este simplu de rememorat.
`,

  HELP_GROUPS_LIST_PERSONAL: `Acesta este grupul dvs. personal unic. Nu-l puteți șterge sau partaja cu oricine altcineva.`,
  HELP_GROUPS_LIST_OTHER_GROUPS: `Acestea sunt oricare alte grupuri care fie le-ați creat ca abonat premium, fie ați fost invitat în ele de altcineva.`,

  HELP_DOODLEPAD: `Desenați un doodle pe care să-l țineți minte. Linii conectate, puncte sau o combinație a ambelor. Cu cât mai multe straturi, cu atât mai sigur. Trebuie să-l țineți minte, acest desen este cheia pentru a genera parolele de acum înainte.

Îngrijorat de priviri curioase? Apăsați pe „Doodle Secret“ și desenează fără a lăsa urme. Faceți o greșeală și puteți începe din nou atingând „Redoodle“.`,

  HELP_SERVICE_SUMMARY: `Apăsați pe ochi pentru a vedea parola. Apăsați pe parola pentru a copia în clipboard. Mergeți pe site-ul web al serviciului și resetați parola curentă cu aceasta nouă.

Apoi, puteți să uitați parola. Data viitoare când aveți nevoie de ea, pur și simplu desenați doodle-ul și forghetti va genera parola dvs. din nou. Nu este salvată nicăieri, este generată când ai nevoie.
`,

  HELP_FIELD_SETTINGS_LENGTH: `Alegeți lungimea forghettible-ului - utilizat pentru o parolă standard sau PIN - sau numărul de cuvinte, dacă folosiți cuvinte memorabile.`,
  HELP_FIELD_SETTINGS_TYPE: `Alegeți tipul de forghettible ca o combinație de litere, simboluri, numere și cuvinte.`,
  HELP_FIELD_SETTINGS_NAME: `Introduceți un nume descriptiv pentru parola forghettible. Rețineți că acest lucru nu este parola în sine.`,
  HELP_FIELD_SETTINGS_RESET: `Dacă aveți nevoie să modificați o parolă generată de forghetti, pur și simplu faceți clic pe resetare și data viitoare când desenați Doodle-ul, o parolă diferită se va genera.`,
  HELP_RESTORE_PAGE: `Setările pentru versiunile vechi ale parolelor forghetti pot fi restaurate, făcând clic pe dată.

Dacă faceți acest lucru, atunci când desenați Doodle-ul, vechea parolă va fi generată.

Puteți schimba între ele după plac!
`,
  HELP_LOGINS_LIST: `Faceți clic pe un login pentru a genera parola.

Utilizați instrumentul de căutare pentru a găsi datele de autentificare de care aveți nevoie.
`,
  HELP_GROUP_PAGE: `Derulați până în partea de jos pentru a șterge grupul, pentru a vedea datele de conectare pentru acest grup sau pentru a părăsi un grup.`,
  HELP_GROUP_SHARING: `Creați o invitație pentru a distribui unui prieten sau coleg.`,
  HELP_GROUP_MEMBERS: `Gestionați permisiunile utilizatorilor din grup, numiți administratori, sau înlăturați membri.`,
  HELP_GROUP_INVITE_MAX_USES: `Controlați cât timp și de câte ori o invitație poate fi folosită înainte de a o trimite la prieteni și colegi.`,

  // ================================================ ===========================
  // E-mailuri
  // ================================================ ===========================

  REFERRAL_EMAIL_SUBJECT: 'O invitație de a utiliza forghetti, pentru că nimic nu este mai sigur',
  REFERRAL_EMAIL_BODY: (url) =>
    `Bună,

Folosesc o aplicație super numită forghetti. M-a ajutat să uit de parole, pentru totdeauna. Parolele nu sunt stocate nicăieri.

Generezi parolele atunci când aveți nevoie de ele, prin crearea unui desen doodle. Desenați același doodle și veți obține aceeași parolă de fiecare dată. Simplu și atât de inteligent.

Folosește acest link de recomandare pentru a te conecta și a obține funcții bonus și pentru tine și pentru mine.
${url}

Distracție placută`,

  // ================================================ ===========================

  GRP_INVITE_EMAIL_SUBJECT: 'invitație de grup forghetti',
  GRP_INVITE_EMAIL_BODY: (inviteUrl, referralUrl, referralCode) =>
    `Bună, aș dori să te alături grupul meu forghetti. Faceți clic pe link-ul de mai jos pentru a deschide Forghetti și a te alătura grupului meu:
${inviteUrl}

Dacă nu ai forghetti, adaugă acest cod de recomandare ${referralCode} în profilul tău de pe aplicație obține mai mult timp premium atât pentru tine cat si pentru mine! Uită de parole, pentru totdeauna!`,

  APPRATE_TITLE: 'Te rugăm sa ne lași un rating.',
  APPRATE_MESSAGE: `Nu va dura mai mult de un minut și ajută la promovarea aplicației noastre. Mulțumim pentru sprijin`,
  APPRATE_CANCEL: 'Nu, mulțumesc',
  APPRATE_LATER: 'Amintește-mi mai tarziu',
  APPRATE_RATE: 'Lasă-ne un rating.',
  APPRATE_YES: 'Da',
  APPRATE_NO: 'Nu chiar',
  APPRATE_PROMPT_TITLE: 'Îți place forghetti?',
  APPRATE_FEEDBACK: 'Vrei sa ne lași feedback?',

  BADGE_HEALTHCHECK_HEADER: 'Efectuați un Control de Securitate',
  BADGE_HEALTHCHECK_INFO: 'Câștigă această insignă prin completarea numelui de utilizator și Controlul de Securitate',
  BADGE_HEALTHCHECK_COMPLETE_INFO:
    'Felicitări! Ați câștigat această insignă prin completarea Controlului de Securitate',
  BADGE_HEALTHCHECK_REWARD: (premium) =>
    premium ? 'Primește 2 servicii suplimentare pentru grupul dvs. personal' : '',
  BADGE_HEALTHCHECK_CTA: 'Efectuați un Control de Securitate',

  BADGE_RATING_HEADER: 'Lasă-ne un rating',
  BADGE_RATING_INFO: 'Câștigă această insignă dacă ne lași un rating',
  BADGE_RATING_COMPLETE_INFO: 'Felicitări! Ați câștigat această insignă pt. ca ne-ai lăsat un rating!',
  BADGE_RATING_REWARD: (premium) => (premium ? 'Primește 2 servicii suplimentare pentru grupul dvs. personal' : ''),
  BADGE_RATING_CTA: 'Lasă-ne un rating',

  BADGE_PROFILECOMPLETE_HEADER: 'Completează-ți profilul',
  BADGE_PROFILECOMPLETE_INFO: 'Câștigă această insignă prin completarea profilului forghetti.',
  BADGE_PROFILECOMPLETE__COMPLETE_INFO:
    'Felicitări! Ați câștigat această insignă prin completarea profilului forghetti.',
  BADGE_PROFILECOMPLETE_REWARD: (premium) =>
    premium ? 'Primește 2 servicii suplimentare pentru grupul dvs. personal' : '',
  BADGE_PROFILECOMPLETE_CTA: 'Completați profilul acum',

  BADGE_SHARING_HEADER: 'Distribuiți forghetti pe Facebook',
  BADGE_SHARING_INFO: 'Câștigă această insignă dându-ne like și share la forghetti pe canalele sociale',
  BADGE_SHARING_COMPLETE_INFO:
    'Felicitări! Ați câștigat această insignă dându-ne like și share la forghetti pe canalele sociale',
  BADGE_SHARING_REWARD: (premium) => (premium ? 'Primește 2 servicii suplimentare pentru grupul dvs. personal' : ''),
  BADGE_SHARING_CTA: 'Distribuiți forghetti pe Facebook',

  BADGE_IMPORT_HEADER: 'Importează servicii',
  BADGE_IMPORT_INFO: 'Câștiga această insignă când importați servicii de pe telefon sau PC keychain',
  BADGE_IMPORT_COMPLETE_INFO:
    'Felicitări! Ați câștigat această insignă prin importarea de servicii de pe telefon sau PC keychain',
  BADGE_IMPORT_REWARD: (premium) => (premium ? 'Primește 2 servicii suplimentare pentru grupul dvs. personal' : ''),
  BADGE_IMPORT_CTA: 'Importează servicii acum ',

  BADGE_SUPERUSER_HEADER: 'Devin-o un super-utilizator',
  BADGE_SUPERUSER_INFO: `Câștigă această insignă când ați distribuit cu succes forghetti la 5 prieteni și aveți mai mult de două grupuri și aveți un cont premium cu peste 50 de servicii în contul dvs. forghetti`,
  BADGE_SUPERUSER_COMPLETE_INFO: `Felicitări! Ați câștigat această insignă pentru că ați distrubit la 5 prieteni, ați adăugat mai mult de două grupuri, ați abonat un cont premium și ați creat peste 50 de servicii`,
  BADGE_SUPERUSER_REWARD: (premium) => (premium ? 'Primește 2 servicii suplimentare pentru grupul dvs. personal' : ''),
  BADGE_SUPERUSER_CTA: 'Distribuie forghetti unui prieten',

  BADGE_SERVICES_50_HEADER: '50 de servicii!',
  BADGE_SERVICES_50_INFO: 'Câștiga această insignă prin crearea a 50 de servicii în contul dvs. forghetti',
  BADGE_SERVICES_50_COMPLETE_INFO:
    'Felicitări! Ați câștigat această insignă prin crearea a mai mult de 50 de servicii în contul forghetti',
  BADGE_SERVICES_50_REWARD: (premium) =>
    premium ? 'Primește 2 servicii suplimentare pentru grupul dvs. personal' : '',
  BADGE_SERVICES_50_CTA: 'Creați un serviciu acum',

  BADGE_SERVICES_150_HEADER: '150 servicii!',
  BADGE_SERVICES_150_INFO: 'Câștiga această insignă prin crearea a 150 de servicii în contul dvs. forghetti',
  BADGE_SERVICES_150_COMPLETE_INFO:
    'Felicitări! Ați câștigat această insignă prin crearea a mai mult de 150 de servicii în contul forghetti',
  BADGE_SERVICES_150_REWARD: (premium) =>
    premium ? 'Primește 2 servicii suplimentare pentru grupul dvs. personal' : '',
  BADGE_SERVICES_150_CTA: 'Creați un serviciu acum',

  BADGE_SERVICES_300_HEADER: '300 servicii!',
  BADGE_SERVICES_300_INFO: 'Câștiga această insignă prin crearea a 300 de servicii în contul dvs. forghetti',
  BADGE_SERVICES_300_COMPLETE_INFO:
    'Felicitări! Ați câștigat această insignă prin crearea a mai mult de 300 de servicii în contul forghetti',
  BADGE_SERVICES_300_REWARD: (premium) =>
    premium ? 'Primește 2 servicii suplimentare pentru grupul dvs. personal' : '',
  BADGE_SERVICES_300_CTA: 'Creați un serviciu acum',

  BADGE_SERVICES_500_HEADER: '500 servicii!',
  BADGE_SERVICES_500_INFO: 'Câștiga această insignă prin crearea a 500 de servicii în contul dvs. forghetti',
  BADGE_SERVICES_500_COMPLETE_INFO:
    'Felicitări! Ați câștigat această insignă prin crearea a mai mult de 500 de servicii în contul forghetti',
  BADGE_SERVICES_500_REWARD: (premium) =>
    premium ? 'Primește 2 servicii suplimentare pentru grupul dvs. personal' : '',
  BADGE_SERVICES_500_CTA: 'Creați un serviciu acum',

  BADGE_SHARING_1_HEADER: 'Distribuie forghetti unui prieten',
  BADGE_SHARING_1_INFO: 'Câștigă această insignă atunci când vă referiți la un prieten forghetti și creează un cont!',
  BADGE_SHARING_1_COMPLETE_INFO:
    'Felicitări! Ați câștigat insigna când a trimis un prieten la forghetti și au creat un cont!',
  BADGE_SHARING_1_REWARD: (premium) => (premium ? 'Primește 2 servicii suplimentare pentru grupul dvs. personal' : ''),
  BADGE_SHARING_1_CTA: 'Distribuie forghetti unui prieten',

  BADGE_SHARING_5_HEADER: 'Distribuie forghetti la 5 prieteni',
  BADGE_SHARING_5_INFO: 'Câștigă această insignă atunci când distribuiți la 5 prieteni care își creează un cont!',
  BADGE_SHARING_5_COMPLETE_INFO:
    'Felicitări! Ați câștigat această insignă distribuind la 5 prieteni care și-au creat conturi!',
  BADGE_SHARING_5_REWARD: (premium) => (premium ? 'Primește 2 servicii suplimentare pentru grupul dvs. personal' : ''),
  BADGE_SHARING_5_CTA: 'Distribuie forghetti unui prieten',

  BADGE_SHARING_10_HEADER: 'Distribuie forghetti la 10 prieteni',
  BADGE_SHARING_10_INFO: 'Câștigă această insignă atunci când distribuiți la 10 prieteni care își creează un cont!',
  BADGE_SHARING_10_COMPLETE_INFO:
    'Felicitări! Ați câștigat această insignă distribuind la 10 prieteni care și-au creat conturi!',
  BADGE_SHARING_10_REWARD: (premium) => (premium ? 'Primește 2 servicii suplimentare pentru grupul dvs. personal' : ''),
  BADGE_SHARING_10_CTA: 'Distribuie forghetti unui prieten',

  BADGE_SHARING_25_HEADER: 'Distribuie forghetti la 25 prieteni',
  BADGE_SHARING_25_INFO: 'Câștigă această insignă atunci când distribuiți la 25 prieteni care își creează un cont!',
  BADGE_SHARING_25_COMPLETE_INFO:
    'Felicitări! Ați câștigat această insignă distribuind la 25 prieteni care și-au creat conturi!',
  BADGE_SHARING_25_REWARD: (premium) => (premium ? 'Primește 2 servicii suplimentare pentru grupul dvs. personal' : ''),
  BADGE_SHARING_25_CTA: 'Distribuie forghetti unui prieten',

  BADGE_SHARING_50_HEADER: 'Distribuie forghetti la 50 prieteni',
  BADGE_SHARING_50_INFO: 'Câștigă această insignă atunci când distribuiți la 50 prieteni care își creează un cont!',
  BADGE_SHARING_50_COMPLETE_INFO:
    'Felicitări! Ați câștigat această insignă distribuind la 50 prieteni care și-au creat conturi!',
  BADGE_SHARING_50_REWARD: (premium) => (premium ? 'Primește 2 servicii suplimentare pentru grupul dvs. personal' : ''),
  BADGE_SHARING_50_CTA: 'Distribuie forghetti unui prieten',

  BADGE_GROUP_1_HEADER: 'Creați un grup nou în forghetti',
  BADGE_GROUP_1_INFO: 'Câștigă această insignă atunci când creați și distribuiți un nou grup în forghetti',
  BADGE_GROUP_1_COMPLETE_INFO:
    'Felicitări! Ați câștigat această insignă când ați creat și distribuit un nou grup în forghetti',
  BADGE_GROUP_1_REWARD: (premium) => (premium ? 'Primește 2 servicii suplimentare pentru grupul dvs. personal' : ''),
  BADGE_GROUP_1_CTA: 'Creați un grup acum',

  BADGE_GROUP_5_HEADER: 'Crearea 5 grupuri în forghetti',
  BADGE_GROUP_5_INFO: 'Câștigă această insignă atunci când ați creat și distribuit 5 grupuri în forghetti',
  BADGE_GROUP_5_COMPLETE_INFO:
    'Felicitări! Ați câștigat această insignă când ați creat și distribuit 5 grupuri în forghetti',
  BADGE_GROUP_5_REWARD: (premium) => (premium ? 'Primește 2 servicii suplimentare pentru grupul dvs. personal' : ''),
  BADGE_GROUP_5_CTA: 'Creați un grup acum',

  BADGE_LEGACY_HEADING: `Ești un superstar!`,
  BADGE_LEGACY_INFO_COMPLETE: `Vă multumim foarte mult pentru că sunteți un utilizator forghetti pe termen lung. Ca semn al aprecierii noastre, primiți această insignă specială și 3 luni de abonament premium gratuit!`,

  JOIN_GORUP_ALERT_MESSAGE: `Te adăugăm la grup. Te anunțăm când procesul este finalizat.`,
  GROUP_UNAVAILABLE: (groupName) =>
    `Numărul de forghettible-uri gratuite pe <em>${groupName}</em> a fost depășit. Pentru a adăuga servicii grupului treceți la premium.`,
  GROUP_PERSONAL_UNAVAILABLE: (groupName) =>
    `Numărul de forghettible-uri gratuite pe grupul dvs. personal a fost depășit. Pentru a adăuga servicii grupului treceți la premium`,
  GROUP_NONE_AVAILABLE: 'Toate grupurile au depășit liimita admisibilă',

  SHARE_INVITE_USERNAME_HEADING: 'Înainte de a începe distribuirea',
  SHARE_INVITE_USERNAME_INFO:
    'Pentru ca prietenii dvs. să identifice ușor grupurile pe care doriți să le distribuiți, vă rugăm să ne furnizați un nume de profil pentru contul dvs.',
  SHARE_INVITE_USERNAME_PLACEHOLDER: 'Numele dumneavoastră',

  LEGACY_USER_ONBOARDING_HEADING: 'Apreciem loialitatea dvs.',
  LEGACY_USER_ONBOARDING_INFO:
    'Ca răsplată vă oferim 3 luni de abonament premium absolut gratuit! Sperăm să vă bucurați de noua versiune de forghetti.',
  CLAIM_PREMIUM: 'Revendică-ți premiul',
  LEGACY_USER_RELOAD:
    'Pentru a aplica abonamentul premium trebuie să reîncărcați aplicația. Acest lucru se va întâmpla în mod automat în câteva secunde.',

  SERVICE_OPEN_ICON_LABEL: 'deschide',
  SERVICE_VIEW_ICON_LABEL: 'vedere',
  SERVICE_HIDE_ICON_LABEL: 'ascunde',
  SERVICE_WEBSITE_ICON_LABEL: 'deschide',
  SERVICE_COPY_ICON_LABEL: 'copiază',

  GROUPSLIST_SWITCH_LABEL: 'schimbă',
  GROUPSLIST_SHARE_LABEL: 'invită',
  GROUPSLIST_SETTINGS_LABEL: 'setări',

  FORGHETTI_GROUP_PERMISSIONS: `Dați clic pe acest <a data-actionclick="openPermissionsLink">link</a> pentru a afla mai multe despre permisiunile de grup.`,

  SUBSCRIPTION_TANDC: `Vă rugăm să rețineți că abonamentul se va reînnoi automat cu excepția cazului în care este anulată reînnoirea automată înainte de sfârșitul perioadei curente. Puteți gestiona și anula abonamentul accesând contul App Store după achiziție. Pentru mai multe informații, vă rugăm să vizitați <a data-actionclick="openTermsOfServiceUrl">termenii și condițiile</a> noastre și <a data-actionclick="openPrivacyPolicyUrl">politica de confidențialitate</a>`,
  SUBSCRIPTION_TANDC_LIFETIME: `Vă rugăm să rețineți că premium forghetti Life vă dă dreptul să ... Pentru mai multe informații, vă rugăm să vizitați <a data-actionclick="openTermsOfServiceUrl">termenii și condițiile</a> noastre și <a data-actionclick="openPrivacyPolicyUrl">politica de confidențialitate</a>`,
  SUBSCRIPTION_ERROR: 'A apărut o eroare la validarea abonamentelor. Vă rugăm să încercați din nou mai târziu.',

  SUBSCRIPTION_TANDC_HEADER: 'abonamentul',
  SERVICE_INJECT_LABEL: 'Utilizează',
  PHONE_NUMBER_TOO_LONG: 'Numar de telefon invalid',

  ADD_SERVICE_LOADING_PLEASE_WAIT: 'Vă rugăm să așteptați în timp ce vom genera serviciul forghetti',
  ADD_SERVICE_LOADING_GENERATING_SERVICE: 'Generez noul serviciu',
  ADD_SERVICE_LOADING_GENERATING_PASSWORD: 'Generez o parolă super-sigure',
  ADD_SERVICE_LOADING_LOADING_SERVICE_DETAILS: 'Se încarcă noul serviciu forghetti',

  VIEW_SERVICE_LOADING_LOADING_SERVICE_DETAIL: 'Vă rugăm să așteptați în timp ce încărcăm serviciul dvs.',
  VIEW_SERVICE_LOADING_LOADING_SERVICE_FORGHETTIBLE: 'Construirea datelor forghettible',

  MIGRATION_ALERT_HEADING: 'Migrați contul',
  MIGRATION_ALERT_MESSAGE: `Pentru a schimba numărul dvs. vă rugăm să mergeți la aplicația noastră web la <a href="https://app.forghetti.com" target="_new">https://app.forghetti.com</a>.`,
  DELETE_ALERT_HEADING: 'Șterge-ți contul',
  DELETE_ALERT_MESSAGE: `Pentru a șterge contul dvs. vă rugăm să mergeți la aplicația noastră web la <a href="https://app.forghetti.com" target="_new">https://app.forghetti.com</a>.`,
  REFERRAL_CODE_UPDATE_NOT_ALLOWED: `Un cod de recomandare a fost deja aplicat contului dvs.`,
  REFERRAL_CODE_APPLIED: (code) => `Codul de recomandare <em>${code}</em> a fost aplicat la cont.`,
  INVALID_PHONE_NUMBER: 'Numarul de telefon este invalid. Vă rugăm să încercați din nou.',
  INVALID_ACTIVATION_CODE: 'Codul de activare nu este valid',
  INVALID_TOO_MANY_ATTEMPTS: `Au fost prea multe încercări nereușite de a vă conecta cu acest număr. Vă rugăm să încercați din nou mai târziu.`,

  REONBOARDING_POPUP_GO_AGAIN: `Ne-am îmbunătățit modul în care poate fi utilizat forghetti. Doriți să vă familiarizați cu modul în care funcționează și ceea ce face forghetti atât de bun?`,
  REONBOARDING_POPUP_BUTTON_GO: `Arătați-mi`,
  REONBOARDING_POPUP_BUTTON_CANCEL: 'Nu, mulțumesc',
  REONBOARDING_POPUP_HEADING: `Noul forghetti 2.0`,
  HELP_QUICKSTART: 'Îmbarcare rapidă',
  YOUR_BADGES: 'Insignele tale',
  GROUP_NOT_OWNER: 'Puteți muta doar servicii în grupuri pe care le dețineti.',
  GROUP_NOT_OWNER_TITLE: 'Mutare servicii',
  VIEWSERVICE_NOT_COPIED_TO_CLIPBOARD: `<span class="toast--title">Veți avea nevoie de noua parolă. </span><span class="toast--text">Faceți clic pe butonul de copiere în câmpul pentru parolă pentru a adăuga noua parolă în clipboard.</span>`,
  EXTENSION_ATTEMPT: (website) =>
    `<strong class="toast--title">Căutare login-uri pentru </strong><span class="toast--clipboard-item">${website}</span>`,
  EXTENSION_ATTEMPT_FAIL: (website) =>
    `<span class="toast--title"><span class="toast--title">Nu s-au putut găsi login-uri</span></span>`,

  UNIVERSAL_LINK_NEW_LOGIN: (url) => `Încerc să găsesc serviciul corespunzător pentru ${url}`,
  UNIVERSAL_LINK_NEW_LOGIN_NO_URL: `Lansarea asistentului pt serviciu nou`,

  CONFIRM_COUNTRY_UPDATE_HEADER: 'Schimbați limba',
  CONFIRM_COUNTRY_UPDATE_NOTE:
    'Pentru a modifica preferințele de limbă, vă rugăm să faceți clic pe butonul de mai jos pentru a salva și reîncărca forghetti.',
  CONFIRM_BUTTON: 'Reîncarcă forghetti.',

  FORGHETTI_LOGIN: 'forghetti',
  FORGHETTI_LOGIN_TAGLINE: 'Uită de parole. Pentru totdeauna.',
  FORGHETTI_LOGIN_CREATE: 'Creează un cont',
  FORGHETTI_LOGIN_ALREADY_HAVE: 'Am deja un cont',

  MUST_BE_LOGGED_IN_ACTION: 'Această acțiune este valabilă numai atunci când sunteți autentificat',
  UPDATING_FORGHETTIBLE: 'Actualizarea forghettible-ului dvs.',
  FORGHETTI_ON_MAC: `Aplicația desktop forghetti este disponibilă pentru Mac. <a data-actionclick="openForghettiCom">Descarcă de aici.</a>`,
  FORGHETTI_ON_WINDOWS: `Aplicația desktop forghetti este disponibilă pentru Windows. <a data-actionclick="openForghettiCom">Descarcă de aici.</a>`,

  REALTIME_NEW_SERVICE_FOR_GROUP: (group) => `Un nou serviciu a fost adăugat la grupul partajat ${group}`,
  REALTIME_REMOVE_SERVICE_FOR_GROUP: (group) => `Un serviciu a fost eliminat din grupul partajat ${group}`,
  REALTIME_KICK_SERVICE_FOR_GROUP: (group) => `Ați fost eliminat din grupul ${group}`,
  REALTIME_BAN_SERVICE_FOR_GROUP: (group) => `Vi s-a interzis accesul la grupul ${group}`,
  REALTIME_JOIN_SERVICE_FOR_GROUP: (group) => `Un nou utilizator s-a alăturat ${group}`,
  REALTIME_REMOVE_GROUP: (group) => `Grupul ${group} a fost eliminat de către deținător`,

  PERSONAL_GROUP: 'Personal',
  SHAREABLE_GROUP: 'Grup partajabil',
  IMPORT_MENU: 'mportă servicii',
  IMPORT_GROUP: 'Servicii importate',

  MONTHS: [
    'Ianuarie',
    'Februarie',
    'Martie',
    'Aprilie',
    'Mai',
    'Iunie',
    'Iulie',
    'August',
    'Septembrie',
    'Octombrie',
    'Noiembrie',
    'Decembrie',
  ],

  AND: 'și',
  NEW_GROUP: 'Grup nou',
  LETS_START_SELECT: 'Să începem prin a selecta unul sau mai multe dintre aceste servicii populare ...',
  CREATE_SERVICES: 'Creați servicii',
  CREATING_SERVICES: 'Acum generăm serviciul dvs.s',
  MUST_SELECT_MORE_THAN_ONE_SERVICES: 'Vă rugăm selectați unul sau mai multe servicii',
  ADD_NOTE: 'Adăugați o notă nouă',
  EDIT_NOTE: 'Notă de editare',
  NOTE: 'Note',
  NOTE_COPIED: 'Notă copiată.',
  DELETE_NOTE: 'Ștergeți nota',

  FORGHETTI_LOGIN_TAGLINE: 'Uitați -vă parolele.',
  FORGHETTI_LOGIN_TAGLINE_2: 'Pentru totdeauna.',

  CREATE_ACCOUNT_1_HEADER_2: 'Creați-vă contul Forghetti.',

  CREATE_ACCOUNT_2_HEADING: 'Introdu codul de verificare.',
  CREATE_ACCOUNT_FINAL_BUTTON: 'Arată-mi cum',

  ONBOARDING_DOODLETUTORIAL2_HEADING_2: 'Desenați un model',
  ONBOARDING_DOODLETUTORIAL3_HEADING_2: 'Mai multe linii',
  ONBOARDING_DOODLETUTORIAL4_HEADING_2: 'Și puncte ...',
  ONBOARDING_DOODLETUTORIAL5_HEADING_2: 'Sau toate împreună',
  ONBOARDING_DOODLETUTORIAL6_HEADING_2: 'Dar amintiți -vă, ordinea contează!',
  ONBOARDING_DOODLETUTORIAL7_HEADING_2: 'Doodle A,',
  ONBOARDING_DOODLETUTORIAL8_HEADING_2: 'Doodle A, nu este același cu Doodle B',
  SECURE_DOODLE_TUTORIAL: 'Tutorialul doodle-ului secure',
  DOODLE_TRY_AGAIN: 'Încearcă din nou',
  WELCOME_TO_FORGHETTI: 'Bine ați venit la Forghetti',

  ONBOARDING_COMPLETE_CONGRATULATIONS: `Felicitări! Tocmai ai făcut primii pași pentru a-ți proteja identitatea online.`,

  ONBOARDING_COMPLETE_CREATE_FORGHETTI: 'Creați un cont Forghetti.',
  ONBOARDING_COMPLETE_SIGNATURE_DOODLE: 'Creați-vă semnătura doodle.',

  ONBOARDING_COMPLETE_RESET_PASSWORD: 'Resetați o parolă compromisa..',

  ONBOARDING_COMPLETE_HEALTHCHECK: 'Rulați un control de sănătate pe conturile dvs. online.',

  ONBOARDING_COMPLETE_IMPORT: 'Importeaza Servicii',
  ONBOARDING_COMPLETE_IMPORT_BUTTON: 'Importeaza Servicii',
  ONBOARDING_COMPLETE_REMIND_LATER: 'Aminteste-mi mai tarziu',
  ONBOARDING_COMPLETE_HEALTHCHECK_BUTTON: 'Rulați un control de sănătate',

  USERNAME_OR_EMAIL: 'Nume de utilizator',
  USERNAME_OR_EMAIL_SECOND: 'E-mail',
  SERVICE_EDIT_USERNAME_PLACEHOLDER: 'Introduceți orice alt detaliu de conectare',

  SERVICE_USERNAME_LABEL: 'Nume de utilizator',
  SERVICE_USERNAME_SECOND_LABEL: 'E-mail',

  ADD_SERVICE_USERNAME_PLACEHOLDER: `Nume de utilizator`,
  SERVICE_USERNAME_PLACEHOLDER_SECOND: `E-mail`,
  FREE_USER_UPGRADE: 'Upgrade la Premium',
  LOGIN_ACCOUNT_1_HEADER_2: 'Conectați-vă la contul dvs. Forghetti.',
  KEYCHAIN_HEADING: 'Salvați-vă doodle',
  KEYCHAIN_MESSAGE:
    'Puteți salva doodle-ul în siguranță pe acest dispozitiv pentru a vă face mai ușoara generarea parolelor.',
  KEYCHAIN_MESSAGE_2: 'Doriți să vă salvați doodle-ul pe acest dispozitiv?',
  KEYCHAIN_MESSAGE_3: 'Puteți schimba oricand acest lucru din setări',
  KEYCHAIN_YES: 'Da, salvează-mi doodle-ul',
  KEYCHAIN_NO: 'Nu, îmi voi desena de fiecare dată doodle-ul',

  LOADING_VAULT: 'Se incarca parolele',
  ONBOARDING_COMPLETE_FIRST_LOGIN: 'Adăugați un serviciu.',
  ONBOARDING_COMPLETE_CONTINUE: `Acum resetați o parolă`,
  STILL_LOADING: 'Vă încărcăm contul',
  STILL_LOADING_2: 'Contul tău a fost verificat',

  CREATE_ACCOUNT_VERIFYING_PHONE: 'Vă verificăm numărul',
  NOTIFICATION_MESSAGE_BAR: `Faceți clic pe „Utilizați” pe un serviciu pentru a vă trimite parola pentru {{service}}`,
  NO_NOTIFICATIONS_HELLO_TEXT: (name) => `Bună ziua ${name}! Încă nu aveți notificări.`,
  NO_NOTIFICATIONS_CHECK: 'Verificați în mod regulat pentru actualizări.',
  REPLAY_DOODLE_HEADING: 'Redare',
  FORGHETTIBLE: 'Forghettible',
  SERVICE_NO_NOTE: 'Fără notă',
  SERVICE_NOTE: 'Notă',
  SERVICE_BAD_NOTE: 'Pentru a debloca această notă, desenati doodle-ul din nou.',
  SERVICE_NAME: 'Numele serviciului',
  REPLAY_DOODLE: 'Replay Doodle',
  ENABLE_BIOMETRICS: 'Activați biometrie',
  DISABLE_BIOMETRICS: 'Dezactivați biometria',
  WEB_DELAY:
    'Stabilirea unei conexiuni și încărcarea detaliilor dvs. Dacă acest lucru durează prea mult, vă rugăm să verificați conexiunea.',
  WEB_WAITING: 'Vă rog să așteptați ...',
  PROFILE_MIGRATE_2_INFO_2: 'Acum introduceți codul de verificare pe care îl primiți pe telefonul dvs. vechi:',
  MIGRATE: 'Migra',
  MONTHLY: 'Lună',
  YEARLY: 'An',
  UNLOCK_BIOMETRICS: 'Deblocați cu biometrie',
  UNLOCK_DESCRIPTION: 'Deblocați -vă contul Forghetti cu biometrie',
  UNLOCK_SERVICE: (service) => `Deblocați ${service}`,
  UNLOCK_SERVICE_DESCRIPTION: (service) => `Pentru a debloca ${service} folosiți biometrie sau redactați -vă doodle`,
  UNLOCK_FALLBACK: 'Folosiți doodle',
  UNLOCK_CANCEL: 'Anulare',
  RATE_US: 'Ne evalua',
  SETUP_NEW_SERVICE_MESSAGE: `Putem configura noul serviciu dupa ce furnizati câteva detalii.`,
  LOGIN_ACCOUNT_1_MSG: `Vă rugăm să introduceți numărul dvs. de telefon mobil pentru a primi un cod de verificare unic de către SMS.`,
  LETS_START_SELECT_COUNT: (count) =>
    `Să începem prin selectarea acestor servicii populare ... puteți selecta inca <em data-count>${count}</em> gratuit`,
  TOAST_TOO_MANY_SERVICES_SELECTED: (count) =>
    `Ați selectat prea multe servicii. Vă rugăm să selectați maximum ${count} servicii.`,
  LETS_START_ADDING_DETAILS: `Să adăugăm câteva detalii.`,
  ONBOARDING_COMPLETE_NEXT_STEPS: `<h2>Doriti sa rulați:</h2><ul><li>un control de sănătate pe conturile dvs. online? </li><li>Sau actualizați un alt serviciu pe Forghetti.</li><li>Sau pur și simplu aruncă o privire în jur ...</li></ul>`,
  ONBOARDING_COMPLETE_CONGRATULATIONS: `Felicitări! Tocmai ai făcut primii pași pentru a-ți proteja identitatea online.`,
  CREATE_ACCOUNT_1_MSG_4: `Notă: Vom folosi numărul dvs. de telefon doar pentru a vă crea/susține contul Forghetti. Veți primi un cod unic de verificare.`,
  MOVE_SERVICE_CONFIRM_NOTE_UPDATE: (fromGroup, toGroup, list, listLength) =>
    `${listLength > 1 ? 'Nou' : 'Un nou'} <em>${list}</em> forghettible${
      listLength > 1 ? 's' : ''
    }   va fi generat din doodle-ul pentru ${togroup}. Dacă doriți să accesați vechiul dvs.forghettible ${
      listLength > 1 ? 's' : ''
    }, , pur și simplu desenati doodle-ul pentru ${fromGroup} `,
  YOUR_SUBSCRIPTION_PARTNER_PREMIUM_TEXT_1: (partner, link) =>
    `Partenerii noștri de la <em> ${partner} </em> vă oferă cu premium Forghetti. Puteți afla mai multe despre abonamentul dvs. aici: <a href="${link}" target="_new"> ${link} </a>`,
  YOUR_SUBSCRIPTION_PARTNER_PREMIUM_TEXT_2: `Sperăm că vă bucurați să folosiți Forghetti.`,
  PARTNER_PREMIUM_HEADING: `Felicitări!`,
  PARTNER_PREMIUM_MESSAGE: (partner) =>
    `În calitate de partener Forghetti, <em> ${partner} </em> membrii primesc forghetti premium. Nu trebuie să faceți nimic, abonamentul va fi aplicat automat în contul dvs. Pentru ca modificările să intre în vigoare imediat, faceți clic pe „Reîncărcați forghetti” acum.`,
  PARNTER_PREMIUM_BUTTON: `Reîncarcă Forghetti`,

  CSL_SUBSCRIPTION_HEADER: `Premium activat`,
  CSL_SUBSCRIPTION_1: `Ați activat serviciul nostru premium. Mulțumesc. Când acest abonament expiră, puteți continua ca utilizator gratuit (și pierde toate beneficiile premium) sau vă puteți reînnoi abonamentul.`,
  CSL_SUBSCRIPTION_2: `Sperăm că vă bucurați să folosiți Forghetti.`,
  LICENCE_KEY_INVALID: (partner) => `Ne pare rău, cererea dvs. de cerere nu este nevalidă.`,
  LICENCE_KEY_USED: (partner) => ` Ne pare rău, cererea dvs. de cerere nu este nevalidă. `,
  LICENCE_KEY_ALREADY_APPLIED: (partner) => `V -ați abonat cu succes. Vă mulțumim pentru utilizarea acestui serviciu.`,

  SERVICE_EMAIL_LABEL: 'E-mail',
  SERVICE_EMAIL_PLACEHOLDER: 'user@email.com',
  SERVICE_EDIT_EMAIL_PLACEHOLDER: 'user@email.com',
  PROFILE_USE_BIOMETRICS: 'Păstrați -vă doodle cu biometrie',
  BIOMETRICS_GROUPS_HEADER: 'Activați biometrie',
  BIOMETRICS_SELECT_GROUP: 'Selectați un grup pentru a vă stoca doodle cu biometrie',
  BIOMETRICS: 'Biometrie',
  BIOMETRICS_ENABLE: 'Permite',
  BIOMETRICS_DISABLE: 'Dezactiva',
  BIOMETRICS_SECURE: 'Asigurați -vă cu biometrie',
  BIOMETRICS_SECURE_MESSAGE: (group) =>
    `Doodle -ul dvs. pentru <em> ${group} </em> va fi stocat în siguranță cu biometrice`,
  BIOMETRICS_USE_DOODLE: 'Folosiți doodle',
  BIOMETRICS_TRY_AGAIN: 'Încearcă din nou',
  NOTES: 'Note',
  SELECT_SPECIAL_CHARACTERS: 'Selectați ce simboluri să includeți',
  WORDS: 'Cuvinte <span class = "Monospace"> Exemplu </span>',
  LETTERS: 'Litere <span class = "Monospace"> ABC </span>',
  NUMBERS: 'Numere <span class = "Monospace"> 123 </span>',
  SYMBOLS: 'Simboluri <span class = "Monospace">! $%</span>',
  SELECT_PASSWORD_TYPE: (forghettibleName) => `${forghettibleName} Opțiuni`,
  SELECT_PASSWORD_NUMBERS: 'Total Litere / Numere / Simboluri',
  SELECT_PASSWORD_WORDS: 'Cuvinte totale',
  ALL: 'Toate',
  SIMPLE: 'Simplu',
  EDIT_PASSWORD_HEADING: 'Setări',
  SERVICE_FIELD_PASSWORD_NO_OPTIONS: 'Trebuie selectată cel puțin o opțiune de parolă',
  SERVICE_FIELD_PASSWORD_NO_SYMBOLS_SELECTED: 'Trebuie selectat cel puțin un simbol',
  SUBSCRIPTION_ENDS: (days) => `Abonamentul dvs. trebuie să expire în ${days} zile.`,
  SUBSCRIPTION_ENDS_TOMORROW: 'Abonamentul dvs. trebuie să expire mâine.',
  SUBSCRIPTION_ENDS_TODAY: 'Abonamentul dvs. trebuie să expire astăzi.',
  NOTIFICATIONS: 'Notificări',
  CONTEXT_CHANGE_TYPE: 'Opțiuni de schimbare',
  CONTEXT_CHANGE_LENGTH: 'Schimbați lungimea',
  CONTEXT_RENAME: 'Redenumiți Forghetible',
  NEW_FORGHETTIBLE: 'Nou Forghetible',
  OLD_FORGHETTIBLE: 'bătrâni forfettible',
  DELETE_FIELD_PROMPT: 'Ești sigur că ai dori să ștergi acest câmp?',
  ONBOARDING2_WELCOME_HEADING: 'Bine ați venit la Forghetti',
  ONBOARDING2_WELCOME_TEXT: 'Conectați -vă la toate serviciile pe care le utilizați cu un singur doodle securizat.',
  ONBOARDING2_DOODLE_HEADING: 'Creați -vă semnătura doodle',
  ONBOARDING2_DOODLE_TEXT:
    'De la linii sau puncte, la squiggles aleatorii sau secvențe de numere. Combinația dvs. unică generează o parolă super puternică. ',
  ONBOARDIN2_HOWITWORKS: 'Cum functioneaza',
  ONBOARDING_CONFIRM_DOODLE: 'Confirmați -vă doodle',
  ONBOARDING_BIOMETRICS_CONFIRM: 'Doriți să salvați acest lucru în brelocul dvs.?',
  SAVING_NOTE: 'Criptarea notei tale',
  };
