const DialogMgr = require('dialog-manager');
const { REONBOARDING_POPUP_HEADING, REONBOARDING_POPUP_BUTTON_GO, REONBOARDING_POPUP_GO_AGAIN, REONBOARDING_POPUP_BUTTON_CANCEL } = require('../../../config/strings');

const heading = _ => ({
  template: 'block-primary-heading',
  fields: {
    heading: REONBOARDING_POPUP_HEADING(),
  },
});

const info = info => ({
  template: 'block-primary-text',
  fields: {
    class: 'wrap-text',
    text: info,
  },
});

const lochy = img => ({
  template: 'block-lochy',
  selector: '',
  attributes: [
    { selector: 'img', type: 'src', content: img },
    { selector: '', type: 'class', content: 'no-border' },
    { selector: 'img', type: 'class', content: 'small' },
  ],
});


const onboarding = _ => ({
  template: 'block-cta-button',
  fields: {
    action: 'reonboarding',
    text: REONBOARDING_POPUP_BUTTON_GO(),
  },
});

const buildOnboarding = _ => {
  const $row = z2hApp.constructBlock(z2hTemplates.compileBlockAttribute(onboarding()));
  return $row;
};

const noThanks = _ => ({
  template: 'block-text-button',
  fields: {

    text: REONBOARDING_POPUP_BUTTON_CANCEL(),
  },
});

const buildNothanks = _ => {
  const $row = z2hApp.constructBlock(z2hTemplates.compileBlockAttribute(noThanks()));
  return $row;
};

module.exports = ({ z2hApp }) => {

  const z2hTemplates = require('../../app/templates');
  const node = $('<div>');

  node.append(z2hApp.constructBlock(z2hTemplates.compileBlockAttribute(heading())));
  node.append(
    z2hApp.constructBlock(lochy(`img/lochy/05a-Lochy-Doctor-v2.gif`)),
  );
  node.append(
    z2hApp.constructBlock(z2hTemplates.compileBlockAttribute(info(REONBOARDING_POPUP_GO_AGAIN()))),
  );

  node.append(buildOnboarding());
  node.append(buildNothanks());

  DialogMgr.showMsg({ closeEnabled: true, msgNode: node[0] });
  $('.cta-button').on('click', () => {
    $('.dialogMgr_root').click();
  });
  $('.cta-button').on('click', () => {
    $('.dialogMgr_root').click();
  });

}