const multiLines = require('./01_multi_lines.json');
const dotsToo = require('./02_dots_too.json');
const allTogether = require('./03_Or_all_together.json');
const doodleA = require('./04_Doodle_A.json');
const doodleB = require('./05_Doodle_B.json');

const gopremium = require('./gopremium.json');
const telluswhatyouthink = require('./telluswhatyouthink.json');
const upgrade = require('./upgrade.json');
const upgradenow = require('./upgradenow.json');

// Export an object which contains all action functions at the root level
const animations = {
 multiLines,
 dotsToo,
 allTogether,
 doodleA,
 doodleB,
  gopremium,
  telluswhatyouthink,
  upgrade,
  upgradenow,

};

module.exports = animations;
