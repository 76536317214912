const config = require('../../../config');

const onboadingTemplate = require('./onboarding_template');

const strings = require('../../../config').strings;

module.exports = ({ nav }) => {
  return onboadingTemplate({
    nav,
    id: 'onboarding-doodletutorial8',
    imagePath: 'img/animations/05_Doodle_B.json',
    secondary_heading: strings.ONBOARDING_DOODLETUTORIAL(),
    primary_heading: strings.ONBOARDING_DOODLETUTORIAL8_HEADING(),
    nextAction: 'beginAddNewService',
    nextLink: strings.ONBOARDING_YOURTURN(),
    callToAction: true,
    //nextPage: 'addService_search',
  });
};
