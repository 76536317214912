module.exports = ({ element }) => {
  //const countryData = $(element).countrySelect('getSelectedCountryData');
  const countryCode = $(element).attr('data-id');

  //if (countryData && countryData.iso2) {
  //  const countryCode = countryData.iso2 === 'gb' ? 'uk' : countryData.iso2;
  window.state.userData.region = countryCode;
  const { setLocale } = require('../../../config/strings');
  //update the locale of the user immediately.
  setLocale(countryCode);
  localStorage.setItem('language', countryCode);
  z2hApp.requestData('post', '', 'users/@me', { region: countryCode }).then(_ => {
    window.location.reload()
  })

  

}


  