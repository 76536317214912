const { LOCHY_LOADING_TEXT_DEFAULT } = require('../../config').strings;
module.exports = {

  makePaneLookBusy: (paneIndex, options = {}) => {
    const t = options.text ? options.text : LOCHY_LOADING_TEXT_DEFAULT();

    $('#pane-' + paneIndex + ' .lochy-loading-text').html(t)
    $('#pane-' + paneIndex).addClass('busy')
    //const nav = $('.nav-bar-wrapper').addClass('hidden');

  },
  makePaneLookIdle: (paneIndex) => {
    $('#pane-' + paneIndex + ' .lochy-loading-text').html(LOCHY_LOADING_TEXT_DEFAULT())
    $('#pane-' + paneIndex).removeClass('busy')
   // const nav = $('.nav-bar-wrapper').removeClass('hidden');
  },
}