//const rootAppUrl =  window.location.origin;
const { FORGHETTI_ROOT_URL } = require('./app');
module.exports = {
  DOWNLOADS: 'https://www.forghetti.com/eng/download/a/',
  LEARN_MORE_ABOUT_SECURITY: 'https://www.forghetti.com/eng/faqs/how-does-forghetti-work',
  GET_VIDEO_HELP: 'https://www.forghetti.com/eng/video-tutorials',
  GET_HELP: 'https://www.forghetti.com/eng/faqs',
  UPGRADE_TODAY: 'https://www.forghetti.com',
  SEND_FEEDBACK: 'https://www.forghetti.com/eng/faqs/how-can-i-contact-forghetti',
  PLAYSTORE: 'https://play.google.com/store/apps/details?id=com.forghetti.forghettiapp',
  APPSTORE: 'https://apps.apple.com/gb/app/forghetti-password-manager/id1446291527',
  REFERRAL_LINK: (code, profileName) => `${FORGHETTI_ROOT_URL}?refer=${encodeURIComponent(code)}`,

  GROUP_INVITE_URL: inviteCode => FORGHETTI_ROOT_URL + '?invite=' + inviteCode,

  SUBSCRIPTION_PRICING: 'https://forghetti.com/eng/pricing',

  COOKIE_POLICY: 'https://www.forghetti.com/eng/cookie-policy',
  PRIVACY_POLICY: 'https://www.forghetti.com/eng/privacy-policy',
  TERMS_OF_SERVICE: 'https://www.forghetti.com/eng/terms-of-service',
  ACCEPTABLE_USE_POLICY: 'https://www.forghetti.com/eng/acceptable-use-policy',
  LEARN_HOW_FORGHETTI_WORKS: 'https://www.forghetti.com/eng/how-it-works',

  CORDOVA_IOS_APP_STORE: 'itms-apps://itunes.apple.com/app/1446291527',
  CORDOVA_ANDROID_APP_STORE: 'market://details?id=com.forghetti.forghettiapp',
};
