import { active } from 'domain';

const showToastMessage = require('../../action_helpers/showToastMessage');
const strings = require('../../../config').strings;

module.exports = ({ currentTarget, z2hApp }) => {
  // Get doodle that user drew
  let doodle = (window.currentDoodlepad.getShape() || {}).shape;

  let error = false;

  // Check that code is not empty
  if (!doodle) {
    showToastMessage(strings.ERROR_NO_DOODLE());
    error = true;
  }

  if (window.state.doodlepadToConfirm !== doodle) {
    showToastMessage(strings.ADD_SERVICE_DODODLES_DO_NOT_MATCH(), { html: true, timeout: 3000, center: true });
    error = true;
  }

  if (!error && confirm(strings.ONBOARDING_BIOMETRICS_CONFIRM())) {
    const personalGroup = window.state.groupsList.find((group) => group.personal);
    localStorage.setItem(personalGroup.id + '_doodle', true);
    localStorage.setItem('bio', true);
    if (window.ss) {
      window.ss.set(
        () => {
          console.log('Stored doodle');
        },
        () => {
          console.log('Failed to store doodle');
        },
        personalGroup.id + '_doodle',
        doodle,
      );
    }
  }

  const paneDetails = window.getActivePaneDetails('#pane-2');
  if (error) {
    if (window.state.doodlepadToConfirm) {
      setTimeout(() => {
        return z2hApp.paneNavigation('back', paneDetails.pane, paneDetails.activePaneIndex - 1);
      }, 1000);
    }
    setTimeout(() => {
      const { biometricsOrDoodle } = require('../../actions/services');
      biometricsOrDoodle({
        currentTarget: null,
        z2hApp,
        pane: paneDetails.pane,
        newPaneIndex: paneDetails.activePaneIndex - 1,
        template: { biometrics: 'addService_biometrics', doodlepad: 'addService_doodle' },
      });
    }, 2000);
  } else {
    const addNewService = require('./addNewService');

    const onSuccess = () =>
      //z2hApp.paneNavigation('addService_onboarding_complete', $('.overlay'));
      z2hApp.paneNavigation('viewService', paneDetails.pane, paneDetails.activePaneIndex + 2);

    const onFailure = () =>
      z2hApp.paneNavigation('addService_search', paneDetails.pane, paneDetails.activePaneIndex - 1);

    z2hApp.paneNavigation('addServicePassword_loading', paneDetails.pane, paneDetails.activePaneIndex + 1);
    addNewService({ z2hApp, onSuccess, onFailure });
  }
};
