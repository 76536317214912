const strings = require('./strings');
const urls = require('./urls');
const app = require('./app');
const locales = require('./locales/index');

module.exports = {
  strings,
  urls,
  app,
  locales,
};
