// const updateServiceIcon = require('../../action_helpers/updateServiceIcon');
const _ = require('lodash');
module.exports = ({ currentTarget }) => {
  // Get source of selected img/icon

  const oldGroup = _.clone(window.state.selectedGroupId);
  const groupId = $(currentTarget).attr('data-id') || window.state.selectedGroupId;
  const movingService = window.z2hApp.pageData.service.movingService;
  // Store in state
  window.state.addService_groupId = groupId;

  window.state.selectedGroupId = groupId;

  // Go to next page
  const pane = window.state.userData.activeOnboarding
    ? 'addService_username_onboard'
    : movingService
    ? 'addService_doodle'
    : 'addService_username';
  const selector = window.state.userData.activeOnboarding ? '.overlay' : movingService ? '#pane-2' : '#pane-2';
  const innerSelector = window.state.userData.activeOnboarding
    ? '.overlay '
    : movingService
    ? '#pane-2-inner'
    : '#pane-2-inner';
  // Display/transition to 'select icon' page
  const paneDetails = window.getActivePaneDetails(selector);

  if (pane === 'addService_doodle') {
    const { biometricsOrDoodle } = require('../../actions/services');
    biometricsOrDoodle({
      currentTarget: null,
      z2hApp,
      pane: paneDetails.pane,
      newPaneIndex: paneDetails.activePaneIndex + 1,
      template: { biometrics: 'addService_biometrics', doodlepad: 'addService_doodle' },
    });
  } else {
    z2hApp.paneNavigation(pane, paneDetails.pane, paneDetails.activePaneIndex + 1);
  }
};
