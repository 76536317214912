/* 

  This code has been copied over to viewProfileAccount but before
  I haven't checked if this is correct just yet. So I'm leaving 
  the code here JIC.
  If it turns out we don't want this page don't forget to also 
  restore the page helper editProfileName, which was renamed to viewProfileAccount
  
*/

const config = require('../../../config');
const fb = require('../../app/firebaseUtils');

const strings = config.strings;

const { PROFILE_NAME_MAXLENGTH } = require('../../../config/app');

module.exports = ({ nav, data }) => {
  const nameId = window.z2hTools.getUid();

  return {
    template: 'block-section-page',
    fields: {
      id: 'edit-profile-name',
      active: false,
      header: strings.PROFILE_EDIT_NAME_HEADER(),

      navigation_left: config.strings.BACK(),
      navigation_left_icon: '',
      navigation_left_data: '0',
      navigation_left_template: 'back',

      navigation_right: strings.SAVE(),
      navigation_right_icon: '',
      navigation_right_data: '0',
      navigation_right_template: 'back',
      navigation_right_action: 'updateProfileName',
      navigation_right_validation: 'editProfileName',
    },
    rows: [
      {
        template: 'block-section-row',
        fields: {},
        columns: [
          {
            template: 'block-login-avatar',
            fields: {
              avatar: data.avatar || config.app.DEFAULT_USER_ICON,
              context: 'addProfileImage',
              data_id: data.id,
            },
          },
          {
            template: 'block-editable-primary-text',
            fields: {
              id: nameId,
              for: nameId,
              value: data.name,
              name: 'profile-name',
              primary_text: data.name,
              placeholder: strings.PROFILE_EDIT_NAME_LABEL(),
              maxlength: PROFILE_NAME_MAXLENGTH,
            },
          },
        ],
      },
      {
        template: 'block-section-row',
        fields: {
          secondary_heading: strings.PROFILE_EDIT_TEL_LABEL(),
        },
        columns: [
          {
            template: 'block-text-group',
            fields: { primary_text: fb.getCurrentUser().phoneNumber },
          },
        ],
      },
    ],
  };
};
