const { YOUR_BADGES, BACK } = require('../../../config/strings');
const { infoTextRow, thirdheading } = require('../../page_helpers/components');

module.exports = ({ nav, data }) => {
  $('body').trigger('z2hApp.launched');
  return {
    template: 'block-section-page',
    fields: {
      id: 'profile_badges',
      active: false,
      navbar: false,
      navigation_left: BACK(),
      navigation_left_data: nav - 1,
      navigation_left_template: 'back',
      header: YOUR_BADGES(),
    },
    rows: [
      {
        template: 'block-section-row',
        fields: {
          class2: 'profile-badges',
          class: 'loading-wheel',
        },
      },
    ],
  };
};
