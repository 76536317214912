const LINK_EXPIRY_VALUES = {
  15: '15 minutos',
  30: '30 minutos',
  60: '1 hora',
  360: '6 horas',
  720: '12 horas',
  1440: '1 día',
  '-1': 'Nunca',
};

const LINK_MAX_USE_VALUES = {
  1: 'Una vez',
  2: 'Dos veces',
  5: '5 veces',
  10: '10 veces',
  20: '20 veces',
  50: '50 veces',
  '-1': 'Sin límite',
};

module.exports = {
  YOU: 'Tú',
  BACK: 'Atrás',
  NEXT: 'Siguiente',
  CONTINUE: 'Continuar',
  SAVE: 'Guardar',
  ADD: 'Añadir',
  SKIP: 'Omitir',
  CANCEL: 'Cancelar',
  PREVIOUS: 'Anterior',
  EDIT: 'Editar',
  NONE: 'Ninguna',
  START: 'Empezar',
  SEARCH: 'Buscar',
  CLOSE: 'Cerrar',
  SEE_DETAILS: 'Ver detalles',
  UNAVAILABLE: 'No disponible',
  DONE: 'Hecho',
  WATCH_AGAIN: 'Ver de nuevo',
  NEW: 'nuevo',

  UNAVAILABLE_FORGHETTIBLES_MESSAGE:
    'Algunos de tus olvídates no están disponibles porque has excedido el límite de tu plan gratuito. ¡Regístrate para obtener una cuenta premium con olvídates ilimitados!',

  FORGHETTI_TAGLINE: 'Olvida tus contraseñas. Para siempre.',
  CREATE_ACCOUNT: 'Crea una cuenta nueva',
  HAVE_ACCOUNT: 'Ya tengo cuenta',

  COMBOBULATING: '... combinando tus olvídates ...',

  CREATE_ACCOUNT_1_HEADER: 'Introduce número',
  CREATE_ACCOUNT_1_HEADING: 'Verifica tu dispositivo',
  CREATE_ACCOUNT_1_MSG:
    'Ingrese su número de teléfono móvil para recibir un <strong>código de verificación único</strong> por SMS.',
  CREATE_ACCOUNT_1_PLACEHOLDER: 'Tu número',
  CREATE_ACCOUNT_1_MSG_2:
    'Cambie el código internacional si es necesario e ingrese su número de teléfono móvil\np. Ej. 07795123456.',
  CREATE_ACCOUNT_1_MSG_3:
    'Los mensajes SMS solo se enviarán al verificar sus dispositivos y para actualizaciones esenciales de la aplicación.',

  ERROR_TEL_NUM_EMPTY: 'Se debe proporcionar el número de móvil',

  MAIN_NAV_GROUP: 'Grupos',
  MAIN_NAV_PROFILE: 'Perfil',
  MAIN_NAV_HEALTHCHECK: 'Salud',
  MAIN_NAV_IMPORT: 'Servicios de importación',
  MAIN_NAV_LOGOUT: 'Cerrar sesión',
  NAVBAR_JOIN_GROUP: 'Unirse al grupo',
  NAVBAR_NEW_GROUP: 'Nuevo grupo',
  NAVBAR_NEW_PASSWORD: 'Nueva contraseña',
  NAVBAR_NEW_PIN: 'Nuevo PIN',
  NAVBAR_NEW_WORD: 'Nueva palabra memorable',
  NAVBAR_HOME: 'Inicio',
  NAVBAR_GROUPS: 'Grupos',
  NAVBAR_HEALTHCHECK: 'Salud',
  NAVBAR_PROFILE: 'Perfil',

  /* Lochy loading - paneBusyText.js */
  LOCHY_LOADING_TEXT_DEFAULT: 'Cargando...',
  LOCHY_LOADING_TEXT_GROUP: (group) => `Cargando servicios para <em>${group}</em>`,
  LOCHY_LOADING_TEXT_GROUPSLIST: 'Cargando grupos',
  LOCHY_LOADING_TEXT_HEALTHCHECK: 'Cargando verificación de estado',
  LOCHY_LOADING_TEXT_CHOOSE_SEARCH_ICON: 'Búsqueda de iconos del servicio en la web',
  LOCHY_LOADING_TEXT_CHOOSE_CHOOSE_GROUP_ICON: 'Subiendo icono de grupo',
  LOCHY_LOADING_TEXT_CREATE_GROUP_INVITE: 'Creando invitación grupal',
  LOCHY_LOADING_TEXT_EDIT_SERVICE: 'Editar los detalles del servicio',
  LOCHY_LOADING_TEXT_UPDATE_SERVICE: 'Guardar los detalles del servicio',
  LOCHY_LOADING_TEXT_PROFILE: 'Cargando tu perfil',
  LOCHY_LOADING_TEXT_SUBSCRIPTION: 'Cargando los detalles de su suscripción',

  /*                                 */

  CREATE_ACCOUNT_2_HEADER: 'Introduzca el código',
  CREATE_ACCOUNT_2_HEADING: 'Ingrese su código de verificación',
  CREATE_ACCOUNT_2_MSG: (telNumber) => `Por favor, introduzca el código de verificación enviado al ${telNumber}..`,
  CREATE_ACCOUNT_2_LINK_BACK: 'No he recibido un código',
  CREATE_ACCOUNT_2_MSG_2: (termsofserviceurl) =>
    `Al ingresar el código de verificación, acepta nuestros <a href="${termsofserviceurl}" target="_new">términos de servicio</a>.`,

  ERROR_TEL_NUM_INVALID: 'Por favor introduce un número de teléfono válido',
  ERROR_ACT_CODE_EMPTY: 'Introduzca el código de activación que se le ha enviado.',
  ERROR_ACT_CODE_INVALID: 'Ingrese un código de activación válido',
  ERROR_ACT_CODE_INVALID: 'El código de activación es incorrecto. Inténtalo de nuevo',

  CREATE_ACCOUNT_VERIFYING: 'Comprobando el código de verificación',
  CREATE_ACCOUNT_VERIFYING_PHONE: 'Verificando su número de teléfono',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_1: 'Nunca se guardan las contraseñas',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_2: 'Solo necesitas recordar tu Garabato',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_3: 'Puedes usar Forghetti en todos los dispositivos.',
  CREATE_ACCOUNT_ONBOARDING_5_LINE_4: 'Actualice para poder compartir contraseñas de forma segura dentro de grupos',

  CREATE_ACCOUNT_REFERRAL_HEADER: 'código de referencia',
  CREATE_ACCOUNT_REFERRAL_HEADING: 'Ingrese un código de referencia',
  CREATE_ACCOUNT_REFERRAL_PLACEHOLDER: 'Código de referencia',
  REFERRAL_ENTRY_MESSAGE_1: `¿Recibiste un código de recomendación de un amigo? Si es así, ingréselo aquí. De lo contrario, puede omitir este paso.`,

  CREATE_ACCOUNT_3_HEADER: 'Editar perfil',
  CREATE_ACCOUNT_3_HEADING: 'Edita tu perfil',
  CREATE_ACCOUNT_3_TEXT: `Puedes establecer un nombre y un icono para tu perfil aquí. Esto ayudará a identificarte si decides compartir el acceso o si te invitan a unirte al grupo de inicio de sesión de otro usuario.`,
  CREATE_ACCOUNT_3_LABEL: 'Tu nombre',
  CREATE_ACCOUNT_3_TEXT_2: `Puedes omitir este paso si lo deseas y dejar la configuración de nombre e imagen para más tarde.`,

  CREATE_ACCOUNT_ADD_LOGIN_MSG: `Seleccione un servicio para actualizar desde estas aplicaciones populares`,

  CREATE_ACCOUNT_FINAL_HEADER: '¡Terminado!',
  CREATE_ACCOUNT_FINAL_MSG: `¡Estás listo para forghetti!`,
  CREATE_ACCOUNT_FINAL_MSG_2: `¡Estás en camino de proteger tu cuenta y olvidarte del estrés de las contraseñas para siempre!`,
  CREATE_ACCOUNT_FINAL_MSG_3: `Ahora te guiaremos a través de los pasos necesarios para actualizar una contraseña existente a una olvídate.`,
  CREATE_ACCOUNT_FINAL_BUTTON: '¡Muéstrame cómo!',
  CREATE_ACCOUNT_FINAL_LINK: `Ya sé moverne en Forghetti`,

  CREATE_ACCOUNT_ADD_CUSTOM_LOGIN: `O algo mas...`,
  CREATE_ACCOUNT_SERVICE_NAME_TOOLTIP: `Para agregar un servicio diferente, deberá proporcionar el nombre del servicio como mínimo. Los campos Correo electrónico / \n\nEmail/Nombre de usuario y Dirección web son opcionales, pero le sugerimos que los agregue también`,
  CREATE_ACCOUNT_CUSTOM_LOGIN_ADD_PASSWORD_TOOLTIP: `Deberá agregar al menos un tipo de contraseña para que se genere.\n\nHay algunas opciones diferentes para elegir, ya que algunos servicios requieren requisitos de contraseña específicos.`,
  CREATE_ACCOUNT_CUSTOM_LOGIN_NEXTPAGE_TOOLTIP: `Una vez que esté satisfecho con todo, haga clic en "Siguiente" para continuar.`,
  CREATE_ACCOUNT_NEW_LOGIN_DOODLEPAD_MSG: `Tu Garabato se utilizará cada vez que quieras recuperar tu contraseña en el futuro, ¡así que haz uno que mole!`,
  CREATE_ACCOUNT_ADD_ANOTHER_LOGIN_TOOLTIP: `Para agregar otro servicio a su cuenta, haga clic aquí para que aparezca la pantalla de inicio de sesión otra vez.`,

  ADD_SERVICE_SEARCH_HEADER: `Agregar nuevo servicio`,
  ADD_SERVICE_SEARCH: `Seleccione un servicio de estas aplicaciones populares o comience a escribir el nombre`,
  ADD_SERVICE_SUGGESTED: `Servicios sugeridos`,
  ADD_SERVICE_CREATE: (name) => `Crear "${name}" "${name}"`,
  ADD_SERVICE_SELECT_ICON_HEADER: `Elige un icono`,
  ADD_SERVICE_SELECT_ICON: (serviceName) => `Selecciona un icono para ${serviceName}`,
  ADD_SERVICE_SELECT_GROUP_HEADER: `Selecciona grupo`,

  ADD_SERVICE_YOUR_GROUP: (serviceName, groupName) =>
    `<em>${serviceName}</em> <em>${serviceName}</em> serás agregado a <em>${groupName}</em> <em>${groupName}</em>`,
  ADD_SERVICE_SELECT_GROUP: (serviceName) => `Selecciona un grupo al que agregar<br /><em>${serviceName}</em>`,
  ADD_SERVICE_SELECT_GROUP_SELECT: (serviceName) =>
    `O selecciona un grupo diferente para agregar <br /><em>${serviceName}</em>`,
  MOVE_SERVICE_SELECT_GROUP: (serviceName) => `Selecciona un grupo al que mover <br /><em>${serviceName}</em>`,
  MOVE_SERVICE_LOADING: 'Moviendo tu servicio',
  MOVE_SERVICE_CONFIRM_HEADER: 'Moviendo tu servicio',
  MOVE_SERVICE_CONFIRM_NOTE: (
    service,
    fromGroup,
    toGroup,
    numFields,
  ) => `<em>${service}</em> se moverá de tu grupo <em>${fromGroup}</em> a <em>${toGroup}</em>.
Este servicio tiene <em>${numFields}</em>olvídate${numFields > 1 ? 's' : ''}.`,
  MOVE_SERVICE_CONFIRM_NOTE_UPDATE: (fromGroup, toGroup, list, listLength) =>
    `Se generará un nuevo <em>${list}</em> olvídate${
      listLength > 1 ? 's' : ''
    } a partir de tu Garabato de <em>${toGroup}</em>. Si desea acceder a su antiguo olvídate${
      listLength > 1 ? 's' : ''
    }, simplemente dibuje su Garabato <em>${fromGroup}</em> en su lugar.`,
  MOVE_SERVICE_CONFIRM: 'Confirmar movimiento',
  ADD_SERVICE_SELECT_GROUP_ONBOARD: (serviceName) => `Agrega tu servicio <em>${serviceName}</em> a un grupo`,
  ADD_SERVICE_USERNAME: (serviceName) =>
    `Ingresa el nombre de usuario para <em>${serviceName}</em> o selecciónalo de la lista a continuación.`,
  ADD_SERVICE_USERNAME_ONBOARD: (serviceName) =>
    `Ingresa tu correo electrónico o nombre de usuario de <em>${serviceName}</em>`,
  ADD_SERVICE_USERNAME_HEADER: `Elije un nombre de usuario`,
  ADD_SERVICE_USERNAME_PLACEHOLDER: `Correo electrónico o nombre de usuario`,
  ADD_SERVICE_SEARCH_FOR_SERVICE: `Ingresa un servicio que usas aquí`,
  ADD_SERVICE_SEARCH_FOR_ICONS: 'Buscar iconos',
  ADD_SERVICE_SUGGESTED_USERNAMES: `Nombres de usuario sugeridos`,
  ADD_SERVICE_DRAW_DOODLE: (groupName) => `Dibuja el Garabato para tu grupo <em>${groupName}</em>`,
  ADD_SERVICE_USERNAME_BLANK: `Debes ingresar un nombre de usuario o dirección de correo electrónico`,
  ADD_SERVICE_USERNAME_TOO_LONG: 'Tu nombre de usuario debe tener menos de 64 caracteres',

  ADD_SERVICE_WEBSITE_HEADER: 'Ingresar sitio web',
  ADD_SERVICE_WEBSITE: (serviceName) =>
    `Ingresa la URL del sitio web para <em>${serviceName}</em>. Puedes omitir este paso e ingresar la URL de un sitio web más tarde.`,
  ADD_SERVICE_WEBSITE_PLACEHOLDER: `URL del sitio web`,

  ADD_SERVICE_SEARCH_ONBOARD_LETS_START: `Comencemos seleccionando uno de estos servicios populares o ...`,
  ADD_SERVICE_SEARCH_ONBOARD_NOW_ITS_YOUR_TURN: `AHORA ES TU TURNO`,
  ADD_SERVICE_DOODLE_DO_IT_AGAIN: `HAGÁMOSLO DE NUEVO`,
  ADD_SERVICE_DOODLE_ONBOARD_NOW_ITS_YOUR_TURN: `AHORA ES TU TURNO`,
  ADD_SERVICE_DOODLE_CREATE_DOODLE: 'Crea tu propio Garabato',
  ADD_SERVICE_DOODLE_CONFIRM_DOODLE: `Comprobemos que puedes recordarlo ... dibuja tu garabato <em>una vez más</em>`,
  ADD_SERVICE_GENERATING_FORGHETTI_ONBOARD: (serviceName) =>
    `Felicitaciones. Forghetti ahora está generando una nueva contraseña para <em>${serviceName}</em>`,
  VIEW_SERVICE_GENERATING_FORGHETTI: (serviceName) => `Generando su contraseña forghetti para <em>${serviceName}</em>`,
  VIEW_SERVICE_RESET_PASSWORD: `Restablece tu contraseña ahora`,
  VIEW_SERVICE_PASSWORD_HELP: (serviceName) =>
    `Hemos generado una contraseña muy segura para <em>${serviceName}</em>. Ahora es el momento de restablecer su contraseña anterior.`,
  VIEW_SERVICE_PASSWORD_HELP_MOVE: (serviceName, groupName) =>
    `Hemos movido <em>${serviceName}</em> a <em>${groupName}</em>. Si tu Garabato para este servicio ha cambiado, deberás actualizar tu contraseña..`,
  VIEW_SERVICE_OLD_PREFIX: 'Antiguo',
  VIEW_SERVICE_NEW_PREFIX: 'Nuevo',
  HIDE_QUICK_SELECT: `No mostrar de nuevo`,
  HIDE_QUICK_SELECT_CONFIRM_TITLE: `¿Ocultar esta pantalla?`,
  HIDE_QUICK_SELECT_CONFIRM: `Esto eliminará esta página para que no se muestre en el futuro, y agregar un nuevo servicio te llevará directamente a la página "agregar otro servicio".\n\nPuedes cambiar esto en "preferencias de usuario" en cualquier momento.`,
  ADD_SERVICE_DODODLES_DO_NOT_MATCH: `Tus Garabato no coinciden. Inténtalo de nuevo.`,
  THEME_COLOUR: 'Color',
  THEME_COLOURS: ['Azul impecable', 'Verde fresco', 'Rojo intrépido', 'Naranja agradable', 'Amarillo amistoso'],
  THEME_MODES: ['Día', 'Noche'],
  THEME_BLUE: 'Azul impecable',

  DELETE_ACCOUNT_CONTINUE: 'Seguir',
  DELETE_ACCOUNT_HEADER: 'Borrar cuenta',
  DELETE_ACCOUNT_HEADING: 'Borrar mi cuenta',
  DELETE_ACCOUNT_LINE_1: 'Eliminar tu cuenta supondrá:',
  DELETE_ACCOUNT_LINE_2: '• Eliminar tu cuenta de Forghetti',
  DELETE_ACCOUNT_LINE_3: '• Borrar toda la información que Forgetti tiene en tu perfil',
  DELETE_ACCOUNT_LINE_4: '• Salir de todos los grupos de Forghetti',
  DELETE_ACCOUNT_LINE_5: '• Eliminar el acceso a tu cuenta de todos los dispositivos conectados',
  DELETE_ACCOUNT_CHANGE_INSTEAD: '¿Prefieres cambiar el teléfono en lugar de borrar tu cuenta?',

  DELETE_ACCOUNT_CONFIRM_MESSAGE: (telNumber) =>
    `Hemos enviado un código de confirmación a tu número de teléfono (${telNumber}). Ingresa ese código para confirmar que deseas eliminar tu cuenta:`,
  DELETE_ACCOUNT_CONFIRM_BUTTON: 'Confirmar',

  DEVICES_HEADER: 'Gestión de dispositivos',
  DEVICES_LAST_ACTIVE: 'Última vez activo: ',
  DEVICES_FORGHETTI_APP: ' - aplicación Forghetti',
  DEVICES_LOG_OUT_ALL: 'Cerrar sesión en todos los dispositivos',

  SERVICES_PERSONAL: 'Personal',
  SERVICES_ADD_LOGIN: 'Agregar inicio de sesión',
  SERVICES_RECENT: 'Reciente', // Not currently used

  SEARCH_INTERNET: 'Busca la imagen en Internet',

  SERVICE_HEADER_NEW: 'Agregar inicio de sesión',
  SERVICE_NAME_PLACEHOLDER: 'Nombra tu inicio de sesión',

  SERVICE_DOODLEPAD_SUBHEADING: 'Garabato en el cuaderno',
  DOODLEPAD_SECRET: 'Ocultar',
  DOODLEPAD_SHOW: 'mostrar',
  DOODLEPAD_REDOODLE: 'Borrar',
  ERROR_NO_DOODLE: 'Dibuja tu Garabato',

  SERVICE_EDIT_FIELD_HEADER: 'Configuración',

  FIELD_TYPES: {
    lns: 'AbC123_*.',
    wns: 'Palabras123_ *.',
    lnx: 'AbC123 ~?%',
    wnx: 'Palabras123 ~?%',
    ln: 'AbC123',
    wn: 'Palabras123',
    l: 'A B C',
    w: 'Palabras',
    n: '123',
  },

  FIELD_TEMPLATE_PIN: 'PIN',
  FIELD_TEMPLATE_COMPLEX_PASSWORD: 'Contraseña compleja',
  FIELD_TEMPLATE_PASSWORD: 'Contraseña',
  FIELD_TEMPLATE_MEMWORD: 'Palabra memorable',
  FIELD_TEMPLATE_FORGHETTIBLE: 'Solo letras',

  SERVICE_USERNAME_LABEL: 'Correo electrónico o nombre de usuario',
  SERVICE_WEBSITE_LABEL: 'Dirección web',

  SERVICE_EDIT_HEADER: 'Detalles',
  SERVICE_EDIT_USERNAME_PLACEHOLDER: 'Ingrese el método de inicio de sesión',
  SERVICE_EDIT_WEBSITE_PLACEHOLDER: 'www.direcciónweb.com',

  SERVICE_ADD_FORGHETTIBLE: 'Agrega un olvídate',
  SERVICE_DFT_FORGHETTIBLE_NAME: 'Contraseña',

  SERVICE_NAME_LENGTH_INVALID: 'El nombre del servicio debe tener entre 2 y 32 caracteres',
  SERVICE_USERNAME_LENGTH_INVALID:
    'El correo electrónico / nombre de usuario del servicio debe tener menos de 64 caracteres',
  SERVICE_ADDRESS_LENGTH_INVALID: 'La dirección web del servicio debe tener menos de 128 caracteres',
  SERVICE_NO_FORGHETTIBLES: 'Debes agregar al menos un olvídate. Una contraseña, por ejemplo.',

  SERVICE_CHOOSE_ICON: 'Elegir icono',

  SERVICE_DOODLEPAD_HEADING: 'Garabato en el cuaderno',
  SERVICE_DOODLEPAD_RIGHT_NAV: 'Detalles',

  SERVICE_ADDING: 'Añadiendo servicio',

  SERVICE_CONNECTING: 'Conectando',

  FIELD_DETAILS_HEADER: 'Configuración',
  FIELD_NAME: 'Nombre',
  FIELD_NAME_PLACEHOLDER: 'Nombra tu olvídate',
  FIELD_TYPE: 'Tipo',
  FIELD_LENGTH: 'Longitud',
  FIELD_LENGTH_PICKER_CUSTOM: 'Personalizado',

  FIELD_RIGHT_NAV: 'OK',

  FIELD_OPTIONS_HEADING: 'Opciones',

  ADVANCED_OPTIONS: 'Opciones avanzadas',
  RESET_FIELD: 'Restablecer olvídate',
  DELETE_FIELD: 'Eliminar',
  RESTORE_FIELD: 'Restaurar olvídate',

  FIELD_ADVANCED_OPTIONS_HEADER: 'Opciones avanzadas',

  RESTORE_FIELD_PAGE_HEADER: 'Restaurar',
  RESTORE_FIELD_SUBHEADING: '¿Necesitas una contraseña antigua?',
  RESTORE_FIELD_DESCRIPTION:
    'Puedes volver a una versión anterior aquí. Simplemente selecciona la versión que te gustaría restaurar.',
  RESTORE_FIELD_VERSION_HISTORY: 'Historial de versiones',
  RESTORE_FIELD_CURRENT: 'Actual',

  SERVICE_VIEW_HEADER: 'Resumen',
  SERVICE_VIEW_RIGHT_NAV: 'Hecho',

  SERVICE_SUMMARY_MESSAGE: `Se han generado tus olvídates. Selecciona a continuación para copiar, ver y acceder a este inicio de sesión.`,
  SERVICE_SUMMARY_FIRST_LOGIN_MSG1: `¡Felicidades! ¡Acabas de generar tu primera contraseña forghetti!`,
  SERVICE_SUMMARY_FIRST_LOGIN_MSG2: `Ahora deberás reemplazar tu contraseña existente por esta nueva para que surta efecto.`,
  SERVICE_SUMMARY_FIRST_LOGIN_PASSWORD_TOOLTIP: `Cuando estés listo, puedes copiar tu nueva contraseña al portapapeles haciendo clic en este icono.`,

  SERVICE_DETAILS_HEADER: 'Detalles',
  SERVICE_DETAILS_RIGHT_NAV: 'Editar',
  SERVICE_DETAILS_DELETE: 'Eliminar inicio de sesión',

  PROFILE_HEADER: 'Perfil',
  PROFILE_WIZARD_COMPLETE: 'Completa tu configuración de Forghetti.',
  PROFILE_WIZARD_SEE_DETAILS: 'Ver detalles > >',
  PROFILE_PREMIUM_BANNER_HEADING: 'Prueba Premium',
  PROFILE_PREMIUM_BANNER_DETAIL: 'Desbloquea funciones para estar más seguro en línea.',
  PROFILE_THEME_MODE_LABEL: 'Modo',
  PROFILE_THEME_COLOUR_LABEL: 'Color',
  PROFILE_BADGES: 'Tus insignias',
  PROFILE_LINK_ACCOUNT: 'Tu cuenta',
  PROFILE_LINK_PREMIUM: 'Premium',
  PROFILE_ENABLE_AUTOFILL: 'Habilitar autocompletar',
  PROFILE_LINK_USER_PREFERENCES: 'Preferencias de la aplicación',
  PROFILE_YOUR_SUBSCRIPTION: 'Tu suscripción',
  PROFILE_LINK_INVITE: 'Comparte Forghetti con un amigo',
  /*PROFILE_LINK_INVITE_SECONDARY: 'Comparte con amigos y gana inicios de sesión gratuitos',*/
  PROFILE_REFERRAL_CODE: 'Código de referencia',
  PROFILE_REFERRAL_CODE_SECONDARY: '',
  PROFILE_LINK_DEVICES: 'Gestión de dispositivos',
  PROFILE_LINK_HELP: 'Consigue ayuda',
  PROFILE_LINK_FEEDBACK: 'Envíanos tus comentarios',
  PROFILE_LINK_ABOUT: 'Acerca de',
  PROFILE_LINK_LOGOUT: 'Cerrar sesión',

  PROFILE_ACCOUNT_HEADER: 'Tu cuenta',
  PROFILE_ACCOUNT_LINK_SECURITY: 'Seguridad',
  PROFILE_ACCOUNT_LINK_MIGRATE: 'Cambia el número',
  PROFILE_ACCOUNT_LINK_DELETE: 'Borrar mi cuenta',
  PROFILE_ACCOUNT_LINK_SUBSCRIPTION: 'Tu suscripción',

  PROFILE_REONBOARDING: 'Aprenda cómo funciona Forghetti',
  PROFILE_GET_ONLINE_HELP: 'Obtener ayuda en línea',
  PROFILE_GET_VIDEO_HELP: 'Obten video ayuda en línea',
  PROFILE_HELP_HEADER: 'Ayuda',

  PROFILE_EDIT_NAME_HEADER: 'detalles del perfil',
  PROFILE_EDIT_NAME_LABEL: 'Tu nombre',
  PROFILE_EDIT_TEL_LABEL: 'Número de teléfono',

  PROFILE_NAME_BLANK: 'El nombre del perfil no puede estar en blanco',
  PROFILE_NAME_TOO_LONG: 'El nombre del perfil no puede tener más de 32 caracteres',

  PROFILE_NAME_INVALID: 'El nombre del perfil solo puede contener letras, números y símbolos',

  PROFILE_MIGRATE_1_HEADER: 'Cambia el número',
  PROFILE_MIGRATE_1_HEADING: 'Número de teléfono actual',
  PROFILE_MIGRATE_1_INFO: 'Ingresa tu nuevo código de país y número de teléfono:',
  PROFILE_MIGRATE_1_PLACEHOLDER: 'Número de teléfono móvil',
  PROFILE_MIGRATE_1_BUTTON: 'Envía el código de verificación',

  PROFILE_MIGRATE_2_HEADER: 'Introduce el código',
  PROFILE_MIGRATE_2_HEADING: 'Número de teléfono actual',
  PROFILE_MIGRATE_2_HEADING2: 'Nuevo número de teléfono',
  PROFILE_MIGRATE_2_INFO: 'Ahora ingresa el código de verificación que recibas en tu nuevo teléfono:',
  PROFILE_MIGRATE_2_BUTTON: 'Confirmar',

  PROFILE_MIGRATE_3_HEADER: 'Todo listo',
  PROFILE_MIGRATE_3_MSG: 'Tu número de teléfono ha sido cambiado',
  PROFILE_MIGRATE_3_HEADING: 'Nuevo número de teléfono',

  PROFILE_ABOUT_HEADER: 'Acerca de',
  PROFILE_ABOUT_PRIVACY: 'Política de privacidad',
  PROFILE_ABOUT_COOKIE: 'Política de cookies',
  PROFILE_ABOUT_TERMS: 'Términos de servicio',
  PROFILE_ABOUT_ACCEPTABLE: 'Política de uso aceptable',
  PROFILE_ABOUT_VERSION_HEADING: 'Versión',

  PROFILE_REFERRAL_CODE_HEADING: 'código de referencia',
  PROFILE_REFERRAL_CODE_LABEL: 'código de referencia',
  PROFILE_REFERRAL_CODE_PLACEHOLDER: 'Ingrese su código de referencia',
  PROFILE_EDIT_REFERRAL_CODE_HEADING: 'Editar código de referencia',
  PROFILE_REFERRAL_CODE_TEXT: 'Si has recibido un código de referencia, ingrésalo a continuación.',

  PROFILE_PREMIUM_TYPE: {
    premium_trial: 'Usuario de prueba premium',
    premium_life: 'Usuario de por vida',
    free: 'Usuario libre',
    premium: 'Suscriptor premium',
    premium_paid: 'Suscriptor premium',
  },
  APP_PREFERENCES_HEADER: 'Preferencias de la aplicación',
  APP_PREFERENCES_THEME: 'Tema',
  APP_PREFERENCES_QUICK_SELECT: 'Mostrar descripciones emergentes',
  APP_PREFERENCES_SHOW_QUICK_SELECT: 'Mostrar pantalla de inicio de sesión de adición rápida',
  APP_PREFERENCES_SECRET_DOODLE: 'Garabato secreto por defecto',
  APP_PREFERENCES_SHOW_TOOLTIPS: 'Mostrar información sobre herramientas',
  APP_PREFERENCES_REQUIRE_TOUCH_ID: 'Requerir datos biométricos para abrir la aplicación',
  APP_PREFERENCES_NOTIFICATIONS: 'Notificaciones',
  APP_PREFERENCES_COUNTRY: 'Idioma',
  APP_PREFERENCES_TOUCH_ID_TOAST: `Asegúrate de que la identificación biométrica esté registrada en tu dispositivo`,

  PREMIUM_HEADER: 'Forghetti Premium',

  YOUR_SUBSCRIPTION_HEADER: 'Tu suscripción',
  YOUR_SUBSCRIPTION_SUBHEADING: 'Prueba Premium',

  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_TEXT_1: (date) =>
    `Actualmente te encuentras en una prueba premium gratuita. Tu prueba vencerá el ${date}. En ese momento, puedes continuar como usuario gratuito (y perder todos sus beneficios premium) o comprar una suscripción premium en la aplicación.`,
  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_TEXT_2: (date) => `Esperamos que estes disfrutando con Forghetti.`,

  YOUR_SUBSCRIPTION_FREE_TEXT_1: (date) =>
    `Actualmente eres un usuario gratuito. Suscríbete hoy para beneficiarte de inicios de sesión ilimitados, la capacidad de crear grupos ilimitados que puedes compartir entre amigos, familiares y compañeros de trabajo y para obtener informes detallados de Verificación de Estado.`,
  YOUR_SUBSCRIPTION_FREE_TEXT_2: (date) => `Esperamos que esté disfrutando con Forghetti.`,
  YOUR_SUBSCRIPTION_FREE_TEXT_3:
    'Si ya tienes una suscripción de la app store, haz clic en restaurar suscripción para volver a solicitarla.',
  YOUR_SUBSCRIPTION_FREE_DOWNLOAD: `¿No tienes la aplicación? Descárgala ahora desde la app store.`,

  YOUR_SUBSCRIPTION_PREMIUM_TEXT_1: (date) =>
    `Actualmente eres un usuario Premium. Su suscripción vence el ${date}. Cuando compras una suscripción, está configurada para renovarse automáticamente. Si lo deseas, puedes desactivarlo en tu cuenta de la App Store.`,
  YOUR_SUBSCRIPTION_PREMIUM_TEXT_2: (date) => `Esperamos que estés disfrutando con Forghetti.`,

  YOUR_SUBSCRIPTION_PREMIUM_NO_DATE_TEXT_1: `Actualmente eres un usuario Premium. Cuando compras una suscripción, está configurada para renovarse automáticamente. Si lo deseas, puede desactivarlo en tu cuenta de App Store.`,

  YOUR_SUBSCRIPTION_PREMIUM_LIFE_TEXT_1: (date) =>
    `Tu subcripción de por vida premium nunca caducará. ¡Puedes seguir usando Forghetti para siempre!`,
  YOUR_SUBSCRIPTION_PREMIUM_LIFE_TEXT_2: (date) => `Esperamos que estés disfrutando con Forghetti.`,
  YOUR_SUBSCRIPTION_FREE_TEXT_APP_3:
    'Si ya tienes una suscripción de la App Store, haz clic en restaurar suscripción para volver a solicitarla.',

  YOUR_SUBSCRIPTION_FREE_TEXT_APP_1: `Actualmente eres un usuario gratuito. Suscríbase hoy para beneficiarse de inicios de sesión ilimitados, la capacidad de crear grupos ilimitados que puede compartir entre amigos, familiares y compañeros de trabajo y para obtener informes detallados de Health Check.`,

  YOUR_SUBSCRIPTION_FREE_TEXT_APP_2: 'Escanea el código QR con tu teléfono para suscribirte al servicio Premium.',
  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_BUTTON_LABEL: 'Ver precios',
  YOUR_SUBSCRIPTION_PREMIUM_TRIAL_CORDOVA_BUTTON_LABEL: 'Ver suscripciones',

  YOUR_SUBSCRIPTION_FREE_BUTTON_LABEL: 'Mejora',

  PREMIUM_NO_PRODUCT_SELCTED: 'Selección de producto no válida',
  PREMIUM_PURCHASE_BUTTON: 'Comprar',
  PREMIUM_PURCHASE_BUTTON_NA: 'Próximamente',
  COMING_SOON: 'Próximamente',
  PREMIUM_PRICE_NONE: 'N / A',
  PREMIUM_PURCHASE_BUTTON_OWNED: 'Subscripción Actual',
  PREMIUM_PRUCHASE_REFRESH_PURCAHSE: 'Refresca tu compra',
  PREMIUM_MONTHLY_HEADER: 'Premium mes a mes',
  PREMIUM_MONTHLY_LIST: [
    {
      description: 'Grupos ilimitados',
    },
    {
      description: 'Inicios de sesión ilimitados',
    },
    {
      description: 'Soporte prioritario',
    },
  ],
  PREMIUM_ANNUAL_ALIAS: 'Plan Anual',
  PREMIUM_MONTHLY_ALIAS: 'Plan mensual',
  PREMIUM_ONE_OFF_ALIAS: 'Compra única',
  PREMIUM_LOADING_OWNED: '¡Felicitaciones! ¡Ahora eres un usuario premium!',
  PREMIUM_LOADING_RELOAD: 'Forghetti se recargará en breve con tu nueva suscripción.',
  PREMIUM_LOADING_APPROVED: '¡Espera mientras confirmamos tu suscripción con la App Store!',
  PREMIUM_LOADING_REGISTERED: 'Espera mientras cargamos las suscripciones disponibles ...',
  PREMIUM_MANAGE_SUBSCRIPTIONS: 'Administrar suscripción',
  PREMIUM_IMPOSSIBLE_TO_VERIFY: 'No ha sido posible verificar tu compra',
  PREMIUM_RESTORE_PURCHASE: 'Restaurar suscripción',
  PREMIUM_YEARLY_HEADER: 'Premium para todo el año',
  PREMIUM_YEARLY_LIST: [
    {
      description: 'Grupos ilimitados',
    },
    {
      description: 'Inicios de sesión ilimitados',
    },
    {
      description: 'Soporte prioritario',
    },
  ],
  PREMIUM_LIFETIME_HEADER: 'Premium para siempre',
  PREMIUM_LIFETIME_LIST: [
    {
      description: 'Grupos ilimitados',
    },
    {
      description: 'Inicios de sesión ilimitados',
    },
    {
      description: 'Soporte prioritario',
    },
  ],
  ENABLE_ANDROID_AUTOFILL_HEADING: 'Habilitar Autocompletar',
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_1: `Forghetti utiliza el servicio de accesibilidad de Android para la función de enfoque automático, que ayuda a obtener contraseñas cuando se le solicita en cualquier aplicación en su dispositivo.`,
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_2:
    'Le aseguramos que Forghetti no lea ni acceda a ningún otro contenido de pantalla. Solo identificamos cuándo aparece un campo de contraseña en la pantalla para que se encuentre automáticamente.',
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_3: `Para usar este servicio, se requiere consentimiento del usuario. Al hacer clic en el botón 'Aceptar', nos da permiso para utilizar el servicio de accesibilidad para AutocOill. Si elige negar el consentimiento, la función de enfoque automático se deshabilitará, pero puede continuar usando Forghetti sin esta funcionalidad.`,
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_4: `Tenga en cuenta que navegar lejos de esta pantalla no se toma como dando su consentimiento y no utilizamos mensajes de autoscalsación para recopilar el consentimiento.`,
  ENABLE_ANDROID_AUTOFILL_INSTRUCTION_5: `Para obtener más información sobre cómo se implementa y asegura nuestro uso de la API de accesibilidad, así como nuestro manejo de los datos del usuario, revise nuestra Política de privacidad.`,

  ENABLE_ANDROID_AUTOFILL_BUTTON: 'Aceptar',
  ENABLE_ANDROID_AUTOFILL_BUTTON_NO: 'Ahora no',

  MAKE_ADMIN_MESSAGE: '¿Te gustaría convertir a este usuario en administrador de este grupo?',
  MAKE_OWNER_MESSAGE: '¿Le gustaría transferir la propiedad de este grupo al usuario?',

  REMOVE_ADMIN_MESSAGE: '¿Te gustaría eliminar el estado de administrador de este usuario?',
  DELETE_GROUP_CONFIRM: '¿Estás seguro de que deseas eliminar este grupo?',
  LEAVE_GROUP_CONFIRM: '¿Te gustaría dejar este grupo?',
  GROUP_DELETED_MESSAGE: 'El grupo ahora ha sido eliminado.',
  REMOVE_MEMBER_MESSAGE: '¿Quieres eliminar a esta persona del grupo?',
  REMOVE_MEMBER_SUCCESS: 'El miembro del grupo ha sido eliminado',
  GROUP_SHARED_BY: 'Grupos compartidos por',
  ERROR_INVALID_NUM_WORDS: 'El número de palabras debe estar entre 1 y 10',
  ERROR_INVALID_NUM_LETTERS: 'La cantidad de caracteres debe estar entre 1 y 64',

  UPGRADE_REQUIRED: 'Se requiere actualización',
  ADD_LOGIN_NOT_ALLOWED: `Como usuario freemium, solo puedes agregar inicios de sesión a tu grupo personal. ¡Actualiza hoy para crear grupos y compartir inicios de sesión entre amigos, familiares y compañeros de trabajo!`,
  UPGRADE_TODAY_MESSAGE: `Como usuario freemium, puedes unirte a un grupo.
¡Actualiza hoy para crear grupos y compartir inicios de sesión entre amigos, familiares y compañeros de trabajo!`,

  UPGRADE_TODAY_BUTTON: 'Actualiza hoy',
  UPGRADE_TODAY_PAGE_HEADING: 'Actualiza hoy',
  UPGRADE_TODAY_MSG_1: 'Convertirse en un usuario Premium',
  UPGRADE_TODAY_PRICE: '£23.99 al año',
  UPGRADE_TODAY_MSG_2: 'Libera todas las funciones que ofrece Forghetti',
  UPGRADE_TODAY_MSG_3:
    'La subcripción premium se puede comprar en la aplicación en cualquiera de nuestras aplicaciones móviles:',

  UPGRADE_TODAY_MSG_4:
    'Una suscripción anual te permite convertirte en un usuario premium con la capacidad de crear inicios de sesión ilimitados, campos olvídate y grupos.',

  INVITE_FRIEND_HEADER: 'Obtén servicios gratuitos',
  INVITE_FRIEND_REFERRAL_LABEL: 'Enlace de referencia',
  INVITE_FRIEND_REFERRAL_COPY: 'Copiar el enlace de referencia al portapapeles',
  INVITE_FRIEND_REFERRAL_EMAIL: 'Enlace de referencia por correo electrónico',
  INVITE_FRIEND_PREAMBLE: 'Comparte Forghetti con un amigo',
  INVITE_FRIEND_TEXT: `Solo comparte tu enlace personal.`,
  INVITE_FRIEND_BENEFIT:
    'Recomienda a un amigo y obtén 5 servicios adicionales a tu grupo personal cuando se registre. Consigue que 5 amigos se registren y te daremos 15 servicios extra.',

  GROUPS_LIST_HEADER: 'Tus grupos',
  GROUPS_LIST_INFO_MESSAGE: 'Administra tus grupos o cambia entre grupos',
  GROUPS_LIST_PERSONAL_HEADING: 'Tu grupo personal',
  GROUPS_LIST_OTHER_GROUPS_HEADING: 'Tus otros grupos',
  GROUPS_LIST_ADD_NEW_GROUP: 'Agregar un nuevo grupo',
  GROUPS_LIST_CURRENTLY_ACTIVE: 'ACTUALMENTE ACTIVO',
  GROUPS_NEW_HEADER: 'Nuevo grupo',
  GROUPS_NEW_RIGHT_NAV: 'Crear',
  GROUPS_NEW_NAME_PLACEHOLDER: 'Ingresa un título',

  GROUPS_ADD_GROUP_HEADER: 'Añadir grupo',
  GROUPS_JOIN_GROUP: 'Unirse al grupo',
  GROUPS_JOIN_GROUP_TEXT: 'Únete a un grupo mediante un enlace de invitación',
  GROUPS_CREATE_GROUP: 'Crea un grupo',
  GROUPS_CREATE_GROUP_TEXT: 'Crea un nuevo grupo para compartir con otros',
  GROUPS_FREE_JOIN_WARNING: 'Como usuario freemium, puedes unirte a un grupo.',
  GROUPS_ADD_UPGRADE:
    'Actualiza hoy para crear grupos y compartir inicios de sesión entre amigos, familiares y compañeros de trabajo.',
  GROUP_NEW_INVITE_GENERATING: 'Generando su invitación grupal',
  LEAVE_GROUP_MESSAGE: 'Has sido eliminado del grupo.',
  GROUP_DETAIL_LOADING: `Recuperando detalles del grupo`,
  GRP_SHARING_HEADING: 'Compartiendo',
  GRP_MEMBERS_HEADING: 'Miembros',
  GRP_SETTINGS_HEADING: 'Configuración de grupo',

  GROUP_VIEW_HEADER: 'Detalles del grupo',
  GROUP_VIEW_RIGHT_NAV: 'Editar',
  GROUP_VIEW_USER_ADMIN: 'Administración',
  GROUP_VIEW_USER_OWNER: 'Propietario',
  GROUP_VIEW_USER_YOU: 'Tú',
  GROUP_VIEW_USER_READ_ONLY: 'Solo lectura',
  GROUP_VIEW_SWITCH_TO: 'Cambiar a este grupo',
  GROUP_VIEW_LEAVE: 'Deja este grupo',
  GROUP_VIEW_DELETE: 'Eliminar grupo',
  GROUP_VIEW_PREMIUM: 'Usuario Premium',
  GROUP_VIEW_FREE: 'Usuario Freemium',
  GROUP_DETAIL_SAVE_LOADING: `Actualiza los detalles de tu grupo`,
  GROUP_EDIT_HEADER: 'Detalles del grupo',
  GROUP_EDIT_MAX_MEMBERS: 'Número máximo de miembros',
  GROUP_EDIT_MAX_MEMBERS_SECONDARY: 'Incluyéndote',
  PERSONAL_GROUP_MESSAGE: `Este es tu grupo personal. Este grupo nunca se comparte con nadie más. Para cambiar el icono de este grupo, ve a tu perfil y cambia la foto de perfil.`,

  GROUP_CHOOSE_ICON: 'Elegir icono',

  GROUP_NAME_BLANK: 'El nombre del grupo no puede estar en blanco',
  GROUP_NAME_TOO_LONG: 'El nombre del grupo no puede tener más de 32 caracteres',

  GRP_MAX_MEMBERS_ERROR: 'El número máximo de miembros debe ser un número entre 1 y 50',

  JOIN_GROUP_PLACEHOLDER: 'Ingresa tu enlace de invitación',
  JOIN_GROUP_HEADING: 'Unirse al grupo',
  GRP_INVITE_CODE_LABEL: 'Enlace de invitación grupal',
  GRP_INVITE_CODE_NOTE: (group) => `Invita a miembros a <em>${group}</em> compartiendo este vínculo`,
  JOIN_GROUP_RIGHT_NAV: 'Unirse',

  REFERRAL_COPY_MESSAGE: (url) =>
    `Te invito a usar Forghetti. Descarga la aplicación y luego olvídate de las contraseñas para siempre. Ir a ${url}`,

  CREATE_GROUP_INVITE_TEXT_1: `Envía este código a tu familia, amigos o miembros del equipo para darles acceso a este grupo.

Para unirte a un grupo, haz clic en 'Agregar, luego haz clic en' Unirse al grupo 'y pega el enlace de tu invitación.`,

  INVITE_NEW_MEMBER: 'Invita a un nuevo miembro',
  GRP_INVITE_HELP_TEXT: `Todos los miembros del grupo tienen la capacidad de crear nuevos inicios de sesión, pero solo los administradores tienen la capacidad de eliminarlos. Los administradores también pueden eliminar miembros; sin embargo, si usted crea el grupo, otro administrador nunca podrá eliminarlo de él.`,

  // Template for text that is copied to clipboard when you choose to copy a group invite code to clipboard
  GRP_INVITE_CLIPBOARD_MESSAGE: (code) => `${code}`,

  INVITE_LINK_HEADING: 'Invitación',
  INVITE_SETTINGS_HEADING: 'Configuraciones',
  LINK_EXPIRY_LABEL: 'El enlace caduca dentro de',
  MAX_USES_LABEL: 'Número máximo de usos',

  LINK_EXPIRY_VALUE: (mins) => LINK_EXPIRY_VALUES[String(mins)],
  LINK_MAX_USES_VALUE: (num) => LINK_MAX_USE_VALUES[String(num)],
  INVITE_NO_LIMIT: LINK_MAX_USE_VALUES['-1'],
  INVITE_EXPIRY_NEVER: LINK_EXPIRY_VALUES['-1'],

  DEACTIVATE_GRP_LINK_CONFIRM: '¿Estás seguro de que deseas eliminar esta invitación?',
  DEACTIVATE_GRP_LINK_SUCCESS: 'La invitación al grupo se eliminó correctamente.',

  GRP_INVITE_CREATE_NAV: 'Crear',

  // Share Group Invite page
  SHARE_INVITE_HEADING: 'Invitar a los miembros',
  GRP_INVITE_CODE_LABEL: 'Enlace de invitación',
  CPY_GRP_INVITE: 'Copiar la invitación al portapapeles',
  DEACTIVATE_LINK: 'Eliminar invitación',
  SHARE_INVITE_VIA_EMAIL: 'Compartir invitación por correo electrónico',
  COPY_INVITE_SUCCESS: `Invitación de grupo copiada al portapapeles.`,

  ONBOARDING_HEADER_1: 'Crea un inicio de sesión en un sitio web favorito',
  ONBOARDING_HEADER_2: 'Dibuja tu garabato',
  ONBOARDING_HEADER_3: 'Se ha generado tu contraseña',
  ONBOARDING_HEADER_4: '¡Copia y listo!',
  ONBOARDING_HEADER_5: '¡Eso es!',
  ONBOARDING_WELCOME_HEADING: `Hola, mi nombre es Locky<br>Bienvenido a Forghetti`,
  ONBOARDING_WELCOME_TEXT:
    'Con Forghetti, puedes iniciar sesión en todos los servicios que usas, con un garabato seguro.',
  ONBOARDING_DOODLE_HEADING: 'Vas a necesitar un garabato de firma',
  ONBOARDING_DOODLE_TEXT:
    'Desde líneas hasta puntos, garabatos aleatorios o secuencias numéricas. Tu combinación única genera una contraseña súper segura.',
  ONBOARDING_HOW_IT_WORKS: 'Cómo funciona',
  ONBOARDING_ADDSERVICE_HEADING: 'Genial para cualquier servicio',
  ONBOARDING_ADDSERVICE_TEXT:
    'Cada vez que agreges un servicio, generamos una contraseña única y ultra fuerte. Incluso puede generar números de PIN y palabras memorables también.',
  ONBOARDING_RESETPASSWORD_HEADING: 'Restablecer e intercambiar su contraseña anterior',
  ONBOARDING_RESETPASSWORD_TEXT:
    'Abre el sitio web y restablece tu contraseña anterior, reemplazándola con tu contraseña nueva y ultra segura.',
  ONBOARDING_SECURESHARING_HEADING: 'Uso compartido seguro de contraseñas',
  ONBOARDING_SECURESHARING_TEXT:
    'Utiliza grupos para compartir el acceso a tus servicios favoritos de forma segura con amigos, familiares y compañeros de trabajo.',
  ONBOARDING_UNLIKE_OTHERS_HEADING: 'A diferencia de otros administradores de contraseñas ...',
  ONBOARDING_UNLIKE_OTHERS_TEXT:
    'No guardamos una base de datos de contraseñas. Los generamos cuando los necesitas. Se calculan utilizando tu garabato como clave. Súper seguro y solo conocido por ti.',
  ONBOARDING_DOODLETUTORIAL1_HEADING: '¡Ahora, a crear tu garabato característico! ¿Cómo hacerlo?',
  ONBOARDING_SHOWMEHOW: '¡Déjame enseñarte como!',
  ONBOARDING_DOODLETUTORIAL2_HEADING: 'En primer lugar, puedes dibujar un patrón ...',
  ONBOARDING_DOODLETUTORIAL: 'Tutorial seguro de Garabato',
  ONBOARDING_DOODLETUTORIAL3_HEADING: 'o una serie de líneas',
  ONBOARDING_DOODLETUTORIAL4_HEADING: 'incluso los puntos funcionan ...',
  ONBOARDING_DOODLETUTORIAL5_HEADING: 'O una combinación de todos ellos juntos',
  ONBOARDING_DOODLETUTORIAL6_HEADING: 'Pero recuerda que el orden importa',
  ONBOARDING_DOODLETUTORIAL7_HEADING: 'Dibujando Garabato A',
  ONBOARDING_DOODLETUTORIAL8_HEADING: 'no es lo mismo que dibujar el Garabato B',
  ONBOARDING_YOURTURN: `Ahora es tu turno`,

  ONBOARDING_COMPLETE_WELCOME: 'Bienvenido a Forghetti',
  ONBOARDING_COMPLETE_CONGRATULATIONS:
    '¡Felicidades! Acabas de dar los primeros pasos para proteger tu identidad en línea',
  ONBOARDING_COMPLETE_COMPLETE_SETUP: 'Completa tu configuración de Forghetti',
  ONBOARDING_COMPLETE_CREATE_FORGHETTI: 'Crea una cuenta Fordhetti',
  ONBOARDING_COMPLETE_SIGNATURE_DOODLE: 'Crea tu propio Garabato',
  ONBOARDING_COMPLETE_FIRST_LOGIN: 'Agrega tu primer servicio',
  ONBOARDING_COMPLETE_RESET_PASSWORD: 'Genera una contraseña segura',
  ONBOARDING_COMPLETE_IMPORT: 'Servicios de importación',
  ONBOARDING_COMPLETE_HEALTHCHECK: 'Ejecuta una verificación de estado en sus cuentas en línea',
  ONBOARDING_COMPLETE_IMPORT_BUTTON: 'Servicios de importación',
  ONBOARDING_COMPLETE_REMIND_LATER: 'Recuérdame más tarde',
  ONBOARDING_COMPLETE_CONTINUE: `¡Adelante!`,
  ONBOARDING_COMPLETE_RESET_PASSWORD: 'Restablecer una contraseña insegura',
  ONBOARDING_COMPLETE_HEALTHCHECK_BUTTON: 'Ejecuta una verificación de estado',
  ONBOARDING_COMPLETE_NEXT_STEPS: `<h2>Ahora, ¿por qué no:</h2><ul><li>Ejecutar una verificación de estado en tus cuentas en línea?</li><li>O actualiza otro servicio en Forghetti</li><li> O simplemente echa un vistazo...</li></ul>`,

  ONBOARDING_EXISTING_TITLE: 'Nuevas características',
  ONBOARDING_EXISTING_WELCOME_HEADING: 'Mil gracias',
  ONBOARDING_EXISTING_WELCOME_TEXT: `El equipo de Forghetti ha estado ocupado. Hemos realizado muchas mejoras entre bastidores y por adelantado. Echa un vistazo a algunas de las nuevas funciones.`,
  ONBOARDING_EXISTING_MOVE_SERVICES_HEADING: 'Mudando Servicios',
  ONBOARDING_EXISTING_MOVE_SERVICES_TEXT:
    'Forghetti ahora te permite mover servicios de un grupo a otro. Simplemente haga clic en los tres puntos de cada servicio y seleccione mover.',
  ONBOARDING_EXISTING_HEALTHCHECK_HEADING: 'Verificación de estado',
  ONBOARDING_EXISTING_HEALTHCHECK_TEXT:
    'Forghetti ahora incluye Verificación de estado, una función que le permite monitorear activamente tus nombres de usuario y contraseñas contra violaciones de datos conocidas.',
  ONBOARIDNG_EXISTING_SHARING_HEADING: 'Compartir en grupo rápido',
  ONBOARDING_EXISTING_SHARING_TEXT:
    'Comparte sus grupos de Forghetti con sus amigos, familiares y compañeros de trabajo con solo hacer clic en un botón.',
  ONBOARDING_EXISTING_SWITCHING_HEADING: 'Cambio de grupo rápido',
  ONBOARDING_EXISTING_SWTICHING_TEXT:
    'Trabajar con tus grupos se ha vuelto más fácil que nunca. Agregar nuevos servicios a un grupo es más rápido y fácil que nunca.',
  ONBOARDING_EXISTING_PRICING_HEADING: 'Actualizaciones de precios',
  ONBOARDING_EXISTING_PRICING_TEXT: 'Forghetti ahora ofrece más planes de suscripción para satisfacer tus necesidades.',

  ACCOUNT_SECURITY_HEADER: 'Seguridad',
  ACCOUNT_SECURITY_HEADING: 'Nada es mas seguro',
  ACCOUNT_SECURITY_SECONDARY_HEADING:
    'Tratamos la seguridad de los datos como una prioridad. Forghetti te permite generar inicios de sesión para múltiples servicios usando un solo garabato. Lo mejor de todo esto es que en las bases de datos de Forghetti no hay contraseñas almacenadas ... ni tan siquiera una. A diferencia de todas las demás aplicaciones de administración de contraseñas, y hay muchas, no almacenamos tus contraseñas.  ',
  ACCOUNT_SECURITY_LEARN_MORE: 'Más información sobre la seguridad de Forghetti',

  MSG_FALLBACK_ERROR_MESSAGE: `Ocurrió un error. Disculpa.`,

  MSG_GROUP_ICON_UPDATED: 'Icono actualizado',
  MSG_COPIED_FORGHETTIBLE: (name) => `${name} copiado al portapapeles`,
  MSG_OPEN_FORGHETTIBLE: `Haga clic aquí para abrir`,
  MSG_DELETE_LOGIN_CONFIRM: '¿Estás seguro de que deseas eliminar este inicio de sesión?',
  MSG_LOGIN_DELETED: (name) => `Inicio de sesión '${name}' eliminado`,
  MSG_SERVICE_ICON_DELETED: 'Icono de servicio actualizado',
  MSG_LOGOUT_CONFIRM: '¿Estás seguro que deseas salir?',
  MSG_LOGOUT_ALL_DEVICES_CONFIRM: '¿Estás seguro?',
  MSG_USER_PROMOTED_ADMIN: 'El usuario ha sido ascendido a administrador.',
  MSG_USER_PROMOTED_OWNER: 'Se ha transferido la propiedad',

  MSG_CONFIRM_RESET: 'Confirma que deseas restablecer esta contraseña',
  MSG_CONFIRM_RESTORE: 'Confirma que te gustaría restaurar esta versión anterior.',
  MSG_NEW_GROUP_CREATED: (name) => `Se ha creado un nuevo grupo: ${name}`,
  MSG_OPENING_WEBSITE: 'Abriendo el sitio web ...',
  MSG_REMOVED_ADMIN: 'Se ha revocado el estado de administrador para este usuario',
  MSG_UNSAVED_CONFIRM: 'Hay cambios sin guardar. ¿Estás seguro de que te gustaría retroceder?',

  EXTENSION_NONE_FOUND: 'No se encontraron olvídates para este sitio web',
  EXTENSION_GO_TO_FORGHETTI: 'Ir a Forghetti',
  EXTENSION_NOACCESS_HEADER: 'Actualiza hoy',
  EXTENSION_UPGRADE: `Actualmente eres un usuario freemium. Actualiza hoy para usar la extensión Forghetti Chrome y benefíciate de inicios de sesión ilimitados y la capacidad de crear grupos y compartir inicios de sesión entre amigos, familiares y compañeros de trabajo. Si deseas actualizar a una suscripción Premium, selecciona el botón Actualizar a continuación.`,
  EXTENSION_NOLOGINFOUND_HEADER: `No encontrado en Forghetti`,
  EXTENSION_NOLOGINFOUND_MSG:
    'No se ha encontrado ningún olvídate para este sitio web. Dibuja un garabato a continuación para crear rápidamente un nuevo olvídate para este sitio web',

  CONTEXT_EDIT_LOGIN_LIST_ITEM: 'Editar',
  CONTEXT_MOVE_LOGIN_LIST_ITEM: 'Moverse',
  CONTEXT_ADD_LOGIN_LIST_ITEM: 'Agregar olvídate',
  CONTEXT_RESET_LOGIN_LIST_ITEM: 'Restablecer olvídate',
  CONTEXT_DELETE_LOGIN_LIST_ITEM: 'Eliminar',
  CONTEXT_UPLOAD_GROUP_PHOTO: 'Tomar foto',
  CONTEXT_UPLOAD_GROUP_IMAGE: 'Cargar imagen',
  CONTEXT_CHOOSE_GROUP_IMAGE: 'Elegir imagen',
  CONTEXT_TAKE_PROFILE_PIC: 'Tomar foto',
  CONTEXT_UPLOAD_PROFILE_PIC: 'Subir foto',
  CONTEXT_UPLOAD_SERVICE_IMAGE: 'Cargar imagen',
  CONTEXT_CHOOSE_SERVICE_IMAGE: 'Elegir imagen',
  CONTEXT_SERVICE_IMAGE_WEBSEARCH: 'Búsqueda Web',
  CONTEXT_MAKE_ADMIN: 'Hacer administrador',
  CONTEXT_MAKE_OWNER: 'Hacer propietario',

  CONTEXT_REMOVE_FROM_GROUP: 'Sacar del grupo',
  CONTEXT_REVOKE_ADMIN: 'Revocar administrador',
  CONTEXT_NEW_GROUP_MEMBERS: 'Miembros máximos de grupos nuevos',
  CONTEXT_SELECT_FIELD_TYPE: 'Selecciona tipo',
  CONTEXT_FIELD_TYPE_LNS: 'Letras, números y símbolos',
  CONTEXT_FIELD_TYPE_WNS: 'Palabras, números y símbolos',
  CONTEXT_FIELD_TYPE_LNX: 'Letras, números y símbolos complejos',
  CONTEXT_FIELD_TYPE_WNX: 'Palabras, números y símbolos complejos',
  CONTEXT_FIELD_TYPE_LN: 'Letras y números',
  CONTEXT_FIELD_TYPE_WN: 'Palabras y números',
  CONTEXT_FIELD_TYPE_L: 'Solo letras',
  CONTEXT_FIELD_TYPE_W: 'Solo palabras',
  CONTEXT_FIELD_TYPE_N: 'Solo números',
  CONTEXT_ADD_FIELD:
    'Selecciona un campo predefinido a continuación. Puedes personalizar la configuración del campo después si es necesario.',
  CONTEXT_ADD_FIELD_PASS: 'Contraseña (letras, números y símbolos)',
  CONTEXT_ADD_FIELD_COMPLEX_PASS: 'Contraseña avanzada (letras, números y símbolos complejos)',

  CONTEXT_ADD_FIELD_PIN: 'Pin (solo números)',
  CONTEXT_ADD_FIELD_MEMWRD: 'Palabra memorable (solo palabras)',
  CONTEXT_ADD_FIELD_FORGHETTIBLE: 'Solo letras',
  CONTEXT_LINK_EXPIRE_15MINS: '15 minutos',
  CONTEXT_LINK_EXPIRE_30MINS: '30 minutos',
  CONTEXT_LINK_EXPIRE_1HOUR: '1 hora',
  CONTEXT_LINK_EXPIRE_6HOURS: '6 horas',
  CONTEXT_LINK_EXPIRE_12HOURS: '12 horas',
  CONTEXT_LINK_EXPIRE_1DAY: '1 día',
  CONTEXT_LINK_EXPIRE_NEVER: 'Nunca',
  CONTEXT_LINK_EXPIRE_CANCEL: 'Cancelar',
  CONTEXT_LINK_USES_1: 'Una vez',
  CONTEXT_LINK_USES_2: 'Dos veces',
  CONTEXT_LINK_USES_5: '5 veces',
  CONTEXT_LINK_USES_10: '10 veces',
  CONTEXT_LINK_USES_20: '20 veces',
  CONTEXT_LINK_USES_50: '50 veces',
  CONTEXT_LINK_USES_NO_LIMIT: 'Sin límite',
  CONTEXT_LINK_USES_CANCEL: 'Cancelar',

  FINGERPRINT_TITLE: 'Bienvenido a Forghetti',
  FINGERPRINT_DESCRIPTION: 'Escanea tu huella dactilar para desbloquear Forhetti',
  FINGERPRINT_DESCRIPTION_FACE: 'Usa Face ID para desbloquear Forghetti',

  GENERATING_GROUP: 'Generando tu grupo',
  GENERATING_PASSWORD: 'Generando su contraseña',
  DELETING_GROUP: 'Eliminando grupo',
  LOADING_SERVICE: 'Cargando su servicio',
  UNABLE_TO_PURCHASE: 'No se puede comprar la suscripción premium actualmente. Vuelve a intentarlo en breve.',

  HEALTHCHECK_WELCOME_HEADER: 'Verificación de estado',
  HEALTHCHECK_WELCOME_NOTE:
    'Ejecuta la evaluación de verificación de estado de Forghetti y descubre si sus datos en línea se han visto comprometidos en una violación de datos.',
  HEALTHCHECK_WELCOME_BUTTON: 'Ejecuta una verificación de estado',
  HEALTHCHECK_USERNAMES_HEADER: `Empecemos por tu correo electrónico`,
  HEALTHCHECK_USERNAMES_INFO_1: 'Verifica tu dirección de correo electrónico',
  HEALTHCHECK_USERNAMES_INFO_2:
    'Facilitanos tu dirección de correo electrónico y la compararemos con cualquier pirateo en línea nuevo o anterior.',
  HEALTHCHECK_USERNAMES_PRIMARY_SUBHEAD: 'Tu dirección de correo electrónico principal',
  HEALTHCHECK_USERNAMES_SECONDARY_SUBHEAD: 'Tu dirección de correo electrónico secundaria',
  HEALTHCHECK_USERNAMES_PLACEHOLDER: 'Dirección de correo electrónico',
  HEALTHCHECK_PASSWORD_HEADER: `Comprobemos tu contraseña`,
  HEALTHCHECK_PASSWORD_INFO_1: '¿Utilizas la misma contraseña a menudo?',
  HEALTHCHECK_PASSWORD_INFO_2: `Antes de usar Forghetti, ¿usabas la misma contraseña a menudo?
Veamos si has quedado expuesto en una filtración de datos.`,
  HEALTHCHECK_PASSWORD_INFO_3:
    'Esta verificación es opcional. Forghetti no almacena ni transmite tu contraseña. Generaremos un hash seguro de su contraseña de inmediato. La aplicación solo transmite una parte del hash de su contraseña para que puedas estar seguro de que tu contraseña está segura con nosotros.',

  HEALTHCHECK_PASSWORD_SUBHEAD: 'Tu contraseña',
  HEALTHCHECK_PASSWORD_OPTIONAL: 'Esto es opcional.',
  HEALTHCHECK_PASSWORD_PLACEHOLDER: 'Contraseña',
  HEALTHCHECK_PASSWORD_VAL_TOO_LONG: 'El campo de contraseña es demasiado largo',

  HEALTHCHECK_RUNNING: 'Ejecutando tu verificación de estado',
  HEALTHCHECK_USERNAME_VAL_FIELD_BLANK: 'La dirección de correo electrónico no puede estar en blanco',
  HEALTHCHECK_USERNAME_VAL_FIELD_DUPLICATE:
    'La dirección de correo electrónico secundaria no puede ser la misma que la primera',
  HEALTHCHECK_USERNAME_VAL_FIELD_TOO_SHORT: 'Dirección de correo electrónico demasiado corta',
  HEALTHCHECK_USERNAME_VAL_FIELD_TOO_LONG: 'Dirección de correo electrónico demasiado larga',

  HEALTHCHECK_ASSESSMENT_HEADER: 'Verificación de estado',
  HEALTHCHECK_ASSESSMENT_EMAIL: (email) => `<em>Fallos de ${email}</em>`,
  HEALTHCHECK_ASSESSMENT_INFO_1: 'Los resultados de tu verificación de estado se muestran a continuación.',
  HEALTHCHECK_ASSESSMENT_INFO_2: 'Los usuarios premium pueden hacer clic en el fallo para obtener más información.',

  HEALTHCHECK_ASSESSMENT_PASSWORD: '<em><em>Resultados para su contraseña</em></em>',
  HEALTHCHECK_ASSESSMENT_COMPROMISED_HEAD: `Servicios comprometidos`,
  HEALTHCHECK_ASSESSMENT_NO_BREACH: 'No hay fallos de seguridad en los servicios con esta cuenta',
  HEALTHCHECK_ASSESSMENT_PASSWORD_STRENGTH: 'Fuerza',
  HEALTHCHECK_ASSESSMENT_PASSWORD_WEAK: 'Débil',
  HEALTHCHECK_ASSESSMENT_PASSWORD_STRONG: 'Fuerte',
  HEALTHCHECK_ASSESSMENT_PASSWORD_COMP: 'Aparece en una base de datos de fallos de seguridad',
  HEALTHCHECK_ASSESSMENT_HIBP_LINK: 'fuente: <a data-actionclick="openHIBP">haveIbeenpwned</a>',
  HEALTHCHECK_ASSESMENT_UPGRADE_MESSAGE:
    'Los detalles del fallo de seguridad solo están disponibles para usuarios premium',
  HEALTHCHECK_DETAILS_PASSWORD_HEADER: 'Resultados de la contraseña',
  HEALTHCHECK_DETAILS_USER_HEADER: `Detalles del fallo seguridad`,
  HEALTHCHECK_DETAILS_USER_TITLE: 'Nombre',
  HEALTHCHECK_DETAILS_USER_DOMAIN: 'Dominio',
  HEALTHCHECK_DETAILS_USER_DATE: 'Fecha del fallo de seguridad',
  HEALTHCHECK_DETAILS_USER_ACCOUNTS_PWNED: 'Cuentas con fallos de seguridad',
  HEALTHCHECK_DETAILS_PASSWORD_INFO_X_BREACH: (breach) =>
    `Tu contraseña se ha incluido en <em>${breach}</em> lista de fallos de seguridad conocidas. Esto no significa necesariamente que sus cuentas se hayan visto comprometidas. Debería considerar la posibilidad de generar una contraseña súper segura con <em>Forghetti</em> lo antes posible.`,
  HEALTHCHECK_DETAILS_PASSWORD_INFO_NO_BREACH: `¡Buenas noticias! Tu contraseña no figura en ninguna lista de fallos de seguridad conocida. Esto no garantiza que sus cuentas no se hayan visto comprometidas. Considere la posibilidad de generar una nueva contraseña súper segura con <em> forghetti </em>.`,
  HEALTHCHECK_DETAILS_PASSWORD_STRENGTH_INFO_STRONG: `Tu contraseña es <em>fuerte</em>. Esto significa que ha superado todos los criterios establecidos por la prueba de seguridad de la contraseña Forghetti.`,
  HEALTHCHECK_DETAILS_PASSWORD_STRENGTH_INFO_WEAK: `Tu contraseña es <em>debil</em>. Esto significa que no cumple con los siguientes criterios establecidos por la prueba de seguridad de la contraseña Forghetti:`,
  HEALTHCHECK_DETAILS_RESET_NOTE: 'Genera una contraseña súper segura con Forghetti ahora',
  HEALTHCHECK_DETAILS_RESET_BUTTON: 'Crea un nuevo servicio',
  HEALTHCHECK_DETAILS_HIBP_LINK: 'fuente: haveIbeenpwned',
  HEALTHCHECK_LOADING_DETAIL: 'Cargando detalles de fallo de seguridad',

  IMPORT_SERVICE_WELCOME_BUTTON: 'Comenzar a importar servicios',
  IMPORT_SERVICE_WELCOME_INFO: 'Importa tus datos de inicio de sesión existentes a Forghetti',
  IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_1: 'Para importar servicios, abre la aplicación de escritorio Forghetti.',
  IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_2:
    'Si no tienes la aplicación de escritorio Fordhetti, haz clic en el enlace de abajo',
  IMPORT_SERVICE_WELCOME_INFO_NOT_DESKTOP_BUTTON: 'Descarga Forghetti',
  IMPORT_SERVICES_LIST_HEADER: 'Seleccionar servicios para importar',
  IMPORT_SERVICES_LIST_ERROR_NONE_SELECTED: 'No se seleccionaron servicios importados',
  IMPORT_SERVICES_YOUR_IMPORTED_SERVICES: 'Tus servicios importados',
  IMPORT_SERVICES_LIST_NOTE: `Elije los servicios que deseas importar a Forghetti.`,
  IMPORT_SERVICES_GROUP_NOTE: 'Selecciona un grupo al que deseas mover tus servicios',
  IMPORT_SERVICES_GROUP_HEADER: 'Selecciona grupo',
  IMPORT_SERVICES_USERNAME_HEADER: 'Ingresa los nombres de usuario',
  IMPORT_SERVICES_USERNAME_NOTE: 'Verifica los nombres de usuario asociados para cada cuenta',
  IMPORT_SERVICES_CREATE_HEADER: 'Crear servicios',
  IMPORT_SERVICES_CREATE_BUTTON: 'Comienza a crear servicios',
  IMPORT_SERVICES_CREATE_HOW_MANY: (number) => `<em>${number}</em> servicios para crear`,
  IMPORT_SERVICES_CREATE_LOADING: (current, total) => `Importado <em>${current}</em> de <em> ${total}</em> servicios`,
  IMPORT_SERVICES_COMPLETE_HEADER: 'Importación completa',
  IMPORT_SERVICES_COMPLETE_NOTE: `¡Felicidades! Tus servicios han sido importados`,
  IMPORT_FOLDER_SELECT_HEADER: 'Importar desde Chrome',
  IMPORT_FOLDER_SELECT_CHROME: 'Importar servicios de Chrome',
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC: `Forghetti deberá acceder a tus datos de inicio de sesión almacenados en tu ordenador.`,
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_2: `Cuando estés listo, haz clic en el botón 'Selecciona tu carpeta de Chrome' a continuación. Aparecerá un cuadro de diálogo en la ubicación de tus datos de inicio de sesión de Chrome. Simplemente selecciona 'Abrir' para dar acceso a Forghetti a esta carpeta.`,
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_3:
    'Si deseas ubicar esta carpeta manualmente, simplemente ve a Libary / Application Support / Google / Chrome',
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_4:
    'Recuerda, tus contraseñas existentes no son descifradas por Forghetti. Forghetti solo recupera tus datos de inicio de sesión.',
  IMPORT_FOLDER_SELECT_CHROME_NOTE_MAC_5:
    'Una vez que se lean tus datos de inicio de sesión, podrás seleccionar qué servicios te gustaría importar a Forghetti.',

  IMPORT_FOLDER_SELECT_CHROME_BUTTON: 'Selecciona tu carpeta de Chrome',

  IMPORT_GROUP_HEADER: 'Tu grupo de servicio importado',
  IMPORT_GROUP_NOTE: (importingServices) =>
    `Se está creando automáticamente un grupo especial de <em>Servicios importados</em>. El grupo contendrá los <em>${importingServices}</em> servicios que seleccionaste para importar.`,
  IMPORT_GROUP_NOTE_2:
    'Los servicios de este grupo no están habilitados. Para habilitar los servicios, deberás mover los servicios a otro grupo. Al hacer clic en continuar, accederás al asistente de servicios de importación. El asistente de servicios de importación hará que el proceso de mover tus servicios de importación sea rápido y fácil.',
  IMPORT_GROUP_NOTE_3:
    'Si no tienes tiempo para mover todos sus servicios importados ahora mismo, no te preocupes. Puedes mover tus servicios importados a través del asistente de servicios de importación en cualquier momento y en cualquier dispositivo.',
  IMPORT_GROUP_BUTTON: 'Continuar',

  IMPORT_SERVICE_IMPORT_NODATA_HEADER: 'No se pueden encontrar los datos de inicio de sesión',
  IMPORT_SERVICE_IMPORT_NODATA_NOTE:
    'Forghetti no encontró ningún dato de inicio de sesión que pudiera usarse para importar',

  IMPORT_MOVE_SERVICE_HEADER: 'Comienza a mover sus servicios',
  IMPORT_MOVE_SERVICE_NOTE_1: (services) =>
    `Tienes ${services} en tu grupo de servicios importados. Para comenzar a activar tus servicios importados, debes moverlos a otro grupo.`,
  IMPORT_MOVE_SERVICE_BUTTON: 'Seguir',
  IMPORT_SERVICE_LOADING: 'Importando sus servicios',
  IMPORT_SERVICE_GROUP_NAME: 'Servicios de importación',
  NEW_GROUP_REQUIRES_PREMIUM: 'La creación de un nuevo grupo requiere una subscripción premium.',

  IMPORT_TYPE_HEADER: 'Seleccionar tipo de importación',
  IMPORT_TYPE_NOTE_1: `Forghetti puede importar servicios automáticamente desde tus datos de inicio de sesión de Google Chrome. Si utilizas Google Chrome y deseas importar servicios mediante este método, haz clic en el botón "Importar Google Chrome" a continuación.`,
  IMPORT_TYPE_BUTTON_1: `Importar Google Chrome`,
  IMPORT_TYPE_NOTE_2: `Alternativamente, puedes importar tus inicios de sesión cargando un documento CSV o Excel (XLSX). Para comenzar a importar servicios usando esta función, haz clic en el botón 'Cargar documento' a continuación.`,
  IMPORT_TYPE_BUTTON_2: 'Cargar documento',
  IMPORT_TYPE_NOTE_3: `Si utilizas principalmente safari, es posible que quieras utilizar la función de carga de documentos para importar tus servicios. Para encontrar las contraseñas del llavero de Safari, abre 'Preferencias' (command + ',') en el navegador Safari y navega hasta la pestaña 'Contraseñas',`,

  IMPORT_UPLOAD_HEADER: 'Cargar documento',
  IMPORT_UPLOAD_NOTE_1:
    'Carga un CSV o una hoja de cálculo (XLSX) para comenzar a importar servicios. Como mínimo necesitará el nombre de un servicio.',
  IMPORT_UPLOAD_BUTTON: 'Cargar tu documento',
  IMPORT_UPLOAD_NOTE_2: 'Descarga el archivo CSV de ejemplo o excel para comenzar.',
  IMPORT_UPLOAD_SAMPLE_CSV: 'Descargar CSV de ejemplo',
  IMPORT_UPLOAD_SAMPLE_XLS: 'Descargar ejemplo de Excel',

  // ===========================================================================
  // Texto de ayuda
  // ===========================================================================

  //NO_LOGINS_MESSAGE: `Para comenzar, toca 'Agregar inicio de sesión' en la esquina superior derecha para agregar un inicio de sesión de un servicio que utilices (por ejemplo, Amazon, Facebook, Barclays).

  NO_LOGINS_MESSAGE: `Agrega servicios a este grupo haciendo clic en el icono + (más)

Comparte este grupo con amigos, colegas o familiares haciendo clic en el icono de compartir (arriba a la derecha)

Puedes cambiar el nombre de este grupo y administrar quién tiene acceso en la configuración del grupo

`,

  LOGIN_RECENT: 'Reciente',
  HELP_LOGIN_TITLE:
    'Introduce un título para tu inicio de sesión (por ejemplo, Amazon). Agrega un logo tocando el ícono de la cámara. Si no seleccionas un icono, se creará uno predeterminado para ti.',
  HELP_LOGIN_USERNAME:
    'Introduce el nombre de usuario que utiliza para tu inicio de sesión. Tu dirección de correo electrónico, por ejemplo. Si lo haces, esto te permitirá copiarlo y pegarlo fácilmente desde la pantalla de resumen de inicio de sesión.',
  HELP_LOGIN_WEBSITE:
    'Opcionalmente, introduce la dirección del sitio web de tu inicio de sesión. Si lo haces, esto te permitirá abrir el sitio web directamente desde la pantalla de resumen de inicio de sesión.',
  HELP_LOGIN_ADD_FORGHETTIBLE: `Agregua una contraseña estándar (la predeterminada es compleja y larga, por lo que no necesitas editar esto en absoluto), una palabra memorable o un PIN aquí. Si deseas más control, agrega un olvídate personalizado.

Debes agregar al menos un olvídate para continuar. Luego, simplemente toca en Siguiente en la esquina superior derecha de la pantalla.`,

  HELP_GROUPS_LIST_HEADER: `Toca el signo + para crear o unirte a un grupo. Los suscriptores gratuitos solo pueden unirse a un grupo de suscriptores de pago.

Puedes utilizar grupos para administrar diferentes categorías de inicio de sesión o para compartir un grupo de inicios de sesión.

Recuerda que deberás compartir el Garabato grupal con todos los miembros para que puedan generar las mismas contraseñas que tu. Por lo tanto, considera usar una palabra, frase o código memorable que sea fácil de recordar.
`,

  HELP_GROUPS_LIST_PERSONAL: `Este es tu grupo personal único. No puedes eliminarlo ni compartirlo con nadie más.`,
  HELP_GROUPS_LIST_OTHER_GROUPS: `Estos son otros grupos que haya creado como suscriptor Premium o que a los que te hayas unido al ser invitado por otra persona.`,

  HELP_DOODLEPAD: `Dibuja un garabato que recordarás. Líneas conectadas, puntos o una combinación de ambos. Cuantas más capas, más seguro. Sin embargo, debe recordarlo, ya que esta es la clave para poder generar tus contraseñas ahora y para siempre.

¿Preocupado por las miradas indiscretas? Toca "Garabato secreto" y dibuja sin dejar rastro. Comete un error y puedes empezar de nuevo tocando 'ReGarabato'. Luego presiona el check para generar tu contraseña.`,

  HELP_SERVICE_SUMMARY: `Toca el botón ojo para ver la contraseña. Toca tu contraseña para copiarla al portapapeles. Ve al sitio web del servicio y restablece tu contraseña actual con esta nueva.

Entonces podrás olvidarlo. La próxima vez que lo necesites, simplemente dibuja el garabato y Forghetti lo generará nuevamente. No se guarda en ningún lugar, sino que se genera cuando lo necesitas con tu Garabato.
`,

  HELP_FIELD_SETTINGS_LENGTH: `Elije la longitud del olvídate como la cantidad de caracteres (que se usa para una contraseña o PIN estándar) o la cantidad de palabras, si usas palabras memorables.`,
  HELP_FIELD_SETTINGS_TYPE: `Elije el tipo de olvídate como una combinación de letras, símbolos, números y palabras.`,
  HELP_FIELD_SETTINGS_NAME: `Ingresa un nombre descriptivo para tu contraseña olvídate. Ten en cuenta que esta no es la contraseña en sí, ya que la genera automáticamente Forghetti.`,
  HELP_FIELD_SETTINGS_RESET: `Si necesitas cambiar una contraseña generada por Forghetti en cualquier momento, simplemente haz clic en restablecer y la próxima vez que dibujes tu Garabato, la contraseña generada habrá cambiado.`,
  HELP_RESTORE_PAGE: `La configuración de las versiones anteriores de las contraseñas Forghetti se puede restaurar haciendo clic en la fecha.

Si haces esto, cuando dibujes tu Garabato, se generará tu contraseña anterior.

¡Puedes cambiar hacia atras a hacia delante como desees!
`,
  HELP_LOGINS_LIST: `Haga clic en un inicio de sesión para generar tu contraseña.

Utiliza la herramienta de búsqueda para encontrar el inicio de sesión que necesitas.
`,
  HELP_GROUP_PAGE: `Desplázate hasta la parte inferior para eliminar el grupo, ver los inicios de sesión de este grupo o dejar un grupo en el que ya no desees ser miembro.`,
  HELP_GROUP_SHARING: `Crea una invitación para compartir con un amigo o colega.`,
  HELP_GROUP_MEMBERS: `Administra los permisos de los usuarios de grupo, conviértelos en administradores o elimínalos por completo.`,
  HELP_GROUP_INVITE_MAX_USES: `Controla cuánto tiempo y cuántas veces se puede usar una invitación antes de enviarla a amigos y colegas.`,

  // ===========================================================================
  // Emails
  // ===========================================================================

  REFERRAL_EMAIL_SUBJECT: 'Una invitación a usar Forghetti, porque nada es más seguro.',
  REFERRAL_EMAIL_BODY: (url) =>
    `Hola,

Estoy usando una gran aplicación llamada Forghetti. Me ha ayudado a poder olvidar mis contraseñas para siempre. Tus contraseñas no se almacenan en ningún lugar.

Generas tus contraseñas cuando las necesitas creando un garabato. Dibuja el mismo garabato y obtendrás la misma contraseña cada vez. Tan simple, pero tan inteligente.

Utiliza este enlace de referencia para registrarte y obtener funciones ampliadas para ti y para mí.
${url}

¡Que te diviertas!`,

  // ===========================================================================

  GRP_INVITE_EMAIL_SUBJECT: 'invitación de grupo Forghetti',
  GRP_INVITE_EMAIL_BODY: (inviteUrl, referralUrl, referralCode) =>
    `Hola, me gustaría compartir mi grupo Forghetti contigo. Haz clic en el siguiente enlace para abrir Forghetti y unirte a mi grupo:
${inviteUrl}

Si no tienes Forghetti, agrega este código de referencia ${referralCode} en tu perfil en la aplicación y obtén más tiempo premium para ti y para mí. ¡Olvídate de tus contraseñas para siempre!`,

  APPRATE_TITLE: '¿Te importaría valorar Forgetti?',
  APPRATE_MESSAGE: `No te llevará más de un minuto y ayudará a dar a conocer nuestra app. Gracias por tu apoyo`,
  APPRATE_CANCEL: 'No, gracias',
  APPRATE_LATER: 'Recuérdamelo más tarde',
  APPRATE_RATE: 'Valorar Forghetti',
  APPRATE_YES: 'Si',
  APPRATE_NO: 'Ahora no',
  APPRATE_PROMPT_TITLE: '¿Te gusta usar Forghetti?',
  APPRATE_FEEDBACK: '¿Te importaría darnos tu opinión?',

  BADGE_HEALTHCHECK_HEADER: 'Ejecuta una verificación de estado',
  BADGE_HEALTHCHECK_INFO:
    'Obtén esta insignia realizando una verificación de estado del nombre de usuario y la contraseña de Forghetti',
  BADGE_HEALTHCHECK_COMPLETE_INFO:
    '¡Felicidades! Has ganado esta insignia al completar la verificación de estado de Forghetti',
  BADGE_HEALTHCHECK_REWARD: (premium) => (premium ? 'Obtén 2 servicios adicionales para tu grupo personal' : ''),
  BADGE_HEALTHCHECK_CTA: 'Ejecuta una verificación de estado ahora',

  BADGE_RATING_HEADER: 'Valorar Forghetti',
  BADGE_RATING_INFO: 'Gana esta insignia al valorar Forghetti',
  BADGE_RATING_COMPLETE_INFO: '¡Felicidades! Has ganado esta insignia al valorar Forghetti',
  BADGE_RATING_REWARD: (premium) => (premium ? 'Obtén 2 servicios adicionales para tu grupo personal' : ''),
  BADGE_RATING_CTA: 'Valorar Forghetti ahora',

  BADGE_PROFILECOMPLETE_HEADER: 'Completa tu perfil',
  BADGE_PROFILECOMPLETE_INFO: 'Gana esta insignia completando tu perfil de Forghetti.',
  BADGE_PROFILECOMPLETE__COMPLETE_INFO: '¡Felicidades! Has ganado esta insignia al completar su perfil de Forghetti.',
  BADGE_PROFILECOMPLETE_REWARD: (premium) => (premium ? 'Obtén 2 servicios adicionales para tu grupo personal' : ''),
  BADGE_PROFILECOMPLETE_CTA: 'Completa tu perfil ahora',

  BADGE_SHARING_HEADER: 'Compartir Forghetti en Facebook',
  BADGE_SHARING_INFO: 'Gana esta insignia dando Me gusta y compartiendo Forghetti en tu red social.',
  BADGE_SHARING_COMPLETE_INFO:
    '¡Felicidades! Has ganado esta insignia al darle Me gusta y compartir Forghetti en tu red social.',
  BADGE_SHARING_REWARD: (premium) => (premium ? 'Obtén 2 servicios adicionales para tu grupo personal' : ''),
  BADGE_SHARING_CTA: 'Compartir Forghetti en Facebook',

  BADGE_IMPORT_HEADER: 'Servicios de importación',
  BADGE_IMPORT_INFO: 'Obtén esta insignia cuando importes servicios desde su teléfono o llavero de PC',
  BADGE_IMPORT_COMPLETE_INFO:
    '¡Felicidades! Has ganado esta insignia al importar servicios desde su teléfono o llavero de PC',
  BADGE_IMPORT_REWARD: (premium) => (premium ? 'Obtén 2 servicios adicionales para tu grupo personal' : ''),
  BADGE_IMPORT_CTA: 'Empieza a importar servicios ahora ',

  BADGE_SUPERUSER_HEADER: 'Conviértete en un superusuario',
  BADGE_SUPERUSER_INFO: `Gana esta insignia cuando hayas compartido Forghetti con 5 amigos, tengas más de dos grupos, tengas una cuenta premium y tengas más de 50 servicios en tu cuenta Forghetti.`,
  BADGE_SUPERUSER_COMPLETE_INFO: `¡Felicidades! Has ganado esta insignia al compartir Forghetti con 5 amigos, agregar más de dos grupos, suscribir una cuenta premium y crear más de 50 servicios.`,
  BADGE_SUPERUSER_REWARD: (premium) => (premium ? 'Obtén 2 servicios adicionales para tu grupo personal' : ''),
  BADGE_SUPERUSER_CTA: 'Compartir Forghetti con un amigo',

  BADGE_SERVICES_50_HEADER: '50 servicios!',
  BADGE_SERVICES_50_INFO: 'Gana esta insignia creando 50 servicios en tu cuenta Forghetti',
  BADGE_SERVICES_50_COMPLETE_INFO:
    '¡Felicidades! Has ganado esta insignia al crear más de 50 servicios en su cuenta Forghetti',
  BADGE_SERVICES_50_REWARD: (premium) => (premium ? 'Obtén 2 servicios adicionales para tu grupo personal' : ''),
  BADGE_SERVICES_50_CTA: 'Crea un servicio ahora',

  BADGE_SERVICES_150_HEADER: '150 servicios!',
  BADGE_SERVICES_150_INFO: 'Gana esta insignia creando 150 servicios en tu cuenta Forghetti',
  BADGE_SERVICES_150_COMPLETE_INFO:
    '¡Felicidades! Has ganado esta insignia al crear más de 150 servicios en su cuenta Forghetti',
  BADGE_SERVICES_150_REWARD: (premium) => (premium ? 'Obtén 2 servicios adicionales para tu grupo personal' : ''),
  BADGE_SERVICES_150_CTA: 'Crea un servicio ahora',

  BADGE_SERVICES_300_HEADER: '300 servicios!',
  BADGE_SERVICES_300_INFO: 'Gana esta insignia creando 300 servicios en tu cuenta Forghetti',
  BADGE_SERVICES_300_COMPLETE_INFO:
    '¡Felicidades! Has ganado esta insignia al crear más de 300 servicios en su cuenta Forghetti',
  BADGE_SERVICES_300_REWARD: (premium) => (premium ? 'Obtén 2 servicios adicionales para tu grupo personal' : ''),
  BADGE_SERVICES_300_CTA: 'Crea un servicio ahora',

  BADGE_SERVICES_500_HEADER: '500 servicios!',
  BADGE_SERVICES_500_INFO: 'Gana esta insignia creando 500 servicios en tu cuenta Forghetti',
  BADGE_SERVICES_500_COMPLETE_INFO:
    '¡Felicidades! Has ganado esta insignia al crear más de 500 servicios en su cuenta Forghetti',
  BADGE_SERVICES_500_REWARD: (premium) => (premium ? 'Obtén 2 servicios adicionales para tu grupo personal' : ''),
  BADGE_SERVICES_500_CTA: 'Crea un servicio ahora',

  BADGE_SHARING_1_HEADER: 'Recomendar Forghetti a un amigo',
  BADGE_SHARING_1_INFO: '¡Gana esta insignia cuando recomiendes Forghetti a un amigo y este cree una cuenta!',
  BADGE_SHARING_1_COMPLETE_INFO:
    '¡Felicidades! ¡Has ganado esta insignia al recomedar Forgetti a un amigo que ha creado una cuenta!',
  BADGE_SHARING_1_REWARD: (premium) => (premium ? 'Obtén 2 servicios adicionales para tu grupo personal' : ''),
  BADGE_SHARING_1_CTA: 'Recomendar Forghetti a un amigo',

  BADGE_SHARING_5_HEADER: 'Recomendar Forghetti a 5 amigos',
  BADGE_SHARING_5_INFO: '¡Gana esta insignia cuando recomiendes Forghetti a 5 amigos y ellos creen una cuenta!',
  BADGE_SHARING_5_COMPLETE_INFO:
    '¡Felicidades! ¡Has ganado esta insignia al recomendar Forghetti a 5 amigos que han creado cuentas!',
  BADGE_SHARING_5_REWARD: (premium) => (premium ? 'Obtén 2 servicios adicionales para tu grupo personal' : ''),
  BADGE_SHARING_5_CTA: 'Recomendar Forghetti a un amigo',

  BADGE_SHARING_10_HEADER: 'Recomendar Forghetti a 10 amigos',
  BADGE_SHARING_10_INFO: '¡Gana esta insignia cuando recomiendes Forghetti a 10 amigos y ellos creen una cuenta!',
  BADGE_SHARING_10_COMPLETE_INFO:
    '¡Felicidades! ¡Has ganado esta insignia al recomendar Forghetti a 10 amigos que han creado cuentas!',
  BADGE_SHARING_10_REWARD: (premium) => (premium ? 'Obtén 2 servicios adicionales para tu grupo personal' : ''),
  BADGE_SHARING_10_CTA: 'Recomendar Forghetti a un amigo',

  BADGE_SHARING_25_HEADER: 'Recomendar Forghetti a 25 amigos',
  BADGE_SHARING_25_INFO: '¡Gana esta insignia cuando recomiendes Forghetti a 25 amigos y ellos creen una cuenta!',
  BADGE_SHARING_25_COMPLETE_INFO:
    '¡Felicidades! ¡Has ganado esta insignia al recomendar Forghetti a 25 amigos que han creado cuentas!',
  BADGE_SHARING_25_REWARD: (premium) => (premium ? 'Obtén 2 servicios adicionales para tu grupo personal' : ''),
  BADGE_SHARING_25_CTA: 'Recomendar Forghetti a un amigo',

  BADGE_SHARING_50_HEADER: 'Recomendar Forghetti con 50 amigos',
  BADGE_SHARING_50_INFO: '¡Gana esta insignia cuando recomiendes Forghetti a 50 amigos y ellos creen una cuenta!',
  BADGE_SHARING_50_COMPLETE_INFO:
    '¡Felicidades! ¡Has ganado esta insignia al recomendar Forghetti a 50 amigos que han creado cuentas!',
  BADGE_SHARING_50_REWARD: (premium) => (premium ? 'Obtén 2 servicios adicionales para tu grupo personal' : ''),
  BADGE_SHARING_50_CTA: 'Recomendar Forghetti a un amigo',

  BADGE_GROUP_1_HEADER: 'Crea un nuevo grupo en Forghetti',
  BADGE_GROUP_1_INFO: 'Gana esta insignia al crear y compartir un nuevo grupo en Forghetti.',
  BADGE_GROUP_1_COMPLETE_INFO:
    '¡Felicidades! Has ganado esta insignia al crear y compartir un nuevo grupo en Forghetti.',
  BADGE_GROUP_1_REWARD: (premium) => (premium ? 'Obtén 2 servicios adicionales para tu grupo personal' : ''),
  BADGE_GROUP_1_CTA: 'Crea un grupo ahora',

  BADGE_GROUP_5_HEADER: 'Crea 5 grupos en Forghetti',
  BADGE_GROUP_5_INFO: 'Gana esta insignia al crear y compartir 5 grupos en Forghetti.',
  BADGE_GROUP_5_COMPLETE_INFO: '¡Felicidades! Has ganado esta insignia al crear y compartir 5 grupos en Forghetti',
  BADGE_GROUP_5_REWARD: (premium) => (premium ? 'Obtén 2 servicios adicionales para tu grupo personal' : ''),
  BADGE_GROUP_5_CTA: 'Crea un grupo ahora',

  BADGE_LEGACY_HEADING: `¡Eres una superestrella!`,
  BADGE_LEGACY_INFO_COMPLETE: `Muchas gracias por ser un usuario veterano de Forghetti. Como pequeña muestra de nuestro agradecimiento, ¡Te otorgamos esta insignia especial y 3 meses de nuestra suscripción premium gratis!`,

  JOIN_GORUP_ALERT_MESSAGE: `Te estamos agregando al grupo. Te avisaremos cuando esté listo.`,
  GROUP_UNAVAILABLE: (groupName) =>
    `Se superó el número olvídates gratuitos en <em>${groupName}</em>. Para agregar servicios al grupo, considera actualizar hoy.`,
  GROUP_PERSONAL_UNAVAILABLE: (groupName) =>
    `Se superó el número de olvídates gratuitos en su grupo personal. Para agregar servicios al grupo, considera actualizar hoy.`,
  GROUP_NONE_AVAILABLE: 'Todos tus grupos han superado el límite permitido',

  SHARE_INVITE_USERNAME_HEADING: 'Antes de empezar a compartir',
  SHARE_INVITE_USERNAME_INFO:
    'Para que tus amigos identifiquen los grupos que deseas compartir, crea un nombre de perfil para tu cuenta.',
  SHARE_INVITE_USERNAME_PLACEHOLDER: 'Tu nombre',

  LEGACY_USER_ONBOARDING_HEADING: 'Valoramos tu fidelidad',
  LEGACY_USER_ONBOARDING_INFO:
    '¡Como recompensa, te ofrecemos una suscripción premium de 3 meses absolutamente gratis! Esperamos que disfrutes de la nueva versión de Forghetti aún más que de la anterior.',
  CLAIM_PREMIUM: 'Recibe tu recompensa',
  LEGACY_USER_RELOAD:
    'Para convertir la suscripción a premium, necesitamos recargar Forghetti. Esto sucederá automáticamente en unos segundos.',

  SERVICE_OPEN_ICON_LABEL: 'abierto',
  SERVICE_VIEW_ICON_LABEL: 'ver',
  SERVICE_HIDE_ICON_LABEL: 'ocultar',
  SERVICE_WEBSITE_ICON_LABEL: 'abierto',
  SERVICE_COPY_ICON_LABEL: 'Copiar',

  GROUPSLIST_SWITCH_LABEL: 'cambiar',
  GROUPSLIST_SHARE_LABEL: 'invitar',
  GROUPSLIST_SETTINGS_LABEL: 'ajustes',

  FORGHETTI_GROUP_PERMISSIONS: `Haz clic en este <a data-actionclick="openPermissionsLink">enlace</a> para obtener más información sobre los permisos de grupo.`,

  SUBSCRIPTION_TANDC: `Ten en cuenta que tu suscripción se renovará automáticamente a menos que se cancele antes del final del período actual. Puedes administrar y cancelar la renovación automática, yendo a tu cuenta de la App Store después de la compra. Para obtener más información, visita nuestros <a data-actionclick="openTermsOfServiceUrl">términos de servicio</a> y nuestra <a data-actionclick="openPrivacyPolicyUrl">política de privacidad</a>.`,
  SUBSCRIPTION_TANDC_LIFETIME: `Tenga en cuenta que el tiempo de elevación premium de Forghetti le da derecho a ... Para obtener más información, visite nuestros <a data-actionclick="openTermsOfServiceUrl">términos de servicio</a> y nuestra <a data-actionclick="openPrivacyPolicyUrl">política de privacidad</a>`,
  SUBSCRIPTION_ERROR: 'Hubo un error al validar tus suscripciones. Por favor, inténtalo de nuevo más tarde.',

  SUBSCRIPTION_TANDC_HEADER: 'Tu suscripción',
  SERVICE_INJECT_LABEL: 'Utilizar',
  PHONE_NUMBER_TOO_LONG: 'Numero de télefono no válido',

  ADD_SERVICE_LOADING_PLEASE_WAIT: 'Espera mientras generamos tu servicio Forghetti',
  ADD_SERVICE_LOADING_GENERATING_SERVICE: 'Generando su nuevo servicio',
  ADD_SERVICE_LOADING_GENERATING_PASSWORD: 'Generando una contraseña súper segura',
  ADD_SERVICE_LOADING_LOADING_SERVICE_DETAILS: 'Cargando tu nuevo servicio Forgetti',

  VIEW_SERVICE_LOADING_LOADING_SERVICE_DETAIL: 'Espera mientras cargamos tu servicio',
  VIEW_SERVICE_LOADING_LOADING_SERVICE_FORGHETTIBLE: 'Construyendo tus datos olvídate',

  MIGRATION_ALERT_HEADING: 'Migra tu cuenta',
  MIGRATION_ALERT_MESSAGE: `Para cambiar tu número, ve a nuestra aplicación web en <a href="https://app.forghetti.com" target="_new">https://app.forghetti.com</a>.`,
  DELETE_ALERT_HEADING: 'Eliminar tu cuenta',
  DELETE_ALERT_MESSAGE: `Para eliminar tu cuenta, ve a nuestra aplicación web en <a href="https://app.forghetti.com" target="_new">https://app.forghetti.com</a>.`,
  REFERRAL_CODE_UPDATE_NOT_ALLOWED: `Ya se ha aplicado un código de referencia a tu cuenta`,
  REFERRAL_CODE_APPLIED: (code) => `El código de referencia <em>${code}</em> se ha aplicado a tu cuenta.`,
  INVALID_PHONE_NUMBER: 'El número telefónico no es válido. Inténtalo de nuevo.',
  INVALID_ACTIVATION_CODE: 'El código de activación no es válido',
  INVALID_TOO_MANY_ATTEMPTS: `Ha habido demasiados intentos fallidos de iniciar sesión con este número. Por favor, inténtelo de nuevo más tarde.`,

  REONBOARDING_POPUP_GO_AGAIN: `Hemos mejorado el uso de Forghetti. ¿Te gustaría familiarizarte con cómo funciona y qué hace que Forghetti sea tan bueno?`,
  REONBOARDING_POPUP_BUTTON_GO: `Muéstrame`,
  REONBOARDING_POPUP_BUTTON_CANCEL: 'No, gracias',
  REONBOARDING_POPUP_HEADING: `Todas las novedades de Forghetti 2.0`,
  HELP_QUICKSTART: 'Incorporación rápida',
  YOUR_BADGES: 'Tus insignias',
  GROUP_NOT_OWNER: 'Solo puedes mover servicios a grupos en los que seas propietario.',
  GROUP_NOT_OWNER_TITLE: 'Moviendo servicios',
  VIEWSERVICE_NOT_COPIED_TO_CLIPBOARD: `<span class="toast--title">Necesitarás tu nueva contraseña.</span><span class="toast--text">Haz clic en el botón copiar dentro del campo de contraseña para agregar su nueva contraseña al portapapeles.</span>`,
  EXTENSION_ATTEMPT: (website) =>
    `<strong class="toast--title">Buscando inicios de sesión para </strong><span class="toast--clipboard-item">${website}</span>`,
  EXTENSION_ATTEMPT_FAIL: (website) =>
    `<span class="toast--title"><span class="toast--title">No se pudo encontrar ningún inicio de sesión coincidente</span></span>`,

  UNIVERSAL_LINK_NEW_LOGIN: (url) => `Intentando encontrar un servicio que coincida con ${url}`,
  UNIVERSAL_LINK_NEW_LOGIN_NO_URL: `Lanzando el nuevo asistente de servicio`,

  CONFIRM_COUNTRY_UPDATE_HEADER: 'Cambia tu idioma',
  CONFIRM_COUNTRY_UPDATE_NOTE:
    'Para cambiar de idioma, por favor, pincha en el botón de abajo para guardar y reiniciar Forghetti',
  CONFIRM_BUTTON: 'Reiniciar Forghetti',

  FORGHETTI_LOGIN: 'Forghetti',
  FORGHETTI_LOGIN_TAGLINE: 'Olvida tus contraseñas. Para siempre.',
  FORGHETTI_LOGIN_CREATE: 'Crear una cuenta',
  FORGHETTI_LOGIN_ALREADY_HAVE: 'Ya tengo una cuenta',
  MUST_BE_LOGGED_IN_ACTION: 'Esta acción solo está disponible al iniciar sesión',

  UPDATING_FORGHETTIBLE: 'Actualizando tu olvídate',
  FORGHETTI_ON_MAC: `Forghetti Desktop está disponible para Mac. <a data-actionclick="openForghettiCom">Consíguelo aquí.</a>`,
  FORGHETTI_ON_WINDOWS: `Forghetti Desktop está disponible para Windows. <a data-actionclick="openForghettiCom">Consíguelo aquí.</a>`,

  REALTIME_NEW_SERVICE_FOR_GROUP: (group) => `Se ha agregado un nuevo servicio al grupo compartido ${group}`,
  REALTIME_REMOVE_SERVICE_FOR_GROUP: (group) => `Se ha eliminado un servicio del grupo compartido ${group}`,
  REALTIME_KICK_SERVICE_FOR_GROUP: (group) => `Has sido expulsado del grupo ${group}`,
  REALTIME_BAN_SERVICE_FOR_GROUP: (group) => `Has sido bloqueado en el grupo ${group}`,
  REALTIME_JOIN_SERVICE_FOR_GROUP: (group) => `Un nuevo usuario se ha unido a ${group}`,
  REALTIME_REMOVE_GROUP: (group) => `El grupo ${group} ha sido eliminado por el propietario`,

  PERSONAL_GROUP: 'Personal',
  SHAREABLE_GROUP: 'Grupo compartible',
  IMPORT_MENU: 'Servicios de importación',
  IMPORT_GROUP: 'Servicios importados',

  MONTHS: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],

  AND: 'y',
  NEW_GROUP: 'Nuevo grupo',
  LETS_START_SELECT: 'Comencemos por seleccionar uno o más de entre estos servicios populares ...',
  CREATE_SERVICES: 'Crear servicios',
  CREATING_SERVICES: 'Ahora estamos generando tu servicios',
  MUST_SELECT_MORE_THAN_ONE_SERVICES: 'Seleccione uno o más servicios',
  ADD_NOTE: 'Agregar nueva nota',
  EDIT_NOTE: 'Editar nota',
  NOTE: 'Notas',
  NOTE_COPIED: 'Nota copiada al portapapeles',
  DELETE_NOTE: 'Borrar nota',

  FORGHETTI_LOGIN_TAGLINE: 'Olvida tus contraseñas.',
  FORGHETTI_LOGIN_TAGLINE_2: 'Para siempre.',
  CREATE_ACCOUNT_1_HEADER_2: 'Crea tu cuenta de Forghetti.',

  CREATE_ACCOUNT_2_HEADING: 'Ingresa tu código de verificación.',
  CREATE_ACCOUNT_FINAL_BUTTON: 'Muéstrame cómo',

  ONBOARDING_DOODLETUTORIAL2_HEADING_2: 'Dibuja un patrón',
  ONBOARDING_DOODLETUTORIAL3_HEADING_2: 'Múltiples líneas',
  ONBOARDING_DOODLETUTORIAL4_HEADING_2: 'Puntos también ...',
  ONBOARDING_DOODLETUTORIAL5_HEADING_2: 'O todo a la vez',
  ONBOARDING_DOODLETUTORIAL6_HEADING_2: 'Pero recuerda, ¡el orden es importante!',
  ONBOARDING_DOODLETUTORIAL7_HEADING_2: 'Doodle A,',
  ONBOARDING_DOODLETUTORIAL8_HEADING_2: 'Doodle A, no coincide con Doodle B',
  SECURE_DOODLE_TUTORIAL: 'Tutorial de Doodle seguro',
  DOODLE_TRY_AGAIN: 'Inténtalo otra vez',
  WELCOME_TO_FORGHETTI: 'Bienvenido a Forghetti',

  ONBOARDING_COMPLETE_CONGRATULATIONS: `¡Felicidades! Acabas de dar los primeros pasos para asegurar tu identidad en línea.`,

  ONBOARDING_COMPLETE_CREATE_FORGHETTI: 'Crea una cuenta de Forghetti.',
  ONBOARDING_COMPLETE_SIGNATURE_DOODLE: 'Crea tu firma Doodle.',

  ONBOARDING_COMPLETE_RESET_PASSWORD: 'Restablecer una contraseña insegura.',

  ONBOARDING_COMPLETE_HEALTHCHECK: 'Ejecuta un chequeo de salud de tus cuentas en línea.',

  ONBOARDING_COMPLETE_IMPORT: 'Importar servicios',
  ONBOARDING_COMPLETE_IMPORT_BUTTON: 'Importar servicios',
  ONBOARDING_COMPLETE_REMIND_LATER: 'Recuérdame más tarde',
  ONBOARDING_COMPLETE_HEALTHCHECK_BUTTON: 'Ejecutar un chequeo de salud',

  USERNAME_OR_EMAIL: 'Nombre de usuario',
  USERNAME_OR_EMAIL_SECOND: 'Correo electrónico',
  SERVICE_EDIT_USERNAME_PLACEHOLDER: 'Ingresa cualquier otro detalle de inicio de sesión',

  SERVICE_USERNAME_LABEL: 'Nombre de usuario',
  SERVICE_USERNAME_SECOND_LABEL: 'Correo electrónico',

  ADD_SERVICE_USERNAME_PLACEHOLDER: `Nombre de usuario`,
  SERVICE_USERNAME_PLACEHOLDER_SECOND: `Correo electrónico`,
  FREE_USER_UPGRADE: 'Mejorar a Premium',

  LOGIN_ACCOUNT_1_HEADER_2: 'Inicia sesión en tu cuenta de Forghetti.',
  KEYCHAIN_HEADING: 'Guarda tu garabato',
  KEYCHAIN_MESSAGE:
    'Puedes guardar tu garabato de forma segura en este dispositivo para facilitar la generación de tus contraseñas.',
  KEYCHAIN_MESSAGE_2: '¿Te gustaría guardar tu garabato en este dispositivo?',
  KEYCHAIN_MESSAGE_3: 'Siempre puedes cambiar esto en tu configuración.',
  KEYCHAIN_YES: 'Sí, guarda mi garabato',
  KEYCHAIN_NO: 'No, dibujaré mi garabato cada vez',

  LOADING_VAULT: 'Cargando tu cámara acorazada.',
  ONBOARDING_COMPLETE_FIRST_LOGIN: 'Agrega un servicio.',
  ONBOARDING_COMPLETE_CONTINUE: `Ahora restablece una contraseña.`,
  STILL_LOADING: 'Estamos cargando tu cuenta.',
  STILL_LOADING_2: 'Tu cuenta ha sido verificada.',

  CREATE_ACCOUNT_VERIFYING_PHONE: 'Estamos verificando tu número',
  NOTIFICATION_MESSAGE_BAR: `Haz clic en 'Usar' en un servicio para enviar tu contraseña a {{service}}`,
  NO_NOTIFICATIONS_HELLO_TEXT: (name) => `Hola ${name}! Todavía no tienes ninguna notificación.`,
  NO_NOTIFICATIONS_CHECK: 'Comprueba regularmente para obtener actualizaciones.',
  REPLAY_DOODLE_HEADING: 'Repetir',
  FORGHETTIBLE: 'Forghettible',
  SERVICE_NO_NOTE: 'Sin nota',
  SERVICE_NOTE: 'Nota',
  SERVICE_BAD_NOTE: 'Para desbloquear esta nota, vuelve a dibujar el garabato.',
  SERVICE_NAME: 'Nombre del Servicio',
  REPLAY_DOODLE: 'Reproducir garabato',
  ENABLE_BIOMETRICS: 'Habilitar biometría',
  DISABLE_BIOMETRICS: 'Deshabilitar la biometría',
  WEB_DELAY: 'Estableciendo una conexión y cargando tus datos. Si esto lleva demasiado tiempo, comprueba tu conexión.',
  WEB_WAITING: 'Trabajando en ello, por favor espera ...',
  PROFILE_MIGRATE_2_INFO_2: 'Ahora ingrese el código de verificación que recibe en su teléfono anterior:',
  MIGRATE: 'Emigrar',
  MONTHLY: 'Mes',
  YEARLY: 'Año',
  UNLOCK_BIOMETRICS: 'Desbloquear con biometría',
  UNLOCK_DESCRIPTION: 'Desbloquee su cuenta de Forghetti con Biometrics',
  UNLOCK_SERVICE: (service) => `Desbloquear ${service}`,
  UNLOCK_SERVICE_DESCRIPTION: (service) => `Para desbloquear ${service} Use biometría o vuelva a dibujar su garabato`,
  UNLOCK_FALLBACK: 'Usa Doodle',
  UNLOCK_CANCEL: 'Cancelar',
  RATE_US: 'Nos califica',

  TOAST_TOO_MANY_SERVICES_SELECTED: (count) =>
    `Has seleccionado demasiados servicios. Selecciona un máximo de ${count} Servicios.`,
  LETS_START_SELECT_COUNT: (count) =>
    `Comencemos seleccionando estos servicios populares ... puede seleccionar <em data-count>${count}</em> más en el nivel gratuito`,
  LETS_START_ADDING_DETAILS: `Agreguemos más detalles`,
  ONBOARDING_COMPLETE_NEXT_STEPS: `<h2>Ahora, ¿por qué</h2><ul><li>no ejecutar un chequeo de salud de tus cuentas en línea,</li><li>O actualizar otro servicio en Forghetti,</li><li>O simplemente echar un vistazo ...?</li></ul>`,
  ONBOARDING_COMPLETE_CONGRATULATIONS: `¡Felicidades! Acabas de dar los primeros pasos para asegurar tu identidad en línea.`,
  CREATE_ACCOUNT_1_MSG_4: `<b>Nota</b>: Solo usaremos tu número para crear/asistir tu cuenta de Forghetti. Recibirás un código de verificación de dispositivo único.`,
  MOVE_SERVICE_CONFIRM_NOTE_UPDATE: (fromGroup, toGroup, list, listLength) =>
    `${listLength > 1 ? 'NUEVO' : 'un nuevo'} <em>${list}</em> forghettible${
      listLength > 1 ? 's' : ''
    } se generará a partir de <em>${toGroup}</em> tu garabato. Si deseas acceder a tu antiguo forghettible${
      listLength > 1 ? 's' : ''
    }, simplemente dibuja tu doodle ${fromGroup} en su lugar.`,
  YOUR_SUBSCRIPTION_PARTNER_PREMIUM_TEXT_1: (partner, link) =>
    `Nuestros socios en <em> ${partner} </em> le proporcionan Forghetti Premium. Puede obtener más información sobre su suscripción aquí: <a href="${link}" target="_new"> ${link} </a>`,
  YOUR_SUBSCRIPTION_PARTNER_PREMIUM_TEXT_2: `Esperamos que disfrutes usando Forghetti.`,
  PARTNER_PREMIUM_HEADING: `Felicidades!`,
  PARTNER_PREMIUM_MESSAGE: (partner) =>
    `Como socio de Forghetti, los miembros <em> ${partner} </em> obtienen forghetti premium. No necesita hacer nada, la suscripción se aplicará automáticamente a su cuenta. Para que los cambios entren en vigencia inmediatamente, haga clic en 'Recargar Forghetti' ahora.`,
  PARNTER_PREMIUM_BUTTON: `Recargar forghetti`,

  CSL_SUBSCRIPTION_HEADER: `Prima activada`,
  CSL_SUBSCRIPTION_1: `Has activado nuestro servicio premium. Gracias. Cuando expira esta suscripción, puede continuar como un usuario gratuito (y perder todos sus beneficios premium) o renovar su suscripción.`,
  CSL_SUBSCRIPTION_2: `Esperamos que esté disfrutando usando Forghetti.`,
  LICENCE_KEY_INVALID: (partner) => `Lo siento, su solicitud de solicitud no es válida.`,
  LICENCE_KEY_USED: (partner) => ` Lo siento, su solicitud de solicitud no es válida. `,
  LICENCE_KEY_ALREADY_APPLIED: (partner) => `Te has suscripto satisfactoriamente. Gracias por usar este servicio.`,

  SERVICE_EMAIL_LABEL: 'Correo electrónico',
  SERVICE_EMAIL_PLACEHOLDER: 'user@email.com',
  SERVICE_EDIT_EMAIL_PLACEHOLDER: 'user@email.com',
  PROFILE_USE_BIOMETRICS: 'Guarde su garabato con biometría',
  BIOMETRICS_GROUPS_HEADER: 'Habilitar biometría',
  BIOMETRICS_SELECT_GROUP: 'Seleccione un grupo para almacenar su garabato con biometría',
  BIOMETRICS: 'Biometría',
  BIOMETRICS_ENABLE: 'Permitir',
  BIOMETRICS_DISABLE: 'Desactivar',
  BIOMETRICS_SECURE: 'Seguro con biometría',
  BIOMETRICS_SECURE_MESSAGE: (group) =>
    `Su garabato para <em> ${group} </em> se almacenará de forma segura con biometría`,
  BIOMETRICS_USE_DOODLE: 'Usa Doodle',
  BIOMETRICS_TRY_AGAIN: 'Intentar otra vez',
  NOTES: 'Notas',
  SELECT_SPECIAL_CHARACTERS: 'Seleccione qué símbolos incluir',
  WORDS: 'Palabras <span class="monoSospace">Ejemplo</span>',
  LETTERS: 'Letras <span class="monoSospace">ABC</span>',
  NUMBERS: 'Números <span class="monoSospace">123</span>',
  SYMBOLS: 'Símbolos <span class="monoSospace">!$%</span>',
  SELECT_PASSWORD_TYPE: (forghettibleName) => `${forghettibleName}`,
  SELECT_PASSWORD_NUMBERS: 'Letras totales / números / símbolos',
  SELECT_PASSWORD_WORDS: 'Palabras totales',
  ALL: 'Todo',
  SIMPLE: 'Simple',
  EDIT_PASSWORD_HEADING: 'Ajustes',
  SERVICE_FIELD_PASSWORD_NO_OPTIONS: 'Se debe seleccionar al menos una opción de contraseña',
  SERVICE_FIELD_PASSWORD_NO_SYMBOLS_SELECTED: 'Se debe seleccionar al menos un símbolo',
  SUBSCRIPTION_ENDS: (days) => `Su suscripción debe expirar en ${days} días.`,
  SUBSCRIPTION_ENDS_TOMORROW: 'Su suscripción debe expirar mañana.',
  SUBSCRIPTION_ENDS_TODAY: 'Su suscripción debe expirar hoy.',
  NOTIFICATIONS: 'Notificaciones',
  CONTEXT_CHANGE_TYPE: 'Opciones de cambio',
  CONTEXT_CHANGE_LENGTH: 'Longitud de cambio',
  CONTEXT_RENAME: 'Cambiar el nombre de Forghettible',
  NEW_FORGHETTIBLE: 'Nuevo fordible',
  OLD_FORGHETTIBLE: 'Viejo forghettible',
  DELETE_FIELD_PROMPT: '¿Estás seguro de que te gustaría eliminar este campo?',
  ONBOARDING2_WELCOME_HEADING: 'Bienvenido a Forghetti',
  ONBOARDING2_WELCOME_TEXT: 'Inicie sesión en todos los servicios que use con un Doodle seguro.',
  ONBOARDING2_DOODLE_HEADING: 'Crea tu Doodle Signature',
  ONBOARDING2_DOODLE_TEXT:
    'Desde líneas o puntos, hasta pellizcos aleatorios o secuencias numéricas. Su combinación única genera una contraseña súper segura. ',
  ONBOARDIN2_HOWITWORKS: 'Cómo funciona',
  ONBOARDING_CONFIRM_DOODLE: 'Confirma tu garabato',
  ONBOARDING_BIOMETRICS_CONFIRM: '¿Le gustaría guardar esto en su llavero?',
  SAVING_NOTE: 'Cifrar tu nota',
};
