const config = require('../../../config');

const onboadingTemplate = require('./onboarding_template');

const strings = require('../../../config').strings;

module.exports = ({ nav }) => {
  return onboadingTemplate({
    nav,
    id: 'onboarding-doodletutorial2',
    //imagePath: 'img/animations/01_multi_lines.json',
    secondary_heading: strings.ONBOARDING_DOODLETUTORIAL(),
    primary_heading: strings.ONBOARDING_DOODLETUTORIAL2_HEADING(),
    // nextLink: ONBOARDING_SHOWMEHOW,
    nextPage: 'onboarding_doodletutorial3',
  });
};
